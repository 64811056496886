import * as React from 'react';
export const StreamlineMultipleUsers1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M2.64 6.133c0 .584.228 1.143.633 1.556.405.413.955.644 1.528.644.573 0 1.123-.231 1.528-.644a2.22 2.22 0 00.633-1.556 2.22 2.22 0 00-.633-1.555 2.141 2.141 0 00-1.528-.645c-.573 0-1.123.232-1.528.645a2.22 2.22 0 00-.633 1.555zM4.801 9.8c-.955 0-1.87.386-2.546 1.074A3.7 3.7 0 001.2 13.467v2.2h1.44l.72 5.866h2.882M17.045 6.133c0 .584.228 1.143.633 1.556.405.413.955.644 1.528.644.573 0 1.123-.231 1.528-.644a2.22 2.22 0 00.633-1.556 2.22 2.22 0 00-.633-1.555 2.141 2.141 0 00-1.528-.645c-.573 0-1.123.232-1.528.645a2.22 2.22 0 00-.633 1.555zM19.206 9.8c.955 0 1.87.386 2.546 1.074a3.7 3.7 0 011.055 2.593v2.2h-1.44l-.72 5.866h-2.882M9.123 3.933c0 .778.303 1.524.844 2.075.54.55 1.273.859 2.037.859s1.497-.31 2.037-.86a2.96 2.96 0 00.844-2.074 2.96 2.96 0 00-.844-2.074A2.855 2.855 0 0012.004 1c-.764 0-1.497.31-2.037.86a2.96 2.96 0 00-.844 2.073zM17.045 13.467a5.18 5.18 0 00-1.476-3.63 4.996 4.996 0 00-3.565-1.504c-1.338 0-2.62.541-3.565 1.504a5.18 5.18 0 00-1.477 3.63v2.2h2.16L9.843 23h4.322l.72-7.333h2.161v-2.2z" />
  </svg>
);
