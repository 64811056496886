import { Stack } from '@spaceship-fspl/components';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import React from 'react';

import { QRCodeCard } from '../cards/qr-code';
import { SecurityCard } from '../cards/security';
import { UserContactDetailsCard } from '../cards/user-contact-details';
import { UserIdentityDetailsCard } from '../cards/user-identity-details';

export const UserDetails: React.FC<React.PropsWithChildren> = () => {
  const isChangePasswordEnabled = useFeatureFlag(
    FeatureFlagKeys.CHANGE_PASSWORD_ENABLED,
  );

  return (
    <Stack spaceY="md">
      <UserIdentityDetailsCard />
      <UserContactDetailsCard />
      {isChangePasswordEnabled && <SecurityCard />}
      <QRCodeCard />
    </Stack>
  );
};
