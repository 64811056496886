import { subMonths } from 'date-fns';

import { getToday, TimeScale } from './date';

export interface DataPoint {
  x: number;
  y: number;
  original?: {
    date?: string | Date;
    y?: string;
  };
}

export interface DataPointContext {
  currentIndex: number;
  current: DataPoint;
}

export const toDateGraphData = <T>(
  data: T[],
  accessor: (o: T) => { date: string; y: string },
): Array<DataPoint> =>
  data.reduce((acc: DataPoint[], data: T) => {
    const { date, y } = accessor(data);
    return [
      ...acc,
      { x: new Date(date).getTime(), y: Number(y), original: { date, y } },
    ];
  }, []);

export const sliceGraphDataByTimeScale = (
  data: DataPoint[],
  timeScale: TimeScale,
): DataPoint[] => {
  let from = new Date(0);
  switch (timeScale) {
    case TimeScale.TWELVE_MONTHS:
      from = subMonths(getToday(), 12);
      break;
    case TimeScale.SIX_MONTHS:
      from = subMonths(getToday(), 6);
      break;
    case TimeScale.THREE_MONTHS:
      from = subMonths(getToday(), 3);
      break;
    case TimeScale.ONE_MONTH:
      from = subMonths(getToday(), 1);
      break;
  }
  return data.filter(({ x }) => x && new Date(x) >= from) || [];
};
