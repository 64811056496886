import { color } from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

import { Stack } from '../layout';
import {
  DefaultLinkComponent,
  PropsWithLinkComponent,
} from '../link-component';
import { UnstyledLink } from '../links';
import { Text } from '../text';

const DocLink = styled(UnstyledLink)`
  text-decoration: underline;
`;

const Paragraph = styled(Text).attrs({
  variant: 4,
  color: 'neutral.070',
})`
  ${DocLink},
  ${DocLink}:active,
  ${DocLink}:visited {
    ${color('neutral.070')}
  }

  ${DocLink}:hover {
    ${color('neutral.050')}
  }
`;

export const DisclaimerSection: React.FC<
  React.PropsWithChildren<PropsWithLinkComponent>
> = ({ LinkComponent = DefaultLinkComponent }) => {
  return (
    <Stack spaceY="sm">
      <Paragraph>
        Spaceship Capital Limited (ABN 67 621 011 649, AFSL 501605) is the
        issuer of the Spaceship Origin Portfolio, Spaceship Universe Portfolio,
        and Spaceship Earth Portfolio, as well as the Spaceship Master Fund (of
        which the Spaceship Galaxy Portfolio and Spaceship Explorer Portfolio
        are investment options) (collectively, Spaceship Voyager).
      </Paragraph>
      <Paragraph>
        Spaceship Capital Limited is the promoter of Spaceship Super. Spaceship
        Super is issued by Diversa Trustees Limited (ABN 49 006 421 638, AFSL
        235153, RSEL L0000635) as trustee of OneSuper (ABN 43 905 581 638)
        (Fund). Spaceship Super is a sub-plan of the Fund.
      </Paragraph>
      <Paragraph>
        Spaceship Capital Limited arranges the US Investing service through a US
        broker-dealer.
      </Paragraph>
      <Paragraph>
        The information on this website is general in nature as it has been
        prepared without taking account of your objectives, financial situation
        or needs. You should consider the relevant{' '}
        <LinkComponent href="/important-documents/">
          {(props) => <DocLink {...props}>Terms and Conditions</DocLink>}
        </LinkComponent>
        {', '}
        <LinkComponent href="/important-documents/">
          {(props) => (
            <DocLink {...props}>Product Disclosure Statement</DocLink>
          )}
        </LinkComponent>{' '}
        and/or{' '}
        <LinkComponent href="/important-documents/">
          {(props) => <DocLink {...props}>Reference Guide</DocLink>}
        </LinkComponent>
        , along with the{' '}
        <LinkComponent href="/important-documents/">
          {(props) => <DocLink {...props}>Target Market Determination</DocLink>}
        </LinkComponent>
        , and obtain appropriate financial and taxation advice, before deciding
        whether Spaceship is right for you.
      </Paragraph>
      <Paragraph>
        All investment products have an element of risk. As share markets go up
        and down, so too can the value of your investment. Please consider the
        risks involved before you jump on board.
      </Paragraph>
      <Paragraph>
        Spaceship Voyager and the US Investing service is only available to
        Australian residents.
      </Paragraph>
      <Paragraph>© {new Date().getFullYear()} Spaceship</Paragraph>
    </Stack>
  );
};
