import {
  Accordion,
  Box,
  Columns,
  Divider,
  Heading,
  Stack,
} from '@spaceship-fspl/components';
import { getSpace } from '@spaceship-fspl/styles';
import {
  useEmployerContributionDocuments,
  useMemberDetails,
} from '@spaceship-fspl/super';
import { LabeledField } from 'components/labeled-field';
import { ResourceList } from 'components/resource-list';
import React from 'react';
import styled from 'styled-components';

export const SuperMemberDetails: React.FC<React.PropsWithChildren> = () => {
  const { data: memberDetails } = useMemberDetails();
  const { data: documents } = useEmployerContributionDocuments();

  return (
    <>
      <Stack spaceY="sm">
        <Stack spaceY="sm">
          <LabeledField
            size="md"
            label={memberDetails?.member?.name?.label ?? 'Member name'}
          >
            {memberDetails?.member?.name?.value}
          </LabeledField>

          <LabeledField
            size="md"
            label={memberDetails?.member?.number?.label ?? 'Member number'}
          >
            {memberDetails?.member?.number?.value}
          </LabeledField>
        </Stack>

        <LabeledField size="md" label={memberDetails.fund.name.label}>
          {memberDetails.fund.name.value}
        </LabeledField>

        <Columns spaceX="sm" spaceY="sm">
          <Columns.Column width={{ xs: 1, md: 5 / 9 }}>
            <LabeledField size="md" label={memberDetails.fund.usi.label}>
              {memberDetails.fund.usi.value}
            </LabeledField>
          </Columns.Column>

          <Columns.Column width={{ xs: 1, md: 4 / 9 }}>
            <LabeledField size="md" label={memberDetails.fund.abn.label}>
              {memberDetails.fund.abn.value}
            </LabeledField>
          </Columns.Column>
        </Columns>
      </Stack>
      {!!documents?.length && (
        <Box marginTop="md">
          <Divider color="neutral.050" />

          <Box paddingTop="xxxs">
            <ResourceList
              isSuperResource={true}
              resources={documents.map(({ id }) => ({
                memberDocumentId: id,
                label: 'Employer contribution letter',
                indicateWillOpenNewTab: true,
              }))}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export const SuperMemberDetailsAccordion: React.FC<
  React.PropsWithChildren
> = () => {
  return (
    <Accordion
      variant="info"
      title={<Heading variant={5}>View member details</Heading>}
      chevronPosition="right"
    >
      <StyledAccordionContent>
        <SuperMemberDetails />
      </StyledAccordionContent>
    </Accordion>
  );
};

const StyledAccordionContent = styled.div`
  margin-bottom: -${getSpace('xs')};
`;
