import * as React from 'react';
export const StreamlineLegalScaleUnequal1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 3.763V21.52M1.001 15.762l3.5-7.166 3.5 7.166" />
    <path vectorEffect="non-scaling-stroke" d="M4.501 19.12a3.493 3.493 0 002.5-.96 3.212 3.212 0 001-2.4H1a3.212 3.212 0 001 2.4 3.493 3.493 0 002.5.96zM22.999 10.002l-3.5-7.232-3.5 7.232" />
    <path vectorEffect="non-scaling-stroke" d="M19.499 13.361a3.492 3.492 0 01-2.5-.96 3.211 3.211 0 01-1-2.4h7a3.212 3.212 0 01-1 2.4 3.493 3.493 0 01-2.5.96zM2.447 9.392L20.557 2.3M7.5 21.52h9-7" />
  </svg>
);
