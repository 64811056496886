import * as React from 'react';
export const StreamlineLightBulb = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M4.95 8.383a7.083 7.083 0 1014.167 0 7.083 7.083 0 00-14.167 0v0zM9.2 18.3h5.667M9.2 21.133h5.667M12.033 22.55v-1.417" />
    <path vectorEffect="non-scaling-stroke" d="M12.033 15.467v-3.542L9.908 9.8M12.033 11.925L14.158 9.8" />
  </svg>
);
