import { Color, getColor } from '@spaceship-fspl/styles';
import React from 'react';
import styled, { keyframes } from 'styled-components';

export const SuccessIcon: React.FC<
  React.PropsWithChildren<{ color?: Color }>
> = ({ color = 'mint.050' }) => {
  const hexColor = getColor(color);

  return (
    <StyledIcon
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <StyledCirclePath
        d="M86 48.0001C86 68.9869 68.9868 86.0001 48 86.0001C27.0132 86.0001 10 68.9869 10 48.0001C10 27.0132 27.0132 10.0001 48 10.0001C68.9868 10.0001 86 27.0132 86 48.0001Z"
        stroke={hexColor}
      />
      <StyledCircleShadowPath
        d="M86 48.0001C86 68.9869 68.9868 86.0001 48 86.0001C27.0132 86.0001 10 68.9869 10 48.0001C10 27.0132 27.0132 10.0001 48 10.0001C68.9868 10.0001 86 27.0132 86 48.0001Z"
        fill={hexColor}
      />
      <StyledTickPath
        d="M33 50.3442 L42.375 59.7263L63 39.0857"
        stroke={hexColor}
      />
    </StyledIcon>
  );
};

const StyledIcon = styled.svg`
  overflow: visible;
`;

const circlePathKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  55% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledCirclePath = styled.path`
  animation: ${circlePathKeyframes} 1.5s linear 0.5s forwards;
  transform-origin: center;
`;

const circleShadowPathKeyframes = keyframes`
  0% {
    opacity: 0.05;
    transform: scale(0.5);
  }
  40% {
    opacity: 0.1;
    transform: scale(1.8);
  }
  85% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`;

const StyledCircleShadowPath = styled.path`
  animation: ${circleShadowPathKeyframes} 1.5s linear 0.5s forwards;
  opacity: 0;
  transform-origin: center;
`;

const tickPathKeyframes = keyframes`
  0% {
    stroke-dashoffset: 50;
    transform: scale(1.5);
  }
  60% {
    stroke-dashoffset: 50;
    transform: scale(1.5);
  }
  100% {
    stroke-dashoffset: 0;
    transform: scale(1);
  }
`;

const StyledTickPath = styled.path`
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: ${tickPathKeyframes} 1.5s linear 0.5s forwards;
  transform-origin: center;
`;
