import { RouteComponentProps, useNavigate } from '@reach/router';
import { Stack } from '@spaceship-fspl/components';
import { useGetUser, useSetGreenIdFields } from '@spaceship-fspl/data';
import {
  COUNTRIES,
  ForeignPassportFields,
  foreignPassportValidations,
  GreenIdTermsAndCondition,
  IDName,
  idSourceProtosToType,
  useID,
  validateNameByLength,
} from '@spaceship-fspl/green-id';
import { formatDate } from '@spaceship-fspl/helpers';
import { GreenIdSourceName } from '@spaceship-fspl/types/externalapi';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import { useGreenID } from 'contexts/greenid';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React from 'react';
import { useForm } from 'react-hook-form';

import { NO_USER_VERIFICATION_ERROR, useReverifyAndNavigate } from './hooks';
import { FormLayout } from './layout';

export const ForeignPassport: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const idName = IDName.INTERNATIONAL_PASSPORT;
  const { getUploadLinkBySourceName, ruleset } = useGreenID();
  const { mutateAsync: postGreenIDFields, isLoading } =
    useSetGreenIdFields(ruleset);
  const verificationId = useID(ruleset);
  const notification = useNotifications();
  const { pop: showIntercom } = useIntercom();
  const reverifyAndNavigate = useReverifyAndNavigate(idName);
  const { data: user } = useGetUser();
  const contact = user?.contact;

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ForeignPassportFields>({
    defaultValues: {
      greenid_visadvs_givenname: contact?.first_name || '',
      greenid_visadvs_middlename: '',
      greenid_visadvs_surname: contact?.last_name || '',
      greenid_visadvs_dob: contact?.date_of_birth
        ? formatDate(new Date(contact.date_of_birth))
        : '',
      greenid_visadvs_passport_number: '',
      greenid_visadvs_country_of_issue: '',
      greenid_visadvs_tandc: GreenIdTermsAndCondition.ON,
    },
  });

  const onSubmit = async (fields: ForeignPassportFields): Promise<void> => {
    const source_name = idSourceProtosToType(GreenIdSourceName.Enum.VISADVS);
    try {
      await postGreenIDFields({
        verification_id: verificationId,
        source_name,
        field_inputs: {
          ...fields,
          greenid_visadvs_tandc: GreenIdTermsAndCondition.ON,
        },
      });

      await reverifyAndNavigate();
    } catch (error) {
      if (
        error instanceof TypeError ||
        (error as Error | undefined)?.message.includes(
          NO_USER_VERIFICATION_ERROR,
        )
      ) {
        // Network error
        notification.popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
        showIntercom();
      } else if (source_name) {
        // API error
        navigate?.(getUploadLinkBySourceName(source_name), {
          replace: true,
        });
      }
    }
  };

  return (
    <FormLayout
      title={idName}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      idName={idName}
    >
      <Stack spaceY="sm">
        <ControllerInputWithInlineError
          name="greenid_visadvs_passport_number"
          control={control}
          placeholder="Passport number"
          type="text"
          rules={foreignPassportValidations.passportNumber}
        />
        <Dropdown
          options={COUNTRIES}
          placeholder="Country of issue"
          errorMessage={errors['greenid_visadvs_country_of_issue']?.message}
          {...register('greenid_visadvs_country_of_issue', {
            required: "Your passport's country of issue is required",
          })}
        />
        <ControllerInputWithInlineError
          name="greenid_visadvs_givenname"
          control={control}
          placeholder="First name (as shown on ID)"
          type="text"
          rules={{
            ...foreignPassportValidations.firstName,
            validate: (value: string) =>
              validateNameByLength({
                name: value,
                otherName: watch('greenid_visadvs_middlename'),
                validLength: 49,
                errorMessage:
                  'Your first and middle names must be between 1 and 49 characters.',
              }),
          }}
        />
        <ControllerInputWithInlineError
          name="greenid_visadvs_middlename"
          control={control}
          placeholder="Middle name (optional)"
          type="text"
          rules={{
            ...foreignPassportValidations.middleName,
            validate: (value: string) =>
              validateNameByLength({
                name: value,
                otherName: watch('greenid_visadvs_givenname'),
                validLength: 49,
                errorMessage:
                  'Your first and middle names must be between 1 and 49 characters.',
              }),
          }}
        />
        <ControllerInputWithInlineError
          name="greenid_visadvs_surname"
          control={control}
          placeholder="Last name (as shown on ID)"
          type="text"
          rules={foreignPassportValidations.lastName}
        />
        <ControllerInputWithInlineError
          name="greenid_visadvs_dob"
          control={control}
          placeholder="Date of birth"
          type="text"
          format="date"
          rules={foreignPassportValidations.dateOfBirth}
          dateFormatOption={{
            delimiter: '/',
          }}
        />
      </Stack>
    </FormLayout>
  );
};
