import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import React, { createContext, useContext, useState } from 'react';

import { InvestmentPlan, OneOffDeposit } from './types';
interface PortfolioRegistration {
  portfolio?: SaverPortfolio.Enum;
  setPortfolio?: (value: SaverPortfolio.Enum) => void;
  oneOffDeposit?: OneOffDeposit;
  setOneOffDeposit?: (value?: OneOffDeposit) => void;
  investmentPlan?: InvestmentPlan;
  setInvestmentPlan?: (value?: InvestmentPlan) => void;
}

const PortfolioRegistrationContext = createContext<
  PortfolioRegistration | undefined
>(undefined);

export const PortfolioRegistrationProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [portfolio, setPortfolio] = useState<SaverPortfolio.Enum | undefined>();
  const [oneOffDeposit, setOneOffDeposit] = useState<string | undefined>();
  const [investmentPlan, setInvestmentPlan] = useState<
    InvestmentPlan | undefined
  >();

  return (
    <PortfolioRegistrationContext.Provider
      value={{
        portfolio,
        setPortfolio,
        oneOffDeposit,
        setOneOffDeposit,
        investmentPlan,
        setInvestmentPlan,
      }}
    >
      {children}
    </PortfolioRegistrationContext.Provider>
  );
};

export const usePortfolioRegistration = (): PortfolioRegistration => {
  const context = useContext(PortfolioRegistrationContext);

  if (!context) {
    throw new Error(
      'webapp: usePortfolioRegistration must be wrapped with <PortfolioRegistrationProvider />',
    );
  }

  return context;
};
