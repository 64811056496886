import { RouteComponentProps, useNavigate } from '@reach/router';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Success } from 'components/layouts/success';
import { useBankAccountSubmissionContext } from 'contexts/bank-account-verification';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';

export const BankAccountSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();

  const { variant } = useBankAccountSubmissionContext();
  const isUserVerified = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );

  const navigateTo = useMemo(() => {
    if (variant === 'onboarding') {
      return isUserVerified
        ? Routes.VOYAGER_ONBOARDING_DEPOSIT
        : Routes.VOYAGER_ONBOARDING_INVESTMENT_PLAN;
    }

    return Routes.ACCOUNT_USER_DETAILS;
  }, [variant, isUserVerified]);

  return (
    <Success
      title="Your bank account has been successfully linked"
      buttonText="Done"
      onContinue={{
        trackingProperties: {
          name: 'voyager_onboarding_link_bank_success_done',
        },
        onClick: () => {
          navigate(navigateTo);
        },
      }}
    />
  );
};
