import * as React from 'react';
export const DollarCircle = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11.999 5.25v13.5M15.125 7.705h-4.688c-.58 0-1.136.226-1.546.63-.41.402-.641.948-.641 1.518 0 .57.23 1.116.64 1.518.41.403.967.63 1.547.63h3.126c.58 0 1.136.226 1.546.629.41.402.641.949.641 1.518 0 .57-.23 1.116-.64 1.519-.41.403-.967.629-1.547.629H8.25" />
    <path vectorEffect="non-scaling-stroke" d="M12 22.25c5.66 0 10.25-4.59 10.25-10.25S17.66 1.75 12 1.75 1.75 6.34 1.75 12 6.34 22.25 12 22.25z" />
  </svg>
);
