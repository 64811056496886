import {
  BoostRecipeParameterType,
  SaverPortfolio,
} from '@spaceship-fspl/graphql';
import { capitalizeWords, formatCurrency } from '@spaceship-fspl/helpers';

import { getPortfolioName } from './helpers';

interface Parameter {
  id: string;
  name: string;
  value: string;
  type: BoostRecipeParameterType;
}

interface GetBoostRecipeTemplateVariablesProps {
  weatherStationName?: string;
  portfolio?: SaverPortfolio | null;
  parameters?: Array<Parameter> | null;
}

export const getBoostRecipeTemplateVariables = ({
  weatherStationName,
  portfolio,
  parameters,
}: GetBoostRecipeTemplateVariablesProps): Record<string, string> => ({
  postcode: weatherStationName ?? '',
  portfolio: capitalizeWords(getPortfolioName(portfolio) ?? ''),
  ...parameters?.reduce((acc: Record<string, string>, parameter) => {
    let value = parameter.value;
    switch (parameter.type) {
      case BoostRecipeParameterType.AUD_AMOUNT:
        value = formatCurrency(parameter.value);
    }

    acc[parameter.name] = value;
    return acc;
  }, {}),
});

interface BoostRecipeFormParameter {
  name: string;
  type: BoostRecipeParameterType;
}

interface BoostRecipeFormInput {
  name: string;
  type: BoostRecipeParameterType | 'portfolio' | 'postcode';
}

interface GetBoostRecipeFormPartsProps {
  description: string;
  parameters: Array<BoostRecipeFormParameter>;
}

interface BoostRecipeFormParts {
  inputs: Array<BoostRecipeFormInput>;
  parts: Array<string>;
}

export const getBoostRecipeFormParts = ({
  description,
  parameters,
}: GetBoostRecipeFormPartsProps): BoostRecipeFormParts => {
  if (!description) {
    return { inputs: [], parts: [] };
  }

  const parts = description
    .replace(/{{\s/g, '{{')
    .replace(/\s}}/g, '}}')
    .split(/({{[a-zA-Z]*}})/g);
  const inputs: Array<BoostRecipeFormInput> = [];

  parts.forEach((part) => {
    switch (true) {
      case part === '{{portfolio}}':
        inputs.push({ name: 'portfolio', type: 'portfolio' });
        break;

      case part === '{{postcode}}':
        inputs.push({ name: 'postcode', type: 'postcode' });
        break;

      case part.slice(0, 2) == '{{' && part.slice(-2) == '}}': {
        const param = parameters.find((sp) => sp.name === part.slice(2, -2));

        if (param) {
          inputs.push(param);
        }
      }
    }
  });

  return { inputs, parts };
};
