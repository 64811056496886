import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { WebAppMoneyWithdrawalPage } from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyWithdrawalPage';
import {
  addDays,
  cleanNumber,
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatCurrency,
  formatDate,
  maskBankAccountNumber,
  sydneyDate,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { Error } from 'components/layouts/error';
import { PageContainer } from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { toAussieDate } from 'helpers/format';
import { TestId } from 'helpers/test-ids';
import { commonValidationRules } from 'helpers/validation';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { FeatureFlagKeys, useFeatureFlag } from '../../helpers/dynamic-config';
import { Routes } from '../routes';

const WITHDRAWAL_NOT_AVAILABLE_MESSAGE_FALLBACK =
  'Withdrawals are not available at this time. Please contact support.';

export const MoneyWithdraw: React.FC<RouteComponentProps> = () => {
  const intercom = useIntercom();
  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );
  const navigate = useNavigate();

  const { control, formState, watch, handleSubmit, setValue } = useForm<{
    audAmount: string;
  }>({
    shouldFocusError: true,
    mode: 'onChange',
  });

  const withdrawalAmount = watch('audAmount');

  const submit = handleSubmit(async (input: { audAmount: string }) => {
    const cleaned = cleanNumber(input.audAmount);
    navigate(Routes.MONEY_WITHDRAW_CONFIRM, {
      state: {
        audAmount: cleaned,
        etaDate: formattedEstimatedExecutionDate,
      },
    });
  });

  const resp = useQuery<WebAppMoneyWithdrawalPage>(
    gql`
      query WebAppMoneyWithdrawalPage($isMoneyDayOneEnabled: Boolean! = false) {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            ... on Account @include(if: $isMoneyDayOneEnabled) {
              moneyAvailableAudBalance
              moneyInstance {
                id
                transactionLimits {
                  withdrawalAvailable
                  withdrawalMinAudAmount
                  withdrawalNotAvailableMessage
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: { isMoneyDayOneEnabled },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // initiaises form with `moneyAvailableAudBalance` when it is less than
        // the `withdrawalMinAudAmount` so the user can make a withdrawal
        // down to $0.00
        const availableMoney =
          Number(data?.contact?.account?.moneyAvailableAudBalance) ?? null;
        if (availableMoney > 0 && availableMoney < withdrawalMinAudAmount) {
          setValue('audAmount', formatCurrency(availableMoney), {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
        }
      },
    },
  );

  const isLoading = resp.loading;
  const error = resp.error;

  const moneyAvailableAudBalance =
    resp.data?.contact?.account?.moneyAvailableAudBalance;

  const bankAccountNumber =
    resp.data?.contact?.account?.activeBankAccount?.accountNumber;

  const withdrawalMinAudAmount =
    Number(
      resp.data?.contact?.account?.moneyInstance?.transactionLimits
        ?.withdrawalMinAudAmount,
    ) ?? 5;

  const transactionLimits =
    resp.data?.contact?.account?.moneyInstance?.transactionLimits;
  const withdrawalAvailable = transactionLimits?.withdrawalAvailable;
  const withdrawalNotAvailableMessage =
    transactionLimits?.withdrawalNotAvailableMessage ??
    WITHDRAWAL_NOT_AVAILABLE_MESSAGE_FALLBACK;

  // @rv: this date needs to be confirmed with product/ops + only business days
  const estimatedExecutionDate = toAussieDate(addDays(sydneyDate(), 2));
  const formattedEstimatedExecutionDate = formatDate(
    estimatedExecutionDate,
    DATE_FORMAT_TRANSACTIONS_LONG,
  );

  const renderAfterAmount = ({
    availableMoney,
    withdrawalAmount,
    formIsTouched,
    formIsDirty,
    formIsValid,
  }: {
    availableMoney?: string | null;
    withdrawalAmount?: string;
    formIsTouched?: boolean;
    formIsValid?: boolean;
    formIsDirty?: boolean;
  }): ReactElement => {
    // if not valid always show a `-`
    if (!formIsValid) {
      return (
        <Text
          data-testid={'money_withdrawal_page_after_amount'}
          isBold
          variant={2}
        >
          {formatCurrency('')}
        </Text>
      );
    }

    if (
      availableMoney &&
      Number(availableMoney) < withdrawalMinAudAmount &&
      Number(availableMoney) > 0
    ) {
      return (
        <Text
          data-testid={'money_withdrawal_page_after_amount'}
          variant={2}
          color={'pink.030'}
          isBold
        >
          {formatCurrency('0')}
        </Text>
      );
    }

    // if form has not been dirtied/touched
    if (!formIsDirty && !formIsTouched && availableMoney) {
      return (
        <Text
          data-testid={'money_withdrawal_page_after_amount'}
          color="neutral.080"
          isBold
          variant={2}
        >
          {formatCurrency(moneyAvailableAudBalance)}
        </Text>
      );
    }

    // if withdrawal amount is valid
    if (availableMoney && withdrawalAmount) {
      const available = Number(availableMoney);
      const withdrawal = Number(cleanNumber(withdrawalAmount));
      const amount = available - withdrawal;
      if (amount >= 0) {
        return (
          <Text
            data-testid={'money_withdrawal_page_after_amount'}
            variant={2}
            color={'pink.030'}
            isBold
          >
            {formatCurrency(amount)}
          </Text>
        );
      }
    }
    return (
      <Text
        data-testid={'money_withdrawal_page_after_amount'}
        variant={2}
        color={'pink.030'}
        isBold
      >
        {formatCurrency('')}
      </Text>
    );
  };

  if (error) {
    return (
      <PageContainer>
        <Error
          title={'How much money would you like to withdraw?'}
          subtitle={GENERIC_ERROR_MESSAGE}
          iconColor="indigo.070"
          buttonText="Go back"
          onContinue={{
            onClick: () => {
              navigate(-1);
            },
            trackingProperties: {
              name: 'money_withdraw_error_go_back',
            },
          }}
        />
      </PageContainer>
    );
  }

  return !isLoading ? (
    <PageContainer>
      <Stack spaceY="xxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
            <Stack spaceY="md">
              <Heading component={'h2'} variant={3} align="center">
                How much money would you like to withdraw?
              </Heading>

              {!withdrawalAvailable ? (
                <Stack spaceY="md" alignX="center">
                  <Box
                    backgroundColor="neutral.000"
                    borderRadius="xs"
                    padding="sm"
                    display="flex"
                  >
                    <FeatherInfoIcon color="indigo.070" />
                    <Box flex={1} marginLeft="xs">
                      <Text variant={3}>{withdrawalNotAvailableMessage}</Text>
                    </Box>
                  </Box>

                  <Button
                    variant="primary"
                    size="lg"
                    trackingProperties={{
                      name: 'money_withdraw_not_available_go_back',
                    }}
                    onClick={() => navigate(-1)}
                    data-testid={TestId.MONEY_WITHDRAW_PAGE_GO_BACK_BUTTON}
                  >
                    Go back
                  </Button>
                  <Button
                    variant="secondary"
                    size="lg"
                    trackingProperties={{
                      name: 'money_withdraw_not_available_contact_support',
                    }}
                    onClick={() => intercom.pop()}
                    data-testid={
                      TestId.MONEY_WITHDRAW_PAGE_CONTACT_SUPPORT_BUTTON
                    }
                  >
                    Contact support
                  </Button>
                </Stack>
              ) : (
                <Box marginTop="md">
                  <form onSubmit={submit}>
                    <Stack spaceY="sm">
                      <ControllerInput
                        name="audAmount"
                        label="Amount"
                        control={control}
                        type="text"
                        format="currency"
                        placeholder="Amount"
                        disabled={
                          Number(moneyAvailableAudBalance) <
                          withdrawalMinAudAmount
                        }
                        rules={{
                          required: 'Withdrawal amount is required',
                          validate: {
                            atMostBalance: (value?: string) =>
                              value && moneyAvailableAudBalance
                                ? commonValidationRules.atMost.validate(
                                    moneyAvailableAudBalance,
                                    value,
                                  )
                                : false,
                            atLeast: (value?: string): boolean | string => {
                              if (value && Number(cleanNumber(value)) < 0) {
                                return 'Amount is invalid';
                              }

                              // form control is disabled if available balance is less than $5,
                              // and user may withdraw the full amount
                              if (
                                Number(moneyAvailableAudBalance) <
                                withdrawalMinAudAmount
                              ) {
                                return true;
                              }

                              return value
                                ? commonValidationRules.atLeast.validate(
                                    withdrawalMinAudAmount ?? '5.00',
                                    value,
                                  )
                                : false;
                            },
                          },
                        }}
                      />
                      <Divider color="neutral.030" />
                      <Columns>
                        <Columns.Column width={1 / 2}>
                          <Text
                            color={'neutral.080'}
                            component={'h3'}
                            isBold
                            variant={4}
                          >
                            Now
                          </Text>
                          <Text
                            data-testid={'money_withdrawal_page_now_amount'}
                            variant={2}
                            isBold
                          >
                            {formatCurrency(moneyAvailableAudBalance)}
                          </Text>
                        </Columns.Column>
                        <Columns.Column width={1 / 2}>
                          <Text
                            color={'neutral.080'}
                            component={'h3'}
                            isBold
                            variant={4}
                          >
                            After
                          </Text>
                          {renderAfterAmount({
                            availableMoney: moneyAvailableAudBalance,
                            withdrawalAmount: withdrawalAmount,
                            formIsDirty: formState.isDirty,
                            formIsValid: formState.isValid,
                            formIsTouched: formState.touchedFields.audAmount,
                          })}
                        </Columns.Column>
                      </Columns>
                      <Divider color="neutral.030" />
                      <Stack spaceY="xxs">
                        <Text
                          color={'neutral.080'}
                          component={'h3'}
                          isBold
                          variant={4}
                        >
                          Destination
                        </Text>
                        <Text isBold variant={2}>
                          Linked bank account
                        </Text>
                        <Text variant={3} color={'neutral.080'}>
                          The proceeds from your withdrawal will be transferred
                          to your linked bank account ending in{' '}
                          {maskBankAccountNumber(bankAccountNumber)}.
                        </Text>
                      </Stack>
                    </Stack>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      marginTop="xl"
                    >
                      <Stack spaceY="xs">
                        <Button
                          variant="primary"
                          size="lg"
                          type="submit"
                          trackingProperties={{
                            name: 'money_withdraw_continue',
                          }}
                        >
                          Continue
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </Box>
              )}
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  ) : null;
};
