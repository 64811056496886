import * as React from 'react';
export const MoneyPlant = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11 13v11m0 0s-.896-3.238-5-6.69" />
    <path vectorEffect="non-scaling-stroke" d="M3.867 19.14c1.033 1.043 2.66 1.424 3.648-.527 2.444-1.342 1.442-3.041.054-3.93A7.74 7.74 0 004.272 14 7.72 7.72 0 001 14.776c1.667 1.5 1.582 3.064 2.867 4.364zM11 23s1.254-2.904 7-6" />
    <path vectorEffect="non-scaling-stroke" d="M20.49 19.14c-.902 1.043-2.327 1.424-3.19-.527-2.139-1.343-1.262-3.041-.048-3.931a6.061 6.061 0 012.884-.681c.999.016 1.98.281 2.864.774-1.46 1.501-1.384 3.065-2.51 4.365zM12.605 3.7h-2.169a1.56 1.56 0 00-.914.285 1.233 1.233 0 00-.496.73c-.063.286-.01.582.15.838s.417.455.727.562l2.2.771c.308.109.564.308.723.563.159.255.211.551.148.837a1.233 1.233 0 01-.494.728 1.56 1.56 0 01-.91.287H9.405m1.6-5.601V3m0 7v-.7" />
    <path vectorEffect="non-scaling-stroke" d="M11 13a6 6 0 100-12 6 6 0 000 12z" />
  </svg>
);
