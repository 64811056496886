import * as React from 'react';
export const StreamlineKoala = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M7.067 8.524a7.017 7.017 0 019.924 0M13.434 12.781a1.526 1.526 0 00-1.404-1.403 1.524 1.524 0 00-1.403 1.403c-.702 3.509-.702 4.912 1.403 4.912s2.105-1.403 1.404-4.912zM8.17 11.378a.35.35 0 01.352.35M7.82 11.729a.35.35 0 01.35-.351M8.17 12.08a.35.35 0 01-.35-.351M8.522 11.729a.351.351 0 01-.351.35M15.89 11.378a.35.35 0 01.35.35M15.539 11.729a.35.35 0 01.35-.351M15.89 12.08a.35.35 0 01-.351-.351M16.24 11.729a.35.35 0 01-.35.35" />
    <path vectorEffect="non-scaling-stroke" d="M9.219 7.05a3.403 3.403 0 00-2.807-3.253A3.824 3.824 0 001.5 7.307c0 2.22 1.403 2.106 1.403 2.106s-.848 3.395 2.122 3.649a7.017 7.017 0 1014-.14c2.981-.25 2.13-3.65 2.13-3.65s1.404.115 1.404-2.104a3.823 3.823 0 00-4.912-3.509 3.406 3.406 0 00-2.806 3.39" />
  </svg>
);
