import { Box } from '@spaceship-fspl/components';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  getSpace,
  padding,
  paddingBottom,
  paddingLeft,
  PaddingProps,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { createProps } from '@spaceship-fspl/substance-style';
import React, { forwardRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

export interface RadioProps extends PaddingProps {
  name: string;
  value: string | number;
  children: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  radioPosition?: 'left' | 'right';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      defaultChecked,
      error,
      radioPosition = 'left',
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
      ...props
    },
    ref,
  ) => {
    const isLeftSideRadio = radioPosition === 'left';

    const paddingProps = {
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
    };

    return (
      <StyledLabel
        hasError={!!error}
        radioPosition={radioPosition}
        {...paddingProps}
      >
        <StyledRadioInput
          defaultChecked={defaultChecked}
          type="radio"
          {...{ ...props, ref }}
        />

        <StyledCustomRadioBox>
          <TickIcon />
        </StyledCustomRadioBox>

        <Box
          display="flex"
          alignItems="center"
          flex={1}
          paddingLeft={isLeftSideRadio ? 'lg' : 'none'}
          paddingRight={isLeftSideRadio ? 'none' : 'lg'}
        >
          {children}
        </Box>
      </StyledLabel>
    );
  },
);

Radio.displayName = 'Radio';

const TickIcon: React.FC<React.PropsWithChildren> = () => (
  <StyledTickSvg
    viewBox="0 0 14 13"
    stroke="currentColor"
    fill="none"
    height="13"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.20067 6.86189L5.83948 10.1764L12.0582 1.74798"
      strokeWidth="3.3"
    />
  </StyledTickSvg>
);

const tickKeyframes = keyframes`
  0% {
    opacity: 0;
    stroke-dasharray: 20;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    stroke-dasharray: 40;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    stroke-dasharray: 40;
    transform: scale(1);
  }
`;

const StyledTickSvg = styled.svg`
  opacity: 0;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  transform: scale(1);
  transform-origin: center;
`;

const radioKeyframes = keyframes`
  0% {
    opacity: 0;
    border-width: 2px;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    border-width: 3px;
  }
`;

const RADIO_SIZE = getSpace('md');

const StyledRadioInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const StyledCustomRadioBox = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})`
  ${borderColor('neutral.050')}
  ${borderWidth('md')}
  ${color('neutral.000')}
  border-style: solid;
  position: relative;
  ${transition}

  &,
  ::before {
    ${borderRadius('xs')}
    line-height: 0;
    height: ${RADIO_SIZE};
    width: ${RADIO_SIZE};
  }

  ::before {
    ${backgroundColor('indigo.070')}
    ${borderColor('indigo.070')}
    border-style: solid;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledLabel = styled.label<
  {
    hasError: boolean;
    radioPosition: 'left' | 'right';
  } & PaddingProps
>`
  display: flex;
  flex-direction: ${({ radioPosition }) =>
    radioPosition === 'left' ? 'row' : 'row-reverse'};
  position: relative;
  min-height: ${RADIO_SIZE};
  cursor: pointer;

  ${StyledRadioInput}:not(:disabled):checked {
    & ~ ${StyledCustomRadioBox} {
      ${backgroundColor('indigo.070')}
      ${borderColor('indigo.070')}
    }

    & ~ ${StyledCustomRadioBox}::before {
      animation: ${radioKeyframes} 250ms ease forwards;
    }

    & ~ ${StyledCustomRadioBox} ${StyledTickSvg} {
      animation: ${tickKeyframes} 250ms ease forwards;
      opacity: 1;
    }
  }

  ${({ hasError }) =>
    hasError
      ? css`
          ${StyledCustomRadioBox} {
            ${borderColor('red.100')}
          }
        `
      : ''}

  ${StyledRadioInput}:disabled ~ ${StyledCustomRadioBox} {
    ${backgroundColor('neutral.030')}
    ${borderColor('neutral.050')}
    ${color('neutral.080')}
  }

  ${createProps({
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
  })}
`;
