import { gql, useQuery } from '@apollo/client';
import { Box, Pill } from '@spaceship-fspl/components';
import { UpdateContactInput, useUpdateContact } from '@spaceship-fspl/graphql';
import { WebAppIdentityDetailsCard } from '@spaceship-fspl/graphql/src/__generated__/WebAppIdentityDetailsCard';
import {
  FeatherAlertTriangleIcon,
  FeatherCheckIcon,
  FeatherLockIcon,
} from '@spaceship-fspl/icons-web';
import { ControllerInput } from 'components/controller-input';
import { LabeledField } from 'components/labeled-field';
import { useNotifications } from 'contexts/notifications';
import { fromISODate, toLongDate } from 'helpers/format';
import { addRumError } from 'helpers/monitoring';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from './components';

export interface IdentityDetailsCardProps {
  isVerified?: boolean;
  isPreferredNameVisible: boolean;
}

type PreferredNameFormFields = Pick<UpdateContactInput, 'preferredName'>;

export const IdentityDetailsCard: React.FC<
  React.PropsWithChildren<IdentityDetailsCardProps>
> = ({ isVerified, isPreferredNameVisible }) => {
  const resp = useQuery<WebAppIdentityDetailsCard>(gql`
    query WebAppIdentityDetailsCard {
      contact {
        id
        firstName
        lastName
        preferredName
        dateOfBirth
      }
    }
  `);
  const contact = resp.data?.contact;
  const { firstName = '', lastName = '' } = contact || {};
  const name = firstName && lastName ? `${firstName} ${lastName}` : undefined;
  const VerifiedIcon = isVerified ? FeatherCheckIcon : FeatherAlertTriangleIcon;
  const [isEditing, setIsEditing] = useState(false);
  const formMethods = useForm<PreferredNameFormFields>();
  const { control, handleSubmit, reset } = formMethods;
  const [updateContact, updateContactMeta] = useUpdateContact();
  const { popToast } = useNotifications();
  useEffect(() => {
    if (contact) {
      reset({
        preferredName: contact.preferredName,
      });
    }
  }, [contact, reset]);

  const onSubmit = async (payload: PreferredNameFormFields): Promise<void> => {
    try {
      await updateContact({
        variables: {
          input: {
            preferredName: payload.preferredName,
          },
        },
      });
      setIsEditing(false);
      popToast({
        message: 'Successfully updated your preferred name!',
        level: 'success',
      });
    } catch (error) {
      addRumError({ error });
      popToast({
        message:
          'Unable to update preferred name. Please try again or contact support.',
        level: 'error',
      });
    }
  };
  return (
    <AccountCard>
      <AccountCardHeading
        title="Your details"
        isReadOnly={!isPreferredNameVisible}
        edit={{
          isEditing,
          isSubmitting: updateContactMeta.loading,
          onClick: () => {
            setIsEditing(true);
          },
          submitText: 'Save',
          onSubmit: handleSubmit(onSubmit),
          onCancel: () => setIsEditing(false),
        }}
      />

      <AccountCardContent isLoading={resp.loading}>
        <Box display="flex" justifyContent="space-between" marginBottom="sm">
          <LabeledField label="Legal name" size="md">
            {name}
          </LabeledField>

          {isVerified !== undefined && !isEditing && (
            <Pill variant={isVerified ? 'success' : 'error'}>
              {isVerified ? 'verified' : 'unverified'}
              <Box paddingLeft="xxxs" lineHeight={0}>
                <VerifiedIcon size="sm" />
              </Box>
            </Pill>
          )}
          {isEditing && (
            <Box lineHeight={0}>
              <FeatherLockIcon size="sm" color="neutral.070" />
            </Box>
          )}
        </Box>

        <Box display="flex" justifyContent="space-between" marginBottom="sm">
          <LabeledField label="Date of birth" size="md">
            {contact?.dateOfBirth &&
              toLongDate(fromISODate(contact?.dateOfBirth))}
          </LabeledField>

          {isEditing && (
            <Box lineHeight={0}>
              <FeatherLockIcon size="sm" color="neutral.070" />
            </Box>
          )}
        </Box>

        {!isEditing && isPreferredNameVisible && (
          <Box display="flex" marginBottom="sm">
            <LabeledField label="Preferred Name" size="md">
              {contact?.preferredName || '—'}
            </LabeledField>
          </Box>
        )}

        {isEditing && isPreferredNameVisible && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <ControllerInput
              name="preferredName"
              control={control}
              placeholder="Preferred Name"
              type="text"
            />
          </form>
        )}
      </AccountCardContent>
    </AccountCard>
  );
};
