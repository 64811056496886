import { createVariant } from '@spaceship-fspl/substance-style';
import { css } from 'styled-components';

import { fontSize, lineHeight } from '../../text';

// FIGMA REFERENCE:
// https://www.figma.com/file/vXLkjFFAdOCa9oODr2i4mr/_S8-COMPONENT-LIBRARY?node-id=1898%3A57265

export const fontFamilies = {
  articleHeading: 'Playfair Display, serif',
  heading:
    'Inter, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, sans-serif',
  text: 'Inter, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, sans-serif',
  monospace: 'IBM Plex Mono, monospace',
};

export const headingVariant = createVariant({
  1: css`
    font-weight: 700;
    ${fontSize({ xs: '32px', lg: '48px' })}
    ${lineHeight({ xs: '46px', lg: '64px' })}
  `,
  2: css`
    font-weight: 700;
    ${fontSize({ xs: '28px', lg: '32px' })}
    ${lineHeight({ xs: '42px', lg: '46px' })}
  `,
  3: css`
    font-weight: 700;
    ${fontSize({ xs: '24px', lg: '28px' })}
    ${lineHeight({ xs: '36px', lg: '42px' })}
  `,
  4: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  `,
  5: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  `,
});

export const textVariant = createVariant({
  1: css`
    font-size: 20px;
    line-height: 30px;
  `,
  2: css`
    font-size: 16px;
    line-height: 26px;
  `,
  3: css`
    font-size: 14px;
    line-height: 22px;
  `,
  4: css`
    font-size: 12px;
    line-height: 18px;
  `,
});
