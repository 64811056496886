import {
  FlattenInterpolationWithTheme,
  fontFamily,
  getColor,
  map,
  ResponsiveValue,
  Theme,
  transition,
} from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../../box';
import { Spinner } from '../../spinner';
import { UnstyledButton, UnstyledButtonProps } from '../unstyled-button';
import { ButtonSize, ButtonVariant, themes } from './themes';

export type { ButtonSize, ButtonVariant };

interface StyledButtonProps {
  theme: Theme;
  $variant: ResponsiveValue<ButtonVariant>;
  $size: ResponsiveValue<ButtonSize>;
}

const themeVariant = ({
  theme,
  $size,
  $variant,
}: StyledButtonProps): FlattenInterpolationWithTheme => css`
  ${map($size, (s) => themes[theme.name].size(s))}
  ${map($variant, (v) => themes[theme.name].color(v))}
`;

const StyledButton = styled(UnstyledButton)<StyledButtonProps>`
  ${themeVariant}
  ${fontFamily('text')}
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  outline: none;
  text-decoration: none;
  ${transition}

  :focus {
    box-shadow: 0 0 0 3px ${getColor('indigo.020')};
  }
`;

export interface ButtonProps extends UnstyledButtonProps {
  variant: ResponsiveValue<ButtonVariant>;
  size: ResponsiveValue<ButtonSize>;
  before?: React.ReactNode;
  after?: React.ReactNode;
  isLoading?: boolean;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  variant,
  size,
  before,
  after,
  isLoading,
  isDisabled,
  children,
  ...otherProps
}) => {
  const content = !isLoading ? (
    <>
      {before && (
        <Box marginRight={size === 'lg' ? 'xxs' : 'xxxs'}>{before}</Box>
      )}
      <Box>{children}</Box>
      {after && <Box marginLeft={size === 'lg' ? 'xxs' : 'xxxs'}>{after}</Box>}
    </>
  ) : (
    <Spinner size={size === 'lg' ? 'md' : 'sm'} />
  );
  return (
    <StyledButton
      {...otherProps}
      $variant={variant}
      $size={size}
      isDisabled={isDisabled || isLoading}
    >
      {content}
    </StyledButton>
  );
};
