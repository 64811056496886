import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  getFormattedAddress,
  isAddressInputValid,
} from '@spaceship-fspl/address';
import { Box, Columns, Inline, Stack, Text } from '@spaceship-fspl/components';
import {
  AddressInput,
  addressScalars,
  countryScalars,
  foreignTaxResidenceScalars,
  SetTaxResidencyInput,
  TaxGroup,
  useSetTaxResidency,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerTaxResidencyPrimaryAddress } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerTaxResidencyPrimaryAddress';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { useTrack } from '@spaceship-fspl/tracking';
import { AddressForm, EMPTY_ADDRESS_FORM } from 'components/address/form';
import { AddressSearch } from 'components/address/search';
import { ControllerInput } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { LinkButton } from 'components/link-button';
import { RadioCard } from 'components/radio-card';
import { GoogleMapsServicesProvider } from 'contexts/googlemaps';
import { useNotifications } from 'contexts/notifications';
import { AccessibilityLabel } from 'helpers/accessibility';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { addRumError } from 'helpers/monitoring';
import { requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ForeignTaxResidenceField, getUpdatedForeignResidences } from './hooks';

export interface GoogleMapsServices {
  placesService?: google.maps.places.PlacesService;
  autoCompleteService?: google.maps.places.AutocompleteService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
}

interface VoyagerTaxResidencyPrimaryAddressProps {
  location: {
    state: {
      taxGroup?: TaxGroup;
      foreignTaxResidences?: Array<ForeignTaxResidenceField>;
    };
  };
  googleMapServices: GoogleMapsServices;
}

export const VoyagerAddTaxResidencyPrimaryAddress: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<VoyagerTaxResidencyPrimaryAddressProps>
  >
> = ({ location, googleMapServices }) => {
  const navigate = useNavigate();
  const track = useTrack();
  const { taxGroup, foreignTaxResidences } = location?.state ?? {};

  useEffect(() => {
    if (navigate) {
      if (!taxGroup || taxGroup === TaxGroup.UNSPECIFIED) {
        navigate(Routes.VOYAGER_TAX_RESIDENCY_ADD);
      } else if (
        [TaxGroup.AUSTRALIA_AND_OVERSEAS, TaxGroup.OVERSEAS_ONLY].includes(
          taxGroup,
        ) &&
        (!foreignTaxResidences || foreignTaxResidences.length === 0)
      ) {
        navigate(Routes.VOYAGER_TAX_RESIDENCY_ADD_COUNTRIES, {
          state: {
            taxGroup,
          },
        });
      }
    }
  }, [foreignTaxResidences, taxGroup, navigate]);

  if (!taxGroup || taxGroup === TaxGroup.UNSPECIFIED) {
    return null;
  }

  return (
    <TaxResidencyPrimaryAddress
      taxGroup={taxGroup}
      foreignTaxResidences={foreignTaxResidences}
      onSubmit={() => {
        track?.(
          MarketingTrackingEvents.VOYAGER_MANDATORY_TAX_RESIDENCY_SUBMITTED,
        );
        navigate?.(Routes.VOYAGER_DASHBOARD);
      }}
      googleMapServices={googleMapServices}
    />
  );
};

export const VoyagerUpdateTaxResidencyPrimaryAddress: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<VoyagerTaxResidencyPrimaryAddressProps>
  >
> = ({ location }) => {
  const navigate = useNavigate();
  const { taxGroup, foreignTaxResidences } = location?.state ?? {};

  useEffect(() => {
    if (navigate) {
      if (!taxGroup || taxGroup === TaxGroup.UNSPECIFIED) {
        navigate(Routes.VOYAGER_TAX_RESIDENCY);
      } else if (
        [TaxGroup.AUSTRALIA_AND_OVERSEAS, TaxGroup.OVERSEAS_ONLY].includes(
          taxGroup,
        ) &&
        (!foreignTaxResidences || foreignTaxResidences.length === 0)
      ) {
        navigate(Routes.VOYAGER_TAX_RESIDENCY_COUNTRIES, {
          state: {
            taxGroup,
          },
        });
      }
    }
  }, [foreignTaxResidences, taxGroup, navigate]);

  if (!taxGroup || taxGroup === TaxGroup.UNSPECIFIED) {
    return null;
  }

  return (
    <TaxResidencyPrimaryAddress
      taxGroup={taxGroup}
      foreignTaxResidences={foreignTaxResidences}
      onSubmit={() => {
        navigate?.(Routes.ACCOUNT_VOYAGER_DETAILS);
      }}
    />
  );
};

const TaxResidencyPrimaryAddress: React.FC<
  React.PropsWithChildren<{
    taxGroup: Exclude<TaxGroup, TaxGroup.UNSPECIFIED>;
    foreignTaxResidences?: Array<ForeignTaxResidenceField>;
    onSubmit: () => void;
    googleMapServices?: GoogleMapsServices;
  }>
> = ({ taxGroup, foreignTaxResidences, onSubmit, googleMapServices }) => {
  const notification = useNotifications();
  const navigate = useNavigate();
  const [setTaxResidency, setTaxResidencyMeta] = useSetTaxResidency();
  const isUpdating = setTaxResidencyMeta.loading;
  const isNovaRestrictedCountryEnabled = useFeatureFlag(
    FeatureFlagKeys.NOVA_RESTRICTED_COUNTRY_ENABLED,
  );
  const isAustraliaOnlyTaxGroup = taxGroup === TaxGroup.AUSTRALIA_ONLY;

  const form = useForm<{
    useExistingAddress: 'true' | 'false';
    address: AddressInput;
    taxReason: string;
    address_search?: string;
  }>({
    defaultValues: {
      address: EMPTY_ADDRESS_FORM,
      taxReason: '',
    },
    shouldFocusError: true,
  });

  const resp = useQuery<WebAppVoyagerTaxResidencyPrimaryAddress>(
    gql`
      query WebAppVoyagerTaxResidencyPrimaryAddress(
        $isOverseasTaxGroup: Boolean!
      ) {
        contact {
          id
          address {
            ...addressScalars
          }
          account {
            id
            novaProductInstance {
              id
            }
          }
          foreignTaxResidences {
            id
            ...foreignTaxResidenceScalars
          }
        }
        countries @include(if: $isOverseasTaxGroup) {
          id
          ...countryScalars
        }
      }
      ${addressScalars}
      ${foreignTaxResidenceScalars}
      ${countryScalars}
    `,
    {
      variables: { isOverseasTaxGroup: !isAustraliaOnlyTaxGroup },
      onError: () => {
        notification.popToast({
          level: 'error',
          message:
            'There was a problem loading your details. Please try again.',
        });
      },
      onCompleted: (data) => {
        const address = data.contact.address;
        const isAddressValid = isAddressInputValid(address);
        const isCurrentAddressAustralian =
          address?.country.toLowerCase() === 'au';

        if (
          !isAddressValid ||
          (isAustraliaOnlyTaxGroup && !isCurrentAddressAustralian)
        ) {
          form.reset({
            useExistingAddress: 'false',
            taxReason: '',
            address_search:
              !isAddressValid && address?.streetAddress
                ? `${address.streetAddress}, ${address?.suburb ?? ''} ${
                    address.state ?? ''
                  }`
                : '',
          });
        }
      },
    },
  );
  const currentAddress = resp.data?.contact.address;
  const isCurrentAddressAustralian =
    currentAddress?.country.toLowerCase() === 'au';
  const isCurrentAddressValid = isAddressInputValid(currentAddress);
  const isNewAddressMandatory =
    !resp.loading &&
    !resp.error &&
    (!isCurrentAddressValid ||
      (isAustraliaOnlyTaxGroup && !isCurrentAddressAustralian));

  const selectedAddressCountryCode =
    form.watch('useExistingAddress') === 'true'
      ? currentAddress?.country.toLowerCase()
      : form.watch('address.country')?.toLowerCase();
  let isTaxReasonRequired = false;

  if (!!selectedAddressCountryCode && !isAustraliaOnlyTaxGroup) {
    const selectedCountryId = resp.data?.countries?.find((country) => {
      return country.countryCode.toLowerCase() === selectedAddressCountryCode;
    })?.id;
    const isAddressCountryInFTRs = !!foreignTaxResidences?.find((ftr) => {
      return ftr.countryId === selectedCountryId;
    });

    // Tax reason is only required if the country of the primary address
    // does not match any of the foreign tax residences (or Australia if
    // that's included in the tax group)
    switch (true) {
      case taxGroup === TaxGroup.OVERSEAS_ONLY && !isAddressCountryInFTRs:
        isTaxReasonRequired = true;
        break;

      case taxGroup === TaxGroup.AUSTRALIA_AND_OVERSEAS &&
        !isAddressCountryInFTRs &&
        selectedAddressCountryCode !== 'au':
        isTaxReasonRequired = true;
        break;

      default:
        isTaxReasonRequired = false;
        break;
    }
  }
  const novaAllowedByCountryID: Map<string, boolean> = useMemo(() => {
    const map = new Map<string, boolean>();
    resp.data?.countries?.forEach((country) => {
      map.set(country.id, country.isNovaAllowed);
    });
    return map;
  }, [resp.data?.countries]);
  const handleSubmit = form.handleSubmit(async (formData) => {
    const useExistingAddress = formData.useExistingAddress === 'true';
    const address: AddressInput = useExistingAddress
      ? {
          unitNumber: currentAddress?.unitNumber ?? '',
          streetNumber: currentAddress?.streetNumber ?? '',
          streetName: currentAddress?.streetName ?? '',
          streetType: currentAddress?.streetType ?? '',
          suburb: currentAddress?.suburb ?? '',
          state: currentAddress?.state ?? '',
          postcode: currentAddress?.postcode ?? '',
          country: currentAddress?.country ?? '',
        }
      : formData.address;

    if (useExistingAddress && !currentAddress) {
      notification.popToast({
        level: 'warning',
        message:
          'Your address is missing.  Please enter an address and try again.',
      });
      return;
    }

    const ftrs = getUpdatedForeignResidences(foreignTaxResidences ?? []);
    const input: SetTaxResidencyInput = {
      taxGroup,
      foreignTaxResidences: ftrs,
      address: {
        ...address,
        taxReason: isTaxReasonRequired ? formData.taxReason : '',
      },
    };
    if (
      isNovaRestrictedCountryEnabled &&
      resp.data?.contact?.account?.novaProductInstance &&
      (ftrs.some((ftr) => novaAllowedByCountryID.get(ftr.countryId) == false) ||
        input.address?.country.toLocaleLowerCase() !== 'au' ||
        taxGroup === TaxGroup.OVERSEAS_ONLY)
    ) {
      navigate(Routes.TAX_RESIDENCY_US_INVESTING_WARNING, {
        state: {
          input,
        },
      });
      return;
    }

    try {
      await setTaxResidency({
        variables: {
          input,
        },
      });
    } catch (error) {
      addRumError({
        error,
        context: {
          reason:
            'Tax residency - primary address: Update address and tax group failed',
        },
      });
      notification.popToast({
        level: 'error',
        message:
          'There was a problem updating your tax residency. Please try again.',
      });
      return;
    }

    notification.popToast({
      level: 'success',
      message: 'Your tax residency details have been updated',
    });

    onSubmit();
  });

  return (
    <PageContainer>
      <Stack spaceY="lg">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
            <Stack spaceY="lg">
              <PageHeading title="What’s your primary home address?" />

              <Text variant={2} isBold={true} align="center">
                This is where you live most of the year.
              </Text>

              <GoogleMapsServicesProvider
                countryRestrictions={isAustraliaOnlyTaxGroup ? 'au' : undefined}
                autoCompleteService={googleMapServices?.autoCompleteService}
                placesService={googleMapServices?.placesService}
                sessionToken={googleMapServices?.sessionToken}
              >
                <FormProvider {...form}>
                  <form onSubmit={handleSubmit}>
                    <Stack spaceY="xl">
                      <Stack spaceY="md">
                        {!!form.formState.errors.useExistingAddress
                          ?.message && (
                          <Inline spaceX="xs" alignY="center" alignX="center">
                            <Box lineHeight={0}>
                              <FeatherAlertTriangleIcon
                                size="md"
                                color="red.100"
                              />
                            </Box>
                            <Text color="red.100" variant={2} isBold={true}>
                              {form.formState.errors.useExistingAddress.message}
                            </Text>
                          </Inline>
                        )}

                        {!isCurrentAddressValid ? (
                          <NewAddressForm
                            countries={resp.data?.countries ?? []}
                            isAustralianOnlyAddress={isAustraliaOnlyTaxGroup}
                            onAddressSearchFallback={() => {
                              form.reset({
                                address: EMPTY_ADDRESS_FORM,
                              });
                            }}
                          />
                        ) : (
                          <Stack spaceY="md">
                            <RadioCard
                              {...form.register(
                                'useExistingAddress',
                                requiredValidation('Selection'),
                              )}
                              value="true"
                              disabled={resp.loading || isNewAddressMandatory}
                              error={!!form.formState.errors.useExistingAddress}
                              contentPadding={{ padding: 'md' }}
                            >
                              <Stack spaceY="sm">
                                <Text variant={2} isBold={true}>
                                  Use my existing address
                                </Text>

                                {currentAddress && (
                                  <Text variant={3}>
                                    {getFormattedAddress(currentAddress)}
                                  </Text>
                                )}
                              </Stack>
                            </RadioCard>

                            <RadioCard
                              {...form.register(
                                'useExistingAddress',
                                requiredValidation('Selection'),
                              )}
                              value="false"
                              disabled={resp.loading || isUpdating}
                              error={!!form.formState.errors.useExistingAddress}
                              contentPadding={{ padding: 'md' }}
                            >
                              <Stack spaceY="lg">
                                <Text variant={2} isBold={true}>
                                  Enter a new address
                                </Text>

                                {form.watch('useExistingAddress') ===
                                  'false' && (
                                  <NewAddressForm
                                    countries={resp.data?.countries ?? []}
                                    isAustralianOnlyAddress={
                                      isAustraliaOnlyTaxGroup
                                    }
                                    onAddressSearchFallback={() => {
                                      form.reset({
                                        address: EMPTY_ADDRESS_FORM,
                                      });
                                    }}
                                  />
                                )}
                              </Stack>
                            </RadioCard>
                          </Stack>
                        )}
                      </Stack>

                      {isTaxReasonRequired && (
                        <Stack spaceY="xs">
                          <Text variant={2} isBold={true}>
                            If this country is different to your tax residency,
                            provide a reason why
                          </Text>

                          <ControllerInput
                            name="taxReason"
                            control={form.control}
                            type="text"
                            placeholder="Specify your reason why"
                            maxLength={300}
                            rules={{
                              required: 'Reason is required',
                            }}
                          />
                        </Stack>
                      )}

                      <Text variant={3} color="neutral.080" align="center">
                        By continuing you confirm that your tax residency is
                        correct.
                      </Text>
                    </Stack>

                    <PageFormButtonContainer>
                      <PageFormContinueButton
                        trackingProperties={{
                          name: 'tax_residency_primary_address_continue',
                        }}
                        isDisabled={resp.loading || !!resp.error}
                        isLoading={isUpdating}
                      />
                    </PageFormButtonContainer>
                  </form>
                </FormProvider>
              </GoogleMapsServicesProvider>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

interface NewAddressFormProps {
  countries?: Array<{
    id: string;
    name: string;
    countryCode: string;
  }>;
  isAustralianOnlyAddress: boolean;
  onAddressSearchFallback: () => void;
}

const NewAddressForm: React.FC<
  React.PropsWithChildren<NewAddressFormProps>
> = ({ countries, isAustralianOnlyAddress, onAddressSearchFallback }) => {
  const isGoogleMapsAutocompleteEnabled = useFeatureFlag(
    FeatureFlagKeys.GOOGLE_MAPS_AUTOCOMPLETE_ENABLED,
  );
  const [isManualAddressFormShown, setIsManualAddressFormShown] = useState(
    false || !isGoogleMapsAutocompleteEnabled,
  );
  const [googlePlaceId, setGooglePlaceId] = useState<string | undefined>();

  return (
    <Stack spaceY="md">
      {isGoogleMapsAutocompleteEnabled && (
        <Stack spaceY="xxs">
          <AddressSearch
            onFallback={() => {
              onAddressSearchFallback();
              setGooglePlaceId(undefined);
              setIsManualAddressFormShown(true);
            }}
            onSelect={(id) => {
              setGooglePlaceId(id);
              setIsManualAddressFormShown(true);
            }}
          />

          {!isManualAddressFormShown && (
            <LinkButton
              aria-label={AccessibilityLabel.MANUAL_ADDRESS}
              size="xxs"
              onClick={(): void => {
                setIsManualAddressFormShown((isShown) => !isShown);
              }}
              trackingProperties={{
                name: 'tax_residency_primary_address_enter_manually',
              }}
            >
              Enter manually
            </LinkButton>
          )}
        </Stack>
      )}

      {isManualAddressFormShown && (
        <AddressForm
          googlePlaceId={googlePlaceId}
          isAustralianOnlyAddress={isAustralianOnlyAddress}
          countries={countries}
        />
      )}
    </Stack>
  );
};
