import {
  VoyagerGaugePercentage,
  VoyagerPieChartPercentage,
} from '@spaceship-fspl/voyager';
import gauge25 from 'assets/gauge-25.svg';
import gauge50 from 'assets/gauge-50.svg';
import gauge75 from 'assets/gauge-75.svg';
import pieChart25 from 'assets/pie-chart-25.svg';
import pieChart50 from 'assets/pie-chart-50.svg';
import pieChart75 from 'assets/pie-chart-75.svg';
import pieChart100 from 'assets/pie-chart-100.svg';
import React from 'react';

export const GaugeIcon: React.FC<{
  value: VoyagerGaugePercentage;
  width?: number | string;
  height?: number | string;
}> = ({ value, width, height }) => {
  switch (value) {
    case VoyagerGaugePercentage.SeventyFive: {
      return (
        <img alt="gauge 75%" width={width} height={height} src={gauge75} />
      );
    }
    case VoyagerGaugePercentage.Fifty: {
      return (
        <img alt="gauge 50%" width={width} height={height} src={gauge50} />
      );
    }
    case VoyagerGaugePercentage.TwentyFive: {
      return (
        <img alt="gauge 25%" width={width} height={height} src={gauge25} />
      );
    }
  }
};

export const PieChartIcon: React.FC<{
  value: VoyagerPieChartPercentage;
  width?: number | string;
  height?: number | string;
}> = ({ value, width, height }) => {
  switch (value) {
    case VoyagerPieChartPercentage.Hundred: {
      return (
        <img
          alt="pie chart 100%"
          width={width}
          height={height}
          src={pieChart100}
        />
      );
    }
    case VoyagerPieChartPercentage.SeventyFive: {
      return (
        <img
          alt="pie chart 75%"
          width={width}
          height={height}
          src={pieChart75}
        />
      );
    }
    case VoyagerPieChartPercentage.Fifty: {
      return (
        <img
          alt="pie chart 50%"
          width={width}
          height={height}
          src={pieChart50}
        />
      );
    }
    case VoyagerPieChartPercentage.TwentyFive: {
      return (
        <img
          alt="pie chart 25%"
          width={width}
          height={height}
          src={pieChart25}
        />
      );
    }
  }
};
