import { createFetch } from '@spaceship-fspl/fetch';
import { config } from 'helpers/config';

export const fetch = createFetch({
  url: config.api.externalUrl,
  headers: {
    's8-device-id': 'web',
    's8-platform': 'WEB',
  },
  credentials: config.auth.useCookies ? 'include' : undefined,
});
