import * as React from 'react';
export const VoyagerOrigin = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M9.95 10.59l1.107.432a.759.759 0 01.066-.06l-.574-1.47-5.752-5.753a.6.6 0 00-.849.849L9.95 10.59zM5.516 8.86a.6.6 0 00-.436 1.117l3.652 1.425h2.03l-.812-.812-4.434-1.73zm5.54 2.162L9.95 10.59l.813.812h.016a1.37 1.37 0 01.277-.38zm-.786.981l.378-.147c.016-.157.06-.306.125-.443l-.01-.01H8.732l1.538.6z" />
    <path d="M1.359 11.402a.6.6 0 000 1.2h7.368l1.543-.6-1.538-.6H1.359zm9.266 1.2l.107-.104a1.36 1.36 0 01-.084-.348l-.378-.147-1.543.6h1.898z" />
    <path d="M10.648 11.856l-.378.147.378.147a1.38 1.38 0 010-.294zm-8.339 4.53l7.373-2.867.943-.917H8.727l-6.853 2.665a.6.6 0 00.435 1.119zm8.501-3.306l.1-.261a1.356 1.356 0 01-.132-.217h-.153l-.943.917 1.127-.438zM6.352 18.427l3.822-3.713.635-1.633-1.127.438-4.166 4.047a.6.6 0 10.836.86z" />
    <path d="M11.402 13.52v-.293a1.368 1.368 0 01-.345-.243l-.248.097-.635 1.633 1.228-1.194zm-2.691 8.268l2.691-6.922v-1.345l-1.228 1.193-2.581 6.639a.6.6 0 001.118.435z" />
    <path d="M10.91 12.819l-.1.261.247-.096a1.358 1.358 0 01-.146-.165zm1.076.547a1.359 1.359 0 01-.368-.055l-.216.21v1.345l.584-1.5zm-.584-.139v.293l.216-.21a1.365 1.365 0 01-.216-.083z" />
    <path d="M12.602 22.644v-7.89l-.542-1.39-.057.002h-.017l-.584 1.5v7.778a.6.6 0 001.2 0z" />
    <path d="M12.602 13.242l-.01-.01a1.354 1.354 0 01-.532.132l.542 1.39v-1.512zm2.47 4.538l-1.324-3.393-1.146-1.145v1.511l1.352 3.463a.6.6 0 101.118-.436zm-1.797-4.605l-.376-.146a1.361 1.361 0 01-.297.198v.015l1.146 1.145-.473-1.212z" />
    <path d="M19.849 19.64a.6.6 0 000-.849l-5.003-5.002-1.571-.614.473 1.212L19 19.64a.6.6 0 00.849 0z" />
    <path d="M13.66 12.602h-.433a1.36 1.36 0 01-.106.18l.154.393 1.571.614-1.186-1.187z" />
    <path d="M12.9 13.029l.375.146-.154-.393a1.367 1.367 0 01-.222.247zm5.983 1.691a.6.6 0 00-.34-.777l-3.436-1.34H13.66l1.186 1.186 3.26 1.272a.6.6 0 00.777-.34zm-5.307-2.715l-.213.082c-.004.066-.013.13-.026.193l.323.322h1.447l-1.531-.597zm-.349.597h.433l-.323-.322a1.35 1.35 0 01-.11.322z" />
    <path d="M23.244 12.002a.6.6 0 00-.6-.6h-7.52l-1.548.603 1.53.597h7.538a.6.6 0 00.6-.6z" />
    <path d="M13.321 11.656c.023.086.037.175.042.266l.213.083 1.549-.603h-1.542l-.262.254zm8.826-2.984a.6.6 0 00-.435-1.119l-6.949 2.702-1.18 1.147h1.542l7.022-2.73z" />
    <path d="M12.916 10.974l-.005.012c.13.117.238.258.316.416h.356l1.18-1.146-1.847.718zm4.554-3.348a.6.6 0 10-.836-.86l-3.345 3.249-.373.959 1.847-.719 2.707-2.629z" />
    <path d="M16.425 1.95a.6.6 0 10-1.119-.436L12.602 8.47v2.213l.687-.667 3.136-8.066zm-3.823-.591a.6.6 0 10-1.2 0v7.019l.62 1.585.58-1.494v-7.11z" />
    <path d="M12.298 10.672c.082.018.16.043.237.075l.067-.065V8.469l-.58 1.494.276.709zm-.573-.004a.895.895 0 01.024-.005l.272-.7-.619-1.585v1.967l.323.323z" />
    <path d="M10.549 9.491l.574 1.47a1.37 1.37 0 01.28-.182v-.434l-.854-.854z" />
    <path d="M9.988 4.754a.6.6 0 10-1.118.436l1.679 4.301.853.854V8.378L9.988 4.754zm1.737 5.914l-.323-.323v.434c.102-.05.21-.088.323-.111zm.573.004l-.277-.709-.272.7a1.368 1.368 0 01.549.009zm.604.307l.014-.005.373-.959-.687.667v.096c.11.054.21.121.3.2zm.419.677l.262-.254h-.356c.04.08.071.166.094.254zm.042.431l.213-.082-.213-.083a1.445 1.445 0 010 .165z" />
    <path d="M12.602 10.682l-.067.065.067.031v-.096zm-1.87 1.816l-.107.104h.153a1.384 1.384 0 01-.046-.104z" />
  </svg>
);
