import { RouteComponentProps } from '@reach/router';
import { Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { PageContainer } from 'components/layouts/page';
import React from 'react';

export const VoyagerAboutEarthPortfolio: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
          <Stack spaceY="lg">
            <Stack spaceY="md">
              <Heading component="h1" variant={3}>
                About our Earth rating
              </Heading>

              <Text variant={2} color="neutral.085">
                The Spaceship Earth Portfolio will invests in companies believed
                to have a positive impact on people and the planet, as assessed
                against the UN Sustainable Development Goal framework (UN
                framework).
              </Text>
            </Stack>

            <Stack spaceY="md">
              <Heading component="h3" variant={4} isBold={true}>
                What is the UN SDG?
              </Heading>

              <Text variant={2} color="neutral.085">
                The purpose of this card is to provide you with a report on the
                unit price for the Earth portfolio. It shows the change in unit
                price over time. It does not show the individual performance of
                your investment — that&apos;s because the performance of your
                investment will depend on the timing of your investments.
                <br />
                <br />
                This card is provided for your information purposes only. Fund
                performance is typically more volatile over shorter periods of
                time due to the sometimes unpredictable nature of the share
                market. Performance over shorter periods (such as a day, week or
                month) will give you insight into this volatility and should not
                be used to make financial decisions. You should consider your
                personal circumstances, financial situation and needs before
                making a decision to invest additional funds, hold your
                investment, or withdraw your investment.
              </Text>
            </Stack>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
