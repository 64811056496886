import { useGetUser } from '@spaceship-fspl/data';

export const useMaskedMobileNumber = (): string => {
  const { data: user } = useGetUser();
  const mobileNumberLastThreeDigits = user?.contact?.phone_number
    ? user.contact.phone_number.slice(-3)
    : '';

  return `*******${mobileNumberLastThreeDigits}`;
};
