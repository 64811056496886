import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Accordion,
  Box,
  Columns,
  Divider,
  Heading,
  LogoBadge,
  PresentationLink,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  WebAppVoyagerWithdrawal,
  WebAppVoyagerWithdrawalVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerWithdrawal';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  ExternalRoutes,
  formatDate,
  maskBankAccountNumber,
  sydneyDate,
  useEstimatedProcessedDate,
} from '@spaceship-fspl/helpers';
import {
  FeatherClockIcon,
  FeatherInfoIcon,
  FundFromSpaceshipIcon,
  StreamlineCashPaymentWalletIcon,
  StreamlineCursorChooseIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  paddingLeft,
  paddingRight,
  paddingY,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  useWithdrawalLearnMoreCopy,
  WITHDRAWAL_LEARN_MORE,
} from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { PageContainer } from 'components/layouts/page';
import { TrackingEvent } from 'helpers/analytics';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { cleanNumber, formatCurrency } from 'helpers/format';
import { commonValidationRules } from 'helpers/validation';
import { withVoyagerTopNavigation } from 'navigation/helpers';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Routes } from './routes';

type FormValues = Partial<{
  audAmount: string;
}>;

interface PageParams {
  productId?: string;
}

const Withdraw: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: PageParams = useParams();

  const estimatedDate = useEstimatedProcessedDate(sydneyDate());
  const resp = useQuery<
    WebAppVoyagerWithdrawal,
    WebAppVoyagerWithdrawalVariables
  >(
    gql`
      query WebAppVoyagerWithdrawal($id: ID!, $idProvided: Boolean!) {
        contact {
          id
          saverProductInstance {
            id
          }
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            saverProductInstance(id: $id) @include(if: $idProvided) {
              id
              portfolio
              investments {
                id
                summary {
                  id
                  audBalance
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: productId,
        idProvided: !!productId,
      },
      onCompleted: (data) => {
        if (
          !data?.contact?.account?.saverProductInstance?.id &&
          data?.contact?.saverProductInstance?.id
        ) {
          navigate(
            `${Routes.VOYAGER_WITHDRAW}/${data.contact.saverProductInstance.id}`,
            { replace: true },
          );
        }
      },
    },
  );

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    shouldFocusError: true,
  });

  const balance =
    resp.data?.contact?.account?.saverProductInstance?.investments?.summary
      ?.audBalance ?? 0;

  const bankAccountNumber =
    resp.data?.contact?.account?.activeBankAccount?.accountNumber;

  const { audAmount } = watch();

  const moneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  return (
    <PageContainer>
      <Stack spaceY="xxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
            <Stack spaceY="xs">
              <Heading variant={3} align="center">
                How much would you like to withdraw?
              </Heading>
              {!moneyDayOneEnabled && (
                <Text variant={2} isBold={true} align="center">
                  Your current balance is {formatCurrency(balance)}.
                </Text>
              )}
            </Stack>

            <Box marginTop="md">
              <form
                onSubmit={handleSubmit((input: FormValues) => {
                  navigate(`${Routes.VOYAGER_WITHDRAW_CONFIRM}/${productId}`, {
                    state: {
                      audAmount: cleanNumber(input?.audAmount ?? ''),
                      portfolio:
                        resp.data?.contact?.account?.saverProductInstance
                          ?.portfolio,
                    },
                  });
                })}
              >
                <Stack spaceY="sm">
                  <Stack spaceY="xxs">
                    <ControllerInput
                      name="audAmount"
                      control={control}
                      type="text"
                      format="currency"
                      placeholder="Withdrawal amount"
                      rules={{
                        required: 'Withdrawal amount is required',
                        validate: {
                          atLeastOneCent: (value?: string): boolean | string =>
                            value
                              ? commonValidationRules.atLeastOneCent.validate(
                                  value,
                                )
                              : false,
                          atMost: (value?: string): boolean | string =>
                            value
                              ? commonValidationRules.atMost.validate(
                                  balance,
                                  value,
                                )
                              : false,
                        },
                      }}
                    />
                  </Stack>
                  {!moneyDayOneEnabled && (
                    <Text variant={3} color="neutral.080">
                      Your withdrawal will be transferred to your bank account
                      account ending in{' '}
                      {maskBankAccountNumber(bankAccountNumber)}.
                    </Text>
                  )}
                </Stack>

                {moneyDayOneEnabled && (
                  <Box marginTop="sm">
                    <Stack spaceY="sm">
                      <Stack spaceY="xxxs">
                        <Text variant={4} color="neutral.080" isBold={true}>
                          Withdrawable balance
                        </Text>
                        <Text variant={2} isBold={true}>
                          {formatCurrency(balance)}
                        </Text>
                      </Stack>

                      <Divider color="neutral.030" />

                      <Stack spaceY="xxxs">
                        <Text variant={4} color="neutral.080" isBold={true}>
                          Destination
                        </Text>
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                        >
                          <Text variant={2} isBold={true}>
                            Spaceship
                          </Text>
                          <Box marginLeft={'xxxs'}>
                            <LogoBadge variant={'dark'} size="sm" />
                          </Box>
                        </Box>
                        <Text variant={3} color="neutral.080">
                          The proceeds from your withdrawal will be transferred
                          to your Spaceship account.
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                )}

                <Box
                  backgroundColor="neutral.030"
                  borderRadius="xxs"
                  padding="md"
                  marginTop="lg"
                >
                  {!!cleanNumber(audAmount ?? '') &&
                  !!estimatedDate &&
                  !errors['audAmount'] ? (
                    <Stack spaceY="xs" alignX="center">
                      <FeatherClockIcon color="indigo.070" />
                      {!moneyDayOneEnabled && (
                        <Text variant={3} align="center">
                          The ETA of your funds in your bank account is{' '}
                          {formatDate(
                            estimatedDate,
                            DATE_FORMAT_TRANSACTIONS_LONG,
                          )}
                          .
                        </Text>
                      )}
                      <Text variant={3} align="center">
                        Your balance is based on the current unit price, which
                        will be different to the unit price you’ll receive on
                        your withdrawal. This means your withdrawal could be
                        less than requested if the unit price goes down.
                      </Text>
                    </Stack>
                  ) : (
                    <WithdrawTaxLiabilityInfoBox />
                  )}
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="xl"
                >
                  <Stack spaceY="xs">
                    <Button
                      variant="primary"
                      size="lg"
                      type="submit"
                      trackingProperties={{ name: 'withdraw_submit' }}
                    >
                      Continue
                    </Button>
                    <Button
                      variant="secondary"
                      size="lg"
                      onClick={async (): Promise<void> =>
                        await navigate(
                          `${Routes.VOYAGER_TRANSACTIONS}/${productId}`,
                        )
                      }
                      trackingProperties={{ name: 'withdraw_cancel' }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </form>
            </Box>
          </Columns.Column>
        </Columns>

        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 5 / 6 }}>
            <WithdrawalInfoAccordion />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const WithdrawTaxLiabilityInfoBox: React.FC<React.PropsWithChildren> = () => {
  const track = useTrack();
  return (
    <Stack spaceY="xs" alignX="center">
      <FeatherInfoIcon color="indigo.070" />
      <Text variant={3} align="center">
        Withdrawing your investment could make you liable for tax on any gain.
      </Text>
      <StyledLearnMorePresentationLink
        href={ExternalRoutes.MONEY_SMART_INVESTING_AND_TAX}
        target="_blank"
        onClick={(e): void => {
          e.stopPropagation();
          track?.(TrackingEvent.CLICK, {
            properties: {
              name: 'withdrawal_learn_more_button',
            },
          });
        }}
      >
        Learn more
      </StyledLearnMorePresentationLink>
    </Stack>
  );
};

const StyledLearnMorePresentationLink = styled(PresentationLink).attrs({
  color: 'indigo.070',
  size: 'xs',
  icon: 'chevron',
})`
  ${backgroundColor('neutral.050')}
  ${borderRadius('sm')}
  ${paddingLeft('md')}
  ${paddingRight('sm')}
  ${paddingY('xxs')}

  :hover {
    text-decoration: none;
  }
`;

const WITHDRAWAL_LEARN_MORE_ICONS = {
  [WITHDRAWAL_LEARN_MORE.PROCESSING_TIME]: StreamlineCursorChooseIcon,
  [WITHDRAWAL_LEARN_MORE.STRIKE_TIME]: FundFromSpaceshipIcon,
  [WITHDRAWAL_LEARN_MORE.TAX_CONSEQUENCES]: StreamlineCashPaymentWalletIcon,
};

const WithdrawalInfoAccordion: React.FC<React.PropsWithChildren> = () => {
  const withdrawalLearnMoreCopy = useWithdrawalLearnMoreCopy({
    isMoneyDayOneEnabled: useFeatureFlag(FeatureFlagKeys.MONEY_DAY_ONE_ENABLED),
  });
  return (
    <Accordion variant="info" title="How withdrawals at Spaceship works">
      <Box paddingY={{ md: 'xxs' }}>
        <Stack spaceY="md">
          {withdrawalLearnMoreCopy.map(({ label, value }) => {
            const Icon = WITHDRAWAL_LEARN_MORE_ICONS[value];

            return (
              <Box display="flex" key={value}>
                <Box marginRight={{ xs: 'sm', md: 'md' }}>
                  <Icon size="xl" color="indigo.070" />
                </Box>
                <Text variant={2} whiteSpace="pre-line">
                  {label}
                </Text>
              </Box>
            );
          })}
          <Text variant={2}>
            More information about withdrawing funds from Spaceship can be found
            in our{' '}
            <TextLink
              color="indigo.070"
              rel="noreferrer"
              href={ExternalRoutes.VOYAGER_TRANSACTIONS_FAQ}
              target="_blank"
            >
              Frequently Asked Questions
            </TextLink>{' '}
            and{' '}
            <TextLink
              color="indigo.070"
              rel="noreferrer"
              href={ExternalRoutes.MONEY_SMART_INVESTING_AND_TAX}
              target="_blank"
            >
              Moneysmart: Investing and tax
            </TextLink>
            .
          </Text>
        </Stack>
      </Box>
    </Accordion>
  );
};

export const VoyagerWithdraw = withVoyagerTopNavigation()(Withdraw);
