import fetch from 'cross-fetch';
import { encode } from 'qss';
import { v4 as uuidv4 } from 'uuid';

export interface RequestErrorData {
  [k: string]: string;
}

export class FetchError extends Error {
  public httpStatusCode: number;
  public data?: RequestErrorData;
  public constructor(httpStatusCode: number, data?: RequestErrorData) {
    super(data?.message);
    this.httpStatusCode = httpStatusCode;
    this.data = data;
  }
}

export interface FetchFactoryOptions {
  url?: string;
  headers?: { [name: string]: string };
  credentials?: 'include' | 'omit' | 'same-origin';
}

export interface FetchOptions {
  method: string;
  url: string;
  body?: unknown | undefined;
  query?: Record<string, unknown>;
  headers?: { [name: string]: string | undefined };
  token?: string;
  credentials?: 'include' | 'omit' | 'same-origin';
}

export interface FetchFunction {
  (options: FetchOptions): Promise<Record<string, unknown>>;
}

export function createFetch(factoryOptions: FetchFactoryOptions) {
  return async function (
    fetchOptions: FetchOptions,
  ): Promise<Record<string, unknown>> {
    const requestId = fetchOptions.headers?.['x-request-id'] ?? uuidv4();

    const response = await fetch(
      `${factoryOptions.url}${fetchOptions.url}${
        fetchOptions.query ? '?' : ''
      }${fetchOptions.query ? encode(fetchOptions.query) : ''}`,
      {
        method: fetchOptions.method,
        credentials: factoryOptions.credentials ?? fetchOptions.credentials,
        headers: {
          ...(fetchOptions.token
            ? { Authorization: `Bearer ${fetchOptions.token}` }
            : {}),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...factoryOptions.headers,
          ...fetchOptions.headers,
          's8-request-id': requestId,
          'x-request-id': requestId,
        },
        body:
          fetchOptions.body !== undefined
            ? JSON.stringify(fetchOptions.body)
            : undefined,
      },
    );

    if (!response.ok) {
      if (response.status >= 500) {
        throw new FetchError(response.status, {
          message: `Sorry, we've run into a problem. Please try again or contact support.`,
        });
      }
      if (response.status >= 400) {
        throw new FetchError(response.status, await response.json());
      }
    }

    // A 204 response does not have a response body, but our protobuf .fromObject(..)
    // method will throw if we return anything but an object - this is a workaround.
    // @see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/204
    // @see: https://github.com/spaceship-fspl/app.spaceship/pull/2503#discussion_r511705686
    if (response.status === 204) {
      return {};
    }

    return response.json();
  };
}
