import { Box } from '@spaceship-fspl/components';
import { FeatherInfoIcon, FeatherLockIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  Color,
  color,
  FlattenInterpolationWithTheme,
  fontSize,
  fontWeight,
  getColor,
  getSpace,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  text,
  transition,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import { TrackingEvent } from 'helpers/analytics';
import React from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from './tooltip';

interface FormatOptions {
  date: CleaveOptions;
  postcode: CleaveOptions;
  currency: CleaveOptions;
  bsb: CleaveOptions;
  accountNumber: CleaveOptions;
  otp: CleaveOptions;
}

// To add options: https://nosir.github.io/cleave.js/
const formatOptions: FormatOptions = {
  date: {
    date: true,
    delimiter: '-',
    datePattern: ['d', 'm', 'Y'],
  },
  postcode: {
    numericOnly: true,
    blocks: [4],
  },
  currency: {
    prefix: '$',
    numeral: true,
    noImmediatePrefix: true,
  },
  bsb: {
    numericOnly: true,
    delimiter: '-',
    blocks: [3, 3],
  },
  accountNumber: {
    numericOnly: true,
    blocks: [4, 4, 4, 4, 4, 4],
  },
  otp: {
    numericOnly: true,
    blocks: [6],
  },
};

export interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as'> {
  type: 'text' | 'email' | 'password' | 'number' | 'tel';
  name: string;
  placeholder: string;
  disabled?: boolean;
  errorMessage?: string;
  format?: keyof FormatOptions;
  autofill?: 'off';
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  dateFormatOption?: FormatOptions['date'];
  suffix?: React.ReactNode;
  tooltip?: string;
}

export const Input: React.FC<React.PropsWithChildren<InputProps>> = ({
  onFocus,
  name,
  disabled,
  readOnly,
  format,
  placeholder,
  errorMessage,
  dateFormatOption,
  suffix,
  tooltip,
  ...props
}) => {
  const track = useTrack();
  // default format to allow text input with emtpy space
  // @see: https://github.com/nosir/cleave.js/issues/368
  let cleaveFormat: CleaveOptions = {
    delimiter: '',
  };

  if (format) {
    cleaveFormat = formatOptions[format];

    if (format === 'date' && dateFormatOption) {
      cleaveFormat = {
        ...cleaveFormat,
        ...dateFormatOption,
      };
    }
  }

  const id = props.id ?? `input_${name}`;

  return (
    <StyledInputContainerBox
      hasError={!!errorMessage && !disabled}
      hasSuffix={disabled || readOnly || !!suffix}
    >
      <StyledCleaveComponent
        {...props}
        id={id}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        onFocus={(e: React.FocusEvent<HTMLInputElement>): void => {
          track?.(TrackingEvent.FOCUS_INPUT, { properties: { name } });
          if (onFocus) {
            onFocus(e);
          }
        }}
        onInit={(owner) => {
          // Fix for a copy paste issue
          // https://github.com/nosir/cleave.js/issues/601
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (owner as any).lastInputValue = '';
        }}
        options={cleaveFormat}
      />

      <StyledLabel htmlFor={id}>
        {(!disabled && errorMessage) || placeholder}
      </StyledLabel>

      {(disabled || readOnly || !!suffix || !!tooltip) && (
        <StyledSuffixContainerBox>
          {tooltip ? (
            <Tooltip content={tooltip}>
              <span>
                <FeatherInfoIcon color="neutral.080" size="md" />
              </span>
            </Tooltip>
          ) : disabled || readOnly ? (
            <FeatherLockIcon size="sm" color="neutral.080" />
          ) : (
            suffix
          )}
        </StyledSuffixContainerBox>
      )}
    </StyledInputContainerBox>
  );
};

const StyledSuffixContainerBox = styled(Box).attrs({
  position: 'absolute',
  right: getSpace('sm'),
  top: '50%',
})`
  transform: translateY(-50%);
`;

export const focusedLabelStyle = css`
  ${fontSize('12px')}
  top: 14px;
  transform: translateY(0%);
`;

export const StyledLabel = styled.label`
  ${color('neutral.080')}
  ${text({ variant: 2 })}
  ${fontWeight(600)}
  ${paddingLeft('sm')}
  line-height: 1em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${transition}
`;

export const inputShadowStyle = ({
  color,
  isThick,
}: {
  color: Color;
  isThick?: boolean;
}): FlattenInterpolationWithTheme => css`
  box-shadow: inset 0px 0px 0px ${isThick ? 2 : 1}px ${getColor(color)};
`;

export const inputStyles = (
  isReadOnly?: boolean,
): FlattenInterpolationWithTheme => {
  const disabledSelector = `:disabled ${isReadOnly ? ', :read-only' : ''}`;

  return css`
    ${backgroundColor('neutral.000')}
    ${borderRadius('xxs')}
    ${color('neutral.100')}
    ${text({ variant: 2 })}
    ${fontWeight(600)}
    ${paddingX('sm')}
    ${inputShadowStyle({ color: 'neutral.050' })}
    border: none;
    box-sizing: border-box;
    height: ${getSpace('xxl')};
    outline: 0;
    ${transition}

    :hover, :focus {
      ${inputShadowStyle({ color: 'indigo.020', isThick: true })}
    }

    :not(:placeholder-shown) {
      ${paddingTop('md')}
      ${inputShadowStyle({ color: 'indigo.070' })}

      :not(${disabledSelector}):hover, :not(${disabledSelector}):focus {
        ${inputShadowStyle({ color: 'indigo.070', isThick: true })}
      }
    }

    ${disabledSelector} {
      ${backgroundColor('neutral.030')}
      box-shadow: none;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      appearance: none;
      display: none;
      margin: 0;
    }

    ::placeholder {
      color: transparent;
    }
  `;
};

const StyledCleaveComponent = styled(Cleave)<{ readOnly?: boolean }>`
  ${({ readOnly }) => inputStyles(readOnly)}
`;

const StyledInputContainerBox = styled(Box).attrs({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
})<{
  hasError: boolean;
  hasSuffix: boolean;
}>`
  ${({ hasSuffix }) =>
    hasSuffix
      ? css`
          ${StyledLabel}, ${StyledCleaveComponent} {
            ${paddingRight('xl')}
          }
        `
      : ''}

  // IMPORTANT: Separate targeting definitions is required for Firefox
  ${StyledCleaveComponent}:not(:placeholder-shown) + ${StyledLabel} {
    ${focusedLabelStyle}
  }

  ${StyledCleaveComponent}:-webkit-autofill + ${StyledLabel} {
    ${focusedLabelStyle}
  }

  ${({ hasError }) => {
    const ua = window.navigator.userAgent;
    const isIE =
      ua.indexOf('Edge') > 0 ||
      ua.indexOf('MSIE ') > 0 ||
      !!ua.match(/Trident.*rv:11\./);

    return hasError || isIE
      ? css`
          ${StyledLabel} {
            ${focusedLabelStyle}
          }

          ${StyledCleaveComponent} {
            ${paddingTop('md')}
          }
        `
      : '';
  }}

  ${({ hasError }) =>
    hasError
      ? css`
          ${StyledLabel} {
            ${color('red.100')}
          }

          ${StyledCleaveComponent} {
            &,
            :not(:disabled, :read-only):hover,
            :not(:disabled, :read-only):focus {
              ${inputShadowStyle({ color: 'red.100', isThick: true })}
            }
          }
        `
      : ''}
`;
