import { Box, Stack } from '@spaceship-fspl/components';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  PaddingProps,
} from '@spaceship-fspl/styles';
import React, { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';

import { InlineError } from './inline-error';

type Variant = 'circle' | 'square';

export interface CheckboxProps extends PaddingProps {
  children?: React.ReactNode;
  name: string;
  errorMessage?: string;
  hideInlineError?: boolean;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  variant?: Variant;
  position?: 'left' | 'right';
  'aria-label'?: string;
}

const Checkbox: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CheckboxProps
> = (
  {
    ['aria-label']: ariaLabel,
    children,
    disabled,
    errorMessage,
    hideInlineError,
    variant = 'square',
    position = 'left',
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    ...props
  },
  ref,
) => {
  const paddingProps = {
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
  };

  return (
    <StyledLabelBox aria-label={ariaLabel} {...paddingProps}>
      <StyledInput type="checkbox" {...{ ...props, disabled, ref }} />

      <StyledCheckboxContainer
        flexDirection={position === 'left' ? 'row' : 'row-reverse'}
      >
        <StyledCheckbox
          variant={variant}
          hasError={!!errorMessage}
          marginLeft={position === 'right' ? 'sm' : 'none'}
          marginRight={position === 'left' ? 'sm' : 'none'}
        >
          <TickIcon />
        </StyledCheckbox>

        {(children || (errorMessage && !hideInlineError)) && (
          <Box display="flex" alignItems="center" flex={1}>
            <Stack spaceY="xs">
              <div>{children}</div>
              {errorMessage && !hideInlineError && (
                <InlineError message={errorMessage} />
              )}
            </Stack>
          </Box>
        )}
      </StyledCheckboxContainer>
    </StyledLabelBox>
  );
};

const TickIcon: React.FC<React.PropsWithChildren> = () => (
  <StyledTickSvg
    viewBox="0 0 14 13"
    stroke="currentColor"
    fill="none"
    height="13"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.20067 6.86189L5.83948 10.1764L12.0582 1.74798"
      strokeWidth="3.3"
    />
  </StyledTickSvg>
);

const tickKeyframes = keyframes`
  0% {
    opacity: 0;
    stroke-dasharray: 20;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    stroke-dasharray: 40;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    stroke-dasharray: 40;
    transform: scale(1);
  }
`;

const StyledTickSvg = styled.svg`
  opacity: 0;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  transform: scale(1);
  transform-origin: center;
`;

const CHECKBOX_SIZE = 24;

const StyledLabelBox = styled(Box).attrs({
  as: 'label',
  display: 'flex',
  position: 'relative',
})`
  cursor: pointer;
  text-align: left;
  user-select: none;
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const checkboxKeyframes = keyframes`
  0% {
    opacity: 0;
    border-width: 2px;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    border-width: 3px;
  }
`;

const StyledCheckbox = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  paddingTop: 'xxxs',
})<{
  variant: Variant;
  hasError: boolean;
}>`
  ${({ hasError }) => borderColor(hasError ? 'red.100' : 'indigo.070')}
  ${borderWidth('md')}
  ${color('neutral.000')}
  border-style: solid;
  box-sizing: border-box;
  transition: 150ms linear;

  &,
  ::before {
    ${({ variant }) => borderRadius(variant === 'square' ? 'xxxs' : 'xs')}
    line-height: 0;
    height: ${CHECKBOX_SIZE}px;
    width: ${CHECKBOX_SIZE}px;
  }

  ::before {
    ${backgroundColor('indigo.070')}
    ${borderColor('indigo.070')}
    border-style: solid;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledCheckboxContainer = styled(Box).attrs({
  display: 'flex',
  width: '100%',
})`
  ${StyledInput}:checked + & ${StyledCheckbox} {
    ${backgroundColor('indigo.070')}
  }

  ${StyledInput}:checked + & ${StyledCheckbox}::before {
    animation: ${checkboxKeyframes} 250ms linear forwards;
  }

  ${StyledInput}:checked + & ${StyledTickSvg} {
    animation: ${tickKeyframes} 250ms linear forwards;
    opacity: 1;
  }

  ${StyledInput}:disabled + & ${StyledCheckbox} {
    ${backgroundColor('neutral.030')}
    ${borderColor('neutral.050')}
    ${color('neutral.080')}
  }
`;

const ForwardedCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(Checkbox);

export { ForwardedCheckbox as Checkbox };
