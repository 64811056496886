import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  fontFamily,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  spaces,
  visuallyHidden,
  width,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import styled, { css } from 'styled-components';

import { TextInputVariant } from './index';

const ERROR_COLOR = 'red.100';

interface StyledLabelProps {
  isFocusedAndNotEmpty: boolean;
  isInvalid: boolean;
  before: boolean;
  variant: TextInputVariant;
  isDisabled: boolean;
  isHidden?: boolean;
}

interface StyledInputProps extends Omit<StyledLabelProps, 'isHidden'> {
  after: boolean;
}

const getInputVariant = createVariant({
  light: css`
    ${({ isFocusedAndNotEmpty }: StyledInputProps) =>
      isFocusedAndNotEmpty
        ? backgroundColor('neutral.000')
        : backgroundColor('neutral.050')}
    ${({ isInvalid }: StyledInputProps) =>
      isInvalid
        ? borderColor(ERROR_COLOR)
        : css`
            ${borderColor('neutral.050')}

            :hover {
              ${borderColor('indigo.020')}
            }

            :focus-within {
              ${borderColor('indigo.070')}
            }
          `}

    ${borderWidth('md')}

    :disabled {
      ${backgroundColor('neutral.050')}
      ${borderWidth('none')}
      ${color('neutral.080')}
    }
  `,
  dark: css`
    ${({ isInvalid }: StyledInputProps) =>
      isInvalid
        ? borderColor(ERROR_COLOR)
        : css`
            ${borderColor('neutral.085')}

            :hover {
              ${borderColor('indigo.020')}
            }

            :focus-within {
              ${borderColor('indigo.070')}
            }

            :disabled {
              ${borderColor('neutral.085')}
            }
          `}

    ${backgroundColor('neutral.100')}
    ${borderWidth('md')}
    ${color('neutral.000')}
  `,
});

export const InnerWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
  box-sizing: border-box;
  height: 64px;
  ${({ before }) => (before ? paddingLeft('xxl') : paddingLeft('sm'))}
  ${({ after }) => (after ? paddingRight('xxl') : paddingRight('sm'))}
  ${paddingTop('lg')}
  ${paddingBottom('sm')}
  font-size: 16px;
  ${width({ xs: '100%', md: '368px' })}
  max-width: 100%;
  outline: none;
  ${borderRadius('xxs')}
  border-style: solid;
  transition: border-color 0.2s ease-in-out;

  ${createProps({ variant: getInputVariant })}

  &::placeholder {
    ${color('neutral.070')}
    opacity: ${({ isFocusedAndNotEmpty }) => (isFocusedAndNotEmpty ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  :disabled {
    cursor: not-allowed;
  }
`;

const labelColorVariant = createVariant({
  light: css`
    ${({ isInvalid }: StyledLabelProps) =>
      color(isInvalid ? ERROR_COLOR : 'neutral.080')}
  `,
  dark: css`
    ${({ isInvalid }: StyledLabelProps) =>
      color(isInvalid ? ERROR_COLOR : 'neutral.070')}
  `,
});

export const Label = styled.label<StyledLabelProps>`
  ${fontFamily('text')}
  font-size: ${({ isDisabled, before }) =>
    isDisabled || before ? '12px' : '16px'};
  ${createProps({ variant: labelColorVariant })}

  position: absolute;
  top: ${({ isDisabled, before }) =>
    isDisabled || before ? spaces.xs : spaces.md};
  left: ${({ before }) => (before ? spaces.xxl : spaces.sm)};

  touch-action: manipulation;
  transform-origin: left top;
  transform: ${({ isFocusedAndNotEmpty, isDisabled, before }) =>
    isFocusedAndNotEmpty && !isDisabled && !before
      ? 'translate3d(0, -10px, 0) scale(0.75)'
      : 'none'};
  transition: all 0.2s ease-in-out;
  cursor: text;
  pointer-events: none;
  ${({ isHidden }) => isHidden && visuallyHidden()}
`;

export const LeftIconWrapper = styled.div`
  width: 64px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  ${color('neutral.080')}
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const RightIconWrapper = styled.div`
  position: absolute;
  width: 64px;
  height: 100%;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  ${color('neutral.080')}
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;
