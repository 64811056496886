import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  ActionLink,
  Box,
  Card,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { BasiqConnectionStatus } from '@spaceship-fspl/graphql';
import { WebAppBoostsBasiqEdit } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsBasiqEdit';
import { formatDate } from '@spaceship-fspl/helpers';
import { FeatherChevronRightIcon } from '@spaceship-fspl/icons-web';
import {
  borderWidths,
  rgba,
  shadows,
  transition,
} from '@spaceship-fspl/styles';
import { InlineError } from 'components/inline-error';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

import { AboutLinkingTrackingBank } from './components/about-linking-tracking-bank';
import { ContentLayout } from './components/layout';

export const BoostsBasiqEdit: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();

  const resp = useQuery<WebAppBoostsBasiqEdit>(
    gql`
      query WebAppBoostsBasiqEdit {
        contact {
          id
          account {
            id
            basiqConnections {
              id
              institution {
                id
                name
              }
              connectedAt
              status
            }
          }
        }
      }
    `,
    {
      onError: () => {
        notifications.popToast({
          level: 'error',
          message: 'Oops, there was a problem loading tracking banks.',
        });
      },
    },
  );

  const basiqConnections = resp?.data?.contact?.account?.basiqConnections;

  return (
    <PageContainer>
      <Stack spaceY={{ xs: 'xl', lg: 'xxl' }}>
        <ContentLayout>
          <Stack spaceY="md">
            <PageHeading
              title="Tracking banks"
              subtitle={
                resp.loading
                  ? ''
                  : 'These are your available tracking banks. We track all the transactions in your tracking bank accounts and feed that information into your boost to automatically boost your investment balance.'
              }
            />

            {resp.loading ? (
              <Box
                height={400}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="lg" />
              </Box>
            ) : (
              <Stack spaceY="sm">
                {basiqConnections &&
                  basiqConnections.map((connection) => {
                    const isConnected =
                      connection.status === BasiqConnectionStatus.CONNECTED;
                    const isDisconnected =
                      connection.status === BasiqConnectionStatus.DISCONNECTED;
                    const isConnecting =
                      connection.status === BasiqConnectionStatus.CONNECTING;

                    if (isDisconnected) {
                      return (
                        <StyledCardLink
                          key={connection.id}
                          to={`${Routes.BOOSTS_BASIQ_RELINK}/${connection.id}`}
                          trackingProperties={{
                            name: 'boosts_basiq_edit_page_relink',
                          }}
                        >
                          <StyledCard
                            borderRadius="sm"
                            paddingX="lg"
                            paddingY="md"
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Stack spaceY="xxxs">
                                <Heading variant={5} isBold={true}>
                                  {connection?.institution?.name}
                                </Heading>

                                <InlineError
                                  message="We ran into a problem connecting to your tracking bank. You’ll need to re-link your tracking bank to continue using it."
                                  isBold={true}
                                />
                              </Stack>

                              <Box marginLeft="sm">
                                <FeatherChevronRightIcon color="indigo.070" />
                              </Box>
                            </Box>
                          </StyledCard>
                        </StyledCardLink>
                      );
                    }

                    if (isConnected) {
                      return (
                        <Card
                          key={connection.id}
                          borderRadius="sm"
                          boxShadow="sm"
                          paddingX="lg"
                          paddingY="md"
                        >
                          <Box display="flex" justifyContent="space-between">
                            <Stack spaceY="xxxs">
                              <Heading variant={5} isBold={true}>
                                {connection?.institution?.name}
                              </Heading>

                              <Text variant={4} color="neutral.085">
                                Linked{' '}
                                {formatDate(
                                  connection?.connectedAt,
                                  'dd MMM yyyy',
                                )}
                              </Text>
                            </Stack>

                            <Box marginLeft="sm">
                              <RouterLink
                                to={`${Routes.BOOSTS_BASIQ_UNLINK}/${connection.id}`}
                                trackingProperties={{
                                  name: 'boosts_basiq_edit_page_unlink',
                                }}
                              >
                                <ActionLink
                                  size="sm"
                                  color="indigo.070"
                                  component="span"
                                >
                                  Unlink
                                </ActionLink>
                              </RouterLink>
                            </Box>
                          </Box>
                        </Card>
                      );
                    }

                    return (
                      <Card
                        key={connection.id}
                        borderRadius="sm"
                        boxShadow="sm"
                        paddingX="lg"
                        paddingY="md"
                      >
                        <Stack spaceY="xxxs">
                          <Heading variant={5} isBold={true}>
                            {connection?.institution?.name}
                          </Heading>

                          <Text variant={4} color="neutral.085">
                            {isConnecting && 'Connecting'}
                          </Text>
                        </Stack>
                      </Card>
                    );
                  })}
              </Stack>
            )}
          </Stack>

          {!resp.loading && (
            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{
                  name: 'boosts_basiq_edit_page_link_new_tracking_bank',
                }}
                onClick={() => {
                  navigate(Routes.BOOSTS_BASIQ_INSTITUTIONS, {
                    state: { type: 'add-source' },
                  });
                }}
              >
                Link new tracking bank
              </PageFormContinueButton>
            </PageFormButtonContainer>
          )}
        </ContentLayout>

        {!resp.loading && <AboutLinkingTrackingBank />}
      </Stack>
    </PageContainer>
  );
};

const StyledCard = styled(Card)``;

const StyledCardLink = styled(RouterLink)`
  ${StyledCard} {
    box-shadow:
      inset 0 0 0 ${borderWidths.md} ${rgba('indigo.070', 0)},
      ${shadows.sm};
    ${transition}

    :hover, :focus {
      box-shadow:
        inset 0 0 0 ${borderWidths.md} ${rgba('indigo.070', 1)},
        ${shadows.sm};
    }
  }
`;
