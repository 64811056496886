import { Box, UnstyledLink } from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import { borderColor, borderWidth } from '@spaceship-fspl/styles';
import appStoreGPlaySvg from 'assets/appstore-gplay.svg';
import appStoreIosSvg from 'assets/appstore-ios.svg';
import React from 'react';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

export interface AppStoreLinkProps {
  store: 'gplay' | 'ios';
  size: 'lg' | 'xl' | 'xxl';
  variant: 'dark' | 'transparent';
  url?: string;
}

export const AppStoreLink: React.FC<
  React.PropsWithChildren<AppStoreLinkProps>
> = ({ store, size, variant, url }) => {
  const isGPlay = store === 'gplay';
  const storeUrl =
    url ??
    (isGPlay
      ? ExternalRoutes.FALLBACK_APP_STORE_GOOGLE_PLAY
      : ExternalRoutes.FALLBACK_APP_STORE_IOS);

  return (
    <UnstyledLink
      href={storeUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`${store} store link`}
    >
      <SvgWrapper
        backgroundColor={variant === 'dark' ? 'black.100' : undefined}
        height={{ xs: 'xl', md: size }}
        isTransparent={variant === 'transparent'}
      >
        <StyledSvg src={isGPlay ? appStoreGPlaySvg : appStoreIosSvg} />
      </SvgWrapper>
    </UnstyledLink>
  );
};

const StyledSvg = styled(SVG)`
  height: 100%;
  width: auto;
`;

const SvgWrapper = styled(Box).attrs({
  as: 'span',
  display: 'inline-block',
  borderRadius: 'xxs',
})<{ isTransparent: boolean }>`
  ${(props) =>
    props.isTransparent &&
    css`
      ${borderWidth('sm')};
      ${borderColor('neutral.080')};
      border-style: solid;
    `};
`;
