import * as React from 'react';
export const StreamlineTouchId2 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M23 15.667v5.866c0 .81-.657 1.467-1.467 1.467h-5.866M15.667 1h5.866C22.343 1 23 1.657 23 2.467v5.866M1 8.333V2.467C1 1.657 1.657 1 2.467 1h5.866M8.333 23H2.467C1.657 23 1 22.343 1 21.533v-5.866M17.867 13.833v-1.466c0-3.63-2.64-6.6-5.867-6.6s-5.867 2.97-5.867 6.6v5.866M17.867 18.233v-1.466M9.067 16.767v1.466" />
    <path vectorEffect="non-scaling-stroke" d="M14.933 18.233V13.1c0-2.017-1.32-3.667-2.933-3.667-1.613 0-2.933 1.65-2.933 3.667v.733M12 17.5v.733M12 12.367v2.2" />
  </svg>
);
