import {
  AnalyticsLineGraphIcon,
  AstronomyPlanetIcon,
  BoostsIcon,
  BriefcaseIcon,
  DollarCircleIcon,
  FacebookIcon,
  FeatherActivityIcon,
  FeatherAlertOctagonIcon,
  FeatherAlertTriangleIcon,
  FeatherArrowDownIcon,
  FeatherArrowLeftIcon,
  FeatherArrowRightIcon,
  FeatherArrowUpIcon,
  FeatherArrowUpRightIcon,
  FeatherCalendarIcon,
  FeatherCameraIcon,
  FeatherCheckCircleIcon,
  FeatherCheckIcon,
  FeatherChevronDownIcon,
  FeatherChevronLeftIcon,
  FeatherChevronRightIcon,
  FeatherChevronUpIcon,
  FeatherCircleIcon,
  FeatherClipboardIcon,
  FeatherClockIcon,
  FeatherCopyIcon,
  FeatherDollarSignIcon,
  FeatherDownloadIcon,
  FeatherEdit2Icon,
  FeatherEyeIcon,
  FeatherEyeOffIcon,
  FeatherFileTextIcon,
  FeatherFilterIcon,
  FeatherGiftIcon,
  FeatherHelpCircleIcon,
  FeatherHomeIcon,
  FeatherInfoIcon,
  FeatherLayersIcon,
  FeatherListIcon,
  FeatherLockIcon,
  FeatherMailIcon,
  FeatherMenuIcon,
  FeatherMessageSquareIcon,
  FeatherMoreVerticalIcon,
  FeatherPlusIcon,
  FeatherRefreshCwIcon,
  FeatherSaveIcon,
  FeatherSearchIcon,
  FeatherSettingsIcon,
  FeatherShareIcon,
  FeatherSlashIcon,
  FeatherTrash2Icon,
  FeatherUploadIcon,
  FeatherUserIcon,
  FeatherXIcon,
  FeatherZapIcon,
  FeatherZapOffIcon,
  FundFromSpaceshipIcon,
  FundToSpaceshipIcon,
  HelpWheelIcon,
  IconProps,
  IndicatorTriangleIcon,
  InstagramIcon,
  LinkedinIcon,
  RssIcon,
  SpaceshipIcon,
  StreamlineAccountingCoinsIcon,
  StreamlineAccountingCoinsStackIcon,
  StreamlineAlertTriangleIcon,
  StreamlineAmericanFootballBall1Icon,
  StreamlineAstronomyTelescopeStarsIcon,
  StreamlineBuildingModern2Icon,
  StreamlineBusinessContractApproveIcon,
  StreamlineButtonLoopArrowIcon,
  StreamlineCashBriefcaseIcon,
  StreamlineCashBulbIcon,
  StreamlineCashPaymentBillsIcon,
  StreamlineCashPaymentWalletIcon,
  StreamlineCheck1Icon,
  StreamlineCheckCircle1Icon,
  StreamlineCoffeeToGoIcon,
  StreamlineColorPaletteIcon,
  StreamlineCommonFileRotateIcon,
  StreamlineContentPaperEditIcon,
  StreamlineCursorChooseIcon,
  StreamlineDiagramArrowDashRightIcon,
  StreamlineDiscountCircleIcon,
  StreamlineDownloadDashArrowIcon,
  StreamlineEarthCashIcon,
  StreamlineFaceId4Icon,
  StreamlineFastFoodBurgerDrinkIcon,
  StreamlineFlagPlain3Icon,
  StreamlineGenderHeteroIcon,
  StreamlineGraphStatsAscendIcon,
  StreamlineKoalaIcon,
  StreamlineLegalHammerIcon,
  StreamlineLegalScale1Icon,
  StreamlineLegalScaleUnequal1Icon,
  StreamlineLightBulbIcon,
  StreamlineLightModeSunnyIcon,
  StreamlineLogout1Icon,
  StreamlineMakeUpEyeIcon,
  StreamlineMonitorHeartBeatIcon,
  StreamlineMultipleNeutral2Icon,
  StreamlineMultipleUsers1Icon,
  StreamlineNaturalDisasterFireIcon,
  StreamlineNetworkUserIcon,
  StreamlineOfficialBuilding2Icon,
  StreamlinePastaBowlWarmIcon,
  StreamlineReligionPeace1Icon,
  StreamlineSatellite1Icon,
  StreamlineSavingBank1Icon,
  StreamlineSearch1Icon,
  StreamlineShieldWarningIcon,
  StreamlineSingleNeutralIcon,
  StreamlineSingleNeutralShieldIcon,
  StreamlineSmileySmile2Icon,
  StreamlineSpaceAstronautAlternateIcon,
  StreamlineSpaceRocketFlyingIcon,
  StreamlineStudyDeskIcon,
  StreamlineTargetCenter1Icon,
  StreamlineTaskListMultipleIcon,
  StreamlineTaxiWaveBusinessManIcon,
  StreamlineTimeClockCircleIcon,
  StreamlineTouchId2Icon,
  StreamlineToysLegoIcon,
  StreamlineTreeIcon,
  StreamlineWalkingIcon,
  StreamlineWaterProtectionFishIcon,
  StreamlineWaterStrawIcon,
  StreamlineWeatherCloudRainIcon,
  SuperGlobalIndexIcon,
  SuperGlobalIndexStarsIcon,
  SuperGrowthxIcon,
  SuperGrowthxStarsIcon,
  SuperProductIcon,
  SuperProductStarsIcon,
  TaxTimeIcon,
  TipLightbulbIcon,
  TwitterIcon,
  VoyagerEarthIcon,
  VoyagerEarthStarsIcon,
  VoyagerOriginIcon,
  VoyagerOriginStarsIcon,
  VoyagerProductIcon,
  VoyagerProductStarsIcon,
  VoyagerUniverseIcon,
  VoyagerUniverseStarsIcon,
} from '@spaceship-fspl/icons-web';
import React from 'react';

interface DynamicIconProps extends IconProps {
  name?: string;
}

export const DynamicIcon: React.FC<
  React.PropsWithChildren<DynamicIconProps>
> = (props) => {
  switch (props.name) {
    case 'feather-activity':
      return <FeatherActivityIcon {...props} />;
    case 'feather-alert-octagon':
      return <FeatherAlertOctagonIcon {...props} />;
    case 'feather-alert-triangle':
      return <FeatherAlertTriangleIcon {...props} />;
    case 'feather-arrow-down':
      return <FeatherArrowDownIcon {...props} />;
    case 'feather-arrow-left':
      return <FeatherArrowLeftIcon {...props} />;
    case 'feather-arrow-right':
      return <FeatherArrowRightIcon {...props} />;
    case 'feather-arrow-up':
      return <FeatherArrowUpIcon {...props} />;
    case 'feather-arrow-up-right':
      return <FeatherArrowUpRightIcon {...props} />;
    case 'feather-calendar':
      return <FeatherCalendarIcon {...props} />;
    case 'feather-camera':
      return <FeatherCameraIcon {...props} />;
    case 'feather-check':
      return <FeatherCheckIcon {...props} />;
    case 'feather-check-circle':
      return <FeatherCheckCircleIcon {...props} />;
    case 'feather-chevron-down':
      return <FeatherChevronDownIcon {...props} />;
    case 'feather-chevron-left':
      return <FeatherChevronLeftIcon {...props} />;
    case 'feather-chevron-right':
      return <FeatherChevronRightIcon {...props} />;
    case 'feather-chevron-up':
      return <FeatherChevronUpIcon {...props} />;
    case 'feather-circle':
      return <FeatherCircleIcon {...props} />;
    case 'feather-clipboard':
      return <FeatherClipboardIcon {...props} />;
    case 'feather-clock':
      return <FeatherClockIcon {...props} />;
    case 'feather-copy':
      return <FeatherCopyIcon {...props} />;
    case 'feather-dollar-sign':
      return <FeatherDollarSignIcon {...props} />;
    case 'feather-download':
      return <FeatherDownloadIcon {...props} />;
    case 'feather-edit-2':
      return <FeatherEdit2Icon {...props} />;
    case 'feather-eye':
      return <FeatherEyeIcon {...props} />;
    case 'feather-eye-off':
      return <FeatherEyeOffIcon {...props} />;
    case 'feather-file-text':
      return <FeatherFileTextIcon {...props} />;
    case 'feather-filter':
      return <FeatherFilterIcon {...props} />;
    case 'feather-gift':
      return <FeatherGiftIcon {...props} />;
    case 'feather-help-circle':
      return <FeatherHelpCircleIcon {...props} />;
    case 'feather-home':
      return <FeatherHomeIcon {...props} />;
    case 'feather-info':
      return <FeatherInfoIcon {...props} />;
    case 'feather-layers':
      return <FeatherLayersIcon {...props} />;
    case 'feather-list':
      return <FeatherListIcon {...props} />;
    case 'feather-lock':
      return <FeatherLockIcon {...props} />;
    case 'feather-mail':
      return <FeatherMailIcon {...props} />;
    case 'feather-menu':
      return <FeatherMenuIcon {...props} />;
    case 'feather-message-square':
      return <FeatherMessageSquareIcon {...props} />;
    case 'feather-more-vertical':
      return <FeatherMoreVerticalIcon {...props} />;
    case 'feather-plus':
      return <FeatherPlusIcon {...props} />;
    case 'feather-refresh-cw':
      return <FeatherRefreshCwIcon {...props} />;
    case 'feather-save':
      return <FeatherSaveIcon {...props} />;
    case 'feather-search':
      return <FeatherSearchIcon {...props} />;
    case 'feather-settings':
      return <FeatherSettingsIcon {...props} />;
    case 'feather-share':
      return <FeatherShareIcon {...props} />;
    case 'feather-slash':
      return <FeatherSlashIcon {...props} />;
    case 'feather-trash-2':
      return <FeatherTrash2Icon {...props} />;
    case 'feather-upload':
      return <FeatherUploadIcon {...props} />;
    case 'feather-user':
      return <FeatherUserIcon {...props} />;
    case 'feather-x':
      return <FeatherXIcon {...props} />;
    case 'feather-zap':
      return <FeatherZapIcon {...props} />;
    case 'feather-zap-off':
      return <FeatherZapOffIcon {...props} />;

    case 'streamline-diagram-arrow-dash-right':
      return <StreamlineDiagramArrowDashRightIcon {...props} />;
    case 'streamline-cash-briefcase':
      return <StreamlineCashBriefcaseIcon {...props} />;
    case 'streamline-check-circle-1':
      return <StreamlineCheckCircle1Icon {...props} />;
    case 'streamline-time-clock-circle':
      return <StreamlineTimeClockCircleIcon {...props} />;
    case 'streamline-content-paper-edit':
      return <StreamlineContentPaperEditIcon {...props} />;
    case 'streamline-discount-circle':
      return <StreamlineDiscountCircleIcon {...props} />;
    case 'streamline-earth-cash':
      return <StreamlineEarthCashIcon {...props} />;
    case 'streamline-face-id-4':
      return <StreamlineFaceId4Icon {...props} />;
    case 'streamline-accounting-coins-stack':
      return <StreamlineAccountingCoinsStackIcon {...props} />;
    case 'streamline-business-contract-approve':
      return <StreamlineBusinessContractApproveIcon {...props} />;
    case 'streamline-button-loop-arrow':
      return <StreamlineButtonLoopArrowIcon {...props} />;
    case 'streamline-walking':
      return <StreamlineWalkingIcon {...props} />;
    case 'streamline-legal-scale-unequal-1':
      return <StreamlineLegalScaleUnequal1Icon {...props} />;
    case 'streamline-search-1':
      return <StreamlineSearch1Icon {...props} />;
    case 'streamline-single-neutral-shield':
      return <StreamlineSingleNeutralShieldIcon {...props} />;
    case 'streamline-shield-warning':
      return <StreamlineShieldWarningIcon {...props} />;
    case 'streamline-space-rocket-flying':
      return <StreamlineSpaceRocketFlyingIcon {...props} />;
    case 'streamline-check-1':
      return <StreamlineCheck1Icon {...props} />;
    case 'streamline-touch-id-2':
      return <StreamlineTouchId2Icon {...props} />;
    case 'streamline-cash-payment-wallet':
      return <StreamlineCashPaymentWalletIcon {...props} />;
    case 'streamline-multiple-users-1':
      return <StreamlineMultipleUsers1Icon {...props} />;
    case 'streamline-pasta-bowl-warm':
      return <StreamlinePastaBowlWarmIcon {...props} />;
    case 'streamline-monitor-heart-beat':
      return <StreamlineMonitorHeartBeatIcon {...props} />;
    case 'streamline-study-desk':
      return <StreamlineStudyDeskIcon {...props} />;
    case 'streamline-gender-hetero':
      return <StreamlineGenderHeteroIcon {...props} />;
    case 'streamline-water-straw':
      return <StreamlineWaterStrawIcon {...props} />;
    case 'streamline-light-mode-sunny':
      return <StreamlineLightModeSunnyIcon {...props} />;
    case 'streamline-graph-stats-ascend':
      return <StreamlineGraphStatsAscendIcon {...props} />;
    case 'streamline-toys-lego':
      return <StreamlineToysLegoIcon {...props} />;
    case 'streamline-legal-scale-1':
      return <StreamlineLegalScale1Icon {...props} />;
    case 'streamline-building-modern-2':
      return <StreamlineBuildingModern2Icon {...props} />;
    case 'streamline-make-up-eye':
      return <StreamlineMakeUpEyeIcon {...props} />;
    case 'streamline-water-protection-fish':
      return <StreamlineWaterProtectionFishIcon {...props} />;
    case 'streamline-tree':
      return <StreamlineTreeIcon {...props} />;
    case 'streamline-legal-hammer':
      return <StreamlineLegalHammerIcon {...props} />;
    case 'streamline-color-palette':
      return <StreamlineColorPaletteIcon {...props} />;
    case 'streamline-cash-bulb':
      return <StreamlineCashBulbIcon {...props} />;
    case 'streamline-official-building-2':
      return <StreamlineOfficialBuilding2Icon {...props} />;
    case 'streamline-network-user':
      return <StreamlineNetworkUserIcon {...props} />;
    case 'streamline-saving-bank':
    case 'streamline-saving-bank-1':
      return <StreamlineSavingBank1Icon {...props} />;
    case 'streamline-target-center-1':
      return <StreamlineTargetCenter1Icon {...props} />;
    case 'streamline-smiley-smile-2':
      return <StreamlineSmileySmile2Icon {...props} />;
    case 'streamline-single-neutral':
      return <StreamlineSingleNeutralIcon {...props} />;
    case 'streamline-koala':
      return <StreamlineKoalaIcon {...props} />;
    case 'streamline-fast-food-burger-drink':
      return <StreamlineFastFoodBurgerDrinkIcon {...props} />;
    case 'streamline-american-football-ball-1':
      return <StreamlineAmericanFootballBall1Icon {...props} />;
    case 'streamline-light-bulb':
      return <StreamlineLightBulbIcon {...props} />;
    case 'streamline-religion-peace-1':
      return <StreamlineReligionPeace1Icon {...props} />;
    case 'streamline-flag-plain-3':
      return <StreamlineFlagPlain3Icon {...props} />;
    case 'streamline-accounting-coins':
      return <StreamlineAccountingCoinsIcon {...props} />;
    case 'streamline-multiple-neutral-2':
      return <StreamlineMultipleNeutral2Icon {...props} />;
    case 'streamline-cash-payment-bills':
      return <StreamlineCashPaymentBillsIcon {...props} />;
    case 'streamline-coffee-to-go':
      return <StreamlineCoffeeToGoIcon {...props} />;
    case 'streamline-taxi-wave-businessman':
    case 'streamline-taxi-wave-business-man':
      return <StreamlineTaxiWaveBusinessManIcon {...props} />;
    case 'streamline-weather-cloud-rain':
      return <StreamlineWeatherCloudRainIcon {...props} />;
    case 'streamline-cursor-choose':
      return <StreamlineCursorChooseIcon {...props} />;
    case 'streamline-astronomy-telescope-stars':
      return <StreamlineAstronomyTelescopeStarsIcon {...props} />;
    case 'streamline-common-file-rotate':
      return <StreamlineCommonFileRotateIcon {...props} />;
    case 'streamline-download-dash-arrow':
      return <StreamlineDownloadDashArrowIcon {...props} />;
    case 'streamline-logout-1':
      return <StreamlineLogout1Icon {...props} />;
    case 'streamline-natural-disaster-fire':
      return <StreamlineNaturalDisasterFireIcon {...props} />;
    case 'streamline-satellite-1':
      return <StreamlineSatellite1Icon {...props} />;
    case 'streamline-space-astronaut-alternate':
      return <StreamlineSpaceAstronautAlternateIcon {...props} />;
    case 'streamline-task-list-multiple':
      return <StreamlineTaskListMultipleIcon {...props} />;
    case 'streamline-alert-triangle':
      return <StreamlineAlertTriangleIcon {...props} />;

    case 'custom-boosts':
      return <BoostsIcon {...props} />;
    case 'custom-help-wheel':
      return <HelpWheelIcon {...props} />;
    case 'custom-astronomy-planet':
      return <AstronomyPlanetIcon {...props} />;
    case 'custom-analytics-line-graph':
      return <AnalyticsLineGraphIcon {...props} />;
    case 'custom-fund-to-spaceship':
      return <FundToSpaceshipIcon {...props} />;
    case 'custom-briefcase':
      return <BriefcaseIcon {...props} />;
    case 'custom-dollar-circle':
      return <DollarCircleIcon {...props} />;
    case 'custom-fund-from-spaceship':
      return <FundFromSpaceshipIcon {...props} />;
    case 'custom-tip-lightbulb':
      return <TipLightbulbIcon {...props} />;
    case 'custom-facebook':
      return <FacebookIcon {...props} />;
    case 'custom-indicator-triangle':
      return <IndicatorTriangleIcon {...props} />;
    case 'custom-instagram':
      return <InstagramIcon {...props} />;
    case 'custom-linkedin':
      return <LinkedinIcon {...props} />;
    case 'custom-rss':
      return <RssIcon {...props} />;
    case 'custom-spaceship':
      return <SpaceshipIcon {...props} />;
    case 'custom-super-global-index':
      return <SuperGlobalIndexIcon {...props} />;
    case 'custom-super-global-index-stars':
      return <SuperGlobalIndexStarsIcon {...props} />;
    case 'custom-super-growthx':
      return <SuperGrowthxIcon {...props} />;
    case 'custom-super-growthx-stars':
      return <SuperGrowthxStarsIcon {...props} />;
    case 'custom-super-product':
      return <SuperProductIcon {...props} />;
    case 'custom-super-product-stars':
      return <SuperProductStarsIcon {...props} />;
    case 'custom-tax-time':
      return <TaxTimeIcon {...props} />;
    case 'custom-twitter':
      return <TwitterIcon {...props} />;
    case 'custom-voyager-earth':
      return <VoyagerEarthIcon {...props} />;
    case 'custom-voyager-earth-stars':
      return <VoyagerEarthStarsIcon {...props} />;
    case 'custom-voyager-origin':
      return <VoyagerOriginIcon {...props} />;
    case 'custom-voyager-origin-stars':
      return <VoyagerOriginStarsIcon {...props} />;
    case 'custom-voyager-product':
      return <VoyagerProductIcon {...props} />;
    case 'custom-voyager-product-stars':
      return <VoyagerProductStarsIcon {...props} />;
    case 'custom-voyager-universe':
      return <VoyagerUniverseIcon {...props} />;
    case 'custom-voyager-universe-stars':
      return <VoyagerUniverseStarsIcon {...props} />;

    default:
      return null;
  }
};
