import { Stack } from '@spaceship-fspl/components';
import { SuperBeneficiariesCard } from 'components/account-cards/super-beneficiaries';
import { SuperInvestmentOptionCard } from 'components/account-cards/super-investment-option';
import { SuperMemberDetailsCard } from 'components/account-cards/super-member-details';
import { SuperOtherActionsCard } from 'components/account-cards/super-other-actions';
import { SuperTaxFileNumberCard } from 'components/account-cards/super-tax-file-number';
import React from 'react';

export const SuperDetails: React.FC<React.PropsWithChildren> = () => {
  return (
    <Stack spaceY="lg">
      <SuperMemberDetailsCard />
      <SuperInvestmentOptionCard />
      <SuperTaxFileNumberCard />
      <SuperBeneficiariesCard />
      <SuperOtherActionsCard />
    </Stack>
  );
};
