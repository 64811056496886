import { gql, useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useCanReadSaver, useOtherStuff } from '@spaceship-fspl/auth';
import { useGetUser, useGetUserVerification } from '@spaceship-fspl/data';
import { WebAppIdentifyVoyagerUser } from '@spaceship-fspl/graphql/src/__generated__/WebAppIdentifyVoyagerUser';
import {
  IdentifyFunction,
  useIdentify,
  useReset,
} from '@spaceship-fspl/tracking';
import {
  GreenIdRuleSet,
  IContact,
  IFraudServiceCheck,
} from '@spaceship-fspl/types/externalapi';
import { IntercomEvent } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { config } from 'helpers/config';
import { formatAddress, timestampToDate } from 'helpers/format';
import { useDeviceFingerprint } from 'helpers/hooks/use-device-fingerprint';
import React, {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { v5 as uuidv5 } from 'uuid';

const anonymise = (id: string): string => uuidv5(id, id);

interface IdentityData {
  serviceChecks?: IFraudServiceCheck[] | null;
}

const IdentityContext = createContext<IdentityData>({});

export const IdentityProvider = memo(
  ({ children }: { children: ReactNode }): JSX.Element => {
    const identify = useIdentify();
    const reset = useReset();
    const fingerprint = useDeviceFingerprint();
    const canReadSaver = useCanReadSaver();
    const intercomHashes = useOtherStuff()?.intercom_hashes;
    const { popToast } = useNotifications();
    const { data: user } = useGetUser();
    const { data: userVerification, isError: isUserVerificationError } =
      useGetUserVerification(GreenIdRuleSet.Enum.VOYAGER_ONBOARDING);

    // identify voyager user with product id
    useQuery<WebAppIdentifyVoyagerUser>(
      gql`
        query WebAppIdentifyVoyagerUser {
          contact {
            id
            saverProductInstance {
              id
            }
          }
        }
      `,
      {
        skip: !canReadSaver,
        onCompleted: (data) => {
          const contactId = data.contact.id;
          const productId = data.contact.saverProductInstance?.id;
          if (productId) {
            identify?.(anonymise(contactId), {
              contextTraits: { product_id: productId },
            });
          }
        },
      },
    );

    useEffect(() => {
      return (): void => {
        reset?.();
        if (window.Intercom) {
          window.Intercom(IntercomEvent.SHUTDOWN);
          window.Intercom(IntercomEvent.BOOT, {
            app_id: config.intercomAppId,
          });
        }
      };
    }, [reset]);

    useEffect(() => {
      if (identify && user?.contact?.id && intercomHashes?.web && fingerprint) {
        identifyUser(identify, user?.contact, intercomHashes.web, fingerprint);
      }
    }, [identify, user, intercomHashes, fingerprint]);

    useEffect(() => {
      if (isUserVerificationError) {
        popToast({
          message: 'There was an error fetching your details.',
          level: 'error',
        });
      }
    }, [popToast, isUserVerificationError]);

    return (
      <IdentityContext.Provider
        value={{
          serviceChecks: userVerification?.service_checks,
        }}
      >
        {children}
      </IdentityContext.Provider>
    );
  },
);

IdentityProvider.displayName = 'IdentityProvider';

const identifyUser = (
  identify: IdentifyFunction,
  contact: IContact,
  intercomHash: string,
  fingerprint: string,
): void => {
  if (!contact.id) {
    console.log('Unable to identify user, missing contact id.');
    return;
  }
  const joinDate = timestampToDate(contact.created_at);
  const hashedContactId = anonymise(contact.id);

  datadogRum.setUser({
    id: contact.id,
    contact_id: contact.id,
    fingerprint,
  });

  identify(hashedContactId, {
    properties: {
      firstName: contact.first_name || '',
      lastName: contact.last_name || '',
      dob: contact.date_of_birth || '',
      phone: contact.phone_number || '',
      email: contact.email_address || '',
      address: formatAddress(contact.address),
    },
    contextTraits: {
      contactId: hashedContactId,
      actualContactId: contact.id,
      fingerprint,
    },
  });

  window.Intercom(IntercomEvent.UPDATE, {
    user_hash: intercomHash,
    app_id: config.intercomAppId,
    user_id: contact.id || '',
    email: contact.email_address || '',
    name: `${contact.first_name} ${contact.last_name}`.trim(),
    phone: contact.phone_number || '',
    created_at: joinDate ? Math.round(joinDate.getTime() / 1000) : 0,
    fingerprint,
  });
};

export const useIdentity = (): IdentityData => useContext(IdentityContext);
