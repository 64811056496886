import { useNavigate } from '@reach/router';
import {
  Box,
  Card,
  Heading,
  Inline,
  Spinner,
  Stack,
  Text,
  TextLink,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { useTreatment } from '@spaceship-fspl/experimentation';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { formatCurrency, TimeScale } from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { borderColor, getNumericSpace } from '@spaceship-fspl/styles';
import {
  useBalanceGraphData,
  useGetMemberBalance,
  usePerformance,
  useUserAccess,
} from '@spaceship-fspl/super';
import { Chart } from 'components/chart';
import {
  ChartTabSelector,
  chartTimeScaleTabOptions,
} from 'components/chart-tab-selector';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import Decimal from 'decimal.js';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { ExperimentNames } from 'helpers/experimentation';
import { toLongDate } from 'helpers/format';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { PersistKey } from 'helpers/persist';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

const CHART_HEIGHT = 64 + getNumericSpace('lg');

export const SuperBalanceCard: React.FC<
  React.PropsWithChildren<{
    chartHeight?: number;
    infoRoute?: Routes;
    portfolioFriendlyName?: string;
  }>
> = ({ chartHeight, infoRoute, portfolioFriendlyName }) => {
  const navigate = useNavigate();
  const [timeScale, setTimeScale] = useLocalStorage<TimeScale>(
    PersistKey.SUPER_BALANCE_TIME_SCALE,
    TimeScale.MAX,
  );
  const isReturnsEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPER_RETURNS_ENABLED,
  );

  const { data: graphData } = useBalanceGraphData(timeScale);
  const { data: balanceData } = useGetMemberBalance();
  const investmentReturn = usePerformance();

  const { canShareEmployerContributionDetails, canSuperMatch } =
    useUserAccess();
  const isSuperMatchEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPERMATCH_ENABLED,
  );
  const supermatchDeeplinkTreatment = useTreatment(
    ExperimentNames.SUPERMATCH_DEEPLINK,
  );

  const hasPortfolio = !!portfolioFriendlyName;
  const isEmptyBalance = hasPortfolio && !balanceData?.closingBalance?.amount;
  const investmentBalance = balanceData?.closingBalance?.amount
    ? new Decimal(balanceData.closingBalance.amount).dividedBy(100).toNumber()
    : '-';

  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    !!superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;

  return (
    <Card padding="lg" height="100%">
      <Inline spaceX="xxxs" alignY="center">
        <Text variant={3} isBold={true}>
          Super Balance {portfolioFriendlyName && `(${portfolioFriendlyName})`}
        </Text>

        {infoRoute && !isEmptyBalance && hasPortfolio && (
          <RouterLink
            to={infoRoute}
            trackingProperties={{ name: 'super_balance_card_info' }}
          >
            <Box lineHeight={0}>
              <FeatherInfoIcon size="sm" color="black.100" strokeWidth={1.5} />
            </Box>
          </RouterLink>
        )}
      </Inline>

      {!portfolioFriendlyName ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <Spinner />
        </Box>
      ) : (
        <>
          {isEmptyBalance && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
              minHeight={150}
            >
              {!canSuperMatch && !canShareEmployerContributionDetails ? (
                <StyledEmptyBalanceBox>
                  <Text
                    variant={2}
                    color="neutral.080"
                    isBold={true}
                    align="center"
                  >
                    Your balance graph will display here when we have more data.
                  </Text>
                </StyledEmptyBalanceBox>
              ) : (
                <Text
                  variant={2}
                  align="center"
                  color="neutral.080"
                  isBold={true}
                >
                  Grow your super by
                  <br />
                  {canShareEmployerContributionDetails && (
                    <RouterLink
                      to={Routes.SUPER_CONTRIBUTIONS}
                      trackingProperties={{
                        name: 'super_balance_card_contribute',
                      }}
                    >
                      <TextLink color="indigo.070" component="span">
                        contributing
                      </TextLink>
                    </RouterLink>
                  )}
                  {canShareEmployerContributionDetails && canSuperMatch && (
                    <> or </>
                  )}
                  {canSuperMatch && (
                    <UnstyledButton
                      onClick={async () => {
                        if (isSuperMatchEnabled) {
                          navigate(Routes.SUPER_SUPERMATCH);
                        } else {
                          await supermatchDeeplinkTreatment.activate();

                          if (
                            supermatchDeeplinkTreatment.name === 'variation'
                          ) {
                            navigate(Routes.SUPER_COMING_SOON_CONSOLIDATE);
                          } else {
                            navigate(Routes.SUPER_CONSOLIDATE);
                          }
                        }
                      }}
                    >
                      <TextLink color="indigo.070" component="span">
                        consolidating
                      </TextLink>
                    </UnstyledButton>
                  )}
                </Text>
              )}
            </Box>
          )}

          {!hasPortfolio && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
              minHeight={150}
            >
              <Text
                variant={2}
                align="center"
                color="neutral.080"
                isBold={true}
              >
                Please select an investment option{' '}
                <RouterLink
                  to={Routes.SUPER_SELECT_INVESTMENT_OPTION}
                  trackingProperties={{
                    name: 'super_balance_card_add_portfolio',
                  }}
                >
                  <TextLink color="indigo.070" component="span">
                    here
                  </TextLink>
                </RouterLink>{' '}
                to display your balance.
              </Text>
            </Box>
          )}

          {!isEmptyBalance && hasPortfolio && (
            <>
              <Stack spaceY="sm">
                <Heading variant={2} component="h3">
                  {formatCurrency(investmentBalance)}
                </Heading>
                {isReturnsEnabled && (
                  <LabeledField size="md" label="Return">
                    {formatCurrency(investmentReturn)}
                  </LabeledField>
                )}
              </Stack>

              {graphData && graphData.length ? (
                <Box
                  position="relative"
                  height={chartHeight ?? CHART_HEIGHT}
                  marginBottom="md"
                >
                  <Chart
                    color="indigo.050"
                    endColor="pink.100"
                    tooltipEnabled={true}
                    formatX={(value): string => toLongDate(new Date(value))}
                    formatY={(value): string => formatCurrency(value)}
                    endLabels={true}
                    data={graphData.map(({ performance, ...rest }) => ({
                      ...rest,
                      position: performance,
                    }))}
                    hideTodayLabel={sftLimitedServicePeriodEnabled}
                  />
                </Box>
              ) : (
                <StyledEmptyBalanceBox>
                  <Text
                    variant={2}
                    color="neutral.080"
                    isBold={true}
                    align="center"
                  >
                    Your balance graph will display here when we have more data.
                  </Text>
                </StyledEmptyBalanceBox>
              )}
              <Box paddingTop="sm">
                <ChartTabSelector
                  options={chartTimeScaleTabOptions}
                  selected={timeScale}
                  setSelected={(t: TimeScale): void => setTimeScale(t)}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Card>
  );
};

const StyledEmptyBalanceBox = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 'md',
  marginTop: 'md',
  height: CHART_HEIGHT,
  borderRadius: 'xxs',
})`
  ${borderColor('neutral.070')}
  border-style: dashed;
  border-width: 1.5px;
`;
