import * as React from 'react';
export const StreamlineTargetCenter1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11.035 11.862l4.075-4.074M15.11 7.788l.555-3.667L18.841 1l.49 2.566 2.567.49-3.122 3.176-3.666.556zM20.851 9.568a9.533 9.533 0 11-8.253-7.052M16.615 10.765a5.134 5.134 0 11-4.987-3.898M6.635 20.067L5.902 23M16.902 20.067L17.635 23" />
  </svg>
);
