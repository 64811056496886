import * as React from 'react';
export const StocksProductStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M17.626 11.04l-.025 1.332a11.922 11.922 0 01-3.2 7.906l-1.315 1.41-.001.002a.857.857 0 01-1.48-.492l-.535-5.064.3-.158a23.016 23.016 0 003.97-2.66c.458-.383.809-.709 1.044-.937a11.804 11.804 0 00.328-.333l.015-.016.003-.003.896-.987zM16.5 13.635a24.026 24.026 0 01-4.366 3.065l.43 4.08 1.105-1.184a10.918 10.918 0 002.831-5.962zm-3.662-7.041l-1.333.02a11.921 11.921 0 00-7.918 3.169l-1.415 1.31H2.17a.857.857 0 00.486 1.483l5.062.554.16-.299a23.02 23.02 0 012.675-3.96c.384-.457.711-.806.94-1.04a11.798 11.798 0 01.334-.327l.017-.015.003-.003.99-.892zm-2.6 1.115a24.026 24.026 0 00-3.082 4.354l-4.078-.446 1.188-1.1a10.918 10.918 0 015.973-2.808z" />
    <path d="M21.465 1.518a.858.858 0 011.012 1.033l-.09.387h-.004c-1.313 5.336-4.13 8.849-6.646 11.036a19.971 19.971 0 01-3.387 2.383c-.445.247-.81.424-1.068.54a9.127 9.127 0 01-.382.164l-.022.009-.007.002-.002.001-.177-.468.176.469-.302.113-3.725-3.726.145-.316c4.088-8.875 12.28-11.16 14.477-11.626h.002zM10.808 16.014l.061-.027c.235-.107.577-.272.997-.505a18.94 18.94 0 003.215-2.263c2.415-2.099 5.13-5.487 6.368-10.673-2.339.535-9.601 2.79-13.406 10.703l2.765 2.765z" />
    <path d="M15.569 8.363a1.74 1.74 0 010-2.466 1.74 1.74 0 012.466 0 1.74 1.74 0 010 2.466 1.74 1.74 0 01-2.466 0zm-9.868 5.862a.5.5 0 01.514.14l1.31 1.428L8.68 14.71a.5.5 0 11.685.729l-1.522 1.43a.5.5 0 01-.71-.027l-.947-1.03-.446 2.947a.5.5 0 01-.807.316l-1.69-1.353-.754 3.388a.5.5 0 11-.976-.217l.927-4.17a.5.5 0 01.8-.283l1.64 1.312.474-3.123a.5.5 0 01.348-.404zm4.613-12.143l-.509.266s-.208.116-.348.31c-.15.2-.24.513-.24.52l-.273.982-.28-.981a1.887 1.887 0 00-.24-.522c-.132-.189-.344-.305-.347-.309l-.513-.266.513-.274s.208-.116.347-.305c.151-.205.24-.514.24-.522L8.938 0l.28.981c.025.089.115.352.24.522.14.189.348.305.348.305l.51.274zm12.378 11.72l-.382.2s-.156.086-.261.231c-.113.15-.18.385-.18.391l-.205.736-.21-.736a1.416 1.416 0 00-.18-.39c-.1-.143-.258-.23-.26-.233l-.385-.2.384-.205s.156-.087.261-.23a1.36 1.36 0 00.18-.39l.205-.736.21.736c.019.066.086.263.18.39a.93.93 0 00.26.23l.383.206zm-15.816 8.64l-.382.2s-.156.086-.26.231c-.113.15-.18.385-.18.391L5.848 24l-.21-.736a1.415 1.415 0 00-.18-.39c-.1-.143-.258-.23-.26-.233l-.386-.2.385-.205s.156-.087.26-.23c.114-.153.181-.384.181-.39l.205-.736.21.736c.018.067.085.263.18.39a.93.93 0 00.26.23l.382.206z" />
  </svg>
);
