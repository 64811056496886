import * as React from 'react';
export const HelpWheel = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M10.202 14.502a3.5 3.5 0 100-7.001 3.5 3.5 0 000 7.001zM14.98 18.75l-4.285-4.284M13.666 11.496l4.285 4.284M2.453 15.78l4.285-4.285M9.708 14.467L5.423 18.75M5.423 3.254l4.285 4.284M6.738 10.508L2.453 6.223M17.95 6.223l-4.284 4.285M10.695 7.538l4.285-4.284" />
    <path vectorEffect="non-scaling-stroke" d="M10.202 20.103a9.102 9.102 0 100-18.203 9.102 9.102 0 100 18.203z" />
    <path vectorEffect="non-scaling-stroke" d="M9.735 20.337a3.5 3.5 0 004.782 1.281l3.638-2.1a3.5 3.5 0 014.782 1.281" />
  </svg>
);
