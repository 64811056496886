import { RouteComponentProps, useNavigate } from '@reach/router';
import { Box, Columns, Stack, Text } from '@spaceship-fspl/components';
import {
  useGetNonExhaustedIDOptions,
  useSourcesLeftToVerify,
} from '@spaceship-fspl/green-id';
import { FeatherChevronRightIcon } from '@spaceship-fspl/icons-web';
import { backgroundColor, transition } from '@spaceship-fspl/styles';
import { OnboardingContainer } from 'components/layouts/onboarding';
import { PageHeading } from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useGreenID } from 'contexts/greenid';
import React from 'react';
import styled from 'styled-components';

import { MultipleIDSelectionForm } from './multiple-id-form';

const IDOptionsLinks: React.FC<React.PropsWithChildren> = () => {
  const { getUploadLinkBySourceName, routes, ruleset } = useGreenID();
  const idOptions = useGetNonExhaustedIDOptions(ruleset);

  return (
    <Stack spaceY="xxs">
      {idOptions.map(({ label, isFieldsSubmissionRejected, sourceName }) => {
        const nextPageLink =
          isFieldsSubmissionRejected && sourceName
            ? getUploadLinkBySourceName(sourceName)
            : routes[label];
        return (
          <RouterLink
            to={nextPageLink}
            trackingProperties={{ name: 'greenid_list_option' }}
            key={label}
          >
            <StyledOptionBox>
              <Text variant={2} isBold={true}>
                {label}
              </Text>
              <Box marginLeft="sm" lineHeight={0}>
                <FeatherChevronRightIcon color="indigo.070" size="md" />
              </Box>
            </StyledOptionBox>
          </RouterLink>
        );
      })}
    </Stack>
  );
};

export const GreenIDList: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const { ruleset } = useGreenID();
  const greenidSourcesLeftToVerify = useSourcesLeftToVerify(ruleset);
  const isGreenIDSourcesLeftToVerifyMoreThanOne =
    greenidSourcesLeftToVerify > 1;

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <Stack spaceY={{ xs: 'md', md: 'xl' }}>
            <PageHeading
              title={`Select ${
                isGreenIDSourcesLeftToVerifyMoreThanOne ? 'two' : 'an'
              } identification document ${
                isGreenIDSourcesLeftToVerifyMoreThanOne ? 'types' : 'type'
              }`}
              subtitle={`To get your account up and running, we need to verify your identity. This is for your security and for regulatory purposes.${
                !isGreenIDSourcesLeftToVerifyMoreThanOne
                  ? ' You only need to do it once.'
                  : ''
              }`}
            />

            {isGreenIDSourcesLeftToVerifyMoreThanOne ? (
              <MultipleIDSelectionForm navigate={navigate} />
            ) : (
              <IDOptionsLinks />
            )}
          </Stack>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

const StyledOptionBox = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 'xxs',
  boxShadow: 'sm',
  padding: 'md',
})`
  ${backgroundColor('neutral.000')}
  ${transition}

  :hover {
    ${backgroundColor('neutral.015')}
  }
`;
