import * as React from 'react';
export const GaugeDashboardMax = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M3.7 15.479h2.37m7.93.002h6M7.7 10.93L6.024 9.254M16.4 10.98l1.676-1.676M12 8.98V6.61m0 9.825c.794 0 1.435-.641 1.435-1.435s-.641-1.435-1.435-1.435-1.435.641-1.435 1.435.641 1.435 1.435 1.435zM12 4C5.926 4 1 8.926 1 15v3.348c0 .526.43.956.957.956h20.087a.96.96 0 00.956-.956V15c0-6.074-4.926-11-11-11z" />
  </svg>
);
