import { useCanReadSaver, useCanReadSuper } from '@spaceship-fspl/auth';
import {
  backgroundColor,
  borderColor as styleBorderColor,
  borderWidth,
  bottom,
  Color,
  left,
  right,
  top,
} from '@spaceship-fspl/styles';
import {
  FeatureFlagKeys,
  superFeatureFlags,
  useFeatureFlag,
  voyagerFeatureFlags,
} from 'helpers/dynamic-config';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { PersistKey, retrieve } from 'helpers/persist';
import React from 'react';
import styled, { css } from 'styled-components';

interface IndicatorPosition {
  top?: Parameters<typeof top>[0];
  bottom?: Parameters<typeof bottom>[0];
  left?: Parameters<typeof left>[0];
  right?: Parameters<typeof right>[0];
}

export interface HighlightFeatureProps {
  featureFlag?: FeatureFlagKeys;
  as?: React.ElementType;
  dismissOnClick?: boolean;
  onClick?: () => void;
  color?: Color;
  borderColor?: Color;
  indicatorPosition?: IndicatorPosition;
}

export const HighlightFeature: React.FC<
  React.PropsWithChildren<HighlightFeatureProps>
> = ({ featureFlag, as: Component = 'button', ...props }) => {
  if (featureFlag === undefined) {
    return <Component onClick={props.onClick}>{props.children}</Component>;
  }

  return <FeatureWrapper featureFlag={featureFlag} as={Component} {...props} />;
};

const FeatureWrapper: React.FC<
  React.PropsWithChildren<
    Omit<HighlightFeatureProps, 'featureFlag' | 'as'> & {
      featureFlag: FeatureFlagKeys;
      as: React.ElementType;
    }
  >
> = ({
  featureFlag,
  children,
  as: Component,
  onClick,
  color,
  dismissOnClick = false,
  borderColor = 'neutral.000',
  indicatorPosition = {},
}) => {
  // TODO SUP-1434:
  // Look into why useLocalStorage doesn't grab latest value when
  // multiple components are using the hook with the same PersistKey
  const storedDismissedKeys = retrieve(PersistKey.HIGHLIGHT_FEATURES_DISMISSED);
  const dismissedKeys = storedDismissedKeys
    ? JSON.parse(storedDismissedKeys)
    : [];
  const [, setDismissedKey] = useLocalStorage<Array<FeatureFlagKeys>>(
    PersistKey.HIGHLIGHT_FEATURES_DISMISSED,
    [],
  );
  const isVoyagerUser = useCanReadSaver();
  const isSuperUser = useCanReadSuper();
  const show =
    useFeatureFlag(featureFlag) &&
    ((voyagerFeatureFlags.includes(featureFlag) && isVoyagerUser) ||
      (superFeatureFlags.includes(featureFlag) && isSuperUser));
  const showIndicator = show && !dismissedKeys.includes(featureFlag);

  const handleClick = async (): Promise<void> => {
    onClick?.();

    if (dismissOnClick) {
      setDismissedKey((keys) => {
        if (keys.includes(featureFlag)) {
          return keys;
        }
        return [...keys, featureFlag];
      });
    }
  };

  return (
    <Component onClick={handleClick}>
      {showIndicator ? (
        <StyledHighlightContainer
          color={color ?? 'red.100'}
          borderColor={borderColor}
          indicatorPosition={indicatorPosition}
        >
          {children}
        </StyledHighlightContainer>
      ) : (
        children
      )}
    </Component>
  );
};

const StyledHighlightContainer = styled.div<{
  color: Color;
  borderColor: Color;
  indicatorPosition: IndicatorPosition;
}>`
  position: relative;

  ::before {
    ${({ color }) => backgroundColor(color)}
    ${({ borderColor }) => styleBorderColor(borderColor)}
    ${borderWidth('md')}
    border-style: solid;
    border-radius: 50%;
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;

    ${({ indicatorPosition }) => css`
      ${top(indicatorPosition.top || 'auto')};
      ${bottom(indicatorPosition.bottom || 'auto')};
      ${left(indicatorPosition.left || 'auto')};
      ${right(indicatorPosition.right || 'auto')};
    `}
  }
`;
