import { gql, useQuery } from '@apollo/client';
import { BeneficiaryBase } from '@sargon/api-client';
import {
  Box,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { WebAppSuperBeneficiaries } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperBeneficiaries';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  hasInProgressBindingNomination,
  useGetBeneficiaries,
  useGetLatestBeneficiaryIntent,
} from '@spaceship-fspl/super';
import { Routes } from 'pages/routes';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from './components';

const BENEFICIARY_DESCRIPTION = {
  [BeneficiaryBase.BeneficiaryTypeEnum.NonBinding]:
    'Non-binding, non lapsing death benefit',
  [BeneficiaryBase.BeneficiaryTypeEnum.Binding]:
    'Binding, non lapsing death benefit',
};

export const SuperBeneficiariesCard: React.FC<React.PropsWithChildren> = () => {
  const beneficiaries = useGetBeneficiaries();
  const latestBeneficiaryIntent = useGetLatestBeneficiaryIntent();

  const resp = useQuery<WebAppSuperBeneficiaries>(
    gql`
      query WebAppSuperBeneficiaries {
        contact {
          id
          account {
            id
            superProductInstance {
              id
              latestBindingBeneficiaryIntentId
            }
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: 'cache-and-network',
    },
  );

  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;

  const hasBeneficiaries = !!beneficiaries.data?.length;
  const currentBeneficiaryType =
    beneficiaries.data?.[0]?.beneficiaryType ??
    BeneficiaryBase.BeneficiaryTypeEnum.NonBinding;
  const hasBindingNomination = hasInProgressBindingNomination({
    beneficiaryIntent: latestBeneficiaryIntent.data,
    currentBeneficiaryType,
    latestBindingBeneficiaryIntentId:
      resp.data?.contact.account?.superProductInstance
        ?.latestBindingBeneficiaryIntentId,
  });

  return (
    <AccountCard>
      <AccountCardHeading
        title="Your beneficiaries"
        isReadOnly={!!sftLimitedServicePeriodEnabled}
        edit={
          !sftLimitedServicePeriodEnabled
            ? {
                isEditing: false,
                to: Routes.SUPER_COMING_SOON,
              }
            : undefined
        }
      />

      <AccountCardContent>
        {!hasBeneficiaries && (
          <Stack spaceY="sm">
            <Text variant={2}>
              A beneficiary is the person you have nominated to receive your
              super in the event of your death.
              <br />
              <br />
              To ensure your benefits go to the right person(s), please make
              sure you keep this information up to date.
              <br />
              <br />
              You can also make or amend binding nomination(s).
              <br />
              <br />
              For more information on nominations, please refer to the{' '}
              <TextLink
                color="indigo.070"
                href={ExternalRoutes.SUPER_REFERENCE_GUIDE}
                target="_blank"
                rel="noreferrer"
              >
                Reference Guide
              </TextLink>
            </Text>
          </Stack>
        )}

        {hasBeneficiaries && (
          <Stack spaceY="md">
            <Stack spaceY="sm">
              <Text variant={2}>
                Below are the beneficiaries you&apos;ve nominated. To ensure
                your benefits go to the right person(s), please make sure you
                keep this information up to date. For more information on
                nominations, please refer to the{' '}
                <TextLink
                  color="indigo.070"
                  href={ExternalRoutes.SUPER_REFERENCE_GUIDE}
                  target="_blank"
                  rel="noreferrer"
                >
                  Reference Guide
                </TextLink>
              </Text>
            </Stack>

            <Stack spaceY="xs">
              <Heading variant={5}>Beneficiary type</Heading>
              <Box
                backgroundColor="neutral.015"
                borderRadius="xs"
                paddingX={{ xs: 'sm', md: 'md' }}
                paddingY={{ xs: 'xs', md: 'sm' }}
              >
                <Text variant={2}>
                  {BENEFICIARY_DESCRIPTION[currentBeneficiaryType]}
                </Text>

                {hasBindingNomination ? (
                  <Text variant={2} color="red.100">
                    Binding nomination (in-progress)
                  </Text>
                ) : null}
              </Box>

              <Heading variant={5}>Benefit proportions</Heading>
              <Box
                backgroundColor="neutral.015"
                borderRadius="xs"
                paddingX={{ xs: 'sm', md: 'md' }}
                paddingY={{ xs: 'xs', md: 'sm' }}
              >
                <Stack spaceY="sm">
                  {beneficiaries.data?.map((b, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box paddingRight="sm">
                        <Text variant={2}>
                          {b.givenName} {b.familyName}
                        </Text>
                      </Box>
                      <Text variant={2}>{b.benefitProportion}%</Text>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Stack>
        )}
      </AccountCardContent>
    </AccountCard>
  );
};
