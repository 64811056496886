import {
  TrackingAction,
  useTracking,
  UseTrackingTrackFunctionParameters,
} from '@spaceship-fspl/tracking';
import React, { forwardRef, HTMLAttributeAnchorTarget } from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  /* reset the native <button> styles */
  margin: 0;
  padding: 0;
  background: none;
  border: none;

  /* universal styles */
  cursor: pointer;
  :disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
`;

export interface UnstyledButtonProps {
  isDisabled?: boolean;
  href?: string;
  rel?: string;
  target?: HTMLAttributeAnchorTarget;
  title?: string;
  tracking?: UseTrackingTrackFunctionParameters;
  onClick?: () => void;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
}

export const UnstyledButton: React.FC<
  React.PropsWithChildren<UnstyledButtonProps>
> = forwardRef(
  (
    {
      isDisabled,
      onClick,
      href,
      rel,
      target,
      tracking,
      children,
      type = 'button',
      ...otherProps
    },
    ref,
  ) => {
    const { track } = useTracking();

    const handleClick = (): void => {
      if (tracking) {
        track(tracking);
      } else if (href && !isDisabled) {
        track({
          action: TrackingAction.Navigate,
          label: href,
        });
      }
      onClick?.();
    };

    // display a button when disabled, even if we have an anchor, because user input needs
    // to be blocked
    if (href && !isDisabled) {
      // because we're using a anchor, we need to emulate the behaviour of a button
      // @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
      const handleKeyDown = (
        event: React.KeyboardEvent<HTMLAnchorElement>,
      ): void => {
        if (event.defaultPrevented) return;
        if (event.metaKey) return;
        if (event.key === ' ') {
          event.currentTarget.click();
        }
      };
      return (
        <Wrapper
          {...otherProps}
          ref={ref as React.RefObject<HTMLAnchorElement>}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          href={href}
          role="button"
          as="a"
          rel={rel}
          target={target}
        >
          {children}
        </Wrapper>
      );
    }
    return (
      <Wrapper
        role="button"
        {...otherProps}
        ref={ref as React.RefObject<HTMLButtonElement>}
        type={type}
        onClick={handleClick}
        disabled={isDisabled}
      >
        {children}
      </Wrapper>
    );
  },
);

UnstyledButton.displayName = 'UnstyledButton';
