import * as React from 'react';
export const StreamlineGenderHetero = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12.037 6.69a5.866 5.866 0 11-7.005-.25M8.37 17.133V23M9.724 13.784a5.868 5.868 0 118.409-.88M17.653 5.148L21.57 1M17.904 1h3.667v3.667M5.437 20.067h5.867" />
  </svg>
);
