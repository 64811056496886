import {
  Accordion,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import React from 'react';

export const TaxResidencyFAQ: React.FC<React.PropsWithChildren> = () => (
  <>
    <Stack spaceY="sm">
      <Heading component="h3" variant={5} isBold={true}>
        Frequently asked questions
      </Heading>

      <Accordion variant="info" title="What is foreign tax residency status">
        <Stack spaceY={{ xs: 'md' }}>
          <Text variant={2} color="neutral.080">
            We are required to collect certain information about you in order to
            determine your foreign tax residency status, having regard to our
            obligations under the Taxation Administration Act 1953 (Cth), which
            gives effect to:
          </Text>
          <ul>
            <li>
              <Text variant={2} color="neutral.080">
                the global standard for the collection, reporting and exchange
                of financial account information for foreign tax residents;
              </Text>
            </li>

            <li>
              <Text variant={2} color="neutral.080">
                and the withholding tax and reporting regime in relation to tax
                residents of the United States of America, known as the Foreign
                Account Tax Compliance Act of the United States of America.
              </Text>
            </li>
          </ul>
          <Text variant={2} color="neutral.080">
            Where required by law, we will provide that information (as well as
            information about your investment in the Fund) to the ATO. The ATO
            will in turn exchange that information with equivalent foreign
            country tax authorities in accordance with the Australian
            Government&apos;s international obligations.
          </Text>
          <Text variant={2} color="neutral.080">
            In order to determine your foreign tax residency status (which we
            need to do at the time first make an investing in the Fund and
            continue to have an investment in the Fund), we may need to obtain
            additional information from you, including documentation and
            certifications.
          </Text>
          <Text variant={2} color="neutral.080">
            If you do not provide any information we reasonably request by the
            time we require it, we may be required to withhold tax on the
            payment of any distribution we pay to you or gross proceeds from the
            sale of investments, or close your Spaceship investment accounts.
          </Text>
          <Text variant={2} color="neutral.080">
            You must tell us if your foreign tax residency status changes.
          </Text>
        </Stack>
      </Accordion>

      <Accordion
        variant="info"
        title="What is a Tax Identification Number (TIN)?"
      >
        <Stack spaceY={{ xs: 'md' }}>
          <Text variant={2} color="neutral.080">
            A Tax Identification Number is an identifying number issued by a
            foreign nation to individuals who the nation deems as a registered
            tax resident.
          </Text>
          <Text variant={2} color="neutral.080">
            For example, in Australia the TIN is a Tax File Number (TFN).{' '}
          </Text>
          <Text variant={2} color="neutral.080">
            Visit the OECD&apos;s website for country specific information about
            TINs:
            <br />
            <TextLink href={ExternalRoutes.OECD_TIN}>
              {ExternalRoutes.OECD_TIN}
            </TextLink>
          </Text>
        </Stack>
      </Accordion>
    </Stack>
  </>
);
