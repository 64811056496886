import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Columns } from '@spaceship-fspl/components';
import { WebAppVoyagerInvestOptions } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerInvestOptions';
import { PageContainer } from 'components/layouts/page';
import { VoyagerInvestNav } from 'components/navigation/voyager-invest';
import React from 'react';

export const VoyagerInvestOptions: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const resp = useQuery<WebAppVoyagerInvestOptions>(gql`
    query WebAppVoyagerInvestOptions {
      contact {
        id
        account {
          id
          saverBoostRecipes {
            id
          }
        }
      }
    }
  `);

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ md: 1 / 2 }}>
          <VoyagerInvestNav
            hasBoosts={!!resp.data?.contact.account?.saverBoostRecipes?.length}
          />
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
