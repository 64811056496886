import * as React from 'react';
export const StreamlineAccountingCoinsStack = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M6.867 6.867c3.24 0 5.866-1.314 5.866-2.934S10.107 1 6.867 1C3.627 1 1 2.313 1 3.933c0 1.62 2.627 2.934 5.867 2.934zM12.733 6.867c0 1.62-2.626 2.933-5.866 2.933C3.627 9.8 1 8.487 1 6.867M12.733 9.8V3.933" />
    <path vectorEffect="non-scaling-stroke" d="M1 3.933V18.6c0 1.62 2.626 2.933 5.867 2.933.738.002 1.475-.072 2.2-.218" />
    <path vectorEffect="non-scaling-stroke" d="M1 15.667c0 1.62 2.626 2.933 5.867 2.933.738.001 1.475-.072 2.2-.218M1 12.733c0 1.62 2.626 2.934 5.867 2.934.738 0 1.475-.072 2.2-.218M1 9.8c0 1.62 2.626 2.933 5.867 2.933.738.002 1.475-.072 2.2-.218M17.133 17.133c3.24 0 5.867-1.313 5.867-2.933 0-1.62-2.627-2.933-5.867-2.933s-5.866 1.313-5.866 2.933c0 1.62 2.626 2.933 5.866 2.933z" />
    <path vectorEffect="non-scaling-stroke" d="M11.267 14.2v5.867c0 1.62 2.626 2.933 5.866 2.933C20.373 23 23 21.687 23 20.067V14.2" />
    <path vectorEffect="non-scaling-stroke" d="M23 17.133c0 1.62-2.626 2.934-5.867 2.934-3.24 0-5.866-1.314-5.866-2.934" />
  </svg>
);
