import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import { usePrevious } from '@spaceship-fspl/helpers';
import {
  ApiError,
  getApiErrors,
  otpValidations,
  useRequestMemberOtp,
  useVerifyMemberOtp,
} from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { LinkButton } from 'components/link-button';
import { Modal } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import { differenceInSeconds } from 'date-fns';
import { TrackingEvent } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { useMaskedMobileNumber } from 'helpers/hooks/use-masked-mobile-number';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { ControllerInputWithInlineError } from './controller-input';

export interface SuperOTPModalProps {
  showModal: boolean;
  onVerifiedOTP: () => void;
  onUnverifiedOTP: () => void;
}

export const SuperOTPModal: React.FC<
  React.PropsWithChildren<SuperOTPModalProps>
> = ({ showModal = false, onVerifiedOTP, onUnverifiedOTP }) => {
  const track = useTrack();
  const { isLoading: isRequestingOtp, mutateAsync: requestMemberOtp } =
    useRequestMemberOtp();
  const { isLoading: isVerifying, mutateAsync: verifyMemberOtp } =
    useVerifyMemberOtp();
  const maskedMobileNumber = useMaskedMobileNumber();
  const { popToast } = useNotifications();
  const prevShowModal = usePrevious(showModal);
  const otpLastRequested = useRef(new Date(0));

  const { control, handleSubmit } = useForm<{
    code: string;
  }>();

  const handleResend = async (): Promise<void> => {
    try {
      const secondsPassed = differenceInSeconds(
        new Date(),
        otpLastRequested.current,
      );

      if (secondsPassed >= 5) {
        await requestMemberOtp();
        otpLastRequested.current = new Date();
        popToast({
          message: 'A new verification code has been sent.',
          level: 'success',
        });
      }
    } catch (error) {
      popToast({
        message: (error as Error | undefined)?.message || GENERIC_ERROR_MESSAGE,
        level: 'error',
      });
    }
  };

  const handleVerify = handleSubmit(async ({ code }) => {
    try {
      await verifyMemberOtp(code);
      await onVerifiedOTP();
    } catch (response) {
      const castedResponse = response as Response;
      const errors = await getApiErrors(castedResponse);
      const sargonError = new ApiError(castedResponse.status, errors);
      popToast({
        message:
          sargonError.httpStatusCode === 401
            ? 'Incorrect verification code'
            : sargonError.message || GENERIC_ERROR_MESSAGE,
        level: 'error',
      });
    }
  });

  useEffect(() => {
    if (showModal && requestMemberOtp) {
      requestMemberOtp();
    }
  }, [requestMemberOtp, showModal]);

  useEffect(() => {
    if (prevShowModal !== undefined && prevShowModal !== showModal) {
      track?.(TrackingEvent.DISPLAY, {
        properties: {
          name: 'super_otp_modal',
          display: showModal,
        },
      });
    }
  }, [track, prevShowModal, showModal]);

  return (
    <Modal closeModal={onUnverifiedOTP} showModal={showModal}>
      <Box
        padding="xl"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box marginBottom="md">
          <Stack spaceY="xs">
            <Heading variant={3} align="center" component="h3">
              Enter your verification code
            </Heading>
            <Text variant={2} color="neutral.080" isBold={true} align="center">
              For security reasons, please enter the verification code we sent
              to your mobile number {maskedMobileNumber}.
            </Text>
          </Stack>
        </Box>

        <form onSubmit={handleVerify}>
          <Stack spaceY="md">
            <ControllerInputWithInlineError
              name="code"
              control={control}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              autoComplete="one-time-code"
              placeholder="Verification code"
              rules={{ maxLength: 6, ...otpValidations.code }}
            />

            <Text variant={4}>
              Didn&apos;t receive a text?{' '}
              <LinkButton
                size="xxs"
                onClick={handleResend}
                trackingProperties={{ name: 'super_otp_send_again_button' }}
              >
                Send again
              </LinkButton>
            </Text>
          </Stack>

          <PageFormButtonContainer>
            <PageFormContinueButton
              isLoading={isVerifying}
              isDisabled={isRequestingOtp}
              trackingProperties={{
                name: 'super_otp_verify_button',
              }}
            >
              Verify
            </PageFormContinueButton>
          </PageFormButtonContainer>
        </form>
      </Box>
    </Modal>
  );
};
