import * as React from 'react';
export const DollarBills = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M5.014 4H2.5A1.5 1.5 0 001 5.5v2.664M5.014 4A4.4 4.4 0 011 8.164M5.014 4H19M1 8.164v5.032M1 17v-3.804M1 17h3.982M1 17v1.26a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5V17M1 13.196A4.402 4.402 0 014.982 17m0 0h14.036M23 13.196V17m0-3.804A4.402 4.402 0 0019.018 17M23 13.196V8.164M23 17h-3.982M19 4h2.5A1.5 1.5 0 0123 5.5v2.664M19 4c0 2.302 1.748 3.97 4 4.164m-12.815 4.403c.4.525 1.03.822 1.69.795m0 0c1.035 0 1.874-.63 1.874-1.406 0-.776-.84-1.406-1.875-1.406C10.84 10.55 10 9.92 10 9.143s.839-1.406 1.874-1.406m0 5.625v.938m0-6.563a2.02 2.02 0 011.69.795m-1.69-.795V6.8" />
  </svg>
);
