import {
  SharedDynamicConfigKeys,
  useNumber,
} from '@spaceship-fspl/dynamic-config';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

import { DATE_FORMAT_REQUESTS, formatDate } from './date';

// 0 indexed. Use with Date().getDay()
export enum DateDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const calculateEstimatedProcessedDate = (
  submittedAt: Date,
  cutoffHour: number,
): Date => {
  // Note getHours() is zero-based, so if cutoffHour = 17, ">= 17" means 5pm or later
  const hour = submittedAt.getHours();
  const day = submittedAt.getDay();
  const isAfterCutoff = hour >= cutoffHour;

  switch (day) {
    case 1: // Monday
      // Before cutoff = +1 (Tuesday), After cutoff = +2 (Wednesday)
      return addDays(submittedAt, isAfterCutoff ? 2 : 1);

    case 2: // Tuesday
      // Before cutoff = +1 (Wednesday), After cutoff = +2 (Thursday)
      return addDays(submittedAt, isAfterCutoff ? 2 : 1);

    case 3: // Wednesday
      // Before cutoff = +1 (Thursday), After cutoff = +2 (Friday)
      return addDays(submittedAt, isAfterCutoff ? 2 : 1);

    case 4: // Thursday
      // Before cutoff = +1 (Friday), After cutoff = +4 (Monday)
      return addDays(submittedAt, isAfterCutoff ? 4 : 1);

    case 5: // Friday
      // Before cutoff = +3 (Monday), After cutoff = +4 (Tuesday)
      return addDays(submittedAt, isAfterCutoff ? 4 : 3);

    case 6: // Saturday
      // Both before & after cutoff = +3 (Tuesday)
      return addDays(submittedAt, 3);

    case 0: // Sunday
      // Both before & after cutoff = +2 (Tuesday)
      return addDays(submittedAt, 2);

    default:
      // Fallback — shouldn’t be reached given the cases above
      return addDays(submittedAt, 1);
  }
};

export const useEstimatedProcessedDate = (submittedAt: Date): string => {
  const cutoffHour = useNumber(
    SharedDynamicConfigKeys.DAILY_ORDER_CUTOFF_HOUR,
    -1,
  );
  return useMemo(
    () =>
      formatDate(
        calculateEstimatedProcessedDate(submittedAt, cutoffHour),
        DATE_FORMAT_REQUESTS,
      ),
    [submittedAt, cutoffHour],
  );
};
