import { ProductOffering } from '@spaceship-fspl/graphql';
import { config } from 'helpers/config';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { PersistKey } from 'helpers/persist';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

/**
 * Example:
 * https://app.preprod.spaceship.com.au/signup/voyager?deeplinkPath=/voyager/onboarding&referralCode=S89RYB6MQI&shortName=Tammy&utmCampaign=voyager
 *                                                                  ^^^^^^^^^^^^^^^^^^^              ^^^^^^^^^^           ^^^^^              ^^^^^^
 */

export enum SearchParamsKeys {
  DEEP_LINK_PATH = 'deeplinkPath',
  REFERRAL_CODE = 'referralCode',
  SHORT_NAME = 'shortName',
  UTM_CAMPAIGN = 'utmCampaign',
}

export enum DeepLinkingParamsKeys {
  UTM_CAMPAIGN = '~campaign',
  VOYAGER_REFERRAL_CODE = 'code',
  SUPER_REFERRAL_CODE = 'superReferralCode',
  VOYAGER_REFERRER = 'referrer',
  SUPER_REFERRER = 'superReferrer',
}

interface DeepLinkingParams {
  [DeepLinkingParamsKeys.UTM_CAMPAIGN]?: string;
  [DeepLinkingParamsKeys.SUPER_REFERRAL_CODE]?: string;
  [DeepLinkingParamsKeys.VOYAGER_REFERRAL_CODE]?: string;
  [DeepLinkingParamsKeys.VOYAGER_REFERRER]?: string;
  [DeepLinkingParamsKeys.SUPER_REFERRER]?: string;
}

export interface DeepLinkingContext {
  params?: DeepLinkingParams;
  setParams: (
    value: DeepLinkingParams | ((t: DeepLinkingParams) => DeepLinkingParams),
  ) => void;
  storeReferralSearchParams: (
    searchParams: URLSearchParams,
    productOffering: ProductOffering,
  ) => void;
  isReady: boolean;
}

const DeepLinkingContext = createContext<DeepLinkingContext | undefined>(
  undefined,
);

export const DeepLinkingProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);

  const [params, setParams] = useLocalStorage<DeepLinkingParams>(
    PersistKey.DEEP_LINKING_PARAMS,
  );

  useEffect(() => {
    if (!isReady) {
      if (config.branchIOKey === '') {
        return;
      }

      try {
        window.branch.init(config.branchIOKey, {}, (err, data) => {
          if (err || !data?.data_parsed) {
            return;
          }

          const params: { [index: string]: unknown } = data.data_parsed;
          if (params['+clicked_branch_link']) {
            setParams(params);
          }

          setIsReady(true);
        });
      } catch {
        // do nothing
      }
    }
  }, [isReady, setIsReady, setParams]);

  const storeReferralSearchParams = useCallback(
    (searchParams: URLSearchParams, productOffering: ProductOffering) => {
      const campaign =
        searchParams.get(SearchParamsKeys.UTM_CAMPAIGN) ?? undefined;

      const code =
        searchParams.get(SearchParamsKeys.REFERRAL_CODE) ?? undefined;

      const referrer =
        searchParams.get(SearchParamsKeys.SHORT_NAME) ?? undefined;

      const params: DeepLinkingParams = {
        [DeepLinkingParamsKeys.UTM_CAMPAIGN]: campaign,
      };

      switch (productOffering) {
        case ProductOffering.VOYAGER: {
          params[DeepLinkingParamsKeys.VOYAGER_REFERRAL_CODE] = code;
          params[DeepLinkingParamsKeys.VOYAGER_REFERRER] = referrer;
          break;
        }
        case ProductOffering.SUPER: {
          params[DeepLinkingParamsKeys.SUPER_REFERRAL_CODE] = code;
          params[DeepLinkingParamsKeys.SUPER_REFERRER] = referrer;
          break;
        }
      }

      if (Object.entries(params).length > 0) {
        setParams(params);
      }
    },
    [setParams],
  );

  return (
    <DeepLinkingContext.Provider
      value={{ params, setParams, storeReferralSearchParams, isReady }}
    >
      {children}
    </DeepLinkingContext.Provider>
  );
};

export const useDeepLinking = (): DeepLinkingContext => {
  const context = useContext(DeepLinkingContext);

  if (!context) {
    throw new Error('useDeepLinking must be wrapped with DeepLinkingProvider');
  }

  return context;
};

export const useUTMCampaign = (): {
  value?: string;
  setValue: (value?: string) => void;
} => {
  const deepLinking = useDeepLinking();

  return {
    value: deepLinking.params?.[DeepLinkingParamsKeys.UTM_CAMPAIGN],
    setValue: (UTMCampaign?: string): void => {
      deepLinking.setParams((params) => ({
        ...params,
        [DeepLinkingParamsKeys.UTM_CAMPAIGN]: UTMCampaign,
      }));
    },
  };
};

export const useVoyagerReferralCode = (): {
  value?: string;
  setValue: (value?: string) => void;
} => {
  const deepLinking = useDeepLinking();

  return {
    value: deepLinking.params?.[DeepLinkingParamsKeys.VOYAGER_REFERRAL_CODE],
    setValue: (code?: string): void => {
      deepLinking.setParams((params) => ({
        ...params,
        [DeepLinkingParamsKeys.VOYAGER_REFERRAL_CODE]: code,
      }));
    },
  };
};

export const useSuperReferralCode = (): {
  value?: string;
  setValue: (value?: string) => void;
  isReady: boolean;
} => {
  const deepLinking = useDeepLinking();

  return {
    value: deepLinking.params?.[DeepLinkingParamsKeys.SUPER_REFERRAL_CODE],
    setValue: (code?: string): void => {
      deepLinking.setParams((params) => ({
        ...params,
        [DeepLinkingParamsKeys.SUPER_REFERRAL_CODE]: code,
      }));
    },
    isReady: deepLinking.isReady,
  };
};

export const useVoyagerReferrer = (): {
  value?: string;
  setValue: (value?: string) => void;
} => {
  const deepLinking = useDeepLinking();

  return {
    value: deepLinking.params?.[DeepLinkingParamsKeys.VOYAGER_REFERRER],
    setValue: (name?: string): void => {
      deepLinking.setParams((params) => ({
        ...params,
        [DeepLinkingParamsKeys.VOYAGER_REFERRER]: name,
      }));
    },
  };
};

export const useSuperReferrer = (): {
  value?: string;
  setValue: (value?: string) => void;
} => {
  const deepLinking = useDeepLinking();

  return {
    value: deepLinking.params?.[DeepLinkingParamsKeys.SUPER_REFERRER],
    setValue: (name?: string): void => {
      deepLinking.setParams((params) => ({
        ...params,
        [DeepLinkingParamsKeys.SUPER_REFERRER]: name,
      }));
    },
  };
};
