import { Theme } from '../../types';
import { textLinkStyles } from './links';
import { fontFamilies, headingVariant, textVariant } from './text';

export const webappGreyTheme: Theme = {
  name: 'webapp-grey',
  fontFamilies,
  headingVariant,
  textVariant,
  textLinkStyles,
};

export const webappWhiteTheme: Theme = {
  ...webappGreyTheme,
  name: 'webapp-white',
};
