import { gql, useQuery } from '@apollo/client';
import {
  ActionLink,
  Box,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  SuperPortfolio,
  useSuperPortfolioFlags,
} from '@spaceship-fspl/graphql';
import {
  WebAppSuperInvestmentOptionCard,
  WebAppSuperInvestmentOptionCard_superPortfolioInformation,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperInvestmentOptionCard';
import {
  SuperGlobalIndexIcon,
  SuperGrowthxIcon,
} from '@spaceship-fspl/icons-web';
import { useGetCurrentInvestmentAllocation } from '@spaceship-fspl/super';
import { RouterLink } from 'components/router-link';
import { Routes } from 'pages/routes';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from './components';

export const SuperInvestmentOptionCard: React.FC<
  React.PropsWithChildren
> = () => {
  const { investmentAllocation, isLoading } =
    useGetCurrentInvestmentAllocation();

  const resp = useQuery<WebAppSuperInvestmentOptionCard>(
    gql`
      query WebAppSuperInvestmentOptionCard($portfolio: SuperPortfolio!) {
        superPortfolioInformation(portfolio: $portfolio) {
          id
          name
          description
          portfolio
          feePercentTotal
          audAdminFeeBase
          learnMoreUrl
        }
      }
    `,
    {
      skip: !investmentAllocation?.key,
      variables: {
        portfolio: investmentAllocation?.key?.toUpperCase(),
      },
    },
  );
  const isLoadingData = isLoading || resp.loading;
  const config = resp.data?.superPortfolioInformation;
  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;
  return (
    <AccountCard>
      <AccountCardHeading
        title="Investment option"
        isReadOnly={!!sftLimitedServicePeriodEnabled}
        edit={
          config
            ? {
                isEditing: false,
                to: Routes.SUPER_CHANGE_INVESTMENT_OPTION,
              }
            : undefined
        }
      />

      <AccountCardContent isLoading={isLoadingData}>
        {!config ? (
          <NoPortfolioCard />
        ) : (
          <PortfolioCardContent config={config} />
        )}
      </AccountCardContent>
    </AccountCard>
  );
};

const NoPortfolioCard: React.FC<React.PropsWithChildren> = () => (
  <Text variant={2} align="center">
    Please select an investment option{' '}
    <RouterLink
      to={Routes.SUPER_SELECT_INVESTMENT_OPTION}
      trackingProperties={{ name: 'super_investment_option_card_add' }}
    >
      <TextLink color="indigo.070" component="span">
        here
      </TextLink>
    </RouterLink>
  </Text>
);

interface PortfolioCardContentProps {
  config: WebAppSuperInvestmentOptionCard_superPortfolioInformation;
}

const PortfolioCardContent: React.FC<
  React.PropsWithChildren<PortfolioCardContentProps>
> = ({ config }) => {
  const Icon =
    config.portfolio === SuperPortfolio.GLOBAL_INDEX
      ? SuperGlobalIndexIcon
      : SuperGrowthxIcon;

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
      <Box marginRight={{ md: 'md' }} marginBottom="xxs">
        <Icon color="indigo.070" size={{ xs: 'xxl', md: 'xxxl' }} />
      </Box>

      <Stack spaceY="xxs">
        <Heading variant={{ xs: 5, md: 4 }} isBold={true}>
          {config.name}
        </Heading>

        <Text variant={3}>{config.description}</Text>

        <ActionLink
          size="xs"
          color="indigo.070"
          href={config.learnMoreUrl}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </ActionLink>
      </Stack>
    </Box>
  );
};
