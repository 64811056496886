export type IconSize = keyof typeof iconSizes;

export const iconSizes = {
  xxs: 8,
  xs: 12,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
  xxl: 64,
  xxxl: 96,
  xxxxl: 120,
};
