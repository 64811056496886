import { RouteComponentProps, useNavigate } from '@reach/router';
import { Stack } from '@spaceship-fspl/components';
import { useGetUser, useSetGreenIdFields } from '@spaceship-fspl/data';
import {
  AustraliaPassportFields,
  australiaPassportValidations,
  GreenIdTermsAndCondition,
  IDName,
  idSourceProtosToType,
  useID,
  validateNameByLength,
} from '@spaceship-fspl/green-id';
import { formatDate } from '@spaceship-fspl/helpers';
import { GreenIdSourceName } from '@spaceship-fspl/types/externalapi';
import australiaPassportImage from 'assets/greenid-australian-passport@2x.png';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { useGreenID } from 'contexts/greenid';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React from 'react';
import { useForm } from 'react-hook-form';

import { NO_USER_VERIFICATION_ERROR, useReverifyAndNavigate } from './hooks';
import { FormLayout } from './layout';

export const AustraliaPassport: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const idName = IDName.AUSTRALIAN_PASSPORT;
  const { getUploadLinkBySourceName, ruleset } = useGreenID();
  const { mutateAsync: postGreenIDFields, isLoading } =
    useSetGreenIdFields(ruleset);
  const verificationId = useID(ruleset);
  const { popToast } = useNotifications();
  const { pop: showIntercom } = useIntercom();
  const reverifyAndNavigate = useReverifyAndNavigate(idName);
  const { data: user } = useGetUser();
  const contact = user?.contact;

  const { handleSubmit, watch, control } = useForm<AustraliaPassportFields>({
    defaultValues: {
      greenid_passportdvs_number: '',
      greenid_passportdvs_givenname: contact?.first_name || '',
      greenid_passportdvs_middlename: '',
      greenid_passportdvs_surname: contact?.last_name || '',
      greenid_passportdvs_dob: contact?.date_of_birth
        ? formatDate(new Date(contact?.date_of_birth))
        : '',
      greenid_passportdvs_tandc: GreenIdTermsAndCondition.ON,
    },
  });

  const onSubmit = async (values: AustraliaPassportFields): Promise<void> => {
    const sourceName = idSourceProtosToType(GreenIdSourceName.Enum.PASSPORTDVS);
    try {
      await postGreenIDFields({
        verification_id: verificationId,
        source_name: sourceName,
        field_inputs: {
          ...values,
          greenid_passportdvs_tandc: GreenIdTermsAndCondition.ON,
        },
      });

      await reverifyAndNavigate();
    } catch (error) {
      if (
        error instanceof TypeError ||
        (error as Error | undefined)?.message.includes(
          NO_USER_VERIFICATION_ERROR,
        )
      ) {
        popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
        showIntercom();
      } else if (sourceName) {
        navigate?.(getUploadLinkBySourceName(sourceName), {
          replace: true,
        });
      }
    }
  };

  return (
    <FormLayout
      title={idName}
      image={{ url: australiaPassportImage, alt: idName }}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      idName={idName}
    >
      <Stack spaceY="sm">
        <ControllerInputWithInlineError
          name="greenid_passportdvs_number"
          control={control}
          placeholder="Document number"
          type="text"
          rules={australiaPassportValidations.documentNumber}
        />

        <ControllerInputWithInlineError
          name="greenid_passportdvs_givenname"
          control={control}
          placeholder="First name (as shown on ID)"
          type="text"
          rules={australiaPassportValidations.firstName}
        />

        <ControllerInputWithInlineError
          name="greenid_passportdvs_middlename"
          control={control}
          placeholder="Middle name (optional)"
          type="text"
          rules={{
            ...australiaPassportValidations.middleName,
            validate: (value: string) =>
              validateNameByLength({
                name: value,
                otherName: watch('greenid_passportdvs_givenname'),
                validLength: 31,
                errorMessage:
                  'Your first and middle names must be between 1 and 31 characters.',
              }),
          }}
        />

        <ControllerInputWithInlineError
          name="greenid_passportdvs_surname"
          control={control}
          placeholder="Last name (as shown on ID)"
          type="text"
          rules={australiaPassportValidations.lastName}
        />

        <ControllerInputWithInlineError
          name="greenid_passportdvs_dob"
          control={control}
          placeholder="Date of birth"
          type="text"
          format="date"
          dateFormatOption={{
            delimiter: '/',
          }}
          rules={australiaPassportValidations.dateOfBirth}
        />
      </Stack>
    </FormLayout>
  );
};
