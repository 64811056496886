import { Box, Heading } from '@spaceship-fspl/components';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
} from 'components/layouts/page';
import { Modal } from 'components/modal';
import React from 'react';

export const BasiqUnlinkModal: React.FC<
  React.PropsWithChildren<{
    showModal: boolean;
    onCloseModal: () => void;
    onUnlink: () => void;
  }>
> = ({ showModal, onCloseModal, onUnlink }) => (
  <Modal showModal={showModal} closeModal={onCloseModal}>
    <Box
      paddingX={{ xs: 'xl', md: 'lg' }}
      paddingY="xl"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Heading variant={3} align="center" component="h3">
        Are you sure you want to unlink your tracking bank and delete your
        unlinked boosts?
      </Heading>

      <PageFormButtonContainer>
        <PageFormContinueButton
          type="button"
          onClick={onUnlink}
          trackingProperties={{
            name: 'basiq_unlink_modal_confirm_button',
          }}
        >
          Yes – unlink and delete
        </PageFormContinueButton>

        <PageFormCancelButton
          onClick={onCloseModal}
          trackingProperties={{ name: 'basiq_unlink_modal_cancel_button' }}
        />
      </PageFormButtonContainer>
    </Box>
  </Modal>
);
