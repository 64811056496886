import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Spinner,
  Stack,
  TextLink,
} from '@spaceship-fspl/components';
import { useCreateMoneyWithdrawal } from '@spaceship-fspl/graphql';
import {
  cleanNumber,
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatDate,
} from '@spaceship-fspl/helpers';
import { Button } from 'components/button';
import { LabeledField } from 'components/labeled-field';
import { PageContainer } from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_WARNING_MESSAGE } from 'helpers/errors';
import { formatCurrency } from 'helpers/format';
import { addRumError } from 'helpers/monitoring';
import { TestId } from 'helpers/test-ids';
import { Routes } from 'pages/routes';
import React from 'react';

import { MONEY_TRANSACTIONS_CHUNK_SIZE } from './dashboard';

interface MoneyWithdrawConfirmProps {
  location: {
    state: {
      audAmount: string;
      etaDate: Date;
    };
  };
}

export const MoneyWithdrawConfirm: React.FC<
  React.PropsWithChildren<RouteComponentProps<MoneyWithdrawConfirmProps>>
> = (props) => {
  const navigate = useNavigate();

  const audAmount = props?.location?.state?.audAmount ?? '';
  const etaDate = props?.location?.state?.etaDate
    ? new Date(props?.location?.state?.etaDate)
    : undefined;

  const notifications = useNotifications();
  const [createMoneyWithdrawal, createMoneyWithdrawalMeta] =
    useCreateMoneyWithdrawal();

  const handleSubmit = (): void => {
    const cleaned = cleanNumber(audAmount);
    createMoneyWithdrawal({
      variables: {
        input: {
          audAmount: cleaned,
        },
        typeFilters: undefined,
        first: MONEY_TRANSACTIONS_CHUNK_SIZE,
      },
      onCompleted: () => {
        navigate(Routes.MONEY_WITHDRAW_SUCCESS, {
          state: {
            audAmount,
            etaDate,
          },
        });
      },
      onError: (error) => {
        addRumError({
          error,
          context: {
            reason: 'createMoneyWithdrawal mutation failed',
          },
        });
        notifications.popToast({
          level: 'warning',
          message: GENERIC_WARNING_MESSAGE,
        });
      },
    });
  };

  return (
    <PageContainer data-testid={TestId.MONEY_WITHDRAW_CONFIRM_PAGE}>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
          <Heading variant={3} align="center">
            Confirm your withdrawal
          </Heading>

          <Box
            backgroundColor="neutral.000"
            padding="lg"
            borderRadius="sm"
            boxShadow="sm"
            marginTop="md"
          >
            <Stack spaceY="sm">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabeledField label="Amount" align="center" size="sm">
                  {formatCurrency(audAmount)}
                </LabeledField>

                <TextLink
                  hideUnderline
                  color="indigo.070"
                  onClick={() => {
                    navigate(Routes.MONEY_DEPOSIT);
                  }}
                >
                  Edit
                </TextLink>
              </Box>

              <Divider color="neutral.030" />

              <LabeledField label="Withdrawal complete" size="sm">
                ETA {formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_LONG)}
              </LabeledField>
            </Stack>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="xl"
          >
            <Stack spaceY="xs" alignX="center">
              <Button
                variant="primary"
                size="lg"
                isLoading={createMoneyWithdrawalMeta.loading}
                trackingProperties={{ name: 'money_withdraw_confirm_submit' }}
                onClick={handleSubmit}
              >
                {createMoneyWithdrawalMeta.loading ? (
                  <Spinner size="sm" />
                ) : (
                  'Confirm'
                )}
              </Button>

              <Box marginTop="sm">
                <TextLink
                  hideUnderline
                  color="indigo.070"
                  onClick={() => {
                    navigate(Routes.MONEY);
                  }}
                >
                  Cancel
                </TextLink>
              </Box>
            </Stack>
          </Box>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
