import {
  createBackgroundColor,
  createBorderBottomColor,
  createBorderColor,
  createColor,
  createFill,
  createGetColor,
  createOpacity,
} from '@spaceship-fspl/substance-mixin';

import { Color, colors } from './colors';
import { map } from './media';

export const getColor = createGetColor<Color>(colors);

export const color = createColor({ map, getColor });
export const backgroundColor = createBackgroundColor({ map, getColor });
export const borderColor = createBorderColor({ map, getColor });
export const borderBottomColor = createBorderBottomColor({ map, getColor });
export const fill = createFill({ map, getColor });

export const opacity = createOpacity({ map });
