import { isAfter, parse } from 'date-fns';

import { State } from './constants';

export const validateNameByLength = ({
  name,
  otherName,
  validLength,
  errorMessage,
}: {
  name?: string;
  otherName?: string;
  validLength: number;
  errorMessage: string;
}): true | string => {
  if (name === undefined) {
    return true;
  }

  const totalLength =
    name.length + (otherName !== undefined ? otherName.length : 0);

  return totalLength <= validLength || errorMessage;
};

export const driverLicenceValidations = {
  number: {
    required: 'Your licence number is required.',
    minLength: {
      value: 1,
      message:
        'Your licence number must be between 1 and 10 alphanumeric characters.',
    },
    maxLength: {
      value: 10,
      message:
        'Your licence number must be between 1 and 10 alphanumeric characters.',
    },
    pattern: {
      value: /^[0-9a-zA-Z]*$/,
      message: 'Your licence number must only contain alphanumeric characters.',
    },
  },
  cardNumber: {
    required: 'Your licence card number is required.',
    pattern: (state: State): { value: RegExp; message: string } => {
      switch (state) {
        case 'ACT':
        case 'QLD':
          return {
            value: /^[A-Za-z0-9]{10}$/,
            message:
              'Your licence card number must be 10 alphanumeric characters.',
          };

        case 'NSW':
          return {
            value: /^[\d]{10}$/,
            message: 'Your licence card number must be 10 digits.',
          };

        case 'NT':
          return {
            value: /^[\d]{6,8}$/,
            message: 'Your licence card number must be between 6 and 8 digits.',
          };

        case 'SA':
        case 'TAS':
          return {
            value: /^[A-Za-z0-9]{9}$/,
            message:
              'Your licence card number must be 9 alphanumeric characters.',
          };

        case 'VIC':
          return {
            value: /^[A-Za-z0-9]{8}$/,
            message:
              'Your licence card number must be 8 alphanumeric characters.',
          };

        case 'WA':
          return {
            value: /^[A-Za-z0-9]{8,10}$/,
            message:
              'Your licence card number must be between 8 and 10 alphanumeric characters.',
          };
      }
    },
  },
  firstName: {
    required: 'Your first name is required.',
    minLength: {
      value: 1,
      message: 'Your first name must be between 1 and 20 characters.',
    },
    maxLength: {
      value: 20,
      message: 'Your first name must be between 1 and 20 characters.',
    },
  },
  middleName: {
    maxLength: {
      value: 20,
      message: 'Your middle name must be between 1 and 20 characters.',
    },
  },
  lastName: {
    required: 'Your last name is required.',
    minLength: {
      value: 1,
      message: 'Your last name must be between 1 and 40 characters.',
    },
    maxLength: {
      value: 40,
      message: 'Your last name must be between 1 and 40 characters.',
    },
  },
  dateOfBirth: {
    required: 'Your date of birth is required.',
  },
};

export const foreignPassportValidations = {
  passportNumber: {
    required: 'Your passport number is required',
    pattern: {
      value: /^[A-Za-z0-9]{1,14}$/,
      message:
        'Your passport number must be between 1 and 14 characters and must not contain spaces or special characters.',
    },
  },
  firstName: {
    required: 'Your first name is required.',
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,49}$/,
      message:
        'Your first and middle names must be between 1 and 49 characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  middleName: {
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,49}$/,
      message:
        'Your first and middle names must be between 1 and 49 characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  lastName: {
    required: 'Your last name is required',
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,49}$/,
      message:
        'Your last name must be between 1 and 49 characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  dateOfBirth: {
    required: 'Your date of birth is required.',
  },
};

export const medicareCardValidations = {
  cardNumber: {
    required: 'Your Medicare card number is required.',
    pattern: {
      value: /^[\d]{10}$/,
      message: 'Your Medicare card number must be 10 digits.',
    },
  },
  name: {
    required: 'Your name is required.',
    pattern: {
      value: /^[A-Za-z0-9\s’‘’'-]{1,27}$/,
      message:
        'Your name must be between 1 and 27 alphanumeric characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  dateOfBirth: {
    required: 'Your date of birth is required.',
  },
  referenceNumber: {
    required: 'Your Medicare reference number must be 1 digit.',
  },
  expiry: {
    required: 'Expiry date is required.',
    pattern: (
      isGreenMedicareCard: boolean,
    ): { value: RegExp; message: string } => {
      return {
        value: isGreenMedicareCard ? /\d{2}\/\d{4}/ : /\d{2}\/\d{2}\/\d{4}/,
        message: `Expiry date must be in ${
          isGreenMedicareCard ? '(MM/YYYY)' : '(DD/MM/YYYY)'
        } format.`,
      };
    },
    validate: (
      expiry: string,
      isGreenMedicareCard: boolean,
    ): boolean | string => {
      return (
        isAfter(
          parse(
            expiry,
            isGreenMedicareCard ? 'MM/yyyy' : 'dd/MM/yyyy',
            new Date(),
          ),
          new Date(),
        ) || 'Expiry date must be at least a day after today'
      );
    },
  },
};

export const australiaPassportValidations = {
  documentNumber: {
    required: 'Your document number is required.',
    pattern: {
      value: /^[a-zA-Z]{1,2}[\d]{7}$/,
      message: 'Your document number is invalid.',
    },
  },
  firstName: {
    required: 'Your first name is required.',
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,31}$/,
      message:
        'Your first name must be between 1 and 31 alphabetic characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  middleName: {
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{0,31}$/,
      message:
        'Your middle name must be between 1 and 31 alphabetic characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  lastName: {
    required: 'Last name is required',
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,31}$/,
      message:
        'Your last name must be between 1 and 31 alphabetic characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  dateOfBirth: {
    required: 'Your date of birth is required.',
  },
};

export const citizenshipCertificateValidations = {
  firstName: {
    required: 'Your first name is required.',
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,100}$/,
      message:
        'Your first name must be between 1 and 100 alphabetic characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  middleName: {
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,100}$/,
      message:
        'Your middle name must be between 1 and 100 alphabetic characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  lastName: {
    required: 'Your last name is required.',
    pattern: {
      value: /^[A-Za-z\s’‘’'-]{1,100}$/,
      message:
        'Your last name must be between 1 and 100 alphabetic characters and may contain hyphens, apostrophes and spaces.',
    },
  },
  dob: {
    required: 'Your date of birth is required.',
  },
  acquisitionDate: {
    required: 'Your aquisition date is required.',
  },
  stockNumber: {
    required: 'Your stock number is required.',
    pattern: {
      value: /^[\d]{4,11}$/,
      message: 'Stock number must be 4-11 digits.',
    },
  },
};
