import * as React from 'react';
export const SuperProductStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M4.079 4.36l-.238.117a.64.64 0 00-.161.137.784.784 0 00-.114.23l-.127.437-.13-.437a.815.815 0 00-.114-.23.602.602 0 00-.161-.137l-.241-.117.24-.12a.563.563 0 00.162-.138.75.75 0 00.114-.23l.127-.434.127.434a.563.563 0 00.275.368l.24.12zM7 1.881l-.395.2a.987.987 0 00-.271.227 1.348 1.348 0 00-.19.385l-.213.728-.216-.728a1.447 1.447 0 00-.186-.385 1.004 1.004 0 00-.271-.227l-.403-.2.403-.203a.99.99 0 00.271-.223c.082-.118.145-.248.186-.385l.213-.725.217.725c.041.138.105.268.189.385.075.09.167.167.271.223L7 1.881zm15.166 15.394l-.57.285c-.151.09-.285.207-.395.344-.119.17-.21.358-.268.557l-.31 1.031-.313-1.031a1.98 1.98 0 00-.268-.557 1.371 1.371 0 00-.395-.344l-.578-.285.578-.306c.15-.083.285-.194.395-.327.118-.169.209-.355.268-.553l.31-1.031.313 1.03c.06.198.15.385.268.554.11.133.244.244.395.327l.57.306zM1.84 9.869a.076.076 0 00-.045.098l1.041 2.861c.015.04.058.06.098.046l2.933-1.068-1.093-3.005L1.84 9.87zm-1.173.508c-.24-.662.1-1.395.763-1.636l4.06-1.478 1.915 5.26-4.06 1.478a1.276 1.276 0 01-1.636-.762L.667 10.377z" />
    <path d="M5.566 7.252a.076.076 0 00-.046.098l1.852 5.087c.014.04.058.06.098.046l5.159-1.878-1.904-5.231-5.159 1.878zm-1.173.508c-.241-.662.1-1.395.763-1.636l6.286-2.288 2.725 7.486L7.88 13.61a1.276 1.276 0 01-1.636-.762L4.393 7.76z" />
    <path d="M10.002 3.25L17.488.525l3.419 9.393-7.486 2.724-3.419-9.392zm1.538.717l2.598 7.137 5.23-1.904-2.597-7.137-5.231 1.904z" />
    <path d="M18.907 2.22c-.592-.607-.992-.66-1.157-.6a.6.6 0 01-.411-1.127c.884-.323 1.777.222 2.427.89.693.71 1.33 1.765 1.776 2.989.445 1.223.636 2.44.562 3.43-.07.93-.403 1.92-1.287 2.243a.6.6 0 01-.412-1.127c.166-.06.44-.36.502-1.205.06-.784-.091-1.829-.492-2.93-.403-1.102-.959-2-1.508-2.563zM12.47 16.467a.6.6 0 01.6.6v5.932a.6.6 0 11-1.2 0v-5.932a.6.6 0 01.6-.6zm-1.566-2.307a.6.6 0 01.274.803l-4.083 8.302a.6.6 0 01-1.077-.53l4.083-8.302a.6.6 0 01.803-.273zm3.086.219a.6.6 0 01.804.273l3.975 8.083a.6.6 0 01-1.077.53l-3.975-8.083a.6.6 0 01.274-.803z" />
    <path d="M12.47 12.308a1.234 1.234 0 100 2.47 1.234 1.234 0 000-2.47zm-2.434 1.235a2.434 2.434 0 114.869 0 2.434 2.434 0 01-4.87 0z" />
  </svg>
);
