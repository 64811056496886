import { Box, UnstyledButton } from '@spaceship-fspl/components';
import { FeatherXIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  color,
  margin,
  maxHeight,
  padding,
  themes,
  transition,
} from '@spaceship-fspl/styles';
import { AUTH_NAV_BAR_ZINDEX } from 'components/navigation/authenticated';
import React from 'react';
import styled, {
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} from 'styled-components';

const MODAL_OVERLAY_ZINDEX = AUTH_NAV_BAR_ZINDEX + 100;
const MODAL_ZINDEX = MODAL_OVERLAY_ZINDEX + 1;

export interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  maxWidth?: number;
  display?: 'block' | 'flex';
  scrollable?: boolean;
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  showModal,
  closeModal,
  maxWidth = 400,
  display,
  scrollable,
}) => {
  if (!showModal) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={themes.webapp.onWhite}>
        <StyledOverlay onClick={closeModal} />
        <StyledModalBox
          maxWidth={maxWidth}
          display={display}
          {...(scrollable && { overflow: 'auto' })}
        >
          <StyledCloseButton onClick={closeModal} aria-label="close modal">
            <FeatherXIcon size="md" />
          </StyledCloseButton>

          {children}
        </StyledModalBox>
      </ThemeProvider>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const overlayAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
`;

const StyledOverlay = styled(UnstyledButton)`
  animation: ${overlayAnimation} 0.2s ease-in forwards;
  ${backgroundColor('neutral.100')}
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: ${MODAL_OVERLAY_ZINDEX};
`;

const modalAnimationStartState = `
  opacity: 0;
  transform: translateX(-50%) translateY(50px);
`;

const modalAnimation = keyframes`
  0% {
    ${modalAnimationStartState}
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
`;

const StyledModalBox = styled(Box).attrs({
  backgroundColor: 'neutral.000',
  borderRadius: 'sm',
  boxShadow: 'lg',
  position: 'fixed',
  left: '50%',
  top: { xs: '48px', md: '96px' },
})`
  animation: ${modalAnimation} 0.2s ease-in 0.1s forwards;
  ${modalAnimationStartState}
  ${maxHeight({ xs: `calc(100% - 96px)`, md: `calc(100% - 192px)` })}
  width: calc(100% - 32px);
  z-index: ${MODAL_ZINDEX};
`;

const StyledCloseButton = styled(UnstyledButton)`
  ${transition}
  ${backgroundColor('neutral.030')}
  ${color('neutral.080')}
  ${margin('sm')}
  ${padding('xxxs')}
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  border-radius: 50%;
  line-height: 0;

  :hover {
    ${color('neutral.000')}
    ${backgroundColor('indigo.070')}
  }
`;
