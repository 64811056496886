import { navigate, RouteComponentProps, Router } from '@reach/router';
import { useCanReadSuper, useIsAuthenticated } from '@spaceship-fspl/auth';
import { Box } from '@spaceship-fspl/components';
import { ProductOffering } from '@spaceship-fspl/graphql/src';
import { HEADER_HEIGHT } from 'components/general-header';
import { OnboardingFooter } from 'components/onboarding-footer';
import { useDeepLinking } from 'contexts/deep-linking';
import { GoogleMapsServicesProvider } from 'contexts/googlemaps';
import { MfaRegistrationOnboardingProvider } from 'contexts/mfa-registration-provider';
import {
  CreateUserProvider,
  SignupRequestProvider,
} from 'contexts/saver/onboarding';
import {
  SignupProductIntentProvider,
  useSignupProductIntent,
} from 'contexts/saver/signup-product-offering';
import { SuperOnboardingEmployerContributionProvider } from 'contexts/super/employer-contribution';
import { SuperOnboardingInvestmentOptionProvider } from 'contexts/super/investment-option';
import {
  SuperOnboardingProvider,
  useSuperOnboarding,
} from 'contexts/super/onboarding';
import { MfaRegistration } from 'pages/mfa-registration';
import { MfaTooManyAttempts } from 'pages/mfa-too-many-attempts';
import { SuperMobileApp } from 'pages/mobile-apps';
import { PageNotFound } from 'pages/page-not-found';
import { Routes } from 'pages/routes';
import { SignupContact } from 'pages/signup-contact';
import { SignupDetails } from 'pages/signup-details';
import { SignupProductSelect } from 'pages/signup-product-select';
import { SuperConsolidateOptions } from 'pages/super/consolidate-options';
import { SignupSuperGenderSelection } from 'pages/super/gender-selection';
import { SuperSignupTfn } from 'pages/super/tfn';
import { SuperEmployerContribution } from 'pages/super-contributions/employer';
import { SuperEmployerContributionSuccess } from 'pages/super-contributions/employer-success';
import SuperSelectInvestmentOption from 'pages/super-select-investment-option';
import { SuperSignupReferred } from 'pages/super-signup/referred';
import { SuperOnboardingSuccess } from 'pages/super-signup/success';
import React, { FC, useEffect } from 'react';

import { SuperSignupError } from '../pages/super-signup/error';
import { AuthenticatedRoutes, ScrollToTopOnRouteChange } from './helpers';
import { SuperMatchOnboarding } from './super-match';

export const Signup: FC<React.PropsWithChildren<RouteComponentProps>> = () => {
  return (
    <>
      <ScrollToTopOnRouteChange />
      <SignupProductIntentProvider>
        <SignupRequestProvider>
          <CreateUserProvider>
            <GoogleMapsServicesProvider countryRestrictions="au">
              <Box
                display="flex"
                flexDirection="column"
                minHeight={`calc(100vh - ${HEADER_HEIGHT}px)`}
              >
                <Box flex={1}>
                  <Router primary={false}>
                    <SignupContact path="/" />
                    <SignupDetails path="details" />
                    <AuthenticatedRoutes path="/">
                      <MfaRegistrationOnboardingProvider path="/verify-phone">
                        <MfaRegistration path="/" />
                        <MfaTooManyAttempts path="/too-many-attempts" />
                      </MfaRegistrationOnboardingProvider>
                      <SignupProductSelect path="choose-product" />
                      <VoyagerOnboarding path="voyager/*" />
                      <Super path="super/*" />
                    </AuthenticatedRoutes>
                    <PageNotFound default={true} />
                  </Router>
                </Box>
                <OnboardingFooterRoutes />
              </Box>
            </GoogleMapsServicesProvider>
          </CreateUserProvider>
        </SignupRequestProvider>
      </SignupProductIntentProvider>
    </>
  );
};

const OnboardingFooterRoutes: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => (
  <Router>
    <OnboardingFooter path="/" />
    <OnboardingFooter path="/super" />
    <OnboardingFooter path="/voyager" />
  </Router>
);

const VoyagerOnboarding: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { onSetProduct } = useSignupProductIntent();
  const { storeReferralSearchParams } = useDeepLinking();

  useEffect(() => {
    storeReferralSearchParams(
      new URL(window.location.href).searchParams,
      ProductOffering.VOYAGER,
    );
    onSetProduct(ProductOffering.VOYAGER);
    navigate(Routes.SIGNUP, { replace: true });
  }, [onSetProduct, storeReferralSearchParams]);

  return null;
};

const Super: FC<React.PropsWithChildren<RouteComponentProps>> = () => (
  <SuperOnboardingProvider>
    <Router>
      <SuperSignupReferred path="referred" />
      <SuperSignup path="*" />
      <SuperOnboarding path="onboarding/*" />
      <PageNotFound default={true} />
    </Router>
  </SuperOnboardingProvider>
);

const SuperSignupContact: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { onSetProduct } = useSignupProductIntent();
  const { storeReferralSearchParams } = useDeepLinking();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    storeReferralSearchParams(
      new URL(window.location.href).searchParams,
      ProductOffering.SUPER,
    );

    if (isAuthenticated) {
      navigate(Routes.SUPER_SIGNUP_PORTFOLIO);
      return;
    }

    onSetProduct(ProductOffering.SUPER);
    navigate(Routes.SIGNUP, { replace: true });
  }, [onSetProduct, isAuthenticated, storeReferralSearchParams]);

  return null;
};

const SuperSignup: FC<React.PropsWithChildren<RouteComponentProps>> = () => {
  const { showSuccessFlow } = useSuperOnboarding();
  const canReadSuper = useCanReadSuper();

  useEffect(() => {
    if (canReadSuper && !showSuccessFlow) {
      navigate(Routes.SUPER_DASHBOARD);
    }
  }, [canReadSuper, showSuccessFlow]);

  if (canReadSuper && !showSuccessFlow) {
    return null;
  }

  return (
    <Router>
      <SuperSignupContact path="/" />
      <SuperOnboardingInvestmentOptionProvider path="choose-investment">
        <SuperSelectInvestmentOption path="/" />
      </SuperOnboardingInvestmentOptionProvider>
      <SignupSuperGenderSelection path="gender" />
      <SuperSignupTfn path="tax-file-number" />
      <SuperSignupError path="error" />
      <PageNotFound default={true} />
    </Router>
  );
};

const SuperOnboarding: FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const canReadSuper = useCanReadSuper();
  return (
    <AuthenticatedRoutes path="/">
      <Router>
        {canReadSuper && (
          <>
            <SuperOnboardingSuccess path="success" />
            <SuperConsolidateOptions path="consolidate" isSignup={true} />
            <SuperMatchOnboarding path="supermatch/*" />
            <SuperOnboardingEmployerContributionProvider path="employer-contribution">
              <SuperEmployerContribution path="/" />
              <SuperEmployerContributionSuccess path="success" />
            </SuperOnboardingEmployerContributionProvider>
            <SuperMobileApp path="mobile-apps" />
          </>
        )}
        <PageNotFound default={true} />
      </Router>
    </AuthenticatedRoutes>
  );
};
