import { RouteComponentProps, useNavigate } from '@reach/router';
import { Box, Inline, Spinner, Visible } from '@spaceship-fspl/components';
import { SpaceshipIcon } from '@spaceship-fspl/icons-web';
import { useGetCurrentInvestmentAllocation } from '@spaceship-fspl/super';
import { CenterOnboardingContainer } from 'components/layouts/onboarding';
import { Success } from 'components/layouts/success';
import { Routes } from 'pages/routes';
import React from 'react';

export const SuperLinkSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const resp = useGetCurrentInvestmentAllocation();
  const hasNoPortfolio = !resp.investmentAllocation;

  if (resp.isLoading) {
    return (
      <CenterOnboardingContainer>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Spinner size="lg" />
        </Box>
      </CenterOnboardingContainer>
    );
  }

  return (
    <Success
      isOnboarding={true}
      title={
        <>
          Your Spaceship Super account has{' '}
          <Visible isHidden={{ xs: true, lg: false }}>
            <br />
          </Visible>
          been successfully linked
        </>
      }
      subtitle={
        hasNoPortfolio ? (
          <>
            Now it&apos;s time to choose your Spaceship Super investment option.
          </>
        ) : undefined
      }
      buttonText={
        hasNoPortfolio ? (
          <>Continue</>
        ) : (
          <Inline alignY="center" spaceX="xxs">
            <span>View my dashboard</span>
            <Box marginTop="xxxs">
              <SpaceshipIcon color="neutral.000" size="lg" />
            </Box>
          </Inline>
        )
      }
      onContinue={
        hasNoPortfolio
          ? {
              trackingProperties: {
                name: 'super_link_success_select_portfolio',
              },
              onClick: () => navigate(Routes.SUPER_SELECT_INVESTMENT_OPTION),
            }
          : {
              trackingProperties: {
                name: 'super_link_success_done',
              },
              onClick: () => navigate(Routes.SUPER_DASHBOARD),
            }
      }
    />
  );
};
