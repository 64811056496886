import { gql } from '@apollo/client';
import {
  ActionLink,
  Box,
  Divider,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { webAppVoyagerTaxFileNumberCard_account_saverProductInstances } from '@spaceship-fspl/graphql/src/__generated__/webAppVoyagerTaxFileNumberCard';
import { FeatherHelpCircleIcon } from '@spaceship-fspl/icons-web';
import { TFN_EXEMPTION_CODE_MAP } from '@spaceship-fspl/voyager';
import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from 'components/account-cards/components';
import { RouterLink } from 'components/router-link';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React from 'react';

export const voyagerTaxFileNumberCardFragment = gql`
  fragment webAppVoyagerTaxFileNumberCard on Contact {
    id
    account {
      id
      saverProductInstances {
        id
        portfolio
        tfnProvided
        tfnExemptionCode
      }
    }
  }
`;

export const VoyagerTaxFileNumberCard: React.FC<
  React.PropsWithChildren<{
    productInstances?:
      | webAppVoyagerTaxFileNumberCard_account_saverProductInstances[]
      | null;
  }>
> = ({ productInstances }) => {
  return (
    <AccountCard>
      <AccountCardHeading title="Tax file number" />

      <AccountCardContent>
        <Stack spaceY="md">
          <Stack spaceY="xs">
            <Text variant={2}>
              Providing your tax file details means we won’t have to deduct tax
              from any distribution you earn.
            </Text>

            <RouterLink
              to={Routes.VOYAGER_TFN_LEARN_MORE}
              trackingProperties={{
                name: 'voyager_tax_file_number_card_learn_more',
              }}
            >
              <Inline spaceX="xxs" alignY="center">
                <ActionLink color="indigo.070" size="sm" component="span">
                  Learn more
                </ActionLink>
                <FeatherHelpCircleIcon color="indigo.070" size="md" />
              </Inline>
            </RouterLink>
          </Stack>

          <Stack spaceY="xs">
            {productInstances?.map((p) => {
              const config = voyagerPortfolios[p.portfolio];
              const PortfolioIcon = config.icon.default;

              return (
                <React.Fragment key={p.id}>
                  <Divider />

                  <Box display="flex">
                    <PortfolioIcon color="indigo.070" size="xxl" />

                    <Box
                      display="flex"
                      flex={1}
                      paddingLeft="xs"
                      paddingTop="xxs"
                    >
                      <Box flex={1} marginRight="xs">
                        <Text variant={2} isBold={true}>
                          {config.title}
                        </Text>
                        <Stack spaceY="xxxs">
                          <Text variant={3}>
                            {p.tfnProvided
                              ? 'TFN provided'
                              : p.tfnExemptionCode
                                ? 'TFN exemption provided'
                                : 'TFN not provided'}
                          </Text>
                          {!!p.tfnExemptionCode && (
                            <Text variant={4} color="neutral.085">
                              {TFN_EXEMPTION_CODE_MAP[p.tfnExemptionCode]}
                            </Text>
                          )}
                        </Stack>
                      </Box>

                      <RouterLink
                        to={`${Routes.VOYAGER_TFN}/${p.id}`}
                        aria-label={`tfn update link ${p.portfolio.toLowerCase()}`}
                        trackingProperties={{
                          name: 'voyager_tax_file_number_card_update',
                        }}
                      >
                        <ActionLink
                          color="indigo.070"
                          size="sm"
                          component="span"
                        >
                          Update
                        </ActionLink>
                      </RouterLink>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
          </Stack>
        </Stack>
      </AccountCardContent>
    </AccountCard>
  );
};
