import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Inline,
  Pill,
  Stack,
  Text,
  TextLink,
  UnstyledButton,
} from '@spaceship-fspl/components';
import {
  SuperPortfolio,
  superPortfolioInformationScalars,
  superTmdDetailsScalars,
  SuperTMDResult,
  useSuperPortfolioFlags,
} from '@spaceship-fspl/graphql';
import {
  WebAppSuperSelectInvestmentOption,
  WebAppSuperSelectInvestmentOption_GLOBAL_INDEX,
  WebAppSuperSelectInvestmentOption_GROWTHX,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperSelectInvestmentOption';
import {
  calcInceptionToPerformanceAsAtDateInMonths,
  ExternalRoutes,
  formatDate,
} from '@spaceship-fspl/helpers';
import {
  FeatherChevronDownIcon,
  SuperGlobalIndexStarsIcon,
  SuperGrowthxStarsIcon,
} from '@spaceship-fspl/icons-web';
import {
  fontWeight,
  lineHeight,
  marginBottom,
  paddingLeft,
  transition,
} from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { Error } from 'components/layouts/error';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import { ResourceList } from 'components/resource-list';
import {
  SuperTmdFormModal,
  SuperTmdFormModalRef,
  SuperTmdIneligibleType,
} from 'components/super-tmd-form-modal';
import {
  InvestmentOptionPageVariant,
  useContext,
} from 'contexts/super/investment-option';
import { parse } from 'date-fns';
import { AccessibilityLabel } from 'helpers/accessibility';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { useSftValues } from 'helpers/hooks/use-sft-values';
import { navigateToLogInWhenUnauthenticated } from 'navigation/helpers';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

interface PortfolioForm {
  portfolio?: SuperPortfolio;
  hasReadAllDocuments?: boolean;
}

export const SuperSelectInvestmentOption: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const superTmdFormModalRef = useRef<SuperTmdFormModalRef>(null);
  const { variant, portfolio, onSelectPortfolio, onTMDEligible, isLoading } =
    useContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [tmdIneligibleType, setTmdIneligibleType] = useState<
    undefined | SuperTmdIneligibleType
  >();
  const Container =
    variant === 'onboarding' || variant === 'add'
      ? OnboardingContainer
      : PageContainer;

  const flagsData = useSuperPortfolioFlags();

  const form = useForm<Partial<PortfolioForm>>({
    shouldFocusError: true,
    mode: 'onBlur',
  });
  const { reset } = form;

  const resp = useQuery<WebAppSuperSelectInvestmentOption>(gql`
    query WebAppSuperSelectInvestmentOption {
      contact {
        id
        account {
          id
          globalIndexTmdDetails: superTMDDetails(portfolio: GLOBAL_INDEX) {
            ...superTmdDetailsFields
          }
          growthXTmdDetails: superTMDDetails(portfolio: GROWTHX) {
            ...superTmdDetailsFields
          }
        }
      }
      GLOBAL_INDEX: superPortfolioInformation(portfolio: GLOBAL_INDEX) {
        id
        ...superPortfolioInformationFields
      }
      GROWTHX: superPortfolioInformation(portfolio: GROWTHX) {
        id
        ...superPortfolioInformationFields
      }
    }
    ${superTmdDetailsScalars}
    ${superPortfolioInformationScalars}
  `);

  useEffect(() => {
    const defaultPortfolio = portfolio || SuperPortfolio.GROWTHX;

    reset({
      portfolio: defaultPortfolio,
    });
  }, [reset, portfolio]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tmdIneligibleType]);

  const onSubmit = async (values: PortfolioForm): Promise<void> => {
    const { portfolio } = values;
    const details =
      portfolio === SuperPortfolio.GLOBAL_INDEX
        ? resp.data?.contact.account?.globalIndexTmdDetails
        : resp.data?.contact.account?.growthXTmdDetails;

    if (!portfolio) {
      return;
    }

    switch (true) {
      case variant === 'change':
        await onSelectPortfolio(portfolio);
        return;

      case details?.result === SuperTMDResult.OK:
        await onTMDEligible?.(portfolio);
        return;

      case !details?.submissionAvailable:
        setTmdIneligibleType('NotAvailable');
        return;

      default:
        superTmdFormModalRef.current?.showModal({
          portfolio,
          tmdResult: details?.result,
        });
        return;
    }
  };
  const superFlagsResp = useSuperPortfolioFlags();
  const { sftOneSuperGoLiveDateFormatted } = useSftValues();
  const sftOneSuperActive =
    superFlagsResp.data?.superPortfolioFlags?.sftOneSuperActive;
  const selectedPortfolio = form.watch('portfolio');
  const portfolios = {
    [SuperPortfolio.GROWTHX]: resp.data?.GROWTHX,
    [SuperPortfolio.GLOBAL_INDEX]: resp.data?.GLOBAL_INDEX,
  };
  const selectedInfo = selectedPortfolio ? portfolios[selectedPortfolio] : null;

  const superPortfolioFlags = flagsData.data?.superPortfolioFlags;
  const sftLimitedServicePeriodEnabled =
    superPortfolioFlags?.sftLimitedServicePeriodEnabled;

  if (
    sftLimitedServicePeriodEnabled &&
    superFlagsResp.data?.superPortfolioFlags
      ?.sftLimitedServicePeriodDisclaimerLink &&
    superPortfolioFlags.sftLimitedServicePeriodDisclaimer
  ) {
    return (
      <Error
        title="Spaceship Super is currently unavailable to new members"
        subtitle={
          <>
            Spaceship Super is being transferred to a new sub-plan of OneSuper.
            While this transfer takes place, we are unable to open new accounts.
            We will contact you on your email after{' '}
            {sftOneSuperGoLiveDateFormatted}. You can read more about the
            transfer{' '}
            <TextLink
              color="indigo.070"
              target="_blank"
              rel="noreferrer"
              href={
                superFlagsResp.data?.superPortfolioFlags
                  ?.sftLimitedServicePeriodDisclaimerLink
              }
            >
              here
            </TextLink>
          </>
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'super_onboarding_not_available_go_back',
          },
        }}
      />
    );
  }

  const createProductInstanceAvailable =
    superPortfolioFlags?.createProductInstanceAvailable ?? true;
  if (!createProductInstanceAvailable) {
    return (
      <Error
        title={
          variant === 'onboarding' || variant === 'add'
            ? 'Choose your investment option'
            : 'Change your investment option'
        }
        subtitle={
          superPortfolioFlags?.createProductInstanceNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'super_onboarding_not_available_go_back',
          },
        }}
      />
    );
  }

  if (tmdIneligibleType === 'NotOk') {
    return (
      <PageContainer>
        <Stack spaceY="xxxl">
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
              <Stack spaceY="lg">
                <Stack spaceY="sm">
                  <Heading
                    component="h1"
                    variant={3}
                    align={{ xs: 'left', md: 'center' }}
                  >
                    Hmm...
                  </Heading>

                  <Text variant={2}>
                    It doesn’t look like you’re in the target market for
                    Spaceship Super and this product may not meet your needs.
                  </Text>

                  <Text variant={2}>
                    It’s worth reviewing our Product Disclosure Statement and
                    Target Market Determination to understand who Spaceship
                    Super is designed for, the potential returns and the level
                    of investment risk.
                  </Text>

                  <Text variant={2}>
                    You also may find it useful to speak to a financial adviser.
                  </Text>

                  <Text variant={2}>
                    If your investment objectives, financial situation or needs
                    change you’re welcome to come back at a later date.
                  </Text>
                </Stack>

                <Box>
                  <Divider color="neutral.050" />
                  <ResourceList
                    resources={[
                      {
                        label: 'Product Disclosure Statement',
                        link: ExternalRoutes.SUPER_PDS,
                        indicateWillOpenNewTab: true,
                      },
                      {
                        label: 'Target Market Determination Statement',
                        link: ExternalRoutes.SUPER_TMD,
                        indicateWillOpenNewTab: true,
                      },
                    ]}
                  />
                  <Divider color="neutral.050" />
                </Box>

                <PageFormButtonContainer>
                  <Button
                    aria-label={AccessibilityLabel.CONTINUE}
                    type="button"
                    variant="primary"
                    size="lg"
                    trackingProperties={{
                      name: 'super_tmd_ineligible_not_ok_explore_button',
                    }}
                    onClick={() => setTmdIneligibleType(undefined)}
                  >
                    Explore other products
                  </Button>
                </PageFormButtonContainer>
              </Stack>
            </Columns.Column>
          </Columns>
        </Stack>
      </PageContainer>
    );
  }

  if (tmdIneligibleType === 'NotAvailable') {
    return (
      <PageContainer>
        <Stack spaceY="xxxl">
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
              <Stack spaceY="lg">
                <Stack spaceY="sm">
                  <Heading
                    component="h1"
                    variant={3}
                    align={{ xs: 'left', md: 'center' }}
                  >
                    Hmm...
                  </Heading>

                  <Text variant={2}>
                    We understand your interest in this product and we
                    appreciate your enthusiasm. However, we have a
                    responsibility to ensure that our products are suitable for
                    our target market.
                  </Text>

                  <Text variant={2}>
                    Based on the information you’ve provided in previous
                    attempts, it seems that you may not align with our target
                    market.
                  </Text>

                  <Text variant={2}>
                    We suggest you read our PDS and TMD again. It could also be
                    beneficial for you to speak with a financial adviser. If
                    your circumstances change in the future, you’re welcome to
                    come back.
                  </Text>
                </Stack>

                <Box>
                  <Divider color="neutral.050" />
                  <ResourceList
                    resources={[
                      {
                        label: 'Product Disclosure Statement',
                        link: ExternalRoutes.SUPER_PDS,
                        indicateWillOpenNewTab: true,
                      },
                      {
                        label: 'Target Market Determination Statement',
                        link: ExternalRoutes.SUPER_TMD,
                        indicateWillOpenNewTab: true,
                      },
                    ]}
                  />
                  <Divider color="neutral.050" />
                </Box>

                <PageFormButtonContainer>
                  <Button
                    aria-label={AccessibilityLabel.CONTINUE}
                    type="button"
                    variant="primary"
                    size="lg"
                    trackingProperties={{
                      name: 'super_tmd_ineligible_not_available_explore_button',
                    }}
                    onClick={() => setTmdIneligibleType(undefined)}
                  >
                    Explore other products
                  </Button>
                </PageFormButtonContainer>
              </Stack>
            </Columns.Column>
          </Columns>
        </Stack>
      </PageContainer>
    );
  }

  return (
    <>
      <Container>
        <Stack spaceY={{ xs: 'md', md: 'xxl' }} alignX="center">
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Columns alignX="center">
                <Columns.Column width={{ xs: 1, lg: 8 / 10, xl: 8 / 12 }}>
                  <Columns spaceY={{ xs: 'lg', md: 'xl' }}>
                    <Columns.Column>
                      <Columns alignX="center">
                        <Columns.Column width={{ xs: 1, lg: 25 / 32 }}>
                          <PageHeading
                            title={
                              variant === 'onboarding' || variant === 'add'
                                ? 'Choose your investment option'
                                : 'Change your investment option'
                            }
                            subtitle="At Spaceship, we think in decades, not days – and we believe your super should, too. Our investment options allow you to invest for tomorrow, today."
                          />
                        </Columns.Column>
                      </Columns>
                    </Columns.Column>

                    <Columns.Column>
                      <Columns
                        spaceX={{ xs: 'none', md: 'sm' }}
                        spaceY={{ xs: 'md', md: 'none' }}
                      >
                        {Object.values(SuperPortfolio).map((portfolioKey) => {
                          const info = portfolios[portfolioKey];
                          if (!info) {
                            return null;
                          }

                          return (
                            <Columns.Column
                              width={{ xs: 1, md: 1 / 2 }}
                              key={portfolioKey}
                            >
                              <RadioCard
                                value={portfolioKey}
                                error={!!form.formState.errors.portfolio}
                                contentPadding={{
                                  paddingBottom: { xs: 'xs', md: 'sm' },
                                }}
                                {...form.register('portfolio')}
                              >
                                <PortfolioContent
                                  portfolioKey={portfolioKey}
                                  isCurrent={
                                    variant === 'change' &&
                                    portfolioKey === portfolio
                                  }
                                  isExpanded={isExpanded}
                                  toggleExpand={() =>
                                    setIsExpanded(!isExpanded)
                                  }
                                  info={info}
                                />
                              </RadioCard>
                            </Columns.Column>
                          );
                        })}
                      </Columns>
                    </Columns.Column>

                    <Columns.Column>
                      <Columns alignX="center">
                        <Columns.Column width={{ xs: 1, lg: 6 / 8 }}>
                          <Stack spaceY="md">
                            <Heading variant={4} isBold={true} component="h3">
                              Please read the important documents below
                              {variant === 'onboarding' &&
                                ' before joining Spaceship Super'}
                              :
                            </Heading>
                            <div>
                              <Divider color="neutral.050" />
                              <ResourceList
                                isSuperResource={true}
                                resources={[
                                  {
                                    label: 'Product Disclosure Statement',
                                    link: ExternalRoutes.SUPER_PDS,
                                    indicateWillOpenNewTab: true,
                                  },
                                  {
                                    label: 'Reference Guide',
                                    link: ExternalRoutes.SUPER_REFERENCE_GUIDE,
                                    indicateWillOpenNewTab: true,
                                  },
                                  {
                                    label: 'Target Market Determination',
                                    link: ExternalRoutes.SUPER_TMD,
                                    indicateWillOpenNewTab: true,
                                  },
                                ]}
                              />
                              <Divider color="neutral.050" />
                            </div>
                          </Stack>
                        </Columns.Column>
                      </Columns>
                    </Columns.Column>

                    {selectedInfo && (
                      <>
                        {(variant === 'onboarding' || variant === 'add') && (
                          <OnboardingTerms
                            title={selectedInfo.title}
                            variant={variant}
                          />
                        )}
                        {variant === 'change' && <ChangeTerms />}
                      </>
                    )}
                  </Columns>

                  {selectedInfo && (
                    <PageFormButtonContainer>
                      <PageFormContinueButton
                        trackingProperties={{
                          name: 'super_save_investment_option',
                          variant,
                        }}
                        isLoading={isLoading}
                      />
                    </PageFormButtonContainer>
                  )}
                </Columns.Column>
              </Columns>
            </form>
          </FormProvider>

          <Box marginBottom="sm">
            {sftOneSuperActive ? (
              <Text variant={4} color="neutral.085">
                Spaceship Super is issued by Diversa Trustees Limited as trustee
                of OneSuper.
              </Text>
            ) : (
              <Text variant={4} color="neutral.085">
                Spaceship Super is issued by Diversa Trustees Limited as trustee
                of the Tidswell Master Superannuation Plan.
              </Text>
            )}
          </Box>
        </Stack>
      </Container>

      {onTMDEligible && (
        <SuperTmdFormModal
          ref={superTmdFormModalRef}
          isLoading={isLoading}
          onTMDEligible={onTMDEligible}
          onTMDIneligible={(ineligibleType) => {
            setTmdIneligibleType(ineligibleType);
            superTmdFormModalRef.current?.closeModal();
          }}
        />
      )}
    </>
  );
};

interface PortfolioContentProps {
  portfolioKey: SuperPortfolio;
  isCurrent: boolean;
  isExpanded: boolean;
  toggleExpand: () => void;
  info:
    | WebAppSuperSelectInvestmentOption_GLOBAL_INDEX
    | WebAppSuperSelectInvestmentOption_GROWTHX;
}

const PortfolioContent: React.FC<
  React.PropsWithChildren<PortfolioContentProps>
> = ({ isCurrent, portfolioKey, isExpanded, toggleExpand, info }) => {
  const performanceAsAtDate = info?.performanceAsAt
    ? parse(info.performanceAsAt, 'd MMMM yyyy', new Date())
    : undefined;
  const inceptionDate = info?.inceptionDate
    ? parse(info.inceptionDate, 'd MMMM yyyy', new Date())
    : undefined;
  const annualisedPerformanceSinceInception =
    info?.sinceInceptionPerformancePercentage &&
    performanceAsAtDate &&
    inceptionDate
      ? `${formatDate(inceptionDate, 'dd MMMM yyyy')} to ${formatDate(
          performanceAsAtDate,
          'dd MMMM yyyy',
        )} (${calcInceptionToPerformanceAsAtDateInMonths(
          inceptionDate,
          performanceAsAtDate,
        )} months)`
      : '';
  const superFlagsResp = useSuperPortfolioFlags();
  const sftOneSuperActive =
    superFlagsResp.data?.superPortfolioFlags?.sftOneSuperActive;
  return (
    <Box paddingY="sm" aria-label={portfolioKey}>
      <Stack spaceY="sm">
        <Box display="flex" justifyContent="space-between">
          {portfolioKey === SuperPortfolio.GLOBAL_INDEX ? (
            <SuperGlobalIndexStarsIcon color="indigo.070" size="xxxl" />
          ) : (
            <SuperGrowthxStarsIcon color="indigo.070" size="xxxl" />
          )}
          {isCurrent && (
            <Box marginTop="md">
              <Pill variant="info">CURRENT OPTION</Pill>
            </Box>
          )}
        </Box>

        <Stack spaceY="xxs">
          <Heading variant={4} isBold={true} component="h3">
            {info.name}
          </Heading>

          <Text variant={3}>{info.description}</Text>
        </Stack>

        <Divider />

        <Stack spaceY="xs">
          <div>
            <Heading variant={5} isBold={true}>
              Investment performance
            </Heading>
            <Text variant={4} color="neutral.080">
              As at {info.performanceAsAt}
            </Text>
          </div>
          <Stack spaceY="xxs">
            <Box display="flex" justifyContent="space-between">
              <Text variant={3}>1 year performance</Text>
              <InfoValue>{info.oneYearPerformancePercentage}%</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Text variant={3}>Annualised performance since inception</Text>
              <InfoValue>
                {info.sinceInceptionPerformancePercentage}% pa
              </InfoValue>
            </Box>
            <Text variant={4} color="neutral.080">
              {annualisedPerformanceSinceInception}
            </Text>
          </Stack>
          {sftOneSuperActive ? (
            <Text variant={4} color="neutral.080" isItalic={true}>
              Past performance is not a reliable indicator of future
              performance.
            </Text>
          ) : (
            <Text variant={4} color="neutral.080" isItalic={true}>
              Returns are net of investment and administration fees and taxes
              but do not include the impact of the administration fees that are
              deducted from your account balance. These returns are not a
              projection. Actual returns may differ, and can be positive or
              negative. Past performance is not a guide to, or reliable
              indicator of, future performance.
            </Text>
          )}
        </Stack>

        <Divider />

        {isExpanded && (
          <>
            <Stack spaceY="xs">
              <div>
                <Heading variant={5} isBold={true}>
                  Standard risk measure
                </Heading>
              </div>
              <Stack spaceY="xxs">
                <Box display="flex" justifyContent="space-between">
                  <Text variant={3}>Level of investment risk</Text>
                  <InfoValue>{info.investmentRiskLevel}</InfoValue>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Text variant={3}>Expected return and risk profile</Text>
                  <InfoValue>{info.riskProfile}</InfoValue>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Text variant={3}>
                    Estimated number of negative annual returns over any 20 year
                    period
                  </Text>
                  <InfoValue>
                    {info.estimatedNegativeAnnualReturnsOver20Years}
                  </InfoValue>
                </Box>
              </Stack>
            </Stack>

            <Divider />

            <Stack spaceY="xs">
              <div>
                <Heading variant={5} isBold={true}>
                  Fees
                </Heading>
              </div>
              <Stack spaceY="xxs">
                <Box display="flex" justifyContent="space-between">
                  <Text variant={3}>Investment fees and cost</Text>
                  <InfoValue>
                    {info.investmentFee ? `${info.investmentFee}% pa` : 'Nil'}
                  </InfoValue>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Text variant={3}>Administration fees and cost</Text>
                  <InfoValue>
                    ${info.audAdminFeeBase} + {info.adminFeePercent}% pa
                  </InfoValue>
                </Box>

                <Text variant={4} color="neutral.080" isItalic={true}>
                  The above are fees and costs that are charged to you. There
                  may be other fees and costs that do not impact your account
                  but are required to be disclosed. Certain activity fees may
                  also apply. You can find more information on Spaceship Super
                  fees and costs by reading the Product Disclosure Statement and
                  the Reference Guide.
                </Text>
              </Stack>
            </Stack>

            <Divider />

            <Stack spaceY="xs">
              <div>
                <Heading variant={5} isBold={true}>
                  Strategic asset allocation
                </Heading>
              </div>
              <Stack spaceY="xxs">
                {info.strategicAssetAllocations.map((allocation) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    key={allocation.name}
                  >
                    <Text variant={3}>{allocation.name}</Text>
                    <InfoValue>{allocation.percentage}%</InfoValue>
                  </Box>
                ))}
              </Stack>
            </Stack>

            <Divider />
          </>
        )}

        <Box display="flex" justifyContent="center">
          <Box width="fit-content">
            <UnstyledButton type="button" onClick={toggleExpand}>
              <Inline spaceX="xxxs" alignY="center">
                <Text variant={3} isBold={true} color="indigo.070">
                  See {isExpanded ? 'less' : 'more'}
                </Text>
                <StyledSeeMoreIconContainer isExpanded={isExpanded}>
                  <FeatherChevronDownIcon color="indigo.070" size="sm" />
                </StyledSeeMoreIconContainer>
              </Inline>
            </UnstyledButton>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const OnboardingTerms: React.FC<
  React.PropsWithChildren<{
    title: string;
    variant?: InvestmentOptionPageVariant;
  }>
> = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Columns.Column>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 6 / 8 }}>
          <Stack spaceY="sm">
            <Checkbox
              aria-label={
                AccessibilityLabel.SUPER_INVESTMENT_OPTION_CONSENT_CHECKBOX
              }
              errorMessage={errors.hasReadAllDocuments?.message as string}
              {...register('hasReadAllDocuments', {
                required: 'This checkbox is required.',
              })}
            >
              <StyledCheckBoxText>
                By clicking &ldquo;Continue&rdquo; you confirm:
              </StyledCheckBoxText>
            </Checkbox>

            <OnboardingTermUnorderedList>
              <OnboardingTermsList>
                <Text variant={2}>
                  You direct the Trustee to invest your contributions into your
                  selected investment option (the{' '}
                  <BoldText>{props.title}</BoldText>).
                </Text>
              </OnboardingTermsList>
              <OnboardingTermsList>
                <Text variant={2}>
                  You{' '}
                  {props.variant === 'onboarding' &&
                    'want to join the Spaceship Super fund and that you '}
                  have read, understood and accepted the important documents
                  above.
                </Text>
              </OnboardingTermsList>
            </OnboardingTermUnorderedList>
          </Stack>
        </Columns.Column>
      </Columns>
    </Columns.Column>
  );
};

const ChangeTerms: React.FC = () => (
  <Columns.Column>
    <Columns alignX="center">
      <Columns.Column width={{ xs: 1, lg: 6 / 8 }}>
        <Stack spaceY="sm">
          <Text variant={2}>
            Switching investment options may incur certain fees and costs. Refer
            to the{' '}
            <TextLink
              color="indigo.070"
              href={ExternalRoutes.SUPER_REFERENCE_GUIDE}
              target="_blank"
              rel="noreferrer"
            >
              Reference Guide
            </TextLink>{' '}
            for more information.
          </Text>
          <Text variant={2}>
            If a switch request is received prior to midnight Sydney time on a
            business day, you will receive the unit price for that day. You’ll
            generally be able to see your new investment option within two to
            three business days.{' '}
          </Text>
          <Text variant={2}>
            <BoldText>By clicking ‘Continue’</BoldText> I direct the Trustee to
            switch my current assets and invest all future monies received into
            my selected investment option.
          </Text>
        </Stack>
      </Columns.Column>
    </Columns>
  </Columns.Column>
);

const InfoValue = styled(Text).attrs({
  variant: 3,
  isBold: true,
  align: 'right',
})`
  min-width: 40%;
  ${paddingLeft('xxxs')};
`;

const OnboardingTermUnorderedList = styled.ul`
  margin: 0;
  ${paddingLeft('lg')};
`;

const OnboardingTermsList = styled.li`
  ${marginBottom('sm')};
`;

const BoldText = styled.span`
  ${fontWeight('bold')};
`;

const StyledCheckBoxText = styled(Heading).attrs({
  variant: 4,
  isBold: true,
})`
  ${lineHeight('20px')}
`;

export const StyledSeeMoreIconContainer = styled.div<{ isExpanded: boolean }>`
  ${transition}
  line-height: 0;
  transform: rotate(${({ isExpanded }) => (isExpanded ? 180 : 0)}deg);
`;

export default navigateToLogInWhenUnauthenticated()(
  SuperSelectInvestmentOption,
);
