import { HttpStatusCode } from 'helpers/status-codes';

export const GENERIC_ERROR_MESSAGE =
  "Sorry, we've run into a problem. Please try again or contact support.";
export const PORTFOLIO_PERFORMANCE =
  'Oops, there was a problem loading portfolio performance info.';
export const UPDATING_PRIMARY_PORTFOLIO =
  'Oops, there was a problem updating your primary portfolio.';
export const VOYAGER_DEPOSIT_LIMIT_EXCEEDED =
  'Oops, you’ve exceeded the number of allowed daily investments. Please try again tomorrow.';

interface ErrorData {
  [k: string]: string;
}

export class ApiError extends Error {
  public httpStatusCode: HttpStatusCode;
  public data?: ErrorData;

  constructor(httpStatusCode: HttpStatusCode, data?: ErrorData) {
    const message = data && data.message ? data.message : undefined;
    super(message);

    this.httpStatusCode = httpStatusCode;
    this.data = data;
  }
}
