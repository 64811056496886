import React from 'react';
import {
  Control,
  Controller,
  ControllerProps as RHFControllerProps,
  FieldValues,
  get,
  Path,
} from 'react-hook-form';

import { Input, InputProps } from './input';
import { InputWithInlineError } from './input-with-inline-error';
import { PasswordInput } from './password-input';
import { TextArea, TextAreaProps } from './text-area';

interface ControllerProps<T extends FieldValues>
  extends Omit<RHFControllerProps, 'render' | 'control' | 'name'> {
  required?: boolean;
  control: Control<T>;
  name: Path<T>;
}

export function ControllerInput<T extends FieldValues>({
  name,
  label,
  defaultValue,
  control,
  rules,
  onBlur,
  ...props
}: ControllerProps<T> &
  Omit<InputProps, 'errorMessage' | 'onChange'>): React.ReactElement {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control as Control<FieldValues>}
      render={({
        field: { onBlur: onBlurField, value, onChange },
        formState: { errors },
      }) => {
        return (
          <Input
            {...props}
            name={name}
            label={label}
            errorMessage={get(errors, name)?.message}
            onChange={onChange}
            onBlur={(e) => {
              onBlur?.(e);
              onBlurField();
            }}
            defaultValue={defaultValue}
            value={value}
          />
        );
      }}
    />
  );
}

ControllerInput.displayName = 'ControllerInput';

export function ControllerPasswordInput<T extends FieldValues>({
  name,
  label,
  defaultValue,
  control,
  rules,
  required,
  onBlur,
  ...props
}: ControllerProps<T> &
  Omit<InputProps, 'type' | 'placeholder' | 'errorMessage' | 'onChange'> & {
    placeholder?: string;
  }): React.ReactElement {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control as Control<FieldValues>}
      render={({
        field: { onBlur, value, onChange },
        formState: { errors },
      }) => {
        return (
          <PasswordInput
            name={name}
            label={label}
            errorMessage={get(errors, name)?.message}
            onChange={onChange}
            defaultValue={defaultValue}
            onBlur={onBlur}
            value={value}
            {...props}
          />
        );
      }}
    />
  );
}

ControllerPasswordInput.displayName = 'ControllerPasswordInput';

export function ControllerInputWithInlineError<T extends FieldValues>({
  name,
  label,
  defaultValue,
  control,
  rules,
  onBlur,
  ...props
}: ControllerProps<T> &
  Omit<InputProps, 'errorMessage' | 'onChange'>): React.ReactElement {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control as Control<FieldValues>}
      render={({
        field: { onBlur: onBlurField, value, onChange },
        formState: { errors },
      }) => {
        return (
          <InputWithInlineError
            {...props}
            name={name}
            label={label}
            errorMessage={get(errors, name)?.message}
            onChange={onChange}
            onBlur={(e) => {
              onBlur?.(e);
              onBlurField();
            }}
            defaultValue={defaultValue}
            value={value}
          />
        );
      }}
    />
  );
}

ControllerInputWithInlineError.displayName = 'ControllerInputWithInlineError';

export function ControllerTextArea<T extends FieldValues>({
  name,
  label,
  defaultValue,
  control,
  rules,
  onBlur,
  showErrors = true,
  ...props
}: ControllerProps<T> &
  Omit<TextAreaProps, 'errorMessage' | 'onChange'> & {
    showErrors?: boolean;
  }): React.ReactElement {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control as Control<FieldValues>}
      render={({
        field: { onBlur: onBlurField, value, onChange },
        formState: { errors },
      }) => {
        return (
          <TextArea
            {...props}
            name={name}
            label={label}
            errorMessage={showErrors && get(errors, name)?.message}
            onChange={onChange}
            onBlur={(e) => {
              onBlur?.(e);
              onBlurField();
            }}
            defaultValue={defaultValue}
            value={value}
          />
        );
      }}
    />
  );
}

ControllerTextArea.displayName = 'ControllerTextArea';
