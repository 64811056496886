import {
  createAlignItems,
  createDisplay,
  createFlex,
  createFlexDirection,
  createFlexGrow,
  createFlexShrink,
  createJustifyContent,
  createMaxHeight,
  createMaxWidth,
  createMinHeight,
  createMinWidth,
  createWidth,
  MixinFunction,
} from '@spaceship-fspl/substance-mixin';
import { ResponsiveMixinFunction } from '@spaceship-fspl/substance-style';

import { map, Media } from './media';
import { Space, spaces } from './space';

export const display = createDisplay({ map });

export const width = createWidth({ map });
export const maxWidth = createMaxWidth({ map });
export const minWidth = createMinWidth({ map });

export type Height = Space | string | number;
export const height: ResponsiveMixinFunction<Media, Height> = (value) =>
  map(
    value,
    (index) =>
      `height: ${
        spaces[index as Space] || (isNaN(Number(index)) ? index : `${index}px`)
      };`,
  );

export const maxHeight = createMaxHeight({ map });
export const minHeight = createMinHeight({ map });

export const flex = createFlex({ map });
export const flexDirection = createFlexDirection({ map });
export const flexGrow = createFlexGrow({ map });
export const flexShrink = createFlexShrink({ map });
export const alignItems = createAlignItems({ map });
export const justifyContent = createJustifyContent({ map });

export const overflow: MixinFunction<Media, 'overflow'> = (overflow) =>
  map(overflow, (value) => `overflow: ${value};`);

export const position: MixinFunction<Media, 'position'> = (position) =>
  map(position, (value) => `position: ${value};`);

export const top: MixinFunction<Media, 'top'> = (top) =>
  map(top, (value) => `top: ${value};`);

export const bottom: MixinFunction<Media, 'bottom'> = (bottom) =>
  map(bottom, (value) => `bottom: ${value};`);

export const left: MixinFunction<Media, 'left'> = (left) =>
  map(left, (value) => `left: ${value};`);

export const right: MixinFunction<Media, 'right'> = (right) =>
  map(right, (value) => `right: ${value};`);
