import { validateEmail } from '@spaceship-fspl/helpers';
import {
  FeatherArrowRightIcon,
  FeatherCheckIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  color,
  getColor,
} from '@spaceship-fspl/styles';
import { TrackingAction, useTracking } from '@spaceship-fspl/tracking';
import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../box';
import { Button } from '../buttons';
import { Text } from '../text';
import { TextInput, TextInputVariant } from '../text-input';

export interface Props {
  id: string;
  variant: TextInputVariant;
  onSubscribe?: (emailAddress: string) => void;
}

const StyledButton = styled(Button)`
  ${color('neutral.000')}
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;

  :disabled {
    background: none;
    ${backgroundColor('neutral.085')}
    ${color('neutral.080')}
  }
`;

const SubmittedWrapper = styled(Box)`
  border: 2px solid ${getColor('neutral.085')};
  ${borderRadius('xxs')}
  margin-left: auto;
  margin-right: auto;
`;

export const NewsletterSubscribe: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  variant,
  onSubscribe,
  ...otherProps
}: Props) => {
  const { identify, track } = useTracking();
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<string | undefined>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    if (validateEmail(value) !== true && value !== '') {
      setError('Email address invalid');
    } else {
      setError(undefined);
    }

    setEmail(value);
  };

  const handleOnSubmit = (e: React.SyntheticEvent): void => {
    if (error || !email) {
      e.preventDefault();
      track({
        action: TrackingAction.Submit,
        label: 'invalid',
      });
      return;
    }
    // Anonymous identify
    identify(undefined, {
      properties: { email },
    });
    track({
      action: TrackingAction.Submit,
      label: 'valid',
    });
    setIsSubmitted(true);
    onSubscribe?.(email);
  };

  const isInvalid = !!error;

  return isSubmitted ? (
    <SubmittedWrapper
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="64px"
      maxWidth={{ md: '368px' }}
      padding="md"
    >
      <Box marginRight="xxxs">
        <FeatherCheckIcon size="lg" color="mint.030" />
      </Box>
      <Text variant={2} color="mint.030">
        Thanks for submitting!
      </Text>
    </SubmittedWrapper>
  ) : (
    <Box
      {...otherProps}
      maxWidth={{ md: '368px' }}
      as="form"
      onSubmit={handleOnSubmit}
    >
      <TextInput
        id={`${id}-email-input`}
        variant={variant}
        label="Email address"
        onChange={onChange}
        value={email}
        errorMessage={error}
        after={
          <StyledButton
            variant="primary"
            size="sm"
            isDisabled={isInvalid}
            aria-label="Subscribe"
            type="submit"
          >
            <FeatherArrowRightIcon size="lg" />
          </StyledButton>
        }
      />
    </Box>
  );
};
