import { navigate } from '@reach/router';
import { Box, Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  DATE_FORMAT_TRANSACTIONS_SHORT,
  enumToString,
  formatDate,
} from '@spaceship-fspl/helpers';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { CenterPageContainer } from 'components/layouts/page';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React from 'react';

import { AnimatedTransactionItem } from './animated-transaction-item';

type VoyagerTransactionVariant = 'invest' | 'withdraw';

export interface VoyagerTransactionSuccessProps {
  variant: VoyagerTransactionVariant;
  productId: string;
  amount: string;
  etaDate?: Date;
  frequency?: SchedulerFrequency.Enum;
}

export const VoyagerTransactionSuccess: React.FC<
  React.PropsWithChildren<VoyagerTransactionSuccessProps>
> = ({
  variant,
  productId,
  amount,
  etaDate,
  frequency = SchedulerFrequency.Enum.ONE_TIME,
}) => {
  const moneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  const formattedLongEtaDate = etaDate
    ? formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_LONG)
    : undefined;

  const formattedShortEtaDate = etaDate
    ? formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_SHORT)
    : undefined;

  const isWithdrawing = variant === 'withdraw';

  const isMakingOneOffDeposit =
    variant === 'invest' && frequency === SchedulerFrequency.Enum.ONE_TIME;
  const isInvestmentPlan =
    variant === 'invest' && frequency !== SchedulerFrequency.Enum.ONE_TIME;

  const handleAnimatedTrasnactionItemTitle = (): string => {
    if (isInvestmentPlan) {
      return `Investment plan (${enumToString(
        SchedulerFrequency.Enum,
        frequency,
      ).toLowerCase()})`;
    }
    if (isWithdrawing) {
      return `Withdrawal`;
    }
    return 'Investment';
  };

  return (
    <CenterPageContainer>
      <Box paddingY="xl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <AnimatedTransactionItem
                variant={variant}
                title={handleAnimatedTrasnactionItemTitle()}
                amount={`${isWithdrawing && amount ? '-' : ''}${amount}`}
                etaDate={formattedShortEtaDate}
              />

              <Stack spaceY="sm" alignX="center">
                <Heading variant={3} align="center">
                  {isWithdrawing && 'Your withdrawal has been submitted.'}
                  {isMakingOneOffDeposit && 'Your investment is on its way!'}
                  {isInvestmentPlan && 'Your investment plan is set!'}
                </Heading>

                {formattedLongEtaDate && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    maxWidth={{ md: 420 }}
                  >
                    <Text variant={1} align="center">
                      {isWithdrawing &&
                        !moneyDayOneEnabled &&
                        'The ETA of your funds in your bank account is '}
                      {isWithdrawing &&
                        moneyDayOneEnabled &&
                        'The ETA of your withdrawal to your Spaceship account is '}
                      {(isMakingOneOffDeposit || isInvestmentPlan) &&
                        'The ETA of your units is '}
                      <strong>{formattedLongEtaDate}</strong>.
                    </Text>
                  </Box>
                )}
              </Stack>

              <Box marginTop="xl">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    console.log('productId', productId);
                    navigate(
                      isMakingOneOffDeposit
                        ? `${Routes.VOYAGER_DASHBOARD}/${productId}`
                        : `${Routes.VOYAGER_TRANSACTIONS}/${productId}`,
                    );
                  }}
                  trackingProperties={{
                    name: 'voyager_transaction_success_button',
                  }}
                >
                  {isMakingOneOffDeposit
                    ? 'View my dashboard'
                    : 'View my transactions'}
                </Button>
              </Box>
            </Box>
          </Columns.Column>
        </Columns>
      </Box>
    </CenterPageContainer>
  );
};
