import {
  Box,
  Stack,
  Text,
  TextLink,
  UnstyledButton,
} from '@spaceship-fspl/components';
import {
  FeatherAlertOctagonIcon,
  FeatherAlertTriangleIcon,
  FeatherCheckCircleIcon,
  FeatherInfoIcon,
  FeatherXIcon,
} from '@spaceship-fspl/icons-web';
import {
  color,
  getSpace,
  IconSize,
  padding,
  transition,
  zIndex,
} from '@spaceship-fspl/styles';
import { config } from 'helpers/config';
import { ApiError, GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

const TOAST_DURATION_MS = config.isRunningTests ? 1000 : 12000;

interface NotificationProps {
  message: string;
  requestId?: string;
  cta?: {
    message: string;
    action(): void;
  };
}

type ToastLevel = 'warning' | 'error' | 'success' | 'info';
interface ToastProps extends NotificationProps {
  level: ToastLevel;
}

interface ErrorMessageMap {
  [c: number]: string;
}

interface NotificationsContext {
  popToast(props: ToastProps): void;
  popBanner(props: NotificationProps): void;
  popApiError(
    error: ApiError,
    map: ErrorMessageMap,
    defaultError?: string,
  ): void;
}

const NotificationsContext = createContext<NotificationsContext | undefined>(
  undefined,
);

interface Notifications {
  toasts: Record<string, ToastProps>;
  activeBanner: NotificationProps | null;
}

export const NotificationsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [{ toasts, activeBanner }, setToastsAndBanner] =
    useState<Notifications>({
      toasts: {},
      activeBanner: null,
    });

  const onDismiss = (message: string): void => {
    setToastsAndBanner(
      ({
        toasts: { [message]: deleted, ...restToasts },
        ...notifications
      }) => ({
        ...notifications,
        toasts: restToasts,
      }),
    );
  };

  const popToast = useCallback((toast: ToastProps): void => {
    setToastsAndBanner((notifications) => {
      if (
        !(notifications.activeBanner && notifications.toasts[toast.message])
      ) {
        return {
          ...notifications,
          toasts: {
            ...notifications.toasts,
            [toast.message]: toast,
          },
        };
      }

      return notifications;
    });
  }, []);

  const popBanner = useCallback((banner: NotificationProps): void => {
    setToastsAndBanner({
      toasts: {},
      activeBanner: banner,
    });
  }, []);

  const hideBanner = (): void =>
    setToastsAndBanner((notifications) => ({
      ...notifications,
      activeBanner: null,
    }));

  const popApiError = (
    error: ApiError,
    map: ErrorMessageMap,
    defaultError?: string,
  ): void => {
    const message =
      map[error.httpStatusCode] || defaultError || GENERIC_ERROR_MESSAGE;

    popToast({
      level: 'error',
      message,
    });
  };

  return (
    <NotificationsContext.Provider value={{ popToast, popBanner, popApiError }}>
      {children}

      <StyledToastContainerBox>
        {Object.entries(toasts).map(([message, { cta, level, requestId }]) => (
          <Toast
            key={message}
            message={message}
            requestId={requestId}
            cta={cta}
            level={level}
            onDismiss={onDismiss}
          />
        ))}
      </StyledToastContainerBox>

      {activeBanner && (
        <StyledBanner>
          <Box
            display="flex"
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent={{ xs: 'flex-start', md: 'center' }}
            padding="sm"
          >
            <Box
              paddingRight="xxs"
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              <FeatherAlertTriangleIcon size="md" color="neutral.100" />
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ md: 'center' }}
            >
              <Text variant={3} color="neutral.100" isBold={true}>
                {activeBanner.message}
              </Text>

              {activeBanner.cta && (
                <Box marginLeft={{ md: 'xs' }}>
                  <Text variant={3} color="neutral.100">
                    <TextLink
                      onClick={activeBanner.cta.action}
                      component="button"
                    >
                      {activeBanner.cta.message}
                    </TextLink>
                  </Text>
                </Box>
              )}
            </Box>

            <StyledBannerCloseButton onClick={hideBanner}>
              <FeatherXIcon size="md" />
            </StyledBannerCloseButton>
          </Box>
        </StyledBanner>
      )}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = (): NotificationsContext => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('Wrap in <NotificationsProvider />');
  }

  return context;
};

const Toast: React.FC<
  React.PropsWithChildren<ToastProps & { onDismiss: (message: string) => void }>
> = ({ message, level, cta, requestId, onDismiss }) => {
  useEffect(() => {
    const id = setTimeout(() => onDismiss?.(message), TOAST_DURATION_MS);
    return () => {
      clearTimeout(id);
    };
  }, [onDismiss, message]);

  return (
    <Box
      key={message}
      backgroundColor="neutral.100"
      boxShadow="lg"
      borderRadius="xxs"
      padding="sm"
      marginBottom="sm"
      minHeight={MIN_HEIGHT}
      width="100%"
    >
      <Stack spaceY="xs">
        <Box display="flex" flex={1} marginRight="xxs">
          <ToastIcon level={level} size="md" />

          <Box marginLeft="xs" flex={1}>
            <Stack spaceY="xs">
              <Text variant={3} color="neutral.000" isBold={true}>
                {message}
              </Text>

              {requestId && (
                <Text variant={4} color="neutral.000" isBold={false}>
                  Ref: {requestId.slice(0, 8)}-{new Date().getTime()}
                </Text>
              )}
            </Stack>
          </Box>
        </Box>

        {cta && (
          <Box display="flex" justifyContent="flex-end">
            <Text variant={3}>
              <TextLink color="neutral.000" onClick={cta.action}>
                {cta.message}
              </TextLink>
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const ToastIcon = ({
  level,
  size,
}: {
  level: ToastLevel;
  size: IconSize;
}): JSX.Element => {
  switch (level) {
    case 'error':
      return <FeatherAlertOctagonIcon size={size} color="red.100" />;
    case 'warning':
      return <FeatherAlertTriangleIcon size={size} color="gold.100" />;
    case 'success':
      return <FeatherCheckCircleIcon size={size} color="mint.050" />;
    default:
      return <FeatherInfoIcon size={size} color="pink.080" />;
  }
};

const MAX_BANNER_WIDTH = 960;
const MIN_HEIGHT = 56;

const StyledToastContainerBox = styled(Box).attrs({
  position: 'fixed',
  left: '50%',
  top: getSpace('lg'),
  paddingX: 'sm',
  width: { xs: '100%', md: 420 },
})`
  ${zIndex('max')}
  transform: translateX(-50%);
`;

const StyledBanner = styled(Box).attrs({
  backgroundColor: 'gold.050',
  borderRadius: 'xxs',
  boxShadow: 'lg',
  minHeight: MIN_HEIGHT,
  width: { xs: `calc(100% - ${getSpace('lg')})`, lg: MAX_BANNER_WIDTH },
  position: 'fixed',
  left: '50%',
  bottom: getSpace('lg'),
})`
  ${zIndex('max')}
  transform: translateX(-50%);
`;

const StyledBannerCloseButton = styled(UnstyledButton)`
  ${color('neutral.100')}
  ${padding('sm')}
  position: absolute;
  right: 0;
  top: 0;
  ${transition}

  :hover {
    ${color('neutral.085')}
  }
`;
