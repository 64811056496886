import * as React from 'react';
export const StreamlineWalking = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M8.092 3.604a2.604 2.604 0 105.207 0 2.604 2.604 0 00-5.207 0v0zM17.69 11.783a2.22 2.22 0 01-1.741-.836A7.637 7.637 0 009.95 8.063 5.957 5.957 0 004 14.015a1.488 1.488 0 102.976 0 2.982 2.982 0 012.232-2.877v.496a16.185 16.185 0 01-2.726 9.003 1.49 1.49 0 102.48 1.65 19.245 19.245 0 002.23-4.552 7.974 7.974 0 013.303 4.198 1.487 1.487 0 102.82-.941 10.949 10.949 0 00-5.38-6.256c.167-1.026.25-2.063.25-3.102v-.03c.557.3 1.047.71 1.441 1.205a5.18 5.18 0 004.067 1.954 1.488 1.488 0 100-2.976l-.003-.004z" />
  </svg>
);
