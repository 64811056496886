import {
  Box,
  Card,
  Columns,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import {
  SaverPortfolio,
  useSaverPortfolioFlags,
} from '@spaceship-fspl/graphql';
import {
  addDays,
  AUSSIE_DATE_FORMAT,
  formatCurrency,
  maskBankAccountNumber,
  sydneyDate,
} from '@spaceship-fspl/helpers';
import { StreamlineCashBulbIcon } from '@spaceship-fspl/icons-web';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import {
  investmentPlanStartDateValidation,
  InvestmentType,
  useInvestmentValidation,
} from '@spaceship-fspl/voyager';
import { Error } from 'components/layouts/error';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageFormSkipButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioButton } from 'components/radio-button';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import {
  enumToPrettyString,
  fromAussieDate,
  toAussieDate,
  toLongDate,
} from 'helpers/format';
import { voyagerPortfolios } from 'helpers/portfolios';
import { commonValidationRules, requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ControllerInput } from './controller-input';

export const PRESET_FREQUENCIES: Array<{
  value: SchedulerFrequency.Enum;
  label: string;
}> = [
  SchedulerFrequency.Enum.WEEKLY,
  SchedulerFrequency.Enum.FORTNIGHTLY,
  SchedulerFrequency.Enum.MONTHLY,
].map((freq) => ({
  label: enumToPrettyString(SchedulerFrequency.Enum, freq),
  value: freq,
}));

export const PRESET_AMOUNTS = ['25', '50', '100'];

export const INVEST_TYPE_OPTIONS = [
  {
    link: Routes.VOYAGER_DEPOSIT,
    label: 'One off',
    value: InvestmentType.ONE_OFF,
  },
  {
    label: 'Investment plan',
    link: Routes.VOYAGER_INVESTMENT_PLAN,
    value: InvestmentType.PLAN,
  },
];

export interface InvestmentPlanForm {
  frequency: string;
  presetAmount: string;
  otherAmount: string;
  startDate: string;
  investmentType?: InvestmentType;
}

export interface VoyagerOnboardingInvestmentPlanProps {
  portfolio?: SaverPortfolio;
  onComplete?: (value: InvestmentPlanForm) => void;
  onSkip?: () => void;
  isSubmitting?: boolean;
  variant: 'onboarding' | 'loggedin';
  isEdit?: boolean;
  onStopPlan?: () => void;
  activeBankAccountNumber?: string;
  isLoading?: boolean;
}

export const defaultFormValues: InvestmentPlanForm = {
  presetAmount: '',
  otherAmount: '',
  startDate: toAussieDate(addDays(sydneyDate(), 3)),
  frequency: PRESET_FREQUENCIES[0]?.label || '',
  investmentType: InvestmentType.PLAN,
};

export const VoyagerInvestmentPlan: React.FC<
  React.PropsWithChildren<VoyagerOnboardingInvestmentPlanProps>
> = ({
  portfolio,
  onComplete,
  onSkip,
  variant,
  isEdit = false,
  onStopPlan,
  activeBankAccountNumber,
  isLoading = false,
  isSubmitting,
}) => {
  const { register, handleSubmit, watch, setValue, control } =
    useFormContext<InvestmentPlanForm>();
  const investmentPlanPushCopy = useString(
    DynamicConfigKeys.INVESTMENT_PLAN_PUSH_COPY,
    'Start by choosing a weekly, fortnightly or monthly investment plan, and Spaceship will do the rest. 🚀',
  );
  const portfolioConfig = portfolio ? voyagerPortfolios[portfolio] : undefined;
  const PortfolioIcon = portfolioConfig?.icon.default ?? undefined;

  const validate = useInvestmentValidation(InvestmentType.PLAN);
  const flagsData = useSaverPortfolioFlags();

  const onSubmit = (value: InvestmentPlanForm): void => {
    onComplete?.(value);
  };

  const presetAmount = watch().presetAmount;
  const otherAmount = watch().otherAmount;
  const formattedAmount =
    otherAmount || (presetAmount ? formatCurrency(presetAmount) : '');
  const frequency = watch().frequency;
  const startDate = watch().startDate;

  useEffect(() => {
    if (otherAmount) {
      setValue('presetAmount', '');
    }
  }, [otherAmount, setValue]);

  useEffect(() => {
    if (presetAmount) {
      setValue('otherAmount', '', { shouldValidate: true });
    }
  }, [presetAmount, setValue]);

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createPaymentScheduleAvailable =
    saverPortfolioFlags?.createPaymentScheduleAvailable ?? true;

  if (!createPaymentScheduleAvailable) {
    return (
      <Error
        title={
          variant === 'onboarding'
            ? 'Would you like to set up an investment plan for this portfolio?'
            : `${isEdit ? 'Edit' : 'Add'} Investment Plan`
        }
        subtitle={
          saverPortfolioFlags?.createPaymentScheduleNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'payment_schedule_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    <Columns alignX="center" spaceY="lg">
      <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 1 / 3 }}>
        <PageHeading
          title={
            variant === 'onboarding'
              ? 'Would you like to set up an investment plan for this portfolio?'
              : `${isEdit ? 'Edit' : 'Add'} Investment Plan`
          }
        />
      </Columns.Column>

      <Columns.Column>
        {isLoading ? (
          <Box
            data-testid="spinner"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner />
          </Box>
        ) : (
          <Columns alignX="center" spaceX="xl" spaceY="md">
            {variant === 'loggedin' && portfolioConfig && (
              <Columns.Column width={{ xs: 1, lg: 2 / 5, xl: 1 / 3 }}>
                <Card borderRadius="sm" padding="md" display="flex">
                  {PortfolioIcon && (
                    <Box paddingRight="xs">
                      <PortfolioIcon color="indigo.070" size="xxl" />
                    </Box>
                  )}

                  <Stack spaceY="xxs">
                    <Text variant={2} isBold={true}>
                      {portfolioConfig.title}
                    </Text>

                    <div>
                      <Text variant={4} color="neutral.080">
                        Amount
                      </Text>
                      <Text variant={3}>
                        {!formattedAmount || !frequency
                          ? '—'
                          : `${formattedAmount} ${frequency.toLocaleLowerCase()}`}
                      </Text>
                    </div>

                    <div>
                      <Text variant={4} color="neutral.080">
                        Next investment
                      </Text>
                      <Text variant={3}>
                        {startDate
                          ? toLongDate(fromAussieDate(startDate))
                          : '—'}
                      </Text>
                    </div>
                  </Stack>
                </Card>
              </Columns.Column>
            )}

            <Columns.Column
              width={{
                xs: 1,
                lg: 3 / 5,
                xl: 2 / 5,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spaceY="md">
                  <Stack spaceY="lg">
                    <Stack spaceY="xs">
                      <Text variant={4} color="neutral.080" isBold={true}>
                        Frequency
                      </Text>
                      <Stack spaceY="xxs">
                        <Columns spaceX="sm" spaceY={{ xs: 'sm', md: 'none' }}>
                          {PRESET_FREQUENCIES.map(({ label }) => {
                            return (
                              <Columns.Column
                                width={{ xs: 1, md: 1 / 3 }}
                                key={label}
                              >
                                <RadioButton
                                  value={label}
                                  {...register('frequency', {
                                    required: true,
                                  })}
                                >
                                  <Text
                                    variant={2}
                                    isBold={true}
                                    align="center"
                                  >
                                    {label}
                                  </Text>
                                </RadioButton>
                              </Columns.Column>
                            );
                          })}
                        </Columns>
                      </Stack>
                    </Stack>

                    <Stack spaceY="xs">
                      <Text variant={4} color="neutral.080" isBold={true}>
                        Amount
                      </Text>
                      <Columns spaceX="sm">
                        {PRESET_AMOUNTS.map((option) => {
                          return (
                            <Columns.Column width="max" key={option}>
                              <RadioButton
                                value={option}
                                {...register('presetAmount')}
                              >
                                <Text variant={2} isBold={true} align="center">
                                  ${option}
                                </Text>
                              </RadioButton>
                            </Columns.Column>
                          );
                        })}
                      </Columns>
                    </Stack>
                  </Stack>

                  <ControllerInput
                    name="otherAmount"
                    control={control}
                    type="text"
                    format="currency"
                    placeholder="Other amount"
                    rules={{
                      validate: (value: string) =>
                        !presetAmount ? validate(value) : true,
                    }}
                  />

                  <ControllerInput
                    name="startDate"
                    control={control}
                    type="text"
                    format="date"
                    placeholder="Start date (dd-mm-yyyy)"
                    rules={{
                      ...requiredValidation('Start date'),
                      pattern: commonValidationRules.date.pattern,
                      validate: (value) =>
                        investmentPlanStartDateValidation(
                          value,
                          AUSSIE_DATE_FORMAT,
                        ),
                    }}
                  />

                  <Box
                    backgroundColor="neutral.030"
                    borderRadius="xxs"
                    padding="md"
                  >
                    <Stack spaceY="xxs">
                      <Box
                        color="indigo.070"
                        display="flex"
                        justifyContent="center"
                      >
                        <StreamlineCashBulbIcon size="lg" />
                      </Box>
                      <Text
                        variant={2}
                        color="neutral.100"
                        isBold={true}
                        align="center"
                      >
                        {investmentPlanPushCopy}
                      </Text>
                    </Stack>
                  </Box>

                  <Stack spaceY="sm">
                    {activeBankAccountNumber && (
                      <Text variant={3} color="neutral.080">
                        On the nominated date for your plan cycle, we’ll check
                        your available money. If you don’t have enough money,
                        we’ll debit the full amount from your linked bank
                        account ending in{' '}
                        {maskBankAccountNumber(activeBankAccountNumber)}.
                      </Text>
                    )}
                  </Stack>
                </Stack>

                <PageFormButtonContainer>
                  <PageFormContinueButton
                    trackingProperties={{ name: 'investment_plan_continue' }}
                    isLoading={isSubmitting}
                  >
                    {variant === 'onboarding'
                      ? 'Continue'
                      : 'Save investment plan'}
                  </PageFormContinueButton>

                  {isEdit && onStopPlan && (
                    <PageFormCancelButton
                      aria-label="Stop investment plan"
                      trackingProperties={{ name: 'investment_plan_stop_plan' }}
                      onClick={onStopPlan}
                      data-testid="stop-plan-button"
                    >
                      Stop Plan
                    </PageFormCancelButton>
                  )}

                  {variant === 'onboarding' ? (
                    <PageFormSkipButton
                      data-testid="skip-button"
                      trackingProperties={{ name: 'investment_plan_skip' }}
                      onClick={onSkip}
                    />
                  ) : (
                    <PageFormCancelButton
                      aria-label="Cancel"
                      data-testid="cancel-button"
                      trackingProperties={{ name: 'investment_plan_cancel' }}
                      onClick={onSkip}
                    />
                  )}
                </PageFormButtonContainer>
              </form>
            </Columns.Column>
          </Columns>
        )}
      </Columns.Column>
    </Columns>
  );
};
