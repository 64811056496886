import { ApolloError } from '@apollo/client';
import { navigate, RouteComponentProps } from '@reach/router';
import { prettifyError } from '@spaceship-fspl/helpers';
import { useNotifications } from 'contexts/notifications';
import { Routes } from 'pages/routes';
import React, { createContext } from 'react';

export interface IMfaRegistration {
  onSuccess(): void;
  onDone(): void;
  onError: (error: unknown) => void;
  whatsAppRegistrationAllowed: boolean;
}

export const MfaRegistrationContext = createContext<
  IMfaRegistration | undefined
>(undefined);

// This provider has been designed so the same page components can be used for MFA registration flow in multiple places,
// e.g. Dashboard, Account settings or Onboarding
// TODO: https://spaceship-hq.atlassian.net/browse/ENG-3593 Add an "MfaOnboardingRegistrationProvider" here.

export const MfaDashboardRegistrationProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const notifications = useNotifications();

  return (
    <MfaRegistrationContext.Provider
      value={{
        onSuccess: () => {
          navigate(Routes.ACCOUNT_MFA_REGISTRATION_PHONE_SUCCESS);
        },
        onDone: () => {
          navigate(Routes.INDEX);
        },
        onError: (error) => {
          const apolloErr = error as ApolloError;
          if (apolloErr?.message?.includes('too many verification attempts')) {
            navigate(Routes.ACCOUNT_MFA_REGISTRATION_TOO_MANY_ATTEMPTS);
            return;
          }

          if (apolloErr?.message) {
            notifications.popToast({
              level: 'warning',
              message: apolloErr.message,
            });
          } else {
            notifications.popToast({
              message: prettifyError(error),
              level: 'error',
            });
          }
        },
        whatsAppRegistrationAllowed: true,
      }}
    >
      {children}
    </MfaRegistrationContext.Provider>
  );
};

export const MfaRegistrationMandatoryProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const notifications = useNotifications();

  return (
    <MfaRegistrationContext.Provider
      value={{
        onSuccess: () => {
          navigate(Routes.ACCOUNT_MFA_REGISTRATION_REQUIRED_SUCCESS);
        },
        onDone: () => {
          navigate(Routes.INDEX);
        },
        onError: (error) => {
          const apolloErr = error as ApolloError;
          if (apolloErr?.message?.includes('too many verification attempts')) {
            navigate(
              Routes.ACCOUNT_MFA_REGISTRATION_REQUIRED_TOO_MANY_ATTEMPTS,
            );
            return;
          }

          if (apolloErr?.message) {
            notifications.popToast({
              level: 'warning',
              message: apolloErr.message,
            });
          } else {
            notifications.popToast({
              message: prettifyError(error),
              level: 'error',
            });
          }
        },
        whatsAppRegistrationAllowed: true,
      }}
    >
      {children}
    </MfaRegistrationContext.Provider>
  );
};

export const MfaRegistrationOnboardingProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const notifications = useNotifications();

  return (
    <MfaRegistrationContext.Provider
      value={{
        onSuccess: () => {
          navigate(Routes.SIGNUP_PRODUCT);
        },
        onDone: () => {
          navigate(Routes.SIGNUP_PRODUCT);
        },
        onError: (error) => {
          const apolloErr = error as ApolloError;
          if (apolloErr?.message?.includes('too many verification attempts')) {
            navigate(Routes.SIGNUP_MFA_REGISTRATION_TOO_MANY_ATTEMPTS);
            return;
          }

          if (apolloErr?.message) {
            notifications.popToast({
              level: 'warning',
              message: apolloErr.message,
            });
          } else {
            notifications.popToast({
              message: prettifyError(error),
              level: 'error',
            });
          }
        },
        whatsAppRegistrationAllowed: false, // Only allowed for existing accounts
      }}
    >
      {children}
    </MfaRegistrationContext.Provider>
  );
};
