import { Card, Spinner } from '@spaceship-fspl/components';
import { stringToDate } from '@spaceship-fspl/helpers';
import { SUPER_DOCUMENTS, useGetDocuments } from '@spaceship-fspl/super';
import { ResourceItemProps, ResourceList } from 'components/resource-list';
import React from 'react';

export const ImportantDocumentsCard: React.FC<
  React.PropsWithChildren<{ show: number }>
> = (props) => {
  const resp = useGetDocuments();

  const documents: Array<ResourceItemProps> =
    resp.data
      ?.sort((a, b) => {
        // Always show "employer contribution form" first
        if (b.type.toLowerCase() === 'employer contribution form') {
          return Number.MAX_SAFE_INTEGER;
        }
        return (
          stringToDate(b.documentDate).getTime() -
          stringToDate(a.documentDate).getTime()
        );
      })
      ?.map((d) => ({
        memberDocumentId: d.id,
        label: d.type,
      })) ?? [];

  return (
    <Card
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingX="md"
      paddingY="sm"
      minHeight={310}
      height="100%"
      aria-label="super important documents card"
    >
      {resp.isLoading ? (
        <Spinner />
      ) : (
        <ResourceList
          isSuperResource={true}
          resources={documents.concat(SUPER_DOCUMENTS).slice(0, props.show)}
        />
      )}
    </Card>
  );
};
