import { IndicatorTriangleIcon } from '@spaceship-fspl/icons-web';
import { Color, color, IconSize } from '@spaceship-fspl/styles';
import styled, { css } from 'styled-components';

interface PerformanceIndicatorProps {
  direction: 'up' | 'down';
  upColor?: Color;
  downColor?: Color;
  size?: IconSize;
}

export const PerformanceIndicator = styled(IndicatorTriangleIcon).attrs(
  ({ size }) => ({
    size: size ?? 'xs',
  }),
)<PerformanceIndicatorProps>`
  ${({ direction, upColor = 'mint.050', downColor = 'red.020' }) => css`
    ${color(direction === 'up' ? upColor : downColor)};
    transform: rotate(${direction === 'up' ? 0 : 180}deg);
  `}
`;
