import {
  backgroundColor,
  borderRadius,
  color,
  paddingX,
} from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

import { UnstyledButton } from '../buttons';
import { Text } from '../text';

interface Control {
  label: string;
  value: string;
}

interface SegmentControlsProps {
  value: string;
  controls: Control[];
  onChange: (value: string) => void;
}

export const SegmentControls: React.FC<
  React.PropsWithChildren<SegmentControlsProps>
> = (props) => {
  return (
    <Wrapper>
      {props.controls.map((c) => {
        const isActive = c.value === props.value;
        return (
          <Segment
            isActive={isActive}
            key={c.label}
            onClick={() => props.onChange(c.value)}
          >
            <Text
              variant={4}
              isBold={true}
              color={isActive ? 'neutral.000' : 'neutral.080'}
            >
              {c.label}
            </Text>
          </Segment>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${backgroundColor('neutral.030')};
  ${borderRadius('md')};
  height: 32px;
  display: flex;
  flex-direction: row;
`;

const SEGMENT_HEIGHT = 32;

const Segment = styled(UnstyledButton)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${SEGMENT_HEIGHT}px;
  border-radius: ${SEGMENT_HEIGHT / 2}px;
  min-width: 48px;
  ${paddingX('xs')};
  ${(props) => backgroundColor(props.isActive ? 'indigo.070' : 'neutral.030')};
  ${(props) => color(props.isActive ? 'neutral.000' : 'neutral.080')};

  :hover {
    ${(props) => !props.isActive && backgroundColor('neutral.050')};
  }
`;
