import { RouteComponentProps, useNavigate } from '@reach/router';
import { useLogin } from '@spaceship-fspl/auth';
import {
  Accordion,
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  useCreateUserAccount,
  useUpdateUserSignupContact,
} from '@spaceship-fspl/data';
import {
  AddressInput,
  ProductOffering,
  useAddProductIntent,
} from '@spaceship-fspl/graphql';
import { SupportConfig } from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import { AddressForm, EMPTY_ADDRESS_FORM } from 'components/address/form';
import { AddressSearch } from 'components/address/search';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { LinkButton } from 'components/link-button';
import {
  useSuperReferralCode,
  useVoyagerReferralCode,
} from 'contexts/deep-linking';
import { useNotifications } from 'contexts/notifications';
import {
  useCreateUserContext,
  useSignupRequestContext,
} from 'contexts/saver/onboarding';
import { useSignupProductIntent } from 'contexts/saver/signup-product-offering';
import { AccessibilityLabel } from 'helpers/accessibility';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { fromAussieDate, toISODate } from 'helpers/format';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface SignupDetailsForm {
  address?: AddressInput;
}

export const SignupDetails: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const [request] = useSignupRequestContext();
  const [createUser] = useCreateUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const { popToast } = useNotifications();
  const login = useLogin();
  const { mutateAsync: updateUserSignupContact } = useUpdateUserSignupContact();
  const { mutateAsync: createUserAccount } = useCreateUserAccount();
  const { product } = useSignupProductIntent();
  const { value: voyagerReferralCode } = useVoyagerReferralCode();
  const [addProductIntent] = useAddProductIntent();
  const { value: superReferralCode } = useSuperReferralCode();

  const form = useForm<SignupDetailsForm>({
    defaultValues: {
      address: EMPTY_ADDRESS_FORM,
    },
    shouldFocusError: true,
  });

  const isGoogleMapsAutocompleteEnabled = useFeatureFlag(
    FeatureFlagKeys.GOOGLE_MAPS_AUTOCOMPLETE_ENABLED,
  );
  const [isManualAddressFormShown, setIsManualAddressFormShown] =
    useState(false);
  const [googlePlaceId, setGooglePlaceId] = useState<string | undefined>();

  const popDetailsError = (): void => {
    popToast({
      level: 'error',
      message:
        'Unable to save your details. Please try again or contact support.',
    });
  };

  const onSubmit = form.handleSubmit(async (values: SignupDetailsForm) => {
    // Ensure context populated
    if (
      !(
        request.createLead?.email_address &&
        createUser?.contact_id &&
        createUser?.password &&
        request.updateLead?.dateOfBirth
      )
    ) {
      popToast({
        level: 'warning',
        message: 'Please complete sign up form before continuing.',
      });
      await navigate(Routes.SIGNUP);
      return;
    }

    setIsLoading(true);

    // Update lead
    try {
      await updateUserSignupContact({
        contact_id: request.updateLead?.contactId,
        first_name: request.updateLead?.firstName,
        last_name: request.updateLead?.lastName,
        phone_number: request.updateLead?.phoneNumber,
        email_address: request.updateLead?.emailAddress,
        product_intent: request.updateLead?.productIntent,
        campaign: request.updateLead?.campaign,
        date_of_birth: toISODate(
          fromAussieDate(request.updateLead.dateOfBirth ?? ''),
        ),
        address: {
          unit_number: values.address?.unitNumber,
          street_number: values.address?.streetNumber,
          street_name: values.address?.streetName,
          street_type: values.address?.streetType,
          state: values.address?.state,
          suburb: values.address?.suburb,
          postcode: values.address?.postcode,
          country: values.address?.country ?? 'AU',
        },
      });
    } catch {
      popDetailsError();
      setIsLoading(false);
      return;
    }

    // Create user
    try {
      await createUserAccount(createUser);
    } catch {
      popDetailsError();
      setIsLoading(false);
      return;
    }

    // Login
    try {
      await login(request.createLead.email_address, createUser.password);
    } catch {
      popToast({
        level: 'warning',
        message:
          "Sorry, we've run into a problem. Please log in again to continue your sign up.",
      });
      setIsLoading(false);
      await navigate(Routes.LOGIN);
      return;
    }

    track?.(MarketingTrackingEvents.SIGNUP_SUBMIT_DETAILS_PAGE);

    // redirect user to voyager onboarding if user has voyager referral or has a voyager product intent
    if (voyagerReferralCode || product === ProductOffering.VOYAGER) {
      try {
        addProductIntent({
          variables: {
            input: { product: product || ProductOffering.VOYAGER },
          },
        });
        // eslint-disable-next-line no-empty
      } catch {}
      setIsLoading(false);
      await navigate(Routes.VOYAGER_ONBOARDING_PORTFOLIO);
      return;
    }

    // redirect user to super onboarding if user has super referral or has a super product intent
    if (superReferralCode || product === ProductOffering.SUPER) {
      try {
        addProductIntent({
          variables: {
            input: { product: product || ProductOffering.VOYAGER },
          },
        });
        // eslint-disable-next-line no-empty
      } catch {}
      setIsLoading(false);
      await navigate(Routes.SUPER_SIGNUP_PORTFOLIO);
      return;
    }
    setIsLoading(false);

    await navigate(Routes.SIGNUP_MFA_REGISTRATION);
  });

  useEffect(() => {
    setIsManualAddressFormShown(!isGoogleMapsAutocompleteEnabled);
  }, [isGoogleMapsAutocompleteEnabled]);

  return (
    <OnboardingContainer>
      <Columns alignX="center" spaceY="sm">
        <Columns.Column width={{ xs: 1, lg: 6 / 12 }}>
          <Stack spaceY={{ xs: 'md', md: 'lg' }}>
            <PageHeading title="What is your residential address?" />
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <Stack spaceY={{ xs: 'md', md: 'lg' }}>
                  {isGoogleMapsAutocompleteEnabled && (
                    <Stack spaceY="xxs">
                      <AddressSearch
                        onFocus={() => {
                          setIsManualAddressFormShown(false);
                        }}
                        onFallback={() => {
                          form.reset({
                            address: EMPTY_ADDRESS_FORM,
                          });
                          setGooglePlaceId(undefined);
                          setIsManualAddressFormShown(true);
                        }}
                        onSelect={(id) => {
                          setGooglePlaceId(id);
                          setIsManualAddressFormShown(true);
                        }}
                      />
                      <LinkButton
                        aria-label={AccessibilityLabel.MANUAL_ADDRESS}
                        size="xxs"
                        onClick={(): void => {
                          setIsManualAddressFormShown((isShown) => !isShown);
                        }}
                        trackingProperties={{
                          name: 'signup_details_address_enter_manually',
                        }}
                      >
                        Enter manually
                      </LinkButton>
                    </Stack>
                  )}
                  {isManualAddressFormShown && (
                    <AddressForm googlePlaceId={googlePlaceId} />
                  )}
                </Stack>

                <Box marginTop="xl">
                  <Disclaimer />
                </Box>

                <PageFormButtonContainer>
                  <PageFormContinueButton
                    isLoading={isLoading}
                    trackingProperties={{
                      name: 'signup_details_submit',
                    }}
                  />
                </PageFormButtonContainer>
              </form>
            </FormProvider>
          </Stack>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

const Disclaimer: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <Stack spaceY="xxs">
        <Heading variant={5} isBold={true} component="h3">
          We need to verify your identity
        </Heading>
        <Text variant={3}>
          By clicking the ‘Continue’ button, I confirm I have consented to
          Spaceship using electronic verification services to verify my identity
          against external data sources, including by disclosing my personal
          information to credit reporting agencies, document issuers, and
          official record holders.
        </Text>

        <Accordion variant="show-more" title="Learn more">
          <Box
            backgroundColor="neutral.000"
            borderRadius="sm"
            padding="md"
            marginTop="xs"
          >
            <Stack spaceY="sm">
              <Heading variant={5} isBold={true} component="h3">
                How we verify your identity
              </Heading>
              <Text variant={3}>
                Spaceship uses electronic verification services to verify your
                identity. We do this to make sure we know who you are and to
                meet regulatory requirements.
                <br />
                <br />
                By creating a Spaceship account, you consent to Spaceship
                verifying your name, date of birth, and residential address
                against external data sources in accordance with anti-money
                laundering and counter-terrorism financing legislation.
                <br />
                <br />
                This includes us disclosing your personal information to a
                credit reporting agency and asking the credit reporting agency
                to assess whether it matches (in whole or in part) the personal
                information contained in a credit information file. <br />
                <br />
                The credit reporting agency may then prepare and provide
                Spaceship with this assessment and may use your personal
                information, and personal information held about other
                individuals, for the purposes of preparing this assessment.
                <br />
                <br />
                <strong>
                  If you disagree with having your identity verified by a credit
                  reporting agency, please contact customer support on{' '}
                  <TextLink
                    color="indigo.070"
                    href={`mailto:${SupportConfig.EMAIL_ADDRESS}`}
                  >
                    {SupportConfig.EMAIL_ADDRESS}
                  </TextLink>{' '}
                  or{' '}
                  <TextLink
                    color="indigo.070"
                    href={`tel:${SupportConfig.PHONE_NUMBER}`}
                  >
                    {SupportConfig.PHONE_NUMBER_DISPLAY}
                  </TextLink>
                  .
                </strong>
              </Text>
            </Stack>
          </Box>
        </Accordion>
      </Stack>
    </>
  );
};
