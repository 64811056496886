import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Accordion,
  ActionLink,
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { ExternalRoutes, SupportConfig } from '@spaceship-fspl/helpers';
import {
  HelpWheelIcon,
  IconProps,
  StreamlineDiscountCircleIcon,
  StreamlineLegalScaleUnequal1Icon,
  StreamlineShieldWarningIcon,
} from '@spaceship-fspl/icons-web';
import { marginTop, paddingLeft } from '@spaceship-fspl/styles';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormSkipButton,
} from 'components/layouts/page';
import { SuperMemberDetailsAccordion } from 'components/super-member-details';
import React from 'react';
import styled from 'styled-components';

import { Routes } from '../routes';

export interface SuperConsolidateOptionsProps extends RouteComponentProps {
  isSignup?: boolean;
}

export const SuperConsolidateOptions: React.FC<
  React.PropsWithChildren<SuperConsolidateOptionsProps>
> = ({ isSignup = false }) => {
  const navigate = useNavigate();
  const handleContinue = (): void => {
    if (isSignup) {
      navigate(Routes.SUPER_SIGNUP_EMPLOYER_CONTRIBUTION);
    } else {
      navigate(Routes.SUPER_DASHBOARD);
    }
  };
  const Container = isSignup ? OnboardingContainer : PageContainer;
  const superFlagsResp = useSuperPortfolioFlags();
  const sftOneSuperActive =
    superFlagsResp.data?.superPortfolioFlags?.sftOneSuperActive;
  return (
    <Container>
      <Columns alignX="center" alignY="center" spaceY="xxl">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <Stack spaceY="lg">
            <Stack spaceY="md">
              <Heading variant={3} component="h1" align="center">
                Consolidate your super
              </Heading>
              <Stack spaceY="md">
                <Text variant={2} align="left">
                  You can find other super accounts you might have and
                  consolidate these into your Spaceship Super account by
                  selecting one of the options below.
                </Text>
                <Text variant={2} align="left">
                  Super consolidation is not mandatory. Before consolidating
                  your super, you should consider how rolling over your existing
                  super funds might impact you.
                </Text>
              </Stack>
            </Stack>

            <Stack spaceY="sm">
              {!isSignup && <SuperMemberDetailsAccordion />}

              <Option title="Use myGov">
                <ul>
                  <li>
                    <Text variant={2}>
                      Log into{' '}
                      <TextLink
                        color="indigo.070"
                        href={ExternalRoutes.SUPER_MY_GOV}
                        rel="noreferrer"
                        target="_blank"
                      >
                        your myGov account
                      </TextLink>{' '}
                      and click through to the ATO service. Please note if
                      you’ve just joined Spaceship Super, it can take a few days
                      for your account to appear within your myGov account.
                    </Text>
                  </li>
                  <li>
                    <Text variant={2}>
                      Navigate to the <strong>&apos;Super&apos;</strong> menu,
                      and then click on <strong>&apos;Manage&apos;</strong>{' '}
                      followed by <strong>&apos;Transfer super&apos;</strong>.
                    </Text>
                  </li>
                  <li>
                    <Text variant={2}>
                      Select the fund you&apos;d like to transfer your money
                      from, and the fund you&apos;d like to transfer your money
                      to, from the funds listed.
                    </Text>
                  </li>
                  <li>
                    <Text variant={2}>
                      If you&apos;d like to rollover your super to Spaceship
                      Super, you would select:{' '}
                      {sftOneSuperActive ? (
                        <strong>&ldquo;THE TRUSTEE FOR ONESUPER&rdquo;</strong>
                      ) : (
                        <strong>
                          &ldquo;THE TRUSTEE FOR TIDSWELL MASTER SUPERANNUATION
                          PLAN.&rdquo;
                        </strong>
                      )}
                    </Text>
                  </li>
                  <li>
                    <Text variant={2}>
                      Follow the instructions and confirm your rollover. Your
                      funds will generally be rolled into your selected super
                      account within 10 business days.
                    </Text>
                  </li>
                </ul>
              </Option>

              <Option title="Use a form">
                <Stack spaceY="md">
                  <Text variant={2}>
                    Download and print the ATO&apos;s{' '}
                    <TextLink
                      color="indigo.070"
                      href={ExternalRoutes.SUPER_ROLLOVER_REQUEST_FORM}
                      rel="noreferrer"
                      target="_blank"
                    >
                      rollover request form
                    </TextLink>
                    .<br />
                    <br />
                    Complete the form and email a scanned copy to:
                  </Text>

                  <Box
                    backgroundColor="neutral.015"
                    borderRadius="sm"
                    padding="sm"
                    display="flex"
                    justifyContent="center"
                  >
                    <ActionLink
                      size="sm"
                      color="indigo.070"
                      href={`mailto:${SupportConfig.EMAIL_ADDRESS}?subject=Super Rollover Request`}
                    >
                      {SupportConfig.EMAIL_ADDRESS}
                    </ActionLink>
                  </Box>

                  <Text variant={2}>
                    We will get in touch with your other super funds to initiate
                    your rollover. Please note this method is only available for
                    those wishing to make full rollovers.
                  </Text>
                </Stack>
              </Option>

              <Option title="Call us">
                <Stack spaceY="md">
                  <Text variant={2}>
                    Gather the following information from the super fund you’re
                    transferring out of:
                  </Text>

                  <ul>
                    <li>
                      <Text variant={2}>The fund name;</Text>
                    </li>
                    <li>
                      <Text variant={2}>Your account number; and</Text>
                    </li>
                    <li>
                      <Text variant={2}>
                        The fund&apos;s Unique Super Identifier (USI).
                      </Text>
                    </li>
                  </ul>

                  <Text variant={2}>
                    Call Spaceship on the number below and select
                    &apos;Super&apos; when prompted:
                  </Text>

                  <Box
                    backgroundColor="neutral.015"
                    borderRadius="sm"
                    padding="sm"
                  >
                    <Stack spaceY="xxxs" alignX="center">
                      <ActionLink
                        size="md"
                        color="indigo.070"
                        href={`tel:${SupportConfig.PHONE_NUMBER}`}
                      >
                        {SupportConfig.PHONE_NUMBER_DISPLAY}
                      </ActionLink>

                      <Text variant={3} color="neutral.085" align="center">
                        8.30am - 5.30pm, Monday to Friday (Sydney time)
                      </Text>
                    </Stack>
                  </Box>

                  <Text variant={2}>
                    Let us know that you&apos;d like to complete a rollover.
                    You&apos;ll also need to confirm if this is a full or
                    partial rollover. From there, we&apos;ll walk you through
                    the process.
                  </Text>
                </Stack>
              </Option>
            </Stack>
          </Stack>

          {isSignup && (
            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{
                  name: 'supermatch_options_continue',
                }}
                onClick={handleContinue}
              />

              <PageFormSkipButton
                trackingProperties={{
                  name: 'supermatch_options_skip',
                }}
                onClick={handleContinue}
              />
            </PageFormButtonContainer>
          )}
        </Columns.Column>

        <Columns.Column width={1}>
          <Accordion
            variant="info"
            title="Learn more about consolidating your super"
          >
            <Box paddingTop="sm">
              <Stack spaceY="md">
                <Text variant={2} color="neutral.085">
                  You should consider how rolling over your existing super
                  accounts might impact you, including:
                </Text>

                <Impact
                  icon={StreamlineLegalScaleUnequal1Icon}
                  copy="How the fees, risks and benefits of your other super funds compare to the fees, risks and benefits of Spaceship Super."
                />
                <Impact
                  icon={StreamlineShieldWarningIcon}
                  copy="How by rolling over the full value of an existing super account to Spaceship Super, your existing super account will be closed and you may lose some benefits, such as life insurance."
                />
                <Impact
                  icon={HelpWheelIcon}
                  copy="That Spaceship Super does not currently offer life, total permanent disability or income protection insurance."
                />
                <Impact
                  icon={StreamlineDiscountCircleIcon}
                  copy="What investment or tax implications may arise for you."
                />

                <Text variant={2} color="neutral.085">
                  Talking to a professional financial adviser can help you make
                  this decision.
                </Text>
              </Stack>
            </Box>
          </Accordion>
        </Columns.Column>
      </Columns>
    </Container>
  );
};

interface OptionProps {
  title: string;
}

const Option: React.FC<React.PropsWithChildren<OptionProps>> = ({
  children,
  title,
}) => {
  return (
    <Accordion variant="option" title={title}>
      <StyledAccordionContent>{children}</StyledAccordionContent>
    </Accordion>
  );
};

interface ImpactProps {
  icon: React.ComponentType<IconProps>;
  copy: string;
}

const Impact: React.FC<React.PropsWithChildren<ImpactProps>> = ({
  icon: Icon,
  copy,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Icon color="indigo.070" size="xl" />

      <Box flex={1} paddingLeft="md">
        <Text variant={2} color="neutral.085">
          {copy}
        </Text>
      </Box>
    </Box>
  );
};

const StyledAccordionContent = styled.div`
  ul {
    ${paddingLeft('md')}
    margin: 0;
  }

  li:not(:first-child) {
    ${marginTop('sm')}
  }
`;
