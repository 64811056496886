import * as React from 'react';
export const FeatherMoreVertical = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 11a1 1 0 100 2 1 1 0 100-2zM12 4a1 1 0 100 2 1 1 0 100-2zM12 18a1 1 0 100 2 1 1 0 100-2z" />
  </svg>
);
