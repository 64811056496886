import * as React from 'react';
export const Galaxy = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M5.077 11.875a6.255 6.255 0 106.255-6.255m9.881 7.215c.448-5.462-3.617-10.253-9.079-10.701-5.462-.448-10.252 3.617-10.7 9.079-.448 5.461 3.616 10.252 9.078 10.7m3.525-9.639a2.406 2.406 0 11-4.812 0 2.406 2.406 0 014.812 0zM7 9.95a1.444 1.444 0 11-2.887 0 1.444 1.444 0 012.887 0zm12.551 4.424a1.567 1.567 0 113.123.256 1.567 1.567 0 01-3.123-.256z" />
  </svg>
);
