import * as React from 'react';
export const Bank = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 .35L0 5.61v3.784h24V5.61L12 .35zM22.478 10.867h-3.853v8.82h-4.699v-8.82h-3.852v8.82H5.375v-8.82H1.522v8.82H0v3.983h24v-3.983h-1.522v-8.82z"
    />
  </svg>
);
