import { gql, useQuery } from '@apollo/client';
import { navigate, RouteComponentProps, useParams } from '@reach/router';
import { Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { voyagerPortfolioPerformanceScalars } from '@spaceship-fspl/graphql';
import {
  WebAppVoyagerUnitPrice,
  WebAppVoyagerUnitPriceVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerUnitPrice';
import { PageContainer } from 'components/layouts/page';
import { voyagerPortfolios } from 'helpers/portfolios';
import React from 'react';

import { Routes } from './routes';
import { VoyagerUnitPriceCard } from './voyager-dashboard/unit-price';

interface PageParams {
  productId?: string;
}

export const VoyagerUnitPrice: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const { productId = '' }: PageParams = useParams();

  const resp = useQuery<
    WebAppVoyagerUnitPrice,
    WebAppVoyagerUnitPriceVariables
  >(
    gql`
      query WebAppVoyagerUnitPrice(
        $productId: ID!
        $productIdProvided: Boolean!
      ) {
        contact {
          id
          account {
            id
            saverProductInstance(id: $productId)
              @include(if: $productIdProvided) {
              id
              portfolio
              portfolioPerformance {
                ...voyagerPortfolioPerformanceFields
              }
            }
          }
        }
      }
      ${voyagerPortfolioPerformanceScalars}
    `,
    {
      variables: {
        productId,
        productIdProvided: !!productId,
      },
    },
  );
  const product = resp.data?.contact?.account?.saverProductInstance;
  const portfolioPerformance = product?.portfolioPerformance;

  if (!productId) {
    navigate(Routes.VOYAGER_DASHBOARD);
    return null;
  }

  if (!product?.portfolio) {
    return null;
  }

  const config = voyagerPortfolios[product.portfolio];

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12 }}>
          <Stack spaceY="md">
            <Heading variant={3}>Spaceship Voyager unit price</Heading>
            <Stack spaceY="sm">
              <VoyagerUnitPriceCard
                portfolio={product.portfolio}
                portfolioPerformance={portfolioPerformance}
                chartHeight={340}
              />

              <Text variant={4} color="neutral.080">
                <strong>
                  Past performance is not a reliable indicator of future
                  performance.
                </strong>{' '}
                Markets can go up and down, which means so too can the value of
                your investment.
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading variant={4} isBold={true}>
                What is this?
              </Heading>

              <Text variant={2} color="neutral.080">
                The purpose of this card is to provide you with a report on the
                unit price for the {config.name} portfolio. It shows the change
                in unit price over time. It does not show the individual
                performance of your investment — that&apos;s because the
                performance of your investment will depend on the timing of your
                investments. This card is provided for your information purposes
                only. Portfolio performance is typically more volatile over
                shorter periods of time due to the sometimes unpredictable
                nature of the share market. Performance over shorter periods
                (such as a day, week or month) will give you insight into this
                volatility and should not be used to make financial decisions.
                You should consider your personal circumstances, financial
                situation and needs before making a decision to invest
                additional funds, hold your investment, or withdraw your
                investment.
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading variant={4} isBold={true}>
                What is the unit price?
              </Heading>

              <Text variant={2} color="neutral.080">
                The unit price – or value of each unit – is determined by
                dividing the portfolio&apos;s net asset value by the number of
                units on issue. A unit represents your investment. You can see
                your unit balance in the &apos;Transaction history&apos; section
                of the app.
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading variant={4} isBold={true}>
                How is the change in unit price calculated?
              </Heading>

              <Text variant={2} color="neutral.080">
                We calculate the unit price change on a rolling basis from the
                relevant period to the most recent unit price available. For
                example, the &apos;Year&apos; percentage is calculated as the
                change in unit price from 12 months ago to the date of the most
                recent unit price available. The &apos;Day&apos; change is
                calculated as the percentage difference in unit price from the
                previous unit price to the most recent unit price available
                (this can end up being more than a calendar day as the unit
                price is calculated each business day).
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading variant={4} isBold={true}>
                When is this information updated?
              </Heading>

              <Text variant={2} color="neutral.080">
                This card is updated after 11am each business day. The portfolio
                invests in assets listed on overseas stock markets. Because
                those markets usually close the following morning (Sydney time),
                the unit price for a business day is calculated at 11.00am
                (Sydney time) on the next business day. We do this to ensure
                that the unit price reflects the market value of assets listed
                on overseas stock markets, as at the close of those markets.
              </Text>
            </Stack>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
