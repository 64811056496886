import * as React from 'react';
export const VoyagerProductStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M13.01 1.45a.6.6 0 01.317.334l5.466 14.196a.6.6 0 01-.327.817c-1.315.506-2.76-.136-3.96-1.223-1.229-1.113-2.364-2.823-3.148-4.857-.784-2.035-1.089-4.063-.924-5.713.161-1.612.803-3.056 2.117-3.564a.6.6 0 01.46.01zm4.415 14.317L12.471 2.901c-.421.42-.736 1.156-.843 2.222-.143 1.427.119 3.266.85 5.162.73 1.897 1.77 3.437 2.834 4.4.793.718 1.52 1.052 2.113 1.082z" />
    <path d="M16.802 9.68a1.056 1.056 0 100-2.111 1.056 1.056 0 000 2.111zM8.399 13.09a.6.6 0 01.144.836l-6.645 9.42a.6.6 0 01-.98-.692l6.645-9.42a.6.6 0 01.836-.145zm2.243-5.907a.6.6 0 01.775.344l2.146 5.572a.6.6 0 01-.344.775l-.881.34a.6.6 0 01-.776-.345L9.416 8.297a.6.6 0 01.345-.775l.88-.34z" />
    <path d="M8.519 4.293a.6.6 0 01.775.345l4.63 12.025a.6.6 0 01-.344.775l-3.52 1.356a.6.6 0 01-.776-.344L4.654 6.425a.6.6 0 01.344-.776l3.52-1.356zM8.39 5.63l-2.401.924 4.199 10.906 2.401-.925L8.39 5.629zm9.36.53a.6.6 0 01.61-.591 2.487 2.487 0 011.69 4.276.6.6 0 11-.834-.863 1.288 1.288 0 00-.875-2.214.6.6 0 01-.59-.609z" />
    <path d="M18.248 3.98a.6.6 0 01.61-.59 4.507 4.507 0 013.063 7.755.6.6 0 01-.833-.864 3.307 3.307 0 00-2.25-5.69.6.6 0 01-.59-.61zm-9.474 7.67a.6.6 0 01-.345.776l-3.867 1.49a.6.6 0 11-.431-1.12l3.867-1.49a.6.6 0 01.776.345z" />
    <path d="M6.122 7.358a.6.6 0 01.287.799l-2.037 4.335a.6.6 0 11-1.086-.51l2.037-4.336a.6.6 0 01.799-.288zm-1.876 6.98a.6.6 0 01.785-.322l4.333 1.814a.6.6 0 11-.463 1.107l-4.333-1.814a.6.6 0 01-.322-.785z" />
    <path d="M3.355 10.998a.6.6 0 01.775.344l1.47 3.813a.6.6 0 01-.345.775l-2.053.791a.6.6 0 01-.776-.344L.957 12.564a.6.6 0 01.344-.776l2.054-.79zm-.129 1.335l-.934.36 1.037 2.693.934-.36-1.037-2.693zM9.198 12.607a1.119 1.119 0 100-2.238 1.119 1.119 0 000 2.238z" />
    <path d="M.424 9.024a.6.6 0 01.743-.41l8.2 2.36a.6.6 0 11-.332 1.154l-8.2-2.362a.6.6 0 01-.411-.742zM22.707 1.226l-.323.158a.749.749 0 00-.217.185 1.048 1.048 0 00-.149.304l-.155.569-.174-.582a.973.973 0 00-.15-.291.765.765 0 00-.216-.185L21 1.226l.323-.159a.772.772 0 00.217-.18 1.07 1.07 0 00.149-.308L21.863 0l.175.579c.032.11.082.214.149.307.06.073.134.135.216.181l.305.159zm-6.919 21.081l-.323.168a.834.834 0 00-.233.194 1.182 1.182 0 00-.158.323l-.178.618-.185-.615a1.047 1.047 0 00-.158-.323.776.776 0 00-.23-.194L14 22.31l.323-.172a.82.82 0 00.23-.19c.07-.1.123-.208.158-.324l.185-.624.184.618c.036.115.09.224.159.323a.864.864 0 00.232.191l.317.175zM3.148 3.265l-.434.217a1.054 1.054 0 00-.297.25c-.095.122-.17.26-.22.407l-.233.795-.24-.795a1.42 1.42 0 00-.174-.42.97.97 0 00-.297-.25L.8 3.265l.427-.236a.992.992 0 00.297-.246c.09-.128.16-.27.204-.42l.236-.796.233.796c.047.15.117.292.207.42.082.1.183.184.298.246l.446.236z" />
  </svg>
);
