import {
  createFontSize,
  createFontStyle,
  createFontWeight,
  createLineHeight,
  createTextAlign,
  createTextDecoration,
} from '@spaceship-fspl/substance-mixin';
import { StyleValue } from '@spaceship-fspl/substance-style';
import { css } from 'styled-components';

import { map, ResponsiveValue } from './media';
import { FlattenInterpolationWithTheme } from './types';

export const fontSize = createFontSize({ map });
export const fontStyle = createFontStyle({ map });
export const fontWeight = createFontWeight({ map });

export const textAlign = createTextAlign({ map });
export const textDecoration = createTextDecoration({ map });

export const lineHeight = createLineHeight({ map });

export const textOverflow = (
  textOverflow: StyleValue<'text-overflow'>,
): string => (textOverflow ? `text-overflow: ${textOverflow};` : '');
export const whiteSpace = (whiteSpace: StyleValue<'white-space'>): string =>
  whiteSpace ? `white-space: ${whiteSpace};` : '';

export interface FontFamilies {
  articleHeading: string;
  heading: string;
  text: string;
  monospace: string;
}

export const fontFamily = (
  font: keyof FontFamilies,
): FlattenInterpolationWithTheme => css`
  font-family: ${({ theme }) => theme.fontFamilies[font]}};
`;

export type HeadingVariant = 1 | 2 | 3 | 4 | 5;

export const heading = ({
  variant,
}: {
  variant: ResponsiveValue<HeadingVariant>;
}): FlattenInterpolationWithTheme => css`
  font-style: normal;
  ${fontFamily('heading')}
  ${({ theme }) => map(variant, (v) => theme.headingVariant(v))}
`;

export type TextVariant = 1 | 2 | 3 | 4;

export const text = ({
  variant,
}: {
  variant: ResponsiveValue<TextVariant>;
}): FlattenInterpolationWithTheme => css`
  font-weight: normal;
  font-style: normal;
  ${fontFamily('text')}
  ${({ theme }) => map(variant, (v) => theme.textVariant(v))}
`;
