import { RouteComponentProps, useNavigate } from '@reach/router';
import { useLogin } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  UnstyledButton,
  Visible,
} from '@spaceship-fspl/components';
import { useUpdatePassword } from '@spaceship-fspl/graphql';
import { NEW_PASSWORD_RULE } from '@spaceship-fspl/helpers';
import {
  FeatherArrowLeftIcon,
  FeatherChevronLeftIcon,
} from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { ControllerPasswordInput } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { useResetPasswordContext } from 'contexts/saver/onboarding';
import { commonValidationRules } from 'helpers/validation';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { Routes } from './routes';

export const PasswordResetNewPassword: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [{ resetId, email }] = useResetPasswordContext();

  const login = useLogin();
  const [updatePassword, updatePasswordMeta] = useUpdatePassword();

  const form = useForm<{ new_password: string }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  useEffect(() => {
    if (!resetId) {
      navigate(Routes.PASSWORD_RESET);
    }
  }, [navigate, resetId]);

  if (!resetId) {
    return null;
  }

  const onSubmit = form.handleSubmit(async ({ new_password }) => {
    const requestId = uuidv4();
    try {
      await updatePassword({
        variables: {
          input: {
            resetId,
            newPassword: new_password,
          },
        },
        context: { headers: { 'x-request-id': requestId } },
      });

      notifications.popToast({
        level: 'success',
        message: 'Password successfully updated.',
      });
    } catch {
      notifications.popToast({
        requestId,
        level: 'error',
        message:
          "Sorry, we've run into a problem. Please try again or contact support.",
      });
      return;
    }

    if (email) {
      const subsequentRequestId = uuidv4();

      try {
        await login(email, new_password, subsequentRequestId);
        await navigate(Routes.VOYAGER_DASHBOARD);
      } catch {
        notifications.popToast({
          level: 'error',
          message: 'Your login failed. Please try again or contact support.',
          requestId: subsequentRequestId,
        });
      }
    } else {
      await navigate(Routes.LOGIN);
    }
  });

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="md">
              <Stack spaceY="xs" alignX={{ sm: 'left', md: 'center' }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Visible
                    isHidden={{ xs: true, md: false }}
                    displayValue="block"
                  >
                    <UnstyledButton
                      onClick={() => navigate(Routes.PASSWORD_RESET_VERIFY)}
                    >
                      <FeatherChevronLeftIcon
                        color="neutral.100"
                        size="lg"
                        strokeWidth={3}
                      />
                    </UnstyledButton>
                  </Visible>
                  <Heading variant={3}>Enter new password</Heading>
                </Box>
                <Text
                  variant={2}
                  color="neutral.080"
                  align={{ sm: 'left', md: 'center' }}
                >
                  {NEW_PASSWORD_RULE}
                </Text>
              </Stack>

              <ControllerPasswordInput
                name="new_password"
                control={form.control}
                rules={commonValidationRules.password}
              />
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{
                  name: 'reset_password_new_password_submit',
                }}
                isLoading={updatePasswordMeta.loading}
              >
                Reset password
              </PageFormContinueButton>

              <Visible isHidden={{ xs: false, md: true }} displayValue="block">
                <Button
                  type="button"
                  variant="tertiary"
                  size="lg"
                  onClick={() => navigate?.(Routes.PASSWORD_RESET_VERIFY)}
                  trackingProperties={{
                    name: 'reset_password_new_password_back',
                  }}
                  isDisabled={updatePasswordMeta.loading}
                  before={<FeatherArrowLeftIcon />}
                >
                  Back
                </Button>
              </Visible>
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
