import * as React from 'react';
export const FeatherCopy = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect vectorEffect="non-scaling-stroke" x={9} y={9} width={13} height={13} rx={2} ry={2} />
    <path vectorEffect="non-scaling-stroke" d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
  </svg>
);
