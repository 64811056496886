import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  ActionLink,
  Box,
  Card,
  Divider,
  DynamicIcon,
  Heading,
  Inline,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { useCancelBoostItemGroup } from '@spaceship-fspl/graphql/src';
import { WebAppBoostsScheduledDetails } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsScheduledDetails';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatCurrency,
  formatDate,
  useRenderTemplate,
} from '@spaceship-fspl/helpers';
import {
  FeatherArrowDownIcon,
  FeatherArrowUpIcon,
} from '@spaceship-fspl/icons-web';
import { useTrack } from '@spaceship-fspl/tracking';
import { getBoostRecipeTemplateVariables } from '@spaceship-fspl/voyager';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { Modal } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import { TrackingEvent } from 'helpers/analytics';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useEffect, useMemo, useState } from 'react';

import { ContentLayout } from './components/layout';

const BOOSTS_ERROR_MESSAGE =
  'Oops, there was a problem loading upcoming boost details.';

interface PageParams {
  boostItemGroupId?: string;
}

export const BoostsScheduledDetails: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const track = useTrack();
  const navigate = useNavigate();
  const { boostItemGroupId }: PageParams = useParams();
  const notifications = useNotifications();
  const [isTransactionListExpanded, setIsTransactionListExpanded] =
    useState(false);
  const [showModal, setShowModal] = useState(false);

  const resp = useQuery<WebAppBoostsScheduledDetails>(
    gql`
      query WebAppBoostsScheduledDetails($id: ID!) {
        boostItemGroup(id: $id) {
          id
          audAmount
          status
          cancellableUntil
          items {
            id
            audAmount
            description
            createdAt
          }
          recipe {
            id
            name
            description
            iconName
            saverProductInstance {
              id
              portfolio
            }
            parameters {
              id
              name
              type
              value
            }
            source {
              ... on WeatherStation {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: boostItemGroupId,
      },
      skip: !boostItemGroupId,
      onCompleted: (data) => {
        if (!data.boostItemGroup) {
          notifications.popToast({
            level: 'error',
            message: BOOSTS_ERROR_MESSAGE,
          });
        }
      },
      onError: () => {
        notifications.popToast({
          level: 'error',
          message: BOOSTS_ERROR_MESSAGE,
        });
      },
    },
  );

  const boostItemGroup = resp.data?.boostItemGroup;
  const recipe = boostItemGroup?.recipe;
  const saverProductInstance = recipe?.saverProductInstance;
  const transactions = boostItemGroup?.items;

  const description = useRenderTemplate(
    recipe?.description ?? '',
    getBoostRecipeTemplateVariables({
      weatherStationName:
        recipe?.source?.__typename === 'WeatherStation'
          ? recipe.source.name
          : '',
      portfolio: saverProductInstance?.portfolio,
      parameters: recipe?.parameters,
    }),
    {
      onError: (error) => {
        addRumError({
          error,
          context: { reason: 'failed to render boost description template' },
        });
        notifications.popToast({
          level: 'error',
          message: BOOSTS_ERROR_MESSAGE,
        });
      },
    },
  );

  useEffect(() => {
    if (!boostItemGroupId) {
      navigate(Routes.BOOSTS_DASHBOARD_HOME);
    }
  }, [boostItemGroupId, navigate]);

  const isTransactionShowMoreButtonVisible =
    transactions && transactions?.length > 5;

  const filteredTransactions = useMemo(() => {
    if (transactions) {
      if (transactions?.length > 5 && !isTransactionListExpanded) {
        return transactions.slice(0, 5);
      }
      return transactions;
    }
    return [];
  }, [transactions, isTransactionListExpanded]);

  return (
    <PageContainer>
      <ContentLayout>
        <Stack spaceY={{ xs: 'sm', md: 'lg' }}>
          <PageHeading title="Upcoming boost" />

          {resp.loading ? (
            <Box
              height={400}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="lg" />
            </Box>
          ) : (
            <Stack spaceY="md">
              {recipe && boostItemGroup?.cancellableUntil && (
                <Card boxShadow="sm" padding={{ xs: 'md', md: 'lg' }}>
                  <Stack spaceY="md">
                    <Stack spaceY={{ xs: 'xs', md: 'sm' }}>
                      <Inline spaceX="xxs">
                        <DynamicIcon
                          name={recipe.iconName}
                          color="neutral.100"
                          size="md"
                        />

                        <Heading variant={5} isBold={true}>
                          {recipe.name}
                        </Heading>
                      </Inline>

                      <Heading variant={3} isBold={true}>
                        {description}
                      </Heading>
                    </Stack>

                    <Stack spaceY="xxxs">
                      <Text variant={4} color="neutral.080" isBold={true}>
                        Scheduled for investment
                      </Text>

                      <Text variant={3} color="neutral.100" isBold={true}>
                        {formatDate(
                          boostItemGroup.cancellableUntil,
                          DATE_FORMAT_TRANSACTIONS_LONG,
                        )}
                      </Text>
                    </Stack>
                  </Stack>
                </Card>
              )}

              {!!transactions?.length && boostItemGroup?.audAmount && (
                <Card borderRadius="sm" boxShadow="sm">
                  <Box
                    paddingX={{ xs: 'md', md: 'lg' }}
                    paddingTop="md"
                    paddingBottom="sm"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Heading variant={5} isBold={true}>
                        Spending history
                      </Heading>

                      <Heading variant={5} isBold={true}>
                        {formatCurrency(boostItemGroup.audAmount)}
                      </Heading>
                    </Box>

                    <Text variant={4}>
                      {transactions.length > 0
                        ? `${transactions.length} time${
                            transactions.length === 1 ? '' : 's'
                          }`
                        : '—'}
                    </Text>
                  </Box>

                  <Divider color="neutral.050" />

                  <Box
                    paddingX={{ xs: 'md', md: 'lg' }}
                    paddingTop="sm"
                    paddingBottom="md"
                  >
                    <Stack spaceY="sm">
                      {filteredTransactions.map((item, index) => {
                        return (
                          <Stack spaceY="sm" key={item.id}>
                            {index !== 0 && <Divider color="neutral.030" />}

                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="flex-end"
                            >
                              <Stack spaceY="xxxs">
                                <Text variant={4} color="neutral.085">
                                  {formatDate(item.createdAt, 'dd MMM yyyy')}
                                </Text>
                                <Text variant={3} isBold={true}>
                                  {item.description}
                                </Text>
                              </Stack>

                              <Text variant={3}>
                                {formatCurrency(item.audAmount)}
                              </Text>
                            </Box>
                          </Stack>
                        );
                      })}

                      {isTransactionShowMoreButtonVisible && (
                        <>
                          <Divider color="neutral.030" />

                          <Box display="flex" justifyContent="center">
                            <ActionLink
                              size="xs"
                              color="indigo.070"
                              onClick={() => {
                                track?.(TrackingEvent.CLICK, {
                                  properties: {
                                    name: `show_${
                                      isTransactionListExpanded
                                        ? 'less'
                                        : 'more'
                                    }_button`,
                                  },
                                });
                                setIsTransactionListExpanded(
                                  (prevState) => !prevState,
                                );
                              }}
                            >
                              <Inline spaceX="xxs" alignY="center">
                                SHOW{' '}
                                {isTransactionListExpanded ? 'LESS' : 'MORE'}
                                {isTransactionListExpanded ? (
                                  <FeatherArrowUpIcon size="sm" />
                                ) : (
                                  <FeatherArrowDownIcon size="sm" />
                                )}
                              </Inline>
                            </ActionLink>
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Box>
                </Card>
              )}
            </Stack>
          )}
        </Stack>

        {!resp.loading && !!recipe && (
          <PageFormButtonContainer>
            <PageFormCancelButton
              trackingProperties={{ name: 'cancel_this_boost_investment' }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Cancel this boost investment
            </PageFormCancelButton>

            <PageFormGoBackButton
              variant="primary"
              trackingProperties={{ name: 'go_back' }}
              onClick={() => {
                navigate(-1);
              }}
            />
          </PageFormButtonContainer>
        )}
      </ContentLayout>

      {boostItemGroupId && (
        <CancelBoostInvestmentModal
          showModal={showModal}
          onCloseModal={() => setShowModal(false)}
          onSuccess={() => {
            setShowModal(false);
            navigate(Routes.BOOSTS_DASHBOARD_HOME);
          }}
          boostItemGroupId={boostItemGroupId}
        />
      )}
    </PageContainer>
  );
};

interface CancelBoostInvestmentModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  onSuccess: () => void;
  boostItemGroupId: string;
}

const CancelBoostInvestmentModal: React.FC<
  React.PropsWithChildren<CancelBoostInvestmentModalProps>
> = (props) => {
  const notifications = useNotifications();
  const [cancelBoostsItemGroup, cancelBoostsItemGroupMeta] =
    useCancelBoostItemGroup();

  return (
    <Modal showModal={props.showModal} closeModal={props.onCloseModal}>
      <Box
        padding="xl"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading variant={3} align="center" component="h3">
          Are you sure you want to cancel this boost investment?
        </Heading>

        <PageFormButtonContainer>
          <PageFormCancelButton
            isDisabled={cancelBoostsItemGroupMeta.loading}
            onClick={props.onCloseModal}
            trackingProperties={{
              name: 'cancel_boost_investment_modal_cancel_button',
            }}
          >
            No - keep it
          </PageFormCancelButton>

          <PageFormContinueButton
            type="button"
            isLoading={cancelBoostsItemGroupMeta.loading}
            trackingProperties={{
              name: 'cancel_boost_investment_modal_confirm_button',
            }}
            onClick={async () => {
              try {
                await cancelBoostsItemGroup({
                  variables: {
                    input: {
                      id: props.boostItemGroupId,
                    },
                  },
                });
              } catch {
                notifications.popToast({
                  level: 'error',
                  message:
                    'Oops, there was a problem cancelling your boost investment.',
                });
              }
            }}
          >
            Yes – cancel investment
          </PageFormContinueButton>
        </PageFormButtonContainer>
      </Box>
    </Modal>
  );
};
