import { useNavigate } from '@reach/router';
import { useRefresh } from '@spaceship-fspl/auth';
import { useGetUserVerification } from '@spaceship-fspl/data';
import {
  ID_OPTIONS,
  IDName,
  isFraudStatusVerified,
  isSourceExhausted,
  parseSourcesLeftToVerify,
} from '@spaceship-fspl/green-id';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  FraudService,
  GreenIdRuleSet,
  IGreenIdInteractiveSource,
} from '@spaceship-fspl/types/externalapi';
import { useGreenID, useSelectedGreenIDSources } from 'contexts/greenid';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { useCallback } from 'react';

export const NO_USER_VERIFICATION_ERROR =
  'Failed to retrieved user verification data';

export type UseReverifyAndNavigateResults = () => Promise<void>;

export const useReverifyAndNavigate = (
  currentIDName?: IDName,
): UseReverifyAndNavigateResults => {
  const navigate = useNavigate();
  const track = useTrack();
  const refreshToken = useRefresh();
  const { getUploadLinkBySourceName, onPending, onVerified, routes, ruleset } =
    useGreenID();
  const getUserVerificationQuery = useGetUserVerification(ruleset);
  const [selectedGreenIDs] = useSelectedGreenIDSources();
  const nextIDName = currentIDName
    ? selectedGreenIDs[selectedGreenIDs.indexOf(currentIDName) + 1]
    : undefined;
  const nextIDLink = routes[nextIDName || 'index'];

  const getUploadLink = useCallback(
    (
      interactiveSources: Array<IGreenIdInteractiveSource>,
      idName?: IDName,
    ): string | undefined => {
      if (!idName) {
        return undefined;
      }

      const iDSourceNames = ID_OPTIONS.find(
        ({ label }) => label === idName,
      )?.value;
      const { isFieldsSubmissionRejected, documentUploads, sourceName } =
        isSourceExhausted(iDSourceNames, interactiveSources);

      if (
        isFieldsSubmissionRejected &&
        documentUploads.length <= 0 &&
        sourceName
      ) {
        return getUploadLinkBySourceName(sourceName);
      }

      return undefined;
    },
    [getUploadLinkBySourceName],
  );

  const navigateToNextLink = useCallback(async (): Promise<void> => {
    const refetchedVerificationData = await getUserVerificationQuery.refetch({
      throwOnError: true,
    });

    if (!refetchedVerificationData.data) {
      throw new Error(NO_USER_VERIFICATION_ERROR);
    }

    await refreshToken();

    const overallStatus = refetchedVerificationData.data?.service_checks?.find(
      ({ service }) => service === FraudService.Enum.GREENID,
    )?.status;
    const isVerified = isFraudStatusVerified(overallStatus);
    const sourcesLeftToVerify = parseSourcesLeftToVerify(
      refetchedVerificationData.data?.green_id_source_details,
    );

    if (isVerified) {
      if (ruleset === GreenIdRuleSet.Enum.VOYAGER_ONBOARDING) {
        track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_GREEN_ID_SUBMITTED);
      }
      onVerified();
      return;
    } else if (sourcesLeftToVerify <= 0) {
      if (ruleset === GreenIdRuleSet.Enum.VOYAGER_ONBOARDING) {
        track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_GREEN_ID_SUBMITTED);
      }
      // verified both ids and no more sources to verify
      onPending();
      return;
    }

    const currentIDUploadLink = getUploadLink(
      refetchedVerificationData.data?.green_id_source_details
        ?.interactive_sources ?? [],
      currentIDName,
    );
    const nextUploadLink = getUploadLink(
      refetchedVerificationData.data?.green_id_source_details
        ?.interactive_sources ?? [],
      nextIDName,
    );

    switch (true) {
      case !!currentIDUploadLink:
        // current id verification failed and need to upload document
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        navigate(currentIDUploadLink!, { replace: true });
        return;

      case !!nextUploadLink:
        // verified first id but next id verification has failed before
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        navigate(nextUploadLink!, { replace: true });
        return;

      default:
        // verified first id but second id has not been verified before
        // OR redirect to greenid page if we verified both ids but sources left to verified is more than 0
        navigate(nextIDLink, { replace: true });
        return;
    }
  }, [
    getUserVerificationQuery,
    refreshToken,
    getUploadLink,
    currentIDName,
    nextIDName,
    onVerified,
    onPending,
    navigate,
    nextIDLink,
    ruleset,
    track,
  ]);

  return navigateToNextLink;
};
