import * as React from 'react';
export const VoyagerProductStarsFilled = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 80 80" fill="none" {...props}>
    <path
      d="M59.679 55.664c-6.629 2.552-16.106-6.033-21.17-19.175-5.065-13.141-3.79-25.86 2.839-28.422M54.835 33.736a3.53 3.53 0 100-7.06 3.53 3.53 0 000 7.06z"
      fill="#fff"
    />
    <path
      d="M3.372 78.273l27.03-38.33"
      stroke="#FAEDFF"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M43.121 45.512l-7.173-18.628-3.92 1.51L39.2 47.022l3.921-1.51zM43.35 57.809L27.87 17.607 16.098 22.14l15.48 40.202 11.772-4.533z"
      fill="#FAEDFF"
    />
    <path
      d="M39.196 47.022l-7.173-18.628-11.772 4.533 7.173 18.628 11.772-4.533z"
      fill="#fff"
    />
    <path
      d="M60.014 22.005c2.475.038 4.834 1.544 5.794 4.038a6.319 6.319 0 01-1.506 6.81M61.674 14.724c5.122.087 10.014 3.195 12.01 8.365a13.06 13.06 0 01-3.127 14.11M26.115 41.055l-17.19 6.619M19.16 25.909L9.406 46.676M31.113 56.96l-21.16-8.864"
      stroke="#fff"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M10.318 40.667l4.55 11.814-5.934 2.285-4.549-11.814 5.933-2.285z"
      fill="#FAEDFF"
      stroke="#FAEDFF"
    />
    <path
      d="M30.21 42.417a2.628 2.628 0 100-5.256 2.628 2.628 0 000 5.256z"
      fill="#FAEDFF"
    />
    <path
      d="M2 32.105L29.424 40"
      stroke="#FAEDFF"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M52.676 30.887l-6.858 2.648"
      stroke="#fff"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M77.04 3.99l-1.026.518s-.422.221-.7.595c-.298.384-.48.988-.48.998l-.557 1.88-.566-1.88c-.047-.173-.23-.672-.48-.998-.268-.364-.69-.585-.7-.595l-1.035-.518 1.036-.527s.422-.22.7-.585c.297-.394.48-.988.48-.998L74.267 0l.566 1.88c.048.173.23.672.48.998.278.364.7.585.7.585l1.026.527zM11.19 8.94l-1.41.7s-.576.307-.97.816c-.412.527-.661 1.352-.661 1.371l-.758 2.58-.777-2.58c-.067-.23-.316-.93-.662-1.371-.374-.5-.95-.806-.968-.816l-1.42-.7 1.42-.72s.585-.306.968-.805c.413-.537.662-1.353.662-1.372l.758-2.58.777 2.58c.067.23.317.93.662 1.372.384.499.969.805.969.805l1.41.72zM55.141 75.75l-1.093.547s-.451.24-.749.633c-.316.403-.518 1.046-.518 1.065L52.196 80l-.604-2.005c-.058-.182-.25-.72-.518-1.065-.288-.383-.739-.623-.748-.633l-1.104-.546 1.104-.557s.45-.24.748-.623c.316-.422.518-1.046.518-1.065l.585-2.005.604 2.005c.058.182.25.72.518 1.065.298.383.748.623.748.623l1.094.557zM59.679 55.664c-6.629 2.552-16.106-6.033-21.17-19.175-5.065-13.141-3.79-25.86 2.839-28.422M54.835 33.736a3.53 3.53 0 100-7.06 3.53 3.53 0 000 7.06z"
      fill="#fff"
    />
    <path
      d="M3.372 78.273l27.03-38.33"
      stroke="#FAEDFF"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M43.121 45.512l-7.173-18.628-3.92 1.51L39.2 47.022l3.921-1.51zM43.35 57.809L27.87 17.607 16.098 22.14l15.48 40.202 11.772-4.533z"
      fill="#FAEDFF"
    />
    <path
      d="M39.196 47.022l-7.173-18.628-11.772 4.533 7.173 18.628 11.772-4.533z"
      fill="#fff"
    />
    <path
      d="M60.014 22.005c2.475.038 4.834 1.544 5.794 4.038a6.319 6.319 0 01-1.506 6.81M61.674 14.724c5.122.087 10.014 3.195 12.01 8.365a13.06 13.06 0 01-3.127 14.11M26.115 41.055l-17.19 6.619M19.16 25.909L9.406 46.676M31.113 56.96l-21.16-8.864"
      stroke="#fff"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M10.318 40.667l4.55 11.814-5.934 2.285-4.549-11.814 5.933-2.285z"
      fill="#FAEDFF"
      stroke="#FAEDFF"
    />
    <path
      d="M30.21 42.417a2.628 2.628 0 100-5.256 2.628 2.628 0 000 5.256z"
      fill="#FAEDFF"
    />
    <path
      d="M2 32.105L29.424 40"
      stroke="#FAEDFF"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M52.676 30.887l-6.858 2.648"
      stroke="#fff"
      strokeWidth={3.29}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M77.04 3.99l-1.026.518s-.422.221-.7.595c-.298.384-.48.988-.48.998l-.557 1.88-.566-1.88c-.047-.173-.23-.672-.48-.998-.268-.364-.69-.585-.7-.595l-1.035-.518 1.036-.527s.422-.22.7-.585c.297-.394.48-.988.48-.998L74.267 0l.566 1.88c.048.173.23.672.48.998.278.364.7.585.7.585l1.026.527zM11.19 8.94l-1.41.7s-.576.307-.97.816c-.412.527-.661 1.352-.661 1.371l-.758 2.58-.777-2.58c-.067-.23-.316-.93-.662-1.371-.374-.5-.95-.806-.968-.816l-1.42-.7 1.42-.72s.585-.306.968-.805c.413-.537.662-1.353.662-1.372l.758-2.58.777 2.58c.067.23.317.93.662 1.372.384.499.969.805.969.805l1.41.72zM55.141 75.75l-1.093.547s-.451.24-.749.633c-.316.403-.518 1.046-.518 1.065L52.196 80l-.604-2.005c-.058-.182-.25-.72-.518-1.065-.288-.383-.739-.623-.748-.633l-1.104-.546 1.104-.557s.45-.24.748-.623c.316-.422.518-1.046.518-1.065l.585-2.005.604 2.005c.058.182.25.72.518 1.065.298.383.748.623.748.623l1.094.557z"
      fill="#fff"
    />
  </svg>
);
