import { Box, Text } from '@spaceship-fspl/components';
import { FeatherEdit2Icon } from '@spaceship-fspl/icons-web';
import {
  borderColor,
  borderRadius,
  borderWidth,
  borderWidths,
  color,
  fontWeight,
  getSpace,
  paddingLeft,
  paddingRight,
  paddingY,
  text,
  transition,
} from '@spaceship-fspl/styles';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

interface BoostTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isCurrency?: boolean;
  hasErrors?: boolean;
}

export const BoostTextInput = forwardRef<HTMLInputElement, BoostTextInputProps>(
  ({ isCurrency = false, hasErrors = false, ...props }, ref) => {
    return (
      <StyledBoostInputContainer>
        <StyledBoostInput
          {...props}
          ref={ref}
          type="text"
          hasErrors={hasErrors}
          isCurrency={isCurrency}
        />

        {isCurrency && <StyledBoostInputPrefix>$</StyledBoostInputPrefix>}

        <StyledBoostEditIconContainer>
          <FeatherEdit2Icon size="sm" />
        </StyledBoostEditIconContainer>
      </StyledBoostInputContainer>
    );
  },
);

BoostTextInput.displayName = 'BoostTextInput';

interface BoostDropdownOption {
  value?: string | number;
  label: string;
}

interface BoostDropdownProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<BoostDropdownOption>;
  hasErrors?: boolean;
  placeholder: string;
}

export const BoostDropdown = forwardRef<HTMLSelectElement, BoostDropdownProps>(
  ({ hasErrors = false, options, ...props }, ref) => {
    return (
      <StyledBoostInputContainer>
        <StyledBoostDropdown {...props} ref={ref} hasErrors={hasErrors}>
          {options.map(({ value, label }) => (
            <option key={value} value={value === undefined ? label : value}>
              {label}
            </option>
          ))}
        </StyledBoostDropdown>

        <StyledBoostEditIconContainer>
          <FeatherEdit2Icon size="sm" />
        </StyledBoostEditIconContainer>
      </StyledBoostInputContainer>
    );
  },
);

BoostDropdown.displayName = 'BoostDropdown';

const StyledBoostInputPrefix = styled(Text).attrs({
  variant: 2,
  isBold: true,
  component: 'div',
})`
  ${transition}
  ${color('indigo.070')}
  position: absolute;
  pointer-events: none;
  left: calc(${getSpace('sm')} + ${borderWidths.md});
  top: 50%;
  transform: translateY(-50%);
`;

const StyledBoostEditIconContainer = styled(Box).attrs({
  position: 'absolute',
})`
  ${transition}
  ${color('indigo.070')}
  line-height: 0;
  pointer-events: none;
  right: calc(${getSpace('sm')} + ${borderWidths.md});
  top: 50%;
  transform: translateY(-50%);
`;

const boostInputStyles = css<{
  hasErrors: boolean;
  isCurrency?: boolean;
}>`
  ${text({ variant: 2 })}
  ${color('neutral.100')}
  ${fontWeight(600)}
  ${borderColor('indigo.070')}
  ${borderRadius('xs')}
  ${borderWidth('md')}
  ${({ isCurrency }) => (isCurrency ? paddingLeft('lg') : paddingLeft('sm'))}
  ${paddingRight('lg')}
  ${paddingY('none')}
  ${transition}
  background: none;
  border-style: dashed;
  display: inline-flex;
  align-items: center;
  height: 40px;
  outline: none;

  ::placeholder {
    ${color('indigo.070')}
    ${transition}
  }

  :not(:placeholder-shown),
  :focus {
    & ~ ${StyledBoostInputPrefix}, & ~ ${StyledBoostEditIconContainer} {
      ${color('neutral.100')}
    }
  }

  :not(:placeholder-shown):not(:focus) {
    ${borderColor('neutral.070')}
  }

  :focus::placeholder {
    ${color('neutral.070')}
  }

  ${({ hasErrors }) =>
    hasErrors
      ? css`
          :not(:placeholder-shown):not(:focus),
          :not(:focus),
          ::placeholder,
          :not(:focus)
            ~ ${StyledBoostInputPrefix},
            :not(:focus)
            ~ ${StyledBoostEditIconContainer} {
            ${borderColor('red.100')}
            ${color('red.100')}
          }
        `
      : ''}
`;

const StyledBoostInput = styled.input`
  ${boostInputStyles}
  width: 80px;
`;

const StyledBoostDropdown = styled.select`
  ${boostInputStyles}
  appearance: none;
  width: 140px;

  ::-ms-expand {
    display: none;
  }
`;

const StyledBoostInputContainer = styled(Box).attrs({
  display: 'inline-flex',
  position: 'relative',
})``;
