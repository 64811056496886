import { gql } from '@apollo/client';
import {
  Box,
  Card,
  Heading,
  Pill,
  Stack,
  Text,
  UnstyledButton,
  Visible,
} from '@spaceship-fspl/components';
import { FeatherArrowRightIcon, FeatherXIcon } from '@spaceship-fspl/icons-web';
import {
  borderRadiusBottomRight,
  borderRadiusTopRight,
  getColor,
  getSpace,
  match,
  transition,
  zIndex,
} from '@spaceship-fspl/styles';
import boostsLaunch from 'assets/animations/boosts_launch.json';
import { RouterLink } from 'components/router-link';
import Lottie from 'lottie-react';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

export const voyagerBoostsCardFragment = gql`
  fragment webAppVoyagerBoostsCard on Contact {
    id
    account {
      id
      saverBoostRecipes {
        id
      }
    }
  }
`;

export const BoostsCard: React.FC<
  React.PropsWithChildren<{
    onDismiss: () => void;
  }>
> = (props) => {
  return (
    <Card
      postion="relative"
      height="100%"
      minHeight={{ xs: 330, md: 200 }}
      backgroundColor="indigo.100"
    >
      <StyledDismissButton onClick={props.onDismiss}>
        <FeatherXIcon color="neutral.030" />
      </StyledDismissButton>

      <RouterLink
        to={Routes.BOOSTS_INTRO}
        trackingProperties={{ name: 'boosts_card_link' }}
      >
        <Box
          position={{ xs: 'unset', md: 'absolute' }}
          top={0}
          right={0}
          width={{ xs: '100%', md: '50%' }}
          paddingX={{ xs: 'xxxs', md: 'none' }}
          paddingTop={{ xs: 'lg', md: 'none' }}
          height="100%"
        >
          <Box display="flex" height="100%" width="100%">
            <Visible isHidden={{ xs: true, md: false }} displayValue="block">
              <StyledLottieOverlay />
            </Visible>
            <Lottie animationData={boostsLaunch} />
          </Box>
        </Box>

        <Box
          paddingX={{ xs: 'md', md: 'lg' }}
          paddingBottom="lg"
          paddingTop={{ md: 'lg' }}
        >
          <Box
            width={{ xs: '100%', md: '50%' }}
            maxWidth={{ xs: '100%', md: 400 }}
          >
            <Stack
              spaceY={{ xs: 'xxs', md: 'xs' }}
              alignX={{ xs: 'center', md: 'left' }}
            >
              <Pill variant="success">New</Pill>
              <Heading
                variant={4}
                color="neutral.000"
                isBold={true}
                align={{ xs: 'center', md: 'left' }}
              >
                Make the most of everyday moments with Boosts
              </Heading>
              <Box display="flex" alignItems="center">
                <Text variant={2} color="neutral.000" isBold={true}>
                  Explore Boosts
                </Text>
                <Box marginLeft="xxs">
                  <FeatherArrowRightIcon color="neutral.000" />
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </RouterLink>
    </Card>
  );
};

const StyledDismissButton = styled(UnstyledButton)`
  position: absolute;
  top: ${getSpace('sm')};
  right: ${getSpace('sm')};
  opacity: 0.4;
  ${zIndex(2)};
  ${transition}

  &:hover {
    opacity: 1;
  }
`;

const gradientColor = getColor('indigo.100');

const StyledLottieOverlay = styled.div`
  position: absolute;
  background: linear-gradient(
    90deg,
    ${gradientColor} 5%,
    transparent 20%,
    transparent 80%,
    ${gradientColor} 95%,
    ${gradientColor} 100%
  );
  width: 100%;
  height: 100%;
  ${zIndex(1)}
  ${borderRadiusTopRight('md')}
  ${borderRadiusBottomRight('md')}

  ${match('xl')`
  background: linear-gradient(90deg, ${gradientColor} 10%, transparent 20%, transparent 80%, ${gradientColor} 90%, ${gradientColor} 100%);
  `};
`;
