import * as React from 'react';
export const StreamlineButtonLoopArrow = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M13.858 14.687a5.425 5.425 0 001.764 1.475 5.655 5.655 0 002.245.636c1.361 0 2.667-.52 3.63-1.444A4.83 4.83 0 0023 11.87a4.83 4.83 0 00-1.503-3.485 5.244 5.244 0 00-3.63-1.443C14.2 6.942 12 11.87 12 11.87s-2.2 4.928-5.867 4.928a5.244 5.244 0 01-3.63-1.444A4.83 4.83 0 011 11.87c0-1.307.54-2.56 1.504-3.485a5.244 5.244 0 013.63-1.443 6.669 6.669 0 012.433.695 6.421 6.421 0 011.966 1.541" />
    <path vectorEffect="non-scaling-stroke" d="M9.931 5.706l.603 3.473-3.617.579" />
  </svg>
);
