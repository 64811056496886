import { Color, color, fontFamily, map } from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled from 'styled-components';

import { DistributivePick } from '../../types';
import { UnstyledLink, UnstyledLinkProps } from '../unstyled-link';

const hoverColorVariant = createVariant({
  'indigo.070': color('indigo.090'),
  'indigo.090': color('indigo.100'),
  'neutral.000': color('neutral.030'),
  'neutral.080': color('neutral.100'),
});

const Wrapper = styled(UnstyledLink)<{
  $color?: ColorPropType;
}>`
  color: inherit;
  ${createProps({ $color: color })}
  ${fontFamily('text')}
  font-size: inherit;
  text-decoration: underline;
  transition: 0.15s color;
  ${({ theme }) => theme.textLinkStyles}

  :active,
  :visited,
  :hover {
    ${({ $color }) => {
      if ($color === undefined) {
        return color('indigo.090');
      }
      return map($color, (c) => hoverColorVariant(c));
    }}
  }
`;

type ColorPropType = DistributivePick<
  Color,
  'indigo.070' | 'indigo.090' | 'neutral.000' | 'neutral.080'
>;

export interface TextLinkProps extends UnstyledLinkProps {
  color?: ColorPropType;
}

export const TextLink = React.forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ color, children, ...otherProps }, ref) => {
    return (
      <Wrapper $color={color} {...otherProps} ref={ref}>
        {children}
      </Wrapper>
    );
  },
);

TextLink.displayName = 'TextLink';
