import * as React from 'react';
export const StreamlineLegalScale1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 22.5v-18M7.5 22.5h9M13.5 3h5.25M5.25 3h5.25M10.5 3a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM9 13.5a3.75 3.75 0 11-7.5 0H9zM5.25 3L1.5 10.5M5.25 3L9 10.5M22.5 13.5a3.75 3.75 0 11-7.5 0h7.5zM18.75 3L15 10.5M18.75 3l3.75 7.5" />
  </svg>
);
