import { Color, getColor } from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

type SpinnerSize = 'sm' | 'md' | 'lg';

const SpinnerConfig: {
  [s in SpinnerSize]: {
    stroke: number;
    diameter: number;
  };
} = {
  sm: {
    diameter: 16,
    stroke: 3,
  },
  md: {
    diameter: 32,
    stroke: 4,
  },
  lg: {
    diameter: 64,
    stroke: 6,
  },
};

const dash = keyframes`
  0% {
    stroke-dasharray: 0, 250;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 250;
    stroke-dashoffset: -100%;
  }
  100% {
    stroke-dasharray: 120, 250;
    stroke-dashoffset: -260%;
  }
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export interface CircleProps {
  color: Color;
  isAnimated: boolean;
  size: SpinnerSize;
}

const Circle = styled.circle<CircleProps>`
  ${({ color, size }) => ({
    stroke: getColor(color),
    strokeWidth: SpinnerConfig[size].stroke,
  })}
  ${({ isAnimated }) =>
    isAnimated &&
    css`
      animation: ${dash} 1.5s ease-in-out infinite;
    `}
`;

const SpinnerSVG = styled.svg<{ size: SpinnerSize }>`
  animation: ${rotate} 1s linear infinite;
  ${({ size }) => ({
    width: SpinnerConfig[size].diameter,
    height: SpinnerConfig[size].diameter,
  })}
`;

export interface SpinnerProps {
  color?: Color;
  bgColor?: Color;
  size?: SpinnerSize;
}

export const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({
  color = 'indigo.070',
  bgColor = 'neutral.050',
  size = 'md',
}) => {
  const { diameter, stroke } = SpinnerConfig[size];
  const common = {
    cx: diameter / 2,
    cy: diameter / 2,
    r: diameter / 2 - stroke,
    size,
    fill: 'none',
  };
  return (
    <SpinnerSVG viewBox={`0 0 ${diameter} ${diameter}`} size={size}>
      <Circle {...common} color={bgColor} isAnimated={false} />
      <Circle {...common} color={color} isAnimated={true} />
    </SpinnerSVG>
  );
};
