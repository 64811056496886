import { gql } from '@apollo/client';
import { navigate } from '@reach/router';
import {
  Box,
  Divider,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { TaxGroup, TaxResidenceStatus } from '@spaceship-fspl/graphql';
import {
  webAppVoyagerTaxResidencyCard,
  webAppVoyagerTaxResidencyCard_foreignTaxResidences,
} from '@spaceship-fspl/graphql/src/__generated__/webAppVoyagerTaxResidencyCard';
import { StreamlineEarthCashIcon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { toLongDate } from 'helpers/format';
import { Routes } from 'pages/routes';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from './components';

export const voyagerTaxResidencyCardFragment = gql`
  fragment webAppVoyagerTaxResidencyCard on Contact {
    id
    isTaxResidencyEditable
    taxResidenceStatus
    taxGroup
    lastUpdatedAt
    address {
      country
    }
    foreignTaxResidences {
      id
      primaryResidence
      tinProvided
      createdAt
      lastUpdatedAt
      country {
        id
        name
        countryCode
      }
      missingTINDetails {
        active
        reason
        otherReasonDescription
      }
    }
  }
`;

export const VoyagerTaxResidencyCard: React.FC<
  React.PropsWithChildren<{
    isLoading: boolean;
    contact?: webAppVoyagerTaxResidencyCard | null;
  }>
> = ({ isLoading, contact }) => {
  const residences = contact?.foreignTaxResidences ?? [];
  const status = contact?.taxResidenceStatus;

  const isOnboardingFlow = window.location.pathname.includes(
    Routes.VOYAGER_ONBOARDING_SUMMARY,
  );

  // These values should be undefined in onboarding flow.
  let ftrLastUpdated: Date | undefined;
  let contactLastUpdated: Date | undefined;

  if (!isOnboardingFlow) {
    const firstResidence = residences
      .slice()
      .sort((a, b) => (a.lastUpdatedAt < b.lastUpdatedAt ? 1 : -1))[0];
    if (firstResidence) {
      ftrLastUpdated = new Date(firstResidence.lastUpdatedAt);
    }

    if (contact?.lastUpdatedAt) {
      contactLastUpdated = new Date(contact.lastUpdatedAt);
    }
  }

  let content;

  switch (true) {
    case status !== TaxResidenceStatus.SPECIFIED:
      content = <UpdateTaxResidency />;
      break;

    case contact?.taxGroup === TaxGroup.AUSTRALIA_ONLY ||
      residences.length === 0:
      content = <NoForeignTaxStatus lastUpdated={contactLastUpdated} />;
      break;

    default:
      content = (
        <WithForeignTaxStatus
          residences={residences}
          taxGroup={contact?.taxGroup}
          lastUpdated={ftrLastUpdated}
        />
      );
      break;
  }

  return (
    <AccountCard>
      <AccountCardHeading
        title="Tax residency"
        edit={
          status === TaxResidenceStatus.SPECIFIED
            ? contact?.isTaxResidencyEditable
              ? {
                  isEditing: false,
                  to: isOnboardingFlow
                    ? Routes.VOYAGER_ONBOARDING_TAX_RESIDENCY
                    : Routes.VOYAGER_TAX_RESIDENCY,
                }
              : {
                  isEditing: false,
                  to: Routes.VOYAGER_TAX_RESIDENCY_NOT_EDITABLE,
                }
            : undefined
        }
      />

      <AccountCardContent isLoading={isLoading}>{content}</AccountCardContent>
    </AccountCard>
  );
};

const WithForeignTaxStatus: React.FC<
  React.PropsWithChildren<{
    lastUpdated?: Date;
    taxGroup?: TaxGroup;
    residences: webAppVoyagerTaxResidencyCard_foreignTaxResidences[];
  }>
> = (props) => {
  return (
    <>
      {props.lastUpdated && (
        <Box marginBottom="sm">
          <Text variant={4} color="neutral.080" isBold={true}>
            LAST UPDATED – {toLongDate(props.lastUpdated)}
          </Text>
        </Box>
      )}

      {!!props.taxGroup && props.taxGroup !== TaxGroup.UNSPECIFIED ? (
        <Text variant={2}>
          {props.taxGroup === TaxGroup.AUSTRALIA_AND_OVERSEAS && (
            <>
              You’ve told us you’re a tax resident of <strong>Australia</strong>{' '}
              and <strong>these countries</strong>:
            </>
          )}

          {props.taxGroup === TaxGroup.OVERSEAS_ONLY && (
            <>
              You’ve told us that you’re <strong>not</strong> a tax resident of
              Australia, but you are a tax resident of{' '}
              <strong>these countries</strong>:
            </>
          )}
        </Text>
      ) : (
        <Text variant={2}>
          You have reported that you are a tax resident of countries other than
          Australia including:
        </Text>
      )}

      <Box marginTop="sm">
        {props.residences?.map((residence, index) => (
          <React.Fragment key={`${residence.id}`}>
            {index === 0 && <Divider size="sm" color="neutral.030" />}

            <Box paddingTop="sm">
              <Inline spaceX="sm" alignY="center" alignX="left">
                <Text variant={2} isBold={true}>
                  {residence.country.name}
                </Text>

                <Text variant={3} color="neutral.080">
                  {residence.tinProvided ? 'TIN provided' : 'TIN not provided'}
                </Text>
              </Inline>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

const NoForeignTaxStatus: React.FC<
  React.PropsWithChildren<{ lastUpdated?: Date | null }>
> = ({ lastUpdated }) => {
  return (
    <>
      {lastUpdated && (
        <Box marginBottom="sm">
          <Text variant={4} color="neutral.080" isBold={true}>
            LAST UPDATED – {toLongDate(lastUpdated)}
          </Text>
        </Box>
      )}

      <Text variant={2}>
        You’ve told us you’re a tax resident of <strong>Australia only</strong>.
      </Text>
    </>
  );
};

const UpdateTaxResidency: React.FC<React.PropsWithChildren> = () => (
  <Stack spaceY="sm" alignX="center">
    <StreamlineEarthCashIcon color="indigo.070" size="lg" />

    <Heading variant={5} align="center">
      Spaceship needs you to update your <br />
      tax residency details
    </Heading>

    <Button
      variant="primary"
      size="sm"
      onClick={(): void => {
        if (
          window.location.pathname.includes(Routes.VOYAGER_ONBOARDING_SUMMARY)
        ) {
          navigate(Routes.VOYAGER_ONBOARDING_TAX_RESIDENCY);
        } else {
          navigate(Routes.VOYAGER_TAX_RESIDENCY);
        }
      }}
      trackingProperties={{
        name: 'tax_residency_update_button',
      }}
    >
      Update now
    </Button>
  </Stack>
);
