import { Loader } from '@googlemaps/js-api-loader';
import { config } from 'helpers/config';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface GoogleMapsServices {
  countryRestrictions?: string;
  isLoading?: boolean;
  placesService?: google.maps.places.PlacesService;
  autoCompleteService?: google.maps.places.AutocompleteService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
}

const GoogleMapsServicesContext = createContext<GoogleMapsServices | undefined>(
  undefined,
);

export const GoogleMapsServicesProvider: React.FC<
  React.PropsWithChildren<{
    countryRestrictions?: string;
    placesService?: google.maps.places.PlacesService;
    autoCompleteService?: google.maps.places.AutocompleteService;
    sessionToken?: google.maps.places.AutocompleteSessionToken;
  }>
> = ({
  children,
  countryRestrictions,
  autoCompleteService,
  sessionToken,
  placesService,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const sessionTokenRef = useRef<
    google.maps.places.AutocompleteSessionToken | undefined
  >(sessionToken);
  const placesServiceRef = useRef<google.maps.places.PlacesService | undefined>(
    placesService,
  );
  const autoCompleteServiceRef = useRef<
    google.maps.places.AutocompleteService | undefined
  >(autoCompleteService);

  const loadGoogleMapsServices = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const loader = new Loader({
      apiKey: config.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
    });
    await loader.load();
    const googleMapElement = new google.maps.Map(document.createElement('div'));

    sessionTokenRef.current = new google.maps.places.AutocompleteSessionToken();
    placesServiceRef.current = new google.maps.places.PlacesService(
      googleMapElement,
    );
    autoCompleteServiceRef.current =
      new google.maps.places.AutocompleteService();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // we can't load google maps when testing
    if (!config.isRunningTests) {
      loadGoogleMapsServices();
    }
  }, [loadGoogleMapsServices]);

  return (
    <GoogleMapsServicesContext.Provider
      value={{
        isLoading,
        sessionToken: sessionTokenRef.current,
        placesService: placesServiceRef.current,
        autoCompleteService: autoCompleteServiceRef.current,
        countryRestrictions,
      }}
    >
      {children}
    </GoogleMapsServicesContext.Provider>
  );
};

export const useGoogleMapsServices = (): GoogleMapsServices => {
  const context = useContext(GoogleMapsServicesContext);

  if (!context) {
    throw new Error(
      'useGoogleMapsServices must be wrapped with GoogleMapsServicesProvider',
    );
  }

  return context;
};
