import { navigate, RouteComponentProps } from '@reach/router';
import { SuperPortfolio } from '@spaceship-fspl/graphql';
import {
  useGetCurrentInvestmentAllocation,
  useGetInvestmentOptions,
  useGetInvestmentsBalance,
  useRequestInvestmentRebalanceTransfer,
  useUpdateFutureCashAllocations,
} from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { useNotifications } from 'contexts/notifications';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { createContext, useCallback, useState } from 'react';

import { useSuperOnboarding } from './onboarding';

export type InvestmentOptionPageVariant = 'onboarding' | 'change' | 'add';

interface InvestmentOption {
  variant: InvestmentOptionPageVariant;
  portfolio?: SuperPortfolio;
  onSelectPortfolio: (portfolio: SuperPortfolio) => Promise<void>;
  onTMDEligible?: (portfolio: SuperPortfolio) => Promise<void>;
  isLoading: boolean;
}

const SuperInvestmentOptionContext = createContext<
  InvestmentOption | undefined
>(undefined);

export interface ProviderProps {
  variant: InvestmentOptionPageVariant;
}

const PORTFOLIO_ALREADY_SELECTED_ERROR = 'portfolio already selected';

export const SuperChangeInvestmentOptionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateInvestmentOption = useUpdateInvestmentOption();
  const currentInvestmentAllocation = useGetCurrentInvestmentAllocation();
  const notification = useNotifications();

  const onSelectPortfolio = async (
    portfolio: SuperPortfolio,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await updateInvestmentOption(portfolio);
      setIsLoading(false);
      navigate(Routes.SUPER_CHANGE_INVESTMENT_OPTION_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      addRumError({
        error,
        context: { reason: 'Change super investment option failed' },
      });
      if (
        (error as Error | undefined)?.message ===
        PORTFOLIO_ALREADY_SELECTED_ERROR
      ) {
        notification.popToast({
          level: 'warning',
          message: 'You are already invested in this option.',
        });
      } else {
        notification.popToast({
          level: 'error',
          message:
            "Oops, something has gone wrong. Contact our customer support team and we'll get you back on track.",
        });
      }
    }
  };

  return (
    <SuperInvestmentOptionContext.Provider
      value={{
        variant: 'change',
        portfolio:
          currentInvestmentAllocation.investmentAllocation?.key?.toUpperCase() as SuperPortfolio,
        onSelectPortfolio,
        isLoading: isLoading || currentInvestmentAllocation.isLoading,
      }}
    >
      {children}
    </SuperInvestmentOptionContext.Provider>
  );
};

export const SuperAddInvestmentOptionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [portfolio, setPortfolio] = useState<SuperPortfolio | undefined>(
    undefined,
  );
  const updateInvestmentOption = useUpdateInvestmentOption();
  const notification = useNotifications();

  const onSelectPortfolio = async (
    portfolio: SuperPortfolio,
  ): Promise<void> => {
    setPortfolio(portfolio);
  };

  const onTMDEligible = async (
    selectedPortfolio: SuperPortfolio,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await updateInvestmentOption(selectedPortfolio);
      onSelectPortfolio(selectedPortfolio);
      setIsLoading(false);
      navigate(Routes.SUPER_SELECT_INVESTMENT_OPTION_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      addRumError({
        error,
        context: { reason: 'Add super investment option failed' },
      });
      if (
        (error as Error | undefined)?.message ===
        PORTFOLIO_ALREADY_SELECTED_ERROR
      ) {
        notification.popToast({
          level: 'warning',
          message: 'You are already invested in this option.',
        });
      } else {
        notification.popToast({
          level: 'error',
          message:
            "Oops, something has gone wrong. Contact our customer support team and we'll get you back on track.",
        });
      }
    }
  };

  return (
    <SuperInvestmentOptionContext.Provider
      value={{
        variant: 'add',
        isLoading,
        portfolio,
        onSelectPortfolio,
        onTMDEligible,
      }}
    >
      {children}
    </SuperInvestmentOptionContext.Provider>
  );
};

export const SuperOnboardingInvestmentOptionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const track = useTrack();
  const [isLoading, setIsLoading] = useState(false);
  const { portfolio, setPortfolio } = useSuperOnboarding();

  const onSelectPortfolio = useCallback(
    async (selectedPortfolio: SuperPortfolio) => {
      setIsLoading(true);
      setPortfolio(selectedPortfolio);
      track?.(MarketingTrackingEvents.SUPER_ONBOARDING_PORTFOLIO_CONFIRM);
      setIsLoading(false);
    },
    [track, setPortfolio],
  );

  const onTMDEligible = async (portfolio: SuperPortfolio): Promise<void> => {
    onSelectPortfolio(portfolio);
    navigate(Routes.SUPER_SIGNUP_GENDER);
  };

  return (
    <SuperInvestmentOptionContext.Provider
      value={{
        variant: 'onboarding',
        portfolio,
        onSelectPortfolio,
        onTMDEligible,
        isLoading,
      }}
    >
      {children}
    </SuperInvestmentOptionContext.Provider>
  );
};

export const useContext = (): InvestmentOption => {
  const context = React.useContext(SuperInvestmentOptionContext);

  if (!context) {
    throw new Error('webapp: Please wrap in <SuperInvestmentOptionProvider />');
  }

  return context;
};

const useUpdateInvestmentOption = (): ((
  portfolio: SuperPortfolio,
) => Promise<void>) => {
  const currentInvestmentAllocation = useGetCurrentInvestmentAllocation();
  const investmentOptions = useGetInvestmentOptions();
  const updateFutureCashAllocations = useUpdateFutureCashAllocations();
  const investmentsBalance = useGetInvestmentsBalance();
  const requestInvestmentRebalanceTransfer =
    useRequestInvestmentRebalanceTransfer();
  const investmentOptionsData = investmentOptions.data;
  const investmentOptionsRefetch = investmentOptions.refetch;

  const update = useCallback(
    async (portfolio: SuperPortfolio) => {
      const data =
        investmentOptionsData ?? (await investmentOptionsRefetch()).data;
      const selectedPortfolio = data?.find(
        (o) => o.key.toUpperCase() === portfolio,
      );

      if (selectedPortfolio?.id && selectedPortfolio?.key) {
        if (
          selectedPortfolio.id ===
          currentInvestmentAllocation.investmentAllocation?.id
        ) {
          throw Error(PORTFOLIO_ALREADY_SELECTED_ERROR);
        }

        const percent = 100;

        await updateFutureCashAllocations.mutateAsync({
          id: selectedPortfolio.id,
          key: selectedPortfolio.key,
          percent,
        });

        if (investmentsBalance.data && investmentsBalance.data.length > 0) {
          await requestInvestmentRebalanceTransfer.mutateAsync({
            id: selectedPortfolio.id,
            percent,
          });
        }
      } else {
        throw Error('invalid portfolio');
      }
    },
    [
      currentInvestmentAllocation.investmentAllocation,
      investmentOptionsRefetch,
      investmentOptionsData,
      investmentsBalance.data,
      requestInvestmentRebalanceTransfer,
      updateFutureCashAllocations,
    ],
  );

  return update;
};
