import { RouteComponentProps } from '@reach/router';
import { Columns, Stack, Text } from '@spaceship-fspl/components';
import { getColor, padding, text } from '@spaceship-fspl/styles';
import { PageContainer, PageHeading } from 'components/layouts/page';
import React from 'react';
import styled from 'styled-components';

export const VoyagerSRM: React.FC<RouteComponentProps> = () => {
  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
          <Stack spaceY="md">
            <PageHeading title="Standard risk measure (SRM)" />

            <Text variant={2}>
              SRM is an industry measure to help customers understand the risk
              level of different investment options through an estimated number
              of negative annual returns over any 20 year period.
            </Text>

            <Text variant={2} isBold={true}>
              As the Spaceship Voyager portfolios all predominantly invest in
              shares, which is a growth investment, they have a higher risk and
              return profile than defensive investments like cash and bonds.
            </Text>

            <Text variant={2} isBold={true}>
              The Spaceship Universe Portfolio and Spaceship Earth Portfolio
              each have a SRM band of 7.
            </Text>

            <Text variant={2} isBold={true}>
              The Spaceship Origin Portfolio has a SRM band of 6.
            </Text>

            <StyledRiskTable>
              <thead>
                <tr>
                  <th>Risk band</th>
                  <th>Risk label</th>
                  <th>
                    Estimated number of negative annual returns over any 20-year
                    period
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Very low</td>
                  <td>&lt; 0.5</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Low</td>
                  <td>0.5 - 1</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Low to medium</td>
                  <td>1 - 2</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Medium</td>
                  <td>2 - 3</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Medium to high</td>
                  <td>3 - 4</td>
                </tr>

                <tr>
                  <td>
                    <strong>6</strong>
                  </td>
                  <td>
                    <strong>High</strong>
                  </td>
                  <td>
                    <strong>4 - 6</strong>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>7</strong>
                  </td>
                  <td>
                    <strong>Very high</strong>
                  </td>
                  <td>
                    <strong>&gt; 6</strong>
                  </td>
                </tr>
              </tbody>
            </StyledRiskTable>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

const StyledRiskTable = styled.table`
  ${text({ variant: 3 })}
  border-collapse: collapse;

  thead th {
    font-weight: 600;
    text-align: left;
  }

  tbody tr {
    border-top: 2px solid ${getColor('indigo.070')};
  }

  th,
  td {
    ${padding('xxs')}
    :not(:first-child) {
      border-left: 2px solid ${getColor('indigo.070')};
    }
  }

  strong {
    font-weight: 700;
  }
`;
