export enum TestId {
  LOADER = 'LOADER',
  ANIMATED_TRANSACTION_ITEM = 'animated_transaction_item',
  MONEY_ACTIVITY_CARD = 'money_activity_card',
  MONEY_TRANSACTION_TYPE_FILTER = 'money_transaction_type_filter',
  MONEY_TRANSACTION_DETAILS = 'money_transaction_details',
  MONEY_TRANSACTION_SUMMARY = 'money_transaction_summary',
  MONEY_TRANSACTION_STATUS = 'money_transaction_status',
  MONEY_TRANSACTION_LIST = 'money_transaction_list',
  MONEY_AVAILABLE_MONEY_CARD = 'money_available_money_card',
  MONEY_READY_TO_INVEST_CARD = 'money_ready_to_invest_card',
  MONEY_LOAD_MORE_TRANSACTIONS_BUTTON = 'money_load_more_transactions_button',
  MONEY_DEPOSIT_PAGE = 'money_deposit_page',
  MONEY_DEPOSIT_PAGE_MAX_LIMIT_EXCEEDED = 'money_deposit_page_max_limit_exceeded',
  MONEY_DEPOSIT_PAGE_GO_BACK_BUTTON = 'money_deposit_page_go_back_button',
  MONEY_DEPOSIT_PAGE_CONTINUE_BUTTON = 'money_deposit_page_continue_button',
  MONEY_DEPOSIT_PAGE_CANCEL_BUTTON = 'money_deposit_page_cancel_button',
  MONEY_DEPOSIT_PAGE_CONTACT_SUPPORT_BUTTON = 'money_deposit_page_contact_support_button',
  MONEY_DEPOSIT_PAGE_NOW_AMOUNT = 'money_deposit_success_page_now_amount',
  MONEY_DEPOSIT_PAGE_AFTER_AMOUNT = 'money_deposit_success_page_after_amount',
  MONEY_DEPOSIT_PAGE_ETA_DATE = 'money_deposit_page_eta_date',
  MONEY_DEPOSIT_CONFIRM_PAGE = 'money_deposit_confirm_page',
  MONEY_DEPOSIT_CONFIRM_PAGE_CONFIRM_BUTTON = 'money_deposit_confirm_page_confirm_button',
  MONEY_DEPOSIT_CONFIRM_PAGE_CANCEL = 'money_deposit_confirm_page_cancel',
  MONEY_OUTSTANDING_BALANCE_CARD = 'money_outstanding_balance_card',
  MONEY_WITHDRAW_CONFIRM_PAGE = 'money_withdraw_confirm_page',
  MONEY_WITHDRAW_PAGE_CANCEL_BUTTON = 'money_withdraw_page_cancel_button',
  MONEY_WITHDRAW_PAGE_NOW_AMOUNT = 'money_withdraw_page_now_amount',
  MONEY_WITHDRAW_PAGE_MIN_AMOUNT_INFO_PANEL = 'money_withdraw_page_min_amount_info_panel',
  MONEY_WITHDRAW_PAGE_NO_AVAILABLE_FUNDS_INFO_PANEL = 'money_withdraw_page_no_available_funds_info_panel',
  MONEY_WITHDRAW_PAGE_GO_BACK_BUTTON = 'money_withdraw_page_go_back_button',
  MONEY_WITHDRAW_PAGE_CONTACT_SUPPORT_BUTTON = 'money_withdraw_page_contact_support_button',
  MONEY_TRANSACTION_SUCCESS_PAGE = 'money_transaction_success_page',
  MONEY_TRANSACTION_SUCCESS_PAGE_SUMMARY_CARD = 'money_transaction_success_page_summary_card',
  MONEY_TRANSACTION_SUCCESS_PAGE_CONTENTS = 'money_transaction_success_page_contents',
  MONEY_TRANSACTION_SUCCESS_PAGE_INFO_BOX = 'money_transaction_success_page_info_box',
  MONEY_ACCOUNT_VERIFICATION_INFO_PANEL = 'money_account_verification_info_panel',
  MONEY_PENDING_ORDERS = 'money_pending_orders',
  VOYAGER_ONE_OFF_DEPOSIT_INPUT = 'voyager_one_off_deposit_input',
  VOYAGER_ONE_OFF_DEPOSIT_FORM_ETA_BOX = 'voyager_one_off_deposit_form_eta_box',
  VOYAGER_ONE_OFF_INFO_PANEL_INVESTMENT_MORE_THAN_AVAILABLE_MONEY = 'voyager_one_off_info_panel_investment_more_than_available_money',
  VOYAGER_WITHDRAW_PAGE = 'voyager_withdraw_page',
  VOYAGER_WITHDRAW_CONFIRM_PAGE = 'voyager_withdraw_confirm_page',
  VOYAGER_WITHDRAW_INFO_ACCORDION = 'voyager_withdraw_info_accordion',
  VOYAGER_ONE_OFF_CONFIRM_PAGE = 'voyager_one_off_confirm_page',
  TRANSACTION_ITEM_SUMMARY = 'transaction_item_summary',
  TRANSACTION_ITEM_CONTENT = 'transaction_item_content',
}
