import { Member } from '@sargon/api-client';

import { useGetMember } from '../react';

const SuppliedTFNStatuses = [
  Member.TaxIdStatusEnum.Supplied,
  Member.TaxIdStatusEnum.Verified,
  Member.TaxIdStatusEnum.Override,
];

export const useHasSuppliedTfn = (): boolean | undefined => {
  const resp = useGetMember();
  return resp?.isLoading
    ? undefined
    : !!resp.data?.taxIdStatus &&
        SuppliedTFNStatuses.includes(resp.data.taxIdStatus);
};
