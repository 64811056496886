import { Member, MemberUpdate } from '@sargon/api-client';

import { ApiErrorCodes } from '../error';
import { useUpdateMember, UseUpdateMemberResults } from '../react';

export interface UserUpdateMemberDetailsResults
  extends Omit<UseUpdateMemberResults, 'mutateAsync'> {
  updateMember: (
    accountId: string | undefined,
    payload: MemberUpdate,
  ) => Promise<Member | undefined>;
}

// This hook is to automatically add the `externalIdpUserId` with the user's
// `contact.account_id` when updating member details. If the account_id is
// missing, an error is thrown.
export const useUpdateMemberDetails = (): UserUpdateMemberDetailsResults => {
  const { mutateAsync: update, ...mutateProps } = useUpdateMember();

  const updateMember = async (
    accountId: string | undefined,
    payload: MemberUpdate,
  ): Promise<Member | undefined> => {
    if (!accountId) {
      throw Error(ApiErrorCodes.UPDATE_MEMBER_MISSING_ACCOUNT_ID);
    }
    return await update({
      externalIdpUserId: accountId,
      ...payload,
    });
  };

  return { updateMember, ...mutateProps };
};
