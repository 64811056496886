import * as React from 'react';
export const StreamlineSingleNeutralShield = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M22.85 9.98a13.05 13.05 0 01-10.875 12.87A13.05 13.05 0 011.1 9.98V2.55c0-.8.65-1.45 1.45-1.45H21.4c.8 0 1.45.65 1.45 1.45v7.43z" />
    <path vectorEffect="non-scaling-stroke" d="M11.975 11.975a3.263 3.263 0 100-6.525 3.263 3.263 0 000 6.525zM17.183 16.567a5.831 5.831 0 00-10.415 0" />
  </svg>
);
