import { Box } from '@spaceship-fspl/components';
import { borderColor, borderWidth, transition } from '@spaceship-fspl/styles';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { RadioProps } from './radio';

export type RadioButtonProps = Omit<RadioProps, 'radioPosition'>;

const RadioButton: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioButtonProps
> = ({ children, error, name, value, ...props }, ref) => {
  const id = `${name}_${value}`;
  return (
    <Box position="relative" height="100%">
      <StyledRadioInput
        {...props}
        name={name}
        value={value}
        type="radio"
        id={id}
        ref={ref}
      />
      <StyledBox
        isDisabled={props.disabled}
        hasError={!!error}
        backgroundColor="neutral.000"
        borderRadius="xxs"
        boxShadow="sm"
        height="100%"
      >
        <StyledLabel htmlFor={id}>
          <Box padding="sm" height="100%">
            {children}
          </Box>
        </StyledLabel>
      </StyledBox>
    </Box>
  );
};

const ForwardedRadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  RadioButton,
);
export { ForwardedRadioButton as RadioButton };

const StyledRadioInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  height: 100%;
`;

const StyledBox = styled(Box)<{
  hasError: boolean;
  isDisabled?: boolean;
}>`
  ${({ hasError }) => borderColor(hasError ? 'red.100' : 'neutral.000')}
  ${borderWidth('md')}
  border-style: solid;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  ${transition}

  ${StyledRadioInput}:checked ~ & {
    ${borderColor('indigo.070')}
  }
`;
