import { navigate, RouteComponentProps } from '@reach/router';
import { RolloverRequest } from '@sargon/api-client';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { SuperMatchErrorType, useHasSuppliedTfn } from '@spaceship-fspl/super';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Routes } from 'pages/routes';
import React, { createContext, useCallback, useContext, useState } from 'react';

export interface SuperMatch {
  variant: 'onboarding' | 'consolidate';
  hasAccess: boolean;
  fundsToTransfer?: RolloverRequest;
  onSkip?: () => void;
  onCancel: () => void;
  onSuccess: () => void;
  onSuccessContinue: () => void;
  onError: (errorType?: SuperMatchErrorType) => void;
  onErrorContinue: () => void;
  onAddTfn: () => void;
  onConsent: () => void;
  onSelectFunds: (value: RolloverRequest) => void;
  returnToStart: () => void;
}

const SuperMatchContext = createContext<SuperMatch | undefined>(undefined);

export const SuperMatchOnboardingProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [hasConsented, setHasConsented] = useState(false);
  const [fundsToTransfer, setFundsToTransfer] = useState<
    RolloverRequest | undefined
  >();
  const isVerified = useIsStatusVerified(GreenIdRuleSet.Enum.SUPERMATCH);
  const hasSuppliedTfn = useHasSuppliedTfn();

  const navigateToEmployerContribution = useCallback((): void => {
    navigate(Routes.SUPER_SIGNUP_EMPLOYER_CONTRIBUTION);
  }, []);

  const onConsent = useCallback((): void => {
    setHasConsented(true);
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH_GREENID);
  }, []);

  const onSuccess = useCallback((): void => {
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH_SUCCESS);
  }, []);

  const onError = useCallback((errorType?: SuperMatchErrorType): void => {
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH_ERROR, {
      state: { errorType },
    });
  }, []);

  const onAddTfn = useCallback((): void => {
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH_TFN);
  }, []);

  const returnToStart = useCallback((): void => {
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH);
  }, []);

  const onSelectFunds = useCallback((funds: RolloverRequest): void => {
    setFundsToTransfer(funds);
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH_TERMS);
  }, []);

  return (
    <SuperMatchContext.Provider
      value={{
        variant: 'onboarding',
        hasAccess: hasConsented && isVerified && !!hasSuppliedTfn,
        fundsToTransfer,
        onSkip: navigateToEmployerContribution,
        onCancel: navigateToEmployerContribution,
        onSuccess,
        onSuccessContinue: navigateToEmployerContribution,
        onError,
        onErrorContinue: navigateToEmployerContribution,
        onAddTfn,
        onConsent,
        onSelectFunds,
        returnToStart,
      }}
    >
      {children}
    </SuperMatchContext.Provider>
  );
};

export const SuperMatchConsolidateProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [hasConsented, setHasConsented] = useState(false);
  const [fundsToTransfer, setFundsToTransfer] = useState<
    RolloverRequest | undefined
  >();
  const isVerified = useIsStatusVerified(GreenIdRuleSet.Enum.SUPERMATCH);
  const hasSuppliedTfn = useHasSuppliedTfn();

  const navigateToDashboard = useCallback((): void => {
    navigate(Routes.SUPER_DASHBOARD);
  }, []);

  const onConsent = useCallback((): void => {
    setHasConsented(true);
    navigate(Routes.SUPER_SUPERMATCH_GREENID);
  }, []);

  const onSuccess = useCallback((): void => {
    navigate(Routes.SUPER_SUPERMATCH_SUCCESS);
  }, []);

  const onError = useCallback((errorType?: SuperMatchErrorType): void => {
    navigate(Routes.SUPER_SUPERMATCH_ERROR, {
      state: { errorType },
    });
  }, []);

  const onAddTfn = useCallback((): void => {
    navigate(Routes.SUPER_SUPERMATCH_TFN);
  }, []);

  const returnToStart = useCallback((): void => {
    navigate(Routes.SUPER_SUPERMATCH);
  }, []);

  const onSelectFunds = useCallback((funds: RolloverRequest): void => {
    setFundsToTransfer(funds);
    navigate(Routes.SUPER_SUPERMATCH_TERMS);
  }, []);

  return (
    <SuperMatchContext.Provider
      value={{
        variant: 'consolidate',
        hasAccess: hasConsented && isVerified && !!hasSuppliedTfn,
        fundsToTransfer,
        onError,
        onCancel: navigateToDashboard,
        onSuccess,
        onSuccessContinue: navigateToDashboard,
        onErrorContinue: navigateToDashboard,
        onAddTfn,
        onConsent,
        onSelectFunds,
        returnToStart,
      }}
    >
      {children}
    </SuperMatchContext.Provider>
  );
};

export const useSuperMatch = (): SuperMatch => {
  const context = useContext(SuperMatchContext);

  if (!context) {
    throw new Error(
      'webapp: useSuperMatch must be wrapped with <SuperMatchOnboardingProvider /> or <SuperMatchProvider />',
    );
  }

  return context;
};
