import {
  createGetSpace,
  createMargin,
  createMarginBottom,
  createMarginLeft,
  createMarginRight,
  createMarginTop,
  createMarginX,
  createMarginY,
  createPadding,
  createPaddingBottom,
  createPaddingLeft,
  createPaddingRight,
  createPaddingTop,
  createPaddingX,
  createPaddingY,
} from '@spaceship-fspl/substance-mixin';
import { createProps } from '@spaceship-fspl/substance-style';

import { map } from './media';

export type Space = keyof typeof spaces;

export const spaces = {
  none: '0px',
  xxxs: '4px',
  xxs: '8px',
  xs: '12px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '48px',
  xxl: '64px',
  xxxl: '96px',
};

export const getSpace = createGetSpace(spaces);
export const getNumericSpace = (space: Space): number =>
  parseInt(getSpace(space) as string, 10);

export const margin = createMargin({ map, getSpace });
export const marginX = createMarginX({ map, getSpace });
export const marginY = createMarginY({ map, getSpace });
export const marginTop = createMarginTop({ map, getSpace });
export const marginRight = createMarginRight({ map, getSpace });
export const marginBottom = createMarginBottom({ map, getSpace });
export const marginLeft = createMarginLeft({ map, getSpace });

export const marginProps = createProps({
  margin,
  marginX,
  marginY,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
});
export type MarginProps = Parameters<typeof paddingProps>[0];

export const padding = createPadding({ map, getSpace });
export const paddingX = createPaddingX({ map, getSpace });
export const paddingY = createPaddingY({ map, getSpace });
export const paddingTop = createPaddingTop({ map, getSpace });
export const paddingRight = createPaddingRight({ map, getSpace });
export const paddingBottom = createPaddingBottom({ map, getSpace });
export const paddingLeft = createPaddingLeft({ map, getSpace });

export const paddingProps = createProps({
  padding,
  paddingX,
  paddingY,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
});
export type PaddingProps = Parameters<typeof paddingProps>[0];
