import * as React from 'react';
export const DollarCirleLines = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12.522 7.47H10.42c-.322 0-.634.105-.882.297a1.288 1.288 0 00-.479.755 1.23 1.23 0 00.144.868c.154.264.402.472.7.584l2.134.8c.299.112.546.319.7.583.154.265.205.572.144.869-.061.296-.23.562-.479.754-.248.192-.56.298-.882.298h-2.1M10.97 7.47v-.968M10.97 15.214v-1.936" />
    <path vectorEffect="non-scaling-stroke" d="M21.24 11.12a10.12 10.12 0 10-20.24 0 10.12 10.12 0 1020.24 0z" />
    <path vectorEffect="non-scaling-stroke" d="M11.12 18.86a7.739 7.739 0 01-7.739-7.74m7.74-7.738a7.739 7.739 0 017.738 7.739" />
    <path vectorEffect="non-scaling-stroke" d="M2.51 16.4C3.938 20.254 7.648 23 12 23c5.59 0 10.12-4.53 10.12-10.12 0-1.753-.535-3.402-1.32-4.84" />
  </svg>
);
