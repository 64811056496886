import { useEffect, useLayoutEffect, useState } from 'react';

export const useSetEqualClientHeights = (
  refs?: React.RefObject<HTMLDivElement>[],
  pageReady = true,
): void => {
  const [windowWidth, setWindowWidth] = useState(0);

  const resizeWindow = (): void => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);

    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (!pageReady || !refs) return;

    if (refs.every((d) => d.current)) {
      let tallest = 0;

      for (const d of refs) {
        // reset min height
        if (d.current) {
          d.current.style.minHeight = '';
        }

        tallest =
          d.current?.clientHeight && d.current?.clientHeight > tallest
            ? d.current?.clientHeight
            : tallest;
      }

      for (const d of refs) {
        if (d.current) {
          d.current.style.minHeight = `${tallest}px`;
        }
      }
    }
  }, [refs, pageReady, windowWidth]);
};

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;
