import { MemberBalanceOverTime } from '@sargon/api-client';
import {
  formatDate,
  sliceGraphDataByTimeScale,
  sydneyToday,
  TimeScale,
  toDateGraphData,
} from '@spaceship-fspl/helpers';
import Decimal from 'decimal.js';
import React, { useCallback } from 'react';

import { useGetMemberBalance, useListAllMemberBalanceOverTime } from '../react';

export interface UseBalanceGraphDataResult {
  data?: {
    x: number;
    y: number;
    performance: number;
  }[];
  refetch: () => void;
  isLoading: boolean;
  error: unknown;
}

export const useBalanceGraphData = (
  timeScale: TimeScale = TimeScale.MAX,
  calculatePerformance = false,
): UseBalanceGraphDataResult => {
  const {
    data: memberBalance,
    refetch: refetchMemberBalance,
    isLoading: isFetchingMemberBalance,
  } = useGetMemberBalance();
  const {
    data: balanceOverTime,
    refetch: refetchMemberBalanceOverAllTime,
    isLoading: isFetchingMemberBalanceOverAllTime,
    error: listAllMemberBalanceOverTimeError,
  } = useListAllMemberBalanceOverTime();

  const data = React.useMemo(() => {
    if (balanceOverTime && balanceOverTime.length) {
      const slicedGraphData = sliceGraphDataByTimeScale(
        toDateGraphData<MemberBalanceOverTime>(
          balanceOverTime,
          ({ to, closingBalance }) => ({
            date: to ? formatDate(to, 'yyyy-MM-dd') : '',
            y: new Decimal(closingBalance?.amount || 0)
              .dividedBy(100)
              .toString(),
          }),
        ).reverse(),
        timeScale,
      ).map((dataPoint, i) => ({
        ...dataPoint,
        performance: new Decimal(balanceOverTime?.[i]?.income?.amount || 0)
          .dividedBy(100)
          .toNumber(),
      }));

      // Purposely set the last graph value to the latest balance/performance for consistency
      if (calculatePerformance && slicedGraphData.length > 0) {
        slicedGraphData[slicedGraphData.length - 1] = {
          x: sydneyToday().getTime(),
          y: new Decimal(memberBalance?.closingBalance?.amount || 0)
            .dividedBy(100)
            .toNumber(),
          performance: new Decimal(memberBalance?.income?.amount || 0)
            .dividedBy(100)
            .toNumber(),
        };
      }

      return slicedGraphData;
    }
    return undefined;
  }, [calculatePerformance, memberBalance, balanceOverTime, timeScale]);

  const refetch = useCallback(() => {
    return Promise.all([
      refetchMemberBalance(),
      refetchMemberBalanceOverAllTime(),
    ]);
  }, [refetchMemberBalance, refetchMemberBalanceOverAllTime]);

  return {
    data,
    refetch,
    isLoading: isFetchingMemberBalance || isFetchingMemberBalanceOverAllTime,
    error: listAllMemberBalanceOverTimeError,
  };
};
