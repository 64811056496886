import {
  backgroundColor,
  borderRadius,
  color,
  height,
  paddingX,
  text,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import styled, { css } from 'styled-components';

const variant = createVariant({
  info: css`
    ${backgroundColor('neutral.030')}
    ${color('neutral.080')}
  `,
  warning: css`
    ${backgroundColor('gold.015')}
    ${color('gold.100')}
  `,
  error: css`
    ${backgroundColor('red.015')}
    ${color('red.100')}
  `,
  success: css`
    ${backgroundColor('mint.015')}
    ${color('mint.100')}
  `,
});

export const Pill = styled.span`
  ${paddingX('xs')}
  align-items: center;
  ${borderRadius('xs')}
  display: inline-flex;
  ${height('md')}
  text-transform: uppercase;
  white-space: nowrap;
  ${createProps({ variant })}
  ${text({ variant: 4 })}
  font-weight: 600;
`;
