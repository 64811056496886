import * as React from 'react';
export const EtfAssetFallback = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#F3E4FF" d="M12 0a12 12 0 100 24 12 12 0 100-24z" />
    <path
      fill="#600CE8"
      d="M17.96 7.855c-.955-.071-2.166.066-3.53.39a.35.35 0 00-.26.421.35.35 0 00.422.26c1.316-.312 2.474-.437 3.316-.373.422.031.763.111.998.22.236.11.358.235.416.375.08.193.051.466-.168.85a.35.35 0 00.131.479.35.35 0 00.479-.131c.279-.49.396-1.006.205-1.465-.139-.335-.422-.582-.768-.742-.345-.16-.762-.248-1.24-.284zm-5.862 1.08c-.448.158-.902.333-1.36.522h-.002c-2.17.9-4.018 1.989-5.277 3.047-.63.53-1.114 1.05-1.416 1.559-.302.508-.436 1.04-.238 1.517.26.63.966.918 1.834 1.01a.35.35 0 00.384-.31.35.35 0 00-.31-.385c-.752-.08-1.15-.318-1.26-.582-.083-.2-.048-.49.192-.893s.673-.883 1.263-1.379c1.18-.992 2.982-2.06 5.096-2.937.448-.185.892-.356 1.326-.508a.35.35 0 00.215-.448.35.35 0 00-.447-.212zm6.234 2.215a.35.35 0 00-.254.086 12.01 12.01 0 01-1.1.864.35.35 0 00-.085.486.35.35 0 00.486.086c.434-.304.824-.608 1.164-.91a.35.35 0 00.03-.494.35.35 0 00-.241-.118zm-8.965 4.34c-.705.175-1.367.298-1.965.367a.35.35 0 00-.306.39.35.35 0 00.386.306 16.476 16.476 0 002.055-.383.35.35 0 00.254-.424.35.35 0 00-.424-.256z"
    />
    <path
      fill="#600CE8"
      d="M13.23 17.55c1.706 0 3.09-1.462 3.09-3.263 0-1.802-1.384-3.263-3.09-3.263-1.708 0-3.091 1.461-3.091 3.263 0 1.801 1.383 3.262 3.09 3.262zm5.666-5.839a.858.858 0 100-1.717.858.858 0 000 1.717zM6.533 17.205a.859.859 0 100-1.717.859.859 0 000 1.717zm6.868-6.868a1.202 1.202 0 100-2.403 1.202 1.202 0 000 2.403z"
    />
    <path
      fill="#600CE8"
      d="M16.955 4.975c-2.005.168-4.885 1.718-7.67 4.158-1.94 1.7-3.452 3.519-4.342 5.115-.444.798-.735 1.54-.836 2.205-.1.666-.004 1.283.383 1.725.58.661 1.586.73 2.737.457 1.15-.273 2.508-.903 3.939-1.819a.35.35 0 00.105-.482.35.35 0 00-.482-.107c-1.383.884-2.69 1.48-3.725 1.726-1.033.246-1.73.126-2.048-.236-.213-.242-.299-.617-.217-1.158.082-.542.339-1.222.756-1.971.834-1.498 2.302-3.273 4.191-4.928 2.712-2.376 5.58-3.846 7.268-3.988a.35.35 0 00.32-.377.35.35 0 00-.379-.32zm1.43.56a.35.35 0 00-.031.494c.171.196.259.473.244.866-.015.392-.141.887-.38 1.443-.476 1.112-1.392 2.466-2.64 3.834a.35.35 0 00.022.494.35.35 0 00.494-.021c1.286-1.411 2.245-2.81 2.767-4.032.262-.61.416-1.177.436-1.693.02-.516-.105-.996-.418-1.354a.35.35 0 00-.494-.03z"
    />
    <path
      fill="#600CE8"
      d="M17.865 6.217a.859.859 0 100-1.717.859.859 0 000 1.717z"
    />
  </svg>
);
