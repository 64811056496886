import { navigate, RouteComponentProps } from '@reach/router';
import { useIsAuthenticated, useLogout } from '@spaceship-fspl/auth';
import {
  ActionLink,
  Box,
  Container,
  Logo,
  LogoVariant,
  Visible,
} from '@spaceship-fspl/components';
import { useSaverPortfolioFlags } from '@spaceship-fspl/graphql';
import { marginLeft, paddingTop, paddingX } from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { RouterLink } from 'components/router-link';
import { AccessibilityLabel } from 'helpers/accessibility';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

export const HEADER_HEIGHT = 72;

export interface GeneralHeaderProps extends RouteComponentProps {
  variant?: LogoVariant;
  isCTAShown?: boolean;
}

export const GeneralHeader: React.FC<
  React.PropsWithChildren<GeneralHeaderProps>
> = ({ variant = 'dark', isCTAShown = true }) => {
  const isAuthenticated = useIsAuthenticated();
  const logout = useLogout();
  const flagsData = useSaverPortfolioFlags();

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createProductInstanceAvailable =
    saverPortfolioFlags?.createProductInstanceAvailable ?? true;

  return (
    <StyledHeader>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <RouterLink to="/" trackingProperties={{ name: 'header_home' }}>
            <Logo variant={variant} />
          </RouterLink>

          {isCTAShown &&
            (isAuthenticated ? (
              <Button
                trackingProperties={{ name: 'logout' }}
                variant="primary"
                size="sm"
                onClick={logout}
              >
                Log out
              </Button>
            ) : (
              <Box display="flex" alignItems="center">
                <RouterLink
                  to={Routes.LOGIN}
                  trackingProperties={{ name: 'header_login' }}
                >
                  <ActionLink color="neutral.100" size="sm" component="span">
                    Log in
                  </ActionLink>
                </RouterLink>

                <Visible
                  isHidden={{
                    xs: true,
                    md: !createProductInstanceAvailable,
                  }}
                >
                  <StyledSignupButton
                    aria-label={AccessibilityLabel.SIGNUP_BUTTON}
                    trackingProperties={{ name: 'signup' }}
                    onClick={(): void => {
                      navigate(Routes.SIGNUP);
                    }}
                  >
                    Sign up
                  </StyledSignupButton>
                </Visible>
              </Box>
            ))}
        </Box>
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  ${paddingTop({ xs: 'xs', md: 'lg' })}
  box-sizing: border-box;
  height: ${HEADER_HEIGHT}px;
`;

const StyledSignupButton = styled(Button).attrs({
  variant: 'tertiary',
  size: 'lg',
})`
  ${marginLeft('md')}
  ${paddingX('md')}
  border-radius: 18px;
  height: 36px;
  min-width: auto;
`;
