import { SuperMemberDetails } from 'components/super-member-details';
import { Routes } from 'pages/routes';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from './components';

export const SuperMemberDetailsCard: React.FC<React.PropsWithChildren> = () => {
  return (
    <AccountCard>
      <AccountCardHeading
        title="Super member details"
        edit={{
          text: 'Share with employer',
          to: Routes.SUPER_CONTRIBUTIONS_EMPLOYER,
          isEditing: false,
          editTrackingProperties: { name: 'super_member_details_card_share' },
        }}
      />

      <AccountCardContent
        padding={{
          padding: { xs: 'md', md: 'lg' },
          paddingBottom: { xs: 'xs', md: 'sm' },
        }}
      >
        <SuperMemberDetails />
      </AccountCardContent>
    </AccountCard>
  );
};
