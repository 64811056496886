import { RouteComponentProps } from '@reach/router';
import { Success } from 'components/layouts/success';
import { useSuperMatch } from 'contexts/super/super-match';
import React from 'react';

export const SuperMatchSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { onSuccessContinue } = useSuperMatch();
  return (
    <Success
      isOnboarding={true}
      showConfetti={true}
      title="Your super is on its way"
      subtitle="Thanks for submitting your consolidation request. It can take up to two weeks for the funds to appear in your account."
      buttonText="Continue"
      onContinue={{
        trackingProperties: {
          name: 'supermatch_success_done',
        },
        onClick: onSuccessContinue,
      }}
    />
  );
};
