import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { SaverPortfolio, useCreateRedemption } from '@spaceship-fspl/graphql';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  ExternalRoutes,
  formatDate,
  sydneyDate,
  useEstimatedProcessedDate,
} from '@spaceship-fspl/helpers';
import {
  FeatherChevronRightIcon,
  FeatherInfoIcon,
} from '@spaceship-fspl/icons-web';
import {
  getColor,
  paddingLeft,
  paddingRight,
  paddingY,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { Button } from 'components/button';
import { LabeledField } from 'components/labeled-field';
import { Error, InlineContactSupportMessage } from 'components/layouts/error';
import { PageContainer } from 'components/layouts/page';
import { LinkButton } from 'components/link-button';
import WithdrawalInfoAccordion from 'components/voyager-withdrawal-info-accordion';
import { useNotifications } from 'contexts/notifications';
import { TrackingEvent } from 'helpers/analytics';
import { formatCurrency } from 'helpers/format';
import { addRumError } from 'helpers/monitoring';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { TestId } from '../helpers/test-ids';

interface VoyagerWithdrawConfirmPageProps {
  productId?: string;
  location: {
    state: {
      portfolio: SaverPortfolio;
      audAmount: string;
      fullRedemption: boolean;
    };
  };
}

export const VoyagerWithdrawConfirm: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerWithdrawConfirmPageProps>>
> = (props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const accordionRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const { productId = '' } = useParams();

  const notifications = useNotifications();
  const [createRedemption, createRedemptionMeta] = useCreateRedemption();
  const estimatedDate = useEstimatedProcessedDate(sydneyDate());
  const audAmount = props?.location?.state?.audAmount ?? '';
  const fullRedemption = props?.location?.state?.fullRedemption ?? false;
  const portfolio: SaverPortfolio | undefined =
    props?.location?.state?.portfolio;
  const submissionDisabled = createRedemptionMeta.loading || audAmount == null;

  if (!productId || !portfolio || !audAmount) {
    return (
      <Error
        title="We've run into a problem."
        subtitle={<InlineContactSupportMessage />}
        buttonText="Go back"
        iconColor="indigo.070"
        onContinue={{
          onClick: () => navigate(Routes.VOYAGER_WITHDRAW),
          trackingProperties: {
            name: 'voyager_withdraw_error_page:contact_support',
          },
        }}
      />
    );
  }

  const config = voyagerPortfolios[portfolio];

  return (
    <PageContainer data-testid={TestId.VOYAGER_WITHDRAW_CONFIRM_PAGE}>
      <Stack spaceY="xxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
            <Heading variant={3} align="center">
              Confirm your withdrawal
            </Heading>

            <Box
              backgroundColor="neutral.000"
              padding="lg"
              borderRadius="sm"
              boxShadow="sm"
              marginTop="md"
            >
              <Stack spaceY="sm">
                <LabeledField label="Portfolio" size="sm">
                  {config.title}
                </LabeledField>

                <Divider color="neutral.030" />

                <Box display="flex" justifyContent="space-between">
                  <LabeledField label="Amount" size="sm">
                    {fullRedemption
                      ? `Withdraw all (${formatCurrency(audAmount)})`
                      : formatCurrency(audAmount)}
                  </LabeledField>
                  <LinkButton
                    trackingProperties={{
                      name: 'withdraw_confirm_edit_amount_button',
                    }}
                    size={'xs'}
                    onClick={() => {
                      navigate(`${Routes.VOYAGER_WITHDRAW}/${productId}`, {
                        replace: true,
                        state: {
                          portfolio,
                          audAmount,
                        },
                      });
                    }}
                  >
                    Edit
                  </LinkButton>
                </Box>

                <Divider color="neutral.030" />

                <LabeledField
                  data-testid={'estimated_date'}
                  label="Withdrawal complete"
                  size="sm"
                >
                  ETA {formatDate(estimatedDate, DATE_FORMAT_TRANSACTIONS_LONG)}
                </LabeledField>
              </Stack>
            </Box>

            <Box
              backgroundColor="neutral.030"
              padding="lg"
              borderRadius="sm"
              boxShadow="sm"
              marginTop="md"
            >
              <Stack spaceY="sm">
                <WithdrawTaxLiabilityInfoBox />
                <Divider color="neutral.050" />
                <WithdrawUnitPriceInfoBox
                  learnMoreAction={() => {
                    if (accordionRef.current) {
                      // timeout so animated chevron finishes before scroll
                      setTimeout(() => {
                        accordionRef.current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }, 500);
                    }
                    setIsAccordionOpen(true);
                  }}
                />
              </Stack>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop="xl"
            >
              <Stack spaceY="md" alignX="center">
                <Button
                  isDisabled={submissionDisabled}
                  variant="primary"
                  size="lg"
                  isLoading={createRedemptionMeta.loading}
                  trackingProperties={{ name: 'withdraw_confirm_submit' }}
                  onClick={async () => {
                    if (audAmount != null) {
                      await createRedemption({
                        variables: {
                          input: {
                            productId,
                            audAmount,
                            fullRedemption,
                          },
                        },
                        onCompleted: () => {
                          navigate(
                            `${Routes.VOYAGER_WITHDRAW_SUCCESS}/${productId}`,
                            {
                              state: {
                                audAmount,
                              },
                            },
                          );
                        },
                        onError: (error) => {
                          addRumError({ error });
                          notifications.popToast({
                            level: 'warning',
                            message:
                              'Sorry, we’ve run into a problem. Please try again in a few moments. If this keeps happening, contact support.',
                          });
                        },
                      });
                    }
                  }}
                >
                  Confirm
                </Button>
                <LinkButton
                  size="sm"
                  onClick={async (): Promise<void> =>
                    await navigate(
                      `${Routes.VOYAGER_TRANSACTIONS}/${productId}`,
                    )
                  }
                  trackingProperties={{ name: 'withdraw_confirm_cancel' }}
                >
                  Cancel
                </LinkButton>
              </Stack>
            </Box>
          </Columns.Column>
        </Columns>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 5 / 6 }}>
            <WithdrawalInfoAccordion
              ref={accordionRef}
              isAccordionOpen={isAccordionOpen}
            />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const StyledLearnMorePresentationLink = styled(PresentationLink).attrs({
  color: 'indigo.070',
  size: 'xs',
  icon: 'chevron',
})`
  ${paddingLeft('md')}
  ${paddingRight('sm')}
  ${paddingY('xxs')}
  :hover {
    text-decoration: none;
  }
`;

const WithdrawTaxLiabilityInfoBox: React.FC<React.PropsWithChildren> = () => {
  const track = useTrack();
  return (
    <Stack spaceY="xs" alignX="center">
      <FeatherInfoIcon color="indigo.070" />
      <Text variant={3} align="center">
        Withdrawing your investment could make you liable for tax on any gain.
      </Text>
      <StyledLearnMorePresentationLink
        href={ExternalRoutes.MONEY_SMART_INVESTING_AND_TAX}
        target="_blank"
        onClick={(e): void => {
          e.stopPropagation();
          track?.(TrackingEvent.CLICK, {
            properties: {
              name: 'voyager_withdrawal_confirm:tax_liability_learn_more_button',
            },
          });
        }}
      >
        Learn more
      </StyledLearnMorePresentationLink>
    </Stack>
  );
};

const AnimatedChevronButton = styled(Button)`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  // this custom CSS is the same as what is used in \`PresentationLink\`:
  :not(:disabled):hover {
    background-color: transparent !important;
    color: ${getColor('indigo.070')};
  }

  :hover svg {
    line-height: 0;
    transition: transform 0.5s;
    transform: translate(2px, 0);
  }
`;

const WithdrawUnitPriceInfoBox: React.FC<
  React.PropsWithChildren<{
    learnMoreAction: () => void;
  }>
> = ({ learnMoreAction }) => {
  return (
    <Stack spaceY="xs" alignX="center">
      <Text variant={3} align="center">
        The unit price will be different on the day we process your withdrawal.
      </Text>
      <Text variant={3} align="center">
        This means your withdrawal could be less than requested if the unit
        price goes down.
      </Text>
      <AnimatedChevronButton
        trackingProperties={{
          name: 'withdraw_confirm_how_withdrawals_work_button',
        }}
        size={'sm'}
        onClick={() => learnMoreAction()}
        variant="tertiary"
      >
        How withdrawals work <FeatherChevronRightIcon size="xs" />
      </AnimatedChevronButton>
    </Stack>
  );
};
