import { useNavigate } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { ExternalRoutes, InternalRoutes } from '@spaceship-fspl/helpers';
import { SuperProductStarsIcon } from '@spaceship-fspl/icons-web';
import { height, themes, width } from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { Routes } from 'pages/routes';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

export const SuperCrossSell: React.FC<React.PropsWithChildren> = () => {
  const navigate = useNavigate();
  const superFlagsResp = useSuperPortfolioFlags();
  const sftOneSuperActive =
    superFlagsResp.data?.superPortfolioFlags?.sftOneSuperActive;
  return (
    <ThemeProvider theme={themes.webapp.onWhite}>
      <Card
        borderRadius="sm"
        boxShadow="sm"
        paddingX={{ xs: 'md', lg: 'lg' }}
        paddingTop={{ xs: 'md', lg: 'lg' }}
        paddingBottom="xl"
      >
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, lg: 3 / 4, xl: 2 / 3 }}>
            <Box marginBottom="lg">
              <Stack spaceY="sm" alignX="center">
                <StyledSuperProductStarsIcon color="indigo.070" />

                <Heading variant={3} align="center" component="h3">
                  Explore
                  <br />
                  Spaceship Super
                </Heading>

                <Text variant={1} align="center">
                  Join the super fund investing where the world is going.
                </Text>
              </Stack>
            </Box>

            <Stack spaceY="lg" alignX="center">
              <Stack spaceY="xs" alignX="center">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    navigate(Routes.ACCOUNT_CONFIRM_DETAILS, {
                      state: {
                        nextRoute: Routes.SUPER_SIGNUP_PORTFOLIO,
                      },
                    });
                  }}
                  trackingProperties={{
                    name: 'account_super_cross_sell_start',
                  }}
                >
                  Sign up
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() =>
                    navigate(Routes.ACCOUNT_CONFIRM_DETAILS, {
                      state: { nextRoute: Routes.SUPER_LINK },
                    })
                  }
                  trackingProperties={{
                    name: 'account_super_linking',
                  }}
                >
                  Already a member?
                </Button>
              </Stack>

              <TextLink
                color="indigo.070"
                rel="noreferrer"
                href={InternalRoutes.SUPER_LEARN_MORE}
                target="_blank"
              >
                Learn more
              </TextLink>
            </Stack>
          </Columns.Column>
        </Columns>
      </Card>

      <Box marginTop="xl">
        <Text variant={4} color="neutral.080">
          {sftOneSuperActive ? (
            <>
              Spaceship Capital Limited is the promoter of Spaceship Super.
              Spaceship Super is issued by Diversa Trustees Limited (ABN 49 006
              421 638, AFSL 235153, RSEL L0000635) as trustee of OneSuper (ABN
              43 905 581 638) (Fund). Spaceship Super is a sub-plan of the Fund.
            </>
          ) : (
            <>
              Spaceship Capital Limited is the promoter of Spaceship Super.
              Spaceship Super is issued by Diversa Trustees Limited (ABN 49 006
              421 638, AFSL 235153, RSEL L0000635) as trustee of the Tidswell
              Master Superannuation Plan (ABN 34 300 938 877) (Fund). Spaceship
              Super is a sub-plan of the Fund.
            </>
          )}
          <br />
          <br />
          The information on this website is general in nature as it has been
          prepared without taking account of your objectives, financial
          situation or needs. You should consider the relevant{' '}
          <TextLink
            href={ExternalRoutes.SUPER_PDS}
            target="_blank"
            rel="noreferrer"
          >
            Product Disclosure Statement
          </TextLink>{' '}
          and{' '}
          <TextLink
            href={ExternalRoutes.SUPER_REFERENCE_GUIDE}
            target="_blank"
            rel="noreferrer"
          >
            Reference Guide
          </TextLink>
          , and obtain appropriate financial and taxation advice, before
          deciding whether Spaceship is right for you.
        </Text>
      </Box>
    </ThemeProvider>
  );
};

const StyledSuperProductStarsIcon = styled(SuperProductStarsIcon)`
  && {
    ${height({ xs: 100, lg: 150 })}
    ${width({ xs: 100, lg: 150 })}
  }
`;
