export const INVALID_EMAIL_MESSAGE = 'The email address is invalid';

export const NEW_PASSWORD_RULE =
  'Your new password must be at least 8 characters long, and contain an uppercase character, a lowercase character, and a numeric character.';

export const validateEmail = (value: string): boolean | string => {
  if (!value) {
    return true;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(value) || value.endsWith('.con')) {
    return INVALID_EMAIL_MESSAGE;
  }
  return true;
};

export const isValueInEnum = <T extends object>(
  e: T,
  token: unknown,
): token is T[keyof T] => Object.values(e).includes(token as T[keyof T]);

export const isValidDollarAmount = (value?: string): boolean => {
  if (typeof value === 'undefined') return false;
  return /^[+-]?\d+\.?\d{0,2}$/.test(value) && !isNaN(parseFloat(value));
};

export const isAsciiString = (value: string): boolean => {
  // eslint-disable-next-line no-control-regex
  return /^[\x00-\x7F]*$/.test(value);
};

// Auspost disallows certain characters from appearing in the address field
export const isInvalidAuspostAddress = (value: string): boolean => {
  const invalidCharacters = [','];
  const regex = new RegExp(`[${invalidCharacters.join('')}]`);

  return regex.test(value);
};
