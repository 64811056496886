import { Heading, Stack, Text } from '@spaceship-fspl/components';
import { Color } from '@spaceship-fspl/styles';
import React from 'react';

type Size = 'sm' | 'md' | 'lg';

interface LabeledFieldProps {
  label: string;
  size: Size;
  align?: 'left' | 'center' | 'right';
  colors?: {
    label: Color;
    value: Color;
  };
}

export const LabeledField: React.FC<
  React.PropsWithChildren<LabeledFieldProps>
> = ({
  label,
  size,
  children = '—',
  align = 'left',
  colors = {
    label: 'neutral.080',
    value: 'black.100',
  },
}) => (
  <Stack spaceY={size === 'lg' ? 'xxxs' : 'none'}>
    <Text variant={4} isBold={true} color={colors.label} align={align}>
      {label}
    </Text>

    {size === 'lg' ? (
      <Heading
        variant={{ xs: 4, xl: 2 }}
        color={colors.value}
        align={align}
        component="div"
      >
        {children}
      </Heading>
    ) : (
      <Text
        variant={size === 'md' ? 2 : 3}
        color={colors.value}
        align={align}
        isBold={true}
        component="div"
      >
        {children}
      </Text>
    )}
  </Stack>
);

LabeledField.displayName = 'LabeledField';
