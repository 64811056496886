import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { WebAppBankAccountSelectAccount } from '@spaceship-fspl/graphql/src/__generated__/WebAppBankAccountSelectAccount';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FeatherInfoIcon,
  StreamlineAlertTriangleIcon,
} from '@spaceship-fspl/icons-web';
import { BAV_BLACKLIST_SAVINGS_ACCOUNTS_INSTITUTION_SLUGS } from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { LabeledField } from 'components/labeled-field';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import {
  useBankAccountSubmissionContext,
  useBankAccountVerificationContext,
} from 'contexts/bank-account-verification';
import { useNotifications } from 'contexts/notifications';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

export const BankAccountSelectAccount: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const upPath = `${location.pathname.replace(/\/+$/, '')}/${Routes.UP}`;

  const { popToast } = useNotifications();
  const { submitBankAccount } = useBankAccountSubmissionContext();
  const { accounts, userToken, selectedBank } =
    useBankAccountVerificationContext();
  const isBavBlackListSavingsAccountsEnabled = useFeatureFlag(
    FeatureFlagKeys.BAV_BLACKLIST_SAVINGS_ACCOUNTS_ENABLED,
  );

  const resp = useQuery<WebAppBankAccountSelectAccount>(gql`
    query WebAppBankAccountSelectAccount {
      contact {
        id
        firstName
        lastName
      }
    }
  `);
  const contact = resp.data?.contact;
  const { firstName = '', lastName = '' } = contact || {};
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : undefined;

  const [isLoading, setIsLoading] = useState(false);
  const { register, watch, handleSubmit } = useForm<{
    terms: boolean;
    accountIndex: string;
  }>({
    defaultValues: {
      accountIndex: '0',
    },
  });

  const { accountIndex: selectedAccountIndex } = watch();
  const selectedAccount = accounts[Number(selectedAccountIndex)];

  const isInstitutionBlacklisted =
    isBavBlackListSavingsAccountsEnabled &&
    BAV_BLACKLIST_SAVINGS_ACCOUNTS_INSTITUTION_SLUGS.includes(
      selectedBank?.slug ?? '',
    );

  const isBankAccountBlacklisted =
    isInstitutionBlacklisted && selectedAccount?.accountType === 'savings';

  const isOnlySavingsAccounts = accounts.every(
    (account) => account.accountType === 'savings',
  );

  const onSubmit = handleSubmit(async ({ accountIndex }) => {
    const { name, bsb, accountNumber } = accounts[Number(accountIndex)] || {};
    if (!(name && bsb && accountNumber)) {
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
      return;
    }

    setIsLoading(true);
    await submitBankAccount({
      source_account_name: name,
      source_bsb: bsb,
      source_account_friendly_name: name,
      source_account_number: accountNumber,
      bank_statements_user_token: userToken,
    });
    setIsLoading(false);
  });

  useEffect(() => {
    if (!selectedBank) {
      navigate?.(upPath);
    }
  }, [accounts, navigate, selectedBank, upPath]);

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
          {accounts.length > 0 &&
          !(isInstitutionBlacklisted && isOnlySavingsAccounts) ? (
            <form onSubmit={onSubmit}>
              <Stack spaceY="md">
                <PageHeading
                  title="Select your account"
                  subtitle="Please enter the details of an account that allows direct debits. Many banks only allow withdrawals from transaction accounts."
                />

                <Stack spaceY="sm">
                  {accounts?.map((account, index) => {
                    const isSelected =
                      index.toString() === selectedAccountIndex;

                    return (
                      <RadioCard
                        key={account.id}
                        value={index}
                        contentPadding={{
                          padding: 'md',
                        }}
                        defaultChecked={isSelected}
                        footer={
                          isSelected &&
                          isBankAccountBlacklisted && (
                            <Box
                              padding="sm"
                              backgroundColor="red.015"
                              borderRadiusBottomLeft="sm"
                              borderRadiusBottomRight="sm"
                              display="flex"
                              alignItems={{
                                xs: 'flex-start',
                                xl: 'center',
                              }}
                            >
                              <Box marginRight="sm">
                                <FeatherInfoIcon color="red.100" />
                              </Box>

                              <Text variant={4} isBold={true} color="red.100">
                                Some bank accounts cannot be used for direct
                                debit. Please select another account.
                              </Text>
                            </Box>
                          )
                        }
                        {...register('accountIndex')}
                      >
                        <Columns spaceY="xs" alignY="bottom">
                          <Columns.Column width={{ xs: 1, md: 6 / 12 }}>
                            <Text variant={2} isBold={true}>
                              {account.name}
                            </Text>
                            <Text variant={4}>
                              {account.accountHolder ?? fullName}
                            </Text>
                          </Columns.Column>

                          <Columns.Column width={{ xs: 4 / 12, md: 2 / 12 }}>
                            <LabeledField label="BSB" size="sm">
                              {account.bsb}
                            </LabeledField>
                          </Columns.Column>

                          <Columns.Column width={{ xs: 8 / 12, md: 4 / 12 }}>
                            <LabeledField size="sm" label="Account number">
                              {account.accountNumber}
                            </LabeledField>
                          </Columns.Column>
                        </Columns>
                      </RadioCard>
                    );
                  })}
                </Stack>

                <Text variant={3}>
                  By clicking ‘Save account’, I agree and consent to the{' '}
                  <TextLink
                    href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                    color="indigo.070"
                    rel="noopener"
                    target="_blank"
                  >
                    Direct Debit Request Service Agreement
                  </TextLink>{' '}
                  and authorise Spaceship Capital Limited to process payments in
                  accordance with the{' '}
                  <TextLink
                    target="_blank"
                    href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                  >
                    Direct Debit Request
                  </TextLink>
                  .
                </Text>

                <Stack spaceY="sm" alignX="center">
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={isBankAccountBlacklisted}
                    trackingProperties={{
                      name: 'bank_select_account_page_submit',
                    }}
                  >
                    Save account
                  </Button>

                  <Button
                    variant="secondary"
                    size="lg"
                    trackingProperties={{
                      name: 'bank_select_account_page_try_another',
                    }}
                    onClick={() => {
                      navigate?.(upPath);
                    }}
                  >
                    Try another bank
                  </Button>
                </Stack>
              </Stack>
            </form>
          ) : (
            <Stack spaceY="xl" alignX="center">
              <Stack spaceY="lg" alignX="center">
                <StreamlineAlertTriangleIcon
                  size="xxxl"
                  color="indigo.070"
                  strokeWidth={4}
                />
                <PageHeading
                  title="Oops, we couldn’t find any accounts that allow direct debits"
                  subtitle="Only transaction accounts can be used for direct debit transactions with Spaceship."
                />
              </Stack>

              <Button
                variant="primary"
                size="lg"
                trackingProperties={{
                  name: 'bank_select_account_page_try_another',
                }}
                onClick={() => {
                  navigate?.(upPath);
                }}
              >
                Try another bank
              </Button>
            </Stack>
          )}
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
