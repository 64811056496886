import {
  Accordion,
  Box,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FundToSpaceshipIcon,
  StreamlineCursorChooseIcon,
  StreamlineDownloadDashArrowIcon,
  StreamlineSavingBank1Icon,
  StreamlineSpaceRocketFlyingIcon,
} from '@spaceship-fspl/icons-web';
import {
  ADD_INVESTMENT_LEARN_MORE,
  useInvestmentLearnMoreCopy,
} from '@spaceship-fspl/voyager';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import React from 'react';

const ADD_INVESTMENT_LEARN_MORE_ICONS = {
  [ADD_INVESTMENT_LEARN_MORE.PROCESSING_TIME]: StreamlineCursorChooseIcon,
  [ADD_INVESTMENT_LEARN_MORE.DIRECT_DEBIT]: StreamlineDownloadDashArrowIcon,
  [ADD_INVESTMENT_LEARN_MORE.ISSUE_TIME]: FundToSpaceshipIcon,
  [ADD_INVESTMENT_LEARN_MORE.UNITS]: StreamlineSpaceRocketFlyingIcon,
  [ADD_INVESTMENT_LEARN_MORE.CANCEL]: StreamlineSavingBank1Icon,
};

export const InvestInfoAccordion: React.FC<React.PropsWithChildren> = () => {
  const investmentLearnMoreCopy = useInvestmentLearnMoreCopy({
    cancelTransactionApplicationEnabled: useFeatureFlag(
      FeatureFlagKeys.CANCEL_TRANSACTION_APPLICATION_ENABLED,
    ),
    isMoneyDayOneEnabled: useFeatureFlag(FeatureFlagKeys.MONEY_DAY_ONE_ENABLED),
  });
  return (
    <Accordion variant="info" title="How investing with Spaceship works">
      <Box paddingY={{ md: 'xxs' }}>
        <Stack spaceY="md">
          {investmentLearnMoreCopy.map(({ label, value }) => {
            const Icon = ADD_INVESTMENT_LEARN_MORE_ICONS[value];

            return (
              <Box display="flex" key={value}>
                <Box marginRight={{ xs: 'sm', md: 'md' }} color="indigo.070">
                  <Icon size="xl" />
                </Box>
                <Text variant={2} whiteSpace="pre-line">
                  {label}{' '}
                  {value === ADD_INVESTMENT_LEARN_MORE.UNITS && (
                    <Text variant={2} component="span">
                      More information about how investing with Spaceship works
                      can be found in our{' '}
                      <TextLink
                        color="indigo.070"
                        target="_blank"
                        rel="noreferrer"
                        href={ExternalRoutes.VOYAGER_TRANSACTIONS_FAQ}
                      >
                        Frequently Asked Questions
                      </TextLink>
                      .
                    </Text>
                  )}
                </Text>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Accordion>
  );
};
