import { navigate, RouteComponentProps } from '@reach/router';
import { AuthError } from '@spaceship-fspl/auth';
import {
  Button,
  Columns,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { MfaVerificationType } from '@spaceship-fspl/graphql';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { useLogin } from 'contexts/login-provider';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { mfaValidationRules } from 'helpers/validation';
import { navigateAwayWhenAuthenticated } from 'navigation/helpers';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

export const MfaLoginChallenge: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = navigateAwayWhenAuthenticated()(() => {
  const login = useLogin();
  const notifications = useNotifications();

  const { control, handleSubmit, reset } = useForm<{
    token: string;
  }>();

  const onSubmit = handleSubmit(async ({ token }) => {
    try {
      await login.submitToken(token);
    } catch (error) {
      addRumError({ error });

      const authError = error as AuthError;
      if (authError?.message?.includes('too many verification attempts')) {
        navigate(Routes.LOGIN_CHALLENGE_TOO_MANY_ATTEMPTS);
        return;
      }

      if (authError.message) {
        notifications.popToast({
          level: 'warning',
          message: authError.message,
          requestId: authError.requestId,
        });
      }
      reset({ token: '' });
    }
  });

  const resend = async (mfaType: MfaVerificationType): Promise<void> => {
    try {
      await login.resendChallenge(mfaType);
      notifications.popToast({
        level: 'success',
        message: `Code sent to ${
          mfaType === MfaVerificationType.SMS ? 'mobile' : 'WhatsApp'
        }`,
      });
    } catch (error) {
      addRumError({ error });

      const authError = error as AuthError;
      if (authError?.message?.includes('too many verification attempts')) {
        navigate(Routes.LOGIN_CHALLENGE_TOO_MANY_ATTEMPTS);
        return;
      }

      if (authError.message) {
        notifications.popToast({
          level: 'warning',
          message: authError.message,
          requestId: authError.requestId,
        });
      }
    }
  };

  useEffect(() => {
    if (!login.credentialsSuccess) {
      navigate(Routes.LOGIN);
    }
  }, [login.credentialsSuccess]);

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <Stack spaceY="xxl">
            {login.mfaChallengeType === MfaVerificationType.MANUAL ? (
              <PageHeading
                title="Multi-factor authentication"
                subtitle="Please enter a verification code provided by the customer support team."
              />
            ) : (
              <PageHeading
                title="Enter your code"
                subtitle="Please enter the six-digit code we sent to your mobile number."
              />
            )}

            <form onSubmit={onSubmit}>
              <Stack spaceY="xl">
                <ControllerInputWithInlineError
                  name="token"
                  control={control}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  placeholder="Verification code"
                  disabled={login.loading}
                  rules={mfaValidationRules.code}
                />

                <Stack alignX="center" spaceY="lg">
                  <Stack alignX="center" spaceY="md">
                    <Button
                      variant="primary"
                      size="lg"
                      type="submit"
                      isLoading={login.loading}
                    >
                      Continue
                    </Button>

                    {login.mfaChallengeType !== MfaVerificationType.MANUAL && (
                      <Text
                        variant={3}
                        color="neutral.080"
                        align="center"
                        isBold={true}
                      >
                        {`Didn't receive a code? `}
                        <TextLink
                          target="_blank"
                          onClick={() => {
                            resend(
                              login.mfaChallengeType || MfaVerificationType.SMS,
                            );
                          }}
                          color="indigo.070"
                          data-testid="resend-code"
                        >
                          Resend code
                        </TextLink>
                        {` or `}
                        <TextLink
                          target="_blank"
                          data-testid="resend-other-method"
                          color="indigo.070"
                          onClick={() => {
                            if (
                              login.mfaChallengeType === MfaVerificationType.SMS
                            ) {
                              resend(MfaVerificationType.WHATSAPP);
                            } else {
                              resend(MfaVerificationType.SMS);
                            }
                          }}
                        >
                          send to{' '}
                          {login.mfaChallengeType === MfaVerificationType.SMS
                            ? 'WhatsApp'
                            : 'SMS'}
                        </TextLink>
                        {` instead.`}
                      </Text>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
});
