import { ApolloClient, gql, QueryResult, useQuery } from '@apollo/client';
import type {
  DynamicConfigs,
  DynamicConfigs_dynamicConfigs,
  DynamicConfigsVariables,
} from '@spaceship-fspl/graphql/src/__generated__/DynamicConfigs';

const DYNAMIC_CONFIGS_QUERY = gql`
  query DynamicConfigs($ids: [ID!]!) {
    dynamicConfigs(ids: $ids) {
      id
      value
    }
  }
`;

export async function warmCache(
  client: ApolloClient<unknown>,
  keys: string[],
): Promise<void> {
  const requestedSet = new Set(keys);
  const receivedMap = new Map<string, DynamicConfigs_dynamicConfigs>();

  const resp = await client.query<DynamicConfigs, DynamicConfigsVariables>({
    query: DYNAMIC_CONFIGS_QUERY,
    variables: { ids: Array.from(requestedSet.values()) },
    fetchPolicy: 'network-only',
  });

  resp.data?.dynamicConfigs?.forEach((c) => {
    receivedMap.set(c.id, c);
  });

  requestedSet.forEach((k) => {
    const dynamicConfigs: Array<DynamicConfigs_dynamicConfigs> = [];
    const received = receivedMap.get(k);
    if (received) {
      dynamicConfigs.push(received);
    }

    client.writeQuery<DynamicConfigs, DynamicConfigsVariables>({
      query: DYNAMIC_CONFIGS_QUERY,
      variables: { ids: [k] },
      data: { dynamicConfigs },
    });
  });
}

export async function loadDynamicConfigValue(
  client: ApolloClient<unknown>,
  key: string,
): Promise<DynamicConfigs_dynamicConfigs | undefined> {
  const resp = await client.query<DynamicConfigs, DynamicConfigsVariables>({
    query: DYNAMIC_CONFIGS_QUERY,
    variables: { ids: [key] },
    fetchPolicy: 'network-only',
  });

  return resp.data?.dynamicConfigs?.[0];
}

export function useDynamicConfigQuery(
  key: string,
): QueryResult<DynamicConfigs, DynamicConfigsVariables> {
  return useQuery<DynamicConfigs, DynamicConfigsVariables>(
    DYNAMIC_CONFIGS_QUERY,
    {
      variables: { ids: [key] },
      fetchPolicy: 'cache-first',
    },
  );
}

export function useRawValue(key: string): string | undefined {
  const resp = useDynamicConfigQuery(key);
  return resp.data?.dynamicConfigs?.[0]?.value;
}

export async function queryRawValue(
  client: ApolloClient<unknown>,
  key: string,
): Promise<string | undefined> {
  const result = await client.query<DynamicConfigs, DynamicConfigsVariables>({
    query: DYNAMIC_CONFIGS_QUERY,
    variables: { ids: [key] },
    fetchPolicy: 'network-only',
  });

  return result.data?.dynamicConfigs?.[0]?.value;
}

export function parseString(fallback: string, str?: string): string {
  return str ? JSON.parse(str) : fallback;
}

export function parseNumber(fallback: number, str?: string): number {
  const num = Number(str);
  if (isNaN(num)) {
    return fallback;
  }
  return num;
}

export function parseBoolean(fallback: boolean, str?: string): boolean {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return fallback;
  }
}
