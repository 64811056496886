import { AddressInput } from '@spaceship-fspl/graphql';
import { addressScalars } from '@spaceship-fspl/graphql/src/__generated__/addressScalars';

import { STREET_TYPES } from './constants';

export const getFormattedStreetAddress = (
  address?: Partial<addressScalars> | null,
): string => {
  if (!address) {
    return '';
  }

  const { unitNumber, streetNumber, streetName, streetAddress, streetType } =
    address;

  if (streetName && streetNumber) {
    const formattedUnitNumber = unitNumber ? unitNumber + '/' : '';
    const formattedStreetType = streetType
      ? streetType[0] + streetType.substr(1).toLocaleLowerCase()
      : '';
    const combinedStreetAddress = [
      streetNumber,
      streetName,
      formattedStreetType,
    ]
      .filter(Boolean)
      .join(' ');

    return (formattedUnitNumber + combinedStreetAddress).trim();
  }

  return streetAddress || '';
};

export const getFormattedAddress = (
  address?: Partial<addressScalars> | null,
): string => {
  if (!address) {
    return '';
  }

  const { suburb, state, postcode } = address;

  const line1 = getFormattedStreetAddress(address);
  const line2 = [suburb, state, postcode].filter(Boolean).join(' ');

  const formattedAddress = line1
    ? [line1, line2].filter(Boolean).join(', ')
    : '';
  const country =
    !address.country || address.country.toLowerCase() === 'au'
      ? ''
      : `, ${address.country}`;

  return `${formattedAddress}${country}`;
};

export const mapGooglePlaceResultToAddressForm = (
  placeResult?: google.maps.places.PlaceResult,
): AddressInput | undefined => {
  if (placeResult?.address_components) {
    const address: AddressInput = {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    };
    placeResult.address_components.forEach((component) => {
      if (component.types.includes('subpremise')) {
        address.unitNumber = component.long_name;
      }
      if (component.types.includes('street_number')) {
        address.streetNumber = component.long_name;
      }
      if (component.types.includes('route')) {
        const splitRouteName = component.long_name.split(' ');
        const lastRouteNameWord = splitRouteName[splitRouteName.length - 1];
        const matchedStreetTypeOption =
          lastRouteNameWord &&
          STREET_TYPES.find(
            (streetType) =>
              streetType.value.toLowerCase() ===
              lastRouteNameWord.toLowerCase(),
          );
        if (matchedStreetTypeOption) {
          address.streetName = splitRouteName
            .slice(0, splitRouteName.length - 1)
            .join(' ');

          address.streetType = matchedStreetTypeOption.value;
        } else {
          address.streetName = component.long_name;
        }
      }
      if (component.types.includes('country')) {
        address.country = component.short_name;
      }
      if (
        (address.country !== 'AU' &&
          component.types.includes('administrative_area_level_2')) ||
        component.types.includes('locality') ||
        component.types.includes('sublocality')
      ) {
        address.suburb = address.suburb || component.long_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        address.state = component.short_name;
      }
      if (component.types.includes('postal_code')) {
        address.postcode = component.long_name;
      }
    });
    return address;
  }

  return undefined;
};

export const isAddressInputValid = (
  address?: {
    country?: string | null;
    postcode?: string | null;
    state?: string | null;
    suburb?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    streetType?: string | null;
    unitNumber?: string | null;
    taxReason?: string | null;
  } | null,
): boolean => {
  if (
    !address ||
    !address.country ||
    !address.postcode ||
    !address.state ||
    !address.suburb ||
    !address.streetName ||
    !address.streetNumber ||
    !address.streetType
  ) {
    return false;
  }

  return true;
};
