import { Box, Stack, UnstyledLink } from '@spaceship-fspl/components';
import {
  FeatherChevronRightIcon,
  FeatherSearchIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  fontWeight,
  getSpace,
  padding,
  transition,
} from '@spaceship-fspl/styles';
import { UseTrackingTrackFunctionParameters } from '@spaceship-fspl/tracking';
import React, { ReactNode, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from './input';

interface Props<T> {
  placeholder: string;
  data: T[];
  renderItem: (item: T) => ReactNode;
  searchKey: (item: T) => string;
  onSelect?: (item: T) => void;
  defaultFilter?: (data: T[]) => T[];
  footerItem?: ReactNode;
}

export function FilterSelect<T>({
  placeholder,
  data,
  defaultFilter,
  onSelect,
  renderItem,
  searchKey,
  footerItem,
}: Props<T>): JSX.Element {
  const { control, watch } = useForm<{ query: string }>({
    defaultValues: {
      query: '',
    },
  });

  const query = watch().query;

  const defaultResults = useMemo(
    () => (defaultFilter ? defaultFilter(data) : []),
    [data, defaultFilter],
  );

  const filteredResults = useMemo(
    () =>
      query
        ? data.filter((bank) =>
            searchKey(bank)
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase()),
          )
        : defaultResults,
    [data, defaultResults, query, searchKey],
  );

  return (
    <Stack spaceY="xxxs">
      <Box position="relative">
        <Controller
          name="query"
          control={control}
          render={({ field: { onBlur, onChange, value } }) => (
            <Input
              name="query"
              type="text"
              placeholder={placeholder}
              onChange={
                onChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={value}
              onBlur={onBlur}
            />
          )}
        />

        <StyledSearchIconContainerBox>
          <FeatherSearchIcon size="md" color="neutral.080" />
        </StyledSearchIconContainerBox>
      </Box>

      {filteredResults && (
        <>
          {filteredResults.map((item, index) => (
            <FilterItem
              key={index}
              onClick={(): void => {
                if (onSelect) {
                  onSelect(item);
                }
              }}
            >
              {renderItem(item)}
            </FilterItem>
          ))}
          {(!!query || filteredResults.length === 0) && footerItem}
        </>
      )}
    </Stack>
  );
}

interface FilterItemProps {
  href?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  tracking?: UseTrackingTrackFunctionParameters;
}

export const FilterItem: React.FC<React.PropsWithChildren<FilterItemProps>> = ({
  children,
  ...props
}) => (
  <StyledFilterItemLink {...props}>
    {children}
    <Box color="neutral.080">
      <FeatherChevronRightIcon size="md" />
    </Box>
  </StyledFilterItemLink>
);

const StyledFilterItemLink = styled(UnstyledLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-style: solid;
  align-items: center;

  ${color('neutral.100')}
  ${backgroundColor('neutral.000')}
  ${borderColor('neutral.050')}
  ${borderRadius('xxs')}
  ${borderWidth('sm')}
  ${padding('sm')}
  ${fontWeight(600)}
  ${transition}

  :hover {
    text-decoration: none;
    ${backgroundColor('indigo.070')}

    &, * {
      ${color('neutral.000')}
    }
  }
`;

const StyledSearchIconContainerBox = styled(Box).attrs({
  position: 'absolute',
  right: getSpace('sm'),
  top: '50%',
})`
  transform: translateY(-50%);
`;
