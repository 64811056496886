import { map, ResponsiveValue } from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css } from 'styled-components';

export interface VisibleProps {
  isHidden: ResponsiveValue<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?:
    | keyof JSX.IntrinsicElements
    | React.ComponentType<React.PropsWithChildren>;
  displayValue?: string;
  className?: string;
}

const Wrapper = styled.span<{
  isHidden: ResponsiveValue<boolean>;
  displayValue?: string;
}>`
  display: ${({ displayValue = 'inline' }) => displayValue};
  ${({ isHidden: isHiddenRV, displayValue = 'inline' }) =>
    map(
      isHiddenRV,
      (isHidden: boolean) => css`
        display: ${isHidden ? 'none' : displayValue};
      `,
    )}
`;

export const Visible: React.FC<React.PropsWithChildren<VisibleProps>> = ({
  children,
  component,
  ...props
}) => (
  <Wrapper {...props} as={component}>
    {children}
  </Wrapper>
);
