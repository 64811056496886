import {
  Accordion,
  Box,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  StreamlineAccountingCoinsIcon,
  StreamlineCashPaymentBillsIcon,
  StreamlineCoffeeToGoIcon,
} from '@spaceship-fspl/icons-web';
import { borderRadius, marginBottom } from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

export const HowBoostsWork: React.FC<React.PropsWithChildren> = () => (
  <Accordion variant="info" title="How does Boosts work?">
    <Stack spaceY="lg">
      <Text variant={2} color="neutral.085">
        Boosts lets you automatically boost your investment balance by making
        the most of everyday moments. You can choose the boosts that suit your
        lifestyle and watch as your dollars stack up.
      </Text>

      <Stack spaceY="md">
        <Box display="flex">
          <Box paddingRight="md">
            <StreamlineAccountingCoinsIcon color="indigo.070" size="xl" />
          </Box>
          <Stack spaceY="xxxs">
            <Heading variant={5}>Round Ups</Heading>
            <Text variant={2} color="neutral.085">
              Every time you spend, we’ll round the purchase up to the next
              whole dollar and invest the spare change.
            </Text>
          </Stack>
        </Box>

        <Box display="flex">
          <Box paddingRight="md">
            <StreamlineCashPaymentBillsIcon color="indigo.070" size="xl" />
          </Box>
          <Stack spaceY="xxxs">
            <Heading variant={5}>Payday Boost</Heading>
            <Text variant={2} color="neutral.085">
              When your pay hits your account, we’ll automatically invest a set
              amount before you get a chance to spend it.
            </Text>
          </Stack>
        </Box>

        <Box display="flex">
          <Box paddingRight="md">
            <StreamlineCoffeeToGoIcon color="indigo.070" size="xl" />
          </Box>
          <Stack spaceY="xxxs">
            <Heading variant={5}>Budget Boost</Heading>
            <Text variant={2} color="neutral.085">
              Invest on the go by picking a budget category. Whenever you spend
              money on that category, you can choose to also boost your
              investments.
            </Text>
          </Stack>
        </Box>
      </Stack>

      <StyledVideoWrapper>
        <StyledIFrame
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/2s4mxBoIQgo"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="Introducing Boosts"
        />
      </StyledVideoWrapper>
    </Stack>
  </Accordion>
);

const StyledVideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  ${borderRadius('md')};
  ${marginBottom('xs')};
`;

const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
