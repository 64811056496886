import * as React from 'react';
export const StreamlineCashPaymentWallet = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M5.165 9.72l-.768-2.387a1.476 1.476 0 01.95-1.856l13.68-4.406a1.475 1.475 0 011.856.952l2.046 6.351a1.476 1.476 0 01-.952 1.857L17.23 11.76" />
    <path vectorEffect="non-scaling-stroke" d="M17.23 18.574v2.529A1.903 1.903 0 0115.321 23H2.91A1.903 1.903 0 011 21.103v-9.486a1.902 1.902 0 011.91-1.896H15.32a1.902 1.902 0 011.91 1.896v2.53M4.689 9.735V23" />
    <path vectorEffect="non-scaling-stroke" d="M14.11 14.147a2.134 2.134 0 00-2.044 2.213 2.133 2.133 0 002.043 2.214h3.576a1.067 1.067 0 001.021-1.107v-2.213a1.066 1.066 0 00-1.021-1.107h-3.576z" />
    <path vectorEffect="non-scaling-stroke" d="M16.156 15.895a.369.369 0 110 .738.369.369 0 010-.738zM12.226 7.573a1.475 1.475 0 012.735.266" />
  </svg>
);
