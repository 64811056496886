import * as React from 'react';
export const StreamlineLegalHammer = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M13.36 8.666l9.137 8.611a1.283 1.283 0 010 1.814l-.907.906a1.282 1.282 0 01-1.813 0l-8.611-9.132" />
    <path vectorEffect="non-scaling-stroke" d="M3.871 8.63a18.64 18.64 0 007.254-7.254 1.279 1.279 0 011.812 0l3.437 3.437a1.278 1.278 0 010 1.813 17.743 17.743 0 00-7.253 7.253 1.281 1.281 0 01-1.813 0L3.87 10.443a1.282 1.282 0 010-1.814zM12.85 21.531a1.47 1.47 0 00-1.468-1.469H4.038a1.47 1.47 0 00-1.47 1.47V23h10.283V21.53zM1.1 23h13.22" />
  </svg>
);
