import { SuperPortfolio } from '@spaceship-fspl/graphql';
import { Gender } from '@spaceship-fspl/types/externalapi';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { PersistKey, remove } from 'helpers/persist';
import React, {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

interface SuperOnboarding {
  gender?: Gender.Enum;
  setGender: (value?: Gender.Enum) => void;
  portfolio?: SuperPortfolio;
  setPortfolio: (value?: SuperPortfolio) => void;
  showSuccessFlow?: boolean;
  setShowSuccessFlow: Dispatch<SetStateAction<boolean>>;
}

const SuperOnboardingContext = createContext<SuperOnboarding | undefined>(
  undefined,
);

interface OnboardingData {
  gender?: Gender.Enum;
  portfolio?: SuperPortfolio;
}

export const SuperOnboardingProvider = memo(
  ({ children }: { children: ReactNode }): JSX.Element => {
    const [data, setData] = useLocalStorage<OnboardingData | undefined>(
      PersistKey.SUPER_SIGNUP_DETAILS,
    );
    const [showSuccessFlow, setShowSuccessFlow] = useState(false);

    const setGender = (value?: Gender.Enum): void => {
      setData({
        ...data,
        gender: value,
      });
    };

    const setPortfolio = (value?: SuperPortfolio): void => {
      setData({
        ...data,
        portfolio: value,
      });
    };

    useEffect(() => {
      return () => {
        remove([PersistKey.SUPER_SIGNUP_DETAILS]);
      };
    }, []);

    return (
      <SuperOnboardingContext.Provider
        value={{
          gender: data?.gender,
          setGender,
          portfolio: data?.portfolio,
          setPortfolio,
          showSuccessFlow,
          setShowSuccessFlow,
        }}
      >
        {children}
      </SuperOnboardingContext.Provider>
    );
  },
);

SuperOnboardingProvider.displayName = 'SuperOnboardingProvider';

export const useSuperOnboarding = (): SuperOnboarding => {
  const context = useContext(SuperOnboardingContext);

  if (!context) {
    throw new Error(
      'useSuperOnboarding must be wrapped with SuperOnboardingProvider',
    );
  }

  return context;
};

export const clearSuperOnboardingValues = (): void => {
  remove([PersistKey.SUPER_SIGNUP_DETAILS]);
};
