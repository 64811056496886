import { gql, useQuery } from '@apollo/client';
import { UpdateContactInput, useUpdateContact } from '@spaceship-fspl/graphql';
import { WebAppContactDetailsSection } from '@spaceship-fspl/graphql/src/__generated__/WebAppContactDetailsSection';
import {
  ContactDetailsCard,
  ContactDetailsCard_ContactFragment,
} from 'components/account-cards/contact-details';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import React, { useState } from 'react';

export interface ContactDetailsSectionProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const ContactDetailsSection: React.FC<
  React.PropsWithChildren<ContactDetailsSectionProps>
> = ({ isEditing, setIsEditing }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { popToast } = useNotifications();
  const [updateContact] = useUpdateContact();

  const resp = useQuery<WebAppContactDetailsSection>(gql`
    query WebAppContactDetailsSection {
      contact {
        id
        ...ContactDetailsCard_ContactFragment
      }
    }
    ${ContactDetailsCard_ContactFragment}
  `);

  const onSubmit = async (payload: UpdateContactInput): Promise<void> => {
    setIsLoading(true);

    try {
      await updateContact({
        variables: {
          input: {
            email: payload.email,
            phoneNumber: payload.phoneNumber,
            address: {
              postcode: payload.address?.postcode ?? '',
              state: payload.address?.state ?? '',
              suburb: payload.address?.suburb ?? '',
              streetName: payload.address?.streetName ?? '',
              streetNumber: payload.address?.streetNumber ?? '',
              streetType: payload.address?.streetType ?? '',
              unitNumber: payload.address?.unitNumber,
              country: payload.address?.country ?? 'AU',
            },
          },
        },
      });
      popToast({
        message: 'Successfully updated your contact details!',
        level: 'success',
      });
      setIsEditing(false);
    } catch (error) {
      addRumError({ error });
      popToast({
        message:
          'Unable to update contact details. Please try again or contact support.',
        level: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContactDetailsCard
      isSignup={true}
      contact={resp.data?.contact}
      onSubmit={onSubmit}
      edit={{
        isEditing,
        isLoading,
        onClick: () => setIsEditing(true),
        editTrackingProperties: {
          name: 'signup_summary_contact_details_card_edit',
        },
        onCancel: () => setIsEditing(false),
        submitText: 'Save',
      }}
    />
  );
};
