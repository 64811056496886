import { Box, Text } from '@spaceship-fspl/components';
import {
  BorderRadius,
  Color,
  FlattenInterpolationWithTheme,
  getColor,
  map,
} from '@spaceship-fspl/styles';
import { createVariant } from '@spaceship-fspl/substance-style';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

type TooltipPlacement = Exclude<TippyProps['placement'], undefined>;

export interface TooltipProps extends TippyProps {
  content: string | React.ReactElement;
  isError?: boolean;
  borderRadius?: BorderRadius;
  maxWidth?: number;
}

const DEFAULT_PLACEMENT: TooltipPlacement = 'top';

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  content,
  borderRadius = 'xxxs',
  maxWidth = 170,
  placement = DEFAULT_PLACEMENT,
  isError = false,
  showOnCreate,
  ...props
}) => {
  return (
    <Tippy
      render={(props) => {
        const renderPlacement = props['data-placement'] || DEFAULT_PLACEMENT;
        return (
          <StyledTooltipBox
            placement={renderPlacement}
            maxWidth={maxWidth}
            borderRadius={borderRadius}
            {...props}
          >
            {typeof content === 'string' ? (
              <Text
                variant={4}
                color="neutral.000"
                isBold={true}
                align="center"
              >
                {content}
              </Text>
            ) : (
              content
            )}

            <StyledArrow placement={renderPlacement} data-popper-arrow />
          </StyledTooltipBox>
        );
      }}
      placement={placement}
      showOnCreate={isError ? true : showOnCreate}
      {...props}
    >
      {children}
    </Tippy>
  );
};

const TOOLTIP_COLOR: Color = 'neutral.100';
const TOOLTIP_OFFSET_BASE = 4;
const TOOLTIP_OFFSET_AXIS = 16;
const TOOLTIP_ARROW_HEIGHT = 6;
const TOOLTIP_ARROW_WIDTH = 12;

const getArrowStyles = (
  placement: 'top' | 'bottom' | 'left' | 'right',
): FlattenInterpolationWithTheme => css`
  ${placement}: 100%;
  border-${placement}: ${TOOLTIP_ARROW_HEIGHT}px solid ${getColor(
    TOOLTIP_COLOR,
  )};

  ${
    placement === 'top' || placement === 'bottom'
      ? `
    border-left: ${TOOLTIP_ARROW_WIDTH / 2}px solid transparent;
    border-right: ${TOOLTIP_ARROW_WIDTH / 2}px solid transparent;
  `
      : `
    border-top: ${TOOLTIP_ARROW_WIDTH / 2}px solid transparent;
    border-bottom: ${TOOLTIP_ARROW_WIDTH / 2}px solid transparent;
  `
  }
`;

const placementVariant = createVariant({
  top: `transform: translateY(-${TOOLTIP_OFFSET_BASE}px);`,
  'top-start': `transform: translateX(-${TOOLTIP_OFFSET_AXIS}px) translateY(-${TOOLTIP_OFFSET_BASE}px);`,
  'top-end': `transform: translateX(${TOOLTIP_OFFSET_AXIS}px) translateY(-${TOOLTIP_OFFSET_BASE}px);`,
  bottom: `transform: translateY(${TOOLTIP_OFFSET_BASE}px);`,
  'bottom-start': `transform: translateX(-${TOOLTIP_OFFSET_AXIS}px) translateY(${TOOLTIP_OFFSET_BASE}px);`,
  'bottom-end': `transform: translateX(${TOOLTIP_OFFSET_AXIS}px) translateY(${TOOLTIP_OFFSET_BASE}px);`,
  left: `transform: translateX(-${TOOLTIP_OFFSET_BASE}px);`,
  'left-start': `transform: translateX(-${TOOLTIP_OFFSET_BASE}px) translateY(-${TOOLTIP_OFFSET_AXIS}px);`,
  'left-end': `transform: translateX(-${TOOLTIP_OFFSET_BASE}px) translateY(${TOOLTIP_OFFSET_AXIS}px);`,
  right: `transform: translateX(${TOOLTIP_OFFSET_BASE}px);`,
  'right-start': `transform: translateX(${TOOLTIP_OFFSET_BASE}px) translateY(-${TOOLTIP_OFFSET_AXIS}px);`,
  'right-end': `transform: translateX(${TOOLTIP_OFFSET_BASE}px) translateY(${TOOLTIP_OFFSET_AXIS}px);`,
  auto: `transform: translateY(-${TOOLTIP_OFFSET_BASE}px);`,
  'auto-start': `transform: translateX(-${TOOLTIP_OFFSET_AXIS}px) translateY(-${TOOLTIP_OFFSET_BASE}px);`,
  'auto-end': `transform: translateX(${TOOLTIP_OFFSET_AXIS}px) translateY(-${TOOLTIP_OFFSET_BASE}px);`,
});

const arrowPlacementVariant = createVariant({
  top: getArrowStyles('top'),
  'top-start': css`
    ${getArrowStyles('top')}
    margin-left: ${TOOLTIP_OFFSET_AXIS}px;
  `,
  'top-end': css`
    ${getArrowStyles('top')}
    margin-left: -${TOOLTIP_OFFSET_AXIS}px;
  `,
  bottom: getArrowStyles('bottom'),
  'bottom-start': css`
    ${getArrowStyles('bottom')}
    margin-left: ${TOOLTIP_OFFSET_AXIS}px;
  `,
  'bottom-end': css`
    ${getArrowStyles('bottom')}
    margin-left: -${TOOLTIP_OFFSET_AXIS}px;
  `,
  left: getArrowStyles('left'),
  'left-start': css`
    ${getArrowStyles('left')}
    margin-top: ${TOOLTIP_OFFSET_AXIS}px;
  `,
  'left-end': css`
    ${getArrowStyles('left')}
    margin-top: -${TOOLTIP_OFFSET_AXIS}px;
  `,
  right: getArrowStyles('right'),
  'right-start': css`
    ${getArrowStyles('right')}
    margin-top: ${TOOLTIP_OFFSET_AXIS}px;
  `,
  'right-end': css`
    ${getArrowStyles('right')}
    margin-top: -${TOOLTIP_OFFSET_AXIS}px;
  `,
  auto: getArrowStyles('top'),
  'auto-start': css`
    ${getArrowStyles('top')}
    margin-left: ${TOOLTIP_OFFSET_AXIS}px;
  `,
  'auto-end': css`
    ${getArrowStyles('top')}
    margin-left: -${TOOLTIP_OFFSET_AXIS}px;
  `,
});

const fadeKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledArrow = styled.div<{ placement: TooltipPlacement }>`
  ${({ placement }) => map(placement, (p) => arrowPlacementVariant(p))}
  height: 0;
  width: 0;
`;

const StyledTooltipBox = styled(Box).attrs({
  backgroundColor: TOOLTIP_COLOR,
  boxShadow: 'md',
  paddingX: 'sm',
  paddingY: 'xs',
  position: 'relative',
})<{ placement: TooltipPlacement }>`
  ${({ placement }) => map(placement, (p) => placementVariant(p))}
  opacity: 0;
  animation: ${fadeKeyframes} 150ms ease-in forwards;
`;
