import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSuper, useIsAuthenticated } from '@spaceship-fspl/auth';
import {
  Box,
  Card,
  Columns,
  DisimissibleInfoPanel,
  Heading,
  InfoPanel,
  Stack,
  Text,
  TextLink,
  Tiles,
} from '@spaceship-fspl/components';
import {
  articleScalars,
  useDismiss,
  useSuperPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { WebAppSuperCrossSellDashboard } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperCrossSellDashboard';
import { WebAppSuperDashboard } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperDashboard';
import {
  FeatherInfoIcon,
  SuperProductStarsIcon,
} from '@spaceship-fspl/icons-web';
import { height, themes, width } from '@spaceship-fspl/styles';
import {
  useGetCurrentInvestmentAllocation,
  useGetMember,
} from '@spaceship-fspl/super';
import { Articles } from 'components/articles';
import { Button } from 'components/button';
import { HeaderWave } from 'components/header-wave';
import { Error } from 'components/layouts/error';
import { PageContainer } from 'components/layouts/page';
import { LinkButton } from 'components/link-button';
import { TodoCard } from 'components/todo-card';
import { DynamicConfigDismissibleId } from 'helpers/dynamic-config';
import {
  JoinedBeforeSftState,
  useJoinedBeforeSft,
} from 'helpers/hooks/use-joined-before-sft';
import { useSftValues } from 'helpers/hooks/use-sft-values';
import { SuperCrossSell } from 'pages/account/section/super-cross-sell';
import { Routes } from 'pages/routes';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { SuperBalanceCard } from './balance-card';
import { ImportantDocumentsCard } from './important-documents-card';
import { ReferralsCard } from './referrals-card';
import { SuperMatchCard } from './supermatch-card';
import { TransactionsCard } from './transactions-card';
import { SuperUnitPriceCard } from './unit-price-card';
import { useTodo } from './use-todo';

const StyledSuperProductStarsIcon = styled(SuperProductStarsIcon)`
  && {
    ${height({ xs: 100, lg: 150 })}
    ${width({ xs: 100, lg: 150 })}
  }
`;

export const SuperDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const canReadSuper = useCanReadSuper();
  const navigate = useNavigate();
  const superFlags = useSuperPortfolioFlags();
  const { sftOneSuperGoLiveDateFormatted } = useSftValues();

  if (canReadSuper) {
    return <SuperAccountDashboard />;
  }
  if (
    superFlags.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled &&
    superFlags.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimerLink
  ) {
    return (
      <PageContainer>
        <Stack spaceY="md">
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
              <ThemeProvider theme={themes.webapp.onWhite}>
                <Card
                  borderRadius="sm"
                  boxShadow="sm"
                  paddingX={{ xs: 'md', lg: 'lg' }}
                  paddingTop={{ xs: 'md', lg: 'lg' }}
                  paddingBottom="xl"
                >
                  <Columns alignX="center">
                    <Columns.Column width={{ xs: 1 }}>
                      <Box marginBottom="lg">
                        <Stack spaceY="sm" alignX="center">
                          <StyledSuperProductStarsIcon color="indigo.070" />

                          <Heading variant={3} align="center" component="h3">
                            Spaceship Super is currently unavailable to new
                            members
                          </Heading>

                          <Text variant={1}>
                            Spaceship Super is being transferred to a new
                            sub-plan of OneSuper. While this transfer takes
                            place, we are unable to open new accounts. We will
                            contact you on your email after{' '}
                            {sftOneSuperGoLiveDateFormatted}. You can read more
                            about the transfer{' '}
                            <TextLink
                              href={
                                superFlags.data?.superPortfolioFlags
                                  ?.sftLimitedServicePeriodDisclaimerLink
                              }
                            >
                              here
                            </TextLink>
                          </Text>
                          <Button
                            variant="primary"
                            size="lg"
                            type="reset"
                            trackingProperties={{
                              name: 'go_back_super_unavailable',
                            }}
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Back
                          </Button>
                        </Stack>
                      </Box>
                    </Columns.Column>
                  </Columns>
                </Card>
              </ThemeProvider>
            </Columns.Column>
          </Columns>
        </Stack>
      </PageContainer>
    );
  }

  return <SuperCrossSellDashboard />;
};

const SuperAccountDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const [dismiss, dismissMeta] = useDismiss();

  const navigate = useNavigate();
  const {
    data: memberData,
    error: memberError,
    isLoading: memberIsLoading,
  } = useGetMember();

  const { state: joinedBeforeSftState } = useJoinedBeforeSft();

  const { hasTodoItems, todoItems } = useTodo();

  const currentSuperPortfolioKey =
    useGetCurrentInvestmentAllocation().investmentAllocation?.key;

  const handleDismissSftPostMigrationDisclaimer: () => void = async () => {
    await dismiss({
      variables: {
        input: {
          id: DynamicConfigDismissibleId.SUPER_SFT_POST_MIGRATION_INFORMATION_CARD,
        },
      },
      optimisticResponse: {
        dismiss: {
          __typename: 'DismissPayload',
          dismissible: {
            __typename: 'Dismissible',
            id: DynamicConfigDismissibleId.SUPER_SFT_POST_MIGRATION_INFORMATION_CARD,
            hidden: true,
          },
        },
      },
    });
  };

  const resp = useQuery<WebAppSuperDashboard>(
    gql`
      query WebAppSuperDashboard(
        $portfolio: SuperPortfolio!
        $sftPostMigrationInformationDismissibleId: String!
      ) {
        joinSftPostMigrationInformationDismissible: dismissible(
          id: $sftPostMigrationInformationDismissibleId
        ) {
          id
          hidden
        }
        contact {
          id
          preferredName
        }
        superPortfolioInformation(portfolio: $portfolio) {
          id
          name
        }
        articles {
          id
          ...articleScalars
        }
      }
      ${articleScalars}
    `,
    {
      skip: !currentSuperPortfolioKey,
      variables: {
        portfolio: currentSuperPortfolioKey?.toUpperCase(),
        sftPostMigrationInformationDismissibleId:
          DynamicConfigDismissibleId.SUPER_SFT_POST_MIGRATION_INFORMATION_CARD,
      },
    },
  );
  const info = resp.data?.superPortfolioInformation;
  const articles = resp.data?.articles;
  const flagsResp = useSuperPortfolioFlags();
  const { sftOneSuperGoLiveDateFormatted } = useSftValues();
  const sftLink =
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimerLink;
  const sftLimitedServicePeriodDisclaimer =
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimer;
  const sftPostMigrationDisclaimer =
    flagsResp.data?.superPortfolioFlags?.sftPostMigrationDisclaimer;
  const sftLimitedServicePeriodEnabled =
    sftLink &&
    sftLimitedServicePeriodDisclaimer &&
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;
  const sftPostMigrationEnabled =
    sftPostMigrationDisclaimer &&
    flagsResp.data?.superPortfolioFlags?.sftPostMigrationEnabled;
  const showSftPostMigrationInformationDismissed =
    resp.data?.joinSftPostMigrationInformationDismissible?.hidden ?? true;
  if (!memberIsLoading && memberError) {
    if (sftLimitedServicePeriodEnabled) {
      return (
        <Error
          title="We’re currently experiencing a service outage for Spaceship Super."
          subtitle={
            <span>
              This interruption is related to the transfer to OneSuper. We will
              restore your account information as quickly as possible.
              Information about your account may not be up to date during the
              transfer period between 10 May 2024 and{' '}
              {sftOneSuperGoLiveDateFormatted}. Read more about the transfer{' '}
              <TextLink href={sftLink} target="_blank" color="indigo.070">
                here
              </TextLink>
              .
            </span>
          }
          iconColor="indigo.070"
          buttonText="Try again"
          onContinue={{
            onClick: () => {
              window.location.reload();
            },
            trackingProperties: {
              name: 'super_api_failed_try_again',
            },
          }}
        />
      );
    }

    return (
      <Error
        title="We're unable to display your Spaceship Super details right now."
        subtitle="We’ll fix it as soon as possible."
        iconColor="indigo.070"
        buttonText="Try again"
        onContinue={{
          onClick: () => {
            window.location.reload();
          },
          trackingProperties: {
            name: 'super_api_failed_try_again',
          },
        }}
      />
    );
  }

  return (
    <PageContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="md"
      >
        <HeaderWave
          name={resp.data?.contact?.preferredName || memberData?.givenName}
        />

        {!sftLimitedServicePeriodEnabled && sftPostMigrationEnabled && (
          <Button
            onClick={() => {
              navigate(Routes.SUPER_CONTRIBUTIONS);
            }}
            variant="primary"
            size="sm"
            trackingProperties={{
              name: 'super_dashboard_contribute',
            }}
          >
            Contribute
          </Button>
        )}
      </Box>

      {sftLimitedServicePeriodEnabled ? (
        <InfoPanel textVariant={3} color="pink.030" icon={FeatherInfoIcon}>
          {sftLimitedServicePeriodDisclaimer} Read more about the transfer{' '}
          <TextLink href={sftLink} target="_blank" color="indigo.070">
            here
          </TextLink>
          .
        </InfoPanel>
      ) : null}

      {joinedBeforeSftState === JoinedBeforeSftState.JOINED_BEFORE &&
      sftPostMigrationDisclaimer ? (
        <DisimissibleInfoPanel
          color={'indigo.040'}
          dismissHandler={handleDismissSftPostMigrationDisclaimer}
          dismissed={showSftPostMigrationInformationDismissed}
          dismissalPending={dismissMeta?.loading ?? false}
          dismissible={true}
        >
          <Text variant={3} color="neutral.085">
            {sftPostMigrationDisclaimer}
          </Text>
        </DisimissibleInfoPanel>
      ) : null}

      <ReferralsCard />
      <SuperMatchCard />

      <Stack spaceY={{ xs: 'md', md: 'lg' }}>
        <Tiles
          columns={{ xs: 1, lg: hasTodoItems ? 3 : 2 }}
          spaceX="md"
          spaceY="md"
        >
          {hasTodoItems && <TodoCard items={todoItems} />}

          <SuperBalanceCard
            infoRoute={Routes.SUPER_BALANCE}
            portfolioFriendlyName={info?.name}
          />

          <SuperUnitPriceCard
            infoRoute={Routes.SUPER_UNIT_PRICE}
            portfolioFriendlyName={info?.name}
          />
        </Tiles>

        <Tiles columns={{ xs: 1, lg: 2 }} spaceX="md" spaceY="md">
          <Stack spaceY="xxs">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading component="h3" variant={4} isBold={true}>
                Important Documents
              </Heading>
              <LinkButton
                trackingProperties={{
                  name: 'super_dashboard_important_documents_view_all',
                }}
                onClick={() => {
                  navigate(Routes.SUPER_IMPORTANT_DOCUMENTS);
                }}
                size="xxs"
              >
                View all &rsaquo;
              </LinkButton>
            </Box>
            <ImportantDocumentsCard show={5} />
          </Stack>

          <Stack spaceY="xxs">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading component="h3" variant={4} isBold={true}>
                Recent transactions
              </Heading>
              <LinkButton
                trackingProperties={{
                  name: 'super_dashboard_transaction_history_view_all',
                }}
                onClick={() => {
                  navigate(Routes.SUPER_TRANSACTIONS);
                }}
                size="xxs"
              >
                View all &rsaquo;
              </LinkButton>
            </Box>
            <TransactionsCard show={5} />
          </Stack>
        </Tiles>

        {!!articles && (
          <Stack spaceY="sm">
            <Heading component="h3" variant={4} isBold={true}>
              Today&apos;s news
            </Heading>

            <Articles articles={articles} />
          </Stack>
        )}
      </Stack>
    </PageContainer>
  );
};

const SuperCrossSellDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const isAuthenticated = useIsAuthenticated();

  const resp = useQuery<WebAppSuperCrossSellDashboard>(
    gql`
      query WebAppSuperCrossSellDashboard {
        contact {
          id
          firstName
          preferredName
        }
      }
    `,
    {
      skip: !isAuthenticated,
    },
  );

  return (
    <PageContainer>
      <Stack spaceY="md">
        <Box display="flex" justifyContent="space-between">
          <HeaderWave
            name={
              resp.data?.contact?.preferredName || resp.data?.contact?.firstName
            }
          />
        </Box>

        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
            <SuperCrossSell />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};
