import { Box, Card, Heading, Inline, Text } from '@spaceship-fspl/components';
import {
  DataPoint,
  formatPercentage,
  TimeScale,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { borderColor, Color } from '@spaceship-fspl/styles';
import { Chart } from 'components/chart';
import {
  ChartTabSelector,
  chartTimeScaleTabOptions,
} from 'components/chart-tab-selector';
import { LabeledField } from 'components/labeled-field';
import { PerformanceIndicator } from 'components/performance-indicator';
import { RouterLink } from 'components/router-link';
import { isSameDay, isValid } from 'date-fns';
import { AccessibilityLabel } from 'helpers/accessibility';
import { toLongDate } from 'helpers/format';
import React from 'react';
import styled from 'styled-components';

const CHART_HEIGHT = 100;

const fieldColors: Record<'label' | 'value', Color> = {
  label: 'neutral.070',
  value: 'neutral.000',
};

interface UnitPricePerformance {
  oneYearPerformanceMovement: number;
  day?: string | number | null;
  week?: string | number | null;
  month?: string | number | null;
  year?: string | number | null;
}

interface Portfolio {
  name: string;
  latestUnitPrice?: string | number;
  latestUnitPriceDate?: Date;
  hasLessThanOneYearOfData: boolean;
}

export interface UnitPriceCardProps {
  graphData: Array<DataPoint>;
  scale: TimeScale;
  setScale: (scale: TimeScale) => void;
  portfolio: Portfolio;
  performance: UnitPricePerformance;
  infoRoute?: string;
  trackChartName: string;
  chartHeight?: number;
  showUnitPriceMovement?: boolean;
}

export const UnitPriceCard: React.FC<
  React.PropsWithChildren<UnitPriceCardProps>
> = ({
  graphData,
  scale,
  setScale,
  portfolio: {
    name: portfolioName,
    latestUnitPrice,
    latestUnitPriceDate,
    hasLessThanOneYearOfData,
  },
  showUnitPriceMovement = true,
  performance: {
    oneYearPerformanceMovement,
    day: dayPerformance,
    week: weekPerformance,
    month: monthPerformance,
    year: yearPerformance,
  },
  infoRoute,
  trackChartName,
  chartHeight,
}) => {
  const hasGraphData = graphData.length > 0;
  const priceToDisplay = latestUnitPrice ? `$${latestUnitPrice}` : '—';
  let dateToDisplay = '—';

  if (latestUnitPriceDate) {
    if (isSameDay(latestUnitPriceDate, new Date())) {
      dateToDisplay = "Today's";
    } else if (isValid(latestUnitPriceDate)) {
      dateToDisplay = toLongDate(latestUnitPriceDate);
    }
  }

  return (
    <Card
      aria-label={AccessibilityLabel.UNIT_PRICE_CARD}
      backgroundColor="indigo.100"
      height="100%"
      padding="lg"
    >
      {dateToDisplay && (
        <Inline spaceX="xxxs" alignY="center">
          <Text variant={3} color="neutral.000" isBold={true}>
            {portfolioName} unit price ({dateToDisplay})
          </Text>

          {infoRoute && (
            <RouterLink
              to={infoRoute}
              trackingProperties={{ name: 'unit_price_card_info' }}
            >
              <Box lineHeight={0}>
                <FeatherInfoIcon
                  size="sm"
                  color="neutral.000"
                  strokeWidth={1.5}
                />
              </Box>
            </RouterLink>
          )}
        </Inline>
      )}

      <Heading variant={2} color="neutral.000">
        {priceToDisplay}
      </Heading>

      {showUnitPriceMovement ? (
        <Box
          data-testid="unit-price-movement-section"
          display="flex"
          justifyContent="space-between"
          marginTop="xs"
        >
          <LabeledField
            size="md"
            label={hasLessThanOneYearOfData ? 'All time' : 'Year'}
            colors={fieldColors}
          >
            <Box display="flex" alignItems="center">
              <Box marginRight="xxxs" lineHeight={0}>
                <PerformanceIndicator
                  direction={oneYearPerformanceMovement < 0 ? 'down' : 'up'}
                  size="xxs"
                  upColor="neutral.000"
                  downColor="neutral.000"
                />
              </Box>
              {formatPercentage(yearPerformance, 2)}
            </Box>
          </LabeledField>

          <Inline spaceX="md">
            {dayPerformance && (
              <LabeledField size="sm" label="Day" colors={fieldColors}>
                {formatPercentage(dayPerformance, 2)}
              </LabeledField>
            )}

            <LabeledField size="sm" colors={fieldColors} label="Week">
              {formatPercentage(weekPerformance, 2)}
            </LabeledField>

            <LabeledField size="sm" colors={fieldColors} label="Month">
              {formatPercentage(monthPerformance, 2)}
            </LabeledField>
          </Inline>
        </Box>
      ) : null}

      {hasGraphData ? (
        <Box
          position="relative"
          height={chartHeight ?? CHART_HEIGHT}
          marginBottom="md"
        >
          <Chart
            tooltipEnabled={true}
            tooltipBackgroundColor="neutral.000"
            labelColor="neutral.070"
            tooltipTextColor="black.100"
            formatX={(value): string => toLongDate(new Date(value))}
            formatY={(value): string => `$${value}`}
            endLabels={true}
            color="blue.050"
            endColor="mint.030"
            data={graphData}
          />
        </Box>
      ) : (
        <DashedBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="xs"
          paddingX="md"
          paddingY="sm"
          marginTop="sm"
          position="relative"
        >
          <Text variant={2} color="neutral.070" isBold={true} align="center">
            Unit price performance data will display here once the relevant time
            period has passed.
          </Text>
        </DashedBox>
      )}

      <Box paddingTop="sm">
        <ChartTabSelector
          options={chartTimeScaleTabOptions}
          selected={scale}
          color="mint.100"
          setSelected={(opt: TimeScale): void => setScale(opt)}
          trackChartName={trackChartName}
        />
      </Box>
    </Card>
  );
};

const DashedBox = styled(Box)`
  border-style: dashed;
  border-width: 1.5px;
  ${borderColor('neutral.070')};
`;
