import { ScheduleFrequency } from '@spaceship-fspl/graphql';
import {
  formatCurrency,
  isValidDollarAmount,
  sydneyToday,
} from '@spaceship-fspl/helpers';
import { add, isAfter, isBefore, isValid, parse } from 'date-fns';

const MAX_START_DATE_MONTHS = 3;
export const VOYAGER_INVESTMENT_DEFAULT_FREQUENCY = ScheduleFrequency.WEEKLY;

export const getVoyagerInvestmentDefaultStartDate = (): Date =>
  add(sydneyToday(), {
    days: 3,
  });
export const getVoyagerInvestmentPlanMinStartDate = (): Date => sydneyToday();
export const getVoyagerInvestmentPlanMaxStartDate = (): Date =>
  add(getVoyagerInvestmentPlanMinStartDate(), {
    months: MAX_START_DATE_MONTHS,
  });

export const investmentPlanStartDateValidation = (
  value: string | Date,
  format = '',
): boolean | string => {
  if (typeof value === 'string' && !format) {
    throw new Error('string date must have date format');
  }
  const formattedDateValue =
    typeof value === 'string' ? parse(value, format, new Date()) : value;

  if (!isValid(formattedDateValue)) {
    return 'Date is invalid';
  }

  if (isBefore(formattedDateValue, getVoyagerInvestmentPlanMinStartDate())) {
    return 'Date must be in the future';
  }

  if (isAfter(formattedDateValue, getVoyagerInvestmentPlanMaxStartDate())) {
    return `Date must not be more than ${MAX_START_DATE_MONTHS} months from today`;
  }
  return true;
};

export const goalAmountValidation = (
  value: string,
  totalBalance: number,
): boolean | string => {
  const amount = Number(value);

  if (isNaN(amount) || !isValidDollarAmount(value)) {
    return 'Goal amount is invalid';
  }

  if (amount <= totalBalance) {
    return `Goal amount must be more than your current total balance of ${formatCurrency(
      totalBalance,
    )}`;
  }

  if (amount > 2000000) {
    return 'Goal amount must be $2m or less';
  }

  return true;
};
