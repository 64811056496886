import { useNavigate } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
  Visible,
} from '@spaceship-fspl/components';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { VoyagerProductStarsIcon } from '@spaceship-fspl/icons-web';
import { height, themes, width } from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { Routes } from 'pages/routes';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

export const VoyagerCrossSell: React.FC<React.PropsWithChildren> = () => {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={themes.webapp.onWhite}>
      <Card
        borderRadius="sm"
        boxShadow="sm"
        paddingX={{ xs: 'md', lg: 'lg' }}
        paddingTop={{ xs: 'md', lg: 'lg' }}
        paddingBottom="xl"
      >
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, lg: 3 / 4, xl: 2 / 3 }}>
            <Box marginBottom="lg">
              <Stack spaceY="sm" alignX="center">
                <StyledVoyagerProductStarsIcon color="indigo.070" />

                <Heading variant={3} align="center" component="h3">
                  Explore
                  <br />
                  Spaceship Voyager
                </Heading>

                <Text variant={1} align="center">
                  Start investing with as little as{' '}
                  <Visible isHidden={{ xs: true, lg: false }}>
                    <br />
                  </Visible>
                  you like.
                </Text>
              </Stack>
            </Box>

            <Stack spaceY="lg" alignX="center">
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  navigate(Routes.VOYAGER_ONBOARDING_CROSS_SELL_START);
                }}
                trackingProperties={{
                  name: 'account_voyager_cross_sell_start',
                }}
              >
                Sign up
              </Button>
              <TextLink
                color="indigo.070"
                rel="noreferrer"
                href={InternalRoutes.VOYAGER_LEARN_MORE}
                target="_blank"
              >
                Learn more
              </TextLink>
            </Stack>
          </Columns.Column>
        </Columns>
      </Card>
    </ThemeProvider>
  );
};

const StyledVoyagerProductStarsIcon = styled(VoyagerProductStarsIcon)`
  && {
    ${height({ xs: 100, lg: 150 })}
    ${width({ xs: 100, lg: 150 })}
  }
`;
