import { ProductOffering } from '@spaceship-fspl/graphql';
import React, { createContext, useContext, useState } from 'react';

interface SignupProductIntent {
  product?: ProductOffering;
  onSetProduct: (value: ProductOffering) => void;
}

const SignupProductIntentContext = createContext<
  SignupProductIntent | undefined
>(undefined);

export const SignupProductIntentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [product, setProduct] = useState<ProductOffering>();

  return (
    <SignupProductIntentContext.Provider
      value={{
        product,
        onSetProduct: setProduct,
      }}
    >
      {children}
    </SignupProductIntentContext.Provider>
  );
};

export const useSignupProductIntent = (): SignupProductIntent => {
  const context = useContext(SignupProductIntentContext);

  if (!context) {
    throw new Error(
      'webapp: useSignupProductIntent must be wrapped with SignupProductIntentProvider',
    );
  }

  return context;
};
