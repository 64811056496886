import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSaver } from '@spaceship-fspl/auth';
import { Box, Spinner } from '@spaceship-fspl/components';
import { useInitiateUserVerification } from '@spaceship-fspl/data';
import { useIsStatusUnknown, useOverallStatus } from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { useNotifications } from 'contexts/notifications';
import { Routes } from 'pages/routes';
import React, { FC, useCallback, useEffect, useRef } from 'react';

const ruleset = GreenIdRuleSet.Enum.VOYAGER_ONBOARDING;

export const VoyagerCrossSellStart: FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const canReadSaver = useCanReadSaver();
  const hasStartedVerification = useRef(false);
  const initUserVerification = useInitiateUserVerification(ruleset);
  const isGreenIdUnknownStatus = useIsStatusUnknown(ruleset);
  const greenidOverallStatus = useOverallStatus(ruleset);

  const firstRoute = Routes.VOYAGER_ONBOARDING_PORTFOLIO;

  const startVerifyingUser = useCallback(async () => {
    hasStartedVerification.current = true;

    try {
      await initUserVerification.mutateAsync({
        rule_set: GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
      });
      navigate(firstRoute, { replace: true });
    } catch {
      notifications.popBanner({
        message: 'Oops, something went wrong',
        cta: {
          message: 'Try again',
          action: startVerifyingUser,
        },
      });
    }
  }, [firstRoute, initUserVerification, navigate, notifications]);

  useEffect(() => {
    if (canReadSaver) {
      notifications.popToast({
        message: 'You have already signed up to Voyager.',
        level: 'info',
      });
      navigate(Routes.VOYAGER_DASHBOARD, { replace: true });
    }
  }, [canReadSaver, navigate, notifications]);

  useEffect(() => {
    // Check if user has already initialised verification
    if (
      !canReadSaver &&
      (greenidOverallStatus === undefined || isGreenIdUnknownStatus)
    ) {
      if (!hasStartedVerification.current) {
        startVerifyingUser();
      }
    } else {
      navigate(firstRoute, { replace: true });
    }
  }, [
    canReadSaver,
    greenidOverallStatus,
    isGreenIdUnknownStatus,
    navigate,
    startVerifyingUser,
    firstRoute,
  ]);

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  );
};
