import {
  useGetUserVerification,
  useInitiateUserVerification,
} from '@spaceship-fspl/data';
import {
  rethrowError,
  sydneyToday,
  timestampToDate,
} from '@spaceship-fspl/helpers';
import {
  FraudService,
  FraudVerificationStatus,
  GreenIdRuleSet,
  IFraudServiceCheck,
  IGreenIdInteractiveSource,
} from '@spaceship-fspl/types/externalapi';
import { differenceInCalendarDays } from 'date-fns';
import { useCallback, useMemo, useRef, useState } from 'react';

import {
  ID_OPTIONS,
  IDOption,
  IDSourceList,
  SourceExhaustedInfo,
} from './constants';
import {
  isFraudStatusInProgress,
  isFraudStatusRejected,
  isFraudStatusUnknown,
  isFraudStatusVerified,
  isIDSourceStatusVerified,
  isSourceExhausted,
  parseSourceName,
  parseSourcesLeftToVerify,
} from './helpers';

const useData = (
  ruleset: GreenIdRuleSet.Enum,
): IFraudServiceCheck | undefined => {
  const { data: userVerificationData } = useGetUserVerification(ruleset);
  return userVerificationData?.service_checks?.find(
    ({ service }) => service === FraudService.Enum.GREENID,
  );
};

export const useID = (
  ruleset: GreenIdRuleSet.Enum,
): string | null | undefined => useData(ruleset)?.id;

export const useOverallStatus = (
  ruleset: GreenIdRuleSet.Enum,
): FraudVerificationStatus.Enum | null | undefined => useData(ruleset)?.status;

export const useIsStatusVerified = (ruleset: GreenIdRuleSet.Enum): boolean =>
  isFraudStatusVerified(useOverallStatus(ruleset));
export const useIsStatusInProgress = (ruleset: GreenIdRuleSet.Enum): boolean =>
  isFraudStatusInProgress(useOverallStatus(ruleset));
export const useIsStatusRejected = (ruleset: GreenIdRuleSet.Enum): boolean =>
  isFraudStatusRejected(useOverallStatus(ruleset));
export const useIsStatusUnknown = (ruleset: GreenIdRuleSet.Enum): boolean =>
  isFraudStatusUnknown(useOverallStatus(ruleset));

export const useDaysSinceLastCheck = (ruleset: GreenIdRuleSet.Enum): number => {
  const lastCheckedDate = timestampToDate(useData(ruleset)?.last_checked_at);
  return lastCheckedDate
    ? differenceInCalendarDays(sydneyToday(), lastCheckedDate)
    : 0;
};

export const useSourcesLeftToVerify = (
  ruleset: GreenIdRuleSet.Enum,
): number => {
  const { data: userVerification } = useGetUserVerification(ruleset);
  return useMemo(() => {
    if (userVerification) {
      return parseSourcesLeftToVerify(
        userVerification?.green_id_source_details,
      );
    }
    return 0;
  }, [userVerification]);
};

export const useInteractiveSources = (
  ruleset: GreenIdRuleSet.Enum,
): IGreenIdInteractiveSource[] => {
  const { data: userVerification } = useGetUserVerification(ruleset);
  if (userVerification) {
    return userVerification?.green_id_source_details?.interactive_sources || [];
  }
  return [];
};

export const useIsSourceExhausted = (
  ruleset: GreenIdRuleSet.Enum,
  sourceNameOrNames?: IDSourceList | IDSourceList[],
): SourceExhaustedInfo => {
  return isSourceExhausted(sourceNameOrNames, useInteractiveSources(ruleset));
};

export const useGetNonExhaustedIDOptions = (
  ruleset: GreenIdRuleSet.Enum,
): Array<IDOption & SourceExhaustedInfo> => {
  const greenidInteractiveSources = useInteractiveSources(ruleset);
  return ID_OPTIONS.map((option) => ({
    ...option,
    ...isSourceExhausted(option.value, greenidInteractiveSources),
  })).filter((option) => !option.isSourceExhausted);
};

export const useIsSourceVerified = (
  ruleset: GreenIdRuleSet.Enum,
  sourceNameOrNames?: IDSourceList | IDSourceList[],
): boolean => {
  const interactiveSources = useInteractiveSources(ruleset);

  if (sourceNameOrNames) {
    const sourceName = parseSourceName(sourceNameOrNames, interactiveSources);

    return (
      interactiveSources.filter(
        (source) =>
          source.name === sourceName && isIDSourceStatusVerified(source.status),
      ).length > 0
    );
  }
  return false;
};

export interface UseVerifyUserResults {
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  verifyUser: () => void;
  statusIsPending: boolean;
  statusIsVerified: boolean;
  hasNoVerification: boolean;
  sourcesLeftToVerify: number;
}

export const useVerifyUser = (
  ruleset: GreenIdRuleSet.Enum,
): UseVerifyUserResults => {
  const [isLoading, setIsLoading] = useState(false);
  const hasInitVerification = useRef(false);
  const initiateVerification = useInitiateUserVerification(ruleset);
  const getVerificationResp = useGetUserVerification(ruleset);

  const overallStatus = useOverallStatus(ruleset);
  const isUnknownStatus = useIsStatusUnknown(ruleset);
  const isPendingStatus = useIsStatusInProgress(ruleset);
  const isVerifiedStatus = useIsStatusVerified(ruleset);
  const sourcesLeftToVerify = useSourcesLeftToVerify(ruleset);
  const hasNoVerification = overallStatus === undefined || isUnknownStatus;

  const verifyUser = useCallback(async () => {
    // If the user:
    // - has no verification status
    // - has not called `initiateUserVerification` yet
    // Call `initiateUserVerification` first
    if (hasNoVerification && !hasInitVerification.current) {
      setIsLoading(true);

      try {
        await initiateVerification.mutateAsync({ rule_set: ruleset });
        hasInitVerification.current = true;
      } catch (error) {
        setIsLoading(false);
        rethrowError(error);
      }
    }

    // Then call `getVerification` again to get latest status.
    // Note: `verifyUser` may be called a second time (skipping the above
    // `initiateUserVerification`), if the status is still undefined/unknown,
    // which means the init verification wasn't complete on illion's side,
    // so try get verification again.
    try {
      setIsLoading(true);
      await getVerificationResp.refetch({ throwOnError: true });
    } catch (error) {
      rethrowError(error);
    } finally {
      setIsLoading(false);
    }
  }, [getVerificationResp, hasNoVerification, initiateVerification, ruleset]);

  return {
    isLoading:
      isLoading ||
      initiateVerification.isLoading ||
      getVerificationResp.isLoading,
    isError:
      initiateVerification.isError ||
      getVerificationResp.isError ||
      getVerificationResp.isRefetchError,
    isFetched: getVerificationResp.isFetched,
    verifyUser,
    statusIsPending: isPendingStatus,
    statusIsVerified: isVerifiedStatus,
    hasNoVerification,
    sourcesLeftToVerify,
  };
};
