import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  ActionLink,
  Box,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  TFNExemptionCode,
  useDeleteTfn,
  useDeleteTfnExemption,
  useUpdateTfn,
} from '@spaceship-fspl/graphql';
import {
  WebAppVoyagerTaxFileNumber,
  WebAppVoyagerTaxFileNumberVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerTaxFileNumber';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import { TFN_EXEMPTION_CODE_MAP } from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { AccessibilityLabel } from 'helpers/accessibility';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { voyagerPortfolios } from 'helpers/portfolios';
import { commonValidationRules } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React from 'react';
import { useForm } from 'react-hook-form';

interface PageParams {
  productId?: string;
}

export const VoyagerTaxFileNumber: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: PageParams = useParams();

  const resp = useQuery<
    WebAppVoyagerTaxFileNumber,
    WebAppVoyagerTaxFileNumberVariables
  >(
    gql`
      query WebAppVoyagerTaxFileNumber($id: ID!, $idProvided: Boolean!) {
        contact {
          id
          saverProductInstance {
            id
          }
          account {
            id
            saverProductInstance(id: $id) @include(if: $idProvided) {
              id
              portfolio
              tfnProvided
              tfnExemptionCode
            }
          }
        }
      }
    `,
    {
      variables: {
        id: productId,
        idProvided: !!productId,
      },
      onCompleted: (data) => {
        if (
          !data?.contact?.account?.saverProductInstance?.id &&
          data?.contact?.saverProductInstance?.id
        ) {
          navigate(
            `${Routes.VOYAGER_TFN}/${data.contact.saverProductInstance.id}`,
            { replace: true },
          );
        }
      },
    },
  );

  const product = resp.data?.contact?.account?.saverProductInstance;

  if (!product?.portfolio) {
    return null;
  }

  const config = voyagerPortfolios[product.portfolio];

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 2 / 3, xl: 1 / 2 }}>
          <Stack spaceY="xs">
            <Heading
              variant={4}
              color="neutral.085"
              align="center"
              component="h2"
            >
              {config.title}
            </Heading>
            {!!product.tfnProvided && <TFNProvided productId={productId} />}
            {!product.tfnProvided && product.tfnExemptionCode && (
              <TFNExemptionProvided
                productId={productId}
                tfnExemptionCode={product.tfnExemptionCode}
              />
            )}
            {!product.tfnProvided && !product.tfnExemptionCode && (
              <TFNForm productId={productId} />
            )}
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

interface BaseProps {
  productId: string;
}

interface ExceptionProps extends BaseProps {
  tfnExemptionCode: TFNExemptionCode;
}

const TFNProvided: React.FC<React.PropsWithChildren<BaseProps>> = (props) => {
  const navigate = useNavigate();
  const notification = useNotifications();
  const [deleteTfn, deleteTfnResult] = useDeleteTfn();

  return (
    <>
      <Stack spaceY="lg">
        <PageHeading title="Tax File Number" />

        <Box
          borderRadius="sm"
          backgroundColor="neutral.050"
          padding={{ xs: 'md', md: 'lg' }}
        >
          <Stack spaceY="sm">
            <Text variant={2} color="black.100" isBold={true}>
              You&apos;ve already provided us with your Tax File Number (TFN).
            </Text>

            <Text variant={2} color="black.100">
              You can choose to remove your TFN. If you do so, we will be
              required by law to withhold tax from your investment income at the
              highest marginal rate plus Medicare levy. However, you may be able
              to claim a credit for amounts withheld from the ATO as part of
              your tax return.
              <br />
              <br />
              Taxation law requires us to retain tax records for the later of
              five years after the record is made or five years after your final
              transaction has been completed. Your TFN will be destroyed once
              this period has expired.
            </Text>
          </Stack>
        </Box>
      </Stack>

      <Box display="flex" justifyContent="center" marginTop="xl">
        <Inline spaceX="md" spaceY="sm" alignX="center" alignY="center">
          <Button
            aria-label={AccessibilityLabel.CANCEL_BUTTON}
            variant="secondary"
            size="lg"
            trackingProperties={{
              name: 'voyager_tfn_provided_cancel',
            }}
            onClick={() => {
              navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="lg"
            aria-label={AccessibilityLabel.REMOVE_TFN_BUTTON}
            isLoading={deleteTfnResult.loading}
            trackingProperties={{
              name: 'voyager_tfn_provided_delete',
              product_id: props.productId,
            }}
            onClick={async () => {
              try {
                await deleteTfn({
                  variables: { input: { productId: props.productId } },
                });
                navigate(`${Routes.VOYAGER_TFN}/${props.productId}`);
                notification.popToast({
                  level: 'success',
                  message: 'Your Tax File Number has been removed',
                });
              } catch {
                notification.popToast({
                  level: 'error',
                  message: GENERIC_ERROR_MESSAGE,
                });
              }
            }}
          >
            Remove TFN
          </Button>
        </Inline>
      </Box>
    </>
  );
};

const TFNExemptionProvided: React.FC<
  React.PropsWithChildren<ExceptionProps>
> = (props) => {
  const navigate = useNavigate();
  const notification = useNotifications();
  const [deleteTfnExemption, deleteTfnExemptionResult] =
    useDeleteTfnExemption();

  return (
    <>
      <Stack spaceY="lg">
        <Heading variant={3} align="center" component="h1">
          Tax File Number
        </Heading>

        <Box
          borderRadius="sm"
          backgroundColor="neutral.050"
          padding={{ xs: 'md', md: 'lg' }}
        >
          <Stack spaceY="sm">
            <Text variant={2} color="black.100" isBold={true}>
              Exemption provided
            </Text>
            <Text variant={2} color="black.100">
              {TFN_EXEMPTION_CODE_MAP[props.tfnExemptionCode]}
            </Text>
          </Stack>
        </Box>
      </Stack>

      <Box display="flex" justifyContent="center" marginTop="xl">
        <Inline spaceX="md" spaceY="sm" alignX="center" alignY="center">
          <Button
            aria-label={AccessibilityLabel.CANCEL_BUTTON}
            variant="secondary"
            size="lg"
            trackingProperties={{
              name: 'voyager_tfn_exemption_provided_cancel',
            }}
            onClick={() => {
              navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="lg"
            aria-label={AccessibilityLabel.REMOVE_TFN_EXEMPTION_BUTTON}
            trackingProperties={{
              name: 'voyager_tfn_provided_delete',
              product_id: props.productId,
            }}
            onClick={async () => {
              try {
                await deleteTfnExemption({
                  variables: { input: { productId: props.productId } },
                });
                navigate(`${Routes.VOYAGER_TFN}/${props.productId}`);
                notification.popToast({
                  level: 'success',
                  message: 'Your Tax File Number exemption has been removed',
                });
              } catch {
                notification.popToast({
                  level: 'error',
                  message: GENERIC_ERROR_MESSAGE,
                });
              }
            }}
            isLoading={deleteTfnExemptionResult.loading}
          >
            Remove TFN Exemption
          </Button>
        </Inline>
      </Box>
    </>
  );
};

const TFNForm: React.FC<React.PropsWithChildren<BaseProps>> = (props) => {
  const navigate = useNavigate();
  const notification = useNotifications();
  const [updateTfn, updateTfnResult] = useUpdateTfn();
  const { control, handleSubmit } = useForm<{ tfn: string }>();

  const onSubmit = handleSubmit(async ({ tfn }) => {
    try {
      await updateTfn({
        variables: {
          input: {
            tfn,
            productId: props.productId,
          },
        },
      });
      navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
      notification.popToast({
        level: 'success',
        message: 'Your Tax File Number details have been updated',
      });
    } catch {
      notification.popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  });

  return (
    <Stack spaceY="lg">
      <Heading variant={3} align="center" component="h1">
        What&apos;s your Tax File Number
      </Heading>

      <form className="fs-block" onSubmit={onSubmit}>
        <Stack spaceY="lg">
          <Stack spaceY="xxs">
            <ControllerInput
              name="tfn"
              control={control}
              type="text"
              placeholder="Tax file number"
              rules={commonValidationRules.taxFileNumber}
            />
            <RouterLink
              to={`${Routes.VOYAGER_TFN_EXEMPTION}/${props.productId}`}
              trackingProperties={{ name: 'voyager_tfn_page_exempt' }}
            >
              <ActionLink color="indigo.070" size="xs" component="span">
                I&apos;m exempt from providing a TFN
              </ActionLink>
            </RouterLink>
          </Stack>
          <Stack spaceY="sm">
            <Text variant={2}>
              You can choose to provide us with your Tax File Number (TFN), or
              claim an exemption, for taxation purposes. You are not required to
              provide us with this information. If you don’t, we are required by
              law to deduct withholding tax from your investment income at the
              highest marginal rate plus Medicare levy. However, you may be able
              to claim a credit from the ATO for amounts withheld as part of
              your tax return. More information about claiming an exemption from
              quoting a TFN can be found on the ATO&apos;s website.
            </Text>
            <Text variant={2} isBold={true}>
              What’s my TFN?
            </Text>
            <Text variant={2}>
              A TFN is a unique identifier issued by the Australian Tax Office
              (ATO) for taxation purposes. Refer to the{' '}
              <TextLink
                color="indigo.070"
                href={ExternalRoutes.ATO_TFN}
                target="_blank"
                rel="noreferrer"
              >
                ATO&apos;s website
              </TextLink>
              .
            </Text>
          </Stack>
        </Stack>

        <Box display="flex" justifyContent="center" marginTop="xl">
          <Inline spaceX="md" spaceY="sm" alignX="center" alignY="center">
            <Button
              aria-label={AccessibilityLabel.CANCEL_BUTTON}
              variant="secondary"
              size="lg"
              trackingProperties={{
                name: 'voyager_tfn_form_cancel',
              }}
              onClick={() => {
                navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="lg"
              aria-label={AccessibilityLabel.SAVE_BUTTON}
              type="submit"
              trackingProperties={{
                name: 'voyager_tfn_form_save',
                product_id: props.productId,
              }}
              isLoading={updateTfnResult.loading}
            >
              Save
            </Button>
          </Inline>
        </Box>
      </form>
    </Stack>
  );
};
