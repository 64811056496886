import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { WebAppSuperBalance } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperBalance';
import { useGetCurrentInvestmentAllocation } from '@spaceship-fspl/super';
import { PageContainer } from 'components/layouts/page';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import React from 'react';

import { SuperBalanceCard } from './balance-card';

export const SuperBalance: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const isReturnsEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPER_RETURNS_ENABLED,
  );
  const currentSuperPortfolioKey =
    useGetCurrentInvestmentAllocation().investmentAllocation?.key;
  const resp = useQuery<WebAppSuperBalance>(
    gql`
      query WebAppSuperBalance($portfolio: SuperPortfolio!) {
        superPortfolioInformation(portfolio: $portfolio) {
          id
          name
        }
      }
    `,
    {
      skip: !currentSuperPortfolioKey,
      variables: {
        portfolio: currentSuperPortfolioKey?.toUpperCase(),
      },
    },
  );
  const info = resp.data?.superPortfolioInformation;

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 2 / 3 }}>
          <Stack spaceY="md">
            <Heading component="h1" variant={3}>
              Spaceship Super account balance
            </Heading>
            <SuperBalanceCard
              chartHeight={340}
              portfolioFriendlyName={info?.name}
            />

            <Stack spaceY="sm">
              <Heading component="h2" variant={4} isBold={true}>
                What is the account balance?
              </Heading>
              <Text variant={2} color="neutral.080">
                The graph shows your account balance over time. Each data point
                represents the account balance calculated using the latest
                available unit price on the respective day.
                <br />
                <br />
                The account balance displayed here is an estimate. It&apos;s
                based on the number of units you hold in{' '}
                {resp?.data?.superPortfolioInformation?.name}, multiplied by the
                latest available unit price. Any recent investments (e.g.
                contributions, rollovers) will not be reflected in your balance
                until they have been invested.
                <br />
                <br />
                If your investment is redeemed — for example, if you make an
                investment switch, roll out, benefit payment or other withdrawal
                — your redemption balance will reflect the most recent unit
                price as of the day of redemption, less any applicable fees and
                costs, and may be different.
              </Text>
            </Stack>

            {isReturnsEnabled && (
              <Stack spaceY="sm">
                <Heading component="h2" variant={4} isBold={true}>
                  Your return
                </Heading>
                <Text variant={2} color="neutral.080">
                  The dollar amount displayed here is the return you&apos;ve
                  earned on your investment option since joining Spaceship
                  Super.
                </Text>
              </Stack>
            )}
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
