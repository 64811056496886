import { MemberStatus } from '@sargon/api-client';

import { useGetCurrentInvestmentAllocation, useGetMember } from '../react';

// TODO: Remove this declaration once MemberStatus enum is updated and contains `Child` value
// This is a patch on `@sargon/api-client` typings to fix MemberStatus enum to include `Child`. The Sargon API returns `Child` value when a parent open a super account on behalf of their child.
// @see: https://spaceship-hq.atlassian.net/browse/ITSM-5389

// Ref: https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
declare module '@sargon/api-client' {
  enum MemberStatus {
    // Enums which is not included in the original enum
    Child = 'Child',
  }
}

export interface UseUserAccessResult {
  canVoluntaryContribute: boolean;
  canSuperMatch: boolean;
  canShareEmployerContributionDetails: boolean;
  isLoading: boolean;
}

export const useUserAccess = (): UseUserAccessResult => {
  const investmentAllocationResp = useGetCurrentInvestmentAllocation();
  const memberResp = useGetMember();
  const memberStatus = memberResp.data?.memberStatus;
  const hasPortfolio = !!investmentAllocationResp.investmentAllocation?.id;
  const hasMemberImpactId = !!memberResp.data?.impactId;
  const isActiveOrPendingMemberStatus =
    !!memberStatus &&
    [MemberStatus.Active, MemberStatus.Pending].includes(memberStatus);

  return {
    canVoluntaryContribute:
      hasMemberImpactId &&
      (memberStatus === MemberStatus.Active ||
        memberStatus === MemberStatus.Child),
    canSuperMatch:
      hasMemberImpactId && isActiveOrPendingMemberStatus && hasPortfolio,
    canShareEmployerContributionDetails:
      hasMemberImpactId && isActiveOrPendingMemberStatus && hasPortfolio,
    isLoading:
      (hasPortfolio && investmentAllocationResp.isLoading) ||
      memberResp.isLoading,
  };
};
