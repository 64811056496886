import * as React from 'react';
export const StreamlineSingleNeutral = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 11.23A5.115 5.115 0 1012 1a5.115 5.115 0 000 10.23zM2.5 22.923a9.5 9.5 0 0119 0" />
  </svg>
);
