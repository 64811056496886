import { gql, useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import { WebAppVoyagerAddPortfolioInvestment } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerAddPortfolioInvestment';
import { sydneyDate, toSydneyStartOfDay } from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import { OneOffDepositOnCompleteData } from 'components/voyager-one-off-deposit-form';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { cleanNumber, toISODate } from 'helpers/format';
import { Routes } from 'pages/routes';
import React, { createContext, useCallback, useContext } from 'react';

import { useOnboardingRequestContext } from './onboarding';
import { usePortfolioRegistration } from './portfolio-registration';
import { InvestmentPlan, OneOffDeposit } from './types';

interface InvestmentSetup {
  variant: 'onboarding' | 'add';
  activeBankAccountNumber?: string;
  oneOffDeposit?: OneOffDeposit;
  investmentPlan?: InvestmentPlan;
  onOneOffDepositComplete?: (data: OneOffDepositOnCompleteData) => void;
  onOneOffDepositSkip?: () => void;
  onInvestmentPlanComplete?: (value: InvestmentPlan) => void;
  onInvestmentPlanSkip?: () => void;
}

const InvestmentSetupContext = createContext<InvestmentSetup | undefined>(
  undefined,
);

export const OnboardingInvestmentProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [request, setRequest] = useOnboardingRequestContext();
  const track = useTrack();

  const onOneOffDepositSkip = useCallback(() => {
    track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_ONE_OFF_DEPOSIT_SKIP);

    setRequest({
      ...request,
      createOneOffDeposit: undefined,
    });

    navigate(Routes.VOYAGER_ONBOARDING_INVESTMENT_PLAN);
  }, [request, setRequest, track]);

  const onOneOffDepositComplete = useCallback(
    ({ audAmount }: OneOffDepositOnCompleteData): void => {
      track?.(
        MarketingTrackingEvents.VOYAGER_ONBOARDING_ONE_OFF_DEPOSIT_SUBMIT,
      );

      setRequest({
        ...request,
        ...(!!audAmount && {
          createOneOffDeposit: {
            ...request.createOneOffDeposit,
            amount: audAmount,
            frequency: SchedulerFrequency.Enum.ONE_TIME,
            start_date: toISODate(toSydneyStartOfDay(sydneyDate())),
          },
        }),
      });

      navigate(Routes.VOYAGER_ONBOARDING_INVESTMENT_PLAN);
    },
    [request, setRequest, track],
  );

  const onInvestmentPlanComplete = useCallback(
    (investmentPlan?: InvestmentPlan) => {
      track?.(
        MarketingTrackingEvents.VOYAGER_ONBOARDING_INVESTMENT_PLAN_SUBMIT,
      );

      setRequest({
        ...request,
        ...(!!investmentPlan?.amount &&
          !!investmentPlan.start_date && {
            createPaymentSchedule: {
              ...request.createPaymentSchedule,
              ...investmentPlan,
              amount: cleanNumber(investmentPlan.amount ?? ''),
              start_date: investmentPlan.start_date,
            },
          }),
      });

      navigate(Routes.VOYAGER_ONBOARDING_SUMMARY);
    },
    [request, setRequest, track],
  );

  const onInvestmentPlanSkip = useCallback(() => {
    track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_INVESTMENT_PLAN_SKIP);

    setRequest({
      ...request,
      createPaymentSchedule: undefined,
    });
    navigate(Routes.VOYAGER_ONBOARDING_SUMMARY);
  }, [request, setRequest, track]);

  return (
    <InvestmentSetupContext.Provider
      value={{
        variant: 'onboarding',
        oneOffDeposit: request?.createOneOffDeposit?.amount || undefined,
        investmentPlan: {
          amount: request?.createPaymentSchedule?.amount || undefined,
          frequency: request?.createPaymentSchedule?.frequency || undefined,
          start_date: request?.createPaymentSchedule?.start_date || undefined,
        },
        onOneOffDepositComplete,
        onOneOffDepositSkip,
        onInvestmentPlanComplete,
        onInvestmentPlanSkip,
        activeBankAccountNumber:
          request?.createSaverAccount?.source_account_number || '',
      }}
    >
      {children}
    </InvestmentSetupContext.Provider>
  );
};

export const AddNewPortfolioInvestmentProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const { oneOffDeposit, setOneOffDeposit, investmentPlan, setInvestmentPlan } =
    usePortfolioRegistration();

  const { data: bankAccountData } =
    useQuery<WebAppVoyagerAddPortfolioInvestment>(gql`
      query WebAppVoyagerAddPortfolioInvestment {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
          }
        }
      }
    `);

  const activeBankAccountNumber =
    bankAccountData?.contact?.account?.activeBankAccount?.accountNumber || '';

  const onOneOffDepositSkip = (): void => {
    setOneOffDeposit?.('');
    navigate(Routes.PORTFOLIO_ADD_INVEST_INVESTMENT_PLAN);
  };

  const onOneOffDepositComplete = ({
    audAmount,
  }: OneOffDepositOnCompleteData): void => {
    setOneOffDeposit?.(audAmount);
    navigate(Routes.PORTFOLIO_ADD_INVEST_INVESTMENT_PLAN);
  };

  const onInvestmentPlanComplete = (investmentPlan?: InvestmentPlan): void => {
    if (investmentPlan) {
      setInvestmentPlan?.(investmentPlan);
    }

    navigate(Routes.PORTFOLIO_ADD_SUMMARY);
  };

  const onInvestmentPlanSkip = (): void => {
    setInvestmentPlan?.(undefined);
    navigate(Routes.PORTFOLIO_ADD_SUMMARY);
  };

  return (
    <InvestmentSetupContext.Provider
      value={{
        variant: 'add',
        oneOffDeposit,
        onOneOffDepositComplete,
        onOneOffDepositSkip,
        investmentPlan,
        onInvestmentPlanComplete,
        onInvestmentPlanSkip,
        activeBankAccountNumber,
      }}
    >
      {children}
    </InvestmentSetupContext.Provider>
  );
};

export const useInvestmentSetup = (): InvestmentSetup => {
  const context = useContext(InvestmentSetupContext);

  if (!context) {
    throw new Error(
      'webapp: useInvestmentSetup must be wrapped with <OnboardingInvestmentProvider /> or <AddNewPortfolioInvestmentProvider />',
    );
  }

  return context;
};
