import {
  Box,
  DistributivePick,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { TimeScale } from '@spaceship-fspl/helpers';
import {
  Color,
  color,
  fontWeight,
  getColor,
  text,
} from '@spaceship-fspl/styles';
import { createVariant } from '@spaceship-fspl/substance-style';
import { useTrack } from '@spaceship-fspl/tracking';
import { TrackingEvent } from 'helpers/analytics';
import React from 'react';
import styled, { css } from 'styled-components';

export interface ChartTimeScaleTabOptionType {
  label: string;
  value: TimeScale;
}

export const chartTimeScaleTabOptions: ChartTimeScaleTabOptionType[] = [
  { label: '1M', value: TimeScale.ONE_MONTH },
  { label: '3M', value: TimeScale.THREE_MONTHS },
  { label: '6M', value: TimeScale.SIX_MONTHS },
  { label: '1Y', value: TimeScale.TWELVE_MONTHS },
  { label: 'MAX', value: TimeScale.MAX },
];

type ColorPropType = DistributivePick<
  Color,
  'indigo.050' | 'indigo.060' | 'mint.100'
>;

interface VariantProps {
  isSelected: boolean;
}

const selectorVariant = createVariant({
  'indigo.050': css`
    ${(props: VariantProps) =>
      color(props.isSelected ? 'indigo.050' : 'neutral.080')};
    background-color: ${(props) =>
      props.isSelected ? getColor('indigo.015') : 'transparent'};
  `,
  'indigo.060': css`
    ${(props: VariantProps) =>
      color(props.isSelected ? 'neutral.000' : 'neutral.070')};
    background-color: ${(props: VariantProps) =>
      props.isSelected ? getColor('indigo.060') : 'transparent'};
  `,
  'mint.100': css`
    ${(props: VariantProps) =>
      color(props.isSelected ? 'neutral.000' : 'neutral.070')};
    background-color: ${(props: VariantProps) =>
      props.isSelected ? getColor('mint.100') : 'transparent'};
  `,
});

interface Option<T> {
  label?: string;
  value: T;
}

interface Props<T> {
  selected: T;
  options: Option<T>[];
  setSelected: (value: T) => void;
  trackChartName?: string;
  color?: ColorPropType;
}

export function ChartTabSelector<T>({
  options,
  selected,
  setSelected,
  color = 'indigo.050',
  trackChartName,
}: Props<T>): React.ReactElement {
  const track = useTrack();
  return (
    <Box display="flex" justifyContent="space-between">
      {options.map((opt) => (
        <UnstyledButton
          key={String(opt.value)}
          onClick={() => {
            if (trackChartName) {
              track?.(TrackingEvent.CLICK, {
                properties: {
                  name: `${trackChartName}_option_${(
                    opt.label || String(opt.value)
                  ).toLowerCase()}`,
                },
              });
            }
            setSelected(opt.value);
          }}
        >
          <StyledTab isSelected={selected === opt.value} $color={color}>
            {opt.label ?? (opt.value as unknown as string)}
          </StyledTab>
        </UnstyledButton>
      ))}
    </Box>
  );
}

const StyledTab = styled(Box).attrs({
  paddingX: 'xs',
  paddingY: 'xxxs',
  borderRadius: 'lg',
})<VariantProps & { $color: ColorPropType }>`
  ${text({ variant: 4 })}
  ${({ isSelected }) => fontWeight(isSelected ? 600 : 400)}
  ${({ $color }) => selectorVariant($color)};
  line-height: 1em;
`;
