import {
  SharedDynamicConfigKeys,
  useNumber,
} from '@spaceship-fspl/dynamic-config';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

import { DATE_FORMAT_REQUESTS, formatDate } from './date';

// 0 indexed. Use with Date().getDay()
export enum DateDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const calculateEstimatedProcessedDate = (
  submittedAt: Date,
  cutoffHour: number,
): Date => {
  const hour = submittedAt.getHours() + 1; // getHours is 0 indexed
  const day = submittedAt.getDay();
  const isAfterCutoff = hour >= cutoffHour;

  switch (day) {
    // Weekends are equivalent to Monday before cutoff hour.
    case DateDays.Saturday:
      return addDays(submittedAt, 5);
    case DateDays.Sunday:
      return addDays(submittedAt, 4);

    // Monday - Before cutoff hour: Thursday, After cutoff hour Friday.
    case DateDays.Monday:
      return addDays(submittedAt, isAfterCutoff ? 4 : 3);

    // Tuesday - Before cutoff hour: Friday, After cutoff hour Monday.
    case DateDays.Tuesday:
      return addDays(submittedAt, isAfterCutoff ? 6 : 3);

    // Wednesday - Before cutoff hour: Monday, After cutoff hour Tuesday.
    case DateDays.Wednesday:
      return addDays(submittedAt, isAfterCutoff ? 6 : 5);

    // Thursday - Before cutoff hour: Tuesday, After cutoff hour Wednesday.
    case DateDays.Thursday:
      return addDays(submittedAt, isAfterCutoff ? 6 : 5);

    // Friday - Before cutoff hour: Wednesday, After cutoff hour Thursday.
    case DateDays.Friday:
      return addDays(submittedAt, isAfterCutoff ? 6 : 5);

    default:
      return addDays(submittedAt, 7);
  }
};

export const useEstimatedProcessedDate = (submittedAt: Date): string => {
  const cutoffHour = useNumber(
    SharedDynamicConfigKeys.DAILY_ORDER_CUTOFF_HOUR,
    -1,
  );
  return useMemo(
    () =>
      formatDate(
        calculateEstimatedProcessedDate(submittedAt, cutoffHour),
        DATE_FORMAT_REQUESTS,
      ),
    [submittedAt, cutoffHour],
  );
};
