import * as React from 'react';
export const StreamlineFlagPlain3 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1.5 22.5v-21M1.5 17.328l2.944-.906a8.969 8.969 0 017.34.933 8.975 8.975 0 007.167.992l2.787-.797a1.05 1.05 0 00.762-1.01V5.595a1.052 1.052 0 00-1.338-1.01l-2.211.632a8.974 8.974 0 01-7.168-.986 8.97 8.97 0 00-7.34-.933L1.5 4.202" />
  </svg>
);
