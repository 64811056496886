import { gql } from '@apollo/client';
import {
  Box,
  Columns,
  InfoPanel,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useGetEstimatedAnonymousApplicationExecutionDate } from '@spaceship-fspl/data';
import {
  SaverTransactionLimits_SaverProductInstanceFragment,
  useEstimatedApplicationExecutionDate,
  useSaverPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { VoyagerOneOffDepositFormFragment as VoyagerOneOffDepositFormFragmentType } from '@spaceship-fspl/graphql/src/__generated__/VoyagerOneOffDepositFormFragment';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  ExternalRoutes,
  formatDate,
  maskBankAccountNumber,
  sydneyDate,
  useDebounceValue,
} from '@spaceship-fspl/helpers';
import { FeatherClockIcon, FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  InvestmentType,
  useInvestmentValidation,
} from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { Error } from 'components/layouts/error';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormSkipButton,
  PageHeading,
} from 'components/layouts/page';
import { InvestInfoAccordion } from 'components/transactions/invest-info-accordion';
import { useIntercom } from 'contexts/intercom';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { cleanNumber, formatCurrency } from 'helpers/format';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { FeatureFlagKeys, useFeatureFlag } from '../helpers/dynamic-config';
import { ControllerInput } from './controller-input';
import { Dropdown } from './dropdown';
import { RadioButton } from './radio-button';

const PRESET_AMOUNTS = ['25', '50', '100'];

export interface OneOffDepositOnCompleteData {
  productId: string;
  audAmount: string;
  etaDate?: string | null;
}

export interface VoyagerOneOffDepositFormProps {
  variant: 'onboarding' | 'loggedin';
  onComplete?: (data: OneOffDepositOnCompleteData) => void;
  isSubmitting?: boolean;
  activeBankAccountNumber?: string;
  onSkip?: () => void;
  defaultProductId?: string;
  account?: VoyagerOneOffDepositFormFragmentType | null;
}

export const VoyagerOneOffDepositFormFragment = gql`
  fragment VoyagerOneOffDepositFormFragment on Account {
    id
    activeBankAccount {
      id
      accountNumber
    }
    ... on Account @include(if: $isMoneyDayOneEnabled) {
      moneyAvailableAudBalance
    }
    saverProductInstancesConnection {
      edges {
        node {
          id
          portfolio
          investments {
            id
            summary {
              id
              audBalance
            }
          }
          portfolioInformation {
            id
            title
          }
          ...SaverTransactionLimits_SaverProductInstanceFragment
        }
      }
    }
  }
  ${SaverTransactionLimits_SaverProductInstanceFragment}
`;

export const VoyagerOneOffDepositForm: React.FC<
  React.PropsWithChildren<VoyagerOneOffDepositFormProps>
> = ({
  account,
  activeBankAccountNumber,
  variant,
  defaultProductId,
  onComplete,
  onSkip,
  isSubmitting,
}) => {
  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  const moneyAvailableAudBalance = account?.moneyAvailableAudBalance;
  const productInstances =
    account?.saverProductInstancesConnection?.edges.flatMap(({ node }) =>
      node ? [node] : [],
    );

  const dropdownOptions =
    productInstances?.map((product) => ({
      value: product.id,
      label: product?.portfolioInformation?.title ?? '',
    })) ?? [];

  const selectedProduct =
    productInstances?.find((product) => product.id === defaultProductId) ??
    productInstances?.[0];

  const validate = useInvestmentValidation(InvestmentType.ONE_OFF);
  const flagsData = useSaverPortfolioFlags();

  const { control, register, setValue, watch, handleSubmit } = useForm<{
    amount: string;
    presetAmount: string;
    productId: string;
  }>({
    defaultValues: {
      amount: '',
      presetAmount: '',
      productId: defaultProductId ?? productInstances?.[0]?.id ?? '',
    },
  });

  const productId = watch('productId');
  const amount = watch('amount');
  const presetAmount = watch('presetAmount');

  useEffect(() => {
    if (amount) {
      setValue('presetAmount', '', { shouldTouch: true, shouldDirty: true });
    }
    if (presetAmount) {
      setValue('amount', '', { shouldValidate: true, shouldTouch: true });
    }
  }, [amount, presetAmount, setValue]);

  const chosenAmount = cleanNumber(amount || presetAmount || '');
  const [debouncedAmountValue] = useDebounceValue(chosenAmount, 300);

  const intercom = useIntercom();

  const directDebitBuyAvailable =
    selectedProduct?.transactionLimits?.directDebitBuyAvailable ?? true;
  const directDebitBuyNotAvailableMessage =
    selectedProduct?.transactionLimits?.directDebitBuyNotAvailableMessage;

  const sydneyDateRef = useRef(sydneyDate()).current;
  const estimatedExecutionDateResp = useEstimatedApplicationExecutionDate({
    variables: {
      productId: productId || '',
      audAmount: debouncedAmountValue || '',
      applicationTime: sydneyDateRef.toISOString(),
    },
    skip: variant === 'onboarding' || !productId || !debouncedAmountValue,
  });

  const anonEstimatedExecutionDateResp =
    useGetEstimatedAnonymousApplicationExecutionDate(debouncedAmountValue);

  const estimatedExecutionDate =
    variant === 'onboarding'
      ? anonEstimatedExecutionDateResp.data
          ?.estimated_application_execution_date
      : estimatedExecutionDateResp.data?.contact.account?.saverProductInstance
          ?.estimatedApplicationExecutionDate;

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createPaymentScheduleAvailable =
    saverPortfolioFlags?.createPaymentScheduleAvailable ?? true;

  if (!createPaymentScheduleAvailable) {
    return (
      <Error
        title={
          variant === 'onboarding'
            ? 'How much would you like to invest today?'
            : 'Make an investment'
        }
        subtitle={
          saverPortfolioFlags?.createPaymentScheduleNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'payment_schedule_not_available_go_back',
          },
        }}
      />
    );
  }

  const showDropdown =
    variant === 'loggedin' &&
    productInstances &&
    productInstances.length > 1 &&
    !isMoneyDayOneEnabled;

  const depositAmountExceedsAvailableMoney =
    Number(cleanNumber(amount)) > Number(moneyAvailableAudBalance) ||
    Number(presetAmount) > Number(moneyAvailableAudBalance);

  return (
    <Stack spaceY="xxl">
      <form
        onSubmit={handleSubmit((data): void => {
          onComplete?.({
            productId: data.productId,
            audAmount: cleanNumber(data.amount || data.presetAmount),
            etaDate: estimatedExecutionDate,
          });
        })}
      >
        <Columns alignX="center">
          <Columns.Column
            width={{
              xs: 1,
              lg: variant === 'onboarding' ? 4 / 12 : 6 / 12,
              xl: 4 / 12,
            }}
          >
            <Stack spaceY="md">
              <Stack spaceY="lg">
                <PageHeading
                  title={
                    variant === 'onboarding'
                      ? 'How much would you like to invest today?'
                      : isMoneyDayOneEnabled
                        ? `How much would you like to invest in ${selectedProduct?.portfolioInformation?.title}?`
                        : 'Make an investment'
                  }
                />

                {showDropdown ? (
                  <Dropdown
                    placeholder="Select portfolio"
                    options={dropdownOptions}
                    {...register('productId', {
                      required: 'Portfolio is required',
                    })}
                  />
                ) : null}
              </Stack>

              {!directDebitBuyAvailable ? (
                <Stack spaceY="md" alignX="center">
                  <Box
                    backgroundColor="neutral.000"
                    borderRadius="xs"
                    padding="sm"
                    display="flex"
                  >
                    <FeatherInfoIcon color="indigo.070" />
                    <Box flex={1} marginLeft="xs">
                      <Text variant={3}>
                        {directDebitBuyNotAvailableMessage}
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    variant="secondary"
                    size="lg"
                    trackingProperties={{
                      name: 'one_off_deposit_contact_support',
                    }}
                    onClick={() => intercom.pop()}
                  >
                    Contact support
                  </Button>
                </Stack>
              ) : (
                <>
                  <Stack spaceY="lg">
                    <Stack spaceY="xs">
                      <Text variant={4} color="neutral.080" isBold={true}>
                        Amount
                      </Text>
                      <Columns spaceX="sm">
                        {PRESET_AMOUNTS.map((option) => {
                          return (
                            <Columns.Column
                              data-testid={
                                'voyager_deposit_preset_amount_button'
                              }
                              width="max"
                              key={option}
                            >
                              <RadioButton
                                value={option}
                                {...register('presetAmount')}
                              >
                                <Text variant={2} isBold={true} align="center">
                                  ${option}
                                </Text>
                              </RadioButton>
                            </Columns.Column>
                          );
                        })}
                      </Columns>

                      <ControllerInput
                        name="amount"
                        control={control}
                        width="100%"
                        placeholder="Other amount"
                        type="text"
                        format="currency"
                        rules={{
                          validate: (value: string) =>
                            !presetAmount ? validate(value) : true,
                        }}
                      />
                    </Stack>
                  </Stack>

                  {!isMoneyDayOneEnabled && estimatedExecutionDate && (
                    <Box
                      backgroundColor="neutral.030"
                      borderRadius="xs"
                      padding="md"
                    >
                      <Stack spaceY="xxs" alignX="center">
                        <Box color="indigo.070">
                          <FeatherClockIcon size="md" />
                        </Box>
                        <Text variant={2} align="center">
                          The ETA of your next units is{' '}
                          <Text variant={2} isBold={true} component="span">
                            {formatDate(
                              estimatedExecutionDate,
                              DATE_FORMAT_TRANSACTIONS_LONG,
                            )}
                          </Text>
                        </Text>
                      </Stack>
                    </Box>
                  )}

                  <Stack spaceY="sm">
                    {!isMoneyDayOneEnabled || variant === 'onboarding' ? (
                      <>
                        {activeBankAccountNumber && (
                          <Text variant={3} color="neutral.080">
                            Your investment will be transferred from your bank
                            account ending in{' '}
                            {maskBankAccountNumber(activeBankAccountNumber)}.
                          </Text>
                        )}

                        <Text variant={3} color="neutral.080">
                          By clicking ‘
                          {variant === 'onboarding'
                            ? 'Continue'
                            : 'Add investment'}
                          ’, you acknowledge this investment will be processed
                          in accordance with our{' '}
                          <TextLink
                            color="indigo.070"
                            target="_blank"
                            rel="noreferrer"
                            href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                          >
                            Direct Debit Request
                          </TextLink>{' '}
                          and{' '}
                          <TextLink
                            color="indigo.070"
                            target="_blank"
                            rel="noreferrer"
                            href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                          >
                            Direct Debit Request Service Agreement
                          </TextLink>
                          .
                        </Text>
                      </>
                    ) : (
                      <>
                        <Stack spaceY="xs">
                          <Text variant={4} isBold color={'neutral.080'}>
                            Available money
                          </Text>
                          <Text variant={2} isBold>
                            {formatCurrency(moneyAvailableAudBalance)}
                          </Text>
                        </Stack>
                        {amount || presetAmount ? (
                          <Stack spaceY="xs">
                            {depositAmountExceedsAvailableMoney ? (
                              <Box marginTop="md">
                                <InfoPanel
                                  color={'indigo.040'}
                                  backgroundColor={'indigo.015'}
                                  iconColor={'neutral.100'}
                                  icon={FeatherInfoIcon}
                                  textVariant={3}
                                  textColor={'neutral.100'}
                                  leftBorderColor={'indigo.015'}
                                  boxShadow={'none'}
                                >
                                  Your order is for more than your available
                                  money. The total amount will be debited from
                                  your linked bank account.
                                </InfoPanel>
                              </Box>
                            ) : null}

                            <Text variant={4} isBold color={'neutral.080'}>
                              Source
                            </Text>
                            <Text variant={2} isBold>
                              Linked bank account
                            </Text>
                            <Text variant={3} color={'neutral.080'}>
                              We&apos;ll debit the money from your linked bank
                              account ending in{' '}
                              {maskBankAccountNumber(activeBankAccountNumber)}.
                            </Text>
                          </Stack>
                        ) : null}
                      </>
                    )}
                  </Stack>
                </>
              )}
            </Stack>

            {directDebitBuyAvailable && (
              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{ name: 'one_off_deposit_continue' }}
                  isLoading={isSubmitting}
                >
                  {variant === 'onboarding' ? 'Continue' : 'Add investment'}
                </PageFormContinueButton>

                {variant === 'onboarding' && (
                  <PageFormSkipButton
                    trackingProperties={{ name: 'one_off_deposit_skip' }}
                    onClick={onSkip}
                  />
                )}
              </PageFormButtonContainer>
            )}
          </Columns.Column>
        </Columns>
      </form>

      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 5 / 6 }}>
          <InvestInfoAccordion />
        </Columns.Column>
      </Columns>
    </Stack>
  );
};
