import * as React from 'react';
export const StreamlineSmileySmile2 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1.375 12a10.625 10.625 0 1021.25 0 10.625 10.625 0 00-21.25 0v0z" />
    <path vectorEffect="non-scaling-stroke" d="M8.458 8.813a.354.354 0 110 .708.354.354 0 010-.709M15.542 8.813a.354.354 0 110 .707.354.354 0 010-.707M17.712 14.833a6.376 6.376 0 01-11.428 0" />
  </svg>
);
