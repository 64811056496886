export enum SuperMatchErrorType {
  SUPERMATCH_DOWN,
  NO_ACCOUNTS_FOUND,
  TRANSFER_REQUEST_FAILED,
}

export type SuperMatchErrorCopy = {
  title: string;
  copy: string;
};

// TODO: SUP-1540 Copy to be reviewed by content
const errorCopyMap: {
  [key in SuperMatchErrorType]: SuperMatchErrorCopy;
} = {
  [SuperMatchErrorType.SUPERMATCH_DOWN]: {
    title: 'Oops, SuperMatch appears to be down right now',
    copy: 'Please try again later.',
  },
  [SuperMatchErrorType.NO_ACCOUNTS_FOUND]: {
    title: 'We didn’t find any super accounts',
    copy: 'We didn’t find any super accounts that match your details. If you know about any other super accounts we couldn’t find, you can contact our customer support team.',
  },
  [SuperMatchErrorType.TRANSFER_REQUEST_FAILED]: {
    title: 'Transfer request failed',
    copy: 'There seems to be a problem submitting your request. Please contact our customer support team.',
  },
};

export function getSuperMatchError(
  type?: SuperMatchErrorType,
): SuperMatchErrorCopy {
  return errorCopyMap[type ?? SuperMatchErrorType.SUPERMATCH_DOWN];
}
