import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Button,
  Card,
  Columns,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { fromProtoPortfolio } from '@spaceship-fspl/graphql';
import {
  StreamlineAccountingCoinsStackIcon,
  StreamlineContentPencilWriteIcon,
  StreamlineFaceId4Icon,
} from '@spaceship-fspl/icons-web';
import { PageContainer } from 'components/layouts/page';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import { AccessibilityLabel } from 'helpers/accessibility';
import { voyagerPortfolios } from 'helpers/portfolios';
import React from 'react';

export const VoyagerTMDSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { portfolio, portfolioIcon, onTMDEligible } = usePortfolioSelection();
  const gqlPortfolio = fromProtoPortfolio(portfolio);
  const portfolioInfo = gqlPortfolio
    ? voyagerPortfolios[gqlPortfolio]
    : undefined;
  const Icon = portfolioIcon?.stars;

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
            <Stack spaceY="lg">
              <Stack spaceY="sm">
                <Heading
                  component="h1"
                  variant={3}
                  align={{ xs: 'left', md: 'center' }}
                >
                  Welcome aboard!
                </Heading>

                {Icon && (
                  <Box display="flex" justifyContent="center">
                    <Icon color="indigo.070" size="xxxl" />
                  </Box>
                )}

                <Heading
                  component="h3"
                  variant={4}
                  align={{ xs: 'left', md: 'center' }}
                >
                  We just need a few more details so you can start investing in
                  the {portfolioInfo?.title}.
                </Heading>
              </Stack>

              <Stack spaceY="lg" alignX="center">
                <Card
                  paddingX={{ xs: 'md', md: 'lg' }}
                  paddingTop={{ xs: 'md', md: 'lg' }}
                  paddingBottom={{ xs: 'md', md: 'lg' }}
                  boxShadow="sm"
                  borderRadius="sm"
                >
                  <Stack spaceY={{ xs: 'sm', md: 'md' }}>
                    <Box flexDirection="row" display="flex">
                      <Box marginRight="xs">
                        <StreamlineFaceId4Icon size="md" strokeWidth={1.5} />
                      </Box>
                      <Text variant={2}>ID Documents</Text>
                    </Box>

                    <Box flexDirection="row" display="flex">
                      <Box marginRight="xs">
                        <StreamlineContentPencilWriteIcon
                          size="md"
                          strokeWidth={1.5}
                        />
                      </Box>
                      <Text variant={2}>Tax details</Text>
                    </Box>

                    <Box flexDirection="row" display="flex">
                      <Box marginRight="xs">
                        <StreamlineAccountingCoinsStackIcon
                          size="md"
                          strokeWidth={1.5}
                        />
                      </Box>
                      <Text variant={2}>Bank details</Text>
                    </Box>
                  </Stack>
                </Card>

                <Button
                  aria-label={AccessibilityLabel.CONTINUE}
                  type="submit"
                  variant="primary"
                  size="lg"
                  onClick={onTMDEligible}
                >
                  Continue
                </Button>
              </Stack>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};
