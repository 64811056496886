import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Card,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { SaverPortfolio } from '@spaceship-fspl/graphql';
import { WebAppCompanyDetails } from '@spaceship-fspl/graphql/src/__generated__/WebAppCompanyDetails';
import {
  DATE_FORMAT_SHORT_MONTH_NAME,
  formatCurrencyWithCodeAndSymbol,
  formatDate,
  humanReadable,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  borderRadius,
  getColor,
  getSpace,
  marginBottom,
  match,
  paddingBottom,
  paddingTop,
} from '@spaceship-fspl/styles';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import { formatPercentage } from 'helpers/format';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CompanyChart } from './company-chart';

interface ProfileSection {
  header?: string;
  paragraphs: string[];
}

export const CompanyDetails: React.FC<{
  instrumentId?: string;
  portfolio?: SaverPortfolio | null;
}> = ({ instrumentId, portfolio }) => {
  const resp = useQuery<WebAppCompanyDetails>(
    gql`
      query WebAppCompanyDetails(
        $instrumentId: ID!
        $portfolio: SaverPortfolio!
      ) {
        instrument(id: $instrumentId) {
          id
          name
          audLatestPrice
          audMarketCapitalisation
          ytdPercentageChange
          ytdPercentageHigh
          ytdPercentageLow
          targetAllocationPercentage(portfolio: $portfolio)
          profile(portfolio: $portfolio) {
            id
            bodyText
            youtubeUrl
            youtubeVideoCaption
          }
          marketPrices {
            audAmount
            date
          }
        }
      }
    `,
    {
      skip: !portfolio || !instrumentId,
      variables: {
        instrumentId,
        portfolio,
      },
    },
  );

  const instrument = resp.data?.instrument;
  const profile = instrument?.profile;
  const profileCopy = useMemo(
    () => parseProfile(profile?.bodyText ?? '', portfolio),
    [portfolio, profile],
  );
  const latestInstrumentMarketPrice =
    instrument?.marketPrices?.[instrument.marketPrices?.length - 1];

  const youtubeId = useMemo(() => {
    if (profile?.youtubeUrl) {
      try {
        const { searchParams } = new URL(profile.youtubeUrl);
        return searchParams.get('v');
      } catch {
        return undefined;
      }
    }
    return undefined;
  }, [profile]);

  return (
    <Stack spaceY="md">
      <CompanyChartWrapper>
        <Container>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, md: 10 / 12 }}>
              <Stack spaceY="md">
                <Heading variant={2} component="h1" color="neutral.000">
                  {instrument?.name ?? '—'}
                </Heading>
                <CompanyChart
                  instrument={instrument}
                  isLoading={resp.loading}
                />
              </Stack>
            </Columns.Column>
          </Columns>
        </Container>
      </CompanyChartWrapper>

      <Container>
        <Columns alignX="center" spaceY={{ xs: 'md', lg: 'none' }}>
          <Columns.Column width={{ xs: 1, md: 10 / 12, lg: 4 / 12 }}>
            <Stack spaceY="sm">
              <Stack spaceY="sm">
                <Heading variant={4} component="h3">
                  Stats
                </Heading>

                <Stack spaceY="md">
                  <Columns>
                    <Columns.Column width={1 / 3}>
                      <LabeledField size="md" label="Share price">
                        {instrument?.audLatestPrice
                          ? formatCurrencyWithCodeAndSymbol(
                              parseFloat(instrument.audLatestPrice),
                            )
                          : '—'}
                      </LabeledField>
                    </Columns.Column>
                    {instrument?.audMarketCapitalisation &&
                    instrument?.audMarketCapitalisation.length > 0 ? (
                      <Columns.Column width={1 / 3}>
                        <LabeledField size="md" label="Asset size">
                          {instrument?.audMarketCapitalisation
                            ? `A${humanReadable(
                                parseInt(instrument.audMarketCapitalisation),
                                2,
                              )}`
                            : '—'}
                        </LabeledField>
                      </Columns.Column>
                    ) : null}
                    <Columns.Column width={1 / 3}>
                      <LabeledField size="md" label="Target allocation">
                        {instrument?.targetAllocationPercentage
                          ? `${parseFloat(
                              instrument.targetAllocationPercentage,
                            ).toFixed(2)}%`
                          : '—'}
                      </LabeledField>
                    </Columns.Column>
                  </Columns>

                  <Columns>
                    <Columns.Column width={1 / 3}>
                      <LabeledField size="md" label="YTD">
                        {instrument?.ytdPercentageChange
                          ? formatPercentage(instrument.ytdPercentageChange, 2)
                          : '—'}
                      </LabeledField>
                    </Columns.Column>
                    <Columns.Column width={1 / 3}>
                      <LabeledField size="md" label="YTD low">
                        {instrument?.ytdPercentageLow
                          ? formatPercentage(instrument.ytdPercentageLow, 2)
                          : '—'}
                      </LabeledField>
                    </Columns.Column>
                    <Columns.Column width={1 / 3}>
                      <LabeledField size="md" label="YTD high">
                        {instrument?.ytdPercentageHigh
                          ? formatPercentage(instrument.ytdPercentageHigh, 2)
                          : '—'}
                      </LabeledField>
                    </Columns.Column>
                  </Columns>
                </Stack>
              </Stack>

              <Stack spaceY="md">
                <Stack spaceY="xs">
                  <Text variant={4} color="neutral.080">
                    Past performance is not a guide to, or reliable indicator
                    of, future performance.
                  </Text>

                  {!!latestInstrumentMarketPrice && (
                    <Text variant={4} color="neutral.080">
                      Price is in AUD and performance as at{' '}
                      {formatDate(
                        latestInstrumentMarketPrice.date,
                        DATE_FORMAT_SHORT_MONTH_NAME,
                      )}
                    </Text>
                  )}
                </Stack>

                {!!youtubeId && (
                  <Visible
                    isHidden={{ xs: true, lg: false }}
                    displayValue="block"
                  >
                    <MediaSection
                      id={youtubeId}
                      caption={profile?.youtubeVideoCaption ?? ''}
                    />
                  </Visible>
                )}
                {portfolio === SaverPortfolio.EARTH && (
                  <Card
                    backgroundColor="neutral.030"
                    padding="md"
                    borderRadius="sm"
                    boxShadow="none"
                  >
                    <Stack spaceY="sm">
                      <Box display="flex" justifyContent="space-between">
                        <Heading variant={5} component="h4">
                          How the Spaceship Earth Portfolio invests
                        </Heading>

                        <RouterLink
                          to={Routes.VOYAGER_PORTFOLIO_ABOUT_EARTH}
                          trackingProperties={{
                            name: 'voyager_portfolio_page_about_earth',
                          }}
                        >
                          <FeatherInfoIcon color="neutral.070" />
                        </RouterLink>
                      </Box>

                      <Text variant={2}>
                        The Spaceship Earth Portfolio invests in companies that
                        have a positive impact on people and the planet in areas
                        such as poverty, inequality, climate change, the
                        environment, and quality education by contributing
                        towards the advancement of the UN Sustainable
                        Development Goals agenda.
                      </Text>
                    </Stack>
                  </Card>
                )}
              </Stack>
            </Stack>
          </Columns.Column>

          <Columns.Column width={{ xs: 0, lg: 1 / 12 }}>
            {/*Don't worry about it*/ null}
          </Columns.Column>

          <Columns.Column width={{ xs: 1, md: 10 / 12, lg: 5 / 12 }}>
            <Box marginBottom={{ xs: 'xxl', lg: 'xxxl' }}>
              <Stack spaceY="sm">
                <Heading variant={4} component="h3">
                  Profile
                </Heading>
                {profileCopy.length > 0 ? (
                  profileCopy.map(({ header, paragraphs }, index) => (
                    <Stack spaceY="sm" key={`profile-copy-wrapper-${index}`}>
                      {header && <Heading variant={5}>{header}</Heading>}
                      {paragraphs.map((line, index) => (
                        <Text
                          variant={2}
                          color="neutral.085"
                          key={`profile-text-copy${index}`}
                        >
                          {line}
                        </Text>
                      ))}
                    </Stack>
                  ))
                ) : (
                  <Heading variant={4} component="p">
                    —
                  </Heading>
                )}

                {!!youtubeId && (
                  <Visible
                    isHidden={{ xs: false, lg: true }}
                    displayValue="block"
                  >
                    <MediaSection
                      id={youtubeId}
                      caption={profile?.youtubeVideoCaption ?? ''}
                    />
                  </Visible>
                )}
              </Stack>
            </Box>
          </Columns.Column>
        </Columns>
      </Container>
    </Stack>
  );
};

const MediaSection: React.FC<
  React.PropsWithChildren<{ id: string; caption: string }>
> = ({ id, caption }) => {
  return (
    <Stack spaceY="sm">
      <Heading variant={4} isBold={true} color="black.100" component="h3">
        Media
      </Heading>
      <VideoWrapper>
        <StyledIFrame
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title={caption}
        />
      </VideoWrapper>
    </Stack>
  );
};

const HEADER_NAV_HEIGHT = 80;

const parseProfile = (
  rawText: string,
  portfolio?: SaverPortfolio | null,
): ProfileSection[] => {
  const texts = rawText.split('\n').filter((value) => !!value);
  const sections = texts
    .filter((text) => text.startsWith('#'))
    .map((heading) => texts.indexOf(heading))
    .map((start, startIndex, indexes) => [start, indexes[startIndex + 1]])
    .map(([start, end]) => {
      const [header, ...paragraphs] = texts.slice(start, end);
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        header: header!.slice(1).trim(),
        paragraphs,
      };
    });

  return portfolio === SaverPortfolio.INDEX ? sections.slice(0, 1) : sections;
};

const CompanyChartWrapper = styled.div`
  padding-top: calc(${getSpace('sm')} + ${HEADER_NAV_HEIGHT}px);
  ${paddingBottom('md')};
  background: linear-gradient(
    354.96deg,
    ${getColor('indigo.100')} 2.35%,
    ${getColor('indigo.090')} 164.11%
  );

  ${match('lg')`
      ${paddingTop('lg')};
    `};
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  ${borderRadius('md')};
  ${marginBottom('xs')};
`;

const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
