import {
  backgroundColor,
  borderColor,
  borderWidth,
  color,
  linearGradient,
  LinearGradientConfig,
  paddingX,
  rgba,
  ThemeName,
  VariantInterpolationWithTheme,
} from '@spaceship-fspl/styles';
import { createVariant } from '@spaceship-fspl/substance-style';
import { css } from 'styled-components';

export type ButtonSize = 'lg' | 'sm';
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

interface ThemeVariants {
  size: (variant: ButtonSize) => VariantInterpolationWithTheme;
  color: (variant: ButtonVariant) => VariantInterpolationWithTheme;
}

// MARKETING
// Figma Reference:
// https://www.figma.com/file/vXLkjFFAdOCa9oODr2i4mr/_S8-COMPONENT-LIBRARY?node-id=1898%3A56244

const primaryGradient: LinearGradientConfig = {
  angle: '238.64deg',
  colorStops: [
    ['blue.050', '-90.67%'],
    ['indigo.050', '122.19%'],
  ],
};

const primaryGradientStyle = css`
  ${color('neutral.000')}
  background: ${linearGradient(primaryGradient)};
`;

const marketing: ThemeVariants = {
  size: createVariant({
    sm: css`
      ${paddingX('md')}
      min-width: 96px;
      height: 36px;
      font-size: 16px;
      border-radius: 18px;
    `,
    lg: css`
      ${paddingX('lg')}
      min-width: 220px;
      height: 64px;
      font-size: 20px;
      border-radius: 32px;
    `,
  }),
  color: createVariant({
    primary: css`
      ${primaryGradientStyle}
      :not(:disabled):hover, :not(:disabled):active {
        background: ${linearGradient({
          angle: '238.64deg',
          colorStops: [
            ['blue.070', '-90.67%'],
            ['indigo.070', '122.19%'],
          ],
        })};
      }
      :disabled {
        color: ${rgba('neutral.000', 0.4)};
        background: ${linearGradient({
          ...primaryGradient,
          alpha: 0.4,
        })};
      }
    `,
    secondary: css`
      ${color('neutral.000')}
      background: none;
      ${backgroundColor('neutral.100')}
      :not(:disabled):hover, :not(:disabled):active {
        ${primaryGradientStyle}
      }
      :disabled {
        color: ${rgba('neutral.000', 0.4)};
        background-color: ${rgba('neutral.100', 0.4)};
      }
    `,
    tertiary: css`
      ${color('neutral.100')}
      ${backgroundColor('neutral.030')}
      :not(:disabled):hover, :not(:disabled):active {
        ${backgroundColor('neutral.050')}
      }
      :disabled {
        ${color('neutral.050')}
        ${backgroundColor('neutral.015')}
      }
    `,
  }),
};

// WEBAPP
// Figma Reference:
// https://www.figma.com/file/vXLkjFFAdOCa9oODr2i4mr/_S8-COMPONENT-LIBRARY?node-id=152%3A1373

const webappVariant = (isWhiteBackground: boolean): ThemeVariants => ({
  size: createVariant({
    sm: css`
      ${paddingX('md')}
      min-width: 96px;
      height: 32px;
      font-size: 12px;
      border-radius: 16px;
    `,
    lg: css`
      ${paddingX('lg')}
      min-width: 220px;
      height: 56px;
      font-size: 16px;
      border-radius: 28px;
    `,
  }),
  color: createVariant({
    primary: css`
      ${color('neutral.000')}
      ${backgroundColor('indigo.070')}

      :not(:disabled):hover, :not(:disabled):active {
        ${backgroundColor('indigo.080')}
      }

      :disabled {
        background: ${rgba('indigo.070', 0.4)};
      }
    `,
    secondary: css`
      ${color('indigo.070')}
      ${backgroundColor(isWhiteBackground ? 'neutral.030' : 'neutral.050')}

      :not(:disabled):hover, :not(:disabled):active {
        ${color('neutral.000')}
        ${backgroundColor('indigo.070')}
      }

      :disabled {
        color: ${rgba('indigo.070', 0.4)};
        ${backgroundColor(isWhiteBackground ? 'neutral.030' : 'neutral.050')}
      }
    `,
    tertiary: css`
      ${color('indigo.070')}
      ${borderColor('indigo.070')}
      ${borderWidth('md')}
      border-style: solid;

      :not(:disabled):hover,
      :not(:disabled):active {
        ${color('neutral.000')}
        ${backgroundColor('indigo.070')}
      }

      :disabled {
        color: ${rgba('indigo.070', 0.4)};
        border-color: ${rgba('indigo.070', 0.4)};
      }
    `,
  }),
});

export const themes: Record<ThemeName, ThemeVariants> = {
  marketing,
  'webapp-grey': webappVariant(false),
  'webapp-white': webappVariant(true),
};
