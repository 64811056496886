import * as React from 'react';
export const CircleCheck = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 22 22" fill="none" {...props}>
    <path fill="#00AAA0" d="M11 0a11 11 0 100 22 11 11 0 100-22z" />
    <path
      d="M6.059 11.373l3.673 3.81 5.511-7.62"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
