import * as React from 'react';
export const StreamlineToysLego = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M8.333 22.333h-6.6A.733.733 0 011 21.6v-5.133M8.333 16.467H1V10.6M12.733 10.6H1V5.467a.733.733 0 01.733-.734H12a.733.733 0 01.733.734V10.6zM1.733 4.733v-2.2a.733.733 0 01.734-.733h2.2a.733.733 0 01.733.733v2.2M8.333 4.733v-2.2a.733.733 0 01.734-.733h2.2a.733.733 0 01.733.733v2.2M12 16.467v-2.2a.734.734 0 01.733-.734h2.2a.734.734 0 01.734.734v2.2M18.6 16.467v-2.2a.734.734 0 01.733-.734h2.2a.734.734 0 01.734.734v2.2M11.267 16.467H23v5.866H11.267v-5.866z" />
  </svg>
);
