import { Card } from '@spaceship-fspl/components';
import { useAppStoreLinks } from '@spaceship-fspl/graphql';
import { VoyagerMobileAppPromotion } from 'components/mobile-app-promotion';
import React from 'react';

export const QRCodeCard: React.FC<React.PropsWithChildren> = () => {
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <Card borderRadius="sm" padding="md" backgroundColor="indigo.100">
      <VoyagerMobileAppPromotion
        theme="light"
        size="md"
        variant="login"
        appleAppStoreUrl={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
        googlePlayStoreUrl={
          appStoreLinksResp.data?.googlePlayStoreDeeplink?.url
        }
      />
    </Card>
  );
};
