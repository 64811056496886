import { FundSearchFund } from '@sargon/api-client';
import { Box, SegmentControls, Stack, Text } from '@spaceship-fspl/components';
import { formatCurrency } from '@spaceship-fspl/helpers';
import {
  FeatherCheckIcon,
  FeatherInfoIcon,
  FeatherXIcon,
} from '@spaceship-fspl/icons-web';
import { FUND_DETAILS, ListRolloverRequests } from '@spaceship-fspl/super';
import { ControllerInput } from 'components/controller-input';
import { Decimal } from 'decimal.js';
import { cleanNumber } from 'helpers/format';
import React from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';

import {
  CONSENTED_COPY,
  DEFINED_BENEFIT_COPY,
  FundCard,
  SPACESHIP_ACCOUNT_COPY,
  ZERO_BALANCE_COPY,
} from './card';

const StatusIcon: React.FC<
  React.PropsWithChildren<{ hasFeature: boolean }>
> = ({ hasFeature }) =>
  hasFeature ? (
    <FeatherCheckIcon color="mint.050" size="sm" />
  ) : (
    <FeatherXIcon color="red.100" size="sm" />
  );

const FundDetailsList: React.FC<
  React.PropsWithChildren<{
    label: string;
    hasFeature?: boolean;
    value?: string;
  }>
> = (props) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Text variant={3} color="neutral.080">
        {props.label}
      </Text>
      {props.value ? (
        <Text variant={3}>{props.value}</Text>
      ) : (
        <StatusIcon hasFeature={props.hasFeature ?? false} />
      )}
    </Box>
  );
};

const ClaimedFundDetails: React.FC<
  React.PropsWithChildren<{
    hasInsurance: boolean;
    hasDefinedBenefits: boolean;
    isGovernmentContributionsAccepted: boolean;
    isInwardRolleroverAccepted: boolean;
    accountNumber?: string;
    activityStatus?: string;
    abn?: string;
    usi?: string;
  }>
> = (props) => {
  return (
    <Box paddingY="sm" paddingX="md" backgroundColor="neutral.015">
      <Stack spaceY="xxs">
        <FundDetailsList label="Insurance" hasFeature={props.hasInsurance} />

        <FundDetailsList
          label="Defined benefits"
          hasFeature={props.hasDefinedBenefits}
        />

        <FundDetailsList
          label="Accepts government contributions"
          hasFeature={props.isGovernmentContributionsAccepted}
        />

        <FundDetailsList
          label="Accepts inward rollovers"
          hasFeature={props.isInwardRolleroverAccepted}
        />

        <FundDetailsList label="Account number" value={props.accountNumber} />

        <FundDetailsList label="Activity status" value={props.activityStatus} />

        <FundDetailsList label="ABN" value={props.abn} />

        <FundDetailsList label="USI" value={props.usi} />
      </Stack>
    </Box>
  );
};

const FooterCopy: React.FC<
  React.PropsWithChildren<{
    hasDefinedBenefits: boolean;
    hasConsented: boolean;
    isZeroBalance: boolean;
    isSpaceshipSuper: boolean;
  }>
> = (props) => {
  let infoCopy: string | undefined;
  switch (true) {
    case props.hasDefinedBenefits:
      infoCopy = DEFINED_BENEFIT_COPY;
      break;

    case props.hasConsented:
      infoCopy = CONSENTED_COPY;
      break;

    case props.isZeroBalance && !props.isSpaceshipSuper:
      infoCopy = ZERO_BALANCE_COPY;
      break;

    case props.isSpaceshipSuper:
      infoCopy = SPACESHIP_ACCOUNT_COPY;
      break;

    default:
      break;
  }

  if (infoCopy) {
    return (
      <Box display="flex">
        <Box marginRight="xxs">
          <FeatherInfoIcon color="indigo.070" />
        </Box>
        <Text variant={4}>{infoCopy}</Text>
      </Box>
    );
  }

  return null;
};

const hasConsentedFund = (
  checkFundABN: string | undefined,
  checkAccountNumber: string | undefined,
  rolloverRequests?: ListRolloverRequests,
): boolean =>
  !!rolloverRequests?.funds?.some(
    ({ fundABN, accountNumber }) =>
      fundABN === checkFundABN && accountNumber === checkAccountNumber,
  );

export enum ClaimedFundTransferType {
  NONE = 'none',
  PARTIAL = 'partial',
  FULL = 'full',
}

const TRANSFER_OPTIONS = [
  {
    label: 'None',
    value: ClaimedFundTransferType.NONE,
  },
  {
    label: 'Partial',
    value: ClaimedFundTransferType.PARTIAL,
  },
  {
    label: 'Full',
    value: ClaimedFundTransferType.FULL,
  },
];

export const ClaimedFundCard: React.FC<
  React.PropsWithChildren<
    FundSearchFund & { rolloverRequests?: ListRolloverRequests }
  >
> = (props) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const name = `claimedFund.${props.fundABN}-${props.accountNumber}`;

  const segmentFieldName = `${name}.transferType`;
  const transferAmountFieldName = `${name}.amount`;

  const canTransfer =
    props.canRollover &&
    props.rolloverStatus !== FundSearchFund.RolloverStatusEnum.OwnFund;

  const hasConsented = hasConsentedFund(
    props.fundABN,
    props.accountNumber,
    props.rolloverRequests,
  );

  const transferAmountFieldErrorMessage = get(
    errors,
    `claimedFund.${props.fundABN}-${props.accountNumber}.amount`,
  )?.message;

  const transferAmount = watch(transferAmountFieldName);

  const showSummaryTransferAmount =
    transferAmount &&
    Number(cleanNumber(transferAmount)) > 0 &&
    !transferAmountFieldErrorMessage;

  const isFullTransfer =
    watch(segmentFieldName) === ClaimedFundTransferType.FULL;

  const isSpaceshipSuper =
    props.uniqueSuperIdentifier === FUND_DETAILS.uniqueSuperIdentifier;

  const balanceAmount = new Decimal(
    props.accountBalance?.amount || 0,
  ).dividedBy(100);

  const isPartialTransferAllowed = new Decimal(
    props.accountBalance?.amount || 0,
  ).greaterThan(0);

  return (
    <FundCard
      fundName={isSpaceshipSuper ? 'Spaceship' : (props.friendlyFundName ?? '')}
      balance={formatCurrency(balanceAmount.toNumber())}
      transferAmount={
        isFullTransfer
          ? formatCurrency(balanceAmount.toNumber())
          : showSummaryTransferAmount
            ? transferAmount
            : ''
      }
    >
      <ClaimedFundDetails
        hasInsurance={!!props.insurance}
        hasDefinedBenefits={!!props.definedBenefit}
        isGovernmentContributionsAccepted={
          !!props.fundAcceptGovernmentContributions
        }
        isInwardRolleroverAccepted={!!props.inwardRollover}
        accountNumber={props.accountNumber}
        activityStatus={props.activityStatus}
        abn={props.fundABN}
        usi={props.uniqueSuperIdentifier}
      />

      <Box paddingX="sm" paddingTop="sm">
        <FooterCopy
          hasDefinedBenefits={!!props.definedBenefit}
          hasConsented={hasConsented}
          isZeroBalance={balanceAmount.isZero()}
          isSpaceshipSuper={isSpaceshipSuper}
        />

        {canTransfer && !props.definedBenefit && !hasConsented && (
          <Stack spaceY="sm">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant={4} isBold={true}>
                TRANSFER TO SPACESHIP
              </Text>

              <Controller
                name={segmentFieldName}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SegmentControls
                    controls={
                      isPartialTransferAllowed
                        ? TRANSFER_OPTIONS
                        : TRANSFER_OPTIONS.filter(
                            (t) => t.value === ClaimedFundTransferType.PARTIAL,
                          )
                    }
                    value={value}
                    onChange={(value) => {
                      if (value === ClaimedFundTransferType.FULL) {
                        setValue(transferAmountFieldName, 0);
                      }
                      onChange(value);
                    }}
                  />
                )}
              />
            </Box>

            {watch(segmentFieldName) === ClaimedFundTransferType.PARTIAL && (
              <ControllerInput
                name={transferAmountFieldName}
                type="text"
                format="currency"
                placeholder="Amount"
                control={control}
                rules={{
                  validate: (value) => {
                    const enteredAmount = new Decimal(
                      Number(cleanNumber(value)) || 0,
                    );

                    if (
                      enteredAmount.greaterThan(balanceAmount) ||
                      enteredAmount.lessThanOrEqualTo(0)
                    ) {
                      return `Amount must be between 0 and ${formatCurrency(
                        balanceAmount.toNumber(),
                      )}`;
                    }
                    return true;
                  },
                }}
              />
            )}
          </Stack>
        )}
      </Box>
    </FundCard>
  );
};
