import { gql } from '@apollo/client';
import { Box, Divider, Stack, Text } from '@spaceship-fspl/components';
import { useSetPrimarySaverProductInstance } from '@spaceship-fspl/graphql';
import { webAppVoyagerPrimaryPortfolioCardContact_account_saverProductInstances } from '@spaceship-fspl/graphql/src/__generated__/webAppVoyagerPrimaryPortfolioCardContact';
import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from 'components/account-cards/components';
import { Dropdown } from 'components/dropdown';
import { useNotifications } from 'contexts/notifications';
import { UPDATING_PRIMARY_PORTFOLIO } from 'helpers/errors';
import { formatCurrency } from 'helpers/format';
import { voyagerPortfolios } from 'helpers/portfolios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export const voyagerPrimaryPortfolioCardContactFragment = gql`
  fragment webAppVoyagerPrimaryPortfolioCardContact on Contact {
    id
    account {
      id
      saverProductInstances {
        id
        primary
        portfolio
        investments {
          id
          summary {
            id
            audBalance
          }
        }
        portfolioInformation {
          id
          accountFee
        }
      }
    }
  }
`;

export const VoyagerPrimaryPortfolioCard: React.FC<
  React.PropsWithChildren<{
    productInstances?:
      | webAppVoyagerPrimaryPortfolioCardContact_account_saverProductInstances[]
      | null;
  }>
> = ({ productInstances }) => {
  const { popToast } = useNotifications();
  const { register, setValue, reset, watch, getValues, handleSubmit } =
    useForm<{
      primaryPortfolioId: string;
    }>({
      shouldFocusError: true,
      mode: 'onBlur',
      defaultValues: {
        primaryPortfolioId: '',
      },
    });
  const [setPrimarySaverProductInstance, setPrimarySaverProductInstanceMeta] =
    useSetPrimarySaverProductInstance();

  const [editing, setEditing] = useState(false);
  const hasMultipleVoyagerPortfolios = productInstances?.length
    ? productInstances.length > 1
    : false;

  useEffect(() => {
    const primaryPortfolio = productInstances?.find((p) => p.primary);
    if (primaryPortfolio && !getValues('primaryPortfolioId')) {
      setValue('primaryPortfolioId', primaryPortfolio.id);
    }
  }, [getValues, productInstances, setValue]);

  if (!hasMultipleVoyagerPortfolios) {
    return null;
  }
  const primaryPortfolio = productInstances?.find((p) => p.primary);
  const PrimaryPortfolioIcon = primaryPortfolio
    ? voyagerPortfolios[primaryPortfolio.portfolio].icon.default
    : null;
  const primaryPortfolioConfig = primaryPortfolio
    ? voyagerPortfolios[primaryPortfolio.portfolio]
    : null;

  const options =
    productInstances?.map((p) => ({
      label: `${voyagerPortfolios[p.portfolio].title} (${formatCurrency(
        p?.investments?.summary?.audBalance,
      )})`,
      value: p.id,
    })) ?? [];

  const submit = handleSubmit(async ({ primaryPortfolioId }) => {
    try {
      await setPrimarySaverProductInstance({
        variables: { input: { id: primaryPortfolioId } },
      });
      setEditing(false);
    } catch {
      popToast({
        message: UPDATING_PRIMARY_PORTFOLIO,
        level: 'error',
      });
    }
  });
  const currentSelectedPortfolioId = watch('primaryPortfolioId');

  const feeAmount =
    primaryPortfolio?.portfolioInformation?.accountFee ??
    primaryPortfolioConfig?.newFeesFallback.accountFee;

  return (
    <AccountCard>
      <AccountCardHeading
        title="Primary Portfolio"
        edit={{
          onClick: () => {
            setEditing(true);
          },
          isEditing: editing,
          onCancel: () => {
            setEditing(false);
            reset({ primaryPortfolioId: primaryPortfolio?.id ?? '' });
          },
          onSubmit: submit,
          isLoading: setPrimarySaverProductInstanceMeta.loading,
          submitText: 'Update',
          isSubmitDisabled: currentSelectedPortfolioId === primaryPortfolio?.id,
        }}
      />
      <AccountCardContent>
        <Stack spaceY="sm">
          <Text variant={2}>
            If you have multiple portfolios, you can select a primary portfolio.
          </Text>
          <Text variant={2}>
            {`If you’re eligible for the monthly fee of ${feeAmount}, it will be deducted from your primary portfolio.`}
          </Text>

          <Text variant={2}>
            However, if your primary portfolio has insufficient funds when we
            charge your monthly fee, your portfolio with the next highest
            balance will be charged.
          </Text>
        </Stack>
        <Box marginY="md">
          <Divider color="neutral.050" />
        </Box>
        {primaryPortfolioConfig && !editing && (
          <React.Fragment>
            <Box display="flex">
              <Box alignItems="center">
                {PrimaryPortfolioIcon && (
                  <PrimaryPortfolioIcon color="indigo.070" size="xxl" />
                )}
              </Box>

              <Box display="flex" flex={1} paddingLeft="xs" paddingTop="xxs">
                <Stack>
                  <Text variant={3} color="neutral.080" isBold={true}>
                    Primary portfolio
                  </Text>
                  <Box flex={1}>
                    <Text variant={2} isBold={true}>
                      {primaryPortfolioConfig.title}
                    </Text>
                  </Box>
                  <Text variant={3}>
                    {formatCurrency(
                      primaryPortfolio?.investments?.summary.audBalance,
                    )}
                  </Text>
                </Stack>
              </Box>
            </Box>
          </React.Fragment>
        )}
        <Box display={editing ? 'block' : 'none'}>
          <Dropdown
            options={options}
            placeholder="Primary Portfolio"
            {...register('primaryPortfolioId')}
          />
        </Box>
      </AccountCardContent>
    </AccountCard>
  );
};
