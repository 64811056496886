import { RouteComponentProps } from '@reach/router';
import { Error } from 'components/layouts/error';
import { useIntercom } from 'contexts/intercom';
import React from 'react';

export const SuperLinkError: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const intercom = useIntercom();

  return (
    <Error
      title={<>Oops, we couldn&apos;t link your account</>}
      subtitle={
        <>
          We can&apos;t find that account right now. Please contact our support
          team and we&apos;ll get you back on track.
        </>
      }
      buttonText="Contact support"
      onContinue={{
        onClick: () => intercom.pop(),
        trackingProperties: { name: 'super_link_error_continue' },
      }}
      isOnboarding={true}
    />
  );
};
