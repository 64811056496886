import { ErrorItem } from '@sargon/api-client';

export enum ApiErrorCodes {
  ACCOUNT_WITH_EMAIL_EXISTS = '"email" is already in use',
  UPDATE_MEMBER_MISSING_ACCOUNT_ID = 'Could not update member details - missing member account id',
}

export enum AmplifyAuthLoginErrorCodes {
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
}

export enum AmplifyAuthResetPasswordErrorCodes {
  INVALID_PASSWORD = 'InvalidPasswordException',
  OTP_CODE_MISMATCH = 'CodeMismatchException',
}

export class AmplifyAuthApiError extends Error {
  public code: string;

  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }

  public get isPasswordResetRequired(): boolean {
    return this.code === AmplifyAuthLoginErrorCodes.PASSWORD_RESET_REQUIRED;
  }

  public get isEmailOrPasswordInvalid(): boolean {
    return (
      this.code === AmplifyAuthLoginErrorCodes.NOT_AUTHORIZED ||
      this.code === AmplifyAuthLoginErrorCodes.USER_NOT_FOUND
    );
  }
}

export enum LinkAccountApiErrorCodes {
  AMPLIFY_LOGIN = 'aws cognito - unable to login',
  AMPLIFY_GET_TOKEN = 'aws cognito - unable to get auth token',
  MISSING_AMPLIFY_TOKEN = 'link super account - missing aws cognito auth token',
  MISMATCH_PERSONAL_DETAILS = 'mismatch_personal_details',
  LINK_ACCOUNT = 'link super account - unable to link super account',
  REFRESH_SPACESHIP_TOKEN = 'refresh spaceship token - unable to refresh token',
  GET_MEMBER = 'get member - unable to fetch member',
  GET_MEMBER_UNDEFINED = 'get member - data is undefined',
}

export class LinkAccountApiError extends Error {
  public code: AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes;

  constructor(
    code: AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes,
    message: string,
  ) {
    super(message);
    this.code = code;
  }
}

export const getApiErrors = async (resp: Response): Promise<ErrorItem[]> => {
  try {
    const errJson = await resp.clone().json();
    return errJson.errors || [];
  } catch {
    return [];
  }
};

export const isOtpError = async (resp: Response): Promise<boolean> => {
  const errors = await getApiErrors(resp);
  return (
    (resp.status === 401 && resp.headers.get('x-super-otp') === 'required') ||
    !!errors.find((e) => e.detail === 'OTP Session Required')
  );
};

export const getSargonApiError = async (error: unknown): Promise<ApiError> => {
  const castedResponse = error as Response;
  const errors = await getApiErrors(castedResponse);
  return new ApiError(castedResponse.status, errors);
};

export class ApiError extends Error {
  public httpStatusCode: number;
  public errors?: ErrorItem[];

  constructor(statusCode: number, errors?: ErrorItem[]) {
    const message = errors?.[0]?.detail;
    super(message);

    this.httpStatusCode = statusCode;
    this.errors = errors;
  }
}
