import * as React from 'react';
export const StreamlineAmericanFootballBall1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M22.548 4.23a2.826 2.826 0 00-2.928-2.928c-3.466.132-9.34.992-13.332 4.988-3.993 3.996-4.855 9.862-4.986 13.334a2.825 2.825 0 002.928 2.928c3.47-.13 9.344-.992 13.337-4.986 3.993-3.994 4.85-9.865 4.981-13.336zM15.467 8.385L8.383 15.47M10.863 10.864l2.125 2.125M8.383 13.344l2.125 2.125M13.342 8.385l2.125 2.125M1.938 14.69l7.224 7.223M21.912 9.163L14.689 1.94" />
  </svg>
);
