import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useIsAuthenticated, useRefresh } from '@spaceship-fspl/auth';
import {
  useCreatePaymentSchedule,
  useCreateVoyagerProduct,
} from '@spaceship-fspl/data';
import { useApplyVoyagerReferralCode } from '@spaceship-fspl/graphql';
import { WebAppVoyagerOnboardingSummary } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerOnboardingSummary';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { useTrack } from '@spaceship-fspl/tracking';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { IdentityDetailsCard } from 'components/account-cards/identity-details';
import {
  VoyagerTaxResidencyCard,
  voyagerTaxResidencyCardFragment,
} from 'components/account-cards/voyager-tax-residency';
import { OnboardingSummary } from 'components/layouts/onboarding-summary';
import { useVoyagerReferralCode } from 'contexts/deep-linking';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { useOnboardingRequestContext } from 'contexts/saver/onboarding';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';

import { BankAccountSection } from './bank-account';
import { ContactDetailsSection } from './contact-details';
import { InvestmentPlanSection, OneOffInvestmentSection } from './investments';
import { PortfolioSection } from './portfolio';
import { ReferralSection } from './referral';

export const VoyagerOnboardingSummary: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const [isDepositEditing, setIsDepositEditing] = useState(false);
  const [isInvestmentPlanEditing, setIsInvestmentPlanEditing] = useState(false);
  const [isContactDetailsEditing, setIsContactDetailsEditing] = useState(false);
  const isEditing =
    isDepositEditing || isInvestmentPlanEditing || isContactDetailsEditing;
  const [request] = useOnboardingRequestContext();
  const { popToast } = useNotifications();
  const { pop } = useIntercom();
  const { value: voyagerReferralCode, setValue: setVoyagerReferralCode } =
    useVoyagerReferralCode();

  const refreshToken = useRefresh();
  const { mutateAsync: createVoyagerProduct } = useCreateVoyagerProduct();
  const { mutateAsync: createPaymentSchedule } = useCreatePaymentSchedule();
  const [applyReferralCode] = useApplyVoyagerReferralCode();
  const isUserVerified = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const isAuthenticated = useIsAuthenticated();

  const resp = useQuery<WebAppVoyagerOnboardingSummary>(
    gql`
      query WebAppVoyagerOnboardingSummary {
        contact {
          id
          ...webAppVoyagerTaxResidencyCard
        }
      }
      ${voyagerTaxResidencyCardFragment}
    `,
    {
      skip: !isAuthenticated,
    },
  );

  useEffect(() => {
    if (!request.createSaverAccount) {
      navigate(Routes.VOYAGER_ONBOARDING_PORTFOLIO);
    }
  }, [navigate, request.createSaverAccount]);

  const submit = async (): Promise<void> => {
    if (!request.createSaverAccount) {
      return;
    }

    try {
      await createVoyagerProduct(request.createSaverAccount);
      await refreshToken();
      track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_ACCOUNT_CREATED);
    } catch {
      popToast({
        message:
          'Something went wrong while creating your Voyager account. Please try again or contact support.',
        level: 'error',
        cta: {
          message: 'Contact support',
          action: pop,
        },
      });
      return;
    }

    if (voyagerReferralCode) {
      // if we received a referral code via Branch IO try to submit it for the
      // user.
      try {
        await applyReferralCode({
          variables: {
            input: { code: voyagerReferralCode.toUpperCase() },
          },
        });
        setVoyagerReferralCode(undefined);
      } catch {
        // do nothing
      }
    }

    try {
      await Promise.all([
        request.createOneOffDeposit &&
          createPaymentSchedule(request.createOneOffDeposit),
        request.createPaymentSchedule &&
          createPaymentSchedule(request.createPaymentSchedule),
      ]);
      await navigate(Routes.ONBOARDING_MOBILE_APPS);
    } catch {
      await navigate(Routes.ONBOARDING_MOBILE_APPS);
      popToast({
        message:
          'Your account was successfully created, however there was a problem setting up some of your investments.',
        level: 'warning',
      });
    }
  };

  return (
    <OnboardingSummary
      variant="signup"
      isEditing={isEditing}
      onComplete={submit}
    >
      <PortfolioSection hideEdit={true} />
      {isUserVerified && (
        <OneOffInvestmentSection
          isEditing={isDepositEditing}
          setIsEditing={setIsDepositEditing}
        />
      )}
      <InvestmentPlanSection
        isEditing={isInvestmentPlanEditing}
        setIsEditing={setIsInvestmentPlanEditing}
      />
      <IdentityDetailsCard isPreferredNameVisible={false} />
      <VoyagerTaxResidencyCard
        isLoading={resp.loading}
        contact={resp.data?.contact}
      />
      <ContactDetailsSection
        isEditing={isContactDetailsEditing}
        setIsEditing={setIsContactDetailsEditing}
      />
      <BankAccountSection />
      <ReferralSection />
    </OnboardingSummary>
  );
};
