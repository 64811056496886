import {
  ActionLink,
  Box,
  Card,
  Divider,
  DynamicIcon,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import { BoostItemGroupStatus } from '@spaceship-fspl/graphql';
import { capitalizeWords, formatCurrency } from '@spaceship-fspl/helpers';
import {
  FeatherChevronRightIcon,
  FeatherInfoIcon,
} from '@spaceship-fspl/icons-web';
import { color, paddingY, transition } from '@spaceship-fspl/styles';
import { RouterLink } from 'components/router-link';
import { Tooltip } from 'components/tooltip';
import { DATE_FORMAT_TRANSACTIONS } from 'components/transactions';
import { TransactionStatusIndicator } from 'components/transactions/status-indicator';
import { format } from 'date-fns';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  BoostRecipe,
  BoostRecipeGroup,
  BoostTransaction,
  BoostYTDTransactionSummaries,
} from './types';

interface BoostsDashboardHomeProps {
  sortedRecipes: Array<BoostRecipe>;
  ytdTransactionSummaries: BoostYTDTransactionSummaries;
  sortedTransactions: Array<BoostTransaction>;
}

export const BoostsDashboardHome: React.FC<
  React.PropsWithChildren<BoostsDashboardHomeProps>
> = (props) => {
  const boostItemGroupAudLockMinimumThreshold = formatCurrency(
    useString(
      DynamicConfigKeys.BOOST_ITEM_GROUP_AUD_LOCK_MINIMUM_THRESHOLD,
      '10',
    ),
  );

  const upcomingBoostGroups: Array<BoostRecipeGroup> = [];
  props.sortedRecipes.forEach((r) => {
    if (r.groups) {
      upcomingBoostGroups.push(
        ...r.groups.filter(
          (g) =>
            [
              BoostItemGroupStatus.UNLOCKED,
              BoostItemGroupStatus.PENDING_TRANSACTION,
              BoostItemGroupStatus.LOCKED,
            ].includes(g.status) && Number(g.audAmount) > 0,
        ),
      );
    }
  });

  return (
    <Stack spaceY="md">
      <Card borderRadius="sm" boxShadow="sm">
        <Box
          paddingTop="md"
          paddingBottom="sm"
          paddingX={{ xs: 'md', md: 'lg' }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading variant={5} isBold={true}>
            Upcoming boosts
            {upcomingBoostGroups.length > 0 &&
              ` (${upcomingBoostGroups.length})`}
          </Heading>

          <Box paddingLeft="xxs">
            <Tooltip
              placement="left"
              maxWidth={250}
              content={
                <Text
                  variant={4}
                  color="neutral.000"
                  isBold={true}
                  align="center"
                >
                  Your upcoming boosts have been pre-authorised by you. We’ll
                  process this investment one day after your upcoming boosts
                  reach {boostItemGroupAudLockMinimumThreshold}. You can cancel
                  your upcoming boost at any time before we process the
                  investment.
                </Text>
              }
            >
              <span>
                <FeatherInfoIcon color="neutral.070" size="md" />
              </span>
            </Tooltip>
          </Box>
        </Box>

        <Divider color="neutral.050" />

        {upcomingBoostGroups.length > 0 ? (
          <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="xxs">
            {upcomingBoostGroups.map((group, idx) => (
              <Fragment key={`${group.recipe?.name}_${idx}`}>
                {idx !== 0 && <Divider color="neutral.030" />}

                <StyledUpcomingBoostRouterLink
                  to={`${Routes.BOOSTS_SCHEDULED_DETAILS}/${group.id}`}
                  trackingProperties={{
                    name: 'boosts_dashboard_home_page_upcoming_boost',
                  }}
                >
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {group.recipe?.iconName && (
                      <DynamicIcon
                        name={group.recipe?.iconName}
                        color="neutral.100"
                        size="lg"
                      />
                    )}

                    <Box flex={1} marginX="sm">
                      <Text variant={4} isBold={true} color="neutral.080">
                        {group.cancellableUntil
                          ? format(
                              new Date(group.cancellableUntil),
                              DATE_FORMAT_TRANSACTIONS,
                            )
                          : 'Pending'}
                      </Text>

                      <Text variant={3} isBold={true}>
                        {group.recipe?.name}
                      </Text>
                    </Box>

                    <Text variant={3} isBold={true} align="right">
                      {formatCurrency(group.audAmount)}
                    </Text>
                  </Box>

                  <Box marginLeft={{ xs: 'xxs', md: 'sm' }}>
                    <FeatherChevronRightIcon color="indigo.070" size="md" />
                  </Box>
                </StyledUpcomingBoostRouterLink>
              </Fragment>
            ))}
          </Box>
        ) : (
          <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="md">
            <Text variant={3}>Any upcoming boosts will appear here.</Text>
          </Box>
        )}
      </Card>

      {Object.keys(props.ytdTransactionSummaries).length > 0 && (
        <Card borderRadius="sm" boxShadow="sm">
          <Box
            paddingTop="md"
            paddingBottom="sm"
            paddingX={{ xs: 'md', md: 'lg' }}
            display="flex"
          >
            <Heading variant={5} isBold={true}>
              Boost breakdown (this year)
            </Heading>
          </Box>

          <Divider color="neutral.050" />

          <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="xxs">
            {Object.keys(props.ytdTransactionSummaries).map(
              (recipeName, idx) => {
                const summary = props.ytdTransactionSummaries[recipeName];

                return (
                  <Fragment key={recipeName}>
                    {idx !== 0 && <Divider color="neutral.030" />}
                    <Box
                      display="flex"
                      flex={1}
                      alignItems="center"
                      justifyContent="space-between"
                      paddingY="sm"
                    >
                      {summary?.iconName && (
                        <DynamicIcon
                          name={summary.iconName}
                          color="neutral.100"
                          size="lg"
                        />
                      )}

                      <Box flex={1} marginX="sm">
                        <Text variant={3} isBold={true}>
                          {recipeName}
                        </Text>

                        <Text variant={4}>
                          {summary?.count ?? 0} time
                          {summary?.count !== 1 && 's'}
                        </Text>
                      </Box>

                      <Text variant={3} isBold={true} align="right">
                        {formatCurrency(summary?.audTotal)}
                      </Text>
                    </Box>
                  </Fragment>
                );
              },
            )}
          </Box>
        </Card>
      )}

      <Card borderRadius="sm" boxShadow="sm">
        <Box
          paddingTop="md"
          paddingBottom="sm"
          paddingX={{ xs: 'md', md: 'lg' }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading variant={5} isBold={true}>
            Boosts history
          </Heading>

          {props.sortedTransactions.length > 0 && (
            <Box paddingLeft="xxs">
              <RouterLink
                to={Routes.VOYAGER_TRANSACTIONS}
                trackingProperties={{
                  name: 'boosts_dashboard_home_page_boost_transactions',
                }}
              >
                <ActionLink color="indigo.070" size="xxs" component="span">
                  See more
                </ActionLink>
              </RouterLink>
            </Box>
          )}
        </Box>

        <Divider color="neutral.050" />

        {props.sortedTransactions.length > 0 ? (
          <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="xxs">
            {props.sortedTransactions.map((transaction, idx) => (
              <Fragment key={`${transaction}_${idx}`}>
                {idx !== 0 && <Divider color="neutral.030" />}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingY="sm"
                >
                  <Box flex={1} marginRight="sm">
                    <Stack spaceY="xxxs">
                      <Text variant={4} isBold={true} color="neutral.080">
                        {format(
                          new Date(transaction.requestedAt),
                          DATE_FORMAT_TRANSACTIONS,
                        )}
                      </Text>

                      <Text variant={3}>
                        {transaction.itemGroup?.recipe?.name}
                      </Text>

                      <Inline spaceX="xxxs" alignY="center">
                        <TransactionStatusIndicator
                          status={transaction.status}
                        />
                        <Text variant={4} color="neutral.080">
                          {capitalizeWords(transaction.status)}
                        </Text>
                      </Inline>
                    </Stack>
                  </Box>

                  <Text variant={3} align="right">
                    {formatCurrency(transaction.audAmount)}
                  </Text>
                </Box>
              </Fragment>
            ))}
          </Box>
        ) : (
          <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="md">
            <Text variant={3}>
              Your Boosts transaction history will appear here after your first
              boost is invested.
            </Text>
          </Box>
        )}
      </Card>
    </Stack>
  );
};

const StyledUpcomingBoostRouterLink = styled(RouterLink)`
  ${paddingY('sm')}
  display: flex;
  align-items: center;

  * {
    ${transition}
  }

  :hover * {
    ${color('indigo.070')}
  }
`;
