import { gql, useQuery } from '@apollo/client';
import {
  ActionLink,
  Box,
  Heading,
  Spinner,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import {
  SetSuperTargetMarketDeterminationAnswersInput,
  SuperPortfolio,
  SuperTMDInvestmentGoal,
  SuperTMDInvestmentGoalSubQuestion,
  SuperTMDResult,
  SuperTMDWithdrawalTimeframe,
  useSetSuperTargetMarketDeterminationAnswers,
} from '@spaceship-fspl/graphql';
import { WebAppSuperPortfolioTMD } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperPortfolioTMD';
import { usePrevious } from '@spaceship-fspl/helpers';
import {
  FeatherChevronLeftIcon,
  FeatherChevronRightIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderWidthTop,
  color,
  margin,
  minHeight,
  padding,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { tmdDetailsForPortfolio } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { Modal } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import { TrackingEvent } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';

import { Button } from './button';
import { InlineError } from './inline-error';
export type SuperTmdIneligibleType = 'NotOk' | 'NotAvailable';

export interface SuperTmdFormModalRef {
  showModal: (params: {
    portfolio: SuperPortfolio;
    tmdResult?: SuperTMDResult | null;
  }) => void;
  closeModal: () => void;
}

interface SuperTmdFormModalProps {
  isLoading: boolean;
  onTMDEligible: (portfolio: SuperPortfolio) => Promise<void>;
  onTMDIneligible: (ineligibleType: SuperTmdIneligibleType) => void;
}

type FormStep =
  | 'intro'
  | 'withdrawalTimeframe'
  | 'investmentGoal'
  | 'investmentGoalSubQuestion'
  | 'confirmation';

export const SuperTmdFormModal = forwardRef<
  SuperTmdFormModalRef,
  SuperTmdFormModalProps
>((props, ref) => {
  const resp = useQuery<WebAppSuperPortfolioTMD>(gql`
    query WebAppSuperPortfolioTMD {
      contact {
        id
        age
        superPreservationAge
      }
    }
  `);
  const containerRef = useRef<HTMLDivElement>(null);
  const tmdResult = useRef<SuperTMDResult | null | undefined>();
  const [formStep, setFormStep] = useState<FormStep>('intro');
  const [isEditForm, setIsEditForm] = useState(false);
  const formSteps: Array<FormStep> = [
    'intro',
    'withdrawalTimeframe',
    'investmentGoal',
    'investmentGoalSubQuestion',
    'confirmation',
  ];
  const [showModal, setShowModal] = useState(false);
  const prevShowModal = usePrevious(showModal);

  const [setSuperTMDAnswers, setSuperTMDAnswersMeta] =
    useSetSuperTargetMarketDeterminationAnswers();
  const isLoading = props.isLoading || setSuperTMDAnswersMeta.loading;
  const { popToast } = useNotifications();
  const track = useTrack();
  const form = useForm<SetSuperTargetMarketDeterminationAnswersInput>({
    defaultValues: {
      questionsVersion: '1.4',
    },
  });

  const reset = (): void => {
    if (!isLoading) {
      setShowModal(false);
      setFormStep('intro');
      setIsEditForm(false);
      form.reset({
        questionsVersion: '1.4',
        portfolio: undefined,
        withdrawalTimeframe: undefined,
        investmentGoal: undefined,
        investmentGoalSubQuestion: undefined,
      });
    }
  };

  const handleSubmit = form.handleSubmit(async (formData) => {
    const { portfolio } = formData;

    try {
      const { data: submissionData } = await setSuperTMDAnswers({
        variables: {
          input: formData,
        },
      });
      const details = tmdDetailsForPortfolio(
        portfolio,
        submissionData?.setSuperTargetMarketDeterminationAnswers,
      );

      switch (details?.result) {
        case SuperTMDResult.OK:
          // All ok, continue onboarding/new product
          props.onTMDEligible(portfolio);
          break;

        case SuperTMDResult.NOT_OK:
          props.onTMDIneligible(
            !tmdResult.current || details.submissionAvailable
              ? 'NotOk'
              : 'NotAvailable',
          );
          break;

        default:
          // unhandled result enum from API
          popToast({
            level: 'error',
            message: GENERIC_ERROR_MESSAGE,
          });
          break;
      }
    } catch (error) {
      addRumError({ error });
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  });

  useImperativeHandle(ref, () => ({
    showModal: (params) => {
      setShowModal(true);
      form.setValue('portfolio', params.portfolio);
      tmdResult.current = params.tmdResult;
    },
    closeModal: reset,
  }));

  useEffect(() => {
    containerRef.current?.scrollTo?.(0, 0);
  }, [formStep]);

  useEffect(() => {
    switch (true) {
      case !!form.formState.errors.withdrawalTimeframe?.message:
        setFormStep('withdrawalTimeframe');
        break;

      case !!form.formState.errors.investmentGoal?.message:
        setFormStep('investmentGoal');
        break;

      case !!form.formState.errors.investmentGoalSubQuestion?.message:
        setFormStep('investmentGoalSubQuestion');
        break;
    }
  }, [form.formState.errors]);

  useEffect(() => {
    if (prevShowModal !== undefined && prevShowModal !== showModal) {
      track?.(TrackingEvent.DISPLAY, {
        properties: {
          name: 'super_tmd_form_modal',
          display: showModal,
        },
      });
    }
  }, [track, prevShowModal, showModal]);

  const superPreservationAge =
    Number(resp.data?.contact?.superPreservationAge) -
    Number(resp.data?.contact?.age);
  const hasSuperPreservationAge =
    !resp.error && !isNaN(superPreservationAge) && superPreservationAge > 0;

  const questions = {
    withdrawalTimeframe: {
      question:
        'Do you plan to access your super for retirement within 10 years?',
      bodyCopy:
        'Remember, access to super is generally restricted until preservation age is reached (which is between 55 and 60, depending on when you were born.)',
      bodyCopyDynamic: `Based on your age (${resp.data?.contact?.age} years old), you're unlikely to be able to access your super for approximately ${superPreservationAge} year${superPreservationAge > 1 ? 's' : ''}. (This is based on when you’ll reach preservation age, which is between 55 and 60, depending on when you were born.)`,
      options: [
        {
          value: SuperTMDWithdrawalTimeframe.NOT_WITHIN_10_YEARS,
          label: 'No, not within 10 years',
        },
        {
          value: SuperTMDWithdrawalTimeframe.WITHIN_10_YEARS,
          label: 'Yes, within 10 years',
        },
      ],
    },
    investmentGoal: {
      question:
        'Which best describes where you want your super to be invested?',
      options: [
        {
          value: SuperTMDInvestmentGoal.HIGHER_RISK,
          label: (
            <Stack spaceY="xxs">
              <span>Choice 1</span>
              <span>
                I want my super to be predominantly invested in growth assets
                such as Australian and international shares
              </span>
            </Stack>
          ),
        },
        {
          value: SuperTMDInvestmentGoal.MEDIUM_RISK,
          label: (
            <Stack spaceY="xxs">
              <span>Choice 2</span>
              <span>
                I&apos;d like my super to be invested in a mixture of growth
                (such as shares) and defensive assets (such as bonds)
              </span>
            </Stack>
          ),
        },
        {
          value: SuperTMDInvestmentGoal.LOWER_RISK,
          label: (
            <Stack spaceY="xxs">
              <span>Choice 3</span>
              <span>
                I&apos;d like my super to be predominantly invested in defensive
                assets (such as bonds)
              </span>
            </Stack>
          ),
        },
        {
          value: SuperTMDInvestmentGoal.NONE_APPLY,
          label: (
            <Stack spaceY="xxs">
              <span>Choice 4</span>
              <span>None of these apply to me</span>
            </Stack>
          ),
        },
      ],
    },
    investmentGoalSubQuestion: {
      question: (
        <>
          Our super investment options predominantly invest in growth assets
          such as Australian and international shares for long-term capital
          growth, suiting an investor with a high tolerance for volatility and
          investment risk. It&apos;s worth reviewing our PDS to understand our
          fund’s volatility and risk.
          <br />
          <br />
          Are you comfortable with this level of risk and volatility?
        </>
      ),
      options: [
        {
          value: SuperTMDInvestmentGoalSubQuestion.YES,
          label: 'Yes',
        },
        {
          value: SuperTMDInvestmentGoalSubQuestion.NO,
          label: 'No',
        },
      ],
    },
  };

  return (
    <Modal
      closeModal={reset}
      showModal={showModal}
      maxWidth={800}
      display="flex"
    >
      {resp.loading ? (
        <Spinner />
      ) : (
        <StyledContainer ref={containerRef}>
          {!formSteps.slice(0, 2).includes(formStep) && (
            <StyledBackButton
              aria-label="go back to previous question"
              onClick={() => {
                const currentIndex = formSteps.findIndex(
                  (value) => value === formStep,
                );
                const prevStep = formSteps[currentIndex - 1];

                setIsEditForm(false);

                switch (prevStep) {
                  case 'investmentGoalSubQuestion':
                    if (!form.getValues('investmentGoalSubQuestion')) {
                      form.resetField('investmentGoal');
                      setFormStep('investmentGoal');
                    } else {
                      form.resetField(prevStep);
                      setFormStep(prevStep);
                    }
                    break;

                  case 'investmentGoal':
                  case 'withdrawalTimeframe':
                    form.resetField(prevStep);
                    setFormStep(prevStep);
                    break;
                }
              }}
            >
              <FeatherChevronLeftIcon size="md" />
            </StyledBackButton>
          )}

          <FormProvider {...form}>
            <Box as="form" onSubmit={handleSubmit} height="100%">
              <StyledTMDCarouselBox
                height="100%"
                width={`${formSteps.length * 100}%`}
                currentIndex={formSteps.indexOf(formStep)}
              >
                {formSteps.map((step) => (
                  <Box
                    display="flex"
                    height="100%"
                    paddingX={{ xs: 'sm', md: 'lg' }}
                    paddingTop="xxl"
                    paddingBottom={{ xs: 'md', md: 'lg' }}
                    flex={1}
                    key={step}
                    data-testid={step}
                    style={
                      step !== formStep
                        ? {
                            visibility: 'hidden',
                          }
                        : {}
                    }
                  >
                    {step === 'intro' && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        flex={{ md: 1 }}
                      >
                        <Box display="flex" flex={1}>
                          <Stack spaceY="md">
                            <Heading
                              variant={4}
                              align="center"
                              component="h4"
                              isBold={true}
                            >
                              We have a few questions!
                            </Heading>

                            <Text
                              variant={2}
                              color="neutral.085"
                              align="center"
                            >
                              Before you submit your application, you need to
                              answer a few general questions about your
                              situation to check whether you&apos;re likely to
                              be in the target market for Spaceship Super.
                            </Text>

                            <Text
                              variant={2}
                              color="neutral.085"
                              align="center"
                            >
                              Please answer as honestly and accurately as you
                              can, as you won&apos;t be able to submit an
                              application if you&apos;re unlikely to be in the
                              target market.
                            </Text>
                          </Stack>
                        </Box>

                        <PageFormButtonContainer>
                          <Stack spaceY="md" alignX="center">
                            <Button
                              type="button"
                              variant="primary"
                              size="lg"
                              onClick={() => setFormStep('withdrawalTimeframe')}
                              trackingProperties={{
                                name: 'super_tmd_form_modal_intro_continue',
                              }}
                            >
                              Continue
                            </Button>

                            <Text
                              variant={4}
                              color="neutral.085"
                              align="center"
                            >
                              The purpose of these questions is to help assess
                              whether you are likely to be in the target market
                              for Spaceship Super. These questions should not be
                              taken to constitute financial advice. You should
                              consider your own financial objectives, situation
                              and needs before making any decision about whether
                              to become a member of Spaceship Super. Read the
                              Spaceship Super PDS and TMD before making a
                              decision about this product.
                            </Text>
                          </Stack>
                        </PageFormButtonContainer>
                      </Box>
                    )}

                    {step === 'withdrawalTimeframe' && (
                      <FormStepQuestion
                        name="withdrawalTimeframe"
                        question={questions.withdrawalTimeframe.question}
                        bodyCopy={
                          hasSuperPreservationAge
                            ? questions.withdrawalTimeframe.bodyCopyDynamic
                            : questions.withdrawalTimeframe.bodyCopy
                        }
                        options={questions.withdrawalTimeframe.options}
                        onSelectOption={() => {
                          if (isEditForm) {
                            setFormStep('confirmation');
                          } else {
                            setFormStep('investmentGoal');
                          }
                        }}
                      />
                    )}

                    {step === 'investmentGoal' && (
                      <FormStepQuestion
                        name="investmentGoal"
                        question={questions.investmentGoal.question}
                        options={questions.investmentGoal.options}
                        onSelectOption={(option) => {
                          switch (option) {
                            case SuperTMDInvestmentGoal.HIGHER_RISK:
                            case SuperTMDInvestmentGoal.MEDIUM_RISK:
                              if (
                                isEditForm &&
                                !!form.watch('investmentGoalSubQuestion')
                              ) {
                                setFormStep('confirmation');
                              } else {
                                setFormStep('investmentGoalSubQuestion');
                              }
                              break;

                            default:
                              form.setValue(
                                'investmentGoalSubQuestion',
                                undefined,
                              );
                              setFormStep('confirmation');
                              break;
                          }
                        }}
                      />
                    )}

                    {step === 'investmentGoalSubQuestion' && (
                      <FormStepQuestion
                        name="investmentGoalSubQuestion"
                        isRequired={[
                          SuperTMDInvestmentGoal.HIGHER_RISK,
                          SuperTMDInvestmentGoal.MEDIUM_RISK,
                        ].includes(form.watch('investmentGoal'))}
                        question={questions.investmentGoalSubQuestion.question}
                        options={questions.investmentGoalSubQuestion.options}
                        onSelectOption={() => {
                          setFormStep('confirmation');
                        }}
                      />
                    )}

                    {step === 'confirmation' && (
                      <Box display="flex" flexDirection="column">
                        <Box flex={1}>
                          <Stack spaceY="lg">
                            <Heading
                              variant={4}
                              align="center"
                              component="h4"
                              isBold={true}
                            >
                              Is the information you’ve provided true and
                              correct?
                            </Heading>

                            <Stack spaceY="md">
                              <Stack
                                spaceY="xs"
                                data-testid="withdrawalTimeframe-confirmation"
                              >
                                <Heading
                                  variant={5}
                                  align="left"
                                  component="h5"
                                >
                                  {questions.withdrawalTimeframe.question}
                                </Heading>

                                <Box display="flex">
                                  <Text variant={2} component="div">
                                    {
                                      questions.withdrawalTimeframe.options.find(
                                        ({ value }) =>
                                          value ===
                                          form.watch('withdrawalTimeframe'),
                                      )?.label
                                    }
                                  </Text>

                                  <Box marginLeft="sm">
                                    <Text variant={2} component="div">
                                      <ActionLink
                                        color="indigo.070"
                                        size="sm"
                                        onClick={() => {
                                          setIsEditForm(true);
                                          setFormStep('withdrawalTimeframe');
                                        }}
                                      >
                                        Edit
                                      </ActionLink>
                                    </Text>
                                  </Box>
                                </Box>
                              </Stack>

                              <Stack
                                spaceY="xs"
                                data-testid="investmentGoal-confirmation"
                              >
                                <Heading
                                  variant={5}
                                  align="left"
                                  component="h5"
                                >
                                  {questions.investmentGoal.question}
                                </Heading>

                                <Box display="flex">
                                  <Text variant={2} component="div">
                                    {
                                      questions.investmentGoal.options.find(
                                        ({ value }) =>
                                          value ===
                                          form.watch('investmentGoal'),
                                      )?.label
                                    }
                                  </Text>

                                  <Box marginLeft="sm">
                                    <Text variant={2} component="div">
                                      <ActionLink
                                        color="indigo.070"
                                        size="sm"
                                        onClick={() => {
                                          setIsEditForm(true);
                                          setFormStep('investmentGoal');
                                        }}
                                      >
                                        Edit
                                      </ActionLink>
                                    </Text>
                                  </Box>
                                </Box>
                              </Stack>

                              {!!form.watch('investmentGoalSubQuestion') && (
                                <Stack
                                  spaceY="xs"
                                  data-testid="investmentGoalSubQuestion-confirmation"
                                >
                                  <Heading
                                    variant={5}
                                    align="left"
                                    component="h5"
                                  >
                                    {
                                      questions.investmentGoalSubQuestion
                                        .question
                                    }
                                  </Heading>

                                  <Box display="flex">
                                    <Text variant={2} component="div">
                                      {
                                        questions.investmentGoalSubQuestion.options.find(
                                          ({ value }) =>
                                            value ===
                                            form.watch(
                                              'investmentGoalSubQuestion',
                                            ),
                                        )?.label
                                      }
                                    </Text>

                                    <Box marginLeft="sm">
                                      <Text variant={2} component="div">
                                        <ActionLink
                                          color="indigo.070"
                                          size="sm"
                                          onClick={() => {
                                            setIsEditForm(true);
                                            setFormStep(
                                              'investmentGoalSubQuestion',
                                            );
                                          }}
                                        >
                                          Edit
                                        </ActionLink>
                                      </Text>
                                    </Box>
                                  </Box>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                        </Box>

                        <PageFormButtonContainer>
                          <PageFormContinueButton
                            isLoading={isLoading}
                            trackingProperties={{
                              name: 'super_tmd_form_modal_confirmation_continue',
                            }}
                          >
                            Yes - continue
                          </PageFormContinueButton>
                        </PageFormButtonContainer>
                      </Box>
                    )}
                  </Box>
                ))}
              </StyledTMDCarouselBox>
            </Box>
          </FormProvider>
        </StyledContainer>
      )}
    </Modal>
  );
});

SuperTmdFormModal.displayName = 'SuperTmdFormModal';

interface FormStepQuestionProps<T> {
  name: keyof SetSuperTargetMarketDeterminationAnswersInput;
  question: React.ReactNode;
  bodyCopy?: React.ReactNode;
  options: Array<{
    value: T;
    label: React.ReactNode;
  }>;
  onSelectOption: (option: T) => void;
  isRequired?: boolean;
}

function FormStepQuestion<T extends string>({
  name,
  question,
  bodyCopy,
  options,
  onSelectOption,
  isRequired = true,
}: FormStepQuestionProps<T>): React.ReactElement {
  const form = useFormContext();

  return (
    <StyledFieldSet>
      <Stack spaceY="sm">
        <Heading variant={4} align="left" component="legend" isBold={true}>
          {question}
        </Heading>

        {!!bodyCopy && (
          <Text variant={3} color="neutral.085">
            {bodyCopy}
          </Text>
        )}

        <Controller
          name={name}
          rules={{
            required: isRequired ? 'Please select an option' : undefined,
          }}
          control={form.control}
          render={({ field, fieldState }) => (
            <Stack spaceY="xs">
              {fieldState.error?.message && (
                <InlineError message={fieldState.error.message} />
              )}

              <div>
                {options.map((option) => (
                  <StyledOptionLabel key={option.value}>
                    <StyledRadio
                      {...field}
                      type="radio"
                      value={option.value}
                      onClick={() => {
                        form.setValue(name, option.value);
                        onSelectOption(option.value);
                      }}
                    />

                    <Text
                      variant={2}
                      isBold={true}
                      align="left"
                      component="div"
                    >
                      {option.label}
                    </Text>

                    <Box marginLeft="sm">
                      <FeatherChevronRightIcon color="indigo.070" />
                    </Box>
                  </StyledOptionLabel>
                ))}
              </div>
            </Stack>
          )}
        />
      </Stack>
    </StyledFieldSet>
  );
}

const StyledContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  ${minHeight({ md: 700 })}
`;

const StyledTMDCarouselBox = styled(Box).attrs({
  display: 'flex',
})<{ currentIndex: number }>`
  margin-left: -${({ currentIndex }) => currentIndex * 100}%;
  ${transition}
`;

const StyledFieldSet = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`;

const StyledRadio = styled.input`
  opacity: 0;
  position: absolute;
`;

const StyledOptionLabel = styled.label`
  ${paddingY('sm')}
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  :not(:first-child) {
    ${borderWidthTop('sm')}
    ${borderColor('neutral.030')}
    border-top-style: solid;
  }
`;

const StyledBackButton = styled(UnstyledButton)`
  ${transition}
  ${color('neutral.080')}
  ${margin('sm')}
  ${padding('xxxs')}
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  border-radius: 50%;
  line-height: 0;

  :hover {
    ${color('neutral.000')}
    ${backgroundColor('indigo.070')}
  }
`;
