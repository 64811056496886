import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Inline,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { useSetGreenIdFields } from '@spaceship-fspl/data';
import {
  getIDNameBySourceName,
  IDSourceList,
  isSourceNameValid,
  useID,
} from '@spaceship-fspl/green-id';
import {
  FeatherAlertTriangleIcon,
  FeatherTrash2Icon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  color,
  marginLeft,
  marginTop,
  transition,
} from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { FileInput } from 'components/file-input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useGreenID } from 'contexts/greenid';
import { useNotifications } from 'contexts/notifications';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useReverifyAndNavigate } from './hooks';

const MAX_FILE_SIZE_MEGABYTES = 3;

const fileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (typeof fileReader.result !== 'string') {
        reject('Error converting file to base64.');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resolve(fileReader.result.split(';base64,')[1]!);
      }
    };
    fileReader.onerror = () => {
      reject('Error converting file to base64.');
    };
  });

interface PageParams {
  sourceName?: IDSourceList;
}

export const GreenIDUploadDocuments: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { sourceName }: PageParams = useParams();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const { popToast } = useNotifications();
  const { routes, ruleset } = useGreenID();
  const {
    mutateAsync: postGreenIDFields,
    isLoading,
    error,
    reset,
  } = useSetGreenIdFields(ruleset);
  const verificationId = useID(ruleset);
  const [errorIndices, setErrorIndices] = useState<number[]>([]);

  const idName = getIDNameBySourceName(sourceName);
  const reverifyAndNavigate = useReverifyAndNavigate(idName);

  useEffect(() => {
    // id sourceName is needed in API payload
    if (!isSourceNameValid(sourceName)) {
      navigate?.(routes.index, {
        replace: true,
      });
    }
  }, [sourceName, navigate, routes]);

  const onSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const encodedFiles = await Promise.all(uploadedFiles.map(fileToBase64));

    try {
      await Promise.all(
        encodedFiles.map((base64File, index) => {
          return postGreenIDFields(
            {
              verification_id: verificationId,
              source_name: 'docupload',
              field_inputs: {
                // Prefix is used to distinguish "docupload" sources from GreenID in the fraud service.
                greenid_docupload_type: `DOCUPLOAD_${sourceName?.toUpperCase()}`,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                greenid_docupload_filename: uploadedFiles[index]!.name,
                greenid_docupload_data: base64File,
              },
            },
            {
              onError: () => {
                setErrorIndices((prevState) => {
                  return [...prevState, index];
                });
              },
            },
          );
        }),
      );
      handleReset();
      await reverifyAndNavigate();
    } catch {
      popToast({
        level: 'error',
        message: 'Oops, the server appears to be down right now',
      });
    }
  };

  const handleUpload = (files: File[]): void => {
    if (files.length > 0) {
      const fileToBeUploaded: File[] = [];

      files.forEach((file) => {
        if (file.size > MAX_FILE_SIZE_MEGABYTES * 1000000) {
          popToast({
            level: 'error',
            message: `${file.name}'s file size is too large. Please choose a file less than ${MAX_FILE_SIZE_MEGABYTES}MB.`,
          });
        } else {
          fileToBeUploaded.push(file);
        }
      });

      setUploadedFiles((prevState) => {
        return [...prevState, ...fileToBeUploaded];
      });
    }
  };

  const handleRemoveFile = (selectedIndex: number): void => {
    setUploadedFiles((prevState) => {
      return prevState.filter((_, index) => index !== selectedIndex);
    });
  };

  const handleReset = (): void => {
    reset();
    setErrorIndices([]);
    setUploadedFiles([]);
  };

  return (
    <OnboardingContainer>
      <form onSubmit={onSubmit} onReset={handleReset}>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 10 / 12 }}>
            <Stack spaceY={{ xs: 'md', md: 'lg' }}>
              <Columns alignX="center">
                <Columns.Column width={{ xs: 1, lg: 2 / 3 }}>
                  <PageHeading
                    title={
                      <>
                        Upload your
                        <br />
                        {idName || 'supporting documentation'}
                      </>
                    }
                    subtitle="Please make sure your chosen document clearly displays your details."
                  />
                </Columns.Column>
              </Columns>

              <Columns
                alignX="center"
                reverse={{ lg: true }}
                spaceX="xl"
                spaceY="md"
              >
                <Columns.Column width={{ xs: 1, lg: 'min' }}>
                  <Box
                    backgroundColor="neutral.030"
                    borderRadius="sm"
                    padding="lg"
                    width={{ md: 316 }}
                  >
                    <StyledCriteriaList>
                      <li>
                        <Text variant={2} color="black.100">
                          Maximum file size: 3MB
                        </Text>
                      </li>
                      <li>
                        <Text variant={2} color="black.100">
                          Maximum number of files: 1
                        </Text>
                      </li>
                      <li>
                        <Text variant={2} color="black.100">
                          Accepted file types: PDF, GIF, JPG, PNG, BMP, TIF
                        </Text>
                      </li>
                    </StyledCriteriaList>
                  </Box>
                </Columns.Column>

                <Columns.Column width={{ xs: 1, lg: 'min' }}>
                  <Stack spaceY="sm">
                    <Heading
                      variant={5}
                      color="black.100"
                      isBold={true}
                      component="h4"
                    >
                      Documents
                    </Heading>

                    <Box width={{ md: 376 }}>
                      <Stack spaceY="md">
                        <FileInput
                          label="Upload"
                          name="file-upload"
                          onChange={handleUpload}
                          accept=".jpg, .gif, .png, .bmp, .tiff, .pdf"
                          isDisabled={uploadedFiles.length === 1 || !!error}
                        />

                        <div>
                          {uploadedFiles.map((file, index) => {
                            return (
                              <React.Fragment key={file.name}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  paddingY="sm"
                                >
                                  <Text variant={2} color="black.100">
                                    {file.name}
                                  </Text>

                                  {errorIndices.indexOf(index) !== -1 ? (
                                    <Inline spaceX="xxs" alignY="center">
                                      <FeatherAlertTriangleIcon
                                        size="md"
                                        color="red.100"
                                      />
                                      <Text
                                        variant={4}
                                        color="red.100"
                                        isBold={true}
                                      >
                                        Failed
                                      </Text>
                                    </Inline>
                                  ) : (
                                    <StyledTrashButton
                                      isDisabled={isLoading}
                                      onClick={() => handleRemoveFile(index)}
                                    >
                                      <FeatherTrash2Icon size="md" />
                                    </StyledTrashButton>
                                  )}
                                </Box>

                                <Divider color="neutral.050" />
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </Stack>
                    </Box>
                  </Stack>
                </Columns.Column>
              </Columns>
            </Stack>

            <Box marginTop="xl">
              <Text variant={4} color="neutral.085">
                By pressing ‘Submit,’ you agree that you’re authorised to
                provide the attached identification documents and you understand
                the details will be checked against records held by the Issuer
                or Official Record Holder.
              </Text>
            </Box>
          </Columns.Column>
        </Columns>

        <PageFormButtonContainer>
          {error ? (
            <Button
              variant="primary"
              size="lg"
              type="reset"
              trackingProperties={{
                name: 'retry_upload_id_documents',
              }}
            >
              Try again
            </Button>
          ) : (
            <PageFormContinueButton
              trackingProperties={{
                name: 'submit_uploaded_id_documents',
              }}
              isLoading={isLoading}
              isDisabled={uploadedFiles.length === 0}
            >
              Submit
            </PageFormContinueButton>
          )}

          <PageFormCancelButton
            onClick={() => {
              navigate?.(routes.index);
            }}
            trackingProperties={{
              name: 'upload_try_another_id',
            }}
            isDisabled={isLoading}
          >
            Try another ID
          </PageFormCancelButton>
        </PageFormButtonContainer>
      </form>
    </OnboardingContainer>
  );
};

const StyledCriteriaList = styled.ul`
  margin: 0;
  padding: 0;
  ${marginLeft('md')}

  li:not(:first-child) {
    ${marginTop('xxs')}
  }
`;

const StyledTrashButton = styled(UnstyledButton)`
  ${color('black.100')}
  ${transition}
  position: relative;

  ::before {
    ${backgroundColor('neutral.050')}
    ${transition}
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 0;
    width: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    z-index: -1;
  }

  :hover {
    ${color('red.100')}

    ::before {
      height: 32px;
      width: 32px;
    }
  }
`;
