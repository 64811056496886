import { gql, useQuery } from '@apollo/client';
import { useIsAuthenticated } from '@spaceship-fspl/auth';
import {
  countryScalars,
  foreignTaxResidenceScalars,
  missingTINDetailsScalars,
  MissingTINReason,
  SetForeignTaxResidenceInput,
  TaxGroup,
  useSetTaxResidency,
} from '@spaceship-fspl/graphql';
import { WebAppUseTaxResidencyForm } from '@spaceship-fspl/graphql/src/__generated__/WebAppUseTaxResidencyForm';

export interface Country {
  id: string;
  name: string;
  tinRequired: boolean;
  tinInformationLink?: string | null;
}

export type ForeignTaxResidenceField = Partial<{
  id: string;
  countryId: string;
  countryName: string;
  tin?: string;
  tinProvided: boolean;
  tinRequired: boolean;
  tinInformationLink?: string;
  declaredTinMissing?: boolean;
  missingTinReason?: MissingTINReason;
  missingTinOtherDescription?: string;
}>;

export type TaxResidencyFormData = {
  isForeignTaxResident: 'no' | 'yes';
  foreignTaxResidences?: ForeignTaxResidenceField[];
};

interface UseTaxResidencyForm {
  initialFormValues: TaxResidencyFormData;
  handleAustraliaAndOverseasSubmit: (
    formData: TaxResidencyFormData,
  ) => Promise<void>;
  handleAustraliaOnlySubmit: () => Promise<void>;
  updateLoading: boolean;
  fetchLoading: boolean;
  countries: Country[];
}

export const useTaxResidencyForm = (): UseTaxResidencyForm => {
  const isAuthenticated = useIsAuthenticated();
  const [setTaxResidency, setTaxResidencyMeta] = useSetTaxResidency();
  const resp = useQuery<WebAppUseTaxResidencyForm>(
    gql`
      query WebAppUseTaxResidencyForm(
        $isAuthenticated: Boolean!
        $filters: CountryFilters
      ) {
        contact @include(if: $isAuthenticated) {
          id
          foreignTaxResidences {
            id
            ...foreignTaxResidenceScalars
            country {
              id
              ...countryScalars
            }
            missingTINDetails {
              ...missingTINDetailsScalars
            }
          }
        }
        countries(filters: $filters) {
          id
          ...countryScalars
        }
      }
      ${foreignTaxResidenceScalars}
      ${missingTINDetailsScalars}
      ${countryScalars}
    `,
    {
      variables: {
        isAuthenticated,
        filters: {
          taxResidencyAllowed: true,
        },
      },
    },
  );
  const existingFTRs = resp.data?.contact?.foreignTaxResidences ?? [];
  const countries = resp.data?.countries ?? [];

  let initialFormValues: TaxResidencyFormData = {
    isForeignTaxResident: 'no',
    foreignTaxResidences: [],
  };

  if (existingFTRs.length > 0) {
    initialFormValues = {
      isForeignTaxResident: 'yes',
      foreignTaxResidences: existingFTRs.map((ftr) => ({
        id: ftr.id,
        countryId: ftr.country.id,
        countryName: ftr.country.name,
        tinProvided: ftr.tinProvided,
        tinRequired: ftr.country.tinRequired,
        tinInformationLink: ftr.country.tinInformationLink ?? undefined,
        declaredTinMissing: !ftr.tinProvided,
        missingTinReason: ftr.missingTINDetails?.reason ?? undefined,
        missingTinOtherDescription:
          ftr.missingTINDetails?.otherReasonDescription ?? undefined,
      })),
    };
  }

  const handleAustraliaAndOverseasSubmit = async (
    formData: TaxResidencyFormData,
  ): Promise<void> => {
    const ftrs = getUpdatedForeignResidences(
      formData.foreignTaxResidences ?? [],
    );
    await setTaxResidency({
      variables: {
        input: {
          taxGroup: TaxGroup.AUSTRALIA_AND_OVERSEAS,
          foreignTaxResidences: ftrs,
        },
      },
    });
  };

  const handleAustraliaOnlySubmit = async (): Promise<void> => {
    // contact will have an AU address here.
    await setTaxResidency({
      variables: {
        input: {
          taxGroup: TaxGroup.AUSTRALIA_ONLY,
        },
      },
    });
  };

  const updateLoading = setTaxResidencyMeta.loading;

  return {
    countries,
    initialFormValues,
    handleAustraliaOnlySubmit,
    handleAustraliaAndOverseasSubmit,
    updateLoading,
    fetchLoading: resp.loading,
  };
};

/**
 * When called will compare form data with data on record, and
 * add, delete, or update accordingly.
 *
 * Comparison is based on id. Residences with empty ids are
 * assumed to be new.
 */
export const getUpdatedForeignResidences = (
  formFTRs: ForeignTaxResidenceField[],
): SetForeignTaxResidenceInput[] => {
  const residences: SetForeignTaxResidenceInput[] = [];

  formFTRs.forEach((ftr) => {
    if (ftr.id && ftr.countryId) {
      residences.push({
        id: ftr.id,
        tin: ftr.tin,
        countryId: ftr.countryId,
        missingTINReason: ftr.missingTinReason,
        missingTINOtherReasonDescription: ftr.missingTinOtherDescription,
      });
    } else if (ftr.countryId) {
      residences.push({
        tin: ftr.tin,
        countryId: ftr.countryId,
        missingTINReason: ftr.missingTinReason,
        missingTINOtherReasonDescription: ftr.missingTinOtherDescription,
      });
    }
  });
  return residences;
};
