import { Box, Card, Text } from '@spaceship-fspl/components';
import {
  FeatherInfoIcon,
  FeatherXIcon,
  IconComponent,
} from '@spaceship-fspl/icons-web';
import {
  Color,
  getColor,
  getSpace,
  shadows,
  TextVariant,
} from '@spaceship-fspl/styles';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';

const InfoPanelWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  border-radius: ${getSpace('sm')};
  overflow: hidden;
  margin-bottom: ${getSpace('sm')};
  text-align: left;
`;

const InfoPanelFill = styled.div<{
  borderLeftColor: Color;
}>`
  min-width: 11px;
  background-color: ${({ borderLeftColor }) => getColor(borderLeftColor)};
`;

type InfoPanelVariants = 'default' | 'old';

type Props = {
  color: Color;
  icon: IconComponent;
  backgroundColor?: Color;
  isBold?: boolean;
  iconColor?: Color;
  textColor?: Color;
  textVariant?: TextVariant;
  variant?: InfoPanelVariants;
  leftBorderColor?: Color;
  boxShadow?: keyof typeof shadows;
};

export const InfoPanel: React.FC<PropsWithChildren<Props>> = ({
  backgroundColor,
  boxShadow,
  children,
  color,
  icon,
  iconColor,
  isBold = false,
  leftBorderColor,
  textColor,
  textVariant = 2,
  variant = 'default',
}) => {
  const Icon = icon;
  const isDefault = variant === 'default';

  const resolvedBackgroundColor = backgroundColor
    ? backgroundColor
    : isDefault
      ? 'neutral.000'
      : 'neutral.050';

  return (
    <InfoPanelWrapper
      boxShadow={boxShadow ?? (isDefault ? 'md' : 'none')}
      backgroundColor={resolvedBackgroundColor}
    >
      {isDefault ? (
        <InfoPanelFill borderLeftColor={leftBorderColor ?? color} />
      ) : null}

      <Box
        paddingLeft={{
          xs: isDefault ? 'xs' : 'sm',
          sm: isDefault ? 'xs' : 'lg',
        }}
        paddingTop={isDefault ? 'sm' : 'md'}
      >
        <Icon color={iconColor ?? color} size="md" />
      </Box>
      <Box paddingX="xs" paddingY={isDefault ? 'sm' : 'md'}>
        <Text
          variant={textVariant}
          color={textColor ?? 'neutral.085'}
          isBold={isBold}
        >
          {children}
        </Text>
      </Box>
    </InfoPanelWrapper>
  );
};

const DismissibleInfoPanel = styled(Card)`
  display: flex;
  flex-direction: row;
  border-radius: ${getSpace('sm')};
  overflow: hidden;
  margin-bottom: ${getSpace('sm')};
  text-align: left;
`;

const DismissibleButtonBox = styled(Box)`
  cursor: pointer;
`;

export const DisimissibleInfoPanel: React.FC<
  PropsWithChildren<{
    dismissible: boolean;
    dismissHandler: () => void;
    dismissalPending: boolean;
    dismissed?: boolean;
    color: Color;
    message?: string;
    backgroundColor?: Color;
    icon?: IconComponent;
  }>
> = ({
  children,
  dismissible,
  dismissalPending = false,
  dismissHandler,
  message,
  dismissed,
  icon: Icon,
  backgroundColor = 'neutral.000',
  color,
}) => {
  const [panelDismissed, setPanelDismissed] = useState<boolean>(true);

  useEffect(() => {
    setPanelDismissed(!!dismissed);
  }, [dismissed]);

  if (panelDismissed || (!children && !message)) {
    return null;
  }

  const handleDismissal: (handler: () => void, pending: boolean) => void = (
    handler,
    pending,
  ) => {
    if (!pending) {
      handler();
    }
  };

  return (
    <DismissibleInfoPanel boxShadow={'md'} backgroundColor={backgroundColor}>
      <InfoPanelFill borderLeftColor={color} />
      <Box paddingLeft={'xs'} paddingTop={'sm'}>
        {Icon ? (
          <Icon color={color} />
        ) : (
          <FeatherInfoIcon color={color} size="md" />
        )}
      </Box>
      <Box paddingX="xs" paddingY={'sm'}>
        {children ? (
          children
        ) : (
          <Text variant={3} color={'neutral.080'}>
            {message}
          </Text>
        )}
      </Box>
      {dismissible ? (
        <DismissibleButtonBox
          role={'button'}
          onClick={() => handleDismissal(dismissHandler, dismissalPending)}
          paddingRight={'xs'}
          paddingTop={'sm'}
        >
          <FeatherXIcon color={'neutral.080'} />
        </DismissibleButtonBox>
      ) : null}
    </DismissibleInfoPanel>
  );
};
