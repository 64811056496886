import { GraphQLFormattedError } from 'graphql';

import { MfaVerificationType } from './__generated__/globalTypes';

export type SpaceshipGraphQLErrorExtensions = {
  mfa_required_type?: string | null;
  mfa_required_to?: string | null;
};

export const getMFARequiredPayloadFromErrors = (
  graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
): { type: MfaVerificationType; to?: string; reason: string } | undefined => {
  return graphQLErrors
    .flatMap(({ extensions, message }) => {
      switch (extensions?.mfa_required_type) {
        case MfaVerificationType.SMS:
          return {
            type: MfaVerificationType.SMS,
            to: extensions?.mfa_required_to ?? undefined,
            reason: message,
          };
        case MfaVerificationType.EMAIL:
          return {
            type: MfaVerificationType.EMAIL,
            to: extensions?.mfa_required_to ?? undefined,
            reason: message,
          };
        default:
          return undefined;
      }
    })
    .filter(Boolean)?.[0];
};

export const isMoneyBalanceMismatchForPurchaseMessage = (
  graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
): boolean => {
  return graphQLErrors.some(({ message }) => {
    return message.match(/please refresh to continue with your investment/i);
  });
};
