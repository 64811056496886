import {
  FraudVerificationStatus,
  GreenIdSourceName,
  GreenIdSourceStatus,
  IGreenIdInteractiveSource,
  IGreenIdSourceDetails,
} from '@spaceship-fspl/types/externalapi';

import {
  DRIVERS_LICENCE_SOURCE_NAMES,
  ID_OPTIONS,
  ID_SOURCE_NAMES,
  IDName,
  IDSourceList,
  idSourceTypeToProtos,
  IN_PROGRESS_FRAUD_STATUSES,
  PENDING_STATUSES,
  REJECTED_FRAUD_STATUSES,
  REJECTED_STATUSES,
  SourceExhaustedInfo,
  VERIFIED_FRAUD_STATUSES,
  VERIFIED_STATUSES,
} from './constants';

export const idSourceProtosToType = (
  enumName?: GreenIdSourceName.Enum | string,
): IDSourceList | undefined => {
  switch (enumName) {
    case GreenIdSourceName.Enum.CITIZENSHIPCERTIFICATEDVS:
      return 'citizenshipcertificatedvs';
    case GreenIdSourceName.Enum.MEDICAREDVS:
      return 'medicaredvs';
    case GreenIdSourceName.Enum.PASSPORTDVS:
      return 'passportdvs';
    case GreenIdSourceName.Enum.ACTREGODVS:
      return 'actregodvs';
    case GreenIdSourceName.Enum.NSWREGODVS:
      return 'nswregodvs';
    case GreenIdSourceName.Enum.QLDREGODVS:
      return 'qldregodvs';
    case GreenIdSourceName.Enum.TASREGODVS:
      return 'tasregodvs';
    case GreenIdSourceName.Enum.VICREGODVS:
      return 'vicregodvs';
    case GreenIdSourceName.Enum.NTREGODVS:
      return 'ntregodvs';
    case GreenIdSourceName.Enum.SAREGODVS:
      return 'saregodvs';
    case GreenIdSourceName.Enum.WAREGODVS:
      return 'waregodvs';
    case GreenIdSourceName.Enum.VISADVS:
      return 'visadvs';
    default:
      return undefined;
  }
};

export const getIDNameBySourceName = (
  sourceName?: IDSourceList,
): IDName | undefined => {
  if (!sourceName) {
    return undefined;
  }
  return ID_OPTIONS.find(({ value }) =>
    Array.isArray(value)
      ? value.indexOf(sourceName) !== -1
      : sourceName === value,
  )?.label;
};

export const isSourceNameValid = (sourceName?: IDSourceList): boolean => {
  return (sourceName && ID_SOURCE_NAMES.includes(sourceName)) || false;
};

// Parses the driver's licence from array
export const parseSourceName = (
  sourceName: IDSourceList | IDSourceList[],
  sources: IGreenIdInteractiveSource[],
): IDSourceList | undefined => {
  if (Array.isArray(sourceName)) {
    // Only time we use this is when we're dealing with drivers licences
    // Find the first submitted one and use that type.
    const driversLicenceProtos = DRIVERS_LICENCE_SOURCE_NAMES.map(
      (name) => idSourceTypeToProtos[name],
    );
    const found = sources.find(
      ({ name }) => name && driversLicenceProtos.includes(name),
    );
    if (found?.name) {
      return idSourceProtosToType(found.name);
    }
    return undefined;
  } else {
    return sourceName;
  }
};

export const isFraudStatusVerified = (
  status?: FraudVerificationStatus.Enum | null,
): boolean => !!status && VERIFIED_FRAUD_STATUSES.includes(status);

export const isFraudStatusInProgress = (
  status?: FraudVerificationStatus.Enum | null,
): boolean => !!status && IN_PROGRESS_FRAUD_STATUSES.includes(status);

export const isFraudStatusRejected = (
  status?: FraudVerificationStatus.Enum | null,
): boolean => !!status && REJECTED_FRAUD_STATUSES.includes(status);

export const isFraudStatusUnknown = (
  status?: FraudVerificationStatus.Enum | null,
): boolean => status === FraudVerificationStatus.Enum.UNKNOWN;

export const isIDSourceStatusVerified = (
  status?: GreenIdSourceStatus.Enum | null,
): boolean => !!status && VERIFIED_STATUSES.includes(status);

export const isIDSourceStatusPending = (
  status?: GreenIdSourceStatus.Enum | null,
): boolean => !!status && PENDING_STATUSES.includes(status);

export const isIDSourceStatusRejected = (
  status?: GreenIdSourceStatus.Enum | null,
): boolean => !!status && REJECTED_STATUSES.includes(status);

// Determines whether a source is submittable to. Each case is ordered by priority.
const calcIsSourceExhausted = (
  sources: IGreenIdInteractiveSource[],
): boolean => {
  if (!sources.length) {
    // Case 1. No sources submitted, source is available.
    return false;
  } else if (sources.find((s) => isIDSourceStatusPending(s.status))) {
    // Case 2. At least one source is pending / in review.
    return true;
  } else if (
    sources.length ===
    sources.filter((s) => isIDSourceStatusVerified(s.status)).length
  ) {
    // Case 3. All sources are verified.
    return true;
  }
  return false;
};

export const isSourceExhausted = (
  sourceNameOrNames: IDSourceList | IDSourceList[] | undefined,
  greenidInteractiveSources: IGreenIdInteractiveSource[],
): SourceExhaustedInfo => {
  let sourceName: IDSourceList | undefined = undefined;
  let isSourceExhausted = false;
  let isFieldsSubmissionRejected = false;
  const documentUploads: IGreenIdInteractiveSource[] = [];
  const fieldSubmissions: IGreenIdInteractiveSource[] = [];

  if (sourceNameOrNames) {
    sourceName = parseSourceName(sourceNameOrNames, greenidInteractiveSources);

    if (sourceName) {
      const protoName = idSourceTypeToProtos[sourceName];
      greenidInteractiveSources.forEach((source) => {
        if (source.name === protoName) {
          if (source.is_document_upload) {
            documentUploads.push(source);
          } else {
            fieldSubmissions.push(source);
          }
        }
      });
    }

    if (documentUploads.length > 0) {
      // Use document upload sources to determine if source is exhausted
      isSourceExhausted = calcIsSourceExhausted(documentUploads);
    } else if (fieldSubmissions.length > 0) {
      // Use field submissions to determine if source is exhausted
      isSourceExhausted = calcIsSourceExhausted(fieldSubmissions);
    }

    isFieldsSubmissionRejected = Boolean(
      fieldSubmissions.find(({ status }) => isIDSourceStatusRejected(status)),
    );
  }

  return {
    isSourceExhausted,
    isFieldsSubmissionRejected,
    documentUploads,
    fieldSubmissions,
    sourceName,
  };
};

export const parseSourcesLeftToVerify = (
  greenidSourceDetails: IGreenIdSourceDetails | undefined | null,
): number => {
  let greenidSourcesLeftToVerify = 0;
  if (
    greenidSourceDetails?.sources_left_to_verify !== undefined &&
    greenidSourceDetails?.sources_left_to_verify !== null
  ) {
    greenidSourcesLeftToVerify = Number(
      greenidSourceDetails.sources_left_to_verify,
    );
  }
  return greenidSourcesLeftToVerify;
};
