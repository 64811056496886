import * as React from 'react';
export const AstronomyPlanet = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M6.194 18.63A8.84 8.84 0 019.693 3.22a8.834 8.834 0 018.835 2.848M20.636 11.595a8.84 8.84 0 01-9.17 9.039" />
    <path vectorEffect="non-scaling-stroke" d="M3.946 15.844c-1.753 2.733-2.433 4.934-1.579 5.789 1.478 1.478 6.988-1.638 12.308-6.96 5.32-5.32 8.438-10.833 6.956-12.31-.85-.852-3.061-.156-5.778 1.581" />
  </svg>
);
