import { RouteComponentProps } from '@reach/router';
import { getSuperMatchError, SuperMatchErrorType } from '@spaceship-fspl/super';
import { Error } from 'components/layouts/error';
import { useSuperMatch } from 'contexts/super/super-match';
import React from 'react';

interface SuperMatchErrorProps {
  location: {
    state: {
      errorType: SuperMatchErrorType;
    };
  };
}

export const SuperMatchError: React.FC<
  React.PropsWithChildren<RouteComponentProps<SuperMatchErrorProps>>
> = (props) => {
  const { onErrorContinue } = useSuperMatch();
  const { title, copy } = getSuperMatchError(props?.location?.state?.errorType);

  return (
    <Error
      title={title}
      subtitle={copy}
      onContinue={{
        onClick: onErrorContinue,
        trackingProperties: { name: 'supermatch_error_continue' },
      }}
      isOnboarding={true}
    />
  );
};
