import {
  Box,
  Card,
  Divider,
  Heading,
  PresentationLink,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import {
  backgroundColor,
  borderRadius,
  Color,
  paddingX,
  paddingY,
} from '@spaceship-fspl/styles';
import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import {
  AccountCard,
  AccountCardEditProps,
  AccountCardHeading,
} from 'components/account-cards/components';
import { Checkbox } from 'components/checkbox';
import { RadioCard } from 'components/radio-card';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import { VoyagerPortfolioConfig, voyagerPortfolios } from 'helpers/portfolios';
import { requiredValidation } from 'helpers/validation';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

export interface VoyagerPortfolioCardProps {
  portfolio: SaverPortfolio.Enum;
  setPortfolio: (portfolio: SaverPortfolio.Enum) => void;
  edit?: AccountCardEditProps;
}

export const VoyagerPortfolioCard: React.FC<
  React.PropsWithChildren<VoyagerPortfolioCardProps>
> = ({ children, portfolio, setPortfolio, edit }) => {
  const { currentPortfolios } = usePortfolioSelection();

  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
    reset,
  } = useForm<{
    portfolio: SaverPortfolio.Enum | string;
    pds: boolean;
  }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  const portfolioValue = watch('portfolio');
  const portfolioValueNumber = portfolioValue
    ? Number(portfolioValue)
    : undefined;
  const selectedPortfolio = Object.values(voyagerPortfolios).find(
    (p) => p.portfolio === portfolioValueNumber,
  );
  const pdsLink = selectedPortfolio
    ? selectedPortfolio.pds
    : InternalRoutes.IMPORTANT_DOCUMENTS;
  const savedPortfolio = Object.values(voyagerPortfolios).find(
    (p) => p.portfolio === Number(portfolio),
  );

  const onSubmit = handleSubmit((payload) => {
    setPortfolio(Number(payload.portfolio));
  });

  useEffect(() => {
    reset({ portfolio: String(portfolio) });
  }, [portfolio, reset]);

  return (
    <AccountCard>
      <AccountCardHeading title="Portfolio" edit={edit} />

      {!edit?.isEditing && !!savedPortfolio && (
        <Card backgroundColor="indigo.100" borderRadius="sm" padding="lg">
          <PortfolioCardContent theme="light" {...savedPortfolio} />
        </Card>
      )}

      {edit?.isEditing && (
        <form onSubmit={onSubmit}>
          <Stack spaceY="md">
            {Object.values(voyagerPortfolios).map((voyagerPortfolio) => {
              const alreadyHasPortfolio = currentPortfolios?.includes(
                voyagerPortfolio.portfolio,
              );
              return (
                <RadioCard
                  key={voyagerPortfolio.portfolio}
                  value={voyagerPortfolio.portfolio}
                  disabled={alreadyHasPortfolio}
                  error={!!errors.portfolio}
                  contentPadding={{ padding: 'md' }}
                  {...register('portfolio', {
                    ...requiredValidation('Portfolio'),
                  })}
                >
                  <PortfolioCardContent
                    alreadyHasPortfolio={alreadyHasPortfolio}
                    {...voyagerPortfolio}
                  />
                </RadioCard>
              );
            })}

            <div>
              <Checkbox
                errorMessage={errors?.pds?.message}
                {...register('pds', { required: 'This checkbox is required.' })}
              >
                <Text variant={3}>
                  I&apos;ve read and agree to the{' '}
                  <TextLink
                    color="indigo.070"
                    rel="noopener"
                    target="_blank"
                    href={pdsLink}
                  >
                    Product Disclosure Statement
                  </TextLink>{' '}
                  and{' '}
                  <TextLink
                    color="indigo.070"
                    rel="noopener"
                    target="_blank"
                    href={InternalRoutes.REFERENCE_GUIDE}
                  >
                    Reference Guide
                  </TextLink>{' '}
                  for my selected portfolio.
                </Text>
              </Checkbox>

              {children}
            </div>

            <Divider color="neutral.070" />
          </Stack>
        </form>
      )}
    </AccountCard>
  );
};

interface PortfolioCardContentProps extends VoyagerPortfolioConfig {
  alreadyHasPortfolio?: boolean;
  theme?: 'light' | 'dark';
}

const PortfolioCardContent: React.FC<
  React.PropsWithChildren<PortfolioCardContentProps>
> = ({
  title,
  description,
  icon,
  homepage,
  alreadyHasPortfolio = false,
  theme = 'dark',
}) => {
  const color: Color = theme === 'light' ? 'neutral.000' : 'neutral.100';
  const Icon = icon.stars;

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={{ md: 'center' }}
      position="relative"
    >
      {alreadyHasPortfolio && (
        <StyledUnavailableStatus>
          You&apos;ve already signed up to this portfolio
        </StyledUnavailableStatus>
      )}

      <Box marginRight={{ md: 'sm' }} marginBottom={{ xs: 'sm', md: 'none' }}>
        <Icon
          color={theme === 'light' ? 'neutral.000' : 'indigo.070'}
          size="xxxl"
        />
      </Box>

      <Stack spaceY="xxs">
        <Stack spaceY="xs">
          <Heading variant={5} isBold={true} color={color}>
            {title}
          </Heading>
          <Text variant={3} color={color}>
            {description}
          </Text>
        </Stack>

        <PresentationLink
          icon="chevron"
          size="xs"
          color={theme === 'light' ? 'neutral.000' : 'indigo.070'}
          href={homepage}
          target="_blank"
          rel="noopener"
        >
          Learn more
        </PresentationLink>
      </Stack>
    </Box>
  );
};

const StyledUnavailableStatus = styled(Text).attrs({
  component: 'span',
  variant: 4,
  color: 'neutral.000',
})`
  ${backgroundColor('neutral.100')}
  ${borderRadius('sm')}
  ${paddingX('xxs')}
  ${paddingY('xxxs')}
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
