export interface TrackingProperties {
  name: string;
  [key: string]: string;
}

export enum TrackingEvent {
  REFRESH = 'refresh',
  FOCUS_INPUT = 'focus_input',
  DWELL_TIME = 'dwell_time',
  NAVIGATE = 'navigate',
  CLICK = 'click',
  INTERCOM = 'intercom',
  DISPLAY = 'display',
  DEBUG = 'debugging',
}

export enum MarketingTrackingEvents {
  LOGIN_START = 'login_start',
  LOGIN_SUCCESSFUL = 'login_successful',
  SIGNUP_START = 'signup_start',
  SIGNUP_SUBMIT_CONTACT_PAGE = 'signup_submit_contact_page',
  SIGNUP_SUBMIT_DETAILS_PAGE = 'signup_submit_details_page',
  VOYAGER_ONBOARDING_START = 'voyager_onboarding_start',
  VOYAGER_ONBOARDING_SUBMIT_CONTACT_PAGE = 'voyager_onboarding_submit_contact_page',
  VOYAGER_ONBOARDING_SUBMIT_DETAILS_PAGE = 'voyager_onboarding_submit_details_page',
  VOYAGER_ONBOARDING_PORTFOLIO_CONFIRM = 'voyager_onboarding_portfolio_confirm',
  VOYAGER_ONBOARDING_TAX_RESIDENCY_PAGE = 'voyager_onboarding_tax_residency_page',
  VOYAGER_ONBOARDING_GREEN_ID_START = 'voyager_onboarding_green_id_start',
  VOYAGER_ONBOARDING_GREEN_ID_SUBMITTED = 'voyager_onboarding_green_id_submitted',
  VOYAGER_ONBOARDING_LINK_BANK_SELECT_SCREEN = 'voyager_onboarding_link_bank_select_screen',
  VOYAGER_ONBOARDING_LINK_BANK_SELECT_METHOD_SCREEN = 'voyager_onboarding_link_bank_select_method_screen',
  VOYAGER_ONBOARDING_LINK_BANK_AUTO_SELECT = 'voyager_onboarding_link_bank_auto_select',
  VOYAGER_ONBOARDING_LINK_BANK_MANUAL_SELECT = 'voyager_onboarding_link_bank_manual_select',
  VOYAGER_ONBOARDING_LINK_BANK_AUTO_SUBMIT = 'voyager_onboarding_link_bank_auto_submit',
  VOYAGER_ONBOARDING_LINK_BANK_MANUAL_SUBMIT = 'voyager_onboarding_link_bank_manual_submit',
  VOYAGER_ONBOARDING_ONE_OFF_DEPOSIT_SUBMIT = 'voyager_onboarding_deposit_submit',
  VOYAGER_ONBOARDING_ONE_OFF_DEPOSIT_SKIP = 'voyager_onboarding_deposit_skip',
  VOYAGER_ONBOARDING_INVESTMENT_PLAN_SUBMIT = 'voyager_onboarding_ip_submit',
  VOYAGER_ONBOARDING_INVESTMENT_PLAN_SKIP = 'voyager_onboarding_ip_skip',
  VOYAGER_ONBOARDING_ACCOUNT_CREATED = 'voyager_onboarding_account_created',
  VOYAGER_ADD_NEW_PORTFOLIO_SUCCESSFUL = 'voyager_add_new_portfolio_successful',
  VOYAGER_MANDATORY_TAX_RESIDENCY_SUBMITTED = 'voyager_mandatory_tax_residency_submitted',
  SUPER_ONBOARDING_START = 'super_onboarding_start',
  SUPER_ONBOARDING_SUBMIT_CONTACT_PAGE = 'super_onboarding_submit_contact_page',
  SUPER_ONBOARDING_SUBMIT_DETAILS_PAGE = 'super_onboarding_submit_details_page',
  SUPER_ONBOARDING_PORTFOLIO_CONFIRM = 'super_onboarding_portfolio_confirm',
  SUPER_ONBOARDING_GENDER_CONFIRM = 'super_onboarding_gender_confirm',
  SUPER_ONBOARDING_TFN_SUBMIT = 'super_onboarding_tfn_submit',
  SUPER_ONBOARDING_TFN_SKIP = 'super_onboarding_tfn_skip',
  SUPER_ONBOARDING_ACCOUNT_CREATED = 'super_onboarding_account_created',
  SUPER_EMPLOYER_CONTRIBUTION_SENT = 'super_employer_contribution_sent',
  SUPERMATCH_ROLLOVER_REQUEST_SUCCESSFUL = 'supermatch_rollover_request_successful',
  SUPER_REFERRALS_DASHBOARD_CARD_CLICK = 'super_referrals_dashboard_card_click',
  SUPER_REFERRALS_NAVIGATION_CLICK = 'super_referrals_navigation_click',
  SUPER_REFERRALS_ACCOUNT_CLICK = 'super_referrals_account_click',
  SUPER_REFERRALS_REFERRED_START = 'super_referrals_referred_start',
  SUPER_REFERRALS_SHARE_CODE = 'super_referrals_share_code',
}
