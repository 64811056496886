import * as React from 'react';
export const StreamlineCashSearch = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M7.983 11.602c.43.565 1.108.885 1.817.856 1.114 0 2.017-.678 2.017-1.513 0-.835-.903-1.512-2.017-1.512-1.114 0-2.016-.678-2.016-1.514S8.686 6.407 9.8 6.407a2.175 2.175 0 011.817.855M9.8 12.459v1.008M9.8 5.4v1.007" />
    <path vectorEffect="non-scaling-stroke"
      clipRule="evenodd"
      d="M9.86 18.721A8.86 8.86 0 109.86 1a8.86 8.86 0 000 17.721z"
    />
    <path vectorEffect="non-scaling-stroke" d="M16.127 16.126L23 23" />
  </svg>
);
