import { gql } from '@apollo/client';
import {
  Box,
  Columns,
  Divider,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  MoneyPaymentSource,
  MoneyTransactionStatus,
  MoneyTransactionType,
} from '@spaceship-fspl/graphql';
import { MoneyTransactionDetails_MoneyTransactionDetailsFragment as MoneyTransactionDetails_MoneyTransactionDetailsFragmentType } from '@spaceship-fspl/graphql/src/__generated__/MoneyTransactionDetails_MoneyTransactionDetailsFragment';
import { WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node } from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyDashboardPage';
import {
  capitalizeWords,
  formatCurrency,
  formatDate,
  maskBankAccountNumber,
} from '@spaceship-fspl/helpers';
import {
  backgroundColor,
  borderWidthTop,
  Color,
  getColor,
  getSpace,
  match,
  paddingBottom,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { LabeledField } from 'components/labeled-field';
import { DATE_FORMAT_TRANSACTIONS } from 'components/transactions';
import { format } from 'date-fns';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled, { css } from 'styled-components';

import { MoneyTransactionStatusIndicator } from './money-transaction-status-indicator';

export const MoneyTransactionDetails_MoneyTransactionDetailsFragment = gql`
  fragment MoneyTransactionDetails_MoneyTransactionDetailsFragment on MoneyTransaction {
    id
    moneyReference
    audAmount
    status
    createdAt
    lastUpdatedAt
    statusMessage
    type

    paymentSourceDetails {
      paymentSource
      bankAccount {
        id
        accountName
        accountNumber
        bsb
      }
    }
  }
`;

interface MoneyTransactionItemProps {
  moneyTransaction: MoneyTransactionDetails_MoneyTransactionDetailsFragmentType;
  isExpandable?: boolean;
}

export const MoneyTransactionItem: React.FC<
  React.PropsWithChildren<MoneyTransactionItemProps>
> = ({ isExpandable, moneyTransaction }) => {
  if (isExpandable) {
    return (
      <StyledDetails
        data-testid={TestId.MONEY_TRANSACTION_DETAILS}
        key={moneyTransaction.id}
      >
        <StyledSummary data-testid={TestId.MONEY_TRANSACTION_SUMMARY}>
          <TransactionDetails moneyTransaction={moneyTransaction} />
        </StyledSummary>
        <MoneyTransactionExpansionPanel moneyTransaction={moneyTransaction} />
      </StyledDetails>
    );
  }
  return (
    <StyledContentOnlyContainer
      data-testid={TestId.MONEY_TRANSACTION_SUMMARY}
      key={moneyTransaction.id}
    >
      <TransactionDetails moneyTransaction={moneyTransaction} />
    </StyledContentOnlyContainer>
  );
};

export const MoneyTransactionExpansionPanel: React.FC<
  TransactionDetailsProps
> = ({ moneyTransaction }) => {
  const referenceNumber = moneyTransaction?.moneyReference ?? '—';
  const { accountName = '—', bsb = '—' } =
    moneyTransaction?.paymentSourceDetails?.bankAccount ?? {};

  const accountNumber = moneyTransaction?.paymentSourceDetails?.bankAccount
    ?.accountNumber
    ? maskBankAccountNumber(
        moneyTransaction.paymentSourceDetails.bankAccount.accountNumber,
      )
    : '—';

  const transactionTime = moneyTransaction?.createdAt
    ? `${formatDate(moneyTransaction.createdAt, 'dd MMM yyyy h:mm a')}`
    : '—';

  return (
    <Stack spaceY={{ xs: 'sm', md: 'md' }}>
      <Divider color="neutral.050" />

      <StyledContentBox>
        <Stack spaceY="md">
          <Columns spaceX="lg" spaceY="sm">
            <Columns.Column width="min">
              <LabeledField size="sm" label="Initiated">
                {transactionTime}
              </LabeledField>
            </Columns.Column>

            <Columns.Column width="min">
              <LabeledField size="sm" label="Reference number">
                {referenceNumber}{' '}
              </LabeledField>
            </Columns.Column>
          </Columns>

          <Columns spaceX="lg" spaceY="sm">
            <Columns.Column width="min">
              <LabeledField size="sm" label="Linked bank account">
                {accountName}
              </LabeledField>
            </Columns.Column>

            <Columns.Column width="min">
              <Inline spaceX="lg">
                <LabeledField size="sm" label="BSB">
                  {bsb}
                </LabeledField>
                <LabeledField size="sm" label="Account number">
                  {accountNumber}
                </LabeledField>
              </Inline>
            </Columns.Column>
          </Columns>
        </Stack>
      </StyledContentBox>
    </Stack>
  );
};

interface TransactionDetailsProps {
  moneyTransaction: WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node;
}

/**
 * transaction details for the money transaction
 */
const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  moneyTransaction: {
    audAmount,
    createdAt,
    paymentSourceDetails,
    status,
    statusMessage,
    type,
  },
}) => {
  let transactionType = undefined;
  let amountPrefix = '';

  switch (type) {
    case 'DISTRIBUTION': {
      transactionType = 'Distribution';
      amountPrefix = '+';
      break;
    }
    case 'DIVIDEND':
      transactionType = 'Dividend';
      amountPrefix = '-';
      break;
    case 'FEE':
      transactionType = 'Fee';
      amountPrefix = '-';
      break;
    case 'OTHER':
      transactionType = 'Other'; // TODO: assume this will use transaction object data for statusMessage

      if (Number(audAmount) > 0) {
        amountPrefix = '+';
      } else if (Number(audAmount) < 0) {
        amountPrefix = '-';
      }
      break;
    case 'PURCHASE':
      transactionType = 'Buy'; // TODO: assume this will use transaction object data in place of statusMessage
      amountPrefix = '-';
      break;
    case 'SALE':
      transactionType = 'Sell'; // TODO: assume this will have a status message
      amountPrefix = '+';
      break;
    case MoneyTransactionType.MONEY_IN: {
      transactionType = 'Money in';
      amountPrefix = '+';
      break;
    }
    case MoneyTransactionType.MONEY_OUT: {
      transactionType = 'Money out';
      amountPrefix = '-';
      break;
    }
  }

  const handleAudAmountColour = (): Color => {
    switch (status) {
      case MoneyTransactionStatus.FAILED:
      case MoneyTransactionStatus.CANCELLED: {
        return 'neutral.080';
      }
      default: {
        if (amountPrefix === '+') {
          return 'green.100';
        }
        return 'neutral.100';
      }
    }
  };

  const handleMapDepositSource = (
    source?: MoneyPaymentSource | null,
  ): string => {
    switch (source) {
      case MoneyPaymentSource.DIRECT_ENTRY: {
        return '- Direct entry';
      }
      case MoneyPaymentSource.PAYTO: {
        return '- Pay to';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Box>
      <Columns>
        <Columns.Column width={9 / 12}>
          <Columns>
            <Columns.Column width={{ xs: 1, lg: 3 / 12 }}>
              <Box
                display="flex"
                alignItems="center"
                marginBottom={{ xs: 'xs', md: 'none' }}
              >
                <Text
                  whiteSpace="nowrap"
                  variant={4}
                  isBold={true}
                  color="neutral.080"
                >
                  {createdAt
                    ? format(new Date(createdAt), DATE_FORMAT_TRANSACTIONS)
                    : 'Pending'}
                </Text>
              </Box>
            </Columns.Column>
            <Columns.Column width={{ xs: 1, lg: 7 / 12 }}>
              <Box
                display="flex"
                alignItems="center"
                marginBottom={{ xs: 'xs', md: 'none' }}
              >
                <Text variant={3} isBold={true} align="left">
                  {transactionType}{' '}
                  {statusMessage ? `(${statusMessage})` : null}{' '}
                  {handleMapDepositSource(paymentSourceDetails?.paymentSource)}
                </Text>
              </Box>
            </Columns.Column>
            <Columns.Column width={{ xs: 1, lg: 2 / 12 }}>
              <Box
                data-testid={TestId.MONEY_TRANSACTION_STATUS}
                display="flex"
                alignItems="center"
              >
                <MoneyTransactionStatusIndicator status={status} />
                <Text variant={4} isBold={true} color={'neutral.080'}>
                  {capitalizeWords(status)}
                </Text>
              </Box>
            </Columns.Column>
          </Columns>
        </Columns.Column>
        <Columns.Column width={3 / 12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={'flex-start'}
          >
            <Text
              variant={3}
              isBold={true}
              align="right"
              color={handleAudAmountColour()}
            >
              {amountPrefix} {formatCurrency(audAmount)}
            </Text>
          </Box>
        </Columns.Column>
      </Columns>
    </Box>
  );
};
const dividerStyle = css`
  :not(:first-child) {
    ${borderWidthTop('sm')};
    border-top-style: solid;
    border-top-color: ${getColor('neutral.030')};
  }
`;

const StyledDetails = styled.details<{
  isExpandable?: boolean;
}>`
  ${dividerStyle}
  cursor: ${(props) => (props.isExpandable ? 'pointer' : 'unset')};
  position: relative;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    width: ${getSpace('xxxs')};
    background-color: transparent;
  }

  &[open] {
    ${backgroundColor('neutral.015')};

    ::before {
      ${backgroundColor('indigo.070')};
    }
  }

  [open] + &[open] {
    border-top-color: ${getColor('neutral.070')};
  }
`;

const StyledSummary = styled.summary`
  ${paddingX({ xs: 'md', md: 'lg' })}
  ${paddingY('md')}
  ${transition}
  cursor: pointer;
  list-style: none;
  outline: none;

  ${StyledDetails}[open] & {
    ${paddingBottom({ xs: 'sm', md: 'md' })}
  }

  :hover {
    ${backgroundColor('neutral.015')}
  }

  ::-webkit-details-marker,
  ::marker {
    display: none;
  }
`;

const DATE_MIN_WIDTH = 85;
const StyledContentBox = styled(Box).attrs({
  paddingX: { xs: 'md', md: 'lg' },
  paddingBottom: 'md',
})`
  ${match('md')`
    margin-left: calc(${DATE_MIN_WIDTH}px + ${getSpace('xxs')});
  `};
`;

const StyledContentOnlyContainer = styled.div`
  ${dividerStyle}
  ${paddingY('sm')}
  ${paddingX({ xs: 'md', md: 'lg' })}
`;
