import { RouteComponentProps } from '@reach/router';
import { Box, Card, Columns, Heading, Stack } from '@spaceship-fspl/components';
import { Text } from '@spaceship-fspl/components';
import {
  SetTaxResidencyInput,
  useSetTaxResidency,
} from '@spaceship-fspl/graphql';
import { prettifyError } from '@spaceship-fspl/helpers';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { Modal } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useState } from 'react';

interface Props {
  location: {
    state: {
      input: SetTaxResidencyInput;
    };
  };
}

export const UsInvestingWarning: React.FC<
  React.PropsWithChildren<RouteComponentProps<Props>>
> = ({ navigate, location }) => {
  const { popToast } = useNotifications();
  const [setTaxResidency, setTaxResidencyMeta] = useSetTaxResidency();
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
            <Stack spaceY="lg">
              <PageHeading title="Important information about changing your tax residency details" />

              <Card>
                <Box paddingY="md">
                  <Stack spaceY="xs" alignX="center">
                    <FeatherAlertTriangleIcon color="red.100" size="lg" />
                    <Text variant={2} isBold={true}>
                      To have a US Investing account, you must:
                    </Text>
                    <ul>
                      <li>
                        <Text variant={2}>Be an Australian tax resident;</Text>
                      </li>
                      <li>
                        <Text variant={2}>
                          Have an Australian residential address;
                        </Text>
                      </li>
                      <li>
                        <Text variant={2}>
                          Not be a US citizen or tax resident;
                        </Text>
                      </li>
                      <li>
                        <Text variant={2}>
                          Not be a citizen of certain other countries.
                        </Text>
                      </li>
                    </ul>
                  </Stack>
                </Box>
              </Card>
              <Heading variant={4} isBold={true}>
                What changing your tax residency details means for your US
                Investing account
              </Heading>
              <Text variant={2}>
                Because your tax residency is changing to a country that falls
                outside our eligibility criteria, we will no longer be able to
                offer our US Investing service to you and you will no longer be
                able to hold US securities. When you confirm this change, your
                US Investing account will be set to allow sell orders only.
                Investment plans and any pending buy orders will be cancelled.
              </Text>
              <Text variant={2} isBold={true}>
                Once you confirm, you will have to contact our customer support
                team if you’d like to change your tax residency details again,
                so please ensure the information you provide is accurate.
              </Text>
              <Text variant={2}>
                It is your responsibility to provide up to date and accurate
                information about your tax residency.
              </Text>
              <Text variant={2}>
                Please note, if you change your tax residency details, this
                change will be applied to all other Spaceship products, and be
                reported to the ATO. However, this won’t impact your ability to
                use any other Spaceship products.{' '}
              </Text>
              <Heading variant={4} isBold={true}>
                What happens next?
              </Heading>
              <Text variant={2}>
                Once you confirm, we’ll send you an email with information on
                how to sell your holdings or transfer them to another brokerage
                provider. You’ll have 30 days to complete these actions.
              </Text>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{
                    name: 'us_investing_tax_residency_warning_proceed',
                  }}
                  onClick={() => {
                    setModalVisibility(true);
                  }}
                >
                  I understand - proceed
                </PageFormContinueButton>

                <PageFormCancelButton
                  trackingProperties={{
                    name: 'us_investing_tax_residency_warning_cancel',
                  }}
                  onClick={() => {
                    navigate?.(-1);
                  }}
                >
                  Cancel
                </PageFormCancelButton>
              </PageFormButtonContainer>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
      <Modal
        showModal={isModalVisible}
        maxWidth={600}
        closeModal={() => setModalVisibility(false)}
      >
        <Box padding={'xl'}>
          <Stack spaceY={'xs'}>
            <Box paddingBottom={'sm'}>
              <PageHeading title="Your US Investing account will be restricted" />
            </Box>
            <Text variant={2}>
              Your US Investing account will be set to allow sell orders only.
              Investment plans and any pending buy orders will be cancelled.
            </Text>
            <PageFormButtonContainer>
              <PageFormCancelButton
                trackingProperties={{
                  name: 'us_investing_tax_residency_warning_cancel',
                }}
                onClick={() => {
                  setModalVisibility(false);
                }}
              >
                Cancel
              </PageFormCancelButton>
              <PageFormContinueButton
                trackingProperties={{
                  name: 'us_investing_tax_residency_warning_confirm_proceed',
                }}
                isLoading={setTaxResidencyMeta.loading}
                onClick={async () => {
                  if (location?.state.input) {
                    try {
                      await setTaxResidency({
                        variables: {
                          input: location.state.input,
                        },
                      });
                      navigate?.(Routes.VOYAGER_DASHBOARD);
                      popToast({
                        message: 'Your tax residency has been updated',
                        level: 'success',
                      });
                    } catch (e) {
                      addRumError({ error: e });
                      popToast({
                        message: prettifyError(e),
                        level: 'error',
                      });
                    } finally {
                      setModalVisibility(false);
                    }
                  }
                }}
              >
                I understand - Confirm
              </PageFormContinueButton>
            </PageFormButtonContainer>
          </Stack>
        </Box>
      </Modal>
    </PageContainer>
  );
};
