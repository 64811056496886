import * as React from 'react';
export const StreamlineFastFoodBurgerDrink = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M6.967 22.551H3.425L1.3 6.967h12.042L12.996 9.5" />
    <path vectorEffect="non-scaling-stroke" d="M6.259 16.884L8.384 1.3l4.25.708M11.217 19.718h9.917v1.416a1.417 1.417 0 01-1.416 1.417h-7.084a1.417 1.417 0 01-1.417-1.417v-1.416zM14.05 12.634h4.25a2.833 2.833 0 012.834 2.833v1.417h-9.917v-1.417a2.833 2.833 0 012.834-2.833v0zM9.8 16.884h12.751v2.834H9.801v-2.834z" />
  </svg>
);
