import { navigate } from '@reach/router';
import { Box, Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  DATE_FORMAT_TRANSACTIONS_SHORT,
  formatDate,
} from '@spaceship-fspl/helpers';
import { Button } from 'components/button';
import { CenterPageContainer } from 'components/layouts/page';
import { TestId } from 'helpers/test-ids';
import { Routes } from 'pages/routes';
import React from 'react';

import { AnimatedTransactionItem } from './animated-transaction-item';

export enum MoneyTransactionVariant {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

export interface MoneyTransactionSuccessProps {
  variant: MoneyTransactionVariant;
  amount: string;
  etaDate?: Date;
}

export const MoneyTransactionSuccess: React.FC<
  React.PropsWithChildren<MoneyTransactionSuccessProps>
> = ({ variant, amount, etaDate }) => {
  const formattedLongEtaDate = etaDate
    ? formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_LONG)
    : undefined;

  const formattedShortEtaDate = etaDate
    ? formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_SHORT)
    : undefined;

  const isDeposit = variant === MoneyTransactionVariant.DEPOSIT;

  const title = {
    [MoneyTransactionVariant.DEPOSIT]: 'Your money is on its way!',
    [MoneyTransactionVariant.WITHDRAW]: 'Your withdrawal has been submitted.',
  }[variant];

  const copy = {
    [MoneyTransactionVariant.DEPOSIT]: (
      <>
        The ETA of your money to Spaceship is{' '}
        <strong>{formattedLongEtaDate}</strong>.
      </>
    ),
    [MoneyTransactionVariant.WITHDRAW]: (
      <>
        The ETA of your withdrawal to your bank account is{' '}
        <strong>{formattedLongEtaDate}</strong>
      </>
    ),
  }[variant];

  const transactionTitle = {
    [MoneyTransactionVariant.DEPOSIT]: 'Money in',
    [MoneyTransactionVariant.WITHDRAW]: 'Withdrawal',
  }[variant];

  return (
    <CenterPageContainer>
      <Box paddingY="xl" data-testid={TestId.MONEY_TRANSACTION_SUCCESS_PAGE}>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                data-testid={TestId.MONEY_TRANSACTION_SUCCESS_PAGE_SUMMARY_CARD}
              >
                <AnimatedTransactionItem
                  formatAmountAsNegative={isDeposit ? false : true}
                  variant={variant}
                  title={transactionTitle}
                  etaDate={formattedShortEtaDate}
                  amount={amount}
                />
              </Box>

              <Stack
                spaceY="sm"
                alignX="center"
                data-testid={TestId.MONEY_TRANSACTION_SUCCESS_PAGE_CONTENTS}
              >
                <Heading variant={3} align="center">
                  {title}
                </Heading>

                {formattedLongEtaDate && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    maxWidth={{ md: 420 }}
                  >
                    <Text variant={1} align="center">
                      {copy}
                    </Text>
                  </Box>
                )}
              </Stack>

              <Box marginTop="xl">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    navigate(Routes.MONEY);
                  }}
                  trackingProperties={{
                    name: 'money_transaction_success_button',
                  }}
                >
                  View my dashboard
                </Button>
              </Box>
            </Box>
          </Columns.Column>
        </Columns>
      </Box>
    </CenterPageContainer>
  );
};
