import * as React from 'react';
export const FeatherZapOff = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12.41 6.75L13 2l-2.43 2.92M18.57 12.91L21 10h-5.34M8 8l-5 6h9l-1 8 5-6M1 1l22 22" />
  </svg>
);
