import {
  type Address,
  BeneficiaryBase,
  type BeneficiaryIntent,
  Transaction,
  UnitPrice,
} from '@sargon/api-client';
import {
  type CreateBindingBeneficiaryIntentInput,
  SuperPortfolio,
  SuperTMDResult,
} from '@spaceship-fspl/graphql';
import { SetSuperTargetMarketDeterminationAnswers } from '@spaceship-fspl/graphql/src/__generated__/SetSuperTargetMarketDeterminationAnswers';
import { SuperTMDDetails } from '@spaceship-fspl/graphql/src/__generated__/SuperTMDDetails';
import {
  camelCaseToText,
  formatDateForRequests,
  stringToDate,
  toSydneyStartOfDay,
} from '@spaceship-fspl/helpers';
import { subMonths, subWeeks, subYears } from 'date-fns';

/**
 * Calculates the performance delta in percentage between the last item in our
 * list and the first point outside the bounds specified by fn and num.
 */
export const calculatePerformance = (
  data: UnitPrice[],
  timePeriod: 'week' | 'month' | 'year',
): number | undefined => {
  const mostRecentPrice = data?.[0];
  let thresholdDate: Date;

  if (!mostRecentPrice || !mostRecentPrice.date) {
    return;
  }

  const mostRecentPriceDate = toSydneyStartOfDay(
    new Date(mostRecentPrice.date),
  );

  switch (timePeriod) {
    case 'year':
      thresholdDate = subYears(mostRecentPriceDate, 1);
      break;

    case 'month':
      thresholdDate = subMonths(mostRecentPriceDate, 1);
      break;

    case 'week':
    default:
      thresholdDate = subWeeks(mostRecentPriceDate, 1);
      break;
  }

  const mostRecentSellPrice = mostRecentPrice.sellPrice?.amount || 0;
  const timePeriodSellPrice =
    data.find(
      ({ date }) => date && toSydneyStartOfDay(new Date(date)) <= thresholdDate,
    )?.sellPrice?.amount || 0;

  return mostRecentSellPrice && timePeriodSellPrice
    ? (mostRecentSellPrice / timePeriodSellPrice - 1) * 100
    : 0;
};

const TRANSACTION_DESCRIPTION_MAP = {
  [Transaction.TransactionTypeEnum.AccountBalance]: 'Account Balance',
  [Transaction.TransactionTypeEnum.BenefitPayment]: 'Benefit Payment',
  [Transaction.TransactionTypeEnum.Commission]: 'Commission',
  [Transaction.TransactionTypeEnum.Contribution]: 'Contribution',
  [Transaction.TransactionTypeEnum.ContributionsTax]: 'Contributions Tax',
  [Transaction.TransactionTypeEnum.Fees]: 'Fees',
  [Transaction.TransactionTypeEnum.GST]: 'GST',
  [Transaction.TransactionTypeEnum.InsurancePremium]: 'Insurance Premium',
  [Transaction.TransactionTypeEnum.InsurerPayment]: 'Insurer Payment',
  [Transaction.TransactionTypeEnum.MemberProtection]: 'Member Protection',
  [Transaction.TransactionTypeEnum.MiscExpense]: 'Misc Expense',
  [Transaction.TransactionTypeEnum.MiscIncome]: 'Quarterly Returns',
  [Transaction.TransactionTypeEnum.OtherTaxes]: 'Other Taxes',
  [Transaction.TransactionTypeEnum.PAYGTaxes]: 'PAYG Taxes',
  [Transaction.TransactionTypeEnum.PaymentAmount]: 'Payment Amount',
  [Transaction.TransactionTypeEnum.PensionPayment]: 'Pension Payment',
  [Transaction.TransactionTypeEnum.RITC]: 'RITC',
  [Transaction.TransactionTypeEnum.Surcharge]: 'Administration Fees',
  [Transaction.TransactionTypeEnum.TaxRebate]: 'TaxRebate',
  [Transaction.TransactionTypeEnum.TaxRemittance]: 'Tax Remittance',
  [Transaction.TransactionTypeEnum.TransferToReserve]: 'Transfer To Reserve',
};

export const getTransactionDescription = (
  type?: Transaction.TransactionTypeEnum,
  coa?: string,
): string => {
  // IMPORTANT:
  // CoA codes less than four digits are padded with '0', i.e. '200' -> '0200'
  // @see: https://spaceship-hq.atlassian.net/wiki/spaces/PROJ/pages/1738637498/REF+Super+Transaction+CoA+Codes
  switch (coa) {
    case '0200':
      return 'Personal contribution';

    case '0310':
      return 'Employer contribution';

    case '0320':
    case '1220':
      return 'Contribution tax';

    case '1210':
      return 'Salary sacrifice contribution';

    case '2210':
      return 'Spouse contribution';

    case '2450':
      return 'Administration fee';

    case '2465':
      return 'Switching fee';

    case '2675':
      return 'Benefit payment (First Home Super Saver)';

    case '2766':
      return 'Protecting Your Super fee rebate';

    case '3210':
      return 'Rollover into Spaceship';

    case '6323':
      return 'Benefit payment (Unclaimed super)';

    case '6380':
      return 'Rollover out of Spaceship';

    case '6393':
      return 'Partial rollover out of Spaceship';

    case '6396':
      return 'Benefit payment (Compassionate grounds)';

    case '8501':
      return 'Administration fee GST';

    case '8561':
      return 'Switching fee GST';
  }

  return type
    ? TRANSACTION_DESCRIPTION_MAP[type] || camelCaseToText(type)
    : '-';
};

export enum SuperTmdIneligibilityReason {
  INCORRECT_ANSWERS,
  TOO_MANY_ATTEMPTS,
}

export const tmdContentForIneligibilityReason = (
  reason: SuperTmdIneligibilityReason,
): { title: string; description: string } => {
  switch (reason) {
    case SuperTmdIneligibilityReason.INCORRECT_ANSWERS: {
      return {
        title: 'Hmm...',
        description:
          'It doesn’t look like you’re in the target market for Spaceship Super and this product may not meet your needs.\n\nIt’s worth reviewing our Product Disclosure Statement and Target Market Determination to understand who Spaceship Super is designed for, the potential returns and the level of investment risk.\n\nYou also may find it useful to speak to a financial adviser.\n\nIf your investment objectives, financial situation or needs change you’re welcome to come back at a later date.',
      };
    }
    case SuperTmdIneligibilityReason.TOO_MANY_ATTEMPTS: {
      return {
        title: 'Hmm...',
        description:
          'We understand your interest in this product and we appreciate your enthusiasm. However, we have a responsibility to ensure that our products are suitable for our target market.\n\nBased on the information you’ve provided in previous attempts, it seems that you may not align with our target market.\n\nWe suggest you read our PDS and TMD again. It could also be beneficial for you to speak with a financial adviser. If your circumstances change in the future, you’re welcome to come back.',
      };
    }
  }
};

export const tmdDetailsForPortfolio = (
  portfolio: SuperPortfolio,
  data?:
    | SuperTMDDetails['contact']
    | SetSuperTargetMarketDeterminationAnswers['setSuperTargetMarketDeterminationAnswers']
    | null,
):
  | {
      result: SuperTMDResult | null;
      submissionAvailable: boolean | null;
    }
  | undefined => {
  switch (portfolio) {
    case SuperPortfolio.GLOBAL_INDEX: {
      return data?.account?.globalIndexSuperTMDDetails;
    }
    case SuperPortfolio.GROWTHX: {
      return data?.account?.growthXSuperTMDDetails;
    }
  }
};

export const formatBeneficiaryAddress = (
  address: Address | undefined,
  placeholder = '-',
): string => {
  if (address) {
    return `${address.line1},${address?.line2 ? ` ${address.line2}, ` : ' '}${address.suburb}, ${address.state} ${address.postcode}`;
  }

  return placeholder;
};

export const mapBeneficiaryPayloadAllocation = ({
  beneficiaries,
  benefitProportion,
}: {
  beneficiaries: BeneficiaryBase[];
  benefitProportion: Record<string, string>;
}): BeneficiaryBase[] =>
  beneficiaries.map((beneficiary, index) => {
    return {
      ...beneficiary,
      address: {
        postcode: beneficiary.address?.postcode ?? '',
        state: beneficiary.address?.state ?? '',
        suburb: beneficiary.address?.suburb ?? '',
        line1: beneficiary.address?.line1 ?? '',
        line2: beneficiary.address?.line2 ?? '',
        countryCode: 'AU',
      },
      benefitProportion: Number(benefitProportion[index]),
      beneficiaryType: BeneficiaryBase.BeneficiaryTypeEnum.NonBinding,
      birthDate: beneficiary.birthDate
        ? formatDateForRequests(stringToDate(beneficiary.birthDate))
        : undefined,
    };
  });

export const mapBeneficiaryMutationPayload = ({
  id,
  beneficiaries,
}: {
  id: string;
  beneficiaries: NonNullable<BeneficiaryBase>[];
}): CreateBindingBeneficiaryIntentInput => ({
  id,
  beneficiaries: beneficiaries.map((beneficiary) => ({
    dateOfBirth: beneficiary?.birthDate ?? null,
    familyName: beneficiary?.familyName ?? null,
    givenName: beneficiary?.givenName ?? null,
    postcode: beneficiary?.address?.postcode ?? null,
    proportion: beneficiary?.benefitProportion
      ? String(beneficiary.benefitProportion)
      : null,
    relationship: beneficiary?.relationship ?? null,
    state: beneficiary?.address?.state ?? null,
    streetLine1: beneficiary?.address?.line1 ?? null,
    streetLine2: beneficiary?.address?.line2 ?? null,
    suburb: beneficiary?.address?.suburb ?? null,
    countryCode: 'AU',
  })),
});

export const hasInProgressBindingNomination = ({
  beneficiaryIntent,
  latestBindingBeneficiaryIntentId,
  currentBeneficiaryType,
}: {
  beneficiaryIntent: BeneficiaryIntent | undefined;
  latestBindingBeneficiaryIntentId: string | null | undefined;
  currentBeneficiaryType: BeneficiaryBase.BeneficiaryTypeEnum | undefined;
}): boolean => {
  const currentIntentId = beneficiaryIntent?.id;

  return (
    currentBeneficiaryType === BeneficiaryBase.BeneficiaryTypeEnum.NonBinding &&
    currentIntentId === latestBindingBeneficiaryIntentId
  );
};
