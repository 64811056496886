import {
  FeatherArrowDownIcon,
  FeatherChevronDownIcon,
  IconComponent,
  IconProps,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  color,
  fontWeight,
  getColor,
  match,
  PaddingProps,
  rgba,
  transition,
} from '@spaceship-fspl/styles';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Divider } from '../divider';
import { Heading } from '../heading';
import { Text } from '../text';

type Variant = 'default' | 'info' | 'option' | 'show-more' | 'faq';

interface AccordionTheme {
  titleBackgroundColor?: string;
  titleTextClosedColor?: string;
  titleTextClosedColorHover?: string;
  titleTextOpenColor?: string;
  chevronIconClosedColor?: string;
  chevronIconOpenColor?: string;
}

const defaultTheme: AccordionTheme = {
  titleBackgroundColor: getColor('neutral.030'),
  titleTextClosedColor: getColor('black.100'),
  titleTextClosedColorHover: getColor('indigo.090'),
  titleTextOpenColor: getColor('black.100'),
  chevronIconClosedColor: getColor('indigo.070'),
  chevronIconOpenColor: getColor('indigo.070'),
};

export const accordionTheme: Record<'dark' | 'light', AccordionTheme> = {
  dark: {
    titleBackgroundColor: rgba('neutral.000', 0.1),
    titleTextClosedColor: getColor('neutral.000'),
    titleTextClosedColorHover: getColor('indigo.015'),
    titleTextOpenColor: getColor('black.100'),
    chevronIconClosedColor: getColor('neutral.000'),
    chevronIconOpenColor: getColor('indigo.070'),
  },
  light: defaultTheme,
};

export interface AccordionProps extends PaddingProps {
  variant: Variant;
  title: React.ReactNode;
  open?: boolean;
  onToggle?: () => void;
  chevronPosition?: 'left' | 'right'; // Only use `left` if accordion is full width on page
  chevronIconProps?: IconProps;
}

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  variant,
  ...props
}) => {
  switch (variant) {
    case 'info':
      return <InfoAccordion {...props} />;

    case 'option':
      return <OptionAccordion {...props} />;

    case 'show-more':
      return <ShowMoreAccordion {...props} />;

    case 'faq':
      return <FAQAccordion {...props} />;

    case 'default':
    default:
      return <DefaultAccordion {...props} />;
  }
};

const UnstyledAccordion: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<AccordionProps & { chevronIcon?: IconComponent }>
  >
> = ({
  variant,
  title,
  open,
  onToggle,
  chevronPosition = 'right',
  chevronIcon: ChevronIcon = FeatherChevronDownIcon,
  chevronIconProps,
  children,
  ...paddingProps
}) => {
  return (
    <StyledAccordionDetails open={open} onToggle={onToggle}>
      <StyledAccordionSummary>
        <Box
          display="flex"
          flexDirection={chevronPosition === 'right' ? 'row' : 'row-reverse'}
          alignItems="center"
          {...paddingProps}
        >
          {title}
          <StyledIcon variant={variant}>
            <ChevronIcon {...chevronIconProps} />
          </StyledIcon>
        </Box>
      </StyledAccordionSummary>

      <div>{children}</div>
    </StyledAccordionDetails>
  );
};

const DefaultAccordion: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<Omit<AccordionProps, 'variant'>>
  >
> = ({ title, ...props }) => {
  return (
    <UnstyledAccordion
      variant="default"
      title={<Box flex={1}>{title}</Box>}
      chevronIconProps={{
        color: 'black.100',
        size: 'md',
      }}
      paddingY="sm"
      {...props}
    />
  );
};

const FAQAccordion: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<Omit<AccordionProps, 'variant'>>
  >
> = ({ title, ...props }) => {
  return (
    <UnstyledAccordion
      variant="faq"
      title={
        <Box flex={1}>
          <StyledFAQAccordionTitle>{title}</StyledFAQAccordionTitle>
        </Box>
      }
      paddingY="md"
      chevronIcon={FeatherArrowDownIcon}
      {...props}
    />
  );
};

const InfoAccordion: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<Omit<AccordionProps, 'variant'>>
  >
> = ({
  title,
  children,
  open,
  onToggle,
  chevronPosition = 'left', // InfoAccordion is usually full width on the page
  chevronIconProps,
}) => {
  return (
    <StyledAccordionDetails open={open} onToggle={onToggle}>
      <StyledAccordionSummary>
        <StyledInfoAccordionTitleBox
          flexDirection={chevronPosition === 'right' ? 'row' : 'row-reverse'}
        >
          <Box
            paddingLeft={chevronPosition === 'left' ? 'md' : 'none'}
            paddingRight={chevronPosition === 'right' ? 'md' : 'none'}
            flex={1}
          >
            <StyledInfoAccordionTitle>{title}</StyledInfoAccordionTitle>
          </Box>
          <StyledIcon variant="info">
            <FeatherChevronDownIcon size="md" {...chevronIconProps} />
          </StyledIcon>
        </StyledInfoAccordionTitleBox>
      </StyledAccordionSummary>

      <StyledInfoAccordionContentContainerBox>
        <Divider color="neutral.050" />
        <StyledAccordionContentBox
          paddingTop="md"
          paddingBottom="lg"
          paddingX="lg"
          chevronPosition={chevronPosition}
        >
          {children}
        </StyledAccordionContentBox>
      </StyledInfoAccordionContentContainerBox>
    </StyledAccordionDetails>
  );
};

const OptionAccordion: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<Omit<AccordionProps, 'variant'>>
  >
> = ({ title, chevronPosition = 'right', children, ...props }) => {
  return (
    <Box borderRadius="sm" boxShadow="sm" backgroundColor="neutral.000">
      <UnstyledAccordion
        variant="info"
        chevronIconProps={{
          color: 'indigo.070',
          size: 'md',
        }}
        chevronPosition={chevronPosition}
        title={
          <Box
            paddingLeft={chevronPosition === 'left' ? 'md' : 'none'}
            paddingRight={chevronPosition === 'right' ? 'md' : 'none'}
            flex={1}
          >
            <StyledOptionAccordionTitle>{title}</StyledOptionAccordionTitle>
          </Box>
        }
        paddingX="lg"
        paddingY="md"
        {...props}
      >
        <Divider color="neutral.050" />
        <StyledAccordionContentBox
          paddingTop="md"
          paddingBottom="lg"
          paddingX="lg"
          chevronPosition={chevronPosition}
        >
          {children}
        </StyledAccordionContentBox>
      </UnstyledAccordion>
    </Box>
  );
};

const ShowMoreAccordion: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<Omit<AccordionProps, 'variant'>>
  >
> = ({ children, title, ...props }) => {
  return (
    <UnstyledAccordion
      variant="show-more"
      chevronIconProps={{
        color: 'indigo.070',
        size: 'sm',
      }}
      title={
        <Box marginRight="xxxs">
          <StyledShowMoreAccordionTitle>{title}</StyledShowMoreAccordionTitle>
        </Box>
      }
      {...props}
    >
      {children}
    </UnstyledAccordion>
  );
};

const StyledAccordionDetails = styled.details`
  width: 100%;
`;

const StyledIcon = styled.div<{ variant: Variant }>`
  ${transition}
  line-height: 0;
  transform-origin: center;
  transform: rotate(
    ${({ variant }) =>
      variant === 'show-more' || variant === 'faq' ? '270deg' : '0deg'}
  );
  ${({ theme, variant }) => {
    if (variant === 'info') {
      return `color: ${
        theme.chevronIconClosedColor ?? defaultTheme.chevronIconClosedColor
      };`;
    }

    if (variant === 'faq') {
      return css`
        ${color('neutral.070')}
      `;
    }

    return '';
  }}

  ${StyledAccordionDetails}[open] & {
    ${({ variant }) =>
      `transform: rotate( ${variant === 'faq' ? 360 : 180}deg);`}

    ${({ theme, variant }) =>
      variant === 'info' || variant === 'faq'
        ? `color: ${
            theme.chevronIconOpenColor ?? defaultTheme.chevronIconOpenColor
          };`
        : ''}
  }
`;

const StyledAccordionSummary = styled.summary`
  cursor: pointer;
  list-style: none;
  width: 100%;
  outline: none;

  ::-webkit-details-marker,
  ::marker {
    display: none;
  }
`;

const StyledInfoAccordionContentContainerBox = styled(Box).attrs({
  backgroundColor: 'neutral.000',
})`
  ${borderRadiusBottomLeft('sm')}
  ${borderRadiusBottomRight('sm')}
`;

const StyledInfoAccordionTitle = styled(Heading).attrs({
  variant: { xs: 5, md: 4 },
  align: 'left',
  component: 'div',
})`
  ${fontWeight({ xs: 700, md: 600 })}
  ${transition}
`;

const StyledInfoAccordionTitleBox = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  paddingX: 'lg',
  paddingY: 'md',
})<{ theme: AccordionTheme }>`
  ${borderRadius('sm')}
  background-color: ${({ theme }) =>
    theme.titleBackgroundColor ?? defaultTheme.titleBackgroundColor};

  &,
  ${StyledInfoAccordionTitle} {
    color: ${({ theme }) =>
      theme.titleTextClosedColor ?? defaultTheme.titleTextClosedColor};

    :hover {
      color: ${({ theme }) =>
        theme.titleTextClosedColorHover ??
        defaultTheme.titleTextClosedColorHover};
    }
  }

  ${StyledAccordionDetails}[open] & {
    ${backgroundColor('neutral.000')}
    ${borderRadiusBottomLeft('none')}
    ${borderRadiusBottomRight('none')}

    &, ${StyledInfoAccordionTitle} {
      color: ${({ theme }) =>
        theme.titleTextOpenColor ?? defaultTheme.titleTextOpenColor};
    }
  }
`;

const StyledOptionAccordionTitle = styled(Heading).attrs({
  variant: 5,
  isBold: true,
  align: 'left',
  component: 'div',
})`
  ${transition}

  :hover {
    ${color('indigo.090')}
  }
`;

const StyledFAQAccordionTitle = styled(Text).attrs({
  variant: 1,
  isBold: true,
})`
  ${transition}

  :hover {
    ${color('indigo.060')}
  }

  ${StyledAccordionDetails}[open] & {
    ${color('indigo.060')}
  }
`;

const StyledShowMoreAccordionTitle = styled(Text).attrs({
  variant: 3,
  isBold: true,
  color: 'indigo.070',
})`
  ${transition}

  :hover {
    ${color('indigo.090')}
    text-decoration: underline;
  }
`;

const StyledAccordionContentBox = styled(Box)<{
  chevronPosition: 'left' | 'right';
}>`
  ${({ chevronPosition }) => {
    if (chevronPosition === 'left') {
      return match('md')`
        padding-left: 80px;
      `;
    }
    return '';
  }}
`;
