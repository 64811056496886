export enum TestId {
  LOADER = 'LOADER',
  MONEY_ACTIVITY_CARD = 'money_activity_card',
  MONEY_TRANSACTION_TYPE_FILTER = 'money_transaction_type_filter',
  MONEY_TRANSACTION_DETAILS = 'money_transaction_details',
  MONEY_TRANSACTION_SUMMARY = 'money_transaction_summary',
  MONEY_TRANSACTION_STATUS = 'money_transaction_status',
  MONEY_TRANSACTION_LIST = 'money_transaction_list',
  MONEY_AVAILABLE_MONEY_CARD = 'money_available_money_card',
  MONEY_READY_TO_INVEST_CARD = 'money_ready_to_invest_card',
  MONEY_LOAD_MORE_TRANSACTIONS_BUTTON = 'money_load_more_transactions_button',
  MONEY_DEPOSIT_PAGE = 'money_deposit_page',
  MONEY_DEPOSIT_PAGE_GO_BACK_BUTTON = 'money_deposit_page_go_back_button',
  MONEY_DEPOSIT_PAGE_CONFIRM_BUTTON = 'money_deposit_page_confirm_button',
  MONEY_DEPOSIT_PAGE_CONTACT_SUPPORT_BUTTON = 'money_deposit_page_contact_support_button',
  MONEY_DEPOSIT_PAGE_NOW_AMOUNT = 'money_deposit_success_page_now_amount',
  MONEY_DEPOSIT_PAGE_AFTER_AMOUNT = 'money_deposit_success_page_after_amount',
  MONEY_WITHDRAW_CONFIRM_PAGE = 'money_withdraw_confirm_page',
  MONEY_WITHDRAW_PAGE_GO_BACK_BUTTON = 'money_withdraw_page_go_back_button',
  MONEY_WITHDRAW_PAGE_CONTACT_SUPPORT_BUTTON = 'money_withdraw_page_contact_support_button',
  MONEY_TRANSACTION_SUCCESS_PAGE = 'money_transaction_success_page',
  MONEY_TRANSACTION_SUCCESS_PAGE_SUMMARY_CARD = 'money_transaction_success_page_summary_card',
  MONEY_TRANSACTION_SUCCESS_PAGE_CONTENTS = 'money_transaction_success_page_contents',
  MONEY_TRANSACTION_SUCCESS_PAGE_INFO_BOX = 'money_transaction_success_page_info_box',
  VOYAGER_ONE_OFF_DEPOSIT_FORM_ETA_BOX = 'voyager_one_off_deposit_form_eta_box',
}
