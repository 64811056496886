import React from 'react';

import { Media, queries } from '../media';

const useMatchMedia = (query: string): boolean => {
  const [matches, setMatches] = React.useState<boolean>(() =>
    typeof window !== 'undefined' ? window.matchMedia(query).matches : false,
  );
  React.useEffect(() => {
    const handleMediaChange = (event: MediaQueryListEvent): void =>
      setMatches(event.matches);
    const mql = window.matchMedia(query);
    // @see https://github.com/mdn/sprints/issues/858
    // Safari uses addListener & removeListener
    if (typeof mql.addEventListener !== 'undefined') {
      mql.addEventListener('change', handleMediaChange);
    } else {
      mql.addListener(handleMediaChange);
    }
    return () => {
      if (typeof mql.removeEventListener !== 'undefined') {
        mql.removeEventListener('change', handleMediaChange);
      } else {
        mql.removeListener(handleMediaChange);
      }
    };
  }, [query]);
  return matches;
};

export const useBetweenMedia = (mediaA: Media, mediaB: Media): boolean => {
  const from = useMatchMedia(queries[mediaA]);
  const to = useMatchMedia(queries[mediaB]);
  return from && !to;
};
