import React from 'react';

import { IdentifyFunction, TrackFunction } from './segment';

export * from './segment';

type Noop = () => void;
const noop: Noop = () => {
  // noop
};

const TrackingFunctionsContext = React.createContext<{
  identify: IdentifyFunction | Noop;
  track: TrackFunction | Noop;
}>({
  track: noop,
  identify: noop,
});
const TrackingCategoriesContext = React.createContext<string[]>([]);

export interface TrackingProviderProps {
  track?: TrackFunction;
  identify?: IdentifyFunction;
}

export const TrackingProvider: React.FC<
  React.PropsWithChildren<TrackingProviderProps>
> = ({ track, identify, children }) => {
  const functions = React.useMemo(
    () => ({ track: track ?? noop, identify: identify ?? noop }),
    [track, identify],
  );
  return (
    <TrackingFunctionsContext.Provider value={functions}>
      {children}
    </TrackingFunctionsContext.Provider>
  );
};

export interface TrackingCategoryProps {
  category: string;
}

export const TrackingCategory: React.FC<
  React.PropsWithChildren<TrackingCategoryProps>
> = ({ category, children }) => {
  const parent = React.useContext(TrackingCategoriesContext);
  const concatenated = React.useMemo(
    () => [...parent, category],
    [parent, category],
  );
  return (
    <TrackingCategoriesContext.Provider value={concatenated}>
      {children}
    </TrackingCategoriesContext.Provider>
  );
};

export function withTrackingCategory(
  category: string,
): <P extends { children?: React.ReactNode }>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
) => (props: P) => JSX.Element {
  return function <P extends { children?: React.ReactNode }>(
    Component: React.ComponentType<React.PropsWithChildren<P>>,
  ) {
    return function WithTrackingCategory(props: P) {
      return (
        <TrackingCategory category={category}>
          <Component {...props} />
        </TrackingCategory>
      );
    };
  };
}

export enum TrackingAction {
  Navigate = 'Navigate',
  Select = 'Select',
  Show = 'Show',
  Hide = 'Hide',
  Submit = 'Submit',
}

interface CategoryParameters {
  category?: string;
}

export interface NavigateTrackingParameters extends CategoryParameters {
  action: TrackingAction.Navigate;
  label: string;
}

export interface ShowTrackingParameters extends CategoryParameters {
  action: TrackingAction.Show;
}

export interface HideTrackingParameters extends CategoryParameters {
  action: TrackingAction.Hide;
}

export interface SelectTrackingParameters extends CategoryParameters {
  action: TrackingAction.Select;
  label: string;
}

export interface SubmitTrackingParameters extends CategoryParameters {
  action: TrackingAction.Submit;
  label: 'valid' | 'invalid';
}

export type UseTrackingTrackFunctionParameters =
  | NavigateTrackingParameters
  | ShowTrackingParameters
  | HideTrackingParameters
  | SelectTrackingParameters
  | SubmitTrackingParameters;

export interface UseTrackingResult {
  identify: IdentifyFunction | Noop;
  track: (parameters: UseTrackingTrackFunctionParameters) => void;
}

export const useTracking = (): UseTrackingResult => {
  const { track, identify } = React.useContext(TrackingFunctionsContext);
  const categories = React.useContext(TrackingCategoriesContext);
  return React.useMemo<UseTrackingResult>(
    () => ({
      identify,
      track: ({
        category = categories.length ? categories.join(' | ') : undefined,
        action,
        ...otherParams
      }: UseTrackingTrackFunctionParameters) => {
        track(action, {
          properties: {
            category,
            ...otherParams,
            /*
              We use the beacon transport mode which uses navigator.sendBeacon() so the browser
              will honor sending even after the user has navigated away from the page
              @see https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
            */
            transport: 'beacon',
          },
        });
      },
    }),
    [track, identify, categories],
  );
};
