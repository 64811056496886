import {
  backgroundColor,
  paddingBottom,
  paddingTop,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import React from 'react';
import styled from 'styled-components';

import { Box } from '../box';
import { Divider } from '../divider';
import { Container, Stack } from '../layout';
import { PropsWithLinkComponent } from '../link-component';
import { Logo } from '../logo';
import { SocialLinks } from '../social-links';
import { Text } from '../text';
import { Visible } from '../visible';
import { DisclaimerSection } from './disclaimer-section';
import { LinksSection } from './links-section';
import { NewsletterSection } from './newsletter-section';

const Outer = styled.footer`
  ${backgroundColor('neutral.100')}
`;

const Inner = styled.div`
  ${paddingTop('xl')}
  ${paddingBottom({ xs: 'xl', lg: 'xxxl' })}
`;

const FooterDivider: React.FC = () => <Divider color="indigo.100" />;

export type FooterProps = PropsWithLinkComponent & {
  isFirstHomeEnabled?: boolean;
};

export const Footer: React.FC<FooterProps> = ({
  LinkComponent,
  isFirstHomeEnabled,
}) => {
  const handleChatWithUs = (): void => {
    if (typeof window === 'object' && typeof window.Intercom === 'function') {
      Intercom('show');
    }
  };
  return (
    <TrackingCategory category="Footer">
      <Outer>
        <Container>
          <Inner>
            <Stack spaceY={{ xs: 'lg', md: 'xl' }}>
              <Visible
                isHidden={{ md: true }}
                component="div"
                displayValue="block"
              >
                <Stack spaceY={{ xs: 'lg', md: 'none' }}>
                  <Box display="flex" justifyContent="center">
                    <Logo variant="light" />
                  </Box>
                  <FooterDivider />
                </Stack>
              </Visible>
              <NewsletterSection LinkComponent={LinkComponent} />
              <Stack spaceY={{ xs: 'none', md: 'xl' }}>
                <FooterDivider />
                <LinksSection
                  onChatWithUs={handleChatWithUs}
                  LinkComponent={LinkComponent}
                  isFirstHomeEnabled={isFirstHomeEnabled}
                />
                <FooterDivider />
              </Stack>
              <Visible isHidden={{ md: true }} displayValue="block">
                <Stack spaceY={{ xs: 'lg', md: 'none' }}>
                  <Visible isHidden={{ md: true }} displayValue="block">
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        marginBottom="md"
                      >
                        <Text variant={4} color="neutral.070" isBold={true}>
                          Follow us on
                        </Text>
                      </Box>
                      <SocialLinks
                        size="lg"
                        variant="light"
                        orientation="horizontal"
                        space="between"
                      />
                    </Box>
                  </Visible>
                  <FooterDivider />
                </Stack>
              </Visible>
              <DisclaimerSection LinkComponent={LinkComponent} />
            </Stack>
          </Inner>
        </Container>
      </Outer>
    </TrackingCategory>
  );
};
