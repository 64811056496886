import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { Address, MemberUpdate } from '@sargon/api-client';
import { getFormattedStreetAddress } from '@spaceship-fspl/address';
import { Box, Card, Columns, Stack, Text } from '@spaceship-fspl/components';
import { addressScalars } from '@spaceship-fspl/graphql';
import { WebAppSuperLinkMismatch } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperLinkMismatch';
import {
  ErrorWithCode,
  formatPhone,
  stripWhitespace,
} from '@spaceship-fspl/helpers';
import {
  FeatherCheckIcon,
  FeatherXIcon,
  StreamlineAlertTriangleIcon,
} from '@spaceship-fspl/icons-web';
import {
  LinkAccountApiErrorCodes,
  useAmplifyAuth,
  useLinkAccount,
  useUpdateMember,
} from '@spaceship-fspl/super';
import { LabeledField } from 'components/labeled-field';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export interface SuperLinkMismatchProps {
  location: {
    state: {
      email: {
        isMatch: boolean;
        value: string;
      };
      phone: {
        isMatch: boolean;
        value: string;
      };
      address: {
        isMatch: boolean;
        value: Address;
      };
    };
  };
}

const MISMATCH_AFTER_UPDATE =
  'Super linking update mismatch details - details are still mismatched after update';

export const SuperLinkMismatch: React.FC<
  React.PropsWithChildren<RouteComponentProps<SuperLinkMismatchProps>>
> = ({ location }) => {
  const navigate = useNavigate();
  const [matches, setMatches] = useState(location?.state);
  const [isLoading, setIsLoading] = useState(false);
  const { popToast } = useNotifications();
  const amplifyAuth = useAmplifyAuth();
  const linkAccount = useLinkAccount();
  const updateMember = useUpdateMember();

  const resp = useQuery<WebAppSuperLinkMismatch>(gql`
    query WebAppSuperLinkMismatch {
      contact {
        id
        email
        phoneNumber
        address {
          ...addressScalars
        }
      }
    }
    ${addressScalars}
  `);

  const onCancel = async (): Promise<void> => {
    setIsLoading(true);
    await amplifyAuth.logout();
    setIsLoading(false);
    navigate(Routes.SUPER_LINK, { replace: true });
  };

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);

    let authToken;

    // 1. Get auth token
    try {
      authToken = await amplifyAuth.getAuthToken();
      if (!authToken) {
        throw Error('Missing aws cognito auth token');
      }
    } catch (error) {
      setIsLoading(false);
      addRumError({
        error,
        context: {
          reason: 'Super linking update mismatch details: no auth token',
        },
      });
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
      navigate(Routes.SUPER_LINK, { replace: true });
      return;
    }

    // 2. Update mismatched contact details
    const memberData: MemberUpdate = {};

    if (!matches?.email?.isMatch && resp?.data?.contact?.email) {
      memberData.email = resp.data.contact.email;
    }
    if (!matches?.phone?.isMatch && resp?.data?.contact?.phoneNumber) {
      memberData.phoneMobile = stripWhitespace(resp.data.contact.phoneNumber);
    }
    if (!matches?.address?.isMatch && resp?.data?.contact?.address) {
      const address: Address = {
        line1: getFormattedStreetAddress(resp?.data?.contact?.address) ?? '',
        line2: '',
        suburb: resp?.data?.contact?.address.suburb ?? '',
        state: resp?.data?.contact?.address.state ?? '',
        postcode: resp?.data?.contact?.address.postcode ?? '',
        countryCode: resp?.data?.contact?.address.country ?? '',
      };
      memberData.addressPostal = address;
      memberData.addressResidential = address;
    }

    try {
      await updateMember.mutateAsync(memberData);
    } catch (error) {
      setIsLoading(false);
      addRumError({
        error,
        context: {
          reason:
            'Super linking update mismatch details - unable to update member details',
        },
      });
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
      return;
    }

    // 3. Retry linking
    const mismatches: string[] = [];

    try {
      const data = await linkAccount.linkSuperAccount();

      if (
        data?.matches &&
        data?.member?.email &&
        data?.member?.phoneMobile &&
        data?.member?.addressResidential
      ) {
        // there are still mismatched details
        setMatches({
          email: {
            isMatch: data.matches.emailAddress,
            value: data.member.email,
          },
          phone: {
            isMatch: data.matches.phoneNumber,
            value: data.member.phoneMobile,
          },
          address: {
            isMatch: data.matches.address,
            value: data.member.addressResidential,
          },
        });

        for (const [item, isMatch] of Object.entries(data.matches)) {
          if (!isMatch) {
            mismatches.push(item.replace(/_/g, ' '));
          }
        }

        throw new Error(MISMATCH_AFTER_UPDATE);
      } else {
        setIsLoading(false);
        await amplifyAuth.logout();
        navigate(Routes.SUPER_LINK_SUCCESS);
      }
    } catch (error) {
      setIsLoading(false);

      const castedError = error as
        | ErrorWithCode<LinkAccountApiErrorCodes>
        | undefined;

      if (castedError?.message === MISMATCH_AFTER_UPDATE) {
        addRumError({
          error,
          context: {
            reason: `${MISMATCH_AFTER_UPDATE}: ${mismatches.join(', ')}`,
          },
        });
        popToast({
          level: 'error',
          message: `Your Spaceship Super account details do not match: ${mismatches.join(
            ', ',
          )}`,
        });
      } else {
        addRumError({
          error,
          context: {
            reason: `Super linking update mismatch details: ${castedError?.code}`,
          },
        });

        if (
          castedError?.code === LinkAccountApiErrorCodes.REFRESH_SPACESHIP_TOKEN
        ) {
          // linking was still successful so navigate to success screen
          popToast({
            level: 'warning',
            message:
              'Your Spaceship Super account was successfully linked, however there was a problem loading your data.',
          });
          navigate(Routes.SUPER_LINK_SUCCESS);
        } else {
          popToast({
            level: 'error',
            message: GENERIC_ERROR_MESSAGE,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      !location?.state?.address ||
      !location?.state?.email ||
      !location?.state?.phone
    ) {
      navigate(Routes.SUPER_LINK, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      // Not logged into AWS Cognito, so won't be able to update
      const authToken = await amplifyAuth.getAuthToken();
      if (!authToken) {
        if (mounted) {
          navigate(Routes.SUPER_LINK, { replace: true });
        }
      }
    })();

    return () => {
      mounted = false;
    };
  }, [amplifyAuth, navigate]);

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <form onSubmit={handleSubmit}>
            <Stack spaceY={{ xs: 'lg', md: 'xl' }}>
              <Stack spaceY="sm" alignX="center">
                <StreamlineAlertTriangleIcon
                  size="xxxl"
                  color="indigo.070"
                  strokeWidth={3}
                />

                <PageHeading
                  title={
                    <>
                      Oops, your Spaceship Super account details don&apos;t
                      match:
                    </>
                  }
                />
              </Stack>

              <Box display="flex" justifyContent="center">
                <Card
                  padding={{ xs: 'md', md: 'lg' }}
                  width={{ xs: '100%', md: 'auto' }}
                  minWidth={{ md: 420 }}
                >
                  <Stack spaceY={{ xs: 'sm', md: 'md' }}>
                    <MatchItem
                      label="Email address"
                      value={matches?.email?.value}
                      isMatch={matches?.email?.isMatch}
                    />

                    <MatchItem
                      label="Mobile number"
                      value={formatPhone(matches?.phone?.value)}
                      isMatch={matches?.phone?.isMatch}
                    />

                    <MatchItem
                      label="Address"
                      value={
                        <>
                          {matches?.address?.value.line1}
                          <br />
                          {matches?.address?.value.line2 && (
                            <>
                              {matches?.address?.value.line2}
                              <br />
                            </>
                          )}
                          {matches?.address?.value.suburb},{' '}
                          {matches?.address?.value.state},{' '}
                          {matches?.address?.value.postcode},{' '}
                          {matches?.address?.value.countryCode}
                        </>
                      }
                      isMatch={matches?.address?.isMatch}
                    />
                  </Stack>
                </Card>
              </Box>

              <Text variant={2} align="center">
                Would you like Spaceship to update your Spaceship Super contact
                information and link your accounts for you?
              </Text>
            </Stack>

            <PageFormButtonContainer>
              <StyledPageFormContinueButton
                trackingProperties={{ name: 'super_link_mismatch_update' }}
                isLoading={
                  isLoading || updateMember.isLoading || linkAccount.isLoading
                }
              >
                Yes — update information
              </StyledPageFormContinueButton>

              <StyledPageFormCancelButton
                onClick={onCancel}
                trackingProperties={{ name: 'super_link_mismatch_cancel' }}
                isDisabled={
                  isLoading || updateMember.isLoading || linkAccount.isLoading
                }
              />
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

interface MatchItemProps {
  label: string;
  value?: React.ReactNode;
  isMatch?: boolean;
}

const MatchItem: React.FC<React.PropsWithChildren<MatchItemProps>> = ({
  label,
  value,
  isMatch = false,
}) => {
  return (
    <Box display="flex">
      <Box marginRight={{ xs: 'xxs', md: 'sm' }}>
        {isMatch ? (
          <FeatherCheckIcon size="md" color="mint.100" />
        ) : (
          <FeatherXIcon size="md" color="red.100" />
        )}
      </Box>

      <LabeledField label={label} size="md">
        {value}
      </LabeledField>
    </Box>
  );
};

const StyledPageFormContinueButton = styled(PageFormContinueButton)`
  min-width: 270px;
`;

const StyledPageFormCancelButton = styled(PageFormCancelButton)`
  min-width: 270px;
`;
