import { useString } from '@spaceship-fspl/dynamic-config';
import { formatDate, toSydneyStartOfDay } from '@spaceship-fspl/helpers';
import { DynamicConfigKeys } from 'helpers/dynamic-config';

export const useSftValues = (): {
  sftOneSuperGoLiveDateFormatted: string;
} => {
  const sftOneSuperGoLiveDate = useString(
    DynamicConfigKeys.SFT_ONE_SUPER_GO_LIVE_DATE,
    '2024-06-11',
  );
  const sftOneSuperGoLiveDateFormatted = formatDate(
    toSydneyStartOfDay(new Date(sftOneSuperGoLiveDate)),
    'd MMMM yyyy',
  );

  return {
    sftOneSuperGoLiveDateFormatted,
  };
};
