import * as React from 'react';
export const StreamlineOfficialBuilding2 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect vectorEffect="non-scaling-stroke" x={1.5} y={6.96} width={21} height={2.88} rx={0.5} />
    <path vectorEffect="non-scaling-stroke" d="M21 6.96c0-2.88-4.5-5.76-9-5.76S3 4.08 3 6.96" />
    <rect vectorEffect="non-scaling-stroke" x={1.5} y={17.04} width={21} height={2.88} rx={0.75} />
    <path vectorEffect="non-scaling-stroke" d="M1.5 22.8h21M3 9.84v7.2M6 9.84v7.2M9 9.84v7.2M15 9.84v7.2M21 9.84v7.2M18 9.84v7.2" />
  </svg>
);
