import { SargonApiClient } from '@sargon/api-client';
import { Provider } from '@spaceship-fspl/super';
import { SuperOTPModal } from 'components/super-otp-modal';
import { amplifyAuthConfig } from 'helpers/amplify-auth';
import { superClient } from 'helpers/super-client';
import React, { useRef, useState } from 'react';

export interface ProviderProps {
  client?: SargonApiClient;
}

export const SuperProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ client = superClient, children }) => {
  const [showSuperOTPModal, setShowSuperOTPModal] = useState(false);
  const verifiedOTP = useRef<(() => void) | undefined>();
  const unverifiedOTP = useRef<(() => void) | undefined>();

  const handleOnOTPRequired = (
    verified: () => void,
    unverified: () => void,
  ): void => {
    setShowSuperOTPModal(true);
    verifiedOTP.current = verified;
    unverifiedOTP.current = unverified;
  };

  return (
    <Provider
      client={client}
      onOTPRequired={handleOnOTPRequired}
      amplifyAuthConfig={amplifyAuthConfig}
    >
      <>
        {children}
        <SuperOTPModal
          showModal={showSuperOTPModal}
          onVerifiedOTP={() => {
            setShowSuperOTPModal(false);
            verifiedOTP.current?.();
          }}
          onUnverifiedOTP={() => {
            setShowSuperOTPModal(false);
            unverifiedOTP.current?.();
          }}
        />
      </>
    </Provider>
  );
};
