import * as React from 'react';
export const StreamlineHourglass = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M18.446 5.783c0 3.437-2.9 6.217-6.476 6.217-3.577 0-6.477-2.78-6.477-6.217V1h12.953v4.783zm0 12.434c0-3.437-2.9-6.217-6.476-6.217-3.577 0-6.477 2.78-6.477 6.217V23h12.953v-4.783zM3.5 1h16.938M3.5 23h16.938" />
    <path vectorEffect="non-scaling-stroke" d="M5.493 19.174H8.98l2.99-2.87 2.989 2.87h3.487M6.888 9.61H17.06" />
  </svg>
);
