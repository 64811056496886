import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Container,
  Inline,
  Stack,
} from '@spaceship-fspl/components';
import { useAppStoreLinks } from '@spaceship-fspl/graphql';
import { FeatherArrowLeftIcon, SpaceshipIcon } from '@spaceship-fspl/icons-web';
import voygerFeatureImage from 'assets/feature-image@2x.png';
import superFeatureImage from 'assets/feature-image-super@2x.png';
import { Button } from 'components/button';
import { IntercomFooter } from 'components/intercom-footer';
import {
  SuperMobileAppPromotion,
  SuperMobileAppPromotionVariant,
  VoyagerMobileAppPromotion,
} from 'components/mobile-app-promotion';
import { AccessibilityLabel } from 'helpers/accessibility';
import * as React from 'react';
import styled from 'styled-components';

import { Routes } from './routes';

interface MobileAppsProps {
  featureImage: string;
  cta?: React.ReactNode;
}

const DownloadMobileApp: React.FC<React.PropsWithChildren<MobileAppsProps>> = ({
  featureImage,
  cta,
  children,
}) => {
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Container>
        <Box paddingBottom="md">
          <Stack spaceY={{ xs: 'xl', md: 'xxxl', lg: 'xl' }} alignX="center">
            <Columns
              spaceX={{ xs: 'none', md: 'md' }}
              alignX="center"
              alignY="center"
            >
              <Columns.Column width={{ xs: 1, lg: 5 / 12 }}>
                <Columns
                  alignX="center"
                  alignY="center"
                  spaceX={{ xs: 'none', md: 'md', lg: 'none' }}
                >
                  <Columns.Column>
                    <Box display="flex" justifyContent="center">
                      <Box maxWidth={{ xs: '330px', lg: '480px' }}>
                        <StyledFeatureImage
                          src={featureImage}
                          alt="Spaceship mobile app"
                        />
                      </Box>
                    </Box>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
              <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                {children}
              </Columns.Column>
            </Columns>
            {cta}
          </Stack>
          <IntercomFooter />
        </Box>
      </Container>
    </Box>
  );
};

export const VoyagerMobileApp: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <DownloadMobileApp
      featureImage={voygerFeatureImage}
      cta={
        <Button
          variant="primary"
          size="lg"
          aria-label={AccessibilityLabel.DASHBOARD}
          trackingProperties={{
            name: 'voyager_mobile_app_view_dashboard',
          }}
          onClick={(): Promise<void> => navigate(Routes.VOYAGER_DASHBOARD)}
        >
          <Inline alignY="center" spaceX="xxs">
            <span>View my dashboard</span>
            <Box marginTop="xxxs">
              <SpaceshipIcon color="neutral.000" size="lg" />
            </Box>
          </Inline>
        </Button>
      }
    >
      <VoyagerMobileAppPromotion
        variant="login"
        theme="dark"
        size="lg"
        isStacked={true}
        appleAppStoreUrl={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
        googlePlayStoreUrl={
          appStoreLinksResp.data?.googlePlayStoreDeeplink?.url
        }
      />
    </DownloadMobileApp>
  );
};

export const SuperMobileApp: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <DownloadMobileApp
      featureImage={superFeatureImage}
      cta={
        <Button
          variant="primary"
          size="lg"
          aria-label={AccessibilityLabel.DASHBOARD}
          trackingProperties={{
            name: 'super_mobile_app_view_dashboard',
          }}
          onClick={(): Promise<void> => navigate(Routes.SUPER_DASHBOARD)}
        >
          <Inline alignY="center" spaceX="xxs">
            <span>View my dashboard</span>
            <Box marginTop="xxxs">
              <SpaceshipIcon color="neutral.000" size="lg" />
            </Box>
          </Inline>
        </Button>
      }
    >
      <SuperMobileAppPromotion
        variant="login"
        theme="dark"
        size="lg"
        isStacked={true}
        appleAppStoreUrl={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
        googlePlayStoreUrl={
          appStoreLinksResp.data?.googlePlayStoreDeeplink?.url
        }
      />
    </DownloadMobileApp>
  );
};

export interface SuperComingSoonProps {
  variant?: SuperMobileAppPromotionVariant;
}

export const SuperComingSoon: React.FC<
  React.PropsWithChildren<RouteComponentProps<SuperComingSoonProps>>
> = () => {
  const { variant = 'login' }: SuperComingSoonProps = useParams();
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <DownloadMobileApp
      featureImage={superFeatureImage}
      cta={
        <Button
          aria-label={AccessibilityLabel.GO_BACK}
          trackingProperties={{
            name: 'super_mobile_app_go_back',
          }}
          onClick={(): void => window.history.back()}
          variant="tertiary"
          size="lg"
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <Box marginRight="xxs">
              <FeatherArrowLeftIcon />
            </Box>
            Go back
          </Box>
        </Button>
      }
    >
      <SuperMobileAppPromotion
        variant={variant}
        theme="dark"
        size="lg"
        isStacked={true}
        isInDevelopment={true}
        appleAppStoreUrl={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
        googlePlayStoreUrl={
          appStoreLinksResp.data?.googlePlayStoreDeeplink?.url
        }
      />
    </DownloadMobileApp>
  );
};

export const VoyagerComingSoon: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <DownloadMobileApp
      featureImage={voygerFeatureImage}
      cta={
        <Button
          aria-label={AccessibilityLabel.GO_BACK}
          trackingProperties={{
            name: 'voyager_mobile_app_go_back',
          }}
          onClick={(): void => window.history.back()}
          variant="tertiary"
          size="lg"
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <Box marginRight="xxs">
              <FeatherArrowLeftIcon />
            </Box>
            Go back
          </Box>
        </Button>
      }
    >
      <VoyagerMobileAppPromotion
        variant="login"
        theme="dark"
        size="lg"
        isStacked={true}
        isInDevelopment={true}
        appleAppStoreUrl={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
        googlePlayStoreUrl={
          appStoreLinksResp.data?.googlePlayStoreDeeplink?.url
        }
      />
    </DownloadMobileApp>
  );
};

const StyledFeatureImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 100%;
`;
