import {
  Box,
  Columns,
  Heading,
  Pill,
  PresentationLink,
  Text,
} from '@spaceship-fspl/components';
import { PayToAgreementStatus } from '@spaceship-fspl/graphql';
import { formatCurrency, maskBankAccountNumber } from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  BankAccountStatus,
  IBankAccount,
} from '@spaceship-fspl/types/externalapi';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import { Routes } from 'pages/routes';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardEditProps,
  AccountCardHeading,
} from './components';

// TODO: Remove once onboarding is migrated to the graph
export const OldVoyagerBankAccountCard: React.FC<
  React.PropsWithChildren<{
    variant: 'account' | 'signup_summary';
    bankAccount?: IBankAccount;
    edit?: AccountCardEditProps;
    isReadOnly?: boolean;
  }>
> = ({ bankAccount, ...props }) => {
  const activeBankAccount = bankAccount && {
    id: bankAccount?.id ?? '',
    friendlyName: bankAccount?.friendly_name ?? '',
    bsb: bankAccount?.bsb ?? '',
    accountNumber: bankAccount?.account_number ?? '',
    accountName: bankAccount?.account_name ?? '',
    verified:
      bankAccount?.status !== BankAccountStatus.Enum.VERIFICATION_REQUIRED,
  };
  return <VoyagerBankAccountCard {...props} bankAccount={activeBankAccount} />;
};

export const VoyagerBankAccountCard: React.FC<
  React.PropsWithChildren<{
    variant: 'account' | 'signup_summary';
    bankAccount?: {
      id: string;
      verified: boolean;
      friendlyName: string;
      accountName: string;
      bsb: string;
      accountNumber: string;
      payToAgreement?: {
        id: string;
        status: PayToAgreementStatus;
        maxAudAmount: string | null;
      } | null;
    } | null;
    edit?: AccountCardEditProps;
    isReadOnly?: boolean;
  }>
> = ({ variant, bankAccount, edit, isReadOnly }) => {
  const payToAgreement = bankAccount?.payToAgreement;
  const activePayToAgreement =
    payToAgreement?.status === PayToAgreementStatus.ACTIVE;

  return (
    <AccountCard>
      <AccountCardHeading
        title="Linked bank account"
        edit={
          edit
            ? {
                ...edit,
                editTrackingProperties: edit.editTrackingProperties || {
                  name: `${variant}_bank_account_card_edit`,
                },
              }
            : undefined
        }
        isReadOnly={isReadOnly || edit?.canUpdateBankAccount === false}
      />

      {!bankAccount ? (
        <AccountCardContent isEmptyContent={true}>
          <Text variant={2} color="neutral.080" isBold={true}>
            Link your bank account to start investing
          </Text>
          <RouterLink
            to={Routes.ACCOUNT_BANK_ACCOUNT}
            trackingProperties={{
              name: `${variant}_bank_account_card_link`,
            }}
          >
            <PresentationLink
              size="sm"
              color="indigo.070"
              icon="chevron"
              component="span"
            >
              Link bank account
            </PresentationLink>
          </RouterLink>
        </AccountCardContent>
      ) : (
        <AccountCardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="sm"
          >
            <Heading variant={5} isBold={true}>
              {bankAccount.friendlyName}
            </Heading>

            {bankAccount?.verified ? (
              <Pill variant="success">Active</Pill>
            ) : (
              <Pill variant="info">Requires verification</Pill>
            )}
          </Box>

          <Box marginBottom="sm">
            <Columns spaceX="lg" spaceY="sm" className="fs-block">
              <Columns.Column width={{ xs: 1, md: 'max' }}>
                <LabeledField label="Account holder" size="md">
                  {bankAccount.accountName}
                </LabeledField>
              </Columns.Column>

              <Columns.Column width="min">
                <LabeledField label="BSB" size="md">
                  {bankAccount.bsb}
                </LabeledField>
              </Columns.Column>

              <Columns.Column width="min">
                <LabeledField label="Account number" size="md">
                  {maskBankAccountNumber(bankAccount.accountNumber ?? '')}
                </LabeledField>
              </Columns.Column>
            </Columns>
          </Box>

          {edit?.isLoading === false &&
            edit?.canUpdateBankAccount === false && (
              <Box
                backgroundColor="neutral.015"
                borderRadius="sm"
                padding={{ xs: 'sm', md: 'md' }}
                marginBottom="sm"
              >
                <Box display="flex">
                  <Box marginRight="xs" lineHeight={0}>
                    <FeatherInfoIcon color="neutral.080" />
                  </Box>
                  <Text variant={3} color="neutral.085">
                    For security purposes you are currently unable to link a new
                    bank account but you can continue to make investments and
                    withdrawals.
                  </Text>
                </Box>
              </Box>
            )}

          <Box marginBottom="sm">
            <Columns spaceX="lg" spaceY="sm" className="fs-block">
              <Columns.Column width="min">
                <LabeledField label="Payments to Spaceship" size="md">
                  {activePayToAgreement
                    ? 'PayTo + Direct debit'
                    : 'Direct debit'}
                </LabeledField>
              </Columns.Column>

              <Columns.Column width="min">
                <LabeledField label="Speed" size="md">
                  {activePayToAgreement ? 'Fastest' : 'Standard (2 days)'}
                </LabeledField>
              </Columns.Column>
            </Columns>
          </Box>

          {activePayToAgreement ? (
            <Text variant={3} color="neutral.080">
              {`Spaceship will use direct debit if the amount is more than ${formatCurrency(payToAgreement?.maxAudAmount)} or if PayTo is unavailable.`}
            </Text>
          ) : null}
        </AccountCardContent>
      )}
    </AccountCard>
  );
};
