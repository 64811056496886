import * as React from 'react';
export const StreamlineColorPalette = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M5.4 8.35a6.6 6.6 0 1013.2 0 6.6 6.6 0 00-13.2 0v0z" />
    <path vectorEffect="non-scaling-stroke" d="M1 15.683a6.6 6.6 0 1013.2 0 6.6 6.6 0 00-13.2 0v0z" />
    <path vectorEffect="non-scaling-stroke" d="M9.8 15.683a6.601 6.601 0 1013.202 0 6.601 6.601 0 00-13.202 0v0z" />
  </svg>
);
