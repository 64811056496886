import * as React from 'react';
export const StreamlineCheckCircle1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M6.133 13.197l2.396 3.4a1.025 1.025 0 001.669.05l7.669-9.703" />
    <path vectorEffect="non-scaling-stroke" d="M1 12a11 11 0 1022 0 11 11 0 00-22 0z" />
  </svg>
);
