import { css, FlattenSimpleInterpolation } from 'styled-components';

export const visuallyHidden = (): FlattenSimpleInterpolation => css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;
