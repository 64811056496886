import * as React from 'react';
export const CloudEmpty = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_1_32)">
      <path vectorEffect="non-scaling-stroke" d="M23 12a3.835 3.835 0 00-2.41-3.558 3.832 3.832 0 00-.804-4.228 3.831 3.831 0 00-4.228-.804A3.826 3.826 0 0012 1a3.835 3.835 0 00-3.558 2.41 3.832 3.832 0 00-4.228.804 3.832 3.832 0 00-.804 4.228A3.825 3.825 0 001 12c0 1.569.957 2.975 2.41 3.558a3.832 3.832 0 00.804 4.228 3.831 3.831 0 004.228.803A3.825 3.825 0 0012 23a3.835 3.835 0 003.558-2.41 3.831 3.831 0 004.228-.804 3.832 3.832 0 00.803-4.228A3.835 3.835 0 0023 12z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_1_32">
        <path vectorEffect="non-scaling-stroke" fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
