import { rgba } from './styling';

export const shadows = {
  none: 'none',
  sm: `0px 2px 6px ${rgba('neutral.100', 0.12)}, 0px -1px 5px ${rgba(
    'neutral.100',
    0.04,
  )}`,
  md: `1px 4px 24px ${rgba('neutral.100', 0.12)}, 0px 4px 8px ${rgba(
    'neutral.100',
    0.08,
  )}`,
  lg: `2px 24px 36px ${rgba('neutral.100', 0.16)}, 0px 0px 8px ${rgba(
    'neutral.100',
    0.06,
  )}`,
};

export const boxShadow = (size: keyof typeof shadows): string =>
  `box-shadow: ${shadows[size]};`;
