import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { FeatherHelpCircleIcon } from '@spaceship-fspl/icons-web';
import { useTrack } from '@spaceship-fspl/tracking';
import { FilterItem, FilterSelect } from 'components/filter-select';
import { PageContainer } from 'components/layouts/page';
import {
  useBankAccountSubmissionContext,
  useBankAccountVerificationContext,
} from 'contexts/bank-account-verification';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { Institution } from 'helpers/bank-statements-api';
import React, { useEffect } from 'react';

import { Routes } from './routes';

const defaultBanks = ['cba', 'anz', 'nab', 'ing', 'westpac'];

export const BankAccount: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const track = useTrack();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.replace(/\/+$/, '');
  const { allBanks, setSelectedBank } = useBankAccountVerificationContext();
  const { variant } = useBankAccountSubmissionContext();

  useEffect(() => {
    if (variant === 'onboarding') {
      track?.(
        MarketingTrackingEvents.VOYAGER_ONBOARDING_LINK_BANK_SELECT_SCREEN,
      );
    }
  }, [track, variant]);

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <Stack spaceY="sm">
            <Heading
              component="h1"
              variant={3}
              align={{ xs: 'left', md: 'center' }}
            >
              Link bank account
            </Heading>
            <Text
              variant={2}
              color="neutral.080"
              align={{ xs: 'left', md: 'center' }}
            >
              To start investing, you need to link a bank account.
            </Text>
            <FilterSelect
              placeholder="Find your bank"
              data={allBanks}
              defaultFilter={(banks: Institution[]): Institution[] =>
                banks.filter((b) => defaultBanks.includes(b?.slug || ''))
              }
              renderItem={({ name }: Institution): React.ReactNode => (
                <Text variant={2} isBold={true}>
                  {name}
                </Text>
              )}
              searchKey={({ name }: Institution): string => name || ''}
              onSelect={(bank: Institution): void => {
                setSelectedBank(bank);
                navigate(`${pathname}/${Routes.BANK_ACCOUNT_SELECT_METHOD}`);
              }}
              footerItem={
                <FilterItem
                  onClick={() => {
                    navigate(`${pathname}/${Routes.BANK_ACCOUNT_MANUAL}`);
                  }}
                >
                  <Inline alignY="center">
                    <Box marginRight="xxs">
                      <FeatherHelpCircleIcon size="md" color="indigo.070" />
                    </Box>
                    <Text
                      component="span"
                      variant={2}
                      isBold={true}
                      color="indigo.070"
                    >
                      Can&apos;t find my bank
                    </Text>
                  </Inline>
                </FilterItem>
              }
            />
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
