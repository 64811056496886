import {
  Box,
  Card,
  Heading,
  PresentationLink,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { FeatherXIcon } from '@spaceship-fspl/icons-web';
import {
  fontSize,
  getSpace,
  lineHeight,
  match,
  padding,
  transition,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import superReferralsDashboardImage from 'assets/super-referrals-dashboard.png';
import { RouterLink } from 'components/router-link';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { useDismissed } from 'helpers/hooks/dismissed';
import { useIsEligibleForSuperReferrals } from 'helpers/hooks/use-is-eligible-for-super-referrals';
import { PersistKey } from 'helpers/persist';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

const TIME_TO_DISMISS_MS = 1000 * 60 * 60 * 24 * 30; // 30 days

export const ReferralsCard: React.FC<React.PropsWithChildren> = () => {
  const track = useTrack();
  const canRefer = useIsEligibleForSuperReferrals();
  const [isDismissed, dismiss] = useDismissed(
    PersistKey.SUPER_REFERRALS_CARD_DISMISSED_UNTIL,
    TIME_TO_DISMISS_MS,
  );

  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;

  if (isDismissed || !canRefer || sftLimitedServicePeriodEnabled) {
    return null;
  }

  return (
    <StyledCard>
      <StyledDismissButton onClick={dismiss}>
        <FeatherXIcon color="neutral.000" />
      </StyledDismissButton>

      <Box
        padding="lg"
        width={{
          md: 'calc(100% - 250px)',
          lg: 'calc(100% - 380px)',
          xl: 'calc(100% - 450px)',
        }}
        minWidth={{ md: 280, lg: 360 }}
      >
        <Stack spaceY="xs">
          <StyledHeading>
            Share your love for Spaceship Super with friends
          </StyledHeading>

          <Stack spaceY="sm" alignX={{ xs: 'center', md: 'left' }}>
            <Text
              variant={2}
              color="neutral.050"
              align={{ xs: 'center', md: 'left' }}
            >
              Tell your friends about Spaceship Super and help spread the word
              about the super fund investing where the world is going.
            </Text>

            <RouterLink
              to={Routes.SUPER_REFERRAL_SHARE_CODE}
              trackingProperties={{
                name: 'super_referrals_card_share_code',
              }}
              onClick={() =>
                track?.(
                  MarketingTrackingEvents.SUPER_REFERRALS_DASHBOARD_CARD_CLICK,
                )
              }
            >
              <PresentationLink
                size="sm"
                color="neutral.000"
                icon="arrow"
                component="span"
              >
                Share Spaceship Super
              </PresentationLink>
            </RouterLink>
          </Stack>
        </Stack>
      </Box>
    </StyledCard>
  );
};

const StyledCard = styled(Card).attrs({
  backgroundColor: 'neutral.100',
  position: 'relative',
  marginBottom: 'md',
})`
  background-image: url(${superReferralsDashboardImage});
  background-repeat: no-repeat;
  background-size: 340px;
  background-position: top center;
  padding-top: 120px;

  ${match('sm')`
    background-size: 420px;
    padding-top: 180px;
  `}

  ${match('md')`
    background-size: 380px;
    background-position: calc(100% + ${getSpace('xl')}) center;
    padding-top: 0;
  `}

  ${match('lg')`
    background-size: 580px;
    background-position: calc(100% + ${getSpace('xl')}) -${getSpace('lg')};
  `}

  ${match('xl')`
    background-size: auto;
  `}
`;

const StyledHeading = styled(Heading).attrs({
  variant: 4,
  color: 'neutral.000',
  isBold: true,
  align: { xs: 'center', md: 'left' },
})`
  ${fontSize({ lg: '24px' })}
  ${lineHeight({ lg: '36px' })}
`;

const StyledDismissButton = styled(UnstyledButton)`
  ${padding('sm')}
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.4;
  ${transition}

  :hover {
    opacity: 1;
  }
`;
