import { RouteComponentProps, useNavigate } from '@reach/router';
import { Stack } from '@spaceship-fspl/components';
import { useGetUser, useSetGreenIdFields } from '@spaceship-fspl/data';
import {
  GreenIdTermsAndCondition,
  IDName,
  idSourceProtosToType,
  MEDICARE_CARD_TYPES,
  MedicareCardFields,
  MedicareCardType,
  medicareCardValidations,
  useID,
} from '@spaceship-fspl/green-id';
import { formatDate } from '@spaceship-fspl/helpers';
import { GreenIdSourceName } from '@spaceship-fspl/types/externalapi';
import blueMedicareCardImage from 'assets/greenid_medicare_blue@2x.png';
import greenMedicareCardImage from 'assets/greenid_medicare_green@2x.png';
import yellowMedicareCardImage from 'assets/greenid_medicare_yellow@2x.png';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import { useGreenID } from 'contexts/greenid';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React from 'react';
import { useForm } from 'react-hook-form';

import { NO_USER_VERIFICATION_ERROR, useReverifyAndNavigate } from './hooks';
import { FormLayout } from './layout';

const GreenIDImages: Record<MedicareCardType, string> = {
  [MedicareCardType.AUSTRALIAN_CITIZEN_CARD]: greenMedicareCardImage,
  [MedicareCardType.INTERIM_MEDICARE_CARD]: blueMedicareCardImage,
  [MedicareCardType.RECIPROCAL_HEALTH_CARE_CARD]: yellowMedicareCardImage,
};

export const MedicareCard: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const idName = IDName.MEDICARE_CARD;
  const { getUploadLinkBySourceName, ruleset } = useGreenID();
  const { mutateAsync: postGreenIDFields, isLoading } =
    useSetGreenIdFields(ruleset);
  const verificationId = useID(ruleset);
  const { popToast } = useNotifications();
  const { pop: showIntercom } = useIntercom();
  const reverifyAndNavigate = useReverifyAndNavigate(idName);
  const { data: user } = useGetUser();
  const contact = user?.contact;

  const { control, register, watch, handleSubmit } =
    useForm<MedicareCardFields>({
      defaultValues: {
        greenid_medicaredvs_cardColour:
          MedicareCardType.AUSTRALIAN_CITIZEN_CARD,
        greenid_medicaredvs_number: '',
        greenid_medicaredvs_individualReferenceNumber: '',
        greenid_medicaredvs_expiry: '',
        greenid_medicaredvs_nameOnCard:
          contact?.first_name && contact?.last_name
            ? `${contact.first_name} ${contact.last_name}`
            : '',
        greenid_medicaredvs_dob: contact?.date_of_birth
          ? formatDate(new Date(contact?.date_of_birth))
          : '',
        greenid_medicaredvs_tandc: GreenIdTermsAndCondition.ON,
      },
    });

  const selectedMedicareCard = MEDICARE_CARD_TYPES.find(
    ({ value }) => value === watch('greenid_medicaredvs_cardColour'),
  );

  const isGreenMedicareCard =
    selectedMedicareCard?.value === MedicareCardType.AUSTRALIAN_CITIZEN_CARD;

  const onSubmit = async (values: MedicareCardFields): Promise<void> => {
    const sourceName = idSourceProtosToType(GreenIdSourceName.Enum.MEDICAREDVS);
    try {
      await postGreenIDFields({
        verification_id: verificationId,
        source_name: sourceName,
        field_inputs: {
          ...values,
          greenid_medicaredvs_tandc: GreenIdTermsAndCondition.ON,
        },
      });
      await reverifyAndNavigate();
    } catch (error) {
      if (
        error instanceof TypeError ||
        (error as Error | undefined)?.message.includes(
          NO_USER_VERIFICATION_ERROR,
        )
      ) {
        // Network error
        popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
        showIntercom();
      } else if (sourceName) {
        // API error
        navigate?.(getUploadLinkBySourceName(sourceName), {
          replace: true,
        });
      }
    }
  };

  return (
    <FormLayout
      title={idName}
      idName={idName}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      image={{
        url: selectedMedicareCard
          ? GreenIDImages[selectedMedicareCard.value]
          : '',
        alt: selectedMedicareCard?.label || '',
      }}
    >
      <Stack spaceY="sm">
        <Dropdown
          placeholder="Card type"
          options={MEDICARE_CARD_TYPES.map(({ label, value }) => ({
            label,
            value,
          }))}
          {...register('greenid_medicaredvs_cardColour')}
        />
        <ControllerInputWithInlineError
          name="greenid_medicaredvs_number"
          control={control}
          placeholder="Card number"
          type="number"
          rules={medicareCardValidations.cardNumber}
        />
        <ControllerInputWithInlineError
          name="greenid_medicaredvs_nameOnCard"
          control={control}
          placeholder="Name (exactly as shown)"
          type="text"
          maxLength={27}
          rules={medicareCardValidations.name}
        />
        <ControllerInputWithInlineError
          name="greenid_medicaredvs_dob"
          control={control}
          placeholder="Date of birth"
          type="text"
          format="date"
          dateFormatOption={{
            delimiter: '/',
          }}
          rules={medicareCardValidations.dateOfBirth}
        />
        <ControllerInputWithInlineError
          name="greenid_medicaredvs_individualReferenceNumber"
          control={control}
          placeholder="Reference number"
          type="text"
          maxLength={1}
          rules={medicareCardValidations.referenceNumber}
        />
        <ControllerInputWithInlineError
          name="greenid_medicaredvs_expiry"
          control={control}
          type="text"
          format="date"
          dateFormatOption={{
            datePattern: isGreenMedicareCard ? ['m', 'Y'] : ['d', 'm', 'Y'],
            delimiter: '/',
          }}
          placeholder={`Expiry date ${
            isGreenMedicareCard ? '(MM/YYYY)' : '(DD/MM/YYYY)'
          }`}
          rules={{
            ...medicareCardValidations.expiry,
            pattern:
              medicareCardValidations.expiry.pattern(isGreenMedicareCard),
            validate: (value: string) =>
              medicareCardValidations.expiry.validate(
                value,
                isGreenMedicareCard,
              ),
          }}
        />
      </Stack>
    </FormLayout>
  );
};
