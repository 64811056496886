import { Box, Stack } from '@spaceship-fspl/components';
import { FeatherLockIcon } from '@spaceship-fspl/icons-web';
import {
  Color,
  color,
  FlattenInterpolationWithTheme,
  fontSize,
  fontWeight,
  getColor,
  getSpace,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  text,
  transition,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { TrackingEvent } from 'helpers/analytics';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { InlineError } from './inline-error';
import { inputStyles } from './input';

export interface TextAreaProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'ref' | 'as'> {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<React.PropsWithChildren<TextAreaProps>> = ({
  onFocus,
  name,
  disabled = false,
  readOnly = false,
  placeholder,
  errorMessage,
  ...props
}) => {
  const growContainerRef = useRef<HTMLDivElement>(null);
  const track = useTrack();
  const id = props.id ?? `input_${name}`;

  return (
    <Stack spaceY="xxxs">
      <StyledContainerBox
        hasError={!!errorMessage && !disabled}
        hasSuffix={readOnly}
      >
        <StyledGrowContainer
          ref={growContainerRef}
          $hasPlaceholder={!!placeholder}
        >
          <StyledTextArea
            {...props}
            id={id}
            name={name}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            onFocus={(e: React.FocusEvent<HTMLTextAreaElement>): void => {
              track?.(TrackingEvent.FOCUS_INPUT, { properties: { name } });
              if (onFocus) {
                onFocus(e);
              }
            }}
            onInput={(e) => {
              if (growContainerRef.current) {
                growContainerRef.current.dataset.replicatedValue =
                  e.currentTarget.value;
              }
              props.onInput?.(e);
            }}
          />

          {placeholder && <StyledLabel htmlFor={id}>{placeholder}</StyledLabel>}
        </StyledGrowContainer>

        {readOnly && (
          <StyledSuffixContainerBox>
            <FeatherLockIcon size="sm" color="neutral.080" />
          </StyledSuffixContainerBox>
        )}
      </StyledContainerBox>

      {errorMessage && <InlineError message={errorMessage} isBold={true} />}
    </Stack>
  );
};

const StyledSuffixContainerBox = styled(Box).attrs({
  position: 'absolute',
  right: getSpace('sm'),
  top: '50%',
})`
  transform: translateY(-50%);
`;

export const focusedLabelStyle = css`
  ${fontSize('12px')}
`;

export const StyledLabel = styled.label`
  ${color('neutral.080')}
  ${text({ variant: 2 })}
  ${fontWeight(600)}
  ${paddingLeft('sm')}
  line-height: 1em;
  pointer-events: none;
  position: absolute;
  top: 14px;
  ${transition}
`;

export const inputShadowStyle = ({
  color,
  isThick,
}: {
  color: Color;
  isThick?: boolean;
}): FlattenInterpolationWithTheme => css`
  box-shadow: inset 0px 0px 0px ${isThick ? 2 : 1}px ${getColor(color)};
`;

const StyledGrowContainer = styled.div<{
  $hasPlaceholder: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(2, minmax(100%, 100%));
  ${text({ variant: 2 })}
  ${fontWeight(600)}

  ::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    word-break: break-word;
    visibility: hidden;
    grid-area: 1 / 1 / 2 / 2;

    // replicate exact styles from input styles
    ${paddingX('sm')}
    border: none;
    box-sizing: border-box;
    outline: 0;
    min-height: 100px;

    ${({ $hasPlaceholder: hasPlaceholder }) => {
      if (hasPlaceholder) {
        return css`
          ${paddingTop('lg')}
          ${paddingBottom('xs')}
        `;
      }

      return css`
        ${paddingY('xs')}
      `;
    }}
  }
`;

const StyledTextArea = styled.textarea`
  ${({ readOnly }) => inputStyles(readOnly)}
  height: auto;
  min-height: 100px;
  resize: none;
  overflow: hidden;
  grid-area: 1 / 1 / 2 / 2;

  ${({ placeholder }) => {
    if (!placeholder) {
      return css`
        &&,
        &&:not(:placeholder-shown) {
          ${paddingY('xs')}
        }
      `;
    }

    return css`
      &&,
      &&:not(:placeholder-shown) {
        ${paddingTop('lg')}
        ${paddingBottom('xs')}
      }
    `;
  }}
`;

const StyledContainerBox = styled(Box).attrs({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
})<{
  hasError: boolean;
  hasSuffix: boolean;
}>`
  ${({ hasSuffix }) =>
    hasSuffix
      ? css`
          ${StyledLabel}, ${StyledTextArea} {
            ${paddingRight('xl')}
          }
        `
      : ''}

  // IMPORTANT: Separate targeting definitions is required for Firefox
  ${StyledTextArea}:not(:placeholder-shown) + ${StyledLabel} {
    ${focusedLabelStyle}
  }

  ${StyledTextArea}:-webkit-autofill + ${StyledLabel} {
    ${focusedLabelStyle}
  }

  ${({ hasError }) => {
    const ua = window.navigator.userAgent;
    const isIE =
      ua.indexOf('Edge') > 0 ||
      ua.indexOf('MSIE ') > 0 ||
      !!ua.match(/Trident.*rv:11\./);

    return hasError || isIE
      ? css`
          ${StyledLabel} {
            ${focusedLabelStyle}
          }

          ${StyledTextArea} {
            ${paddingTop('md')}
          }
        `
      : '';
  }}

  ${({ hasError }) =>
    hasError
      ? css`
          ${StyledLabel} {
            ${color('red.100')}
          }

          ${StyledTextArea} {
            &,
            :not(:disabled, :read-only):hover,
            :not(:disabled, :read-only):focus {
              ${inputShadowStyle({ color: 'red.100', isThick: true })}
            }
          }
        `
      : ''}
`;
