import { navigate, RouteComponentProps } from '@reach/router';
import { SuperEmployerContributionShareOption as ShareOption } from '@spaceship-fspl/super';
import { Routes } from 'pages/routes';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

export type SuperEmployerContributionPageVariant = 'onboarding' | 'loggedin';

export interface SuperEmployerContributionForm {
  shareOption: ShareOption;
  employerEmail?: string;
}

export interface SuperEmployerContributionProps {
  variant: SuperEmployerContributionPageVariant;
  shareOption?: ShareOption;
  setShareOption: Dispatch<SetStateAction<ShareOption | undefined>>;
  onSubmitSuccess: () => void;
  onComplete: () => void;
  onSkip?: () => void;
}

const SuperEmployerContributionContext = createContext<
  SuperEmployerContributionProps | undefined
>(undefined);

export const SuperOnboardingEmployerContributionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [shareOption, setShareOption] = useState<ShareOption | undefined>();

  const onSubmitSuccess = (): void => {
    navigate(Routes.SUPER_SIGNUP_EMPLOYER_CONTRIBUTION_SUCCESS);
  };

  const onComplete = (): void => {
    navigate(Routes.SUPER_SIGNUP_MOBILE_APPS);
  };

  return (
    <SuperEmployerContributionContext.Provider
      value={{
        variant: 'onboarding',
        shareOption,
        setShareOption,
        onSubmitSuccess,
        onComplete,
        onSkip: onComplete,
      }}
    >
      {children}
    </SuperEmployerContributionContext.Provider>
  );
};

export const SuperEmployerContributionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [shareOption, setShareOption] = useState<ShareOption | undefined>();

  const onSubmitSuccess = (): void => {
    navigate(Routes.SUPER_CONTRIBUTIONS_EMPLOYER_SUCCESS);
  };

  const onComplete = (): void => {
    navigate(Routes.ACCOUNT_SUPER_DETAILS);
  };

  return (
    <SuperEmployerContributionContext.Provider
      value={{
        variant: 'loggedin',
        shareOption,
        setShareOption,
        onSubmitSuccess,
        onComplete,
      }}
    >
      {children}
    </SuperEmployerContributionContext.Provider>
  );
};

export const useEmployerContributionContext =
  (): SuperEmployerContributionProps => {
    const context = React.useContext(SuperEmployerContributionContext);

    if (!context) {
      throw new Error(
        'webapp: Please wrap in <SuperEmployerContributionProvider />',
      );
    }

    return context;
  };
