import { useGoogleMapsServices } from 'contexts/googlemaps';
import { useEffect, useState } from 'react';

interface GetSuggestedPlacesByTextResponse {
  predictions: google.maps.places.AutocompletePrediction[];
  status: google.maps.places.PlacesServiceStatus;
}

export const useGetAutocompletePredictions = (
  inputText?: string,
): {
  data: GetSuggestedPlacesByTextResponse | undefined;
  isError: boolean;
  isLoading: boolean;
} => {
  const { autoCompleteService, sessionToken, countryRestrictions } =
    useGoogleMapsServices();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<
    GetSuggestedPlacesByTextResponse | undefined
  >();

  useEffect(() => {
    const getPlacePredictions = (
      input: string,
    ): Promise<GetSuggestedPlacesByTextResponse> => {
      return new Promise<GetSuggestedPlacesByTextResponse>(
        (resolve, reject) => {
          if (!autoCompleteService?.getPlacePredictions) {
            reject(
              'google.maps.places.autoCompleteService: Google script not loaded',
            );
          }

          if (!sessionToken) {
            reject(
              'google.maps.places.autocompleteSessionToken: Google script not loaded',
            );
          }

          try {
            autoCompleteService?.getPlacePredictions(
              {
                input,
                types: ['address'],
                componentRestrictions: countryRestrictions
                  ? {
                      country: countryRestrictions,
                    }
                  : undefined,
                sessionToken: sessionToken,
              },
              (predictions, status) => {
                if (status === 'OK') {
                  resolve({ predictions, status });
                } else {
                  reject(
                    new Error(
                      `Failed to get suggested places. Status: ${status} - ${predictions}`,
                    ),
                  );
                }
              },
            );
          } catch (err) {
            reject(err);
          }
        },
      );
    };

    const getSuggestedPlacesByText = async (
      inputText: string,
    ): Promise<void> => {
      setIsLoading(true);
      try {
        const data = await getPlacePredictions(inputText);
        setData(data);
        setIsError(false);
      } catch {
        setData(undefined);
        setIsError(true);
      }
      setIsLoading(false);
    };

    if (inputText) {
      getSuggestedPlacesByText(inputText);
    }
    // @see https://github.com/spaceship-fspl/ui.spaceship/pull/988/files#r579974622
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText]);

  return { isLoading, isError, data };
};
