import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import {
  ExternalRoutes,
  formatCurrency,
  InternalRoutes,
} from '@spaceship-fspl/helpers';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import React from 'react';

export const BoostsTermsAndConditions: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const boostItemGroupAudLockMinimumThreshold = formatCurrency(
    useString(
      DynamicConfigKeys.BOOST_ITEM_GROUP_AUD_LOCK_MINIMUM_THRESHOLD,
      '10',
    ),
  );

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 2 / 3 }}>
          <Box marginBottom={{ xs: 'sm', md: 'md' }}>
            <PageHeading title="Boosts Terms and Conditions" />
          </Box>

          <Stack spaceY="md">
            <Heading variant={4} isBold={true}>
              Terms and conditions for processing your Boost investments
            </Heading>
            <Text variant={2}>
              Your Boost investments are typically submitted for processing the
              business day following reaching a minimum threshold of{' '}
              {boostItemGroupAudLockMinimumThreshold}. For example, if you’ve
              chosen the Round Ups boost, we’ll wait until the accumulated value
              of your rounded up transactions reaches{' '}
              {boostItemGroupAudLockMinimumThreshold} before processing.
              <br />
              <br />
              The normal process for making an investment as outlined in the
              relevant product disclosure statement applies.
              <br />
              <br />
              Your tracking account transaction information will generally
              update once a day. Therefore, there may be a delay between the
              time you make a transaction and when your boost information is
              updated. We’ve taken reasonable care in the design of the Boosts
              functionality however do not guarantee the identification and
              categorisation of a transaction. For example, a miscategorised
              transaction could fail to trigger a boost or trigger a boost.
              <br />
              <br />
              You can cancel pending boost investments via the Spaceship app.
              However, they cannot be cancelled once submitted for processing.
              <br />
              <br />
              Your boost may be cancelled without notice, for example, where a
              related direct debit is dishonored. You will need to re-add your
              boost where it is cancelled.
            </Text>

            <Heading variant={4} isBold={true}>
              Terms and conditions for collecting your transaction information
            </Heading>
            <Text variant={2} component="div">
              By using Boosts, you agree to us collecting and using your
              personal information, including bank transaction information, as
              described in our{' '}
              <TextLink
                href={InternalRoutes.PRIVACY_POLICY}
                target="_blank"
                color="indigo.070"
              >
                Privacy Collection Notice
              </TextLink>
              .
              <br />
              <br />
              We use a third-party service provider (Basiq) to obtain the
              transaction information required to enable you to use our Boosts
              feature.
              <br />
              <br />
              By using the Boosts feature, you authorise and appoint Spaceship
              and Basiq as your limited agent solely for the purpose of
              accessing third party data sources designated by you (your
              accounts) through such third party websites, applications and
              servers, and retrieving information and related documents{' '}
              <strong>(your data)</strong> from the designated accounts for the
              purposes of collecting and collating the data obtained. Spaceship
              and Basiq will use due skill and care in acting as limited agents.
              By using the Boosts feature, you license to Spaceship, and
              authorise Spaceship to grant Basiq a non-exclusive, royalty-free
              sub-license to authentication details and other information
              required for the access to your accounts.
              <br />
              <br />
              You agree that the account providers shall be entitled to rely on
              the foregoing authorisation granted and the appointment as agent.
              <br />
              <br />
              You warrant and agree that:
              <ul>
                <li>You are not requiring data for commercial use;</li>
                <li>
                  All information you provide or submits during your use of the
                  feature, is true, accurate, current and complete;
                </li>
                <li>
                  You possesses the legal authority to provide the information
                  (e.g. you must be the relevant account holder, or have
                  permission of joint account holders); and
                </li>
              </ul>
              You are responsible for updating and maintaining correct (a)
              information about yourself (including your personal details) and
              your accounts (including your access information), and (b)
              information that Basiq retrieves from your accounts at your
              request. You acknowledge that Basiq is acting as Spaceship’s
              subcontractor and that your sole recourse is under its agreement
              with Spaceship. Basiq is in no way responsible for your
              relationship with the providers of your accounts or Spaceship, or
              any of their products, services, acts or omissions. You release
              Basiq from all liability and will hold Basiq harmless against any
              and all claims resulting directly or indirectly from Basiq’s
              access to your accounts and the retrieval and collation of your
              data.
              <br />
              <br />
              To the extent you access the Basiq Website or Platform, please
              note the Basiq End User Terms available on Basiq’s Website.
              <br />
              <br />
              For information on Basiq’s privacy practices, please review
              Basiq’s privacy policy available on its website at{' '}
              <TextLink
                target="_blank"
                href={ExternalRoutes.BASIQ}
                color="indigo.070"
              >
                www.basiq.io
              </TextLink>
              .
            </Text>

            <Heading variant={4} isBold={true}>
              Boosts and the ePayments Code
            </Heading>
            <Text variant={2}>
              The ePayments Code is a code of practice that most banks have
              subscribed to. The ePayments Code protects you when making
              electronic payments such as by using internet banking. If your
              bank subscribes to the ePayments Code, you have rights and
              obligations in relation to sharing your internet banking details.
              <br />
              <br />
              By entering your internet banking details, you acknowledge that
              your rights under the ePayments Code may be affected and where an
              unauthorised transaction or fraudulent activity occurs, you may be
              liable for any loss that arises.
            </Text>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
