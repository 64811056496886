import { Box, Text } from '@spaceship-fspl/components';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import React from 'react';

interface InlineErrorProps {
  message: string;
  isBold?: boolean;
}

export const InlineError: React.FC<
  React.PropsWithChildren<InlineErrorProps>
> = ({ isBold, message }) => (
  <Box display="flex">
    <Box display="flex" alignItems="center" marginRight="xxs" height={22}>
      <FeatherAlertTriangleIcon
        size="sm"
        color="red.100"
        strokeWidth={isBold ? 2 : 1.5}
      />
    </Box>

    <Text color="red.100" variant={3} isBold={isBold}>
      {message}
    </Text>
  </Box>
);
