import * as React from 'react';
export const VoyagerUniverseStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12 2.1c-5.468 0-9.9 4.432-9.9 9.9 0 5.467 4.432 9.9 9.9 9.9 5.467 0 9.9-4.433 9.9-9.9 0-5.468-4.433-9.9-9.9-9.9zM.9 12C.9 5.868 5.87.9 12 .9S23.1 5.868 23.1 12c0 6.13-4.97 11.1-11.1 11.1S.9 18.13.9 12z" />
    <path d="M12 5.754a6.246 6.246 0 100 12.492 6.246 6.246 0 000-12.492zM4.554 12a7.446 7.446 0 1114.893 0 7.446 7.446 0 01-14.893 0z" />
    <path d="M15.828 11.998c0 .263-.838.427-.896.672-.057.254.625.764.516.991-.112.23-.94.014-1.098.213-.158.2.236.96.035 1.118-.202.159-.85-.394-1.084-.282-.233.112-.201.965-.452 1.023-.25.057-.59-.726-.853-.726-.262 0-.608.78-.853.726-.245-.055-.225-.914-.452-1.023-.228-.11-.885.44-1.084.282-.199-.158.196-.919.035-1.118-.162-.199-.986.018-1.098-.213-.112-.23.573-.74.516-.991-.058-.25-.896-.41-.896-.671 0-.263.838-.427.896-.672.057-.245-.625-.763-.516-.991.11-.228.94-.014 1.098-.213.158-.2-.236-.96-.035-1.118.202-.159.85.394 1.084.282.233-.112.201-.965.452-1.023.25-.057.59.726.853.726.262 0 .608-.78.853-.726.253.058.225.914.452 1.023.228.11.885-.44 1.084-.282.199.158-.196.919-.035 1.118.162.199.986-.018 1.098.213.112.23-.573.74-.516.991.058.25.896.41.896.671zm-3.783 2.113a2.175 2.175 0 100-4.35 2.175 2.175 0 000 4.35zm6.319-6.693a1.383 1.383 0 11-2.766-.001 1.383 1.383 0 012.766 0zm2.685 12.074a2.014 2.014 0 11-4.03 0 2.014 2.014 0 014.03 0zM3.397 22.181l-.498.205s-.208.09-.34.236c-.147.153-.237.392-.237.392l-.268.75-.273-.75a1.265 1.265 0 00-.237-.392 1.124 1.124 0 00-.34-.236L.7 22.18l.504-.204s.205-.09.34-.236c.147-.153.237-.392.237-.392l.268-.75.273.75c.023.066.116.268.237.392.132.146.34.236.34.236l.498.204zM1.529 2.026l-.265.132s-.11.058-.182.153a.856.856 0 00-.124.256l-.14.484-.145-.484a1 1 0 00-.124-.256C.48 2.219.37 2.16.368 2.158L.1 2.026l.268-.136s.11-.057.181-.15a.89.89 0 00.124-.256L.814 1l.144.484c.012.043.06.173.124.256a.64.64 0 00.182.15l.265.136zM23.96 5.409l-.364.181s-.15.078-.247.21c-.107.136-.17.35-.17.352l-.196.666-.2-.666a1.16 1.16 0 00-.17-.351c-.094-.127-.244-.208-.247-.21L22 5.408l.366-.185s.15-.077.248-.207c.106-.138.17-.349.17-.352L22.98 4l.198.665c.018.06.081.24.17.352a.925.925 0 00.248.207l.363.185z" />
  </svg>
);
