import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Heading,
  Inline,
  Spinner,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { WebAppSuperReferralShareCode } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperReferralShareCode';
import { WEBAPP_PATH } from '@spaceship-fspl/helpers';
import { FeatherCopyIcon } from '@spaceship-fspl/icons-web';
import {
  borderColor,
  borderWidth,
  fontFamily,
  match,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import superReferralsLGImage from 'assets/super-referrals-lg.png';
import superReferralsSMImage from 'assets/super-referrals-sm.png';
import { InlineError } from 'components/inline-error';
import { PageContainer } from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { useIsEligibleForSuperReferrals } from 'helpers/hooks/use-is-eligible-for-super-referrals';
import { copyTextToClipboard } from 'helpers/utils';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';
import styled from 'styled-components';

export const SuperReferralsShareCode: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const canRefer = useIsEligibleForSuperReferrals();
  const { popToast } = useNotifications();

  const resp = useQuery<WebAppSuperReferralShareCode>(gql`
    query WebAppSuperReferralShareCode {
      contact {
        id
        firstName
        account {
          id
          superProductInstance {
            id
            referralShareUrl
          }
        }
      }
    }
  `);
  const superProductInstance =
    resp?.data?.contact?.account?.superProductInstance;
  const referralLink =
    superProductInstance?.referralShareUrl ??
    `${WEBAPP_PATH}${Routes.SUPER_SIGNUP_REFERRED}`;
  const shareLink = referralLink ?? '';

  const copyReferralLink = async (): Promise<void> => {
    try {
      await copyTextToClipboard(
        `Hey. I’m using Spaceship Super and wanted to share it with you. It’s the super fund investing where the world is going. Refer to the PDS and TMD to see if it is right for you. You can check it out through this link: ${shareLink}`,
      );
      track?.(MarketingTrackingEvents.SUPER_REFERRALS_SHARE_CODE);
      popToast({
        level: 'success',
        message:
          'We’ve copied your Share Spaceship Super message to the clipboard.',
      });
    } catch {
      popToast({
        level: 'error',
        message:
          'Oops, there was a problem copying your Share Spaceship Super message to clipboard. Please try again.',
      });
    }
  };

  useEffect(() => {
    if (canRefer === false) {
      navigate?.(Routes.SUPER_DASHBOARD);
    }
  }, [canRefer, navigate]);

  if (!canRefer) {
    return null;
  }

  return (
    <StyledBackgroundBox>
      <PageContainer>
        <Columns>
          <Columns.Column width={{ lg: 1 / 2, xl: 2 / 5 }}>
            <Stack spaceY="md">
              <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
                <Heading
                  variant={{ xs: 3, lg: 1 }}
                  color="neutral.000"
                  component="h1"
                >
                  Share your love for Spaceship Super with friends
                </Heading>

                <Text variant={2} color="neutral.000">
                  Tell your friends about Spaceship Super and help spread the
                  word about the super fund investing where the world is going.
                  <br />
                  <br />
                  Competitive performance. Forward-thinking investment options.
                  Simple to join. Your friend&apos;s future self will thank you!
                </Text>
              </Stack>

              <Text variant={3} color="neutral.070">
                Note: This is not a promotional offer. No one likes spam, not
                even friends; please get permission before sharing!
              </Text>
            </Stack>

            <Card
              borderRadius="sm"
              padding={{ xs: 'md', lg: 'lg' }}
              marginTop={{ xs: 'md', lg: 'lg' }}
            >
              {referralLink ? (
                <Stack spaceY="md">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Heading
                      variant={5}
                      color="black.100"
                      isBold={true}
                      component="h3"
                    >
                      Sharing link
                    </Heading>

                    <UnstyledButton onClick={copyReferralLink}>
                      <Inline spaceX="xxs">
                        <FeatherCopyIcon color="neutral.100" />

                        <Text variant={3} color="neutral.100" isBold={true}>
                          Copy
                        </Text>
                      </Inline>
                    </UnstyledButton>
                  </Box>

                  <StyledCopyBox>
                    <StyledReferralLinkText>{shareLink}</StyledReferralLinkText>
                  </StyledCopyBox>
                </Stack>
              ) : resp.loading ? (
                <Box display="flex" justifyContent="center">
                  <Spinner />
                </Box>
              ) : (
                <InlineError message={GENERIC_ERROR_MESSAGE} />
              )}
            </Card>
          </Columns.Column>
        </Columns>
      </PageContainer>
    </StyledBackgroundBox>
  );
};

const StyledBackgroundBox = styled(Box).attrs({
  display: { lg: 'flex' },
  alignItems: { lg: 'center' },
  justifyContent: { lg: 'center' },
  minHeight: '100vh',
  backgroundColor: 'neutral.100',
})`
  background-image: url(${superReferralsSMImage});
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 350px;
  white-space: pre-line;

  ${match('md')`
    background-image: url(${superReferralsLGImage});
    background-size: 900px;
    padding-top: 380px;
  `}

  ${match('lg')`
    background-position: calc(50% + 210px) calc(50% + 120px);
    background-size: 1000px;
    padding-top: 0;
  `}

  ${match('xl')`
    background-position: calc(50% + 360px) calc(50% + 120px);
    background-size: auto;
  `}
`;

const StyledCopyBox = styled(Box).attrs({
  borderRadius: 'xxs',
  paddingX: { xs: 'sm', lg: 'md' },
  paddingY: 'xs',
})`
  ${borderColor('mint.100')}
  ${borderWidth('md')}
  border-style: dashed;
`;

const StyledReferralLinkText = styled(Text).attrs({
  variant: 2,
  color: 'mint.100',
  isBold: true,
})`
  ${fontFamily('monospace')}
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`;
