import * as React from 'react';
export const StreamlineNaturalDisasterFire = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11.696 12.246a3.364 3.364 0 01.823-1.907 3.61 3.61 0 011.813-1.112 1.187 1.187 0 01.193 1.663c.53.149 1.093.15 1.622.002a2.91 2.91 0 001.369-.833 3.072 3.072 0 00.959-2.219c0-.832-.345-1.63-.96-2.218a3.397 3.397 0 00-2.315-.901c-.864 0-1.695.323-2.315.901a5.17 5.17 0 00-1.857 3.104 5.03 5.03 0 00.668 3.52z" />
    <path vectorEffect="non-scaling-stroke" d="M19.604 13.655a7.166 7.166 0 001.37-8.21 7.163 7.163 0 00-1.802-2.32 7.358 7.358 0 00-9.94.65 32.135 32.135 0 00-6.83 9.985.716.716 0 00.894.945l3.149-1.05-3.817 6.877a.717.717 0 00.981.971l4.859-2.78-.683 3.413a.716.716 0 001.105.734 78.214 78.214 0 0010.714-9.215z" />
  </svg>
);
