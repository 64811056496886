import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  InfoPanel,
  LogoBadge,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  WebAppVoyagerWithdrawal,
  WebAppVoyagerWithdrawalVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerWithdrawal';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatDate,
  maskBankAccountNumber,
  sydneyDate,
  useEstimatedProcessedDate,
} from '@spaceship-fspl/helpers';
import { FeatherClockIcon, FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { Error, InlineContactSupportMessage } from 'components/layouts/error';
import { CenterPageContainer, PageContainer } from 'components/layouts/page';
import { PendingVerification } from 'components/layouts/pending-verification';
import { LinkButton } from 'components/link-button';
import WithdrawalInfoAccordion from 'components/voyager-withdrawal-info-accordion';
import { cleanNumber, formatCurrency } from 'helpers/format';
import { TestId } from 'helpers/test-ids';
import { commonValidationRules } from 'helpers/validation';
import { withVoyagerTopNavigation } from 'navigation/helpers';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FeatureFlagKeys, useFeatureFlag } from '../helpers/dynamic-config';
import { Routes } from './routes';

type FormValues = Partial<{
  audAmount: string;
}>;

interface PageParams {
  productId?: string;
  location: {
    state: {
      audAmount: string;
    };
  };
}

export const Withdraw: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = (props) => {
  const navigate = useNavigate();
  const { productId = '' } = useParams();
  const withdrawalAmount = props.location?.state?.audAmount;
  const estimatedDate = useEstimatedProcessedDate(sydneyDate());
  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const moneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  const resp = useQuery<
    WebAppVoyagerWithdrawal,
    WebAppVoyagerWithdrawalVariables
  >(
    gql`
      query WebAppVoyagerWithdrawal($id: ID!, $idProvided: Boolean!) {
        contact {
          id
          saverProductInstance {
            id
          }
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            saverProductInstance(id: $id) @include(if: $idProvided) {
              id
              portfolio
              investments {
                id
                summary {
                  id
                  audBalance
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: productId,
        idProvided: !!productId,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (
          !data?.contact?.account?.saverProductInstance?.id &&
          data?.contact?.saverProductInstance?.id
        ) {
          navigate(
            `${Routes.VOYAGER_WITHDRAW}/${data.contact.saverProductInstance.id}`,
            { replace: true },
          );
        }
      },
    },
  );
  const isLoading =
    resp.loading || resp.networkStatus === NetworkStatus.refetch;

  const {
    control,
    watch,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
  } = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      // `withdrawalAmount` is only truthy when clicking `Edit` on the withdraw-confirm page
      audAmount: withdrawalAmount
        ? formatCurrency(withdrawalAmount)
        : undefined,
    },
  });

  const balance =
    Number(
      resp.data?.contact?.account?.saverProductInstance?.investments?.summary
        ?.audBalance,
    ) ?? 0;
  const isBalanceZeroOrNegative = balance <= 0;

  const bankAccountNumber =
    resp.data?.contact?.account?.activeBankAccount?.accountNumber;

  const { audAmount } = watch();
  const isFullRedemption = balance === Number(cleanNumber(audAmount ?? ''));

  const showUnitPriceWarning =
    !moneyDayOneEnabled &&
    !!cleanNumber(audAmount ?? '') &&
    !!estimatedDate &&
    !errors['audAmount'];

  useEffect(() => {
    if (isDirty && (balance === 0 || audAmount === '$')) {
      setValue('audAmount', '');
    }
  }, [balance, setValue, audAmount, isDirty]);

  if (isLoading) {
    return (
      <CenterPageContainer data-testid={TestId.VOYAGER_WITHDRAW_PAGE}>
        <Box display="flex" justifyContent="center">
          <Spinner size="md" />
        </Box>
      </CenterPageContainer>
    );
  }

  if (!resp.loading && resp.error) {
    return (
      <Error
        title="We've run into a problem."
        subtitle={<InlineContactSupportMessage />}
        buttonText="Try again"
        iconColor="indigo.070"
        onContinue={{
          onClick: () => resp.refetch(),
          trackingProperties: {
            name: 'voyager_withdraw_error_page:contact_support',
          },
        }}
        secondaryButton={
          <LinkButton
            size="sm"
            trackingProperties={{ name: 'one_off_deposit_contact_support' }}
            onClick={() =>
              navigate(Routes.VOYAGER_TRANSACTIONS, {
                state: { productId: productId },
              })
            }
          >
            Go back
          </LinkButton>
        }
      />
    );
  }

  if (!resp.loading && !isVerifiedUser) {
    return (
      <CenterPageContainer>
        <PendingVerification
          variant={'withdrawal'}
          onBack={{
            onClick: () => {
              navigate(Routes.VOYAGER_TRANSACTIONS);
            },
            trackingProperties: {
              name: 'voyager_withdraw_page:user_not_verified',
            },
          }}
        />
      </CenterPageContainer>
    );
  }

  return (
    <PageContainer data-testid={TestId.VOYAGER_WITHDRAW_PAGE}>
      <Stack spaceY="xxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
            <Stack spaceY="md">
              <Heading variant={3} align="center">
                How much would you like to withdraw?
              </Heading>
              {!moneyDayOneEnabled && (
                <Text variant={2} isBold={true} align="center">
                  Your current balance is {formatCurrency(balance)}.
                </Text>
              )}

              {isBalanceZeroOrNegative ? (
                <InfoPanel
                  backgroundColor={'indigo.010'}
                  leftBorderColor={'indigo.010'}
                  boxShadow={'none'}
                  color={'black.100'}
                  textColor={'black.100'}
                  icon={FeatherInfoIcon}
                  iconAlignment="center"
                  iconSize={'sm'}
                  textVariant={3}
                >
                  No available funds to withdraw.
                </InfoPanel>
              ) : null}
            </Stack>

            <Box marginTop="md">
              <form
                onSubmit={handleSubmit((input: FormValues) => {
                  const withdrawalAmount = cleanNumber(input?.audAmount ?? '');
                  navigate(`${Routes.VOYAGER_WITHDRAW_CONFIRM}/${productId}`, {
                    state: {
                      audAmount: withdrawalAmount,
                      portfolio:
                        resp.data?.contact?.account?.saverProductInstance
                          ?.portfolio,
                      fullRedemption: isFullRedemption,
                    },
                  });
                })}
              >
                <Stack spaceY="sm">
                  <Stack spaceY="sm">
                    <ControllerInput
                      name="audAmount"
                      disabled={isBalanceZeroOrNegative}
                      control={control}
                      type="text"
                      format="currency"
                      placeholder="Withdrawal amount"
                      rules={{
                        required: 'Withdrawal amount is required',
                        validate: {
                          atLeastOneCent: (value?: string): boolean | string =>
                            value
                              ? commonValidationRules.atLeastOneCent.validate(
                                  value,
                                )
                              : false,
                          atMost: (value?: string): boolean | string =>
                            value
                              ? commonValidationRules.atMost.validate(
                                  balance,
                                  value,
                                )
                              : false,
                        },
                      }}
                    />
                    {isFullRedemption && balance ? (
                      <InfoPanel
                        backgroundColor="indigo.010"
                        boxShadow="none"
                        color="black.100"
                        icon={FeatherInfoIcon}
                        iconAlignment="center"
                        iconColor="black.100"
                        leftBorderColor="indigo.010"
                        marginBottom="none"
                        textVariant={3}
                        textColor="black.100"
                      >
                        We will withdraw your entire balance.
                      </InfoPanel>
                    ) : null}
                  </Stack>

                  {!moneyDayOneEnabled && (
                    <Text variant={3} color="neutral.080">
                      Your withdrawal will be transferred to your bank account
                      ending in {maskBankAccountNumber(bankAccountNumber)}.
                    </Text>
                  )}
                  <Divider color="neutral.030" />
                </Stack>
                {moneyDayOneEnabled && (
                  <Box marginTop="sm">
                    <Stack spaceY="sm">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack spaceY="xxxs">
                          <Text variant={4} color="neutral.080" isBold={true}>
                            Available to withdraw
                          </Text>
                          <Text variant={2} isBold={true}>
                            {formatCurrency(balance)}
                          </Text>
                        </Stack>
                        <Button
                          variant={'secondary'}
                          size={'sm'}
                          isDisabled={isBalanceZeroOrNegative}
                          trackingProperties={{
                            name: 'voyager_withdrawal|withdraw_all',
                          }}
                          onClick={() => {
                            if (balance > 0) {
                              navigate(
                                `${Routes.VOYAGER_WITHDRAW_CONFIRM}/${productId}`,
                                {
                                  state: {
                                    audAmount: balance.toString(),
                                    portfolio:
                                      resp.data?.contact?.account
                                        ?.saverProductInstance?.portfolio,
                                    fullRedemption: true,
                                  },
                                },
                              );
                            }
                          }}
                        >
                          Withdraw all
                        </Button>
                      </Box>

                      <Divider color="neutral.030" />

                      <Stack spaceY="xxxs">
                        <Text variant={4} color="neutral.080" isBold={true}>
                          Destination
                        </Text>
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                        >
                          <Text variant={2} isBold={true}>
                            Spaceship
                          </Text>
                          <Box marginLeft={'xxxs'}>
                            <LogoBadge variant={'dark'} size="sm" />
                          </Box>
                        </Box>
                        <Text variant={3} color="neutral.080">
                          The proceeds from your withdrawal will be transferred
                          to your Spaceship account.
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                )}

                {showUnitPriceWarning ? (
                  <Box
                    backgroundColor="neutral.030"
                    borderRadius="xxs"
                    padding="md"
                    marginTop="lg"
                  >
                    <Stack spaceY="xs" alignX="center">
                      <FeatherClockIcon color="indigo.070" />
                      {!moneyDayOneEnabled && (
                        <Text variant={3} align="center">
                          The ETA of your funds in your bank account is{' '}
                          {formatDate(
                            estimatedDate,
                            DATE_FORMAT_TRANSACTIONS_LONG,
                          )}
                          .
                        </Text>
                      )}
                      <Text variant={3} align="center">
                        Your balance is based on the current unit price, which
                        will be different to the unit price you’ll receive on
                        your withdrawal. This means your withdrawal could be
                        less than requested if the unit price goes down.
                      </Text>
                    </Stack>
                  </Box>
                ) : null}

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="xl"
                >
                  <Stack spaceY="md" alignX="center">
                    {balance > 0 ? (
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        isDisabled={
                          !!errors['audAmount'] || isBalanceZeroOrNegative
                        }
                        trackingProperties={{ name: 'withdraw_submit' }}
                      >
                        Continue
                      </Button>
                    ) : null}
                    <LinkButton
                      size="sm"
                      onClick={async (): Promise<void> =>
                        await navigate(
                          `${Routes.VOYAGER_TRANSACTIONS}/${productId}`,
                        )
                      }
                      trackingProperties={{ name: 'withdraw_cancel' }}
                    >
                      {isBalanceZeroOrNegative ? 'Go back' : 'Cancel'}
                    </LinkButton>
                  </Stack>
                </Box>
              </form>
            </Box>
          </Columns.Column>
        </Columns>

        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 5 / 6 }}>
            <WithdrawalInfoAccordion />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

export const VoyagerWithdraw = withVoyagerTopNavigation()(Withdraw);
