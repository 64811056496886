import { UnstyledButton } from '@spaceship-fspl/components';
import { FeatherEyeIcon, FeatherEyeOffIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  getSpace,
  transition,
} from '@spaceship-fspl/styles';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Input } from './input';

interface PasswordInputProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  errorMessage?: string;
  placeholder?: string;
}

export const PasswordInput: React.FC<
  React.PropsWithChildren<PasswordInputProps>
> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const PasswordIcon = showPassword ? FeatherEyeOffIcon : FeatherEyeIcon;

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      placeholder={props.placeholder ?? 'Password'}
      suffix={
        <StyledToggleButton onClick={() => setShowPassword(!showPassword)}>
          <StyledIconContainer>
            <PasswordIcon size="md" color="neutral.080" />
          </StyledIconContainer>
        </StyledToggleButton>
      }
    />
  );
};

const StyledIconContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledToggleButton = styled(UnstyledButton)`
  position: relative;

  ::after {
    ${backgroundColor('neutral.030')}
    ${borderRadius('md')}
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    height: 0;
    width: 0;
    z-index: 0;
    ${transition}
  }

  :hover::after {
    opacity: 1;
    height: ${getSpace('xl')};
    width: ${getSpace('xl')};
  }
`;
