import { createGlobalStyle } from 'styled-components';

export const Reset = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
`;
