import { StreamlineSpaceRocketFlyingIcon } from '@spaceship-fspl/icons-web';
import { getColor, getSpace } from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

import { Box } from '../box';
import { Text } from '../text';

const PowerUpRelative = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const PowerUpWrapper = styled.div`
  padding: ${getSpace('xs')};
  border-color: ${getColor('pink.030')};
  border-width: 2px;
  border-style: solid;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`;

const PowerUpHeading = styled.div`
  color: ${getColor('neutral.000')};
  background-color: ${getColor('pink.030')};
  border-radius: 12px;
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 0px;
  height: 32px;
  margin: -16px;
  box-sizing: border-box;
  padding: ${getSpace('xxs')} ${getSpace('xs')};
`;
const RocketIconStyled = styled(StreamlineSpaceRocketFlyingIcon)`
  width: 20px;
  height: 20px;
`;

export const PowerUpPanel: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <PowerUpRelative>
      <PowerUpWrapper>
        <PowerUpHeading>
          <RocketIconStyled color="neutral.000" />{' '}
          <Box paddingLeft={'xxxs'}>
            <Text variant={2} isBold={true} color={'neutral.000'}>
              Power Up!
            </Text>
          </Box>
        </PowerUpHeading>
        <Box marginTop={'xxs'}>{children}</Box>
      </PowerUpWrapper>
    </PowerUpRelative>
  );
};
