import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import {
  FeatherCheckIcon,
  FeatherCircleIcon,
  FeatherXIcon,
} from '@spaceship-fspl/icons-web';
import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import { Modal, ModalProps } from 'components/modal';
import React from 'react';

export interface VoyagerInvestableAssetsModalProps extends ModalProps {
  portfolioName: string;
  portfolio: SaverPortfolio.Enum | null;
}

export const VoyagerInvestableAssetsModal: React.FC<
  VoyagerInvestableAssetsModalProps
> = ({ portfolioName, portfolio, showModal, closeModal }) => (
  <Modal
    showModal={showModal}
    closeModal={closeModal}
    maxWidth={800}
    scrollable
  >
    <Box paddingX={{ xs: 'md', md: 'xl' }} paddingY="xl">
      <Stack spaceY="md">
        <Heading variant={3}>Allocation into the {portfolioName}</Heading>

        <Text variant={2} color="neutral.085">
          Your total investable assets include your savings, financial assets
          (such as managed funds, stocks, bonds and commodities), super, and any
          real estate you hold.
        </Text>

        {(portfolio === SaverPortfolio.Enum.EARTH ||
          portfolio === SaverPortfolio.Enum.INDEX ||
          portfolio === SaverPortfolio.Enum.UNIVERSE) && (
          <Text variant={2} color="neutral.085">
            It’s important not to put all your eggs in one basket. This
            portfolio has a single investment strategy and is concentrated in
            stocks. It’s not diversified across asset classes and strategies.
          </Text>
        )}
        {(portfolio === SaverPortfolio.Enum.GALAXY ||
          portfolio === SaverPortfolio.Enum.EXPLORER) && (
          <Text variant={2} color="neutral.085">
            It’s important not to put all your eggs in one basket. This
            portfolio has a multi-asset investment strategy. It’s diversified
            across asset classes and strategies such as stocks, bonds and cash.
          </Text>
        )}

        <Box display="flex" flexDirection="row">
          <FeatherCheckIcon color="mint.100" size="md" />

          {(portfolio === SaverPortfolio.Enum.EARTH ||
            portfolio === SaverPortfolio.Enum.INDEX ||
            portfolio === SaverPortfolio.Enum.UNIVERSE) && (
            <Box flexShrink={1} marginLeft="xs">
              <Heading variant={5}>Up to 25%</Heading>
              <Text variant={3} color="neutral.085">
                This portfolio is generally suitable for investors that are
                investing up to 25% of their investable assets.
              </Text>
            </Box>
          )}
          {(portfolio === SaverPortfolio.Enum.GALAXY ||
            portfolio === SaverPortfolio.Enum.EXPLORER) && (
            <Box flexShrink={1} marginLeft="xs">
              <Heading variant={5}>Up to 75%</Heading>
              <Text variant={3} color="neutral.085">
                This portfolio is generally suitable for investors that are
                investing up to 75% of their investable assets.
              </Text>
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="row">
          {(portfolio === SaverPortfolio.Enum.EARTH ||
            portfolio === SaverPortfolio.Enum.INDEX ||
            portfolio === SaverPortfolio.Enum.UNIVERSE) && (
            <>
              <FeatherCircleIcon color="gold.100" />
              <Box flexShrink={1} marginLeft="xs">
                <Heading variant={5}>25-50%</Heading>
                <Text variant={3} color="neutral.085">
                  This portfolio may be suitable for investors that are
                  investing up to 50% of their investable assets.
                </Text>
              </Box>
            </>
          )}
          {(portfolio === SaverPortfolio.Enum.GALAXY ||
            portfolio === SaverPortfolio.Enum.EXPLORER) && (
            <>
              <FeatherXIcon color="red.100" />
              <Box flexShrink={1} marginLeft="xs">
                <Heading variant={5}>75-100%</Heading>
                <Text variant={3} color="neutral.085">
                  This portfolio is not suitable for investors that are
                  investing more than 75% of their investable assets.
                </Text>
              </Box>
            </>
          )}
        </Box>

        {(portfolio === SaverPortfolio.Enum.EARTH ||
          portfolio === SaverPortfolio.Enum.INDEX ||
          portfolio === SaverPortfolio.Enum.UNIVERSE) && (
          <Box display="flex" flexDirection="row">
            <FeatherXIcon color="red.100" />

            <Box flexShrink={1} marginLeft="xs">
              <Heading variant={5}>50-100%</Heading>
              <Text variant={3} color="neutral.085">
                This portfolio is not suitable for investors that are investing
                more than 50% of their investable assets.
              </Text>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  </Modal>
);
