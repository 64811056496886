import { PersistKey, retrieve, store } from 'helpers/persist';
import { useCallback, useState } from 'react';

export const useLocalStorage = <T>(
  key: PersistKey,
  initialValue?: T,
): [T, (value: T | ((t: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = retrieve(key);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((t: T) => T)) => {
      try {
        setStoredValue((prevValue) => {
          const valueToStore =
            value instanceof Function ? value(prevValue) : value;
          store(key, JSON.stringify(valueToStore));
          return valueToStore;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  return [storedValue, setValue];
};
