import * as React from 'react';
export const MergeArrow = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M5.863 5.297l6.621 6.705M5.863 5.297A2.244 2.244 0 102.624 2.19a2.244 2.244 0 003.239 3.106zm6.621 6.705L5.83 18.679m6.655-6.677h9.632M5.83 18.679a2.244 2.244 0 10-3.17 3.175 2.244 2.244 0 003.17-3.175zM17.902 7.787l4.214 4.215m0 0l-4.214 4.214" />
  </svg>
);
