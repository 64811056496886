import { AddressInput } from '@spaceship-fspl/graphql';
import { api, Product } from '@spaceship-fspl/types/externalapi';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { addRumError } from 'helpers/monitoring';
import { PersistKey } from 'helpers/persist';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

interface SignupRequests {
  createLead?: api.external.ICreateLeadRequestBody;
  updateLead?: {
    contactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    emailAddress?: string | null;
    dateOfBirth?: string | null;
    address?: AddressInput;
    productIntent?: Product.Enum;
    campaign?: string | null;
  };
}

interface OnboardingRequests {
  createSaverAccount?: api.external.ICreateSaverAccountRequestBody;
  createPaymentSchedule?: api.external.ICreatePaymentScheduleRequestBody;
  createOneOffDeposit?: api.external.ICreatePaymentScheduleRequestBody;
}

const CreateUserContext = createContext<
  [
    api.external.ICreateUserRequestBody | undefined,
    (createUser: api.external.ICreateUserRequestBody) => void,
  ]
>([
  undefined,
  (): void => addRumError({ error: new Error('signup context not provided') }),
]);

export const CreateUserProvider: React.FC<{
  children?: ReactNode | undefined;
}> = ({ children }) => {
  const [createUser, setCreateUser] =
    useState<api.external.ICreateUserRequestBody>();

  return (
    <CreateUserContext.Provider value={[createUser, setCreateUser]}>
      {children}
    </CreateUserContext.Provider>
  );
};

export const useCreateUserContext = (): [
  api.external.ICreateUserRequestBody | undefined,
  (createUser: api.external.ICreateUserRequestBody) => void,
] => useContext(CreateUserContext);

const SignupRequestContext = createContext<
  [SignupRequests, (requests: SignupRequests) => void]
>([
  {},
  (): void => addRumError({ error: new Error('signup context not provided') }),
]);

export const SignupRequestProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [requests, setRequests] = useLocalStorage<SignupRequests>(
    PersistKey.SIGNUP,
    {},
  );
  useEffect(() => {
    return (): void => setRequests({});
  }, [setRequests]);
  return (
    <SignupRequestContext.Provider value={[requests, setRequests]}>
      {children}
    </SignupRequestContext.Provider>
  );
};

export const useSignupRequestContext = (): [
  SignupRequests,
  (requests: SignupRequests) => void,
] => useContext(SignupRequestContext);

const OnboardingRequestContext = createContext<
  [OnboardingRequests, (requests: OnboardingRequests) => void]
>([
  {},
  (): void =>
    addRumError({ error: new Error('onboarding context not provided') }),
]);

export const OnboardingRequestProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [requests, setRequests] = useLocalStorage<OnboardingRequests>(
    PersistKey.ONBOARDING,
    {},
  );

  useEffect(() => {
    return (): void => setRequests({});
  }, [setRequests]);

  return (
    <OnboardingRequestContext.Provider value={[requests, setRequests]}>
      {children}
    </OnboardingRequestContext.Provider>
  );
};

export const useOnboardingRequestContext = (): [
  OnboardingRequests,
  (requests: OnboardingRequests) => void,
] => useContext(OnboardingRequestContext);

interface ResetPasswordData {
  email?: string;
  resetId?: string;
}

const ResetPasswordContext = createContext<
  [ResetPasswordData, Dispatch<SetStateAction<ResetPasswordData>>]
>([
  {},
  (): void =>
    addRumError({ error: new Error('reset password context not provided') }),
]);

export const ResetPasswordProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <ResetPasswordContext.Provider value={useState({})}>
    {children}
  </ResetPasswordContext.Provider>
);

export const useResetPasswordContext = (): [
  ResetPasswordData,
  Dispatch<SetStateAction<ResetPasswordData>>,
] => useContext(ResetPasswordContext);
