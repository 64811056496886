import {
  borderRadii,
  borderRadius,
  Color,
  getColor,
} from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

type PortfolioMixProps = {
  value: { value: number; color: Color; label?: string }[];
};

const PortfolioMixWrapper = styled.div`
  ${borderRadius('lg')};
  min-height: 8px;
  max-height: 8px;
  min-width: 100%;
  background-color: ${getColor('neutral.030')};
  position: relative;
`;

const PortfolioMixLine = styled.div<{
  value: number;
  color: Color;
  positionLeft?: number;
  borderLeftRadius?: string;
  borderRightRadius?: string;
}>`
  border-bottom-left-radius: ${({ borderLeftRadius }) => borderLeftRadius};
  border-top-left-radius: ${({ borderLeftRadius }) => borderLeftRadius};
  border-bottom-right-radius: ${({ borderRightRadius }) => borderRightRadius};
  border-top-right-radius: ${({ borderRightRadius }) => borderRightRadius};
  position: absolute;
  top: 0;
  left: ${({ positionLeft }) => (positionLeft ? `${positionLeft - 2}%` : 0)};
  bottom: 0;
  min-height: 8px;
  max-height: 8px;
  width: ${({ value }) => `${value + 2}%`};
  background-color: ${({ color }) => getColor(color)};
`;

export const PortfolioMix: React.FC<PortfolioMixProps> = ({ value }) => {
  return (
    <PortfolioMixWrapper data-testid="portfolio-mix">
      {value.map((v, i, arr) => {
        const positionLeft = arr
          .slice(0, i)
          .reduce((acc, curr) => acc + curr.value, 0);
        return (
          <PortfolioMixLine
            key={i}
            color={v.color}
            value={v.value}
            positionLeft={positionLeft}
            borderRightRadius={i === arr.length - 1 ? borderRadii.lg : '0px'}
            borderLeftRadius={i === 0 ? borderRadii.lg : '0px'}
            aria-label={`${v.label}`}
          />
        );
      })}
    </PortfolioMixWrapper>
  );
};
