import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { WebAppBoostsBasiqError } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsBasiqError';
import { Error } from 'components/layouts/error';
import { Routes } from 'pages/routes';
import React from 'react';

import { BoostRecipeCreateParams, BoostRecipeEditParams } from './types';

type BoostsBasiqErrorProps = {
  institutionId: string;
} & (
  | { type: 'reconnect-source' }
  | { type: 'add-source' }
  | ({ type: 'add-source-and-edit-recipe' } & Omit<
      BoostRecipeEditParams,
      'sourceId'
    >)
  | ({ type: 'add-source-and-create-recipe' } & BoostRecipeCreateParams)
);

export const BoostsBasiqError: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<{
      location: { state: BoostsBasiqErrorProps };
    }>
  >
> = (props) => {
  const navigate = useNavigate();
  const state = props.location?.state;
  const type = state?.type ?? '';

  const resp = useQuery<WebAppBoostsBasiqError>(
    gql`
      query WebAppBoostsBasiqError {
        contact {
          id
          account {
            id
            basiqConnections {
              id
            }
          }
        }
      }
    `,
    {
      skip: !type || type === 'add-source' || type === 'reconnect-source',
    },
  );

  const hasBoostsConnection =
    !!resp?.data?.contact.account?.basiqConnections?.length;

  return (
    <Error
      title="Oops, we ran into a problem linking your tracking bank"
      subtitle="Please try linking another tracking bank."
      iconColor="indigo.070"
      buttonText="Link another tracking bank"
      onContinue={{
        onClick: () => {
          switch (type) {
            case 'add-source':
            case 'reconnect-source':
              navigate(Routes.BOOSTS_BASIQ_INSTITUTIONS, {
                state: {
                  type: 'add-source',
                },
              });
              break;

            case 'add-source-and-create-recipe':
              navigate(
                hasBoostsConnection
                  ? Routes.BOOSTS_BASIQ_SELECT
                  : Routes.BOOSTS_BASIQ_INSTITUTIONS,
                {
                  state: {
                    ...state,
                    type: 'create-recipe',
                  },
                },
              );
              break;

            case 'add-source-and-edit-recipe':
              navigate(
                hasBoostsConnection
                  ? Routes.BOOSTS_BASIQ_SELECT
                  : Routes.BOOSTS_BASIQ_INSTITUTIONS,
                {
                  state: {
                    ...state,
                    type: 'edit-recipe',
                  },
                },
              );
              break;

            default:
              navigate(Routes.BOOSTS_DASHBOARD_HOME);
          }
        },
        trackingProperties: {
          name: 'voyager_boost_basiq_error_link_another_bank_button',
        },
      }}
    />
  );
};
