import * as React from 'react';
export const FeatherCalendar = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect vectorEffect="non-scaling-stroke" x={3} y={4} width={18} height={18} rx={2} ry={2} />
    <path vectorEffect="non-scaling-stroke" d="M16 2v4M8 2v4M3 10h18" />
  </svg>
);
