import { useGetActiveBankAccount } from '@spaceship-fspl/data';
import { OldVoyagerBankAccountCard } from 'components/account-cards/voyager-bank-account';
import React from 'react';

export const BankAccountSection: React.FC<React.PropsWithChildren> = () => {
  const bankAccount = useGetActiveBankAccount();

  return (
    <OldVoyagerBankAccountCard
      variant="signup_summary"
      bankAccount={bankAccount}
      isReadOnly={true}
    />
  );
};
