import { RouteComponentProps, useNavigate } from '@reach/router';
import { Stack } from '@spaceship-fspl/components';
import { useGetUser, useSetGreenIdFields } from '@spaceship-fspl/data';
import {
  AustralianCitizenshipFields,
  citizenshipCertificateValidations,
  GreenIdTermsAndCondition,
  IDName,
  idSourceProtosToType,
  useID,
  validateNameByLength,
} from '@spaceship-fspl/green-id';
import { formatDate } from '@spaceship-fspl/helpers';
import { GreenIdSourceName } from '@spaceship-fspl/types/externalapi';
import citizenshipCertImage from 'assets/greenid-citizenship-certificate@2x.png';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { useGreenID } from 'contexts/greenid';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React from 'react';
import { useForm } from 'react-hook-form';

import { NO_USER_VERIFICATION_ERROR, useReverifyAndNavigate } from './hooks';
import { FormLayout } from './layout';

export const CitizenshipCertificate: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const idName = IDName.AUSTRALIAN_CITIZENSHIP_CERTIFICATE;
  const { getUploadLinkBySourceName, ruleset } = useGreenID();
  const { mutateAsync: postGreenIDFields, isLoading } =
    useSetGreenIdFields(ruleset);
  const verificationId = useID(ruleset);
  const { data: user } = useGetUser();
  const contact = user?.contact;

  const { control, handleSubmit, watch } = useForm<AustralianCitizenshipFields>(
    {
      defaultValues: {
        greenid_citizenshipcertificatedvs_acquisition_date: '',
        greenid_citizenshipcertificatedvs_stock_number: '',
        greenid_citizenshipcertificatedvs_givenname: contact?.first_name || '',
        greenid_citizenshipcertificatedvs_middlename: '',
        greenid_citizenshipcertificatedvs_surname: contact?.last_name || '',
        greenid_citizenshipcertificatedvs_dob: contact?.date_of_birth
          ? formatDate(new Date(contact.date_of_birth))
          : '',
        greenid_citizenshipcertificatedvs_tandc: GreenIdTermsAndCondition.ON,
      },
    },
  );
  const reverifyAndNavigate = useReverifyAndNavigate(idName);

  const { popToast } = useNotifications();
  const { pop: showIntercom } = useIntercom();

  const onSubmit = async (
    values: AustralianCitizenshipFields,
  ): Promise<void> => {
    const sourceName = idSourceProtosToType(
      GreenIdSourceName.Enum.CITIZENSHIPCERTIFICATEDVS,
    );
    try {
      await postGreenIDFields({
        verification_id: verificationId,
        source_name: sourceName,
        field_inputs: {
          ...values,
          greenid_citizenshipcertificatedvs_tandc: GreenIdTermsAndCondition.ON,
        },
      });

      await reverifyAndNavigate();
    } catch (error) {
      if (
        error instanceof TypeError ||
        (error as Error | undefined)?.message.includes(
          NO_USER_VERIFICATION_ERROR,
        )
      ) {
        // Network error
        popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
        showIntercom();
      } else if (sourceName) {
        // API error
        navigate?.(getUploadLinkBySourceName(sourceName), { replace: true });
      }
    }
  };

  return (
    <FormLayout
      title={idName}
      idName={idName}
      image={{ url: citizenshipCertImage, alt: idName }}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
    >
      <Stack spaceY="sm">
        <ControllerInputWithInlineError
          name="greenid_citizenshipcertificatedvs_acquisition_date"
          control={control}
          placeholder="Acquisition date"
          type="text"
          format="date"
          dateFormatOption={{
            delimiter: '/',
          }}
          rules={citizenshipCertificateValidations.acquisitionDate}
        />
        <ControllerInputWithInlineError
          name="greenid_citizenshipcertificatedvs_stock_number"
          control={control}
          placeholder="Stock number"
          type="text"
          rules={citizenshipCertificateValidations.stockNumber}
        />
        <ControllerInputWithInlineError
          name="greenid_citizenshipcertificatedvs_givenname"
          control={control}
          placeholder="First name (as shown on ID)"
          type="text"
          rules={{
            ...citizenshipCertificateValidations.firstName,
            validate: (value: string) =>
              validateNameByLength({
                name: value,
                otherName: watch(
                  'greenid_citizenshipcertificatedvs_middlename',
                ),
                validLength: 49,
                errorMessage:
                  'Your first and middle names must be between 1 and 49 characters.',
              }),
          }}
        />
        <ControllerInputWithInlineError
          name="greenid_citizenshipcertificatedvs_middlename"
          control={control}
          placeholder="Middle name (optional)"
          type="text"
          rules={{
            ...citizenshipCertificateValidations.middleName,
            validate: (value: string) =>
              validateNameByLength({
                name: value,
                otherName: watch('greenid_citizenshipcertificatedvs_givenname'),
                validLength: 49,
                errorMessage:
                  'Your first and middle names must be between 1 and 49 characters.',
              }),
          }}
        />
        <ControllerInputWithInlineError
          name="greenid_citizenshipcertificatedvs_surname"
          control={control}
          placeholder="Last name (as shown on ID)"
          type="text"
          rules={citizenshipCertificateValidations.lastName}
        />
        <ControllerInputWithInlineError
          name="greenid_citizenshipcertificatedvs_dob"
          control={control}
          placeholder="Date of birth"
          type="text"
          format="date"
          dateFormatOption={{
            delimiter: '/',
          }}
          rules={citizenshipCertificateValidations.dob}
        />
      </Stack>
    </FormLayout>
  );
};
