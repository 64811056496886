import { gql } from '@apollo/client';
import {
  ActionLink,
  Box,
  Inline,
  Stack,
  Text,
  UnstyledLink,
} from '@spaceship-fspl/components';
import { SaverPortfolio } from '@spaceship-fspl/graphql';
import {
  webAppArticleCardFragment,
  webAppArticleCardFragment_instrument,
} from '@spaceship-fspl/graphql/src/__generated__/webAppArticleCardFragment';
import {
  formatCurrencyWithCodeAndSymbol,
  humanReadable,
  InternalRoutes,
} from '@spaceship-fspl/helpers';
import {
  boxShadow,
  color,
  fontFamily,
  fontSize,
  getColor,
  getSpace,
  lineHeight,
  marginX,
  match,
  paddingX,
  paddingY,
  zIndex,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import articleStatsBackdrop from 'assets/article-stats-backdrop.svg';
import yellowOval from 'assets/article-yellow-oval.svg';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import { TrackingEvent, TrackingProperties } from 'helpers/analytics';
import React, { memo } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const articleCardFragment = gql`
  fragment webAppArticleCardFragment on Article {
    id
    heading
    subheading
    articleUrl
    imageUrl
    publication
    publicationUrl
    instrument {
      id
      name
      audLatestPrice
      audMarketCapitalisation
      ytdPercentageChange
      portfolios
    }
  }
`;

interface Article extends Omit<webAppArticleCardFragment, 'instrument'> {
  instrument?: webAppArticleCardFragment_instrument | null;
}

const ArticleCard: React.FC<
  React.PropsWithChildren<{
    article: Article;
    productId?: string;
    portfolio?: SaverPortfolio;
    trackingProperties?: TrackingProperties;
  }>
> = ({ article, productId, portfolio, trackingProperties }) => {
  const track = useTrack();
  const trackUrl = (url?: string | null): void => {
    track?.(TrackingEvent.CLICK, {
      properties: { name: 'article_clicked', url },
    });
  };

  return (
    <StyledArticleCard>
      {article.imageUrl && (
        <StyledArticleImage
          src={article.imageUrl}
          alt={`${article.heading} article image`}
        />
      )}

      <Box paddingBottom="sm">
        <Box padding="lg" paddingBottom="md">
          <Stack spaceY="xxxs">
            <StyledPublicationLink
              href={article.publicationUrl ?? ''}
              onClick={() => trackUrl(article.publicationUrl)}
              target="_blank"
            >
              {article.publication}
            </StyledPublicationLink>

            <Stack spaceY="xs">
              <StyledArticleLink
                href={article.articleUrl ?? ''}
                onClick={() => trackUrl(article.articleUrl)}
                target="_blank"
              >
                <StyledArticleHeading>{article.heading}</StyledArticleHeading>
              </StyledArticleLink>

              <Text variant={3} color="neutral.080">
                {article.subheading}
              </Text>
            </Stack>
          </Stack>
        </Box>

        {!!article.instrument &&
          !!productId &&
          trackingProperties &&
          !!portfolio &&
          article.instrument.portfolios?.includes(portfolio) && (
            <StyledCompanyProfileContainer>
              <RouterLink
                to={`/voyager/portfolio/${productId}/${article.instrument.id}`}
                trackingProperties={trackingProperties}
              >
                <StyledCompanyProfileBox>
                  <Text variant={3} isBold={true} color="neutral.100">
                    {article.instrument.name}
                  </Text>

                  <Box display="flex" marginTop="xxs">
                    <Inline spaceX="sm" spaceY="xxs">
                      <LabeledField label="Share price" size="sm">
                        {article.instrument.audLatestPrice
                          ? formatCurrencyWithCodeAndSymbol(
                              article.instrument.audLatestPrice,
                            )
                          : '-'}
                      </LabeledField>
                      <LabeledField label="This year" size="sm">
                        {article.instrument.ytdPercentageChange
                          ? `${parseFloat(
                              article.instrument.ytdPercentageChange,
                            ).toFixed(2)}%`
                          : '-'}
                      </LabeledField>
                      <LabeledField label="Asset size" size="sm">
                        {article.instrument.audMarketCapitalisation
                          ? `A${humanReadable(
                              parseInt(
                                article.instrument.audMarketCapitalisation,
                              ),
                              2,
                            )}`
                          : '-'}
                      </LabeledField>
                    </Inline>
                  </Box>
                </StyledCompanyProfileBox>
              </RouterLink>
            </StyledCompanyProfileContainer>
          )}
      </Box>
    </StyledArticleCard>
  );
};

export const Articles: React.FC<
  React.PropsWithChildren<{
    articles: Array<Article>;
    portfolio?: SaverPortfolio;
    productId?: string;
    trackingProperties?: TrackingProperties;
  }>
> = memo(({ articles, portfolio, productId, trackingProperties }) => {
  const track = useTrack();
  const trackUrl = (url?: string | null): void => {
    track?.(TrackingEvent.CLICK, {
      properties: { name: 'article_clicked', url },
    });
  };

  return (
    <StyledContainer>
      {articles?.map((article) => (
        <StyledArticleContainer key={article.id}>
          <ArticleCard
            article={article}
            portfolio={portfolio}
            productId={productId}
            trackingProperties={trackingProperties}
          />
        </StyledArticleContainer>
      ))}
      <StyledArticleContainer>
        <UnstyledLink
          href={InternalRoutes.LEARN_BLOG}
          target="_blank"
          onClick={() => trackUrl(InternalRoutes.LEARN_BLOG)}
        >
          <SVG
            title="Click here to read the Spaceship blog."
            src={yellowOval}
          />
        </UnstyledLink>
      </StyledArticleContainer>
    </StyledContainer>
  );
});

Articles.displayName = 'Articles';

const StyledContainer = styled.div`
  width: 100vw;
  margin-left: -${getSpace('md')};
  margin-right: -${getSpace('md')};
  margin-top: -${getSpace('sm')};
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  ${match('md')`
    display: block;
    width: auto;
    overflow: initial;
    scroll-snap-type: initial;
    margin-left: 0;
    margin-right: 0;
    column-gap: ${getSpace('sm')};
    columns: 2;
  `}

  ${match('lg')`
    column-gap: ${getSpace('md')};
    columns: 3;
  `}
`;

const StyledArticleContainer = styled.div`
  ${paddingX({ xs: 'md', md: 'none' })}
  ${paddingY('sm')}
  break-inside: avoid;
  min-width: 80%;
  scroll-snap-align: center;

  ${match('md')`
    min-width: auto;
  `}
`;

const StyledArticleCard = styled(Box).attrs({
  borderRadius: 'sm',
  backgroundColor: 'neutral.000',
  boxShadow: 'sm',
  display: 'flex',
  flexDirection: 'column',
  height: { xs: '100%', md: 'auto' },
  position: 'relative',
  overflow: 'hidden',
})`
  transition: 0.15s box-shadow;

  :hover {
    ${boxShadow('md')}
  }
`;

const StyledArticleImage = styled.img`
  height: 170px;
  filter: saturate(75%);
  object-fit: cover;
  object-position: center center;
  width: 100%;
`;

const StyledArticleHeading = styled.h3`
  ${fontFamily('articleHeading')}
  ${fontSize({ xs: '20px', lg: '24px' })}
  ${lineHeight({ xs: '30px', lg: '36px' })}
  ${color('neutral.100')}
  font-weight: 700;
  margin: 0;
`;

const StyledPublicationLink = styled(ActionLink).attrs({
  color: 'neutral.080',
  size: 'xxs',
})`
  position: relative;
  ${zIndex(1)}
`;

const StyledArticleLink = styled(UnstyledLink)`
  :focus ${StyledArticleHeading} {
    opacity: 0.4;
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

const StyledCompanyProfileContainer = styled.div`
  position: relative;
  ${zIndex(1)}
`;

const StyledCompanyProfileBox = styled(Box).attrs({
  backgroundColor: 'neutral.015',
  borderRadius: 'xxs',
  padding: 'sm',
})`
  ${marginX('sm')}
  background-image: url(${articleStatsBackdrop});
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: 85% center;
  transition: 0.15s box-shadow;

  :hover {
    box-shadow: ${getColor('indigo.020')} 0 0 0 2px;
  }
`;
