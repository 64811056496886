import * as React from 'react';
export const FeatherSearch = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11 3a8 8 0 100 16 8 8 0 100-16zM21 21l-4.35-4.35" />
  </svg>
);
