import { navigate, RouteComponentProps } from '@reach/router';
import {
  Box,
  Button,
  Columns,
  Divider,
  Heading,
  InfoPanel,
  Inline,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  fromProtoPortfolio,
  SaverTMDFundsAllocation,
  SaverTMDInvestmentGoal,
  SaverTMDResult,
  SaverTMDWithdrawalFrequency,
  SaverTMDWithdrawalFrequencyDailySubQuestion,
  SetSaverTargetMarketDeterminationAnswersInput,
  TMDInvestmentGoalComfortLevel,
  useSetSaverTargetMarketDeterminationAnswers,
  useSubmitPreApprovedTargetMarketDeterminationAnswers,
} from '@spaceship-fspl/graphql';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import {
  FeatherCheckIcon,
  FeatherInfoIcon,
  StreamlineCheckCircle1Icon,
} from '@spaceship-fspl/icons-web';
import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import {
  TMDDetails,
  tmdDetailsForPortfolio,
  VoyagerGaugePercentage,
  VoyagerPieChartPercentage,
} from '@spaceship-fspl/voyager';
import { ControllerTextArea } from 'components/controller-input';
import { Error } from 'components/layouts/error';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { ResourceList } from 'components/resource-list';
import { TMDFormMultiQuestion } from 'components/tmd-form-field';
import { GaugeIcon, PieChartIcon } from 'components/voyager-portfolio-icons';
import { useNotifications } from 'contexts/notifications';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import { AccessibilityLabel } from 'helpers/accessibility';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { voyagerPortfolios } from 'helpers/portfolios';
import React, { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { Routes } from './routes';

enum Section {
  Loading = 'Loading',
  ErrorSection = 'ErrorSection',
  MainForm = 'MainForm',
  NotOk = 'NotOk',
  ResubmissionConfirmation = 'ResubmissionConfirmation',
  NotAvailable = 'NotAvailable',
  InReview = 'InReview',
}

export const VoyagerTMD: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const [submissionInProgress, setSubmissionInProgress] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState<Section>(
    Section.Loading,
  );
  const { popToast } = useNotifications();
  const {
    portfolio,
    navigateToPortfolioSelection,
    navigateToPreApprovedPortfolioSelection,
    onTMDEligible,
    pdsLink,
    tmdLink,
    tmdDetailsByPortfolio,
    saverTMDDetails,
  } = usePortfolioSelection();
  const [setPreApprovedTMDAnswers] =
    useSubmitPreApprovedTargetMarketDeterminationAnswers();
  const [setSaverTMDAnswers, setSaverTMDAnswersMeta] =
    useSetSaverTargetMarketDeterminationAnswers();
  const tmdVersion4Enabled = useFeatureFlag(
    FeatureFlagKeys.PERCY_TMD_PHASE_ONE_ENABLED,
  );
  const percyTMDPhaseTwoEnabled = useFeatureFlag(
    FeatureFlagKeys.PERCY_TMD_PHASE_TWO_ENABLED,
  );
  const questionsVersion = tmdVersion4Enabled ? '4' : '3';
  const form = useForm<SetSaverTargetMarketDeterminationAnswersInput>({
    defaultValues: {
      questionsVersion,
    },
  });

  useEffect(() => {
    if (!form.formState.isValid && form.formState.isSubmitted) {
      window.scrollTo(0, 0);
    }
  }, [form.formState.isValid, form.formState.isSubmitted]);

  useEffect(() => {
    // This relies on the portfolio being passed in from the usePortfolioSelection context. If this isn't there, then
    // navigate back to the portfolio selection page.
    const gqlPortfolio = fromProtoPortfolio(portfolio);
    if (gqlPortfolio) {
      form.setValue('portfolio', gqlPortfolio);
    } else {
      navigateToPortfolioSelection();
    }
  }, [portfolio, navigateToPortfolioSelection, form]);

  useEffect(() => {
    if (!saverTMDDetails.loading && saverTMDDetails.error) {
      addRumError({ error: saverTMDDetails.error?.message });
      setActiveSection(Section.ErrorSection);
    }
  }, [saverTMDDetails.loading, saverTMDDetails.error]);

  useEffect(() => {
    const selectedProductTMDDetails =
      portfolio && tmdDetailsByPortfolio[portfolio];

    if (saverTMDDetails.loading || !selectedProductTMDDetails) {
      return;
    }
    if (selectedProductTMDDetails.preApproved) {
      if (selectedProductTMDDetails.resubmissionConfirmationRequired) {
        setActiveSection(Section.ResubmissionConfirmation);
        return;
      }
      // TMD already preApproved for this portfolio. The parent component should check this before navigating here. Do nothing in
      // this case. Keeping this case in to handle the race condition immediately after submitting, but before
      // navigation happens.
      return;
    }

    switch (selectedProductTMDDetails.result) {
      case SaverTMDResult.OK:
      case SaverTMDResult.MANUALLY_APPROVED:
        // The previous page should check the TMD result before routing here,
        // and route elsewhere. In the case where a user navigates back to this
        // page after a successful TMD result, the navigateToPortfolioSelection()
        // is required. The `submissionInProgress` check ensures that side effects
        // caused by `onSubmit()` cannot trigger navigation.
        if (!submissionInProgress) {
          navigateToPortfolioSelection();
        }
        break;
      case SaverTMDResult.REQUIRES_REVIEW:
        setActiveSection(Section.InReview);
        break;
      default:
        if (!selectedProductTMDDetails.submissionAvailable) {
          setActiveSection(Section.NotAvailable);
        } else if (activeSection === Section.Loading) {
          setActiveSection(Section.MainForm);
        }
        break;
    }
  }, [
    activeSection,
    saverTMDDetails.loading,
    portfolio,
    tmdDetailsByPortfolio,
    onTMDEligible,
    percyTMDPhaseTwoEnabled,
    navigateToPortfolioSelection,
    submissionInProgress,
  ]);

  const onSubmit = async (): Promise<void> => {
    setSubmissionInProgress(true);
    if (!portfolio) {
      return;
    }
    if (!saverTMDDetails.data) {
      return;
    }

    const currentTmdDetails = tmdDetailsForPortfolio(
      portfolio,
      saverTMDDetails.data.contact,
    );
    const selectedProductTMDDetails =
      portfolio && tmdDetailsByPortfolio[portfolio];
    if (!selectedProductTMDDetails) {
      return;
    }

    if (
      selectedProductTMDDetails?.resubmissionConfirmationRequired &&
      activeSection === Section.MainForm
    ) {
      setActiveSection(Section.ResubmissionConfirmation);
      return;
    }
    try {
      let newTmdDetails: TMDDetails | undefined;
      let hasPreApprovedPortfolios = false;
      if (currentTmdDetails?.preApproved) {
        // resubmissionConfirmation required when rendering this page, previous portfolio-selection will skip this screen entirely
        // iff !resubmissionConfirmationRequired
        const { portfolio, resubmissionConfirmation } = form.getValues();
        const { data } = await setPreApprovedTMDAnswers({
          variables: {
            input: {
              portfolio,
              resubmissionConfirmation,
            },
          },
        });
        newTmdDetails = tmdDetailsForPortfolio(
          portfolio,
          data?.submitPreApprovedTargetMarketDetermination,
        );
      } else {
        const submissionPayload: SetSaverTargetMarketDeterminationAnswersInput =
          { ...form.getValues(), questionsVersion };
        if (
          submissionPayload.fundsAllocation !==
          SaverTMDFundsAllocation.TWENTY_FIVE_TO_FIFTY_PERCENTAGE
        ) {
          // the `comfortLevel` and `comfortLevelOtherReason` fields should only be present when funds allocation response is 25-50%
          submissionPayload.comfortLevel = undefined;
          submissionPayload.comfortLevelOtherReason = undefined;
        }
        const { data: submissionData } = await setSaverTMDAnswers({
          variables: {
            input: submissionPayload,
            includePreApproved: percyTMDPhaseTwoEnabled,
          },
        });

        // Check the result of the mutation. Note that customer may already have account.saverTMDDetails(portfolio) = OK when they land on this form,
        // but we require them to re-submit each time, this is why we rely on the result from the mutation.
        newTmdDetails = tmdDetailsForPortfolio(
          portfolio,
          submissionData?.setSaverTargetMarketDeterminationAnswers,
        );
        hasPreApprovedPortfolios =
          (
            submissionData?.setSaverTargetMarketDeterminationAnswers?.account
              ?.preApprovedPortfolioInformations ?? []
          )?.length > 0;
      }

      if (newTmdDetails?.preApproved) {
        // All ok, continue onboarding/new product
        onTMDEligible();
        return;
      }

      switch (newTmdDetails?.result) {
        case SaverTMDResult.OK:
        case SaverTMDResult.MANUALLY_APPROVED:
          // All ok, continue onboarding/new product
          onTMDEligible();
          break;
        case SaverTMDResult.MANUALLY_REJECTED:
        case SaverTMDResult.NOT_OK: {
          if (percyTMDPhaseTwoEnabled && hasPreApprovedPortfolios) {
            navigateToPreApprovedPortfolioSelection();
          } else {
            setActiveSection(Section.NotOk);
          }
          break;
        }
        case SaverTMDResult.REQUIRES_REVIEW:
          setActiveSection(Section.InReview);
          break;
        default:
          // unhandled result enum from API
          popToast({
            level: 'error',
            message: GENERIC_ERROR_MESSAGE,
          });
      }
    } catch (error) {
      addRumError({ error });
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
    }
    setSubmissionInProgress(false);
  };

  if (!portfolio) {
    return null;
  }

  return (
    <div data-testid={activeSection}>
      {(() => {
        switch (true) {
          case activeSection === Section.NotOk:
            return <NotOkPage />;

          case activeSection === Section.InReview:
            return <InReviewPage />;

          case activeSection === Section.NotAvailable:
            return <NotAvailablePage />;

          case activeSection === Section.ResubmissionConfirmation:
            return (
              <FormProvider {...form}>
                <ResubmissionConfirmationPage
                  isActive={activeSection === Section.ResubmissionConfirmation}
                  loading={setSaverTMDAnswersMeta.loading}
                  onSubmit={onSubmit}
                />
              </FormProvider>
            );

          case activeSection === Section.ErrorSection:
            return <ErrorPage />;

          case activeSection === Section.Loading:
            return <PageContainer></PageContainer>;

          case activeSection === Section.MainForm:
            return (
              <PageContainer>
                <Stack spaceY="xxxl">
                  <Columns alignX="center">
                    <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
                      <Stack spaceY="md">
                        {tmdVersion4Enabled ? (
                          <>
                            <Heading
                              component="h1"
                              variant={3}
                              align={{ xs: 'left', md: 'center' }}
                            >
                              Please answer the following questions as
                              accurately as you can
                            </Heading>
                            <Text variant={2} align="center">
                              {`We need to ask you a few general questions about your investment goals to check whether you're likely to be in the target market for the Spaceship Voyager portfolios.`}
                            </Text>
                            <InfoPanel
                              icon={FeatherInfoIcon}
                              color="gold.100"
                              isBold
                            >
                              {`If your answers indicate you're not likely in
                                  the target market, we will not be able to open
                                  this portfolio for you.`}
                            </InfoPanel>
                          </>
                        ) : (
                          <>
                            <Heading
                              component="h1"
                              variant={3}
                              align={{ xs: 'left', md: 'center' }}
                            >
                              Great, we have a few questions!
                            </Heading>
                            <Text variant={2} align="center">
                              {`Before we continue with your application, we need to ask you a few general questions about your investment goals to check whether you're likely to be in the target market for this portfolio.`}
                            </Text>
                          </>
                        )}

                        <FormProvider {...form}>
                          <form onSubmit={form.handleSubmit(onSubmit)}>
                            <Stack spaceY="xl">
                              <TMDFormMultiQuestion
                                questionTitle="Which goal resonates with you the most when considering this portfolio?"
                                name="investmentGoal"
                                options={[
                                  {
                                    value:
                                      SaverTMDInvestmentGoal.HIGHER_RISK_LONG_TERM,
                                    questionBody: (
                                      <div data-testid="investmentGoal-high">
                                        <Stack spaceY={'xs'}>
                                          <Box
                                            display="flex"
                                            alignItems="flex-start"
                                          >
                                            <Box marginRight="xs">
                                              <GaugeIcon
                                                value={
                                                  VoyagerGaugePercentage.SeventyFive
                                                }
                                                width="52px"
                                              />
                                            </Box>
                                            <Stack spaceY={'xs'}>
                                              <Text variant={2} isBold>
                                                High growth investing
                                              </Text>
                                              <Inline spaceX={'md'}>
                                                <Inline spaceX={'xxxs'}>
                                                  <Text variant={2}>Risk:</Text>
                                                  <Text variant={2} isBold>
                                                    Very high
                                                  </Text>
                                                </Inline>
                                                <Inline spaceX={'xxxs'}>
                                                  <Text variant={2}>
                                                    Min time:
                                                  </Text>
                                                  <Text variant={2} isBold>
                                                    7 years
                                                  </Text>
                                                </Inline>
                                              </Inline>
                                            </Stack>
                                          </Box>
                                          <Divider />
                                          <TMDQuestionCheckItem>
                                            Seeking high long-term growth
                                            (shares).
                                          </TMDQuestionCheckItem>
                                          <TMDQuestionCheckItem>
                                            Comfortable with significant ups and
                                            downs in the market{' '}
                                            {portfolio !==
                                            SaverPortfolio.Enum.INDEX ? (
                                              <TextLink
                                                color="indigo.070"
                                                href="srm"
                                                target="_blank"
                                              >
                                                (SRM 7).
                                              </TextLink>
                                            ) : (
                                              <TextLink
                                                color="indigo.070"
                                                href="srm"
                                                target="_blank"
                                              >
                                                (SRM 6).
                                              </TextLink>
                                            )}
                                          </TMDQuestionCheckItem>
                                        </Stack>
                                      </div>
                                    ),
                                  },
                                  {
                                    value:
                                      SaverTMDInvestmentGoal.MEDIUM_RISK_MEDIUM_TERM,
                                    questionBody: (
                                      <div data-testid="investmentGoal-medium">
                                        <Stack spaceY={'xs'}>
                                          <Box
                                            display="flex"
                                            alignItems="flex-start"
                                          >
                                            <Box marginRight="xs">
                                              <GaugeIcon
                                                value={
                                                  VoyagerGaugePercentage.Fifty
                                                }
                                                width="52px"
                                              />
                                            </Box>
                                            <Stack spaceY={'xs'}>
                                              <Text variant={2} isBold>
                                                Balanced investing
                                              </Text>
                                              <Inline spaceX={'md'}>
                                                <Inline spaceX={'xxxs'}>
                                                  <Text variant={2}>Risk:</Text>
                                                  <Text variant={2} isBold>
                                                    Medium
                                                  </Text>
                                                </Inline>
                                                <Inline spaceX={'xxxs'}>
                                                  <Text variant={2}>
                                                    Min time:
                                                  </Text>
                                                  <Text variant={2} isBold>
                                                    5 years
                                                  </Text>
                                                </Inline>
                                              </Inline>
                                            </Stack>
                                          </Box>
                                          <TMDQuestionCheckItem>
                                            Seeking a balance between stability
                                            (bonds) and growth (shares).
                                          </TMDQuestionCheckItem>
                                          <TMDQuestionCheckItem>
                                            Comfortable with a moderate level of
                                            ups and downs in the market{' '}
                                            <TextLink
                                              color="indigo.070"
                                              href="srm"
                                              target="_blank"
                                            >
                                              (SRM 4).
                                            </TextLink>
                                          </TMDQuestionCheckItem>
                                        </Stack>
                                      </div>
                                    ),
                                  },
                                  {
                                    value:
                                      SaverTMDInvestmentGoal.LOWER_RISK_SHORT_TERM,
                                    questionBody: (
                                      <div data-testid="investmentGoal-low">
                                        <Stack spaceY={'xs'}>
                                          <Box
                                            display="flex"
                                            alignItems="flex-start"
                                          >
                                            <Box marginRight="xs">
                                              <GaugeIcon
                                                value={
                                                  VoyagerGaugePercentage.TwentyFive
                                                }
                                                width="52px"
                                              />
                                            </Box>
                                            <Stack spaceY={'xs'}>
                                              <Text variant={2} isBold>
                                                Conservative investing
                                              </Text>
                                              <Inline spaceX={'md'}>
                                                <Inline spaceX={'xxxs'}>
                                                  <Text variant={2}>Risk:</Text>
                                                  <Text variant={2} isBold>
                                                    Low / medium
                                                  </Text>
                                                </Inline>
                                                <Inline spaceX={'xxxs'}>
                                                  <Text variant={2}>
                                                    Min time:
                                                  </Text>
                                                  <Text variant={2} isBold>
                                                    3 years
                                                  </Text>
                                                </Inline>
                                              </Inline>
                                            </Stack>
                                          </Box>
                                          <Divider />
                                          <TMDQuestionCheckItem>
                                            Prefer investments with stability
                                            (bonds) and a small portion with
                                            growth (shares).
                                          </TMDQuestionCheckItem>
                                          <TMDQuestionCheckItem>
                                            Comfortable with a low-to-moderate
                                            level of ups and downs in the market{' '}
                                            <TextLink
                                              color="indigo.070"
                                              href="srm"
                                              target="_blank"
                                            >
                                              (SRM 3).
                                            </TextLink>
                                          </TMDQuestionCheckItem>
                                          {/* <Divider /> */}
                                        </Stack>
                                      </div>
                                    ),
                                  },
                                  {
                                    value: SaverTMDInvestmentGoal.NONE_APPLY,
                                    questionBody: 'None of these apply to me',
                                  },
                                ]}
                              />

                              <TMDFormMultiQuestion
                                questionTitle="How much of your total investable assets (in savings, shares, super, property and so on) would you consider investing in this portfolio?"
                                name="fundsAllocation"
                                options={[
                                  {
                                    icon: (
                                      <PieChartIcon
                                        value={
                                          VoyagerPieChartPercentage.TwentyFive
                                        }
                                        width="30px"
                                        height="30px"
                                      />
                                    ),
                                    value:
                                      SaverTMDFundsAllocation.UP_TO_TWENTY_FIVE_PERCENTAGE,
                                    questionBody: 'Up to 25%',
                                  },
                                  {
                                    icon: (
                                      <PieChartIcon
                                        value={VoyagerPieChartPercentage.Fifty}
                                        width="30px"
                                        height="30px"
                                      />
                                    ),
                                    value:
                                      SaverTMDFundsAllocation.TWENTY_FIVE_TO_FIFTY_PERCENTAGE,
                                    questionBody: '25% - 50%',
                                    subquestion:
                                      portfolio ===
                                        SaverPortfolio.Enum.EXPLORER ||
                                      portfolio === SaverPortfolio.Enum.GALAXY
                                        ? undefined
                                        : tmdVersion4Enabled
                                          ? {
                                              options: [
                                                {
                                                  questionBody:
                                                    "I'm comfortable, I have a very high tolerance for risk",
                                                  value:
                                                    TMDInvestmentGoalComfortLevel.HIGH_TOLERANCE_FOR_RISK,
                                                },
                                                {
                                                  questionBody:
                                                    "I'm comfortable, I've spoken to a financial adviser",
                                                  value:
                                                    TMDInvestmentGoalComfortLevel.SPOKEN_TO_FINANCIAL_ADVISOR,
                                                },
                                                {
                                                  questionBody:
                                                    'None of the above',
                                                  value:
                                                    TMDInvestmentGoalComfortLevel.OTHER,
                                                  subquestionReason: {
                                                    name: 'comfortLevelOtherReason',
                                                    questionBody: `Tell us your comfort level and why in at least 50 characters.`,
                                                  },
                                                },
                                              ],
                                              name: 'comfortLevel',
                                              questionBody: (
                                                <Stack spaceY="xs">
                                                  <Text variant={2}>
                                                    {`This portfolio predominantly invests in shares. It is not diversified across multiple kinds of assets such as property, bonds and cash. This means this investment is more susceptible to significant ups and downs than if it were invested across multiple kinds of assets.`}
                                                  </Text>
                                                  <Text variant={2} isBold>
                                                    {`How comfortable are you investing 25-50% of your investable assets predominantly in shares?`}
                                                  </Text>
                                                  <Divider color="indigo.070" />
                                                </Stack>
                                              ),
                                            }
                                          : {
                                              name: 'fundsAllocationMediumSubQuestion',
                                              questionBody: (
                                                <Stack spaceY="xs">
                                                  <Text variant={2}>
                                                    {`This portfolio predominantly invests in shares. It is not diversified across multiple kinds of assets such as property, bonds and cash. This means this investment is more susceptible to significant ups and downs than if it were invested across multiple kinds of assets.`}
                                                  </Text>
                                                  <Text variant={2} isBold>
                                                    {`How comfortable are you investing 25-50% of your investable assets predominantly in shares?`}
                                                  </Text>
                                                  <Text variant={2} isBold>
                                                    {`Tell us your comfort level and why`}
                                                  </Text>
                                                </Stack>
                                              ),
                                              prompts: [
                                                `I’m comfortable, I have a very high tolerance for risk and`,
                                                `I’m comfortable, I’ve spoken to a financial adviser and`,
                                              ],
                                            },
                                  },
                                  ...((portfolio ===
                                    SaverPortfolio.Enum.INDEX ||
                                    portfolio === SaverPortfolio.Enum.EARTH ||
                                    portfolio ===
                                      SaverPortfolio.Enum.UNIVERSE) &&
                                  tmdVersion4Enabled
                                    ? [
                                        {
                                          icon: (
                                            <PieChartIcon
                                              value={
                                                VoyagerPieChartPercentage.SeventyFive
                                              }
                                              width="30px"
                                              height="30px"
                                            />
                                          ),
                                          value:
                                            SaverTMDFundsAllocation.FIFTY_TO_SEVENTY_FIVE_PERCENTAGE,
                                          questionBody: '50% - 75%',
                                        },
                                        {
                                          icon: (
                                            <PieChartIcon
                                              value={
                                                VoyagerPieChartPercentage.SeventyFive
                                              }
                                              width="30px"
                                              height="30px"
                                            />
                                          ),
                                          value:
                                            SaverTMDFundsAllocation.SEVENTY_FIVE_TO_HUNDRED_PERCENTAGE,
                                          questionBody: '75% - 100%',
                                        },
                                      ]
                                    : []),
                                  ...(portfolio ===
                                    SaverPortfolio.Enum.EXPLORER ||
                                  portfolio === SaverPortfolio.Enum.GALAXY
                                    ? [
                                        {
                                          icon: (
                                            <PieChartIcon
                                              value={
                                                VoyagerPieChartPercentage.SeventyFive
                                              }
                                              width="30px"
                                              height="30px"
                                            />
                                          ),
                                          value:
                                            SaverTMDFundsAllocation.FIFTY_TO_SEVENTY_FIVE_PERCENTAGE,
                                          questionBody: '50% - 75%',
                                        },
                                        {
                                          icon: (
                                            <PieChartIcon
                                              value={
                                                VoyagerPieChartPercentage.Hundred
                                              }
                                            />
                                          ),
                                          value:
                                            SaverTMDFundsAllocation.SEVENTY_FIVE_TO_HUNDRED_PERCENTAGE,
                                          questionBody: '75% - 100%',
                                        },
                                      ]
                                    : []),
                                  ...((portfolio ===
                                    SaverPortfolio.Enum.INDEX ||
                                    portfolio === SaverPortfolio.Enum.EARTH ||
                                    portfolio ===
                                      SaverPortfolio.Enum.UNIVERSE) &&
                                  !tmdVersion4Enabled
                                    ? [
                                        {
                                          icon: (
                                            <PieChartIcon
                                              value={
                                                VoyagerPieChartPercentage.Hundred
                                              }
                                            />
                                          ),
                                          value:
                                            SaverTMDFundsAllocation.FIFTY_TO_HUNDRED_PERCENTAGE,
                                          questionBody: '50% - 100%',
                                        },
                                      ]
                                    : []),
                                ]}
                              />

                              <TMDFormMultiQuestion
                                questionTitle="How often would you expect to withdraw funds from your investment?"
                                name="withdrawalFrequency"
                                options={[
                                  {
                                    value:
                                      SaverTMDWithdrawalFrequency.WEEKLY_OR_LONGER,
                                    questionBody: 'Weekly or less frequently',
                                  },
                                  {
                                    value: SaverTMDWithdrawalFrequency.DAILY,
                                    questionBody: 'Daily',
                                    subquestion: {
                                      name: 'withdrawalFrequencyDailySubQuestion',
                                      questionBody: `You have the flexibility to request withdrawals on a daily basis. Usually, you can expect to receive your funds within five business days, but in some circumstances, it may take up to 21 business days (or more in exceptional circumstances) to receive your funds. Is this timeframe acceptable?`,
                                      options: [
                                        {
                                          value:
                                            SaverTMDWithdrawalFrequencyDailySubQuestion.YES,
                                          questionBody: 'Yes',
                                        },
                                        {
                                          value:
                                            SaverTMDWithdrawalFrequencyDailySubQuestion.NO,
                                          questionBody: 'No',
                                        },
                                      ],
                                    },
                                  },
                                ]}
                              />

                              {tmdVersion4Enabled ? (
                                <Text variant={3} align="center">
                                  The purpose of these questions is to help us
                                  assess whether you are likely to be in the
                                  target market for the Spaceship Voyager
                                  portfolios. All information provided,
                                  including in response to these questions, is
                                  general in nature and has been prepared
                                  without taking into account your objectives,
                                  needs, and financial situation. You should
                                  seek professional advice and consider your own
                                  circumstances. Obtain and consider the
                                  relevant{' '}
                                  <TextLink href={pdsLink} target="_blank">
                                    PDS
                                  </TextLink>
                                  {' and '}
                                  <TextLink href={tmdLink} target="_blank">
                                    TMD
                                  </TextLink>{' '}
                                  before making a decision about our products.
                                </Text>
                              ) : (
                                <Text variant={3} align="center">
                                  The purpose of these questions is to help us
                                  assess whether you are likely to be in the
                                  target market for this portfolio. These
                                  questions should not be taken to constitute
                                  financial advice. You should consider your own
                                  financial objectives, situation, needs or
                                  whether this portfolio is an appropriate
                                  product for you. Read our{' '}
                                  <TextLink href={pdsLink} target="_blank">
                                    PDS
                                  </TextLink>
                                  {' and '}
                                  <TextLink href={tmdLink} target="_blank">
                                    TMD
                                  </TextLink>{' '}
                                  before making a decision about this product.
                                </Text>
                              )}
                            </Stack>

                            <PageFormButtonContainer>
                              <PageFormContinueButton
                                trackingProperties={{
                                  name: 'voyager_tmd_continue',
                                }}
                                isDisabled={false}
                                isLoading={setSaverTMDAnswersMeta.loading}
                              />
                            </PageFormButtonContainer>
                          </form>
                        </FormProvider>
                      </Stack>
                    </Columns.Column>
                  </Columns>
                </Stack>
              </PageContainer>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

const NotOkPage = (): JSX.Element => {
  const percyTMDPhaseTwoEnabled = useFeatureFlag(
    FeatureFlagKeys.PERCY_TMD_PHASE_TWO_ENABLED,
  );

  const { navigateToPortfolioSelection, variant, pdsLink, tmdLink } =
    usePortfolioSelection();

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
            <Stack spaceY="lg">
              <Stack spaceY="sm">
                <Heading
                  component="h1"
                  variant={3}
                  align={{ xs: 'left', md: 'center' }}
                >
                  Hmm...
                </Heading>

                <Text variant={2}>
                  {`It doesn’t look likely that you’re in the target market for this portfolio, which means that it may not meet your needs.`}
                </Text>

                <Text variant={2}>
                  {`It’s worth reviewing our Product Disclosure Statement and Target Market Determination to understand who the portfolio is designed for, the potential returns and the level of investment risk.`}
                </Text>

                <Text variant={2}>
                  You also may find it useful to speak to a financial adviser.
                </Text>

                <Text variant={2}>
                  {`You’re welcome to come back if your investment objectives, financial situation or needs change.`}
                </Text>
              </Stack>

              {percyTMDPhaseTwoEnabled ? null : (
                <Box>
                  <Divider color="neutral.050" />
                  <ResourceList
                    resources={[
                      {
                        label: 'Product Disclosure Statement',
                        link: pdsLink,
                      },
                      {
                        label: 'Target Market Determination Statement',
                        link: tmdLink,
                      },
                    ]}
                  />

                  <Divider color="neutral.050" />
                </Box>
              )}

              <PageFormButtonContainer>
                <Button
                  aria-label={AccessibilityLabel.CONTINUE}
                  type="submit"
                  variant="primary"
                  size="lg"
                  onClick={() => navigateToPortfolioSelection()}
                >
                  {variant === 'onboarding'
                    ? percyTMDPhaseTwoEnabled
                      ? 'Review portfolios again'
                      : 'Explore other products'
                    : percyTMDPhaseTwoEnabled
                      ? `Review portfolios again`
                      : `Back to dashboard`}
                </Button>
              </PageFormButtonContainer>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const NotAvailablePage = (): JSX.Element => {
  const { pdsLink, tmdLink, navigateToPortfolioSelection, variant } =
    usePortfolioSelection();

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
            <Stack spaceY="lg">
              <Stack spaceY="sm">
                <Heading
                  component="h1"
                  variant={3}
                  align={{ xs: 'left', md: 'center' }}
                >
                  Hmm...
                </Heading>

                <Text variant={2}>
                  {`We understand your interest in this product and we appreciate your enthusiasm. However, we have a responsibility to ensure that our products are suitable for our target market.`}
                </Text>

                <Text variant={2}>
                  {`Based on the information you’ve provided in previous attempts, it seems that you may not align with our target market.`}
                </Text>

                <Text variant={2}>
                  {`We suggest you read our PDS and TMD again. It could also be beneficial for you to speak with a financial adviser. If your circumstances change in the future, you’re welcome to come back.`}
                </Text>
              </Stack>

              <Box>
                <Divider color="neutral.050" />
                <ResourceList
                  resources={[
                    {
                      label: 'Product Disclosure Statement',
                      link: pdsLink,
                    },
                    {
                      label: 'Target Market Determination Statement',
                      link: tmdLink,
                    },
                  ]}
                />

                <Divider color="neutral.050" />
              </Box>

              <PageFormButtonContainer>
                <Button
                  aria-label={AccessibilityLabel.CONTINUE}
                  type="submit"
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    if (variant === 'onboarding') {
                      navigateToPortfolioSelection();
                    } else {
                      navigate(Routes.VOYAGER_DASHBOARD);
                    }
                  }}
                >
                  {variant === 'onboarding'
                    ? 'Explore other products'
                    : 'Back to dashboard'}
                </Button>
              </PageFormButtonContainer>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const InReviewPage = (): JSX.Element => {
  const { portfolio, variant } = usePortfolioSelection();
  const gqlPortfolio = fromProtoPortfolio(portfolio);
  const portfolioInfo = gqlPortfolio
    ? voyagerPortfolios[gqlPortfolio]
    : undefined;

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
            <Stack spaceY="lg">
              <Stack spaceY="sm">
                <Box display="flex" justifyContent="center">
                  <StreamlineCheckCircle1Icon
                    color="indigo.070"
                    size="xxxl"
                    strokeWidth={3}
                  />
                </Box>

                <Heading
                  component="h1"
                  variant={3}
                  align={{ xs: 'left', md: 'center' }}
                >
                  We’ve got your responses
                </Heading>

                <Text variant={2}>
                  Thanks for applying to invest in the {portfolioInfo?.title}.
                  We’re reviewing your responses to see if you’re likely to be
                  in the target market.
                </Text>

                <Text variant={2}>
                  We’ll let you know the outcome by email, typically within two
                  business days.
                </Text>
              </Stack>

              {variant === 'onboarding' && (
                <PageFormButtonContainer>
                  <Button
                    aria-label={AccessibilityLabel.CONTINUE}
                    type="submit"
                    variant="primary"
                    size="lg"
                    href={InternalRoutes.VOYAGER_LEARN_MORE}
                  >
                    Done
                  </Button>
                </PageFormButtonContainer>
              )}

              {variant === 'add' && (
                <PageFormButtonContainer>
                  <Button
                    aria-label={AccessibilityLabel.CONTINUE}
                    type="submit"
                    variant="primary"
                    size="lg"
                    onClick={() => {
                      navigate(Routes.VOYAGER_DASHBOARD);
                    }}
                  >
                    Done
                  </Button>
                </PageFormButtonContainer>
              )}
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const ResubmissionConfirmationPage: React.FC<
  React.PropsWithChildren<{
    isActive: boolean;
    loading: boolean;
    onSubmit: () => void;
  }>
> = ({ isActive, loading, onSubmit }) => {
  const form = useFormContext();
  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 9 / 12, lg: 6 / 12 }}>
            <Stack spaceY="md">
              <Heading
                component="h1"
                variant={3}
                align={{ xs: 'left', md: 'center' }}
              >
                It looks like you&apos;ve told us about your investing goals and
                needs before.
                <br />
                Can you tell us why your answers have changed?
              </Heading>

              <Text variant={2}>
                For example, perhaps your objectives, circumstances, needs
                (and/or understanding of the product) have changed?
                <br />
                <br />
                Please be descriptive because your answer will help us determine
                whether you&apos;re likely to be in the target market for this
                product.
              </Text>

              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack spaceY="xl">
                  <ControllerTextArea
                    data-testid="resubmissionConfirmation-text-area"
                    name="resubmissionConfirmation"
                    control={form.control}
                    aria-labelledby="resubmissionConfirmation"
                    rules={{
                      required: isActive
                        ? 'Your response is required'
                        : undefined,
                      maxLength: {
                        value: 1000,
                        message: 'Maximum 1000 characters allowed',
                      },
                      minLength: {
                        value: 50,
                        message: 'Minimum 50 characters needed',
                      },
                    }}
                  />
                </Stack>

                <PageFormButtonContainer>
                  <PageFormContinueButton
                    trackingProperties={{
                      name: 'voyager_tmd_continue',
                    }}
                    isLoading={loading}
                  />
                </PageFormButtonContainer>
              </form>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const ErrorPage = (): JSX.Element => {
  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Error
            title={'Hello'}
            subtitle={GENERIC_ERROR_MESSAGE}
            iconColor="indigo.070"
            buttonText="Go back"
            onContinue={{
              onClick: () => {
                window.history.back();
              },
              trackingProperties: {
                name: '',
              },
            }}
          />
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const TMDQuestionCheckItem: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Box flexDirection="row" display="flex">
    <Box marginRight="xs">
      <FeatherCheckIcon color="indigo.070" size="sm" />
    </Box>

    <Box flex={1}>
      <Text variant={2}>{children}</Text>
    </Box>
  </Box>
);
