import { gql } from '@apollo/client';
import { webAppVoyagerBankAccountCard_account_activeBankAccount } from '@spaceship-fspl/graphql/src/__generated__/webAppVoyagerBankAccountCard';
import { VoyagerBankAccountCard as BankAccountCard } from 'components/account-cards/voyager-bank-account';
import { useNavigateWithBiometricVerificationToBAV } from 'helpers/hooks/use-navigate-with-biometric-status-to-bav';
import React from 'react';

export const voyagerBankAccountCardFragment = gql`
  fragment webAppVoyagerBankAccountCard on Contact {
    id
    account {
      id
      canUpdateBankAccount
      activeBankAccount {
        id
        verified
        friendlyName
        accountName
        bsb
        accountNumber
      }
    }
  }
`;

export const VoyagerBankAccountCard: React.FC<
  React.PropsWithChildren<{
    isLoading: boolean;
    canUpdateBankAccount: boolean;
    bankAccount?: webAppVoyagerBankAccountCard_account_activeBankAccount | null;
  }>
> = ({ isLoading, canUpdateBankAccount, bankAccount }) => {
  const { navigate, isLoading: isLoadingNavigate } =
    useNavigateWithBiometricVerificationToBAV();

  return (
    <BankAccountCard
      variant="account"
      bankAccount={bankAccount}
      edit={
        bankAccount?.verified
          ? {
              isEditing: false,
              isLoading: isLoadingNavigate || isLoading,
              canUpdateBankAccount,
              onClick: () => navigate(),
            }
          : undefined
      }
    />
  );
};
