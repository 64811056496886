import { ApolloError } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Accordion,
  Box,
  Card,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  BiometricVerificationStatus,
  OCRTransactionReason,
  useBiometricVerificationStatus,
  useCreateBiometricVerificationSMS,
} from '@spaceship-fspl/graphql';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import {
  FeatherAlertTriangleIcon,
  FeatherCheckCircleIcon,
  FeatherCheckIcon,
  FeatherInfoIcon,
  StreamlineSingleNeutralShieldIcon,
} from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { IntercomFooter } from 'components/intercom-footer';
import { Modal } from 'components/modal';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { useDismissed } from 'helpers/hooks/dismissed';
import { useMaskedMobileNumber } from 'helpers/hooks/use-masked-mobile-number';
import { addRumError } from 'helpers/monitoring';
import { PersistKey } from 'helpers/persist';
import { Routes } from 'pages/routes';
import React, { FC, useState } from 'react';

export const SecurityCheck: FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const { pop: showIntercom } = useIntercom();
  const { popToast } = useNotifications();
  const maskedMobileNumber = useMaskedMobileNumber();
  const [isSmsModalVisible, setIsSmsModalVisible] = useState(false);
  const [
    initiateBiometricVerification,
    { loading: isInitiateBiometricVerificationLoading },
  ] = useCreateBiometricVerificationSMS({
    onError: (error: ApolloError) => {
      popToast({
        message: GENERIC_ERROR_MESSAGE,
        level: 'error',
      });
      addRumError({ error });
    },
  });

  // When initiating a security check on this device, set this back to 0
  // which enables the showing of the dashboard success and info card.
  //
  // This is to handle the case when a previous biometric verification expires
  // (90 days), status will go from: `VERIFIED` -> `UNVERIFIED`, so when the
  // user initiates again, we want to enable showing the success card on the
  // dashboard.
  const [, resetBiometricVerificationStatusSuccessCard] = useDismissed(
    PersistKey.BIOMETRIC_VERIFICATION_SUCCESS_DISMISSED_UNTIL,
    0, // Reset dismissTimeInMs to show card
  );

  const handleInitiateSmsVerification = async (): Promise<void> => {
    resetBiometricVerificationStatusSuccessCard();
    await initiateBiometricVerification({
      variables: {
        biometricInput: {
          reason: OCRTransactionReason.CHANGE_BANK_ACCOUNT,
        },
      },
    });
    setIsSmsModalVisible(false);
  };

  const {
    data: biometricVerificationStatusData,
    loading: isBiometricVerificationStatusDataLoading,
  } = useBiometricVerificationStatus({
    fetchPolicy: 'network-only',
  });

  const biometricVerificationStatus =
    biometricVerificationStatusData?.contact?.biometricVerificationStatus;

  const SecurityCheckUnverifiedControls: React.FC<
    React.PropsWithChildren
  > = () => (
    <Button
      variant="primary"
      size="lg"
      trackingProperties={{
        name: 'security_check_run_button',
      }}
      onClick={(): void => setIsSmsModalVisible(true)}
      isDisabled={isBiometricVerificationStatusDataLoading}
      isLoading={isBiometricVerificationStatusDataLoading}
    >
      Run security check
    </Button>
  );

  const SecurityCheckInProgressControls: React.FC<
    React.PropsWithChildren
  > = () => (
    <Stack spaceY="md">
      <Card backgroundColor="neutral.030" padding="lg" boxShadow="none">
        <Columns alignY="top" spaceY="md">
          <Columns.Column width={1 / 8}>
            <FeatherCheckIcon color="mint.100" />
          </Columns.Column>

          <Columns.Column width={7 / 8}>
            <Text variant={2} isBold={true}>
              We’ve sent a text to your mobile number {maskedMobileNumber}. If
              you don’t receive this text, please contact our customer support
              team.
            </Text>
          </Columns.Column>

          <Columns.Column width={1 / 8}>
            <FeatherInfoIcon color="indigo.070" />
          </Columns.Column>

          <Columns.Column width={7 / 8}>
            <Text variant={2} isBold={true}>
              Once you complete the security check, please check your email for
              confirmation.
            </Text>
          </Columns.Column>
        </Columns>
      </Card>
    </Stack>
  );

  const renderVerificationControls = (): JSX.Element | null => {
    switch (biometricVerificationStatus) {
      case undefined:
      case BiometricVerificationStatus.UNVERIFIED:
        return <SecurityCheckUnverifiedControls />;

      case BiometricVerificationStatus.IN_PROGRESS:
        return <SecurityCheckInProgressControls />;

      default:
        return null;
    }
  };

  const SecurityCheckVerified: React.FC<React.PropsWithChildren> = () => (
    <Columns alignX="center">
      <Columns.Column width={{ xs: 1, lg: 5 / 12 }}>
        <Stack spaceY={{ xs: 'md' }} alignX="center">
          <FeatherCheckCircleIcon color="mint.100" size="xxxl" />
          <Heading component="h1" variant={3} align="center">
            Congratulations! You have successfully passed our security check.
          </Heading>
          <Button
            variant="primary"
            size="lg"
            trackingProperties={{
              name: 'security_check_link_new_bank_account_button',
            }}
            onClick={() => navigate(Routes.BANK_ACCOUNT)}
          >
            Link new bank account
          </Button>
        </Stack>
      </Columns.Column>
    </Columns>
  );

  const SecurityCheckFailed: React.FC<React.PropsWithChildren> = () => (
    <Columns alignX="center">
      <Columns.Column width={{ xs: 1, lg: 5 / 12 }}>
        <Stack spaceY={{ xs: 'md' }} alignX="center">
          <FeatherAlertTriangleIcon color="gold.100" size="xxxl" />
          <Heading component="h1" variant={3}>
            Oops, we were unable to complete our security check. Please check
            your email for more details.
          </Heading>
          <Button
            variant="primary"
            size="lg"
            trackingProperties={{
              name: 'security_check_contact_support_button',
            }}
            onClick={showIntercom}
          >
            Contact support
          </Button>
        </Stack>
      </Columns.Column>
    </Columns>
  );

  const SecurityCheckDefault: React.FC<React.PropsWithChildren> = () => (
    <>
      <Stack spaceY={{ xs: 'xxl' }}>
        <Columns alignX="center" spaceY="sm">
          <Columns.Column width={{ xs: 1, lg: 5 / 12 }}>
            <Stack spaceY={{ xs: 'xl' }} alignX="center">
              <Stack spaceY={{ xs: 'md' }} alignX="center">
                <StreamlineSingleNeutralShieldIcon
                  size="xxxl"
                  color="indigo.070"
                  strokeWidth={3}
                />
                <Heading component="h1" variant={3}>
                  Security Check
                </Heading>
                <Text variant={2}>
                  To change your bank account, we need to perform a security
                  check. This helps keep Spaceship and your account secure.
                </Text>
                <Text variant={3} color="neutral.080">
                  Spaceship respects your{' '}
                  <TextLink
                    href={InternalRoutes.PRIVACY_POLICY}
                    target="_blank"
                  >
                    privacy
                  </TextLink>
                </Text>
              </Stack>

              {renderVerificationControls()}
            </Stack>
          </Columns.Column>
        </Columns>

        <LearnMoreAccordion />
      </Stack>

      <Modal
        showModal={isSmsModalVisible}
        closeModal={(): void => setIsSmsModalVisible(false)}
      >
        <Box padding="xl">
          <Stack spaceY={{ xs: 'md' }} alignX="center">
            <Stack spaceY={{ xs: 'sm' }} alignX="center">
              <Heading component="h2" variant={4} align="center">
                To begin, we’ll send a text to your mobile number{' '}
                {maskedMobileNumber}.
              </Heading>
              <Text variant={2} align="center">
                If you don’t receive this text, please contact our customer
                support team.
              </Text>
            </Stack>
            <Button
              variant="primary"
              size="lg"
              trackingProperties={{ name: 'security_check_run_button' }}
              onClick={handleInitiateSmsVerification}
              isDisabled={isInitiateBiometricVerificationLoading}
              isLoading={isInitiateBiometricVerificationLoading}
            >
              Send SMS
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );

  const renderVerificationState = (): JSX.Element => {
    switch (biometricVerificationStatus) {
      case BiometricVerificationStatus.VERIFIED:
        return <SecurityCheckVerified />;

      case BiometricVerificationStatus.FAILED:
        return <SecurityCheckFailed />;

      default:
        return <SecurityCheckDefault />;
    }
  };

  return (
    <Box paddingTop={{ xs: 'lg', md: 'xxxl' }}>
      <Container>{renderVerificationState()}</Container>
      <IntercomFooter />
    </Box>
  );
};

const LearnMoreAccordion: React.FC<React.PropsWithChildren> = () => {
  return (
    <Columns alignX="center" spaceY="sm">
      <Columns.Column width={{ xs: 1, lg: 10 / 12 }}>
        <Accordion variant="info" title="Learn more">
          <Text variant={3}>
            To change your bank account, we need to perform a security check.
            This helps keep Spaceship and your account secure.
            <br />
            <br />
            To complete the security check, all you’ll need is a current driver
            licence, passport or proof of age card. We’ll ask you to take a
            photo of your identification document, confirm your details, and do
            a live selfie.
            <br />
            <br />
            Once we complete the security check, you’ll be able to link your new
            bank account.
            <br />
            <br />
            It typically takes us just a few minutes to complete the security
            check, but it may take up to two business days where manual review
            is required.
            <br />
            <br />
            For more information about how we handle your personal details,
            please refer to our{' '}
            <TextLink href={InternalRoutes.PRIVACY_POLICY}>
              Privacy Collection Notice
            </TextLink>
            .
          </Text>
        </Accordion>
      </Columns.Column>
    </Columns>
  );
};
