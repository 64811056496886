import { useApolloClient } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Columns,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { getBoolean, useString } from '@spaceship-fspl/dynamic-config';
import { CenterPageContainer } from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import { config } from 'helpers/config';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const AppDisabled: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const intercom = useIntercom();
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);

  const mainCopy = useString(
    DynamicConfigKeys.APP_DISABLED_COPY,
    'Spaceship is currently undergoing scheduled maintenance.',
  );

  useEffect(() => {
    let mounted = true;
    (async () => {
      setLoading(true);
      await getBoolean(client, DynamicConfigKeys.APP_DISABLED, false);

      if (mounted) {
        setLoading(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [client]);

  return (
    <CenterPageContainer>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 1 / 3 }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginY={{ xs: 'none', lg: 'md' }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Stack spaceY="md">
                <Emoji>
                  <span role="img" aria-label="maintenance">
                    👷
                  </span>
                </Emoji>
                <Text variant={2} align="center">
                  {mainCopy}
                </Text>
                <Text variant={2} align="center">
                  If you have any questions in the mean time, please contact
                  customer support on{' '}
                  <TextLink color="indigo.070" onClick={() => intercom.pop()}>
                    chat
                  </TextLink>
                  ,{' '}
                  <TextLink
                    color="indigo.070"
                    href={`mailto:${config.support.email}`}
                  >
                    {config.support.email}
                  </TextLink>{' '}
                  or{' '}
                  <TextLink
                    color="indigo.070"
                    href={`tel:${config.support.phone}`}
                  >
                    {config.support.phone}
                  </TextLink>{' '}
                </Text>
              </Stack>
            )}
          </Box>
        </Columns.Column>
      </Columns>
    </CenterPageContainer>
  );
};

const Emoji = styled.p`
  text-align: center;
  font-size: 100px;
  line-height: 100px;
`;
