import { getColor } from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

export const Indicator = styled.div<{ $variant: 'up' | 'down' }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  position: relative;
  top: 40%;
  left: 50%;

  ${createProps({
    $variant: createVariant({
      up: css`
        border-bottom: 5px solid ${getColor('mint.050')};
      `,
      down: css`
        border-top: 5px solid ${getColor('red.030')};
      `,
    }),
  })}
`;

export const getIndicator = (
  value?: number | string,
): React.ReactElement | null => {
  if (!value) {
    return null;
  }

  value = Number(value);

  if (value > 0) {
    return <Indicator $variant="up" />;
  } else if (value < 0) {
    return <Indicator $variant="down" />;
  }

  return null;
};
