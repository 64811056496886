import {
  alignItems,
  display,
  getSpace,
  justifyContent,
  map,
  marginBottom,
  Media,
  paddingLeft,
  paddingTop,
  paddingX,
} from '@spaceship-fspl/styles';
import {
  createColumnLayout,
  createContainerLayout,
  createInlineLayout,
  createStackLayout,
  createTileLayout,
} from '@spaceship-fspl/substance-layout';
import { unstable_mapValueToValue } from '@spaceship-fspl/substance-style';

import { Visible } from '../visible';

export const Columns = createColumnLayout({
  map,
  getSpace,
  alignItems,
  paddingTop,
  paddingLeft,
});

export const Container = createContainerLayout({
  paddingX,
  width: 1200,
  space: { xs: 'sm', sm: 'md', xl: 'lg' },
});

export const Inline = createInlineLayout({
  map,
  getSpace,
  alignItems,
  justifyContent,
  paddingTop,
  paddingLeft,
});

export const Stack = createStackLayout({
  display,
  alignItems,
  marginBottom,
  getChildDisplayValue: (child) => {
    if (child.type === Visible) {
      if (process.env.NODE_ENV !== 'production') {
        if (child.props.displayValue !== 'block') {
          console.error(
            'Visible "displayValue" must be "block" when Visible is a direct child of Stack.',
          );
        }
      }
      return unstable_mapValueToValue<Media, boolean, 'block' | 'none'>(
        child.props.isHidden,
        (isHidden) => (isHidden ? 'none' : 'block'),
      );
    }
    return undefined;
  },
});

export const Tiles = createTileLayout({
  map,
  getSpace,
  alignItems,
  justifyContent,
  paddingTop,
  paddingLeft,
});
