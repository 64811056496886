import { ExternalRoutes, InternalRoutes } from '@spaceship-fspl/helpers';
import {
  borderColor,
  color,
  display,
  match,
  paddingBottom,
  paddingLeft,
  paddingTop,
  text,
} from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Columns } from '../layout';
import {
  DefaultLinkComponent,
  PropsWithLinkComponent,
} from '../link-component';
import { UnstyledLink } from '../links';
import { Logo } from '../logo';
import { SocialLinks } from '../social-links';
import { Text, TextProps } from '../text';

const LinkColumns = styled(Columns)`
  & > * {
    justify-content: space-between;
  }
`;

const LinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const LinkListRow = styled.li<{ isBorderless?: boolean }>`
  display: block;
  width: 100%;

  ${paddingTop({ xs: 'sm', md: 'none' })}
  ${paddingBottom({ xs: 'sm', md: 'md' })}
  ${match('md')`
    :last-of-type {
      padding: 0;
    }
  `}

  ${({ isBorderless }) =>
    !isBorderless &&
    css`
      ${match('xs')`
      border-top: 1px solid;
      ${borderColor('indigo.100')}
    `}
      ${match('md')`
      border: none;
    `}
    `}
`;

const LinkListHeading: React.FC<
  Omit<TextProps, 'variant' | 'color' | 'isBold' | 'align'> & {
    children?: React.ReactNode | undefined;
  }
> = ({ children, ...otherProps }) => (
  <Text
    {...otherProps}
    variant={2}
    color="neutral.070"
    isBold
    align={{ xs: 'center', md: 'left' }}
  >
    {children}
  </Text>
);

const LinkListLink = styled(UnstyledLink)<{ isSmall?: boolean }>`
  ${color('neutral.000')}
  ${({ isSmall }) => text({ variant: isSmall ? 3 : 2 })}
  ${display({ xs: 'flex', md: 'block' })}
  :hover {
    text-decoration: underline;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      line-height: 14px;
    `}
`;

interface LinkSectionProps extends PropsWithLinkComponent {
  onChatWithUs: () => void;
  isFirstHomeEnabled?: boolean;
}

const InvestSectionHeader = styled.div`
  ${color('neutral.000')}
  ${text({ variant: 2 })}
  ${display({ xs: 'flex', md: 'block' })}
  ${paddingBottom({ xs: 'xs', md: 'sm' })}
`;

const InvestSectionLinks = styled.div`
  border-left: 1px solid;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  ${borderColor('neutral.000')}
  ${paddingLeft('xs')}
`;

const InvestingSection: React.FC<{
  children?: React.ReactNode | undefined;
}> = ({ children }) => {
  return (
    <>
      <InvestSectionHeader>Investing</InvestSectionHeader>
      <InvestSectionLinks>{children}</InvestSectionLinks>
    </>
  );
};

export const LinksSection: React.FC<LinkSectionProps> = ({
  onChatWithUs,
  LinkComponent = DefaultLinkComponent,
  isFirstHomeEnabled,
}) => (
  <Columns>
    <Columns.Column width={{ xs: 1, lg: 1 / 3 }}>
      <Box
        display={{ xs: 'none', md: 'flex' }}
        flexDirection={{ md: 'row', lg: 'column' }}
        justifyContent="space-between"
        height="100%"
        paddingBottom={{ md: 'lg', lg: 'none' }}
      >
        <Logo variant="light" />
        <Box
          display="flex"
          flexDirection={{ md: 'row', lg: 'column' }}
          justifyContent="space-between"
          alignItems={{ md: 'center', lg: 'flex-start' }}
        >
          <Box
            display="flex"
            justifyContent="center"
            marginRight={{ md: 'sm', lg: 'none' }}
            marginBottom={{ md: 'none', lg: 'sm' }}
          >
            <Text variant={4} color="neutral.070" isBold={true}>
              Follow us on
            </Text>
          </Box>
          <SocialLinks
            size="sm"
            variant="light"
            orientation="horizontal"
            space="xs"
          />
        </Box>
      </Box>
    </Columns.Column>
    <Columns.Column width={{ xs: 1, lg: 2 / 3 }}>
      <LinkColumns spaceX={{ xs: 'none', md: 'md' }}>
        <Columns.Column width={{ xs: 1, md: 'min' }}>
          <LinkList>
            <LinkListRow isBorderless>
              <LinkListHeading>Products</LinkListHeading>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/">
                {(props) => <LinkListLink {...props}>Home</LinkListLink>}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <InvestingSection>
                <LinkComponent href="/voyager/">
                  {(props) => (
                    <LinkListLink isSmall {...props}>
                      Spaceship Voyager
                    </LinkListLink>
                  )}
                </LinkComponent>
                <LinkComponent href="/us-investing/">
                  {(props) => (
                    <LinkListLink isSmall {...props}>
                      US Investing
                    </LinkListLink>
                  )}
                </LinkComponent>
              </InvestingSection>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/super/">
                {(props) => <LinkListLink {...props}>Super</LinkListLink>}
              </LinkComponent>
            </LinkListRow>

            {isFirstHomeEnabled && (
              <LinkListRow>
                <LinkComponent href="/first-home/">
                  {(props) => (
                    <LinkListLink {...props}>First Home</LinkListLink>
                  )}
                </LinkComponent>
              </LinkListRow>
            )}

            <LinkListRow>
              <LinkComponent href="/learn/">
                {(props) => <LinkListLink {...props}>Learn</LinkListLink>}
              </LinkComponent>
            </LinkListRow>
          </LinkList>
        </Columns.Column>
        <Columns.Column width={{ xs: 1, md: 'min' }}>
          <LinkList>
            <LinkListRow>
              <LinkListHeading>Explore</LinkListHeading>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/about/">
                {(props) => <LinkListLink {...props}>About us</LinkListLink>}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/careers/">
                {(props) => <LinkListLink {...props}>Careers</LinkListLink>}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/news/">
                {(props) => <LinkListLink {...props}>News</LinkListLink>}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkListLink
                href={ExternalRoutes.MEDIUM}
                rel="noopener"
                target="_blank"
              >
                Life Aboard
              </LinkListLink>
            </LinkListRow>
          </LinkList>
        </Columns.Column>
        <Columns.Column width={{ xs: 1, md: 'min' }}>
          <LinkList>
            <LinkListRow>
              <LinkListHeading>Legal</LinkListHeading>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/important-documents/">
                {(props) => (
                  <LinkListLink {...props}>Important Documents</LinkListLink>
                )}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkListLink
                href={InternalRoutes.FINANCIAL_SERVICES_GUIDE}
                target="_blank"
              >
                Financial Services Guide
              </LinkListLink>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/privacy-policy/">
                {(props) => (
                  <LinkListLink {...props}>Privacy Policy</LinkListLink>
                )}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/privacy-policy/">
                {(props) => (
                  <LinkListLink {...props}>
                    Privacy Collection Notice
                  </LinkListLink>
                )}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href="/app-terms/">
                {(props) => (
                  <LinkListLink {...props}>
                    App Terms and Conditions
                  </LinkListLink>
                )}
              </LinkComponent>
            </LinkListRow>
            <LinkListRow>
              <LinkComponent href={InternalRoutes.COMPLAINTS_POLICY}>
                {(props) => (
                  <LinkListLink {...props}>Complaints Policy</LinkListLink>
                )}
              </LinkComponent>
            </LinkListRow>
          </LinkList>
        </Columns.Column>
        <Columns.Column width={{ xs: 1, md: 'min' }}>
          <LinkList>
            <LinkListRow>
              <LinkListHeading>Contact us</LinkListHeading>
            </LinkListRow>
            <LinkListRow>
              <LinkListLink href="https://help.spaceship.com.au/en/">
                FAQ
              </LinkListLink>
            </LinkListRow>
            <LinkListRow>
              <LinkListLink href="tel:1300 049 532">1300 049 532</LinkListLink>
            </LinkListRow>
            <LinkListRow>
              <LinkListLink href="mailto:hello@spaceship.com.au">
                hello@spaceship.com.au
              </LinkListLink>
            </LinkListRow>
            <LinkListRow>
              <LinkListLink onClick={onChatWithUs}>Chat with us</LinkListLink>
            </LinkListRow>
          </LinkList>
        </Columns.Column>
      </LinkColumns>
    </Columns.Column>
  </Columns>
);
