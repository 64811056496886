import {
  FraudVerificationStatus,
  GreenIdSourceName,
  GreenIdSourceStatus,
  IGreenIdInteractiveSource,
} from '@spaceship-fspl/types/externalapi';

export type DriverLicenceState =
  | 'actregodvs'
  | 'nswregodvs'
  | 'qldregodvs'
  | 'tasregodvs'
  | 'vicregodvs'
  | 'ntregodvs'
  | 'saregodvs'
  | 'waregodvs';

// Shortlist of submittable sources for GreenID
// Complete list of data sources from Green ID API: https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/8880634/Australian+Data+Sources
// More information about flows: https://spaceship-hq.atlassian.net/wiki/spaces/APP/pages/615514163/Green+ID+and+how+it+works
export type IDSourceList =
  | 'citizenshipcertificatedvs'
  | 'medicaredvs'
  | 'passportdvs'
  | 'actregodvs'
  | 'visadvs'
  | DriverLicenceState;

export const DRIVERS_LICENCE_SOURCE_NAMES: IDSourceList[] = [
  'actregodvs',
  'nswregodvs',
  'qldregodvs',
  'tasregodvs',
  'vicregodvs',
  'ntregodvs',
  'saregodvs',
  'waregodvs',
];

export const ID_SOURCE_NAMES: IDSourceList[] = [
  ...DRIVERS_LICENCE_SOURCE_NAMES,
  'citizenshipcertificatedvs',
  'medicaredvs',
  'passportdvs',
  'actregodvs',
  'visadvs',
];

export type State = 'ACT' | 'NT' | 'NSW' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';

export const REGODVS_STATE_MAP: {
  [key in State]: IDSourceList;
} = {
  ACT: 'actregodvs',
  NSW: 'nswregodvs',
  QLD: 'qldregodvs',
  TAS: 'tasregodvs',
  VIC: 'vicregodvs',
  NT: 'ntregodvs',
  SA: 'saregodvs',
  WA: 'waregodvs',
};
export enum IDName {
  AUSTRALA_DRIVER_LICENCE = 'Australian driver licence',
  AUSTRALIAN_PASSPORT = 'Australian passport',
  INTERNATIONAL_PASSPORT = 'International passport',
  MEDICARE_CARD = 'Medicare card',
  AUSTRALIAN_CITIZENSHIP_CERTIFICATE = 'Australian citizenship certificate',
}

export interface IDOption {
  label: IDName;
  value: IDSourceList | IDSourceList[];
}

export const ID_OPTIONS: IDOption[] = [
  {
    label: IDName.AUSTRALA_DRIVER_LICENCE,
    value: DRIVERS_LICENCE_SOURCE_NAMES,
  },
  {
    label: IDName.AUSTRALIAN_PASSPORT,
    value: 'passportdvs',
  },
  {
    label: IDName.INTERNATIONAL_PASSPORT,
    value: 'visadvs',
  },
  {
    label: IDName.MEDICARE_CARD,
    value: 'medicaredvs',
  },
  {
    label: IDName.AUSTRALIAN_CITIZENSHIP_CERTIFICATE,
    value: 'citizenshipcertificatedvs',
  },
];

export const COUNTRIES = [
  { value: '', label: 'Country' },
  { value: 'AFG', label: 'AFGHANISTAN' },
  { value: 'ALB', label: 'ALBANIA' },
  { value: 'DZA', label: 'ALGERIA' },
  { value: 'ASM', label: 'AMERICAN SAMOA' },
  { value: 'AND', label: 'ANDORRA' },
  { value: 'AGO', label: 'ANGOLA' },
  { value: 'AIA', label: 'ANGUILLA' },
  { value: 'ATA', label: 'ANTARCTICA' },
  { value: 'ATG', label: 'ANTIGUA AND BARBUDA' },
  { value: 'ARG', label: 'ARGENTINA' },
  { value: 'ARM', label: 'ARMENIA' },
  { value: 'ABW', label: 'ARUBA' },
  { value: 'AUT', label: 'AUSTRIA' },
  { value: 'AZE', label: 'AZERBAIJAN' },
  { value: 'BHS', label: 'BAHAMAS' },
  { value: 'BHR', label: 'BAHRAIN' },
  { value: 'BGD', label: 'BANGLADESH' },
  { value: 'BRB', label: 'BARBADOS' },
  { value: 'BLR', label: 'BELARUS' },
  { value: 'BEL', label: 'BELGIUM' },
  { value: 'BLZ', label: 'BELIZE' },
  { value: 'BEN', label: 'BENIN' },
  { value: 'BMU', label: 'BERMUDA' },
  { value: 'BTN', label: 'BHUTAN' },
  { value: 'BOL', label: 'BOLIVIA' },
  { value: 'BIH', label: 'BOSNIA & HERZEGOVINA' },
  { value: 'BWA', label: 'BOTSWANA' },
  { value: 'BVT', label: 'BOUVET ISLAND' },
  { value: 'BRA', label: 'BRAZIL' },
  { value: 'GBP', label: 'BRIT -PROTECTED PERS' },
  { value: 'GBD', label: 'BRIT DEPEND TERRCIT' },
  { value: 'IOT', label: 'BRIT INDIAN OCN TERR' },
  { value: 'GBR', label: 'BRITISH -CITIZEN' },
  { value: 'GBO', label: 'BRITISH -O/SEAS CITZ' },
  { value: 'GBS', label: 'BRITISH -SUBJECT' },
  { value: 'GBN', label: 'BRITISH NATIONAL OVERSEAS' },
  { value: 'BRN', label: 'BRUNEI DARUSSALAM' },
  { value: 'BGR', label: 'BULGARIA' },
  { value: 'BFA', label: 'BURKINA FASO (BFA)' },
  { value: 'HVO', label: 'BURKINA FASO (HVO)' },
  { value: 'BUR', label: 'BURMA' },
  { value: 'BDI', label: 'BURUNDI' },
  { value: 'BYS', label: 'Byelorussian - Soviet Socialist Republic' },
  { value: 'KHM', label: 'CAMBODIA' },
  { value: 'CMR', label: 'CAMEROON' },
  { value: 'CAN', label: 'CANADA' },
  { value: 'CPV', label: 'CAPE VERDE' },
  { value: 'CYM', label: 'CAYMAN ISLANDS' },
  { value: 'CAF', label: 'CENTRAL AFRICAN REP' },
  { value: 'TCD', label: 'CHAD' },
  { value: 'CHL', label: 'CHILE' },
  { value: 'CHN', label: 'CHINA' },
  { value: 'CXR', label: 'CHRISTMAS ISLAND' },
  { value: 'CCK', label: 'COCOS (KEELING) ISL.' },
  { value: 'COL', label: 'COLOMBIA' },
  { value: 'COM', label: 'COMOROS' },
  { value: 'COG', label: 'CONGO' },
  { value: 'COK', label: 'COOK ISLANDS' },
  { value: 'CRI', label: 'COSTA RICA' },
  { value: 'CIV', label: "COTE D'IVOIRE" },
  { value: 'HRV', label: 'CROATIA' },
  { value: 'CUB', label: 'CUBA' },
  { value: 'CYP', label: 'CYPRUS' },
  { value: 'CZE', label: 'CZECH REPUBLIC' },
  { value: 'CSK', label: 'CZECHOSLOVAKIA' },
  { value: 'COD', label: 'DEM REP OF THE CONGO' },
  { value: 'DNK', label: 'DENMARK' },
  { value: 'DJI', label: 'DJIBOUTI' },
  { value: 'DMA', label: 'DOMINICA' },
  { value: 'DOM', label: 'DOMINICAN REPUBLIC' },
  { value: 'TMP', label: 'EAST TIMOR' },
  { value: 'ECU', label: 'ECUADOR' },
  { value: 'EGY', label: 'EGYPT' },
  { value: 'SLV', label: 'EL SALVADOR' },
  { value: 'GNQ', label: 'EQUATORIAL GUINEA' },
  { value: 'ERI', label: 'ERITREA' },
  { value: 'EST', label: 'ESTONIA' },
  { value: 'ETH', label: 'ETHIOPIA' },
  { value: 'FLK', label: 'FALKLAND ISLANDS' },
  { value: 'FRO', label: 'FAROE ISLANDS' },
  { value: 'FJI', label: 'FIJI' },
  { value: 'FIN', label: 'FINLAND' },
  { value: 'FRA', label: 'FRANCE' },
  { value: 'FXX', label: 'FRANCE, METROPOLITAN' },
  { value: 'GUF', label: 'FRENCH GUIANA' },
  { value: 'PYF', label: 'FRENCH POLYNESIA' },
  { value: 'ATF', label: 'FRENCH SOUTHERN TERR' },
  { value: 'MKD', label: 'FYR MACEDONIA' },
  { value: 'GAB', label: 'GABON' },
  { value: 'GMB', label: 'GAMBIA' },
  { value: 'GEO', label: 'GEORGIA' },
  { value: 'SGS', label: 'GEORGIA/SANDWICH ISL' },
  { value: 'D', label: 'GERMANY' },
  { value: 'DDR', label: 'GERMANY (DEMOCRATIC REPUBLIC OF)' },
  { value: 'GHA', label: 'GHANA' },
  { value: 'GIB', label: 'GIBRALTAR' },
  { value: 'GRC', label: 'GREECE' },
  { value: 'GRL', label: 'GREENLAND' },
  { value: 'GRD', label: 'GRENADA' },
  { value: 'GLP', label: 'GUADELOUPE' },
  { value: 'GUM', label: 'GUAM' },
  { value: 'GTM', label: 'GUATEMALA' },
  { value: 'GIN', label: 'GUINEA' },
  { value: 'GNB', label: 'GUINEA-BISSAU' },
  { value: 'GUY', label: 'GUYANA' },
  { value: 'HTI', label: 'HAITI' },
  { value: 'HMD', label: 'HEARD & MCDONALD ISL' },
  { value: 'HND', label: 'HONDURAS' },
  { value: 'HKG', label: 'HONG KONG SAR' },
  { value: 'HUN', label: 'HUNGARY' },
  { value: 'ISL', label: 'ICELAND' },
  { value: 'IND', label: 'INDIA' },
  { value: 'IDN', label: 'INDONESIA' },
  { value: 'IRN', label: 'IRAN' },
  { value: 'IRQ', label: 'IRAQ' },
  { value: 'IRL', label: 'IRELAND' },
  { value: 'ISR', label: 'ISRAEL' },
  { value: 'ITA', label: 'ITALY' },
  { value: 'JAM', label: 'JAMAICA' },
  { value: 'JPN', label: 'JAPAN' },
  { value: 'JOR', label: 'JORDAN' },
  { value: 'KAZ', label: 'KAZAKHSTAN' },
  { value: 'KEN', label: 'KENYA' },
  { value: 'KIR', label: 'KIRIBATI' },
  { value: 'PRK', label: 'KOREA, NORTH' },
  { value: 'KOR', label: 'KOREA, SOUTH' },
  { value: 'RKS', label: 'KOSOVO' },
  { value: 'KWT', label: 'KUWAIT' },
  { value: 'KGZ', label: 'KYRGYZSTAN' },
  { value: 'LAO', label: 'LAO PEOPLES DEM REP' },
  { value: 'LVA', label: 'LATVIA' },
  { value: 'LBN', label: 'LEBANON' },
  { value: 'LSO', label: 'LESOTHO' },
  { value: 'LBR', label: 'LIBERIA' },
  { value: 'LBY', label: 'LIBYA' },
  { value: 'LIE', label: 'LIECHTENSTEIN' },
  { value: 'LTU', label: 'LITHUANIA' },
  { value: 'LUX', label: 'LUXEMBOURG' },
  { value: 'MAC', label: 'MACAU SAR' },
  { value: 'MDG', label: 'MADAGASCAR' },
  { value: 'MWI', label: 'MALAWI' },
  { value: 'MYS', label: 'MALAYSIA' },
  { value: 'MDV', label: 'MALDIVES' },
  { value: 'MLI', label: 'MALI' },
  { value: 'MLT', label: 'MALTA' },
  { value: 'MHL', label: 'MARSHALL ISLANDS' },
  { value: 'MTQ', label: 'MARTINIQUE' },
  { value: 'MRT', label: 'MAURITANIA' },
  { value: 'MUS', label: 'MAURITIUS' },
  { value: 'MYT', label: 'MAYOTTE' },
  { value: 'MEX', label: 'MEXICO' },
  { value: 'FSM', label: 'MICRONESIA' },
  { value: 'MDA', label: 'MOLDOVA, REPUBLIC OF' },
  { value: 'MCO', label: 'MONACO' },
  { value: 'MNG', label: 'MONGOLIA' },
  { value: 'MNE', label: 'MONTENEGRO' },
  { value: 'MSR', label: 'MONTSERRAT' },
  { value: 'MAR', label: 'MOROCCO' },
  { value: 'MOZ', label: 'MOZAMBIQUE' },
  { value: 'MMR', label: 'MYANMAR' },
  { value: 'NAM', label: 'NAMIBIA' },
  { value: 'NRU', label: 'NAURU' },
  { value: 'NPL', label: 'NEPAL' },
  { value: 'NLD', label: 'NETHERLANDS' },
  { value: 'ANT', label: 'NETHERLANDS ANTILLES' },
  { value: 'NTZ', label: 'NEUTRAL ZONE' },
  { value: 'NCL', label: 'NEW CALEDONIA' },
  { value: 'NZL', label: 'NEW ZEALAND' },
  { value: 'NIC', label: 'NICARAGUA' },
  { value: 'NER', label: 'NIGER' },
  { value: 'NGA', label: 'NIGERIA' },
  { value: 'NIU', label: 'NIUE' },
  { value: 'NFK', label: 'NORFOLK ISLAND' },
  { value: 'MNP', label: 'NORTHERN MARIANA ISL' },
  { value: 'NOR', label: 'NORWAY' },
  { value: 'OMN', label: 'OMAN' },
  { value: 'PAK', label: 'PAKISTAN' },
  { value: 'PLW', label: 'PALAU' },
  { value: 'PSE', label: 'PALESTINIAN AUTHRTY' },
  { value: 'PAN', label: 'PANAMA' },
  { value: 'PNG', label: 'PAPUA NEW GUINEA' },
  { value: 'PRY', label: 'PARAGUAY' },
  { value: 'PER', label: 'PERU' },
  { value: 'PHL', label: 'PHILIPPINES' },
  { value: 'PCN', label: 'PITCAIRN' },
  { value: 'POL', label: 'POLAND' },
  { value: 'PRT', label: 'PORTUGAL' },
  { value: 'PRI', label: 'PUERTO RICO' },
  { value: 'QAT', label: 'QATAR' },
  { value: 'XXB', label: 'REFUGEE AS PER ART 1' },
  { value: 'XXC', label: 'REFUGEE OTHER' },
  { value: 'REU', label: 'REUNION' },
  { value: 'ROU', label: 'ROMANIA' },
  { value: 'ROM', label: 'ROMANIA PRE 1/2/2002' },
  { value: 'RUS', label: 'RUSSIAN FEDERATION' },
  { value: 'RWA', label: 'RWANDA' },
  { value: 'LCA', label: 'SAINT LUCIA' },
  { value: 'WSM', label: 'SAMOA' },
  { value: 'SMR', label: 'SAN MARINO' },
  { value: 'STP', label: 'SAO TOME & PRINCIPE' },
  { value: 'SAU', label: 'SAUDI ARABIA' },
  { value: 'SEN', label: 'SENEGAL' },
  { value: 'SRB', label: 'SERBIA' },
  { value: 'SCG', label: 'SERBIA AND MONTENEGRO' },
  { value: 'SYC', label: 'SEYCHELLES' },
  { value: 'SLE', label: 'SIERRA LEONE' },
  { value: 'SGP', label: 'SINGAPORE' },
  { value: 'SVK', label: 'SLOVAK REPUBLIC' },
  { value: 'SVN', label: 'SLOVENIA' },
  { value: 'SLB', label: 'SOLOMON ISLANDS' },
  { value: 'SOM', label: 'SOMALIA' },
  { value: 'ZAF', label: 'SOUTH AFRICA' },
  { value: 'SSD', label: 'SOUTH SUDAN' },
  { value: 'SUN', label: 'SOVIET UNION' },
  { value: 'ESP', label: 'SPAIN' },
  { value: 'LKA', label: 'SRI LANKA' },
  { value: 'SHN', label: 'ST HELENA' },
  { value: 'KNA', label: 'ST KITTS & NEVIS' },
  { value: 'SPM', label: 'ST PIERRE & MIQUELON' },
  { value: 'VCT', label: 'ST VINCENT/GRENADINE' },
  { value: 'XXA', label: 'STATELESS PERSON' },
  { value: 'SDN', label: 'SUDAN' },
  { value: 'SUR', label: 'SURINAME' },
  { value: 'SJM', label: 'SVALBARD & JAN MAYEN' },
  { value: 'SWZ', label: 'SWAZILAND' },
  { value: 'SWE', label: 'SWEDEN' },
  { value: 'CHE', label: 'SWITZERLAND' },
  { value: 'SYR', label: 'SYRIA' },
  { value: 'TWN', label: 'TAIWAN' },
  { value: 'TJK', label: 'TAJIKISTAN' },
  { value: 'TZA', label: 'TANZANIA' },
  { value: 'THA', label: 'THAILAND' },
  { value: 'TLS', label: 'TIMOR-LESTE' },
  { value: 'TGO', label: 'TOGO' },
  { value: 'TKL', label: 'TOKELAU' },
  { value: 'TON', label: 'TONGA' },
  { value: 'TTO', label: 'TRINIDAD AND TOBAGO' },
  { value: 'TUN', label: 'TUNISIA' },
  { value: 'TUR', label: 'TURKEY' },
  { value: 'TKM', label: 'TURKMENISTAN' },
  { value: 'TCA', label: 'TURKS AND CAICOS ISL' },
  { value: 'TUV', label: 'TUVALU' },
  { value: 'UMI', label: 'U.S MINOR ISLANDS' },
  { value: 'UGA', label: 'UGANDA' },
  { value: 'UKR', label: 'UKRAINE' },
  { value: 'ARE', label: 'UNITED ARAB EMIRATES' },
  { value: 'UNA', label: 'UNITED NATIONS AGNCY' },
  { value: 'UNO', label: 'UNITED NATIONS ORG' },
  { value: 'USA', label: 'UNITED STATES' },
  { value: 'UNK', label: 'UNMIK TRAVEL DOC' },
  { value: 'XXX', label: 'UNSPCFED NATIONALITY' },
  { value: 'URY', label: 'URUGUAY' },
  { value: 'UZB', label: 'UZBEKISTAN' },
  { value: 'VUT', label: 'VANUATU' },
  { value: 'VAT', label: 'VATICAN CITY STATE' },
  { value: 'VEN', label: 'VENEZUELA' },
  { value: 'VNM', label: 'VIETNAM' },
  { value: 'VGB', label: 'VIRGIN ISLANDS (BRIT' },
  { value: 'VIR', label: 'VIRGIN ISLANDS (U.S)' },
  { value: 'WLF', label: 'WALLIS & FUTUNA ISL' },
  { value: 'ESH', label: 'WESTERN SAHARA' },
  { value: 'YEM', label: 'YEMEN, REPUBLIC OF' },
  { value: 'YMD', label: 'YEMEN (DEMOCRATIC PEOPLES REPUBLIC)' },
  { value: 'YUG', label: 'YUGOSLAVIA' },
  { value: 'ZAR', label: 'ZAIRE' },
  { value: 'ZMB', label: 'ZAMBIA' },
  { value: 'ZWE', label: 'ZIMBABWE' },
];

export const DRIVER_LICENCE_SOURCE_STATES = [
  { value: 'nswregodvs', label: 'New South Wales' },
  { value: 'vicregodvs', label: 'Victoria' },
  { value: 'qldregodvs', label: 'Queensland' },
  { value: 'saregodvs', label: 'South Australia' },
  { value: 'waregodvs', label: 'Western Australia' },
  { value: 'tasregodvs', label: 'Tasmania' },
  { value: 'actregodvs', label: 'Australian Capital Territory' },
  { value: 'ntregodvs', label: 'Northern Territory' },
];

export enum MedicareCardType {
  AUSTRALIAN_CITIZEN_CARD = 'G',
  INTERIM_MEDICARE_CARD = 'B',
  RECIPROCAL_HEALTH_CARE_CARD = 'Y',
}

export const MEDICARE_CARD_TYPES: {
  label: string;
  value: MedicareCardType;
}[] = [
  {
    label: 'Australian resident card (green)',
    value: MedicareCardType.AUSTRALIAN_CITIZEN_CARD,
  },
  {
    label: 'Interim Medicare card (blue)',
    value: MedicareCardType.INTERIM_MEDICARE_CARD,
  },
  {
    label: 'Reciprocal Health Care Agreement (RHCA) card (yellow)',
    value: MedicareCardType.RECIPROCAL_HEALTH_CARE_CARD,
  },
];

export enum GreenIdTermsAndCondition {
  ON = 'on',
  OFF = 'off',
}

export interface ForeignPassportFields {
  greenid_visadvs_givenname: string;
  greenid_visadvs_middlename: string;
  greenid_visadvs_surname: string;
  greenid_visadvs_dob: string;
  greenid_visadvs_passport_number: string;
  greenid_visadvs_country_of_issue: string;
  greenid_visadvs_tandc: GreenIdTermsAndCondition;
}

export interface MedicareCardFields {
  greenid_medicaredvs_cardColour: MedicareCardType;
  greenid_medicaredvs_number: string;
  greenid_medicaredvs_dob: string;
  greenid_medicaredvs_individualReferenceNumber: string;
  greenid_medicaredvs_nameOnCard: string;
  greenid_medicaredvs_expiry: string;
  greenid_medicaredvs_tandc: GreenIdTermsAndCondition;
}

export interface DriverLicenceFields {
  state: DriverLicenceState;
  number: string;
  cardNumber: string;
  givenname: string;
  middlename: string;
  surname: string;
  dob: string;
  tandc: GreenIdTermsAndCondition;
}

export interface AustraliaPassportFields {
  greenid_passportdvs_number: string;
  greenid_passportdvs_givenname: string;
  greenid_passportdvs_middlename: string;
  greenid_passportdvs_surname: string;
  greenid_passportdvs_dob: string;
  greenid_passportdvs_tandc: GreenIdTermsAndCondition;
}

export interface AustralianCitizenshipFields {
  greenid_citizenshipcertificatedvs_acquisition_date: string;
  greenid_citizenshipcertificatedvs_stock_number: string;
  greenid_citizenshipcertificatedvs_givenname: string;
  greenid_citizenshipcertificatedvs_middlename: string;
  greenid_citizenshipcertificatedvs_surname: string;
  greenid_citizenshipcertificatedvs_dob: string;
  greenid_citizenshipcertificatedvs_tandc: GreenIdTermsAndCondition;
}

export const idSourceTypeToProtos: {
  [k in IDSourceList]: GreenIdSourceName.Enum;
} = {
  citizenshipcertificatedvs: GreenIdSourceName.Enum.CITIZENSHIPCERTIFICATEDVS,
  medicaredvs: GreenIdSourceName.Enum.MEDICAREDVS,
  passportdvs: GreenIdSourceName.Enum.PASSPORTDVS,
  actregodvs: GreenIdSourceName.Enum.ACTREGODVS,
  nswregodvs: GreenIdSourceName.Enum.NSWREGODVS,
  qldregodvs: GreenIdSourceName.Enum.QLDREGODVS,
  tasregodvs: GreenIdSourceName.Enum.TASREGODVS,
  vicregodvs: GreenIdSourceName.Enum.VICREGODVS,
  ntregodvs: GreenIdSourceName.Enum.NTREGODVS,
  saregodvs: GreenIdSourceName.Enum.SAREGODVS,
  waregodvs: GreenIdSourceName.Enum.WAREGODVS,
  visadvs: GreenIdSourceName.Enum.VISADVS,
};

export const VERIFIED_FRAUD_STATUSES = [
  FraudVerificationStatus.Enum.MANUALLY_VERIFIED,
  FraudVerificationStatus.Enum.AUTOMATICALLY_VERIFIED,
];

export const IN_PROGRESS_FRAUD_STATUSES = [
  FraudVerificationStatus.Enum.PENDING,
  FraudVerificationStatus.Enum.DOCUMENT_UPLOAD_REQUIRED,
  FraudVerificationStatus.Enum.DOCUMENT_IN_REVIEW,
];

export const REJECTED_FRAUD_STATUSES = [
  FraudVerificationStatus.Enum.MANUALLY_REJECTED,
  FraudVerificationStatus.Enum.AUTOMATICALLY_REJECTED,
  FraudVerificationStatus.Enum.ERROR,
  FraudVerificationStatus.Enum.UNKNOWN,
];

export const VERIFIED_STATUSES = [
  GreenIdSourceStatus.Enum.VERIFIED,
  GreenIdSourceStatus.Enum.VERIFIED_ADMIN,
  GreenIdSourceStatus.Enum.VERIFIED_WITH_CHANGES,
];

export const PENDING_STATUSES = [GreenIdSourceStatus.Enum.PENDING];

export const REJECTED_STATUSES = [
  GreenIdSourceStatus.Enum.AUTOFAIL,
  GreenIdSourceStatus.Enum.ERROR,
  GreenIdSourceStatus.Enum.FAILED,
  GreenIdSourceStatus.Enum.IN_PROGRESS,
  GreenIdSourceStatus.Enum.LOCKED_OUT,
  GreenIdSourceStatus.Enum.NOT_CONTRIBUTING,
  GreenIdSourceStatus.Enum.UNKNOWN,
];

export interface SourceExhaustedInfo {
  isSourceExhausted: boolean;
  isFieldsSubmissionRejected: boolean;
  documentUploads: IGreenIdInteractiveSource[];
  fieldSubmissions: IGreenIdInteractiveSource[];
  sourceName: IDSourceList | undefined;
}
