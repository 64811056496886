import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from 'components/account-cards/components';
import { LabeledField } from 'components/labeled-field';
import { useVoyagerReferralCode } from 'contexts/deep-linking';
import React from 'react';

export const ReferralSection: React.FC<React.PropsWithChildren> = () => {
  const { value: voyagerReferralCode } = useVoyagerReferralCode();

  if (!voyagerReferralCode) {
    return null;
  }

  return (
    <AccountCard>
      <AccountCardHeading title="Referral" />

      <AccountCardContent>
        <LabeledField label="Referral code" size="md">
          {voyagerReferralCode}
        </LabeledField>
      </AccountCardContent>
    </AccountCard>
  );
};
