import { gql } from '@apollo/client';
import { getFormattedAddress } from '@spaceship-fspl/address';
import { useCanReadSaver } from '@spaceship-fspl/auth';
import { Box, Pill, Stack, Text, TextLink } from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import { addressScalars, UpdateContactInput } from '@spaceship-fspl/graphql';
import { ContactDetailsCard_ContactFragment as ContactDetailsCard_ContactFragmentType } from '@spaceship-fspl/graphql/src/__generated__/ContactDetailsCard_ContactFragment';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { FeatherCheckIcon, FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { AddressFormInputs } from 'components/address/form';
import { ControllerInput } from 'components/controller-input';
import { Input } from 'components/input';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import {
  commonValidationRules,
  DEFAULT_COUNTRY_CODE,
  requiredValidation,
} from 'helpers/validation';
import { AsYouType } from 'libphonenumber-js';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AccountCard,
  AccountCardContent,
  AccountCardEditProps,
  AccountCardHeading,
} from './components';

const validation = {
  email: requiredValidation('Email'),
  phoneNumber: {
    required: 'Mobile is required',
    ...commonValidationRules.phoneNumber,
  },
  street_address: requiredValidation('Residential address'),
  city: requiredValidation('City'),
  state: requiredValidation('State'),
  postcode: requiredValidation('Postcode'),
};

export const ContactDetailsCard_ContactFragment = gql`
  fragment ContactDetailsCard_ContactFragment on Contact {
    id
    email
    emailVerified
    phoneNumber
    phoneNumberMasked
    phoneNumberVerified
    address {
      ...addressScalars
    }
    account {
      id
      novaProductInstance {
        id
      }
    }
  }
  ${addressScalars}
`;

export interface ContactDetailsCardProps {
  contact?: ContactDetailsCard_ContactFragmentType | null;
  onSubmit: (payload: UpdateContactInput) => Promise<void>;
  edit?: AccountCardEditProps;
  isReadOnly?: boolean;
  isSignup?: boolean;
}

export const ContactDetailsCard: React.FC<
  React.PropsWithChildren<ContactDetailsCardProps>
> = ({ children, contact, onSubmit, isReadOnly, edit, isSignup }) => {
  const formMethods = useForm<UpdateContactInput>();
  const { handleSubmit, reset, control } = formMethods;
  const isVoyagerUser = useCanReadSaver();
  const w8benURL = useString(
    DynamicConfigKeys.NOVA_W8BEN_FAQ_LINK,
    InternalRoutes.NOVA_W8BEN,
  );

  useEffect(() => {
    let mounted = true;

    if (contact && mounted) {
      reset({
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        address: {
          postcode: contact.address?.postcode ?? '',
          state: contact.address?.state ?? '',
          suburb: contact.address?.suburb ?? '',
          streetName: contact.address?.streetName ?? '',
          streetNumber: contact.address?.streetNumber ?? '',
          streetType: contact.address?.streetType ?? '',
          unitNumber: contact.address?.unitNumber,
          country: contact.address?.country ?? 'AU',
        },
      });
    }

    return () => {
      mounted = false;
    };
  }, [contact, reset]);

  return (
    <AccountCard>
      <AccountCardHeading
        title="Contact Information"
        isReadOnly={isReadOnly}
        edit={{
          ...edit,
          editTrackingProperties: {
            name: 'signup_summary_contact_details_card_edit',
          },
          submitTrackingProperties: {
            name: 'signup_summary_contact_details_edit_save',
          },
          cancelTrackingProperties: {
            name: 'signup_summary_contact_details_edit_cancel',
          },
          isEditing: !!edit?.isEditing,
          onSubmit: handleSubmit(onSubmit),
        }}
      />

      <AccountCardContent>
        {edit?.isEditing ? (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spaceY="md">
                <ControllerInput
                  name="email"
                  type="email"
                  placeholder="Email address"
                  control={control}
                  rules={validation.email}
                />

                <ControllerInput
                  name="phoneNumber"
                  control={control}
                  type="tel"
                  placeholder="Mobile number"
                  rules={{
                    required: validation.phoneNumber.required,
                    validate: (value?: string | null) => {
                      if (value) {
                        return validation.phoneNumber.validate(
                          value,
                          isSignup ?? false,
                        );
                      }
                      return true;
                    },
                    onBlur: (
                      event: React.FocusEvent<HTMLInputElement>,
                    ): void => {
                      event.target.value = new AsYouType(
                        DEFAULT_COUNTRY_CODE,
                      ).input(event.target.value);
                    },
                  }}
                />

                {isVoyagerUser &&
                contact?.address?.country.toLowerCase() !== 'au' ? (
                  <Stack spaceY="xxs">
                    <Input
                      name="address"
                      type="text"
                      placeholder="Residential address"
                      value={getFormattedAddress(contact?.address)}
                      disabled={true}
                    />

                    <Text variant={3}>
                      Your residential address is currently not an Australian
                      address. To update, please edit your tax residency{' '}
                      <RouterLink
                        to={Routes.VOYAGER_TAX_RESIDENCY}
                        trackingProperties={{
                          name: 'contact_details_card_tax_residency',
                        }}
                      >
                        <TextLink color="indigo.070" component="span">
                          here
                        </TextLink>
                      </RouterLink>
                      .
                    </Text>
                  </Stack>
                ) : (
                  <>
                    <AddressFormInputs />

                    {!!contact?.account?.novaProductInstance?.id && (
                      <Box
                        backgroundColor="neutral.015"
                        borderRadius="xs"
                        padding="sm"
                        display="flex"
                      >
                        <FeatherInfoIcon color="indigo.070" />
                        <Box flex={1} marginLeft="xs">
                          <Text variant={3}>
                            We’ll notify our US broker-dealer about your address
                            change by sending a new{' '}
                            <TextLink
                              href={w8benURL}
                              target="_blank"
                              color="indigo.070"
                              rel="noopener noreferrer"
                            >
                              W-8BEN form
                            </TextLink>
                            . Changing your address will temporarily halt your
                            ability to transfer your US securities to another
                            broker while our US Investing service provider
                            approves your address change. This should take 1-2
                            business days.
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Stack>

              {children}
            </form>
          </FormProvider>
        ) : (
          <Stack spaceY="sm">
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="sm"
            >
              <LabeledField label="Email address" size="md">
                {contact?.email}
              </LabeledField>

              {contact?.emailVerified && (
                <Pill variant={'success'} data-testid="email-verified-pill">
                  verified
                  <Box paddingLeft="xxxs" lineHeight={0}>
                    <FeatherCheckIcon size="sm" />
                  </Box>
                </Pill>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="sm"
            >
              <LabeledField label="Mobile number" size="md">
                {contact?.phoneNumberMasked}
              </LabeledField>

              {contact?.phoneNumberVerified && (
                <Pill variant={'success'} data-testid="phone-verified-pill">
                  verified
                  <Box paddingLeft="xxxs" lineHeight={0}>
                    <FeatherCheckIcon size="sm" />
                  </Box>
                </Pill>
              )}
            </Box>

            <LabeledField label="Residential address" size="md">
              {getFormattedAddress(contact?.address)}
            </LabeledField>
          </Stack>
        )}
      </AccountCardContent>
    </AccountCard>
  );
};

ContactDetailsCard.displayName = 'ContactDetailsCard';
