import * as React from 'react';
export const Lightning = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.175 13.376L15.57 1.223a.643.643 0 011.081.667l-3.194 7.65h5.363a.642.642 0 01.468 1.084L7.895 22.777a.643.643 0 01-1.081-.667l3.194-7.65H4.643a.642.642 0 01-.468-1.084z"
    />
  </svg>
);
