import {
  Color,
  color,
  fontWeight,
  heading,
  HeadingVariant,
  MixinFunctionValue,
  ResponsiveValue,
  textAlign,
} from '@spaceship-fspl/styles';
import { createProps } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled from 'styled-components';

export type HeadingAlignment = 'left' | 'center' | 'right';

const H = styled.p<{
  variant: ResponsiveValue<HeadingVariant>;
  textAlign?: MixinFunctionValue<'text-align'>;
  textColor?: ResponsiveValue<Color>;
  fontWeight?: MixinFunctionValue<'font-weight'>;
}>`
  margin: 0;
  ${heading}
  ${createProps({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: fontWeight as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textAlign: textAlign as any,
    textColor: color,
  })}
`;

export interface HeadingProps {
  variant: ResponsiveValue<HeadingVariant>;
  align?: ResponsiveValue<HeadingAlignment>;
  color?: ResponsiveValue<Color>;
  isBold?: boolean;
  className?: string;
  component?: React.ElementType;
}

export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  align,
  color = 'neutral.100',
  component,
  isBold = false,
  ...props
}) => (
  <H
    {...props}
    as={component}
    textAlign={align}
    textColor={color}
    fontWeight={isBold === true ? '700' : undefined}
  />
);
