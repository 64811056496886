import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Container,
  Divider,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  useAppStoreLinks,
  useSuperPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { VoyagerMobileAppPromotion } from 'components/mobile-app-promotion';
import React from 'react';

export const OnboardingFooter: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const appStoreLinksResp = useAppStoreLinks();
  const superFlagsResp = useSuperPortfolioFlags();
  const sftoneSuperActive =
    superFlagsResp.data?.superPortfolioFlags?.sftOneSuperActive;
  return (
    <footer>
      <Box backgroundColor="neutral.100">
        <Container>
          <Box paddingX={{ lg: 'xxxl' }} paddingY={{ xs: 'xxl', lg: 'xxxl' }}>
            <VoyagerMobileAppPromotion
              variant="signup"
              theme="light"
              size="lg"
              appleAppStoreUrl={
                appStoreLinksResp.data?.appleAppStoreDeeplink?.url
              }
              googlePlayStoreUrl={
                appStoreLinksResp.data?.googlePlayStoreDeeplink?.url
              }
            />
          </Box>

          <Divider color="neutral.085" />

          <Box paddingY="xl">
            <Text variant={4} color="neutral.030">
              Spaceship Capital Limited (ABN 67 621 011 649, AFSL 501605) is the
              issuer of the Spaceship Origin Portfolio, Spaceship Universe
              Portfolio, and Spaceship Earth Portfolio, as well as the Spaceship
              Master Fund (of which the Spaceship Galaxy Portfolio and Spaceship
              Explorer Portfolio are investment options) (collectively,
              Spaceship Voyager).
              <br />
              <br />
              {sftoneSuperActive ? (
                <>
                  Spaceship Capital Limited is the promoter of Spaceship Super.
                  Spaceship Super is issued by Diversa Trustees Limited (ABN 49
                  006 421 638, AFSL 235153, RSEL L0000635) as trustee of
                  OneSuper (ABN 43 905 581 638) (Fund). Spaceship Super is a
                  sub-plan of the Fund.
                </>
              ) : (
                <>
                  Spaceship Capital Limited is the promoter of Spaceship Super.
                  Spaceship Super is issued by Diversa Trustees Limited (ABN 49
                  006 421 638, AFSL 235153, RSEL L0000635) as trustee of the
                  Tidswell Master Superannuation Plan (ABN 34 300 938 877)
                  (Fund). Spaceship Super is a sub-plan of the Fund.
                </>
              )}
              <br />
              <br />
              Spaceship Capital Limited arranges the US Investing service
              through a US broker-dealer.
              <br />
              <br />
              The information on this website is general in nature as it has
              been prepared without taking account of your objectives, financial
              situation or needs. You should consider the relevant{' '}
              <TextLink
                href={InternalRoutes.APP_TERMS}
                target="_blank"
                rel="noreferrer"
                color="neutral.000"
              >
                Terms and Conditions
              </TextLink>
              ,{' '}
              <TextLink
                href={InternalRoutes.IMPORTANT_DOCUMENTS}
                target="_blank"
                rel="noreferrer"
                color="neutral.000"
              >
                Product Disclosure Statement
              </TextLink>{' '}
              and/or{' '}
              <TextLink
                href={InternalRoutes.IMPORTANT_DOCUMENTS}
                target="_blank"
                rel="noreferrer"
                color="neutral.000"
              >
                Reference Guide
              </TextLink>
              , along with the{' '}
              <TextLink
                href={InternalRoutes.IMPORTANT_DOCUMENTS}
                target="_blank"
                rel="noreferrer"
                color="neutral.000"
              >
                Target Market Determination
              </TextLink>
              , and obtain appropriate financial and taxation advice, before
              deciding whether Spaceship is right for you.
              <br />
              <br />
              All investment products have an element of risk. As share markets
              go up and down, so too can the value of your investment. Please
              consider the risks involved before you jump on board.
              <br />
              <br />
              Spaceship Voyager and the US Investing service is only available
              to Australian residents.
              <br />
              <br />
              &copy; {new Date().getFullYear()} Spaceship
            </Text>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
