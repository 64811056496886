import { StreamlineCheck1Icon } from '@spaceship-fspl/icons-web';
import { Color, marginTop, ResponsiveValue } from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

import { Box } from '../box';
import { Text } from '../text';

const ItemContainer = styled(Box)`
  :not(:first-child) {
    ${marginTop('sm')}
  }
`;

export const Item: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      textColor?: ResponsiveValue<Color>;
    }>
  >
> = ({ children, textColor }) => (
  <ItemContainer display="flex" flexDirection="row" role="listitem">
    <Box marginTop={{ xs: 'xxxs', xl: 'xxs' }}>
      <StreamlineCheck1Icon color="indigo.020" />
    </Box>
    <Box flex={1} marginLeft="md">
      <Text variant={1} color={textColor}>
        {children}
      </Text>
    </Box>
  </ItemContainer>
);

// TODO: add color prop
export const List: React.FC<React.PropsWithChildren> & {
  Item: typeof Item;
} = ({ children }) => <div role="list">{children}</div>;

List.Item = Item;
