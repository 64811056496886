import { Box } from '@spaceship-fspl/components';
import {
  FlattenInterpolationWithTheme,
  map,
  VariantInterpolationWithTheme,
} from '@spaceship-fspl/styles';
import { createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

type Iteration = 'infinite' | number;

export interface ContainerProps {
  index?: number;
  left?: number; // percentage
  delay?: number;
  iteration?: Iteration;
}

type MovementSize = 'sm' | 'md' | 'lg';
type MovementVariant = 1 | 2 | 3 | 4 | 5;

export interface AnimateProps extends ContainerProps {
  duration: number;
  movementSize: MovementSize;
  movementVariant: MovementVariant;
}

const DEFAULT_ITERATION: Iteration = 'infinite';

const fallKeyframes = keyframes`
  from { top: 0%; }
  to { top: 100%; }
`;

const fadeKeyframes = keyframes`
  0%, 90%, 100% {
    opacity: 0;
  }
  16%, 50% {
    opacity: 0.9;
  }
`;

const animation = ({
  movementSize,
  duration,
  delay = 0,
  iteration = DEFAULT_ITERATION,
}: AnimateProps): FlattenInterpolationWithTheme => css`
  ${duration}ms ${movementSize === 'sm'
    ? 'linear'
    : 'cubic-bezier(.05,1.16,.92,.92)'} ${delay}ms ${iteration} forwards
`;

const fallAnimation = ({
  duration,
  delay = 0,
  iteration = DEFAULT_ITERATION,
}: AnimateProps): FlattenInterpolationWithTheme => css`
  ${fallKeyframes} ${duration}ms cubic-bezier(.36,-0.6,.4,1.01) ${delay}ms ${iteration} forwards
`;

const fadeAnimation = ({
  duration,
  delay = 0,
  iteration = DEFAULT_ITERATION,
}: AnimateProps): FlattenInterpolationWithTheme => css`
  ${fadeKeyframes} ${duration}ms linear ${delay}ms ${iteration} forwards
`;

type MoveKeyframes = Record<
  MovementSize,
  (variant: MovementVariant) => VariantInterpolationWithTheme
>;

const moveRightKeyframes: MoveKeyframes = {
  sm: createVariant({
    1: keyframes`
      from { transform: translateX(0px) rotate(-45deg); }
      to { transform: translateX(60px) rotate(280deg); }
    `,
    2: keyframes`
      from { transform: translateX(0px) rotate(-245deg); }
      to { transform: translateX(40px) rotate(240deg); }
    `,
    3: keyframes`
      from { transform: translateX(0px) rotate(-250deg); }
      to { transform: translateX(55px) rotate(270deg); }
    `,
    4: keyframes`
      from { transform: translateX(0px) rotate(-45deg); }
      to { transform: translateX(40px) rotate(280deg); }
    `,
    5: keyframes`
      from { transform: translateX(0px) rotate(-165deg); }
      to { transform: translateX(80px) rotate(190deg); }
    `,
  }),
  md: createVariant({
    1: keyframes`
      from { transform: translateX(0px) rotate(-45deg); }
      to { transform: translateX(120px) rotate(280deg); }
    `,
    2: keyframes`
      from { transform: translateX(0px) rotate(-245deg); }
      to { transform: translateX(130px) rotate(240deg); }
    `,
    3: keyframes`
      from { transform: translateX(0px) rotate(-250deg); }
      to { transform: translateX(140px) rotate(270deg); }
    `,
    4: keyframes`
      from { transform: translateX(0px) rotate(-45deg); }
      to { transform: translateX(150px) rotate(280deg); }
    `,
    5: keyframes`
      from { transform: translateX(0px) rotate(-165deg); }
      to { transform: translateX(160px) rotate(190deg); }
    `,
  }),
  lg: createVariant({
    1: keyframes`
      from { transform: translateX(0px) rotate(-45deg); }
      to { transform: translateX(250px) rotate(280deg); }
    `,
    2: keyframes`
      from { transform: translateX(0px) rotate(-245deg); }
      to { transform: translateX(300px) rotate(240deg); }
    `,
    3: keyframes`
      from { transform: translateX(0px) rotate(-250deg); }
      to { transform: translateX(350px) rotate(270deg); }
    `,
    4: keyframes`
      from { transform: translateX(0px) rotate(-45deg); }
      to { transform: translateX(400px) rotate(280deg); }
    `,
    5: keyframes`
      from { transform: translateX(0px) rotate(-165deg); }
      to { transform: translateX(450px) rotate(190deg); }
    `,
  }),
};

const moveLeftKeyframes: MoveKeyframes = {
  sm: createVariant({
    1: keyframes`
      from { transform: translateX(0px) rotate(60deg); }
      to { transform: translateX(-50px) rotate(-170deg); }
    `,
    2: keyframes`
      from { transform: translateX(0px) rotate(122deg); }
      to { transform: translateX(-45px) rotate(-360deg); }
    `,
    3: keyframes`
      from { transform: translateX(0px) rotate(210deg); }
      to { transform: translateX(-55px) rotate(-310deg); }
    `,
    4: keyframes`
      from { transform: translateX(0px) rotate(122deg); }
      to { transform: translateX(-50px) rotate(-360deg); }
    `,
    5: keyframes`
      from { transform: translateX(0px) rotate(210deg); }
      to { transform: translateX(-45px) rotate(-310deg); }
    `,
  }),
  md: createVariant({
    1: keyframes`
      from { transform: translateX(0px) rotate(60deg); }
      to { transform: translateX(-120px) rotate(-170deg); }
    `,
    2: keyframes`
      from { transform: translateX(0px) rotate(122deg); }
      to { transform: translateX(-135px) rotate(-360deg); }
    `,
    3: keyframes`
      from { transform: translateX(0px) rotate(210deg); }
      to { transform: translateX(-140px) rotate(-310deg); }
    `,
    4: keyframes`
      from { transform: translateX(0px) rotate(122deg); }
      to { transform: translateX(-150px) rotate(-360deg); }
    `,
    5: keyframes`
      from { transform: translateX(0px) rotate(210deg); }
      to { transform: translateX(-165px) rotate(-310deg); }
    `,
  }),
  lg: createVariant({
    1: keyframes`
      from { transform: translateX(0px) rotate(60deg); }
      to { transform: translateX(-250px) rotate(-170deg); }
    `,
    2: keyframes`
      from { transform: translateX(0px) rotate(122deg); }
      to { transform: translateX(-290px) rotate(-360deg); }
    `,
    3: keyframes`
      from { transform: translateX(0px) rotate(210deg); }
      to { transform: translateX(-350px) rotate(-310deg); }
    `,
    4: keyframes`
      from { transform: translateX(0px) rotate(122deg); }
      to { transform: translateX(-410px) rotate(-360deg); }
    `,
    5: keyframes`
      from { transform: translateX(0px) rotate(210deg); }
      to { transform: translateX(-450px) rotate(-310deg); }
    `,
  }),
};

const StyledAnimate = styled(Box)<AnimateProps>`
  left: ${({ left }) => left ?? 0}%;
  opacity: 0;
  position: absolute;
  transform-origin: center;
`;

export const AnimateRight = styled(StyledAnimate)<AnimateProps>`
  ${({ movementSize, movementVariant }) =>
    map(movementSize, (s) =>
      map(
        movementVariant,
        (v) => css`
          animation:
            ${fallAnimation},
            ${fadeAnimation},
            ${moveRightKeyframes[s](v)} ${animation};
        `,
      ),
    )}
`;

export const AnimateLeft = styled(StyledAnimate)<AnimateProps>`
  ${({ movementSize, movementVariant }) =>
    map(movementSize, (s) =>
      map(
        movementVariant,
        (v) => css`
          animation:
            ${fallAnimation},
            ${fadeAnimation},
            ${moveLeftKeyframes[s](v)} ${animation};
        `,
      ),
    )}
`;

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  left = 0, // additional left positioning added to all confetti shapes
  delay = 0, // additional delay added to all confetti shapes
  iteration = DEFAULT_ITERATION, // child iteration prop can overwrite
}) => {
  return (
    <Box height="100%" width="100%" position="relative">
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement<ContainerProps>(child)) {
          return React.cloneElement(child, {
            index,
            left: left + (child.props.left ?? 0),
            delay: delay + (child.props.delay ?? 0),
            iteration: child.props.iteration ?? iteration,
          });
        }
        return child;
      })}
    </Box>
  );
};
