import { gql, useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';
import {
  ActionLink,
  Box,
  Card,
  Divider,
  DynamicIcon,
  Heading,
  Inline,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  BasiqConnectionStatus,
  BoostRecipeStatus,
} from '@spaceship-fspl/graphql';
import {
  WebAppToggleBoostRecipeStatus,
  WebAppToggleBoostRecipeStatusVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppToggleBoostRecipeStatus';
import { useRenderTemplate } from '@spaceship-fspl/helpers';
import {
  StreamlineAccountingCoinsIcon,
  StreamlineCashPaymentBillsIcon,
  StreamlineCoffeeToGoIcon,
} from '@spaceship-fspl/icons-web';
import { getBoostRecipeTemplateVariables } from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { InlineError } from 'components/inline-error';
import { RouterLink } from 'components/router-link';
import { Toggle } from 'components/toggle';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useState } from 'react';

import { RemoveBoostModal } from '../components/remove-boost-modal';
import { BoostBasiqConnection, BoostRecipe } from './types';

interface BoostsDashboardBoostsProps {
  sortedRecipes: Array<BoostRecipe>;
  connections: Array<BoostBasiqConnection>;
}

export const BoostsDashboardBoosts: React.FC<
  React.PropsWithChildren<BoostsDashboardBoostsProps>
> = ({ sortedRecipes, connections }) => {
  const navigate = useNavigate();
  const [showRemoveBoostModal, setShowRemoveBoostModal] = useState(false);
  const [selectedRecipeId, setSelectedRecipeId] = useState<
    string | undefined
  >();

  return (
    <>
      <Stack spaceY="xl" alignX="center">
        {sortedRecipes.length > 0 ? (
          <Stack spaceY="md">
            {sortedRecipes.map((recipe) => (
              <RecipeCard
                key={recipe.id}
                hasConnections={connections.length > 0}
                onClickRemoveRecipe={(recipeId) => {
                  setSelectedRecipeId(recipeId);
                  setShowRemoveBoostModal(true);
                }}
                {...recipe}
              />
            ))}
          </Stack>
        ) : (
          <Card
            paddingX={{ xs: 'md', md: 'lg' }}
            paddingTop={{ xs: 'xl', md: 'xxl' }}
            paddingBottom={{ xs: 'lg', md: 'xxl' }}
            boxShadow="sm"
            borderRadius="sm"
          >
            <Stack spaceY={{ xs: 'sm', md: 'md' }}>
              <Inline spaceX="xxs" alignX="center">
                <StreamlineAccountingCoinsIcon color="indigo.070" size="xl" />
                <StreamlineCashPaymentBillsIcon color="indigo.070" size="xl" />
                <StreamlineCoffeeToGoIcon color="indigo.070" size="xl" />
              </Inline>

              <Text variant={2} isBold={true} align="center">
                Automatically boost your investment balance by making the most
                of everyday moments. You can choose the boosts that suit your
                lifestyle and watch as your dollars stack up.
              </Text>
            </Stack>
          </Card>
        )}

        <Button
          variant="primary"
          size="lg"
          onClick={() => navigate(Routes.BOOSTS_RECIPE_SELECT)}
          trackingProperties={{
            name: 'boosts_dashboard_boosts_page_add_boost',
          }}
        >
          Add new boost
        </Button>
      </Stack>

      <RemoveBoostModal
        recipeId={selectedRecipeId}
        showModal={showRemoveBoostModal}
        onCloseModal={() => setShowRemoveBoostModal(false)}
      />
    </>
  );
};

const RecipeCard: React.FC<
  React.PropsWithChildren<
    BoostRecipe & {
      hasConnections: boolean;
      onClickRemoveRecipe: (recipeId: string) => void;
    }
  >
> = (props) => {
  const notifications = useNotifications();
  const intercom = useIntercom();
  const variables = getBoostRecipeTemplateVariables({
    weatherStationName:
      props.source?.__typename === 'WeatherStation'
        ? props.source.name
        : undefined,
    portfolio: props.saverProductInstance?.portfolio,
    parameters: props.parameters,
  });
  const description = useRenderTemplate(props.description, variables, {
    onError: (error) => {
      addRumError({
        error,
        context: { reason: 'Render boost template failed' },
      });
    },
  });
  const standardParameters = props.parameters?.map((parameter) => {
    const { __typename, id, ...rest } = parameter;
    return rest;
  });
  const [toggleRecipe] = useMutation<
    WebAppToggleBoostRecipeStatus,
    WebAppToggleBoostRecipeStatusVariables
  >(gql`
    mutation WebAppToggleBoostRecipeStatus($input: UpdateBoostRecipeInput!) {
      updateBoostRecipe(input: $input) {
        recipe {
          id
          status
        }
      }
    }
  `);

  let errorMsg;
  let showRemoveButton = false;

  if (props.source?.__typename === 'BasiqConnection') {
    switch (props.source.status) {
      case BasiqConnectionStatus.DISCONNECTED:
        errorMsg =
          'We ran into a problem connecting to your tracking bank. You’ll need to re-link your tracking bank to continue using it.';
        showRemoveButton = true;
        break;

      case BasiqConnectionStatus.REMOVED:
        errorMsg =
          'Your tracking bank for this boost has been removed. You’ll need to select or link another tracking bank to continue using it.';
        showRemoveButton = true;
        break;
    }
  }

  const handleToggleRecipe = async (): Promise<void> => {
    const nextStatus =
      props.status === BoostRecipeStatus.ACTIVE
        ? BoostRecipeStatus.INACTIVE
        : BoostRecipeStatus.ACTIVE;

    try {
      await toggleRecipe({
        variables: {
          input: {
            id: props.id,
            status: nextStatus,
          },
        },
        optimisticResponse: {
          updateBoostRecipe: {
            __typename: 'UpdateBoostRecipePayload',
            recipe: {
              id: props.id,
              status: nextStatus,
              __typename: 'BoostRecipe',
            },
          },
        },
      });
    } catch {
      notifications.popToast({
        level: 'error',
        message: `Oops, something went wrong and your ${
          props.name
        } boost was not ${
          nextStatus === BoostRecipeStatus.ACTIVE ? 'enabled' : 'disabled'
        }. Contact our customer support team and we’ll get you back on track.`,
        cta: {
          message: 'Contact support',
          action: () => intercom.pop(),
        },
      });
    }
  };

  return (
    <Card borderRadius="sm" boxShadow="sm">
      <Box paddingX={{ xs: 'md', md: 'lg' }} paddingTop="md" paddingBottom="sm">
        <Stack spaceY="xs">
          <Inline spaceX="xxs">
            <DynamicIcon name={props.iconName} size="md" color="black.100" />
            <Heading variant={5} color="black.100" isBold={true}>
              {props.name}
            </Heading>
          </Inline>

          <div>
            <Text variant={3} color="black.100">
              {description}
            </Text>

            {props.source?.__typename === 'BasiqConnection' &&
              (errorMsg ? (
                <Box marginTop="xs">
                  <InlineError message={errorMsg} isBold={true} />
                </Box>
              ) : (
                <Box marginTop={{ xs: 'xxs', md: 'xxxs' }}>
                  <Text variant={4} color="neutral.080">
                    Tracking bank:{' '}
                    <strong>
                      {props.source.institution?.name ?? 'Unknown'}
                    </strong>
                  </Text>
                </Box>
              ))}
          </div>
        </Stack>
      </Box>

      <Divider color="neutral.050" />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={{ xs: 'md', md: 'lg' }}
        paddingY="sm"
        minHeight={64}
      >
        {props.source?.__typename === 'BasiqConnection' && showRemoveButton && (
          <>
            <ActionLink
              color="indigo.070"
              size="xs"
              onClick={() => {
                props.onClickRemoveRecipe(props.id);
              }}
            >
              Remove
            </ActionLink>

            {props.source.status === BasiqConnectionStatus.DISCONNECTED && (
              <RouterLink
                to={`${Routes.BOOSTS_BASIQ_RELINK}/${props.source.id}`}
                trackingProperties={{
                  name: 'boosts_dashboard_boosts_page_relink_bank',
                }}
              >
                <PresentationLink
                  icon="chevron"
                  color="indigo.070"
                  size="xs"
                  component="span"
                >
                  Link tracking bank
                </PresentationLink>
              </RouterLink>
            )}

            {props.source.status === BasiqConnectionStatus.REMOVED &&
              (props.hasConnections ? (
                <RouterLink
                  to={Routes.BOOSTS_BASIQ_SELECT}
                  state={{
                    type: 'edit-existing-recipe',
                    recipeId: props.id,
                    parameters: standardParameters,
                  }}
                  trackingProperties={{
                    name: 'boosts_dashboard_boosts_page_link_bank',
                  }}
                >
                  <PresentationLink
                    icon="chevron"
                    color="indigo.070"
                    size="xs"
                    component="span"
                  >
                    Link tracking bank
                  </PresentationLink>
                </RouterLink>
              ) : (
                <RouterLink
                  to={Routes.BOOSTS_BASIQ_INSTITUTIONS}
                  state={{
                    type: 'edit-existing-recipe',
                    recipeId: props.id,
                    parameters: standardParameters,
                  }}
                  trackingProperties={{
                    name: 'boosts_dashboard_boosts_page_link_new_bank',
                  }}
                >
                  <PresentationLink
                    icon="chevron"
                    color="indigo.070"
                    size="xs"
                    component="span"
                  >
                    Link tracking bank
                  </PresentationLink>
                </RouterLink>
              ))}
          </>
        )}

        {!showRemoveButton && (
          <>
            <RouterLink
              to={Routes.BOOSTS_RECIPE_SETUP}
              state={{
                type: 'edit-recipe',
                recipeId: props.id,
              }}
              trackingProperties={{ name: 'boosts_dashboard_boosts_page_edit' }}
            >
              <ActionLink color="indigo.070" size="xs" component="span">
                Edit
              </ActionLink>
            </RouterLink>

            <Toggle
              label={props.status === BoostRecipeStatus.ACTIVE ? 'On' : 'Off'}
              checked={props.status === BoostRecipeStatus.ACTIVE}
              onChange={handleToggleRecipe}
            />
          </>
        )}
      </Box>
    </Card>
  );
};
