import * as React from 'react';
export const AnalyticsLineGraph = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M4 16.5l2.646-3.998M17.5 11.5l2.5-4M14.388 13.652L9.5 11.5M5.14 18.215a2.063 2.063 0 11-4.063.716 2.063 2.063 0 014.063-.716zM9.537 9.9a2.063 2.063 0 11-4.063.716A2.063 2.063 0 019.537 9.9zM22.92 5.027a2.063 2.063 0 11-4.063.716 2.063 2.063 0 014.063-.716zM18.522 13.344a2.063 2.063 0 11-4.063.715 2.063 2.063 0 014.063-.715z" />
  </svg>
);
