import { RouteComponentProps } from '@reach/router';
import { Box, Columns, Stack } from '@spaceship-fspl/components';
import { Text } from '@spaceship-fspl/components';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import React from 'react';

export const TaxResidencyNotEditable: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { pop: showIntercom } = useIntercom();

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
            <Stack spaceY="lg" alignX="center">
              <Box>
                <FeatherAlertTriangleIcon color="indigo.070" size="xxxl" />
              </Box>
              <PageHeading title="You can’t update your tax residency through the app" />
              <Text variant={2} align="center">
                Please contact support to change your tax residency details.
              </Text>
              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{
                    name: 'us_investing_tax_residency_warning_proceed',
                  }}
                  onClick={() => {
                    showIntercom();
                  }}
                >
                  Contact support
                </PageFormContinueButton>
              </PageFormButtonContainer>
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};
