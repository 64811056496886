import { ApolloClient } from '@apollo/client';

import {
  parseBoolean,
  parseNumber,
  parseString,
  queryRawValue,
  useRawValue,
  warmCache as _warmCache,
} from './lib';

export { useDynamicConfigQuery } from './lib';

export enum SharedDynamicConfigKeys {
  MIN_INVESTMENT_PLAN_AMOUNT = 'min_investment_plan_amount',
  MAX_INVESTMENT_AMOUNT = 'max_investment_amount',
  DAILY_ORDER_CUTOFF_HOUR = 'daily_order_cutoff_hour',
}

export enum DynamicConfigDeepLinksName {
  AUTH_TFN = 'authTfn',
  AUTH_DEPOSIT = 'authDeposit',
  APPLE_APP_STORE = 'appleAppStore',
  SUPER_REFERRAL = 'superReferral',
  AUTH_BANK_DETAILS = 'authBankDetails',
  GOOGLE_PLAY_STORE = 'googlePlayStore',
  VOYAGER_REFERRAL = 'voyagerReferral',
  AUTH_SUPER_EXPLORE = 'authSuperExplore',
  ONBOARDING_RESUME = 'onboardingResume',
  AUTH_INVESTMENT_PLAN = 'authInvestmentPlan',
  AUTH_REFERRALS_SHARE = 'authReferralsShare',
  AUTH_VOYAGER_PORTFOLIO = 'authVoyagerPortfolio',
  AUTH_TRANSACTION_DETAIL = 'authTransactionDetail',
  BIOMETRIC_VERIFICATION_REDIRECT = 'biometricVerificationRedirect',
}

/**
 * React hook that returns a `boolean` dynamic config value.
 *
 * This hook only performs a network request if the value isn't already in the cache.
 *
 * @param {string} key dynamic config key
 * @param {boolean} fallback value to use if unable to resolve dynamic config value
 * @returns {boolean} resolved value
 */
export function useBoolean(key: string, fallback: boolean): boolean {
  const value = useRawValue(key);
  return parseBoolean(fallback, value);
}

/**
 * React hook that returns a `string` dynamic config value.
 *
 * This hook only performs a network request if the value isn't already in the cache.
 *
 * @param {string} key dynamic config key
 * @param {string} fallback value to use if unable to resolve dynamic config value
 * @returns {string} resolved value
 */
export function useString(key: string, fallback: string): string {
  const value = useRawValue(key);
  return parseString(fallback, value);
}

/**
 * React hook that returns a `number` dynamic config value.
 *
 * This hook only performs a network request if the value isn't already in the cache.
 *
 * @param {string} key dynamic config key
 * @param {number} fallback value to use if unable to resolve dynamic config value
 * @returns {number} resolved value
 */
export function useNumber(key: string, fallback: number): number {
  const value = useRawValue(key);
  return parseNumber(fallback, value);
}

/**
 * Ad-hoc function that returns a `boolean` dynamic config value.
 *
 * This function will always perform a network request.
 *
 * @async
 * @param {ApolloClient} client ApolloClient instance
 * @param {string} key dynamic config key
 * @param {boolean} fallback value to use if unable to resolve dynamic config value
 * @returns {Promise<boolean>} resolved value
 */
export async function getBoolean(
  client: ApolloClient<unknown>,
  key: string,
  fallback: boolean,
): Promise<boolean> {
  try {
    const value = await queryRawValue(client, key);
    return parseBoolean(fallback, value);
  } catch {
    return fallback;
  }
}

/**
 * Ad-hoc function that returns a `string` dynamic config value.
 *
 * This function will always perform a network request.
 *
 * @async
 * @param {ApolloClient} client ApolloClient instance
 * @param {string} key dynamic config key
 * @param {string} fallback value to use if unable to resolve dynamic config value
 * @returns {Promise<string>} resolved value
 */
export async function getString(
  client: ApolloClient<unknown>,
  key: string,
  fallback: string,
): Promise<string> {
  try {
    const value = await queryRawValue(client, key);
    return parseString(fallback, value);
  } catch {
    return fallback;
  }
}

/**
 * Ad-hoc function that returns a `number` dynamic config value.
 *
 * This function will always perform a network request.
 *
 * @async
 * @param {ApolloClient} client ApolloClient instance
 * @param {string} key dynamic config key
 * @param {number} fallback value to use if unable to resolve dynamic config value
 * @returns {Promise<number>} resolved value
 */
export async function getNumber(
  client: ApolloClient<unknown>,
  key: string,
  fallback: number,
): Promise<number> {
  try {
    const value = await queryRawValue(client, key);
    return parseNumber(fallback, value);
  } catch {
    return fallback;
  }
}

/**
 * Performs a bulk config value lookup and primes the
 * cache with values for the provided config keys.
 *
 * @async
 * @param {ApolloClient} client ApolloClient instance
 * @param {Array<string>} keys keys to fetch
 * @returns {Promise<void>}
 */
export async function warmCache(
  client: ApolloClient<unknown>,
  keys: Array<string>,
): Promise<void> {
  await _warmCache(client, [
    ...keys,
    ...Object.values(SharedDynamicConfigKeys),
  ]);
}
