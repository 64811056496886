import { Box, Container, Inline, Logo } from '@spaceship-fspl/components';
import {
  backgroundColor,
  getNumericSpace,
  transition,
} from '@spaceship-fspl/styles';
import { HEADER_HEIGHT } from 'components/general-header';
import { RouterLink } from 'components/router-link';
import React, { memo } from 'react';
import styled from 'styled-components';

interface ProgressHeaderProps {
  step: number;
  total: number;
  headerButtons?: React.ReactElement;
}

const PROGRESS_BAR_HEIGHT = 5;
export const PROGRESS_HEADER_HEIGHT =
  PROGRESS_BAR_HEIGHT + HEADER_HEIGHT + getNumericSpace('xxxs');

export const ProgressHeader: React.FC<
  React.PropsWithChildren<ProgressHeaderProps>
> = memo(({ step, total, headerButtons }) => (
  <header>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={PROGRESS_HEADER_HEIGHT}
    >
      <StyledProgressBar value={step} max={total} />

      <Container>
        <Box
          display="flex"
          alignItems="center"
          height={HEADER_HEIGHT}
          justifyContent="space-between"
        >
          <RouterLink
            to="/"
            trackingProperties={{ name: 'progress_header_home' }}
          >
            <Logo variant="dark" />
          </RouterLink>
          {headerButtons && (
            <Inline spaceX="xs" alignY="center">
              {headerButtons}
            </Inline>
          )}
        </Box>
      </Container>
    </Box>
  </header>
));

ProgressHeader.displayName = 'ProgressHeader';

const StyledProgressBar = styled.progress`
  appearance: none;
  border: none;
  width: 100%;
  display: block;
  height: ${PROGRESS_BAR_HEIGHT}px;

  &,
  ::-webkit-progress-bar {
    ${backgroundColor('neutral.050')}
    border: none;
  }

  ::-webkit-progress-value {
    ${backgroundColor('indigo.070')}
    border: none;
    ${transition}
  }

  ::-moz-progress-bar {
    ${backgroundColor('indigo.070')}
  }
`;
