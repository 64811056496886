import * as React from 'react';
export const StreamlineAnalyticsPie1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11.57 22.686c5.673 0 10.272-4.598 10.272-10.27H11.57V2.144C5.898 2.145 1.3 6.743 1.3 12.415c0 5.673 4.598 10.271 10.27 10.271z" />
    <path vectorEffect="non-scaling-stroke" d="M22.773 9.417c-.468.001-7.29-.026-7.757-.027.002-.467 0-7.469-.004-7.936m7.764 7.963c.006-5.135-3.792-7.839-7.761-7.963" />
  </svg>
);
