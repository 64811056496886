import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Card,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  useReconnectBasiqConnection,
  useRemoveBasiqConnection,
} from '@spaceship-fspl/graphql';
import { WebAppBoostsBasiqRelinkDetails } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsBasiqRelinkDetails';
import { formatDate } from '@spaceship-fspl/helpers';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import {
  CenterPageContainer,
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { BasiqUnlinkModal } from './components/basiq-unlink-modal';
import { ContentLayout } from './components/layout';

const RELINK_MISSING_FIELD_ERROR =
  'Oops, there was a problem re-linking your bank.';

type TrackingBankLoginForm = Partial<{
  username: string;
  secondaryUsername: string;
  password: string;
  securityCode: string;
}>;

interface PageParams {
  connectionId?: string;
}

export const BoostsBasiqRelink: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { connectionId = '' }: PageParams = useParams();
  const [showConfirmUnlinkModal, setShowConfirmUnlinkModal] = useState(false);
  const { control, handleSubmit } = useForm<TrackingBankLoginForm>();
  const [reconnectBasiqConnection, reconnectBasiqConnectionMeta] =
    useReconnectBasiqConnection();
  const [removeBasiqConnection, removeBasiqConnectionMeta] =
    useRemoveBasiqConnection();

  const notifications = useNotifications();
  const intercom = useIntercom();

  const resp = useQuery<WebAppBoostsBasiqRelinkDetails>(
    gql`
      query WebAppBoostsBasiqRelinkDetails {
        contact {
          id
          account {
            id
            basiqConnections {
              id
              status
              connectedAt
              institution {
                id
                name
                usernameCaption
                secondaryUsernameCaption
                passwordCaption
                securityCodeCaption
              }
            }
          }
        }
      }
    `,
    {
      skip: !connectionId,
    },
  );

  useEffect(() => {
    if (!connectionId) {
      navigate(Routes.BOOSTS_DASHBOARD_HOME);
    }
  }, [connectionId, navigate]);

  const basiqConnections = resp.data?.contact.account?.basiqConnections;

  const connection = useMemo(() => {
    if (basiqConnections && basiqConnections.length > 0) {
      return basiqConnections.filter(({ id }) => id === connectionId)?.[0];
    }
    return;
  }, [basiqConnections, connectionId]);

  const onSubmit = async (values: TrackingBankLoginForm): Promise<void> => {
    if (!connectionId) {
      addRumError({ error: 'onSubmit is called without connectionId' });
      notifications.popToast({
        level: 'error',
        message: RELINK_MISSING_FIELD_ERROR,
        cta: {
          message: 'Contact support',
          action: () => {
            intercom.pop();
          },
        },
      });
      return;
    }

    if (!values.username) {
      addRumError({ error: 'onSubmit is called without username' });
      notifications.popToast({
        level: 'error',
        message: RELINK_MISSING_FIELD_ERROR,
        cta: {
          message: 'Contact support',
          action: () => {
            intercom.pop();
          },
        },
      });
      return;
    }

    if (!values.password) {
      addRumError({ error: 'onSubmit is called without password' });
      notifications.popToast({
        level: 'error',
        message: RELINK_MISSING_FIELD_ERROR,
        cta: {
          message: 'Contact support',
          action: () => {
            intercom.pop();
          },
        },
      });
      return;
    }

    try {
      await reconnectBasiqConnection({
        variables: {
          input: {
            id: connectionId,
            password: values.password,
            securityCode: values.securityCode,
            secondaryUsername: values.secondaryUsername,
            username: values.username,
          },
        },
      });

      navigate(Routes.BOOSTS_BASIQ_SUCCESS, {
        state: {
          type: 'reconnect-source',
        },
      });
    } catch {
      navigate(Routes.BOOSTS_BASIQ_ERROR, {
        state: {
          type: 'reconnect-source',
        },
      });
    }
  };

  if (reconnectBasiqConnectionMeta?.loading) {
    return (
      <CenterPageContainer>
        <Stack spaceY="md" alignX="center">
          <Spinner size="lg" />
          <Stack spaceY="sm" alignX="center">
            <Heading variant={{ xs: 4, md: 3 }} isBold={true} align="center">
              We are now linking your tracking bank...
            </Heading>

            <Text variant={2} align="center">
              Sit tight — this can take up to 30 seconds.
            </Text>
          </Stack>
        </Stack>
      </CenterPageContainer>
    );
  }

  if (resp.loading) {
    return (
      <CenterPageContainer>
        <Box display="flex" justifyContent="center">
          <Spinner size="lg" />
        </Box>
      </CenterPageContainer>
    );
  }

  return (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentLayout>
          <Stack spaceY="md">
            <PageHeading
              title={`Relink your ${
                connection?.institution?.name ?? ''
              } Bank accounts`}
              subtitle="We ran into a problem connecting to your tracking bank. Please provide your password so we can re-link your tracking bank."
            />

            {connection && (
              <Stack spaceY="lg">
                <Card
                  borderRadius="sm"
                  boxShadow="sm"
                  paddingX="lg"
                  paddingY="md"
                >
                  <Stack spaceY="xxxs">
                    <Heading variant={5} isBold={true}>
                      {connection?.institution?.name}
                    </Heading>

                    <Text variant={4} color="neutral.085">
                      Disconnected{' '}
                      {formatDate(connection.connectedAt, 'dd MMM yyyy')}
                    </Text>
                  </Stack>
                </Card>

                <Stack spaceY="md">
                  {connection?.institution?.usernameCaption && (
                    <ControllerInput
                      name="username"
                      placeholder={connection.institution.usernameCaption}
                      control={control}
                      rules={{
                        required: `${connection.institution.usernameCaption} is required`,
                      }}
                      type="text"
                    />
                  )}

                  {connection?.institution?.secondaryUsernameCaption && (
                    <ControllerInput
                      name="secondaryUsername"
                      placeholder={
                        connection.institution.secondaryUsernameCaption
                      }
                      control={control}
                      rules={{
                        required: `${connection.institution.secondaryUsernameCaption} is required`,
                      }}
                      type="text"
                    />
                  )}

                  {connection?.institution?.passwordCaption && (
                    <ControllerPasswordInput
                      name="password"
                      placeholder={connection.institution.passwordCaption}
                      control={control}
                      rules={{
                        required: `${connection.institution.passwordCaption} is required`,
                      }}
                    />
                  )}

                  {connection?.institution?.securityCodeCaption && (
                    <ControllerInput
                      name="securityCode"
                      placeholder={connection.institution.securityCodeCaption}
                      control={control}
                      rules={{
                        required:
                          connection.institution.securityCodeCaption.match(
                            /if available|optional/g,
                          )?.length === 0
                            ? `${connection.institution.securityCodeCaption} is required`
                            : false,
                      }}
                      type="number"
                    />
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>

          {connection && (
            <PageFormButtonContainer>
              <PageFormContinueButton
                aria-label="Relink tracking bank"
                trackingProperties={{ name: 'basiq_relink_page_relink_button' }}
                isLoading={removeBasiqConnectionMeta.loading}
              >
                Relink tracking bank
              </PageFormContinueButton>

              <PageFormCancelButton
                aria-label="Unlink tracking bank"
                trackingProperties={{ name: 'basiq_relink_page_unlink_button' }}
                isDisabled={removeBasiqConnectionMeta.loading}
                onClick={() => {
                  setShowConfirmUnlinkModal(true);
                }}
              >
                Unlink tracking bank
              </PageFormCancelButton>
            </PageFormButtonContainer>
          )}
        </ContentLayout>
      </form>
      {connectionId && (
        <BasiqUnlinkModal
          showModal={showConfirmUnlinkModal}
          onCloseModal={() => {
            setShowConfirmUnlinkModal(false);
          }}
          onUnlink={async () => {
            try {
              if (!connectionId) {
                throw new Error(
                  'Attempting to unlink tracking back without connectionId',
                );
              }

              await removeBasiqConnection({
                variables: { input: { id: connectionId } },
              });

              navigate(Routes.BOOSTS_BASIQ_SUCCESS, {
                state: {
                  type: 'disconnect-source',
                },
              });
            } catch (error) {
              addRumError({ error });
              notifications.popToast({
                level: 'error',
                message: 'Oops, we are unable to unlink your tracking bank.',
              });
            }
          }}
        />
      )}
    </PageContainer>
  );
};
