import * as React from 'react';
export const StreamlinePastaBowlWarm = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M16.92 1c1.404 2.81-2.836 3.795-.734 6.6M12.52 1c1.404 2.81-2.836 3.795-.734 6.6M8.12 1c1.404 2.81-2.836 3.795-.734 6.6M1 12.733c0 .778 1.159 1.524 3.222 2.074 2.063.55 4.86.86 7.778.86 2.917 0 5.715-.31 7.778-.86 2.063-.55 3.222-1.296 3.222-2.074 0-.778-1.159-1.524-3.222-2.074-2.063-.55-4.86-.86-7.778-.86-2.917 0-5.715.31-7.778.86C2.159 11.209 1 11.955 1 12.733z" />
    <path vectorEffect="non-scaling-stroke" d="M23 12.733C23 18.404 18.075 23 12 23S1 18.403 1 12.733" />
  </svg>
);
