import { gql, useQuery } from '@apollo/client';
import { Box, Card, Columns, Text } from '@spaceship-fspl/components';
import { DatedBalance, SaverPortfolio } from '@spaceship-fspl/graphql';
import {
  WebAppRecentMovers,
  WebAppRecentMoversVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppRecentMovers';
import {
  DATE_FORMAT_REQUESTS,
  formatCurrencyWithCodeAndSymbol,
  formatDate,
} from '@spaceship-fspl/helpers';
import { Chart } from 'components/chart';
import { PerformanceIndicator } from 'components/performance-indicator';
import { RouterLink } from 'components/router-link';
import { format } from 'date-fns';
import { Routes } from 'pages/routes';
import React, { memo } from 'react';
import styled from 'styled-components';

export const RecentMovers: React.FC<
  React.PropsWithChildren<{
    productId: string;
    portfolio: SaverPortfolio;
    singleRow?: boolean;
    numToShow: number;
  }>
> = memo(({ productId, portfolio, singleRow, numToShow }) => {
  const perRow = singleRow ? numToShow : numToShow / 2;

  const resp = useQuery<WebAppRecentMovers, WebAppRecentMoversVariables>(
    gql`
      query WebAppRecentMovers(
        $numToShow: Int!
        $portfolio: SaverPortfolio!
        $marketPricesFrom: String!
        $marketPricesTo: String!
      ) {
        instruments(
          first: $numToShow
          portfolios: [$portfolio]
          orderBy: YTD_PERFORMANCE_DESC
        ) {
          id
          name
          audLatestPrice
          ytdPercentageChange
          marketPrices(
            dateRange: { from: $marketPricesFrom, to: $marketPricesTo }
          ) {
            audAmount
            date
          }
        }
      }
    `,
    {
      variables: {
        numToShow,
        portfolio,
        marketPricesFrom: '1990-01-01',
        marketPricesTo: format(new Date(), DATE_FORMAT_REQUESTS),
      },
    },
  );

  return (
    <Columns spaceY="md" spaceX="md">
      {!resp.data?.instruments
        ? Array.from({ length: numToShow }).map((_, index) => (
            <Columns.Column
              key={index}
              width={{ xs: 1 / 2, sm: 1 / (numToShow / 2), md: 1 / perRow }}
            >
              <LoadingMover />
            </Columns.Column>
          ))
        : resp.data.instruments.map((instrument) => (
            <Columns.Column
              key={instrument.id}
              width={{ xs: 1 / 2, md: 1 / (numToShow / 2), lg: 1 / perRow }}
            >
              <Mover productId={productId} instrument={instrument} />
            </Columns.Column>
          ))}
    </Columns>
  );
});

RecentMovers.displayName = 'RecentMovers';

const LoadingMover: React.FC<React.PropsWithChildren> = () => (
  <Card>
    <Box padding="md">
      <PerformanceIndicator
        upColor="mint.100"
        downColor="red.100"
        direction="up"
      />
      <Box height={50} />
      <Text variant={4} isBold={true}>
        —
      </Text>
    </Box>
  </Card>
);

const Mover: React.FC<
  React.PropsWithChildren<{
    productId: string;
    instrument: {
      id: string;
      name: string;
      audLatestPrice: string;
      ytdPercentageChange: string;
      marketPrices: DatedBalance[] | null;
    };
  }>
> = ({ productId, instrument }) => {
  const isDown = Number(instrument.ytdPercentageChange) < 0;
  const latestInstrumentMarketPrice =
    instrument?.marketPrices?.[instrument.marketPrices?.length - 1];

  return (
    <RouterLink
      to={`${Routes.VOYAGER_PORTFOLIO}/${productId}/${instrument.id}`}
      trackingProperties={{ name: 'voyager_dashboard_recent_mover' }}
    >
      <Card>
        <Box padding="md">
          <Box display="flex" alignItems="center">
            <Box marginRight="xxxs" lineHeight={0}>
              <PerformanceIndicator
                upColor="mint.100"
                downColor="red.100"
                direction={isDown ? 'down' : 'up'}
              />
            </Box>

            <Text
              variant={1}
              isBold={true}
              color={isDown ? 'red.100' : 'mint.100'}
            >
              {formatChange(instrument.ytdPercentageChange)}
            </Text>
          </Box>
          <Box position="relative" height={42} marginBottom="xxs">
            <Chart
              color={isDown ? 'red.030' : 'mint.050'}
              isThin={true}
              data={
                instrument?.marketPrices?.map(({ date, audAmount }) => ({
                  x: new Date(date).getTime(),
                  y: Number(audAmount),
                })) || []
              }
            />
          </Box>
          <Text variant={4} isBold={true} isTruncated={true}>
            {instrument.name || '—'}
          </Text>
          <Text variant={4} color="neutral.080" isBold={true}>
            {formatCurrencyWithCodeAndSymbol(instrument.audLatestPrice)}
          </Text>

          {!!latestInstrumentMarketPrice && (
            <PerformanceAsAtDateText>
              {formatDate(latestInstrumentMarketPrice.date, 'd MMM yy')}
            </PerformanceAsAtDateText>
          )}
        </Box>
      </Card>
    </RouterLink>
  );
};

const formatChange = (change?: string | null): string => {
  if (change === null || change === undefined) {
    return '—';
  }
  const delta = Number(change);
  if (delta < 0) {
    return `(${delta.toFixed(0)}%)`;
  }
  return `${delta.toFixed(0)}%`;
};

const PerformanceAsAtDateText = styled(Text).attrs({
  variant: 4,
  color: 'neutral.080',
  align: 'right',
})`
  font-size: 10px;
  line-height: 10px;
`;
