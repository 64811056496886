import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import { AccountCardEditFormButtons } from 'components/account-cards/components';
import { VoyagerPortfolioCard } from 'components/account-cards/voyager-portfolio';
import { useOnboardingRequestContext } from 'contexts/saver/onboarding';
import React from 'react';

export interface PortfolioSectionProps {
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  hideEdit?: boolean;
}

export const PortfolioSection: React.FC<
  React.PropsWithChildren<PortfolioSectionProps>
> = ({ hideEdit, isEditing, setIsEditing }) => {
  const [request, setRequest] = useOnboardingRequestContext();
  const setPortfolio = (portfolio: SaverPortfolio.Enum): void => {
    if (setIsEditing) {
      setRequest({
        ...request,
        createSaverAccount: {
          ...request.createSaverAccount,
          portfolio: portfolio,
        },
      });
      setIsEditing(false);
    }
  };

  if (!request.createSaverAccount?.portfolio) {
    return null;
  }

  return (
    <VoyagerPortfolioCard
      portfolio={request.createSaverAccount?.portfolio}
      setPortfolio={setPortfolio}
      edit={
        hideEdit
          ? undefined
          : {
              onClick: () => setIsEditing?.(true),
              isEditing: !!isEditing,
            }
      }
    >
      <AccountCardEditFormButtons
        cancel={{
          onClick: () => setIsEditing?.(false),
          trackingProperties: { name: 'portfolio_edit_cancel' },
        }}
        submitTrackingProperties={{ name: 'portfolio_edit_save' }}
      />
    </VoyagerPortfolioCard>
  );
};
