import { useIsVerifiedAccount } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Divider,
  Inline,
  PresentationLink,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { enumToString, formatCurrency } from '@spaceship-fspl/helpers';
import { DollarCircleIcon, FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import { LabeledField } from 'components/labeled-field';
import { fromISODate, toLongDate } from 'helpers/format';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardEditProps,
  AccountCardHeading,
} from './components';

export interface VoyagerInvestmentPlanSummaryCardProps {
  plan?: {
    frequency: SchedulerFrequency.Enum;
    audAmount: string;
    nextDue: string;
  };
  edit?: AccountCardEditProps;
  onSetupPlan: () => void;
  onStopPlan?: () => void;
  isLoading?: boolean;
  isNewPlan?: boolean;
}

export const VoyagerInvestmentPlanSummaryCard: React.FC<
  React.PropsWithChildren<VoyagerInvestmentPlanSummaryCardProps>
> = ({
  children,
  plan,
  edit,
  onSetupPlan,
  onStopPlan,
  isLoading = false,
  isNewPlan = false,
}) => {
  const isVerifiedUser = useIsVerifiedAccount();
  const displayAudAmount = formatCurrency(plan?.audAmount);
  const displayFrequency = plan
    ? enumToString(SchedulerFrequency.Enum, plan.frequency).toLowerCase()
    : '';
  const displayNextDue = plan ? toLongDate(fromISODate(plan.nextDue)) : '';

  return (
    <AccountCard>
      <AccountCardHeading title="Investment plan" edit={edit} />

      <AccountCardContent isLoading={isLoading}>
        {(!edit || !edit.isEditing) &&
          (plan ? (
            <Stack spaceY="sm">
              <Columns
                alignY="bottom"
                spaceX={{ md: 'lg', lg: 'xl' }}
                spaceY={{ xs: 'sm', xl: 'none' }}
              >
                <Columns.Column width={{ xs: 1, md: 1 / 2, lg: 'min' }}>
                  <LabeledField label="Amount" size="md">
                    {displayAudAmount} / {displayFrequency}
                  </LabeledField>
                </Columns.Column>

                <Columns.Column width={{ xs: 1, md: 1 / 2, lg: 'min' }}>
                  <LabeledField
                    label={isNewPlan ? 'Starting' : 'Next investment'}
                    size="md"
                  >
                    {displayNextDue}
                  </LabeledField>
                </Columns.Column>

                {onStopPlan && (
                  <Columns.Column width={{ xs: 1, lg: 'max' }}>
                    <Box
                      display="flex"
                      flex={1}
                      alignItems="flex-end"
                      justifyContent="flex-end"
                    >
                      <UnstyledButton onClick={onStopPlan}>
                        <Text
                          variant={3}
                          color="indigo.070"
                          isBold={true}
                          align="center"
                          component="span"
                        >
                          STOP PLAN
                        </Text>
                      </UnstyledButton>
                    </Box>
                  </Columns.Column>
                )}
              </Columns>

              {!isVerifiedUser && (
                <>
                  <Divider size="sm" color="neutral.050" />
                  <Inline spaceX="xxs">
                    <FeatherInfoIcon size="sm" color="neutral.080" />
                    <Text variant={3} color="neutral.080" isBold={true}>
                      Your investment plan will start after we&apos;ve verified
                      your account.
                    </Text>
                  </Inline>
                </>
              )}
            </Stack>
          ) : (
            <Stack spaceY="xxs" alignX="center">
              <DollarCircleIcon size="lg" color="indigo.070" />

              <Text variant={2} align="center">
                No current investment plan
              </Text>

              <PresentationLink
                color="indigo.070"
                size="sm"
                icon="chevron"
                onClick={onSetupPlan}
              >
                Set up plan
              </PresentationLink>
            </Stack>
          ))}

        {children}
      </AccountCardContent>
    </AccountCard>
  );
};
