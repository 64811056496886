import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Button,
  Columns,
  InfoPanel,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { MoneyTransactionType } from '@spaceship-fspl/graphql';
import {
  WebAppMoneyDashboardPage,
  WebAppMoneyDashboardPageVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyDashboardPage';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { getColor } from '@spaceship-fspl/styles';
import {
  AccountVerificationStatusInfoPanel,
  AccountVerificationStatusInfoPanel_AccountFragment,
} from 'components/account-verification-status-info-panel';
import { PageContainer } from 'components/layouts/page';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { TestId } from 'helpers/test-ids';
import { Routes } from 'pages/routes';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  MoneyActivityCard,
  MoneyActivityCard_MoneyActivityCardFragment,
} from './components/activity-card/money-activity-card';
import {
  MoneyAvailableCard,
  MoneyAvailableCard_MoneyAvailableFragment,
} from './components/money-available-card';
import {
  MoneyReadyToInvestCard_MoneyReadyToInvestFragment,
  ReadyToInvestCard,
} from './components/ready-to-invest-card';

export const MONEY_TRANSACTIONS_CHUNK_SIZE = 10;

export type MoneyFilterOptions = MoneyTransactionType | '';

export const MoneyDashboard: React.FC<RouteComponentProps> = () => {
  const [selectedTypeFilter, setSelectedTypeFilter] =
    useState<MoneyFilterOptions>('');

  const [fetchingMoreTransactions, setFetchingMoreTransactions] =
    useState(false);

  const navigate = useNavigate();
  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  const selectedTypeFilters = React.useMemo(() => {
    if (!selectedTypeFilter) {
      return null;
    }
    if (selectedTypeFilter === MoneyTransactionType.FEE) {
      return [
        MoneyTransactionType.FEE,
        MoneyTransactionType.REG_FEE,
        MoneyTransactionType.TAF_FEE,
      ];
    }
    return [selectedTypeFilter];
  }, [selectedTypeFilter]);

  const resp = useQuery<
    WebAppMoneyDashboardPage,
    WebAppMoneyDashboardPageVariables
  >(
    gql`
      query WebAppMoneyDashboardPage(
        $isMoneyDayOneEnabled: Boolean! = false
        $typeFilters: [MoneyTransactionType!]
        $first: Int
        $after: String
      ) {
        contact {
          id
          account {
            id
            ... on Account @include(if: $isMoneyDayOneEnabled) {
              ...AccountVerificationStatusInfoPanel_AccountFragment
              ...MoneyAvailableCard_MoneyAvailableFragment
              ...MoneyReadyToInvestCard_MoneyReadyToInvestFragment
              moneyInstance {
                id
                outstandingAudBalance
                ...MoneyActivityCard_MoneyActivityCardFragment
              }
            }
          }
        }
      }
      ${MoneyActivityCard_MoneyActivityCardFragment}
      ${MoneyReadyToInvestCard_MoneyReadyToInvestFragment}
      ${MoneyAvailableCard_MoneyAvailableFragment}
      ${AccountVerificationStatusInfoPanel_AccountFragment}
    `,
    {
      variables: {
        isMoneyDayOneEnabled,
        typeFilters: selectedTypeFilters,
        first: MONEY_TRANSACTIONS_CHUNK_SIZE,
      },
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: 'cache-and-network',
      returnPartialData: true,
    },
  );

  const isRefreshing = resp.networkStatus === NetworkStatus.refetch;
  const isLoading = resp.loading || isRefreshing;

  const account = resp.data?.contact?.account;
  const moneyInstance = account?.moneyInstance;
  const outstandingBalanceNumber =
    Number(moneyInstance?.outstandingAudBalance) ?? '';

  const handleFetchMoreTransactions = async (): Promise<void> => {
    try {
      setFetchingMoreTransactions(true);
      await resp.fetchMore<WebAppMoneyDashboardPage>({
        variables: {
          after:
            resp.data?.contact?.account?.moneyInstance?.transactions?.pageInfo
              ?.endCursor,
        },
      });
    } finally {
      setFetchingMoreTransactions(false);
    }
  };

  return (
    <PageContainer>
      <Stack spaceY="md">
        <Columns spaceY="md" spaceX="md" alignX="center" alignY="top">
          <Columns.Column width={{ xs: 1, xl: 3 / 8 }}>
            <Columns spaceX="md" spaceY="md">
              <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 1 }}>
                <Box>
                  {outstandingBalanceNumber ? (
                    <InfoPanel
                      testId={TestId.MONEY_OUTSTANDING_BALANCE_CARD}
                      color="gold.100"
                      icon={FeatherInfoIcon}
                      iconColor="gold.100"
                      iconSize="lg"
                    >
                      <Stack spaceY="xs">
                        <Text isBold variant={2}>
                          You have an outstanding balance
                        </Text>
                        <Text variant={3}>
                          You have an outstanding balance of{' '}
                          {formatCurrency(moneyInstance?.outstandingAudBalance)}{' '}
                          due to a failed direct debit from your bank.
                        </Text>
                        <Box marginTop="xs">
                          <OutstandingBalancePayNowButton
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              navigate(Routes.MONEY_DEPOSIT, {
                                state: {
                                  audAmount:
                                    moneyInstance?.outstandingAudBalance,
                                },
                              });
                            }}
                          >
                            Pay now
                          </OutstandingBalancePayNowButton>
                        </Box>
                      </Stack>
                    </InfoPanel>
                  ) : null}
                  <AccountVerificationStatusInfoPanel account={account} />
                  <MoneyAvailableCard isLoading={isLoading} account={account} />
                </Box>
              </Columns.Column>
              <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 1 }}>
                <ReadyToInvestCard isLoading={isLoading} account={account} />
              </Columns.Column>
            </Columns>
          </Columns.Column>
          <Columns.Column width={{ xs: 1, xl: 5 / 8 }}>
            <Stack spaceY="sm">
              <MoneyActivityCard
                isLoading={isLoading}
                fetchMoreTransactions={handleFetchMoreTransactions}
                isFetchingMoreTransactions={fetchingMoreTransactions}
                moneyInstance={moneyInstance}
                selectedTransactionTypeFilter={selectedTypeFilter}
                setTransactionTypeFilter={setSelectedTypeFilter}
              />
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const OutstandingBalancePayNowButton = styled(Button)`
  color: ${getColor('black.100')};
`;
