import * as React from 'react';
export const StreamlineSpaceAstronautAlternate = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M6.867 19.333h5.866V23H6.867v-3.667z" />
    <path vectorEffect="non-scaling-stroke" d="M18.6 23v-2.933a4.4 4.4 0 00-3.167-4.224A1.71 1.71 0 0114.2 14.2v-2.933a4.4 4.4 0 00-8.8 0V14.2a1.71 1.71 0 01-1.233 1.642A4.4 4.4 0 001 20.066v2.933" />
    <path vectorEffect="non-scaling-stroke" d="M5.4 11.267l1.621-.81a6.212 6.212 0 015.558 0l1.621.81M5.4 14.2l1.621.81a6.214 6.214 0 005.558 0l1.621-.81M1 20.067v-6.6a2.2 2.2 0 012.2-2.2M18.6 20.067v-6.6a2.2 2.2 0 00-2.2-2.2M11.267 1v2.933M9.8 2.467h2.933M21.533 8.333v2.934M20.067 9.8H23M18.6 1a2.957 2.957 0 01-2.933 2.933A2.957 2.957 0 0118.6 6.867a2.957 2.957 0 012.933-2.934A2.957 2.957 0 0118.6 1" />
  </svg>
);
