import { gql, useQuery } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { FeatherCheckIcon } from '@spaceship-fspl/icons-web';
import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from 'components/account-cards/components';
import {
  MfaRegistrationModal,
  MfaRegistrationModalRef,
} from 'components/mfa-registration-modal';
import { ResourceItemProps, ResourceList } from 'components/resource-list';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React, { useMemo, useRef } from 'react';

export const SecurityCard: React.FC<React.PropsWithChildren> = () => {
  const navigate = useNavigate();
  const modalRef = useRef<MfaRegistrationModalRef>(null);

  const resp = useQuery(gql`
    query WebAppSecurityCard {
      contact {
        id
        phoneNumberMasked
        phoneNumberVerified
      }
    }
  `);

  const mfaRegistrationEnabled = useFeatureFlag(
    FeatureFlagKeys.MFA_REGISTRATION_LOGGED_IN_ENABLED,
  );

  const resources = useMemo(() => {
    const res: ResourceItemProps[] = [
      {
        label: 'Change password',
        onClick: () => navigate(Routes.ACCOUNT_CHANGE_PASSWORD),
      },
    ];

    if (mfaRegistrationEnabled && !resp.loading) {
      if (resp.data?.contact?.phoneNumberVerified) {
        res.push({
          label: 'Multi-factor authentication enabled',
          icon: <FeatherCheckIcon />,
          subText: `Your security codes will be sent to ${resp?.data?.contact?.phoneNumberMasked}.`,
        });
      } else {
        res.push({
          label: 'Set up your multi-factor authentication',
          onClick: () => modalRef.current?.showModal(),
        });
      }
    }
    return res;
  }, [resp, navigate, mfaRegistrationEnabled]);

  return (
    <AccountCard>
      <AccountCardHeading title="Security" />
      <AccountCardContent
        padding={{
          paddingX: { xs: 'md', md: 'lg' },
          paddingY: { xs: 'xs', md: 'sm' },
        }}
      >
        <ResourceList isBold={true} resources={resources} />
      </AccountCardContent>

      <MfaRegistrationModal ref={modalRef} />
    </AccountCard>
  );
};
