import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { isAddressInputValid } from '@spaceship-fspl/address';
import { Box, Columns, Inline, Stack, Text } from '@spaceship-fspl/components';
import {
  addressScalars,
  foreignTaxResidenceScalars,
  TaxGroup,
  useSetTaxResidency,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerTaxResidencyTaxGroup } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerTaxResidencyTaxGroup';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioButton } from 'components/radio-button';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React from 'react';
import { useForm } from 'react-hook-form';

import { TaxResidencyFAQ } from './faq';

export const VoyagerAddTaxResidencyTaxGroup: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();

  return (
    <VoyagerTaxResidencyTaxGroup
      navigateToCountries={(taxGroup: TaxGroup) => {
        navigate?.(Routes.VOYAGER_TAX_RESIDENCY_ADD_COUNTRIES, {
          state: {
            taxGroup,
          },
        });
      }}
      navigateToPrimaryAddress={(taxGroup: TaxGroup) => {
        navigate(Routes.VOYAGER_TAX_RESIDENCY_ADD_PRIMARY_ADDRESS, {
          state: {
            taxGroup,
          },
        });
      }}
      onComplete={() => {
        navigate(Routes.VOYAGER_DASHBOARD);
      }}
    />
  );
};

export const VoyagerUpdateTaxResidencyTaxGroup: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();

  return (
    <VoyagerTaxResidencyTaxGroup
      navigateToCountries={(taxGroup: TaxGroup) => {
        navigate?.(Routes.VOYAGER_TAX_RESIDENCY_COUNTRIES, {
          state: {
            taxGroup,
          },
        });
      }}
      navigateToPrimaryAddress={(taxGroup: TaxGroup) => {
        navigate(Routes.VOYAGER_TAX_RESIDENCY_PRIMARY_ADDRESS, {
          state: {
            taxGroup,
          },
        });
      }}
      onComplete={() => {
        navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
      }}
    />
  );
};

const VoyagerTaxResidencyTaxGroup: React.FC<
  React.PropsWithChildren<{
    navigateToCountries: (taxGroup: TaxGroup) => void;
    navigateToPrimaryAddress: (taxGroup: TaxGroup) => void;
    onComplete: () => void;
  }>
> = ({ navigateToCountries, navigateToPrimaryAddress, onComplete }) => {
  const [setTaxResidency, setTaxResidencyMeta] = useSetTaxResidency();
  const notification = useNotifications();
  const { formState, handleSubmit, register, reset } = useForm<{
    taxGroup: Exclude<TaxGroup, TaxGroup.UNSPECIFIED>;
  }>();
  const isUpdating = setTaxResidencyMeta.loading;

  const resp = useQuery<WebAppVoyagerTaxResidencyTaxGroup>(
    gql`
      query WebAppVoyagerTaxResidencyTaxGroup {
        contact {
          id
          taxGroup
          address {
            ...addressScalars
          }
          foreignTaxResidences {
            id
            ...foreignTaxResidenceScalars
          }
        }
      }
      ${addressScalars}
      ${foreignTaxResidenceScalars}
    `,
    {
      onCompleted: (data) => {
        if (
          data.contact.taxGroup &&
          data.contact.taxGroup !== TaxGroup.UNSPECIFIED
        ) {
          reset({
            taxGroup: data.contact.taxGroup,
          });
        }
      },
      onError: () => {
        notification.popToast({
          level: 'error',
          message:
            'There was a problem loading your details. Please try again.',
        });
      },
    },
  );
  const currentAddress = resp.data?.contact.address;

  const onSubmit = handleSubmit(async ({ taxGroup }) => {
    if (taxGroup === TaxGroup.AUSTRALIA_ONLY) {
      // If for some reason, the address or mandatory address components are missing and there was no error OR
      // if currently has an overseas address, go to primary address screen
      if (
        !isAddressInputValid(currentAddress) ||
        currentAddress?.country.toLowerCase() !== 'au'
      ) {
        navigateToPrimaryAddress(taxGroup);
        return;
      }

      try {
        await setTaxResidency({
          variables: {
            input: {
              taxGroup,
              address: {
                unitNumber: currentAddress?.unitNumber ?? '',
                streetNumber: currentAddress?.streetNumber ?? '',
                streetName: currentAddress?.streetName ?? '',
                streetType: currentAddress?.streetType ?? '',
                suburb: currentAddress?.suburb ?? '',
                state: currentAddress?.state ?? '',
                postcode: currentAddress?.postcode ?? '',
                country: currentAddress?.country ?? '',
                taxReason: '',
              },
            },
          },
        });
      } catch (error) {
        addRumError({
          error,
          context: {
            reason: 'Tax residency - update group: Update tax group failed',
          },
        });
        notification.popToast({
          level: 'error',
          message:
            'There was a problem updating your tax residency. Please try again.',
        });
        return;
      }

      notification.popToast({
        level: 'success',
        message: 'Your tax residency details have been updated',
      });

      onComplete();
    } else {
      navigateToCountries(taxGroup);
    }
  });

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 1 / 3 }}>
            <form onSubmit={onSubmit}>
              <Stack spaceY="lg">
                <PageHeading title="Your tax residency" />

                <Text variant={2} isBold={true} align="center">
                  Where do you pay tax?
                </Text>

                {!!formState.errors.taxGroup?.message && (
                  <Inline spaceX="xs" alignY="center" alignX="center">
                    <Box lineHeight={0}>
                      <FeatherAlertTriangleIcon size="md" color="red.100" />
                    </Box>
                    <Text color="red.100" variant={2} isBold={true}>
                      {formState.errors.taxGroup.message}
                    </Text>
                  </Inline>
                )}

                <Stack spaceY="md">
                  <RadioButton
                    value={TaxGroup.AUSTRALIA_ONLY}
                    error={!!formState.errors.taxGroup}
                    disabled={resp.loading || isUpdating}
                    {...register('taxGroup', requiredValidation('Selection'))}
                  >
                    <Text variant={2} isBold={true} align="center">
                      Australia only
                    </Text>
                  </RadioButton>

                  <RadioButton
                    value={TaxGroup.AUSTRALIA_AND_OVERSEAS}
                    error={!!formState.errors.taxGroup}
                    disabled={resp.loading || isUpdating}
                    {...register('taxGroup', requiredValidation('Selection'))}
                  >
                    <Text variant={2} isBold={true} align="center">
                      Australia and overseas
                    </Text>
                  </RadioButton>

                  <RadioButton
                    value={TaxGroup.OVERSEAS_ONLY}
                    error={!!formState.errors.taxGroup}
                    disabled={resp.loading || isUpdating}
                    {...register('taxGroup', requiredValidation('Selection'))}
                  >
                    <Text variant={2} isBold={true} align="center">
                      Overseas only
                    </Text>
                  </RadioButton>
                </Stack>

                <Text variant={3} color="neutral.080" align="center">
                  By continuing you confirm that your tax residency is correct.
                </Text>
              </Stack>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{
                    name: 'tax_residency_tax_group_continue',
                  }}
                  isDisabled={resp.loading || !!resp.error}
                  isLoading={isUpdating}
                />
              </PageFormButtonContainer>
            </form>
          </Columns.Column>
        </Columns>

        <TaxResidencyFAQ />
      </Stack>
    </PageContainer>
  );
};
