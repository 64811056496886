import * as React from 'react';
export const StreamlineSearch1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M9.713 14.722c2.724 0 4.933-2.243 4.933-5.01s-2.21-5.01-4.933-5.01c-2.725 0-4.933 2.243-4.933 5.01s2.208 5.01 4.933 5.01z" />
    <path vectorEffect="non-scaling-stroke" d="M16.807 14.387l5.427 5.51a1.81 1.81 0 010 2.532 1.743 1.743 0 01-2.493 0l-5.426-5.511c-3.63 2.394-8.455 1.618-11.18-1.8-2.724-3.416-2.469-8.37.591-11.48a8.363 8.363 0 0111.304-.605C18.395 5.8 19.16 10.7 16.805 14.387h.002z" />
  </svg>
);
