import { useNavigate } from '@reach/router';
import { Columns, Stack, Text } from '@spaceship-fspl/components';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useGreenID } from 'contexts/greenid';
import React from 'react';

interface FormLayoutProps {
  title: string;
  image?: {
    url: string;
    alt: string;
  };
  idName: string;
  onSubmit: () => void;
  isSubmitting: boolean;
}

export const FormLayout: React.FC<React.PropsWithChildren<FormLayoutProps>> = ({
  children,
  title,
  image,
  onSubmit,
  isSubmitting,
  idName,
}) => {
  const navigate = useNavigate();
  const { routes } = useGreenID();
  const idNameForTracking = `${idName.toLowerCase().split(' ').join('_')}`;
  return (
    <OnboardingContainer>
      <form aria-label="form" onSubmit={onSubmit}>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 10 / 12 }}>
            <Stack spaceY={{ xs: 'md', md: 'lg' }}>
              <Columns alignX="center">
                <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                  <PageHeading
                    title={title}
                    subtitle="Please ensure the details you enter match the details on your identification."
                  />
                </Columns.Column>
              </Columns>

              <Columns
                alignX="center"
                reverse={{ lg: true }}
                spaceX="xl"
                spaceY="md"
              >
                {image?.url && (
                  <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                    <img src={image.url} alt={image.alt} width="100%" />
                  </Columns.Column>
                )}

                <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                  {children}
                </Columns.Column>
              </Columns>

              <DVSDisclaimer />
            </Stack>
          </Columns.Column>
        </Columns>

        <PageFormButtonContainer>
          <PageFormContinueButton
            trackingProperties={{
              name: `submit_${idNameForTracking}_details`,
            }}
            isLoading={isSubmitting}
          >
            Submit
          </PageFormContinueButton>
          <PageFormCancelButton
            onClick={() => navigate(routes.index)}
            trackingProperties={{
              name: `${idNameForTracking}_try_another_id`,
            }}
            isDisabled={isSubmitting}
          >
            Try another ID
          </PageFormCancelButton>
        </PageFormButtonContainer>
      </form>
    </OnboardingContainer>
  );
};

export const DVSDisclaimer: React.FC<React.PropsWithChildren> = () => (
  <Text variant={4} color="neutral.085">
    Your ID details will be submitted to the Australian Goverment’s Document
    Verification Service (DVS). The DVS is a national online system that allows
    organisations to compare an individual’s identifying information with a
    government record.
    <br />
    <br />
    By pressing ‘Submit,’ you agree that you are authorised to provide the ID
    details and you understand that the details will be checked against records
    held by the Issuer or Official Record Holder.
  </Text>
);
