import * as React from 'react';
export const StreamlineCursorChoose = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11.717 18.775l-4.986-4.986a2.226 2.226 0 013.15-3.148l3.172 3.171 5.94-7.919a2.226 2.226 0 013.562 2.672l-7.482 9.972a2.226 2.226 0 01-3.356.238z" />
    <path vectorEffect="non-scaling-stroke" d="M11.711 8.83l2.203-2.94a2.23 2.23 0 012.394-.804M8.96 19.299a2.226 2.226 0 01-2.32-.524l-4.986-4.986a2.226 2.226 0 012.311-3.676" />
  </svg>
);
