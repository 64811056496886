import { gql } from '@apollo/client';
import {
  ActionLink,
  Box,
  Divider,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { webAppVoyagerInvestmentPlanCard_account_saverProductInstances } from '@spaceship-fspl/graphql/src/__generated__/webAppVoyagerInvestmentPlanCard';
import { formatCurrency } from '@spaceship-fspl/helpers';
import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from 'components/account-cards/components';
import { RouterLink } from 'components/router-link';
import { fromISODate, toLongDate } from 'helpers/format';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React from 'react';

export const voyagerInvestmentPlanCardFragment = gql`
  fragment webAppVoyagerInvestmentPlanCard on Contact {
    id
    account {
      id
      saverProductInstances {
        id
        portfolio
        upcomingSchedule {
          id
          audAmount
          frequency
          nextDue
        }
      }
    }
  }
`;

export const VoyagerInvestmentPlanCard: React.FC<
  React.PropsWithChildren<{
    isLoading: boolean;
    productInstances?:
      | webAppVoyagerInvestmentPlanCard_account_saverProductInstances[]
      | null;
  }>
> = ({ isLoading, productInstances }) => {
  return (
    <AccountCard>
      <AccountCardHeading title="Investment plan" />
      <AccountCardContent isLoading={isLoading}>
        <Stack spaceY="xs">
          {productInstances?.map((product, index) => {
            const config = voyagerPortfolios[product.portfolio];
            const PortfolioIcon = config.icon.default;

            return (
              <React.Fragment key={product.id}>
                {index !== 0 && <Divider />}

                <Box display="flex">
                  <PortfolioIcon color="indigo.070" size="xxl" />

                  <Box
                    display="flex"
                    flex={1}
                    paddingLeft="xs"
                    paddingTop="xxs"
                  >
                    <Box flex={1} marginRight="xs">
                      <Text variant={2} isBold={true}>
                        {config.title}
                      </Text>

                      {product.upcomingSchedule?.audAmount ? (
                        <Box marginTop="xxxs">
                          <Inline spaceX="sm" spaceY="xxxs" alignY="bottom">
                            <div>
                              <Text variant={4} color="neutral.080">
                                Amount
                              </Text>
                              <Text variant={3}>
                                {formatCurrency(
                                  product.upcomingSchedule?.audAmount,
                                )}{' '}
                                {(
                                  product.upcomingSchedule.frequency || ''
                                ).toLocaleLowerCase()}
                              </Text>
                            </div>

                            <div>
                              <Text variant={4} color="neutral.080">
                                Next investment
                              </Text>
                              <Text variant={3}>
                                {product.upcomingSchedule.nextDue
                                  ? toLongDate(
                                      fromISODate(
                                        product.upcomingSchedule.nextDue,
                                      ),
                                    )
                                  : ''}
                              </Text>
                            </div>
                          </Inline>
                        </Box>
                      ) : (
                        <Text variant={3}>No plan</Text>
                      )}
                    </Box>

                    <RouterLink
                      to={`${Routes.VOYAGER_INVESTMENT_PLAN}/${product.id}`}
                      aria-label={`investment plan update link ${product.portfolio.toLowerCase()}`}
                      trackingProperties={{
                        name: 'voyager_investment_plan_card_update',
                      }}
                    >
                      <ActionLink color="indigo.070" size="sm" component="span">
                        Update
                      </ActionLink>
                    </RouterLink>
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Stack>
      </AccountCardContent>
    </AccountCard>
  );
};
