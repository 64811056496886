import { RouteComponentProps, useNavigate } from '@reach/router';
import { Success } from 'components/layouts/success';
import { Routes } from 'pages/routes';
import React from 'react';

interface BoostsBasiqSuccessProps {
  location: {
    state: {
      type:
        | 'add-source'
        | 'add-source-and-create-recipe'
        | 'reconnect-source'
        | 'disconnect-source';
    };
  };
}

export const BoostsBasiqSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps<BoostsBasiqSuccessProps>>
> = (props) => {
  const navigate = useNavigate();
  let title = '';
  let subtitle = '';
  let continueRoute = Routes.BOOSTS_DASHBOARD_HOME;

  switch (props.location?.state?.type) {
    case 'add-source':
      title = 'We’re connecting your tracking account';
      subtitle =
        'Check back in 10 minutes to make sure your tracking account is successfully connected';
      continueRoute = Routes.BOOSTS_DASHBOARD_SETTINGS;
      break;

    case 'add-source-and-create-recipe':
      title = 'We’re linking your tracking bank and setting up your boost now';
      continueRoute = Routes.BOOSTS_DASHBOARD_BOOSTS;
      break;

    case 'reconnect-source':
      title = 'We’re reconnecting your linked tracking account';
      continueRoute = Routes.BOOSTS_DASHBOARD_SETTINGS;
      break;

    case 'disconnect-source':
      title = 'We’ve unlinked your tracking bank';
      continueRoute = Routes.BOOSTS_DASHBOARD_SETTINGS;
      break;
  }

  return (
    <Success
      title={title}
      subtitle={subtitle}
      buttonText="Done"
      onContinue={{
        onClick: () => navigate(continueRoute),
        trackingProperties: {
          name: 'voyager_boost_basiq_success_done_button',
        },
      }}
    />
  );
};
