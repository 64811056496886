import { Box, Card, Spinner, Text, TextLink } from '@spaceship-fspl/components';
import { RouterLink } from 'components/router-link';
import { TransactionItem } from 'components/transactions';
import { Routes } from 'pages/routes';
import React, { memo } from 'react';

export interface UpcomingInvestment {
  title: string;
  amount: string;
  iconName: string;
  requestedAt?: Date;
}

interface UpcomingInvestmentsCardProps {
  productId: string;
  hasBoosts: boolean;
  upcomingInvestments: Array<UpcomingInvestment>;
  isLoading: boolean;
}

export const UpcomingInvestmentsCard: React.FC<
  React.PropsWithChildren<UpcomingInvestmentsCardProps>
> = memo(({ productId, hasBoosts, upcomingInvestments, isLoading }) => {
  return (
    <Card
      aria-label="voyager upcoming investments card"
      display="flex"
      height="100%"
    >
      {isLoading ? (
        <Box
          display="flex"
          flex={1}
          padding="md"
          justifyContent="center"
          alignItems="center"
          height={{ xs: 200, xl: 280 }}
        >
          <Spinner />
        </Box>
      ) : upcomingInvestments.length > 0 ? (
        <Box flex={1} paddingX="md" paddingY="xxs">
          {upcomingInvestments.slice(0, 4).map((transaction) => (
            <TransactionItem
              key={transaction.title}
              isExpandable={false}
              {...transaction}
            />
          ))}
        </Box>
      ) : (
        <Box
          minHeight={{ xl: 280 }}
          padding="md"
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text variant={2} color="neutral.080" isBold={true} align="center">
            No upcoming investments at this time.
            <br />
            <br />
            Add to your balance by{' '}
            <RouterLink
              to={`${Routes.VOYAGER_INVESTMENT_PLAN}/${productId}`}
              trackingProperties={{
                name: 'voyager_dashboard_page_upcoming_investments_setup_plan',
              }}
            >
              <TextLink color="indigo.070" component="span">
                setting up a plan
              </TextLink>
            </RouterLink>
            {', making a '}
            <RouterLink
              to={`${Routes.VOYAGER_DEPOSIT}/${productId}`}
              trackingProperties={{
                name: 'voyager_dashboard_page_upcoming_investments_one_off_investment',
              }}
            >
              <TextLink color="indigo.070" component="span">
                one-off investment
              </TextLink>
            </RouterLink>
            <>
              {' '}
              or{' '}
              <RouterLink
                to={
                  hasBoosts ? Routes.BOOSTS_RECIPE_SELECT : Routes.BOOSTS_INTRO
                }
                trackingProperties={{
                  name: 'voyager_dashboard_page_upcoming_investments_boost',
                }}
              >
                <TextLink color="indigo.070" component="span">
                  trying a boost
                </TextLink>
              </RouterLink>
            </>
            .
          </Text>
        </Box>
      )}
    </Card>
  );
});

UpcomingInvestmentsCard.displayName = 'UpcomingInvestmentsCard';
