import { useNavigate } from '@reach/router';
import { Box, Heading } from '@spaceship-fspl/components';
import {
  BoostRecipeStatus,
  useUpdateBoostRecipe,
} from '@spaceship-fspl/graphql';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
} from 'components/layouts/page';
import { Modal } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { Routes } from 'pages/routes';
import React from 'react';

export interface RemoveBoostModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  recipeId?: string;
}

export const RemoveBoostModal: React.FC<
  React.PropsWithChildren<RemoveBoostModalProps>
> = ({ showModal, onCloseModal, recipeId }) => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [updateBoostRecipe, updateBoostRecipeMeta] = useUpdateBoostRecipe();

  const removeBoost = async (): Promise<void> => {
    if (recipeId) {
      try {
        await updateBoostRecipe({
          variables: {
            input: {
              id: recipeId,
              status: BoostRecipeStatus.DELETED,
            },
          },
        });
        onCloseModal();

        await navigate(Routes.BOOSTS_DASHBOARD_BOOSTS);
        notifications.popToast({
          level: 'success',
          message: 'You have successfully removed your boost',
        });
      } catch (error) {
        notifications.popToast({
          level: 'error',
          message:
            (error as Error | undefined)?.message || GENERIC_ERROR_MESSAGE,
        });
      }
    }
  };

  return (
    <Modal showModal={showModal && !!recipeId} closeModal={onCloseModal}>
      <Box
        padding="xl"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading variant={3} align="center" component="h3">
          Are you sure you want to remove this boost?
        </Heading>

        <PageFormButtonContainer>
          <PageFormContinueButton
            type="button"
            onClick={removeBoost}
            isLoading={updateBoostRecipeMeta.loading}
            trackingProperties={{
              name: 'remove_boost_modal_confirm_button',
            }}
          >
            Yes – remove this boost
          </PageFormContinueButton>

          <PageFormCancelButton
            isDisabled={updateBoostRecipeMeta.loading}
            onClick={onCloseModal}
            trackingProperties={{ name: 'remove_boost_modal_cancel_button' }}
          />
        </PageFormButtonContainer>
      </Box>
    </Modal>
  );
};
