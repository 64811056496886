import {
  alignItems,
  backgroundColor,
  borderRadius,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  borderRadiusTopLeft,
  borderRadiusTopRight,
  bottom,
  boxShadow,
  color,
  display,
  flex,
  flexDirection,
  flexGrow,
  flexShrink,
  height,
  justifyContent,
  left,
  lineHeight,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position,
  right,
  top,
  width,
} from '@spaceship-fspl/styles';
import { createProps } from '@spaceship-fspl/substance-style';
import styled from 'styled-components';

export const Box = styled.div`
  box-sizing: border-box;
  ${createProps({
    backgroundColor,
    borderRadius,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    borderRadiusBottomLeft,
    borderRadiusBottomRight,
    boxShadow,
    color,
    display,
    lineHeight,
    position,
    overflow,
    top,
    bottom,
    left,
    right,
    width,
    maxWidth,
    minWidth,
    height,
    maxHeight,
    minHeight,

    flex,
    flexDirection,
    flexGrow,
    flexShrink,
    alignItems,
    justifyContent,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
  })}
`;
