import * as React from 'react';
export const StreamlineBusinessContractApprove = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1 2.434l1.467 1.85v4.368c-.04 1.65 1.874 3.129 3.665 4.647M5.381 7.461l1.73 1.772A1.191 1.191 0 108.817 7.57l-2.69-2.745v-1.43A6.146 6.146 0 005.171 1" />
    <path vectorEffect="non-scaling-stroke" d="M6.133 8.224v11.843c0 .81.657 1.466 1.467 1.466h7.333M23 21.565l-1.467-1.85v-4.367c.035-1.427-1.39-2.724-2.933-4.028v5.2l-1.711-1.753a1.191 1.191 0 00-1.706 1.662l2.693 2.746v1.43c.147.855.473 1.67.957 2.391M12.956 17.867h-3.89M10.533 14.2H9.067M20.067 5.4a4.4 4.4 0 11-4.4-4.4" />
    <path vectorEffect="non-scaling-stroke" d="M20.067 2.099l-4.01 4.009a.551.551 0 01-.781 0l-1.26-1.261" />
  </svg>
);
