/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Columns,
  Inline,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { useBoolean } from '@spaceship-fspl/dynamic-config';
import {
  formatCurrencyWithCodeAndSymbol,
  formatTimeScale,
  sydneyDate,
  TimeScale,
  toSydneyStartOfDay,
} from '@spaceship-fspl/helpers';
import { Color } from '@spaceship-fspl/styles';
import { Chart } from 'components/chart';
import {
  ChartTabSelector,
  chartTimeScaleTabOptions,
} from 'components/chart-tab-selector';
import { LabeledField } from 'components/labeled-field';
import { PerformanceIndicator } from 'components/performance-indicator';
import { subDays, subMonths, subYears } from 'date-fns';
import { FeatureFlagKeys } from 'helpers/dynamic-config';
import { formatCurrency, formatPercentage, toLongDate } from 'helpers/format';
import React, { memo, useMemo, useState } from 'react';

const fieldColors: Record<'label' | 'value', Color> = {
  label: 'neutral.070',
  value: 'neutral.000',
};

interface DatedBalance {
  date: string;
  audAmount: string;
}

interface Instrument {
  audLatestPrice: string;
  marketPrices?: Array<DatedBalance> | null;
}

export const CompanyChart: React.FC<
  React.PropsWithChildren<{
    isLoading: boolean;
    instrument?: Instrument | null;
  }>
> = memo(({ isLoading, instrument }) => {
  const showGraphPercentageChanges = useBoolean(
    FeatureFlagKeys.VOYAGER_GRAPH_PERCENTAGE_CHANGES_ENABLED,
    false,
  );
  const [scale, setScale] = useState<TimeScale>(TimeScale.SIX_MONTHS);

  // TODO: GRAPH-68 - Remove sorting once its fixed
  const prices = useMemo(() => {
    return (instrument?.marketPrices ?? []).slice().sort((day1, day2) => {
      if (!(day1.date && day2.date)) {
        return 0;
      }
      return new Date(day1.date).getTime() - new Date(day2.date).getTime();
    });
  }, [instrument?.marketPrices]);

  const [scaledGraphData, scaledChange] = useMemo(() => {
    switch (scale) {
      case TimeScale.ONE_MONTH:
        return [
          getPricePoints(prices, subMonths(sydneyDate(), 1)),
          calculatePerformance(prices, subMonths, 1),
        ];
      case TimeScale.THREE_MONTHS:
        return [
          getPricePoints(prices, subMonths(sydneyDate(), 3)),
          calculatePerformance(prices, subMonths, 3),
        ];
      case TimeScale.SIX_MONTHS:
        return [
          getPricePoints(prices, subMonths(sydneyDate(), 6)),
          calculatePerformance(prices, subMonths, 6),
        ];
      case TimeScale.TWELVE_MONTHS:
        return [
          getPricePoints(prices, subMonths(sydneyDate(), 12)),
          calculatePerformance(prices, subMonths, 12),
        ];
      case TimeScale.MAX:
      default:
        return [prices, calculatePerformance(prices, subYears, 100)];
    }
  }, [prices, scale]);

  const dayChange = useMemo(
    () => calculatePerformance(prices, subDays, 2),
    [prices],
  );

  const defaultZeroes = new Array(365)
    .fill(undefined)
    .map((_, i) => ({
      x: subDays(sydneyDate(), i).getTime(),
      y: 0,
    }))
    .reverse()
    .filter(({ x }) => {
      switch (scale) {
        case TimeScale.ONE_MONTH:
          return x > subMonths(sydneyDate(), 1).getTime();
        case TimeScale.THREE_MONTHS:
          return x > subMonths(sydneyDate(), 3).getTime();
        case TimeScale.SIX_MONTHS:
          return x > subMonths(sydneyDate(), 6).getTime();
        case TimeScale.TWELVE_MONTHS:
        case TimeScale.MAX:
          return true;
      }
    });

  return (
    <>
      <Columns>
        <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 3 / 5 }}>
          <Inline spaceX="md">
            <LabeledField size="md" label="Share price" colors={fieldColors}>
              {instrument?.audLatestPrice
                ? formatCurrencyWithCodeAndSymbol(
                    parseFloat(instrument.audLatestPrice),
                  )
                : '—'}
            </LabeledField>

            {showGraphPercentageChanges ? (
              <>
                <LabeledField
                  size="md"
                  label={formatTimeScale(scale)}
                  colors={fieldColors}
                >
                  {scaledChange && (
                    <Box display="flex" alignItems="center">
                      <Box marginRight="xxxs" lineHeight={0}>
                        <PerformanceIndicator
                          direction={scaledChange < 0 ? 'down' : 'up'}
                        />
                      </Box>
                      {formatPercentage(Math.abs(scaledChange), 2)}
                    </Box>
                  )}
                </LabeledField>

                <LabeledField
                  size="md"
                  label="Daily change"
                  colors={fieldColors}
                >
                  {dayChange && (
                    <Box display="flex" alignItems="center">
                      <Box marginRight="xxxs" lineHeight={0}>
                        <PerformanceIndicator
                          direction={dayChange < 0 ? 'down' : 'up'}
                        />
                      </Box>
                      {formatPercentage(Math.abs(dayChange), 2)}
                    </Box>
                  )}
                </LabeledField>
              </>
            ) : null}
          </Inline>
        </Columns.Column>

        <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 2 / 5 }}>
          <Visible isHidden={{ xs: true, lg: false }}>
            <ChartTabSelector
              options={chartTimeScaleTabOptions}
              selected={scale}
              color="indigo.060"
              setSelected={(t: TimeScale): void => setScale(t)}
            />
          </Visible>
        </Columns.Column>
      </Columns>

      <Box height={250} marginBottom="md">
        {scaledGraphData.length > 0 || isLoading ? (
          <Chart
            tooltipEnabled={true}
            tooltipBackgroundColor="neutral.000"
            labelColor="neutral.070"
            tooltipTextColor="black.100"
            formatX={(value): string => toLongDate(new Date(value))}
            formatY={(value): string => formatCurrency(value)}
            endLabels={true}
            color="indigo.050"
            endColor="pink.100"
            data={
              isLoading
                ? defaultZeroes
                : scaledGraphData.map(({ date, audAmount }) => ({
                    x: new Date(date || 0).getTime(),
                    y: Number(audAmount),
                  }))
            }
          />
        ) : (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text variant={2} color="neutral.000">
              No data available.
            </Text>
          </Box>
        )}
      </Box>

      <Box display={{ lg: 'none' }} marginTop="md">
        <ChartTabSelector
          options={chartTimeScaleTabOptions}
          selected={scale}
          color="indigo.060"
          setSelected={(t: TimeScale): void => setScale(t)}
        />
      </Box>
    </>
  );
});

CompanyChart.displayName = 'CompanyChart';

const getPricePoints = (data: DatedBalance[], from: Date): DatedBalance[] =>
  data.filter(({ date }) => date && toSydneyStartOfDay(new Date(date)) >= from);

/**
 * Calculates the performance delta in percentage
 * between the last item in our list and the first
 * point outside the bounds specified by fn and num
 */
const calculatePerformance = (
  points: DatedBalance[],
  fn: (date: Date | number, num: number) => Date,
  num: number,
): number | undefined => {
  const mostRecentPrice = points.slice(-1)[0];
  if (!(mostRecentPrice && mostRecentPrice.date)) {
    return undefined;
  }

  const thresholdDate = fn(new Date(mostRecentPrice.date), num);
  const thresholdIndex = points.findIndex(
    ({ date }) => date && toSydneyStartOfDay(new Date(date)) > thresholdDate,
  );

  let dateBoundedPoints = [];
  if (thresholdIndex <= 0) {
    dateBoundedPoints = points;
  } else {
    dateBoundedPoints = points.slice(thresholdIndex - 1);
  }

  if (dateBoundedPoints.length >= 2) {
    const { audAmount: startPrice } = dateBoundedPoints.slice(0)[0]!;
    const { audAmount: endPrice } = dateBoundedPoints.slice(-1)[0]!;
    return (Number(endPrice) / Number(startPrice) - 1) * 100;
  }

  return undefined;
};
