import { RouteComponentProps, useParams } from '@reach/router';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import { VoyagerTransactionSuccess } from 'components/voyager-transaction-success';
import React from 'react';

export interface VoyagerInvestmentPlanSuccessProps {
  productId?: string;
  location: {
    state: {
      productId: string;
      audAmount: string;
      etaDate: Date;
      frequency: Exclude<
        SchedulerFrequency.Enum,
        SchedulerFrequency.Enum.UNKNOWN | SchedulerFrequency.Enum.ONE_TIME
      >;
    };
  };
}

export const VoyagerInvestmentPlanSuccess: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<VoyagerInvestmentPlanSuccessProps>
  >
> = (props) => {
  const { productId = '' } = useParams();
  const audAmount = props?.location?.state?.audAmount ?? '';
  const frequency = props?.location?.state?.frequency;
  const etaDate = props?.location?.state?.etaDate
    ? new Date(props?.location?.state?.etaDate)
    : undefined;

  return (
    <VoyagerTransactionSuccess
      variant="invest"
      productId={productId}
      amount={audAmount}
      frequency={frequency}
      etaDate={etaDate}
    />
  );
};
