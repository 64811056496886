import * as React from 'react';
export const DatabaseSearch = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M10.138 8.719c4.967 0 8.988-1.694 8.988-3.785 0-2.09-4.021-3.784-8.988-3.784-4.968 0-8.989 1.693-8.989 3.784 0 2.091 4.021 3.785 8.989 3.785zM11.557 12.456c-.464.029-.937.048-1.42.048-4.967 0-8.988-1.694-8.988-3.785M10.138 16.761c-4.968 0-8.989-1.693-8.989-3.784" />
    <path vectorEffect="non-scaling-stroke" d="M10.138 21.019c-4.968 0-8.989-1.694-8.989-3.785v-12.3M19.126 4.935v5.203M16.789 21.076a4.288 4.288 0 004.286-4.287 4.288 4.288 0 00-4.286-4.286 4.288 4.288 0 00-4.286 4.286 4.288 4.288 0 004.286 4.287zM22.91 22.91l-3.074-3.074" />
  </svg>
);
