import type { useApolloClient } from '@apollo/client';
import {
  ApolloCache,
  gql,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Gender,
  SaverPortfolio,
  SchedulerFrequency,
  SuperProductInstance,
  TFNExemptionCode,
} from '@spaceship-fspl/types/externalapi';

import { ActiveBankAccount } from './__generated__/ActiveBankAccount';
import {
  AddBankAccount,
  AddBankAccountVariables,
} from './__generated__/AddBankAccount';
import {
  AddProductIntent,
  AddProductIntentVariables,
} from './__generated__/AddProductIntent';
import { addressScalars as addressScalarsInterface } from './__generated__/addressScalars';
import {
  ApplyVoyagerReferralCode,
  ApplyVoyagerReferralCodeVariables,
} from './__generated__/ApplyVoyagerReferralCode';
import { AppStoreLinks } from './__generated__/AppStoreLinks';
import { BankBranch, BankBranchVariables } from './__generated__/BankBranch';
import {
  CancelApplication,
  CancelApplicationVariables,
} from './__generated__/CancelApplication';
import {
  CancelBoostItemGroup,
  CancelBoostItemGroupVariables,
} from './__generated__/CancelBoostItemGroup';
import {
  CancelNovaInvestmentPlan,
  CancelNovaInvestmentPlanVariables,
} from './__generated__/CancelNovaInvestmentPlan';
import {
  CancelNovaOrder,
  CancelNovaOrderVariables,
} from './__generated__/CancelNovaOrder';
import {
  CancelPaymentSchedulesForProductInstances,
  CancelPaymentSchedulesForProductInstancesVariables,
} from './__generated__/CancelPaymentSchedulesForProductInstances';
import { CanUpdateBankAccount } from './__generated__/CanUpdateBankAccount';
import {
  CheckMfaVerification,
  CheckMfaVerificationVariables,
} from './__generated__/CheckMfaVerification';
import {
  CloseSaverProductInstances,
  CloseSaverProductInstancesVariables,
} from './__generated__/CloseSaverProductInstances';
import { Countries } from './__generated__/Countries';
import {
  CreateBasiqConnection,
  CreateBasiqConnectionVariables,
} from './__generated__/CreateBasiqConnection';
import type {
  CreateBindingBeneficiaryIntent,
  CreateBindingBeneficiaryIntentVariables,
} from './__generated__/CreateBindingBeneficiaryIntent';
import {
  CreateBoostRecipe,
  CreateBoostRecipeVariables,
} from './__generated__/CreateBoostRecipe';
import type {
  CreateMoneyDeposit,
  CreateMoneyDepositVariables,
} from './__generated__/CreateMoneyDeposit';
import type {
  CreateMoneyWithdrawal,
  CreateMoneyWithdrawalVariables,
} from './__generated__/CreateMoneyWithdrawal';
import { CreateNovaAccountApplication } from './__generated__/CreateNovaAccountApplication';
import {
  CreateOrUpdateNovaTrustedContact,
  CreateOrUpdateNovaTrustedContactVariables,
} from './__generated__/CreateOrUpdateNovaTrustedContact';
import {
  CreatePaymentSchedule,
  CreatePaymentScheduleVariables,
} from './__generated__/CreatePaymentSchedule';
import {
  CreatePayToAgreement,
  CreatePayToAgreementVariables,
} from './__generated__/CreatePayToAgreement';
import {
  CreateRedemption,
  CreateRedemptionVariables,
} from './__generated__/CreateRedemption';
import {
  CreateSargonMember,
  CreateSargonMemberVariables,
} from './__generated__/CreateSargonMember';
import {
  CreateSaverInvestmentGoal,
  CreateSaverInvestmentGoalVariables,
} from './__generated__/CreateSaverInvestmentGoal';
import {
  CreateSaverProductInstances,
  CreateSaverProductInstancesVariables,
} from './__generated__/CreateSaverProductInstances';
import {
  DeleteForeignTaxResidences,
  DeleteForeignTaxResidencesVariables,
} from './__generated__/DeleteForeignTaxResidences';
import {
  DeleteSaverInvestmentGoal,
  DeleteSaverInvestmentGoalVariables,
} from './__generated__/DeleteSaverInvestmentGoal';
import { DeleteTfn, DeleteTfnVariables } from './__generated__/DeleteTfn';
import {
  DeleteTfnExemption,
  DeleteTfnExemptionVariables,
} from './__generated__/DeleteTfnExemption';
import { Dismiss, DismissVariables } from './__generated__/Dismiss';
import {
  EstimatedApplicationExecutionDate,
  EstimatedApplicationExecutionDateVariables,
} from './__generated__/EstimatedApplicationExecutionDate';
import { ForeignTaxResidences } from './__generated__/ForeignTaxResidences';
import type {
  GenerateNovaMultiMonthStatement,
  GenerateNovaMultiMonthStatementVariables,
} from './__generated__/GenerateNovaMultiMonthStatement';
import { GetBiometricVerificationStatus } from './__generated__/GetBiometricVerificationStatus';
import {
  Gender as GQLGender,
  SaverPortfolio as GQLSaverPortfolio,
  ScheduleFrequency,
  SuperJoinType,
  TFNExemptionCode as GQLTFNExemptionCode,
} from './__generated__/globalTypes';
import { InitiateAccountFeePayment } from './__generated__/InitiateAccountFeePayment';
import {
  InitiateBiometricVerification,
  InitiateBiometricVerificationVariables,
} from './__generated__/InitiateBiometricVerification';
import {
  InitiateMfaVerification,
  InitiateMfaVerificationVariables,
} from './__generated__/InitiateMfaVerification';
import {
  LinkWithSargon,
  LinkWithSargonVariables,
} from './__generated__/LinkWithSargon';
import {
  PlaceNovaOrder,
  PlaceNovaOrderVariables,
} from './__generated__/PlaceNovaOrder';
import {
  PreviewNovaBuyOrder,
  PreviewNovaBuyOrderVariables,
} from './__generated__/PreviewNovaBuyOrder';
import {
  PreviewNovaSellOrder,
  PreviewNovaSellOrderVariables,
} from './__generated__/PreviewNovaSellOrder';
import {
  ReconnectBasiqConnection,
  ReconnectBasiqConnectionVariables,
} from './__generated__/ReconnectBasiqConnection';
import {
  RemoveBasiqConnection,
  RemoveBasiqConnectionVariables,
} from './__generated__/RemoveBasiqConnection';
import { SaverPortfolioFlags } from './__generated__/SaverPortfolioFlags';
import { SaverPortfolios } from './__generated__/SaverPortfolios';
import { SaverTMDDetails } from './__generated__/SaverTMDDetails';
import {
  SendEmployerContributionEmail,
  SendEmployerContributionEmailVariables,
} from './__generated__/SendEmployerContributionEmail';
import {
  SendPasswordResetCode,
  SendPasswordResetCodeVariables,
} from './__generated__/SendPasswordResetCode';
import {
  SendSaverAccountStatement,
  SendSaverAccountStatementVariables,
} from './__generated__/SendSaverAccountStatement';
import {
  SetForeignTaxResidences,
  SetForeignTaxResidencesVariables,
} from './__generated__/SetForeignTaxResidences';
import {
  SetNovaInvestmentPlan,
  SetNovaInvestmentPlanVariables,
} from './__generated__/SetNovaInvestmentPlan';
import {
  SetPrimarySaverProductInstance,
  SetPrimarySaverProductInstanceVariables,
} from './__generated__/SetPrimarySaverProductInstance';
import {
  SetSaverTargetMarketDeterminationAnswers,
  SetSaverTargetMarketDeterminationAnswersVariables,
} from './__generated__/SetSaverTargetMarketDeterminationAnswers';
import {
  SetSuperTargetMarketDeterminationAnswers,
  SetSuperTargetMarketDeterminationAnswersVariables,
} from './__generated__/SetSuperTargetMarketDeterminationAnswers';
import {
  SetTaxResidency,
  SetTaxResidencyVariables,
} from './__generated__/SetTaxResidency';
import type {
  SubmitPreApprovedTargetMarketDeterminationAnswers,
  SubmitPreApprovedTargetMarketDeterminationAnswersVariables,
} from './__generated__/SubmitPreApprovedTargetMarketDeterminationAnswers';
import { SuperPortfolioFlags } from './__generated__/SuperPortfolioFlags';
import { SuperTMDDetails } from './__generated__/SuperTMDDetails';
import {
  TopUpAndCloseBoostItemGroup,
  TopUpAndCloseBoostItemGroupVariables,
} from './__generated__/TopUpAndCloseBoostItemGroup';
import {
  UpdateBoostRecipe,
  UpdateBoostRecipeVariables,
} from './__generated__/UpdateBoostRecipe';
import {
  UpdateContact,
  UpdateContactVariables,
} from './__generated__/UpdateContact';
import {
  UpdateFHSSStatus,
  UpdateFHSSStatusVariables,
} from './__generated__/UpdateFHSSStatus';
import {
  UpdateNovaAccountApplicationFields,
  UpdateNovaAccountApplicationFieldsVariables,
} from './__generated__/UpdateNovaAccountApplicationFields';
import {
  UpdatePassword,
  UpdatePasswordVariables,
} from './__generated__/UpdatePassword';
import {
  UpdatePasswordByCurrentPassword,
  UpdatePasswordByCurrentPasswordVariables,
} from './__generated__/UpdatePasswordByCurrentPassword';
import {
  UpdatePayToAgreement,
  UpdatePayToAgreementVariables,
} from './__generated__/UpdatePayToAgreement';
import {
  UpdateSaverInvestmentGoal,
  UpdateSaverInvestmentGoalVariables,
} from './__generated__/UpdateSaverInvestmentGoal';
import { UpdateTfn, UpdateTfnVariables } from './__generated__/UpdateTfn';
import {
  UpdateTfnExemption,
  UpdateTfnExemptionVariables,
} from './__generated__/UpdateTfnExemption';
import { UserProducts } from './__generated__/UserProducts';
import { ValidateTIN, ValidateTINVariables } from './__generated__/ValidateTIN';
import {
  VerifyPasswordResetCode,
  VerifyPasswordResetCodeVariables,
} from './__generated__/VerifyPasswordResetCode';
import {
  NovaTransaction_NovaBuyTransactionFragment,
  NovaTransaction_NovaRegulatoryFeeTransactionFragment,
  NovaTransaction_NovaSellTransactionFragment,
} from './fragments';

export * from './__generated__/globalTypes';
export * from './errors';
export * from './fragments';

export interface DatedBalance {
  date: string;
  audAmount: string;
}

export const countryScalars = gql`
  fragment countryScalars on Country {
    id
    name
    countryCode
    countryCode3Letter
    isNovaAllowed
    tinRequired
    tinInformationLink
    taxResidencyAllowed
  }
`;

const basiqConnectionScalars = gql`
  fragment basiqConnectionScalars on BasiqConnection {
    id
    status
    connectedAt
    institution {
      id
      name
    }
  }
`;

export const addressScalars = gql`
  fragment addressScalars on Address {
    country
    postcode
    rawAddress
    streetAddress
    state
    suburb
    streetName
    streetNumber
    streetType
    unitNumber
  }
`;

export const phoneNumberVerificationScalars = gql`
  fragment phoneNumberVerificationScalars on Contact {
    id
    phoneNumberVerified
    phoneNumberVerificationRequired
  }
`;

const contactScalars = gql`
  fragment contactScalars on Contact {
    id
    email
    firstName
    lastName
    preferredName
    phoneNumberMasked
    dateOfBirth
    address {
      ...addressScalars
    }
    status
    taxGroup
    taxResidenceStatus
    createdAt
    lastUpdatedAt
    biometricVerificationStatus
    ...phoneNumberVerificationScalars
  }
  ${addressScalars}
  ${phoneNumberVerificationScalars}
`;

const accountScalars = gql`
  fragment accountScalars on Account {
    id
  }
`;

export const missingTINDetailsScalars = gql`
  fragment missingTINDetailsScalars on MissingTINDetails {
    active
    reason
    otherReasonDescription
  }
`;

export const foreignTaxResidenceScalars = gql`
  fragment foreignTaxResidenceScalars on ForeignTaxResidence {
    id
    country {
      id
      countryCode
      name
    }
    primaryResidence
    tinProvided
    createdAt
    lastUpdatedAt
  }
`;

const saverProductInstanceScalars = gql`
  fragment saverProductInstanceScalars on SaverProductInstance {
    id
    portfolio
    tfnProvided
    tfnExemptionCode
    tfnLastUpdatedAt
    createdAt
  }
`;

export const saverProductInstanceConnectionEdgeScalars = gql`
  fragment saverProductInstanceConnectionEdgeScalars on SaverProductInstancesConnectionEdge {
    node {
      id
      primary
      portfolio

      dailyBalances {
        date
        audAmount
      }
      investments {
        id
        summary {
          id
          audBalance
          audMarketReturn
          unitBalance
        }
      }
      upcomingSchedule {
        id
        frequency
        audAmount
        nextDue
      }
    }
  }
`;

export const bankAccountScalars = gql`
  fragment bankAccountScalars on BankAccount {
    id
    accountNumber
    bsb
    friendlyName
    accountName
    createdAt
    lastUpdatedAt
  }
`;

export const unitExchangeScalars = gql`
  fragment unitExchangeScalars on UnitExchange {
    id
    units
    createdAt
  }
`;

export const unitPriceScalars = gql`
  fragment unitPriceScalars on UnitPrice {
    id
    price
    effectiveDate
  }
`;

export const scheduleScalars = gql`
  fragment scheduleScalars on Schedule {
    id
    frequency
    audAmount
    nextDue
  }
`;

const redemptionScalars = gql`
  fragment redemptionScalars on Redemption {
    id
    audAmount
    requestedAt
    status
  }
`;

export const investmentSummaryScalars = gql`
  fragment investmentSummaryScalars on InvestmentSummary {
    id
    unitBalance
    audBalance
    audInvested
    audWithdrawn
    audReferralTotal
    audPromotionTotal
    audMarketReturn
    audAccountFeesPaid
  }
`;

export const articleScalars = gql`
  fragment articleScalars on Article {
    id
    heading
    subheading
    articleUrl
    imageUrl
    publication
    publicationUrl
  }
`;

export const superPortfolioInformationScalars = gql`
  fragment superPortfolioInformationFields on SuperPortfolioInformation {
    id
    name
    portfolio
    title
    description
    learnMoreUrl
    inceptionDate
    investmentFee
    adminFeePercent
    audAdminFeeBase
    adminFeeThirdPartyPercent
    feePercentTotal
    performanceAsAt
    oneMonthPerformancePercentage
    threeMonthPerformancePercentage
    sixMonthPerformancePercentage
    oneYearPerformancePercentage
    threeYearPerformancePercentage
    sinceInceptionPerformancePercentage
    strategicAssetAllocations {
      id
      name
      percentage
    }
    strategicAssetAllocationsInfo
    investmentRiskLevel
    riskProfile
    estimatedNegativeAnnualReturnsOver20Years
  }
`;

export const saverPortfolioInformationScalars = gql`
  fragment voyagerPortfolioInformationFields on SaverPortfolioInformation {
    id
    name
    portfolio
    title
    description
    learnMoreUrl
    inceptionDate
    aboutPortfolio
    performanceAsAt
    oneMonthPerformancePercentage
    threeMonthPerformancePercentage
    sixMonthPerformancePercentage
    oneYearPerformancePercentage
    threeYearPerformancePercentage
    sinceInceptionPerformancePercentage
    accountFee
    accountFeeBalanceCopy
    accountFeeAllPorfoliosCopy
    managementFee
    managementFeeCopy
    tmdSummary
    powerUp
  }
`;

export const voyagerPortfolioPerformanceScalars = gql`
  fragment voyagerPortfolioPerformanceFields on SaverPortfolioPerformance {
    oneDayPerformancePercentage
    oneWeekPerformancePercentage
    oneMonthPerformancePercentage
    oneYearPerformancePercentage
  }
`;

export const novaAssetGroupInformationScalars = gql`
  fragment novaAssetGroupInformationFields on NovaAssetGroupInformation {
    id
    assetGroup
    name
    title
    description
    features
  }
`;

export const voyagerReferralsCardScalars = gql`
  fragment voyagerReferralsCardFields on Account {
    id
    saverReferralShareDetails {
      id
      status
      code
      campaign {
        id
        title
        audReceiverRewardAmount
        audSharerRewardAmount
        audReceiverMinimumInvestmentAmount
        audSharerMinimumInvestmentAmount
        endDate
      }
      rewardDestinationProductInstance {
        id
      }
    }
    saverReferralReceiveDetails {
      id
      status
      referrerShortName
      campaign {
        id
        audReceiverRewardAmount
        audReceiverMinimumInvestmentAmount
      }
      rewardDestinationProductInstance {
        id
        upcomingSchedule {
          id
        }
      }
    }
  }
`;

export const saverTmdDetailsScalars = gql`
  fragment saverTmdDetailsFields on SaverTMDDetails {
    createdAt
    result
    submissionAvailable
    resubmissionConfirmationRequired
    reviewedAt
    preApproved
  }
`;

export const superTmdDetailsScalars = gql`
  fragment superTmdDetailsFields on SuperTMDDetails {
    result
    submissionAvailable
  }
`;

export function useForeignTaxResidences(
  options?: QueryHookOptions<ForeignTaxResidences>,
): QueryResult<ForeignTaxResidences> {
  return useQuery<ForeignTaxResidences>(
    gql`
      query ForeignTaxResidences {
        contact {
          id
          ...contactScalars
          foreignTaxResidences {
            id
            ...foreignTaxResidenceScalars
            country {
              id
              ...countryScalars
            }
            missingTINDetails {
              ...missingTINDetailsScalars
            }
          }
        }
      }
      ${foreignTaxResidenceScalars}
      ${missingTINDetailsScalars}
      ${countryScalars}
      ${contactScalars}
    `,
    options,
  );
}

export function useSaverPortfolioFlags(
  options?: QueryHookOptions<SaverPortfolioFlags>,
): QueryResult<SaverPortfolioFlags> {
  return useQuery<SaverPortfolioFlags>(
    gql`
      query SaverPortfolioFlags {
        saverPortfolioFlags {
          createProductInstanceAvailable
          createProductInstanceNotAvailableMessage
          createPaymentScheduleAvailable
          createPaymentScheduleNotAvailableMessage
          createRedemptionAvailable
          createRedemptionNotAvailableMessage
          createBoostAvailable
          createBoostNotAvailableMessage
        }
      }
    `,
    options,
  );
}

const SUPER_PORFOLIO_FLAGS_QUERY = gql`
  query SuperPortfolioFlags {
    superPortfolioFlags {
      createProductInstanceAvailable
      createProductInstanceNotAvailableMessage
      sftOneSuperActive
      sftLimitedServicePeriodDisclaimer
      sftLimitedServicePeriodDisclaimerLink
      sftLimitedServicePeriodEnabled
      sftPostMigrationEnabled
      sftPostMigrationDisclaimer
      sftPostMigrationFHSSDisclaimer
      sftPostMigrationTransactionHistoryDisclaimer
    }
  }
`;

export function useSuperPortfolioFlags(
  options?: QueryHookOptions<SuperPortfolioFlags>,
): QueryResult<SuperPortfolioFlags> {
  return useQuery<SuperPortfolioFlags>(SUPER_PORFOLIO_FLAGS_QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });
}

export const warmSuperPortfolioFlags = async (
  client: ReturnType<typeof useApolloClient>,
): Promise<void> => {
  const resp = await client.query<SuperPortfolioFlags>({
    query: SUPER_PORFOLIO_FLAGS_QUERY,
    fetchPolicy: 'network-only',
  });

  client.writeQuery<SuperPortfolioFlags>({
    query: SUPER_PORFOLIO_FLAGS_QUERY,
    data: resp.data,
  });
};

export function useSaverTMDDetails(
  options?: QueryHookOptions<SaverTMDDetails>,
): QueryResult<SaverTMDDetails> {
  return useQuery<SaverTMDDetails>(
    gql`
      query SaverTMDDetails {
        contact {
          id
          phoneNumberVerificationRequired
          account {
            id
            indexSaverTMDDetails: saverTMDDetails(portfolio: INDEX) {
              ...saverTmdDetailsFields
            }
            universeSaverTMDDetails: saverTMDDetails(portfolio: UNIVERSE) {
              ...saverTmdDetailsFields
            }
            earthSaverTMDDetails: saverTMDDetails(portfolio: EARTH) {
              ...saverTmdDetailsFields
            }
            galaxySaverTMDDetails: saverTMDDetails(portfolio: GALAXY) {
              ...saverTmdDetailsFields
            }
            explorerSaverTMDDetails: saverTMDDetails(portfolio: EXPLORER) {
              ...saverTmdDetailsFields
            }
          }
        }
      }
      ${saverTmdDetailsScalars}
    `,
    options,
  );
}

export function useSuperTMDDetails(
  options?: QueryHookOptions<SuperTMDDetails>,
): QueryResult<SuperTMDDetails> {
  return useQuery<SuperTMDDetails>(
    gql`
      query SuperTMDDetails {
        contact {
          id
          account {
            id
            globalIndexSuperTMDDetails: superTMDDetails(
              portfolio: GLOBAL_INDEX
            ) {
              ...superTmdDetailsFields
            }
            growthXSuperTMDDetails: superTMDDetails(portfolio: GROWTHX) {
              ...superTmdDetailsFields
            }
          }
        }
      }
      ${superTmdDetailsScalars}
    `,
    options,
  );
}

/**
 * Query for retrieving the biometric verification status.
 * Exported separately so it can be called synchronously.
 */
export const GET_BIOMETRIC_VERIFICATION_STATUS_QUERY = gql`
  query GetBiometricVerificationStatus {
    contact {
      id
      biometricVerificationStatus
    }
  }
`;

/**
 * Hook for retrieving the biometric verification status.
 *
 * This status is updated via webhooks asynchronously, so a
 * fetchPolicy of 'network-only' is necessary to retrieve the most
 * up to date status without being stale.
 */
export function useBiometricVerificationStatus(
  options: QueryHookOptions<GetBiometricVerificationStatus> & {
    fetchPolicy: 'network-only';
  },
): QueryResult<GetBiometricVerificationStatus> {
  return useQuery<GetBiometricVerificationStatus>(
    GET_BIOMETRIC_VERIFICATION_STATUS_QUERY,
    options,
  );
}

export function useCreateBiometricVerificationSMS(
  options?: MutationHookOptions<
    InitiateBiometricVerification,
    InitiateBiometricVerificationVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  InitiateBiometricVerification,
  InitiateBiometricVerificationVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation InitiateBiometricVerification(
        $biometricInput: InitiateBiometricVerificationInput!
      ) {
        initiateBiometricVerification(input: $biometricInput) {
          contact {
            id
            biometricVerificationStatus
          }
        }
      }
    `,
    options,
  );
}

export function useUpdateBoostRecipe(
  options?: MutationHookOptions<
    UpdateBoostRecipe,
    UpdateBoostRecipeVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdateBoostRecipe,
  UpdateBoostRecipeVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      fragment updateRecipeFragment on BoostRecipe {
        id
        status
        source {
          ... on BasiqConnection {
            id
            status
            institution {
              id
              name
            }
            connectedAt
          }
        }
        parameters {
          id
          name
          value
          type
        }
      }

      mutation UpdateBoostRecipe($input: UpdateBoostRecipeInput!) {
        updateBoostRecipe(input: $input) {
          recipe {
            id
            ...updateRecipeFragment
          }
          account {
            id
            saverBoostRecipes {
              id
              ...updateRecipeFragment
            }
          }
        }
      }
    `,
    options,
  );
}

export function useActiveBankAccount(
  options?: QueryHookOptions<ActiveBankAccount>,
): QueryResult<ActiveBankAccount> {
  return useQuery<ActiveBankAccount>(
    gql`
      query ActiveBankAccount {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              ...bankAccountScalars
            }
          }
        }
      }
      ${bankAccountScalars}
    `,
    options,
  );
}

export function useCountries(
  options?: QueryHookOptions<Countries>,
): QueryResult<Countries> {
  return useQuery<Countries>(
    gql`
      query Countries($filters: CountryFilters) {
        countries(filters: $filters) {
          id
          ...countryScalars
        }
      }
      ${countryScalars}
    `,
    options,
  );
}

/**
 * These are all the fields for which we need the latest
 * values after performing any mutation on foreign tax
 * residences.
 */
const foreignTaxResidencesCommonPayload = gql`
  fragment foreignTaxResidencesCommonPayload on Contact {
    id
    ...contactScalars
    foreignTaxResidences {
      id
      ...foreignTaxResidenceScalars
      missingTINDetails {
        ...missingTINDetailsScalars
      }
      country {
        id
        ...countryScalars
      }
    }
  }
  ${contactScalars}
  ${countryScalars}
  ${foreignTaxResidenceScalars}
  ${missingTINDetailsScalars}
`;

/**
 * Perform all three operations serially, but all in
 * a single round trip.
 */
export function useSetForeignTaxResidences(): MutationTuple<
  SetForeignTaxResidences,
  SetForeignTaxResidencesVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation SetForeignTaxResidences(
      $add: AddForeignTaxResidencesInput!
      $update: UpdateForeignTaxResidencesInput!
      $delete: DeleteForeignTaxResidencesInput!
    ) {
      addForeignTaxResidences(input: $add) {
        contact {
          id
        }
      }
      updateForeignTaxResidences(input: $update) {
        contact {
          id
        }
      }
      deleteForeignTaxResidences(input: $delete) {
        contact {
          id
          ...foreignTaxResidencesCommonPayload
        }
      }
    }
    ${foreignTaxResidencesCommonPayload}
  `);
}

export function useDeleteForeignTaxResidences(): MutationTuple<
  DeleteForeignTaxResidences,
  DeleteForeignTaxResidencesVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation DeleteForeignTaxResidences(
      $input: DeleteForeignTaxResidencesInput!
    ) {
      deleteForeignTaxResidences(input: $input) {
        contact {
          id
          ...foreignTaxResidencesCommonPayload
        }
      }
    }
    ${foreignTaxResidencesCommonPayload}
  `);
}

export function useCreateSaverProductInstances(
  options?: MutationHookOptions<
    CreateSaverProductInstances,
    CreateSaverProductInstancesVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateSaverProductInstances,
  CreateSaverProductInstancesVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateSaverProductInstances(
        $input: CreateSaverProductInstancesInput!
      ) {
        createSaverProductInstances(input: $input) {
          account {
            id
            ...accountScalars
            saverProductInstances {
              id
              ...saverProductInstanceScalars
            }
            saverProductInstancesConnection {
              edges {
                ...saverProductInstanceConnectionEdgeScalars
              }
            }
          }
        }
      }
      ${accountScalars}
      ${saverProductInstanceScalars}
      ${saverProductInstanceConnectionEdgeScalars}
    `,
    options,
  );
}
export function useCreatePaymentSchedule(
  options?: MutationHookOptions<
    CreatePaymentSchedule,
    CreatePaymentScheduleVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreatePaymentSchedule,
  CreatePaymentScheduleVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreatePaymentSchedule($input: CreatePaymentScheduleInput!) {
        createPaymentSchedule(input: $input) {
          saverProductInstance {
            id
            ...saverProductInstanceScalars
            upcomingSchedule {
              id
              ...scheduleScalars
            }
          }
          account {
            id
            saverProductInstancesConnection {
              edges {
                ...saverProductInstanceConnectionEdgeScalars
              }
            }
          }
        }
      }
      ${saverProductInstanceScalars}
      ${scheduleScalars}
      ${saverProductInstanceConnectionEdgeScalars}
    `,
    options,
  );
}

export function useSetPrimarySaverProductInstance(
  options?: MutationHookOptions<
    SetPrimarySaverProductInstance,
    SetPrimarySaverProductInstanceVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SetPrimarySaverProductInstance,
  SetPrimarySaverProductInstanceVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SetPrimarySaverProductInstance(
        $input: SetPrimarySaverProductInstanceInput!
      ) {
        setPrimarySaverProductInstance(input: $input) {
          account {
            id
            saverProductInstances {
              id
              primary
            }
          }
        }
      }
    `,
    options,
  );
}

export function toProtoPortfolio(
  portfolio?: GQLSaverPortfolio,
): SaverPortfolio.Enum {
  switch (portfolio) {
    case GQLSaverPortfolio.EARTH:
      return SaverPortfolio.Enum.EARTH;
    case GQLSaverPortfolio.INDEX:
      return SaverPortfolio.Enum.INDEX;
    case GQLSaverPortfolio.UNIVERSE:
      return SaverPortfolio.Enum.UNIVERSE;
    case GQLSaverPortfolio.GALAXY:
      return SaverPortfolio.Enum.GALAXY;
    case GQLSaverPortfolio.EXPLORER:
      return SaverPortfolio.Enum.EXPLORER;
    default:
      return SaverPortfolio.Enum.UNKNOWN;
  }
}

export function fromProtoPortfolio(
  portfolio?: SaverPortfolio.Enum,
): GQLSaverPortfolio | undefined {
  switch (portfolio) {
    case SaverPortfolio.Enum.EARTH:
      return GQLSaverPortfolio.EARTH;
    case SaverPortfolio.Enum.INDEX:
      return GQLSaverPortfolio.INDEX;
    case SaverPortfolio.Enum.UNIVERSE:
      return GQLSaverPortfolio.UNIVERSE;
    case SaverPortfolio.Enum.GALAXY:
      return GQLSaverPortfolio.GALAXY;
    case SaverPortfolio.Enum.EXPLORER:
      return GQLSaverPortfolio.EXPLORER;
    default:
      return;
  }
}

export function toProtoTfnExemptionCode(
  tfnExemptionCode?: GQLTFNExemptionCode,
): TFNExemptionCode.Enum {
  switch (tfnExemptionCode) {
    case GQLTFNExemptionCode.BUSINESS_INVESTOR:
      return TFNExemptionCode.Enum.BUSINESS_INVESTOR;
    case GQLTFNExemptionCode.CENTRELINK:
      return TFNExemptionCode.Enum.CENTRELINK;
    case GQLTFNExemptionCode.ENTITY_NOT_REQUIRED:
      return TFNExemptionCode.Enum.NON_RESIDENT;
    case GQLTFNExemptionCode.NON_RESIDENT:
      return TFNExemptionCode.Enum.NON_RESIDENT;
    case GQLTFNExemptionCode.NOT_AN_ABN:
      return TFNExemptionCode.Enum.NOT_AN_ABN;
    case GQLTFNExemptionCode.PENSIONER:
      return TFNExemptionCode.Enum.PENSIONER;
    case GQLTFNExemptionCode.UNDER_16:
      return TFNExemptionCode.Enum.UNDER_16;
    default:
      return TFNExemptionCode.Enum.UNKNOWN;
  }
}

export function toProtoFrequency(
  frequency?: ScheduleFrequency,
): SchedulerFrequency.Enum {
  switch (frequency) {
    case ScheduleFrequency.ONE_TIME:
      return SchedulerFrequency.Enum.ONE_TIME;
    case ScheduleFrequency.WEEKLY:
      return SchedulerFrequency.Enum.WEEKLY;
    case ScheduleFrequency.MONTHLY:
      return SchedulerFrequency.Enum.MONTHLY;
    case ScheduleFrequency.FORTNIGHTLY:
      return SchedulerFrequency.Enum.FORTNIGHTLY;
    default:
      return SchedulerFrequency.Enum.UNKNOWN;
  }
}

export function fromProtoFrequency(
  frequency?: SchedulerFrequency.Enum,
): ScheduleFrequency {
  switch (frequency) {
    case SchedulerFrequency.Enum.WEEKLY:
      return ScheduleFrequency.WEEKLY;
    case SchedulerFrequency.Enum.MONTHLY:
      return ScheduleFrequency.MONTHLY;
    case SchedulerFrequency.Enum.FORTNIGHTLY:
      return ScheduleFrequency.FORTNIGHTLY;
    case SchedulerFrequency.Enum.ONE_TIME:
    default:
      return ScheduleFrequency.ONE_TIME;
  }
}

export function formatAddress(address: addressScalarsInterface): string {
  if (address.streetAddress !== '') {
    return `${address.streetAddress}\n${address.suburb}, ${address.state}, ${address.postcode}`.trim();
  }

  return `${address.unitNumber ?? ''} ${address.streetNumber ?? ''} ${
    address.streetName ?? ''
  } ${address.streetType ?? ''}\n${address.suburb}, ${address.state}, ${
    address.postcode
  }`.trim();
}

export function useBankBranch(
  options: QueryHookOptions<BankBranch, BankBranchVariables>,
): QueryResult<BankBranch> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useQuery<BankBranch, BankBranchVariables>(
    gql`
      query BankBranch($id: ID!) {
        bankBranch(id: $id) {
          id
          type
          branchName
          payToMinAudAmount
          payToMaxAudAmount
        }
      }
    `,
    options,
  );
}

export function useSendEmployerContributionEmail(): MutationTuple<
  SendEmployerContributionEmail,
  SendEmployerContributionEmailVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation SendEmployerContributionEmail(
      $input: SendEmployerContributionEmailInput!
    ) {
      sendEmployerContributionEmail(input: $input) {
        employerEmailAddress
      }
    }
  `);
}

export function useVoyagerPortfolios(): QueryResult<SaverPortfolios> {
  return useQuery<SaverPortfolios>(gql`
    query SaverPortfolios {
      contact {
        id
        account {
          id
          saverProductInstances {
            id
            portfolio
            upcomingSchedule {
              id
              frequency
              audAmount
              nextDue
            }
            investments {
              id
              summary {
                id
                audBalance
                audMarketReturn
              }
            }
          }
        }
      }
    }
  `);
}

export function fromProtoGender(gender?: Gender.Enum): GQLGender {
  switch (gender) {
    case Gender.Enum.MALE:
      return GQLGender.MALE;

    case Gender.Enum.FEMALE:
      return GQLGender.FEMALE;

    case Gender.Enum.UNSPECIFIED:
    default:
      return GQLGender.UNSPECIFIED;
  }
}

export function fromProtoSuperJoinType(
  joinType?: SuperProductInstance.JoinType.Enum,
): SuperJoinType {
  switch (joinType) {
    case SuperProductInstance.JoinType.Enum.SIGN_UP_WEB:
      return SuperJoinType.SIGN_UP_WEB;

    case SuperProductInstance.JoinType.Enum.SIGN_UP:
    default:
      return SuperJoinType.SIGN_UP;
  }
}

export function useCreateSargonMember(
  options?: MutationHookOptions<
    CreateSargonMember,
    CreateSargonMemberVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateSargonMember,
  CreateSargonMemberVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateSargonMember($input: CreateSargonMemberInput!) {
        createSargonMember(input: $input) {
          account {
            id
          }
        }
      }
    `,
    options,
  );
}

export function useCreateRedemption(
  options?: MutationHookOptions<
    CreateRedemption,
    CreateRedemptionVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateRedemption,
  CreateRedemptionVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateRedemption($input: CreateRedemptionInput!) {
        createRedemption(input: $input) {
          saverProductInstance {
            id
            investments {
              id
              transactions {
                id
                ... on Redemption {
                  ...redemptionScalars
                }
              }
            }
          }
        }
      }
      ${redemptionScalars}
    `,
    options,
  );
}

export function useCancelPaymentScheduleForProductInstances(
  options?: MutationHookOptions<
    CancelPaymentSchedulesForProductInstances,
    CancelPaymentSchedulesForProductInstancesVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CancelPaymentSchedulesForProductInstances,
  CancelPaymentSchedulesForProductInstancesVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CancelPaymentSchedulesForProductInstances(
        $input: CancelPaymentSchedulesForProductInstancesInput!
      ) {
        cancelPaymentSchedulesForProductInstances(input: $input) {
          saverProductInstances {
            id
            ...saverProductInstanceScalars
            upcomingSchedule {
              id
              ...scheduleScalars
            }
          }
          account {
            id
            saverProductInstancesConnection {
              edges {
                ...saverProductInstanceConnectionEdgeScalars
              }
            }
          }
        }
      }
      ${saverProductInstanceScalars}
      ${scheduleScalars}
      ${saverProductInstanceConnectionEdgeScalars}
    `,
    options,
  );
}

export function useDeleteTfnExemption(
  options?: MutationHookOptions<
    DeleteTfnExemption,
    DeleteTfnExemptionVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  DeleteTfnExemption,
  DeleteTfnExemptionVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation DeleteTfnExemption($input: DeleteTfnExemptionInput!) {
        deleteTfnExemption(input: $input) {
          saverProductInstance {
            id
            tfnExemptionCode
          }
        }
      }
    `,
    options,
  );
}

export function useUpdateTfnExemption(
  options?: MutationHookOptions<
    UpdateTfnExemption,
    UpdateTfnExemptionVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdateTfnExemption,
  UpdateTfnExemptionVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdateTfnExemption($input: UpdateTfnExemptionInput!) {
        updateTfnExemption(input: $input) {
          saverProductInstance {
            id
            tfnExemptionCode
          }
        }
      }
    `,
    options,
  );
}

export function useDeleteTfn(
  options?: MutationHookOptions<
    DeleteTfn,
    DeleteTfnVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<DeleteTfn, DeleteTfnVariables, unknown, ApolloCache<unknown>> {
  return useMutation(
    gql`
      mutation DeleteTfn($input: DeleteTfnInput!) {
        deleteTfn(input: $input) {
          saverProductInstance {
            id
            tfnProvided
          }
        }
      }
    `,
    options,
  );
}

export function useUpdateTfn(
  options?: MutationHookOptions<
    UpdateTfn,
    UpdateTfnVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<UpdateTfn, UpdateTfnVariables, unknown, ApolloCache<unknown>> {
  return useMutation(
    gql`
      mutation UpdateTfn($input: UpdateTfnInput!) {
        updateTfn(input: $input) {
          saverProductInstance {
            id
            tfnProvided
          }
        }
      }
    `,
    options,
  );
}

export function useEstimatedApplicationExecutionDate(
  options: QueryHookOptions<
    EstimatedApplicationExecutionDate,
    EstimatedApplicationExecutionDateVariables
  >,
): QueryResult<EstimatedApplicationExecutionDate> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useQuery<
    EstimatedApplicationExecutionDate,
    EstimatedApplicationExecutionDateVariables
  >(
    gql`
      query EstimatedApplicationExecutionDate(
        $productId: ID!
        $audAmount: String!
        $applicationTime: String!
      ) {
        contact {
          id
          account {
            id
            saverProductInstance(id: $productId) {
              id
              estimatedApplicationExecutionDate(
                audAmount: $audAmount
                applicationTime: $applicationTime
              )
            }
          }
        }
      }
    `,
    options,
  );
}

export function useCanUpdateBankAccount(
  options: QueryHookOptions<CanUpdateBankAccount>,
): QueryResult<CanUpdateBankAccount> {
  return useQuery<CanUpdateBankAccount>(
    gql`
      query CanUpdateBankAccount {
        contact {
          id
          account {
            id
            canUpdateBankAccount
          }
        }
      }
    `,
    options,
  );
}

export function useUpdateContact(
  options?: MutationHookOptions<
    UpdateContact,
    UpdateContactVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdateContact,
  UpdateContactVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdateContact($input: UpdateContactInput!) {
        updateContact(input: $input) {
          contact {
            id
            ...contactScalars
            address {
              ...addressScalars
            }
          }
        }
      }
      ${contactScalars}
      ${addressScalars}
    `,
    options,
  );
}

export function useLinkWithSargon(
  options?: MutationHookOptions<
    LinkWithSargon,
    LinkWithSargonVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  LinkWithSargon,
  LinkWithSargonVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation LinkWithSargon($input: LinkWithSargonInput!) {
        linkWithSargon(input: $input) {
          contactMatches {
            firstName
            lastName
            dateOfBirth
            emailAddress
            phoneNumber
            address
          }
          superProductInstance {
            id
            createdAt
          }
        }
      }
    `,
    options,
  );
}

export function useCreateBasiqConnection(
  options?: MutationHookOptions<
    CreateBasiqConnection,
    CreateBasiqConnectionVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateBasiqConnection,
  CreateBasiqConnectionVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateBasiqConnection($input: CreateBasiqConnectionInput!) {
        createBasiqConnection(input: $input) {
          connection {
            id
            ...basiqConnectionScalars
          }
          account {
            id
            basiqConnections {
              id
              ...basiqConnectionScalars
            }
          }
        }
      }
      ${basiqConnectionScalars}
    `,
    options,
  );
}

export function useCreateSaverInvestmentGoal(
  options?: MutationHookOptions<
    CreateSaverInvestmentGoal,
    CreateSaverInvestmentGoalVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateSaverInvestmentGoal,
  CreateSaverInvestmentGoalVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateSaverInvestmentGoal(
        $input: CreateSaverInvestmentGoalInput!
      ) {
        createSaverInvestmentGoal(input: $input) {
          account {
            id
            saverInvestmentGoals {
              id
              name
              startingAudAmount
              targetAudAmount
              emoji
              createdAt
            }
          }
        }
      }
    `,
    options,
  );
}

export function useUpdateSaverInvestmentGoal(
  options?: MutationHookOptions<
    UpdateSaverInvestmentGoal,
    UpdateSaverInvestmentGoalVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdateSaverInvestmentGoal,
  UpdateSaverInvestmentGoalVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdateSaverInvestmentGoal(
        $input: UpdateSaverInvestmentGoalInput!
      ) {
        updateSaverInvestmentGoal(input: $input) {
          saverInvestmentGoal {
            id
            name
            startingAudAmount
            targetAudAmount
            emoji
          }
        }
      }
    `,
    options,
  );
}

export function useDeleteSaverInvestmentGoal(
  options?: MutationHookOptions<
    DeleteSaverInvestmentGoal,
    DeleteSaverInvestmentGoalVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  DeleteSaverInvestmentGoal,
  DeleteSaverInvestmentGoalVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation DeleteSaverInvestmentGoal(
        $input: DeleteSaverInvestmentGoalInput!
      ) {
        deleteSaverInvestmentGoal(input: $input) {
          account {
            id
            saverInvestmentGoals {
              id
              name
              emoji
              startingAudAmount
              targetAudAmount
              createdAt
              completedAt
            }
          }
        }
      }
    `,
    options,
  );
}

export function useCreateBoostRecipe(
  options?: MutationHookOptions<
    CreateBoostRecipe,
    CreateBoostRecipeVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateBoostRecipe,
  CreateBoostRecipeVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateBoostRecipe($input: CreateBoostRecipeInput!) {
        createBoostRecipe(input: $input) {
          account {
            id
            saverBoostRecipes {
              id
              name
              iconName
              status
              source {
                ... on BasiqConnection {
                  id
                  institution {
                    id
                    name
                  }
                  status
                  connectedAt
                }
              }
              parameters {
                id
                name
                value
                type
              }
              description
            }
          }
        }
      }
    `,
    options,
  );
}

export function useCancelBoostItemGroup(
  options?: MutationHookOptions<
    CancelBoostItemGroup,
    CancelBoostItemGroupVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CancelBoostItemGroup,
  CancelBoostItemGroupVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CancelBoostItemGroup($input: CancelBoostItemGroupInput!) {
        cancelBoostItemGroup(input: $input) {
          group {
            id
            status
          }
        }
      }
    `,
    options,
  );
}

export function useTopUpAndCloseBoostItemGroup(
  options?: MutationHookOptions<
    TopUpAndCloseBoostItemGroup,
    TopUpAndCloseBoostItemGroupVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  TopUpAndCloseBoostItemGroup,
  TopUpAndCloseBoostItemGroupVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation TopUpAndCloseBoostItemGroup(
        $input: TopUpAndCloseBoostItemGroupInput!
      ) {
        topUpAndCloseBoostItemGroup(input: $input) {
          group {
            id
            status
            audAmount
            items {
              id
              audAmount
              description
              createdAt
            }
          }
        }
      }
    `,
    options,
  );
}

export function useRemoveBasiqConnection(
  options?: MutationHookOptions<
    RemoveBasiqConnection,
    RemoveBasiqConnectionVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  RemoveBasiqConnection,
  RemoveBasiqConnectionVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation RemoveBasiqConnection($input: RemoveBasiqConnectionInput!) {
        removeBasiqConnection(input: $input) {
          account {
            id
            basiqConnections {
              id
              status
            }
          }
          connection {
            id
            status
          }
        }
      }
    `,
    options,
  );
}

export function useReconnectBasiqConnection(
  options?: MutationHookOptions<
    ReconnectBasiqConnection,
    ReconnectBasiqConnectionVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  ReconnectBasiqConnection,
  ReconnectBasiqConnectionVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation ReconnectBasiqConnection(
        $input: ReconnectBasiqConnectionInput!
      ) {
        reconnectBasiqConnection(input: $input) {
          connection {
            id
            status
          }
        }
      }
    `,
    options,
  );
}

export function useSendPasswordResetCode(
  options?: MutationHookOptions<
    SendPasswordResetCode,
    SendPasswordResetCodeVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SendPasswordResetCode,
  SendPasswordResetCodeVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SendPasswordResetCode($input: SendPasswordResetCodeInput!) {
        sendPasswordResetCode(input: $input) {
          resetId
        }
      }
    `,
    options,
  );
}

export function useVerifyPasswordResetCode(
  options?: MutationHookOptions<
    VerifyPasswordResetCode,
    VerifyPasswordResetCodeVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  VerifyPasswordResetCode,
  VerifyPasswordResetCodeVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation VerifyPasswordResetCode($input: VerifyPasswordResetCodeInput!) {
        verifyPasswordResetCode(input: $input) {
          outcome
        }
      }
    `,
    options,
  );
}

export function useUpdatePassword(
  options?: MutationHookOptions<
    UpdatePassword,
    UpdatePasswordVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdatePassword,
  UpdatePasswordVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdatePassword($input: UpdatePasswordInput!) {
        updatePassword(input: $input) {
          resetId
        }
      }
    `,
    options,
  );
}

export function useApplyVoyagerReferralCode(
  options?: MutationHookOptions<
    ApplyVoyagerReferralCode,
    ApplyVoyagerReferralCodeVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  ApplyVoyagerReferralCode,
  ApplyVoyagerReferralCodeVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation ApplyVoyagerReferralCode($input: ApplySaverReferralCodeInput!) {
        applySaverReferralCode(input: $input) {
          result
          details {
            id
            status
            referrerShortName
            rewardDestinationProductInstance {
              id
            }
          }
        }
      }
    `,
    options,
  );
}

export function useCloseProductInstances(
  options?: MutationHookOptions<
    CloseSaverProductInstances,
    CloseSaverProductInstancesVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CloseSaverProductInstances,
  CloseSaverProductInstancesVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CloseSaverProductInstances(
        $input: CloseSaverProductInstancesInput!
      ) {
        closeSaverProductInstances(input: $input) {
          account {
            id
            saverProductInstances {
              id
            }
            saverProductInstancesConnection {
              edges {
                ...saverProductInstanceConnectionEdgeScalars
              }
            }
          }
        }
      }
      ${saverProductInstanceConnectionEdgeScalars}
    `,
    options,
  );
}

export function useAddProductIntent(
  options?: MutationHookOptions<
    AddProductIntent,
    AddProductIntentVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  AddProductIntent,
  AddProductIntentVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation AddProductIntent($input: AddProductIntentInput!) {
        addProductIntent(input: $input) {
          contact {
            id
          }
        }
      }
    `,
    options,
  );
}

export function useSendSaverAccountStatement(
  options?: MutationHookOptions<
    SendSaverAccountStatement,
    SendSaverAccountStatementVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SendSaverAccountStatement,
  SendSaverAccountStatementVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SendSaverAccountStatement(
        $input: SendSaverAccountStatementInput!
      ) {
        sendSaverAccountStatement(input: $input) {
          saverProductInstance {
            id
          }
        }
      }
    `,
    options,
  );
}

export function useDismiss(
  options?: MutationHookOptions<
    Dismiss,
    DismissVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<Dismiss, DismissVariables, unknown, ApolloCache<unknown>> {
  return useMutation(
    gql`
      mutation Dismiss($input: DismissInput!) {
        dismiss(input: $input) {
          dismissible {
            id
            hidden
          }
        }
      }
    `,
    options,
  );
}

export function useUpdateFHSSStatus(
  options?: MutationHookOptions<
    UpdateFHSSStatus,
    UpdateFHSSStatusVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdateFHSSStatus,
  UpdateFHSSStatusVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdateFHSSStatus($input: UpdateFHSSStatusInput!) {
        updateFHSSStatus(input: $input) {
          id
          fhssStatus
        }
      }
    `,
    options,
  );
}

export function useCancelApplication(
  options?: MutationHookOptions<
    CancelApplication,
    CancelApplicationVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CancelApplication,
  CancelApplicationVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CancelApplication($input: CancelApplicationInput!) {
        cancelApplication(input: $input) {
          application {
            id
            status
            cancelDeadline
          }
        }
      }
    `,
    options,
  );
}

export function useUpdatePasswordByCurrentPassword(
  options?: MutationHookOptions<
    UpdatePasswordByCurrentPassword,
    UpdatePasswordByCurrentPasswordVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdatePasswordByCurrentPassword,
  UpdatePasswordByCurrentPasswordVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdatePasswordByCurrentPassword(
        $input: UpdatePasswordByCurrentPasswordInput!
      ) {
        updatePasswordByCurrentPassword(input: $input) {
          contact {
            id
          }
        }
      }
    `,
    options,
  );
}

export function useAppStoreLinks(): QueryResult<AppStoreLinks> {
  return useQuery<AppStoreLinks>(gql`
    query AppStoreLinks {
      appleAppStoreDeeplink: deepLink(name: "appleAppStore") {
        id
        url
      }
      googlePlayStoreDeeplink: deepLink(name: "googlePlayStore") {
        id
        url
      }
    }
  `);
}

export function useUpdateNovaAccountApplicationFields(
  options?: MutationHookOptions<
    UpdateNovaAccountApplicationFields,
    UpdateNovaAccountApplicationFieldsVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  UpdateNovaAccountApplicationFields,
  UpdateNovaAccountApplicationFieldsVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation UpdateNovaAccountApplicationFields(
        $input: NovaAccountApplicationFieldsInput!
      ) {
        updateNovaAccountApplicationFields(input: $input) {
          account {
            id
            novaAccountApplication {
              id
              status
              statusMessage
            }
            novaProductInstance {
              id
            }
          }
        }
      }
    `,
    options,
  );
}

export function useCancelNovaInvestmentPlan(
  options?: MutationHookOptions<
    CancelNovaInvestmentPlan,
    CancelNovaInvestmentPlanVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CancelNovaInvestmentPlan,
  CancelNovaInvestmentPlanVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CancelNovaInvestmentPlan(
        $input: CancelNovaInvestmentPlanInput!
      ) {
        cancelInvestmentPlan(input: $input) {
          asset {
            id
            activityStatus
            investmentPlan {
              id
              frequency
              audAmount
              nextDue
              startDate
            }
          }
          novaProductInstance {
            id
            etfInvestmentPlans: investmentPlansConnection(assetGroup: ETF)
              @connection(key: "etf-investment-plans") {
              edges {
                node {
                  id
                  frequency
                  audAmount
                  nextDue
                  startDate
                  asset {
                    id
                    name
                    symbol
                    logo
                    assetGroup
                    investmentPlan {
                      id
                      frequency
                      audAmount
                      nextDue
                      startDate
                    }
                  }
                }
                cursor
              }
              pageInfo {
                total
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
            stocksInvestmentPlans: investmentPlansConnection(assetGroup: STOCK)
              @connection(key: "stocks-investment-plans") {
              edges {
                node {
                  id
                  frequency
                  audAmount
                  nextDue
                  startDate
                  asset {
                    id
                    name
                    symbol
                    logo
                    assetGroup
                    investmentPlan {
                      id
                      frequency
                      audAmount
                      nextDue
                      startDate
                    }
                  }
                }
                cursor
              }
              pageInfo {
                total
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
          }
        }
      }
    `,
    options,
  );
}

export function useSetNovaInvestmentPlan(
  options?: MutationHookOptions<
    SetNovaInvestmentPlan,
    SetNovaInvestmentPlanVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SetNovaInvestmentPlan,
  SetNovaInvestmentPlanVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SetNovaInvestmentPlan($input: SetNovaInvestmentPlanInput!) {
        setInvestmentPlan(input: $input) {
          asset {
            id
            activityStatus
            investmentPlan {
              id
              frequency
              audAmount
              nextDue
              startDate
            }
          }
          novaProductInstance {
            id
            etfInvestmentPlans: investmentPlansConnection(assetGroup: ETF)
              @connection(key: "etf-investment-plans") {
              edges {
                node {
                  id
                  frequency
                  audAmount
                  nextDue
                  startDate
                  asset {
                    id
                    name
                    symbol
                    logo
                    assetGroup
                    investmentPlan {
                      id
                      frequency
                      audAmount
                      nextDue
                      startDate
                    }
                  }
                }
                cursor
              }
              pageInfo {
                total
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
            stocksInvestmentPlans: investmentPlansConnection(assetGroup: STOCK)
              @connection(key: "stocks-investment-plans") {
              edges {
                node {
                  id
                  frequency
                  audAmount
                  nextDue
                  startDate
                  asset {
                    id
                    name
                    symbol
                    logo
                    assetGroup
                    investmentPlan {
                      id
                      frequency
                      audAmount
                      nextDue
                      startDate
                    }
                  }
                }
                cursor
              }
              pageInfo {
                total
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
          }
        }
      }
    `,
    options,
  );
}

export function usePreviewNovaBuyOrder(
  options?: MutationHookOptions<
    PreviewNovaBuyOrder,
    PreviewNovaBuyOrderVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  PreviewNovaBuyOrder,
  PreviewNovaBuyOrderVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation PreviewNovaBuyOrder($input: NovaBuyOrderPreviewInput!) {
        previewNovaBuyOrder(input: $input) {
          notAvailableMessage
          novaBuyOrderPreview {
            id
            __typename
            expiresAt
            audAmount
            audTradeFeeIncGST
            audEstimatedFxFee
            audInvestableAmount
            etaForInvestment
            bankAccountLast4Digits
            mfaRequired
            novaAsset {
              id
              name
              symbol
              marketStatus
              logo
            }
          }
        }
      }
    `,
    options,
  );
}

export function usePreviewNovaSellOrder(
  options?: MutationHookOptions<
    PreviewNovaSellOrder,
    PreviewNovaSellOrderVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  PreviewNovaSellOrder,
  PreviewNovaSellOrderVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation PreviewNovaSellOrder($input: NovaSellOrderPreviewInput!) {
        previewNovaSellOrder(input: $input) {
          notAvailableMessage
          novaSellOrderPreview {
            id
            unitsToSell
            audEstimatedUnitPrice
            audTradeFeeIncGST
            audEstimatedFxFee
            audEstimatedRegulatoryFee
            audEstimatedProceeds
            novaAsset {
              id
              symbol
              name
              logo
              marketStatus
            }
          }
        }
      }
    `,
    options,
  );
}

export function usePlaceNovaOrder(
  options?: MutationHookOptions<
    PlaceNovaOrder,
    PlaceNovaOrderVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  PlaceNovaOrder,
  PlaceNovaOrderVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation PlaceNovaOrder($input: NovaPlaceOrderInput!) {
        placeNovaOrder(input: $input) {
          novaProductInstance {
            id
            pendingOrders: transactions(
              statuses: [SUBMITTED, PROCESSING, ORDERED, IN_MARKET]
              types: [BUY, SELL]
            )
              @connection(
                key: "pending-orders-all"
                filter: ["statuses", "types"]
              ) {
              pageInfo {
                total
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  ...NovaTransaction_NovaBuyTransactionFragment
                  ...NovaTransaction_NovaSellTransactionFragment
                  ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                }
              }
            }
            transactionHistory: transactions(statuses: [COMPLETED, FAILED])
              @connection(
                key: "pending-orders-all"
                filter: ["statuses", "types"]
              ) {
              pageInfo {
                total
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  ...NovaTransaction_NovaBuyTransactionFragment
                  ...NovaTransaction_NovaSellTransactionFragment
                  ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                }
              }
            }
          }
          novaTransaction {
            id
            ... on NovaBuyTransaction {
              asset {
                id
                activityStatus
                latestInvestments: transactions(types: [BUY, SELL], first: 1)
                  @connection(key: "latest-investments", filter: ["types"]) {
                  pageInfo {
                    total
                    endCursor
                    hasNextPage
                  }
                  edges {
                    node {
                      id
                      ...NovaTransaction_NovaBuyTransactionFragment
                      ...NovaTransaction_NovaSellTransactionFragment
                      ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                    }
                  }
                }
              }
              ...NovaTransaction_NovaBuyTransactionFragment
            }
            ... on NovaSellTransaction {
              asset {
                id
                activityStatus
                latestInvestments: transactions(types: [BUY, SELL], first: 1)
                  @connection(key: "latest-investments", filter: ["types"]) {
                  pageInfo {
                    total
                    endCursor
                    hasNextPage
                  }
                  edges {
                    node {
                      id
                      ...NovaTransaction_NovaBuyTransactionFragment
                      ...NovaTransaction_NovaSellTransactionFragment
                      ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                    }
                  }
                }
              }
              ...NovaTransaction_NovaSellTransactionFragment
            }
          }
        }
      }
      ${NovaTransaction_NovaBuyTransactionFragment}
      ${NovaTransaction_NovaSellTransactionFragment}
      ${NovaTransaction_NovaRegulatoryFeeTransactionFragment}
    `,
    options,
  );
}

export function useCancelNovaOrder(
  options?: MutationHookOptions<
    CancelNovaOrder,
    CancelNovaOrderVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CancelNovaOrder,
  CancelNovaOrderVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CancelNovaOrder($input: NovaCancelOrderInput!) {
        cancelNovaOrder(input: $input) {
          novaProductInstance {
            id
            pendingOrders: transactions(
              statuses: [SUBMITTED, PROCESSING, ORDERED, IN_MARKET]
              types: [BUY, SELL]
            )
              @connection(
                key: "pending-orders-all"
                filter: ["statuses", "types"]
              ) {
              pageInfo {
                total
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  ...NovaTransaction_NovaBuyTransactionFragment
                  ...NovaTransaction_NovaSellTransactionFragment
                  ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                }
              }
            }
            transactionHistory: transactions(statuses: [COMPLETED, FAILED])
              @connection(
                key: "pending-orders-all"
                filter: ["statuses", "types"]
              ) {
              pageInfo {
                total
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  ...NovaTransaction_NovaBuyTransactionFragment
                  ...NovaTransaction_NovaSellTransactionFragment
                  ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                }
              }
            }
          }
          asset {
            id
            activityStatus
            latestInvestments: transactions(types: [BUY, SELL], first: 1)
              @connection(key: "latest-investments", filter: ["types"]) {
              pageInfo {
                total
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  ...NovaTransaction_NovaBuyTransactionFragment
                  ...NovaTransaction_NovaSellTransactionFragment
                  ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
                }
              }
            }
          }
        }
      }
      ${NovaTransaction_NovaBuyTransactionFragment}
      ${NovaTransaction_NovaSellTransactionFragment}
      ${NovaTransaction_NovaRegulatoryFeeTransactionFragment}
    `,
    options,
  );
}

export function useCreateNovaAccountApplication(
  options?: MutationHookOptions<
    CreateNovaAccountApplication,
    null,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateNovaAccountApplication,
  null,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateNovaAccountApplication {
        createNovaAccountApplication {
          account {
            id
            novaAccountApplication {
              id
              status
              statusMessage
            }
          }
        }
      }
    `,
    options,
  );
}

export function useInitiateMfaVerification(
  options?: MutationHookOptions<
    InitiateMfaVerification,
    InitiateMfaVerificationVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  InitiateMfaVerification,
  InitiateMfaVerificationVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation InitiateMfaVerification($input: InitiateMfaVerificationInput!) {
        initiateMfaVerification(input: $input) {
          id
          to
        }
      }
    `,
    options,
  );
}

export function useCheckMfaVerification(
  options?: MutationHookOptions<
    CheckMfaVerification,
    CheckMfaVerificationVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CheckMfaVerification,
  CheckMfaVerificationVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CheckMfaVerification($input: CheckMfaVerificationInput!) {
        checkMfaVerification(input: $input) {
          contact {
            id
            phoneNumberMasked
            ...phoneNumberVerificationScalars
            email
            emailVerified
            account {
              id
              novaAccountApplication {
                id
                status
                statusMessage
              }
            }
          }
          auth {
            authToken
            id
            refreshToken
            scopes
          }
        }
      }
      ${phoneNumberVerificationScalars}
    `,
    options,
  );
}

export function useCreateOrUpdateNovaTrustedContact(
  options?: MutationHookOptions<
    CreateOrUpdateNovaTrustedContact,
    CreateOrUpdateNovaTrustedContactVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  CreateOrUpdateNovaTrustedContact,
  CreateOrUpdateNovaTrustedContactVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation CreateOrUpdateNovaTrustedContact(
        $input: NovaTrustedContactInput!
      ) {
        createOrUpdateNovaTrustedContact(input: $input) {
          novaProductInstance {
            id
            trustedContact {
              id
              firstName
              lastName
              phoneNumber
              emailAddress
            }
          }
        }
      }
    `,
    options,
  );
}

export function useSetSaverTargetMarketDeterminationAnswers(
  options?: MutationHookOptions<
    SetSaverTargetMarketDeterminationAnswers,
    SetSaverTargetMarketDeterminationAnswersVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SetSaverTargetMarketDeterminationAnswers,
  SetSaverTargetMarketDeterminationAnswersVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SetSaverTargetMarketDeterminationAnswers(
        $input: SetSaverTargetMarketDeterminationAnswersInput!
        $includePreApproved: Boolean! = false
      ) {
        setSaverTargetMarketDeterminationAnswers(input: $input) {
          account {
            id
            indexSaverTMDDetails: saverTMDDetails(portfolio: INDEX) {
              ...saverTmdDetailsFields
            }
            universeSaverTMDDetails: saverTMDDetails(portfolio: UNIVERSE) {
              ...saverTmdDetailsFields
            }
            earthSaverTMDDetails: saverTMDDetails(portfolio: EARTH) {
              ...saverTmdDetailsFields
            }
            galaxySaverTMDDetails: saverTMDDetails(portfolio: GALAXY) {
              ...saverTmdDetailsFields
            }
            explorerSaverTMDDetails: saverTMDDetails(portfolio: EXPLORER) {
              ...saverTmdDetailsFields
            }
            preApprovedPortfolioInformations @include(if: $includePreApproved) {
              id
              ...voyagerPortfolioInformationFields
            }
          }
        }
      }
      ${saverTmdDetailsScalars}
      ${saverPortfolioInformationScalars}
    `,
    options,
  );
}

export function useSubmitPreApprovedTargetMarketDeterminationAnswers(
  options?: MutationHookOptions<
    SubmitPreApprovedTargetMarketDeterminationAnswers,
    SubmitPreApprovedTargetMarketDeterminationAnswersVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SubmitPreApprovedTargetMarketDeterminationAnswers,
  SubmitPreApprovedTargetMarketDeterminationAnswersVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SubmitPreApprovedTargetMarketDeterminationAnswers(
        $input: SubmitPreApprovedTargetMarketDeterminationInput!
      ) {
        submitPreApprovedTargetMarketDetermination(input: $input) {
          account {
            id
            indexSaverTMDDetails: saverTMDDetails(portfolio: INDEX) {
              ...saverTmdDetailsFields
            }
            universeSaverTMDDetails: saverTMDDetails(portfolio: UNIVERSE) {
              ...saverTmdDetailsFields
            }
            earthSaverTMDDetails: saverTMDDetails(portfolio: EARTH) {
              ...saverTmdDetailsFields
            }
            galaxySaverTMDDetails: saverTMDDetails(portfolio: GALAXY) {
              ...saverTmdDetailsFields
            }
            explorerSaverTMDDetails: saverTMDDetails(portfolio: EXPLORER) {
              ...saverTmdDetailsFields
            }
            preApprovedPortfolioInformations {
              id
              ...voyagerPortfolioInformationFields
            }
          }
        }
      }
      ${saverTmdDetailsScalars}
      ${saverPortfolioInformationScalars}
    `,
    options,
  );
}

export function useSetSuperTargetMarketDeterminationAnswers(
  options?: MutationHookOptions<
    SetSuperTargetMarketDeterminationAnswers,
    SetSuperTargetMarketDeterminationAnswersVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SetSuperTargetMarketDeterminationAnswers,
  SetSuperTargetMarketDeterminationAnswersVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SetSuperTargetMarketDeterminationAnswers(
        $input: SetSuperTargetMarketDeterminationAnswersInput!
      ) {
        setSuperTargetMarketDeterminationAnswers(input: $input) {
          account {
            id
            globalIndexSuperTMDDetails: superTMDDetails(
              portfolio: GLOBAL_INDEX
            ) {
              ...superTmdDetailsFields
            }
            growthXSuperTMDDetails: superTMDDetails(portfolio: GROWTHX) {
              ...superTmdDetailsFields
            }
          }
        }
      }
      ${superTmdDetailsScalars}
    `,
    options,
  );
}

export function useInitiateAccountFeePayment(
  options?: MutationHookOptions<
    InitiateAccountFeePayment,
    null,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  InitiateAccountFeePayment,
  null,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation InitiateAccountFeePayment {
        initiateAccountFeePayment {
          novaProductInstance {
            id
            feeSummary {
              id
              accountFeeOutstandingAudAmount
              accountFeePendingAudAmount
              accountFeeSuspension
            }
          }
        }
      }
    `,
    options,
  );
}

// copilot: mutation setTaxResidency
export function useSetTaxResidency(
  options?: MutationHookOptions<
    SetTaxResidency,
    SetTaxResidencyVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  SetTaxResidency,
  SetTaxResidencyVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation SetTaxResidency($input: SetTaxResidencyInput!) {
        setTaxResidency(input: $input) {
          contact {
            id
            taxGroup
            taxResidenceStatus
            address {
              ...addressScalars
            }
            account {
              id
              novaAccountApplication {
                id
                status
              }
            }
          }
        }
      }
      ${addressScalars}
    `,
    options,
  );
}

export function useValidateTIN(
  options?: MutationHookOptions<
    ValidateTIN,
    ValidateTINVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  ValidateTIN,
  ValidateTINVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation ValidateTIN($input: ValidateTINInput!) {
        validateTIN(input: $input) {
          valid
        }
      }
    `,
    options,
  );
}

export function useAddBankAccount(
  options?: MutationHookOptions<
    AddBankAccount,
    AddBankAccountVariables,
    unknown,
    ApolloCache<unknown>
  >,
): MutationTuple<
  AddBankAccount,
  AddBankAccountVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(
    gql`
      mutation AddBankAccount($input: AddBankAccountInput!) {
        addBankAccount(input: $input) {
          contact {
            id
            account {
              id
              activeBankAccount {
                id
                ...bankAccountScalars
              }
              novaAccountApplication {
                id
                status
              }
            }
          }
        }
      }
      ${bankAccountScalars}
    `,
    options,
  );
}

export function useUserProducts(
  options?: QueryHookOptions<UserProducts>,
): QueryResult<UserProducts> {
  return useQuery<UserProducts>(
    gql`
      query UserProducts {
        contact {
          id
          account {
            id
            saverProductInstances {
              id
            }
            superProductInstance {
              id
            }
            novaProductInstance {
              id
            }
          }
        }
      }
    `,
    options,
  );
}

export function useCreatePayToAgreement(): MutationTuple<
  CreatePayToAgreement,
  CreatePayToAgreementVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation CreatePayToAgreement($input: CreatePayToAgreementInput!) {
      createPayToAgreement(input: $input) {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              payToAgreement {
                id
                maxAudAmount
              }
            }
          }
        }
      }
    }
  `);
}

export function useUpdatePayToAgreement(): MutationTuple<
  UpdatePayToAgreement,
  UpdatePayToAgreementVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation UpdatePayToAgreement($input: UpdatePayToAgreementInput!) {
      updatePayToAgreement(input: $input) {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              payToAgreement {
                id
                maxAudAmount
              }
            }
          }
        }
      }
    }
  `);
}

export function useCreateBindingBeneficiaryIntent(): MutationTuple<
  CreateBindingBeneficiaryIntent,
  CreateBindingBeneficiaryIntentVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation CreateBindingBeneficiaryIntent(
      $input: CreateBindingBeneficiaryIntentInput!
    ) {
      createBindingBeneficiaryIntent(input: $input) {
        superProductInstance {
          id
        }
      }
    }
  `);
}

export function useGenerateNovaMultiMonthStatement(): MutationTuple<
  GenerateNovaMultiMonthStatement,
  GenerateNovaMultiMonthStatementVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation GenerateNovaMultiMonthStatement(
      $input: GenerateMultiMonthStatementInput!
    ) {
      generateNovaMultiMonthStatement(input: $input) {
        account {
          id
        }
      }
    }
  `);
}

export function useCreateMoneyDeposit(): MutationTuple<
  CreateMoneyDeposit,
  CreateMoneyDepositVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation CreateMoneyDeposit($input: CreateMoneyDepositInput!) {
      createMoneyDeposit(input: $input) {
        account {
          id
          moneyAvailableAudBalance
          moneyInstance {
            id
            totalAudBalance
            pendingAudInflows
            pendingAudOutflows
          }
        }
      }
    }
  `);
}

export function useCreateMoneyWithdrawal(): MutationTuple<
  CreateMoneyWithdrawal,
  CreateMoneyWithdrawalVariables,
  unknown,
  ApolloCache<unknown>
> {
  return useMutation(gql`
    mutation CreateMoneyWithdrawal($input: CreateMoneyWithdrawalInput!) {
      createMoneyWithdrawal(input: $input) {
        account {
          id
          moneyAvailableAudBalance
          moneyInstance {
            id
            totalAudBalance
            pendingAudInflows
            pendingAudOutflows
          }
        }
      }
    }
  `);
}
