import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Button, Columns, Heading, Stack } from '@spaceship-fspl/components';
import {
  useCreateBasiqConnection,
  useCreateBoostRecipe,
} from '@spaceship-fspl/graphql';
import {
  WebAppBoostsSandbox,
  WebAppBoostsSandbox_contact_account_saverProductInstances_boostRecipes,
  WebAppBoostsSandbox_contact_account_saverProductInstances_investments_transactions,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsSandbox';
import { backgroundColor, borderRadius, padding } from '@spaceship-fspl/styles';
import { ControllerInput } from 'components/controller-input';
import { PageContainer } from 'components/layouts/page';
import React, { useEffect } from 'react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

// Lazy, sorry
type BoostRecipe =
  WebAppBoostsSandbox_contact_account_saverProductInstances_boostRecipes;
type Transaction =
  WebAppBoostsSandbox_contact_account_saverProductInstances_investments_transactions;

const CreateConnectionForm: FC<
  React.PropsWithChildren<{
    defaultValues?: {
      institutionId?: string;
      username?: string;
      password?: string;
    };
  }>
> = (props) => {
  const [createConnection, { loading }] = useCreateBasiqConnection();
  const { control, handleSubmit, reset } = useForm<{
    institutionId: string;
    username: string;
    password: string;
  }>();

  const onSubmit = handleSubmit(async (values) => {
    createConnection({ variables: { input: values } });
  });

  useEffect(() => {
    reset(props.defaultValues);
  }, [props.defaultValues, reset]);

  return (
    <form onSubmit={onSubmit}>
      <Stack spaceY="sm">
        <ControllerInput
          name="institutionId"
          control={control}
          type="text"
          placeholder="Institution ID"
        />
        <ControllerInput
          name="username"
          control={control}
          type="text"
          placeholder="Username"
        />
        <ControllerInput
          name="password"
          control={control}
          type="text"
          placeholder="Password"
        />
        <Button variant="primary" size="sm" type="submit" isLoading={loading}>
          Submit
        </Button>
      </Stack>
    </form>
  );
};

const CreateRecipeForm: FC<
  React.PropsWithChildren<{
    defaultValues?: {
      templateId?: string;
      sourceId?: string;
      saverProductInstanceId?: string;
    };
  }>
> = (props) => {
  const [createBoostRecipe, { loading }] = useCreateBoostRecipe();
  const { control, handleSubmit, reset } = useForm<{
    templateId: string;
    sourceId: string;
    saverProductInstanceId: string;
  }>();

  useEffect(() => {
    reset(props.defaultValues);
  }, [props.defaultValues, reset]);

  const onSubmit = handleSubmit(async (values) => {
    createBoostRecipe({ variables: { input: values } });
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spaceY="sm">
        <ControllerInput
          control={control}
          name="templateId"
          type="text"
          placeholder="Template ID"
        />
        <ControllerInput
          name="sourceId"
          control={control}
          type="text"
          placeholder="Source ID"
        />
        <ControllerInput
          control={control}
          name="saverProductInstanceId"
          type="text"
          placeholder="Product ID"
        />
        <Button variant="primary" size="sm" type="submit" isLoading={loading}>
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export const BoostsDebug: FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const resp = useQuery<WebAppBoostsSandbox>(gql`
    query WebAppBoostsSandbox {
      basiqInstitutions {
        id
        name
      }
      boostRecipeTemplates {
        description
        iconName
        id
        name
        parameters {
          id
          name
          type
        }
        sourceType
      }
      contact {
        id
        saverProductInstance {
          id
        }
        account {
          id
          basiqConnections {
            connectedAt
            id
            institution {
              id
              name
            }
            status
          }
          saverProductInstances {
            id
            boostRecipes {
              description
              iconName
              id
              name
              parameters {
                id
                type
                name
                value
              }
              source {
                ... on BasiqConnection {
                  id
                  connectedAt
                  institution {
                    id
                    name
                  }
                  status
                }
              }
              status
            }
            investments {
              id
              transactions(types: [BOOST]) {
                audAmount
                id
                requestedAt
                status
                unitExchange {
                  createdAt
                  id
                  unitPrice {
                    effectiveDate
                    id
                    price
                  }
                  units
                }
                ... on Boost {
                  id
                  audAmount
                  bankAccount {
                    accountName
                    accountNumber
                    bsb
                    createdAt
                    friendlyName
                    id
                    lastUpdatedAt
                    verified
                  }
                  itemGroup {
                    audAmount
                    cancellableUntil
                    id
                    items {
                      audAmount
                      createdAt
                      description
                      id
                    }
                    status
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const institutions = resp.data?.basiqInstitutions;
  const recipeTemplates = resp.data?.boostRecipeTemplates;
  const connections = resp.data?.contact?.account?.basiqConnections;
  const recipes = resp.data?.contact?.account?.saverProductInstances?.reduce(
    (recipes: BoostRecipe[], spi) => {
      return recipes.concat(spi?.boostRecipes ?? []);
    },
    [],
  );
  const transactions =
    resp.data?.contact?.account?.saverProductInstances?.reduce(
      (recipes: Transaction[], spi) => {
        const boostTxs = spi.investments?.transactions ?? [];
        return recipes.concat(boostTxs);
      },
      [],
    );

  // Create connection default values
  const saverProductInstanceId = resp.data?.contact?.saverProductInstance?.id;
  const institutionId = institutions?.find((i) => i.id === 'AU00001')?.id;
  const username = 'gavinBelson';
  const password = 'hooli2016';

  // Create recipe default values
  const templateId = recipeTemplates?.find((t) => t.name === 'Round Up')?.id;
  const sourceId = connections?.[0]?.id;

  return (
    <PageContainer>
      <Stack spaceY="md">
        <Heading variant={3}>Boosts</Heading>

        <Columns spaceY="md" spaceX="md">
          <Columns.Column width={1 / 2}>
            <Heading variant={4}>Institutions</Heading>
            <Pre>{JSON.stringify(institutions, null, 2)}</Pre>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Heading variant={4}>Recipe Templates</Heading>
            <Pre>{JSON.stringify(recipeTemplates, null, 2)}</Pre>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Heading variant={4}>Connections</Heading>
            <Pre>{JSON.stringify(connections, null, 2)}</Pre>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Stack spaceY="md">
              <Heading variant={4}>Create Connection</Heading>
              <CreateConnectionForm
                defaultValues={{ institutionId, username, password }}
              />
            </Stack>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Heading variant={4}>Recipes</Heading>
            <Pre>{JSON.stringify(recipes, null, 2)}</Pre>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Stack spaceY="md">
              <Heading variant={4}>Create Recipe</Heading>
              <CreateRecipeForm
                defaultValues={{ saverProductInstanceId, templateId, sourceId }}
              />
            </Stack>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Heading variant={4}>Transaction History</Heading>
            <Pre>{JSON.stringify(transactions, null, 2)}</Pre>
          </Columns.Column>

          <Columns.Column width={1 / 2}>
            <Heading variant={4}>Errors</Heading>
            <Pre>{JSON.stringify(resp.error, null, 2)}</Pre>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const Pre = styled.pre`
  ${backgroundColor('neutral.050')}
  ${borderRadius('sm')}
  ${padding('md')}
  height: 400px;
  overflow-y: scroll;
`;
