import * as React from 'react';
export const StreamlineCashBriefcase = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M9.75 15.937c0 .932 1.007 1.688 2.25 1.688s2.25-.756 2.25-1.688c0-.932-1.007-1.687-2.25-1.687s-2.25-.756-2.25-1.688c0-.932 1.007-1.687 2.25-1.687s2.25.755 2.25 1.687M12 9.75v1.125M12 17.625v1.125" />
    <path vectorEffect="non-scaling-stroke" d="M1.5 6.75h21v15h-21v-15zM15.342 3.275a1.5 1.5 0 00-1.423-1.025h-3.838a1.5 1.5 0 00-1.423 1.025L7.5 6.75h9l-1.158-3.475z" />
  </svg>
);
