import { TFNExemptionCode } from '@spaceship-fspl/graphql';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';

import { getPortfolioName } from './helpers';

export enum WITHDRAWAL_LEARN_MORE {
  PROCESSING_TIME,
  STRIKE_TIME,
  TAX_CONSEQUENCES,
}

export enum ADD_INVESTMENT_LEARN_MORE {
  PROCESSING_TIME,
  DIRECT_DEBIT,
  ISSUE_TIME,
  UNITS,
  CANCEL,
}

export const TFN_EXEMPTION_CODE_MAP: Record<TFNExemptionCode, string> = {
  [TFNExemptionCode.PENSIONER]:
    'Pensioner receiving a Centrelink (age or disability support) or Service (veteran’s) pension',
  [TFNExemptionCode.CENTRELINK]:
    'Recipient of another eligible Centrelink pension or benefit, such as parenting payment or widow allowance',
  [TFNExemptionCode.UNDER_16]: 'Investor under 16',
  [TFNExemptionCode.ENTITY_NOT_REQUIRED]:
    'Entity not required to lodge an income tax return',
  [TFNExemptionCode.BUSINESS_INVESTOR]:
    'Investors in the business of providing consumer or business finance',
  [TFNExemptionCode.NON_RESIDENT]: 'Non-resident',
  [TFNExemptionCode.NOT_AN_ABN]:
    'Quoted TFN contains alphabetic characters or has more than nine characters (and is not an ABN)',
};

export const TFN_EXEMPTION_CODE_OPTIONS: Array<{
  value: TFNExemptionCode;
  label: string;
}> = Object.entries(TFN_EXEMPTION_CODE_MAP).map(([value, label]) => ({
  value: value as TFNExemptionCode,
  label,
}));

export interface VoyagerPortfolioPerformance {
  lastUpdated: Date;
  oneYearPerformance: number | null;
  sinceFundedDate: number;
}

interface VoyagerPortfolioNewFee2023March {
  accountFee: string;
  accountFeeBalanceCopy: string;
  accountFeeAllPorfoliosCopy: string;
  managementFee: string;
  managementFeeCopy: string;
  managementFeeAndCostsCopy: string;
}

interface VoyagerPortfolioRisk {
  profile: string;
  minSuggestedHolding: number;
}

export enum VoyagerGaugePercentage {
  TwentyFive = 25,
  Fifty = 50,
  SeventyFive = 75,
}

export enum VoyagerPieChartPercentage {
  TwentyFive = 25,
  Fifty = 50,
  SeventyFive = 75,
  Hundred = 100,
}

export enum VoyagerInvestmentStrategy {
  HighGrowth = 75,
  Balanced = 50,
  Conservative = 25,
}

export const VoyagerInvestmentStrategyLabel: {
  [K in VoyagerInvestmentStrategy]: string;
} = {
  [VoyagerInvestmentStrategy.HighGrowth]: 'High Growth',
  [VoyagerInvestmentStrategy.Balanced]: 'Balanced',
  [VoyagerInvestmentStrategy.Conservative]: 'Conservative',
};

export const VoyagerInvestmentStrategyDescription: {
  [K in VoyagerInvestmentStrategy]: string;
} = {
  [VoyagerInvestmentStrategy.HighGrowth]:
    'High growth portfolios generally have a strong focus on long term growth.',
  [VoyagerInvestmentStrategy.Balanced]:
    'Balanced portfolios generally offer a balance of stability and growth assets.',
  [VoyagerInvestmentStrategy.Conservative]:
    'Conservative portfolios generally focus on stable returns with modest growth.',
};

export interface VoyagerPortfolio {
  fundedDate: Date;
  portfolio: SaverPortfolio.Enum;
  name: string;
  title: string;
  tagline: string;
  description: string;
  pds: string;
  tmd: string;
  homepage: string;
  newFeesFallback: VoyagerPortfolioNewFee2023March;
  investmentPerformanceIn1Yr?: string;
  risk: VoyagerPortfolioRisk;
  aboutPortfolio: string;
  investmentStrategy: VoyagerInvestmentStrategy;
  investmentStrategyLabel: string;
  investmentStrategyDescription: string;
  portfolioMix: string;
  portfolioMixValue: number;
  portfolioMixOther?: string;
  portfolioMixOtherValue?: number;
  targetReturn: string;
  targetReturnGauge: VoyagerGaugePercentage;
  investmentRisk: string;
  investmentRiskSummary: string;
  investmentRiskGauge: VoyagerGaugePercentage;
  suggestedTimeframe: string;
  shareOfInvestableAssetsStocks: string;
  shareOfInvestableAssetsStocksPieChart: VoyagerPieChartPercentage;
}

export const VOYAGER_PORTFOLIO_ORIGIN: VoyagerPortfolio = {
  fundedDate: new Date(2018, 4, 15),
  portfolio: SaverPortfolio.Enum.INDEX,
  name: getPortfolioName(SaverPortfolio.Enum.INDEX),
  title: `Spaceship ${getPortfolioName(SaverPortfolio.Enum.INDEX)} Portfolio`,
  tagline: 'A portfolio of some of the world’s largest companies.',
  description:
    'Invest in a rules-based portfolio of 200 of the largest global and Australian companies by market cap, with blue chip and established stocks such as Microsoft, CBA, Alphabet, Wisetech, and Amazon.',
  aboutPortfolio:
    'The Spaceship Origin Portfolio aims to gain exposure to 200 of the world’s largest listed companies by market capitalisation.',
  pds: InternalRoutes.VOYAGER_ORIGIN_PDS,
  tmd: InternalRoutes.VOYAGER_ORIGIN_TMD,
  homepage: InternalRoutes.VOYAGER_ORIGIN,
  newFeesFallback: {
    accountFee: '$3 / month',
    accountFeeBalanceCopy:
      'if you have at least one portfolio with a balance of $100 or more',
    accountFeeAllPorfoliosCopy: 'only one monthly fee for all three portfolios',
    managementFee: '0.15% / year',
    managementFeeCopy: 'calculated on the net asset value of each portfolio',
    managementFeeAndCostsCopy:
      'All fees and costs are inclusive of GST and net of expected reduced input tax credits.',
  },
  investmentStrategy: VoyagerInvestmentStrategy.HighGrowth,
  investmentStrategyLabel:
    VoyagerInvestmentStrategyLabel[VoyagerInvestmentStrategy.HighGrowth],
  investmentStrategyDescription:
    VoyagerInvestmentStrategyDescription[VoyagerInvestmentStrategy.HighGrowth],
  portfolioMix: '85-100% Shares',
  portfolioMixValue: 85,
  targetReturn: 'Very high',
  targetReturnGauge: VoyagerGaugePercentage.SeventyFive,
  investmentRisk: 'Very high',
  investmentRiskSummary: `This portfolio is suitable for investors seeking high long-term growth, who have a very high tolerance for risk and the ability to withstand market fluctuations.`,
  investmentRiskGauge: VoyagerGaugePercentage.SeventyFive,
  suggestedTimeframe: '7 years',
  shareOfInvestableAssetsStocks: 'Up to 25%',
  shareOfInvestableAssetsStocksPieChart: VoyagerPieChartPercentage.TwentyFive,
  risk: {
    profile: 'Very high',
    minSuggestedHolding: 7,
  },
};

export const VOYAGER_PORTFOLIO_UNIVERSE: VoyagerPortfolio = {
  fundedDate: new Date(2018, 4, 15),
  portfolio: SaverPortfolio.Enum.UNIVERSE,
  name: getPortfolioName(SaverPortfolio.Enum.UNIVERSE),
  title: `Spaceship ${getPortfolioName(
    SaverPortfolio.Enum.UNIVERSE,
  )} Portfolio`,
  tagline:
    'A portfolio of world-changing companies we believe meet our “Where the World is Going” criteria.',
  description:
    'Invest in a portfolio of world-changing companies, strategically picked by our investment team, that meet our "Where the World is Going" criteria, including: Nvidia, Meta, Tesla, Apple, and Netflix.',
  aboutPortfolio:
    'The Spaceship Universe Portfolio invests in the world-changing companies we believe meet our “Where the World is Going” criteria.',
  pds: InternalRoutes.VOYAGER_UNIVERSE_PDS,
  tmd: InternalRoutes.VOYAGER_UNIVERSE_TMD,
  homepage: InternalRoutes.VOYAGER_UNIVERSE,
  newFeesFallback: {
    accountFee: '$3 / month',
    accountFeeBalanceCopy:
      'if you have at least one portfolio with a balance of $100 or more',
    accountFeeAllPorfoliosCopy: 'only one monthly fee for all three portfolios',
    managementFee: '0.50% / year',
    managementFeeCopy: 'calculated on the net asset value of each portfolio',
    managementFeeAndCostsCopy:
      'All fees and costs are inclusive of GST and net of expected reduced input tax credits.',
  },
  investmentStrategy: VoyagerInvestmentStrategy.HighGrowth,
  investmentStrategyLabel:
    VoyagerInvestmentStrategyLabel[VoyagerInvestmentStrategy.HighGrowth],
  investmentStrategyDescription:
    VoyagerInvestmentStrategyDescription[VoyagerInvestmentStrategy.HighGrowth],
  portfolioMix: '85-100% Shares',
  portfolioMixValue: 85,
  targetReturn: 'Very high',
  targetReturnGauge: VoyagerGaugePercentage.SeventyFive,
  investmentRisk: 'Very high',
  investmentRiskSummary: `This portfolio is suitable for investors seeking high long-term growth, who have a very high tolerance for risk and the ability to withstand market fluctuations.`,
  investmentRiskGauge: VoyagerGaugePercentage.SeventyFive,
  suggestedTimeframe: '7 years',
  shareOfInvestableAssetsStocks: 'Up to 25%',
  shareOfInvestableAssetsStocksPieChart: VoyagerPieChartPercentage.TwentyFive,
  risk: {
    profile: 'Very high',
    minSuggestedHolding: 7,
  },
};

export const VOYAGER_PORTFOLIO_EARTH: VoyagerPortfolio = {
  fundedDate: new Date(2020, 10, 12),
  portfolio: SaverPortfolio.Enum.EARTH,
  name: getPortfolioName(SaverPortfolio.Enum.EARTH),
  title: `Spaceship ${getPortfolioName(SaverPortfolio.Enum.EARTH)} Portfolio`,
  tagline:
    'A portfolio of companies that have a positive impact on people and the planet.',
  description:
    'Invest in a portfolio of companies that have or are expected to have a positive impact on people and the planet, including: Airbnb, Shopify, Atlassian, Bumble, and First Solar.',
  aboutPortfolio:
    'The Spaceship Earth Portfolio invests in companies that have a positive impact on people and the planet in areas such as poverty, inequality, climate change, the environment, and quality education by contributing towards the advancement of the UN Sustainable Development Goals agenda.',
  pds: InternalRoutes.VOYAGER_EARTH_PDS,
  tmd: InternalRoutes.VOYAGER_EARTH_TMD,
  homepage: InternalRoutes.VOYAGER_EARTH,
  newFeesFallback: {
    accountFee: '$3 / month',
    accountFeeBalanceCopy:
      'if you have at least one portfolio with a balance of $100 or more',
    accountFeeAllPorfoliosCopy: 'only one monthly fee for all three portfolios',
    managementFee: '0.50% / year',
    managementFeeCopy: 'calculated on the net asset value of each portfolio',
    managementFeeAndCostsCopy:
      'All fees and costs are inclusive of GST and net of expected reduced input tax credits.',
  },
  investmentStrategy: VoyagerInvestmentStrategy.HighGrowth,
  investmentStrategyLabel:
    VoyagerInvestmentStrategyLabel[VoyagerInvestmentStrategy.HighGrowth],
  investmentStrategyDescription:
    VoyagerInvestmentStrategyDescription[VoyagerInvestmentStrategy.HighGrowth],
  portfolioMix: '85-100% Shares',
  portfolioMixValue: 85,
  targetReturn: 'Very high',
  targetReturnGauge: VoyagerGaugePercentage.SeventyFive,
  investmentRisk: 'Very high',
  investmentRiskSummary: `This portfolio is suitable for investors seeking high long-term growth, who have a very high tolerance for risk and the ability to withstand market fluctuations.`,
  investmentRiskGauge: VoyagerGaugePercentage.SeventyFive,
  suggestedTimeframe: '7 years',
  shareOfInvestableAssetsStocks: 'Up to 25%',
  shareOfInvestableAssetsStocksPieChart: VoyagerPieChartPercentage.TwentyFive,
  risk: {
    profile: 'Very high',
    minSuggestedHolding: 7,
  },
};

export const VOYAGER_PORTFOLIO_GALAXY: VoyagerPortfolio = {
  fundedDate: new Date(2024, 4, 30),
  portfolio: SaverPortfolio.Enum.GALAXY,
  name: getPortfolioName(SaverPortfolio.Enum.GALAXY),
  title: `Spaceship ${getPortfolioName(SaverPortfolio.Enum.GALAXY)} Portfolio`,
  tagline:
    'Suitable for investors seeking some exposure to the sharemarket but prioritise preserving what they have invested.',
  description:
    'Invest in a portfolio focused on balancing lower-risk investments such as bonds and cash with growth investments that meet our "Where the World is Going" criteria.',
  aboutPortfolio: `The Spacehip Galaxy Portfolio focuses on balancing lower-risk investments such as bonds and cash with growth investments that meet our "Where the World is Going" criteria.\n\nThe allocation to equities invests in WWG companies we believe are best placed to ride the long-term trends that are transforming the way we work and live. We take a long-term approach to investing rather than focusing on short-term metrics or price movements.`,
  pds: InternalRoutes.VOYAGER_MASTER_FUND_PDS,
  tmd: InternalRoutes.VOYAGER_MASTER_FUND_TMD,
  homepage: InternalRoutes.VOYAGER_GALAXY,
  newFeesFallback: {
    accountFee: '$3 / month',
    accountFeeBalanceCopy:
      'if you have at least one portfolio with a balance of $100 or more',
    accountFeeAllPorfoliosCopy: 'only one monthly fee for all five portfolios',
    managementFee: '0.35% / year',
    managementFeeCopy: 'calculated on the net asset value of each portfolio',
    managementFeeAndCostsCopy:
      'All fees and costs are inclusive of GST and net of expected reduced input tax credits. Third-party fees may apply. Find out more by reading the PDS.',
  },
  investmentPerformanceIn1Yr: `Investment performance data for the Spaceship Galaxy Portfolio will be available after one full year of history. The Spaceship Galaxy Portfolio was funded on 30 April 2024.`,
  investmentStrategy: VoyagerInvestmentStrategy.Balanced,
  investmentStrategyLabel:
    VoyagerInvestmentStrategyLabel[VoyagerInvestmentStrategy.Balanced],
  investmentStrategyDescription:
    VoyagerInvestmentStrategyDescription[VoyagerInvestmentStrategy.Balanced],
  portfolioMix: '40% Shares',
  portfolioMixValue: 40,
  portfolioMixOther: '60% Bonds & Cash',
  portfolioMixOtherValue: 60,
  targetReturn: 'Medium',
  targetReturnGauge: VoyagerGaugePercentage.Fifty,
  investmentRisk: 'Medium',
  investmentRiskSummary: `This portfolio is suitable for investors who are seeking a balance between stability and growth with a moderate tolerance for risk.`,
  investmentRiskGauge: VoyagerGaugePercentage.Fifty,
  suggestedTimeframe: '5 years',
  shareOfInvestableAssetsStocks: 'Up to 75%',
  shareOfInvestableAssetsStocksPieChart: VoyagerPieChartPercentage.SeventyFive,
  risk: {
    profile: 'Medium',
    minSuggestedHolding: 5,
  },
};

export const VOYAGER_PORTFOLIO_EXPLORER: VoyagerPortfolio = {
  fundedDate: new Date(2024, 4, 30),
  portfolio: SaverPortfolio.Enum.EXPLORER,
  name: getPortfolioName(SaverPortfolio.Enum.EXPLORER),
  title: `Spaceship ${getPortfolioName(SaverPortfolio.Enum.EXPLORER)} Portfolio`,
  tagline:
    'Suitable for investors seeking some exposure to the sharemarket but prioritise preserving what they have invested.',
  description:
    'Invest in a portfolio focused on lower-risk investments such as bonds and cash, with some strategic exposure to global and Australian companies for growth.',
  aboutPortfolio: `The Spaceship Explorer Portfolio is focused on lower-risk investments such as bonds and cash, with some strategic exposure to global and Australian companies for growth.\n\nIt provides investors with the opportunity to invest in more stable investments such as corporate or government bonds, while having some exposure to the global sharemarket.`,
  pds: InternalRoutes.VOYAGER_MASTER_FUND_PDS,
  tmd: InternalRoutes.VOYAGER_MASTER_FUND_TMD,
  homepage: InternalRoutes.VOYAGER_EXPLORER,
  newFeesFallback: {
    accountFee: '$3 / month',
    accountFeeBalanceCopy:
      'if you have at least one portfolio with a balance of $100 or more',
    accountFeeAllPorfoliosCopy: 'only one monthly fee for all five portfolios',
    managementFee: '0.25% / year',
    managementFeeCopy: 'calculated on the net asset value of each portfolio',
    managementFeeAndCostsCopy:
      'All fees and costs are inclusive of GST and net of expected reduced input tax credits. Third-party fees may apply. Find out more by reading the PDS.',
  },
  investmentPerformanceIn1Yr: `Investment performance data for the Spaceship Explorer Portfolio will be available after one full year of history. The Spaceship Explorer Portfolio was funded on 30 April 2024.`,
  portfolioMix: '20% Shares',
  portfolioMixValue: 20,
  portfolioMixOther: '80% Bonds & Cash',
  portfolioMixOtherValue: 80,
  investmentStrategy: VoyagerInvestmentStrategy.Conservative,
  investmentStrategyLabel:
    VoyagerInvestmentStrategyLabel[VoyagerInvestmentStrategy.Conservative],
  investmentStrategyDescription:
    VoyagerInvestmentStrategyDescription[
      VoyagerInvestmentStrategy.Conservative
    ],
  targetReturn: 'Conservative',
  targetReturnGauge: VoyagerGaugePercentage.TwentyFive,
  investmentRisk: 'Low/medium',
  investmentRiskSummary: `This portfolio is suitable for investors who prioritise stability over growth, with a low-to-medium tolerance for risk.`,
  investmentRiskGauge: VoyagerGaugePercentage.TwentyFive,
  suggestedTimeframe: '3 years',
  shareOfInvestableAssetsStocks: 'Up to 75%',
  shareOfInvestableAssetsStocksPieChart: VoyagerPieChartPercentage.SeventyFive,
  risk: {
    profile: 'Low/medium',
    minSuggestedHolding: 3,
  },
};

export enum InvestmentType {
  'ONE_OFF' = 'one-off',
  'PLAN' = 'plan',
}

export const BAV_EXCLUDED_INSTITUTION_SLUGS = [
  // https://spaceship-hq.atlassian.net/browse/SAR-4504
  'upbank',
];

export const BAV_BLACKLIST_SAVINGS_ACCOUNTS_INSTITUTION_SLUGS = ['ing', 'cba'];
