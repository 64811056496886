import { ApolloClient, gql, useMutation, useQuery } from '@apollo/client';
import type {
  ActivateTreatment,
  ActivateTreatmentVariables,
} from '@spaceship-fspl/graphql/src/__generated__/ActivateTreatment';
import type {
  Treatments,
  Treatments_treatments,
  TreatmentsVariables,
} from '@spaceship-fspl/graphql/src/__generated__/Treatments';
import { useMemo } from 'react';

interface Treatment {
  name: string;
  params: Record<string, string>;
  activate(): Promise<void>;
}

const TREATMENTS_QUERY = gql`
  query Treatments($ids: [ID!]!) {
    treatments(ids: $ids) {
      id
      name
      activationToken
      params {
        id
        value
      }
    }
  }
`;

/**
 * Performs a bulk treatment lookup and primes the
 * cache with treatments for the provided experimentNames.
 *
 * @async
 * @param {ApolloClient} client ApolloClient instance
 * @param {Array<string>} experimentNames experiment names to load
 * @returns {Promise<void>}
 */
export async function loadTreatments(
  client: ApolloClient<unknown>,
  experimentNames: Array<string>,
): Promise<void> {
  const requestedSet = new Set(experimentNames);
  const receivedMap = new Map<string, Treatments_treatments>();

  const resp = await client.query<Treatments, TreatmentsVariables>({
    query: TREATMENTS_QUERY,
    variables: { ids: Array.from(requestedSet.values()) },
    fetchPolicy: 'network-only',
  });

  resp.data?.treatments?.forEach((c) => {
    receivedMap.set(c.id, c);
  });

  requestedSet.forEach((k) => {
    const treatments: Array<Treatments_treatments> = [];
    const received = receivedMap.get(k);
    if (received) {
      treatments.push(received);
    }

    client.writeQuery<Treatments, TreatmentsVariables>({
      query: TREATMENTS_QUERY,
      variables: { ids: [k] },
      data: { treatments },
    });
  });
}

/**
 * Given an experiment name, this hook will return the
 * appropriate `Treatment` for this client.
 *
 * Relies on `loadTreatments` being called _before_ this hook is used.
 *
 * @param {string} experimentName unique name of the experiment
 * @returns {Treatment} treatment for this client, falls back to default treatment if none found
 */
export function useTreatment(experimentName: string): Treatment {
  const resp = useQuery<Treatments, TreatmentsVariables>(TREATMENTS_QUERY, {
    variables: { ids: [experimentName] },
    fetchPolicy: 'cache-only',
  });

  const [activateMutation] = useMutation<
    ActivateTreatment,
    ActivateTreatmentVariables
  >(gql`
    mutation ActivateTreatment($activationToken: String!) {
      activateTreatment(input: { activationToken: $activationToken }) {
        activationToken
      }
    }
  `);

  return useMemo(() => {
    const treatment = resp.data?.treatments?.[0];
    if (!treatment) {
      return {
        name: 'control',
        params: {},
        activate: () => Promise.resolve(),
      };
    }

    const params: Record<string, string> = {};
    for (const p of treatment.params) {
      params[p.id] = p.value;
    }

    const activate = async (): Promise<void> => {
      await activateMutation({
        variables: {
          activationToken: treatment.activationToken,
        },
      });
    };

    return {
      name: treatment.name,
      params,
      activate,
    };
  }, [activateMutation, resp.data]);
}
