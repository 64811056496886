import { RouteComponentProps } from '@reach/router';
import { Box, Spinner } from '@spaceship-fspl/components';
import {
  useGetUserVerification,
  useInitiateUserVerification,
} from '@spaceship-fspl/data';
import {
  isFraudStatusInProgress,
  isFraudStatusUnknown,
  isFraudStatusVerified,
  parseSourcesLeftToVerify,
} from '@spaceship-fspl/green-id';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  FraudService,
  GreenIdRuleSet,
} from '@spaceship-fspl/types/externalapi';
import { Error } from 'components/layouts/error';
import { CenterOnboardingContainer } from 'components/layouts/onboarding';
import { useGreenID } from 'contexts/greenid';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { addRumError } from 'helpers/monitoring';
import React, { useEffect } from 'react';

import { GreenIDList } from './list';

export const GreenID: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props,
) => {
  const { ruleset, onPending, onVerified } = useGreenID();
  const initiateVerificationResp = useInitiateUserVerification(ruleset);
  const getVerificationResp = useGetUserVerification(ruleset);
  const overallStatus = getVerificationResp.data?.service_checks?.find(
    ({ service }) => service === FraudService.Enum.GREENID,
  )?.status;
  const track = useTrack();

  useEffect(() => {
    (async () => {
      if (initiateVerificationResp.isLoading || getVerificationResp.isLoading) {
        return;
      }

      const isUnknownStatus = isFraudStatusUnknown(overallStatus);
      const isVerifiedStatus = isFraudStatusVerified(overallStatus);
      const isPendingStatus = isFraudStatusInProgress(overallStatus);
      const hasNoVerification = overallStatus === undefined || isUnknownStatus;
      const sourcesLeftToVerify = parseSourcesLeftToVerify(
        getVerificationResp.data?.green_id_source_details,
      );

      switch (true) {
        case hasNoVerification: {
          // If the user:
          // - has no verification status
          // - has not called `initiateUserVerification` yet
          // Call `initiateUserVerification` first
          if (
            initiateVerificationResp.status === 'idle' &&
            !getVerificationResp.isError
          ) {
            try {
              await initiateVerificationResp.mutateAsync({ rule_set: ruleset });
            } catch (error) {
              addRumError({ error });
              return;
            }
          }

          // Then call `getVerification` again to get latest status.
          // Note: `verifyUser` may be called a second time (skipping the above
          // `initiateUserVerification`), if the status is still undefined/unknown,
          // which means the init verification wasn't complete on illion's side,
          // so try get verification again.
          if (initiateVerificationResp.isSuccess) {
            try {
              await getVerificationResp.refetch({ throwOnError: true });
            } catch (error) {
              addRumError({ error });
            }
          }

          return;
        }

        case isVerifiedStatus:
          onVerified();
          break;

        case isPendingStatus && !sourcesLeftToVerify:
          onPending();
          break;
      }

      if (
        ruleset === GreenIdRuleSet.Enum.VOYAGER_ONBOARDING &&
        isPendingStatus &&
        sourcesLeftToVerify > 0
      ) {
        track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_GREEN_ID_START);
      }
    })();
  }, [
    getVerificationResp,
    initiateVerificationResp,
    overallStatus,
    onPending,
    onVerified,
    ruleset,
    track,
  ]);

  if (initiateVerificationResp.isError) {
    return (
      <Error
        isOnboarding={true}
        title="Oops, we were unable to verify your identity."
        subtitle="Please try again or contact our customer support team and we’ll get you back on track."
        buttonText="Try again"
        onContinue={{
          onClick: () => {
            initiateVerificationResp.reset();
          },
          trackingProperties: { name: 'greenid_verification_error_retry' },
        }}
      />
    );
  }

  if (getVerificationResp.isError) {
    return (
      <Error
        isOnboarding={true}
        title="Oops, we were unable to verify your identity."
        subtitle="Please try again or contact our customer support team and we’ll get you back on track."
        buttonText="Try again"
        onContinue={{
          onClick: async () => {
            try {
              await getVerificationResp.refetch();
            } catch (error) {
              addRumError({ error });
            }
          },
          trackingProperties: { name: 'greenid_verification_error_retry' },
        }}
      />
    );
  }

  if (
    initiateVerificationResp.isLoading ||
    getVerificationResp.isLoading ||
    overallStatus === undefined ||
    isFraudStatusUnknown(overallStatus)
  ) {
    return (
      <CenterOnboardingContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          data-testid="greenid-loading"
        >
          <Spinner size="lg" />
        </Box>
      </CenterOnboardingContainer>
    );
  }

  // Pending + sources left to verify
  return <GreenIDList {...props} />;
};
