import * as React from 'react';
export const StreamlineMakeUpEye = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M20.795 13.71a.504.504 0 010 .575 10.697 10.697 0 01-9.046 4.28 10.7 10.7 0 01-9.047-4.28.502.502 0 010-.575 10.698 10.698 0 019.047-4.28 10.7 10.7 0 019.046 4.28z" />
    <path vectorEffect="non-scaling-stroke" d="M7.18 13.998a4.569 4.569 0 109.138 0 4.569 4.569 0 00-9.138 0v0zM11.749 13.63a.367.367 0 01.367.368M11.382 13.998a.367.367 0 01.367-.367M11.749 14.365a.367.367 0 01-.367-.367M12.116 13.998a.367.367 0 01-.367.367M20.611 13.459a3.414 3.414 0 002.38-3.508M17.386 10.758a3.426 3.426 0 00.894-3.994M12.492 9.45a3.621 3.621 0 00-.416-4.25M8.26 9.895a3.543 3.543 0 00-2.658-3.279M4.23 12.041A3.354 3.354 0 001 9.803" />
  </svg>
);
