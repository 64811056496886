import * as React from 'react';
export const StreamlineNetworkUser = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M9.786 22.778a11 11 0 1113.12-12.234M9.35 22.68C7.862 20.495 6.867 16.53 6.867 12c0-4.532.995-8.496 2.483-10.68M1.024 11.267h11.71M3.199 5.4h17.602M2.27 17.134h8.264M14.652 1.32a15.823 15.823 0 012.15 6.278" />
    <path vectorEffect="non-scaling-stroke" d="M15.3 13.1a2.567 2.567 0 105.135 0 2.567 2.567 0 00-5.134 0zM17.868 16.4a5.134 5.134 0 00-5.134 5.133V23h10.267v-1.467a5.13 5.13 0 00-5.133-5.134z" />
  </svg>
);
