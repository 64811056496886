import {
  Box,
  Card,
  Columns,
  Divider,
  Spinner,
  Text,
} from '@spaceship-fspl/components';
import { useGetUser } from '@spaceship-fspl/data';
import { formatCurrency } from '@spaceship-fspl/helpers';
import {
  getTransactionDescription,
  useGetTransactions,
} from '@spaceship-fspl/super';
import { DATE_FORMAT_TRANSACTIONS } from 'components/transactions';
import { format } from 'date-fns';
import Decimal from 'decimal.js';
import React, { useMemo } from 'react';

export const TransactionsCard: React.FC<
  React.PropsWithChildren<{ show: number }>
> = (props) => {
  const accountId = useGetUser()?.data?.contact?.account_id;
  const resp = useGetTransactions({
    params: { limit: props.show },
    accountId,
  });

  const sortedTransactions = useMemo(
    () =>
      resp.data?.pages
        .flatMap(({ data }) => data)
        .sort((a, b) => {
          if (!a?.paidDate || !b?.paidDate) {
            return 0;
          }
          return a.paidDate < b.paidDate ? 1 : -1;
        }) ?? [],
    [resp.data],
  );

  return (
    <Card
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingX="md"
      paddingY="sm"
      minHeight={310}
      height="100%"
      aria-label="super recent transactions card"
    >
      {resp.isLoading ? (
        <Spinner />
      ) : sortedTransactions && sortedTransactions.length > 0 ? (
        <Box width="100%">
          {sortedTransactions.map((transaction, index) => {
            if (!transaction) {
              return null;
            }

            const amount =
              transaction.amountIn?.amount || transaction.amountOut?.amount;

            return (
              <React.Fragment key={transaction.id}>
                {index !== 0 && <Divider />}
                <Box paddingY="sm">
                  <Columns alignY="center" spaceX="xxxs">
                    <Columns.Column width={1 / 4}>
                      <Text variant={4} color="neutral.080" isBold={true}>
                        {transaction.paidDate
                          ? format(
                              new Date(transaction.paidDate),
                              DATE_FORMAT_TRANSACTIONS,
                            )
                          : ''}
                      </Text>
                    </Columns.Column>
                    <Columns.Column width={2 / 4}>
                      <Text variant={3}>
                        {getTransactionDescription(
                          transaction.transactionType,
                          transaction.coa,
                        )}
                      </Text>
                    </Columns.Column>
                    <Columns.Column width={1 / 4}>
                      <Text variant={3} align="right">
                        {amount
                          ? formatCurrency(
                              new Decimal(amount).dividedBy(100).toNumber(),
                            )
                          : '-'}
                      </Text>
                    </Columns.Column>
                  </Columns>
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      ) : (
        <Text variant={2} align="center" color="neutral.080" isBold={true}>
          No transactions to display at this time.
        </Text>
      )}
    </Card>
  );
};
