import Fingerprint2 from 'fingerprintjs2';
import { useEffect, useState } from 'react';

export const useDeviceFingerprint = (): string | undefined => {
  const queryParams = new URLSearchParams(window.location.search);
  const fingerprintOverride = queryParams.get('device_fingerprint_override');
  const [fingerprint, setFingerprint] = useState<string>();

  useEffect(() => {
    let isSubscribed = true;

    const getFingerprint = async (): Promise<void> => {
      if (!fingerprintOverride) {
        const components = await Fingerprint2.getPromise();
        const hashedFingerprint = Fingerprint2.x64hash128(
          components.map((component) => component.value).join(''),
          31,
        );
        if (isSubscribed) {
          setFingerprint(hashedFingerprint);
        }
      }
    };

    getFingerprint().catch(console.error);

    return () => {
      isSubscribed = false;
    };
  }, [fingerprintOverride]);

  return fingerprintOverride || fingerprint;
};
