import * as React from 'react';
export const VoyagerUniverse = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12 2.1c-5.468 0-9.9 4.432-9.9 9.9 0 5.467 4.432 9.9 9.9 9.9 5.467 0 9.9-4.433 9.9-9.9 0-5.468-4.433-9.9-9.9-9.9zM.9 12C.9 5.868 5.868.9 12 .9 18.13.9 23.1 5.868 23.1 12c0 6.13-4.97 11.1-11.1 11.1S.9 18.13.9 12z" />
    <path d="M12 5.754a6.246 6.246 0 100 12.492 6.246 6.246 0 000-12.492zM4.554 12a7.446 7.446 0 1114.892 0 7.446 7.446 0 01-14.892 0z" />
    <path d="M15.828 11.998c0 .263-.838.427-.896.672-.058.254.625.764.516.991-.113.23-.94.014-1.098.213-.159.2.236.96.034 1.118-.201.159-.85-.394-1.083-.282-.233.112-.202.965-.452 1.023-.251.057-.591-.726-.853-.726s-.608.78-.853.726c-.245-.055-.225-.914-.453-1.023-.227-.11-.884.44-1.083.282-.199-.158.196-.919.035-1.118-.162-.199-.986.018-1.098-.213-.113-.23.573-.74.516-.991-.058-.25-.897-.41-.897-.671 0-.263.839-.427.897-.672.057-.245-.626-.763-.516-.991.11-.228.94-.014 1.098-.213.158-.2-.237-.96-.035-1.118.202-.159.85.394 1.084.282.233-.112.201-.965.452-1.023.25-.057.59.726.853.726.262 0 .608-.78.853-.726.253.058.224.914.452 1.023.228.11.885-.44 1.083-.282.2.158-.195.919-.034 1.118.161.199.985-.018 1.098.213.112.23-.574.74-.516.991.058.25.896.41.896.671zm-3.783 2.113a2.175 2.175 0 100-4.35 2.175 2.175 0 000 4.35zm6.319-6.693a1.383 1.383 0 11-2.767-.001 1.383 1.383 0 012.767 0zm2.684 12.074a2.014 2.014 0 11-4.028 0 2.014 2.014 0 014.028 0z" />
  </svg>
);
