import {
  Box,
  Card,
  Heading,
  Inline,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { SaverPortfolio } from '@spaceship-fspl/graphql';
import { investmentSummaryScalars } from '@spaceship-fspl/graphql/src/__generated__/investmentSummaryScalars';
import { scheduleScalars } from '@spaceship-fspl/graphql/src/__generated__/scheduleScalars';
import {
  sliceGraphDataByTimeScale,
  TimeScale,
  toDateGraphData,
} from '@spaceship-fspl/helpers';
import placeholderSVG from 'assets/chart-empty-state.svg';
import { Chart } from 'components/chart';
import {
  ChartTabSelector,
  chartTimeScaleTabOptions,
} from 'components/chart-tab-selector';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import { AccessibilityLabel } from 'helpers/accessibility';
import { formatCurrency, toLongDate } from 'helpers/format';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React, { FC, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';

interface Props {
  productId: string;
  portfolio: SaverPortfolio;
  summary?: investmentSummaryScalars | null;
  upcomingSchedule?: scheduleScalars | null;
  dailyBalances?: Array<{
    date: string;
    audAmount: string;
  }> | null;
  chartHeight?: number;
}

export const BalanceCard: FC<React.PropsWithChildren<Props>> = ({
  productId,
  portfolio,
  summary,
  upcomingSchedule,
  dailyBalances,
  chartHeight = 100,
}) => {
  const [timeScale, setTimeScale] = useState(TimeScale.MAX);

  const userBalanceGraph = useMemo(
    () =>
      toDateGraphData(dailyBalances ?? [], ({ date, audAmount }) => ({
        date,
        y: audAmount,
      })),
    [dailyBalances],
  );

  const graphData = useMemo(
    () => sliceGraphDataByTimeScale(userBalanceGraph || [], timeScale),
    [userBalanceGraph, timeScale],
  );

  const config = voyagerPortfolios[portfolio];

  return (
    <Card
      aria-label={AccessibilityLabel.BALANCE_CARD}
      padding="lg"
      height="100%"
    >
      <Text variant={3} isBold={true}>
        Voyager Balance ({config.name})
      </Text>
      <Heading variant={2}>{formatCurrency(summary?.audBalance)}</Heading>
      <Box marginTop="sm" display="flex">
        <Inline spaceX="md">
          <LabeledField size="md" label="Performance">
            {formatCurrency(summary?.audMarketReturn)}
          </LabeledField>
          {upcomingSchedule ? (
            <LabeledField size="md" label="Investment plan">
              {formatCurrency(upcomingSchedule.audAmount)} /{' '}
              {upcomingSchedule.frequency.toLowerCase()}
            </LabeledField>
          ) : (
            <LabeledField size="md" label="Investment plan">
              <RouterLink
                to={`${Routes.VOYAGER_INVESTMENT_PLAN}/${productId}`}
                trackingProperties={{ name: 'voyager_balance_card_setup_plan' }}
              >
                <PresentationLink
                  color="neutral.100"
                  size="sm"
                  component="span"
                  icon="chevron"
                >
                  Set up plan
                </PresentationLink>
              </RouterLink>
            </LabeledField>
          )}
        </Inline>
      </Box>
      {graphData.length >= 5 ? (
        <>
          <Box position="relative" height={chartHeight} marginBottom="md">
            <Chart
              color="indigo.050"
              endColor="pink.100"
              tooltipEnabled={true}
              formatX={(value): string => toLongDate(new Date(value))}
              formatY={(value): string => formatCurrency(value)}
              endLabels={true}
              data={graphData}
            />
          </Box>
          <Box paddingTop="sm">
            <ChartTabSelector
              options={chartTimeScaleTabOptions}
              selected={timeScale}
              setSelected={(t: TimeScale): void => setTimeScale(t)}
            />
          </Box>
        </>
      ) : (
        <Box paddingTop="lg">
          <Stack spaceY="md">
            <SVG src={placeholderSVG} width="100%" />
            <Text variant={4} color="neutral.080" align="center">
              Graph data available once you start investing.
            </Text>
          </Stack>
        </Box>
      )}
    </Card>
  );
};
