import { RouteComponentProps } from '@reach/router';
import { useFetch, useUpdateBankAccount } from '@spaceship-fspl/data';
import { api } from '@spaceship-fspl/types/externalapi';
import { BAV_EXCLUDED_INSTITUTION_SLUGS } from '@spaceship-fspl/voyager';
import { useOnboardingRequestContext } from 'contexts/saver/onboarding';
import {
  Account,
  Institution,
  login as bsLogin,
  loginPreload as bsPreload,
  mfaLogin as bsMfaLogin,
  MFARequest,
} from 'helpers/bank-statements-api';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useNotifications } from './notifications';

interface GetInstitutionsResponse {
  institutions?: Institution[];
  user_token?: string;
}

interface BankAccountVerification {
  userToken?: string;
  allBanks: Institution[];
  accounts: Account[];
  selectedBank?: Institution;
  login: (credentials: {
    [fieldID: string]: string;
  }) => Promise<boolean> | void;
  mfaLogin: (credentials: {
    [fieldID: string]: string;
  }) => Promise<void> | void;
  setSelectedBank: Dispatch<SetStateAction<Institution | undefined>>;
  mfaRequest?: MFARequest;
}

const BankAccountVerificationContext = createContext<
  BankAccountVerification | undefined
>(undefined);

export const BankAccountVerificationProvider: React.FC<
  React.PropsWithChildren<{
    selectedBank?: Institution;
    selectedAccounts?: Array<Account>;
  }>
> = ({ children, selectedBank: defaultSelectedBank, selectedAccounts }) => {
  const unmounted = useRef(false);
  const fetch = useFetch();
  const [allBanks, setAllBanks] = useState<Institution[]>([]);
  const [allAccounts, setAllAccounts] = useState<Account[]>(
    selectedAccounts ?? [],
  );
  const [selectedBank, setSelectedBank] = useState<Institution | undefined>(
    defaultSelectedBank,
  );
  const [mfaRequest, setMfaRequest] = useState<MFARequest | undefined>();
  const [userToken, setUserToken] = useState<string | undefined>();
  const [guid, setGuid] = useState<string | undefined>();
  const slug = selectedBank && selectedBank.slug;
  const { popToast } = useNotifications();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const getAllBanks = useCallback(async (): Promise<void> => {
    try {
      const { institutions }: GetInstitutionsResponse = await fetch({
        url: '/bankstatements/institutions',
        method: 'GET',
      });
      if (institutions) {
        const allowedInstitutions = institutions.filter(
          ({ slug }) => !BAV_EXCLUDED_INSTITUTION_SLUGS.includes(slug ?? ''),
        );
        if (!unmounted.current) {
          setAllBanks(allowedInstitutions);
        }
      }
    } catch (error) {
      popToast({
        level: 'warning',
        message:
          'Something went wrong fetching banks, please refresh to try again or click "Can\'t find my bank" to continue.',
        cta: {
          message: 'Refresh',
          action: (): void => window.location.reload(),
        },
      });
      addRumError({ error });
    }
  }, [fetch, popToast]);

  const getBankAccountsSession = useCallback(async (): Promise<void> => {
    try {
      const { guid, user_token } =
        api.external.GetBankStatementsSessionResponseBody.fromObject(
          await fetch({
            url: '/user/bank-statements-session',
            method: 'GET',
          }),
        );
      if (!unmounted.current) {
        setGuid(guid);
        setUserToken(user_token);
      }
    } catch (error) {
      addRumError({ error });
    }
  }, [fetch]);

  const preload = useCallback(async (): Promise<void> => {
    try {
      if (!(userToken && guid && slug)) {
        throw Error('not enough info to preload');
      }
      const { institution, user_token } = await bsPreload(
        slug,
        userToken,
        guid,
      );
      if (!unmounted.current) {
        setSelectedBank({ ...institution, requires_preload: '0' });
        setUserToken(user_token);
      }
    } catch (error) {
      addRumError({ error });
    }
  }, [guid, slug, userToken]);

  // Returns boolean indicating whether MFA is required
  const login = useCallback(
    async (credentials: { [fieldID: string]: string }): Promise<boolean> => {
      if (!(userToken && guid && slug)) {
        throw Error('not enough info to login');
      }
      const { accounts, user_token, mfa } = await bsLogin(
        {
          credentials: {
            institution: slug,
            ...credentials,
          },
        },
        userToken,
        guid,
      );
      if (!unmounted.current) {
        setUserToken(user_token);
        if (accounts) {
          setAllAccounts(accounts);
        }
        if (mfa) {
          setMfaRequest(mfa);
        }
      }
      return !!mfa;
    },
    [guid, slug, userToken],
  );

  const mfaLogin = useCallback(
    async (credentials: { [fieldID: string]: string }): Promise<void> => {
      if (!(userToken && guid && slug)) {
        throw Error('not enough info to login');
      }
      const { accounts, user_token } = await bsMfaLogin(
        { ...credentials, user_token: userToken },
        userToken,
        guid,
      );
      if (!unmounted.current) {
        setUserToken(user_token);
        if (accounts) {
          setAllAccounts(accounts);
        }
      }
    },
    [guid, slug, userToken],
  );

  // Customers landing anywhere in this context
  // should have access to all banks and session info
  useEffect(() => {
    getAllBanks();
    getBankAccountsSession();
  }, [getAllBanks, getBankAccountsSession]);

  useEffect(() => {
    // Preload bank to get captcha
    if (selectedBank && selectedBank.requires_preload === '1') {
      preload();
    }
  }, [preload, selectedBank]);

  const value = useMemo(
    () => ({
      accounts: allAccounts.filter(
        (a) =>
          a.accountType === 'transaction' ||
          a.accountType === '' ||
          a.accountType === 'savings',
      ),
      login,
      userToken,
      allBanks,
      setSelectedBank,
      selectedBank,
      mfaRequest,
      mfaLogin,
    }),
    [
      allAccounts,
      allBanks,
      login,
      mfaLogin,
      mfaRequest,
      selectedBank,
      userToken,
    ],
  );

  return (
    <BankAccountVerificationContext.Provider value={value}>
      {children}
    </BankAccountVerificationContext.Provider>
  );
};

export const useBankAccountVerificationContext =
  (): BankAccountVerification => {
    const context = useContext(BankAccountVerificationContext);

    if (!context) {
      throw new Error(
        'useBankAccountVerificationContext must be wrapped by <BankAccountVerificationContext />',
      );
    }

    return context;
  };

type CreateBankAccountData = {
  source_account_name: string;
  source_bsb: string;
  source_account_friendly_name: string;
  source_account_number: string;
  bank_statements_user_token?: string;
};

interface BankAccountSubmission {
  variant: 'onboarding' | 'update';
  submitBankAccount: (data: CreateBankAccountData) => Promise<void>;
}

const BankAccountSubmissionContext = createContext<
  BankAccountSubmission | undefined
>(undefined);

export const useBankAccountSubmissionContext = (): BankAccountSubmission => {
  const context = useContext(BankAccountSubmissionContext);

  if (!context) {
    throw new Error(
      'useBankAccountSubmissionContext must be wrapped by <BankAccountSubmissionContext />',
    );
  }

  return context;
};

export const OnboardingBankAccountSubmissionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children, navigate, location }) => {
  if (!navigate) {
    throw new Error('No navigator provided');
  }

  if (!location) {
    throw new Error('No location provided');
  }

  const upPath = `${location.pathname.replace(/\/+$/, '')}/${Routes.UP}`;

  const [request, setRequest] = useOnboardingRequestContext();

  useEffect(() => {
    if (!request.createSaverAccount?.portfolio) {
      navigate?.(Routes.VOYAGER_ONBOARDING_PORTFOLIO);
    }
  }, [navigate, request.createSaverAccount]);

  const value: BankAccountSubmission = useMemo(
    () => ({
      variant: 'onboarding',
      submitBankAccount: async (
        formData: CreateBankAccountData,
      ): Promise<void> => {
        setRequest({
          ...request,
          createSaverAccount: {
            ...request.createSaverAccount,
            ...formData,
          },
        });

        await navigate(`${upPath}${Routes.BANK_ACCOUNT_SUCCESS}`);
      },
    }),
    [navigate, request, setRequest, upPath],
  );

  return (
    <BankAccountSubmissionContext.Provider value={value}>
      {children}
    </BankAccountSubmissionContext.Provider>
  );
};

export const UpdateBankAccountSubmissionProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children, navigate, location }) => {
  if (!navigate) {
    throw new Error('No navigator provided');
  }

  if (!location) {
    throw new Error('No location provided');
  }

  const upPath = `${location.pathname.replace(/\/+$/, '')}/${Routes.UP}`;

  const { mutateAsync: updateBankAccount } = useUpdateBankAccount();

  const value: BankAccountSubmission = useMemo(
    () => ({
      variant: 'update',
      submitBankAccount: async (
        formData: CreateBankAccountData,
      ): Promise<void> => {
        await updateBankAccount(formData);

        await navigate(`${upPath}${Routes.BANK_ACCOUNT_SUCCESS}`);
      },
    }),
    [navigate, updateBankAccount, upPath],
  );

  return (
    <BankAccountSubmissionContext.Provider value={value}>
      {children}
    </BankAccountSubmissionContext.Provider>
  );
};
