import { useLogout } from '@spaceship-fspl/auth';
import { Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { useUpdatePasswordByCurrentPassword } from '@spaceship-fspl/graphql';
import { NEW_PASSWORD_RULE } from '@spaceship-fspl/helpers';
import { ControllerPasswordInput } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { commonValidationRules } from 'helpers/validation';
import React from 'react';
import { useForm } from 'react-hook-form';

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePassword: React.FC<React.PropsWithChildren> = () => {
  const { control, handleSubmit, watch } = useForm<ChangePasswordForm>();

  const currentPasswordValue = watch('currentPassword');
  const newPasswordValue = watch('newPassword');
  const [updatePassword, updatePasswordMeta] =
    useUpdatePasswordByCurrentPassword();
  const { popToast } = useNotifications();
  const logout = useLogout();

  const onSubmit = async (values: ChangePasswordForm): Promise<void> => {
    try {
      await updatePassword({
        variables: {
          input: {
            currentPassword: values.currentPassword,
            newPassword: values.confirmNewPassword,
          },
        },
      });

      popToast({
        message: 'Your password has been successfully updated.',
        level: 'success',
      });
      logout();
    } catch (error) {
      let message = GENERIC_ERROR_MESSAGE;
      if (
        error instanceof Error &&
        error.message?.includes('incorrect current password')
      ) {
        message = 'Oops, your current password is incorrect. Please try again.';
      }
      popToast({
        message,
        level: 'error',
      });
    }
  };

  return (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
            <Stack spaceY="lg">
              <Heading variant={2} align="center">
                Change password
              </Heading>
              <Text variant={2} color="neutral.080" align="center">
                {NEW_PASSWORD_RULE}
              </Text>
              <Stack spaceY="md">
                <ControllerPasswordInput
                  name="currentPassword"
                  placeholder="Current password"
                  control={control}
                  rules={{
                    required: 'Current password is required',
                  }}
                />
                <ControllerPasswordInput
                  name="newPassword"
                  placeholder="New password"
                  control={control}
                  rules={{
                    required: 'New password is required',
                    pattern: commonValidationRules.password.pattern,
                    validate: (value: string) => {
                      if (value === currentPasswordValue) {
                        return 'Your new password should be different than your current password';
                      }
                      return true;
                    },
                  }}
                />
                <ControllerPasswordInput
                  name="confirmNewPassword"
                  placeholder="Confirm new password"
                  control={control}
                  rules={{
                    required: 'Confirm new password is required',
                    validate: (value: string) => {
                      if (value !== newPasswordValue) {
                        return 'Both new password and confirm new password must match';
                      }
                      return true;
                    },
                  }}
                />
              </Stack>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{ name: 'change_password_submit' }}
                type="submit"
                isLoading={updatePasswordMeta.loading}
              >
                Continue
              </PageFormContinueButton>
            </PageFormButtonContainer>
          </Columns.Column>
        </Columns>
      </form>
    </PageContainer>
  );
};
