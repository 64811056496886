import { RouteComponentProps } from '@reach/router';
import {
  Columns,
  Divider,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { SUPER_DOCUMENTS, useGetDocuments } from '@spaceship-fspl/super';
import { PageContainer } from 'components/layouts/page';
import { ResourceList } from 'components/resource-list';
import React from 'react';

export const SuperImportantDocuments: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { data: memberDocuments } = useGetDocuments();
  const superFlagsResp = useSuperPortfolioFlags();
  const sftOneSuperActive =
    superFlagsResp?.data?.superPortfolioFlags?.sftOneSuperActive;
  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
          <Stack spaceY="md">
            <Heading
              component="h1"
              variant={3}
              align={{ xs: 'left', md: 'center' }}
            >
              Important documents
            </Heading>

            <Stack spaceY="xl">
              <div>
                <Divider color="neutral.050" />
                <ResourceList
                  isSuperResource={true}
                  resources={SUPER_DOCUMENTS}
                />
                <Divider color="neutral.050" />
              </div>

              {memberDocuments && (
                <Stack spaceY="sm">
                  <Text
                    variant={4}
                    color="indigo.070"
                    align={{ xs: 'left', md: 'center' }}
                    isBold={true}
                  >
                    MEMBER DOCUMENTS
                  </Text>
                  <div>
                    <Divider color="neutral.050" />
                    <ResourceList
                      isSuperResource={true}
                      resources={memberDocuments.map((document) => ({
                        memberDocumentId: document.id,
                        label: 'Member document',
                      }))}
                    />
                    <Divider color="neutral.050" />
                  </div>
                </Stack>
              )}
            </Stack>

            {sftOneSuperActive ? (
              <Text variant={4} color="neutral.080">
                Spaceship Capital Limited is the promoter of Spaceship Super.
                Spaceship Super is issued by Diversa Trustees Limited (ABN 49
                006 421 638, AFSL 235153, RSEL L0000635) as trustee of OneSuper
                (ABN 43 905 581 638) (Fund). Spaceship Super is a sub-plan of
                the Fund.
              </Text>
            ) : (
              <Text variant={4} color="neutral.080">
                Spaceship Capital Limited (ABN 67 621 011 649, AFSL 501605) is
                the promoter of Spaceship Super. Spaceship Super is issued by
                Diversa Trustees Limited (ABN 49 006 421 638, AFSL 235153, RSEL
                L0000635) as trustee of the Tidswell Master Superannuation Plan
                (ABN 34 300 938 877).
              </Text>
            )}
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
