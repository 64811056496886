import { navigate, RouteComponentProps } from '@reach/router';
import { Columns, Stack } from '@spaceship-fspl/components';
import { PadlockAttentionIcon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import {
  PageContainer,
  PageFormButtonContainer,
  PageHeading,
} from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import React from 'react';

type TooManyAttemptsProps = RouteComponentProps & {
  done?: string;
};

export const MfaTooManyAttempts: React.FC<
  React.PropsWithChildren<TooManyAttemptsProps>
> = ({ done }) => {
  const { pop: showIntercom } = useIntercom();

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <Stack spaceY="xl" alignX="center">
            <PadlockAttentionIcon size={'xxxxl'} />

            <PageHeading
              title="Oops, you've had too many attempts. Please try again later."
              subtitle="If you need help, please contact our customer support team."
            />

            <PageFormButtonContainer>
              <Button
                variant="primary"
                size="lg"
                trackingProperties={{
                  name: 'mfa_too_many_attempts_contact_support_button',
                }}
                onClick={showIntercom}
              >
                Open chat
              </Button>

              {done ? (
                <Button
                  variant="tertiary"
                  size="lg"
                  trackingProperties={{
                    name: 'mfa_too_many_attempts_done',
                  }}
                  onClick={() => {
                    navigate(done);
                  }}
                >
                  Done
                </Button>
              ) : null}
            </PageFormButtonContainer>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
