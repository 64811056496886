import * as React from 'react';
export const TipLightbulb = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M18 13.714a6 6 0 10-8.674 5.349v2.948a.669.669 0 00.668.652h4.012a.668.668 0 00.668-.652v-3A6 6 0 0018 13.714zM12 1.389V3.96M18.857 3.84L17.04 5.674M22.766 9.154h-2.572M5.143 3.84L6.96 5.674M1.234 9.154h2.572" />
  </svg>
);
