import { Member } from '@sargon/api-client';
import { useRefresh } from '@spaceship-fspl/auth';
import { useLinkWithSargon } from '@spaceship-fspl/graphql';
import { LinkWithSargon_linkWithSargon_contactMatches } from '@spaceship-fspl/graphql/src/__generated__/LinkWithSargon';
import { ErrorWithCode } from '@spaceship-fspl/helpers';
import { useState } from 'react';

import {
  AmplifyAuthLoginErrorCodes,
  LinkAccountApiError,
  LinkAccountApiErrorCodes,
} from '../error';
import { useAmplifyAuth, useSargonClient } from '../react';

const useGetMemberRequest = (): ((
  accessToken?: string,
) => Promise<Member | undefined>) => {
  const client = useSargonClient();

  return async (accessToken?: string): Promise<Member | undefined> => {
    const { data } = await client.members({ accessToken }).getMember('me');
    return data;
  };
};

export type LinkAccountContactMatches = Omit<
  LinkWithSargon_linkWithSargon_contactMatches,
  '__typename'
>;

interface LinkAccountMemberMatches {
  matches: LinkAccountContactMatches;
  member: Member;
}

export interface UseLinkAccountResults {
  isLoading: boolean;
  linkSuperAccount: (
    email?: string,
    password?: string,
  ) => Promise<LinkAccountMemberMatches | undefined>;
}

export const useLinkAccount = (): UseLinkAccountResults => {
  const [isLoading, setIsLoading] = useState(false);
  const amplifyAuth = useAmplifyAuth();
  const [linkWithSargon, linkWithSargonMeta] = useLinkWithSargon();
  const refreshSpaceshipToken = useRefresh();
  const getMember = useGetMemberRequest();

  const linkSuperAccount = async (
    email?: string,
    password?: string,
  ): Promise<LinkAccountMemberMatches | undefined> => {
    let amplifyToken;
    let member;
    let matches = linkWithSargonMeta?.data?.linkWithSargon?.contactMatches;

    setIsLoading(true);

    // 1. Get token
    if (email && password) {
      try {
        amplifyToken = await amplifyAuth.login(email, password);
      } catch (error) {
        const castedError = error as ErrorWithCode<
          AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes
        >;

        setIsLoading(false);
        throw new LinkAccountApiError(
          castedError.code || LinkAccountApiErrorCodes.AMPLIFY_LOGIN,
          castedError.message,
        );
      }
    } else {
      try {
        amplifyToken = await amplifyAuth.getAuthToken();
      } catch (error) {
        const castedError = error as ErrorWithCode<
          AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes
        >;

        setIsLoading(false);
        throw new LinkAccountApiError(
          castedError.code || LinkAccountApiErrorCodes.AMPLIFY_GET_TOKEN,
          castedError.message,
        );
      }
    }

    if (!amplifyToken) {
      throw new LinkAccountApiError(
        LinkAccountApiErrorCodes.MISSING_AMPLIFY_TOKEN,
        'Missing auth token',
      );
    }

    // 2. Link with sargon account using token
    // if linking call was successful, don't call again.
    if (!matches) {
      try {
        const linkResp = await linkWithSargon({
          variables: {
            input: {
              auth: amplifyToken,
            },
          },
        });

        matches = linkResp?.data?.linkWithSargon?.contactMatches;
      } catch (error) {
        const castedError = error as ErrorWithCode<
          AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes
        >;

        await amplifyAuth.logout();
        setIsLoading(false);

        if (castedError.message.includes('do not match')) {
          throw new LinkAccountApiError(
            LinkAccountApiErrorCodes.MISMATCH_PERSONAL_DETAILS,
            castedError.message,
          );
        } else {
          throw new LinkAccountApiError(
            LinkAccountApiErrorCodes.LINK_ACCOUNT,
            castedError.message,
          );
        }
      }
    }

    // 3a. If details match, refresh token and return true
    if (matches?.emailAddress && matches?.phoneNumber && matches?.address) {
      try {
        // if everything matched, we need to refresh the token
        // before we fetch all the data
        await refreshSpaceshipToken();
        setIsLoading(false);
        return;
      } catch (error) {
        const castedError = error as ErrorWithCode<
          AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes
        >;

        await amplifyAuth.logout();
        setIsLoading(false);
        throw new LinkAccountApiError(
          LinkAccountApiErrorCodes.REFRESH_SPACESHIP_TOKEN,
          castedError.message,
        );
      }
    }

    // 3b. Details don't match
    try {
      // fetch with amplify token
      member = await getMember(amplifyToken);
      setIsLoading(false);
    } catch (error) {
      const castedError = error as ErrorWithCode<
        AmplifyAuthLoginErrorCodes | LinkAccountApiErrorCodes
      >;

      await amplifyAuth.logout();
      setIsLoading(false);
      throw new LinkAccountApiError(
        LinkAccountApiErrorCodes.GET_MEMBER,
        castedError.message,
      );
    }

    if (!member) {
      throw new LinkAccountApiError(
        LinkAccountApiErrorCodes.GET_MEMBER_UNDEFINED,
        'member data undefined',
      );
    }

    return {
      matches: {
        firstName: matches?.firstName || false,
        lastName: matches?.lastName || false,
        dateOfBirth: matches?.dateOfBirth || false,
        emailAddress: matches?.emailAddress || false,
        phoneNumber: matches?.phoneNumber || false,
        address: matches?.address || false,
      },
      member,
    };
  };

  return {
    isLoading,
    linkSuperAccount,
  };
};
