import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useParams } from '@reach/router';
import { Box } from '@spaceship-fspl/components';
import { SaverPortfolio } from '@spaceship-fspl/graphql';
import { WebAppUnknownVoyagerPortfolioInstrument } from '@spaceship-fspl/graphql/src/__generated__/WebAppUnknownVoyagerPortfolioInstrument';
import {
  WebAppVoyagerPortfolio,
  WebAppVoyagerPortfolioVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerPortfolio';
import { IntercomFooter } from 'components/intercom-footer';
import { useAuthenticatedNav } from 'contexts/authenticated-nav';
import { withVoyagerTopNavigation } from 'navigation/helpers';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';

import { CompanyList } from './company-list';
import { CompanyDetails } from './details';

interface PageParams {
  productId?: string;
  instrumentId?: string;
}

const Portfolio: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const { productId = '', instrumentId = '' }: PageParams = useParams();

  const resp = useQuery<
    WebAppVoyagerPortfolio,
    WebAppVoyagerPortfolioVariables
  >(
    gql`
      query WebAppVoyagerPortfolio(
        $productId: ID!
        $productIdProvided: Boolean!
        $instrumentId: ID!
        $instrumentProvided: Boolean!
      ) {
        contact {
          id
          account {
            id
            saverProductInstance(id: $productId)
              @include(if: $productIdProvided) {
              id
              portfolio
            }
          }
        }
        instrument(id: $instrumentId) @include(if: $instrumentProvided) {
          id
        }
      }
    `,
    {
      variables: {
        productId: productId ?? '',
        productIdProvided: !!productId,
        instrumentId: instrumentId ?? '',
        instrumentProvided: !!instrumentId,
      },
    },
  );
  const product = resp.data?.contact?.account?.saverProductInstance;
  const portfolio = product?.portfolio;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!portfolio) {
    return null;
  }

  // Handle missing or invalid instrument ids
  if (!instrumentId || !resp.data?.instrument) {
    return (
      <UnknownVoyagerPortfolioInstrument
        productId={productId}
        portfolio={portfolio}
      />
    );
  }

  return (
    // 101vh minHeight: Hack to make scrollbar always on reduces page jank
    <Box position="relative" minHeight="101vh">
      <CompanyDetails instrumentId={instrumentId} portfolio={portfolio} />
      <CompanyList
        productId={productId}
        portfolio={portfolio}
        currentInstrumentId={instrumentId}
      />
      <IntercomFooter />
    </Box>
  );
};

export const VoyagerPortfolio = withVoyagerTopNavigation()(Portfolio);

const UnknownVoyagerPortfolioInstrument: React.FC<
  React.PropsWithChildren<{
    productId: string;
    portfolio: SaverPortfolio;
  }>
> = ({ productId, portfolio }) => {
  const { selectVoyagerProduct } = useAuthenticatedNav();
  const resp = useQuery<WebAppUnknownVoyagerPortfolioInstrument>(
    gql`
      query WebAppUnknownVoyagerPortfolioInstrument(
        $portfolio: SaverPortfolio!
      ) {
        instruments(
          portfolios: [$portfolio]
          first: 1
          orderBy: YTD_PERFORMANCE_DESC
        ) {
          id
        }
      }
    `,
    {
      variables: {
        portfolio,
      },
    },
  );

  useEffect(() => {
    if (resp.data?.instruments?.[0]?.id) {
      selectVoyagerProduct(productId, {
        route: Routes.VOYAGER_PORTFOLIO,
        params: resp.data.instruments[0].id,
      });
    }
  }, [productId, resp.data, selectVoyagerProduct]);

  return null;
};
