import * as React from 'react';
export const StreamlineCommonFileRotate = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M17.857 1.1l-2.78 1.522 1.316 2.87" />
    <path vectorEffect="non-scaling-stroke" d="M22.25 7.688a6.565 6.565 0 00-1.506-2.377 6.74 6.74 0 00-5.307-1.9M6.292 22.982l2.779-1.523-1.315-2.87" />
    <path vectorEffect="non-scaling-stroke" d="M1.9 16.393a6.563 6.563 0 001.5 2.377 6.77 6.77 0 005.313 1.917M1.75 1.018h8.786v11.714H1.75V1.018zM13.464 11.223h8.786v11.76h-8.786v-11.76z" />
  </svg>
);
