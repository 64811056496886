import { RouteComponentProps } from '@reach/router';
import { OnboardingContainer } from 'components/layouts/onboarding';
import { VoyagerOneOffDepositForm } from 'components/voyager-one-off-deposit-form';
import { useInvestmentSetup } from 'contexts/saver/investment-setup';
import React from 'react';

export const VoyagerOnboardingDeposit: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const {
    onOneOffDepositComplete,
    onOneOffDepositSkip,
    activeBankAccountNumber,
  } = useInvestmentSetup();

  return (
    <OnboardingContainer>
      <VoyagerOneOffDepositForm
        variant="onboarding"
        onComplete={onOneOffDepositComplete}
        onSkip={onOneOffDepositSkip}
        activeBankAccountNumber={activeBankAccountNumber}
      />
    </OnboardingContainer>
  );
};
