import { gql, useQuery } from '@apollo/client';
import {
  Match,
  RouteComponentProps,
  useNavigate,
  useParams,
} from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Spinner,
  Stack,
  Visible,
} from '@spaceship-fspl/components';
import {
  BasiqConnectionStatus,
  TransactionStatus,
} from '@spaceship-fspl/graphql';
import { WebAppBoostsDashboard } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsDashboard';
import { formatCurrency } from '@spaceship-fspl/helpers';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  color,
  fontWeight,
  getSpace,
  linearGradient,
  rgba,
  right,
  text,
  top,
  transition,
  width,
  zIndex,
} from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { isSameYear } from 'date-fns';
import { Routes } from 'pages/routes';
import React from 'react';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { BoostsDashboardBoosts } from './boosts';
import { BoostsDashboardHome } from './home';
import { BoostsDashboardSettings } from './settings';
import {
  BoostsRecipeWithBasiqConnection,
  BoostTransaction,
  BoostYTDTransactionSummaries,
} from './types';

type DashboardSection = 'home' | 'boosts' | 'settings';

interface PageParams {
  section?: DashboardSection;
}

export const BoostsDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { section = 'home' }: PageParams = useParams();

  const resp = useQuery<WebAppBoostsDashboard>(gql`
    query WebAppBoostsDashboard {
      contact {
        id
        account {
          id
          saverBoostRecipes {
            id
            description
            iconName
            name
            status
            parameters {
              id
              name
              type
              value
            }
            groups {
              id
              status
              audAmount
              cancellableUntil
              recipe {
                id
                name
                iconName
              }
            }
            source {
              ... on WeatherStation {
                id
                postcode
                name
              }
              ... on BasiqConnection {
                id
                status
                connectedAt
                institution {
                  id
                  name
                }
              }
            }
            saverProductInstance {
              id
              portfolio
            }
          }
          basiqConnections {
            id
            status
            connectedAt
            institution {
              id
              name
            }
          }
          saverProductInstances {
            id
            investments {
              id
              transactions(types: [BOOST]) {
                id
                status
                audAmount
                requestedAt
                ... on Boost {
                  estimatedExecutionDate
                  itemGroup {
                    id
                    recipe {
                      id
                      name
                      iconName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const account = resp.data?.contact.account;
  const recipes = account?.saverBoostRecipes ?? [];
  const sortedRecipes = recipes.slice().sort((r1, r2) => {
    if (r1.name < r2.name) {
      return -1;
    } else if (r2.name < r1.name) {
      return 1;
    } else {
      return r1.id < r2.id ? -1 : 1;
    }
  });
  const connections = account?.basiqConnections ?? [];
  const allBoostTransactions: Array<BoostTransaction> = [];
  let totalBoostsAudAmount = 0;
  const ytdTransactionSummaries: BoostYTDTransactionSummaries = {};

  account?.saverProductInstances?.forEach((product) => {
    product.investments?.transactions?.forEach((transaction) => {
      if (transaction.__typename === 'Boost') {
        allBoostTransactions.push(transaction);

        if (
          [
            TransactionStatus.PAID,
            TransactionStatus.PENDING,
            TransactionStatus.TRANSFERRING,
          ].includes(transaction.status)
        ) {
          totalBoostsAudAmount += Number(transaction.audAmount);

          const recipe = transaction.itemGroup.recipe;
          if (
            recipe?.name &&
            isSameYear(new Date(transaction.requestedAt), new Date())
          ) {
            ytdTransactionSummaries[recipe.name] = {
              iconName: recipe.iconName,
              audTotal:
                (ytdTransactionSummaries[recipe.name]?.audTotal ?? 0) +
                Number(transaction.audAmount),
              count: (ytdTransactionSummaries[recipe.name]?.count ?? 0) + 1,
            };
          }
        }
      }
    });
  });

  allBoostTransactions.sort((tx1, tx2) =>
    tx1.requestedAt < tx2.requestedAt ? 1 : -1,
  );

  const basiqConnectedRecipes: Array<BoostsRecipeWithBasiqConnection> =
    useMemo(() => {
      const basiqRecipes: Array<BoostsRecipeWithBasiqConnection> = [];
      if (sortedRecipes.length) {
        sortedRecipes.forEach((recipe) => {
          if (recipe.source?.__typename === 'BasiqConnection') {
            basiqRecipes.push({
              ...recipe,
              source: recipe.source,
            });
          }
        });
      }
      return basiqRecipes;
    }, [sortedRecipes]);

  return (
    <Box position="relative">
      <StyledHeroGradientBox />

      <PageContainer>
        <Visible
          isHidden={{ xs: true, md: false }}
          component="div"
          displayValue="block"
        >
          <Box position="relative">
            <Box position="absolute" top={0} right={0}>
              <StyledAddNewBoostButton
                variant="tertiary"
                size="sm"
                trackingProperties={{
                  name: 'boosts_dashboard_page_add_boost_button',
                }}
                onClick={() => {
                  navigate(Routes.BOOSTS_RECIPE_SELECT);
                }}
              >
                Add new boost
              </StyledAddNewBoostButton>
            </Box>
          </Box>
        </Visible>

        <Stack spaceY="md">
          <Stack spaceY="xxs">
            <Heading
              variant={5}
              color="neutral.000"
              align="center"
              component="h1"
            >
              Boosts Tracker
            </Heading>

            <Heading
              variant={{ xs: 2, lg: 3 }}
              color="neutral.000"
              align="center"
            >
              {formatCurrency(totalBoostsAudAmount)}
            </Heading>
          </Stack>

          <Box display="flex" justifyContent="center">
            <StyledTabNav>
              <Tab route={Routes.BOOSTS_DASHBOARD_HOME} text="Home" />

              <Tab
                route={Routes.BOOSTS_DASHBOARD_BOOSTS}
                text="Boosts"
                showIndicator={
                  recipes.filter(
                    (recipe) =>
                      recipe.source?.__typename === 'BasiqConnection' &&
                      (recipe.source?.status ===
                        BasiqConnectionStatus.DISCONNECTED ||
                        recipe.source?.status ===
                          BasiqConnectionStatus.REMOVED),
                  ).length > 0
                }
              />

              <Tab
                route={Routes.BOOSTS_DASHBOARD_SETTINGS}
                text="Settings"
                showIndicator={
                  connections.filter((connection) => {
                    const isDisconnected =
                      connection.status === BasiqConnectionStatus.DISCONNECTED;

                    const isRecipeSource = !!recipes.find(
                      (recipe) => recipe.source?.id === connection.id,
                    );

                    return isDisconnected && !isRecipeSource;
                  }).length > 0
                }
              />
            </StyledTabNav>
          </Box>

          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
              {resp.loading ? (
                <Box marginTop="xxxl" display="flex" justifyContent="center">
                  <Spinner size="lg" />
                </Box>
              ) : (
                <>
                  {section === 'home' && (
                    <BoostsDashboardHome
                      sortedRecipes={sortedRecipes}
                      ytdTransactionSummaries={ytdTransactionSummaries}
                      sortedTransactions={allBoostTransactions}
                    />
                  )}
                  {section === 'boosts' && (
                    <BoostsDashboardBoosts
                      sortedRecipes={sortedRecipes}
                      connections={connections}
                    />
                  )}
                  {section === 'settings' && (
                    <BoostsDashboardSettings
                      recipes={basiqConnectedRecipes}
                      connections={connections}
                    />
                  )}
                </>
              )}
            </Columns.Column>
          </Columns>
        </Stack>
      </PageContainer>
    </Box>
  );
};

const Tab: React.FC<
  React.PropsWithChildren<{
    route: Routes;
    text: string;
    showIndicator?: boolean;
  }>
> = ({ route, text, showIndicator = false }) => {
  return (
    <Match path={route}>
      {({ match }): JSX.Element => (
        <RouterLink
          to={route}
          trackingProperties={{
            name: `boosts_dashboard_page_${text.toLowerCase()}_tab`,
          }}
        >
          <StyledTab isActiveTab={!!match} showIndicator={showIndicator}>
            {text}
          </StyledTab>
        </RouterLink>
      )}
    </Match>
  );
};

const StyledHeroGradientBox = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 258,
})`
  ${zIndex(-1)}
  background: ${linearGradient({
    angle: '36.87deg',
    colorStops: [
      ['indigo.050', '0.07%'],
      ['red.020', '108.95%'],
    ],
  })};
`;

const TAB_HEIGHT = 40;

const StyledTabNav = styled.nav`
  background-color: ${rgba('indigo.100', 0.2)};
  border-radius: ${TAB_HEIGHT / 2}px;
  display: flex;
  height: ${TAB_HEIGHT}px;
  overflow: hidden;
`;

const StyledTab = styled.div<{
  isActiveTab: boolean;
  showIndicator: boolean;
}>`
  ${({ isActiveTab }) => (isActiveTab ? backgroundColor('indigo.100') : '')}
  ${text({ variant: 3 })}
  ${fontWeight(700)}
  ${width({ xs: 100, md: 120 })}
  color: ${({ isActiveTab }) => rgba('neutral.000', isActiveTab ? 1 : 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${TAB_HEIGHT / 2}px;
  height: 100%;
  position: relative;
  ${transition}

  :hover {
    ${color('neutral.000')}
  }

  ${({ showIndicator }) =>
    showIndicator
      ? css`
          ::after {
            content: '';
            ${backgroundColor('red.100')}
            ${borderRadius('xs')}
      ${right({ xs: getSpace('xs'), md: getSpace('sm') })}
      ${top({ xs: getSpace('xxxs'), md: getSpace('xxs') })};
            height: ${getSpace('xs')};
            width: ${getSpace('xs')};
            position: absolute;
          }
        `
      : ''}
`;

const StyledAddNewBoostButton = styled(Button)`
  ${color('neutral.000')}
  ${borderColor('neutral.000')}

  :not(:disabled):hover,
  :not(:disabled):active {
    background-color: ${rgba('neutral.100', 0.1)};
  }
`;
