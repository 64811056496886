import { gql, useQuery } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { useIsAuthenticated } from '@spaceship-fspl/auth';
import {
  BasiqConnectionStatus,
  BiometricVerificationStatus,
  TaxResidenceStatus,
} from '@spaceship-fspl/graphql/';
import {
  WebAppUseTodo,
  WebAppUseTodoVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppUseTodo';
import {
  useInteractiveSources,
  useIsStatusInProgress,
  useIsStatusRejected,
  useSourcesLeftToVerify,
} from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { TodoItem } from 'components/todo-card';
import { useIntercom } from 'contexts/intercom';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { useDismissed } from 'helpers/hooks/dismissed';
import { PersistKey } from 'helpers/persist';
import { Routes } from 'pages/routes';

interface TodoData {
  todoItems: Array<TodoItem>;
  hasTodoItems: boolean;
}

export const useTodo = (productId: string): TodoData => {
  const navigate = useNavigate();
  const isSecurityCheckEnabled = useFeatureFlag(
    FeatureFlagKeys.SECURITY_CHECK_ENABLED,
  );
  const isAuthenticated = useIsAuthenticated();
  const { pop: showIntercom } = useIntercom();

  const resp = useQuery<WebAppUseTodo, WebAppUseTodoVariables>(
    gql`
      query WebAppUseTodo($productId: ID!, $productIdProvided: Boolean!) {
        contact {
          id
          biometricVerificationStatus
          taxResidenceStatus
          account {
            id
            saverProductInstance(id: $productId)
              @include(if: $productIdProvided) {
              id
              upcomingSchedule {
                id
              }
              investments {
                id
                transactions(types: [APPLICATION], limit: 1) {
                  id
                }
              }
            }
            basiqConnections {
              id
              status
            }
          }
        }
      }
    `,
    {
      skip: !isAuthenticated,
      variables: { productId, productIdProvided: !!productId },
    },
  );

  const [
    isBiometricVerificationSuccessDimissed,
    setIsBiometricVerificationSuccessDimissed,
  ] = useDismissed(
    PersistKey.BIOMETRIC_VERIFICATION_SUCCESS_DISMISSED_UNTIL,
    Number.MAX_VALUE, // Never show after first press, until security check is initiated again
    Number.MAX_VALUE, // Start dismissed, cleared later
  );

  const ruleset = GreenIdRuleSet.Enum.VOYAGER_ONBOARDING;
  const interactiveSources = useInteractiveSources(ruleset);
  const numberOfSourcesLeftToVerify = useSourcesLeftToVerify(ruleset);

  // new greenid failed status
  const isGreenIDStatusFailed = useIsStatusRejected(ruleset);

  // new green id pending status
  const isGreenIDStatusPending = useIsStatusInProgress(ruleset);

  // new greenid not provided status
  const isGreenIDNotProvided =
    isGreenIDStatusPending &&
    numberOfSourcesLeftToVerify > 0 &&
    interactiveSources.length === 0;

  const hasUpcomingSchedule =
    !!resp.data?.contact?.account?.saverProductInstance?.upcomingSchedule;

  const transactions =
    resp.data?.contact?.account?.saverProductInstance?.investments
      ?.transactions ?? [];

  const biometricVerificationStatus =
    resp.data?.contact?.biometricVerificationStatus;

  const isTaxResidencyUnspecified =
    resp.data?.contact?.taxResidenceStatus === TaxResidenceStatus.UNSPECIFIED;

  const hasGreenIDTodoItems =
    isGreenIDNotProvided || isGreenIDStatusPending || isGreenIDStatusFailed;

  const hasDisconnectedConnections =
    !!resp.data?.contact?.account?.basiqConnections?.some(
      (connection) => connection.status === BasiqConnectionStatus.DISCONNECTED,
    );

  const todoItems: Array<TodoItem> = [
    {
      title: 'Verify your identity',
      body: 'To get your account up and running, we need to verify your identity.',
      action: showIntercom,
      isShown: isGreenIDNotProvided,
    },
    {
      title:
        'We’ve received your identification documents and are verifying them.',
      body: 'This normally takes up to one business day.',
      action: async (): Promise<void> => navigate(Routes.ACCOUNT_USER_DETAILS),
      isShown: isGreenIDStatusPending,
    },
    {
      title: 'Oops, we were unable to verify your identity. ',
      body: 'Please contact our customer support team and we’ll get you back on track.',
      action: showIntercom,
      isShown: isGreenIDStatusFailed,
    },
    {
      title: 'Update your Boosts',
      body: 'We need to re-link one (or more) of your tracking banks.',
      isShown: hasDisconnectedConnections,
      action: async (): Promise<void> =>
        navigate(Routes.BOOSTS_DASHBOARD_SETTINGS),
    },
    {
      title: 'Update your Tax Residency',
      body: 'We need you to update your tax residency details for mandatory reporting',
      action: (): Promise<void> => navigate(Routes.VOYAGER_TAX_RESIDENCY),
      isShown: isTaxResidencyUnspecified,
    },
    {
      title: 'Make your first investment',
      body: 'After linking your bank account you can create your first investment.',
      action: (): Promise<void> =>
        navigate(`${Routes.VOYAGER_INVESTMENT_PLAN}/${productId}`),
      isShown:
        !hasGreenIDTodoItems &&
        !hasUpcomingSchedule &&
        transactions.length === 0,
    },
    {
      title: 'Your security check is underway.',
      body: 'Once we’re done, you’ll be able to link your new bank account.',
      isShown:
        isSecurityCheckEnabled &&
        biometricVerificationStatus === BiometricVerificationStatus.IN_PROGRESS,
      action: (): Promise<void> => navigate(Routes.ACCOUNT_SECURITY_CHECK),
    },
    {
      title: 'Oops, we were unable to complete our security check.',
      body: 'Please check your email for more details or contact support. ',
      isShown:
        isSecurityCheckEnabled &&
        biometricVerificationStatus === BiometricVerificationStatus.FAILED,
      action: showIntercom,
    },
    {
      title:
        'Congratulations! You have successfully passed our security check.',
      body: 'You can now link a new bank account.',
      isShown:
        isSecurityCheckEnabled &&
        !isBiometricVerificationSuccessDimissed &&
        biometricVerificationStatus === BiometricVerificationStatus.VERIFIED,
      action: () => {
        setIsBiometricVerificationSuccessDimissed();
      },
      isDismissible: true,
    },
  ]
    .filter((todo) => todo.isShown)
    .slice(0, 2);

  return {
    todoItems,
    hasTodoItems: todoItems.length > 0,
  };
};
