import { Box, Visible } from '@spaceship-fspl/components';
import React from 'react';
import styled from 'styled-components';

import * as Animate from './animate';
import { Shape } from './shape';

export interface ConfettiCannonProps {
  height?: number;
  delay?: number;
}

export const ConfettiCannon: React.FC<
  React.PropsWithChildren<ConfettiCannonProps>
> = ({ height = 600, delay = 1000 }) => {
  const variationDelay = delay + 500;
  const variationWidths = [
    { xs: 150, sm: 185, md: 275, lg: 400, xl: 600 },
    { xs: 125, sm: 155, md: 245, lg: 325, xl: 438 },
    { xs: 100, sm: 125, md: 215, lg: 250, xl: 476 },
  ];
  const iteration = 3; // Limit the iterations because it does cover all other elements on the page

  return (
    <StyledBox>
      <Box
        height={height}
        width={variationWidths[0]}
        position="absolute"
        top="10px"
        left="50%"
      >
        <Animate.Container left={0} delay={delay} iteration={iteration}>
          <Animate.AnimateRight
            movementSize="sm"
            movementVariant={2}
            color="pink.080"
            duration={3200}
            delay={320}
          >
            <Shape variant="rectangle" height={16.48} width={3.27} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="sm"
            movementVariant={3}
            color="blue.070"
            duration={3100}
            delay={420}
          >
            <Shape variant="star" height={8} width={8} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="md"
            movementVariant={4}
            color="red.015"
            duration={3000}
            delay={350}
          >
            <Shape variant="circle" height={9} width={11} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={5}
            color="mint.030"
            duration={2800}
            delay={380}
          >
            <Shape variant="star" height={11} width={11} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={3}
            color="indigo.020"
            duration={3000}
            delay={300}
          >
            <Shape variant="rectangle" height={21.57} width={3.26} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="md"
            movementVariant={1}
            duration={2900}
          >
            <Shape variant="emoji" height={28} width={28} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="md"
            movementVariant={3}
            color="indigo.050"
            duration={2800}
            delay={800}
          >
            <Shape variant="circle" height={8.76} width={8.15} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={1}
            color="indigo.020"
            duration={3000}
            delay={600}
          >
            <Shape variant="rectangle" height={19.65} width={2.72} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="md"
            movementVariant={4}
            color="gold.030"
            duration={2900}
            delay={100}
          >
            <Shape variant="circle" height={5.09} width={5.47} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={4}
            color="indigo.020"
            duration={2900}
          >
            <Shape variant="rectangle" height={29.88} width={2.17} />
          </Animate.AnimateRight>
        </Animate.Container>
      </Box>

      <Box
        height={height - 75}
        width={variationWidths[1]}
        position="absolute"
        top="10px"
        left="calc(50% + 10px)"
      >
        <Animate.Container
          left={0}
          delay={variationDelay}
          iteration={iteration}
        >
          <Animate.AnimateRight
            movementSize="md"
            movementVariant={2}
            color="pink.050"
            duration={3200}
            delay={900}
          >
            <Shape variant="star" height={17} width={17} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={1}
            duration={2800}
            delay={700}
          >
            <Shape variant="emoji" height={28} width={28} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={5}
            color="red.030"
            duration={3000}
            delay={900}
          >
            <Shape variant="rectangle" height={31.63} width={2.05} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={3}
            color="indigo.020"
            duration={3000}
            delay={200}
          >
            <Shape variant="triangle" height={15.76} width={13.83} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={1}
            color="indigo.050"
            duration={3100}
            delay={800}
          >
            <Shape variant="triangle" height={16.6} width={15.13} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={2}
            color="blue.050"
            duration={3100}
            delay={300}
          >
            <Shape variant="star" height={20} width={20} />
          </Animate.AnimateRight>
        </Animate.Container>
      </Box>

      <Box
        height={height - 150}
        width={variationWidths[2]}
        position="absolute"
        top="10px"
        left="calc(50% + 20px)"
      >
        <Animate.Container left={0} delay={delay} iteration={iteration}>
          <Animate.AnimateRight
            movementSize="md"
            movementVariant={1}
            color="red.020"
            duration={2900}
            delay={400}
          >
            <Shape variant="rectangle" height={18} width={3.26} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="sm"
            movementVariant={5}
            color="indigo.050"
            duration={2800}
            delay={200}
          >
            <Shape variant="circle" height={9} width={9} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="md"
            movementVariant={2}
            color="indigo.020"
            duration={3100}
            delay={500}
          >
            <Shape variant="triangle" height={6.5} width={6.18} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={2}
            color="red.030"
            duration={3100}
            delay={400}
          >
            <Shape variant="rectangle" height={21.48} width={3.27} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="md"
            movementVariant={3}
            color="gold.050"
            duration={3000}
            delay={450}
          >
            <Shape variant="star" height={18} width={18} />
          </Animate.AnimateRight>

          <Animate.AnimateRight
            movementSize="lg"
            movementVariant={3}
            color="pink.030"
            duration={2800}
            delay={300}
          >
            <Shape variant="triangle" height={5} width={7} />
          </Animate.AnimateRight>
        </Animate.Container>
      </Box>

      <Visible
        component="div"
        displayValue="block"
        isHidden={{ xs: true, md: false }}
      >
        <Box
          height={height - 75}
          width={variationWidths[1]}
          position="absolute"
          top="5px"
          right="calc(50% + 10px)"
        >
          <Animate.Container left={100} delay={delay} iteration={iteration - 1}>
            <Animate.AnimateLeft
              movementSize="md"
              movementVariant={2}
              color="pink.050"
              duration={3200}
              delay={900}
            >
              <Shape variant="star" height={17} width={17} />
            </Animate.AnimateLeft>

            <Animate.AnimateLeft
              movementSize="lg"
              movementVariant={1}
              duration={3300}
              delay={700}
            >
              <Shape variant="emoji" height={28} width={28} />
            </Animate.AnimateLeft>

            <Animate.AnimateLeft
              movementSize="lg"
              movementVariant={5}
              color="red.030"
              duration={3250}
              delay={900}
            >
              <Shape variant="rectangle" height={31.63} width={2.05} />
            </Animate.AnimateLeft>

            <Animate.AnimateLeft
              movementSize="lg"
              movementVariant={3}
              color="gold.050"
              duration={3600}
              delay={200}
            >
              <Shape variant="triangle" height={15.76} width={13.83} />
            </Animate.AnimateLeft>

            <Animate.AnimateLeft
              movementSize="lg"
              movementVariant={1}
              color="red.020"
              duration={3400}
              delay={800}
            >
              <Shape variant="triangle" height={16.6} width={15.13} />
            </Animate.AnimateLeft>

            <Animate.AnimateLeft
              movementSize="lg"
              movementVariant={2}
              color="blue.050"
              duration={3500}
              delay={300}
            >
              <Shape variant="star" height={20} width={20} />
            </Animate.AnimateLeft>
          </Animate.Container>
        </Box>
      </Visible>

      <Box
        height={height - 150}
        width={variationWidths[2]}
        position="absolute"
        top="5px"
        right="calc(50% + 20px)"
      >
        <Animate.Container left={100} delay={delay} iteration={iteration}>
          <Animate.AnimateLeft
            movementSize="md"
            movementVariant={1}
            color="pink.080"
            duration={3000}
            delay={400}
          >
            <Shape variant="triangle" height={14} width={5} />
          </Animate.AnimateLeft>

          <Animate.AnimateLeft
            movementSize="sm"
            movementVariant={5}
            color="indigo.050"
            duration={3200}
            delay={200}
          >
            <Shape variant="circle" height={9} width={9} />
          </Animate.AnimateLeft>

          <Animate.AnimateLeft
            movementSize="md"
            movementVariant={2}
            color="indigo.020"
            duration={3300}
            delay={500}
          >
            <Shape variant="triangle" height={6.5} width={6.18} />
          </Animate.AnimateLeft>

          <Animate.AnimateLeft
            movementSize="lg"
            movementVariant={2}
            color="red.030"
            duration={3400}
            delay={400}
          >
            <Shape variant="rectangle" height={21.48} width={3.27} />
          </Animate.AnimateLeft>

          <Animate.AnimateLeft
            movementSize="md"
            movementVariant={3}
            color="gold.050"
            duration={3000}
            delay={450}
          >
            <Shape variant="star" height={18} width={18} />
          </Animate.AnimateLeft>

          <Animate.AnimateLeft
            movementSize="lg"
            movementVariant={3}
            color="pink.030"
            duration={3500}
            delay={300}
          >
            <Shape variant="triangle" height={5} width={7} />
          </Animate.AnimateLeft>
        </Animate.Container>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box).attrs({
  position: 'relative',
})`
  pointer-events: none;
  width: 100%;
`;
