import { RouteComponentProps, useNavigate } from '@reach/router';
import { Success } from 'components/layouts/success';
import { useContext } from 'contexts/super/investment-option';
import { Routes } from 'pages/routes';
import React from 'react';

export const SuperSelectInvestmentOptionSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const { variant } = useContext();

  return (
    <Success
      title="Your request has been submitted"
      subtitle="Thanks for submitting your investment option change request. We’ll send you an email when this has been processed."
      onContinue={{
        trackingProperties: {
          name: 'super_select_investment_option_success_continue',
        },
        onClick: () => {
          if (variant === 'change') {
            navigate(Routes.ACCOUNT_SUPER_DETAILS);
          } else {
            navigate(Routes.SUPER_DASHBOARD);
          }
        },
      }}
    />
  );
};
