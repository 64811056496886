import { useEffect, useState } from 'react';

import { usePrevious } from './use-previous';

// Template syntax: {{ variableName }}
// {{         - match two literal '{'
// \s         - match whitespace character
// (          - start capture group
//  [^\s]+    - match non-whitepsace characters
// )          - end capture group
// \s         - match whitespace character
// }}         - match two literal '}'
const TEMPLATE_REGEX = /{{\s([^\s]+)\s}}/g;

interface RenderTemplateOptions {
  onError: (error: Error) => void;
}

export const useRenderTemplate = (
  template: string,
  variables: Record<string, string>,
  options?: RenderTemplateOptions,
): string => {
  const [result, setResult] = useState('');
  const [mismatches, setMismatches] = useState<string | undefined>();
  const prevMismatches = usePrevious(mismatches);

  useEffect(() => {
    const mismatches = [];
    let match;
    while ((match = TEMPLATE_REGEX.exec(template)) !== null) {
      const [, key] = match;
      if (key && variables[key] === undefined) {
        mismatches.push(key);
      }
    }

    if (mismatches.length > 0) {
      setMismatches(`mismatched template variables: ${mismatches.join(', ')}`);
    }

    setResult(
      template.replace(TEMPLATE_REGEX, (_, key) => {
        return variables[key] || '';
      }),
    );
  }, [template, variables]);

  useEffect(() => {
    if (prevMismatches !== mismatches) {
      options?.onError(new Error(mismatches));
    }
  }, [prevMismatches, mismatches, options]);

  return result;
};
