import { useBetweenMedia } from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { TrackingEvent } from 'helpers/analytics';
import { config } from 'helpers/config';
import React, { createContext, memo, useContext, useEffect } from 'react';

interface Intercom {
  pop(): void;
}

// Ref: https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
export interface IntercomSettings {
  app_id: string;
  user_id?: string;
  user_hash?: string;
  name?: string;
  email?: string;
  phone?: string;
  created_at?: number;
  hide_default_launcher?: boolean;
}

declare global {
  interface Window {
    Intercom: (event: IntercomEvent, settings?: IntercomSettings) => void;
  }
}

export enum IntercomEvent {
  BOOT = 'boot',
  SHUTDOWN = 'shutdown',
  SHOW = 'show',
  HIDE = 'hide',
  UPDATE = 'update',
}

export const IntercomContext = createContext<Intercom>({
  pop: () => {
    /* do nothing */
  },
});

export const IntercomProvider: React.FC<React.PropsWithChildren> = memo(
  ({ children }) => {
    const isMobileBreakpoint = useBetweenMedia('xs', 'md');
    const track = useTrack();

    useEffect(() => {
      if (!window.Intercom) {
        window.intercomSettings = {
          app_id: config.intercomAppId,
          custom_launcher_selector: '#custom_intercom_selector',
          hide_default_launcher: isMobileBreakpoint,
        };

        // Intercom interface enforces the below
        const q = [] as [IntercomEvent][];
        const intercom = Object.assign(
          (...args: [IntercomEvent]): void => {
            intercom.c(...args);
          },
          {
            q,
            c: (...args: [IntercomEvent]): void => {
              q.push(args);
            },
          },
        );

        // TODO: Fix typing for Intercom (https://spaceship-hq.atlassian.net/browse/TECH-121)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.Intercom = intercom as any;
        const script = document.createElement('script');
        script.async = true;
        script.src =
          'https://widget.intercom.io/widget/' + config.intercomAppId;
        document.head.appendChild(script);
        window.Intercom(IntercomEvent.BOOT, {
          app_id: config.intercomAppId,
        });
      }

      window.addEventListener('resize', () => {
        if (isMobileBreakpoint) {
          window.Intercom(IntercomEvent.UPDATE, {
            app_id: config.intercomAppId,
            hide_default_launcher: true,
          });
        } else {
          window.Intercom(IntercomEvent.UPDATE, {
            app_id: config.intercomAppId,
            hide_default_launcher: false,
          });
        }
      });

      return (): void => window.Intercom(IntercomEvent.SHUTDOWN);
    }, [isMobileBreakpoint]);

    const pop = (): void => {
      if (window.Intercom !== undefined) {
        track?.(TrackingEvent.INTERCOM, { properties: { time: Date.now() } });
        window.Intercom(IntercomEvent.SHOW);
      }
    };

    return (
      <IntercomContext.Provider value={{ pop }}>
        {children}
      </IntercomContext.Provider>
    );
  },
);

IntercomProvider.displayName = 'IntercomProvider';

export const useIntercom = (): Intercom => useContext(IntercomContext);
