import { Theme } from '../../types';
import { textLinkStyles } from './links';
import { fontFamilies, headingVariant, textVariant } from './text';

export const marketingTheme: Theme = {
  name: 'marketing',
  fontFamilies,
  headingVariant,
  textVariant,
  textLinkStyles,
};
