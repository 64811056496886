import * as React from 'react';
export const StreamlineMultipleNeutral2 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M3.567 8.033a4.033 4.033 0 108.066 0 4.033 4.033 0 00-8.066 0zM1 20.133a6.6 6.6 0 1113.2 0M14.3 10.233a3.3 3.3 0 106.599 0 3.3 3.3 0 00-6.6 0zM15.728 15.066A5.404 5.404 0 0123 20.133" />
  </svg>
);
