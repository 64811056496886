import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import { AccountCardEditFormButtons } from 'components/account-cards/components';
import { VoyagerPortfolioCard } from 'components/account-cards/voyager-portfolio';
import { usePortfolioRegistration } from 'contexts/saver/portfolio-registration';
import React from 'react';

export interface PortfolioSectionProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const PortfolioSection: React.FC<
  React.PropsWithChildren<PortfolioSectionProps>
> = ({ isEditing, setIsEditing }) => {
  const { portfolio, setPortfolio } = usePortfolioRegistration();

  if (!portfolio) {
    return null;
  }

  return (
    <VoyagerPortfolioCard
      portfolio={portfolio}
      setPortfolio={(value: SaverPortfolio.Enum) => {
        setPortfolio?.(value);
        setIsEditing(false);
      }}
      edit={{
        onClick: () => setIsEditing(true),
        isEditing,
      }}
    >
      <AccountCardEditFormButtons
        cancel={{
          onClick: () => setIsEditing(false),
          trackingProperties: { name: 'portfolio_edit_cancel' },
        }}
        submitTrackingProperties={{ name: 'portfolio_edit_save' }}
      />
    </VoyagerPortfolioCard>
  );
};
