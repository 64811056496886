import { MfaVerificationType } from '@spaceship-fspl/graphql';
import {
  MFAVerificationModal,
  MFAVerificationModalRef,
} from 'components/mfa-verification-modal';
import React, { createContext } from 'react';

interface MFAVerification {
  handleOnMFAVerificationRequired: (
    verified: () => void,
    unverified: () => void,
    type: MfaVerificationType,
    to?: string,
  ) => void;
}

const MFAVerificationContext = createContext<MFAVerification | undefined>(
  undefined,
);

export const MFAVerificationProvider: React.FC<
  React.PropsWithChildren<{
    mfaVerificationModalRef?: React.RefObject<MFAVerificationModalRef>;
    handleOnMFAVerificationRequired?: MFAVerification['handleOnMFAVerificationRequired'];
  }>
> = ({
  children,
  mfaVerificationModalRef,
  handleOnMFAVerificationRequired,
}) => {
  if (!handleOnMFAVerificationRequired) {
    handleOnMFAVerificationRequired = (
      verified: () => void,
      unverified: () => void,
      type: MfaVerificationType,
      verificationTo?: string,
    ): void => {
      mfaVerificationModalRef?.current?.showMFAVerification(
        verified,
        unverified,
        type,
        verificationTo,
      );
    };
  }

  return (
    <MFAVerificationContext.Provider
      value={{ handleOnMFAVerificationRequired }}
    >
      {children}
      <MFAVerificationModal ref={mfaVerificationModalRef} />
    </MFAVerificationContext.Provider>
  );
};
