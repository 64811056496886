import * as React from 'react';
export const FeatherAward = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 1a7 7 0 100 14 7 7 0 100-14z" />
    <path vectorEffect="non-scaling-stroke" d="M8.21 13.89L7 23l5-3 5 3-1.21-9.12" />
  </svg>
);
