import {
  Box,
  Card,
  Columns,
  Heading,
  PresentationLink,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { useTreatment } from '@spaceship-fspl/experimentation';
import { FeatherXIcon } from '@spaceship-fspl/icons-web';
import {
  fontSize,
  getSpace,
  lineHeight,
  match,
  padding,
  transition,
} from '@spaceship-fspl/styles';
import solarSystem from 'assets/animations/solar_system.json';
import superMatchConsolidateBanner from 'assets/supermatch-consolidate-banner.png';
import { RouterLink } from 'components/router-link';
import { ExperimentNames } from 'helpers/experimentation';
import { useDismissed } from 'helpers/hooks/dismissed';
import { useIsEligibleForSuperReferrals } from 'helpers/hooks/use-is-eligible-for-super-referrals';
import { PersistKey } from 'helpers/persist';
import Lottie from 'lottie-react';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

const TIME_TO_DISMISS_MS = 1000 * 60 * 60 * 24 * 28; // 28 days

export const SuperMatchCard: React.FC<React.PropsWithChildren> = () => {
  const treatment = useTreatment(ExperimentNames.SUPERMATCH_BANNER);
  const isConsolidateTreatment =
    treatment.name === 'supermatch_consolidate_web';
  const isFinancialMember = useIsEligibleForSuperReferrals();
  const [isDismissed, dismiss] = useDismissed(
    PersistKey.SUPERMATCH_CARD_DISMISSED_UNTIL,
    TIME_TO_DISMISS_MS,
  );

  useEffect(() => {
    if (!isFinancialMember) {
      treatment.activate();
    }
  }, [treatment, isFinancialMember]);

  if (
    isDismissed ||
    isFinancialMember === undefined ||
    isFinancialMember ||
    treatment.name === 'control'
  ) {
    return null;
  }

  return (
    <StyledCard variant={treatment.name}>
      <StyledDismissButton onClick={dismiss}>
        <FeatherXIcon color="neutral.000" />
      </StyledDismissButton>

      <Box padding="lg">
        <Columns
          alignY="center"
          alignX="left"
          reverse={{ xs: true, md: isConsolidateTreatment ? false : true }}
        >
          <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
            <Stack spaceY="xs">
              <StyledHeading>{treatment.params.header}</StyledHeading>
              <Stack spaceY="sm" alignX={{ xs: 'center', md: 'left' }}>
                <Text
                  variant={2}
                  color="neutral.050"
                  align={{ xs: 'center', md: 'left' }}
                >
                  {treatment.params.body}
                </Text>

                <RouterLink
                  to={Routes.SUPER_SUPERMATCH}
                  trackingProperties={{
                    name: 'supermatch_banner',
                    variant: treatment.name,
                  }}
                >
                  <PresentationLink
                    size="sm"
                    color="neutral.000"
                    icon="arrow"
                    component="span"
                  >
                    {treatment.params.cta}
                  </PresentationLink>
                </RouterLink>
              </Stack>
            </Stack>
          </Columns.Column>

          {isConsolidateTreatment && (
            <Columns.Column width={{ sm: 1, md: 1 / 2 }}>
              <Lottie height={200} animationData={solarSystem} />
            </Columns.Column>
          )}
        </Columns>
      </Box>
    </StyledCard>
  );
};

const StyledCard = styled(Card).attrs({
  backgroundColor: 'neutral.100',
  marginBottom: 'md',
})`
  ${(props) =>
    props.variant === 'supermatch_find_web' &&
    css`
      background-image: url(${superMatchConsolidateBanner});
      background-repeat: no-repeat;
      background-size: 340px;
      background-position: top center;

      ${match('xs')`
        background-size: 420px;
        padding-top: 180px;
      `}

      ${match('md')`
        background-size: 380px;
        background-position: calc(100% + ${getSpace('xl')}) center;
        padding-top: 0;
      `}

      ${match('lg')`
        background-size: 580px;
        background-position: calc(100% + ${getSpace('xl')}) -120px};
      `}

      ${match('xl')`
        background-size: auto;
      `}
    `}
`;

const StyledHeading = styled(Heading).attrs({
  variant: 4,
  color: 'neutral.000',
  isBold: true,
  align: { xs: 'center', md: 'left' },
})`
  ${fontSize({ lg: '24px' })}
  ${lineHeight({ lg: '36px' })}
`;

const StyledDismissButton = styled(UnstyledButton)`
  ${padding('sm')}
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.4;
  ${transition}

  :hover {
    opacity: 1;
  }
`;
