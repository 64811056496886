import { RouteComponentProps, useNavigate } from '@reach/router';
import { Success } from 'components/layouts/success';
import { Routes } from 'pages/routes';
import React from 'react';

export interface VoyagerPortfolioRegistrationSuccessProps {
  location: {
    state: {
      productId: string;
      message?: string;
    };
  };
}

export const VoyagerPortfolioRegistrationSuccess: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<VoyagerPortfolioRegistrationSuccessProps>
  >
> = ({ location }) => {
  const navigate = useNavigate();
  return (
    <Success
      isOnboarding={true}
      showConfetti={true}
      title="Your new portfolio has been added!"
      subtitle={
        location?.state?.message ||
        'You can view your new portfolio on your dashboard.'
      }
      buttonText="Done"
      onContinue={{
        trackingProperties: {
          name: 'voyager_portfolio_registration_success_done',
        },
        onClick: () =>
          navigate(
            `${Routes.VOYAGER_DASHBOARD}/${location?.state?.productId}`,
            {
              state: {
                isRegisteredNewPortfolio: true,
              },
            },
          ),
      }}
    />
  );
};
