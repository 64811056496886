import { Box } from '@spaceship-fspl/components';
import { MoneyTransactionStatus } from '@spaceship-fspl/graphql';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { Color, getColor } from '@spaceship-fspl/styles';
import React, { Fragment } from 'react';

const FailedTransactionStatuses = [
  MoneyTransactionStatus.CANCELLED,
  MoneyTransactionStatus.FAILED,
];

export const isFailedTransaction = (status: MoneyTransactionStatus): boolean =>
  FailedTransactionStatuses.some((failedStatus) => failedStatus === status);

type TransactionStep = {
  color: Color;
  status: MoneyTransactionStatus;
};

interface MoneyTransactionStatusIndicatorProps {
  status: MoneyTransactionStatus;
  steps?: [TransactionStep, TransactionStep, TransactionStep];
}

const defaultSteps: TransactionStep[] = [
  {
    color: 'neutral.080',
    status: MoneyTransactionStatus.PENDING,
  },
  {
    color: 'gold.100',
    status: MoneyTransactionStatus.PROCESSING,
  },
  {
    color: 'mint.050',
    status: MoneyTransactionStatus.COMPLETED,
  },
];

const defaultStepColor = 'neutral.050';

export const MoneyTransactionStatusIndicator: React.FC<
  MoneyTransactionStatusIndicatorProps
> = ({ status, steps = defaultSteps }) => {
  const statusStep = steps.findIndex((step) => step.status === status);
  if (!isFailedTransaction(status)) {
    return (
      <Box display="flex" alignItems="center" marginRight={'xxxs'}>
        <svg
          width="24"
          height="6"
          viewBox="0 0 24 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {steps.map(
            (step, index) =>
              step && (
                <Fragment key={step.status}>
                  {index < steps.length - 1 && (
                    <rect
                      x={9 * index + 5}
                      y="2"
                      width="5"
                      height="2"
                      fill={
                        index + 1 <= statusStep
                          ? getColor(
                              steps[statusStep]?.color ?? defaultStepColor,
                            )
                          : getColor(defaultStepColor)
                      }
                    />
                  )}
                  <circle
                    cx={9 * index + 3}
                    cy="3"
                    r="3"
                    fill={
                      index <= statusStep
                        ? getColor(steps[statusStep]?.color ?? defaultStepColor)
                        : getColor(defaultStepColor)
                    }
                  />
                </Fragment>
              ),
          )}
        </svg>
      </Box>
    );
  }

  if (isFailedTransaction(status)) {
    return (
      <Box lineHeight={0} width={24} marginRight={'xxxs'}>
        <FeatherAlertTriangleIcon size="sm" color="red.100" />
      </Box>
    );
  }

  return null;
};
