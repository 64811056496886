import { ExternalRoutes, InternalRoutes } from '@spaceship-fspl/helpers';
import { Gender } from '@spaceship-fspl/types/externalapi';

export const SUPER_PORTFOLIOS_EXAMPLE_BALANCE = 50000;

export const FUND_DETAILS = {
  name: 'SPACESHIP SUPER',
  uniqueSuperIdentifier: '34300938877005',
  australianBusinessNumber: '34300938877',
  uniqueSuperIdentifierSFTActive: '43905581638020',
  australianBusinessNumberSFTActive: '43905581638',
};

export const CONTRIBUTIONS_DETAILS = {
  BSB: '105-025',
  ACCOUNT_NUMBER: '64266140',
  // BPAY
  BILLER_CODE: '439216',
};

// We dismiss the supermatch green id card for 2 weeks since we check last
// check date < 7 and supermatch verification is required again in 2 years
export const SUPERMATCH_GREENID_SUCCESS_NOTIFICATION_DISMISS_TIME_IN_MS = 1209600000;

export enum SuperEmployerContributionShareOption {
  EMPLOYER = 'employer',
  USER = 'user',
}

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: Gender.Enum.MALE,
  },
  {
    label: 'Female',
    value: Gender.Enum.FEMALE,
  },
  {
    label: 'Other',
    value: Gender.Enum.UNSPECIFIED,
  },
];

export const SUPER_DOCUMENTS = [
  {
    label: 'Product Disclosure Statement',
    link: ExternalRoutes.SUPER_PDS,
  },
  {
    label: 'Reference Guide',
    link: ExternalRoutes.SUPER_REFERENCE_GUIDE,
  },
  {
    label: 'Financial Services Guide',
    link: ExternalRoutes.SUPER_FSG,
  },
  {
    label: 'Privacy Collection Notice & Privacy Policy',
    link: InternalRoutes.PRIVACY_POLICY,
  },
  {
    label: 'App Terms & Conditions',
    link: InternalRoutes.APP_TERMS,
  },
  {
    label: 'Other Important Documents',
    link: InternalRoutes.IMPORTANT_DOCUMENTS,
  },
];
