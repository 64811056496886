import { RouteComponentProps, useNavigate } from '@reach/router';
import { AuthError } from '@spaceship-fspl/auth';
import {
  ActionLink,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useMigrateSuperWebUser } from '@spaceship-fspl/data';
import { useSaverPortfolioFlags } from '@spaceship-fspl/graphql';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { FeatherHelpCircleIcon } from '@spaceship-fspl/icons-web';
import {
  AmplifyAuthApiError,
  ApiError,
  useAmplifyAuth,
} from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  AccountCard,
  AccountCardContent,
} from 'components/account-cards/components';
import { Button } from 'components/button';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import { PageContainer } from 'components/layouts/page';
import { ResourceItemProps, ResourceList } from 'components/resource-list';
import { RouterLink } from 'components/router-link';
import { useLogin } from 'contexts/login-provider';
import { useNotifications } from 'contexts/notifications';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { HttpStatusCode } from 'helpers/status-codes';
import { requiredValidation } from 'helpers/validation';
import { navigateAwayWhenAuthenticated } from 'navigation/helpers';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

export const Login: React.FC<React.PropsWithChildren<RouteComponentProps>> =
  navigateAwayWhenAuthenticated()(() => {
    const navigate = useNavigate();
    const track = useTrack();
    const login = useLogin();
    const { login: superWebLogin, logout: superWebLogout } = useAmplifyAuth();
    const { mutateAsync: migrateSuperWebUser } = useMigrateSuperWebUser();
    const flagsData = useSaverPortfolioFlags();

    const notifications = useNotifications();
    const [loading, setLoading] = useState(false);
    const { handleSubmit, setValue, control } = useForm<{
      email: string;
      password: string;
    }>({
      shouldFocusError: true,
    });

    const otherLoginItems: Array<ResourceItemProps> = [
      {
        label: 'Important documents',
        link: InternalRoutes.IMPORTANT_DOCUMENTS,
      },
      {
        label: 'FAQs',
        link: InternalRoutes.FAQS,
      },
    ];

    const showErrorMessage = (
      isInvalidCreds: boolean,
      requestId: string,
    ): void => {
      notifications.popToast({
        requestId,
        level: 'warning',
        message: isInvalidCreds
          ? 'Your login failed. Please check your details and try again.'
          : GENERIC_ERROR_MESSAGE,
      });
    };

    const onLogin = handleSubmit(async ({ email, password }) => {
      let amplifyAuthToken;

      setLoading(true);

      try {
        await superWebLogout();
        await login.submitCredentials(email, password);
      } catch (error) {
        addRumError({ error });

        const authError = error as AuthError;

        if (authError?.message?.includes('too many verification attempts')) {
          navigate(Routes.LOGIN_CHALLENGE_TOO_MANY_ATTEMPTS);
          return;
        }

        const isSpaceshipUnauthorized = authError.isSpaceshipUnauthorized;
        if (isSpaceshipUnauthorized) {
          try {
            // Spaceship login failed - attempt cognito login for super web users
            amplifyAuthToken = await superWebLogin(email, password);

            if (!amplifyAuthToken) {
              throw Error;
            }
          } catch (error) {
            addRumError({ error });

            const amplifyAuthError = error as AmplifyAuthApiError | undefined;
            if (amplifyAuthError?.isPasswordResetRequired) {
              setValue('password', '');
              navigate(Routes.PASSWORD_RESET);
            } else {
              showErrorMessage(
                amplifyAuthError?.isEmailOrPasswordInvalid || false,
                authError.requestId,
              );
            }

            setLoading(false);
            return;
          }

          try {
            // Cognito login successful - create + link user to Spaceship
            await migrateSuperWebUser({
              cognito_access_token: amplifyAuthToken,
              password,
              lead_utm_source: 'migrated_from_web',
            });
          } catch (error) {
            addRumError({ error });

            showErrorMessage(
              (error as ApiError | undefined)?.httpStatusCode ===
                HttpStatusCode.CONFLICT_409,
              authError.requestId,
            );
            setLoading(false);
            return;
          }

          try {
            // Try Spaceship login again
            await login.submitCredentials(email, password);
          } catch (error) {
            addRumError({ error });
            const authError = error as AuthError;

            showErrorMessage(
              authError.isSpaceshipUnauthorized,
              authError.requestId,
            );
          }
        } else if (authError.isContactSupport) {
          notifications.popToast({
            requestId: authError.requestId,
            level: 'warning',
            message: authError.message || GENERIC_ERROR_MESSAGE,
          });
        } else if (authError.isMfaRateLimited) {
          notifications.popToast({
            requestId: authError.requestId,
            level: 'warning',
            message: authError.message,
          });
        } else {
          showErrorMessage(isSpaceshipUnauthorized, authError.requestId);
        }
      }
      setLoading(false);
    });

    useEffect(() => {
      track?.(MarketingTrackingEvents.LOGIN_START);
    }, [track]);

    const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
    const createProductInstanceAvailable =
      saverPortfolioFlags?.createProductInstanceAvailable ?? true;

    return (
      <PageContainer>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
            <form onSubmit={onLogin}>
              <Stack spaceY="xxl">
                <Heading variant={2} align="center">
                  Welcome back
                </Heading>

                <Stack spaceY="xxs">
                  <Stack spaceY="md">
                    <ControllerInput
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Email address"
                      control={control}
                    />

                    <ControllerPasswordInput
                      name="password"
                      control={control}
                      autoComplete="current-password"
                      placeholder="Password"
                      rules={{
                        ...requiredValidation('Password'),
                      }}
                    />
                  </Stack>

                  <RouterLink
                    to={Routes.PASSWORD_RESET}
                    trackingProperties={{
                      name: 'login_page_forgot_password',
                    }}
                  >
                    <ActionLink color="indigo.070" component="span">
                      Forgot password?
                    </ActionLink>
                  </RouterLink>
                </Stack>

                <Stack spaceY="xl">
                  <Stack alignX="center" spaceY="lg">
                    <Stack alignX="center" spaceY="md">
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        trackingProperties={{ name: 'login_submit' }}
                        isLoading={loading}
                      >
                        Log in
                      </Button>

                      <Text variant={4} color="neutral.080" align="center">
                        If you have an account but haven’t set up a password,
                        select ‘Forgot password?’
                      </Text>
                    </Stack>

                    {createProductInstanceAvailable && (
                      <Text
                        variant={3}
                        color="neutral.080"
                        isBold={true}
                        align="center"
                      >
                        New to Spaceship?&nbsp;
                        <RouterLink
                          to={Routes.SIGNUP}
                          trackingProperties={{ name: 'login_page_signup' }}
                        >
                          <TextLink color="indigo.070" component="span">
                            Sign up
                          </TextLink>
                        </RouterLink>
                      </Text>
                    )}
                  </Stack>
                  {!createProductInstanceAvailable && (
                    <AccountCard>
                      <Stack spaceY="md">
                        <Stack spaceY="xs" alignX="center">
                          <Text variant={2} align="center">
                            Spaceship has temporarily suspended the ability for
                            new customers to sign up to the Spaceship products.
                          </Text>

                          <Inline spaceX="xxs" alignY="center">
                            <TextLink
                              href={
                                InternalRoutes.ASIC_STOP_ORDER_LIFTED_MESSAGE_JUNE_2023
                              }
                              target="_blank"
                              rel="noreferrer"
                              color="indigo.070"
                            >
                              Learn more
                            </TextLink>
                            <FeatherHelpCircleIcon
                              color="indigo.070"
                              size="md"
                            />
                          </Inline>
                        </Stack>

                        <AccountCardContent
                          padding={{
                            paddingX: { xs: 'md', md: 'lg' },
                            paddingY: { xs: 'xs', md: 'sm' },
                          }}
                        >
                          <ResourceList
                            isBold={true}
                            resources={otherLoginItems}
                          />
                        </AccountCardContent>
                      </Stack>
                    </AccountCard>
                  )}
                </Stack>
              </Stack>
            </form>
          </Columns.Column>
        </Columns>
      </PageContainer>
    );
  });
