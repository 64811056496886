import { RouteComponentProps, Router } from '@reach/router';
import {
  SuperMatchConsolidateProvider,
  SuperMatchOnboardingProvider,
  useSuperMatch,
} from 'contexts/super/super-match';
import { FeatureFlagKeys } from 'helpers/dynamic-config';
import { PageNotFound } from 'pages/page-not-found';
import { SuperUpdateTfn } from 'pages/super/tfn';
import { SuperMatchConsent } from 'pages/super-match/consent';
import { SuperMatchError } from 'pages/super-match/error';
import { SuperMatchResults } from 'pages/super-match/results';
import { SuperMatchSuccess } from 'pages/super-match/success';
import { SuperMatchTerms } from 'pages/super-match/terms';
import React from 'react';

import {
  SuperMatchConsolidateGreenID,
  SuperMatchOnboardingGreenID,
} from './greenid';
import { FeatureFlaggedRoute } from './helpers';

const SuperMatchRouter: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { variant } = useSuperMatch();

  return (
    <Router>
      <SuperMatchConsent path="/" />
      <SuperUpdateTfn
        path="tax-file-number"
        isOnboarding={variant === 'onboarding'}
      />
      <SuperMatchResults path="results" />
      <SuperMatchTerms path="terms" />
      <SuperMatchSuccess path="success" />
      <SuperMatchError path="error" />
      <PageNotFound default={true} />
    </Router>
  );
};

export const SuperMatchOnboarding: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <SuperMatchOnboardingProvider>
      <Router>
        <FeatureFlaggedRoute
          path="/*"
          flag={FeatureFlagKeys.SUPERMATCH_ENABLED}
          route={SuperMatchRouter}
        />
        <SuperMatchOnboardingGreenID path="id-verification/*" />
      </Router>
    </SuperMatchOnboardingProvider>
  );
};

export const SuperMatchConsolidate: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <SuperMatchConsolidateProvider>
      <Router>
        <FeatureFlaggedRoute
          path="/*"
          flag={FeatureFlagKeys.SUPERMATCH_ENABLED}
          route={SuperMatchRouter}
        />
        <SuperMatchConsolidateGreenID path="id-verification/*" />
      </Router>
    </SuperMatchConsolidateProvider>
  );
};
