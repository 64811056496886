import { voyagerReferralsCardFields } from '@spaceship-fspl/graphql/src/__generated__/voyagerReferralsCardFields';
import { Routes } from 'pages/routes';
import React from 'react';

import { ReferFriendCard } from './components/refer-friend';

export const ReferralCampaignCard: React.FC<
  React.PropsWithChildren<{
    account: voyagerReferralsCardFields;
    onDismiss: () => void;
    isFullWidth?: boolean;
  }>
> = (props) => {
  const receiveDetails = props.account?.saverReferralReceiveDetails;
  const receiveCampaign = receiveDetails?.campaign;
  const receiveStatus = receiveDetails?.status;
  const receiveProductInstance =
    receiveDetails?.rewardDestinationProductInstance;

  const shareDetails = props.account?.saverReferralShareDetails;
  const shareCampaign = shareDetails?.campaign;
  const shareStatus = shareDetails?.status;
  const shareProductInstance = shareDetails?.rewardDestinationProductInstance;

  switch (true) {
    // has referred friends and awaiting payment
    case receiveCampaign &&
      receiveProductInstance &&
      receiveStatus === 'AWAITING_PAYMENT': {
      return (
        <ReferFriendCard
          title={`${receiveDetails?.referrerShortName} referred you!`}
          copy={`Thanks to ${receiveDetails?.referrerShortName}, you’ll receive $${receiveCampaign?.audReceiverRewardAmount} in your Spaceship Voyager account once you invest at least $${receiveCampaign?.audReceiverMinimumInvestmentAmount} into your chosen portfolio.`}
          route={`${Routes.VOYAGER_DEPOSIT}/${receiveProductInstance?.id}`}
          onDismiss={props.onDismiss}
        />
      );
    }

    // eligible to refer and referral campaign is ongoing
    case !!shareCampaign && shareStatus === 'ELIGIBLE': {
      return (
        <ReferFriendCard
          title={shareCampaign?.title || ''}
          copy={`Friends make great space travellers — and you can earn $${shareCampaign?.audSharerRewardAmount} for every friend you invite to join Spaceship Voyager.`}
          route={Routes.VOYAGER_REFERRALS}
          onDismiss={props.onDismiss}
        />
      );
    }

    // ineligible to refer and referral campaign is ongoing
    case !!shareProductInstance && !!shareCampaign: {
      return (
        <ReferFriendCard
          title={shareCampaign?.title || ''}
          copy={`You can earn $${shareCampaign?.audSharerRewardAmount} for every friend you invite to join Spaceship Voyager. To get started, you simply need to invest at least $${shareCampaign?.audSharerMinimumInvestmentAmount} into your chosen portfolio. Terms and conditions apply.`}
          route={Routes.VOYAGER_DEPOSIT}
          onDismiss={props.onDismiss}
        />
      );
    }

    default: {
      return null;
    }
  }
};
