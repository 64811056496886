import { Box, Inline, Text } from '@spaceship-fspl/components';
import { TransactionStatus } from '@spaceship-fspl/graphql';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ConfettiShower } from './confetti/shower';
import { TransactionStatusIndicator } from './transactions/status-indicator';

type Variant = 'invest' | 'deposit' | 'withdraw';

interface AnimatedTransactionItemProps {
  variant: Variant;
  title: string;
  amount: string;
  etaDate?: string;
  formatAmountAsNegative?: boolean;
}

export const AnimatedTransactionItem: React.FC<
  React.PropsWithChildren<AnimatedTransactionItemProps>
> = ({ variant, title, amount, etaDate, formatAmountAsNegative }) => {
  const showConfetti = variant === 'deposit' || variant === 'invest';
  return (
    <>
      <Box
        data-testid={TestId.ANIMATED_TRANSACTION_ITEM}
        height={BOX_HEIGHT}
        marginBottom="xxl"
        position="relative"
      >
        {showConfetti && (
          <StyledCardConfettiContainer>
            <ConfettiShower delay={500} />
          </StyledCardConfettiContainer>
        )}

        <StyledSummaryBox variant={variant}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant={3} isBold={true}>
              {title}
            </Text>

            <Text variant={1} isBold={true}>
              {amount
                ? `${formatAmountAsNegative ? '-' : ''}${formatCurrency(amount)}`
                : null}
            </Text>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Inline spaceX="xxxs" alignY="center">
              <TransactionStatusIndicator status={TransactionStatus.PENDING} />
              <Text variant={4} isBold={true} color="neutral.080">
                Submitted
              </Text>
            </Inline>

            {etaDate && (
              <Text
                variant={4}
                isBold={true}
                isItalic={true}
                color="neutral.080"
              >
                ETA {etaDate}
              </Text>
            )}
          </Box>
        </StyledSummaryBox>

        <StyledBackgroundBox />
      </Box>
    </>
  );
};

const StyledCardConfettiContainer = styled.div`
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  z-index: 2;
`;

const BOX_HEIGHT = 98;

const boxAnimationStartState = `
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
`;

const StyledBox = styled(Box).attrs({
  borderRadius: 'sm',
  paddingX: { xs: 'sm', md: 'md' },
  paddingY: 'md',
  height: BOX_HEIGHT,
  width: { xs: 300, md: 340 },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})`
  ${boxAnimationStartState}
  position: absolute;
  top: 50%;
  left: 50%;
`;

const summaryBoxAnimation = keyframes`
  0% {
    ${boxAnimationStartState}
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(-1deg);
  }
`;

const bigMoneyBagAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-5deg);
    left: calc(50% - 24px);
    top: -70px;
  }
  50% {
    opacity: 1;
    transform: rotate(-1deg);
    left: calc(50% - 20px);
    top: -28px;
  }
  75% {
    opacity: 1;
    transform: rotate(7.5deg);
    left: calc(50% - 18px);
    top: -32px;
  }
  100% {
    opacity: 1;
    transform: rotate(13.31deg);
    left: calc(50% - 16px);
    top: -28px;
  }
`;

const smallMoneyBagAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(2deg);
    left: calc(50% - 16px);
    top: -70px;
  }
  50% {
    opacity: 1;
    transform: rotate(1deg);
    left: calc(50% - 18px);
    top: 0px;
  }
  75% {
    opacity: 1;
    transform: rotate(-9deg);
    left: calc(50% - 20px);
    top: -4px;
  }
  100% {
    opacity: 1;
    transform: rotate(-15deg);
    left: calc(50% - 24px);
    top: 0px;
  }
`;

const StyledSummaryBox = styled(StyledBox).attrs({
  backgroundColor: 'neutral.000',
  boxShadow: 'lg',
})<{ variant: Variant }>`
  animation: ${summaryBoxAnimation} 0.3s ease-in 0.2s forwards;
  z-index: 1;

  ${({ variant }) =>
    variant === 'withdraw'
      ? css`
          ::before,
          ::after {
            content: '💰';
            display: block;
            position: absolute;
            opacity: 0;
          }

          ::before {
            animation: ${bigMoneyBagAnimation} 0.45s ease-in 0.4s forwards;
            font-size: 48px;
          }

          ::after {
            animation: ${smallMoneyBagAnimation} 0.45s ease-in 0.45s forwards;
            font-size: 28px;
          }
        `
      : ''}
`;

const backgroundBoxAnimation = keyframes`
  0% {
    ${boxAnimationStartState}
  }
  100% {
    transform: translateX(calc(-50% + 8px)) translateY(-50%) rotate(4deg);
  }
`;

const StyledBackgroundBox = styled(StyledBox).attrs({
  backgroundColor: 'neutral.050',
})`
  animation: ${backgroundBoxAnimation} 0.3s ease-in 0.2s forwards;
  z-index: 0;
`;
