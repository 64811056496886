import * as React from 'react';
export const StreamlineWaterProtectionFish = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1 5.217A5.389 5.389 0 005.4 3.75a4.446 4.446 0 006.6 0 4.447 4.447 0 006.6 0A5.389 5.389 0 0023 5.217M2.544 19.96c4.712-4.711 6.23-7.019 10.486-7.019a8.588 8.588 0 017.04 3.692 8.585 8.585 0 01-7.04 3.692c-4.133 0-5.55-2.084-10.534-7.068M14.933 15.851a.367.367 0 01-.366.367M14.567 15.483a.366.366 0 01.366.367M14.2 15.85a.367.367 0 01.367-.366M14.567 16.218a.367.367 0 01-.367-.367M21.167 13.284a.367.367 0 01-.367-.366M21.533 12.918a.367.367 0 01-.367.366M21.166 12.55a.367.367 0 01.367.367M20.8 12.918a.367.367 0 01.366-.367M19.7 8.884a.367.367 0 01-.366-.366M20.067 8.518a.367.367 0 01-.367.366M19.7 8.15a.367.367 0 01.367.367M19.334 8.518a.367.367 0 01.366-.367" />
  </svg>
);
