import * as React from 'react';
export const StreamlineCashPaymentBills = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M3.171 12.8h2.5a.485.485 0 01.5.523v9.409a.483.483 0 01-.5.523h-2.5" />
    <path vectorEffect="non-scaling-stroke" d="M10.322 10.584L8.4 12.977a1.4 1.4 0 01-1.045.523H6.171M6.171 20.534c2.144 1.625 4.1 2.716 5.363 2.716h6.273c.76 0 1.238-.054 1.568-1.045a63.51 63.51 0 001.046-7.66c0-.522-.523-1.045-1.568-1.045h-5.932M10.554 12.205L9.006 1.373a.546.546 0 01.54-.623H17.1" />
    <path vectorEffect="non-scaling-stroke" d="M13.839 13.5l-.916-9.159a.499.499 0 01.5-.553h6.9a.5.5 0 01.5.577l-1.38 9.2" />
    <path vectorEffect="non-scaling-stroke" d="M16.7 8.621a.375.375 0 11-.379.379.375.375 0 01.375-.375" />
  </svg>
);
