import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  Columns,
  Divider,
  Spinner,
  Stack,
} from '@spaceship-fspl/components';
import { MoneyAvailableCard_MoneyAvailableFragment as MoneyAvailableCard_MoneyAvailableFragmentType } from '@spaceship-fspl/graphql/src/__generated__/MoneyAvailableCard_MoneyAvailableFragment';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { FeatherMinusIcon, FeatherPlusIcon } from '@spaceship-fspl/icons-web';
import { LabeledField } from 'components/labeled-field';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled from 'styled-components';

import { Routes } from '../../routes';

export const MoneyAvailableCard_MoneyAvailableFragment = gql`
  fragment MoneyAvailableCard_MoneyAvailableFragment on Account {
    id
    moneyAvailableAudBalance
    moneyInstance {
      id
      totalAudBalance
      pendingAudOutflows
      pendingAudInflows
    }
  }
`;

export const MoneyAvailableCard: React.FC<{
  account?: MoneyAvailableCard_MoneyAvailableFragmentType | null;
  isLoading?: boolean;
}> = ({ account, isLoading }) => {
  const moneyInstance = account?.moneyInstance;

  if (!Object.hasOwn(moneyInstance ?? {}, 'totalAudBalance') && isLoading) {
    return (
      <Card
        data-testid={TestId.MONEY_AVAILABLE_MONEY_CARD}
        aria-label="money available card"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box paddingY="md" data-testid={TestId.LOADER}>
          <Spinner />
        </Box>
      </Card>
    );
  }

  return (
    <Card
      data-testid={TestId.MONEY_AVAILABLE_MONEY_CARD}
      aria-label="money available card"
      height="100%"
    >
      <Box paddingX="md" paddingY="md">
        <Box width="100%">
          <LabeledField label={'Available money'} size={'lg'}>
            {formatCurrency(account?.moneyAvailableAudBalance)}
          </LabeledField>
        </Box>
        <Box paddingY={'sm'}>
          <Divider />
        </Box>
        <Stack spaceY="md">
          <LabeledField label={'Total money'} size="md">
            {formatCurrency(moneyInstance?.totalAudBalance)}
          </LabeledField>
          <Columns spaceY="md">
            <Columns.Column width={1 / 2}>
              <LabeledField label={'Pending inflows'} size="md">
                {formatCurrency(moneyInstance?.pendingAudInflows)}
              </LabeledField>
            </Columns.Column>
            <Columns.Column width={1 / 2}>
              <LabeledField label={'Pending outflows'} size="md">
                {formatCurrency(moneyInstance?.pendingAudOutflows)}
              </LabeledField>
            </Columns.Column>
          </Columns>
          <Divider />
          <Columns spaceX={{ xs: 'xxs', md: 'xs' }}>
            <Columns.Column width={1 / 2}>
              <MoneyAvailableCardButton
                size="lg"
                variant="primary"
                type="button"
                href={Routes.MONEY_DEPOSIT}
              >
                <FeatherPlusIcon size="md" />
                Add money
              </MoneyAvailableCardButton>
            </Columns.Column>
            <Columns.Column width={1 / 2}>
              <MoneyAvailableCardButton
                size="lg"
                variant="secondary"
                type="button"
                href={Routes.MONEY_WITHDRAW}
              >
                <FeatherMinusIcon size="md" />
                Withdraw
              </MoneyAvailableCardButton>
            </Columns.Column>
          </Columns>
        </Stack>
      </Box>
    </Card>
  );
};

const MoneyAvailableCardButton = styled(Button)`
  min-width: unset;
  width: 100%;
  height: 45px;
  padding-inline: 0.5rem;
  font-size: 0.925rem;
`;
