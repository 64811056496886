import { useApolloClient } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { GET_BIOMETRIC_VERIFICATION_STATUS_QUERY } from '@spaceship-fspl/graphql';
import { BiometricVerificationStatus } from '@spaceship-fspl/graphql/src/__generated__/globalTypes';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import { useState } from 'react';

export const useNavigateWithBiometricVerificationToBAV = (): {
  isLoading: boolean;
  navigate: () => Promise<void>;
} => {
  const isSecurityCheckEnabled = useFeatureFlag(
    FeatureFlagKeys.SECURITY_CHECK_ENABLED,
  );
  const client = useApolloClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { popBanner } = useNotifications();
  const intercom = useIntercom();

  const handleNavigate = async (): Promise<void> => {
    if (isSecurityCheckEnabled) {
      setIsLoading(true);
      const { data } = await client.query({
        query: GET_BIOMETRIC_VERIFICATION_STATUS_QUERY,
        fetchPolicy: 'network-only',
      });
      setIsLoading(false);

      const status = data?.contact?.biometricVerificationStatus;

      switch (status) {
        case BiometricVerificationStatus.VERIFIED: {
          navigate(Routes.ACCOUNT_BANK_ACCOUNT);
          break;
        }

        case BiometricVerificationStatus.IN_PROGRESS:
        case BiometricVerificationStatus.FAILED:
        case BiometricVerificationStatus.UNVERIFIED: {
          navigate(Routes.ACCOUNT_SECURITY_CHECK);
          break;
        }

        default: {
          popBanner({
            message:
              'Oops, we were unable to get your biometric verfication status. Please try again or contact support.',
            cta: {
              message: 'Contact support',
              action: () => intercom.pop(),
            },
          });
        }
      }
    } else {
      navigate(Routes.ACCOUNT_BANK_ACCOUNT);
    }
  };

  return { isLoading, navigate: handleNavigate };
};
