import { Inline, Text } from '@spaceship-fspl/components';
import {
  backgroundColor,
  borderRadius,
  boxShadow,
  rgba,
  transition,
} from '@spaceship-fspl/styles';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasErrors?: boolean;
  label: React.ReactNode;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ hasErrors = false, label, ...props }, ref) => {
    return (
      <StyledLabel>
        <Inline spaceX="xxs" alignY="center">
          <Text variant={3} color="neutral.085" isBold={true} component="div">
            {label}
          </Text>

          <div>
            <StyledInput type="checkbox" ref={ref} {...props} />
            <StyledToggle hasErrors={hasErrors} />
          </div>
        </Inline>
      </StyledLabel>
    );
  },
);

Toggle.displayName = 'Toggle';

const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const TOGGLE_OFFSET = '2px';
const TOGGLE_SIZE = '28px';

const StyledToggle = styled.div<{ hasErrors: boolean }>`
  ${({ hasErrors }) => backgroundColor(hasErrors ? 'red.100' : 'neutral.050')}
  ${borderRadius('sm')}
  height: calc(${TOGGLE_SIZE} + (${TOGGLE_OFFSET} * 2));
  width: calc(${TOGGLE_SIZE} * 2);
  position: relative;
  ${transition}

  ::after {
    content: '';
    display: block;
    ${backgroundColor('neutral.000')}
    box-shadow: 0px 4px 4px ${rgba('black.100', 0.25)};
    border-radius: 50%;
    position: absolute;
    top: ${TOGGLE_OFFSET};
    left: ${TOGGLE_OFFSET};
    height: ${TOGGLE_SIZE};
    width: ${TOGGLE_SIZE};
    ${transition}
  }

  ${StyledInput}:focus + & {
    ${backgroundColor('indigo.020')}
    ${boxShadow('sm')}
  }

  ${StyledInput}:checked + & {
    ${backgroundColor('indigo.070')}

    ::after {
      left: calc(${TOGGLE_SIZE} - ${TOGGLE_OFFSET});
    }
  }
`;
