import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useCreateUserSignupContact } from '@spaceship-fspl/data';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import { api } from '@spaceship-fspl/types/externalapi';
import { ConsentList } from 'components/consent-list';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import { Input } from 'components/input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import {
  useUTMCampaign,
  useVoyagerReferralCode,
  useVoyagerReferrer,
} from 'contexts/deep-linking';
import { useNotifications } from 'contexts/notifications';
import {
  useCreateUserContext,
  useSignupRequestContext,
} from 'contexts/saver/onboarding';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { addRumError } from 'helpers/monitoring';
import {
  commonValidationRules,
  DEFAULT_COUNTRY_CODE,
  requiredValidation,
  voyagerValidationRules,
} from 'helpers/validation';
import { AsYouType } from 'libphonenumber-js';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface ContactForm extends api.external.ICreateLeadRequestBody {
  date_of_birth: string;
  password: string;
}

export const SignupContact: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const { popToast } = useNotifications();
  const [request, setRequest] = useSignupRequestContext();
  const [createUser, setCreateUser] = useCreateUserContext();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createUserSignupContact } = useCreateUserSignupContact();

  const { value: referralCode } = useVoyagerReferralCode();
  const { value: referrerName } = useVoyagerReferrer();
  const { value: UTMCampaignValue, setValue: setUTMCampaignValue } =
    useUTMCampaign();

  const { control, handleSubmit } = useForm<ContactForm>({
    defaultValues: {
      ...request?.createLead,
      date_of_birth: request?.updateLead?.dateOfBirth || '',
      password: createUser?.password || '',
    },
  });

  const onSubmit = handleSubmit(
    async ({ password, date_of_birth, ...createLeadRequestBody }) => {
      setLoading(true);
      try {
        const { contact_id } = await createUserSignupContact({
          ...createLeadRequestBody,
          source: 'web',
          campaign: UTMCampaignValue,
        });
        setUTMCampaignValue(undefined);
        setRequest({
          ...request,
          createLead: createLeadRequestBody,
          updateLead: {
            ...request.updateLead,
            contactId: contact_id,
            dateOfBirth: date_of_birth,
          },
        });
        setCreateUser({
          contact_id,
          password,
        });

        if (contact_id) {
          track?.(MarketingTrackingEvents.SIGNUP_SUBMIT_CONTACT_PAGE);
          await navigate(Routes.SIGNUP_DETAILS);
        } else {
          throw Error('Empty contact id response from createLead');
        }
      } catch (error) {
        const emailExists = (error as Error | undefined)?.message?.includes(
          'email already exists',
        );
        popToast({
          level: 'error',
          message: emailExists
            ? 'An account already exists for this email address.'
            : 'Unable to save your details. Please try again or contact support.',
        });

        if (!emailExists) {
          addRumError({ error });
        }
      }
      setLoading(false);
    },
  );

  useEffect(() => {
    track?.(MarketingTrackingEvents.SIGNUP_START);
  }, [track]);

  return (
    <OnboardingContainer>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <PageHeading
            title={
              referralCode
                ? referrerName
                  ? `${referrerName} has invited you to start investing with Spaceship Voyager`
                  : 'Join your friends investing with Spaceship Voyager'
                : 'Sign up to Spaceship'
            }
            subtitle="Enter your details to get started."
          />

          <Box marginTop="xl">
            <form onSubmit={onSubmit}>
              <Stack spaceY={{ xs: 'sm', md: 'md' }}>
                <ControllerInput
                  name="first_name"
                  control={control}
                  type="text"
                  placeholder="First name"
                  autoComplete="given-name"
                  rules={{
                    required: validation.first_name.required,
                    validate: (value?: string | null): string | boolean => {
                      if (!value) {
                        return false;
                      }
                      return validation.first_name.validate(value);
                    },
                  }}
                />

                <ControllerInput
                  name="last_name"
                  control={control}
                  type="text"
                  placeholder="Last name"
                  autoComplete="family-name"
                  rules={{
                    required: validation.last_name.required,
                    validate: (value?: string | null): string | boolean => {
                      if (!value) {
                        return false;
                      }
                      return validation.last_name.validate(value);
                    },
                  }}
                />

                <ControllerInput
                  name="phone_number"
                  control={control}
                  type="tel"
                  placeholder="Mobile number"
                  autoComplete="tel"
                  rules={{
                    required: validation.phone_number.required,
                    validate: (value?: string | null): boolean | string => {
                      if (!value) {
                        return false;
                      }
                      return validation.phone_number.validate(value, true);
                    },
                    onBlur: (
                      event: React.FocusEvent<HTMLInputElement>,
                    ): void => {
                      event.target.value = new AsYouType(
                        DEFAULT_COUNTRY_CODE,
                      ).input(event.target.value);
                    },
                  }}
                />

                <ControllerInput
                  name="date_of_birth"
                  control={control}
                  type="text"
                  format="date"
                  autoComplete="bday"
                  placeholder="Date of birth (dd-mm-yyyy)"
                  rules={{
                    required: 'Your date of birth is required',
                    ...voyagerValidationRules.dateOfBirth,
                  }}
                />

                <ControllerInput
                  name="email_address"
                  control={control}
                  type="email"
                  placeholder="Email address"
                  autoComplete="email"
                  rules={validation.email_address}
                />

                <Stack spaceY="xxs">
                  <ControllerPasswordInput
                    name="password"
                    control={control}
                    autoComplete="new-password"
                    rules={validation.password}
                  />
                  <Text variant={4} color="neutral.080">
                    Your password must be at least 8 characters long, contain an
                    upper and lowercase character, and a numeric character.
                  </Text>
                </Stack>

                {referralCode && (
                  <Input
                    type="text"
                    name="referral_code"
                    placeholder="Referral code"
                    disabled={true}
                    value={referralCode}
                    tooltip="This is your unique referral code. It’ll be applied automatically when you sign up. This code is valid for 14 days from the date your friend referred you."
                  />
                )}
              </Stack>

              <Box marginTop="lg">
                <Stack spaceY="md">
                  <Heading variant={5}>
                    By clicking &ldquo;Create account&rdquo; you confirm:
                  </Heading>

                  <ConsentList>
                    <li>
                      You have read the{' '}
                      <TextLink
                        target="_blank"
                        rel="noreferrer"
                        href={InternalRoutes.FINANCIAL_SERVICES_GUIDE}
                      >
                        Spaceship Financial Services Guide
                      </TextLink>
                    </li>

                    <li>
                      You have read and agree to{' '}
                      <TextLink
                        rel="noreferrer"
                        target="_blank"
                        href={InternalRoutes.PRIVACY_POLICY}
                      >
                        Privacy Collection Notice
                      </TextLink>{' '}
                      (which describes how Spaceship handles your personal
                      information) and the{' '}
                      <TextLink
                        rel="noreferrer"
                        target="_blank"
                        href={InternalRoutes.APP_TERMS}
                      >
                        App Terms &amp; Conditions
                      </TextLink>
                      .
                    </li>
                  </ConsentList>
                </Stack>
              </Box>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  isLoading={loading}
                  trackingProperties={{
                    name: 'signup_create_account_submit',
                  }}
                >
                  Continue
                </PageFormContinueButton>
              </PageFormButtonContainer>
            </form>
          </Box>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

const validation = {
  first_name: commonValidationRules.name('First name'),
  last_name: commonValidationRules.name('Last name'),
  email_address: requiredValidation('Email address'),
  phone_number: {
    required: 'Mobile number is required',
    ...commonValidationRules.phoneNumber,
  },
  password: commonValidationRules.password,
};
