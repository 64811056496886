import {
  Box,
  Card,
  Heading,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { FeatherArrowRightIcon, FeatherXIcon } from '@spaceship-fspl/icons-web';
import {
  getSpace,
  linearGradient,
  match,
  transition,
} from '@spaceship-fspl/styles';
import backgroundImage from 'assets/s8-referrals-bg.svg';
import { RouterLink } from 'components/router-link';
import React from 'react';
import styled from 'styled-components';

export const ReferFriendCard: React.FC<
  React.PropsWithChildren<{
    onDismiss: () => void;
    title: string;
    copy: string;
    route: string;
  }>
> = (props) => {
  return (
    <StyledCard
      postion="relative"
      height="100%"
      minHeight={{ xs: 330, md: 200 }}
    >
      <StyledDismissButton onClick={props.onDismiss}>
        <FeatherXIcon color="neutral.030" />
      </StyledDismissButton>
      <RouterLink
        to={props.route}
        trackingProperties={{ name: 'referral_campaign_card_link' }}
      >
        <Box padding="lg" width={{ md: '60%', lg: '40%' }}>
          <Stack spaceY="xs" alignX={{ xs: 'center', md: 'left' }}>
            <Stack spaceY="xxxs">
              <Heading
                variant={4}
                color="neutral.000"
                isBold={true}
                align={{ xs: 'center', md: 'left' }}
              >
                {props.title}
              </Heading>
              <Text
                variant={2}
                color="neutral.000"
                align={{ xs: 'center', md: 'left' }}
              >
                {props.copy}
              </Text>
            </Stack>

            <Box display="flex" alignItems="center">
              <Text variant={2} color="neutral.000" isBold={true}>
                Refer a friend
              </Text>
              <Box marginLeft="xxs">
                <FeatherArrowRightIcon color="neutral.000" />
              </Box>
            </Box>
          </Stack>
        </Box>
      </RouterLink>
    </StyledCard>
  );
};

const StyledCard = styled(Card)<{ isFullWidth?: boolean }>`
  background: url(${backgroundImage}),
    ${linearGradient({
      angle: '149.84deg',
      colorStops: [
        ['indigo.050', '-58.77%'],
        ['indigo.100', '118.07%'],
      ],
    })};

  background-position: right 28% bottom -5%;
  background-repeat: no-repeat;

  background-size: 130%, 100%;

  ${match('md')`
    background-position: left 480% bottom 0%;
    background-size: 95%, 100%;
  `};

  ${match('lg')`
    background-position: right -5% bottom 10%;
    background-size: 70%, 100%;
  `};

  ${match('xl')`
    background-position: right -5% bottom 5%;
    background-size: 60%, 100%;
  `};
`;

const StyledDismissButton = styled(UnstyledButton)`
  position: absolute;
  top: ${getSpace('sm')};
  right: ${getSpace('sm')};
  opacity: 0.4;
  ${transition}

  &:hover {
    opacity: 1;
  }
`;
