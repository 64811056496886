import * as React from 'react';
export const StreamlineSatellite1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M4.834 14.12a3.568 3.568 0 015.043 5.043L4.834 14.12zM19.03 9.005a2.854 2.854 0 00-.366-4.348 2.96 2.96 0 00-3.778.42l-5.51 5.51a1.427 1.427 0 000 2.019l2.018 2.019a1.427 1.427 0 002.018 0l5.619-5.62zM20.546 3.454l-1.514 1.514M5.577 19.432a1.428 1.428 0 01-1.009-1.021M8.209 22.512a5.043 5.043 0 01-6.713-6.735M14.422 13.615l2.018 2.018M14.926 17.148l3.028-3.028L23 19.166l-3.028 3.028-5.046-5.046zM10.385 9.578L8.367 7.56M1.807 4.028L4.834 1 9.88 6.046 6.853 9.074 1.807 4.028z" />
  </svg>
);
