import { Box } from '@spaceship-fspl/components';
import React from 'react';
import styled from 'styled-components';

import * as Animate from './animate';
import { Shape } from './shape';

export interface ConfettiShowerProps {
  height?: number;
  delay?: number;
}

export const ConfettiShower: React.FC<
  React.PropsWithChildren<ConfettiShowerProps>
> = ({ height = 170, delay = 0 }) => {
  return (
    <StyledBox height={height} width={480}>
      <Animate.Container delay={delay}>
        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={2}
          color="indigo.020"
          duration={2100}
          delay={500}
          left={15}
        >
          <Shape variant="triangle" height={6.5} width={6.18} />
        </Animate.AnimateRight>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={2}
          color="red.030"
          duration={2100}
          delay={400}
          left={20}
        >
          <Shape variant="rectangle" height={21.48} width={3.27} />
        </Animate.AnimateLeft>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={3}
          color="gold.050"
          duration={2000}
          delay={450}
          left={25}
        >
          <Shape variant="star" height={18} width={18} />
        </Animate.AnimateLeft>

        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={3}
          color="indigo.020"
          duration={2000}
          delay={300}
          left={30}
        >
          <Shape variant="rectangle" height={21.57} width={3.26} />
        </Animate.AnimateRight>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={1}
          duration={1700}
          left={40}
        >
          <Shape variant="emoji" height={28} width={28} />
        </Animate.AnimateLeft>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={3}
          color="indigo.050"
          duration={1800}
          delay={800}
          left={45}
        >
          <Shape variant="circle" height={8.76} width={8.15} />
        </Animate.AnimateLeft>

        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={1}
          color="indigo.020"
          duration={2000}
          delay={600}
          left={49}
        >
          <Shape variant="rectangle" height={19.65} width={2.72} />
        </Animate.AnimateRight>

        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={4}
          color="gold.030"
          duration={1900}
          delay={100}
          left={55}
        >
          <Shape variant="circle" height={5.09} width={5.47} />
        </Animate.AnimateRight>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={4}
          color="indigo.020"
          duration={1900}
          left={60}
        >
          <Shape variant="rectangle" height={29.88} width={2.17} />
        </Animate.AnimateLeft>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={2}
          color="pink.050"
          duration={2200}
          delay={900}
          left={65}
        >
          <Shape variant="star" height={17} width={17} />
        </Animate.AnimateLeft>

        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={1}
          duration={1800}
          delay={700}
          left={69}
        >
          <Shape variant="emoji" height={28} width={28} />
        </Animate.AnimateRight>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={5}
          color="red.030"
          duration={2000}
          delay={900}
          left={73}
        >
          <Shape variant="rectangle" height={31.63} width={2.05} />
        </Animate.AnimateLeft>

        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={3}
          color="indigo.020"
          duration={2000}
          delay={200}
          left={77}
        >
          <Shape variant="triangle" height={15.76} width={13.83} />
        </Animate.AnimateRight>

        <Animate.AnimateLeft
          movementSize="sm"
          movementVariant={1}
          color="indigo.050"
          duration={2100}
          delay={800}
          left={80}
        >
          <Shape variant="triangle" height={16.6} width={15.13} />
        </Animate.AnimateLeft>

        <Animate.AnimateRight
          movementSize="sm"
          movementVariant={2}
          color="blue.050"
          duration={2100}
          delay={300}
          left={85}
        >
          <Shape variant="star" height={20} width={20} />
        </Animate.AnimateRight>
      </Animate.Container>
    </StyledBox>
  );
};

const StyledBox = styled(Box).attrs({
  position: 'relative',
})`
  pointer-events: none;
`;
