import * as React from 'react';
export const StreamlineTaxiWaveBusinessMan = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M10 3.5a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0v0zM15 19v-.75c0-.597.264-1.17.732-1.591a2.648 2.648 0 011.768-.66c.663 0 1.299.238 1.768.66.469.422.732.994.732 1.59V19" />
    <path vectorEffect="non-scaling-stroke" d="M13 23v-3.334c0-.176.08-.346.22-.471a.8.8 0 01.53-.195h7.5a.8.8 0 01.53.195c.141.125.22.295.22.471V23" />
    <path vectorEffect="non-scaling-stroke" d="M9.75 23V11.289L2.434 3.482a1.457 1.457 0 01-.332-.494 1.397 1.397 0 01.045-1.148c.088-.178.214-.338.37-.47.154-.13.336-.23.533-.294a1.618 1.618 0 011.205.09c.185.091.348.217.48.37l4.787 5.11c.289.31.644.557 1.041.726.397.17.828.257 1.263.257h2.549c1.227 0 2.403.463 3.27 1.287A4.282 4.282 0 0119 12.02v2.196c0 .388-.162.76-.451 1.035-.29.274-.682.428-1.09.428-.41 0-.802-.154-1.09-.428a1.427 1.427 0 01-.452-1.035V12.02" />
  </svg>
);
