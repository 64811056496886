import { gql, useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { useCanReadSaver } from '@spaceship-fspl/auth';
import { Box, Text } from '@spaceship-fspl/components';
import { WebAppVoyagerPortfolioNavigation } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerPortfolioNavigation';
import { FeatherChevronDownIcon } from '@spaceship-fspl/icons-web';
import {
  borderColor,
  borderRadius,
  borderWidth,
  color,
  getSpace,
  heading,
  paddingLeft,
  paddingRight,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { Tooltip } from 'components/tooltip';
import { useAuthenticatedNav } from 'contexts/authenticated-nav';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { AUTH_VOYAGER_TOP_NAV_ZINDEX } from './authenticated';

export interface VoyagerTopNavigationProps {
  productId?: string;
  location?: {
    state: {
      isRegisteredNewPortfolio?: boolean;
    };
  };
}

export const VoyagerTopNavigation: React.FC<
  React.PropsWithChildren<VoyagerTopNavigationProps>
> = ({ productId, location }) => {
  const isRegisteredNewPortfolio = !!location?.state?.isRegisteredNewPortfolio;
  const { selectVoyagerProduct } = useAuthenticatedNav();
  const { pathname } = useLocation();
  const canReadSaver = useCanReadSaver();
  const resp = useQuery<WebAppVoyagerPortfolioNavigation>(
    gql`
      query WebAppVoyagerPortfolioNavigation {
        contact {
          id
          account {
            id
            saverProductInstances {
              id
              portfolio
              portfolioInformation {
                id
                title
              }
            }
          }
        }
      }
    `,
    { skip: !canReadSaver },
  );

  const productInstances = resp?.data?.contact?.account?.saverProductInstances;
  const currentPortfolios = useMemo(
    () =>
      productInstances?.map((pi) => ({
        id: pi.id,
        title: pi.portfolioInformation?.title,
      })) ?? [],
    [productInstances],
  );
  const showHelperTooltip =
    isRegisteredNewPortfolio && currentPortfolios.length === 2;

  if (currentPortfolios.length < 2 || !productId) {
    return null;
  }

  const onProductChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const newProductId = event.target.value;
    selectVoyagerProduct(newProductId, {
      fullPath: pathname.replace(productId, newProductId),
    });
  };

  return (
    <>
      <Box height={64} />

      <StyledNavBox
        height={64}
        backgroundColor="neutral.030"
        boxShadow="md"
        position="fixed"
        top={0}
        left={0}
        right={0}
      >
        <StyledNav aria-label="Voyager top navigation">
          <Box position="relative">
            <HelperTooltip isShow={showHelperTooltip}>
              <StyledSelect value={productId} onChange={onProductChange}>
                {currentPortfolios.map(({ id, title }) => (
                  <option value={id} key={id}>
                    {title}
                  </option>
                ))}
              </StyledSelect>
            </HelperTooltip>

            <StyledIconBox position="absolute">
              <FeatherChevronDownIcon size="md" color="neutral.100" />
            </StyledIconBox>
          </Box>
        </StyledNav>
      </StyledNavBox>
    </>
  );
};

interface HelperTooltipProps {
  isShow: boolean;
}

const HelperTooltip: React.FC<React.PropsWithChildren<HelperTooltipProps>> = ({
  children,
  isShow,
}) => {
  if (isShow) {
    return (
      <Tooltip
        content={
          <Box paddingX="xxs" paddingY="xxxs">
            <Text variant={2} color="neutral.000" isBold={true} align="center">
              Switch between your portfolios here
            </Text>
          </Box>
        }
        borderRadius="xxs"
        maxWidth={350}
        placement="bottom"
        showOnCreate={true}
      >
        <div>{children}</div>
      </Tooltip>
    );
  }

  return <>{children}</>;
};

const StyledNavBox = styled(Box)`
  z-index: ${AUTH_VOYAGER_TOP_NAV_ZINDEX};
`;

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledSelect = styled.select`
  ${transition}
  appearance: none;
  background-color: transparent;
  outline: none;
  ${color('neutral.100')};
  ${heading({ variant: 4 })}
  font-weight: 700;
  ${paddingLeft('md')}
  ${paddingRight('xl')}
  ${paddingY('xxs')}
  ${borderRadius('xs')};
  border-color: transparent;
  border-style: solid;
  ${borderWidth('md')}

  :hover {
    ${borderColor('indigo.020')}
  }

  ::-ms-expand {
    display: none;
  }
`;

const StyledIconBox = styled(Box)`
  top: 50%;
  right: ${getSpace('sm')};
  transform: translateY(-50%);
`;
