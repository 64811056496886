import * as React from 'react';
export const StreamlineWaterStraw = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M17.136 21.655A1.467 1.467 0 0115.674 23h-8.81a1.467 1.467 0 01-1.462-1.345L3.202 7.66a.736.736 0 01.734-.794H18.42a.733.733 0 01.73.794l-2.015 13.994z" />
    <path vectorEffect="non-scaling-stroke" d="M11.269 19.333l3.194-15.975A2.934 2.934 0 0117.34 1h3.462" />
    <path vectorEffect="non-scaling-stroke" d="M18.233 14.033a3.671 3.671 0 01-4.03-1.3 3.667 3.667 0 01-5.868 0A3.669 3.669 0 014.2 13.998" />
  </svg>
);
