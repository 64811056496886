import { RouteComponentProps, useNavigate } from '@reach/router';
import { ContributionTypeEnum } from '@sargon/api-client';
import {
  Accordion,
  Box,
  Card,
  Columns,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FeatherAlertTriangleIcon,
  FeatherCopyIcon,
  FeatherInfoIcon,
} from '@spaceship-fspl/icons-web';
import { FeatherArrowUpRightIcon } from '@spaceship-fspl/icons-web';
import { width } from '@spaceship-fspl/styles';
import {
  CONTRIBUTIONS_DETAILS,
  useGetMember,
  useHasSuppliedTfn,
  useUserAccess,
} from '@spaceship-fspl/super';
import { Error } from 'components/layouts/error';
import { PageContainer } from 'components/layouts/page';
import { Radio } from 'components/radio';
import { SuperTaxFileNumberCard } from 'components/super-tax-file-number-card';
import { useNotifications } from 'contexts/notifications';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { copyTextToClipboard } from 'helpers/utils';
import { Routes } from 'pages/routes';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

const contributionTypeLabel = {
  [ContributionTypeEnum.NON_CONCESSIONAL]: 'Personal',
  [ContributionTypeEnum.SPOUSAL]: 'Spouse',
  [ContributionTypeEnum.OTHER]: 'Other',
};

const ExternalLink = styled(TextLink)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const SuperVoluntaryContribution: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const bpayBillerCode = useString(
    DynamicConfigKeys.SUPER_BPAY_BILLER_CODE,
    CONTRIBUTIONS_DETAILS.BILLER_CODE,
  );
  const hasSuppliedTfn = useHasSuppliedTfn();
  const { canVoluntaryContribute } = useUserAccess();
  const { data: member, isLoading, error } = useGetMember();
  const { control, watch } = useForm({
    defaultValues: {
      contributionType: ContributionTypeEnum.NON_CONCESSIONAL,
    },
  });
  const navigate = useNavigate();
  const { popToast } = useNotifications();
  const contributionType = watch('contributionType');
  const referenceNumber = member?.bpayCrn?.find(
    (crn) => crn && crn.type === contributionType,
  )?.crn;

  if (error) {
    return (
      <Error
        title="Make a contribution"
        subtitle={GENERIC_ERROR_MESSAGE}
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'create_product_instance_not_available_go_back',
          },
        }}
      />
    );
  }
  return (
    <PageContainer>
      <Stack spaceY="xl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, lg: 6 / 12 }}>
            <Stack spaceY="lg">
              <Stack spaceY="md">
                <Stack alignX="center" spaceY="sm">
                  <Heading variant={3} component="h1">
                    Make a contribution
                  </Heading>
                  <Text variant={3} isBold={true}>
                    SUPER MEMBER NUMBER: {member?.impactId}
                  </Text>
                </Stack>
                <Text variant={2}>
                  You can boost your super balance by making after-tax
                  contributions to your Spaceship Super account using the BPAY
                  details below.
                </Text>
              </Stack>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  data-testid="spinner"
                >
                  <Spinner />
                </Box>
              ) : (
                <>
                  {!hasSuppliedTfn && (
                    <SuperTaxFileNumberCard
                      copy="Provide your Tax File Number (TFN) to avoid paying more taxes than necessary on certain types of super contributions."
                      onClick={() => {
                        navigate(Routes.SUPER_TFN);
                      }}
                    />
                  )}

                  {!canVoluntaryContribute && (
                    <Card>
                      <Box padding="lg">
                        <Columns alignX="center">
                          <Columns.Column width={{ xs: 1, lg: 2 / 3 }}>
                            <Stack spaceY="xs" alignX="center">
                              <FeatherAlertTriangleIcon color="indigo.070" />
                              <Text variant={2} isBold={true} align="center">
                                You cannot make voluntary contributions until
                                your account is active.
                              </Text>
                            </Stack>
                          </Columns.Column>
                        </Columns>
                      </Box>
                    </Card>
                  )}
                  {canVoluntaryContribute && hasSuppliedTfn && (
                    <Card>
                      <Box
                        paddingX="md"
                        paddingY="sm"
                        data-testid="super_voluntary_contribution_type_card"
                      >
                        <Box marginY="sm">
                          <Text variant={2} isBold={true}>
                            Select contribution type
                          </Text>
                        </Box>
                        <Controller
                          control={control}
                          name="contributionType"
                          render={({ field }) => (
                            <>
                              <Divider />
                              <Box marginY="sm">
                                <Radio
                                  {...field}
                                  name="personal"
                                  value={ContributionTypeEnum.NON_CONCESSIONAL}
                                  checked={
                                    field.value ===
                                    ContributionTypeEnum.NON_CONCESSIONAL
                                  }
                                  data-testid="super_voluntary_contribution_personal_radio"
                                  radioPosition="right"
                                >
                                  <Text variant={2}>Personal contribution</Text>
                                </Radio>
                              </Box>
                              <Divider />
                              <Box marginY="sm">
                                <Radio
                                  {...field}
                                  name="spouse"
                                  value={ContributionTypeEnum.SPOUSAL}
                                  checked={
                                    field.value === ContributionTypeEnum.SPOUSAL
                                  }
                                  data-testid="super_voluntary_contribution_spouse_radio"
                                  radioPosition="right"
                                >
                                  <Text variant={2}>Spouse contribution</Text>
                                </Radio>
                              </Box>
                              <Divider />
                              <Box marginY="sm">
                                <Radio
                                  {...field}
                                  name="other"
                                  value={ContributionTypeEnum.OTHER}
                                  checked={
                                    field.value === ContributionTypeEnum.OTHER
                                  }
                                  data-testid="super_voluntary_contribution_other_radio"
                                  radioPosition="right"
                                >
                                  <Text variant={2}>Other contribution</Text>
                                </Radio>
                              </Box>
                            </>
                          )}
                        />
                      </Box>
                    </Card>
                  )}
                  {contributionType === ContributionTypeEnum.OTHER && (
                    <Box data-testid="volutary-contribution-other-copy">
                      <Stack spaceY={'sm'}>
                        <Box>
                          <Text variant={2} isBold>
                            When to choose ‘other’
                          </Text>
                        </Box>
                        <Box>
                          <Text variant={2}>
                            You should select ‘other’ when you want to make a
                            CGT contribution, downsizer contribution, member
                            third party contribution or child contribution.
                          </Text>
                        </Box>
                        <Box>
                          <Text variant={2} isBold>
                            Once the payment is made, please complete the below
                            form and email it back to us.{' '}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <ExternalLink
                            color="indigo.070"
                            rel="noreferrer"
                            href={
                              ExternalRoutes.SPACESHIP_SUPER_THIRD_PARTY_CONTRIBUTION_FORM
                            }
                            target="_blank"
                          >
                            Super contribution form
                            <FeatherArrowUpRightIcon />
                          </ExternalLink>
                        </Box>
                        <Box>
                          <Divider />
                        </Box>
                      </Stack>
                    </Box>
                  )}
                  {canVoluntaryContribute && hasSuppliedTfn && (
                    <>
                      <Card>
                        <Box
                          paddingY="sm"
                          paddingX="md"
                          display="flex"
                          flexDirection="row"
                          backgroundColor="indigo.015"
                        >
                          <Box>
                            <FeatherInfoIcon size="md" color="neutral.100" />
                          </Box>
                          <Box paddingLeft="sm">
                            <Text variant={3} color="neutral.100">
                              Each contribution type has a different BPAY
                              reference number. Please check the details are
                              correct before making your contribution.
                            </Text>
                          </Box>
                        </Box>
                      </Card>
                      <Card backgroundColor="misc.bpay">
                        <Box
                          paddingX="md"
                          paddingY="sm"
                          data-testid="super_voluntary_contribution_bpay_card"
                        >
                          <Box marginY="sm">
                            <Text variant={2} isBold={true} color="neutral.000">
                              {contributionTypeLabel[contributionType]}{' '}
                              contributions
                            </Text>
                          </Box>
                          <Divider />
                          <Box paddingY="md" flexDirection="row" display="flex">
                            <Box
                              flex={1}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Box>
                                <Text variant={3} color="neutral.070" isBold>
                                  Biller name
                                </Text>
                              </Box>
                              <Box paddingTop="xxs">
                                <Text variant={1} color="neutral.000" isBold>
                                  Spaceship Super
                                </Text>
                              </Box>
                            </Box>
                            {/* eslint-disable-next-line @typescript-eslint/no-require-imports */}
                            <BpayImage src={require('assets/bpay.png')} />
                          </Box>
                          <Divider />
                          <Box
                            paddingY="md"
                            flexDirection="row"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              flex={1}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Box>
                                <Text variant={3} color="neutral.070" isBold>
                                  Biller code
                                </Text>
                              </Box>
                              <Box paddingTop="xxs">
                                <Text variant={1} color="neutral.000" isBold>
                                  {bpayBillerCode}
                                </Text>
                              </Box>
                            </Box>
                            <Box
                              paddingRight="md"
                              onClick={() => {
                                copyTextToClipboard(bpayBillerCode);
                                popToast({
                                  message: 'Copied biller code',
                                  level: 'success',
                                });
                              }}
                              data-testid="super_voluntary_contribution_biller_code_copy"
                            >
                              <StyledFeatherCopyIcon color="neutral.000" />
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            paddingY="md"
                            flexDirection="row"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              flex={1}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Box>
                                <Text variant={3} color="neutral.070" isBold>
                                  Reference number
                                </Text>
                              </Box>
                              <Box paddingTop="xxs">
                                <Text variant={1} color="neutral.000" isBold>
                                  {referenceNumber}
                                </Text>
                              </Box>
                            </Box>
                            <Box
                              paddingRight="md"
                              data-testid="super_voluntary_contribution_biller_reference_copy"
                              onClick={() => {
                                if (!referenceNumber) return;
                                copyTextToClipboard(referenceNumber);
                                popToast({
                                  message: `Copied biller reference`,
                                  level: 'success',
                                });
                              }}
                            >
                              <StyledFeatherCopyIcon color="neutral.000" />
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Columns.Column>
        </Columns>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, lg: 10 / 12 }}>
            <Accordion
              variant="info"
              title="Learn more about after-tax contributions"
            >
              <Stack spaceY="md">
                <Text variant={2}>
                  You can boost your super balance by making an after-tax
                  contribution. An after-tax contribution — also known as a
                  personal contribution or a non-concessional contribution — is
                  money you choose to pay into your super fund from your
                  after-tax income. Before considering an after-tax
                  contribution, check your eligibility by referring to the{' '}
                  <TextLink
                    href={ExternalRoutes.SUPER_REFERENCE_GUIDE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reference Guide
                  </TextLink>
                  .
                  <br />
                  <br />
                  <strong>Tax considerations</strong>
                  <br />
                  As after-tax or non-concessional contributions are made into
                  your super fund from after-tax income, these contributions are
                  not taxed in your super fund. You can choose to claim a tax
                  deduction on personal contributions. The contribution will be
                  classified as a before-tax (concessional) contribution.
                  <br />
                  <br />
                  <strong>Further information</strong>
                  <br />
                  There are caps on the contributions you can make each
                  financial year. Making additional contributions can have tax
                  and other benefits in certain circumstances. You should
                  consider your objectives, financial situation and needs before
                  deciding to contribute. We are unable to provide you with
                  personal financial or tax advice. A licensed financial or tax
                  adviser can help you make a decision.
                  <br />
                  <br />
                  For more information on tax considerations and to view
                  eligibility criteria, visit the Australian Tax Office’s
                  website at{' '}
                  <TextLink
                    href={ExternalRoutes.ATO}
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.ato.gov.au/
                  </TextLink>
                  .
                </Text>
              </Stack>
            </Accordion>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const BpayImage = styled.img`
  ${width({ xs: '40px', sm: '60px' })}
  height: auto;
  max-width: 100%;
`;

const StyledFeatherCopyIcon = styled(FeatherCopyIcon)`
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;
