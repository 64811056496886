import { Box, Columns, Inline, Stack, Text } from '@spaceship-fspl/components';
import {
  FeatherAlertTriangleIcon,
  SpaceshipIcon,
} from '@spaceship-fspl/icons-web';
import { match } from '@spaceship-fspl/styles';
import backgroundImage from 'assets/summary-confetti.svg';
import { Button } from 'components/button';
import { OnboardingContainer } from 'components/layouts/onboarding';
import { PageHeading } from 'components/layouts/page';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export interface OnboardingSummaryProps {
  variant: 'signup' | 'portfolio_registration';
  subtitle?: string;
  isEditing: boolean;
  onComplete: () => void;
}

export const OnboardingSummary: React.FC<
  React.PropsWithChildren<OnboardingSummaryProps>
> = ({ children, variant, subtitle, isEditing, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showIsEditingError, setShowIsEditingError] = useState(false);

  const submit = async (): Promise<void> => {
    if (isEditing) {
      setShowIsEditingError(true);
      window.scrollTo(0, 0);
      return;
    }

    setIsLoading(true);
    await onComplete();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isEditing) {
      setShowIsEditingError(false);
    }
  }, [isEditing]);

  return (
    <StyledConfettiPage>
      <OnboardingContainer>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
            <Stack spaceY="xl" alignX="center">
              <Stack spaceY="lg">
                <PageHeading title="Summary" subtitle={subtitle} />

                {showIsEditingError && (
                  <Inline spaceX="xs" alignY="center" alignX="center">
                    <Box lineHeight={0}>
                      <FeatherAlertTriangleIcon size="md" color="red.100" />
                    </Box>
                    <Text color="red.100" variant={2} isBold={true}>
                      You have unsaved edits.
                    </Text>
                  </Inline>
                )}

                {children}
              </Stack>

              <Button
                variant="primary"
                size="lg"
                trackingProperties={{ name: `${variant}_summary_confirm` }}
                isLoading={isLoading}
                onClick={submit}
              >
                <Inline alignY="center">
                  <span>Confirm and finish setup</span>
                  <Box
                    display={{ xs: 'none', md: 'inline-block' }}
                    marginLeft="xs"
                  >
                    <SpaceshipIcon color="neutral.000" size="lg" />
                  </Box>
                </Inline>
              </Button>
            </Stack>
          </Columns.Column>
        </Columns>
      </OnboardingContainer>
    </StyledConfettiPage>
  );
};

const StyledConfettiPage = styled.div`
  ${match('md')`
    background: url(${backgroundImage}) center top no-repeat;
  `}
`;
