import {
  australianStateValidations,
  globalStateValidations,
  mapGooglePlaceResultToAddressForm,
  postcodeValidations,
  STATES,
  STREET_TYPES,
  streetNameValidations,
  streetNumberValidations,
  streetTypeValidations,
  suburbValidations,
  unitNumberValidations,
} from '@spaceship-fspl/address';
import { Box, Columns, Spinner } from '@spaceship-fspl/components';
import { AddressInput } from '@spaceship-fspl/graphql';
import { AutoCompleteDropdown, Option } from 'components/autocomplete-dropdown';
import { ControllerInput } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import { useGetPlaceDetails } from 'helpers/hooks/googlemaps/use-get-place-details';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const EMPTY_ADDRESS_FORM: AddressInput = {
  unitNumber: '',
  streetNumber: '',
  streetName: '',
  streetType: '',
  state: '',
  suburb: '',
  postcode: '',
  country: '',
};

type AddressFormInputsProps = {
  isAustralianOnlyAddress?: boolean;
  countries?: Array<{
    id: string;
    name: string;
    countryCode: string;
  }>;
};

export const AddressFormInputs: React.FC<
  React.PropsWithChildren<AddressFormInputsProps>
> = ({ isAustralianOnlyAddress = true, countries }) => {
  const {
    formState: { errors },
    register,
    control,
    watch,
  } = useFormContext<{
    address: AddressInput;
  }>();

  const countryOptions = countries
    ?.slice() // copy before sorting in place
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((country) => ({
      label: country.name,
      value: country.countryCode,
    }));
  const isAustralia =
    isAustralianOnlyAddress || watch().address?.country?.toLowerCase() === 'au';

  return (
    <Columns spaceX="sm" spaceY="sm">
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <ControllerInput
          control={control}
          name="address.unitNumber"
          type="text"
          placeholder="Unit number"
          rules={unitNumberValidations}
        />
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <ControllerInput
          name="address.streetNumber"
          control={control}
          type="text"
          placeholder="Street number"
          autoComplete="address-level3"
          rules={streetNumberValidations}
        />
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <ControllerInput
          name="address.streetName"
          control={control}
          type="text"
          placeholder="Street name"
          autoComplete="street-address"
          rules={streetNameValidations}
        />
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        {isAustralia ? (
          <Controller
            control={control}
            rules={streetTypeValidations}
            name="address.streetType"
            render={({ formState: { errors: controllerErrors } }) => (
              <Dropdown
                options={STREET_TYPES}
                id="street_type"
                data-testid="street_type"
                placeholder="Street type"
                errorMessage={controllerErrors.address?.streetType?.message}
                {...register('address.streetType', streetTypeValidations)}
              />
            )}
          />
        ) : (
          <ControllerInput
            name="address.streetType"
            control={control}
            type="text"
            placeholder="Street type"
            maxLength={256}
            rules={streetTypeValidations}
          />
        )}
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 3 }}>
        <ControllerInput
          name="address.suburb"
          control={control}
          type="text"
          placeholder="City"
          autoComplete="address-level2"
          rules={suburbValidations}
        />
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 3 }}>
        {isAustralia ? (
          <Dropdown
            placeholder="State"
            options={STATES}
            errorMessage={errors.address?.state?.message}
            autoComplete="address-level1"
            {...register('address.state', australianStateValidations)}
          />
        ) : (
          <ControllerInput
            name="address.state"
            control={control}
            type="text"
            placeholder="State"
            autoComplete="address-level1"
            maxLength={256}
            rules={globalStateValidations}
          />
        )}
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 3 }}>
        <ControllerInput
          name="address.postcode"
          control={control}
          type="text"
          placeholder="Postcode"
          autoComplete="postal-code"
          maxLength={isAustralianOnlyAddress ? 4 : 10}
          rules={{
            required: postcodeValidations.required,
            validate: (value) => {
              return postcodeValidations.validate(
                watch().address?.state || '',
                value || '',
                isAustralia,
              );
            },
          }}
        />
      </Columns.Column>
      {!isAustralianOnlyAddress && !!countries && (
        <Columns.Column width={1}>
          <Controller
            control={control}
            name="address.country"
            rules={{
              required: 'Your country is required',
            }}
            render={({
              field: { onChange, value },
              formState: { errors: controllerErrors },
            }) => (
              <AutoCompleteDropdown
                options={countryOptions ?? []}
                id="country"
                placeholder="Country"
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                errorMessage={controllerErrors.address?.country?.message}
                onChange={(option) => {
                  onChange((option as Option).value);
                }}
                value={
                  (countryOptions?.find(
                    (option) =>
                      value?.toLowerCase() === option.value.toLowerCase(),
                  ) as Option) || ''
                }
              />
            )}
          />
        </Columns.Column>
      )}
    </Columns>
  );
};

AddressFormInputs.displayName = 'AddressFormInputs';

export const AddressForm: React.FC<
  React.PropsWithChildren<
    {
      googlePlaceId?: string;
    } & AddressFormInputsProps
  >
> = ({ googlePlaceId, ...props }) => {
  const { data, isLoading } = useGetPlaceDetails(googlePlaceId);
  const { reset, getValues } = useFormContext();

  useEffect(() => {
    if (data?.result) {
      const addressFormData = mapGooglePlaceResultToAddressForm(data.result);
      reset({ ...getValues(), address: addressFormData });
    }
  }, [data, reset, getValues]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop="lg"
      >
        <Spinner />
      </Box>
    );
  }

  return <AddressFormInputs {...props} />;
};

AddressForm.displayName = 'AddressForm';
