import * as React from 'react';
export const StreamlineStudyDesk = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M21.9 15.559H2.1a.732.732 0 01-.711-.555l-.367-1.467a.732.732 0 01.711-.911h20.534a.732.732 0 01.71.91l-.366 1.468a.732.732 0 01-.71.555zM3.2 15.559l-1.467 7.333M20.8 15.559l1.467 7.333M14.2 12.626a2.933 2.933 0 115.867 0" />
    <path vectorEffect="non-scaling-stroke" d="M14.933 2.359a2.2 2.2 0 012.2 2.2v5.133" />
    <path vectorEffect="non-scaling-stroke" d="M10.85 1.625a2.934 2.934 0 013.35 4.817l-3.35-4.817zM10.507 12.626H3.933a1.467 1.467 0 110-2.934h6.574M9.04 9.692v2.934M10.507 9.692H3.933a1.467 1.467 0 010-2.933h6.574M9.04 6.759v2.933" />
  </svg>
);
