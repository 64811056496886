export enum AccessibilityLabel {
  CONTINUE = 'continue button',
  SKIP = 'skip button',
  DASHBOARD = 'view dashboard button',
  MANUAL_ADDRESS = 'enter manual address button',
  GO_BACK = 'go back',
  BALANCE_CARD = 'balance card',
  UNIT_PRICE_CARD = 'unit price card',
  WITHDRAW_BUTTON = 'withdraw button',
  INVEST_BUTTON = 'invest button',
  SAVE_BUTTON = 'save button',
  CANCEL_BUTTON = 'cancel button',
  SIGNUP_BUTTON = 'sign up button',
  REMOVE_TFN_BUTTON = 'remove tfn button',
  REMOVE_TFN_EXEMPTION_BUTTON = 'remove tfn exemption button',
  SUPER_INVESTMENT_OPTION_CONSENT_CHECKBOX = 'super investment option consent checkbox',
}
