import { Client as AuthClient } from '@spaceship-fspl/auth';

import { config } from './config';
import { fetch } from './fetch';
import { PersistKey, retrieve, store } from './persist';

export const authClient = new AuthClient({
  fetch,
  storage: {
    read: async () => {
      const stored = retrieve(PersistKey.AUTH);
      return stored && JSON.parse(stored);
    },
    write: async (state) => store(PersistKey.AUTH, JSON.stringify(state)),
  },
  useCookies: config.auth.useCookies,
});
