import { getSpace, marginTop, text } from '@spaceship-fspl/styles';
import purpleTickSvg from 'assets/purple-tick.svg';
import styled from 'styled-components';

const ICON_SIZE_PX = '24px';

export const ConsentList = styled.ul`
  ${text({ variant: 2 })}
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding-left: calc(${ICON_SIZE_PX} + ${getSpace('sm')});
    position: relative;

    ::before {
      display: block;
      content: '';
      background: url(${purpleTickSvg}) no-repeat 0 0 transparent;
      background-size: ${ICON_SIZE_PX} ${ICON_SIZE_PX};
      position: absolute;
      height: ${ICON_SIZE_PX};
      width: ${ICON_SIZE_PX};
      left: 0;
    }

    :not(:first-child) {
      ${marginTop('md')}
    }
  }
`;
