import { gql, useQuery } from '@apollo/client';
import {
  useCanWriteSaver,
  useCanWriteSuper,
  useHasNova,
} from '@spaceship-fspl/auth';
import {
  UpdateContactInput,
  useSuperPortfolioFlags,
  useUpdateContact,
} from '@spaceship-fspl/graphql';
import { useUpdateMember } from '@spaceship-fspl/super';
import {
  ContactDetailsCard,
  ContactDetailsCard_ContactFragment,
} from 'components/account-cards/contact-details';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import React, { useState } from 'react';

export const UserContactDetailsCard: React.FC<React.PropsWithChildren> = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { popToast } = useNotifications();
  const canWriteSaver = useCanWriteSaver();
  const canWriteSuper = useCanWriteSuper();
  const hasNova = useHasNova();
  const [updateContact] = useUpdateContact();
  const updateMember = useUpdateMember();

  const resp = useQuery(gql`
    query WebAppEditContactDetails {
      contact {
        id
        ...ContactDetailsCard_ContactFragment
      }
    }
    ${ContactDetailsCard_ContactFragment}
  `);

  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;
  const contact = resp.data?.contact;

  let isEditable = true;
  if (!contact || !contact.email) {
    isEditable = false;
  }
  if (contact && contact.email) {
    // super only user
    if (canWriteSuper && !canWriteSaver) {
      isEditable = !sftLimitedServicePeriodEnabled;
    } else if (canWriteSuper && canWriteSaver) {
      // super and voyager user
      isEditable = !sftLimitedServicePeriodEnabled;
    } else {
      isEditable = !canWriteSuper && (canWriteSaver || hasNova);
    }
  }

  const onSubmit = async (payload: UpdateContactInput): Promise<void> => {
    const updateSargonContact = async (): Promise<void> => {
      if (!canWriteSuper) {
        return;
      }
      const member: Parameters<typeof updateMember.mutateAsync>[0] = {};

      // only update fields which have changed in order to avoid failures attempting to update restricted fields when the values
      // haven't changed

      if (payload.email && contact?.email !== payload.email) {
        member.email = payload.email;
      }

      if (payload.phoneNumber && contact?.phoneNumber !== payload.phoneNumber) {
        member.phoneMobile = payload.phoneNumber;
      }

      if (
        (payload.address &&
          contact?.address?.suburb !== payload.address.suburb) ||
        contact?.address?.state !== payload.address?.state ||
        contact?.address?.postcode !== payload.address?.postcode ||
        contact?.address?.country !== payload.address?.country
      ) {
        const street = `${payload.address?.streetNumber} ${payload.address?.streetName} ${payload.address?.streetType}`;
        const address = {
          line1: payload.address?.unitNumber || street,
          line2: payload.address?.unitNumber ? street : '',
          suburb: payload.address?.suburb ?? '',
          state: payload.address?.state ?? '',
          postcode: payload.address?.postcode ?? '',
          countryCode: 'AU',
        };
        member.addressPostal = address;
        member.addressResidential = address;
      }

      //  OTP is required if the user is verified and tries to update their email or phone which is handled in <SuperProvider />
      // @see https://api-docs.certane.com/#operation/updateMember
      await updateMember.mutateAsync(member);
    };

    setIsLoading(true);

    try {
      // we intentionally run these requests synchronously because we don't have any form of "transaction" across the two systems
      // the super request is the one most likely to fail due to stricter requirements so we run in first
      await updateSargonContact();
      await updateContact({
        variables: {
          input: {
            email: payload.email,
            phoneNumber: payload.phoneNumber,
            address: {
              postcode: payload.address?.postcode ?? '',
              state: payload.address?.state ?? '',
              suburb: payload.address?.suburb ?? '',
              streetName: payload.address?.streetName ?? '',
              streetNumber: payload.address?.streetNumber ?? '',
              streetType: payload.address?.streetType ?? '',
              unitNumber: payload.address?.unitNumber,
              country: payload.address?.country ?? 'AU',
            },
          },
        },
      });
      setIsEditing(false);
      popToast({
        message: 'Successfully updated your contact details!',
        level: 'success',
      });
    } catch (error) {
      addRumError({ error });
      popToast({
        message:
          'Unable to update contact details. Please try again or contact support.',
        level: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContactDetailsCard
      contact={contact}
      onSubmit={onSubmit}
      isReadOnly={!isEditable}
      edit={
        isEditable
          ? {
              isEditing,
              onClick: () => setIsEditing(true),
              editTrackingProperties: {
                name: 'account_contact_details_card_edit',
              },
              onCancel: () => setIsEditing(false),
              submitText: 'Save',
              isLoading,
            }
          : undefined
      }
    />
  );
};
