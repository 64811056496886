import { Box } from '@spaceship-fspl/components';
import { FeatherChevronDownIcon } from '@spaceship-fspl/icons-web';
import {
  color,
  getSpace,
  paddingRight,
  paddingTop,
} from '@spaceship-fspl/styles';
import {
  focusedLabelStyle,
  inputShadowStyle,
  inputStyles,
  StyledLabel as Label,
} from 'components/input';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

export interface Option {
  value?: string | number;
  label: string;
}

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  options: Option[];
  placeholder: string;
  disabled?: boolean;
  errorMessage?: string;
  defaultValue?: Option['value'];
}

export const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>(
  (
    { disabled, errorMessage, name, options, placeholder, children, ...props },
    ref,
  ) => {
    return (
      <StyledDropdownContainerBox isError={!!errorMessage}>
        <StyledSelect
          ref={ref}
          id={name}
          name={name}
          disabled={disabled}
          {...props}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value === undefined ? label : value}>
              {label}
            </option>
          ))}
        </StyledSelect>

        <StyledLabel htmlFor={name}>
          {(!disabled && errorMessage) || placeholder}
        </StyledLabel>

        <Box position="absolute" right={getSpace('sm')} top="calc(50% - 8px)">
          <FeatherChevronDownIcon color="neutral.080" size="sm" />
        </Box>
      </StyledDropdownContainerBox>
    );
  },
);

Dropdown.displayName = 'Dropdown';

const StyledLabel = styled(Label)`
  left: 0;
  ${focusedLabelStyle}
`;

const StyledSelect = styled.select`
  ${inputStyles()}
  ${paddingRight('lg')}
  appearance: none;
  outline: none;
  width: 100%;

  :not(:empty) {
    ${paddingTop('md')}
    ${inputShadowStyle({ color: 'indigo.070' })}

    :not(:disabled):hover, :not(:disabled):focus {
      ${inputShadowStyle({ color: 'indigo.070', isThick: true })}
    }
  }

  ::-ms-expand {
    display: none;
  }
`;

const StyledDropdownContainerBox = styled(Box).attrs({
  position: 'relative',
  backgroundColor: 'neutral.000',
  borderRadius: 'xxs',
})<{ isError: boolean }>`
  ${({ isError }) =>
    isError
      ? css`
          ${StyledLabel} {
            ${color('red.100')}
          }

          ${StyledSelect},
          ${StyledSelect}:not(:disabled):hover,
    ${StyledSelect}:not(:disabled):focus {
            ${inputShadowStyle({ color: 'red.100', isThick: true })}
          }
        `
      : ''}
`;
