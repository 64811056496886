import { UnclaimedMoneys } from '@sargon/api-client';
import {
  Box,
  Divider,
  SegmentControls,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { Decimal } from 'decimal.js';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CONSENTED_COPY, FundCard } from './card';

export enum UnclaimedFundsTransferType {
  NONE = 'none',
  FULL = 'full',
}

export const UnclaimedFundsCard: React.FC<
  React.PropsWithChildren<{
    hasConsented: boolean;
    unclaimedMoneys: UnclaimedMoneys[];
    total?: string;
  }>
> = ({ unclaimedMoneys, total, hasConsented }) => {
  const { control, watch } = useFormContext();

  const segmentFieldName = 'unclaimedFundsTransferType';
  const isFullTransfer = watch(segmentFieldName) === 'full';

  return (
    <FundCard
      fundName="ATO held monies"
      balance={total || ''}
      transferAmount={isFullTransfer && total ? total : ''}
    >
      <Box paddingX="md">
        <Stack spaceY="sm">
          <Stack spaceY="xs">
            <Divider />
            {unclaimedMoneys.map((fund) => {
              return (
                <div key={fund.typeOfFund}>
                  <Text variant={3} color="neutral.085" isBold={true}>
                    {fund.typeOfFund}
                  </Text>

                  <Text variant={3} color="neutral.085">
                    {formatCurrency(
                      new Decimal(fund?.amount?.amount || 0)
                        .dividedBy(100)
                        .toNumber(),
                    )}
                  </Text>
                </div>
              );
            })}
            <Divider />
          </Stack>

          {hasConsented ? (
            <Box display="flex">
              <Box marginRight="xxs">
                <FeatherInfoIcon color="indigo.070" />
              </Box>
              <Box display="flex" alignItems="center">
                <Text variant={4}>{CONSENTED_COPY}</Text>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant={4} isBold={true}>
                TRANSFER TO SPACESHIP
              </Text>

              <Controller
                name={segmentFieldName}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SegmentControls
                    controls={[
                      {
                        label: 'None',
                        value: UnclaimedFundsTransferType.NONE,
                      },
                      {
                        label: 'Full',
                        value: UnclaimedFundsTransferType.FULL,
                      },
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </FundCard>
  );
};
