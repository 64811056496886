import { RouteComponentProps, useNavigate } from '@reach/router';
import { useUserAccess } from '@spaceship-fspl/super';
import { Success } from 'components/layouts/success';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React from 'react';

export const SuperOnboardingSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const { canSuperMatch } = useUserAccess();
  const isSuperMatchEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPERMATCH_ENABLED,
  );

  return (
    <Success
      isOnboarding={true}
      showConfetti={true}
      title="Congratulations"
      subtitle="You're now a Spaceship Super member. Next you'll be able to find and consolidate any existing super accounts."
      onContinue={{
        trackingProperties: { name: 'signup_super_success_continue' },
        onClick: () => {
          if (isSuperMatchEnabled && canSuperMatch) {
            navigate(Routes.SUPER_SIGNUP_SUPERMATCH);
          } else {
            navigate(Routes.SUPER_SIGNUP_CONSOLIDATE);
          }
        },
      }}
    />
  );
};
