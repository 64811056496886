import { DocumentSummary } from '@sargon/api-client';

import { useGetDocuments } from '../react';

export interface UseEmployerContributionDocumentsResults {
  isLoading: boolean;
  data?: Array<DocumentSummary>;
  error: unknown;
}

export const useEmployerContributionDocuments =
  (): UseEmployerContributionDocumentsResults => {
    const { isLoading, data: documents, error } = useGetDocuments();
    const employerContributionDocuments = documents?.filter(
      (document) => document.type === 'Employer Contribution Form',
    );

    return {
      isLoading,
      data: employerContributionDocuments,
      error,
    };
  };
