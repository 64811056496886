import { useString } from '@spaceship-fspl/dynamic-config';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { toSydneyStartOfDay } from '@spaceship-fspl/helpers';
import { useGetMember } from '@spaceship-fspl/super';
import { isBefore } from 'date-fns';
import { useMemo, useState } from 'react';

import { DynamicConfigKeys } from '../dynamic-config';
import { addRumError } from '../monitoring';

export enum JoinedBeforeSftState {
  ERROR = 'error',
  LOADING = 'loading',
  JOINED_BEFORE = 'joined_before',
  JOINED_AFTER = 'joined_after',
}

export const useJoinedBeforeSft: () => {
  state: JoinedBeforeSftState;
} = () => {
  const [error, setError] = useState<boolean>(false);

  const sftMigrationCutoffDate = useString(
    DynamicConfigKeys.SFT_ONE_SUPER_GO_LIVE_DATE,
    '2024-06-11',
  );

  const handleUserJoinedBeforeSft: (
    joinedDate: string,
    sftCutoffDate: string,
  ) => boolean = (joinedDate, sftCutoffDate) => {
    try {
      return joinedDate
        ? isBefore(
            toSydneyStartOfDay(new Date(joinedDate)),
            toSydneyStartOfDay(new Date(sftCutoffDate)),
          )
        : false;
    } catch (error) {
      addRumError({
        error,
        context: {
          reason: 'Super Joined Before SFT hook - unable to parse joined date',
        },
      });
      return false;
    }
  };

  const {
    data: memberData,
    isLoading: memberDataIsLoading,
    error: memberError,
  } = useGetMember();

  const {
    data: superFlagsData,
    loading: superFlagsLoading,
    error: superFlagsError,
  } = useSuperPortfolioFlags({
    onError: (error) => {
      setError(true);
      addRumError({
        error,
        context: {
          reason:
            'Super Joined Before SFT hook - unable to load super portfolio flags',
        },
      });
    },
  });

  const isLoading = memberDataIsLoading || superFlagsLoading;

  useMemo(() => {
    if (memberError || superFlagsError) {
      setError(true);
    } else {
      setError(false);
    }
  }, [memberError, superFlagsError]);

  const joinedBefore = useMemo(() => {
    if (isLoading) {
      return false;
    }
    if (
      memberData?.joinedDate &&
      superFlagsData?.superPortfolioFlags &&
      sftMigrationCutoffDate
    ) {
      return handleUserJoinedBeforeSft(
        memberData.joinedDate,
        sftMigrationCutoffDate,
      );
    }
    return false;
  }, [
    isLoading,
    memberData?.joinedDate,
    sftMigrationCutoffDate,
    superFlagsData?.superPortfolioFlags,
  ]);

  let state: JoinedBeforeSftState;
  if (error) {
    state = JoinedBeforeSftState.ERROR;
  } else if (isLoading) {
    state = JoinedBeforeSftState.LOADING;
  } else if (joinedBefore) {
    state = JoinedBeforeSftState.JOINED_BEFORE;
  } else {
    state = JoinedBeforeSftState.JOINED_AFTER;
  }
  return { state };
};
