import { UnitPrice } from '@sargon/api-client';
import {
  DataPoint,
  getToday,
  TimeScale,
  toDateGraphData,
  toSydneyStartOfDay,
  truncateDecimal,
} from '@spaceship-fspl/helpers';
import { subMonths, subYears } from 'date-fns';
import { Decimal } from 'decimal.js';
import { useMemo } from 'react';

import { calculatePerformance } from '../helpers';
import {
  useGetAllInvestmentPerformance,
  useGetCurrentInvestmentAllocation,
} from '../react';

interface UnitPriceData {
  latestUnitPrice?: UnitPrice;
  latestCalculatedUnitPrice?: string;
  oneYearPerformanceMovement: number;
  oneYearPerformance?: number;
  oneMonthPerformance?: number;
  oneWeekPerformance?: number;
  hasLessThanOneYearOfData?: boolean;
  isLoading: boolean;
  error: unknown;
}

export enum UnitPriceFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

const weeklyFilter = (data: UnitPrice): boolean => !!data.weekly;

export const useUnitPrice = (): UnitPriceData => {
  const currentInvestmentAllocation = useGetCurrentInvestmentAllocation();
  const portfolioId = currentInvestmentAllocation.investmentAllocation?.id;
  const allInvestmentPerformance = useGetAllInvestmentPerformance(portfolioId);
  const weeklyUnitPrices = allInvestmentPerformance.data?.filter(weeklyFilter);
  const latestUnitPrice = allInvestmentPerformance.data?.[0];
  const latestCalculatedUnitPrice =
    latestUnitPrice?.sellPrice?.amount && latestUnitPrice.sellPrice.precision
      ? truncateDecimal(
          new Decimal(latestUnitPrice.sellPrice.amount)
            .dividedBy(Math.pow(10, latestUnitPrice.sellPrice.precision))
            .toNumber(),
        ).toString()
      : undefined;
  const latestUnitPriceDate =
    latestUnitPrice?.date && toSydneyStartOfDay(new Date(latestUnitPrice.date));

  const firstUnitPrice = allInvestmentPerformance.data?.slice(-1)[0];
  const firstUnitPriceDate =
    firstUnitPrice?.date && toSydneyStartOfDay(new Date(firstUnitPrice?.date));

  const oneWeekPerformance = calculatePerformance(
    weeklyUnitPrices || [],
    'week',
  );
  const oneMonthPerformance = calculatePerformance(
    weeklyUnitPrices || [],
    'month',
  );
  const oneYearPerformance = calculatePerformance(
    weeklyUnitPrices || [],
    'year',
  );
  const oneYearPerformanceMovement = oneYearPerformance
    ? oneYearPerformance > 0
      ? 1
      : -1
    : 0;
  const hasLessThanOneYearOfData =
    firstUnitPriceDate && latestUnitPriceDate
      ? firstUnitPriceDate > subYears(latestUnitPriceDate, 1)
      : undefined;
  return {
    latestUnitPrice,
    latestCalculatedUnitPrice,
    oneYearPerformanceMovement,
    oneYearPerformance,
    oneMonthPerformance,
    oneWeekPerformance,
    hasLessThanOneYearOfData,
    isLoading:
      currentInvestmentAllocation.isLoading ||
      allInvestmentPerformance.isLoading,
    error: currentInvestmentAllocation.error ?? allInvestmentPerformance.error,
  };
};

export const useUnitPriceGraphData = (
  timeScale: TimeScale,
): {
  data: Array<DataPoint>;
  isLoading: boolean;
  error: unknown;
} => {
  const currentInvestmentAllocation = useGetCurrentInvestmentAllocation();
  const allInvestmentPerformance = useGetAllInvestmentPerformance(
    currentInvestmentAllocation.investmentAllocation?.id,
  );

  const data = useMemo(() => {
    const getPricesFrom = (fromDate: Date): UnitPrice[] =>
      allInvestmentPerformance.data?.filter(
        ({ date }) => date && toSydneyStartOfDay(new Date(date)) >= fromDate,
      ) ?? [];
    let pricesFrom: UnitPrice[];

    switch (timeScale) {
      case TimeScale.TWELVE_MONTHS:
        pricesFrom = getPricesFrom(subYears(getToday(), 1));
        break;

      case TimeScale.SIX_MONTHS:
        pricesFrom = getPricesFrom(subMonths(getToday(), 6));
        break;

      case TimeScale.THREE_MONTHS:
        pricesFrom = getPricesFrom(subMonths(getToday(), 3));
        break;

      case TimeScale.ONE_MONTH:
        pricesFrom = getPricesFrom(subMonths(getToday(), 1));
        break;

      case TimeScale.MAX:
      default:
        pricesFrom = allInvestmentPerformance.data || [];
        break;
    }

    return toDateGraphData<UnitPrice>(pricesFrom, ({ date, sellPrice }) => ({
      date: date ?? '',
      y: new Decimal(sellPrice?.amount || 0)
        .dividedBy(Math.pow(10, sellPrice?.precision || 6))
        .toString(),
    })).reverse();
  }, [allInvestmentPerformance.data, timeScale]);

  return {
    data,
    isLoading:
      currentInvestmentAllocation.isLoading ||
      allInvestmentPerformance.isLoading,
    error: currentInvestmentAllocation.error ?? allInvestmentPerformance.error,
  };
};
