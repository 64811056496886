import { RouteComponentProps } from '@reach/router';
import {
  MoneyTransactionSuccess,
  MoneyTransactionVariant,
} from 'components/money-transaction-success';
import React from 'react';

interface MoneyWithdrawSuccessProps {
  location: {
    state: {
      audAmount: string;
      etaDate?: Date;
    };
  };
}

export const MoneyWithdrawSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps<MoneyWithdrawSuccessProps>>
> = (props) => {
  const audAmount = props?.location?.state?.audAmount ?? '';
  const etaDate = props?.location?.state?.etaDate
    ? new Date(props?.location?.state?.etaDate)
    : undefined;

  return (
    <MoneyTransactionSuccess
      variant={MoneyTransactionVariant.WITHDRAW}
      amount={audAmount}
      etaDate={etaDate}
    />
  );
};
