import { fill } from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

export type LogoBadgeVariant = 'light' | 'dark';
export type LogoBadgeSize = 'sm' | 'md' | 'lg' | 'xl';

export interface LogoBadgeProps {
  variant: LogoBadgeVariant;
  size?: LogoBadgeSize;
}

const variant = createVariant({
  light: css`
    ${fill('neutral.000')}
  `,
  dark: css`
    ${fill('neutral.100')}
  `,
});

const size = createVariant({
  sm: css`
    height: 16px;
    width: 16px;
  `,
  md: css`
    height: 24px;
    width: 24px;
  `,
  lg: css`
    height: 32px;
    width: 32px;
  `,
  xl: css`
    height: 100px;
    width: 100px;
  `,
});

const SVG = styled.svg`
  vertical-align: middle;
  ${createProps({
    size,
  })}
`;

const Path = styled.path`
  ${createProps({
    variant,
  })}
`;

export const LogoBadge: React.FC<React.PropsWithChildren<LogoBadgeProps>> = ({
  variant,
  size = 'lg',
}) => (
  <SVG size={size} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
    <Path
      variant={variant}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.261 26C102.261 26 99.252 31.6 91.0085 40.2087C93.8781 46.2609 95.4955 53.0261 95.4955 60.1739C95.4955 83.1477 78.9563 102.209 57.287 105.826C69.9824 101.148 79.2 89.2348 72.9042 78.0523C68.365 69.9654 56.3828 70.8002 43.9131 76.1391C16.713 87.8089 0 93.1129 0 93.1129L102.261 26ZM105.982 23.8609C104.156 21.0261 102.017 18.5217 99.6522 16.3652C100.731 16.6087 108.122 18.2261 108.174 18.1913C114.4 14.0522 120 14.5391 120 14.5391C120 14.5391 118.191 19.9652 111.965 24.1044C111.965 24.1044 110.4 31.7043 110.174 32.7826C109.183 29.7043 107.791 26.7131 105.982 23.8609ZM26.6087 58.487C16.5391 60.2087 12.8 55.9478 11.2869 51.4957C8.73042 44.1043 11.8261 31.8783 21.8087 23.7043C29.4087 17.4609 39.2696 14 49.8261 14C64.3132 14 77.2002 20.8174 85.5652 31.4261C72.1737 36.0696 40.5565 56.1043 26.6087 58.487Z"
    />
  </SVG>
);
