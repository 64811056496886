import { rgba as rgbaPolished } from 'polished';
import { css } from 'styled-components';

import { backgroundColor, getColor } from '../color';
import { Color, colors } from '../colors';
import { getSpace, Space } from '../space';
import { FlattenInterpolationWithTheme } from '../types';

export interface LinearGradientConfig {
  alpha?: number;
  angle: string;
  colorStops: [Color, string][];
}

export const linearGradient = ({
  alpha = 1,
  angle,
  colorStops,
}: LinearGradientConfig): string =>
  `linear-gradient(${angle}, ${colorStops
    .map((x) => {
      const hex = getColor(x[0]);
      if (!hex) throw new Error();
      return `${alpha >= 1 ? hex : rgbaPolished(hex, alpha)} ${x[1]}`;
    })
    .join(',')})`;

export const rgba = (color: Color, alpha: number): string => {
  const c = getColor(color);
  if (!c) throw new Error();
  return rgbaPolished(c, alpha);
};

export const hexToColor = (hex: string): Color | undefined => {
  let color: Color | undefined;

  Object.entries(colors).some(([baseName, baseScale]) => {
    Object.entries(baseScale).some(([scale, hexColor]) => {
      if (hexColor.toLowerCase() === hex.toLowerCase()) {
        color = `${baseName}.${scale}` as Color;
        return true;
      }
      return false;
    });
    return !!color;
  });

  return color;
};

export interface ScrollbarConfig {
  padding?: Space;
  thumbColor: Color;
  trackColor: Color;
  width?: number;
}

export const scrollbar = ({
  padding = 'xxs',
  thumbColor,
  trackColor,
  width = 5,
}: ScrollbarConfig): FlattenInterpolationWithTheme => {
  const scrollbarCommonStyles = css`
    background-clip: content-box;
    border-radius: calc(${getSpace(padding)} + ${width / 2}px);
    border: ${getSpace(padding)} solid transparent;
    width: ${width}px;
  `;

  return css`
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${getColor(thumbColor)} transparent;

    /* Chrome/Safari/Edge */
    ::-webkit-scrollbar {
      width: calc((${getSpace(padding)} * 2) + ${width}px);
    }

    ::-webkit-scrollbar-track {
      ${scrollbarCommonStyles}
      ${backgroundColor(trackColor)}
    }

    ::-webkit-scrollbar-thumb {
      ${scrollbarCommonStyles}
      ${backgroundColor(thumbColor)}
    }
  `;
};
