import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
  UnstyledButton,
  Visible,
} from '@spaceship-fspl/components';
import {
  useSendPasswordResetCode,
  useVerifyPasswordResetCode,
  VerifyPasswordResetOutcome,
} from '@spaceship-fspl/graphql';
import {
  FeatherArrowLeftIcon,
  FeatherChevronLeftIcon,
} from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { useResetPasswordContext } from 'contexts/saver/onboarding';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

export const PasswordResetVerify: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [{ email, resetId }, setRequest] = useResetPasswordContext();
  const [sendCode, sendCodeMeta] = useSendPasswordResetCode();
  const [verifyCode, verifyCodeMeta] = useVerifyPasswordResetCode();
  const form = useForm<{ code: string }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  const isLoading = sendCodeMeta.loading || verifyCodeMeta.loading;

  useEffect(() => {
    if (!email || !resetId) {
      navigate(Routes.PASSWORD_RESET);
    }
  }, [email, navigate, resetId]);

  if (!email || !resetId) {
    return null;
  }

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <form
            onSubmit={form.handleSubmit(async ({ code }) => {
              try {
                const resp = await verifyCode({
                  variables: { input: { resetId, code } },
                });

                switch (resp.data?.verifyPasswordResetCode?.outcome) {
                  case VerifyPasswordResetOutcome.SUCCESS:
                    return navigate(Routes.PASSWORD_RESET_NEW_PASSWORD);
                  case VerifyPasswordResetOutcome.MISMATCH:
                    return notifications.popToast({
                      level: 'error',
                      message: 'Invalid code.',
                    });
                  case VerifyPasswordResetOutcome.EXPIRED:
                    return notifications.popToast({
                      level: 'error',
                      message:
                        'Expired code, please tap on send again to get a new code.',
                    });
                  case VerifyPasswordResetOutcome.USED:
                    return notifications.popToast({
                      level: 'error',
                      message:
                        'Code has already been used, please tap on send again to get a new code.',
                    });
                  default:
                    return notifications.popToast({
                      level: 'error',
                      message: GENERIC_ERROR_MESSAGE,
                    });
                }
              } catch {
                notifications.popToast({
                  level: 'error',
                  message: GENERIC_ERROR_MESSAGE,
                });
              }
            })}
          >
            <Stack spaceY="md">
              <Stack spaceY="xs" alignX={{ sm: 'left', md: 'center' }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Visible
                    isHidden={{ xs: true, md: false }}
                    displayValue="block"
                  >
                    <UnstyledButton
                      onClick={() => navigate(Routes.PASSWORD_RESET)}
                    >
                      <FeatherChevronLeftIcon
                        color="neutral.100"
                        size="lg"
                        strokeWidth={3}
                      />
                    </UnstyledButton>
                  </Visible>
                  <Heading variant={3}>Verify your identity</Heading>
                </Box>
                <Text variant={2} color="neutral.080">
                  We sent a message to your mobile with a 6-digit verification
                  code.
                </Text>
              </Stack>

              <Stack spaceY="sm">
                <ControllerInput
                  name="code"
                  control={form.control}
                  type="text"
                  format="otp"
                  placeholder="Code"
                  rules={validation.code}
                />
                <Text variant={3} color="neutral.080" align="center">
                  Didn&apos;t receive a text?{' '}
                  <TextLink
                    color="indigo.070"
                    onClick={async (): Promise<void> => {
                      try {
                        const resp = await sendCode({
                          variables: { input: { email } },
                        });

                        if (resp.data?.sendPasswordResetCode?.resetId) {
                          setRequest({
                            resetId: resp.data.sendPasswordResetCode.resetId,
                            email,
                          });

                          notifications.popToast({
                            level: 'success',
                            message: 'A new code is on its way!',
                          });
                        }
                      } catch {
                        notifications.popToast({
                          message:
                            'Unable to resend verification code. Please try again or contact support.',
                          level: 'warning',
                        });
                      }
                    }}
                  >
                    Send again
                  </TextLink>
                </Text>
              </Stack>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{ name: 'reset_password_verify_submit' }}
                isLoading={isLoading}
              >
                Verify
              </PageFormContinueButton>

              <Visible isHidden={{ xs: false, md: true }} displayValue="block">
                <Button
                  type="button"
                  variant="tertiary"
                  size="lg"
                  onClick={() => navigate(Routes.PASSWORD_RESET)}
                  trackingProperties={{ name: 'reset_password_verify_back' }}
                  isDisabled={isLoading}
                  before={<FeatherArrowLeftIcon />}
                >
                  Back
                </Button>
              </Visible>
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

const validation = {
  code: { required: 'Code is required' },
};
