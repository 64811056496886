import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Columns,
  Container,
  Heading,
  Inline,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { useAppStoreLinks } from '@spaceship-fspl/graphql';
import {
  linearGradient,
  map,
  ResponsiveValue,
  width,
} from '@spaceship-fspl/styles';
import novaETFsFeatureImage from 'assets/nova-etfs-feature-image@2x.png';
import qrCodeLogin from 'assets/qr-code-login.svg';
import { AppStoreLink } from 'components/app-store-link';
import { IntercomFooter } from 'components/intercom-footer';
import { AUTH_NAV_BAR_HEIGHT_MOBILE } from 'components/navigation';
import React from 'react';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

export const NovaEtfs: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <StyledGradientBox
      showGradient={{ xs: true, md: false }}
      minHeight={{
        xs: `calc(100vh - ${AUTH_NAV_BAR_HEIGHT_MOBILE}px)`,
        md: '100vh',
      }}
      display="flex"
    >
      <Container>
        <StyledGradientBox
          showGradient={{ xs: false, md: true }}
          borderRadius="md"
          paddingX={{ md: 'md', lg: 'lg', xl: 'xxl' }}
          paddingY={{ md: 'lg', xl: 'xxl' }}
          marginY={{ xs: 'sm', md: 'md' }}
        >
          <Columns
            spaceX={{ xs: 'none', xl: 'md' }}
            spaceY={{ xs: 'lg', xl: 'none' }}
            alignX="center"
            alignY="center"
          >
            <Columns.Column width={{ xs: 1, xl: 7 / 12 }}>
              <Box display="flex" justifyContent="center">
                <StyledFeatureImage
                  src={novaETFsFeatureImage}
                  alt="Spaceship mobile app"
                />
              </Box>
            </Columns.Column>

            <Columns.Column width={{ xs: 1, xl: 5 / 12 }}>
              <Stack spaceY="sm" alignX="center">
                <Heading
                  variant={2}
                  color="neutral.000"
                  align="center"
                  component="h1"
                >
                  Explore US ETFs
                  <Visible isHidden={{ xs: true, xl: false }}>
                    <br />
                  </Visible>
                  <Visible isHidden={{ xs: false, xl: true }}>&nbsp;</Visible>
                  in the Spaceship app
                </Heading>

                <Text variant={1} color="neutral.000" align="center">
                  Invest in US ETFs and Stocks with as little as A$10.
                  <Visible isHidden={{ xs: true, lg: false, xl: true }}>
                    <br />
                  </Visible>
                  <Visible isHidden={{ xs: false, lg: true, xl: false }}>
                    &nbsp;
                  </Visible>
                  Download the Spaceship app and explore what’s on offer.
                </Text>
              </Stack>

              <Box marginTop="xl">
                <Inline alignX="center" spaceX="sm">
                  <AppStoreLink
                    store="gplay"
                    variant="dark"
                    size="xl"
                    url={appStoreLinksResp.data?.googlePlayStoreDeeplink?.url}
                  />

                  <AppStoreLink
                    store="ios"
                    variant="dark"
                    size="xl"
                    url={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
                  />
                </Inline>
              </Box>

              <Visible isHidden={{ xs: true, lg: false }}>
                <Box marginTop="md">
                  <Stack spaceY="xxs" alignX="center">
                    <Box
                      borderRadius="sm"
                      backgroundColor="neutral.000"
                      height={{ lg: 180, xl: 200 }}
                      width={{ lg: 180, xl: 200 }}
                      padding="sm"
                    >
                      <StyledQrCodeSvg
                        src={qrCodeLogin}
                        title="App download QR code"
                      />
                    </Box>

                    <Text
                      variant={2}
                      color="neutral.000"
                      align="center"
                      isBold={true}
                    >
                      Scan code for app
                    </Text>
                  </Stack>
                </Box>
              </Visible>
            </Columns.Column>
          </Columns>

          <IntercomFooter />
        </StyledGradientBox>
      </Container>
    </StyledGradientBox>
  );
};

const StyledGradientBox = styled(Box)<{
  showGradient: ResponsiveValue<boolean>;
}>`
  ${({ showGradient }) =>
    map(showGradient, (show) =>
      show
        ? css`
            background: ${linearGradient({
              angle: '223.75deg',
              colorStops: [
                ['product.etf', '-1.6%'],
                ['indigo.100', '84.75%'],
              ],
            })};
          `
        : 'background: none;',
    )}
`;

const StyledFeatureImage = styled.img`
  ${width({ xs: '80%', sm: '220px', md: '280px', xl: '100%' })}
  height: auto;
  max-width: 100%;
`;

const StyledQrCodeSvg = styled(SVG)`
  height: 100%;
  width: 100%;
`;
