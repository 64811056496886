import { Status, useStatus } from '@spaceship-fspl/auth';
import { FetchFunction } from '@spaceship-fspl/fetch';
import { enumToString } from '@spaceship-fspl/helpers';
import { api, GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import { QueryKeys } from './helpers';
import { useAuthenticatedFetch, useFetch } from './react';

export const createUserSignupContact =
  (fetch: FetchFunction) =>
  async (
    payload: api.external.ICreateLeadRequestBody,
  ): Promise<api.external.ICreateLeadResponseBody> =>
    await fetch({
      url: '/user/lead',
      method: 'POST',
      body: api.external.CreateLeadRequestBody.create(payload),
    });

export type UseCreateUserSignupContactResponse = UseMutationResult<
  api.external.ICreateLeadResponseBody,
  undefined,
  api.external.ICreateLeadRequestBody,
  undefined
>;

export const useCreateUserSignupContact =
  (): UseCreateUserSignupContactResponse => {
    const fetch = useFetch();
    return useMutation(createUserSignupContact(fetch));
  };

export const updateUserSignupContact =
  (fetch: FetchFunction) =>
  async (
    payload: api.external.IUpdateLeadRequestBody,
  ): Promise<api.external.IUpdateLeadResponseBody> =>
    await fetch({
      url: '/user/lead',
      method: 'PUT',
      body: api.external.UpdateLeadRequestBody.create(payload),
    });

export type UseUpdateUserSignupContactResponse = UseMutationResult<
  api.external.IUpdateLeadResponseBody,
  undefined,
  api.external.IUpdateLeadRequestBody,
  undefined
>;

export const useUpdateUserSignupContact =
  (): UseUpdateUserSignupContactResponse => {
    const fetch = useFetch();
    return useMutation(updateUserSignupContact(fetch));
  };

export const createUserAccount =
  (fetch: FetchFunction) =>
  async (
    payload: api.external.ICreateUserRequestBody,
  ): Promise<api.external.ICreateUserResponseBody> =>
    await fetch({
      url: '/user',
      method: 'POST',
      body: api.external.CreateUserRequestBody.create(payload),
    });

export type UseCreateUserAccountResponse = UseMutationResult<
  api.external.ICreateUserResponseBody,
  undefined,
  api.external.ICreateUserRequestBody,
  undefined
>;

export const useCreateUserAccount = (): UseCreateUserAccountResponse => {
  const fetch = useFetch();
  return useMutation(createUserAccount(fetch));
};

export const migrateSuperWebUser =
  (fetch: FetchFunction) =>
  async (
    payload: api.external.IMigrateSuperWebUserRequestBody,
  ): Promise<void> => {
    await fetch({
      url: '/user/migrate-super-web',
      method: 'POST',
      body: api.external.MigrateSuperWebUserRequestBody.create(payload),
    });
  };

export type UseMigrateSuperWebUserResponse = UseMutationResult<
  void,
  undefined,
  api.external.IMigrateSuperWebUserRequestBody,
  undefined
>;

export const useMigrateSuperWebUser = (): UseMigrateSuperWebUserResponse => {
  const fetch = useFetch();
  return useMutation(migrateSuperWebUser(fetch));
};

export const getUser = async (
  fetch: FetchFunction,
): Promise<api.external.IGetUserResponseBody> => {
  return api.external.GetUserResponseBody.fromObject(
    await fetch({
      url: '/user',
      method: 'GET',
    }),
  );
};

export const useGetUser =
  (): UseQueryResult<api.external.IGetUserResponseBody> => {
    const authenticatedFetch = useAuthenticatedFetch();
    const isLoggedIn = useStatus() === Status.AUTHENTICATED;
    return useQuery([QueryKeys.GetUser], () => getUser(authenticatedFetch), {
      enabled: isLoggedIn,
    });
  };

export const initiateUserVerification =
  (fetch: FetchFunction) =>
  async (payload: api.external.IVerifyContactRequestBody): Promise<void> => {
    await fetch({
      url: '/user/verification',
      method: 'POST',
      body: api.external.VerifyContactRequestBody.create(payload),
    });
  };

export type UseInitiateUserVerificationResponse = UseMutationResult<
  void,
  undefined,
  api.external.IVerifyContactRequestBody,
  undefined
>;

export const useInitiateUserVerification = (
  ruleset: GreenIdRuleSet.Enum,
): UseInitiateUserVerificationResponse => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(initiateUserVerification(authenticatedFetch), {
    onSuccess: () =>
      queryClient.invalidateQueries([QueryKeys.GetVerification, ruleset]),
  });
};

export const setGreenIdFields =
  (fetch: FetchFunction) =>
  async (
    payload: api.external.ISetGreenIdFieldsRequestBody,
  ): Promise<api.external.ISetGreenIdFieldsResponseBody> =>
    await fetch({
      url: '/user/greenid/fields',
      method: 'POST',
      body: api.external.SetGreenIdFieldsRequestBody.create(payload),
    });

export type UseSetGreenIdFields = UseMutationResult<
  api.external.ISetGreenIdFieldsResponseBody,
  undefined,
  api.external.ISetGreenIdFieldsRequestBody,
  undefined
>;

export const useSetGreenIdFields = (
  ruleset: GreenIdRuleSet.Enum,
): UseSetGreenIdFields => {
  const authenticatedFetch = useAuthenticatedFetch();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: api.external.ISetGreenIdFieldsRequestBody) =>
      setGreenIdFields(authenticatedFetch)(payload),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([QueryKeys.GetVerification, ruleset]),
    },
  );
};

export const getUserVerification =
  (fetch: FetchFunction) =>
  async (
    ruleset: GreenIdRuleSet.Enum,
  ): Promise<api.external.GetUserVerificationResponseBody> =>
    api.external.GetUserVerificationResponseBody.fromObject(
      await fetch({
        method: 'GET',
        url: '/user/verification',
        query: {
          rule_set: enumToString(GreenIdRuleSet.Enum, ruleset),
        },
      }),
    );

export const useGetUserVerification = (
  ruleset: GreenIdRuleSet.Enum,
): UseQueryResult<api.external.IGetUserVerificationResponseBody> => {
  const authenticatedFetch = useAuthenticatedFetch();
  const isLoggedIn = useStatus() === Status.AUTHENTICATED;
  const enabled = isLoggedIn && !!ruleset;
  return useQuery(
    [QueryKeys.GetVerification, ruleset],
    () => getUserVerification(authenticatedFetch)(ruleset),
    { enabled },
  );
};
