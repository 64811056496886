import * as React from 'react';
export const StreamlineWeatherCloudRain = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M8.776 18.184l-3.75 3.75M14.026 18.184l-3.75 3.75M8.026 9.184a5.993 5.993 0 000 0 5.93 5.93 0 01.113-1.122" />
    <path vectorEffect="non-scaling-stroke" d="M14.026 15.184A6 6 0 108.14 8.062a3.745 3.745 0 10-1.613 7.122h7.5z" />
  </svg>
);
