const WEBSITE_PATH = process.env.WEBSITE_PATH || 'https://www.spaceship.com.au';
const documentsPath = `${WEBSITE_PATH}/documents`;
export const WEBAPP_PATH =
  process.env.WEBAPP_PATH || 'https://app.spaceship.com.au';

export const COMPLAINTS_POLICY = `${WEBSITE_PATH}/complaints-policy/`;
export const PRIVACY_POLICY = `${WEBSITE_PATH}/privacy-policy/`;
export const FINANCIAL_SERVICES_GUIDE = `${documentsPath}/spaceship_fsg.pdf`;
export const ASIC_STOP_ORDER_MESSAGE_JUNE_2023 = `${documentsPath}/a-message-from-spaceship.pdf`;
export const ASIC_STOP_ORDER_LIFTED_MESSAGE_JUNE_2023 = `${documentsPath}/a-message-from-spaceship-16-june-2023.pdf`;
export const IMPORTANT_DOCUMENTS = `${WEBSITE_PATH}/important-documents/`;
export const APP_TERMS = `${WEBSITE_PATH}/app-terms/`;
export const LEARN_BLOG = `${WEBSITE_PATH}/learn/`;
export const FAQS = 'https://help.spaceship.com.au/en/';
export const TAX_TIME_FAQ =
  'https://help.spaceship.com.au/en/articles/5390076-what-to-expect-at-tax-time-if-you-have-a-spaceship-voyager-portfolio';
export const ILLION_TERMS = `${documentsPath}/spaceship_illion_terms_and_conditions.pdf`;

export const ASIC_STOP_ORDER_TEMP_REDIRECT =
  ASIC_STOP_ORDER_LIFTED_MESSAGE_JUNE_2023;

export const VOYAGER_LEARN_MORE = `${WEBSITE_PATH}/voyager`;
export const VOYAGER_UNIVERSE = `${WEBSITE_PATH}/voyager/universe/`;
export const VOYAGER_ORIGIN = `${WEBSITE_PATH}/voyager/origin/`;
export const VOYAGER_EARTH = `${WEBSITE_PATH}/voyager/earth/`;
export const VOYAGER_GALAXY = `${WEBSITE_PATH}/voyager/galaxy/`;
export const VOYAGER_EXPLORER = `${WEBSITE_PATH}/voyager/explorer/`;
export const VOYAGER_UNIVERSE_PDS = `${documentsPath}/spaceship_voyager_universe_pds.pdf`;
export const VOYAGER_ORIGIN_PDS = `${documentsPath}/spaceship_voyager_origin_pds.pdf`;
export const VOYAGER_EARTH_PDS = `${documentsPath}/spaceship_voyager_earth_pds.pdf`;
export const VOYAGER_MASTER_FUND_PDS = `${documentsPath}/spaceship_master_fund_pds.pdf`;
export const VOYAGER_UNIVERSE_TMD = `${documentsPath}/spaceship_voyager_universe_tmd.pdf`;
export const VOYAGER_ORIGIN_TMD = `${documentsPath}/spaceship_voyager_origin_tmd.pdf`;
export const VOYAGER_EARTH_TMD = `${documentsPath}/spaceship_voyager_earth_tmd.pdf`;
export const VOYAGER_MASTER_FUND_TMD = `${documentsPath}/spaceship_master_fund_tmd.pdf`;
export const REFERENCE_GUIDE = `${documentsPath}/spaceship_voyager_reference_guide.pdf`;
export const VOYAGER_FEE_CHANGES_MARCH_2023 = `${WEBSITE_PATH}/important-documents/fee-changes-230123/`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2024 = `${documentsPath}/spaceship_voyager_universe_financial_report_2024.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2024 = `${documentsPath}/spaceship_voyager_origin_financial_report_2024.pdf`;
export const VOYAGER_EARTH_FINANCIAL_REPORT_2024 = `${documentsPath}/spaceship_voyager_earth_financial_report_2024.pdf`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2023 = `${documentsPath}/spaceship_voyager_universe_financial_report_2023.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2023 = `${documentsPath}/spaceship_voyager_origin_financial_report_2023.pdf`;
export const VOYAGER_EARTH_FINANCIAL_REPORT_2023 = `${documentsPath}/spaceship_voyager_earth_financial_report_2023.pdf`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2022 = `${documentsPath}/spaceship_voyager_universe_financial_report_2022.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2022 = `${documentsPath}/spaceship_voyager_origin_financial_report_2022.pdf`;
export const VOYAGER_EARTH_FINANCIAL_REPORT_2022 = `${documentsPath}/spaceship_voyager_earth_financial_report_2022.pdf`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2021 = `${documentsPath}/spaceship_voyager_universe_financial_report_2021.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2021 = `${documentsPath}/spaceship_voyager_origin_financial_report_2021.pdf`;
export const VOYAGER_EARTH_FINANCIAL_REPORT_2021 = `${documentsPath}/spaceship_voyager_earth_financial_report_2021.pdf`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2020 = `${documentsPath}/spaceship_voyager_universe_financial_report_2020.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2020 = `${documentsPath}/spaceship_voyager_origin_financial_report_2020.pdf`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2019 = `${documentsPath}/spaceship_voyager_universe_financial_report_2019.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2019 = `${documentsPath}/spaceship_voyager_origin_financial_report_2019.pdf`;

export const VOYAGER_UNIVERSE_FINANCIAL_REPORT_2018 = `${documentsPath}/spaceship_voyager_universe_financial_report_2018.pdf`;
export const VOYAGER_ORIGIN_FINANCIAL_REPORT_2018 = `${documentsPath}/spaceship_voyager_origin_financial_report_2018.pdf`;

export const SUPER_LEARN_MORE = `${WEBSITE_PATH}/super`;
export const SUPER_FIRST_HOME_SUPER_SAVER = `${WEBSITE_PATH}/super/first-home-super-saver`;
export const SUPER_PDS = `${documentsPath}/spaceship_super_product_disclosure_statement.pdf`;
export const SUPER_REFERENCE_GUIDE = `${documentsPath}/spaceship_super_reference_guide.pdf`;
export const SUPER_RETIREMENT_INCOME_STRATEGY_SUMMARY = `${documentsPath}/spaceship_super_retirement_income_strategy_summary.pdf`;
export const SUPER_STANDARD_RISK_MEASURE_CHANGE_NOTICE_2022 = `${documentsPath}/spaceship_super_standard_risk_measure_change_notice_2022.pdf`;
export const SUPER_LETTER_OF_COMPLIANCE = `${documentsPath}/spaceship_super_letter_of_compliance.pdf`;
export const SUPER_MEMBER_OUTCOMES_ASSESSMENT_2023 = `${documentsPath}/spaceship_moa_30_06_2023.pdf`;
export const SUPER_PORTFOLIO_HOLDINGS_DISCLOSURE_2022 = `${documentsPath}/spaceship_super_portfolio_holdings_disclosure_2022.xlsx`;
export const SUPER_PORTFOLIO_HOLDINGS_DISCLOSURE_2023 = `${documentsPath}/spaceship_super_portfolio_holdings_disclosure_2023_12_31.xlsx`;
export const SUPER_PORTFOLIO_HOLDINGS_DISCLOSURE_2024 = `${documentsPath}/spaceship_super_portfolio_holdings_disclosure_2024_06_30.xlsx`;
export const SUPER_NOTICE_OF_ANNUAL_MEMBER_MEETING_2023 = `${documentsPath}/spaceship_super_notice_of_annual_member_meeting_2023.pdf`;
export const SUPER_ANNUAL_MEMBER_MEETING_2023 = `${documentsPath}/spaceship_super_annual_member_meeting_2023.pdf`;
export const SUPER_NOTICE_OF_INVESTMENT_CHANGES_2023 = `${documentsPath}/notice_of_investment_changes_for_spaceship_super_2023_07_04.pdf`;
export const SUPER_NOTICE_OF_FEE_CHANGE_SEPT_2023 = `${documentsPath}/notice_of_fee_change_for_spaceship_super_growthx_2023_09_01.pdf`;
export const SUPER_SIGNIFICANT_EVENT_NOTICE_OCT_2023 = `${documentsPath}/spaceship_super_significant_event_notice_2023_10_13.pdf`;
export const SUPER_SIGNIFICANT_EVENT_NOTICE_APR_2024 = `${documentsPath}/spaceship_super_significant_event_notice_2024_04_15.pdf`;
export const SUPER_SIGNIFICANT_EVENT_NOTICE_MAY_2024 = `${documentsPath}/spaceship_super_significant_event_notice_2024_05_29.pdf`;
export const SUPER_PDS_UPDATE_MAY_2024 = `${documentsPath}/spaceship_super_pds_update_2024_05_02.pdf`;
export const SUPER_ANNUAL_REPORT_2023 = `${documentsPath}/spaceship_super_annual_report_2023.pdf`;

export const NOVA_FEE_SCHEDULE = `${documentsPath}/spaceship_us_investing_fee_schedule.pdf`;
export const NOVA_TERMS = `${documentsPath}/spaceship_us_investing_terms_and_conditions.pdf`;
export const NOVA_W8BEN = `${documentsPath}/fw8ben.pdf`;

export const SIGNUP = '/signup/';
