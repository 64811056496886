import { differenceInMonths, getWeekOfMonth, getWeeksInMonth } from 'date-fns';

const YEAR_FREQUENCY = {
  week: 52,
  fortnight: 26,
  month: 12,
};

/**
 * Further reading:
 * Government Calculator:
 * www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/compound-interest-calculator
 */
export function calcCompoundedReturn({
  initialDeposit,
  compoundRate,
  amount,
  frequency,
  period,
}: {
  initialDeposit: number;
  compoundRate: number;
  amount: number;
  frequency: 'week' | 'fortnight' | 'month';
  period: number;
}): number {
  const frequencyInYear = YEAR_FREQUENCY[frequency];
  const principal = initialDeposit * Math.pow(1 + compoundRate, period);
  const contributionPrincipal =
    amount *
    frequencyInYear *
    ((Math.pow(1 + compoundRate, period) - 1) / compoundRate);

  return principal + contributionPrincipal;
}

// Get the number of months between inception date and performance as at date
// Note: Approved by Diversa
export function calcInceptionToPerformanceAsAtDateInMonths(
  inceptionDate: Date,
  performanceAsAtDate: Date,
): number {
  const months = differenceInMonths(performanceAsAtDate, inceptionDate);
  let totalMonths;

  // check if performance as at date is the last week of the month (this is usually the case)
  if (
    getWeekOfMonth(performanceAsAtDate) === getWeeksInMonth(performanceAsAtDate)
  ) {
    // if the inception date is in the first week of the month, include it in the calculations
    totalMonths = getWeekOfMonth(inceptionDate) === 1 ? months + 1 : months;
  } else {
    totalMonths = months;
  }

  return totalMonths;
}
