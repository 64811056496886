import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import { Modal, ModalProps } from 'components/modal';
import React from 'react';

export interface VoyagerPortfolioMixModalProps extends ModalProps {
  portfolioName: string;
  portfolio: SaverPortfolio.Enum | null;
}

export const VoyagerPortfolioMixModal: React.FC<
  VoyagerPortfolioMixModalProps
> = ({ portfolioName, portfolio, showModal, closeModal }) => (
  <Modal
    showModal={showModal}
    closeModal={closeModal}
    maxWidth={800}
    scrollable={
      portfolio === SaverPortfolio.Enum.EXPLORER ||
      portfolio === SaverPortfolio.Enum.GALAXY
    }
  >
    <Box paddingX={{ xs: 'md', md: 'xl' }} paddingY="xl">
      <Stack spaceY="sm">
        <Heading variant={3}>Portfolio mix for {portfolioName}</Heading>

        {(portfolio === SaverPortfolio.Enum.EARTH ||
          portfolio === SaverPortfolio.Enum.INDEX ||
          portfolio === SaverPortfolio.Enum.UNIVERSE) && (
          <>
            <Text variant={2} color="neutral.085">
              This portfolio has a single investment strategy and is
              concentrated in stocks.
            </Text>

            <Heading variant={5}>85-100% in shares</Heading>

            <Text variant={3} color="neutral.085">
              The fund always holds international and Australian shares within
              this range.
            </Text>

            <Heading variant={5}>0-15% in cash</Heading>

            <Text variant={3} color="neutral.085">
              The fund holds cash to facilitate buying and selling of shares, as
              well as money moving in and out of the fund. Cash will never
              constitute more than 15% of holdings in the portfolio.
            </Text>
          </>
        )}
        {(portfolio === SaverPortfolio.Enum.GALAXY ||
          portfolio === SaverPortfolio.Enum.EXPLORER) && (
          <>
            <Text variant={2} color="neutral.085">
              This portfolio has a multi-asset investment strategy and invests
              in stocks, bonds and cash.
            </Text>

            {portfolio === SaverPortfolio.Enum.EXPLORER && (
              <Heading variant={5}>0-15% in Australian shares</Heading>
            )}
            {portfolio === SaverPortfolio.Enum.GALAXY && (
              <Heading variant={5}>5-15% in Australian shares</Heading>
            )}

            <Text variant={3} color="neutral.085">
              The portfolio may gain this exposure through ETFs managed by other
              investment managers or by directly holding equities managed by
              Spaceship&apos;s investment team.
            </Text>

            {portfolio === SaverPortfolio.Enum.EXPLORER && (
              <Heading variant={5}>0-25% in international shares</Heading>
            )}
            {portfolio === SaverPortfolio.Enum.GALAXY && (
              <Heading variant={5}>5-40% in international shares</Heading>
            )}

            <Text variant={3} color="neutral.085">
              The portfolio may gain this exposure through ETFs managed by other
              investment managers or by directly holding equities managed by
              Spaceship&apos;s investment team.
            </Text>

            {portfolio === SaverPortfolio.Enum.EXPLORER && (
              <Heading variant={5}>20-80% in bonds</Heading>
            )}
            {portfolio === SaverPortfolio.Enum.GALAXY && (
              <Heading variant={5}>15-75% in bonds</Heading>
            )}

            <Text variant={3} color="neutral.085">
              The portfolio gains exposure to Australian and international bonds
              through ETFs managed by other investment managers.
            </Text>

            {portfolio === SaverPortfolio.Enum.EXPLORER && (
              <Heading variant={5}>5-40% in cash</Heading>
            )}
            {portfolio === SaverPortfolio.Enum.GALAXY && (
              <Heading variant={5}>5-30% in cash</Heading>
            )}

            <Text variant={3} color="neutral.085">
              The portfolio may gain exposure to cash either by directly holding
              cash or through ETFs managed by other investment managers.
            </Text>
          </>
        )}
      </Stack>
    </Box>
  </Modal>
);
