import { RouteComponentProps } from '@reach/router';
import { Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { useGetUser } from '@spaceship-fspl/data';
import { useSendEmployerContributionEmail } from '@spaceship-fspl/graphql';
import { validateEmail } from '@spaceship-fspl/helpers';
import { SuperEmployerContributionShareOption as ShareOption } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormSkipButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import { SuperMemberDetailsAccordion } from 'components/super-member-details';
import { useNotifications } from 'contexts/notifications';
import {
  SuperEmployerContributionForm,
  useEmployerContributionContext,
} from 'contexts/super/employer-contribution';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { addRumError } from 'helpers/monitoring';
import React from 'react';
import { useForm } from 'react-hook-form';

export const SuperEmployerContribution: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const track = useTrack();
  const { variant, setShareOption, onSubmitSuccess, onSkip } =
    useEmployerContributionContext();
  const isSignup = variant === 'onboarding';
  const Container = isSignup ? OnboardingContainer : PageContainer;
  const { data: user } = useGetUser();
  const [sendEmail, sendEmailMeta] = useSendEmployerContributionEmail();
  const { popToast } = useNotifications();
  const { control, handleSubmit, register, watch } =
    useForm<SuperEmployerContributionForm>({
      defaultValues: {
        shareOption: ShareOption.EMPLOYER,
      },
    });
  const shareOptionValue = watch('shareOption');

  const handleSend = handleSubmit(async ({ shareOption, employerEmail }) => {
    const shareWithEmployer = shareOption === ShareOption.EMPLOYER;

    try {
      await sendEmail({
        variables: {
          input: shareWithEmployer
            ? {
                employerEmailAddress: employerEmail,
              }
            : {},
        },
      });

      track?.(MarketingTrackingEvents.SUPER_EMPLOYER_CONTRIBUTION_SENT);
      setShareOption(shareOption);
      onSubmitSuccess();
    } catch (error) {
      addRumError({ error });
      popToast({
        level: 'error',
        message:
          'Oops, we had trouble submitting your request. Please try again in a few minutes.',
      });
      return;
    }
  });

  return (
    <Container>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <form onSubmit={handleSend}>
            <Stack spaceY="md">
              <Stack spaceY="lg">
                <PageHeading
                  title="Share your super member details"
                  subtitle={`${
                    !isSignup ? 'Started a new job? ' : ''
                  }Share your member details with your new employer so they can contribute to your Spaceship account.`}
                />
                <SuperMemberDetailsAccordion />
              </Stack>

              <RadioCard
                value={ShareOption.EMPLOYER}
                {...register('shareOption')}
              >
                <Stack spaceY="xxs">
                  <Heading variant={5} component="h3">
                    Send to your employer by email
                  </Heading>

                  {shareOptionValue === ShareOption.EMPLOYER && (
                    <Stack spaceY="sm">
                      <Text variant={2} color="neutral.085">
                        Enter your employer&apos;s email address below to send
                        them a copy of your member details. A copy will also be
                        emailed to you.
                      </Text>

                      <ControllerInputWithInlineError
                        name="employerEmail"
                        control={control}
                        type="text"
                        placeholder="Email address"
                        rules={{
                          required: 'The email address is required',
                          validate: (value?: string) => {
                            if (value) {
                              return validateEmail(value);
                            }
                            return false;
                          },
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
              </RadioCard>

              <RadioCard value={ShareOption.USER} {...register('shareOption')}>
                <Stack spaceY="xxs">
                  <Heading variant={5} component="h3">
                    I don&apos;t have my employer’s email
                  </Heading>

                  {shareOptionValue === ShareOption.USER && (
                    <Text variant={2} color="neutral.085">
                      A copy will be sent to your email{' '}
                      <strong>{user?.contact?.email_address}</strong>. Simply
                      forward the email or print and hand the form to your
                      employer.
                    </Text>
                  )}
                </Stack>
              </RadioCard>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                isLoading={sendEmailMeta.loading}
                trackingProperties={{
                  name: `${
                    isSignup ? 'signup_' : ''
                  }super_employer_contribution_send`,
                }}
              >
                Send
              </PageFormContinueButton>

              {isSignup && onSkip && (
                <PageFormSkipButton
                  trackingProperties={{
                    name: 'signup_super_employer_contribution_skip',
                  }}
                  onClick={onSkip}
                  isDisabled={sendEmailMeta.loading}
                />
              )}
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </Container>
  );
};
