import * as React from 'react';
export const VoyagerEarthStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M2.286 1.7l-.386.193s-.16.083-.264.224a1.28 1.28 0 00-.181.375l-.209.708-.211-.708a1.26 1.26 0 00-.181-.375c-.102-.135-.261-.22-.264-.224L.2 1.7l.39-.196s.16-.083.264-.221c.113-.147.18-.371.18-.374L1.245.2l.21.709c.02.064.087.254.182.374.107.135.264.22.264.22l.386.197zm21.577 16.788l-.307.13s-.126.054-.212.15a.771.771 0 00-.144.248l-.166.472-.168-.472a.845.845 0 00-.145-.249c-.082-.092-.208-.147-.211-.15l-.31-.129.31-.132s.126-.055.211-.147c.093-.098.145-.248.145-.248l.165-.473.169.473a.83.83 0 00.144.248.735.735 0 00.212.147l.307.132zm-2.049 3.696l-.53.218s-.221.095-.362.251c-.157.163-.252.418-.252.418l-.285.797-.291-.797a1.347 1.347 0 00-.252-.418c-.141-.156-.362-.251-.362-.251l-.537-.218.537-.218s.218-.095.362-.251c.157-.163.252-.417.252-.417l.285-.798.291.798c.025.07.123.285.252.417.141.156.362.251.362.251l.53.218zM21.27 2.882a.6.6 0 010 .849L3.592 21.409a.6.6 0 01-.848-.849L20.42 2.882a.6.6 0 01.849 0z" />
    <path d="M19.277 4.134a.6.6 0 01-.466.71c-5.666 1.172-9.18 4.677-11.289 7.918a22.921 22.921 0 00-2.187 4.314 19.302 19.302 0 00-.463 1.362 12.204 12.204 0 00-.128.463l-.005.022-.001.004m0 0a.6.6 0 01-1.17-.27l.585.136-.584-.136v-.004l.003-.008.007-.03.028-.111c.025-.095.062-.233.114-.407.103-.349.263-.845.492-1.447a24.12 24.12 0 012.303-4.542c2.225-3.42 5.98-7.183 12.052-8.44a.6.6 0 01.71.466m.867.636a.6.6 0 01.465.709c-1.256 6.072-5.02 9.827-8.44 12.052a24.124 24.124 0 01-4.541 2.303c-.602.23-1.099.389-1.448.492a13.421 13.421 0 01-.517.142l-.03.008-.009.002h-.002s-.002 0-.137-.584l.135.585a.6.6 0 01-.27-1.17m0 0h.005l.022-.006.093-.024a12.4 12.4 0 00.37-.103c.323-.096.79-.246 1.362-.463a22.922 22.922 0 004.313-2.188c3.242-2.11 6.747-5.622 7.92-11.29a.6.6 0 01.709-.465" />
    <path d="M20.7 5.913a.6.6 0 01.65.544c.346 3.88-.408 6.79-1.784 8.958-1.374 2.162-3.332 3.53-5.304 4.394-1.969.862-3.97 1.23-5.47 1.383-.752.078-1.383.102-1.829.106a14.432 14.432 0 01-.663-.008l-.039-.002h-.014s-.002 0 .033-.6l-.034.6a.6.6 0 01.068-1.199m0 0h.008l.029.002.122.004c.109.003.27.006.478.003a18.91 18.91 0 001.718-.1c1.425-.146 3.294-.493 5.112-1.289 1.814-.794 3.558-2.027 4.772-3.938 1.21-1.906 1.929-4.542 1.602-8.207a.6.6 0 01.544-.651M3.958 17.964v-.007l-.002-.029a13.235 13.235 0 01-.007-.6c.005-.415.027-1.009.1-1.718.146-1.425.493-3.294 1.29-5.112.794-1.815 2.027-3.558 3.937-4.772 1.907-1.21 4.542-1.929 8.208-1.602a.6.6 0 10.106-1.195c-3.88-.346-6.79.408-8.957 1.784-2.162 1.374-3.53 3.332-4.394 5.304-.862 1.969-1.23 3.97-1.384 5.47a20.115 20.115 0 00-.106 1.829 14.408 14.408 0 00.008.663l.002.038v.011l.001.004s0 .002.6-.033l-.6.034a.6.6 0 001.198-.069z" />
    <path d="M4.588 4.325a.6.6 0 01.849 0l14.47 14.469a.6.6 0 01-.85.848L4.589 5.173a.6.6 0 010-.848zM9.48 1.551a.6.6 0 01.848 0l12.282 12.28a.6.6 0 11-.848.848L9.48 2.399a.6.6 0 010-.848zM1.815 8.69a.6.6 0 01.848 0L15.21 21.236a.6.6 0 11-.848.848L1.815 9.538a.6.6 0 010-.848z" />
    <path d="M12 2.1c-5.468 0-9.9 4.432-9.9 9.9 0 5.467 4.432 9.9 9.9 9.9 5.467 0 9.9-4.433 9.9-9.9 0-5.468-4.433-9.9-9.9-9.9zM.9 12C.9 5.87 5.87.9 12 .9S23.1 5.87 23.1 12 18.13 23.1 12 23.1.9 18.13.9 12z" />
    <path d="M7.27 8.494a1.702 1.702 0 100-3.405 1.702 1.702 0 000 3.405z" />
  </svg>
);
