import {
  Color,
  color,
  fontFamily,
  fontWeight,
  map,
  ResponsiveValue,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

import { UnstyledLink, UnstyledLinkProps } from '../unstyled-link';

type ActionLinkSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

const size = createVariant({
  xxs: css`
    font-size: 12px;
  `,
  xs: css`
    font-size: 14px;
  `,
  sm: css`
    font-size: 16px;
  `,
  md: css`
    font-size: 20px;
  `,
  lg: css`
    font-size: 24px;
  `,
});

const props = createProps({
  $size: (s: ResponsiveValue<ActionLinkSize>) => map(s, (ss) => size(ss)),
  $color: color,
});

const Wrapper = styled(UnstyledLink)`
  color: inherit;
  ${props}
  ${createProps({ $color: color })}
  ${fontFamily('text')}
  ${fontWeight(600)}

  :hover {
    text-decoration: underline;
  }
`;

export interface ActionLinkProps extends UnstyledLinkProps {
  color?: Color;
  size?: ResponsiveValue<ActionLinkSize>;
}

export const ActionLink = React.forwardRef<HTMLAnchorElement, ActionLinkProps>(
  ({ size = 'xxs', color, children, ...otherProps }, ref) => {
    return (
      <Wrapper $size={size} $color={color} {...otherProps} ref={ref}>
        {children}
      </Wrapper>
    );
  },
);

ActionLink.displayName = 'ActionLink';
