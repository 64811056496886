import {
  Box,
  Columns,
  Divider,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderWidth,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import React from 'react';
import {
  Controller,
  Path,
  PathValue,
  UnpackNestedValue,
  useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';

import { Button } from './button';
import { ControllerTextArea } from './controller-input';
import { Radio } from './radio';

const ValidationError = ({
  message,
}: {
  message: string | undefined;
}): JSX.Element => (
  <Inline spaceX="xs" alignY="center" alignX="center">
    <Box lineHeight={0}>
      <FeatherAlertTriangleIcon size="md" color="red.100" />
    </Box>
    <Text color="red.100" variant={2} isBold={true}>
      {message}
    </Text>
  </Inline>
);

interface TMDFormMultiQuestionProps<T> {
  name: Path<T>;
  questionTitle: React.ReactNode;
  options: Array<{
    icon?: React.ReactNode;
    value: string;
    questionBody: React.ReactNode;
    subquestion?: {
      name: Path<T>;
      questionBody: React.ReactNode;
      prompts?: Array<UnpackNestedValue<PathValue<T, Path<T>>>>;
      options?: Array<{
        value: string;
        questionBody: string;
        subquestionReason?: {
          questionBody: string;
          name: Path<T>;
        };
      }>;
    };
  }>;
}

// Radio question form component, where each option may have a sub-question option.
// This is a semi-generic component, but does cater to the Voyager/Super TMD forms.
export function TMDFormMultiQuestion<T extends object>({
  questionTitle,
  name,
  options,
}: TMDFormMultiQuestionProps<T>): React.ReactElement {
  const form = useFormContext<T>();

  return (
    <Stack spaceY="sm" data-testid={name}>
      <Text variant={1} isBold={true} align="left">
        {questionTitle}
      </Text>

      <Controller
        name={name}
        rules={{ required: 'Please select an option' }}
        control={form.control}
        render={({ field, fieldState }) => (
          <Stack spaceY="xs">
            {fieldState.error && (
              <ValidationError message={fieldState.error.message} />
            )}

            {options.map((opt, key) => (
              <StyledOptionBox key={key} checked={field.value === opt.value}>
                <Radio
                  {...field}
                  value={opt.value}
                  error={!!fieldState.error}
                  radioPosition="right"
                  onChange={(event) => {
                    // Reset subquestion to make sure we never keep old subquestion values after the question has
                    // demounted.
                    if (opt.subquestion) {
                      form.resetField(opt.subquestion.name);
                    }
                    field.onChange(event);
                  }}
                  padding="md"
                >
                  {typeof opt.questionBody === 'string' ? (
                    <Box display="flex">
                      {opt.icon ? <Box marginRight="xs">{opt.icon}</Box> : null}
                      <Text variant={2} isBold={true}>
                        {opt.questionBody}
                      </Text>
                    </Box>
                  ) : (
                    opt.questionBody
                  )}
                </Radio>

                {opt.subquestion && field.value === opt.value && (
                  <>
                    <Divider size="md" />

                    <Box padding="md">
                      <Stack spaceY="sm">
                        <Text
                          variant={2}
                          isBold={false}
                          component="div"
                          id={`${opt.value}_${opt.subquestion.name}`}
                        >
                          {opt.subquestion.questionBody}
                        </Text>

                        {!opt.subquestion?.options && (
                          <>
                            <ControllerTextArea
                              name={opt.subquestion?.name}
                              control={form.control}
                              aria-labelledby={`${opt.value}_${opt.subquestion.name}`}
                              rules={{
                                required: 'Your response is required',
                                maxLength: {
                                  value: 1000,
                                  message: 'Maximum 1000 characters allowed',
                                },
                                minLength: {
                                  value: 50,
                                  message: 'Minimum 50 characters needed',
                                },
                              }}
                            />

                            {opt.subquestion.prompts && (
                              <Stack spaceY="xxs">
                                <Text variant={3} isBold={true}>
                                  Optional starters:
                                </Text>

                                <Columns
                                  spaceX={{ xl: 'xxs' }}
                                  spaceY={{ xs: 'xxs', xl: 'none' }}
                                >
                                  {opt.subquestion.prompts.map(
                                    (prompt, index) => (
                                      <Columns.Column
                                        width={{ xs: 1, xl: 1 / 2 }}
                                        key={index}
                                      >
                                        <StyledPromptButton
                                          variant="tertiary"
                                          size="sm"
                                          trackingProperties={{
                                            name: 'tmd_prompt',
                                          }}
                                          onClick={() => {
                                            if (opt.subquestion?.name) {
                                              form.setValue(
                                                opt.subquestion.name,
                                                prompt as PathValue<T, Path<T>>,
                                              );
                                            }
                                          }}
                                        >
                                          {prompt as unknown as string}...
                                        </StyledPromptButton>
                                      </Columns.Column>
                                    ),
                                  )}
                                </Columns>
                              </Stack>
                            )}
                          </>
                        )}
                      </Stack>
                    </Box>

                    {opt.subquestion?.options && (
                      <Controller
                        name={opt.subquestion.name}
                        rules={{
                          required: 'Please select an option',
                        }}
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <Box paddingY="sm">
                            <Stack spaceY="xs">
                              {fieldState.error && (
                                <ValidationError
                                  message={fieldState.error.message}
                                />
                              )}

                              {opt.subquestion?.options?.map(
                                (subquestion, key, arr) => (
                                  <div key={key}>
                                    <Box paddingY="xs">
                                      <Radio
                                        {...field}
                                        value={subquestion.value}
                                        error={!!fieldState.error}
                                        radioPosition="right"
                                        paddingLeft="md"
                                        paddingRight="md"
                                        paddingBottom="sm"
                                      >
                                        <Text variant={2} isBold={true}>
                                          {subquestion.questionBody}
                                        </Text>
                                      </Radio>
                                      {subquestion.subquestionReason &&
                                        field.value === subquestion.value && (
                                          <Box padding="md">
                                            <Text
                                              variant={2}
                                              isBold={false}
                                              component="div"
                                              id={`${subquestion.value}_${subquestion.subquestionReason.name}`}
                                            >
                                              {
                                                subquestion.subquestionReason
                                                  .questionBody
                                              }
                                            </Text>
                                            <ControllerTextArea
                                              control={form.control}
                                              name={
                                                subquestion.subquestionReason
                                                  .name
                                              }
                                              data-testid={
                                                subquestion.subquestionReason
                                                  .name
                                              }
                                              rules={{
                                                required:
                                                  'Your response is required',
                                                maxLength: {
                                                  value: 500,
                                                  message:
                                                    'Maximum 1000 characters allowed',
                                                },
                                                minLength: {
                                                  value: 50,
                                                  message:
                                                    'Minimum 50 characters needed',
                                                },
                                              }}
                                            />
                                          </Box>
                                        )}
                                      {key !== arr.length - 1 && (
                                        <Divider size="md" key={key} />
                                      )}
                                    </Box>
                                  </div>
                                ),
                              )}
                            </Stack>
                          </Box>
                        )}
                      />
                    )}
                  </>
                )}
              </StyledOptionBox>
            ))}
          </Stack>
        )}
      />
    </Stack>
  );
}

const StyledOptionBox = styled(Box).attrs({
  borderRadius: 'sm',
  boxShadow: 'sm',
})<{ checked: boolean }>`
  ${backgroundColor('neutral.000')}
  ${transition}

  ${borderColor('neutral.000')}
  ${borderWidth('md')}
  border-style: solid;

  ${({ checked }) => checked && borderColor('indigo.070')}
  }

  :hover {
    ${({ checked }) => !checked && borderColor('indigo.020')}
    }
  }
`;

const StyledPromptButton = styled(Button)`
  ${paddingX('xs')}
  ${paddingY('xxxs')}
  border-radius: 20px;
  min-height: 40px;
  height: auto;
  text-align: left;
`;
