import { RouteComponentProps, useNavigate } from '@reach/router';
import { Success } from 'components/layouts/success';
import { Routes } from 'pages/routes';
import React from 'react';

interface BoostsRecipeSetupSuccessProps {
  location: {
    state: {
      title: string;
    };
  };
}

export const BoostsRecipeSetupSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps<BoostsRecipeSetupSuccessProps>>
> = (props) => {
  const navigate = useNavigate();
  return (
    <Success
      title={props.location?.state?.title ?? ''}
      buttonText="View my boosts"
      onContinue={{
        onClick: () => navigate(Routes.BOOSTS_DASHBOARD_BOOSTS),
        trackingProperties: {
          name: 'voyager_boost_recipe_setup_done',
        },
      }}
      extraButtons={[
        {
          title: 'Add another boost',
          onClick: () => navigate(Routes.BOOSTS_RECIPE_SELECT),
          trackingProperties: {
            name: 'voyager_boost_recipe_setup_add_another',
          },
        },
      ]}
    />
  );
};
