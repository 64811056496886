import * as React from 'react';
export const StreamlineTree = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M19.367 12.733a7.334 7.334 0 01-14.667 0C4.7 7.6 9.833 1 12.033 1s7.334 6.6 7.334 11.733zM12.033 8.333V23M15.7 12.733a3.666 3.666 0 01-3.667 3.667M12.033 13.467A2.933 2.933 0 019.1 10.533" />
  </svg>
);
