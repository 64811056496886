import { Box, Columns, Stack } from '@spaceship-fspl/components';
import { ConfettiCannon } from 'components/confetti/cannon';
import { CenterOnboardingContainer } from 'components/layouts/onboarding';
import {
  CenterPageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormExtraButton,
  PageHeading,
} from 'components/layouts/page';
import { SuccessIcon } from 'components/success-icon';
import { TrackingProperties } from 'helpers/analytics';
import React from 'react';

export interface SuccessProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  buttonText?: React.ReactNode;
  onContinue: {
    onClick: () => void;
    trackingProperties: TrackingProperties;
  };
  isOnboarding?: boolean;
  showConfetti?: boolean;
  extraButtons?: Array<{
    title: string;
    onClick: () => void;
    trackingProperties: TrackingProperties;
  }>;
}

export const Success: React.FC<React.PropsWithChildren<SuccessProps>> = ({
  title,
  subtitle,
  buttonText,
  onContinue,
  isOnboarding = false,
  showConfetti = false,
  extraButtons,
}) => {
  const Container = isOnboarding
    ? CenterOnboardingContainer
    : CenterPageContainer;

  return (
    <>
      {showConfetti && <ConfettiCannon delay={1000} />}

      <Container>
        <Columns alignX="center" alignY="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginY={{ xs: 'none', lg: 'md' }}
            >
              <Stack spaceY="md" alignX="center">
                <Box height={96} width={96}>
                  <SuccessIcon />
                </Box>

                <PageHeading title={title} subtitle={subtitle} />
              </Stack>

              <PageFormButtonContainer>
                <PageFormContinueButton {...onContinue}>
                  {buttonText || 'Continue'}
                </PageFormContinueButton>
                {extraButtons?.map(({ title, ...buttonProps }, index) => (
                  <PageFormExtraButton key={index} {...buttonProps}>
                    {title}
                  </PageFormExtraButton>
                ))}
              </PageFormButtonContainer>
            </Box>
          </Columns.Column>
        </Columns>
      </Container>
    </>
  );
};
