import * as React from 'react';
export const StreamlineGraphStatsAscend = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M23 12.6V6h-6.6" />
    <path vectorEffect="non-scaling-stroke" d="M23 6l-7.763 7.763a1.467 1.467 0 01-2.074 0l-3.059-3.059a1.467 1.467 0 00-2.075 0L1 17.734" />
  </svg>
);
