import { useCanReadSuper } from '@spaceship-fspl/auth';
import { useGetMemberBalance } from '@spaceship-fspl/super';

export const useIsEligibleForSuperReferrals = (): boolean | undefined => {
  const isSuperUser = useCanReadSuper();
  const memberBalanceResp = useGetMemberBalance();

  const isFinancialMember = memberBalanceResp.isFetched
    ? Number(memberBalanceResp?.data?.closingBalance?.amount ?? 0) > 0
    : undefined;

  return isSuperUser && isFinancialMember;
};
