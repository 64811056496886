import * as React from 'react';
export const EtfProductStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M2.709 15.397c-.317.533-.339.89-.246 1.115.126.303.573.615 1.596.724a.5.5 0 11-.107.995c-1.076-.115-2.058-.482-2.412-1.336-.27-.65-.076-1.361.31-2.01.39-.657 1.03-1.346 1.843-2.028 1.629-1.37 4.041-2.79 6.85-3.955a34.773 34.773 0 011.765-.677.5.5 0 11.332.943c-.563.199-1.136.418-1.714.658-2.741 1.136-5.058 2.509-6.59 3.796-.768.646-1.316 1.251-1.627 1.775zM14.963 7.93a.5.5 0 01.371-.602c1.762-.417 3.352-.598 4.588-.504.617.046 1.171.162 1.62.37.454.21.83.528 1.02.985.26.629.087 1.313-.27 1.94a.5.5 0 11-.868-.495c.287-.505.304-.845.214-1.062-.067-.159-.216-.321-.515-.46-.303-.14-.729-.24-1.277-.281-1.094-.083-2.573.076-4.281.48a.5.5 0 01-.602-.37zm5.781 3.328a.5.5 0 01-.041.706c-.442.393-.949.79-1.511 1.185a.5.5 0 01-.575-.819c.536-.376 1.013-.75 1.421-1.114a.5.5 0 01.706.042zM5.798 17.743a.5.5 0 01.439-.554 20.48 20.48 0 002.54-.474.5.5 0 01.24.97c-.948.235-1.845.402-2.665.497a.5.5 0 01-.554-.44zm7.991 1.683c2.21 0 4.003-1.892 4.003-4.226 0-2.333-1.792-4.225-4.003-4.225-2.211 0-4.003 1.892-4.003 4.225 0 2.334 1.792 4.226 4.003 4.226z" />
    <path d="M21.128 11.864a1.112 1.112 0 100-2.224 1.112 1.112 0 000 2.224zM5.115 18.98a1.112 1.112 0 100-2.223 1.112 1.112 0 000 2.224zm8.897-8.896a1.557 1.557 0 100-3.113 1.557 1.557 0 000 3.113z" />
    <path d="M2.916 18.15c-.107.704.016 1.173.27 1.462.383.437 1.224.621 2.608.292 1.341-.318 3.014-1.08 4.808-2.23a.5.5 0 01.54.843c-1.85 1.184-3.629 2.007-5.117 2.36-1.445.343-2.812.283-3.592-.606-.522-.596-.636-1.41-.506-2.27.13-.863.514-1.843 1.089-2.874 1.15-2.066 3.122-4.435 5.633-6.635 1.804-1.582 3.656-2.873 5.368-3.797 1.703-.919 3.302-1.493 4.595-1.601a.5.5 0 11.084.996c-1.099.092-2.558.596-4.205 1.485-1.638.884-3.428 2.13-5.183 3.669-2.448 2.145-4.337 4.428-5.419 6.37-.542.973-.867 1.835-.973 2.536zm17.52-14.32a.5.5 0 01.706.046c.422.482.578 1.11.553 1.785-.026.671-.23 1.421-.567 2.21-.677 1.58-1.929 3.408-3.592 5.233a.5.5 0 11-.74-.674c1.618-1.774 2.794-3.51 3.412-4.952.31-.722.468-1.348.487-1.854.02-.504-.1-.854-.306-1.089a.5.5 0 01.047-.706z" />
    <path d="M19.794 4.747a1.112 1.112 0 100-2.224 1.112 1.112 0 000 2.224zM7.318 3.412l-.455.228s-.187.097-.31.263c-.133.17-.213.437-.213.44l-.245.834-.249-.834a1.482 1.482 0 00-.213-.44c-.119-.159-.306-.26-.31-.263l-.458-.228.458-.23s.188-.098.31-.26a1.55 1.55 0 00.213-.44l.245-.834.25.834c.021.076.1.3.212.44.126.159.31.26.31.26l.455.23zM4.874 7.69l-.36.15s-.148.066-.25.178a.9.9 0 00-.169.292l-.195.555-.198-.555a.995.995 0 00-.17-.292c-.097-.109-.245-.174-.249-.177l-.364-.152.364-.155s.148-.065.25-.173a.95.95 0 00.169-.292l.195-.556.198.556c.018.05.083.198.17.292a.865.865 0 00.248.173l.361.155zM20.89 22.115l-.624.257s-.26.111-.426.295a1.66 1.66 0 00-.296.491l-.335.938-.343-.938a1.582 1.582 0 00-.296-.49 1.379 1.379 0 00-.425-.296l-.631-.257.63-.256s.257-.112.427-.296c.183-.19.295-.49.295-.49l.336-.938.342.938c.03.083.145.335.296.49.166.184.426.296.426.296l.624.256z" />
  </svg>
);
