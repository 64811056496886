import * as React from 'react';
export const StreamlineSpaceRocketFlying = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M22.5 1.567l-2.158.24a7.5 7.5 0 00-4.475 2.15L6.06 13.766l4.24 4.242L20.109 8.2a7.493 7.493 0 002.15-4.475l.24-2.158zM6.06 13.765l-3.622-1.208a.749.749 0 01-.293-1.241l.232-.232a6 6 0 016.14-1.45l1.255.418-3.712 3.713z" />
    <path vectorEffect="non-scaling-stroke" d="M6.06 13.765l-3.622-1.208a.749.749 0 01-.293-1.241l.232-.232a6 6 0 016.14-1.45l1.255.418-3.712 3.713zM10.3 18.007l1.208 3.622a.749.749 0 001.241.293l.233-.232a6 6 0 001.449-6.14l-.416-1.25-3.715 3.707z" />
    <path vectorEffect="non-scaling-stroke" d="M10.3 18.007l1.208 3.622a.749.749 0 001.241.293l.233-.232a6 6 0 001.449-6.14l-.416-1.25-3.715 3.707zM7.779 18.538c0 .597-.236 1.17-.659 1.59-.878.879-5.3 2.122-5.3 2.122s1.243-4.425 2.12-5.3c.387-.387.9-.62 1.445-.655" />
  </svg>
);
