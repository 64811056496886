import * as React from 'react';
export const StreamlineSavingBank1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1.75 23h20.533M1.75 20.067h20.533M3.217 9.8v7.333M6.15 9.8v7.333M10.55 9.8v7.333M13.483 9.8v7.333M17.883 9.8v7.333M20.817 9.8v7.333M22.283 6.867H1.75l9.474-5.634a1.467 1.467 0 011.586 0l9.473 5.634z" />
  </svg>
);
