import * as React from 'react';
export const StreamlineShieldWarning = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M2.5 4.093v7.418c0 4.8 2.956 9.105 7.436 10.828l1.015.39c.675.26 1.423.26 2.098 0l1.015-.39A11.602 11.602 0 0021.5 11.511V4.093a1.449 1.449 0 00-.846-1.328A21.143 21.143 0 0012 1.001a21.144 21.144 0 00-8.654 1.764c-.519.238-.85.757-.846 1.328z" />
    <path vectorEffect="non-scaling-stroke" d="M12 13.424a.365.365 0 110 .73.365.365 0 010-.73M12 11.232V6.847" />
  </svg>
);
