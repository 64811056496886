import * as React from 'react';
export const StreamlineEarthCash = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M9.705 22.757a11 11 0 1113.198-12.209M7.19 15.669a1.467 1.467 0 001.423-1.822l-.733-2.933a1.466 1.466 0 00-1.423-1.11H1.224c-.824 4.05.692 8.22 3.926 10.794l.986-4.93H7.19z" />
    <path vectorEffect="non-scaling-stroke" d="M20.786 5.404h-3.974c-.672 0-1.259.458-1.422 1.11l-.456 1.805M18.6 14.202h-1.987a1.312 1.312 0 00-.489 2.53l2.018.807a1.312 1.312 0 01-.489 2.53h-1.985M17.134 14.202v-.733M17.134 20.802v-.734" />
    <path vectorEffect="non-scaling-stroke" d="M17.134 23.001a5.866 5.866 0 100-11.731 5.866 5.866 0 000 11.731z" />
  </svg>
);
