import { Box, Container } from '@spaceship-fspl/components';
import { IntercomFooter } from 'components/intercom-footer';
import { PROGRESS_HEADER_HEIGHT } from 'components/progress-header';
import React from 'react';

export const OnboardingContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Container>
      <Box
        paddingTop={{ xs: 'none', md: 'lg' }}
        paddingBottom={{ xs: 'none', md: 'xxxl' }}
      >
        {children}
      </Box>
      <IntercomFooter />
    </Container>
  );
};

export const CenterOnboardingContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight={`calc(100vh - ${PROGRESS_HEADER_HEIGHT}px)`}
    alignItems="center"
    justifyContent="center"
    paddingY={{ xs: 'md', md: 'xl' }}
  >
    <Container>{children}</Container>
    <IntercomFooter />
  </Box>
);
