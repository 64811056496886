import { navigate } from '@reach/router';
import { Routes } from 'pages/routes';
import React, { createContext, memo, useContext, useState } from 'react';

interface SelectProductNavigation {
  route?: Routes;
  fullPath?: string;
  params?: string;
}

export interface AuthenticatedNavContextProps {
  voyagerProductId: string;
  selectVoyagerProduct: (
    productId: string,
    navigation?: SelectProductNavigation,
  ) => void;
}

const AuthenticatedNavContext = createContext<
  AuthenticatedNavContextProps | undefined
>(undefined);

export const AuthenticatedNavProvider: React.FC<React.PropsWithChildren> = memo(
  ({ children }) => {
    const [voyagerProductId, setVoyagerProductId] = useState<string>('');

    const selectVoyagerProduct = (
      newProductId: string,
      navigation?: SelectProductNavigation,
    ): void => {
      setVoyagerProductId(newProductId);

      if (navigation?.route) {
        navigate(
          `${navigation.route}/${newProductId}${
            navigation.params ? `/${navigation.params}` : ''
          }`,
          { replace: true },
        );
      } else if (navigation?.fullPath) {
        navigate(navigation.fullPath, { replace: true });
      }
    };

    return (
      <AuthenticatedNavContext.Provider
        value={{
          voyagerProductId,
          selectVoyagerProduct,
        }}
      >
        {children}
      </AuthenticatedNavContext.Provider>
    );
  },
);

AuthenticatedNavProvider.displayName = 'AuthenticatedNavProvider';

export const useAuthenticatedNav = (): AuthenticatedNavContextProps => {
  const context = useContext(AuthenticatedNavContext);

  if (!context) {
    throw new Error('Wrap in <AuthenticatedNavProvider />');
  }

  return context;
};
