import { RouteComponentProps, useNavigate } from '@reach/router';
import { Stack } from '@spaceship-fspl/components';
import { useGetUser, useSetGreenIdFields } from '@spaceship-fspl/data';
import {
  DRIVER_LICENCE_SOURCE_STATES,
  DriverLicenceFields,
  DriverLicenceState,
  driverLicenceValidations,
  GreenIdTermsAndCondition,
  IDName,
  REGODVS_STATE_MAP,
  State,
  useID,
} from '@spaceship-fspl/green-id';
import { formatDate } from '@spaceship-fspl/helpers';
import actregodvsImage from 'assets/greenid-licence-act.png';
import oldActregodvsImage from 'assets/greenid-licence-act-old.png';
import nswregodvsImage from 'assets/greenid-licence-nsw.png';
import oldNswregodvsImage from 'assets/greenid-licence-nsw-old.png';
import ntregodvsImage from 'assets/greenid-licence-nt.png';
import oldNtregodvsImage from 'assets/greenid-licence-nt-old.png';
import qldregodvsImage from 'assets/greenid-licence-qld.png';
import oldQldregodvsImage from 'assets/greenid-licence-qld-old.png';
import saregodvsImage from 'assets/greenid-licence-sa.png';
import oldSaregodvsImage from 'assets/greenid-licence-sa-old.png';
import tasregodvsImage from 'assets/greenid-licence-tas.png';
import oldTasregodvsImage from 'assets/greenid-licence-tas-old.png';
import vicregodvsImage from 'assets/greenid-licence-vic.png';
import oldVicregodvsImage from 'assets/greenid-licence-vic-old.png';
import waregodvsImage from 'assets/greenid-licence-wa.png';
import oldWaregodvsImage from 'assets/greenid-licence-wa-old.png';
import { ControllerInputWithInlineError } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import { useGreenID } from 'contexts/greenid';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React from 'react';
import { useForm } from 'react-hook-form';

import { NO_USER_VERIFICATION_ERROR, useReverifyAndNavigate } from './hooks';
import { FormLayout } from './layout';

const DRIVER_LICENCE_IMAGES_NO_CARD_NUMBER: Record<DriverLicenceState, string> =
  {
    actregodvs: oldActregodvsImage,
    nswregodvs: oldNswregodvsImage,
    qldregodvs: oldQldregodvsImage,
    tasregodvs: oldTasregodvsImage,
    vicregodvs: oldVicregodvsImage,
    ntregodvs: oldNtregodvsImage,
    saregodvs: oldSaregodvsImage,
    waregodvs: oldWaregodvsImage,
  };

const DRIVER_LICENCE_IMAGES: Record<DriverLicenceState, string> = {
  actregodvs: actregodvsImage,
  nswregodvs: nswregodvsImage,
  qldregodvs: qldregodvsImage,
  tasregodvs: tasregodvsImage,
  vicregodvs: vicregodvsImage,
  ntregodvs: ntregodvsImage,
  saregodvs: saregodvsImage,
  waregodvs: waregodvsImage,
};

export const DriverLicence: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const idName = IDName.AUSTRALA_DRIVER_LICENCE;
  const { getUploadLinkBySourceName, ruleset } = useGreenID();
  const { mutateAsync: postGreenIDFields, isLoading } =
    useSetGreenIdFields(ruleset);
  const verificationId = useID(ruleset);
  const notification = useNotifications();
  const { pop: showIntercom } = useIntercom();
  const reverifyAndNavigate = useReverifyAndNavigate(idName);
  const { data: user } = useGetUser();
  const contact = user?.contact;
  const isCardNumberInputEnabled = useFeatureFlag(
    FeatureFlagKeys.GREEN_ID_DRIVERS_LICENCE_CARD_NUMBER_ENABLED,
  );
  const isQldCardNumberInputEnabled = useFeatureFlag(
    FeatureFlagKeys.GREEN_ID_DRIVERS_LICENCE_CARD_NUMBER_QLD_ENABLED,
  );
  const isVicCardNumberInputEnabled = useFeatureFlag(
    FeatureFlagKeys.GREEN_ID_DRIVERS_LICENCE_CARD_NUMBER_VIC_ENABLED,
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DriverLicenceFields>({
    defaultValues: {
      state: 'nswregodvs',
      number: '',
      cardNumber: '',
      givenname: contact?.first_name ?? '',
      middlename: '',
      surname: contact?.last_name ?? '',
      dob: contact?.date_of_birth
        ? formatDate(new Date(contact?.date_of_birth))
        : '',
      tandc: GreenIdTermsAndCondition.ON,
    },
  });

  const onSubmit = async ({
    state,
    ...fields
  }: DriverLicenceFields): Promise<void> => {
    try {
      await postGreenIDFields({
        verification_id: verificationId,
        source_name: state,
        field_inputs: {
          [`greenid_${state}_number`]: fields.number,
          [`greenid_${state}_cardnumber`]: fields.cardNumber,
          [`greenid_${state}_givenname`]: fields.givenname,
          [`greenid_${state}_middlename`]: fields.middlename,
          [`greenid_${state}_surname`]: fields.surname,
          [`greenid_${state}_dob`]: fields.dob,
          [`greenid_${state}_tandc`]: GreenIdTermsAndCondition.ON,
        },
      });

      await reverifyAndNavigate();
    } catch (error) {
      if (
        error instanceof TypeError ||
        (error as Error | undefined)?.message.includes(
          NO_USER_VERIFICATION_ERROR,
        )
      ) {
        // Network error
        notification.popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
        showIntercom();
      } else if (state) {
        // API error
        navigate?.(getUploadLinkBySourceName(state), {
          replace: true,
        });
      }
    }
  };

  const selectedDriverLicenceState: DriverLicenceState = watch('state');
  const selectedState = Object.keys(REGODVS_STATE_MAP).find(
    (key) => REGODVS_STATE_MAP[key as State] === selectedDriverLicenceState,
  ) as State;
  let isCardNumberForStateEnabled;

  switch (selectedState) {
    case 'QLD':
      isCardNumberForStateEnabled = isQldCardNumberInputEnabled;
      break;

    case 'VIC':
      isCardNumberForStateEnabled = isVicCardNumberInputEnabled;
      break;

    default:
      isCardNumberForStateEnabled = isCardNumberInputEnabled;
      break;
  }

  return (
    <FormLayout
      title={idName}
      image={{
        url: isCardNumberForStateEnabled
          ? DRIVER_LICENCE_IMAGES[selectedDriverLicenceState]
          : DRIVER_LICENCE_IMAGES_NO_CARD_NUMBER[selectedDriverLicenceState],
        alt: idName,
      }}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      idName={idName}
    >
      <Stack spaceY="sm">
        <Dropdown
          options={DRIVER_LICENCE_SOURCE_STATES}
          placeholder="State or territory"
          errorMessage={errors?.state?.message}
          {...register('state')}
        />
        <ControllerInputWithInlineError
          name="number"
          control={control}
          placeholder="Licence No"
          type="text"
          rules={driverLicenceValidations.number}
        />
        {isCardNumberForStateEnabled && (
          <ControllerInputWithInlineError
            name="cardNumber"
            control={control}
            placeholder="Licence Card No"
            type="text"
            rules={{
              ...driverLicenceValidations.cardNumber,
              pattern:
                driverLicenceValidations.cardNumber.pattern(selectedState),
            }}
          />
        )}
        <ControllerInputWithInlineError
          name="givenname"
          control={control}
          placeholder="First name (as shown on ID)"
          type="text"
          rules={driverLicenceValidations.firstName}
        />
        <ControllerInputWithInlineError
          name="middlename"
          control={control}
          placeholder="Middle name (optional)"
          type="text"
          rules={driverLicenceValidations.middleName}
        />
        <ControllerInputWithInlineError
          name="surname"
          control={control}
          placeholder="Last name (as shown on ID)"
          type="text"
          rules={driverLicenceValidations.lastName}
        />
        <ControllerInputWithInlineError
          name="dob"
          control={control}
          placeholder="Date of birth"
          type="text"
          format="date"
          dateFormatOption={{
            delimiter: '/',
          }}
          rules={driverLicenceValidations.dateOfBirth}
        />
      </Stack>
    </FormLayout>
  );
};
