import { FetchFunction } from '@spaceship-fspl/fetch';
import {
  enumToString,
  isValidDollarAmount,
  isValueInEnum,
  sydneyDate,
} from '@spaceship-fspl/helpers';
import {
  api,
  IBankAccount,
  SaverPortfolio,
} from '@spaceship-fspl/types/externalapi';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { QueryKeys } from './helpers';
import { useAuthenticatedFetch } from './react';

export const createVoyagerProduct =
  (fetch: FetchFunction) =>
  async (
    body: api.external.ICreateSaverAccountRequestBody,
  ): Promise<api.external.ICreateSaverAccountResponseBody> =>
    api.external.CreateSaverAccountResponseBody.fromObject(
      await fetch({
        url: '/saver/account',
        method: 'POST',
        body: api.external.CreateSaverAccountRequestBody.create(body),
      }),
    );

export const useCreateVoyagerProduct = (): UseMutationResult<
  api.external.ICreateSaverAccountResponseBody,
  unknown,
  api.external.ICreateSaverAccountRequestBody,
  unknown
> => {
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(createVoyagerProduct(authenticatedFetch));
};

export const getVoyagerAccount =
  (fetch: FetchFunction) =>
  async (): Promise<api.external.IGetSaverAccountResponseBody> =>
    api.external.GetSaverAccountResponseBody.fromObject(
      await fetch({
        url: '/saver/account',
        method: 'GET',
      }),
    );

export const useGetVoyagerAccount = (
  options?: Omit<
    UseQueryOptions<
      api.external.IGetSaverAccountResponseBody,
      unknown,
      api.external.IGetSaverAccountResponseBody
    >,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<api.external.IGetSaverAccountResponseBody> => {
  const authenticatedFetch = useAuthenticatedFetch();
  return useQuery(
    [QueryKeys.GetVoyagerAccount],
    getVoyagerAccount(authenticatedFetch),
    options,
  );
};

export const useGetActiveBankAccount = (): IBankAccount | undefined => {
  const { data: saverAccount } = useGetVoyagerAccount();
  return saverAccount?.bank_accounts?.filter((account) => account.active)[0];
};

export const createPaymentSchedule =
  (fetch: FetchFunction) =>
  async (
    body: api.external.ICreatePaymentScheduleRequestBody,
  ): Promise<api.external.ICreatePaymentScheduleResponseBody> =>
    api.external.CreatePaymentScheduleResponseBody.fromObject(
      await fetch({
        url: '/saver/account/payment-schedule',
        method: 'POST',
        body: api.external.CreatePaymentScheduleRequestBody.create(body),
      }),
    );

export const useCreatePaymentSchedule = (): UseMutationResult<
  api.external.ICreatePaymentScheduleResponseBody,
  unknown,
  api.external.ICreatePaymentScheduleRequestBody,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(createPaymentSchedule(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const cancelAllPaymentSchedulesByPortfolio =
  (fetch: FetchFunction) =>
  async (
    body: api.external.ICancelAllPaymentSchedulesByPortfolioRequestBody,
  ): Promise<api.external.CancelAllPaymentSchedulesByPortfolioResponseBody> =>
    api.external.CancelAllPaymentSchedulesByPortfolioResponseBody.fromObject(
      await fetch({
        url: '/saver/account/payment-schedule',
        method: 'DELETE',
        body: api.external.CancelAllPaymentSchedulesByPortfolioRequestBody.create(
          body,
        ),
      }),
    );

export const useCancelAllPaymentSchedulesByPortfolio = (): UseMutationResult<
  api.external.ICancelAllPaymentSchedulesByPortfolioResponseBody,
  unknown,
  api.external.ICancelAllPaymentSchedulesByPortfolioRequestBody,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(cancelAllPaymentSchedulesByPortfolio(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const updateBankAccount =
  (fetch: FetchFunction) =>
  async (
    body: api.external.IUpdateSourceBankAccountRequestBody,
  ): Promise<api.external.IUpdateSourceBankAccountResponseBody> =>
    api.external.UpdateSourceBankAccountResponseBody.fromObject(
      await fetch({
        url: '/saver/account/source-bank-account',
        method: 'PUT',
        body: api.external.UpdateSourceBankAccountRequestBody.create(body),
      }),
    );

export const useUpdateBankAccount = (): UseMutationResult<
  api.external.IUpdateSourceBankAccountResponseBody,
  unknown,
  api.external.IUpdateSourceBankAccountRequestBody,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(updateBankAccount(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const createTFN =
  (fetch: FetchFunction) =>
  async (
    body: api.external.IInsertTFNRequestBody,
  ): Promise<api.external.IInsertTFNResponseBody> =>
    api.external.InsertTFNResponseBody.fromObject(
      await fetch({
        url: '/saver/account/tfn',
        method: 'POST',
        body: api.external.InsertTFNRequestBody.create(body),
      }),
    );

export const useCreateTFN = (): UseMutationResult<
  api.external.IInsertTFNResponseBody,
  unknown,
  api.external.IInsertTFNRequestBody,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();

  return useMutation(createTFN(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const createTFNExemption =
  (fetch: FetchFunction) =>
  async (
    body: api.external.ICreateTFNExemptionRequestBody,
  ): Promise<api.external.ICreateTFNExemptionResponseBody> =>
    api.external.CreateTFNExemptionResponseBody.fromObject(
      await fetch({
        url: '/saver/account/tfn/exempt',
        method: 'POST',
        body: api.external.CreateTFNExemptionRequestBody.create(body),
      }),
    );

export const useCreateTFNExemption = (): UseMutationResult<
  api.external.ICreateTFNExemptionResponseBody,
  unknown,
  api.external.ICreateTFNExemptionRequestBody,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(createTFNExemption(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const markTFNDeleted =
  (fetch: FetchFunction) =>
  async (): Promise<api.external.IMarkTFNDeletedResponseBody> =>
    api.external.MarkTFNDeletedResponseBody.fromObject(
      await fetch({
        url: '/saver/account/tfn',
        method: 'DELETE',
        body: api.external.MarkTFNDeletedRequestBody.create({}),
      }),
    );

export const useMarkTFNDeleted = (): UseMutationResult<
  api.external.IMarkTFNDeletedResponseBody,
  unknown,
  void,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(markTFNDeleted(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const withdrawal =
  (fetch: FetchFunction) =>
  async (
    body: api.external.IWithdrawalRequestBody,
  ): Promise<api.external.IWithdrawalResponseBody> =>
    api.external.WithdrawalResponseBody.fromObject(
      await fetch({
        url: '/saver/account/withdraw',
        method: 'POST',
        body: api.external.WithdrawalRequestBody.create(body),
      }),
    );

export const useWithdrawal = (): UseMutationResult<
  api.external.IWithdrawalResponseBody,
  unknown,
  api.external.IWithdrawalRequestBody,
  unknown
> => {
  const queryClient = useQueryClient();
  const authenticatedFetch = useAuthenticatedFetch();
  return useMutation(withdrawal(authenticatedFetch), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GetVoyagerAccount),
  });
};

export const getUnitPriceGraphData =
  (fetch: FetchFunction) =>
  async (
    portfolio: SaverPortfolio.Enum,
  ): Promise<api.external.IGetUnitPriceGraphDataResponseBody> =>
    api.external.GetUnitPriceGraphDataResponseBody.fromObject(
      await fetch({
        method: 'GET',
        url: '/saver/unit-price/graph',
        query: {
          date: '1970-01-01',
          portfolio: enumToString(SaverPortfolio.Enum, Number(portfolio)),
        },
      }),
    );

export const useUnitPriceGraphData = (
  portfolio: SaverPortfolio.Enum | null | undefined,
): UseQueryResult<api.external.IGetUnitPriceGraphDataResponseBody> => {
  const authenticatedFetch = useAuthenticatedFetch();
  return useQuery(
    [QueryKeys.GetUnitPrices, portfolio],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getUnitPriceGraphData(authenticatedFetch)(portfolio!),
    {
      enabled:
        isValueInEnum(SaverPortfolio.Enum, portfolio) &&
        portfolio !== SaverPortfolio.Enum.UNKNOWN,
    },
  );
};

export const getEstimatedApplicationExecutionDate =
  (fetch: FetchFunction) =>
  async (
    portfolio: SaverPortfolio.Enum,
    applicationAmount: string,
    applicationTime: Date = sydneyDate(),
  ): Promise<api.external.IEstimateApplicationExecutionDateResponseBody> => {
    return await fetch({
      method: 'GET',
      url: '/saver/account/estimated-application-execution-date',
      query: {
        portfolio: enumToString(SaverPortfolio.Enum, portfolio),
        application_time: applicationTime.toISOString(),
        aud_application_amount: applicationAmount,
      },
    });
  };

export const getEstimatedAnonymousApplicationExecutionDate =
  (fetch: FetchFunction) =>
  async (
    applicationAmount: string,
    applicationTime: Date = sydneyDate(),
  ): Promise<api.external.IEstimateApplicationExecutionDateResponseBody> => {
    return await fetch({
      method: 'GET',
      url: '/saver/account/estimated-anonymous-application-execution-date',
      query: {
        application_time: applicationTime.toISOString(),
        aud_application_amount: applicationAmount,
      },
    });
  };

export const useGetEstimatedAnonymousApplicationExecutionDate = (
  applicationAmount?: string | null,
  applicationTime?: Date,
): UseQueryResult<api.external.IEstimateApplicationExecutionDateResponseBody> => {
  const authenticatedFetch = useAuthenticatedFetch();

  return useQuery(
    [
      'estimated-anonymous-application-execution-date',
      applicationAmount,
      applicationTime,
    ],
    () =>
      getEstimatedAnonymousApplicationExecutionDate(authenticatedFetch)(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Only called when truthy thanks to `enabled`
        applicationAmount,
        applicationTime,
      ),
    {
      enabled: !!applicationAmount && isValidDollarAmount(applicationAmount),
    },
  );
};
