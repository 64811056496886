import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import {
  ActionLink,
  Box,
  Card,
  Columns,
  Divider,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import { WebAppVoyagerInvestmentPlanPortfolios } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerInvestmentPlanPortfolios';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { InvestInfoAccordion } from 'components/transactions/invest-info-accordion';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import { fromISODate, toLongDate } from 'helpers/format';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React from 'react';

export const VoyagerInvestmentPlanPortfolios: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const investmentPlanCopy = useString(
    DynamicConfigKeys.INVESTMENT_PLAN_PUSH_COPY,
    'Start by choosing a weekly, fortnightly or monthly investment plan, and Spaceship will do the rest. 🚀',
  );
  const resp = useQuery<WebAppVoyagerInvestmentPlanPortfolios>(gql`
    query WebAppVoyagerInvestmentPlanPortfolios {
      contact {
        id
        account {
          id
          saverProductInstances {
            id
            portfolio
            upcomingSchedule {
              id
              audAmount
              frequency
              nextDue
            }
          }
        }
      }
    }
  `);
  const productInstances = resp?.data?.contact?.account?.saverProductInstances;

  return (
    <PageContainer>
      <Columns alignX="center" spaceY="xl">
        <Columns.Column width={{ xs: 1, lg: 2 / 3, xl: 1 / 2 }}>
          <PageHeading title="Investment plan" subtitle={investmentPlanCopy} />

          <Card marginTop="lg" padding={{ xs: 'md', sm: 'lg' }}>
            <Stack spaceY="xs">
              {productInstances?.map((product, index) => {
                const config = voyagerPortfolios[product.portfolio];
                const PortfolioIcon = config.icon.default;

                return (
                  <React.Fragment key={product.id}>
                    {index !== 0 && <Divider />}

                    <Box display="flex">
                      <PortfolioIcon color="indigo.070" size="xxl" />

                      <Box
                        display="flex"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        flex={1}
                        paddingLeft="xs"
                        paddingTop="xxs"
                      >
                        <Box
                          flex={1}
                          marginRight="xs"
                          marginBottom={{ xs: 'xs', sm: 'none' }}
                        >
                          <Text variant={2} isBold={true}>
                            {config.title}
                          </Text>

                          {product.upcomingSchedule?.audAmount ? (
                            <Box marginTop="xxxs">
                              <Inline spaceX="sm" spaceY="xxxs" alignY="bottom">
                                <div>
                                  <Text variant={4} color="neutral.080">
                                    Amount
                                  </Text>
                                  <Text variant={3}>
                                    {formatCurrency(
                                      product.upcomingSchedule?.audAmount,
                                    )}{' '}
                                    {(
                                      product.upcomingSchedule.frequency || ''
                                    ).toLocaleLowerCase()}
                                  </Text>
                                </div>

                                <div>
                                  <Text variant={4} color="neutral.080">
                                    Next investment
                                  </Text>
                                  <Text variant={3}>
                                    {product.upcomingSchedule.nextDue
                                      ? toLongDate(
                                          fromISODate(
                                            product.upcomingSchedule.nextDue,
                                          ),
                                        )
                                      : ''}
                                  </Text>
                                </div>
                              </Inline>
                            </Box>
                          ) : (
                            <Text variant={3}>No plan</Text>
                          )}
                        </Box>

                        <RouterLink
                          to={`${Routes.VOYAGER_INVESTMENT_PLAN}/${product.id}`}
                          aria-label={`investment plan update link ${product.portfolio.toLowerCase()}`}
                          trackingProperties={{
                            name: 'voyager_investment_plan_portfolios_update',
                          }}
                        >
                          <ActionLink
                            color="indigo.070"
                            size="sm"
                            component="span"
                          >
                            Update
                          </ActionLink>
                        </RouterLink>
                      </Box>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Stack>
          </Card>
        </Columns.Column>

        <Columns.Column>
          <InvestInfoAccordion />
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
