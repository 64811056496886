import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';

import { FUND_DETAILS } from '../constants';
import { useGetMember, useIsValidSuperMember } from '../react';

enum MemberDetailsLabels {
  MEMBER_NAME = 'Member name',
  MEMBER_NUMBER = 'Member number',
  FUND_NAME = 'Fund name',
  FUND_USI = 'Unique Super Identifier (USI)',
  FUND_ABN = 'Australian Business Number (ABN)',
}

interface LabelValue {
  label: MemberDetailsLabels;
  value: string;
}

export interface UseMemberDetailsResults {
  isLoading: boolean;
  data: {
    member?: {
      name: LabelValue;
      number: LabelValue;
    };
    fund: {
      name: LabelValue;
      usi: LabelValue;
      abn: LabelValue;
    };
  };
  error: unknown;
}

export const useMemberDetails = (): UseMemberDetailsResults => {
  const { data: member, isLoading: isLoadingGetMember, error } = useGetMember();
  const isValidSuperMember = useIsValidSuperMember();
  const resp = useSuperPortfolioFlags();
  const isLoading = isLoadingGetMember || resp.loading;
  const fund = {
    name: {
      label: MemberDetailsLabels.FUND_NAME,
      value: FUND_DETAILS.name,
    },
    usi: {
      label: MemberDetailsLabels.FUND_USI,
      value: resp.data?.superPortfolioFlags?.sftOneSuperActive
        ? FUND_DETAILS.uniqueSuperIdentifierSFTActive
        : FUND_DETAILS.uniqueSuperIdentifier,
    },
    abn: {
      label: MemberDetailsLabels.FUND_ABN,
      value: resp.data?.superPortfolioFlags?.sftOneSuperActive
        ? FUND_DETAILS.australianBusinessNumberSFTActive
        : FUND_DETAILS.australianBusinessNumber,
    },
  };
  const data =
    isLoading || !isValidSuperMember
      ? { fund }
      : {
          member: {
            name: {
              label: MemberDetailsLabels.MEMBER_NAME,
              value:
                member?.givenName || member?.familyName
                  ? `${member.givenName} ${member.familyName}`
                  : '-',
            },
            number: {
              label: MemberDetailsLabels.MEMBER_NUMBER,
              value: member?.impactId ? `${member?.impactId}` : '-',
            },
          },
          fund,
        };

  return {
    isLoading,
    data,
    error,
  };
};
