import { gql, useQuery } from '@apollo/client';
import { Box, Stack } from '@spaceship-fspl/components';
import { useTreatment } from '@spaceship-fspl/experimentation';
import { WebAppVoyagerDetails } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerDetails';
import {
  VoyagerTaxResidencyCard,
  voyagerTaxResidencyCardFragment,
} from 'components/account-cards/voyager-tax-residency';
import { ExperimentNames } from 'helpers/experimentation';
import { useDismissed } from 'helpers/hooks/dismissed';
import { PersistKey } from 'helpers/persist';
import {
  BoostsCard,
  voyagerBoostsCardFragment,
} from 'pages/voyager-dashboard/boosts-card';
import React, { useEffect } from 'react';

import {
  VoyagerBankAccountCard,
  voyagerBankAccountCardFragment,
} from '../cards/voyager-bank-account';
import {
  VoyagerInvestmentPlanCard,
  voyagerInvestmentPlanCardFragment,
} from '../cards/voyager-investment-plan';
import {
  VoyagerOtherActionsCard,
  voyagerOtherActionsCardFragment,
} from '../cards/voyager-other-actions';
import {
  VoyagerPrimaryPortfolioCard,
  voyagerPrimaryPortfolioCardContactFragment,
} from '../cards/voyager-primary-portfolio';
import {
  VoyagerTaxFileNumberCard,
  voyagerTaxFileNumberCardFragment,
} from '../cards/voyager-tax-file-number';

export const VoyagerDetails: React.FC<React.PropsWithChildren> = () => {
  const [isBoostsCardDismissed, setIsBoostsCardDismissed] = useDismissed(
    PersistKey.BOOSTS_CARD_LAST_DISMISSED,
    Number.MAX_VALUE,
  );

  const payToExperiment = useTreatment(ExperimentNames.PAYTO);
  const isPayToEnabled = payToExperiment.name === 'available';

  useEffect(() => {
    payToExperiment.activate();
  }, [payToExperiment]);

  const resp = useQuery<WebAppVoyagerDetails>(
    gql`
      query WebAppVoyagerDetails($isPayToEnabled: Boolean! = false) {
        contact {
          id
          ...webAppVoyagerInvestmentPlanCard
          ...webAppVoyagerBankAccountCard
          ...webAppVoyagerTaxResidencyCard
          ...webAppVoyagerTaxFileNumberCard
          ...webAppVoyagerPrimaryPortfolioCardContact
          ...webAppVoyagerOtherActionsCard
          ...webAppVoyagerBoostsCard
        }
      }
      ${voyagerInvestmentPlanCardFragment}
      ${voyagerBankAccountCardFragment}
      ${voyagerTaxResidencyCardFragment}
      ${voyagerTaxFileNumberCardFragment}
      ${voyagerPrimaryPortfolioCardContactFragment}
      ${voyagerOtherActionsCardFragment}
      ${voyagerBoostsCardFragment}
    `,
    { variables: { isPayToEnabled }, pollInterval: isPayToEnabled ? 10000 : 0 },
  );
  const account = resp.data?.contact?.account;

  const hasBoosts = !!resp.data?.contact?.account?.saverBoostRecipes?.length;
  const isBoostsCardVisible = !hasBoosts && !isBoostsCardDismissed;

  return (
    <Box paddingBottom="md">
      <Stack spaceY="md">
        {!resp.loading && isBoostsCardVisible && (
          <BoostsCard onDismiss={() => setIsBoostsCardDismissed()} />
        )}
        <VoyagerInvestmentPlanCard
          isLoading={resp.loading}
          productInstances={account?.saverProductInstances}
        />
        <VoyagerBankAccountCard
          isLoading={resp.loading}
          canUpdateBankAccount={!!account?.canUpdateBankAccount}
          bankAccount={account?.activeBankAccount}
        />
        <VoyagerTaxResidencyCard
          isLoading={resp.loading}
          contact={resp.data?.contact}
        />
        <VoyagerTaxFileNumberCard
          productInstances={account?.saverProductInstances}
        />
        <VoyagerPrimaryPortfolioCard
          productInstances={account?.saverProductInstances}
        />
        <VoyagerOtherActionsCard hasBoosts={hasBoosts} />
      </Stack>
    </Box>
  );
};
