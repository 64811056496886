import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  SafeAndEasyBankLinkingAccordion,
  SafeAndEasyBankLinkingContent,
} from 'components/accordions/bank-account';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import { PageContainer } from 'components/layouts/page';
import {
  useBankAccountSubmissionContext,
  useBankAccountVerificationContext,
} from 'contexts/bank-account-verification';
import { useNotifications } from 'contexts/notifications';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { CredentialsField } from 'helpers/bank-statements-api';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export const BankAccountLogin: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const upPath = `${location.pathname.replace(/\/+$/, '')}/${Routes.UP}`;

  const { selectedBank, login } = useBankAccountVerificationContext();
  const { variant } = useBankAccountSubmissionContext();
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm<
    {
      terms: boolean;
    } & { [fieldID: string]: string }
  >();
  const track = useTrack();
  const [loading, setLoading] = useState(false);
  const { popToast } = useNotifications();

  useEffect(() => {
    if (!selectedBank) {
      navigate?.(upPath);
    }
  }, [navigate, selectedBank, upPath]);

  const getField = ({
    values,
    fieldID,
    type,
    name,
    src,
  }: CredentialsField): React.ReactNode => {
    if (!(type && name && fieldID)) {
      return;
    }

    switch (type.toLowerCase()) {
      case 'email':
        return (
          <ControllerInput
            name={fieldID}
            control={control}
            key={fieldID}
            placeholder={name}
            type="email"
            rules={{ ...requiredValidation(name) }}
          />
        );

      case 'text':
        return (
          <ControllerInput
            name={fieldID}
            control={control}
            key={fieldID}
            autofill="off"
            placeholder={name}
            type="text"
            rules={requiredValidation(name)}
          />
        );

      case 'password':
        return (
          <div className="fs-block" key={fieldID}>
            <ControllerInput
              name={fieldID}
              control={control}
              key={fieldID}
              autofill="off"
              placeholder={name}
              type="password"
              rules={requiredValidation(name)}
            />
          </div>
        );

      case 'select':
        return (
          <Dropdown
            key={fieldID}
            errorMessage={errors[fieldID]?.message}
            placeholder={name}
            options={
              (values &&
                Object.entries(values).map(([value, label]) => ({
                  label,
                  value,
                }))) ||
              []
            }
            {...register(fieldID, { ...requiredValidation(name) })}
          />
        );

      case 'captcha':
        return (
          src && (
            <Stack spaceY="xs" key={fieldID}>
              <Heading variant={5}>CAPTCHA</Heading>
              <img src={src} alt="captcha" />
              <ControllerInput
                name={fieldID}
                control={control}
                placeholder={name}
                type="text"
                rules={requiredValidation(name)}
              />
            </Stack>
          )
        );

      default:
        return null;
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      if (variant === 'onboarding') {
        track?.(
          MarketingTrackingEvents.VOYAGER_ONBOARDING_LINK_BANK_AUTO_SUBMIT,
        );
      }
      const loginResult = await login(values);
      if (navigate && loginResult === false) {
        await navigate(`${upPath}${Routes.BANK_ACCOUNT_SELECT_ACCOUNT}`);
      } else if (navigate && loginResult === true) {
        await navigate(`${upPath}${Routes.BANK_ACCOUNT_2FA}`);
      }
    } catch (error) {
      const castedError = error as Error & {
        errorCode?: unknown;
        error?: unknown;
      };

      popToast({
        message: castedError.errorCode
          ? `There was a problem logging you in. Message from your bank: "${castedError.error}"`
          : GENERIC_ERROR_MESSAGE,
        level: 'error',
      });
    }
    setLoading(false);
  });

  return (
    <PageContainer>
      <Box marginTop={{ xs: 'lg', md: 'xxxl' }}>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
            <form onSubmit={onSubmit}>
              <Stack spaceY="lg">
                <Heading component="h1" variant={3} align={{ md: 'center' }}>
                  {`Link ${selectedBank ? selectedBank.name : 'your'} account`}
                </Heading>

                <Stack spaceY="sm">
                  {selectedBank?.credentials?.map(getField)}
                </Stack>

                <Box>
                  <SafeAndEasyBankLinkingAccordion>
                    <SafeAndEasyBankLinkingContent />
                  </SafeAndEasyBankLinkingAccordion>

                  <Box
                    as="input"
                    type="checkbox"
                    display="none"
                    checked
                    {...register('terms')}
                  />
                </Box>

                <Text variant={3} color="neutral.080">
                  By clicking Log in to bank, you agree with our{' '}
                  <TextLink
                    href={InternalRoutes.ILLION_TERMS}
                    color="indigo.070"
                    rel="noopener"
                    target="_blank"
                  >
                    Bank Account Verification Terms and Conditions
                  </TextLink>
                  .
                </Text>

                <Stack spaceY="sm" alignX="center">
                  <Button
                    variant="primary"
                    size="lg"
                    trackingProperties={{
                      name: 'bank_account_login_page_submit',
                    }}
                    isLoading={loading}
                    type="submit"
                  >
                    Log in to bank
                  </Button>
                  <Button
                    trackingProperties={{
                      name: 'bank_account_login_page_try_another',
                    }}
                    variant="secondary"
                    size="lg"
                    onClick={async (): Promise<void> => {
                      await navigate?.(upPath);
                    }}
                  >
                    Try another bank
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Columns.Column>
        </Columns>
      </Box>
    </PageContainer>
  );
};
