interface Config {
  isProduction: boolean;
  isRunningTests: boolean;
  segmentKey: string;
  datadog: {
    env: 'prod' | 'preprod';
    clientToken: string;
    applicationId: string;
  };
  intercomAppId: string;
  branchIOKey: string;
  api: {
    externalUrl: string;
    graphqlUrl: string;
  };
  bankStatementsApi: {
    baseUrl: string;
    apiKey: string;
  };
  googleMapsApiKey: string;
  super: {
    client: {
      basePath: string;
      clientId: string;
      useCookies: boolean;
    };
    amplify: {
      userPoolId: string;
    };
  };
  support: {
    phone: string;
    email: string;
  };
  auth: {
    useCookies: boolean;
  };
}

export enum Environment {
  PRODUCTION = 'production',
  PREPROD = 'preprod',
  DEVELOPMENT = 'development',
}

const preprodConfig: Config = {
  isProduction: false,
  isRunningTests: process.env.JEST_WORKER_ID !== undefined,
  segmentKey: 'tfHvnXuhjYeddPW3h4GTfuGllK9ocWLs',
  datadog: {
    env: 'preprod',
    clientToken: 'pub65a3e816c200ed3476def7d4da44a5e0',
    applicationId: '608e6ee3-0dd6-4d53-bec6-9917f850f363',
  },
  intercomAppId: 'rv619haf',
  api: {
    externalUrl: 'https://api.preprod.spaceship.com.au/v0/external',
    graphqlUrl: 'https://api.preprod.spaceship.com.au/v0/external',
  },
  bankStatementsApi: {
    baseUrl: 'https://test.bankstatements.com.au/api/v1',
    apiKey: 'QQMNVHJYGWBA9KULN2T1XOIWCHLEDJRKDYKUZXGF',
  },
  branchIOKey: 'key_test_dnBXtoQrKLtZn9WprLpXCohfFtd1vfnd',
  googleMapsApiKey: 'AIzaSyDbmA5FAoNoZrrFF_6YmcanZzIQ2i62XRE',
  super: {
    client: {
      basePath: 'https://super.api.preprod.spaceship.com.au',
      clientId: '3qidfv772fbegb9iq9ad1qmere',
      useCookies: false,
    },
    amplify: {
      userPoolId: 'ap-southeast-2_lswkNnhrj',
    },
  },
  support: {
    phone: '1300 049 532',
    email: 'help@spaceship.com.au',
  },
  auth: {
    useCookies: false,
  },
};

interface ConfigMap {
  [Environment.PRODUCTION]: Config;
  [Environment.PREPROD]: Config;
  [Environment.DEVELOPMENT]: Config;
}

const envToConfig: ConfigMap = {
  [Environment.PRODUCTION]: {
    isProduction: true,
    isRunningTests: process.env.JEST_WORKER_ID !== undefined,
    segmentKey: 'OINDMOsYWUPJE6KFBvHQZFbHa8p4JCPH',
    datadog: {
      clientToken: 'pub65a3e816c200ed3476def7d4da44a5e0',
      applicationId: '608e6ee3-0dd6-4d53-bec6-9917f850f363',
      env: 'prod',
    },
    intercomAppId: 'sxx9mgxp',
    api: {
      externalUrl: 'https://api.spaceshipinvest.com.au/v0/external',
      graphqlUrl: 'https://api.spaceshipinvest.com.au/v0/external',
    },
    bankStatementsApi: {
      baseUrl: 'https://www.bankstatements.com.au/api/v1',
      apiKey: 'HS1QP2H9UCTGBWXFQHODPM9HRQYD0A4AWMBQ8OQH',
    },
    branchIOKey: 'key_live_amx4qbKwLND7iY3fuGo2BkcpxCm6bY86',
    googleMapsApiKey: 'AIzaSyCczISXMXpTkD3UIS3K2lRTljylrRO37Gc',
    super: {
      client: {
        basePath: 'https://super.api.spaceship.com.au',
        clientId: '2duchtathkqcggfp5dtkvohgd',
        useCookies: false,
      },
      amplify: {
        userPoolId: 'ap-southeast-2_XNV4yXp57',
      },
    },
    support: {
      phone: '1300 049 532',
      email: 'help@spaceship.com.au',
    },
    auth: {
      useCookies: false,
    },
  },
  [Environment.PREPROD]: preprodConfig,
  [Environment.DEVELOPMENT]: {
    ...preprodConfig,
    segmentKey: '',
  },
};

export const config = ((): Config => {
  const env = process.env.ENV;

  if (!env) {
    throw Error('no environment provided');
  }

  switch (env) {
    case Environment.PRODUCTION:
    case Environment.PREPROD:
    case Environment.DEVELOPMENT:
      return envToConfig[env];
    default:
      throw Error(`unsupported environment: ${env}`);
  }
})();

export const IS_PRODUCTION = process.env.ENV === Environment.PRODUCTION;
