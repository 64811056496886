import { PersistKey } from 'helpers/persist';
import { useCallback } from 'react';

import { useLocalStorage } from './local-storage';

export const useDismissed = (
  key: PersistKey,
  dismissTimeInMs: number,
  initialValue = 0,
): [boolean, () => void] => {
  const [dismissUntil, setDismissUntil] = useLocalStorage<number>(
    key,
    initialValue,
  );

  const dismissed = !!dismissUntil && dismissUntil >= Date.now();

  const onDismiss = useCallback(
    () => setDismissUntil(Date.now() + dismissTimeInMs),
    [dismissTimeInMs, setDismissUntil],
  );

  return [dismissed, onDismiss];
};
