import { SargonApiClient } from '@sargon/api-client';

export interface SuperClientConfig {
  readonly basePath: string;
  readonly clientId: string;
  readonly clientSecret?: string;
  readonly useCookies?: boolean;
}

export const createSuperApiClient = (
  options: SuperClientConfig,
): SargonApiClient =>
  new SargonApiClient({
    clientSecret: '',
    ...options,
    version: 'v1',
  });
