import {
  useDaysSinceLastCheck,
  useIsStatusInProgress,
  useIsStatusRejected,
  useOverallStatus,
} from '@spaceship-fspl/green-id';
import { SUPERMATCH_GREENID_SUCCESS_NOTIFICATION_DISMISS_TIME_IN_MS } from '@spaceship-fspl/super';
import {
  FraudVerificationStatus,
  GreenIdRuleSet,
} from '@spaceship-fspl/types/externalapi';
import { TodoItem } from 'components/todo-card';
import { useIntercom } from 'contexts/intercom';
import { useDismissed } from 'helpers/hooks/dismissed';
import { PersistKey } from 'helpers/persist';

interface Todo {
  todoItems: TodoItem[];
  hasTodoItems: boolean;
}

export const useTodo = (): Todo => {
  const { pop: showIntercom } = useIntercom();

  const ruleset = GreenIdRuleSet.Enum.SUPERMATCH;
  const overallGreenIdStatus = useOverallStatus(ruleset);
  const daysSinceLastGreenIDCheck = useDaysSinceLastCheck(ruleset);

  const [
    isGreenIDVerificationSuccessDimissed,
    setIsGreenIDVerificationSuccessDimissed,
  ] = useDismissed(
    PersistKey.SUPERMATCH_GREENID_VERIFICATION_SUCCESS_DISMISSED_UNTIL,
    SUPERMATCH_GREENID_SUCCESS_NOTIFICATION_DISMISS_TIME_IN_MS,
  );

  const isGreenIDStatusFailed = useIsStatusRejected(ruleset);

  const isGreenIDStatusPending = useIsStatusInProgress(ruleset);

  const todoItems: TodoItem[] = [
    {
      title: 'Congratulations! We have successfully verified your identity.',
      body: 'You can now consolidate your super',
      action: () => {
        setIsGreenIDVerificationSuccessDimissed();
      },
      isDismissible: true,
      isShown:
        overallGreenIdStatus ===
          FraudVerificationStatus.Enum.MANUALLY_VERIFIED &&
        daysSinceLastGreenIDCheck < 7 &&
        !isGreenIDVerificationSuccessDimissed,
    },
    {
      title:
        'We’ve received your identification documents and are verifying them.',
      body: 'This normally takes up to one business day.',
      isShown: isGreenIDStatusPending,
    },
    {
      title: 'Oops, we were unable to verify your identity. ',
      body: 'Please contact our customer support team and we’ll get you back on track.',
      action: showIntercom,
      isShown: isGreenIDStatusFailed,
    },
  ]
    .filter((todo) => todo.isShown)
    .slice(0, 2);

  return {
    todoItems,
    hasTodoItems: todoItems.length > 0,
  };
};
