import { gql } from '@apollo/client';
import {
  Box,
  Card,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { MoneyTransactionType } from '@spaceship-fspl/graphql';
import { MoneyActivityCard_MoneyActivityCardFragment as MoneyActivityCard_MoneyActivityCardFragmentType } from '@spaceship-fspl/graphql/src/__generated__/MoneyActivityCard_MoneyActivityCardFragment';
import { FeatherChevronDownIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  color,
  fontWeight,
  getColor,
  getSpace,
  paddingLeft,
  paddingRight,
  text,
} from '@spaceship-fspl/styles';
import { inputShadowStyle } from 'components/input';
import { TestId } from 'helpers/test-ids';
import type { MoneyFilterOptions } from 'pages/money/dashboard';
import React from 'react';
import styled from 'styled-components';

import {
  MoneyTransactionList,
  MoneyTransactionList_MoneyTransactionListConnectionFragment,
} from './money-transaction-list';

export const MoneyActivityCard_MoneyActivityCardFragment = gql`
  fragment MoneyActivityCard_MoneyActivityCardFragment on MoneyInstance {
    id
    transactions(types: $typeFilters, first: $first, after: $after)
      @connection(key: "money-transaction-activity", filter: ["types"]) {
      ...MoneyTransactionList_MoneyTransactionListConnectionFragment
      pageInfo {
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${MoneyTransactionList_MoneyTransactionListConnectionFragment}
`;

type TypeFilterOption = {
  label: string;
  value: MoneyFilterOptions;
};

const typeFilterOptions: Array<TypeFilterOption> = [
  {
    label: 'Show all types',
    value: '',
  },
  {
    label: 'Money in',
    value: MoneyTransactionType.MONEY_IN,
  },
  {
    label: 'Money out',
    value: MoneyTransactionType.MONEY_OUT,
  },
  {
    label: 'Money in reversal',
    value: MoneyTransactionType.MONEY_IN_REVERSAL,
  },
  {
    label: 'Money out reversal',
    value: MoneyTransactionType.MONEY_OUT_REVERSAL,
  },
  {
    label: 'Fees',
    value: MoneyTransactionType.FEE,
  },
  {
    label: 'Buys',
    value: MoneyTransactionType.PURCHASE,
  },
  {
    label: 'Boosts',
    value: MoneyTransactionType.BOOST,
  },
  {
    label: 'Sells',
    value: MoneyTransactionType.SALE,
  },
  {
    label: 'Distributions',
    value: MoneyTransactionType.DISTRIBUTION,
  },
  {
    label: 'Dividends',
    value: MoneyTransactionType.DIVIDEND,
  },
  {
    label: 'Merger/acquisition',
    value: MoneyTransactionType.MERGER_ACQUISITION,
  },
];

export const MoneyActivityCard: React.FC<{
  isLoading: boolean;
  fetchMoreTransactions: () => Promise<void>;
  isFetchingMoreTransactions: boolean;
  setTransactionTypeFilter: (value: MoneyTransactionType) => void;
  selectedTransactionTypeFilter?: MoneyFilterOptions;
  moneyInstance?: MoneyActivityCard_MoneyActivityCardFragmentType | null;
}> = ({
  isLoading,
  isFetchingMoreTransactions,
  fetchMoreTransactions,
  moneyInstance,
  setTransactionTypeFilter,
  selectedTransactionTypeFilter,
}) => {
  const pageInfo = moneyInstance?.transactions?.pageInfo;
  const hasMoreToLoad = pageInfo?.hasNextPage;

  return (
    <Box display="flex" flexDirection="column">
      <Card
        data-testid={TestId.MONEY_ACTIVITY_CARD}
        aria-label="money activity card"
        overflow="hidden"
      >
        <Stack spaceY="sm">
          <Box
            paddingTop="sm"
            paddingLeft="lg"
            paddingRight="lg"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading component="h2" variant={5} isBold={true}>
              Activity
            </Heading>

            <Box position="relative" display="flex" alignItems="center">
              <FilterSelectInput
                data-testid={TestId.MONEY_TRANSACTION_TYPE_FILTER}
                disabled={
                  !moneyInstance?.transactions &&
                  isLoading &&
                  isFetchingMoreTransactions
                }
                value={selectedTransactionTypeFilter}
                onChange={({ target: { value } }): void => {
                  setTransactionTypeFilter(value as MoneyTransactionType);
                }}
              >
                {typeFilterOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </FilterSelectInput>
              <Box position="absolute" right={getSpace('sm')}>
                <FeatherChevronDownIcon size="sm" color="neutral.080" />
              </Box>
            </Box>
          </Box>
          <Divider />
        </Stack>

        {!moneyInstance?.transactions && isLoading ? (
          <Box
            data-testid={TestId.LOADER}
            display="flex"
            justifyContent="center"
            paddingY="lg"
          >
            <Spinner />
          </Box>
        ) : (
          <>
            {moneyInstance?.transactions?.edges ? (
              <MoneyTransactionList
                connection={moneyInstance?.transactions}
                isLoading={isLoading}
                isFetchingMore={isFetchingMoreTransactions}
              />
            ) : (
              <Box padding="lg">
                <Text variant={2} isBold={true} align="center">
                  No transactions to display at this time.
                </Text>
              </Box>
            )}

            {hasMoreToLoad ? (
              <>
                <Divider size="md" />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingY="sm"
                  paddingX={{ xs: 'md', md: 'lg' }}
                >
                  {isFetchingMoreTransactions ? (
                    <Box
                      data-testid={TestId.LOADER}
                      display="flex"
                      justifyContent="center"
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    <LoadMoreButton>
                      <UnstyledButton
                        data-testid={TestId.MONEY_LOAD_MORE_TRANSACTIONS_BUTTON}
                        isDisabled={!hasMoreToLoad}
                        onClick={fetchMoreTransactions}
                      >
                        Load more transactions
                      </UnstyledButton>
                    </LoadMoreButton>
                  )}
                </Box>
              </>
            ) : null}
          </>
        )}
      </Card>

      {!moneyInstance && isLoading ? null : (
        <>
          {moneyInstance?.transactions?.edges ? (
            <Box display="flex" paddingY="xs" justifyContent="center">
              <Text variant={3}>
                Showing {moneyInstance?.transactions?.edges.length} of{' '}
                {pageInfo?.total} entries
              </Text>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

const LoadMoreButton = styled.div`
  cursor: pointer;

  :hover {
    text-decoration: underline;
    text-decoration-color: ${getColor('indigo.070')};
  }

  button {
    font-family:
      Inter,
      -apple-system,
      BlinkMacSystemFont,
      Helvetica Neue,
      Helvetica,
      sans-serif;
    color: ${getColor('neutral.100')};
    font-weight: bold;
    font-size: 0.825rem;
    padding-block: 0.425rem;
  }

  button {
    position: relative;
    color: #000;
    text-decoration: none;
  }

  button:hover {
    color: #000;
  }

  button::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${getColor('indigo.070')};
    transform-origin: top left;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  button:hover::before {
    transform: scaleX(1);
  }
`;

const FilterSelectInput = styled.select`
  ${backgroundColor('neutral.000')}
  ${borderRadius('xxs')}
  ${text({ variant: 4 })}
  ${color('neutral.100')}
  ${fontWeight(600)}
  ${paddingLeft('sm')}
  ${paddingRight('lg')}
  ${inputShadowStyle({ color: 'neutral.050' })}
  appearance: none;
  border: none;
  outline: none;
  line-height: normal;
  width: 180px;
  height: 40px;

  :hover,
  :focus {
    cursor: pointer;
    ${inputShadowStyle({ color: 'indigo.070', isThick: false })}
  }
`;
