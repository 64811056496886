import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  fromProtoFrequency,
  fromProtoPortfolio,
  ScheduleFrequency,
  useCreatePaymentSchedule,
  useCreateSaverProductInstances,
} from '@spaceship-fspl/graphql';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { formatDateForRequests, stringToDate } from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { OnboardingSummary } from 'components/layouts/onboarding-summary';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { usePortfolioRegistration } from 'contexts/saver/portfolio-registration';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';

import { BankAccountSection } from './bank-account';
import { InvestmentPlanSection, OneOffInvestmentSection } from './investments';
import { PortfolioSection } from './portfolio';

export const VoyagerPortfolioRegistrationSummary: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const [isPortfolioEditing, setIsPortfolioEditing] = useState(false);
  const [isDepositEditing, setIsDepositEditing] = useState(false);
  const [isInvestmentPlanEditing, setIsInvestmentPlanEditing] = useState(false);
  const isEditing =
    isPortfolioEditing || isDepositEditing || isInvestmentPlanEditing;
  const { portfolio, investmentPlan, oneOffDeposit } =
    usePortfolioRegistration();
  const [createPaymentSchedule] = useCreatePaymentSchedule();
  const [createSaverProductInstances] = useCreateSaverProductInstances();
  const { popToast } = useNotifications();
  const intercom = useIntercom();
  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );

  useEffect(() => {
    if (!portfolio) {
      navigate(Routes.PORTFOLIO_ADD);
    }
  }, [navigate, portfolio]);

  const submit = async (): Promise<void> => {
    const gqlPortfolio = fromProtoPortfolio(portfolio);
    let successMessage;
    let newProductId;

    if (!gqlPortfolio) {
      return;
    }

    try {
      const { data } = await createSaverProductInstances({
        variables: { input: { portfolios: [gqlPortfolio] } },
      });
      const saverProductInstances =
        data?.createSaverProductInstances?.account?.saverProductInstances;

      newProductId = saverProductInstances?.find(
        (p) => p.portfolio === gqlPortfolio,
      )?.id;

      if (!newProductId) {
        throw Error('Portfolio registration: cannot find new product id');
      }
    } catch (error) {
      addRumError({ error });
      popToast({
        message: GENERIC_ERROR_MESSAGE,
        level: 'error',
        cta: {
          message: 'Contact support',
          action: intercom.pop,
        },
      });
      return;
    }

    try {
      const promises = [];

      if (oneOffDeposit) {
        promises.push(
          createPaymentSchedule({
            variables: {
              input: {
                productId: newProductId,
                audAmount: oneOffDeposit,
                frequency: ScheduleFrequency.ONE_TIME,
              },
            },
          }),
        );
      }

      if (
        investmentPlan?.amount &&
        investmentPlan?.start_date &&
        investmentPlan?.frequency
      ) {
        promises.push(
          createPaymentSchedule({
            variables: {
              input: {
                productId: newProductId,
                audAmount: investmentPlan.amount,
                startDate: formatDateForRequests(
                  stringToDate(investmentPlan.start_date),
                ),
                frequency: fromProtoFrequency(investmentPlan.frequency),
              },
            },
          }),
        );
      }

      if (promises.length) {
        await Promise.all(promises);
      }
    } catch (error) {
      addRumError({ error });
      successMessage =
        'Your new portfolio has been successfully added, however there was a problem setting up some of your investments.';
    }

    track?.(MarketingTrackingEvents.VOYAGER_ADD_NEW_PORTFOLIO_SUCCESSFUL);

    navigate(`${Routes.PORTFOLIO_ADD_SUCCESS}`, {
      state: {
        productId: newProductId,
        message: successMessage,
      },
    });
  };

  return (
    <OnboardingSummary
      variant="portfolio_registration"
      subtitle="Please check your bank account and investment information carefully."
      isEditing={isEditing}
      onComplete={submit}
    >
      <PortfolioSection
        isEditing={isPortfolioEditing}
        setIsEditing={setIsPortfolioEditing}
      />
      <BankAccountSection />
      {isVerifiedUser && (
        <OneOffInvestmentSection
          isEditing={isDepositEditing}
          setIsEditing={setIsDepositEditing}
        />
      )}
      <InvestmentPlanSection
        isEditing={isInvestmentPlanEditing}
        setIsEditing={setIsInvestmentPlanEditing}
      />
    </OnboardingSummary>
  );
};
