import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Spinner,
  Stack,
  Text,
  TextLink,
  UnstyledButton,
  Visible,
} from '@spaceship-fspl/components';
import { ErrorWithCode, NEW_PASSWORD_RULE } from '@spaceship-fspl/helpers';
import {
  FeatherArrowLeftIcon,
  FeatherChevronLeftIcon,
} from '@spaceship-fspl/icons-web';
import { useAmplifyAuth } from '@spaceship-fspl/super';
import { Button } from 'components/button';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { commonValidationRules } from 'helpers/validation';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

export const PasswordResetVerifyCognito: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = (state as { email?: string })?.email;
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const {
    forgotPassword: amplifyResetPassword,
    forgotPasswordSubmit: amplifyResetPasswordSubmit,
  } = useAmplifyAuth();

  const { control, handleSubmit } = useForm<{
    code: string;
    new_password: string;
  }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  useEffect(() => {
    if (!email) {
      navigate(Routes.PASSWORD_RESET);
    }
  }, [email, navigate]);

  if (!email) {
    return null;
  }

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          {isSendingCode ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Spinner />
            </Box>
          ) : (
            <form
              onSubmit={handleSubmit(async ({ code, new_password }) => {
                setLoading(true);
                try {
                  await amplifyResetPasswordSubmit(email, code, new_password);
                  setLoading(false);
                  await navigate(Routes.LOGIN);
                  notifications.popToast({
                    message: 'Your password was successfully reset.',
                    level: 'success',
                  });
                } catch (error) {
                  if (
                    (error as ErrorWithCode | undefined)?.code ===
                    'CodeMismatchException'
                  ) {
                    notifications.popToast({
                      level: 'error',
                      message: 'Invalid verification code.',
                    });
                  } else {
                    addRumError({ error });
                    // network issues, validation issues (e.g. pw too long) and all other errors
                    notifications.popToast({
                      level: 'error',
                      message: GENERIC_ERROR_MESSAGE,
                    });
                  }
                }
                setLoading(false);
              })}
            >
              <Stack spaceY="lg">
                <Stack spaceY="xs" alignX={{ sm: 'left', md: 'center' }}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Visible
                      isHidden={{ xs: true, md: false }}
                      displayValue="block"
                    >
                      <UnstyledButton
                        onClick={() => navigate(Routes.PASSWORD_RESET)}
                      >
                        <FeatherChevronLeftIcon
                          color="neutral.100"
                          size="lg"
                          strokeWidth={3}
                        />
                      </UnstyledButton>
                    </Visible>
                    <Heading variant={3}>Reset your password</Heading>
                  </Box>
                  <Text
                    variant={2}
                    color="neutral.080"
                    align={{ sm: 'left', md: 'center' }}
                  >
                    We sent a message to your email with a 6-digit verification
                    code.
                  </Text>
                </Stack>

                <Stack spaceY="xs">
                  <ControllerInput
                    name="code"
                    control={control}
                    type="text"
                    format="otp"
                    placeholder="Code"
                    rules={validation.code}
                  />
                  <Text variant={3} color="neutral.080">
                    Didn&apos;t receive an email?{' '}
                    <TextLink
                      color="indigo.070"
                      onClick={async (): Promise<void> => {
                        setIsSendingCode(true);
                        try {
                          await amplifyResetPassword(email);
                          notifications.popToast({
                            level: 'success',
                            message: 'A new code is on its way!',
                          });
                        } catch {
                          notifications.popToast({
                            message:
                              'Unable to resend verification code. Please try again or contact support.',
                            level: 'warning',
                          });
                        }
                        setIsSendingCode(false);
                      }}
                    >
                      Send again
                    </TextLink>
                  </Text>
                </Stack>

                <Stack spaceY="xs">
                  <ControllerPasswordInput
                    name="new_password"
                    control={control}
                    placeholder="New Password"
                    rules={commonValidationRules.password}
                  />
                  <Text variant={3} color="neutral.080">
                    {NEW_PASSWORD_RULE}
                  </Text>
                </Stack>
              </Stack>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{ name: 'reset_password_cognito' }}
                  isLoading={loading}
                >
                  Verify
                </PageFormContinueButton>

                <Visible
                  isHidden={{ xs: false, md: true }}
                  displayValue="block"
                >
                  <Button
                    type="button"
                    variant="tertiary"
                    size="lg"
                    onClick={() => navigate?.(Routes.PASSWORD_RESET)}
                    trackingProperties={{ name: 'reset_password_cognito_back' }}
                    isDisabled={loading}
                    before={<FeatherArrowLeftIcon />}
                  >
                    Back
                  </Button>
                </Visible>
              </PageFormButtonContainer>
            </form>
          )}
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

const validation = {
  code: { required: 'Verification code is required' },
};
