import { RouteComponentProps, Router } from '@reach/router';
import {
  GreenIDSuperMatchConsolidateProvider,
  GreenIDSuperMatchOnboardingProvider,
  GreenIDVoyagerOnboardingProvider,
} from 'contexts/greenid';
import { GreenID } from 'pages/greenid';
import { AustraliaPassport } from 'pages/greenid/australia-passport';
import { CitizenshipCertificate } from 'pages/greenid/citizenship-certificate';
import { DriverLicence } from 'pages/greenid/driver-licence';
import { ForeignPassport } from 'pages/greenid/foreign-passport';
import { MedicareCard } from 'pages/greenid/medicare-card';
import { GreenIDUploadDocuments } from 'pages/greenid/upload';
import { PageNotFound } from 'pages/page-not-found';
import React from 'react';

const GreenIDRouter: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <Router>
      <GreenID path="/" />
      <GreenIDUploadDocuments path="/:sourceName/upload" />
      <DriverLicence path="driver-licence" />
      <AustraliaPassport path="australia-passport" />
      <ForeignPassport path="foreign-passport" />
      <CitizenshipCertificate path="citizenship-certificate" />
      <MedicareCard path="medicare-card" />
      <PageNotFound default={true} />
    </Router>
  );
};

export const VoyagerOnboardingGreenID: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <GreenIDVoyagerOnboardingProvider>
      <GreenIDRouter />
    </GreenIDVoyagerOnboardingProvider>
  );
};

export const SuperMatchOnboardingGreenID: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <GreenIDSuperMatchOnboardingProvider>
      <GreenIDRouter />
    </GreenIDSuperMatchOnboardingProvider>
  );
};

export const SuperMatchConsolidateGreenID: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <GreenIDSuperMatchConsolidateProvider>
      <GreenIDRouter />
    </GreenIDSuperMatchConsolidateProvider>
  );
};
