import * as React from 'react';
export const FundFromSpaceship = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M17.133 12.733a5.867 5.867 0 100-11.733 5.867 5.867 0 000 11.733zM8.333 14.2H6.345a1.312 1.312 0 00-.488 2.53l2.018.807a1.312 1.312 0 01-.49 2.53H5.4M6.867 14.2v-.733M6.867 20.8v-.733" />
    <path vectorEffect="non-scaling-stroke" d="M6.867 23a5.867 5.867 0 100-11.733 5.867 5.867 0 000 11.733zM1 4.667l2.2 3.666 3.667-2.2M8.274 1.146a6.602 6.602 0 00-4.32 6.733" />
    <path vectorEffect="non-scaling-stroke"
      d="M14.678 6.583c.098.285.337.557.985.448.529-.09 1.452-.574 2.298-1.017.588-.308 1.138-.597 1.492-.719a2.921 2.921 0 00-2.297-1.117c-.68 0-1.313.222-1.802.622-.641.525-.84 1.308-.676 1.783zm6.078-1.76a2.414 2.414 0 00-.41-.48c.069.015.55.119.553.117.404-.266.767-.234.767-.234s-.117.347-.522.613l-.027.128c-.03.15-.08.382-.089.428a2.423 2.423 0 00-.272-.572zm-.23.128s-.193.357-.723.906c.184.385.288.818.288 1.274a2.948 2.948 0 01-2.454 2.913c.815-.298 1.407-1.058 1.003-1.772-.292-.517-1.061-.463-1.863-.122-1.748.744-2.821 1.083-2.821 1.083l6.57-4.282z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);
