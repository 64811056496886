import * as React from 'react';
export const StreamlineAccountingCoins = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M8 8.5a7.5 7.5 0 1015 0 7.5 7.5 0 00-15 0zM7 14c0 .288.064.569.185.807.12.238.29.422.49.53l1.652.886c.231.125.423.354.542.648.12.293.159.633.111.962a1.59 1.59 0 01-.37.837c-.192.213-.434.33-.683.33H7.3M8 20v-1M5.414 9A7.297 7.297 0 1015 18.585" />
    <path vectorEffect="non-scaling-stroke" d="M17.603 5h-2.171c-.333 0-.655.109-.912.307a1.329 1.329 0 00-.494.78c-.063.306-.01.623.149.897.159.273.414.487.723.603l2.204.826c.309.116.564.33.724.603.159.274.211.59.148.897a1.33 1.33 0 01-.494.78 1.494 1.494 0 01-.912.307H14.4M16 5V4M16 13v-2" />
  </svg>
);
