import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSaver } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { validateEmail } from '@spaceship-fspl/helpers';
import { useAmplifyAuth } from '@spaceship-fspl/super';
import { ControllerInput } from 'components/controller-input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface SuperLinkResetPasswordProps {
  location: {
    state: {
      email?: string;
      isResetRequired?: boolean;
    };
  };
}

export const SuperLinkResetPassword: React.FC<
  React.PropsWithChildren<RouteComponentProps<SuperLinkResetPasswordProps>>
> = ({ location }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { popToast } = useNotifications();
  const isVoyagerUser = useCanReadSaver();
  const { forgotPassword } = useAmplifyAuth();
  const isResetRequired = location?.state?.isResetRequired;

  const { control, handleSubmit } = useForm<{
    email: string;
  }>({
    shouldFocusError: true,
    defaultValues: {
      email: location?.state?.email ?? '',
    },
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    setIsLoading(true);

    try {
      await forgotPassword(email);
      setIsLoading(false);
      navigate(Routes.SUPER_LINK_RESET_PASSWORD_NEW, { state: { email } });
    } catch (error) {
      addRumError({
        error,
        context: {
          reason: 'awscognito: forgotPassword - unable to send email OTP',
        },
      });
      popToast({
        level: 'error',
        message:
          'There seems to be a problem. Please check your email and try again.',
      });
      setIsLoading(false);
    }
  });

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 3 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="xl">
              <PageHeading
                title="Reset your password"
                subtitle={
                  isResetRequired ? (
                    <>
                      Please reset your password in order to access your
                      Spaceship Super account.
                    </>
                  ) : (
                    <>Forgot your password? Let&apos;s set a new one.</>
                  )
                }
              />

              <ControllerInput
                name="email"
                control={control}
                type="email"
                placeholder="Email address"
                rules={{
                  ...requiredValidation('Email'),
                  validate: validateEmail,
                }}
              />
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{
                  name: 'super_link_reset_password_continue',
                }}
                isLoading={isLoading}
              />
            </PageFormButtonContainer>

            {(!isResetRequired || isVoyagerUser) && (
              <Box marginTop="lg">
                <Stack spaceY="xxxs">
                  {!isResetRequired && (
                    <Text variant={3} color="neutral.080" align="center">
                      Just remembered your password?{' '}
                      <RouterLink
                        to={Routes.SUPER_LINK}
                        trackingProperties={{
                          name: 'super_link_reset_password_page_login',
                        }}
                      >
                        <TextLink color="indigo.070" component="span">
                          Log in
                        </TextLink>
                      </RouterLink>
                    </Text>
                  )}

                  {isVoyagerUser && (
                    <Text variant={3} color="neutral.080" align="center">
                      Don&apos;t have an account?{' '}
                      <RouterLink
                        to={Routes.ACCOUNT_CONFIRM_DETAILS}
                        state={{
                          nextRoute: Routes.SUPER_SIGNUP_PORTFOLIO,
                        }}
                        trackingProperties={{
                          name: 'super_link_reset_password_page_signup',
                        }}
                      >
                        <TextLink color="indigo.070" component="span">
                          Sign up
                        </TextLink>
                      </RouterLink>
                    </Text>
                  )}
                </Stack>
              </Box>
            )}
          </form>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};
