import * as React from 'react';
export const VoyagerExplorerStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M2.037 17.461l-.376.189s-.154.08-.257.218c-.11.139-.178.36-.178.363l-.204.687-.208-.687a1.278 1.278 0 00-.177-.363c-.101-.133-.255-.212-.258-.218L0 17.46l.379-.192s.154-.08.258-.212c.11-.144.177-.36.177-.363l.205-.687.207.687c.018.062.086.245.178.363.103.133.257.212.257.212l.376.192zM8.75 1.684l-.434.215s-.179.095-.296.25a1.453 1.453 0 00-.206.42l-.232.795-.24-.795a1.46 1.46 0 00-.205-.42c-.114-.152-.293-.246-.296-.25l-.438-.215.438-.223s.178-.094.296-.245c.128-.165.206-.418.206-.421L7.575 0l.24.795c.02.07.097.282.205.42.117.152.296.246.296.246l.434.223zM23.885 19.438l-.435.215s-.178.095-.296.25a1.452 1.452 0 00-.205.42l-.233.795-.239-.795a1.463 1.463 0 00-.205-.42c-.115-.152-.293-.246-.297-.25l-.438-.215.438-.223s.179-.094.297-.245c.128-.165.205-.418.205-.421l.232-.795.24.794c.02.071.097.283.205.422.118.151.296.245.296.245l.435.223zM17.59 2.323c-.72 0-1.411.127-2.05.357l7.789 7.547a6.034 6.034 0 00-5.739-7.904zM16.37 8.765L4.225 20.908a.509.509 0 000 .72l.038.038c.199.198.52.198.72 0L17.125 9.523a.509.509 0 000-.72l-.038-.038a.509.509 0 00-.719 0zM20.372 15.127l-4.974 8.619a.507.507 0 01-.694.185l-.047-.027a.507.507 0 01-.186-.694l4.49-7.776 1.414-.307h-.003zM10.73 5.26l-8.524 5.129a.51.51 0 00-.175.697l.026.044a.51.51 0 00.698.175l7.692-4.628.283-1.418z" />
    <path d="M14.111 3.427a6.047 6.047 0 00-1.741 1.906l2.984 2.89.596-.596c.64-.64 1.674-.64 2.314 0 .64.64.64 1.674 0 2.314l-.56.559 3.079 2.98a6.088 6.088 0 001.849-1.801l-8.518-8.252h-.003zM16.752 11.45L14.6 13.602a5.997 5.997 0 002.99.791c.66 0 1.297-.108 1.893-.303l-2.728-2.64h-.003zM14.4 9.173l-2.6-2.52a6.033 6.033 0 00.498 4.62l2.103-2.1zM2.037 17.461l-.376.189s-.154.08-.257.218c-.11.139-.178.36-.178.363l-.204.687-.208-.687a1.278 1.278 0 00-.177-.363c-.101-.133-.255-.212-.258-.218L0 17.46l.379-.192s.154-.08.258-.212c.11-.144.177-.36.177-.363l.205-.687.207.687c.018.062.086.245.178.363.103.133.257.212.257.212l.376.192zM8.75 1.684l-.434.215s-.179.095-.296.25a1.453 1.453 0 00-.206.42l-.232.795-.24-.795a1.46 1.46 0 00-.205-.42c-.114-.152-.293-.246-.296-.25l-.438-.215.438-.223s.178-.094.296-.245c.128-.165.206-.418.206-.421L7.575 0l.24.795c.02.07.097.282.205.42.117.152.296.246.296.246l.434.223zM23.885 19.438l-.435.215s-.178.095-.296.25a1.452 1.452 0 00-.205.42l-.233.795-.239-.795a1.463 1.463 0 00-.205-.42c-.115-.152-.293-.246-.297-.25l-.438-.215.438-.223s.179-.094.297-.245c.128-.165.205-.418.205-.421l.232-.795.24.794c.02.071.097.283.205.422.118.151.296.245.296.245l.435.223zM17.59 2.323c-.72 0-1.411.127-2.05.357l7.789 7.547a6.034 6.034 0 00-5.739-7.904z" />
    <path d="M16.37 8.765L4.225 20.908a.509.509 0 000 .72l.038.038c.199.198.52.198.72 0L17.125 9.523a.509.509 0 000-.72l-.038-.038a.509.509 0 00-.719 0zM20.372 15.127l-4.974 8.619a.507.507 0 01-.694.185l-.047-.027a.507.507 0 01-.186-.694l4.49-7.776 1.414-.307h-.003zM10.73 5.26l-8.524 5.129a.51.51 0 00-.175.697l.026.044a.51.51 0 00.698.175l7.692-4.628.283-1.418z" />
    <path d="M14.111 3.427a6.047 6.047 0 00-1.741 1.906l2.984 2.89.596-.596c.64-.64 1.674-.64 2.314 0 .64.64.64 1.674 0 2.314l-.56.559 3.079 2.98a6.088 6.088 0 001.849-1.801l-8.518-8.252h-.003zM16.752 11.45L14.6 13.602a5.997 5.997 0 002.99.791c.66 0 1.297-.108 1.893-.303l-2.728-2.64h-.003zM14.4 9.173l-2.6-2.52a6.033 6.033 0 00.498 4.62l2.103-2.1z" />
  </svg>
);
