import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import { Box, Spinner, TextLink } from '@spaceship-fspl/components';
import { WebAppVoyagerDeposit } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerDeposit';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Error, InlineContactSupportMessage } from 'components/layouts/error';
import { CenterPageContainer, PageContainer } from 'components/layouts/page';
import { PendingVerification } from 'components/layouts/pending-verification';
import {
  OneOffDepositOnCompleteData,
  VoyagerOneOffDepositForm,
  VoyagerOneOffDepositFormFragment,
} from 'components/voyager-one-off-deposit-form';
import { withVoyagerTopNavigation } from 'navigation/helpers';
import React from 'react';

import { FeatureFlagKeys, useFeatureFlag } from '../helpers/dynamic-config';
import { Routes } from './routes';

interface VoyagerDepositProps {
  productId?: string;
}

const Deposit: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerDepositProps>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: VoyagerDepositProps = useParams();

  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );

  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  const resp = useQuery<WebAppVoyagerDeposit>(
    gql`
      query WebAppVoyagerDeposit($isMoneyDayOneEnabled: Boolean! = false) {
        contact {
          id
          account {
            id
            ...VoyagerOneOffDepositFormFragment
            __typename @include(if: $isMoneyDayOneEnabled)
          }
        }
      }
      ${VoyagerOneOffDepositFormFragment}
    `,
    {
      variables: {
        isMoneyDayOneEnabled,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const isLoading =
    resp.loading || resp.networkStatus === NetworkStatus.refetch;

  const onCancel = (): void => {
    navigate(-1);
  };

  const portfolio =
    resp.data?.contact?.account?.saverProductInstancesConnection?.edges?.find(
      ({ node }) => node?.id === productId,
    )?.node?.portfolio;

  const handleSubmit = async ({
    productId,
    audAmount,
    etaDate,
  }: OneOffDepositOnCompleteData): Promise<void> => {
    navigate(`${Routes.VOYAGER_DEPOSIT_CONFIRM}/${productId}`, {
      state: {
        productId,
        portfolio,
        audAmount,
        etaDate,
      },
    });

    return;
  };

  if (isLoading) {
    return (
      <CenterPageContainer>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      </CenterPageContainer>
    );
  }

  if (resp.error) {
    return (
      <CenterPageContainer>
        <Error
          iconColor="indigo.070"
          title="We’ve run into a problem."
          buttonText="Try again"
          subtitle={<InlineContactSupportMessage />}
          secondaryButton={
            <TextLink
              hideUnderline
              color="indigo.070"
              onClick={() => {
                navigate(Routes.VOYAGER_DASHBOARD);
              }}
            >
              Go back
            </TextLink>
          }
          onContinue={{
            trackingProperties: {
              name: 'one_off_deposit_error_try_again',
            },
            onClick: () => {
              resp.refetch();
            },
          }}
        />
      </CenterPageContainer>
    );
  }

  if (!isVerifiedUser) {
    return (
      <CenterPageContainer>
        <PendingVerification
          variant="investment"
          onBack={{
            trackingProperties: {
              name: 'one_off_deposit_verify_go_back',
            },
            onClick: () => navigate(Routes.VOYAGER_DASHBOARD),
          }}
        />
      </CenterPageContainer>
    );
  }

  return (
    <PageContainer>
      <VoyagerOneOffDepositForm
        account={resp.data?.contact?.account}
        variant="loggedin"
        onComplete={handleSubmit}
        onCancel={onCancel}
        activeBankAccountNumber={
          resp?.data?.contact?.account?.activeBankAccount?.accountNumber || ''
        }
        productId={productId}
      />
    </PageContainer>
  );
};

export const VoyagerDeposit = withVoyagerTopNavigation()(Deposit);
