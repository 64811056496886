import { backgroundColor, borderWidth } from '@spaceship-fspl/styles';
import { createProps } from '@spaceship-fspl/substance-style';
import React from 'react';
import RRImage from 'react-render-image';
import styled, { css } from 'styled-components';

import { LogoBadge } from '../logo';

export type AvatarSize = 'sm' | 'md' | 'lg' | 'xl';

const sizes: Record<AvatarSize, string> = {
  sm: '32px',
  md: '48px',
  lg: '64px',
  xl: '200px',
};

const props = createProps({
  $size: (size: AvatarSize) => css`
    height: ${sizes[size]};
    width: ${sizes[size]};
  `,
});

interface AvatarProps {
  size: AvatarSize;
  src?: string;
  srcset?: string;
  alt?: string;
}

const Image = styled.img`
  ${props}
`;

const Wrapper = styled.div`
  ${props}

  ${backgroundColor('neutral.030')}
  ${borderWidth('md')}
  align-items: center;
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: 0.15s;
`;

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
  children,
  src,
  srcset,
  size,
  alt,
  ...props
}) => {
  if (process.env.NODE_ENV === 'development') {
    if (children && src) {
      console.error(
        'Avatar: Cannot render both an image and children. The image will be rendered.',
      );
    }
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !props['aria-label'] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !props['aria-describedby'] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !props['aria-hidden']
    ) {
      console.error('Avatar: No accessiblity label provided.');
    }
  }
  return (
    <Wrapper {...props} $size={size}>
      {src && (
        <RRImage
          src={src}
          srcset={srcset}
          sizes={sizes[size]}
          loaded={
            <Image
              $size={size}
              src={src}
              srcSet={srcset}
              sizes={sizes[size]}
              alt={alt}
            />
          }
          errored={<LogoBadge variant="dark" size={size} />}
        />
      )}
      {!src && children}
      {!src && !children && <LogoBadge variant="dark" size={size} />}
    </Wrapper>
  );
};
