import { Box, Text } from '@spaceship-fspl/components';
import { sydneyDate, TIMEZONE_SYDNEY } from '@spaceship-fspl/helpers';
import type { Color } from '@spaceship-fspl/styles';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { toISODate } from 'helpers/format';
import React from 'react';

const formatDate = (value: number, hideTodayLabel = false): string => {
  return toISODate(toDate(value, TIMEZONE_SYDNEY)) == toISODate(sydneyDate()) &&
    !hideTodayLabel
    ? 'TODAY'
    : format(new Date(value), 'MMM yyyy');
};

export const ChartLabels: React.FC<{
  start: number;
  end: number;
  hideTodayLabel?: boolean;
  labelColor: Color;
}> = ({ start, end, labelColor, hideTodayLabel }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Text variant={4} isBold={true} color={labelColor}>
        {formatDate(start, hideTodayLabel)}
      </Text>
      <Text variant={4} isBold={true} color={labelColor}>
        {formatDate(end, hideTodayLabel)}
      </Text>
    </Box>
  );
};

ChartLabels.displayName = 'ChartLabels';
