import { ApolloError } from '@apollo/client';

export type ErrorWithCode<T = string> = Error & { code: T };

type Assert = (error: unknown, message?: string) => asserts error;

export const rethrowError: Assert = (
  error: unknown,
  message = 'Unknown error occurred',
): asserts error => {
  if (error === undefined || error === null) {
    throw new Error(message);
  }

  if (typeof error === 'string') {
    throw new Error(error);
  }

  throw error;
};

export const prettifyError = (
  error: unknown,
  fallbackMessage = "Sorry, we've run into a problem. Please try again or contact support.",
): string => {
  if (error instanceof ApolloError) {
    if (
      error.message.match(
        /(response not successful|network request failed)/i,
      ) ||
      error.message.length === 0
    ) {
      return fallbackMessage;
    }

    return error.message;
  }

  return fallbackMessage;
};

export const canIgnoreError = (error: unknown): boolean => {
  if (error instanceof Error) {
    // Intercom throws a double registration error here for no reason
    // see https://spaceship-hq.atlassian.net/browse/ENG-2895
    return !!error.message.match(/we already have a registered user/i);
  }

  return false;
};
