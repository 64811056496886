import { toSydneyStartOfDay } from '@spaceship-fspl/helpers';
import { IUnitPriceGraphDataPoint } from '@spaceship-fspl/types/externalapi';
import { subYears } from 'date-fns';

export const hasLessThanOneYearOfData = (
  data?: IUnitPriceGraphDataPoint[] | null,
): boolean => {
  const first = data?.[0];
  const last = data?.[data?.length - 1];

  const firstDate = first?.date && toSydneyStartOfDay(new Date(first.date));
  const lastDate = last?.date && toSydneyStartOfDay(new Date(last.date));

  if (!firstDate || !lastDate) {
    return true;
  }

  return firstDate > subYears(lastDate, 1);
};
