import { navigate, RouteComponentProps } from '@reach/router';
import { IDName, IDSourceList } from '@spaceship-fspl/green-id';
import { createListContext } from '@spaceship-fspl/helpers';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Routes } from 'pages/routes';
import React, { createContext, useContext } from 'react';

import { useOnboardingRequestContext } from './saver/onboarding';

const [SelectedGreenIDSourcesProvider, useSelectedGreenIDSources] =
  createListContext<IDName>();

export { useSelectedGreenIDSources };

interface GreenID {
  ruleset: GreenIdRuleSet.Enum;
  routes: Record<'index' | IDName, Routes>;
  getUploadLinkBySourceName: (sourceName: IDSourceList) => string;
  onPending: () => void;
  onVerified: () => void;
}

const GreenIDContext = createContext<GreenID | undefined>(undefined);

export const GREEN_ID_VOYAGER_ONBOARDING_ROUTES = {
  index: Routes.VOYAGER_ONBOARDING_GREENID,
  [IDName.AUSTRALA_DRIVER_LICENCE]:
    Routes.VOYAGER_ONBOARDING_GREENID_DRIVER_LICENCE,
  [IDName.AUSTRALIAN_PASSPORT]:
    Routes.VOYAGER_ONBOARDING_GREENID_AUSTRALIA_PASSPORT,
  [IDName.INTERNATIONAL_PASSPORT]:
    Routes.VOYAGER_ONBOARDING_GREENID_FOREIGN_PASSPORT,
  [IDName.MEDICARE_CARD]: Routes.VOYAGER_ONBOARDING_GREENID_MEDICARE,
  [IDName.AUSTRALIAN_CITIZENSHIP_CERTIFICATE]:
    Routes.VOYAGER_ONBOARDING_GREENID_CITIZENSHIP_CERTIFICATE,
};

export const GreenIDVoyagerOnboardingProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const [requests] = useOnboardingRequestContext();

  const getUploadLinkBySourceName = (sourceName: IDSourceList): string =>
    `${GREEN_ID_VOYAGER_ONBOARDING_ROUTES.index}/${sourceName}/upload`;

  const navigateToBAV = (): void => {
    navigate(Routes.VOYAGER_ONBOARDING_BANK_ACCOUNT);
  };

  const handleVerified = (): void => {
    // if user has selected portfolio & setup a bank account, redirect to summary screen
    if (
      requests.createSaverAccount?.portfolio &&
      !!requests.createSaverAccount.source_account_name
    ) {
      navigate(Routes.VOYAGER_ONBOARDING_SUMMARY);
      return;
    }

    navigateToBAV();
  };

  return (
    <GreenIDContext.Provider
      value={{
        ruleset: GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
        routes: GREEN_ID_VOYAGER_ONBOARDING_ROUTES,
        getUploadLinkBySourceName,
        onPending: navigateToBAV,
        onVerified: handleVerified,
      }}
    >
      <SelectedGreenIDSourcesProvider>
        {children}
      </SelectedGreenIDSourcesProvider>
    </GreenIDContext.Provider>
  );
};

export const GreenIDSuperMatchOnboardingProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const routes = {
    index: Routes.SUPER_SIGNUP_SUPERMATCH_GREENID,
    [IDName.AUSTRALA_DRIVER_LICENCE]:
      Routes.SUPER_SIGNUP_SUPERMATCH_GREENID_DRIVER_LICENCE,
    [IDName.AUSTRALIAN_PASSPORT]:
      Routes.SUPER_SIGNUP_SUPERMATCH_GREENID_AUSTRALIA_PASSPORT,
    [IDName.INTERNATIONAL_PASSPORT]:
      Routes.SUPER_SIGNUP_SUPERMATCH_GREENID_FOREIGN_PASSPORT,
    [IDName.MEDICARE_CARD]: Routes.SUPER_SIGNUP_SUPERMATCH_GREENID_MEDICARE,
    [IDName.AUSTRALIAN_CITIZENSHIP_CERTIFICATE]:
      Routes.SUPER_SIGNUP_SUPERMATCH_GREENID_CITIZENSHIP_CERTIFICATE,
  };

  const getUploadLinkBySourceName = (sourceName: IDSourceList): string =>
    `${routes.index}/${sourceName}/upload`;

  const navigateToEmployerContribution = (): void => {
    navigate(Routes.SUPER_SIGNUP_EMPLOYER_CONTRIBUTION);
  };

  const navigateToStartFundSearch = (): void => {
    navigate(Routes.SUPER_SIGNUP_SUPERMATCH_RESULTS);
  };

  return (
    <GreenIDContext.Provider
      value={{
        ruleset: GreenIdRuleSet.Enum.SUPERMATCH,
        routes,
        getUploadLinkBySourceName,
        onPending: navigateToEmployerContribution,
        onVerified: navigateToStartFundSearch,
      }}
    >
      <SelectedGreenIDSourcesProvider>
        {children}
      </SelectedGreenIDSourcesProvider>
    </GreenIDContext.Provider>
  );
};

export const GreenIDSuperMatchConsolidateProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => {
  const routes = {
    index: Routes.SUPER_SUPERMATCH_GREENID,
    [IDName.AUSTRALA_DRIVER_LICENCE]:
      Routes.SUPER_SUPERMATCH_GREENID_DRIVER_LICENCE,
    [IDName.AUSTRALIAN_PASSPORT]:
      Routes.SUPER_SUPERMATCH_GREENID_AUSTRALIA_PASSPORT,
    [IDName.INTERNATIONAL_PASSPORT]:
      Routes.SUPER_SUPERMATCH_GREENID_FOREIGN_PASSPORT,
    [IDName.MEDICARE_CARD]: Routes.SUPER_SUPERMATCH_GREENID_MEDICARE,
    [IDName.AUSTRALIAN_CITIZENSHIP_CERTIFICATE]:
      Routes.SUPER_SUPERMATCH_GREENID_CITIZENSHIP_CERTIFICATE,
  };

  const getUploadLinkBySourceName = (sourceName: IDSourceList): string =>
    `${routes.index}/${sourceName}/upload`;

  const navigateToDashboard = (): void => {
    navigate(Routes.SUPER_DASHBOARD);
  };

  const navigateToStartFundSearch = (): void => {
    navigate(Routes.SUPER_SUPERMATCH_RESULTS);
  };

  return (
    <GreenIDContext.Provider
      value={{
        ruleset: GreenIdRuleSet.Enum.SUPERMATCH,
        routes,
        getUploadLinkBySourceName,
        onPending: navigateToDashboard,
        onVerified: navigateToStartFundSearch,
      }}
    >
      <SelectedGreenIDSourcesProvider>
        {children}
      </SelectedGreenIDSourcesProvider>
    </GreenIDContext.Provider>
  );
};

export const useGreenID = (): GreenID => {
  const context = useContext(GreenIDContext);

  if (!context) {
    throw new Error(
      'webapp: useGreenID must be wrapped with <GreenIDVoyagerOnboardingProvider />',
    );
  }

  return context;
};
