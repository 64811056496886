import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import { Box, Button, Columns, Stack, Text } from '@spaceship-fspl/components';
import {
  fromProtoFrequency,
  SaverTransactionLimits_SaverProductInstanceFragment,
  toProtoFrequency,
  useCancelPaymentScheduleForProductInstances,
  useCreatePaymentSchedule,
  useEstimatedApplicationExecutionDate,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerInvestmentPlan } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerInvestmentPlan';
import {
  AUSSIE_DATE_FORMAT,
  formatDate,
  formatDateForRequests,
  sydneyDate,
  sydneyToday,
  toSydneyStartOfDay,
  useDebounceValue,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { InvestInfoAccordion } from 'components/transactions/invest-info-accordion';
import {
  defaultFormValues,
  InvestmentPlanForm,
  PRESET_AMOUNTS,
  PRESET_FREQUENCIES,
  VoyagerInvestmentPlan as InvestmentPlan,
} from 'components/voyager-investment-plan';
import { VoyagerStopPlanModal } from 'components/voyager-stop-plan-modal';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { isValid, parse } from 'date-fns';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { cleanNumber, fromAussieDate } from 'helpers/format';
import { addRumError } from 'helpers/monitoring';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Routes } from './routes';

interface PageParams {
  productId?: string;
}

export const VoyagerInvestmentPlan: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: PageParams = useParams();

  const [isStopPlanModalShown, setIsStopPlanModalShown] = useState(false);

  const { reset, ...methods } = useForm<InvestmentPlanForm>({
    defaultValues: defaultFormValues,
  });

  const [createPaymentSchedule, { loading: isSubmitting }] =
    useCreatePaymentSchedule();

  const [cancelPaymentSchedules, { loading: isCancelling }] =
    useCancelPaymentScheduleForProductInstances();

  const { popToast } = useNotifications();

  const intercom = useIntercom();

  const resp = useQuery<WebAppVoyagerInvestmentPlan>(
    gql`
      query WebAppVoyagerInvestmentPlan(
        $productId: ID!
        $idProvided: Boolean!
      ) {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            saverProductInstance(id: $productId) @include(if: $idProvided) {
              id
              portfolio
              upcomingSchedule {
                id
                frequency
                audAmount
                nextDue
              }
              ...SaverTransactionLimits_SaverProductInstanceFragment
            }
            saverProductInstances @skip(if: $idProvided) {
              id
            }
          }
        }
      }
      ${SaverTransactionLimits_SaverProductInstanceFragment}
    `,
    {
      variables: { idProvided: !!productId, productId },
      onCompleted: (data) => {
        const productInstances = data?.contact?.account?.saverProductInstances;

        if (!productId && productInstances && productInstances[0]?.id) {
          navigate(
            `${Routes.VOYAGER_INVESTMENT_PLAN}/${productInstances[0]?.id}`,
            { replace: true },
          );
          return;
        }
      },
    },
  );

  const upcomingSchedule =
    resp?.data?.contact?.account?.saverProductInstance?.upcomingSchedule;

  const productTransactionLimits =
    resp?.data?.contact?.account?.saverProductInstance?.transactionLimits;

  useEffect(() => {
    if (upcomingSchedule) {
      const presetFrequency = PRESET_FREQUENCIES.find(
        ({ value }) => value === toProtoFrequency(upcomingSchedule.frequency),
      )?.label;
      const isPresetAmount = PRESET_AMOUNTS.includes(
        upcomingSchedule.audAmount,
      );

      reset({
        ...defaultFormValues,
        frequency: presetFrequency || defaultFormValues.frequency,
        presetAmount: isPresetAmount ? upcomingSchedule.audAmount : '',
        otherAmount: !isPresetAmount ? upcomingSchedule.audAmount : '',
        startDate: upcomingSchedule?.nextDue
          ? formatDate(new Date(upcomingSchedule?.nextDue), AUSSIE_DATE_FORMAT)
          : defaultFormValues.startDate,
      });
    }
  }, [upcomingSchedule, reset]);

  const startDateValue = useDebounceValue(methods.watch('startDate'), 300);
  const amountValue = useDebounceValue(
    cleanNumber(methods.watch('presetAmount') || methods.watch('otherAmount')),
    300,
  );

  const startDateTimeValue = useMemo(() => {
    const startDate = parse(startDateValue, AUSSIE_DATE_FORMAT, new Date());

    if (isValid(startDate)) {
      const startDateSydneyStartOfDay = toSydneyStartOfDay(startDate);
      return startDateSydneyStartOfDay.getTime() === sydneyToday().getTime()
        ? sydneyDate()
        : startDateSydneyStartOfDay;
    }

    return upcomingSchedule?.nextDue
      ? new Date(upcomingSchedule.nextDue)
      : parse(defaultFormValues.startDate, AUSSIE_DATE_FORMAT, new Date());
  }, [startDateValue, upcomingSchedule?.nextDue]);

  const estimatedExecutionDateResp = useEstimatedApplicationExecutionDate({
    variables: {
      productId: productId || '',
      audAmount: amountValue || '',
      applicationTime: startDateTimeValue.toISOString(),
    },
    skip: !(productId && amountValue && isValid(startDateTimeValue)),
  });

  const estimatedExecutionDate =
    estimatedExecutionDateResp.data?.contact.account?.saverProductInstance
      ?.estimatedApplicationExecutionDate;

  const handleSubmit = async (data: InvestmentPlanForm): Promise<void> => {
    if (productId && data.frequency) {
      try {
        if (upcomingSchedule) {
          await cancelPaymentSchedules({
            variables: {
              input: {
                productIds: [productId],
              },
            },
          });
        }

        const startDate = formatDateForRequests(fromAussieDate(data.startDate));
        const frequency = PRESET_FREQUENCIES.find(
          ({ label }) => label === data.frequency,
        )?.value;
        const audAmount = cleanNumber(data.otherAmount || data.presetAmount);
        await createPaymentSchedule({
          variables: {
            input: {
              productId,
              audAmount,
              frequency: fromProtoFrequency(frequency),
              startDate,
            },
          },
        });
        navigate(`${Routes.VOYAGER_INVESTMENT_PLAN_SUCCESS}/${productId}`, {
          state: {
            productId,
            audAmount,
            frequency,
            etaDate: estimatedExecutionDate,
          },
        });
      } catch (error) {
        addRumError({ error });
        popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
      }
    }
  };

  return (
    <PageContainer>
      <VoyagerStopPlanModal
        productId={productId}
        showModal={isStopPlanModalShown}
        closeModal={() => {
          setIsStopPlanModalShown(false);
        }}
        onSuccess={() => {
          navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
        }}
      />
      <Stack spaceY="xl">
        {!resp.loading &&
        !!productId &&
        !productTransactionLimits?.createInvestmentPlanAvailable ? (
          <Stack spaceY="md" alignX="center">
            <PageHeading title={`Investment plan`} />
            <Box
              backgroundColor="neutral.000"
              borderRadius="xs"
              padding="sm"
              display="flex"
            >
              <FeatherInfoIcon color="indigo.070" />
              <Box flex={1} marginLeft="xs">
                <Text variant={3}>
                  {productTransactionLimits?.investmentPlanNotAvailableMessage}
                </Text>
              </Box>
            </Box>

            <Button
              variant="secondary"
              size="lg"
              onClick={() => intercom.pop()}
            >
              Contact support
            </Button>
          </Stack>
        ) : (
          <FormProvider reset={reset} {...methods}>
            <InvestmentPlan
              portfolio={
                resp?.data?.contact?.account?.saverProductInstance?.portfolio
              }
              variant="loggedin"
              isEdit={!!upcomingSchedule}
              onComplete={handleSubmit}
              onSkip={() => {
                window.history.back();
              }}
              onStopPlan={() => {
                setIsStopPlanModalShown(true);
              }}
              isSubmitting={isSubmitting || isCancelling}
              activeBankAccountNumber={
                resp?.data?.contact?.account?.activeBankAccount?.accountNumber
              }
              isLoading={resp.loading}
            />
          </FormProvider>
        )}
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 5 / 6 }}>
            <InvestInfoAccordion />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};
