import {
  borderRadius,
  color,
  getColor,
  linearGradient,
  LinearGradientConfig,
} from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from '../text';
type InvestmentStrategyScaleProps = {
  value: number;
  label?: string;
};

const primaryGradient: LinearGradientConfig = {
  angle: '90deg',
  colorStops: [
    ['indigo.015', '-20.67%'],
    ['indigo.070', '122.19%'],
  ],
};
const primaryGradientStyle = css`
  ${color('neutral.000')}
  background: ${linearGradient(primaryGradient)};
`;

const Wrapper = styled.div`
  ${borderRadius('lg')};
  min-height: 8px;
  max-height: 8px;
  min-width: 100%;
  ${primaryGradientStyle}
  position: relative;
`;

const Line = styled.div<{
  value: number;
}>`
  position: absolute;
  top: -4px;
  left: ${({ value }) => `${value}%`};
  height: 16px;
  width: 2px;
  background-color: ${getColor('neutral.100')};
`;

const LabelWrapper = styled.div<{ value: number }>`
  position: absolute;
  top: 0px;
  min-width: 100%;
  margin-top: 16px;
  transform: translateX(-50%);
  white-space: nowrap;
`;

export const InvestmentStrategyScale: React.FC<
  InvestmentStrategyScaleProps
> = ({ value, label }) => {
  return (
    <Wrapper data-testid="investment-strategy-scale">
      <Line aria-label={label} value={value}>
        <LabelWrapper value={value}>
          <Text variant={3} color="indigo.070" isBold>
            {label}
          </Text>
        </LabelWrapper>
      </Line>
    </Wrapper>
  );
};
