import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  accordionTheme,
  Box,
  Columns,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { rgba, zIndex } from '@spaceship-fspl/styles';
import boostsLaunch from 'assets/animations/boosts_launch.json';
import { Button } from 'components/button';
import { CenterPageContainer } from 'components/layouts/page';
import Lottie from 'lottie-react';
import { Routes } from 'pages/routes';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { HowBoostsWork } from './components/how-boosts-work';

export const BoostsIntro: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  return (
    <Box backgroundColor="indigo.100" minHeight="100vh">
      <CenterPageContainer>
        <Stack spaceY={{ xs: 'lg', md: 'xxxl' }}>
          <Columns alignX="center" spaceY={{ md: 'sm' }}>
            <Columns.Column>
              <Box display="flex" justifyContent="center">
                <Box
                  position="relative"
                  height={{ md: 300 }}
                  width={{ md: 650 }}
                >
                  <StyledLottieOverlay />
                  <Lottie animationData={boostsLaunch} />
                </Box>
              </Box>
            </Columns.Column>

            <Columns.Column width={{ lg: 7 / 12 }}>
              <Stack alignX="center" spaceY={{ xs: 'md', md: 'xl' }}>
                <Stack spaceY={{ xs: 'sm', md: 'md' }}>
                  <Heading
                    variant={1}
                    color="neutral.000"
                    align="center"
                    component="h1"
                  >
                    Boosts
                  </Heading>
                  <Text
                    variant={{ xs: 2, md: 1 }}
                    color="neutral.000"
                    align="center"
                  >
                    Spaceship can help you automatically boost your investment
                    balance by making the most of everyday moments.
                  </Text>
                </Stack>
                <Button
                  variant="primary"
                  size="lg"
                  trackingProperties={{
                    name: 'boosts_intro_page_add_a_boost',
                  }}
                  onClick={() => {
                    navigate(Routes.BOOSTS_RECIPE_SELECT);
                  }}
                >
                  Add a boost
                </Button>
              </Stack>
            </Columns.Column>
          </Columns>

          <ThemeProvider theme={accordionTheme.dark}>
            <HowBoostsWork />
          </ThemeProvider>
        </Stack>
      </CenterPageContainer>
    </Box>
  );
};

const StyledLottieOverlay = styled.div`
  ${zIndex(1)}
  position: absolute;
  background: linear-gradient(
    90deg,
    ${rgba('indigo.100', 1)} 0%,
    ${rgba('indigo.100', 0)} 15%,
    ${rgba('indigo.100', 0)} 85%,
    ${rgba('indigo.100', 1)} 100%
  );
  width: 100%;
  height: 100%;
`;
