import * as React from 'react';
export const StreamlineFaceId4 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M23 18.6v2.933A1.467 1.467 0 0121.533 23H18.6M18.6 1h2.933A1.467 1.467 0 0123 2.467V5.4M1 5.4V2.467A1.467 1.467 0 012.467 1H5.4M5.4 23H2.467A1.466 1.466 0 011 21.533V18.6M7.967 9.067a4.033 4.033 0 108.066 0 4.033 4.033 0 00-8.066 0zM18.224 18.967a6.6 6.6 0 00-12.448 0" />
  </svg>
);
