import { datadogRum } from '@datadog/browser-rum';

import { config } from './config';

export const initMonitoring = (): void => {
  const sampleRate = 100;

  datadogRum.init({
    applicationId: config.datadog.applicationId,
    clientToken: config.datadog.clientToken,
    site: 'datadoghq.com',
    env: config.datadog.env,
    service: 'spaceship-web',
    sampleRate: sampleRate,
    trackInteractions: false, // PII
    allowedTracingOrigins: [
      /https:\/\/api.*\.spaceship\.com\.au/,
      /https:\/\/api.*\.spaceshipinvest\.com\.au/,
    ],
  });
};

export const addRumError = ({
  error,
  context,
}: {
  error: unknown;
  context?: Record<string, unknown> | undefined;
}): void => {
  datadogRum.addError(error, context);
};

export const trackRumCustomAction = (
  eventName: string,
  properties?: Record<string, unknown>,
): void => {
  datadogRum.addAction(eventName, properties);
};
