import {
  useCanReadSuper,
  useIsAuthenticated,
  useRefresh,
} from '@spaceship-fspl/auth';
import {
  fromProtoGender,
  fromProtoSuperJoinType,
  SuperPortfolio,
  useCreateSargonMember,
} from '@spaceship-fspl/graphql';
import { rethrowError } from '@spaceship-fspl/helpers';
import {
  Gender,
  SuperProductInstance,
} from '@spaceship-fspl/types/externalapi';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  QueryKeys,
  useGetInvestmentOptions,
  useGetMember,
  useUpdateFutureCashAllocations,
} from '../react';

interface CreateAccountProps {
  tfn?: string;
  gender?: Gender.Enum;
  portfolio?: SuperPortfolio;
  joinType?: SuperProductInstance.JoinType.Enum;
}

export interface UseCreateAccountResult {
  createSuperAccount: (
    accountDetails: CreateAccountProps,
    callback?: () => void,
  ) => Promise<void>;
  isLoading: boolean;
}

export const useCreateAccount = (): UseCreateAccountResult => {
  const queryClient = useQueryClient();
  const getRefreshToken = useRefresh();
  const isLoggedIn = useIsAuthenticated();
  const isSuperUser = useCanReadSuper();
  const [createSargonMember, createSargonMemberMeta] = useCreateSargonMember({
    onCompleted: () => {
      queryClient.invalidateQueries(QueryKeys.Product);
      queryClient.invalidateQueries(QueryKeys.Member);
      queryClient.invalidateQueries(QueryKeys.InvestmentOptions);
    },
  });
  const {
    mutateAsync: updateFutureCashAllocations,
    isSuccess: isUpdateFutureCashAllocationsSuccess,
    reset: resetUpdateFutureCashAllocations,
  } = useUpdateFutureCashAllocations();
  const { refetch: fetchMember, isSuccess: isFetchMemberSuccess } =
    useGetMember();
  const {
    refetch: getInvestmentOptions,
    isSuccess: isGetInvestmentOptionsSuccess,
  } = useGetInvestmentOptions();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    resetUpdateFutureCashAllocations();
  }, [resetUpdateFutureCashAllocations]);

  const createSuperAccount = useCallback(
    async (accountDetails?: CreateAccountProps, callback?: () => void) => {
      const userGender = accountDetails?.gender;
      const userPortfolio = accountDetails?.portfolio;
      const userTfn = accountDetails?.tfn;
      const userJoinType =
        accountDetails?.joinType || SuperProductInstance.JoinType.Enum.SIGN_UP;
      let selectedPortfolioOption;

      if (!userGender || !userPortfolio) {
        throw Error(
          `Missing super details: Gender - ${userGender}, Portfolio - ${userPortfolio}`,
        );
      }

      if (!isLoggedIn) {
        throw Error('Not logged in');
      }

      if (isSuperUser) {
        throw Error('Already a super user');
      }

      setIsLoading(true);

      if (!createSargonMemberMeta.data) {
        try {
          await createSargonMember({
            variables: {
              input: {
                tfn: userTfn,
                gender: fromProtoGender(userGender),
                superJoinType: fromProtoSuperJoinType(userJoinType),
              },
            },
          });
        } catch (error) {
          setIsLoading(false);
          rethrowError(error);
        }
      }

      // Do the things before token is refreshed
      callback?.();

      try {
        await getRefreshToken();
      } catch (error) {
        setIsLoading(false);
        rethrowError(error);
      }

      if (!isFetchMemberSuccess) {
        try {
          await fetchMember();
        } catch (error) {
          setIsLoading(false);
          rethrowError(error);
        }
      }

      if (
        !isGetInvestmentOptionsSuccess ||
        !isUpdateFutureCashAllocationsSuccess
      ) {
        try {
          const { data: investmentOptions } = await getInvestmentOptions();
          selectedPortfolioOption = investmentOptions?.filter(
            ({ key }) => key.toUpperCase() === userPortfolio,
          )[0];

          if (!selectedPortfolioOption?.key || !selectedPortfolioOption?.id) {
            throw Error;
          }
        } catch (error) {
          setIsLoading(false);
          rethrowError(error);
        }

        try {
          await updateFutureCashAllocations({
            id: selectedPortfolioOption?.id,
            key: selectedPortfolioOption?.key,
            percent: 100,
          });
        } catch (error) {
          setIsLoading(false);
          rethrowError(error);
        }
      }

      setIsLoading(false);
    },
    [
      createSargonMember,
      createSargonMemberMeta,
      fetchMember,
      getInvestmentOptions,
      getRefreshToken,
      isFetchMemberSuccess,
      isGetInvestmentOptionsSuccess,
      isLoggedIn,
      isUpdateFutureCashAllocationsSuccess,
      isSuperUser,
      updateFutureCashAllocations,
    ],
  );

  return { createSuperAccount, isLoading };
};
