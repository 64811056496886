import { gql, useLazyQuery } from '@apollo/client';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { Columns, Stack, Text, TextLink } from '@spaceship-fspl/components';
import { useGetUser } from '@spaceship-fspl/data';
import {
  WebBankAccountManual,
  WebBankAccountManualVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebBankAccountManual';
import {
  ExternalRoutes,
  sanitizeBankAccountName,
} from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import { api } from '@spaceship-fspl/types/externalapi';
import { ControllerInput } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useBankAccountSubmissionContext } from 'contexts/bank-account-verification';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const validation = {
  source_account_name: {
    required: true,
  },
  source_account_friendly_name: {
    required: 'Account nickname is required',
  },
  source_account_number: {
    pattern: {
      value: /\d+/,
      message: 'Account number must be digits only',
    },
  },
  source_bsb: {
    pattern: {
      value: /^\d{3}-\d{3}$/,
      message: 'BSB must be valid (6 digits)',
    },
  },
  pds: {
    required: 'This checkbox is required.',
  },
};

export const BankAccountManual: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const track = useTrack();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.replace(/\/+$/, '');
  const { variant, submitBankAccount } = useBankAccountSubmissionContext();
  const { data: user } = useGetUser();

  const [loading, setLoading] = useState(false);

  const accountHolder = user?.contact
    ? `${user?.contact.first_name || ''} ${user?.contact.last_name || ''}`
    : '';

  const [queryBankBranch] = useLazyQuery<
    WebBankAccountManual,
    WebBankAccountManualVariables
  >(gql`
    query WebBankAccountManual($id: ID!) {
      bankBranch(id: $id) {
        id
        type
      }
    }
  `);

  const { handleSubmit, reset, control } = useForm<
    api.external.ICreateSaverAccountRequestBody & { pds: boolean }
  >({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  useEffect(() => {
    reset({
      source_account_name: sanitizeBankAccountName(accountHolder),
      pds: false,
    });
  }, [accountHolder, reset]);

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <form
            onSubmit={handleSubmit(async (body) => {
              setLoading(true);
              if (variant === 'onboarding') {
                track?.(
                  MarketingTrackingEvents.VOYAGER_ONBOARDING_LINK_BANK_MANUAL_SUBMIT,
                );
              }
              await submitBankAccount({
                source_account_name: body.source_account_name || '',
                source_bsb: body.source_bsb || '',
                source_account_friendly_name: body.source_account_name || '',
                source_account_number:
                  body.source_account_number?.replace(/\s+/g, '') || '',
                bank_statements_user_token: undefined,
              });
              setLoading(false);
            })}
          >
            <Stack spaceY="md">
              <PageHeading
                title="Enter your BSB and Account number"
                subtitle="Please enter the details of an account that allows direct debits. Many banks only allow withdrawals from transaction accounts."
              />

              <ControllerInput
                name="source_account_name"
                control={control}
                type="text"
                readOnly={true}
                placeholder="Account holder"
                rules={validation.source_account_name}
              />

              <Columns spaceX="sm" spaceY="sm">
                <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                  <ControllerInput
                    name="source_bsb"
                    control={control}
                    type="text"
                    format="bsb"
                    placeholder="BSB"
                    rules={{
                      ...validation.source_bsb,
                      validate: async (
                        bsb?: string | null,
                      ): Promise<boolean | string> => {
                        if (bsb) {
                          const resp = await queryBankBranch({
                            variables: { id: bsb },
                          });

                          return !!resp.data?.bankBranch || 'BSB is invalid';
                        }
                        return true;
                      },
                    }}
                  />
                </Columns.Column>
                <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                  <ControllerInput
                    name="source_account_number"
                    control={control}
                    type="text"
                    format="accountNumber"
                    placeholder="Account number"
                    rules={validation.source_account_number}
                  />
                </Columns.Column>
              </Columns>
              <Text variant={3}>
                By clicking ‘Add account’, I agree and consent to the{' '}
                <TextLink
                  href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                  color="indigo.070"
                  rel="noopener"
                  target="_blank"
                >
                  Direct Debit Request Service Agreement
                </TextLink>{' '}
                and authorise Spaceship Capital Limited to process payments in
                accordance with the{' '}
                <TextLink
                  target="_blank"
                  href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                >
                  Direct Debit Request
                </TextLink>
                .
              </Text>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{ name: 'bank_account_manual_submit' }}
                isLoading={loading}
              >
                Add account
              </PageFormContinueButton>

              <PageFormCancelButton
                onClick={() => navigate?.(`${pathname}/${Routes.UP}`)}
                trackingProperties={{
                  name: 'bank_account_manual_try_another_bank',
                }}
                isDisabled={loading}
              >
                Try another bank
              </PageFormCancelButton>
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
