import * as React from 'react';
export const StreamlineContentPaperEdit = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M22.392 14.805l-7.456 7.46-3.666.733.733-3.666 7.46-7.46a2.065 2.065 0 012.924 0l.01.008a2.069 2.069 0 01-.005 2.925zM8.332 20.064H2.466A1.467 1.467 0 011 18.598V2.467A1.466 1.466 0 012.466 1h14.665a1.466 1.466 0 011.467 1.466v6.6M8.332 5.4h5.866M5.4 9.799h8.799M5.4 14.198h7.332" />
  </svg>
);
