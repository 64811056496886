import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Stack,
} from '@spaceship-fspl/components';
import { SaverPortfolio, useCreateRedemption } from '@spaceship-fspl/graphql';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatDate,
  sydneyDate,
  useEstimatedProcessedDate,
} from '@spaceship-fspl/helpers';
import { Button } from 'components/button';
import { LabeledField } from 'components/labeled-field';
import { PageContainer } from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { formatCurrency } from 'helpers/format';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React from 'react';

interface Props {
  productId?: string;
  location: {
    state: {
      portfolio: SaverPortfolio;
      audAmount: string;
    };
  };
}

export const VoyagerWithdrawConfirm: React.FC<
  React.PropsWithChildren<RouteComponentProps<Props>>
> = (props) => {
  const navigate = useNavigate();
  const { productId = '' } = useParams();

  const notifications = useNotifications();
  const [createRedemption, createRedemptionMeta] = useCreateRedemption();
  const estimatedDate = useEstimatedProcessedDate(sydneyDate());
  const audAmount = props?.location?.state?.audAmount ?? '';
  const portfolio: SaverPortfolio | undefined =
    props?.location?.state?.portfolio;

  if (!productId || !portfolio || !audAmount) {
    navigate(`${Routes.VOYAGER_WITHDRAW}/${productId}`, { replace: true });
    return null;
  }

  const config = voyagerPortfolios[portfolio];

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
          <Heading variant={3} align="center">
            Confirm your withdrawal
          </Heading>

          <Box
            backgroundColor="neutral.000"
            padding="lg"
            borderRadius="sm"
            boxShadow="sm"
            marginTop="md"
          >
            <Stack spaceY="sm">
              <LabeledField label="Portfolio" align="center" size="sm">
                {config.title}
              </LabeledField>

              <Divider color="neutral.030" />

              <LabeledField label="Amount" align="center" size="sm">
                {formatCurrency(audAmount)}
              </LabeledField>

              <Divider color="neutral.030" />

              <LabeledField
                label="Withdrawal complete"
                align="center"
                size="sm"
              >
                ETA {formatDate(estimatedDate, DATE_FORMAT_TRANSACTIONS_LONG)}
              </LabeledField>
            </Stack>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="xl"
          >
            <Stack spaceY="xs">
              <Button
                variant="primary"
                size="lg"
                isLoading={createRedemptionMeta.loading}
                trackingProperties={{ name: 'withdraw_confirm_submit' }}
                onClick={async () => {
                  try {
                    await createRedemption({
                      variables: {
                        input: {
                          productId,
                          audAmount,
                        },
                      },
                    });
                    await navigate(
                      `${Routes.VOYAGER_WITHDRAW_SUCCESS}/${productId}`,
                      {
                        state: {
                          audAmount,
                        },
                      },
                    );
                  } catch {
                    notifications.popToast({
                      level: 'warning',
                      message:
                        'Withdrawal request failed. Please check your details and try again.',
                    });
                  }
                }}
              >
                Continue
              </Button>
              <Button
                variant="secondary"
                size="lg"
                onClick={async (): Promise<void> =>
                  await navigate(`${Routes.VOYAGER_WITHDRAW}/${productId}`)
                }
                trackingProperties={{ name: 'withdraw_confirm_cancel' }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
