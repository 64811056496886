import { useUnitPriceGraphData } from '@spaceship-fspl/data';
import { SaverPortfolio, toProtoPortfolio } from '@spaceship-fspl/graphql';
import { WebAppVoyagerDashboard_contact_account_saverProductInstances_portfolioPerformance } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerDashboard';
import { getToday, TimeScale } from '@spaceship-fspl/helpers';
import { IUnitPriceGraphDataPoint } from '@spaceship-fspl/types/externalapi';
import { UnitPriceCard } from 'components/unit-price-card';
import { subMonths } from 'date-fns';
import Decimal from 'decimal.js';
import { voyagerPortfolios } from 'helpers/portfolios';
import { hasLessThanOneYearOfData } from 'helpers/unit-price';
import React, { memo, useMemo, useState } from 'react';

export interface Props {
  portfolio: SaverPortfolio;
  portfolioPerformance:
    | WebAppVoyagerDashboard_contact_account_saverProductInstances_portfolioPerformance
    | null
    | undefined;
  infoRoute?: string;
  chartHeight?: number;
}

export const VoyagerUnitPriceCard: React.FC<React.PropsWithChildren<Props>> =
  memo(({ portfolio, portfolioPerformance, infoRoute, chartHeight }) => {
    const { data: { unit_prices: graphData } = {} } = useUnitPriceGraphData(
      toProtoPortfolio(portfolio),
    );

    const isGraphDataLessThanAYear = useMemo(() => {
      return hasLessThanOneYearOfData(graphData);
    }, [graphData]);

    const [scale, setScale] = useState<TimeScale>(TimeScale.MAX);

    const scaledGraphData = useMemo(
      () => sliceGraphDataByTimeScale(graphData, scale),
      [graphData, scale],
    );

    const { day, week, month, year } = useMemo(
      () => ({
        day: portfolioPerformance?.oneDayPerformancePercentage,
        week: portfolioPerformance?.oneWeekPerformancePercentage,
        month: portfolioPerformance?.oneMonthPerformancePercentage,
        year: portfolioPerformance?.oneYearPerformancePercentage,
      }),
      [
        portfolioPerformance?.oneDayPerformancePercentage,
        portfolioPerformance?.oneMonthPerformancePercentage,
        portfolioPerformance?.oneWeekPerformancePercentage,
        portfolioPerformance?.oneYearPerformancePercentage,
      ],
    );

    const lastPoint = graphData?.slice(-1)[0];
    const lastPointDate = lastPoint?.date
      ? new Date(lastPoint.date)
      : undefined;

    const config = voyagerPortfolios[portfolio];

    return (
      <UnitPriceCard
        graphData={
          scaledGraphData.map(({ date, aud_price }) => ({
            x: new Date(date || 0).getTime(),
            y: Number(aud_price),
          })) || []
        }
        scale={scale}
        setScale={setScale}
        portfolio={{
          name: config.name,
          latestUnitPrice: lastPoint?.aud_price || undefined,
          latestUnitPriceDate: lastPointDate,
          hasLessThanOneYearOfData: isGraphDataLessThanAYear,
        }}
        performance={{
          oneYearPerformanceMovement:
            year == null ? 0 : new Decimal(year).toNumber() < 0 ? -1 : 1,
          day,
          week,
          month,
          year,
        }}
        infoRoute={infoRoute}
        trackChartName="voyager_unit_price_chart"
        chartHeight={chartHeight}
      />
    );
  });

VoyagerUnitPriceCard.displayName = 'VoyagerUnitPriceCard';

const sliceGraphDataByTimeScale = (
  data: IUnitPriceGraphDataPoint[] | null | undefined,
  timeScale: TimeScale,
): IUnitPriceGraphDataPoint[] => {
  const firstDataDate = new Date(data?.[0]?.date ?? 0);
  let from = new Date(0);
  switch (timeScale) {
    case TimeScale.TWELVE_MONTHS:
      from = subMonths(getToday(), 12);
      break;
    case TimeScale.SIX_MONTHS:
      from = subMonths(getToday(), 6);
      break;
    case TimeScale.THREE_MONTHS:
      from = subMonths(getToday(), 3);
      break;
    case TimeScale.ONE_MONTH:
      from = subMonths(getToday(), 1);
      break;
  }
  if (firstDataDate > from && timeScale !== TimeScale.MAX) {
    return [];
  }
  return data?.filter(({ date }) => date && new Date(date) >= from) || [];
};
