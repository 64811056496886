import * as React from 'react';
export const StreamlineCoffeeToGo = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M3 3.75h18v4.5H3v-4.5zM18.148 21.916a1.5 1.5 0 01-1.491 1.334H7.343a1.5 1.5 0 01-1.491-1.334L4.5 8.25h15l-1.352 13.666zM18.415 1.579A1.5 1.5 0 0017.073.75H6.927a1.5 1.5 0 00-1.342.829L4.5 3.75h15l-1.085-2.171z" />
    <path vectorEffect="non-scaling-stroke" d="M9 15.75a3 3 0 106 0 3 3 0 00-6 0z" />
  </svg>
);
