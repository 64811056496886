import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Divider,
  Heading,
  Inline,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import {
  bankAccountScalars,
  BoostItemGroupStatus,
  investmentSummaryScalars,
  scheduleScalars,
  unitExchangeScalars,
  unitPriceScalars,
} from '@spaceship-fspl/graphql';
import {
  WebAppVoyagerTransactions,
  WebAppVoyagerTransactionsVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerTransactions';
import { capitalizeWords, formatCurrency } from '@spaceship-fspl/helpers';
import {
  FeatherChevronDownIcon,
  FeatherInfoIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  color,
  fontWeight,
  getSpace,
  paddingLeft,
  paddingRight,
  text,
} from '@spaceship-fspl/styles';
import { getTransactionFilters } from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { inputShadowStyle } from 'components/input';
import { LabeledField } from 'components/labeled-field';
import { PageContainer } from 'components/layouts/page';
import { Tooltip } from 'components/tooltip';
import { TransactionItem, TransactionList } from 'components/transactions';
import { useAuthenticatedNav } from 'contexts/authenticated-nav';
import { AccessibilityLabel } from 'helpers/accessibility';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { voyagerPortfolios } from 'helpers/portfolios';
import { withVoyagerTopNavigation } from 'navigation/helpers';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Routes } from './routes';

interface PageParams {
  productId?: string;
}

const Transactions: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: PageParams = useParams();

  const [filter, setFilter] = useState('all');
  const { voyagerProductId, selectVoyagerProduct } = useAuthenticatedNav();

  const resp = useQuery<
    WebAppVoyagerTransactions,
    WebAppVoyagerTransactionsVariables
  >(
    gql`
      query WebAppVoyagerTransactions($id: ID!, $idProvided: Boolean!) {
        contact {
          id
          saverProductInstance @skip(if: $idProvided) {
            id
          }
          account {
            id
            saverProductInstance(id: $id) @include(if: $idProvided) {
              id
              portfolio
              boostRecipes {
                id
                name
                iconName
                groups {
                  id
                  status
                  audAmount
                  cancellableUntil
                }
              }
              upcomingSchedule {
                id
                ...scheduleScalars
              }
              investments {
                id
                summary {
                  id
                  ...investmentSummaryScalars
                }
                transactions {
                  id
                  audAmount
                  requestedAt
                  status
                  unitExchange {
                    id
                    ...unitExchangeScalars
                    unitPrice {
                      id
                      ...unitPriceScalars
                    }
                  }
                  ... on Boost {
                    estimatedExecutionDate
                    boostBankAccount: bankAccount {
                      id
                      ...bankAccountScalars
                    }
                    itemGroup {
                      id
                      recipe {
                        id
                        name
                        description
                        source {
                          ... on WeatherStation {
                            id
                            postcode
                            name
                          }
                        }
                        parameters {
                          id
                          name
                          value
                          type
                        }
                      }
                      items {
                        id
                        createdAt
                        description
                        audAmount
                      }
                    }
                  }
                  ... on Referral {
                    isReferrer
                    referralShortName
                    referrerShortName
                  }
                  ... on Redemption {
                    fullRedemption
                    redemptionBankAccount: bankAccount {
                      id
                      ...bankAccountScalars
                    }
                  }
                  ... on Application {
                    estimatedExecutionDate
                    cancelDeadline
                    schedule {
                      id
                      ...scheduleScalars
                    }
                    applicationBankAccount: bankAccount {
                      id
                      ...bankAccountScalars
                    }
                  }
                  ... on AccountFee {
                    type
                  }
                }
              }
            }
          }
        }
      }
      ${investmentSummaryScalars}
      ${bankAccountScalars}
      ${unitExchangeScalars}
      ${unitPriceScalars}
      ${scheduleScalars}
    `,
    {
      variables: { id: productId, idProvided: !!productId },
      onCompleted: (data) => {
        if (
          !data?.contact?.account?.saverProductInstance &&
          data?.contact?.saverProductInstance?.id
        ) {
          selectVoyagerProduct(data.contact.saverProductInstance.id, {
            route: Routes.VOYAGER_TRANSACTIONS,
          });
        }
      },
    },
  );

  const cancelFeatureEnabled = useFeatureFlag(
    FeatureFlagKeys.CANCEL_TRANSACTION_APPLICATION_ENABLED,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (productId && voyagerProductId !== productId) {
      selectVoyagerProduct(productId);
    }
  }, [productId, selectVoyagerProduct, voyagerProductId]);

  const product = resp.data?.contact?.account?.saverProductInstance;
  if (!product?.portfolio) {
    return null;
  }
  const config = voyagerPortfolios[product.portfolio];
  const summary = product?.investments?.summary;
  const upcomingSchedule = product?.upcomingSchedule;
  const transactions = product?.investments?.transactions ?? [];
  const footerCopy =
    '* The fees shown here are deducted directly from your portfolio by redeeming units. The transaction history does not show the percentage-based management fee, which is calculated on the net asset value of each portfolio and reflected in the daily unit price.';

  const upcomingInvestments: Array<{
    title: string;
    amount: string;
    iconName: string;
    route: string;
    requestedAt?: Date;
  }> = [];

  if (upcomingSchedule && upcomingSchedule.nextDue) {
    upcomingInvestments.push({
      title: `${capitalizeWords(upcomingSchedule.frequency)} Plan`,
      requestedAt: new Date(upcomingSchedule.nextDue),
      amount: upcomingSchedule.audAmount,
      iconName: 'streamline-button-loop-arrow',
      route: `${Routes.VOYAGER_INVESTMENT_PLAN}/${productId}`,
    });
  }

  resp.data?.contact.account?.saverProductInstance?.boostRecipes?.forEach(
    (recipe) =>
      recipe.groups?.forEach((recipeGroup) => {
        if (
          [BoostItemGroupStatus.LOCKED, BoostItemGroupStatus.UNLOCKED].includes(
            recipeGroup.status,
          )
        ) {
          upcomingInvestments.push({
            title: recipe.name,
            requestedAt: recipeGroup.cancellableUntil
              ? new Date(recipeGroup.cancellableUntil)
              : undefined,
            amount: recipeGroup.audAmount,
            iconName: recipe.iconName,
            route: `${Routes.BOOSTS_SCHEDULED_DETAILS}/${recipeGroup.id}`,
          });
        }
      }),
  );

  const filteredTransactions = transactions.filter((tx) => {
    switch (filter) {
      case 'one-off':
        return (
          tx.__typename === 'Application' &&
          tx.schedule.frequency === 'ONE_TIME'
        );
      case 'plan':
        return (
          tx.__typename === 'Application' &&
          tx.schedule.frequency !== 'ONE_TIME'
        );
      case 'withdrawal':
        return tx.__typename === 'Redemption';
      case 'distribution':
        return tx.__typename === 'Reinvestment';
      case 'referral':
        return tx.__typename === 'Referral';
      case 'promo':
        return tx.__typename === 'Promotion';
      case 'fee':
        return tx.__typename === 'AccountFee';
      case 'boost':
        return tx.__typename === 'Boost';
      default:
        return true;
    }
  });

  const hasTransactions = transactions.length > 0;
  const showTransactions = filteredTransactions.length > 0;

  const summaryItems = [
    {
      label: 'Units',
      value: summary?.unitBalance ?? '0',
    },
    {
      label: 'Investment',
      value: formatCurrency(summary?.audInvested ?? 0),
    },
    {
      label: 'Withdrawn',
      value: formatCurrency(summary?.audWithdrawn ?? 0),
    },
    {
      label: 'Referrals',
      value: formatCurrency(summary?.audReferralTotal ?? 0),
    },
    {
      label: 'Promotions',
      value: formatCurrency(summary?.audPromotionTotal ?? 0),
    },
    {
      label: 'Fees*',
      value: formatCurrency(summary?.audAccountFeesPaid ?? 0),
    },
  ];

  return (
    <PageContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom="md"
      >
        <Heading variant={3}>Transactions</Heading>
        <Inline spaceX="sm">
          <Button
            trackingProperties={{ name: 'ignore' }}
            size="sm"
            variant="tertiary"
            onClick={async (): Promise<void> =>
              await navigate(`${Routes.VOYAGER_WITHDRAW}/${productId}`)
            }
            aria-label={AccessibilityLabel.WITHDRAW_BUTTON}
            isDisabled={!product}
          >
            Withdraw
          </Button>
          <Visible isHidden={{ xs: true, md: false }}>
            <Button
              trackingProperties={{ name: 'ignore' }}
              variant="primary"
              size="sm"
              onClick={async (): Promise<void> =>
                await navigate(`${Routes.VOYAGER_DEPOSIT}/${productId}`)
              }
              aria-label={AccessibilityLabel.INVEST_BUTTON}
              isDisabled={!product}
            >
              Invest
            </Button>
          </Visible>
        </Inline>
      </Box>
      <Columns spaceX="md" spaceY="md">
        <Columns.Column width={{ xs: 1, lg: 4 / 12 }}>
          <Card paddingTop="sm" paddingBottom={{ xs: 'sm', lg: 'md' }}>
            <Stack spaceY="sm">
              <Box paddingLeft="lg">
                <Heading variant={5} isBold={true}>
                  Summary
                </Heading>
              </Box>
              <Divider />
              <Box paddingLeft="lg" paddingRight="lg">
                <Stack spaceY="sm">
                  <LabeledField label={config.title} size="lg">
                    {formatCurrency(summary?.audBalance ?? 0)}
                  </LabeledField>

                  <Visible
                    isHidden={{ md: false, lg: true }}
                    displayValue="block"
                  >
                    <Stack spaceY="sm">
                      {summaryItems.map((i) => (
                        <Stack spaceY="sm" key={i.label}>
                          <Divider />
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Text variant={4} color="neutral.080" isBold={true}>
                              {i.label}
                            </Text>
                            <Text variant={3} isBold={true}>
                              {i.value}
                            </Text>
                          </Box>
                        </Stack>
                      ))}
                    </Stack>
                  </Visible>
                  <Visible
                    isHidden={{ xs: true, lg: false }}
                    displayValue="block"
                  >
                    <Stack spaceY="sm">
                      {summaryItems.map((i) => (
                        <Stack spaceY="sm" key={i.label}>
                          <Divider />
                          <LabeledField label={i.label} size="lg">
                            {i.value}
                          </LabeledField>
                        </Stack>
                      ))}
                    </Stack>
                  </Visible>
                </Stack>
              </Box>
            </Stack>
          </Card>
          <Visible isHidden={{ xs: true, lg: false }}>
            <Box marginTop="md">
              <Text variant={4} color="neutral.080">
                {footerCopy}
              </Text>
            </Box>
          </Visible>
        </Columns.Column>

        <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
          <Stack spaceY="md">
            {upcomingInvestments.length > 0 && (
              <Card>
                <Box
                  paddingTop="md"
                  paddingBottom="sm"
                  paddingX="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Heading variant={5} isBold={true}>
                    Upcoming investments
                  </Heading>

                  <Box paddingLeft="xxs">
                    <Tooltip
                      placement="left"
                      maxWidth={250}
                      content={
                        <Text
                          variant={4}
                          color="neutral.000"
                          isBold={true}
                          align="center"
                        >
                          Your upcoming boosts have been pre-authorised by you.
                          We’ll process these investments on the date noted.
                          <br />
                          <br />
                          Make sure you have sufficent funds in your bank
                          account or your investment may fail.
                        </Text>
                      }
                    >
                      <span>
                        <FeatherInfoIcon color="neutral.070" size="md" />
                      </span>
                    </Tooltip>
                  </Box>
                </Box>

                <Divider />

                <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="xxs">
                  {upcomingInvestments.map((transaction) => (
                    <TransactionItem
                      key={transaction.title}
                      isExpandable={false}
                      iconName={transaction.iconName}
                      route={transaction.route}
                      title={transaction.title}
                      amount={transaction.amount}
                      requestedAt={transaction.requestedAt}
                    />
                  ))}
                </Box>
              </Card>
            )}

            <Card>
              <Stack spaceY="sm">
                <Box
                  paddingTop="sm"
                  paddingLeft="lg"
                  paddingRight="lg"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading variant={5} isBold={true}>
                    History
                  </Heading>

                  <Visible isHidden={!hasTransactions}>
                    <Box position="relative" display="flex" alignItems="center">
                      <FilterSelect
                        value={filter}
                        onChange={({ target: { value } }): void => {
                          setFilter(value);
                        }}
                      >
                        {getTransactionFilters(product.portfolio).map(
                          ({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ),
                        )}
                      </FilterSelect>
                      <Box position="absolute" right={getSpace('sm')}>
                        <FeatherChevronDownIcon size="sm" color="neutral.080" />
                      </Box>
                    </Box>
                  </Visible>
                </Box>
                <Divider />
              </Stack>
              {showTransactions ? (
                <TransactionList
                  transactions={filteredTransactions}
                  portfolio={product.portfolio}
                  cancelFeatureEnabled={cancelFeatureEnabled}
                />
              ) : (
                <Box padding="lg">
                  <Text variant={2} isBold={true} align="center">
                    No transactions to display at this time.
                  </Text>
                </Box>
              )}
            </Card>
          </Stack>
        </Columns.Column>
      </Columns>
      <Visible isHidden={{ md: false, lg: true }}>
        <Box marginTop="md">
          <Text variant={4} color="neutral.080">
            {footerCopy}
          </Text>
        </Box>
      </Visible>
    </PageContainer>
  );
};

export const VoyagerTransactions = withVoyagerTopNavigation()(Transactions);

const FilterSelect = styled.select`
  ${backgroundColor('neutral.000')}
  ${borderRadius('xxs')}
  ${text({ variant: 4 })}
  ${color('neutral.100')}
  ${fontWeight(600)}
  ${paddingLeft('sm')}
  ${paddingRight('lg')}
  ${inputShadowStyle({ color: 'indigo.070' })}
  appearance: none;
  border: none;
  outline: none;
  line-height: normal;
  width: 180px;
  height: 40px;

  :hover,
  :focus {
    ${inputShadowStyle({ color: 'indigo.070', isThick: true })}
  }
`;
