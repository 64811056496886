import {
  borderColor,
  BorderWidth,
  borderWidthTop,
  Color,
  opacity,
  ResponsiveValue,
} from '@spaceship-fspl/styles';
import { createProps } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled from 'styled-components';

const HR = styled.hr`
  border: none;
  border-top-style: solid;
  margin: 0;
  ${createProps({ borderColor, borderWidthTop, opacity })}
`;

export interface DividerProps {
  color?: ResponsiveValue<Color>;
  size?: ResponsiveValue<BorderWidth>;
  opacity?: ResponsiveValue<number>;
}

export const Divider: React.FC<React.PropsWithChildren<DividerProps>> = ({
  color = 'neutral.030',
  size = 'sm',
  ...otherProps
}) => <HR {...otherProps} borderColor={color} borderWidthTop={size} />;
