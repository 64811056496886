import { useCanReadSaver } from '@spaceship-fspl/auth';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { IdentityDetailsCard } from 'components/account-cards/identity-details';
import React from 'react';

export const UserIdentityDetailsCard: React.FC<
  React.PropsWithChildren
> = () => {
  const isVerified = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const canReadSaver = useCanReadSaver();

  return (
    <IdentityDetailsCard
      isVerified={canReadSaver ? isVerified : undefined}
      isPreferredNameVisible={true}
    />
  );
};
