import {
  isAsciiString,
  isInvalidAuspostAddress,
  normalizeAccents,
} from '@spaceship-fspl/helpers';

import { STATE_POSTCODES } from './constants';

export const unitNumberValidations = {
  validate: (value?: string): boolean | string => {
    if (value) {
      if (
        !isAsciiString(normalizeAccents(value)) ||
        isInvalidAuspostAddress(value)
      ) {
        return 'Unit number has invalid characters';
      }
      return (
        value.length <= 256 ||
        'Unit number must be less than or equal 256 characters'
      );
    }

    // Unit number is an optional field
    return true;
  },
};

export const streetNumberValidations = {
  required: 'Street number is required',
  validate: (value: string): boolean | string => {
    if (
      !isAsciiString(normalizeAccents(value)) ||
      isInvalidAuspostAddress(value)
    ) {
      return 'Street number has invalid characters';
    }
    return (
      value.length <= 256 ||
      'Street number must be less than or equal 256 characters'
    );
  },
};

export const streetNameValidations = {
  required: 'Street name is required',
  validate: (value: string): boolean | string => {
    if (
      !isAsciiString(normalizeAccents(value)) ||
      isInvalidAuspostAddress(value)
    ) {
      return 'Street name has invalid characters';
    }
    return (
      value.length <= 256 ||
      'Street name must be less than or equal 256 characters'
    );
  },
};

export const addressLineValidations = {
  required: 'Address line is required',
  validate: (value: string): boolean | string => {
    if (
      !isAsciiString(normalizeAccents(value)) ||
      isInvalidAuspostAddress(value)
    ) {
      return 'Address line has invalid characters';
    }

    return (
      value.length <= 256 ||
      'Address line must be less than or equal 256 characters'
    );
  },
};

export const addressLine2Validations = {
  validate: (value?: string): boolean | string => {
    if (value != null) {
      if (
        !isAsciiString(normalizeAccents(value)) ||
        isInvalidAuspostAddress(value)
      ) {
        return 'Address line has invalid characters';
      }

      if (value.length > 256) {
        return 'Address line must be less than or equal 256 characters';
      }
    }

    // Address line 2 is an optional field
    return true;
  },
};

export const cityValidations = {
  required: 'City is required',
  validate: (value: string): boolean | string => {
    if (
      !isAsciiString(normalizeAccents(value)) ||
      isInvalidAuspostAddress(value)
    ) {
      return 'City has invalid characters';
    }
    return (
      value.length <= 256 || 'City must be less than or equal 256 characters'
    );
  },
};

export const suburbValidations = {
  required: 'Suburb name is required',
  validate: (value: string): boolean | string => {
    if (
      !isAsciiString(normalizeAccents(value)) ||
      isInvalidAuspostAddress(value)
    ) {
      return 'Suburb has invalid characters';
    }
    return (
      value.length <= 256 || 'Suburb must be less than or equal 256 characters'
    );
  },
};

export const streetTypeValidations = {
  required: 'Street type is required',
  validate: (value: string): boolean | string => {
    if (!isAsciiString(normalizeAccents(value))) {
      return 'Street type has invalid characters';
    }
    return (
      value.length <= 256 ||
      'Street type must be less than or equal 256 characters'
    );
  },
};

export const australianStateValidations = {
  required: 'State is required',
  validate: (value: string): boolean | string => {
    if (!(value in STATE_POSTCODES)) {
      return 'Invalid state';
    }
    return true;
  },
};

export const globalStateValidations = {
  required: 'State is required',
  validate: (value: string): boolean | string => {
    if (!isAsciiString(normalizeAccents(value))) {
      return 'State has invalid characters';
    }
    return (
      value.length <= 256 || 'State must be less than or equal 256 characters'
    );
  },
};

export const postcodeValidations = {
  required: 'Postcode is required',
  validate: (
    state: string,
    value: string,
    isAustralianAddress: boolean,
  ): boolean | string => {
    if (!isAustralianAddress) {
      if (!isAsciiString(value)) {
        return 'Postcode has invalid characters';
      }
      return (
        value.length <= 10 ||
        'Postcode must be less than or equal 10 characters'
      );
    } else {
      const postcode = parseInt(value);

      if (STATE_POSTCODES[state]) {
        const postcodeRanges = STATE_POSTCODES[state];
        const valid = postcodeRanges?.some(
          ([low, high]) => postcode >= low && postcode <= high,
        );

        return valid || 'Postcode is invalid';
      }

      return 'Postcode is invalid';
    }
  },
};
