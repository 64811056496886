import {
  Box,
  Card,
  Divider,
  Inline,
  Stack,
  Text,
  UnstyledButton,
  Visible,
} from '@spaceship-fspl/components';
import {
  FeatherCheckIcon,
  FeatherChevronDownIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  color,
  getColor,
  transition,
} from '@spaceship-fspl/styles';
import React, { useState } from 'react';
import styled from 'styled-components';

export const DEFINED_BENEFIT_COPY =
  'This account is a defined benefit account and cannot be consolidated online. Please contact us for more information to consolidate this account.';
export const CONSENTED_COPY = 'You already initiated a rollover of your funds.';
export const ZERO_BALANCE_COPY =
  'No balance has been reported to the Australian Tax Office for this fund, however you may still rollover your full balance.';
export const SPACESHIP_ACCOUNT_COPY = 'This is your Spaceship Super account.';

export const FundCard: React.FC<
  React.PropsWithChildren<{
    fundName: string;
    balance: string;
    transferAmount?: string;
  }>
> = ({ fundName, balance, transferAmount, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      borderRadius="sm"
      paddingBottom={transferAmount && !isExpanded ? 'sm' : 'md'}
    >
      <StyledToggleButton
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      >
        <Box
          width="100%"
          paddingTop="md"
          paddingBottom={isExpanded ? 'sm' : 'none'}
          paddingX="sm"
        >
          <Stack spaceY="xxxs">
            <FundName variant={2} isBold={true} align="left">
              {fundName}
            </FundName>

            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant={2}>{balance}</Text>
              <StyledToggleIcon isExpanded={isExpanded} size="sm" />
            </Box>
          </Stack>
        </Box>
      </StyledToggleButton>

      {transferAmount && !isExpanded && (
        <Box paddingX="sm">
          <Stack spaceY="sm">
            <Divider />

            <Box display="flex" justifyContent="flex-end">
              <Inline spaceX="xxxs" alignY="center" alignX="center">
                <FeatherCheckIcon size="sm" color="indigo.070" />
                <Inline spaceX="xs" alignY="center" alignX="center">
                  <Text variant={4} color="indigo.070" isBold={true}>
                    TO TRANSFER
                  </Text>
                  <Text variant={1} isBold={true}>
                    {transferAmount}
                  </Text>
                </Inline>
              </Inline>
            </Box>
          </Stack>
        </Box>
      )}

      <Visible isHidden={!isExpanded}>{children}</Visible>
    </Card>
  );
};

const FundName = styled(Text)``;

const StyledToggleIcon = styled(FeatherChevronDownIcon)<{
  isExpanded?: boolean;
}>`
  ${transition}
  transform: rotate(${({ isExpanded }) => (isExpanded ? 180 : 0)}deg);
  stroke: ${getColor('neutral.080')};
`;

const StyledToggleButton = styled(UnstyledButton)<{ isExpanded?: boolean }>`
  ${transition}
  width: 100%;
  ${borderRadius('sm')};

  :hover {
    ${(props) =>
      props.isExpanded
        ? `${backgroundColor('neutral.000')}`
        : `${backgroundColor('neutral.015')}`};
  }

  :hover ${StyledToggleIcon} {
    stroke: ${getColor('indigo.070')};
  }

  :hover ${FundName} {
    ${color('indigo.070')};
  }
`;
