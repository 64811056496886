import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Container,
  Divider,
  Heading,
  Inline,
  PresentationLink,
  Spinner,
  Stack,
  Text,
  TextLink,
  UnstyledButton,
} from '@spaceship-fspl/components';
import {
  SaverReferralReceiveStatus,
  SaverReferralShareStatus,
  TransactionStatus,
  unitExchangeScalars,
  unitPriceScalars,
} from '@spaceship-fspl/graphql';
import {
  WebAppVoyagerReferrals,
  WebAppVoyagerReferrals_contact,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerReferrals';
import {
  capitalizeWords,
  formatCurrency,
  WEBAPP_PATH,
} from '@spaceship-fspl/helpers';
import { FeatherCopyIcon } from '@spaceship-fspl/icons-web';
import {
  borderRadius,
  borderWidth,
  color,
  fontFamily,
  getColor,
  linearGradient,
  match,
  padding,
  paddingBottom,
} from '@spaceship-fspl/styles';
import backgroundImage from 'assets/s8-referrals-bg.svg';
import { IntercomFooter } from 'components/intercom-footer';
import { RouterLink } from 'components/router-link';
import { Tooltip } from 'components/tooltip';
import { TransactionList } from 'components/transactions';
import { useNotifications } from 'contexts/notifications';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { copyTextToClipboard } from 'helpers/utils';
import React, { memo } from 'react';
import styled from 'styled-components';

import { Routes } from './routes';

interface ReferralTransaction {
  __typename: 'Referral';
  id: string;
  status: TransactionStatus;
  unitExchange: null | {
    units: string;
    createdAt: string;
    unitPrice: {
      price: string;
    };
  };
  audAmount: string | null;
  requestedAt: string;
  isReferrer: boolean;
  referralShortName: string | null;
  referrerShortName: string | null;
}

export const VoyagerReferrals: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const isMultiPortfolioEnabled = useFeatureFlag(
    FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED,
  );

  const resp = useQuery<WebAppVoyagerReferrals>(gql`
    query WebAppVoyagerReferrals {
      contact {
        id
        saverProductInstance {
          id
          portfolio
          investments {
            id
            summary {
              id
              audBalance
            }
          }
        }
        account {
          id
          saverProductInstances {
            id
            primary
            portfolio
            investments {
              id
              transactions(types: [REFERRAL]) {
                id
                audAmount
                requestedAt
                status
                unitExchange {
                  id
                  ...unitExchangeScalars
                  unitPrice {
                    id
                    ...unitPriceScalars
                  }
                }
                ... on Referral {
                  isReferrer
                  referralShortName
                  referrerShortName
                }
              }
            }
          }
          saverReferralShareDetails {
            id
            status
            campaign {
              id
              title
            }
          }
          ...webAppReferralCodeFragment
        }
      }
    }
    ${unitExchangeScalars}
    ${unitPriceScalars}
    ${referralCodeFragment}
  `);

  const account = resp.data?.contact?.account;
  const contact = resp.data?.contact;
  const primaryProductInstance = resp.data?.contact?.saverProductInstance;
  const primaryPortfolio = primaryProductInstance?.portfolio;
  const primaryPortfolioBalance =
    primaryProductInstance?.investments?.summary?.audBalance;

  const shareDetails = account?.saverReferralShareDetails;
  const shareCampaign = shareDetails?.campaign;

  const referredTransactions =
    resp.data?.contact?.account?.saverProductInstances?.reduce(
      (allTransactions, pi) => {
        const productTransactions = pi?.investments?.transactions ?? [];
        const referred = productTransactions.filter(
          (tx) => tx.__typename === 'Referral' && tx.isReferrer,
        ) as ReferralTransaction[];

        if (!referred || referred.length === 0) {
          return allTransactions;
        }

        return [...allTransactions, ...referred];
      },
      [] as ReferralTransaction[],
    ) ?? [];

  return (
    <>
      <HeroBackground>
        <Container>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
              <Stack spaceY={{ xs: 'md', md: 'lg' }}>
                <Heading
                  variant={{ xs: 3, md: 1 }}
                  isBold={true}
                  color="neutral.000"
                  align="center"
                >
                  {shareCampaign?.title ?? 'Refer a friend'}
                </Heading>

                {shareCampaign ? (
                  <ReferralCampaign contact={contact} />
                ) : (
                  <Text
                    variant={2}
                    isBold={true}
                    color="neutral.000"
                    align="center"
                  >
                    This season of the Take Flight program has come to an end.
                  </Text>
                )}
              </Stack>
            </Columns.Column>
          </Columns>
        </Container>
      </HeroBackground>

      <Box marginTop="xl" marginBottom="md">
        <Container>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
              <Stack spaceY="md">
                <Card>
                  <Box paddingX="lg" paddingY="sm">
                    <Heading variant={5} isBold={true}>
                      Your referrals
                    </Heading>
                  </Box>
                  <Divider />

                  {resp.loading ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      padding="lg"
                    >
                      <Spinner />
                    </Box>
                  ) : referredTransactions.length > 0 ? (
                    <Box paddingX="lg" paddingY="xxs">
                      <TransactionList
                        expandable={false}
                        transactions={referredTransactions}
                      />
                    </Box>
                  ) : (
                    <Box padding="lg">
                      <Text
                        variant={2}
                        isBold={true}
                        color="neutral.080"
                        align="center"
                      >
                        You have not made any referrals.
                      </Text>
                    </Box>
                  )}
                </Card>

                {isMultiPortfolioEnabled ? (
                  <Stack spaceY="xs">
                    <Text variant={4} color="neutral.085" isBold={true}>
                      ABOUT REFERRAL PAYMENTS
                    </Text>

                    <Text variant={4} color="neutral.085">
                      Bonuses are added within&nbsp;<strong>five</strong>
                      &nbsp;days of your friend meeting the eligibility criteria
                      and that investment being processed and paid.
                    </Text>

                    <Text variant={4} color="neutral.085">
                      Your bonus units will be added to your Spaceship Voyager
                      portfolio below:
                    </Text>

                    {primaryPortfolio && primaryPortfolioBalance && (
                      <Box
                        backgroundColor="neutral.050"
                        borderRadius="xxs"
                        padding="sm"
                      >
                        <Stack spaceY="xxs">
                          <Text variant={4} color="neutral.085" isBold={true}>
                            Spaceship {capitalizeWords(primaryPortfolio)}&nbsp;
                            Portfolio
                          </Text>

                          <Text variant={4} color="neutral.085">
                            {formatCurrency(primaryPortfolioBalance)}
                          </Text>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                ) : (
                  <Text variant={4} color="neutral.085">
                    Bonuses are added within 5 days of your friend meeting the
                    eligibility criteria and that investment being processed and
                    paid.
                  </Text>
                )}
              </Stack>
            </Columns.Column>
          </Columns>
        </Container>
      </Box>
      <IntercomFooter />
    </>
  );
};

const referralCodeFragment = gql`
  fragment webAppReferralCodeFragment on Account {
    id
    saverReferralShareDetails {
      id
      status
      code
      url
      campaign {
        id
        title
        audReceiverRewardAmount
        audSharerRewardAmount
        audReceiverMinimumInvestmentAmount
        audSharerMinimumInvestmentAmount
        maxCodeUsageDays
        termsAndConditionsUrl
      }
    }
    saverReferralReceiveDetails {
      id
      status
    }
  }
`;

const ReferralCampaign: React.FC<
  React.PropsWithChildren<{
    contact?: WebAppVoyagerReferrals_contact | null;
  }>
> = memo(({ contact }) => {
  const notification = useNotifications();

  const shareDetails = contact?.account?.saverReferralShareDetails;
  const referralLink =
    shareDetails?.url ?? `${WEBAPP_PATH}${Routes.VOYAGER_SIGNUP}`;
  const shareCampaign = shareDetails?.campaign;
  const shareStatus = shareDetails?.status;

  const receiveDetails = contact?.account?.saverReferralReceiveDetails;
  const receiveStatus = receiveDetails?.status;

  const referralCopy = `Hey. Start investing with Spaceship Voyager today! Get $${shareCampaign?.audReceiverRewardAmount} in your account when you enter this code ${shareDetails?.code} within ${shareCampaign?.maxCodeUsageDays} days of signing up and invest at least $${shareCampaign?.audReceiverMinimumInvestmentAmount} in your chosen portfolio within 90 days of signing up. T&Cs apply. Spaceship Voyager issued by Spaceship Capital Ltd AFSL 501605. General advice only. Consider the PDS on our website before making a decision to invest with Spaceship Voyager. ${referralLink}`;

  return (
    <Columns
      spaceY={{ xs: 'md', lg: 'none' }}
      spaceX={{ xs: 'none', lg: 'md' }}
    >
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <Stack spaceY="md">
          <Text variant={2} isBold={true} color="neutral.000">
            Share your unique code with a friend. After they’re successfully
            jumped aboard and invested at least $
            {shareCampaign?.audSharerMinimumInvestmentAmount}, you’ll each
            receive ${shareCampaign?.audSharerRewardAmount} in your Spaceship
            Voyager accounts. Win/win!
          </Text>

          <Text variant={2} isBold={true} color="neutral.000">
            By sharing this code, you agree to the&nbsp;
            <TextLink
              href={shareCampaign?.termsAndConditionsUrl || '#'}
              target="_blank"
              color="neutral.000"
            >
              Terms &amp; Conditions
            </TextLink>
            &nbsp;that apply to this offer. No one likes spam, not even friends;
            please get permission before sharing!
          </Text>
        </Stack>
      </Columns.Column>

      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <Stack spaceY="sm">
          <Card padding="lg">
            <Stack spaceY="sm">
              <div>
                <Text variant={2} isBold={true} align="center">
                  Share referral code
                </Text>
              </div>

              {shareStatus === SaverReferralShareStatus.ELIGIBLE ? (
                <>
                  <ReferralCodeBox>
                    <ReferralCodeText
                      variant={{ xs: 1, lg: 2 }}
                      isBold={true}
                      color="mint.050"
                      align="center"
                    >
                      {shareDetails?.code}
                    </ReferralCodeText>
                  </ReferralCodeBox>

                  <Box display="flex" justifyContent="center">
                    <Tooltip content="Copy referral code">
                      <StyledCopyButton
                        onClick={async (): Promise<void> => {
                          if (
                            shareDetails?.code &&
                            (await copyTextToClipboard(referralCopy))
                          ) {
                            notification.popToast({
                              level: 'success',
                              message: 'Referral code copied.',
                            });
                          } else {
                            notification.popToast({
                              level: 'error',
                              message: 'Unable to copy referral code.',
                            });
                          }
                        }}
                      >
                        <Inline spaceX="xxs">
                          <FeatherCopyIcon size="md" />
                          <CopyText>Copy</CopyText>
                        </Inline>
                      </StyledCopyButton>
                    </Tooltip>
                  </Box>
                </>
              ) : (
                <IneligibleBox>
                  <Heading
                    variant={3}
                    color="neutral.085"
                    align="center"
                    isBold={true}
                  >
                    INELIGIBILE
                  </Heading>
                </IneligibleBox>
              )}
            </Stack>
          </Card>

          {receiveStatus === SaverReferralReceiveStatus.ELIGIBLE && (
            <Box
              display="flex"
              justifyContent={{ xs: 'center', lg: 'flex-end' }}
            >
              <RouterLink
                to={Routes.VOYAGER_REFERRALS_ENTER_CODE}
                trackingProperties={{
                  name: 'voyager_referrals_page_enter_code',
                }}
              >
                <PresentationLink
                  color="neutral.000"
                  size="xs"
                  icon="chevron"
                  component="span"
                >
                  Enter referral code
                </PresentationLink>
              </RouterLink>
            </Box>
          )}
        </Stack>
      </Columns.Column>
    </Columns>
  );
});

ReferralCampaign.displayName = 'ReferralCampaign';

const BACKGROUND_IMAGE_HEIGHT = 260;
const BACKGROUND_IMAGE_WIDTH = 502;

const HeroBackground = styled(Box).attrs({
  paddingTop: { xs: 'lg', md: 'xl', lg: 'xxl' },
})`
  background:
    url(${backgroundImage}) no-repeat,
    ${linearGradient({
      angle: '166.41deg',
      colorStops: [
        ['indigo.050', '-58.77%'],
        ['indigo.100', '118.07%'],
      ],
    })};
  background-position: 72% bottom;
  background-size: 130%, 100%;
  padding-bottom: 150px;

  ${match('md')`
    background-position: center bottom;
    background-size: 80%, 100%;
    padding-bottom: 192px;
  `};

  ${match('lg')`
    background-position: calc(100% + 111px) bottom, center;
    background-size: ${BACKGROUND_IMAGE_WIDTH}px ${BACKGROUND_IMAGE_HEIGHT}px, 100%;
    ${paddingBottom('xxl')};
  `};
`;

const ReferralCodeBox = styled.div`
  ${padding('md')};
  ${borderRadius('xxs')};
  border-style: dashed;
  ${borderWidth('md')};
  border-color: ${getColor('mint.050')};
`;

const ReferralCodeText = styled(Heading)`
  ${fontFamily('monospace')}
  letter-spacing: 0.05em;
  word-break: break-word;
`;

const IneligibleBox = styled(Box).attrs({
  padding: 'xs',
  backgroundColor: 'neutral.015',
})`
  ${borderWidth('sm')};
  border-color: ${getColor('neutral.080')};
  border-style: dashed;
`;

const CopyText = styled(Text).attrs({
  variant: 2,
  isBold: true,
})``;

const StyledCopyButton = styled(UnstyledButton)`
  :hover {
    ${color('indigo.070')};
    ${CopyText} {
      ${color('indigo.070')};
    }
  }
`;
