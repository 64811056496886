import { RouteComponentProps, useNavigate } from '@reach/router';
import { Box, Stack, Text } from '@spaceship-fspl/components';
import { IDName, useGetNonExhaustedIDOptions } from '@spaceship-fspl/green-id';
import { backgroundColor, transition } from '@spaceship-fspl/styles';
import { Checkbox } from 'components/checkbox';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { useGreenID, useSelectedGreenIDSources } from 'contexts/greenid';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

interface FormState {
  sources: Record<IDName, boolean>;
}

const getFormDefaultSourcesValue = (
  selectedGreenIDToBeVerified: IDName[],
): FormState['sources'] => {
  const sources = {
    [IDName.AUSTRALA_DRIVER_LICENCE]: false,
    [IDName.AUSTRALIAN_PASSPORT]: false,
    [IDName.INTERNATIONAL_PASSPORT]: false,
    [IDName.MEDICARE_CARD]: false,
    [IDName.AUSTRALIAN_CITIZENSHIP_CERTIFICATE]: false,
  };

  if (selectedGreenIDToBeVerified.length > 0) {
    selectedGreenIDToBeVerified.forEach((selectedGreenIDName) => {
      sources[selectedGreenIDName] = true;
    });
  }

  return sources;
};

export const MultipleIDSelectionForm: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const { routes, getUploadLinkBySourceName, ruleset } = useGreenID();
  const idOptions = useGetNonExhaustedIDOptions(ruleset);

  const [selectedGreenIDs, { set: setSelectedGreenIDs }] =
    useSelectedGreenIDSources();

  const {
    handleSubmit: onSubmit,
    register,
    watch,
  } = useForm<FormState>({
    defaultValues: {
      sources: getFormDefaultSourcesValue(selectedGreenIDs),
    },
  });

  const handleSubmit = ({ sources }: FormState): void => {
    const selectedGreenIDToBeVerified: IDName[] = (
      Object.keys(sources) as IDName[]
    ).filter((name) => sources[name] === true);

    // save selected greenids to context
    setSelectedGreenIDs(selectedGreenIDToBeVerified);

    const nextIDName = selectedGreenIDToBeVerified[0];
    const failedIDOption = idOptions.find(
      (id) => id.isFieldsSubmissionRejected && id.label === nextIDName,
    );

    // redirect to upload page if id has been rejected before
    if (failedIDOption?.sourceName) {
      navigate?.(getUploadLinkBySourceName(failedIDOption.sourceName));
    } else {
      navigate?.(routes[nextIDName || 'index']);
    }
  };

  const selectedIdIndices = Object.values(watch('sources'));
  const areTwoIdsSelected =
    selectedIdIndices.filter((value) => value).length === 2;

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Stack spaceY="xxs">
        {idOptions.map(({ label }) => {
          return (
            <StyledOptionBox key={label}>
              <Checkbox
                variant="circle"
                position="right"
                padding="md"
                disabled={areTwoIdsSelected && watch('sources')[label] !== true}
                {...register(`sources.${label}` as const)}
              >
                <Text variant={2} isBold={true}>
                  {label}
                </Text>
              </Checkbox>
            </StyledOptionBox>
          );
        })}
      </Stack>

      <PageFormButtonContainer>
        <PageFormContinueButton
          trackingProperties={{
            name: 'id_verification_list_continue',
          }}
          isDisabled={!areTwoIdsSelected}
        />
      </PageFormButtonContainer>
    </form>
  );
};

const StyledOptionBox = styled(Box).attrs({
  borderRadius: 'xxs',
  boxShadow: 'sm',
})`
  ${backgroundColor('neutral.000')}
  ${transition}

  :hover {
    ${backgroundColor('neutral.015')}
  }
`;
