import * as React from 'react';
export const StreamlineTaskListMultiple = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M9.8 7.867a1 1 0 011-1H22a1 1 0 011 1V22a1 1 0 01-1 1H10.8a1 1 0 01-1-1V7.867z" />
    <path vectorEffect="non-scaling-stroke" d="M9.8 20.067H6.867c-.81 0-1.467-.657-1.467-1.467V5.4c0-.81.657-1.467 1.467-1.467h10.266c.81 0 1.467.657 1.467 1.467v1.467" />
    <path vectorEffect="non-scaling-stroke" d="M5.4 17.133H2.467c-.81 0-1.467-.656-1.467-1.466v-13.2C1 1.657 1.657 1 2.467 1h10.266c.81 0 1.467.657 1.467 1.467v1.466M17.133 11.267h2.934M12.733 10.533l.734.734L14.933 9.8M17.133 15.667h2.934M12.733 14.933l.734.734 1.466-1.467M17.133 20.067h2.934M12.733 19.333l.734.734 1.466-1.467" />
  </svg>
);
