import { RouteComponentProps } from '@reach/router';
import { useGetUser } from '@spaceship-fspl/data';
import { SuperEmployerContributionShareOption as ShareOption } from '@spaceship-fspl/super';
import { Success } from 'components/layouts/success';
import { useEmployerContributionContext } from 'contexts/super/employer-contribution';
import React from 'react';

export const SuperEmployerContributionSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { variant, shareOption, onComplete } = useEmployerContributionContext();
  const { data: user } = useGetUser();
  const isSignup = variant === 'onboarding';
  const shareWithEmployer = shareOption === ShareOption.EMPLOYER;

  return (
    <Success
      isOnboarding={isSignup}
      title={
        shareWithEmployer
          ? 'Your member details have been shared'
          : 'Your member details have been sent'
      }
      subtitle={
        shareWithEmployer ? (
          'Please contact your employer to confirm they have received your member details.'
        ) : (
          <>
            Please check your email{' '}
            <strong>{user?.contact?.email_address}</strong> for your Spaceship
            Super member details.
          </>
        )
      }
      buttonText="Done"
      onContinue={{
        trackingProperties: {
          name: `${isSignup ? 'signup_' : ''}super_employer_contribution_done`,
        },
        onClick: onComplete,
      }}
    />
  );
};
