import { RouteComponentProps, useNavigate } from '@reach/router';
import { Error, ErrorProps } from 'components/layouts/error';
import { useIntercom } from 'contexts/intercom';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React from 'react';

export const SuperSignupError: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const intercom = useIntercom();
  const isSuperCrossSellEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPER_CROSS_SELL,
  );

  const errorProps: ErrorProps = {
    title: 'Oops, it seems you already have a Spaceship Super account',
    subtitle:
      'It looks like you already have a Spaceship Super account. You can link it in the Spaceship mobile app or contact our customer support team for help.',
    buttonText: 'Contact support',
    onContinue: {
      trackingProperties: { name: 'super_signup_error_continue' },
      onClick: () => intercom.pop(),
    },
    isOnboarding: true,
  };

  if (isSuperCrossSellEnabled) {
    errorProps.subtitle =
      'It looks like you already have a Spaceship Super account. You can link it using the "Link account" button below or contact our customer support team for help.';
    errorProps.buttonText = 'Link account';
    errorProps.onContinue = {
      onClick: () => navigate(Routes.SUPER_LINK),
      trackingProperties: { name: 'super_signup_error_continue' },
    };
  }

  return <Error {...errorProps} />;
};
