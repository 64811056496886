import * as React from 'react';
export const StreamlineLightModeSunny = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M7.6 12a4.4 4.4 0 108.8 0 4.4 4.4 0 00-8.8 0zM12 1v3.667M12 19.333V23M23 12h-3.667M4.667 12H1M20.067 3.933l-2.934 2.934M6.867 17.133l-2.934 2.934M20.066 20.067l-2.933-2.934M6.867 6.867L3.934 3.933" />
  </svg>
);
