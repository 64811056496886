import { Match } from '@reach/router';
import { Box, Inline, Text, UnstyledButton } from '@spaceship-fspl/components';
import {
  Color,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  IconSize,
  iconSizes,
  paddingX,
  Theme,
} from '@spaceship-fspl/styles';
import { HighlightFeature } from 'components/highlight-feature';
import { RouterLink } from 'components/router-link';
import { Tooltip } from 'components/tooltip';
import { FeatureFlagKeys } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React, { forwardRef } from 'react';
import styled, { ThemeProps } from 'styled-components';

interface IconContainerProps {
  mobileSize?: IconSize;
  desktopSize?: IconSize;
}

const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = ({
  children,
  mobileSize,
  desktopSize,
}) => (
  <Box
    height={{
      xs: mobileSize || iconSizes.md,
      md: desktopSize || iconSizes.lg,
    }}
    width={{
      xs: mobileSize || iconSizes.md,
      md: desktopSize || iconSizes.lg,
    }}
  >
    {children}
  </Box>
);

interface CommonProps {
  icon: React.ReactElement;
  iconSize?: IconContainerProps;
  label: string;
  ariaLabel?: string;
  tooltip?: string;
  showLabelInDesktop?: boolean;
}

interface HighlightFeatureProps {
  highlightFeature?: FeatureFlagKeys;
  dismissHighlightFeatureOnClick?: boolean;
}

export interface NavItemProps extends CommonProps, HighlightFeatureProps {
  route?: Routes | string;
  path?: string;
  showInMobileOnly?: boolean;
  showInDesktopOnly?: boolean;
  showInMoreMenuOnly?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}

const NAV_ITEM_BG_COLOR_ACTIVE: Color = 'indigo.080';
const NAV_ITEM_BG_COLOR_INACTIVE: Color = 'neutral.000';
const NAV_ITEM_COLOR_ACTIVE: Color = 'neutral.000';
const NAV_ITEM_COLOR_INACTIVE: Color = 'neutral.100';

export const NavItem: React.FC<React.PropsWithChildren<NavItemProps>> = ({
  route,
  path,
  label,
  ariaLabel,
  tooltip,
  onClick,
  highlightFeature,
  dismissHighlightFeatureOnClick,
  isActive,
  ...item
}) => {
  return (
    <Match path={path ?? ''}>
      {({ match }): JSX.Element => {
        const isMatch = path !== undefined ? !!match : false;
        const content = (
          <Tooltip placement="right" content={tooltip || label} touch={false}>
            <Content
              isActive={isActive ?? isMatch}
              label={label}
              tooltip={tooltip}
              {...item}
            />
          </Tooltip>
        );

        return (
          <HighlightFeature
            as={(props) =>
              route ? (
                <RouterLink
                  to={route}
                  trackingProperties={{ name: 'authenticated_nav_item' }}
                  aria-label={`${(ariaLabel ?? label).toLowerCase()} menu item`}
                  {...props}
                />
              ) : (
                <StyledNavItemButton
                  aria-label={`${(ariaLabel ?? label).toLowerCase()} menu item`}
                  {...props}
                />
              )
            }
            onClick={onClick}
            borderColor={
              isMatch ? NAV_ITEM_BG_COLOR_ACTIVE : NAV_ITEM_BG_COLOR_INACTIVE
            }
            indicatorPosition={{
              top: { xs: '27px', md: '40px' },
              right: { xs: 'calc(50% - 16px)', md: '16px' },
            }}
            featureFlag={highlightFeature}
            dismissOnClick={dismissHighlightFeatureOnClick}
          >
            {content}
          </HighlightFeature>
        );
      }}
    </Match>
  );
};

export interface NavItemButtonProps extends CommonProps, HighlightFeatureProps {
  onClick: () => void;
}

export const NavItemButton: React.FC<
  React.PropsWithChildren<NavItemButtonProps>
> = ({
  label,
  tooltip,
  onClick,
  highlightFeature,
  dismissHighlightFeatureOnClick,
  ...item
}) => {
  return (
    <HighlightFeature
      as={(props) => (
        <StyledNavItemButton
          aria-label={`${(item.ariaLabel ?? label).toLowerCase()} menu item`}
          {...props}
        />
      )}
      onClick={onClick}
      borderColor={NAV_ITEM_BG_COLOR_INACTIVE}
      indicatorPosition={{
        top: { xs: '27px', md: '40px' },
        right: { xs: 'calc(50% - 16px)', md: '16px' },
      }}
      featureFlag={highlightFeature}
      dismissOnClick={dismissHighlightFeatureOnClick}
    >
      <Tooltip placement="right" content={tooltip || label} touch={false}>
        <Content tooltip={tooltip} label={label} {...item} />
      </Tooltip>
    </HighlightFeature>
  );
};

interface ContentProps extends CommonProps {
  isActive?: boolean;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  (
    { isActive = false, icon, iconSize = {}, label, showLabelInDesktop },
    ref,
  ) => {
    const backgroundColor: Color = isActive
      ? NAV_ITEM_BG_COLOR_ACTIVE
      : NAV_ITEM_BG_COLOR_INACTIVE;
    const color: Color = isActive
      ? NAV_ITEM_COLOR_ACTIVE
      : NAV_ITEM_COLOR_INACTIVE;

    return (
      <StyledContentBox
        color={color}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={{ xs: 'space-between', md: 'center' }}
        backgroundColor={backgroundColor}
        height={{
          xs: '100%',
          md: showLabelInDesktop ? NAV_ITEM_LG_HEIGHT : NAV_ITEM_HEIGHT,
        }}
        paddingTop={{ xs: 'xs', md: 'none' }}
        paddingBottom={{ xs: 'xxs', md: 'none' }}
        width="100%"
        ref={ref}
      >
        <IconContainer {...iconSize}>{icon}</IconContainer>

        <StyledContentLabelBox
          display={{ md: showLabelInDesktop ? 'block' : 'none' }}
          marginTop={{ md: 'xxs' }}
        >
          {label}
        </StyledContentLabelBox>
      </StyledContentBox>
    );
  },
);

Content.displayName = 'Content';

export const MORE_MENU_NAV_ITEM_HEIGHT = 56;

export const MoreMenuNavItem: React.FC<
  React.PropsWithChildren<NavItemProps>
> = ({
  icon,
  label,
  tooltip,
  route,
  path,
  onClick,
  highlightFeature,
  dismissHighlightFeatureOnClick,
  ariaLabel,
}) => {
  return (
    <Match path={path ?? ''}>
      {({ match }): JSX.Element => {
        const isMatch = path !== undefined ? !!match : false;
        const content = (
          <StyledMoreMenuNavItem>
            <Inline alignY="center" spaceX="sm">
              <Box color={isMatch ? 'indigo.070' : 'neutral.100'}>
                <IconContainer>{icon}</IconContainer>
              </Box>

              <StyledMoreMenuNavItemLabel
                color={isMatch ? 'indigo.070' : 'black.100'}
                variant={{ xs: 3, sm: 2 }}
              >
                <strong>{label || tooltip}</strong>
              </StyledMoreMenuNavItemLabel>
            </Inline>
          </StyledMoreMenuNavItem>
        );

        return (
          <HighlightFeature
            as={(props) =>
              route ? (
                <RouterLink
                  {...props}
                  aria-label={`${(ariaLabel ?? label).toLowerCase()} nav item`}
                  to={route}
                  trackingProperties={{
                    name: 'authenticated_more_menu_nav_item',
                  }}
                />
              ) : (
                <UnstyledButton
                  {...props}
                  aria-label={`${(ariaLabel ?? label).toLowerCase()} nav item`}
                />
              )
            }
            onClick={onClick}
            indicatorPosition={{
              top: '32px',
              left: '40px',
            }}
            featureFlag={highlightFeature}
            dismissOnClick={dismissHighlightFeatureOnClick}
          >
            {content}
          </HighlightFeature>
        );
      }}
    </Match>
  );
};

const NAV_ITEM_HEIGHT = 70;
const NAV_ITEM_LG_HEIGHT = 90;

const StyledContentBox = styled(Box)`
  line-height: 0;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const StyledNavItemButton = styled(UnstyledButton)`
  display: flex;
  justify-content: center;
  ${height({ xs: '100%', md: 'auto' })}
  width: 100%;
`;

const StyledContentLabelBox = styled(Box)<ThemeProps<Theme>>`
  ${fontFamily('monospace')}
  ${fontSize({ xs: '10px', md: '12px' })}
  ${fontWeight({ xs: 600, md: 700 })}
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
`;

const StyledMoreMenuNavItem = styled.div`
  ${paddingX({ xs: 'sm', sm: 'md' })}
  display: flex;
  align-items: center;
  height: ${MORE_MENU_NAV_ITEM_HEIGHT}px;
  width: 100%;
`;

const StyledMoreMenuNavItemLabel = styled(Text)`
  ${fontFamily('monospace')}
`;
