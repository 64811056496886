import React from 'react';

import { Client, ClientState, Scope, Status } from './client';

interface ContextValue extends ClientState {
  login: Client['login'];
  logout: Client['logout'];
  refresh: Client['refresh'];
  ensureToken: Client['ensureToken'];
  ensureScopes: Client['ensureScopes'];
  ensureIterableAuthToken: Client['ensureIterableAuthToken'];
  setPartialState: Client['setPartialState'];
}

const Context = React.createContext<ContextValue | undefined>(undefined);

export interface ProviderProps {
  client: Client;
}

const initialState: ClientState = {
  id: undefined,
  status: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  scopes: undefined,
  otherStuff: undefined,
  authInfo: undefined,
};

export const Provider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  client,
  children,
}) => {
  const [state, setState] = React.useState<ClientState>(initialState);

  React.useEffect(() => {
    const setStateFromClient = (): void =>
      setState({
        id: client.id,
        status: client.status,
        accessToken: client.accessToken,
        refreshToken: client.refreshToken,
        scopes: client.scopes,
        otherStuff: client.otherStuff,
        authInfo: client.authInfo,
      });

    setStateFromClient();

    const listener = (): void => setStateFromClient();
    client.on(listener);
    return () => client.off(listener);
  }, [client, setState]);

  const value = React.useMemo(
    () => ({
      ...state,
      login: client.login.bind(client),
      logout: client.logout.bind(client),
      refresh: client.forceRefresh.bind(client),
      ensureToken: client.ensureToken.bind(client),
      ensureScopes: client.ensureScopes.bind(client),
      ensureIterableAuthToken: client.ensureIterableAuthToken.bind(client),
      setPartialState: client.setPartialState.bind(client),
    }),
    [state, client],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useContext = (): ContextValue => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('@spaceship-fspl/auth: Please wrap in <Provider/>');
  }
  return context;
};

export const useStatus = (): Client['status'] => useContext().status;
export const useAccessToken = (): Client['accessToken'] =>
  useContext().accessToken;
export const useScopes = (): Client['scopes'] => useContext().scopes;
export const useLogin = (): Client['login'] => useContext().login;
export const useLogout = (): Client['logout'] => useContext().logout;
export const useRefresh = (): Client['refresh'] => useContext().refresh;
export const useEnsureToken = (): Client['ensureToken'] =>
  useContext().ensureToken;
export const useEnsureScopes = (): Client['ensureScopes'] =>
  useContext().ensureScopes;
export const useEnsureIterableAuthToken =
  (): Client['ensureIterableAuthToken'] => useContext().ensureIterableAuthToken;
export const useOtherStuff = (): Client['otherStuff'] =>
  useContext().otherStuff;
export const useSetPartialState = (): Client['setPartialState'] =>
  useContext().setPartialState;

export const useIsAuthenticated = (): boolean => {
  return useStatus() === Status.AUTHENTICATED;
};

export const useIsVerifiedAccount = (): boolean => {
  return useScopes()?.includes(Scope.ACCOUNT_VERIFIED) ?? false;
};

export const useCanReadSaver = (): boolean => {
  return useScopes()?.includes(Scope.SAVER_READ) ?? false;
};

export const useCanWriteSaver = (): boolean => {
  return useScopes()?.includes(Scope.SAVER_WRITE) ?? false;
};

export const useCanReadSuper = (): boolean => {
  return useScopes()?.includes(Scope.SUPER_READ) ?? false;
};

export const useCanWriteSuper = (): boolean => {
  // no such thing atm so assume if we can read we can also write
  return useCanReadSuper();
};

export const useHasNova = (): boolean => {
  return useScopes()?.includes(Scope.NOVA) ?? false;
};

export const useCanWriteAccount = (): boolean => {
  return useScopes()?.includes(Scope.ACCOUNT_WRITE) ?? false;
};

export const useIsUserWithNoProduct = (): boolean => {
  const canWriteAccount = useCanWriteAccount();
  const canReadSaver = useCanReadSaver();
  const canReadSuper = useCanReadSuper();
  return canWriteAccount && !canReadSaver && !canReadSuper;
};
