import * as React from 'react';
export const Boosts = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M11.696 12.05a3.364 3.364 0 01.823-1.907 3.61 3.61 0 011.813-1.112 1.187 1.187 0 01.193 1.663c.53.149 1.093.15 1.623.002a2.91 2.91 0 001.368-.834 3.072 3.072 0 00.959-2.218c0-.832-.345-1.63-.96-2.218a3.397 3.397 0 00-2.314-.901c-.865 0-1.696.323-2.316.9A5.169 5.169 0 0011.03 8.53a5.03 5.03 0 00.667 3.52z" />
    <path vectorEffect="non-scaling-stroke" d="M19.605 13.458a7.165 7.165 0 002.089-5.36 7.173 7.173 0 00-2.522-5.17 7.357 7.357 0 00-9.94.65 32.135 32.135 0 00-6.829 9.986.715.715 0 00.893.945l3.149-1.05-3.817 6.877a.717.717 0 00.981.97l4.859-2.779-.683 3.413a.716.716 0 001.105.734 78.224 78.224 0 0010.715-9.216z" />
  </svg>
);
