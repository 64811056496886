import * as React from 'react';
export const StreamlineDownloadDashArrow = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12.001 16.5h3v3.75l8.25-8.25-8.25-8.25V7.5h-3M9.001 16.5h-3M3.001 16.5H.751M9.001 7.5h-3M3.001 7.5H.751" />
  </svg>
);
