import { Heading, Inline } from '@spaceship-fspl/components';
import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  name?: string | null;
}

export const HeaderWave: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <Heading color="neutral.100" variant={3} component="div">
    <Inline spaceX="xxxs">
      <StyledGreeting isReady={!!props?.name}>
        Hi {props?.name ?? ''}{' '}
      </StyledGreeting>

      <StyledWaveEmoji aria-hidden>👋</StyledWaveEmoji>
    </Inline>
  </Heading>
);

const StyledGreeting = styled.div<{ isReady: boolean }>`
  max-width: ${({ isReady }) => (isReady ? 1000 : 0)}px;
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 5s;
`;

const waveKeyframes = keyframes`
    0% { transform: rotate(  0.0deg) }
   10% { transform: rotate(-10.0deg) }
   20% { transform: rotate( 12.0deg) }
   30% { transform: rotate(-10.0deg) }
   40% { transform: rotate(  9.0deg) }
   50% { transform: rotate(  0.0deg) }
  100% { transform: rotate(  0.0deg) }
`;

const StyledWaveEmoji = styled.div`
  animation-name: ${waveKeyframes};
  animation-duration: 2.5s;
  animation-iteration-count: 2;
  transform-origin: 70% 70%;
`;
