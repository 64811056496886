import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { ControllerInput } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useBankAccountVerificationContext } from 'contexts/bank-account-verification';
import { useNotifications } from 'contexts/notifications';
import { MFAField } from 'helpers/bank-statements-api';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { camelPad, formatNumberWithOrdinal } from 'helpers/format';
import { requiredValidation } from 'helpers/validation';
import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, get, useForm } from 'react-hook-form';

import { Routes } from './routes';

export const BankAccount2FA: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const upPath = `${location.pathname.replace(/\/+$/, '')}/${Routes.UP}`;

  const { mfaLogin, mfaRequest, selectedBank } =
    useBankAccountVerificationContext();
  const { handleSubmit, control } = useForm<{
    [fieldID: string]: string;
  }>();
  const { popToast } = useNotifications();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedBank) {
      navigate?.(upPath);
    }
  }, [navigate, selectedBank, upPath]);

  const getField = (
    {
      id,
      type,
      value,
      options,
      label,
      subElements,
      src,
      width,
      height,
    }: MFAField,
    index: number,
  ): ReactNode => {
    if (!type) {
      return;
    }
    const key = `${id}${index}`;

    switch (type.toLowerCase()) {
      case 'input':
        return (
          id && (
            <ControllerInput
              name={id}
              control={control}
              key={key}
              placeholder={`Enter your ${toFriendlyMFAInputText(id)}`}
              type="text"
              rules={requiredValidation(toFriendlyMFAInputText(id))}
            />
          )
        );

      case 'password':
        return (
          id && (
            <ControllerInput
              name={id}
              control={control}
              key={key}
              placeholder={`Enter your ${toFriendlyMFAInputText(id)}`}
              type="password"
              rules={requiredValidation(toFriendlyMFAInputText(id))}
            />
          )
        );

      case 'options':
        return (
          id && (
            <Controller
              control={control}
              name={id}
              rules={requiredValidation(toFriendlyMFAInputText(id))}
              render={({ field, formState: { errors } }) => {
                return (
                  <Dropdown
                    {...field}
                    name={id}
                    errorMessage={get(errors, id)?.message}
                    key={key}
                    placeholder={label || 'Please select'}
                    options={
                      (options &&
                        Object.entries(options).map(([value, label]) => ({
                          value,
                          label,
                        }))) ||
                      []
                    }
                  />
                );
              }}
            />
          )
        );

      case 'header':
        return (
          value && (
            <Heading key={key} component="h4" variant={5}>
              {value}
            </Heading>
          )
        );

      case 'instructions':
        return (
          value && (
            <Text key={key} variant={2} color="neutral.080">
              {value}
            </Text>
          )
        );

      case 'set':
        if (subElements) {
          const labelElement = label ? (
            <Heading key={key} component="h4" variant={5}>
              {label}
            </Heading>
          ) : null;
          const fieldElement = subElements.reduce(
            (acc: ReactNode[], element, index) => {
              const field = getField(element, index);
              if (field) {
                // Concat incase there are nested subElements
                return acc.concat(field);
              }
              return acc;
            },
            [],
          );
          return labelElement ? [labelElement, ...fieldElement] : fieldElement;
        }
        return null;

      case 'image':
        return (
          id &&
          src && (
            <img
              src={src}
              width={width}
              height={height}
              key={key}
              alt="multi factor authentication"
            />
          )
        );

      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <form
            onSubmit={handleSubmit(async (values) => {
              try {
                setLoading(true);
                await mfaLogin(values);
                await navigate?.(
                  `${upPath}${Routes.BANK_ACCOUNT_SELECT_ACCOUNT}`,
                );
              } catch (error) {
                const castedError = error as Error & {
                  errorCode?: unknown;
                  error?: unknown;
                };
                popToast({
                  message: castedError.errorCode
                    ? `There was a problem providing your MFA. Message from your bank: "${castedError.error}"`
                    : GENERIC_ERROR_MESSAGE,
                  level: 'error',
                });
              }
              setLoading(false);
            })}
          >
            {selectedBank?.name && (
              <Text
                variant={4}
                color="neutral.080"
                isBold={true}
                align="center"
              >
                LINK {selectedBank.name.toUpperCase()} ACCOUNT
              </Text>
            )}

            <Stack spaceY="md">
              <PageHeading
                title={mfaRequest ? mfaRequest.title : 'Account security'}
              />
              <Stack spaceY="sm">{mfaRequest?.fields?.map(getField)}</Stack>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{ name: 'bank_account_2fa_submit' }}
                isLoading={loading}
              >
                Submit
              </PageFormContinueButton>

              <PageFormCancelButton
                onClick={() => navigate?.(upPath)}
                trackingProperties={{
                  name: 'bank_account_2fa_try_another_bank',
                }}
                isDisabled={loading}
              >
                Try another bank
              </PageFormCancelButton>
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

const toFriendlyMFAInputText = (id: string): string => {
  // Some banks (such as HSBC) require the customer
  // to insert indexed characters from their password.
  const RCCField = 'RCCField';
  if (id.includes(RCCField)) {
    const index = Number(id.replace(RCCField, ''));
    if (isNaN(index)) {
      return id;
    }
    return `${formatNumberWithOrdinal(index + 1)} character`;
  }
  return camelPad(id);
};
