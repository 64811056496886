import { IconProps } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  color,
  getSpace,
  height,
  width,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

import { UnstyledButton, UnstyledButtonProps } from '../unstyled-button';

export type IconButtonVariant = 'light' | 'dark';
export type IconButtonSize = 'sm' | 'lg';

const StyledButton = styled(UnstyledButton)`
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;

  :focus {
    outline: none;
  }

  ${createProps({
    $variant: createVariant({
      dark: css`
        ${backgroundColor('neutral.030')}
        ${color('neutral.080')}
        transition: 0.15s;

        :focus,
        :not(:disabled):hover {
          ${backgroundColor('neutral.050')}
          ${color('neutral.100')}
        }
      `,
      light: css`
        ${backgroundColor('neutral.000')}
        ${color('neutral.100')}
        transition: 0.15s opacity;

        :focus,
        :not(:disabled):hover {
          opacity: 0.8;
        }
      `,
    }),
    $width: width,
    $height: height,
  })}
`;

export interface IconButtonProps extends UnstyledButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?:
    | keyof JSX.IntrinsicElements
    | React.ComponentType<React.PropsWithChildren>;
  icon: React.ComponentType<IconProps>;
  size: IconButtonSize;
  variant: IconButtonVariant;
}

export const IconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = ({
  variant,
  size,
  icon: Icon,
  ...otherProps
}) => {
  return (
    <StyledButton
      {...otherProps}
      $variant={variant}
      $width={getSpace(size === 'sm' ? 'lg' : 'xl')}
      $height={size === 'sm' ? 'lg' : 'xl'}
    >
      <Icon size={size === 'sm' ? 'sm' : 'md'} />
    </StyledButton>
  );
};
