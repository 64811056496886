import { RouteComponentProps } from '@reach/router';
import {
  Accordion,
  Box,
  Columns,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  useIsStatusInProgress,
  useSourcesLeftToVerify,
} from '@spaceship-fspl/green-id';
import { SupportConfig } from '@spaceship-fspl/helpers';
import {
  StreamlineBusinessContractApproveIcon,
  StreamlineFaceId4Icon,
  StreamlineSearch1Icon,
  StreamlineSingleNeutralShieldIcon,
  StreamlineTaskListMultipleIcon,
  StreamlineTimeClockCircleIcon,
} from '@spaceship-fspl/icons-web';
import {
  useHasSuppliedTfn,
  useRecordSuperMatchConsent,
} from '@spaceship-fspl/super';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { AccountCardContent } from 'components/account-cards/components';
import { Checkbox } from 'components/checkbox';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageFormSkipButton,
  PageHeading,
} from 'components/layouts/page';
import { SuperTaxFileNumberCard } from 'components/super-tax-file-number-card';
import { useNotifications } from 'contexts/notifications';
import { useSuperMatch } from 'contexts/super/super-match';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

const ConsentBox: React.FC<
  React.PropsWithChildren<{
    isGreenIdInPendingState: boolean;
    hasSuppliedTfn?: boolean;
  }>
> = ({ isGreenIdInPendingState, hasSuppliedTfn }) => {
  const { onAddTfn } = useSuperMatch();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (isGreenIdInPendingState) {
    return (
      <AccountCardContent>
        <Stack spaceY="xs" alignX="center">
          <StreamlineSingleNeutralShieldIcon color="indigo.070" size="lg" />
          <Text variant={2} align="center">
            We’ve received your identification documents and are verifying them.
            This normally takes up to one business day.
          </Text>
        </Stack>
      </AccountCardContent>
    );
  }

  // still loading TFN data
  if (hasSuppliedTfn === undefined) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  if (hasSuppliedTfn === false) {
    return (
      <SuperTaxFileNumberCard
        copy="Add a Tax File Number to your account to continue"
        onClick={onAddTfn}
      />
    );
  }

  return (
    <Checkbox
      errorMessage={errors?.consent?.message as string}
      {...register('consent', { required: 'Your consent is required.' })}
    >
      <Text variant={2}>
        I consent to Spaceship using my TFN to run a search on the ATO&apos;s
        SuperMatch service to find my super, and if requested by me, reuniting
        me with my super.
      </Text>
    </Checkbox>
  );
};

export const SuperMatchConsent: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const recordConsentRequest = useRecordSuperMatchConsent();
  const { variant, onCancel, onConsent, onSkip } = useSuperMatch();
  const hasSuppliedTfn = useHasSuppliedTfn();
  const { popToast } = useNotifications();
  const greenidSourcesLeftToVerify = useSourcesLeftToVerify(
    GreenIdRuleSet.Enum.SUPERMATCH,
  );
  const isOverallStatusInProgress = useIsStatusInProgress(
    GreenIdRuleSet.Enum.SUPERMATCH,
  );
  const isGreenIdInPendingState =
    isOverallStatusInProgress && greenidSourcesLeftToVerify === 0;
  const isOnboarding = variant === 'onboarding';
  const Container = isOnboarding ? OnboardingContainer : PageContainer;

  const formMethods = useForm<{ consent: boolean }>({
    defaultValues: {
      consent: false,
    },
  });

  const onSubmit = formMethods.handleSubmit(async () => {
    try {
      await recordConsentRequest.mutateAsync();
      onConsent();
    } catch (error) {
      addRumError({ error });
      popToast({
        message: GENERIC_ERROR_MESSAGE,
        level: 'error',
      });
    }
  });

  return (
    <Container>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <FormProvider {...formMethods}>
            <form onSubmit={onSubmit}>
              <Stack spaceY="lg">
                <PageHeading
                  title="Find and consolidate your super"
                  subtitle="Spaceship can use your Tax File Number (TFN) to help you find and consolidate other super accounts you might have."
                />

                <Stack spaceY="md">
                  <Heading variant={4} isBold={true}>
                    How it works
                  </Heading>

                  <Stack spaceY="sm">
                    <Box display="flex">
                      <Box marginRight="md">
                        <StreamlineSearch1Icon size="xl" color="indigo.070" />
                      </Box>

                      <Text variant={2}>
                        Spaceship can run a SuperMatch search to help you find
                        other super accounts you might have, including any
                        Australian Tax Office (ATO)-held super.
                      </Text>
                    </Box>

                    <Box display="flex">
                      <Box marginRight="md">
                        <StreamlineTaskListMultipleIcon
                          size="xl"
                          color="indigo.070"
                        />
                      </Box>

                      <Text variant={2}>
                        You can then review your SuperMatch search results and
                        decide whether you want to consolidate your other super
                        accounts or any ATO-held super amounts into your
                        Spaceship Super account.
                      </Text>
                    </Box>

                    <Box display="flex">
                      <Box marginRight="md">
                        <StreamlineTimeClockCircleIcon
                          size="xl"
                          color="indigo.070"
                        />
                      </Box>

                      <Text variant={2}>
                        Super consolidation is not mandatory and you can do this
                        at any time. There is no cost to you to use the
                        SuperMatch service.
                      </Text>
                    </Box>

                    <Box display="flex">
                      <Box marginRight="md">
                        <StreamlineBusinessContractApproveIcon
                          size="xl"
                          color="indigo.070"
                        />
                      </Box>

                      <Text variant={2}>
                        By asking us to find your other super accounts, you are
                        consenting to us using your TFN to search the ATO’s
                        SuperMatch service for any other super monies you may
                        have that are held by the ATO.
                      </Text>
                    </Box>

                    <Box display="flex">
                      <Box marginRight="md">
                        <StreamlineFaceId4Icon size="xl" color="indigo.070" />
                      </Box>

                      <Stack spaceY="xs">
                        <Text variant={2}>
                          To run a SuperMatch search, we may also need to verify
                          your identity.
                        </Text>
                        <Text variant={3}>
                          By continuing, you consent to Spaceship using a third
                          party electronic verification service to verify your
                          identity against external data sources, including by
                          disclosing your personal information to credit
                          reporting agencies, document issuers, and official
                          record holders.
                        </Text>

                        <Accordion variant="show-more" title="Learn more">
                          <Box
                            backgroundColor="neutral.000"
                            borderRadius="sm"
                            padding="md"
                            marginTop="xs"
                          >
                            <Stack spaceY="sm">
                              <Heading variant={5} isBold={true} component="h3">
                                How we verify your identity
                              </Heading>
                              <Text variant={3}>
                                Spaceship uses electronic verification services
                                to verify your identity. We do this to make sure
                                we know who you are and to meet regulatory
                                requirements.
                                <br />
                                <br />
                                By creating a Spaceship account, you consent to
                                Spaceship verifying your name, date of birth,
                                and residential address against external data
                                sources in accordance with anti-money laundering
                                and counter-terrorism financing legislation.
                                <br />
                                <br />
                                This includes us disclosing your personal
                                information to a credit reporting agency and
                                asking the credit reporting agency to assess
                                whether it matches (in whole or in part) the
                                personal information contained in a credit
                                information file. <br />
                                <br />
                                The credit reporting agency may then prepare and
                                provide Spaceship with this assessment and may
                                use your personal information, and personal
                                information held about other individuals, for
                                the purposes of preparing this assessment.
                                <br />
                                <br />
                                <strong>
                                  If you disagree with having your identity
                                  verified by a credit reporting agency, please
                                  contact customer support on{' '}
                                  <TextLink
                                    color="indigo.070"
                                    href={`mailto:${SupportConfig.EMAIL_ADDRESS}`}
                                  >
                                    {SupportConfig.EMAIL_ADDRESS}
                                  </TextLink>{' '}
                                  or{' '}
                                  <TextLink
                                    color="indigo.070"
                                    href={`tel:${SupportConfig.PHONE_NUMBER}`}
                                  >
                                    {SupportConfig.PHONE_NUMBER_DISPLAY}
                                  </TextLink>
                                  .
                                </strong>
                              </Text>
                            </Stack>
                          </Box>
                        </Accordion>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>

                <Divider color="neutral.050" size="md" />

                <ConsentBox
                  isGreenIdInPendingState={isGreenIdInPendingState}
                  hasSuppliedTfn={hasSuppliedTfn}
                />
              </Stack>

              <PageFormButtonContainer>
                {!isGreenIdInPendingState && hasSuppliedTfn && (
                  <PageFormContinueButton
                    trackingProperties={{ name: 'supermatch_consent_continue' }}
                  />
                )}

                {onSkip && (
                  <PageFormSkipButton
                    onClick={onSkip}
                    trackingProperties={{ name: 'supermatch_consent_skip' }}
                  />
                )}

                {!isOnboarding && (
                  <PageFormCancelButton
                    onClick={onCancel}
                    trackingProperties={{ name: 'supermatch_consent_cancel' }}
                  />
                )}
              </PageFormButtonContainer>
            </form>
          </FormProvider>
        </Columns.Column>
      </Columns>
    </Container>
  );
};
