import { Stack } from '@spaceship-fspl/components';
import React from 'react';

import { InlineError } from './inline-error';
import { Input, InputProps } from './input';

export const InputWithInlineError: React.FC<
  React.PropsWithChildren<InputProps>
> = ({ errorMessage, ...props }) => {
  return (
    <Stack spaceY="xxxs">
      <Input {...props} errorMessage={errorMessage && props.placeholder} />
      {errorMessage && <InlineError message={errorMessage} />}
    </Stack>
  );
};
