import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import { StreamlineFaceId4Icon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import type { TrackingProperties } from 'helpers/analytics';
import React from 'react';

interface PendingVerificationProps {
  variant: 'investment' | 'withdrawal' | 'add-money' | 'withdraw-money';
  onBack?: {
    trackingProperties: TrackingProperties;
    onClick: () => void;
  };
}

export const PendingVerification: React.FC<PendingVerificationProps> = ({
  variant,
  onBack,
}) => {
  const renderSubtitle = (): string => {
    switch (variant) {
      case 'investment':
        return "Once this is complete, you'll be able to make an investment.";
      case 'withdrawal':
        return "Once this is complete, you'll be able to make a withdrawal.";
      case 'add-money':
        return 'Once this is complete, you’ll be able to add money to your Spaceship account.';
      case 'withdraw-money':
        return "Once this is complete, you'll be able to withdraw from your Spaceship account.";
    }
  };

  return (
    <Stack spaceY="md" alignX="center">
      <StreamlineFaceId4Icon strokeWidth={5} color="indigo.070" size="xxxl" />

      <Stack spaceY="xs">
        <Heading variant={3} component="h1" align="center">
          We&apos;re still verifying your ID.
        </Heading>

        <Text variant={1} align="center">
          {renderSubtitle()}
        </Text>
      </Stack>

      {onBack ? (
        <Box marginTop="md">
          <Button
            variant="primary"
            size="lg"
            trackingProperties={onBack.trackingProperties}
            onClick={onBack.onClick}
          >
            Go back
          </Button>
        </Box>
      ) : null}
    </Stack>
  );
};
