import { Box, Columns, Text } from '@spaceship-fspl/components';
import {
  EtfProductIcon,
  FeatherChevronRightIcon,
  StocksProductIcon,
  VoyagerProductIcon,
} from '@spaceship-fspl/icons-web';
import { colors, getColor } from '@spaceship-fspl/styles';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type Product = 'voyager' | 'etf' | 'stocks';

export const ReadyToInvestActionItem: React.FC<{
  title: string;
  product: Product;
  productAction: () => void;
  ctaElement?: ReactNode;
  message?: string;
}> = ({ title, message, ctaElement, product, productAction }) => {
  return (
    <ActionItem
      data-testid={`action-item-${product}`}
      role="button"
      onClick={productAction}
    >
      <Columns spaceX={{ xs: 'xxs', sm: 'xs', lg: 'none' }}>
        <Columns.Column width={{ xs: 1 / 5, md: 0.6 / 4, lg: 1 / 5 }}>
          <Box display="flex" alignItems="flex-start">
            <ProductIcon product={product} />
          </Box>
        </Columns.Column>
        <Columns.Column width={{ xs: 3 / 5, md: 2.4 / 4, lg: 3 / 5 }}>
          <Box>
            <Box
              marginBottom={{ xs: 'xxs', sm: 'none', lg: 'xxs', xl: 'none' }}
            >
              <Text variant={2} isBold={true}>
                {title}
              </Text>
            </Box>
            <Text variant={3}>{message}</Text>
          </Box>
        </Columns.Column>
        <Columns.Column width={{ xs: 1 / 5, md: 1 / 4, lg: 1 / 5 }}>
          <Box display="flex" alignItems="flex-start" justifyContent="flex-end">
            {ctaElement ? (
              ctaElement
            ) : (
              <FeatherChevronRightIcon
                testId={'chevron-right-icon'}
                color={'indigo.070'}
                size={'md'}
              />
            )}
          </Box>
        </Columns.Column>
      </Columns>
    </ActionItem>
  );
};

const ActionItem = styled.div`
  cursor: pointer;
`;

interface ProductIconWrapperProps {
  readonly $backgroundColour: string;
}

const ProductIconWrapper = styled.div<ProductIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;

  border-radius: 60px;
  background-color: ${(props: ProductIconWrapperProps) =>
    props.$backgroundColour};
`;

const ProductIcon: React.FC<{ product: Product }> = ({ product }) => {
  let icon = null;

  const productBackgroundColour =
    getColor(`product.${product}`) ?? colors.indigo['070'];

  if (!product) {
    return null;
  }

  switch (product) {
    case 'voyager': {
      icon = (
        <VoyagerProductIcon
          testId={'voyager-product-icon'}
          color={'neutral.000'}
          size={'lg'}
        />
      );
      break;
    }
    case 'etf': {
      icon = (
        <EtfProductIcon
          testId="etf-product-icon"
          color={'neutral.000'}
          size={'lg'}
        />
      );
      break;
    }
    case 'stocks': {
      icon = (
        <StocksProductIcon
          testId="stocks-product-icon"
          color={'neutral.000'}
          size={'lg'}
        />
      );
      break;
    }
  }

  return (
    <ProductIconWrapper $backgroundColour={productBackgroundColour}>
      {icon}
    </ProductIconWrapper>
  );
};
