import * as React from 'react';
export const StreamlineCashBulb = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M9.25 20.938h5.5M12 20.938v1.375M19.563 9.25A7.562 7.562 0 109.25 16.297v1.89h5.5v-1.89a7.565 7.565 0 004.813-7.047z" />
    <path vectorEffect="non-scaling-stroke" d="M10.297 10.94a2.036 2.036 0 001.703.803c1.044 0 1.89-.636 1.89-1.419 0-.782-.846-1.418-1.89-1.418s-1.891-.635-1.891-1.419c0-.783.847-1.418 1.891-1.418a2.04 2.04 0 011.703.802M12 11.742v.946M12 5.125v.944" />
  </svg>
);
