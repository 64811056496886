import { Columns } from '@spaceship-fspl/components';
import React from 'react';

export const ContentLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Columns alignX="center">
      <Columns.Column width={{ xs: 1, md: 10 / 12, lg: 6 / 12 }}>
        {children}
      </Columns.Column>
    </Columns>
  );
};
