import {
  Box,
  Button,
  Columns,
  Divider,
  DynamicIcon,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  AccountFeeType,
  BoostRecipeParameterType,
  SaverPortfolio,
  ScheduleFrequency,
  TransactionStatus,
} from '@spaceship-fspl/graphql';
import { bankAccountScalars } from '@spaceship-fspl/graphql/src/__generated__/bankAccountScalars';
import type {
  WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node_productTransaction,
  WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node_productTransaction_Application_voyagerApplicationBankAccount,
  WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node_productTransaction_Redemption_voyagerRedemptionBankAccount,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyDashboardPage';
import { capitalizeWords, useRenderTemplate } from '@spaceship-fspl/helpers';
import {
  FeatherChevronRightIcon,
  FeatherInfoIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderWidthTop,
  color,
  getColor,
  getSpace,
  match,
  paddingBottom,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { getBoostRecipeTemplateVariables } from '@spaceship-fspl/voyager';
import { LabeledField } from 'components/labeled-field';
import { RouterLink } from 'components/router-link';
import { Tooltip } from 'components/tooltip';
import { format } from 'date-fns';
import { formatRelative } from 'date-fns';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { formatCurrency } from 'helpers/format';
import { addRumError } from 'helpers/monitoring';
import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';

import { TransactionStatusIndicator } from './status-indicator';
import { VoyagerCancelTransactionModal } from './voyager-cancel-transaction-modal';

export const DATE_FORMAT_TRANSACTIONS = 'dd MMM yyyy';

type BoostDetails = {
  recipe?: null | {
    name?: string;
    description?: string;
    source?:
      | (
          | { __typename: 'BasiqConnection' }
          | {
              __typename: 'WeatherStation';
              id: string;
              postcode: string;
              name: string;
            }
        )
      | null;
    parameters?: Array<{
      id: string;
      name: string;
      value: string;
      type: BoostRecipeParameterType;
    }> | null;
  };
  items?: null | Array<{
    id: string;
    createdAt: string;
    description: string;
    audAmount: string;
  }>;
};

export type Transaction = {
  id: string;
  status: TransactionStatus;
  unitExchange: null | {
    units: string;
    createdAt: string;
    unitPrice: {
      price: string;
    };
  };
  audAmount: string | null;
  requestedAt: string;
} & (
  | { __typename: 'Promotion' }
  | { __typename: 'Reinvestment' }
  | {
      __typename: 'AccountFee';
      type: AccountFeeType;
    }
  | {
      __typename: 'Application';
      applicationBankAccount: bankAccountScalars;
      estimatedExecutionDate: string;
      cancelDeadline: string;
      schedule: { frequency: ScheduleFrequency };
    }
  | {
      __typename: 'Redemption';
      fullRedemption?: boolean;
      redemptionBankAccount: bankAccountScalars | null;
    }
  | {
      __typename: 'Referral';
      isReferrer: boolean;
      referralShortName: string | null;
      referrerShortName: string | null;
    }
  | {
      __typename: 'Boost';
      estimatedExecutionDate: string;
      boostBankAccount: bankAccountScalars;
      itemGroup: BoostDetails;
    }
);

interface TransactionListProps {
  transactions: Transaction[];
  expandable?: boolean;
  portfolio?: SaverPortfolio;
  cancelFeatureEnabled?: boolean;
}

interface TransactionItemProps {
  title: string;
  requestedAt?: Date;
  iconName?: string;
  route?: string;
  status?: TransactionStatus;
  amount?: string | null;
  units?: string | null;
  unitPrice?: string | null;
  unitText?: string;
  unitsExchangedAt?: Date | null;
  bankDetails?: bankAccountScalars | null;
  isExpandable: boolean;
  expandedCopy?: string;
  boostDetails?: BoostDetails;
  portfolio?: SaverPortfolio;
  cancelCopyText?: string;
  onCancel?: () => void;
  showMoneyView?: boolean;
}

export const TransactionList = memo(
  ({
    transactions,
    portfolio,
    expandable = true,
    cancelFeatureEnabled = false,
  }: TransactionListProps) => {
    const [cancelTransactionId, setCancelTransactionId] = useState('');
    return (
      <StyledTransactionListContainer isExpandableList={expandable}>
        {transactions.map((tx) => {
          let title = `${tx.__typename}`;
          let unitText = 'Units issued';
          let expandedCopy: string | undefined;
          let bankDetails: bankAccountScalars | null | undefined;
          let cancelCopyText: string | undefined;
          switch (tx.__typename) {
            case 'Boost':
              bankDetails = tx.boostBankAccount;
              if (tx.itemGroup.recipe) {
                title = tx.itemGroup.recipe.name ?? 'Boost';
              }
              break;
            case 'Application':
              bankDetails = tx.applicationBankAccount;
              title =
                tx.schedule.frequency === 'ONE_TIME'
                  ? 'One-off investment'
                  : `Investment plan (${tx.schedule.frequency.toLowerCase()})`;
              if (new Date(tx?.cancelDeadline).getTime() > Date.now()) {
                cancelCopyText = `You can cancel this transaction later if you need to. You have until ${formatRelative(
                  new Date(tx.cancelDeadline),
                  new Date(),
                )}`;
              }
              break;
            case 'Redemption':
              title = tx.fullRedemption ? 'Full withdrawal' : 'Withdrawal';
              bankDetails = tx.redemptionBankAccount;
              unitText = 'Units redeemed';
              break;
            case 'Reinvestment':
              title = 'Distribution';
              break;
            case 'Referral':
              if (tx.isReferrer) {
                title = `Referral (${tx.referralShortName})`;
                if (tx.status !== TransactionStatus.PAID) {
                  expandedCopy =
                    "Your friend hasn't met the eligibility criteria yet. Once they do, you'll both receive your bonuses within five days!";
                }
              } else {
                title = `Referred by ${tx.referrerShortName}`;
              }
              break;
            case 'AccountFee':
              title =
                tx.type === AccountFeeType.ACCOUNT_FEE_MARCH_2023
                  ? 'Monthly fee'
                  : 'Management fee';
              unitText = 'Units redeemed';
              break;
          }

          const requestedAt = new Date(tx.requestedAt);

          let unitsExchangedAt: Date | undefined;
          if (tx.unitExchange?.createdAt) {
            unitsExchangedAt = new Date(tx.unitExchange?.createdAt);
          }

          return (
            <TransactionItem
              title={title}
              key={tx.id}
              requestedAt={requestedAt}
              status={tx?.status}
              amount={tx?.audAmount}
              units={tx?.unitExchange?.units}
              unitPrice={tx?.unitExchange?.unitPrice?.price}
              unitsExchangedAt={unitsExchangedAt}
              bankDetails={bankDetails}
              isExpandable={expandable}
              expandedCopy={expandedCopy}
              unitText={unitText}
              portfolio={portfolio}
              boostDetails={
                tx.__typename === 'Boost' ? tx.itemGroup : undefined
              }
              cancelCopyText={cancelFeatureEnabled ? cancelCopyText : ''}
              onCancel={() => {
                setCancelTransactionId(tx.id);
              }}
            />
          );
        })}
        {cancelFeatureEnabled && (
          <VoyagerCancelTransactionModal
            showModal={cancelTransactionId !== ''}
            closeModal={() => {
              setCancelTransactionId('');
            }}
            transactionId={cancelTransactionId}
          />
        )}
      </StyledTransactionListContainer>
    );
  },
);

TransactionList.displayName = 'TransactionList';

export const ProductTransactionItem: React.FC<{
  tx: WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node_productTransaction | null;
}> = ({ tx }) => {
  const [cancelTransactionId, setCancelTransactionId] = useState('');
  const cancelFeatureEnabled = useFeatureFlag(
    FeatureFlagKeys.CANCEL_TRANSACTION_APPLICATION_ENABLED,
  );

  let title = `${tx?.__typename}`;
  let boostDetails: BoostDetails | undefined;
  let unitText = 'Units issued';
  let status: TransactionStatus | undefined;
  let amount: string | null | undefined;
  let units: string | null | undefined;
  let unitPrice: string | null | undefined;
  let expandedCopy: string | undefined;
  let bankDetails:
    | WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node_productTransaction_Redemption_voyagerRedemptionBankAccount
    | WebAppMoneyDashboardPage_contact_account_moneyInstance_transactions_edges_node_productTransaction_Application_voyagerApplicationBankAccount
    | null = null;
  let cancelCopyText: string | undefined;
  let unitsExchangedAt: Date | undefined;
  let requestedAt: Date | undefined;
  let portfolio: SaverPortfolio | undefined;

  switch (tx?.__typename) {
    case 'Application': {
      bankDetails = tx?.voyagerApplicationBankAccount;
      status = tx?.voyagerStatus;
      amount = tx?.audAmount;
      units = tx?.unitExchange?.units;
      unitPrice = tx?.unitExchange?.unitPrice.price;
      portfolio = tx?.saverProductInstance?.portfolio;
      title =
        tx.schedule.frequency === ScheduleFrequency.ONE_TIME
          ? 'One-off investment'
          : `Investment plan (${tx.schedule.frequency.toLowerCase()})`;

      if (tx?.requestedAt) {
        requestedAt = new Date(tx.requestedAt);
      }

      if (new Date(tx?.cancelDeadline).getTime() > Date.now()) {
        cancelCopyText = `You can cancel this transaction later if you need to. You have until ${formatRelative(
          new Date(tx.cancelDeadline),
          new Date(),
        )}`;
      }

      if (tx?.unitExchange?.createdAt) {
        unitsExchangedAt = new Date(tx.unitExchange.createdAt);
      }

      break;
    }
    case 'Boost': {
      status = tx?.voyagerStatus;
      amount = tx?.audAmount;
      portfolio = tx?.saverProductInstance.portfolio;
      bankDetails = tx?.voyagerBoostBankAccount;
      boostDetails = tx?.itemGroup;

      if (tx?.requestedAt) {
        requestedAt = new Date(tx.requestedAt);
      }

      if (tx?.itemGroup?.recipe) {
        title = `Boost - ${tx.itemGroup.recipe.name}`;
      }

      break;
    }
    case 'Redemption': {
      title = tx?.fullRedemption ? 'Full withdrawal' : 'Withdrawal';
      bankDetails = tx?.voyagerRedemptionBankAccount;
      unitText = 'Units redeemed';
      status = tx?.voyagerStatus;
      amount = tx?.audAmount;
      units = tx?.unitExchange?.units;
      unitPrice = tx?.unitExchange?.unitPrice.price;
      portfolio = tx?.saverProductInstance.portfolio;

      if (tx?.requestedAt) {
        requestedAt = new Date(tx.requestedAt);
      }

      if (tx?.unitExchange?.createdAt) {
        unitsExchangedAt = new Date(tx.unitExchange.createdAt);
      }

      break;
    }
  }

  return (
    <>
      <TransactionItem
        showMoneyView
        title={title}
        key={tx?.id}
        requestedAt={requestedAt}
        status={status}
        amount={amount}
        units={units}
        unitPrice={unitPrice}
        unitsExchangedAt={unitsExchangedAt}
        bankDetails={bankDetails}
        isExpandable
        expandedCopy={expandedCopy}
        unitText={unitText}
        portfolio={portfolio}
        cancelCopyText={cancelCopyText}
        boostDetails={boostDetails}
        onCancel={() => {
          if (tx?.id) {
            setCancelTransactionId(tx.id);
          }
        }}
      />

      {cancelFeatureEnabled && (
        <VoyagerCancelTransactionModal
          showModal={cancelTransactionId !== ''}
          closeModal={() => {
            setCancelTransactionId('');
          }}
          transactionId={cancelTransactionId}
        />
      )}
    </>
  );
};

export const TransactionItem: React.FC<
  React.PropsWithChildren<TransactionItemProps>
> = (props) => {
  const hasUnitDetails =
    !!props.units && !!props.unitText && !!props.unitsExchangedAt;
  const hasBankDetails = !!props.bankDetails;
  const hasExpandableCopy = !!props.expandedCopy;

  const isExpandable =
    props.isExpandable &&
    (hasUnitDetails || hasBankDetails || hasExpandableCopy);

  const summary = (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {props.iconName && (
        <Box marginRight="sm">
          <DynamicIcon name={props.iconName} color="neutral.100" size="lg" />
        </Box>
      )}

      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        flex={1}
      >
        <Box minWidth={DATE_MIN_WIDTH}>
          <Text
            whiteSpace="nowrap"
            variant={4}
            isBold={true}
            color="neutral.080"
          >
            {props?.requestedAt
              ? format(props.requestedAt, DATE_FORMAT_TRANSACTIONS)
              : 'Pending'}
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          justifyContent="space-between"
          flex={1}
          marginLeft={{ md: 'xxs' }}
          marginRight="xxs"
        >
          <Box marginY={{ xs: 'xxxs', md: 'none' }}>
            <Text variant={3} isBold={true} align="left">
              {props.title}
            </Text>
          </Box>

          {props.status && (
            <Box minWidth={100} marginLeft={{ md: 'sm' }}>
              <Inline spaceX="xxxs" alignY="center">
                <TransactionStatusIndicator status={props.status} />
                <Text variant={4} isBold={true} color="neutral.080">
                  {capitalizeWords(props.status)}
                </Text>
              </Inline>
            </Box>
          )}
        </Box>
      </Box>

      <Box minWidth={{ md: 80 }}>
        <Text variant={3} isBold={true} align="right">
          {formatCurrency(props.amount)}
        </Text>
      </Box>
    </Box>
  );

  const renderContent = (): React.ReactNode => (
    <StyledContentBox>
      <Stack spaceY="md">
        {hasUnitDetails && (
          <Columns spaceX="lg" spaceY="sm">
            <Columns.Column width="min">
              <LabeledField size="md" label="Units">
                {props.units}
              </LabeledField>
            </Columns.Column>

            <Columns.Column width="min">
              <LabeledField size="md" label="Unit price">
                ${Number(props.unitPrice)}
              </LabeledField>
            </Columns.Column>

            <Columns.Column width="min">
              <LabeledField
                size="md"
                label={props?.unitText || ''}
                align="left"
              >
                {props.unitsExchangedAt &&
                  format(props.unitsExchangedAt, DATE_FORMAT_TRANSACTIONS)}
              </LabeledField>
            </Columns.Column>
          </Columns>
        )}

        {hasBankDetails && (
          <Columns spaceX="lg" spaceY="sm">
            <Columns.Column width="min">
              <LabeledField size="md" label="Linked bank account">
                {props?.bankDetails?.friendlyName}
              </LabeledField>
            </Columns.Column>

            <Columns.Column width="min">
              <Inline spaceX="lg">
                <LabeledField size="md" label="BSB">
                  {props?.bankDetails?.bsb}
                </LabeledField>
                <LabeledField size="md" label="Account number">
                  {props?.bankDetails?.accountNumber}
                </LabeledField>
              </Inline>
            </Columns.Column>
          </Columns>
        )}

        {hasExpandableCopy && (
          <Text variant={3} align="left">
            {props.expandedCopy}
          </Text>
        )}

        {!!props.boostDetails && !!props.portfolio && (
          <BoostTransactionDetails
            portfolio={props.portfolio}
            {...props.boostDetails}
          />
        )}
        {!!props.cancelCopyText && !!props.onCancel && (
          <>
            <Divider color="neutral.050" />
            <Box display="flex" alignItems="center">
              <Box paddingRight="sm">
                <Tooltip
                  placement="left"
                  maxWidth={250}
                  content={
                    <Text
                      variant={4}
                      color="neutral.000"
                      isBold={true}
                      align="center"
                    >
                      We process payments at 4pm each day (eg. If you make an
                      investment at 8pm it will be processed the following
                      business day at 4pm).
                      <br />
                      <br />
                      This means that you are able to cancel your investment if
                      your cancellation request is received before 4pm.
                    </Text>
                  }
                >
                  <span>
                    <FeatherInfoIcon color="neutral.070" size="md" />
                  </span>
                </Tooltip>
              </Box>
              <Box paddingRight="sm">
                <Text variant={3} isBold={true}>
                  {props.cancelCopyText}
                </Text>
              </Box>
              <Button size="sm" variant="tertiary" onClick={props.onCancel}>
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </StyledContentBox>
  );

  if (props.route) {
    return (
      <StyledTransactionItemRouterLink
        to={props.route}
        trackingProperties={{ name: 'transaction_item_link' }}
      >
        <Box flex={1}>{summary}</Box>
        <Box marginLeft={{ xs: 'xxs', md: 'sm' }}>
          <FeatherChevronRightIcon color="indigo.070" size="md" />
        </Box>
      </StyledTransactionItemRouterLink>
    );
  }

  if (!isExpandable) {
    return <StyledContentOnlyContainer>{summary}</StyledContentOnlyContainer>;
  }

  if (props.showMoneyView) {
    return <>{renderContent()}</>;
  }

  return (
    <StyledDetails>
      <StyledSummary>{summary}</StyledSummary>

      <Stack spaceY={{ xs: 'sm', md: 'md' }}>
        <Divider color="neutral.050" />

        {renderContent()}
      </Stack>
    </StyledDetails>
  );
};

interface BoostTransactionDetailsProps extends BoostDetails {
  portfolio: SaverPortfolio;
}

const BoostTransactionDetails: React.FC<
  React.PropsWithChildren<BoostTransactionDetailsProps>
> = ({ portfolio, recipe, items }) => {
  const boostDescription = useRenderTemplate(
    recipe?.description ?? '',
    getBoostRecipeTemplateVariables({
      weatherStationName:
        recipe?.source?.__typename === 'WeatherStation'
          ? recipe?.source?.name
          : '',
      portfolio,
      parameters: recipe?.parameters,
    }),
    {
      onError: (error) => {
        addRumError({ error });
      },
    },
  );

  return (
    <Stack spaceY="md">
      <Divider color="neutral.070" />

      <Stack spaceY="sm">
        <Text variant={3} isBold={true}>
          Boost tracker
        </Text>

        <Stack spaceY="xxxs">
          <Text variant={4} color="neutral.080" isBold={true}>
            Boost
          </Text>
          <Text variant={3} isBold={true}>
            {boostDescription}
          </Text>
        </Stack>

        {items && items.length > 0 && (
          <Stack spaceY="xxs">
            <Text variant={4} color="neutral.080" isBold={true}>
              Spending history
            </Text>

            <Stack spaceY={{ xs: 'sm', md: 'xxs' }}>
              {items?.map((item) => (
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  key={item.id}
                >
                  <Box minWidth={DATE_MIN_WIDTH} marginRight="sm">
                    <Text variant={{ xs: 4, md: 3 }}>
                      {format(
                        new Date(item?.createdAt),
                        DATE_FORMAT_TRANSACTIONS,
                      )}
                    </Text>
                  </Box>

                  <Box display="flex" flex={1} justifyContent="space-between">
                    <Text variant={3} isBold={true}>
                      {item?.description}
                    </Text>

                    <Box marginLeft="sm">
                      <Text variant={3}>{formatCurrency(item?.audAmount)}</Text>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const DATE_MIN_WIDTH = 85;

const dividerStyle = css`
  :not(:first-child) {
    ${borderWidthTop('sm')};
    border-top-style: solid;
    border-top-color: ${getColor('neutral.030')};
  }
`;

const StyledContentOnlyContainer = styled.div`
  ${dividerStyle}
  ${paddingY('sm')}
`;

const StyledDetails = styled.details<{
  isExpandable?: boolean;
}>`
  ${dividerStyle}
  cursor: ${(props) => (props.isExpandable ? 'pointer' : 'unset')};
  position: relative;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    width: ${getSpace('xxxs')};
    background-color: transparent;
  }

  &[open] {
    ${backgroundColor('neutral.015')};

    ::before {
      ${backgroundColor('indigo.070')};
    }
  }

  [open] + &[open] {
    border-top-color: ${getColor('neutral.070')};
  }
`;

const StyledSummary = styled.summary`
  ${paddingX({ xs: 'md', md: 'lg' })}
  ${paddingY('md')}
  ${transition}
  cursor: pointer;
  list-style: none;
  outline: none;

  ${StyledDetails}[open] & {
    ${paddingBottom({ xs: 'sm', md: 'md' })}
  }

  :hover {
    ${backgroundColor('neutral.015')}
  }

  ::-webkit-details-marker,
  ::marker {
    display: none;
  }
`;

const StyledContentBox = styled(Box).attrs({
  paddingX: { xs: 'md', md: 'lg' },
  paddingBottom: 'md',
})`
  ${match('md')`
    margin-left: calc(${DATE_MIN_WIDTH}px + ${getSpace('xxs')});
  `};
`;

const StyledTransactionItemRouterLink = styled(RouterLink)`
  ${dividerStyle}
  ${paddingY('sm')}
  display: flex;
  align-items: center;

  * {
    ${transition}
  }

  :hover * {
    ${color('indigo.070')}
  }
`;

const StyledTransactionListContainer = styled.div<{
  isExpandableList: boolean;
}>`
  ${({ isExpandableList }) =>
    isExpandableList
      ? css`
          ${StyledContentOnlyContainer},
          ${StyledTransactionItemRouterLink} {
            ${paddingX({ xs: 'md', md: 'lg' })}
            ${paddingY('md')}
          }
        `
      : ''}
`;
