import { useApolloClient } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useLogin as useLoginMutation } from '@spaceship-fspl/auth';
import { MfaVerificationType } from '@spaceship-fspl/graphql';
import { useTrack } from '@spaceship-fspl/tracking';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { warmDynamicConfigCache } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React, { createContext } from 'react';
import { v4 as uuidV4 } from 'uuid';

export interface LoginDetails {
  // Login step 1: submit credentials. This will return a challenge if MFA is required.
  submitCredentials: (email: string, password: string) => Promise<void>;
  // Login step 2: submit the MFA challenge token.
  submitToken: (token: string) => Promise<void>;
  // MFA challenge may be re-requested with either SMS or WHATSAPP type.
  resendChallenge: (verificationType: MfaVerificationType) => Promise<void>;
  // True if the email+password is correct, does not indicate MFA status.
  credentialsSuccess: boolean;
  mfaChallengeType?: MfaVerificationType;
  loading: boolean;
}

interface LoginProps {
  location: {
    state: {
      continueToSuperCrossSell?: boolean;
    };
  };
}

export const LoginContext = createContext<LoginDetails | undefined>(undefined);

export const LoginProvider: React.FC<
  React.PropsWithChildren<RouteComponentProps<LoginProps>>
> = ({ children, location }) => {
  const [loginState, setLoginState] = React.useState<{
    email?: string;
    password?: string;
    mfaChallengeId?: string | null;
    mfaChallengeType?: MfaVerificationType;
  }>();

  const [loading, setLoading] = React.useState(false);
  const loginMutation = useLoginMutation();
  const navigate = useNavigate();
  const track = useTrack();
  const client = useApolloClient();
  const queryParams = new URLSearchParams(location?.search);
  const redirect = queryParams.get('redirect');

  const onLoginComplete = async (): Promise<void> => {
    track?.(MarketingTrackingEvents.LOGIN_SUCCESSFUL);

    try {
      // Wait for this to finish before proceeding.
      await warmDynamicConfigCache(client);
    } catch {
      // We don't want failing to fetch dynamic
      // configuration to prevent app entry.
    }

    if (redirect && redirect.startsWith('/')) {
      await navigate(redirect);
    } else {
      await navigate('/', { state: location?.state });
    }
  };

  return (
    <LoginContext.Provider
      value={{
        loading,
        mfaChallengeType: loginState?.mfaChallengeType,
        credentialsSuccess: !!(loginState?.email && loginState?.password),
        submitCredentials: async (email: string, password: string) => {
          setLoading(true);
          const requestId = uuidV4();
          try {
            const mfaChallenge = await loginMutation(
              email,
              password,
              requestId,
            );
            if (mfaChallenge) {
              setLoginState({
                email,
                password,
                mfaChallengeId: mfaChallenge.id,
                mfaChallengeType: mfaChallenge.type,
              });

              // propagate query params to the challenge page (e.g. make sure redirect continues to work)
              await navigate(
                `${Routes.LOGIN_CHALLENGE}?${queryParams.toString()}`,
                { state: location?.state },
              );
              return;
            }
          } finally {
            setLoading(false);
          }

          await onLoginComplete();
        },
        submitToken: async (token: string) => {
          const requestId = uuidV4();
          if (
            loginState?.email &&
            loginState?.password &&
            loginState.mfaChallengeType
          ) {
            try {
              setLoading(true);
              const mfaChallenge = await loginMutation(
                loginState?.email,
                loginState?.password,
                requestId,
                {
                  token,
                  // ID not relevant to the Manual type
                  id:
                    loginState.mfaChallengeType !== MfaVerificationType.MANUAL
                      ? loginState?.mfaChallengeId
                      : null,
                },
              );
              if (mfaChallenge) {
                throw new Error('unexpected mfa challenge');
              } else {
                await onLoginComplete();
              }
            } finally {
              setLoading(false);
            }
          }
        },
        resendChallenge: async (verificationType: MfaVerificationType) => {
          const requestId = uuidV4();

          if (loginState?.email && loginState?.password) {
            try {
              setLoading(true);
              const mfaChallenge = await loginMutation(
                loginState?.email,
                loginState?.password,
                requestId,
                undefined,
                verificationType,
              );
              if (mfaChallenge) {
                setLoginState({
                  ...loginState,
                  mfaChallengeId: mfaChallenge.id,
                  mfaChallengeType: mfaChallenge.type,
                });
              }
            } finally {
              setLoading(false);
            }
          }
        },
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = (): LoginDetails => {
  const context = React.useContext(LoginContext);

  if (!context) {
    throw new Error('webapp: Please wrap in <LoginProvider />');
  }

  return context;
};
