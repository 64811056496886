import { Box, Card, Spinner, Text } from '@spaceship-fspl/components';
import { Transaction, TransactionList } from 'components/transactions';
import React, { memo } from 'react';

interface RecentTransactionsCardProps {
  transactions: Transaction[];
  isLoading: boolean;
}

export const RecentTransactionsCard: React.FC<
  React.PropsWithChildren<RecentTransactionsCardProps>
> = memo((props) => {
  return (
    <Card
      display="flex"
      justifyContent="center"
      height="100%"
      aria-label="voyager recent transactions card"
    >
      {props.isLoading ? (
        <Box
          display="flex"
          flex={1}
          padding="md"
          justifyContent="center"
          alignItems="center"
          height={{ xs: 200, xl: 280 }}
        >
          <Spinner />
        </Box>
      ) : props.transactions.length > 0 ? (
        <Box flex={1} paddingX="md" paddingY="xxs">
          <TransactionList
            expandable={false}
            transactions={props.transactions}
          />
        </Box>
      ) : (
        <Box
          minHeight={{ xl: 280 }}
          padding="md"
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text variant={2} color="neutral.080" isBold={true} align="center">
            No transactions to display at this time.
          </Text>
        </Box>
      )}
    </Card>
  );
});

RecentTransactionsCard.displayName = 'RecentTransactionsCard';
