import {
  TrackingAction,
  useTracking,
  UseTrackingTrackFunctionParameters,
} from '@spaceship-fspl/tracking';
import React, { HTMLAttributeAnchorTarget } from 'react';
import styled from 'styled-components';

const Wrapper = styled.a<{ enabled: boolean }>`
  /* reset the native <a> styles */
  text-decoration: none;
  :focus {
    outline: none;
  }

  /* universal styles */
  cursor: pointer;
  :disabled {
    cursor: default;
  }
  ${({ enabled }) =>
    !enabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  `};
`;

export interface UnstyledLinkProps {
  children: React.ReactNode;
  href?: string;
  rel?: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  tracking?: UseTrackingTrackFunctionParameters;
  component?: React.ElementType;
  enabled?: boolean;
}

export const UnstyledLink = React.forwardRef<
  HTMLAnchorElement,
  UnstyledLinkProps
>(
  (
    {
      href,
      onClick,
      tracking,
      children,
      component,
      enabled = true,
      ...otherProps
    },
    ref,
  ) => {
    const { track } = useTracking();

    const handleClick = async (
      event: React.MouseEvent<HTMLAnchorElement>,
    ): Promise<void> => {
      if (tracking) {
        track(tracking);
      } else if (href) {
        track({
          action: TrackingAction.Navigate,
          label: href,
        });
      }
      onClick?.(event);
    };

    return (
      <Wrapper
        {...otherProps}
        as={component}
        ref={ref}
        role={href ? 'link' : onClick ? 'button' : null}
        href={href}
        onClick={handleClick}
        tabIndex={0}
        enabled={enabled}
      >
        {children}
      </Wrapper>
    );
  },
);

UnstyledLink.displayName = 'UnstyledLink';
