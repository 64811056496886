import { formatCurrency } from '@spaceship-fspl/helpers';
import { LabeledField } from 'components/labeled-field';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardEditProps,
  AccountCardHeading,
} from './components';

export interface VoyagerOneOffInvestmentCardProps {
  audAmount?: string;
  edit?: AccountCardEditProps;
}

export const VoyagerOneOffInvestmentCard: React.FC<
  React.PropsWithChildren<VoyagerOneOffInvestmentCardProps>
> = ({ children, audAmount, edit }) => {
  const displayAudAmount = audAmount ? formatCurrency(audAmount) : undefined;

  return (
    <AccountCard>
      <AccountCardHeading title="One off investment" edit={edit} />

      <AccountCardContent>
        {(!edit || !edit.isEditing) && (
          <LabeledField label="Amount" size="md">
            {displayAudAmount}
          </LabeledField>
        )}

        {children}
      </AccountCardContent>
    </AccountCard>
  );
};
