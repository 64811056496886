import {
  getLastFinancialYearDateRange,
  getToday,
  stringToDate,
} from '@spaceship-fspl/helpers';
import { isBefore, isSameDay } from 'date-fns';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { useGetMemberBalance, useListMemberBalanceOverTime } from '../react';

export const usePerformance = (): number | undefined => {
  const lastFinancialYearEndDate = getLastFinancialYearDateRange(getToday())[1];

  /**
   * CURRENT DAILY INCOME
   */
  const currentBalanceQry = useGetMemberBalance();
  const currentDailyIncome = useMemo(
    () =>
      currentBalanceQry.data?.income?.amount !== undefined
        ? new Decimal(currentBalanceQry.data.income.amount)
        : new Decimal(0),
    [currentBalanceQry.data],
  );

  /**
   * YEARLY BALANCE OVER TIME
   */
  const yearlyBalanceOverTimeQry = useListMemberBalanceOverTime({
    params: {
      frequency: 'yearly',
    },
  });

  // Add up income for each past financial year to get past performance
  const totalYearlyBalanceOverTime = useMemo(
    () =>
      yearlyBalanceOverTimeQry.data
        ? yearlyBalanceOverTimeQry.data.reduce(
            (incomeSum, { income }) => incomeSum.add(income?.amount || 0),
            new Decimal(0),
          )
        : new Decimal(0),
    [yearlyBalanceOverTimeQry.data],
  );

  const hasYearlyBalanceOverTimeData = !!yearlyBalanceOverTimeQry.data?.length;
  const mostRecentYearlyData = yearlyBalanceOverTimeQry.data?.[0]; // data is in desc date order
  const mostRecentYearlyEndDate = mostRecentYearlyData?.to
    ? stringToDate(mostRecentYearlyData.to.toString())
    : undefined;
  const isHasYearlyDataAndMissingLastYear =
    !!mostRecentYearlyEndDate &&
    isBefore(mostRecentYearlyEndDate, lastFinancialYearEndDate);
  const isHasYearlyDataAndHasLastYear =
    !!mostRecentYearlyEndDate &&
    isSameDay(mostRecentYearlyEndDate, lastFinancialYearEndDate);

  /**
   * DAILY BALANCE OVER TIME
   *
   *
   * Fetch the last 100 days of daily balance data in order to get the
   * data for 30/06 when it is not available in the yearly data (Certane
   * mentioned yearly BOT will include this data within the first quarter
   * of the new financial year so 100 days should be enough)
   *
   * Most of the time, this will be disabled because for at least 3/4 of
   * the year, the data is provided in the yearly BOT response.
   */
  const dailyBalanceOverTimeQry = useListMemberBalanceOverTime({
    params: {
      frequency: 'daily',
    },
    isEnabled:
      yearlyBalanceOverTimeQry.isSuccess &&
      (!hasYearlyBalanceOverTimeData || isHasYearlyDataAndMissingLastYear),
  });

  const lastEofyDailyData = dailyBalanceOverTimeQry.data?.find((dayData) => {
    if (dayData.to) {
      const dayDate = stringToDate(dayData.to.toString());
      return isSameDay(dayDate, lastFinancialYearEndDate);
    }
    return false;
  });

  const isLoading =
    currentBalanceQry.isLoading ||
    yearlyBalanceOverTimeQry.isLoading ||
    dailyBalanceOverTimeQry.isLoading;
  const isError =
    currentBalanceQry.isError ||
    yearlyBalanceOverTimeQry.isError ||
    dailyBalanceOverTimeQry.isError;

  const superPerformance = useMemo(() => {
    if (isLoading || isError) {
      return undefined;
    }

    const lastEofyDailyIncome = new Decimal(
      lastEofyDailyData?.income?.amount ?? 0,
    );
    let totalPerformance: Decimal;

    switch (true) {
      /**
       * New financial member within the current financial year:
       * - DOES NOT have any yearly balance over time data
       * - DOES NOT have last EOFY in daily balance over time data
       * Their performance is their current income
       *
       * Performance = current balance income
       */
      case !hasYearlyBalanceOverTimeData && !lastEofyDailyData:
        totalPerformance = currentDailyIncome;
        break;

      /**
       * Member who became financial in the last financial year:
       * - DOES NOT have any yearly balance over time data
       * - but have last EOFY in daily balance over time data
       *
       * Performance = missing EOFY daily income + current balance income
       */
      case !hasYearlyBalanceOverTimeData && !!lastEofyDailyData:
        totalPerformance = lastEofyDailyIncome.add(currentDailyIncome);
        break;

      /**
       * Member who became financial over 2 financial years ago AND new
       * financial year began recently (the yearly balance over time data
       * for the most recent FY is not yet ready from Certane API because
       * they require time to review):
       * - have yearly balance over time data
       * - but yearly balance over time data DOES NOT contain the most
       *   recent complete financial year (most recent data `to` date is
       *   before the last FY end date)
       *
       * Performance =
       *  missing EOFY daily income +
       *  total yearly income +
       *  current balance income
       */
      case isHasYearlyDataAndMissingLastYear:
        totalPerformance = lastEofyDailyIncome.add(
          totalYearlyBalanceOverTime.add(currentDailyIncome),
        );
        break;

      /**
       * Member who became financial last financial year or earlier
       * AND Certane API has reviewed last financial year data:
       * - have yearly balance over time data
       * - yearly balance over time data also contains the most
       *   recent complete financial year (most recent data `to` date is
       *   the same as the last FY end date)
       *
       * Performance = Total yearly income + current balance income
       */
      case isHasYearlyDataAndHasLastYear:
      default:
        totalPerformance = totalYearlyBalanceOverTime.add(currentDailyIncome);
        break;
    }

    return totalPerformance.dividedBy(100).toNumber();
  }, [
    isError,
    isLoading,
    isHasYearlyDataAndHasLastYear,
    isHasYearlyDataAndMissingLastYear,
    currentDailyIncome,
    hasYearlyBalanceOverTimeData,
    lastEofyDailyData,
    totalYearlyBalanceOverTime,
  ]);

  return superPerformance;
};
