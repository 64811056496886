import * as React from 'react';
export const StreamlineReligionPeace1 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1.38 12.005a10.625 10.625 0 1021.25 0 10.625 10.625 0 00-21.25 0v0zM12.005 1.38v21.25" />
    <path vectorEffect="non-scaling-stroke" d="M4.264 19.284l7.741-7.74 7.9 7.566" />
  </svg>
);
