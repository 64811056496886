import { gql } from '@apollo/client';
import { InfoPanel, Stack, Text } from '@spaceship-fspl/components';
import { VerificationStatus } from '@spaceship-fspl/graphql';
import { AccountVerificationStatusInfoPanel_AccountFragment as AccountVerificationStatusInfoPanel_AccountFragmentType } from '@spaceship-fspl/graphql/src/__generated__/AccountVerificationStatusInfoPanel_AccountFragment';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { TestId } from 'helpers/test-ids';
import React from 'react';

export const AccountVerificationStatusInfoPanel_AccountFragment = gql`
  fragment AccountVerificationStatusInfoPanel_AccountFragment on Account {
    id
    verificationStatus
    verificationStatusMessage
  }
`;

export const AccountVerificationStatusInfoPanel: React.FC<{
  account?: AccountVerificationStatusInfoPanel_AccountFragmentType | null;
}> = ({ account }) => {
  if (
    account?.verificationStatus === VerificationStatus.IN_REVIEW &&
    account?.verificationStatusMessage
  ) {
    return (
      <InfoPanel
        testId={TestId.MONEY_ACCOUNT_VERIFICATION_INFO_PANEL}
        color="gold.100"
        icon={FeatherInfoIcon}
        leftBorderColor="neutral.000"
      >
        <Stack spaceY={'xxxs'}>
          <Text variant={2} isBold>
            We’re reviewing your account
          </Text>
          <Text variant={3}>{account?.verificationStatusMessage}</Text>
        </Stack>
      </InfoPanel>
    );
  }

  return null;
};
