import { Link as ReachLink, LinkProps as ReachLinkProps } from '@reach/router';
import { useTrack } from '@spaceship-fspl/tracking';
import { TrackingEvent, TrackingProperties } from 'helpers/analytics';
import React from 'react';
import styled from 'styled-components';

interface RouterLinkProps {
  children: React.ReactNode;
  trackingProperties: TrackingProperties;
  onClick?: () => void;
  'aria-label'?: string;
  'aria-current'?: boolean | string;
}

export const RouterLink = <TState extends Record<string, unknown>>({
  to,
  replace,
  getProps,
  state,
  onClick,
  children,
  trackingProperties,
  className,
  'aria-label': ariaLabel,
  'aria-current': ariaCurrent,
}: RouterLinkProps & ReachLinkProps<TState>): JSX.Element => {
  const track = useTrack();
  return (
    <UnstyledReachLink
      className={className}
      aria-label={ariaLabel}
      aria-current={ariaCurrent}
      to={to}
      replace={replace}
      getProps={getProps}
      state={state}
      onClick={(event) => {
        event.stopPropagation();
        track?.(TrackingEvent.CLICK, { properties: trackingProperties });
        onClick?.();
      }}
    >
      {children}
    </UnstyledReachLink>
  );
};

RouterLink.displayName = 'RouterLink';

const UnstyledReachLink = styled(ReachLink)`
  /* reset the native <a> styles */
  color: inherit;
  text-decoration: none;
  :focus {
    outline: none;
  }

  /* universal styles */
  cursor: pointer;
  :disabled {
    cursor: default;
  }
`;

UnstyledReachLink.displayName = 'UnstyledReachLink';
