import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  UnstyledButton,
  Visible,
} from '@spaceship-fspl/components';
import { useSendPasswordResetCode } from '@spaceship-fspl/graphql';
import {
  FeatherArrowLeftIcon,
  FeatherChevronLeftIcon,
} from '@spaceship-fspl/icons-web';
import { useAmplifyAuth } from '@spaceship-fspl/super';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { useResetPasswordContext } from 'contexts/saver/onboarding';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { Routes } from './routes';

enum Source {
  EMPLOYMENT_HERO = 'employmenthero',
  SET_PASSWORD = 'set_password',
}

export const PasswordReset: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const [sendCode] = useSendPasswordResetCode();
  const [loading, setLoading] = useState(false);
  const [, setRequest] = useResetPasswordContext();
  const notifications = useNotifications();
  const { forgotPassword: amplifyResetPassword } = useAmplifyAuth();
  const form = useForm<{ email: string }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  const params = new URL(window.location.href).searchParams;
  const source = params.get('source') || '';

  let title = 'Reset your password';
  let body = 'Please enter your registration email.';

  if (source === Source.EMPLOYMENT_HERO || source === Source.SET_PASSWORD) {
    title = 'Set your password';
    body =
      'Welcome to Spaceship! To set up access to your member dashboard, enter your email and we’ll send you a password code.';
  }

  const onSubmit = form.handleSubmit(async ({ email }) => {
    const requestId = uuidv4();

    try {
      setLoading(true);

      const resp = await sendCode({
        variables: { input: { email } },
        context: { headers: { 'x-request-id': requestId } },
      });
      const resetId = resp.data?.sendPasswordResetCode?.resetId;

      if (resetId) {
        setRequest({ email, resetId });
        return navigate(Routes.PASSWORD_RESET_VERIFY);
      }

      // No resetId implies the user may be a legacy
      // super user.
      await amplifyResetPassword(email);
      await navigate(Routes.PASSWORD_RESET_SUPER_VERIFY, { state: { email } });
    } catch {
      notifications.popToast({
        requestId,
        level: 'warning',
        message:
          'Unable to reset password. Please check your email address and try again.',
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="md">
              <Stack spaceY="xs" alignX={{ sm: 'left', md: 'center' }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Visible
                    isHidden={{ xs: true, md: false }}
                    displayValue="block"
                  >
                    <UnstyledButton
                      onClick={() => {
                        navigate(Routes.LOGIN, { replace: true });
                      }}
                    >
                      <FeatherChevronLeftIcon
                        color="neutral.100"
                        size="lg"
                        strokeWidth={3}
                      />
                    </UnstyledButton>
                  </Visible>
                  <Heading variant={3}>{title}</Heading>
                </Box>
                <Text variant={2} color="neutral.080">
                  {body}
                </Text>
              </Stack>

              <ControllerInput
                name="email"
                control={form.control}
                type="email"
                placeholder="Email address"
                rules={validation.email}
              />
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{ name: 'reset_password_submit' }}
                isLoading={loading}
              />

              <Visible isHidden={{ xs: false, md: true }} displayValue="block">
                <Button
                  type="button"
                  variant="tertiary"
                  size="lg"
                  onClick={() => navigate?.(Routes.LOGIN)}
                  trackingProperties={{ name: 'reset_password_back' }}
                  isDisabled={loading}
                  before={<FeatherArrowLeftIcon />}
                >
                  Back
                </Button>
              </Visible>
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

const validation = {
  email: { required: 'Email address is required' },
};
