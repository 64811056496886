import { TimeScale } from '@spaceship-fspl/helpers';
import { useUnitPrice, useUnitPriceGraphData } from '@spaceship-fspl/super';
import { UnitPriceCard } from 'components/unit-price-card';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { PersistKey } from 'helpers/persist';
import { Routes } from 'pages/routes';
import React, { memo } from 'react';

export const SuperUnitPriceCard: React.FC<
  React.PropsWithChildren<{
    infoRoute?: Routes;
    chartHeight?: number;
    portfolioFriendlyName?: string;
  }>
> = memo(({ infoRoute, chartHeight, portfolioFriendlyName }) => {
  const showUnitPriceMovement = useFeatureFlag(
    FeatureFlagKeys.SUPER_UNIT_PRICE_MOVEMENT_VISIBLE,
  );
  const {
    hasLessThanOneYearOfData,
    oneMonthPerformance,
    oneWeekPerformance,
    oneYearPerformance,
    oneYearPerformanceMovement,
    latestCalculatedUnitPrice,
    latestUnitPrice,
  } = useUnitPrice();
  const [scale, setScale] = useLocalStorage<TimeScale>(
    PersistKey.SUPER_UNIT_PRICE_TIME_SCALE,
    TimeScale.MAX,
  );
  const { data: graphData } = useUnitPriceGraphData(scale);

  const latestUnitPriceDate = latestUnitPrice?.date
    ? new Date(latestUnitPrice.date)
    : undefined;

  const graphDataAtLastIndex = graphData[graphData.length - 1];

  const displayedDate = graphDataAtLastIndex
    ? new Date(graphDataAtLastIndex.x)
    : latestUnitPriceDate;

  return (
    <UnitPriceCard
      graphData={graphData}
      scale={scale}
      setScale={setScale}
      showUnitPriceMovement={showUnitPriceMovement}
      portfolio={{
        name: portfolioFriendlyName ?? '—',
        latestUnitPrice: latestCalculatedUnitPrice,
        latestUnitPriceDate: displayedDate,
        hasLessThanOneYearOfData: hasLessThanOneYearOfData ?? false,
      }}
      performance={{
        oneYearPerformanceMovement,
        week: oneWeekPerformance,
        month: oneMonthPerformance,
        year: oneYearPerformance,
      }}
      infoRoute={infoRoute}
      trackChartName="super_unit_price_chart"
      chartHeight={chartHeight}
    />
  );
});

SuperUnitPriceCard.displayName = 'SuperUnitPriceCard';
