import {
  Color,
  color,
  fontStyle,
  fontWeight,
  MixinFunctionValue,
  ResponsiveValue,
  text,
  textAlign,
  textDecoration,
  whiteSpace,
} from '@spaceship-fspl/styles';
import { createProps, StyleValue } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type TextVariant = 1 | 2 | 3 | 4;
export type TextAlignment = 'left' | 'center' | 'right';

const truncate = (): FlattenSimpleInterpolation => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const P = styled.p<{
  variant: ResponsiveValue<TextVariant>;
  textAlign?: MixinFunctionValue<'text-align'>;
  textColor?: ResponsiveValue<Color>;
  fontStyle?: MixinFunctionValue<'font-style'>;
  fontWeight?: MixinFunctionValue<'font-weight'>;
  textDecoration?: StyleValue<'text-decoration'>;
  whiteSpace?: StyleValue<'white-space'>;
  isTruncated?: boolean;
}>`
  margin: 0;
  ${text}
  ${createProps({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textAlign: textAlign as any,
    textColor: color,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontStyle: fontStyle as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: fontWeight as any,
    textDecoration,
    whiteSpace,
    isTruncated: (isTruncated: boolean) =>
      isTruncated ? truncate() : undefined,
  })}
`;

export interface TextProps {
  variant: ResponsiveValue<TextVariant>;
  align?: ResponsiveValue<TextAlignment>;
  color?: ResponsiveValue<Color>;
  isUnderlined?: boolean;
  isItalic?: boolean;
  isBold?: boolean;
  isTruncated?: boolean;
  className?: string;
  component?: React.ElementType;
  id?: string;
  href?: string;
  whiteSpace?: string;
}

export const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  align,
  color = 'neutral.100',
  component,
  isBold,
  isItalic,
  isUnderlined,
  isTruncated = false,
  whiteSpace,
  ...props
}) => (
  <P
    {...props}
    as={component}
    textAlign={align}
    textColor={color}
    textDecoration={isUnderlined ? 'underline' : undefined}
    fontWeight={
      isBold === true ? '600' : isBold === false ? 'normal' : undefined
    }
    fontStyle={isItalic ? 'italic' : undefined}
    whiteSpace={whiteSpace}
    isTruncated={isTruncated}
  />
);
