import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSaver } from '@spaceship-fspl/auth';
import { ActionLink, Columns, Stack, Text } from '@spaceship-fspl/components';
import { WebAppSuperLinkLogin } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperLinkLogin';
import { ErrorWithCode } from '@spaceship-fspl/helpers';
import {
  AmplifyAuthLoginErrorCodes,
  LinkAccountApiErrorCodes,
  useLinkAccount,
} from '@spaceship-fspl/super';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface SuperLinkLoginProps {
  location: {
    state: {
      email?: string;
    };
  };
}

export const SuperLinkLogin: React.FC<
  React.PropsWithChildren<RouteComponentProps<SuperLinkLoginProps>>
> = ({ location }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isVoyagerUser = useCanReadSaver();
  const linkAccount = useLinkAccount();
  const { popToast } = useNotifications();
  const resp = useQuery<WebAppSuperLinkLogin>(gql`
    query WebAppSuperLinkLogin {
      contact {
        id
        email
      }
    }
  `);

  const { control, handleSubmit, reset } = useForm<{
    email: string;
    password: string;
  }>({
    shouldFocusError: true,
    defaultValues: {
      email: location?.state?.email || resp?.data?.contact?.email,
    },
  });

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setIsLoading(true);
    try {
      const data = await linkAccount.linkSuperAccount(email, password);
      setIsLoading(false);
      if (data?.matches && data?.member) {
        // need to update the mismatched details
        navigate(Routes.SUPER_LINK_MISMATCH, {
          state: {
            email: {
              isMatch: data.matches.emailAddress,
              value: data.member.email,
            },
            phone: {
              isMatch: data.matches.phoneNumber,
              value: data.member.phoneMobile,
            },
            address: {
              isMatch: data.matches.address,
              value: data.member.addressResidential,
            },
          },
        });
      } else {
        navigate(Routes.SUPER_LINK_SUCCESS);
      }
    } catch (error) {
      setIsLoading(false);

      const castedError = error as ErrorWithCode | undefined;

      switch (castedError?.code) {
        case AmplifyAuthLoginErrorCodes.PASSWORD_RESET_REQUIRED:
          navigate(Routes.SUPER_LINK_RESET_PASSWORD, {
            state: {
              email,
              isResetRequired: true,
            },
          });
          return;

        case LinkAccountApiErrorCodes.MISMATCH_PERSONAL_DETAILS:
          navigate(Routes.SUPER_LINK_ERROR);
          return;

        default:
          addRumError({
            error,
            context: {
              reason: castedError?.code,
            },
          });

          if (
            castedError?.code ===
            LinkAccountApiErrorCodes.REFRESH_SPACESHIP_TOKEN
          ) {
            // linking was still successful so navigate to success screen
            popToast({
              level: 'warning',
              message:
                'Your Spaceship Super account was successfully linked, however there was a problem loading your data.',
            });
            navigate(Routes.SUPER_LINK_SUCCESS);
            return;
          } else if (
            castedError?.code === AmplifyAuthLoginErrorCodes.NOT_AUTHORIZED
          ) {
            popToast({
              level: 'error',
              message: 'You have entered an invalid email or password.',
            });
          } else {
            popToast({
              level: 'error',
              message: GENERIC_ERROR_MESSAGE,
            });
          }
          break;
      }
    }
  });

  useEffect(() => {
    if (resp?.data?.contact.email) {
      reset({
        email: resp?.data.contact.email,
      });
    }
  }, [reset, resp.data]);

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 3 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="xl">
              <PageHeading
                title="Log in to Spaceship Super to link your account"
                subtitle="Log in with your existing Spaceship Super password to get started."
              />

              <Stack spaceY="md">
                <ControllerInput
                  name="email"
                  control={control}
                  type="email"
                  placeholder="Email address"
                  rules={requiredValidation('Email')}
                />

                <Stack spaceY="xxs">
                  <ControllerPasswordInput
                    name="password"
                    placeholder="Password"
                    control={control}
                    rules={requiredValidation('Password')}
                  />

                  <Text variant={3}>
                    <RouterLink
                      to={Routes.SUPER_LINK_RESET_PASSWORD}
                      trackingProperties={{
                        name: 'super_link_login_page_forgot_password',
                      }}
                    >
                      <ActionLink color="neutral.080" component="span">
                        Forgot password?
                      </ActionLink>
                    </RouterLink>
                  </Text>
                </Stack>
              </Stack>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                isLoading={isLoading}
                trackingProperties={{
                  name: 'super_link_link_account',
                }}
              >
                Link account
              </PageFormContinueButton>

              {isVoyagerUser && (
                <PageFormCancelButton
                  isDisabled={isLoading}
                  trackingProperties={{
                    name: 'super_link_cancel',
                  }}
                  onClick={() => {
                    navigate(Routes.ACCOUNT_SUPER_DETAILS);
                  }}
                />
              )}
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};
