import {
  SharedDynamicConfigKeys,
  useNumber,
} from '@spaceship-fspl/dynamic-config';

import { ADD_INVESTMENT_LEARN_MORE, WITHDRAWAL_LEARN_MORE } from './constants';

export const useWithdrawalLearnMoreCopy = ({
  isMoneyDayOneEnabled,
}: {
  isMoneyDayOneEnabled: boolean;
}): Array<{
  label: string;
  value: WITHDRAWAL_LEARN_MORE;
}> => {
  const cutoffHour = useCutoffHourDisplay();

  return [
    {
      label: isMoneyDayOneEnabled
        ? `We receive your withdrawal request. If the request is made after ${cutoffHour}, the payment instruction will be processed the next business day.`
        : `We receive your withdrawal request. If the request is made after ${cutoffHour}, the request is processed on the next business day.`,
      value: WITHDRAWAL_LEARN_MORE.PROCESSING_TIME,
    },
    {
      label:
        'We exchange your units for funds at the unit price we strike after 11am (Sydney time) on the business day after we process your withdrawal request.\n\nAs such, depending on when your withdrawal request is processed, the value of your withdrawal may fall if the unit price goes down.',
      value: WITHDRAWAL_LEARN_MORE.STRIKE_TIME,
    },
    {
      label:
        'Once exchanged for funds, your funds leave Spaceship and should arrive in your bank account in 1-3 business days.\n\nPlease note that making a withdrawal can have tax consequences (as you may incur a capital gain or loss redeeming your units).',
      value: WITHDRAWAL_LEARN_MORE.TAX_CONSEQUENCES,
    },
  ];
};

export const useInvestmentLearnMoreCopy = ({
  cancelTransactionApplicationEnabled,
  isMoneyDayOneEnabled,
}: {
  cancelTransactionApplicationEnabled: boolean;
  isMoneyDayOneEnabled: boolean;
}): Array<{
  label: string;
  value: ADD_INVESTMENT_LEARN_MORE;
}> => {
  const cutoffHour = useCutoffHourDisplay();

  return [
    {
      label: isMoneyDayOneEnabled
        ? `We receive your withdrawal request. If the request is made after ${cutoffHour}, the payment instruction will be processed the next business day.`
        : `We receive your investment request. If the request is made after ${cutoffHour}, the direct debit instruction will be processed the next business day.`,
      value: ADD_INVESTMENT_LEARN_MORE.PROCESSING_TIME,
    },
    {
      label: isMoneyDayOneEnabled
        ? 'We’ll check your available money. If you don’t have enough money, we’ll debit the full amount from your linked bank account. It can take up to three business days for funds to clear from your bank.'
        : 'We will direct debit your funds from your nominated bank account. It can take up to three business days for these funds to clear.',
      value: ADD_INVESTMENT_LEARN_MORE.DIRECT_DEBIT,
    },
    {
      label:
        'Once we receive the necessary funds from your bank, we’ll generally issue your units at the unit price we strike after 11am on the next business day in NSW.',
      value: ADD_INVESTMENT_LEARN_MORE.ISSUE_TIME,
    },
    {
      label: 'Your units will appear in your account!',
      value: ADD_INVESTMENT_LEARN_MORE.UNITS,
    },
    ...((cancelTransactionApplicationEnabled && [
      {
        label: `To cancel an investment, you’ll need to make a cancellation request. If your investment transaction is made before 4pm (Sydney time), you have until then to cancel. If your investment transaction is made after this time, you can cancel up to 4pm (Sydney time) the next business day.`,
        value: ADD_INVESTMENT_LEARN_MORE.CANCEL,
      },
    ]) ||
      []),
  ];
};

const useCutoffHourDisplay = (): string => {
  const cutoffHourConfig = useNumber(
    SharedDynamicConfigKeys.DAILY_ORDER_CUTOFF_HOUR,
    17,
  );

  // convert 24hr => 12hr am/pm display (i.e 17 => 5pm)
  const hour = cutoffHourConfig % 12 || 12;
  const ampm = cutoffHourConfig < 12 ? 'am' : 'pm';
  return `${hour}${ampm}`;
};
