import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Heading,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  CreateBasiqConnectionInput,
  useCreateBasiqConnection,
  useCreateBoostRecipe,
  useUpdateBoostRecipe,
} from '@spaceship-fspl/graphql';
import {
  WebAppBasiqLoginDetails,
  WebAppBasiqLoginDetailsVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppBasiqLoginDetails';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import {
  CenterPageContainer,
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React from 'react';
import { useForm } from 'react-hook-form';

import { AboutLinkingTrackingBank } from './components/about-linking-tracking-bank';
import { ContentLayout } from './components/layout';
import { BoostRecipeCreateParams, BoostRecipeEditParams } from './types';

export const BoostsBasiqLogin: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<{
      location: {
        state: { institutionId: string } & (
          | { type: 'add-source' }
          | ({ type: 'add-source-and-edit-recipe' } & Omit<
              BoostRecipeEditParams,
              'sourceId'
            >)
          | ({ type: 'add-source-and-create-recipe' } & BoostRecipeCreateParams)
        );
      };
    }>
  >
> = (props) => {
  const state = props.location?.state;
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [updateBoostRecipe, updateBoostRecipeMeta] = useUpdateBoostRecipe();
  const [createBoostRecipe, createBoostRecipeMeta] = useCreateBoostRecipe();
  const [createBasiqConnection, createBasiqConnectionMeta] =
    useCreateBasiqConnection();

  const form = useForm<
    Partial<{
      username: string;
      secondaryUsername: string;
      password: string;
      securityCode: string;
    }>
  >();

  const resp = useQuery<
    WebAppBasiqLoginDetails,
    WebAppBasiqLoginDetailsVariables
  >(
    gql`
      query WebAppBasiqLoginDetails(
        $institutionId: ID!
        $institutionIdProvided: Boolean!
      ) {
        basiqInstitution(id: $institutionId)
          @include(if: $institutionIdProvided) {
          id
          name
          usernameCaption
          passwordCaption
          secondaryUsernameCaption
          securityCodeCaption
        }
      }
    `,
    {
      variables: {
        institutionId: state?.institutionId ?? '',
        institutionIdProvided: !!state?.institutionId,
      },
    },
  );

  const institution = resp.data?.basiqInstitution;
  const bankName = institution?.name ?? 'bank';

  const handleSubmit = form.handleSubmit(async (fields) => {
    if (!state?.institutionId) {
      return addRumError({
        error: new Error('handleSubmit called without institutionId state'),
      });
    }

    if (!fields.password) {
      return addRumError({
        error: new Error('handleSubmit called without password'),
      });
    }

    if (!fields.username) {
      return addRumError({
        error: new Error('handleSubmit called without username'),
      });
    }

    const createConnInput: CreateBasiqConnectionInput = {
      institutionId: state.institutionId,
      password: fields.password,
      username: fields.username,
      secondaryUsername: fields.secondaryUsername,
      securityCode: fields.securityCode,
    };

    switch (state?.type) {
      case 'add-source':
        try {
          await createBasiqConnection({
            variables: { input: createConnInput },
          });
          navigate(Routes.BOOSTS_BASIQ_SUCCESS, {
            state: {
              type: state?.type,
            },
          });
        } catch {
          navigate(Routes.BOOSTS_BASIQ_ERROR, {
            state: {
              type: state.type,
            },
          });
        }

        break;

      case 'add-source-and-create-recipe':
        {
          try {
            const createConnResp = await createBasiqConnection({
              variables: { input: createConnInput },
            });

            const sourceId =
              createConnResp.data?.createBasiqConnection?.connection?.id;

            if (!sourceId) {
              throw Error('no source id returned');
            }

            await createBoostRecipe({
              variables: {
                input: {
                  templateId: state.templateId,
                  saverProductInstanceId: state.saverProductInstanceId,
                  parameters: state.parameters,
                  sourceId,
                },
              },
            });
            navigate(Routes.BOOSTS_BASIQ_SUCCESS, {
              state: {
                type: state?.type,
              },
            });
          } catch (error) {
            addRumError({ error });
            navigate(Routes.BOOSTS_BASIQ_ERROR, {
              state: {
                type: state.type,
                templateId: state.templateId,
                saverProductInstanceId: state.saverProductInstanceId,
                parameters: state.parameters,
              },
            });
          }
        }
        break;

      case 'add-source-and-edit-recipe':
        {
          try {
            const createConnResp = await createBasiqConnection({
              variables: { input: createConnInput },
            });

            const sourceId =
              createConnResp.data?.createBasiqConnection?.connection?.id;

            if (!sourceId) {
              throw Error('no source id returned');
            }
            await updateBoostRecipe({
              variables: {
                input: {
                  id: state.recipeId,
                  parameters: state.parameters,
                  sourceId,
                },
              },
            });
            navigate(Routes.BOOSTS_DASHBOARD_SETTINGS);
            notifications.popToast({
              level: 'success',
              message: 'We’ve updated your linked tracking account',
            });
          } catch (error) {
            addRumError({ error });
            navigate(Routes.BOOSTS_BASIQ_ERROR, {
              state: {
                type: state.type,
                recipeId: state.recipeId,
                parameters: state.parameters,
              },
            });
          }
        }
        break;

      default:
        addRumError({ error: 'handleSubmit called with unexpected type' });
    }
  });

  if (
    createBasiqConnectionMeta.loading ||
    createBoostRecipeMeta.loading ||
    updateBoostRecipeMeta.loading
  ) {
    return (
      <CenterPageContainer>
        <Stack spaceY={{ xs: 'md', md: 'lg' }} alignX="center">
          <Spinner size="lg" />

          <Stack spaceY={{ xs: 'xs', md: 'md' }} alignX="center">
            <Heading variant={3} component="h1" align="center">
              We are now linking your tracking bank...
            </Heading>
            <Text variant={2}>Sit tight — this can take up to 30 seconds.</Text>
          </Stack>
        </Stack>
      </CenterPageContainer>
    );
  }

  return (
    <PageContainer>
      <Stack spaceY={{ xs: 'lg', md: 'xxl' }}>
        <ContentLayout>
          <Stack spaceY={{ xs: 'sm', md: 'lg' }}>
            <PageHeading title={`Link your ${bankName} account`} />

            {resp.loading ? (
              <Box
                height={400}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="lg" />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Stack spaceY={{ xs: 'lg', md: 'md' }}>
                  <Stack spaceY="md">
                    {institution?.usernameCaption && (
                      <ControllerInput
                        name="username"
                        control={form.control}
                        placeholder={institution.usernameCaption}
                        type="text"
                        rules={{
                          required: `${institution.usernameCaption} is required`,
                        }}
                      />
                    )}

                    {institution?.secondaryUsernameCaption && (
                      <ControllerInput
                        name="secondaryUsername"
                        control={form.control}
                        placeholder={institution.secondaryUsernameCaption}
                        type="text"
                        rules={{
                          required: `${institution.secondaryUsernameCaption} is required`,
                        }}
                      />
                    )}

                    {institution?.passwordCaption && (
                      <ControllerPasswordInput
                        name="password"
                        control={form.control}
                        placeholder={institution.passwordCaption}
                        rules={{
                          required: `${institution.passwordCaption} is required`,
                        }}
                      />
                    )}

                    {institution?.securityCodeCaption && (
                      <ControllerPasswordInput
                        name="securityCode"
                        control={form.control}
                        placeholder={institution.securityCodeCaption}
                        rules={
                          institution.securityCodeCaption.match(
                            /if available|optional/g,
                          )?.length === 0
                            ? {
                                required: `${institution.securityCodeCaption} is required`,
                              }
                            : undefined
                        }
                      />
                    )}
                  </Stack>

                  <Text variant={3} component="div">
                    By logging in, you agree with our{' '}
                    <RouterLink
                      to={Routes.BOOSTS_TERMS_AND_CONDITIONS}
                      trackingProperties={{ name: 'basiq_login_t_and_c' }}
                    >
                      <TextLink color="indigo.070" component="span">
                        Boosts Terms and Conditions
                      </TextLink>
                    </RouterLink>{' '}
                    including:
                    <ul>
                      <li>How boost investments work</li>
                      <li>Digital data capture consent; and</li>
                      <li>How boosts interact with the ePayments Code</li>
                    </ul>
                  </Text>
                </Stack>

                <PageFormButtonContainer>
                  <PageFormContinueButton
                    trackingProperties={{
                      name: 'basiq_login_login_in_and_link_tracking_bank',
                    }}
                  >
                    Log in and link tracking bank
                  </PageFormContinueButton>
                  <PageFormGoBackButton
                    trackingProperties={{ name: 'basiq_login_go_back' }}
                    onClick={() => navigate(-1)}
                  />
                </PageFormButtonContainer>
              </form>
            )}
          </Stack>
        </ContentLayout>

        {!resp.loading && <AboutLinkingTrackingBank />}
      </Stack>
    </PageContainer>
  );
};
