import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  useCanReadSaver,
  useCanReadSuper,
  useIsUserWithNoProduct,
} from '@spaceship-fspl/auth';
import { Box, Spinner } from '@spaceship-fspl/components';
import { OnboardingStatus } from '@spaceship-fspl/graphql/src';
import { WebAppResumeOnboarding } from '@spaceship-fspl/graphql/src/__generated__/WebAppResumeOnboarding';
import { getMostRecentlyTmdApprovedPortfolio } from '@spaceship-fspl/voyager';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import React, { useEffect } from 'react';

import { Routes } from './routes';

export const ResumeOnboarding: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const canReadSaver = useCanReadSaver();
  const canReadSuper = useCanReadSuper();
  const isUserWithNoProduct = useIsUserWithNoProduct();
  const isSuperOnly = canReadSuper && !canReadSaver;
  const { onPortfolioSelect, saverTMDDetails } = usePortfolioSelection();

  const resp = useQuery<WebAppResumeOnboarding>(gql`
    query WebAppResumeOnboarding {
      contact {
        id
        onboardingStatus
        phoneNumberVerified
      }
    }
  `);

  const onboardingStatus = resp.data?.contact.onboardingStatus;
  const isLoading = resp.loading || saverTMDDetails.loading;
  const phoneNumberVerified = resp.data?.contact.phoneNumberVerified;

  const latestTmdApprovedPortfolio = getMostRecentlyTmdApprovedPortfolio(
    saverTMDDetails.data?.contact,
  );

  useEffect(() => {
    if (!isLoading) {
      if (!phoneNumberVerified) {
        navigate(Routes.SIGNUP_MFA_REGISTRATION);
      } else if (isUserWithNoProduct) {
        if (
          onboardingStatus === OnboardingStatus.VOYAGER &&
          latestTmdApprovedPortfolio
        ) {
          onPortfolioSelect(latestTmdApprovedPortfolio);
          navigate(Routes.VOYAGER_ONBOARDING_TMD_SUCCESS);
        } else {
          navigate(Routes.SIGNUP_PRODUCT);
        }
      } else if (isSuperOnly) {
        navigate(Routes.SUPER_DASHBOARD);
      } else {
        navigate(Routes.VOYAGER_DASHBOARD);
      }
    }
  }, [
    isSuperOnly,
    isLoading,
    isUserWithNoProduct,
    navigate,
    onboardingStatus,
    phoneNumberVerified,
    latestTmdApprovedPortfolio,
    onPortfolioSelect,
  ]);

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  );
};
