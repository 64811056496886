import { gql } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { Box, Pill } from '@spaceship-fspl/components';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { FeatherArrowRightIcon } from '@spaceship-fspl/icons-web';
import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from 'components/account-cards/components';
import { ResourceItemProps, ResourceList } from 'components/resource-list';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';

export const voyagerOtherActionsCardFragment = gql`
  fragment webAppVoyagerOtherActionsCard on Contact {
    id
    account {
      id
      saverBoostRecipes {
        id
      }
    }
  }
`;

export const VoyagerOtherActionsCard: React.FC<
  React.PropsWithChildren<{
    hasBoosts: boolean;
  }>
> = ({ hasBoosts }) => {
  const navigate = useNavigate();

  const otherActions = useMemo(() => {
    const items: Array<ResourceItemProps> = [
      {
        label: 'Refer a friend',
        onClick: () => navigate(Routes.VOYAGER_REFERRALS),
      },
      {
        label: 'Boosts',
        onClick: () =>
          navigate(
            hasBoosts ? Routes.BOOSTS_DASHBOARD_HOME : Routes.BOOSTS_INTRO,
          ),
      },
      {
        label: 'Add a new portfolio',
        icon: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box marginRight="xxs">
              <Pill variant="success">NEW</Pill>
            </Box>
            <FeatherArrowRightIcon color="neutral.080" />
          </Box>
        ),
        onClick: () => navigate(Routes.PORTFOLIO_ADD),
      },
      {
        label: 'Transaction history',
        onClick: () => navigate(Routes.VOYAGER_TRANSACTIONS),
      },
      {
        label: 'Important documents',
        link: InternalRoutes.IMPORTANT_DOCUMENTS,
      },
    ];

    return items;
  }, [hasBoosts, navigate]);

  return (
    <AccountCard>
      <AccountCardHeading title="Other actions" />

      <AccountCardContent
        padding={{
          paddingX: { xs: 'md', md: 'lg' },
          paddingY: { xs: 'xs', md: 'sm' },
        }}
      >
        <ResourceList isBold={true} resources={otherActions} />
      </AccountCardContent>
    </AccountCard>
  );
};
