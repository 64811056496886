import { useGoogleMapsServices } from 'contexts/googlemaps';
import { useEffect, useState } from 'react';

export interface GetAddressDetailsByPlaceId {
  result: google.maps.places.PlaceResult;
  status: google.maps.places.PlacesServiceStatus;
}

export const useGetPlaceDetails = (
  placeId?: string,
): {
  data: GetAddressDetailsByPlaceId | undefined;
  isError: boolean;
  isLoading: boolean;
} => {
  const { placesService, sessionToken } = useGoogleMapsServices();

  const [data, setData] = useState<GetAddressDetailsByPlaceId | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getPlaceDetails = (
      placeId: string,
    ): Promise<GetAddressDetailsByPlaceId> => {
      return new Promise<GetAddressDetailsByPlaceId>((resolve, reject) => {
        if (!placesService?.getDetails) {
          return reject(
            'google.maps.places.PlacesService: Google script not loaded',
          );
        }
        if (!sessionToken) {
          return reject(
            'google.maps.places.AutocompleteSessionToken: Google script not loaded',
          );
        }

        try {
          placesService.getDetails(
            {
              placeId,
              fields: ['address_components'],
              sessionToken,
            },
            (result, status) => {
              if (status === 'OK') {
                return resolve({ result, status });
              } else {
                reject(
                  new Error(
                    `Failed to get place details with placeId of ${placeId}. Status: ${status} - ${result} `,
                  ),
                );
              }
            },
          );
        } catch (error) {
          reject(error);
        }
      });
    };

    const getGooglePlaceDetailsByPlaceId = async (
      placeId: string,
    ): Promise<void> => {
      setIsLoading(true);
      try {
        const data = await getPlaceDetails(placeId);
        setData(data);
        setIsError(false);
      } catch {
        setIsError(true);
        setData(undefined);
      }
      setIsLoading(false);
    };

    if (placeId) {
      getGooglePlaceDetailsByPlaceId(placeId);
    }
    // @see https://github.com/spaceship-fspl/ui.spaceship/pull/988/files#r579974622
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  return { isLoading, isError, data };
};
