import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Card,
  DynamicIcon,
  Heading,
  Inline,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  SaverTransactionLimits_SaverProductInstanceFragment,
  useSaverPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { WebAppBoostsRecipeSelect } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsRecipeSelect';
import { FeatherChevronRightIcon } from '@spaceship-fspl/icons-web';
import {
  borderWidths,
  rgba,
  shadows,
  transition,
} from '@spaceship-fspl/styles';
import { Error } from 'components/layouts/error';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

import { HowBoostsWork } from './components/how-boosts-work';
import { ContentLayout } from './components/layout';

export const BoostsRecipeSelect: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const notification = useNotifications();
  const navigate = useNavigate();
  const flagsData = useSaverPortfolioFlags();

  const resp = useQuery<WebAppBoostsRecipeSelect>(
    gql`
      query WebAppBoostsRecipeSelect {
        boostRecipeTemplates {
          id
          name
          descriptionPreview
          iconName
          disclaimer
        }
        contact {
          id
          account {
            id
            saverProductInstances {
              id
              ...SaverTransactionLimits_SaverProductInstanceFragment
            }
          }
        }
      }
      ${SaverTransactionLimits_SaverProductInstanceFragment}
    `,
    {
      onError: () => {
        notification.popToast({
          level: 'error',
          message: 'Oops, there was a problem loading boost recipes.',
        });
      },
    },
  );

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;

  const investmentPlanAvailable =
    resp?.data?.contact?.account?.saverProductInstances?.reduce(
      (acc, productInstance) => {
        return (
          acc && productInstance.transactionLimits.createInvestmentPlanAvailable
        );
      },
      true,
    ) ?? true;

  const transactionLimitNotAvailableMessage =
    resp?.data?.contact?.account?.saverProductInstances?.find(
      (productInstance) =>
        productInstance.transactionLimits.directDebitBuyNotAvailableMessage !=
        null,
    )?.transactionLimits?.directDebitBuyNotAvailableMessage ?? null;

  const createBoostAvailable =
    (saverPortfolioFlags?.createBoostAvailable && investmentPlanAvailable) ??
    true;

  if (!createBoostAvailable) {
    return (
      <Error
        title="Set up your boosts"
        subtitle={
          transactionLimitNotAvailableMessage ??
          saverPortfolioFlags?.createBoostNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        showContactSupportButton={true}
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'boost_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    <PageContainer>
      <Stack spaceY={{ xs: 'md', md: 'xxl' }}>
        <ContentLayout>
          <Stack spaceY={{ xs: 'sm', md: 'lg' }}>
            <PageHeading title="Set up your boosts" />

            {resp.loading ? (
              <Box
                height={400}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="lg" />
              </Box>
            ) : (
              <Stack spaceY="sm">
                {resp.data?.boostRecipeTemplates?.map((template) => (
                  <RouterLink
                    key={template.id}
                    to={Routes.BOOSTS_RECIPE_SETUP}
                    state={{
                      type: 'create-recipe',
                      templateId: template.id,
                    }}
                    trackingProperties={{
                      name: 'voyager_boost_recipe_select',
                    }}
                  >
                    <StyledCard>
                      <Stack spaceY="xxs">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Inline spaceX="xxs" alignY="center">
                            <DynamicIcon
                              name={template.iconName}
                              color="neutral.100"
                              size="md"
                            />
                            <Heading variant={5} isBold={true}>
                              {template.name}
                            </Heading>
                          </Inline>
                          <FeatherChevronRightIcon
                            color="indigo.070"
                            size="md"
                          />
                        </Box>

                        <Text variant={3}>{template.descriptionPreview}</Text>

                        {template.disclaimer && (
                          <Text variant={4} color="neutral.080">
                            {template.disclaimer}
                          </Text>
                        )}
                      </Stack>
                    </StyledCard>
                  </RouterLink>
                ))}
              </Stack>
            )}
          </Stack>

          {!resp.loading && (
            <PageFormButtonContainer>
              <PageFormGoBackButton
                trackingProperties={{ name: 'boosts_recipe_select_go_back' }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </PageFormButtonContainer>
          )}
        </ContentLayout>

        {!resp.loading && <HowBoostsWork />}
      </Stack>
    </PageContainer>
  );
};

const StyledCard = styled(Card).attrs({
  padding: 'md',
  borderRadius: 'sm',
})`
  box-shadow:
    inset 0 0 0 ${borderWidths.md} ${rgba('indigo.070', 0)},
    ${shadows.sm};
  ${transition}

  :hover, :focus {
    box-shadow:
      inset 0 0 0 ${borderWidths.md} ${rgba('indigo.070', 1)},
      ${shadows.sm};
  }
`;
