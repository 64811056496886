import { RouteComponentProps } from '@reach/router';
import { Box, Columns, Heading } from '@spaceship-fspl/components';
import { fontSize } from '@spaceship-fspl/styles';
import { CenterPageContainer } from 'components/layouts/page';
import React from 'react';
import styled from 'styled-components';

export const PageNotFound: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => (
  <Box backgroundColor="indigo.100">
    <CenterPageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3 }}>
          <StyledTitle
            variant={1}
            component="h1"
            color="indigo.040"
            align="center"
          >
            404
          </StyledTitle>
          <Heading
            variant={{ xs: 2, md: 1 }}
            color="indigo.040"
            align="center"
            isBold={true}
          >
            Oops... looks like the page you requested can&apos;t be found.
          </Heading>
        </Columns.Column>
      </Columns>
    </CenterPageContainer>
  </Box>
);

const StyledTitle = styled(Heading)`
  ${fontSize({ xs: '130px', md: '200px', lg: '300px' })}
  line-height: 1em;
`;
