import React from 'react';

import { Box } from '../box';

export const Card: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.ComponentProps<typeof Box>>
  >
> = ({ children, ...props }) => (
  <Box
    width="100%"
    borderRadius="md"
    boxShadow="md"
    backgroundColor="neutral.000"
    position="relative"
    {...props}
  >
    {children}
  </Box>
);
