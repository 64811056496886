import { Stack, Visible } from '@spaceship-fspl/components';
import { FeatherUploadIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  getColor,
  padding,
  text,
  transition,
} from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css } from 'styled-components';

interface FileInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  label?: string;
  name: string;
  isDisabled?: boolean;
  onChange: (value: File[]) => void;
}

export const FileInput: React.FC<React.PropsWithChildren<FileInputProps>> = ({
  label,
  isDisabled,
  onChange,
  ...props
}) => {
  return (
    <StyledLabel $isDisabled={!!isDisabled}>
      <Visible isHidden={true}>
        <input
          {...props}
          type="file"
          disabled={isDisabled}
          onChange={(e) => {
            onChange(Array.from(e.target.files || []));
          }}
        />
      </Visible>

      <Stack alignX="center" spaceY="xxxs">
        <FeatherUploadIcon size="md" />
        <span>{label || 'Upload file'}</span>
      </Stack>
    </StyledLabel>
  );
};

const StyledLabel = styled.label<{ $isDisabled: boolean }>`
  ${text({ variant: 2 })}
  ${backgroundColor('neutral.030')}
  ${borderWidth('md')}
  ${borderColor('indigo.070')}
  ${borderRadius('xxs')}
  ${padding('sm')}
  ${color('indigo.070')}
  ${transition}
  box-sizing: border-box;
  display: inline-block;
  border-style: dashed;
  font-weight: 600;
  width: 100%;
  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled
      ? `
    cursor: not-allowed;
    opacity: 0.4;
  `
      : css`
          :focus,
          :hover {
            box-shadow: 0 0 0 3px ${getColor('indigo.015')};
          }
        `}
`;
