import {
  Accordion,
  AccordionProps,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { FeatherLockIcon } from '@spaceship-fspl/icons-web';
import * as React from 'react';

export type SafeAndEasyBankLinkingAccordionProps = Pick<
  AccordionProps,
  'open' | 'onToggle'
>;

export const SafeAndEasyBankLinkingAccordion: React.FC<
  React.PropsWithChildren<SafeAndEasyBankLinkingAccordionProps>
> = ({ children, open, onToggle }) => (
  <Accordion
    variant="info"
    chevronPosition="right"
    chevronIconProps={{ size: 'lg' }}
    title={
      <Stack spaceY="xxs">
        <Inline spaceX="xxs" alignY="baseline">
          <FeatherLockIcon size="md" color="indigo.070" strokeWidth={2} />
          <Heading variant={5}>Safe and easy</Heading>
        </Inline>

        <Text variant={2}>
          Spaceship uses 256-bit encryption and never stores your bank login
          details.
        </Text>
      </Stack>
    }
    onToggle={onToggle}
    open={open}
  >
    {children}
  </Accordion>
);

export const SafeAndEasyBankLinkingContent: React.FC<
  React.PropsWithChildren
> = () => (
  <Stack spaceY="md">
    <Stack spaceY="md">
      <Heading variant={5} isBold={true}>
        Why do I need to link a bank account?
      </Heading>
      <Text variant={2}>
        Your linked bank account will be used for any investments and
        withdrawals you make with Spaceship
      </Text>
    </Stack>

    <Stack spaceY="md">
      <Heading variant={5} isBold={true}>
        How do I link my bank account?
      </Heading>
      <Text variant={2}>
        The easiest way to link your bank account is to provide your bank login
        information within the app. We use this information to establish a
        secure and automatic connection with your bank and verify your bank
        account.
      </Text>
    </Stack>

    <Stack spaceY="md">
      <Heading variant={5} isBold={true}>
        How is my bank account login information protected and stored?
      </Heading>
      <Text variant={2}>
        Your personal information is encrypted with 256-bit encryption and
        secured by 2048-bit keys. Your login credentials are never viewed or
        stored by us. They are retrieved one time, and once they have been
        encrypted and passed to the bank for verification, they are securely
        discarded.
      </Text>
    </Stack>

    <Stack spaceY="md">
      <Heading variant={5} isBold={true}>
        Is there another way to link my bank account?
      </Heading>
      <Text variant={2}>
        Yes, you can choose to link your bank account by providing your BSB and
        account number.
      </Text>
    </Stack>
  </Stack>
);
