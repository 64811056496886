import { gql, useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionProps,
  Box,
  Divider,
  Heading,
  Inline,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { SaverPortfolio } from '@spaceship-fspl/graphql';
import { WebAppCompanyList } from '@spaceship-fspl/graphql/src/__generated__/WebAppCompanyList';
import { capitalizeWords, formatPercentage } from '@spaceship-fspl/helpers';
import {
  FeatherChevronLeftIcon,
  FeatherChevronRightIcon,
  FeatherListIcon,
  FeatherSearchIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  fontWeight,
  getSpace,
  match,
  padding,
  paddingX,
  paddingY,
  rgba,
  text,
  transition,
} from '@spaceship-fspl/styles';
import { ControllerInput } from 'components/controller-input';
import { PerformanceIndicator } from 'components/performance-indicator';
import { useAuthenticatedNav } from 'contexts/authenticated-nav';
import { Routes } from 'pages/routes';
import React, { Fragment, memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

export const CompanyList: React.FC<
  React.PropsWithChildren<{
    productId: string;
    portfolio: SaverPortfolio;
    currentInstrumentId?: string;
  }>
> = memo(({ productId, portfolio, currentInstrumentId }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [countryFilters, setCountryFilters] = useState<string[]>([]);
  const [sectorFilters, setSectorFilters] = useState<string[]>([]);
  const { selectVoyagerProduct } = useAuthenticatedNav();

  const form = useForm<{ company_search: string }>({
    defaultValues: {
      company_search: '',
    },
  });

  const resp = useQuery<WebAppCompanyList>(
    gql`
      query WebAppCompanyList($portfolio: SaverPortfolio!) {
        instruments(portfolios: [$portfolio]) {
          id
          name
          country
          sector
          ytdPercentageChange
        }
        saverPortfolioInformation(portfolio: $portfolio) {
          id
          title
        }
      }
    `,
    {
      skip: !portfolio,
      variables: {
        portfolio,
      },
    },
  );

  const instruments = useMemo(
    () =>
      resp.data?.instruments?.slice().sort((a, b) => {
        if (!a.name || !b.name) {
          return 0;
        }
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }) ?? [],
    [resp.data?.instruments],
  );

  const countries = instruments
    .map(({ country }) => capitalizeWords(country))
    .filter(onlyUnique)
    .sort();

  const sectors = instruments
    .map(({ sector }) => capitalizeWords(sector))
    .filter(onlyUnique)
    .sort();

  const portfolioTitle = resp.data?.saverPortfolioInformation?.title;
  const companySearch = form.watch().company_search?.toLowerCase();

  const companies = useMemo(() => {
    let filteredCompanies =
      (companySearch
        ? instruments?.filter(({ name }) =>
            name.toLowerCase().includes(companySearch),
          )
        : instruments) ?? [];

    if (countryFilters.length) {
      filteredCompanies = filteredCompanies.filter(({ country }) =>
        countryFilters.includes(capitalizeWords(country)),
      );
    }
    if (sectorFilters.length) {
      filteredCompanies = filteredCompanies.filter(({ sector }) =>
        sectorFilters.includes(capitalizeWords(sector)),
      );
    }
    return filteredCompanies;
  }, [instruments, countryFilters, sectorFilters, companySearch]);

  const currentIndex = useMemo(
    () => instruments.findIndex(({ id }) => id === currentInstrumentId),
    [currentInstrumentId, instruments],
  );

  const prevCompanyId =
    currentIndex && instruments[Number(currentIndex) - 1]?.id;

  const nextCompanyId =
    currentIndex !== undefined &&
    currentIndex >= 0 &&
    instruments[Number(currentIndex) + 1]?.id;

  return (
    <>
      <StyledControlsContainerBox>
        <Box
          display="flex"
          flexDirection={{ md: 'column' }}
          flex={{ xs: 1, md: 'none' }}
          alignItems="center"
        >
          <Box padding="sm">
            <StyledCircleButton
              onClick={() => setIsCollapsed(false)}
              isActive={false}
            >
              <FeatherListIcon size="md" color="neutral.000" />
            </StyledCircleButton>
          </Box>

          <StyledToggleTextContainer>
            <Text variant={4} color="neutral.000" isBold={true}>
              {portfolioTitle}
            </Text>
          </StyledToggleTextContainer>
        </Box>

        <Box padding="sm">
          <Inline spaceX="xxs">
            <StyledCircleButton
              isDisabled={!prevCompanyId}
              onClick={async () => {
                if (prevCompanyId) {
                  selectVoyagerProduct(productId, {
                    route: Routes.VOYAGER_PORTFOLIO,
                    params: prevCompanyId,
                  });
                }
              }}
            >
              <FeatherChevronLeftIcon size="md" color="neutral.000" />
            </StyledCircleButton>
            <StyledCircleButton
              isDisabled={!nextCompanyId}
              onClick={async () => {
                if (nextCompanyId) {
                  selectVoyagerProduct(productId, {
                    route: Routes.VOYAGER_PORTFOLIO,
                    params: nextCompanyId,
                  });
                }
              }}
            >
              <FeatherChevronRightIcon size="md" color="neutral.000" />
            </StyledCircleButton>
          </Inline>
        </Box>
      </StyledControlsContainerBox>

      <StyledCompanyListCollapsibleBox isCollapsed={isCollapsed}>
        <StyledToggleButtonContainerBox>
          <StyledCircleButton
            onClick={() => setIsCollapsed(true)}
            isActive={true}
          >
            <FeatherListIcon size="md" color="neutral.000" />
          </StyledCircleButton>
        </StyledToggleButtonContainerBox>

        <StyledCompanyListContentBox>
          <Box padding="sm">
            <Heading variant={5} component="h4">
              {portfolioTitle}
            </Heading>
            <Text variant={4} color="neutral.080">
              All figures are YTD
            </Text>

            <Box position="relative" marginTop="md">
              <ControllerInput
                name="company_search"
                control={form.control}
                type="text"
                placeholder="Search companies"
              />
              <StyledSearchIconBox>
                <FeatherSearchIcon color="neutral.080" size="md" />
              </StyledSearchIconBox>
            </Box>
          </Box>

          <Section title="Countries">
            <StyledFiltersListContainerBox>
              <Box marginRight="xs" marginBottom="xs">
                <StyledFilterButton
                  onClick={(): void => setCountryFilters([])}
                  isSelected={!countryFilters.length}
                >
                  All
                </StyledFilterButton>
              </Box>
              {countries?.map((country) => (
                <Box marginRight="xs" marginBottom="xs" key={country}>
                  <StyledFilterButton
                    onClick={(): void =>
                      setCountryFilters(
                        countryFilters.includes(country)
                          ? countryFilters.filter((c) => c !== country)
                          : [...countryFilters, country],
                      )
                    }
                    isSelected={countryFilters.includes(country)}
                  >
                    {country}
                  </StyledFilterButton>
                </Box>
              ))}
            </StyledFiltersListContainerBox>
          </Section>

          <Section title="Sectors">
            <StyledFiltersListContainerBox>
              <Box marginRight="xs" marginBottom="xs">
                <StyledFilterButton
                  onClick={(): void => setSectorFilters([])}
                  isSelected={!sectorFilters.length}
                >
                  All
                </StyledFilterButton>
              </Box>
              {sectors?.map((sector) => (
                <Box marginRight="xs" marginBottom="xs" key={sector}>
                  <StyledFilterButton
                    onClick={(): void =>
                      setSectorFilters(
                        sectorFilters.includes(sector)
                          ? sectorFilters.filter((c) => c !== sector)
                          : [...sectorFilters, sector],
                      )
                    }
                    isSelected={sectorFilters.includes(sector)}
                  >
                    {sector}
                  </StyledFilterButton>
                </Box>
              ))}
            </StyledFiltersListContainerBox>
          </Section>

          <Section title="Companies" open={true}>
            {companies.map((i, index) => {
              const isActiveCompany = currentIndex === index;
              return (
                <Fragment key={i?.id || ''}>
                  <StyledCompanyButton
                    isActive={isActiveCompany}
                    onClick={() => {
                      selectVoyagerProduct(productId, {
                        route: Routes.VOYAGER_PORTFOLIO,
                        params: i.id,
                      });
                      setIsCollapsed(true);
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      paddingX="sm"
                      paddingY="xs"
                    >
                      <Box flex={1}>
                        <StyledCompanyButtonText
                          variant={3}
                          isBold={isActiveCompany}
                          align="left"
                        >
                          {i?.name}
                        </StyledCompanyButtonText>
                      </Box>

                      <Inline spaceX="xxxs">
                        <PerformanceIndicator
                          direction={
                            parseFloat(i.ytdPercentageChange) < 0
                              ? 'down'
                              : 'up'
                          }
                          size="xxs"
                        />
                        <StyledCompanyButtonText
                          variant={3}
                          isBold={isActiveCompany}
                        >
                          {formatPercentage(
                            parseFloat(i.ytdPercentageChange),
                            2,
                          )}
                        </StyledCompanyButtonText>
                      </Inline>
                    </Box>
                  </StyledCompanyButton>

                  <Divider color="neutral.030" size="sm" />
                </Fragment>
              );
            })}
          </Section>
        </StyledCompanyListContentBox>
      </StyledCompanyListCollapsibleBox>
    </>
  );
});

CompanyList.displayName = 'CompanyList';

interface SectionProps extends Omit<AccordionProps, 'variant'> {
  title: React.ReactNode;
}

const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  title,
  children,
  ...props
}) => (
  <>
    <Divider color="neutral.050" size="md" />
    <Accordion
      variant="default"
      title={
        <Box flex={1} paddingLeft="xs">
          <Heading variant={5} align="left" component="h4">
            {title}
          </Heading>
        </Box>
      }
      chevronPosition="left"
      paddingX="xs"
      {...props}
    >
      <Divider color="neutral.050" size="md" />
      {children}
    </Accordion>
  </>
);

function onlyUnique<T>(value: T, index: number, self: Array<T>): boolean {
  return self.indexOf(value) === index;
}

const StyledControlsContainerBox = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
})`
  pointer-events: none;
`;

const StyledCircleButton = styled(UnstyledButton)<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  ${borderRadius('md')}
  ${padding('xs')}
  background-color: ${rgba('indigo.060', 0.4)};
  cursor: pointer;
  pointer-events: auto;
  ${transition}

  ${({ isActive, isDisabled }) => {
    if (isActive) {
      return backgroundColor('indigo.060');
    }

    return isDisabled
      ? `
      cursor: default;
      opacity: 0.2;
    `
      : '';
  }}

  :hover {
    ${({ isActive, isDisabled }) => {
      if (isActive) {
        return backgroundColor('indigo.080');
      }

      return !isDisabled ? backgroundColor('indigo.060') : '';
    }}
  }
`;

const StyledToggleButtonContainerBox = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 'sm',
})`
  transform: translateX(50%);
  z-index: 1;
`;

const StyledToggleTextContainer = styled.div`
  opacity: 0.4;

  ${match('md')`
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  `}
`;

const StyledFilterButton = styled(UnstyledButton)<{ isSelected: boolean }>`
  ${borderRadius('xxxs')}
  ${borderWidth('md')}
  ${text({ variant: 3 })}
  ${paddingY('xxxs')}
  ${paddingX('xs')}
  border-style: solid;

  :hover {
    background-color: ${rgba('indigo.015', 0.4)};
    border-color: ${rgba('indigo.015', 0.4)};
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          ${backgroundColor('indigo.015')};
          ${borderColor('indigo.015')};
          ${color('indigo.050')};
          ${fontWeight(600)}
        `
      : css`
          ${borderColor('neutral.030')};
        `};
`;

const COMPANY_LIST_BOX_WIDTH = 330;

const StyledCompanyListContentBox = styled(Box).attrs({
  backgroundColor: 'neutral.000',
  boxShadow: 'lg',
  paddingBottom: 'xxl',
  position: 'fixed',
  height: '100%',
  width: COMPANY_LIST_BOX_WIDTH,
})`
  opacity: 1;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  transform: translateX(0%);
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const StyledCompanyListCollapsibleBox = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
})<{ isCollapsed: boolean }>`
  pointer-events: auto;
  width: ${COMPANY_LIST_BOX_WIDTH}px;

  &,
  ${StyledCompanyListContentBox} {
    transition: 250ms ease;
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          opacity: 0;
          pointer-events: none;
          width: 0;

          ${StyledCompanyListContentBox} {
            opacity: 0;
            transform: translateX(-100%);
          }
        `
      : ''}
`;

const StyledSearchIconBox = styled(Box).attrs({
  position: 'absolute',
  right: getSpace('sm'),
  top: '50%',
})`
  transform: translateY(-50%);
`;

const StyledCompanyButtonText = styled(Text).attrs({
  variant: 3,
})``;

const StyledCompanyButton = styled(UnstyledButton)<{ isActive: boolean }>`
  width: 100%;

  ${({ isActive }) =>
    isActive
      ? css`
          &,
          :hover {
            ${backgroundColor('indigo.015')}
          }

          ${StyledCompanyButtonText}, ${StyledCompanyButtonText}:hover {
            ${color('indigo.070')}
          }
        `
      : css`
          ${color('black.100')}

          :hover {
            background-color: ${rgba('indigo.015', 0.4)};
          }
        `}
`;

const StyledFiltersListContainerBox = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'row',
  paddingX: 'sm',
  paddingY: 'xs',
})`
  flex-wrap: wrap;
`;
