import { Box, Spinner, Text, UnstyledButton } from '@spaceship-fspl/components';
import { useDebounceValue } from '@spaceship-fspl/helpers';
import {
  FeatherChevronRightIcon,
  FeatherSearchIcon,
  FeatherXIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  color,
  fontWeight,
  padding,
  text,
} from '@spaceship-fspl/styles';
import { ControllerInput } from 'components/controller-input';
import { LinkButton } from 'components/link-button';
import { useGetAutocompletePredictions } from 'helpers/hooks/googlemaps/use-get-autocomplete-predictions';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

export const AddressSearch: React.FC<
  React.PropsWithChildren<{
    onSelect: (id: string) => void;
    onFocus?: () => void;
    onFallback: () => void;
  }>
> = ({ onSelect, onFocus, onFallback }) => {
  const { watch, setValue, control } = useFormContext();
  const addressInput = watch().address_search;
  const debouncedAddressInput = useDebounceValue(addressInput, 500);

  const { data, isError, isLoading } = useGetAutocompletePredictions(
    debouncedAddressInput,
  );

  const showAddressOptions = useMemo(() => {
    return data?.predictions && data?.predictions?.length > 0 && !isError;
  }, [isError, data?.predictions]);

  const handleReset = (): void => {
    onFallback();
    setValue('address_search', '');
  };

  return (
    <Box position="relative">
      <ControllerInput
        name="address_search"
        control={control}
        type="text"
        placeholder="Find your residential address"
        onFocus={onFocus}
        suffix={
          !addressInput ? (
            <FeatherSearchIcon color="neutral.080" size="md" />
          ) : (
            <UnstyledButton
              onClick={() => {
                setValue('address_search', '');
              }}
            >
              <FeatherXIcon color="neutral.080" size="md" />
            </UnstyledButton>
          )
        }
        rules={{
          validate: (value) => {
            if (!watch().address && !value) {
              return 'Your address is required';
            }
            return true;
          },
        }}
      />

      {addressInput?.length > 1 && (
        <StyledAddressOptionContainerBox>
          {isLoading ? (
            <Box
              padding="md"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner />
            </Box>
          ) : showAddressOptions ? (
            <>
              <StyledAddressOptionButton onClick={handleReset}>
                Can&apos;t find your address? Enter manually
              </StyledAddressOptionButton>

              {data?.predictions.map(({ description, place_id }) => (
                <StyledAddressOptionButton
                  key={description}
                  onClick={() => {
                    onSelect(place_id);
                    setValue('address_search', '');
                  }}
                >
                  {description}
                </StyledAddressOptionButton>
              ))}
            </>
          ) : (
            <Box padding="lg" display="flex" justifyContent="center">
              <Text
                variant={2}
                color="neutral.080"
                isBold={true}
                align="center"
              >
                No matches found,&nbsp;
              </Text>
              <LinkButton
                size="sm"
                onClick={handleReset}
                trackingProperties={{ name: 'address_search_enter_manually' }}
              >
                enter manually <FeatherChevronRightIcon size="sm" />
              </LinkButton>
            </Box>
          )}
        </StyledAddressOptionContainerBox>
      )}
    </Box>
  );
};

const StyledAddressOptionContainerBox = styled(Box).attrs({
  borderRadius: 'xxs',
  backgroundColor: 'neutral.000',
  boxShadow: 'md',
  marginTop: 'sm',
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
})`
  z-index: 2;
`;

const StyledAddressOptionButton = styled(UnstyledButton)`
  ${padding('sm')}
  ${text({ variant: 2 })}
  ${fontWeight(600)}
  text-align: left;
  width: 100%;
  word-break: break-word;

  :hover {
    ${backgroundColor('indigo.070')}
    ${color('neutral.000')}
  }
`;
