import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import { useCancelPaymentScheduleForProductInstances } from '@spaceship-fspl/graphql';
import { Button } from 'components/button';
import { Modal, ModalProps } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import React from 'react';

export interface VoyagerStopPlanModalProps extends ModalProps {
  productId: string;
  onSuccess?: () => void;
}

export const VoyagerStopPlanModal: React.FC<
  React.PropsWithChildren<VoyagerStopPlanModalProps>
> = ({ showModal, closeModal, productId, onSuccess }) => {
  const [cancelPaymentSchedules, cancelPaymentSchedulesMeta] =
    useCancelPaymentScheduleForProductInstances();
  const { popToast } = useNotifications();

  const handleStopPlan = async (): Promise<void> => {
    if (productId) {
      try {
        await cancelPaymentSchedules({
          variables: {
            input: {
              productIds: [productId],
            },
          },
        });
        popToast({
          message: 'Your current investment plan has been stopped.',
          level: 'success',
        });
        onSuccess?.();
      } catch (error) {
        popToast({
          message: (error as Error).message,
          level: 'error',
        });
        return;
      }
    }
    closeModal();
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box paddingX={{ xs: 'md', md: 'xl' }} paddingY="xl">
        <Stack spaceY="lg">
          <Stack spaceY="xs">
            <Heading variant={3} color="black.100" align="center">
              Stop plan
            </Heading>
            <Text variant={2} color="neutral.080" align="center" isBold={true}>
              Are you sure you want to stop your investment plan?
            </Text>
          </Stack>

          <Stack spaceY="sm" alignX="center">
            <Button
              variant="primary"
              size="lg"
              onClick={handleStopPlan}
              isLoading={cancelPaymentSchedulesMeta.loading}
              trackingProperties={{
                name: 'voyager_stop_plan_modal_stop_plan_button',
              }}
            >
              Stop plan
            </Button>

            <Button
              variant="secondary"
              size="lg"
              isDisabled={cancelPaymentSchedulesMeta.loading}
              onClick={closeModal}
              trackingProperties={{
                name: 'voyager_stop_plan_modal_cancel_button',
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
