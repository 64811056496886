import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  ApplySaverReferralCodeResult,
  SaverReferralReceiveStatus,
  useApplyVoyagerReferralCode,
} from '@spaceship-fspl/graphql/src';
import { WebAppVoyagerReferralsEnterCode } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerReferralsEnterCode';
import { FeatherArrowLeftIcon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { PageContainer } from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

const DEFAULT_ERROR_MESSAGE =
  'Oops, there seems to be a problem with your referral code. Please contact our customer support team for help.';

export const VoyagerReferralsEnterCode: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const intercom = useIntercom();
  const notification = useNotifications();
  const [applyReferralCode] = useApplyVoyagerReferralCode();

  const form = useForm<{ code: string }>({
    shouldFocusError: true,
  });

  const resp = useQuery<WebAppVoyagerReferralsEnterCode>(gql`
    query WebAppVoyagerReferralsEnterCode {
      contact {
        id
        account {
          id
          saverReferralReceiveDetails {
            id
            status
            referrerShortName
            campaign {
              id
              title
              termsAndConditionsUrl
              audReceiverRewardAmount
              audReceiverMinimumInvestmentAmount
            }
            rewardDestinationProductInstance {
              id
            }
          }
        }
      }
    }
  `);

  const receiveDetails =
    resp.data?.contact?.account?.saverReferralReceiveDetails;
  const campaign = receiveDetails?.campaign;
  const status = receiveDetails?.status;

  const showError = (message: string): void => {
    notification.popToast({
      message,
      level: 'error',
    });
  };

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
          <Stack spaceY="md">
            <Heading variant={3} isBold={true} align="center">
              {campaign?.title || 'Enter referral code'}
            </Heading>

            {status !== SaverReferralReceiveStatus.ELIGIBLE && (
              <Stack spaceY="lg" alignX="center">
                <Text variant={2} color="neutral.080" isBold={true}>
                  You are no longer eligible to enter a referral code.
                </Text>

                <Button
                  type="button"
                  variant="tertiary"
                  size="lg"
                  onClick={() => navigate?.(Routes.VOYAGER_REFERRALS)}
                  trackingProperties={{
                    name: 'referral_enter_code_ineligible_back',
                  }}
                  isDisabled={loading}
                  before={<FeatherArrowLeftIcon />}
                >
                  Back
                </Button>
              </Stack>
            )}

            {status === SaverReferralReceiveStatus.ELIGIBLE && (
              <Columns alignX="center">
                <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 6 / 12 }}>
                  <Stack spaceY="sm">
                    <Text variant={2} color="neutral.080" isBold={true}>
                      Once you’ve invested at least $
                      {campaign?.audReceiverMinimumInvestmentAmount} into your
                      chosen portfolio, you’ll receive $
                      {campaign?.audReceiverRewardAmount} in your Spaceship
                      Voyager account.
                    </Text>

                    <Text variant={2} color="neutral.080" isBold={true}>
                      By entering your code, you agree to the&nbsp;
                      <TextLink
                        href={campaign?.termsAndConditionsUrl || '#'}
                        target="_blank"
                        color="indigo.070"
                      >
                        Terms &amp; Conditions
                      </TextLink>
                    </Text>

                    <form
                      onSubmit={form.handleSubmit(async (payload) => {
                        setLoading(true);
                        try {
                          const resp = await applyReferralCode({
                            variables: {
                              input: payload,
                            },
                          });

                          const data = resp.data?.applySaverReferralCode;

                          if (!data) {
                            throw new Error('Unexpected empty response.');
                          }

                          const { result, details } = data;
                          const product =
                            details.rewardDestinationProductInstance;

                          switch (result) {
                            case ApplySaverReferralCodeResult.SUCCESS:
                              break;

                            case ApplySaverReferralCodeResult.INVALID:
                              showError('Invalid referral code.');
                              setLoading(false);
                              return;

                            case ApplySaverReferralCodeResult.ALREADY_APPLIED:
                              showError('You have already entered a code.');
                              setLoading(false);
                              return;

                            case ApplySaverReferralCodeResult.BANNED:
                            case ApplySaverReferralCodeResult.EXPIRED:
                            default:
                              showError(DEFAULT_ERROR_MESSAGE);
                              setLoading(false);
                              return;
                          }

                          await navigate(Routes.VOYAGER_REFERRALS);

                          if (
                            details.status ===
                            SaverReferralReceiveStatus.PENDING_FINALISATION
                          ) {
                            notification.popToast({
                              level: 'success',
                              message: `Thanks to ${details.referrerShortName}, you’ll receive $${campaign?.audReceiverRewardAmount} in your Spaceship Voyager account once you invest at least $${campaign?.audReceiverMinimumInvestmentAmount} in your chosen portfolio.`,
                              cta: {
                                message: 'Invest now',
                                action: (): void => {
                                  navigate(
                                    `${Routes.VOYAGER_DEPOSIT}/${
                                      product?.id ?? ''
                                    }`,
                                  );
                                },
                              },
                            });
                          } else {
                            notification.popToast({
                              level: 'success',
                              message: `Thanks to ${details.referrerShortName}, you’re set to take flight with $${campaign?.audReceiverMinimumInvestmentAmount} invested into your Spaceship Voyager account.`,
                            });
                          }
                          return;
                        } catch {
                          notification.popToast({
                            message: DEFAULT_ERROR_MESSAGE,
                            level: 'error',
                            cta: {
                              message: 'Contact support',
                              action: intercom.pop,
                            },
                          });
                        }
                        setLoading(false);
                      })}
                    >
                      <Stack spaceY="md">
                        <ControllerInput
                          name="code"
                          control={form.control}
                          type="text"
                          placeholder="Referral code"
                          rules={{
                            required: 'Referral code required',
                          }}
                        />

                        <Box display="flex" justifyContent="center">
                          <Button
                            variant="primary"
                            size="lg"
                            type="submit"
                            trackingProperties={{
                              name: 'referral_submit_code',
                            }}
                            isLoading={loading}
                          >
                            Submit
                          </Button>
                        </Box>

                        <Text variant={3} color="neutral.080">
                          Bonuses are added within 24 hours of your investment
                          of at least $
                          {campaign?.audReceiverMinimumInvestmentAmount} being
                          processed and paid.
                        </Text>
                      </Stack>
                    </form>
                  </Stack>
                </Columns.Column>
              </Columns>
            )}
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
