import * as React from 'react';
export const StreamlineMonitorHeartBeat = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M16.92 16.615l-4.392 4.582a.733.733 0 01-1.058 0l-4.392-4.583M1.705 10.752a5.548 5.548 0 011.556-7.17 5.547 5.547 0 017.208.547l1.53 1.527 1.532-1.532a5.547 5.547 0 018.766 6.624" />
    <path vectorEffect="non-scaling-stroke" d="M1 13.682h6.373a.368.368 0 00.328-.203l1.685-3.37a.368.368 0 01.684.059l1.644 5.475a.369.369 0 00.684.04l1.536-3.583a.367.367 0 01.642-.06l1.091 1.637H23" />
  </svg>
);
