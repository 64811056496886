import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

import { MfaVerificationType } from './__generated__/globalTypes';

export type SpaceshipGraphQLErrorExtensions = {
  mfa_required_type?: string | null;
  mfa_required_to?: string | null;
};

export const getMFARequiredPayloadFromErrors = (
  graphQLErrors: GraphQLErrors,
): { type: MfaVerificationType; to?: string; reason: string } | undefined => {
  return graphQLErrors
    .flatMap(
      ({
        extensions,
        message,
      }: GraphQLError & {
        extensions?: SpaceshipGraphQLErrorExtensions | null;
      }) => {
        switch (extensions?.mfa_required_type) {
          case MfaVerificationType.SMS:
            return {
              type: MfaVerificationType.SMS,
              to: extensions?.mfa_required_to ?? undefined,
              reason: message,
            };
          case MfaVerificationType.EMAIL:
            return {
              type: MfaVerificationType.EMAIL,
              to: extensions?.mfa_required_to ?? undefined,
              reason: message,
            };
          default:
            return undefined;
        }
      },
    )
    .filter(Boolean)?.[0];
};
