import { navigate } from '@reach/router';
import { useCanReadSuper } from '@spaceship-fspl/auth';
import { useSuperReferralCode, useSuperReferrer } from 'contexts/deep-linking';
import { Routes } from 'pages/routes';
import { useCallback } from 'react';

export const useNavigateToSuperCrossSellFromReferral = (): (() => void) => {
  const { value: code, setValue: setCode } = useSuperReferralCode();
  const { setValue: setReferrer } = useSuperReferrer();
  const isSuperUser = useCanReadSuper();

  const navigateToSuperCrossSellFromReferral = useCallback(async () => {
    if (isSuperUser) {
      await navigate(Routes.SUPER_DASHBOARD);
    } else if (code) {
      // Pressing 'back' from confirm details screen will go to account super screen instead of super referred screen
      await navigate(Routes.ACCOUNT_SUPER_DETAILS, {
        replace: true,
      });
      await navigate(Routes.ACCOUNT_CONFIRM_DETAILS, {
        state: { nextRoute: Routes.SUPER_SIGNUP_DETAILS },
      });
    }

    // We don't save the referral code for cross-sell
    setCode(undefined);
    setReferrer(undefined);
  }, [code, isSuperUser, setCode, setReferrer]);

  return navigateToSuperCrossSellFromReferral;
};
