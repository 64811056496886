import { useSetPartialState } from '@spaceship-fspl/auth';
import { Box, Heading, Spinner, Stack, Text } from '@spaceship-fspl/components';
import {
  MfaVerificationType,
  useCheckMfaVerification,
  useInitiateMfaVerification,
} from '@spaceship-fspl/graphql';
import { InitiateMfaVerification_initiateMfaVerification } from '@spaceship-fspl/graphql/src/__generated__/InitiateMfaVerification';
import {
  capitalizeFirstLetter,
  prettifyError,
  usePrevious,
} from '@spaceship-fspl/helpers';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { LinkButton } from 'components/link-button';
import { Modal } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import { TrackingEvent } from 'helpers/analytics';
import { mfaValidationRules } from 'helpers/validation';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import { ControllerInputWithInlineError } from './controller-input';

export interface MFAVerificationModalRef {
  showMFAVerification(
    verified: () => void,
    unverified: () => void,
    type: MfaVerificationType,
    verificationTo?: string,
  ): void;
}

export const MFAVerificationModal = forwardRef<MFAVerificationModalRef>(
  (_, ref) => {
    const track = useTrack();
    const { popToast } = useNotifications();
    const [showModal, setShowModal] = useState(false);
    const prevShowModal = usePrevious(showModal);

    const [initiateMfaVerification, initiateMfaVerificationMeta] =
      useInitiateMfaVerification();
    const [checkMfaVerification, checkMfaVerificationMeta] =
      useCheckMfaVerification();
    // we are storing this because when we resend the code we would loose the phone number
    const [mfaVerification, setMfaVerification] =
      useState<InitiateMfaVerification_initiateMfaVerification | null>(null);
    const setPartialAuthState = useSetPartialState();

    const [to, setTo] = useState<string | undefined>(undefined);
    const [type, setType] = useState(MfaVerificationType.SMS);
    const verifiedMFA = React.useRef<(() => void) | undefined>();
    const unverifiedMFA = React.useRef<(() => void) | undefined>();

    const handleOnMFAVerificationRequired = (
      verified: () => void,
      unverified: () => void,
      type: MfaVerificationType,
      verificationTo?: string,
    ): void => {
      setType(type);
      setTo(verificationTo);
      setShowModal(true);
      verifiedMFA.current = verified;
      unverifiedMFA.current = unverified;
    };

    useImperativeHandle(ref, () => ({
      showMFAVerification: handleOnMFAVerificationRequired,
    }));

    const { control, handleSubmit } = useForm<{
      code: string;
    }>();

    const initiateVerification = useCallback(
      async ({ resend = false } = {}) => {
        try {
          const response = await initiateMfaVerification({
            variables: {
              input: {
                type,
                to,
              },
            },
          });
          setMfaVerification(response.data?.initiateMfaVerification ?? null);

          if (resend && response.data?.initiateMfaVerification) {
            popToast({
              message: 'Code resent',
              level: 'success',
            });
          }
        } catch (error) {
          popToast({
            message: prettifyError(error),
            level: 'error',
          });
        }
      },
      [initiateMfaVerification, type, to, popToast],
    );

    const reset = (): void => {
      setType(MfaVerificationType.SMS);
      setTo(undefined);
    };

    const verificationType =
      type === MfaVerificationType.SMS ? 'mobile number' : 'email address';

    const handleVerify = handleSubmit(async ({ code }) => {
      try {
        const response = await checkMfaVerification({
          variables: {
            input: {
              answer: {
                id: mfaVerification?.id ?? '',
                token: code,
              },
            },
          },
        });

        popToast({
          message: `${capitalizeFirstLetter(verificationType)} verified`,
          level: 'success',
        });

        // we get a new auth token with some 2fa goodness
        const auth = response.data?.checkMfaVerification?.auth;
        if (auth) {
          setPartialAuthState({
            accessToken: auth.authToken,
            refreshToken: auth.refreshToken,
            scopes: auth.scopes,
          });
        }
        setShowModal(false);
        reset();
        verifiedMFA.current?.();
      } catch (error) {
        popToast({
          message: prettifyError(error),
          level: 'error',
        });
      }
    });

    useEffect(() => {
      if (showModal) {
        initiateVerification();
      }
    }, [initiateVerification, showModal]);

    useEffect(() => {
      if (prevShowModal !== undefined && prevShowModal !== showModal) {
        track?.(TrackingEvent.DISPLAY, {
          properties: {
            name: 'mfa-verification_modal',
            display: showModal,
          },
        });
      }
    }, [track, prevShowModal, showModal]);

    return (
      <Modal
        closeModal={() => {
          setShowModal(false);
          reset();
          unverifiedMFA.current?.();
        }}
        showModal={showModal}
      >
        <Box
          padding="xl"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box marginBottom="md">
            <Stack spaceY="xs">
              <Heading variant={3} align="center" component="h3">
                {`Verify your ${verificationType}`}
              </Heading>
              {initiateMfaVerificationMeta.loading && !mfaVerification ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Spinner />
                </Box>
              ) : (
                <Text
                  variant={2}
                  color="neutral.080"
                  isBold={true}
                  align="center"
                >
                  {`Please enter the six-digit verification code we sent to your ${verificationType} ${
                    mfaVerification?.to ?? '—'
                  }`}
                </Text>
              )}
            </Stack>
          </Box>

          <form onSubmit={handleVerify}>
            <Stack spaceY="md">
              <ControllerInputWithInlineError
                name="code"
                control={control}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
                placeholder="Verification code"
                rules={mfaValidationRules.code}
              />

              <Text variant={4}>
                Didn&apos;t receive a text?{' '}
                <LinkButton
                  size="xxs"
                  onClick={() => {
                    initiateVerification({ resend: true });
                  }}
                  trackingProperties={{
                    name: 'mfa-verification_send_again_button',
                  }}
                >
                  Send again
                </LinkButton>
              </Text>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                isLoading={checkMfaVerificationMeta.loading}
                isDisabled={initiateMfaVerificationMeta.loading}
                trackingProperties={{
                  name: 'mfa-verification_verify_button',
                }}
              >
                Verify
              </PageFormContinueButton>
            </PageFormButtonContainer>
          </form>
        </Box>
      </Modal>
    );
  },
);

MFAVerificationModal.displayName = 'MFAVerificationModal';
