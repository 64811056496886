import * as React from 'react';
export const TaxTime = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#prefix__clip0_1_48)">
      <path d="M2.727 3h13.286v17.232H2.727V3z" fill="#F5F5F8" />
      <path
        d="M7.922 4.967v.449h-.61v1.852h-.56V5.416h-.61v-.45h1.78zM9.594 6.861h-.858l-.138.407h-.587l.833-2.301h.649l.832 2.3h-.593l-.138-.406zM9.45 6.43l-.285-.843-.282.843h.567zM11.947 7.268l-.469-.705-.413.705h-.636l.738-1.17-.754-1.131h.652l.462.695.407-.695h.636l-.731 1.16.76 1.14h-.652z"
        fill="#BEBECB"
      />
      <path
        d="M4.66 8.717h9.42v.644H4.66v-.644zM4.66 13.548h4.026v.645H4.66v-.645zM10.055 13.548h4.026v.645h-4.026v-.645zM4.66 10.005h9.42v.645H4.66v-.645zM4.66 14.837h4.026v.644H4.66v-.644zM10.055 14.837h4.026v.644h-4.026v-.644zM4.66 16.125h4.026v.644H4.66v-.644zM10.055 16.125h4.026v.644h-4.026v-.644zM4.66 11.294h6.039v.644h-6.04v-.644zM4.66 17.413h2.657v.645H4.66v-.645zM10.055 17.413h2.657v.645h-2.657v-.645z"
        fill="#E3E1E8"
      />
      <path
        d="M24 14.277a6.321 6.321 0 11-12.642 0 6.321 6.321 0 0112.642 0z"
        fill="#701AEB"
      />
      <path
        d="M24 14.277a6.321 6.321 0 11-12.642 0 6.321 6.321 0 0112.642 0z"
        fill="url(#prefix__paint0_linear_1_48)"
      />
      <path
        d="M23.219 14.277a5.54 5.54 0 11-11.08 0 5.54 5.54 0 0111.08 0z"
        fill="#CDCBFA"
      />
      <path
        d="M22.793 14.277a5.114 5.114 0 11-10.228 0 5.114 5.114 0 0110.228 0z"
        fill="#F5F5F8"
      />
      <path
        d="M17.892 9.802a.213.213 0 11-.426 0 .213.213 0 01.426 0zM17.892 18.751a.213.213 0 11-.426 0 .213.213 0 01.426 0zM22.154 14.49a.213.213 0 110-.426.213.213 0 010 .426zM13.205 14.49a.213.213 0 110-.426.213.213 0 010 .426zM20.994 11.264a.213.213 0 11-.302-.302.213.213 0 01.302.302zM14.666 17.591a.213.213 0 11-.301-.3.213.213 0 01.3.3zM20.692 17.591a.213.213 0 11.302-.3.213.213 0 01-.302.3zM14.364 11.264a.213.213 0 11.302-.302.213.213 0 01-.302.302z"
        fill="#8966F7"
      />
      <path
        d="M15.047 11.985a.222.222 0 11.315-.315l2.196 2.197a.222.222 0 11-.314.314l-2.197-2.196zM20.165 14.064a.213.213 0 110 .426h-1.989a.213.213 0 110-.426h1.989z"
        fill="#701AEB"
      />
      <path
        d="M19.96 10.945l-1.972 3.121-.245-.15 2.217-2.971z"
        fill="#FFDF52"
      />
      <path
        d="M18.105 14.277a.426.426 0 11-.852 0 .426.426 0 01.852 0z"
        fill="#7725EE"
      />
      <path
        d="M.489 16.662s.067.203.189.343c.121.14.297.218.297.218s-.176.078-.298.217c-.122.14-.19.341-.19.341s-.068-.202-.19-.342C.177 17.299 0 17.22 0 17.22s.176-.078.298-.217c.123-.14.19-.342.19-.342zM18.072 4.95l-.17-.097a.055.055 0 01-.023-.026.067.067 0 01-.005-.038.06.06 0 01.017-.032.047.047 0 01.03-.014l.189-.015a.05.05 0 00.025-.01.06.06 0 00.017-.022l.084-.195a.056.056 0 01.023-.027.045.045 0 01.033-.005.05.05 0 01.028.019c.008.01.012.022.012.035l.013.217c0 .01.003.02.008.029a.054.054 0 00.02.02l.17.096c.01.006.018.015.023.027a.066.066 0 01.004.037.06.06 0 01-.016.033.047.047 0 01-.03.014l-.19.014a.047.047 0 00-.024.01.057.057 0 00-.018.023l-.085.194a.055.055 0 01-.023.028.045.045 0 01-.032.005.05.05 0 01-.029-.02.063.063 0 01-.012-.034l-.012-.217a.07.07 0 00-.009-.029.06.06 0 00-.018-.02zM22.675 7.477s.056.168.157.284c.1.117.247.182.247.182s-.146.064-.248.18c-.102.116-.158.284-.158.284s-.056-.168-.157-.285c-.101-.116-.248-.181-.248-.181s.147-.065.249-.18c.101-.117.158-.284.158-.284z"
        fill="#F3E4FF"
      />
    </g>
    <defs>
      <linearGradient
        id="prefix__paint0_linear_1_48"
        x1={0}
        y1={11.799}
        x2={24}
        y2={11.799}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.1} />
        <stop offset={0.45} stopColor="#EEE3FF" stopOpacity={0.1} />
        <stop offset={0.75} stopColor="#B789FF" stopOpacity={0.1} />
        <stop offset={0.89} stopColor="#8F48FF" stopOpacity={0.1} />
      </linearGradient>
      <clipPath id="prefix__clip0_1_48">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
