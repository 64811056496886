import { gql } from '@apollo/client';
import { navigate } from '@reach/router';
import { useCanWriteSaver } from '@spaceship-fspl/auth';
import {
  Box,
  Button,
  Card,
  Divider,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { MoneyReadyToInvestCard_MoneyReadyToInvestFragment as MoneyReadyToInvestCard_MoneyReadyToInvestFragmentType } from '@spaceship-fspl/graphql/src/__generated__/MoneyReadyToInvestCard_MoneyReadyToInvestFragment';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled from 'styled-components';

import { Routes } from '../../routes';
import { ReadyToInvestActionItem } from './ready-to-invest-action-item';

export const MoneyReadyToInvestCard_MoneyReadyToInvestFragment = gql`
  fragment MoneyReadyToInvestCard_MoneyReadyToInvestFragment on Account {
    id
    saverAudBalance
    etfsAudBalance
    stocksAudBalance
  }
`;

export const ReadyToInvestCard: React.FC<{
  account?: MoneyReadyToInvestCard_MoneyReadyToInvestFragmentType | null;
  isLoading?: boolean;
}> = ({ account, isLoading }) => {
  const hasVoyager = useCanWriteSaver();

  return (
    <Card
      aria-label="money ready to invest card"
      data-testid={TestId.MONEY_READY_TO_INVEST_CARD}
      display="flex"
      height="100%"
    >
      <Box display="flex" flexDirection="column" width="100%" padding="md">
        <Box marginBottom={'md'}>
          <Text isBold={true} color={'indigo.070'} variant={2}>
            Ready to Invest?
          </Text>
        </Box>

        {!Object.hasOwn(account ?? {}, 'saverAudBalance') && isLoading ? (
          <Box
            data-testid={TestId.LOADER}
            display="flex"
            justifyContent="center"
            paddingY="lg"
          >
            <Spinner />
          </Box>
        ) : (
          <>
            <Stack spaceY={'md'}>
              <ReadyToInvestActionItem
                product={'voyager'}
                productAction={() => {
                  if (hasVoyager || account?.saverAudBalance) {
                    navigate(Routes.VOYAGER_DEPOSIT);
                  } else {
                    navigate(Routes.VOYAGER_DASHBOARD);
                  }
                }}
                title={'Spaceship Voyager'}
                message={
                  account?.saverAudBalance
                    ? formatCurrency(account?.saverAudBalance)
                    : `You haven't invested yet!`
                }
                ctaElement={
                  !account?.saverAudBalance && (
                    <ReadyToInvestCtaButton variant={'primary'} size={'sm'}>
                      Get started
                    </ReadyToInvestCtaButton>
                  )
                }
              />
              <Divider />
              <ReadyToInvestActionItem
                product={'etf'}
                productAction={() => navigate(Routes.ETF_DASHBOARD)}
                title={'US ETFs'}
                message={
                  account?.etfsAudBalance
                    ? formatCurrency(account?.etfsAudBalance)
                    : `You haven't invested yet!`
                }
                ctaElement={
                  !account?.etfsAudBalance && (
                    <ReadyToInvestCtaButton variant={'primary'} size={'sm'}>
                      Get started
                    </ReadyToInvestCtaButton>
                  )
                }
              />
              <Divider />
              <ReadyToInvestActionItem
                product={'stocks'}
                productAction={() => navigate(Routes.STOCKS_DASHBOARD)}
                title={'US stocks'}
                message={
                  account?.stocksAudBalance
                    ? formatCurrency(account?.stocksAudBalance)
                    : `You haven't invested yet!`
                }
                ctaElement={
                  !account?.stocksAudBalance && (
                    <ReadyToInvestCtaButton variant={'primary'} size={'sm'}>
                      Get started
                    </ReadyToInvestCtaButton>
                  )
                }
              />
            </Stack>
          </>
        )}
      </Box>
    </Card>
  );
};

const ReadyToInvestCtaButton = styled(Button)`
  padding-inline: 8px;
  font-size: 14px;
`;
