import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import { useCancelApplication } from '@spaceship-fspl/graphql';
import { Button } from 'components/button';
import { Modal, ModalProps } from 'components/modal';
import { useNotifications } from 'contexts/notifications';
import React from 'react';

export interface VoyagerCancelTransactionModalProps extends ModalProps {
  transactionId: string;
}

export const VoyagerCancelTransactionModal: React.FC<
  React.PropsWithChildren<VoyagerCancelTransactionModalProps>
> = ({ showModal, closeModal, transactionId }) => {
  const [cancelApplication, cancelApplicationMeta] = useCancelApplication();
  const { popToast } = useNotifications();
  const handleCancelTransaction = async (): Promise<void> => {
    try {
      await cancelApplication({
        variables: {
          input: {
            id: transactionId,
          },
        },
      });
      popToast({
        message: 'This investment has been cancelled.',
        level: 'success',
      });
    } catch (err) {
      popToast({
        message: (err as Error).message,
        level: 'error',
      });
    } finally {
      closeModal();
    }
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box paddingX={{ xs: 'md', md: 'xl' }} paddingY="xl">
        <Stack spaceY="lg">
          <Stack spaceY="xs">
            <Heading variant={3} color="black.100" align="center">
              Cancel Investment
            </Heading>
            <Text variant={2} color="neutral.080" align="center" isBold={true}>
              Are you sure you want to cancel this investment?
            </Text>
          </Stack>

          <Stack spaceY="sm" alignX="center">
            <Button
              variant="secondary"
              size="lg"
              isDisabled={cancelApplicationMeta.loading}
              onClick={closeModal}
              trackingProperties={{
                name: 'voyager_cancel_transaction_modal_cancel_button',
              }}
            >
              No, go back
            </Button>
            <Button
              variant="primary"
              size="lg"
              onClick={handleCancelTransaction}
              isLoading={cancelApplicationMeta.loading}
              trackingProperties={{
                name: 'voyager_cancel_transaction_modal_confirm_button',
              }}
            >
              Yes, cancel this investment
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
