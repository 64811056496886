import * as React from 'react';
export const PadlockAttention = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#prefix__a)">
      <path
        stroke="#701AEB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18.526 11.725H4.876a1.95 1.95 0 00-1.95 1.95V20.5a1.95 1.95 0 001.95 1.95h13.65a1.95 1.95 0 001.95-1.95v-6.825a1.95 1.95 0 00-1.95-1.95zm-11.702 0v-3.9a4.875 4.875 0 119.75 0v3.9"
      />
      <path fill="#E11B03" d="M19.2 5.5a4.8 4.8 0 100 9.6 4.8 4.8 0 100-9.6z" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.9}
        d="M19.201 7.6v3.9"
      />
      <path fill="#fff" d="M19.2 12.4a.45.45 0 100 .9.45.45 0 100-.9z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
