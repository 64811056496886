/**
 * This component is an adapter for `next/link`, `gatsby/link`, `react-router/link` etc
 */

export interface LinkComponentProps {
  href: string;
  children: (props: {
    href: string;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent) => void;
  }) => React.ReactElement;
}

export interface PropsWithLinkComponent {
  LinkComponent?: React.ComponentType<LinkComponentProps>;
}

export const DefaultLinkComponent: React.FC<LinkComponentProps> = ({
  children,
  ...otherProps
}) => children({ ...otherProps });
