import { ApolloClient } from '@apollo/client';
import { loadTreatments } from '@spaceship-fspl/experimentation';

export enum ExperimentNames {
  SUPERMATCH_BANNER = 'supermatch_banner',
  SUPERMATCH_DEEPLINK = 'supermatch_deeplink',
  PAYTO = 'payto_rollout_experiment',
}

export async function loadAllTreatments(
  client: ApolloClient<unknown>,
): Promise<void> {
  await loadTreatments(client, Object.values(ExperimentNames));
}
