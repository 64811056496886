import { PresentationLink, Stack, Text } from '@spaceship-fspl/components';
import { StreamlineContentPaperEditIcon } from '@spaceship-fspl/icons-web';
import { useHasSuppliedTfn } from '@spaceship-fspl/super';
import { AccountCardContent } from 'components/account-cards/components';
import { LinkButton } from 'components/link-button';
import { useIntercom } from 'contexts/intercom';
import React from 'react';

export interface SuperTaxFileNumberCardProps {
  onClick: () => void;
  copy?: React.ReactNode;
  showProvidedTfnCard?: boolean;
}

export const SuperTaxFileNumberCard: React.FC<
  React.PropsWithChildren<SuperTaxFileNumberCardProps>
> = ({ onClick, copy, showProvidedTfnCard = false }) => {
  const { pop: showIntercom } = useIntercom();
  const hasSuppliedTfn = useHasSuppliedTfn();

  if (hasSuppliedTfn && !showProvidedTfnCard) {
    return null;
  }

  return (
    <AccountCardContent>
      {hasSuppliedTfn ? (
        <Text variant={2}>
          You&apos;ve already provided us with your Tax File Number (TFN). To
          update please get in touch with{' '}
          <LinkButton
            size="sm"
            onClick={showIntercom}
            trackingProperties={{
              name: 'super_update_supplied_tfn_support_button',
            }}
          >
            support
          </LinkButton>
          .
        </Text>
      ) : (
        <Stack spaceY="xs" alignX="center">
          <StreamlineContentPaperEditIcon color="indigo.070" size="lg" />

          <Text variant={2} align="center">
            {copy ||
              'You can provide Spaceship with your TFN to help manage your Spaceship Super account.'}
          </Text>

          <PresentationLink
            color="indigo.070"
            size="sm"
            icon="chevron"
            onClick={onClick}
          >
            Provide TFN
          </PresentationLink>
        </Stack>
      )}
    </AccountCardContent>
  );
};
