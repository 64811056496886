import { useNavigate } from '@reach/router';
import {
  ActionLink,
  Box,
  Card,
  Divider,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { BasiqConnectionStatus } from '@spaceship-fspl/graphql';
import { formatDate } from '@spaceship-fspl/helpers';
import { Button } from 'components/button';
import { InlineError } from 'components/inline-error';
import { RouterLink } from 'components/router-link';
import { Routes } from 'pages/routes';
import React, { Fragment } from 'react';

import { BoostBasiqConnection, BoostsRecipeWithBasiqConnection } from './types';

export const BoostsDashboardSettings: React.FC<
  React.PropsWithChildren<{
    recipes: Array<BoostsRecipeWithBasiqConnection>;
    connections: Array<BoostBasiqConnection>;
  }>
> = (props) => {
  const navigate = useNavigate();
  const hasBoostsConnection = !!props.connections.length;
  const hasBoostsRecipe = !!props.recipes.length;

  return (
    <Stack spaceY="md">
      {hasBoostsRecipe && (
        <Card borderRadius="sm" boxShadow="sm">
          <Box
            paddingTop="md"
            paddingBottom="sm"
            paddingX={{ xs: 'md', md: 'lg' }}
          >
            <Heading variant={5} isBold={true}>
              Linked boosts
            </Heading>
          </Box>

          <Divider color="neutral.050" />

          <Box
            paddingX={{ xs: 'md', md: 'lg' }}
            paddingTop="sm"
            paddingBottom="md"
          >
            <Stack spaceY="sm">
              {props.recipes.map((recipe, index) => {
                const recipeWithSameNames = props.recipes.filter(
                  (r) => r.name === recipe.name,
                );
                let recipeIndex = 0;
                if (recipeWithSameNames.length > 1) {
                  recipeIndex = recipeWithSameNames.findIndex(
                    (r) => r.id === recipe.id,
                  );
                }

                return (
                  <Fragment key={recipe?.id}>
                    {index !== 0 && <Divider color="neutral.030" />}

                    <Stack spaceY="xs">
                      <Box display="flex" justifyContent="space-between">
                        <Stack spaceY="xxxs">
                          <Text variant={2} isBold={true}>
                            {recipe?.name}{' '}
                            {recipeIndex > 0 && `(${recipeIndex + 1})`}
                          </Text>

                          <Text variant={4} color="neutral.085">
                            {recipe?.source?.institution?.name}
                          </Text>
                        </Stack>

                        <ActionLink
                          size="sm"
                          color="indigo.070"
                          onClick={() => {
                            if (
                              recipe?.source?.status ===
                              BasiqConnectionStatus.DISCONNECTED
                            ) {
                              navigate(Routes.BOOSTS_BASIQ_LOGIN, {
                                state: {
                                  type: 'add-source-and-edit-recipe',
                                  recipeId: recipe?.id,
                                  institutionId: recipe.source?.institution?.id,
                                },
                              });
                              return;
                            }

                            navigate(Routes.BOOSTS_BASIQ_SELECT, {
                              state: {
                                type: 'edit-existing-recipe',
                                recipeId: recipe?.id,
                              },
                            });
                          }}
                        >
                          Edit
                        </ActionLink>
                      </Box>

                      {recipe?.source?.status ===
                        BasiqConnectionStatus.DISCONNECTED && (
                        <InlineError
                          message="We ran into a problem connecting to your tracking bank. You’ll need to re-link your tracking bank to continue using it."
                          isBold={true}
                        />
                      )}

                      {recipe?.source?.status ===
                        BasiqConnectionStatus.REMOVED && (
                        <InlineError
                          message="Your tracking bank for this boost has been removed. You’ll need to link another tracking bank to continue using it."
                          isBold={true}
                        />
                      )}
                    </Stack>
                  </Fragment>
                );
              })}
            </Stack>
          </Box>
        </Card>
      )}

      {hasBoostsConnection && (
        <Card borderRadius="sm" boxShadow="sm">
          <Box
            paddingTop="md"
            paddingBottom="sm"
            paddingX={{ xs: 'md', md: 'lg' }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading variant={5} isBold={true}>
              Tracking banks
            </Heading>

            <Box paddingLeft="xxs">
              <RouterLink
                to={Routes.BOOSTS_BASIQ_EDIT}
                trackingProperties={{
                  name: 'dashboard_settings_tracking_banks_card_edit',
                }}
              >
                <ActionLink size="sm" color="indigo.070" component="span">
                  Edit
                </ActionLink>
              </RouterLink>
            </Box>
          </Box>

          <Divider color="neutral.050" />

          <Box
            paddingX={{ xs: 'md', md: 'lg' }}
            paddingTop="sm"
            paddingBottom="md"
          >
            <Stack spaceY="sm">
              {props.connections.map((connection, index) => {
                return (
                  <Fragment key={connection?.id}>
                    {index !== 0 && <Divider color="neutral.030" />}

                    <Stack spaceY="xxxs">
                      <Text variant={2} isBold={true}>
                        {connection.institution?.name}
                      </Text>

                      {connection.status ===
                        BasiqConnectionStatus.CONNECTED && (
                        <Text variant={4} color="neutral.085">
                          Linked{' '}
                          {formatDate(connection.connectedAt, 'dd MMM yyyy')}
                        </Text>
                      )}

                      {connection.status ===
                        BasiqConnectionStatus.CONNECTING && (
                        <Text variant={4} color="neutral.085">
                          Connecting
                        </Text>
                      )}

                      {connection.status ===
                        BasiqConnectionStatus.DISCONNECTED && (
                        <InlineError
                          message="We ran into a problem connecting to your tracking bank. You’ll need to re-link your tracking bank to continue using it."
                          isBold={true}
                        />
                      )}
                    </Stack>
                  </Fragment>
                );
              })}
            </Stack>
          </Box>
        </Card>
      )}

      {!hasBoostsConnection && !hasBoostsRecipe && (
        <Card borderRadius="sm" boxShadow="sm">
          <Box
            paddingTop="md"
            paddingBottom="sm"
            paddingX={{ xs: 'md', md: 'lg' }}
          >
            <Heading variant={5} isBold={true}>
              Tracking banks
            </Heading>
          </Box>

          <Divider color="neutral.050" />

          <Box paddingX={{ xs: 'md', md: 'lg' }} paddingY="md">
            <Stack spaceY="sm" alignX="center">
              <Text
                variant={3}
                color="neutral.080"
                isBold={true}
                align="center"
              >
                No tracking banks linked
              </Text>

              <Button
                variant="primary"
                size="lg"
                trackingProperties={{
                  name: 'boosts_dashboard_settings_link_new_tracking_bank_button',
                }}
                onClick={() => {
                  navigate(Routes.BOOSTS_BASIQ_INSTITUTIONS, {
                    state: { type: 'add-source' },
                  });
                }}
              >
                Link new tracking bank
              </Button>
            </Stack>
          </Box>
        </Card>
      )}
    </Stack>
  );
};
