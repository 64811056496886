import { RouteComponentProps } from '@reach/router';
import { Box, Inline, Text } from '@spaceship-fspl/components';
import { FeatherHelpCircleIcon } from '@spaceship-fspl/icons-web';
import { Color } from '@spaceship-fspl/styles';
import React from 'react';

export interface IntercomFooterProps extends RouteComponentProps {
  light?: boolean;
}

export const IntercomFooter: React.FC<
  React.PropsWithChildren<IntercomFooterProps>
> = ({ light = false }) => {
  const color: Color = light ? 'neutral.000' : 'neutral.080';

  return (
    <Box
      id="custom_intercom_selector"
      display={{ md: 'none' }}
      paddingY="lg"
      width="100%"
    >
      <Inline alignX="center" alignY="center" spaceX="xxs">
        <FeatherHelpCircleIcon size="md" color={color} />
        <Text variant={3} color={color}>
          Need help?
        </Text>
      </Inline>
    </Box>
  );
};
