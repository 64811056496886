import * as React from 'react';
export const VoyagerGalaxy = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" {...props}>
    <path
      d="M12.567 10.967s3.08-.736 4.274 2.462c1.422 3.814-2.544 11.52-12.506 7.934"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M13.199 12.007s1.796.749 1.695 3.237c-.082 2.036-2.677 6.49-9.446 4.171"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12.859 13.238s.908 3.475-2.945 4.432c-5.105 1.268-7.524-4.83-7.524-4.83"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M11.776 13.392s-2.974.811-4.306-2.299C5.883 7.385 9.538-.397 19.684 2.721"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M11.004 12.277s-1.85-.658-1.85-3.104c0-2.004 2.44-6.49 9.388-4.503"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M11.42 11.267s-1.073-3.45 2.81-4.58c5.145-1.496 7.869 4.524 7.869 4.524"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path d="M13.398 11.8a1.168 1.168 0 11-2.206.765 1.168 1.168 0 012.206-.765z" />
    <path
      d="M3.004 20.736l-.614-.392M1.322 19.483L1 19.233M1.934 11.386l-.156-.48M21.677 3.857l-.665-.467M23 5.1l-.32-.32M19.942 5.583l-.32-.248M22.559 12.59s.055.091.123.247c.079.18.126.336.126.336"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path d="M18.41 22.252a.461.461 0 100-.923.461.461 0 000 .923zM4.241 8.482a.461.461 0 100-.923.461.461 0 000 .923z" />
  </svg>
);
