import { createVariant } from '@spaceship-fspl/substance-style';
import { css } from 'styled-components';

import { fontSize, lineHeight } from '../../text';

export const fontFamilies = {
  articleHeading: '"Playfair Display", Times New Roman, serif',
  heading: 'Poppins, Helvetica, Arial, sans-serif',
  text: 'Inter, Helvetica, Arial, sans-serif',
  monospace: 'IBM Plex Mono, monospace',
};

export const headingVariant = createVariant({
  1: css`
    font-weight: bold;
    letter-spacing: -2px;
    ${fontSize({ xs: '48px', lg: '64px' })}
    ${lineHeight({ xs: '64px', lg: '76px' })}
  `,
  2: css`
    font-weight: bold;
    letter-spacing: -0.6px;
    ${fontSize({ xs: '32px', lg: '48px' })}
    ${lineHeight({ xs: '46px', lg: '64px' })}
  `,
  3: css`
    font-weight: 600;
    ${fontSize({ xs: '28px', lg: '32px' })}
    ${lineHeight({ xs: '40px', lg: '46px' })}
  `,
  4: css`
    font-weight: 600;
    ${fontSize({ xs: '24px', lg: '28px' })}
    ${lineHeight({ xs: '36px', lg: '40px' })}
  `,
  5: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  `,
});

export const textVariant = createVariant({
  1: css`
    font-weight: 500;
    ${fontSize({ xs: '20px', lg: '24px' })}
    ${lineHeight({ xs: '32px', lg: '38px' })}
  `,
  2: css`
    font-size: 16px;
    line-height: 26px;
  `,
  3: css`
    font-size: 14px;
    line-height: 22px;
  `,
  4: css`
    font-size: 12px;
    line-height: 18px;
  `,
});
