import { Box, Heading, Stack } from '@spaceship-fspl/components';
import {
  EtfProductIcon,
  IconComponent,
  StocksProductIcon,
  SuperProductIcon,
  VoyagerProductIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  Color,
  height,
  match,
  padding,
  transition,
  width,
} from '@spaceship-fspl/styles';
import { RouterLink } from 'components/router-link';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

export type Product = 'voyager' | 'super' | 'etf' | 'stocks' | 'money';

export type ProductsNavProps = {
  activeProduct: Product;
  onSelectActiveProduct: (product: Product) => void;
};

export const ProductsNav: React.FC<
  React.PropsWithChildren<ProductsNavProps>
> = ({ activeProduct, onSelectActiveProduct }) => {
  const productNavItems: Array<{
    product: Product;
    label: string;
    icon: IconComponent;
    color: Color;
    route: Routes | string;
    onClick: () => void;
    isVisible?: boolean;
  }> = useMemo(() => {
    return [
      {
        product: 'voyager',
        label: 'Portfolios',
        icon: VoyagerProductIcon,
        color: 'product.voyager',
        route: Routes.VOYAGER_DASHBOARD,
        onClick: () => onSelectActiveProduct('voyager'),
      },
      {
        product: 'etf',
        label: 'ETFs',
        icon: EtfProductIcon,
        color: 'product.etf',
        route: Routes.ETF_DASHBOARD,
        onClick: () => onSelectActiveProduct('etf'),
      },
      {
        product: 'stocks',
        label: 'Stocks',
        icon: StocksProductIcon,
        color: 'product.stocks',
        route: Routes.STOCKS_DASHBOARD,
        onClick: () => onSelectActiveProduct('stocks'),
      },
      {
        product: 'super',
        label: 'Super',
        icon: SuperProductIcon,
        color: 'product.super',
        route: Routes.SUPER_DASHBOARD,
        onClick: () => onSelectActiveProduct('super'),
      },
    ];
  }, [onSelectActiveProduct]);

  return (
    <Stack spaceY={{ xs: 'sm', md: 'md' }}>
      <Heading variant={4} isBold={true} align={{ md: 'center' }}>
        Choose your product
      </Heading>

      <Stack spaceY="sm" alignX="center">
        {productNavItems.map(
          ({
            product,
            icon: Icon,
            color,
            label,
            route,
            onClick,
            isVisible = true,
          }) => {
            const isActive = product === activeProduct;

            return isVisible ? (
              <StyledRouterLink
                key={product}
                to={route}
                onClick={onClick}
                trackingProperties={{
                  name: `authenticated_switch_product_nav_${product}`,
                }}
                isActive={isActive}
                aria-label={`Switch to ${label}`}
                aria-current={isActive ? 'page' : undefined}
              >
                <Stack spaceY="xs" alignX="center">
                  <Box
                    height={48}
                    width={48}
                    borderRadius="md"
                    backgroundColor={color}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon
                      testId={`product_nav_${product}_icon`}
                      color="neutral.000"
                      size="lg"
                    />
                  </Box>

                  <Heading
                    variant={5}
                    color={isActive ? 'indigo.070' : 'black.100'}
                    align="center"
                  >
                    {label}
                  </Heading>
                </Stack>
              </StyledRouterLink>
            ) : null;
          },
        )}
      </Stack>
    </Stack>
  );
};

const StyledRouterLink = styled(RouterLink)<{ isActive: boolean }>`
  ${backgroundColor('neutral.000')}
  ${borderRadius('xxs')}
  ${borderColor('neutral.050')}
  ${borderWidth({ xs: 'none', md: 'md' })}
  ${boxShadow('sm')}
  ${height({ xs: 120, md: 80 })}
  ${width({ xs: 120, md: 80 })}
  ${padding('md')}
  ${transition}
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) =>
    isActive
      ? borderColor('indigo.070')
      : css`
          :hover {
            ${borderColor('indigo.020')}
          }
        `}

  ${match('md')`
    ${boxShadow('none')}
  `}
`;
