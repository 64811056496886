import * as React from 'react';
export const StreamlineNetworkPin = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M3.21 16.797h8.805M3.74 6.357h16.515m-7.823 5.22H2m19.864-1.306A10.004 10.004 0 0011.032 2.05 10.004 10.004 0 002.009 12.22c.113 5.23 4.228 9.493 9.449 9.78-5.22-6.96-5.317-14.322-.096-19.977m1.27 0A14.034 14.034 0 0116.349 9.4m1.218 6.77c.723 0 1.305-.583 1.305-1.305 0-.723-.582-1.306-1.305-1.306-.722 0-1.305.583-1.305 1.306 0 .722.583 1.305 1.305 1.305zm3.916-1.305c0 2.61-3.916 6.525-3.916 6.525s-3.915-3.915-3.915-6.525a3.91 3.91 0 013.915-3.916 3.91 3.91 0 013.916 3.916z" />
  </svg>
);
