import * as React from 'react';
export const FeatherLock = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect vectorEffect="non-scaling-stroke" x={3} y={11} width={18} height={11} rx={2} ry={2} />
    <path vectorEffect="non-scaling-stroke" d="M7 11V7a5 5 0 0110 0v4" />
  </svg>
);
