import { RouteComponentProps, useNavigate } from '@reach/router';
import { Columns, Stack } from '@spaceship-fspl/components';
import { StreamlineEarthCashIcon } from '@spaceship-fspl/icons-web';
import {
  CenterPageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { Routes } from 'pages/routes';
import React from 'react';

export const VoyagerTaxResidencyIntro: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();

  return (
    <CenterPageContainer>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3 }}>
          <Stack spaceY="lg" alignX="center">
            <StreamlineEarthCashIcon
              size="xxxl"
              color="indigo.070"
              strokeWidth={3}
            />
            <PageHeading title="We need to update your tax residency details" />
          </Stack>

          <PageFormButtonContainer>
            <PageFormContinueButton
              trackingProperties={{
                name: 'voyager_tax_residency_intro_page_update',
              }}
              onClick={() => {
                navigate(Routes.VOYAGER_TAX_RESIDENCY_ADD);
              }}
            >
              Update now
            </PageFormContinueButton>
          </PageFormButtonContainer>
        </Columns.Column>
      </Columns>
    </CenterPageContainer>
  );
};
