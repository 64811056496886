import {
  Box,
  Card,
  Heading,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { useString } from '@spaceship-fspl/dynamic-config';
import { FeatherArrowRightIcon } from '@spaceship-fspl/icons-web';
import { StreamlineShieldCheckIcon } from '@spaceship-fspl/icons-web';
import { getSpace, transition, zIndex } from '@spaceship-fspl/styles';
import {
  MfaRegistrationModal,
  MfaRegistrationModalRef,
} from 'components/mfa-registration-modal';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import React, { useRef } from 'react';
import styled from 'styled-components';

export const MfaRegistrationCard: React.FC<React.PropsWithChildren> = () => {
  const modalRef = useRef<MfaRegistrationModalRef>(null);

  const copy = useString(
    DynamicConfigKeys.MFA_REGISTRATION_CARD_COPY,
    'Get peace of mind by securing your account with multi-factor authentication',
  );

  return (
    <>
      <Card
        postion="relative"
        height="100%"
        onClick={() => {
          modalRef.current?.showModal();
        }}
        style={{ cursor: 'pointer' }}
        padding="md"
      >
        <StyledTopRightButton>
          <FeatherArrowRightIcon color="neutral.100" />
        </StyledTopRightButton>

        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'column', lg: 'row' }}
        >
          <Box display="flex">
            <Box paddingRight="xxs" paddingTop="xxxs">
              <StreamlineShieldCheckIcon />
            </Box>
            <StyledHeading variant={4} color="neutral.100" isBold={true}>
              Secure your account
            </StyledHeading>
          </Box>

          <Box
            paddingLeft={{ xs: 'none', md: 'none', lg: 'md' }}
            paddingRight={{ xs: 'none', md: 'none', lg: 'md' }}
            paddingTop={{ xs: 'xs', md: 'xs', lg: 'none' }}
            display="flex"
            alignItems="center"
          >
            <Text variant={2} color="neutral.100" isBold={true}>
              {copy}
            </Text>
          </Box>
        </Box>
      </Card>

      <MfaRegistrationModal ref={modalRef} />
    </>
  );
};

const StyledTopRightButton = styled(UnstyledButton)`
  position: absolute;
  top: ${getSpace('sm')};
  right: ${getSpace('sm')};
  opacity: 0.4;
  ${zIndex(2)};
  ${transition}

  &:hover {
    opacity: 1;
  }
`;

const StyledHeading = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
