import React, { useContext, useMemo, useState } from 'react';

export type UseListResult<T> = [
  T[],
  {
    prepend: (item: T) => void;
    clear: () => void;
    remove: (item: T) => void;
    set: (item: T[]) => void;
  },
];

export function createListContext<T>(): [
  React.FC<React.PropsWithChildren>,
  () => UseListResult<T>,
] {
  const ListContext = React.createContext<UseListResult<T> | undefined>(
    undefined,
  );

  const ListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [list, setList] = useState<T[]>([]);
    const prepend = React.useCallback(
      (item: T): void => setList((state) => [item, ...state]),
      [setList],
    );
    const clear = React.useCallback(() => setList([]), []);
    const remove = React.useCallback(
      (item: T): void => setList((state) => state.filter((v) => v !== item)),
      [setList],
    );
    const set = React.useCallback(
      (items: T[] = []): void => setList(items),
      [setList],
    );
    return (
      <ListContext.Provider
        value={useMemo(
          () => [list, { prepend, clear, remove, set }],
          [list, prepend, clear, remove, set],
        )}
      >
        {children}
      </ListContext.Provider>
    );
  };

  const useList = (): UseListResult<T> => {
    const context = useContext(ListContext);
    if (!context) {
      throw Error('List must be wrapped by list provider');
    }
    return context;
  };

  return [ListProvider, useList];
}
