import * as React from 'react';
export const VoyagerEarth = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M21.27 2.882a.6.6 0 010 .849L3.592 21.409a.6.6 0 01-.848-.849L20.42 2.882a.6.6 0 01.849 0z" />
    <path d="M19.277 4.134a.6.6 0 01-.466.71c-5.666 1.172-9.18 4.677-11.289 7.918a22.92 22.92 0 00-2.187 4.314c-.171.448-.326.902-.463 1.362-.046.153-.088.308-.128.463l-.005.022v.004m0 0a.6.6 0 01-1.17-.27v-.004l.003-.008.007-.03.028-.111c.025-.095.062-.233.114-.407.103-.35.263-.845.492-1.447a24.121 24.121 0 012.303-4.542c2.225-3.42 5.98-7.183 12.052-8.44a.6.6 0 01.71.466m.867.636a.6.6 0 01.465.709c-1.256 6.072-5.02 9.827-8.44 12.052a24.122 24.122 0 01-4.54 2.303c-.603.23-1.1.389-1.449.492-.171.05-.344.098-.517.142l-.03.008-.009.002h-.002s-.002 0-.137-.584l.135.585a.6.6 0 01-.27-1.17m0 0h.005l.022-.006.093-.024a12.4 12.4 0 00.37-.103c.323-.096.79-.246 1.362-.463a22.92 22.92 0 004.313-2.188c3.242-2.11 6.747-5.622 7.92-11.29a.6.6 0 01.71-.465" />
    <path d="M20.7 5.913a.6.6 0 01.65.544c.346 3.88-.408 6.79-1.784 8.958-1.374 2.162-3.332 3.53-5.304 4.394-1.97.862-3.97 1.23-5.47 1.383-.752.078-1.383.102-1.83.106-.22.003-.441 0-.662-.008l-.04-.002h-.013s-.002 0 .033-.6l-.034.6a.6.6 0 11.068-1.199m0 0h.008l.029.002.122.004c.109.003.27.006.478.003.574-.007 1.147-.04 1.718-.1 1.425-.146 3.294-.493 5.112-1.289 1.814-.794 3.558-2.027 4.772-3.938 1.21-1.906 1.929-4.542 1.602-8.207a.6.6 0 01.544-.65M3.958 17.963v-.007l-.002-.029c-.007-.2-.01-.4-.007-.6.005-.415.027-1.009.1-1.718.146-1.425.493-3.294 1.29-5.112.794-1.815 2.027-3.558 3.937-4.772 1.907-1.21 4.542-1.929 8.208-1.602a.6.6 0 10.106-1.195c-3.88-.346-6.79.408-8.957 1.784-2.162 1.374-3.53 3.332-4.394 5.304-.862 1.97-1.23 3.97-1.384 5.47a20.116 20.116 0 00-.106 1.83c-.003.22 0 .441.008.662l.002.038v.015s0 .002.6-.033l-.6.034a.6.6 0 001.199-.069z" />
    <path d="M4.588 4.325a.6.6 0 01.85 0l14.47 14.47a.6.6 0 01-.85.847L4.588 5.173a.6.6 0 010-.848zM9.48 1.551a.6.6 0 01.848 0l12.282 12.28a.599.599 0 01-.42 1.04.6.6 0 01-.428-.192L9.48 2.4a.6.6 0 010-.848zm-7.665 7.14a.6.6 0 01.848 0L15.21 21.235a.599.599 0 01-.42 1.04.6.6 0 01-.428-.192L1.815 9.538a.6.6 0 010-.848z" />
    <path d="M12 2.1c-5.468 0-9.9 4.432-9.9 9.9 0 5.467 4.432 9.9 9.9 9.9 5.467 0 9.9-4.433 9.9-9.9 0-5.468-4.433-9.9-9.9-9.9zM.9 12C.9 5.87 5.87.9 12 .9S23.1 5.87 23.1 12 18.13 23.1 12 23.1.9 18.13.9 12z" />
    <path d="M7.27 8.494a1.703 1.703 0 100-3.405 1.703 1.703 0 000 3.405z" />
  </svg>
);
