import { SaverPortfolio } from '@spaceship-fspl/graphql';
import {
  IconComponent,
  VoyagerEarthIcon,
  VoyagerEarthStarsIcon,
  VoyagerExplorerIcon,
  VoyagerExplorerStarsIcon,
  VoyagerGalaxyIcon,
  VoyagerGalaxyStarsIcon,
  VoyagerOriginIcon,
  VoyagerOriginStarsIcon,
  VoyagerUniverseIcon,
  VoyagerUniverseStarsIcon,
} from '@spaceship-fspl/icons-web';
import {
  VOYAGER_PORTFOLIO_EARTH,
  VOYAGER_PORTFOLIO_EXPLORER,
  VOYAGER_PORTFOLIO_GALAXY,
  VOYAGER_PORTFOLIO_ORIGIN,
  VOYAGER_PORTFOLIO_UNIVERSE,
  VoyagerPortfolio,
} from '@spaceship-fspl/voyager';

export interface VoyagerPortfolioConfig extends VoyagerPortfolio {
  icon: {
    default: IconComponent;
    stars: IconComponent;
  };
}

interface VoyagerPortfolioMap {
  [SaverPortfolio.INDEX]: VoyagerPortfolioConfig;
  [SaverPortfolio.UNIVERSE]: VoyagerPortfolioConfig;
  [SaverPortfolio.EARTH]: VoyagerPortfolioConfig;
  [SaverPortfolio.GALAXY]: VoyagerPortfolioConfig;
  [SaverPortfolio.EXPLORER]: VoyagerPortfolioConfig;
}

export const voyagerPortfolios: VoyagerPortfolioMap = {
  [SaverPortfolio.UNIVERSE]: {
    ...VOYAGER_PORTFOLIO_UNIVERSE,
    icon: {
      default: VoyagerUniverseIcon,
      stars: VoyagerUniverseStarsIcon,
    },
  },
  [SaverPortfolio.EARTH]: {
    ...VOYAGER_PORTFOLIO_EARTH,
    icon: {
      default: VoyagerEarthIcon,
      stars: VoyagerEarthStarsIcon,
    },
  },
  [SaverPortfolio.INDEX]: {
    ...VOYAGER_PORTFOLIO_ORIGIN,
    icon: {
      default: VoyagerOriginIcon,
      stars: VoyagerOriginStarsIcon,
    },
  },
  [SaverPortfolio.GALAXY]: {
    ...VOYAGER_PORTFOLIO_GALAXY,
    icon: {
      default: VoyagerGalaxyIcon,
      stars: VoyagerGalaxyStarsIcon,
    },
  },
  [SaverPortfolio.EXPLORER]: {
    ...VOYAGER_PORTFOLIO_EXPLORER,
    icon: {
      default: VoyagerExplorerIcon,
      stars: VoyagerExplorerStarsIcon,
    },
  },
};
