import { RouteComponentProps, useNavigate } from '@reach/router';
import { Columns, Stack } from '@spaceship-fspl/components';
import { ErrorWithCode, NEW_PASSWORD_RULE } from '@spaceship-fspl/helpers';
import {
  AmplifyAuthResetPasswordErrorCodes,
  useAmplifyAuth,
} from '@spaceship-fspl/super';
import {
  ControllerInput,
  ControllerPasswordInput,
} from 'components/controller-input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { commonValidationRules, requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface SuperLinkResetPasswordNewProps {
  location: {
    state: {
      email?: string;
    };
  };
}

export const SuperLinkResetPasswordNew: React.FC<
  React.PropsWithChildren<RouteComponentProps<SuperLinkResetPasswordNewProps>>
> = ({ location }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { popToast } = useNotifications();
  const { forgotPasswordSubmit } = useAmplifyAuth();
  const email = location?.state?.email;

  const { control, handleSubmit } = useForm<{
    otp: string;
    password: string;
  }>({
    shouldFocusError: true,
  });

  const onSubmit = handleSubmit(async ({ otp, password }) => {
    if (!email) {
      navigate(Routes.SUPER_LINK_RESET_PASSWORD, { replace: true });
      return;
    }

    setIsLoading(true);

    try {
      await forgotPasswordSubmit(email, otp, password);
      popToast({
        level: 'success',
        message: 'Your password has been reset.',
      });
      setIsLoading(false);
      navigate(Routes.SUPER_LINK, { state: { email } });
    } catch (error) {
      const castedError = error as ErrorWithCode;

      addRumError({
        error: castedError,
        context: {
          reason: 'awscognito: forgotPasswordSubmit - unable to reset password',
        },
      });

      popToast({
        level: 'error',
        message:
          castedError.code ===
          AmplifyAuthResetPasswordErrorCodes.OTP_CODE_MISMATCH
            ? castedError.message
            : castedError.code ===
                AmplifyAuthResetPasswordErrorCodes.INVALID_PASSWORD
              ? NEW_PASSWORD_RULE
              : 'There seems to be a problem. Please check your email and try again.',
      });

      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (!email) {
      navigate(Routes.SUPER_LINK_RESET_PASSWORD, { replace: true });
    }
  }, [email, navigate]);

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 3 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="xl">
              <PageHeading
                title="Reset your password"
                subtitle={NEW_PASSWORD_RULE}
              />

              <Stack spaceY="md">
                <ControllerInput
                  name="otp"
                  control={control}
                  type="text"
                  placeholder="Reset code (sent via email)"
                  rules={requiredValidation('Email code')}
                />

                <ControllerPasswordInput
                  name="password"
                  control={control}
                  placeholder="New password"
                  rules={commonValidationRules.password}
                />
              </Stack>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{
                  name: 'super_link_reset_password_new_reset',
                }}
                isLoading={isLoading}
              >
                Reset your password
              </PageFormContinueButton>
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};
