import { ResponsiveMixinFunction } from '@spaceship-fspl/substance-style';

import { map, Media } from './media';

export type ZIndex = 'auto' | -1 | 0 | 1 | 2 | 'max';

export const zIndexes: Record<ZIndex, string | number> = {
  auto: 'auto',
  '-1': -1,
  0: 0,
  1: 1,
  2: 2,
  max: 2147483647, // max z-index value for most browsers
};

export const zIndex: ResponsiveMixinFunction<Media, ZIndex> = (zIndex) =>
  map(zIndex, (index) => `z-index: ${zIndexes[index]};`);
