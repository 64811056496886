import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Columns, Stack } from '@spaceship-fspl/components';
import { MoneyTransactionType } from '@spaceship-fspl/graphql';
import {
  WebAppMoneyDashboardPage,
  WebAppMoneyDashboardPageVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyDashboardPage';
import { PageContainer } from 'components/layouts/page';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import React, { useState } from 'react';

import {
  MoneyActivityCard,
  MoneyActivityCard_MoneyActivityCardFragment,
} from './components/activity-card/money-activity-card';
import {
  MoneyAvailableCard,
  MoneyAvailableCard_MoneyAvailableFragment,
} from './components/money-available-card';
import {
  MoneyReadyToInvestCard_MoneyReadyToInvestFragment,
  ReadyToInvestCard,
} from './components/ready-to-invest-card';

// TODO: revert to 10 when backend up and running
const TRANSACTIONS_CHUNK_SIZE = 8;

export type MoneyFilterOptions = MoneyTransactionType | '';

export const MoneyDashboard: React.FC<RouteComponentProps> = () => {
  const [selectedTypeFilter, setSelectedTypeFilter] =
    useState<MoneyFilterOptions>('');

  const [fetchingMoreTransactions, setFetchingMoreTransactions] =
    useState(false);

  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );

  const resp = useQuery<
    WebAppMoneyDashboardPage,
    WebAppMoneyDashboardPageVariables
  >(
    gql`
      query WebAppMoneyDashboardPage(
        $isMoneyDayOneEnabled: Boolean! = false
        $typeFilters: [MoneyTransactionType!]
        $first: Int
        $after: String
      ) {
        contact {
          id
          account {
            id
            ... on Account @include(if: $isMoneyDayOneEnabled) {
              ...MoneyAvailableCard_MoneyAvailableFragment
              ...MoneyReadyToInvestCard_MoneyReadyToInvestFragment
              moneyInstance {
                id
                ...MoneyActivityCard_MoneyActivityCardFragment
              }
            }
          }
        }
      }
      ${MoneyActivityCard_MoneyActivityCardFragment}
      ${MoneyReadyToInvestCard_MoneyReadyToInvestFragment}
      ${MoneyAvailableCard_MoneyAvailableFragment}
    `,
    {
      variables: {
        isMoneyDayOneEnabled,
        typeFilters: selectedTypeFilter ? [selectedTypeFilter] : undefined,
        first: TRANSACTIONS_CHUNK_SIZE,
      },
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: 'cache-and-network',
      returnPartialData: true,
    },
  );
  const isRefreshing = resp.networkStatus === NetworkStatus.refetch;
  const isLoading = resp.loading || isRefreshing;

  const account = resp.data?.contact?.account;
  const moneyInstance = account?.moneyInstance;

  const handleFetchMoreTransactions = async (): Promise<void> => {
    try {
      setFetchingMoreTransactions(true);
      await resp.fetchMore<WebAppMoneyDashboardPage>({
        variables: {
          after:
            resp.data?.contact?.account?.moneyInstance?.transactions?.pageInfo
              ?.endCursor,
        },
      });
    } finally {
      setFetchingMoreTransactions(false);
    }
  };

  return (
    <PageContainer>
      <Stack spaceY="md">
        <Columns spaceY="md" spaceX="md" alignX="center" alignY="top">
          <Columns.Column width={{ xs: 1, xl: 3 / 8 }}>
            <Columns spaceX="md" spaceY="md">
              <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 1 }}>
                <MoneyAvailableCard isLoading={isLoading} account={account} />
              </Columns.Column>
              <Columns.Column width={{ xs: 1, lg: 1 / 2, xl: 1 }}>
                <ReadyToInvestCard isLoading={isLoading} account={account} />
              </Columns.Column>
            </Columns>
          </Columns.Column>
          <Columns.Column width={{ xs: 1, xl: 5 / 8 }}>
            <MoneyActivityCard
              isLoading={isLoading}
              fetchMoreTransactions={handleFetchMoreTransactions}
              isFetchingMoreTransactions={fetchingMoreTransactions}
              moneyInstance={moneyInstance}
              selectedTransactionTypeFilter={selectedTypeFilter}
              setTransactionTypeFilter={setSelectedTypeFilter}
            />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};
