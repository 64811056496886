import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSuper, useIsAuthenticated } from '@spaceship-fspl/auth';
import { Box, Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { ProductOffering } from '@spaceship-fspl/graphql/src';
import { StreamlineSpaceRocketFlyingIcon } from '@spaceship-fspl/icons-web';
import { useTrack } from '@spaceship-fspl/tracking';
import { Button } from 'components/button';
import { CenterOnboardingContainer } from 'components/layouts/onboarding';
import {
  useDeepLinking,
  useSuperReferralCode,
  useSuperReferrer,
} from 'contexts/deep-linking';
import { useSignupProductIntent } from 'contexts/saver/signup-product-offering';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { useNavigateToSuperCrossSellFromReferral } from 'helpers/hooks/use-navigate-to-super-cross-sell-from-referral';
import { Routes } from 'pages/routes';
import React, { useCallback, useEffect, useRef } from 'react';

export const SuperSignupReferred: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const hasCheckedValidReferral = useRef(false);
  const isLoggedIn = useIsAuthenticated();
  const isSuperUser = useCanReadSuper();
  const { value: code, isReady } = useSuperReferralCode();
  const { value: referrer } = useSuperReferrer();
  const navigateToSuperCrossSell = useNavigateToSuperCrossSellFromReferral();
  const { onSetProduct } = useSignupProductIntent();
  const { storeReferralSearchParams } = useDeepLinking();

  const handleNavigateToSuperOnboarding = useCallback((): void => {
    storeReferralSearchParams(
      new URL(window.location.href).searchParams,
      ProductOffering.VOYAGER,
    );
    onSetProduct(ProductOffering.SUPER);
    navigate(Routes.SIGNUP);
  }, [navigate, onSetProduct, storeReferralSearchParams]);

  useEffect(() => {
    if (isReady && !hasCheckedValidReferral.current) {
      hasCheckedValidReferral.current = true;

      switch (true) {
        case isLoggedIn && isSuperUser:
          navigate(Routes.SUPER_DASHBOARD);
          break;

        case !code && isLoggedIn:
          navigate(Routes.ACCOUNT_CONFIRM_DETAILS, {
            state: {
              nextRoute: Routes.SUPER_SIGNUP_PORTFOLIO,
            },
          });
          break;

        case !code && !isLoggedIn:
          handleNavigateToSuperOnboarding();
          break;

        default:
          track?.(MarketingTrackingEvents.SUPER_REFERRALS_REFERRED_START);
          break;
      }
    }
  }, [
    track,
    code,
    isLoggedIn,
    isReady,
    isSuperUser,
    navigate,
    handleNavigateToSuperOnboarding,
  ]);

  if (!code) {
    return null;
  }

  return (
    <CenterOnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ md: 2 / 3, lg: 1 / 2 }}>
          <Box marginBottom="md" display="flex" justifyContent="center">
            <StreamlineSpaceRocketFlyingIcon
              color="indigo.070"
              size="xxxl"
              strokeWidth={4}
            />
          </Box>

          <Stack spaceY="xl" alignX="center">
            <Stack spaceY="sm">
              <Heading
                variant={3}
                align="center"
                color="black.100"
                component="h1"
              >
                Welcome to Spaceship Super
              </Heading>

              <Text variant={2} align="center">
                Your friend {referrer ? `${referrer} ` : ''}shared Spaceship
                Super with you and we&apos;re excited to welcome you on board.
              </Text>
            </Stack>

            {isLoggedIn ? (
              <Button
                variant="primary"
                size="lg"
                trackingProperties={{ name: 'super_signup_referred_continue' }}
                onClick={navigateToSuperCrossSell}
              >
                Continue
              </Button>
            ) : (
              <Stack spaceY="md" alignX="center">
                <Button
                  variant="secondary"
                  size="lg"
                  trackingProperties={{ name: 'super_signup_referred_login' }}
                  onClick={() =>
                    navigate(Routes.LOGIN, {
                      state: { continueToSuperCrossSell: true },
                    })
                  }
                >
                  Already a Spaceship member?
                </Button>

                <Button
                  variant="primary"
                  size="lg"
                  trackingProperties={{ name: 'super_signup_referred_signup' }}
                  onClick={handleNavigateToSuperOnboarding}
                >
                  I&apos;m new to Spaceship
                </Button>
              </Stack>
            )}
          </Stack>
        </Columns.Column>
      </Columns>
    </CenterOnboardingContainer>
  );
};
