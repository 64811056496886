import * as React from 'react';
export const Briefcase = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M20.43 6.445H3.65a2 2 0 00-2 2v12.327a2 2 0 002 2h16.78a2 2 0 002-2V8.445a2 2 0 00-2-2zM15.75 4.219a2.968 2.968 0 00-2.968-2.969h-1.484a2.969 2.969 0 00-2.969 2.969" />
    <path vectorEffect="non-scaling-stroke" d="M12.524 13.124h-.968a1 1 0 00-1 1v.969a1 1 0 001 1h.968a1 1 0 001-1v-.969a1 1 0 00-1-1z" />
    <path vectorEffect="non-scaling-stroke" d="M22.43 9.414c0 2.617-3.87 4.782-8.906 5.142M1.65 9.414c0 2.617 3.87 4.782 8.906 5.142" />
  </svg>
);
