import * as React from 'react';
export const Award = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M5.363 14.815l-3.434 5.95c-.134.233-.028.388.23.34l3.214-.552 1.11 3.096c.085.252.276.271.41.039l2.927-4.983M20.38 14.815l3.433 5.95c.134.233.028.388-.23.34l-3.213-.552-1.11 3.096c-.086.252-.277.271-.411.039l-2.927-4.983" />
    <path vectorEffect="non-scaling-stroke" d="M12.871 19.265c4.754 0 8.608-3.899 8.608-8.708 0-4.808-3.854-8.707-8.608-8.707-4.754 0-8.608 3.899-8.608 8.707 0 4.81 3.854 8.708 8.608 8.708z" />
    <path vectorEffect="non-scaling-stroke" d="M16.64 8.168l-4.515 4.567a.712.712 0 01-1.014 0L9.466 11.07" />
  </svg>
);
