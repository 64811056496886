import * as React from 'react';
export const StreamlineContentPencilWrite = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M18.506 8.515V3.737c0-.955-.782-1.737-1.738-1.737H6.344c-.956 0-1.738.782-1.738 1.737v13.9c0 .955.782 1.737 1.738 1.737h6.95l2.605 2.606v-2.606h.87c.616 0 1.18-.321 1.493-.851M2.877 4.84A1.752 1.752 0 002 6.352v13.9c0 .955.782 1.737 1.737 1.737h6.95c.608 0 1.173-.322 1.486-.843m-5.83-16.54h3.475m-1.737 3.91v-3.91m7.14 3.231l-3.648-1.042 1.043 3.649m2.606-2.607l6.237 6.238a1.84 1.84 0 010 2.606 1.84 1.84 0 01-2.606 0l-6.237-6.238m2.606-2.606l-2.606 2.607m5.455 5.455l2.606-2.606m-8.252-8.687h3.474m-8.687 6.95h3.475m-3.475 2.606h5.647m-5.647 2.606h7.819" />
  </svg>
);
