import { Color, getColor, getNumericSpace } from '@spaceship-fspl/styles';
import ChartJS from 'chart.js';
import React, { Fragment, memo, useContext, useEffect, useRef } from 'react';
import { ThemeContext } from 'styled-components';

import { ChartLabels } from './chart-labels';

interface ChartProps {
  data: { x: number; y: number }[];
  color?: Color;
  endColor?: Color;
  isThin?: boolean;
  tooltipEnabled?: boolean;
  tooltipBackgroundColor?: Color;
  tooltipTextColor?: Color;
  labelColor?: Color;
  endLabels?: boolean;
  formatX?: (value: string | number) => string;
  formatY?: (value: string | number) => string;
  hideTodayLabel?: boolean;
}

ChartJS.Tooltip.positioners.custom = (
  elements,
  eventPosition,
): { x: number; y: number } => {
  const TOOLTIP_CLIP_OFFSET = getNumericSpace('xs');
  if (elements.length < 1) {
    return { x: Math.max(eventPosition.x, TOOLTIP_CLIP_OFFSET), y: 0 };
  }
  const width = elements[0]._chart.width;
  return {
    x: Math.min(
      Math.max(eventPosition.x, TOOLTIP_CLIP_OFFSET),
      width - TOOLTIP_CLIP_OFFSET,
    ),
    y: 0,
  };
};

// Parent MUST be relatively positioned
// and have no children other than Chart
export const Chart = memo(
  ({
    data,
    color = 'indigo.050',
    endColor = color,
    isThin = false,
    endLabels = false,
    tooltipEnabled = false,
    tooltipBackgroundColor = 'neutral.100',
    tooltipTextColor = 'neutral.000',
    labelColor = 'neutral.080',
    formatX = (value: string | number): string => value.toString(),
    formatY = (value: string | number): string => value.toString(),
    hideTodayLabel,
  }: ChartProps): JSX.Element => {
    const canvasRef = useRef<HTMLCanvasElement>();
    const chartRef = useRef<ChartJS>();
    const themeContext = useContext(ThemeContext);

    // Init the chart if it doesn't exist
    useEffect(() => {
      if (canvasRef.current && !chartRef.current) {
        chartRef.current = new ChartJS(canvasRef.current, {
          type: 'line',
          data: {
            labels: data.map(({ x }) => formatX(x)),
            datasets: [
              {
                data,
                fill: false,
                borderColor: color,
                borderWidth: isThin ? 2 : 3,
                pointRadius: 0, // hide point
              },
            ],
          },
          options: {
            layout: {
              padding: {
                top: tooltipEnabled ? getNumericSpace('lg') : 0,
              },
            },
            animation: { duration: 150 },
            events: [
              'mousemove',
              'mouseout',
              'click',
              'touchstart',
              'touchmove',
              'touchend',
            ],
            hover: {
              mode: 'x',
            },
            tooltips: {
              callbacks: {
                label: ({ yLabel }): string => (yLabel ? formatY(yLabel) : ''),
              },
              enabled: tooltipEnabled,
              position: 'custom',
              mode: 'x-axis',
              backgroundColor: getColor(tooltipBackgroundColor),
              bodyFontColor: getColor(tooltipTextColor),
              bodyFontFamily: themeContext.fontFamilies.text,
              titleFontFamily: themeContext.fontFamilies.text,
              titleFontColor: getColor(tooltipTextColor),
              bodyAlign: 'center',
              titleAlign: 'center',
              displayColors: false,
              caretSize: 0,
            },
            maintainAspectRatio: false,
            legend: { display: false },
            scales: {
              xAxes: [{ display: false }],
              yAxes: [{ display: false }],
            },
          },
        });
      }
    }, [
      canvasRef,
      color,
      data,
      endColor,
      endLabels,
      formatX,
      formatY,
      labelColor,
      isThin,
      tooltipBackgroundColor,
      tooltipEnabled,
      tooltipTextColor,
      themeContext.fontFamilies.text,
    ]);

    useEffect(() => {
      if (chartRef?.current?.data.datasets) {
        chartRef.current.data.datasets[0]!.data = data;
        chartRef.current.data.labels = data.map(({ x }) => formatX(x));
        chartRef.current.update();
      }
    }, [data, formatX]);

    useEffect(() => {
      if (chartRef?.current?.data.datasets && canvasRef?.current) {
        const ctx = canvasRef.current.getContext('2d');
        let lineGradient: CanvasGradient | undefined;
        if (ctx && endColor) {
          lineGradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
          lineGradient.addColorStop(0, getColor(color) as string);
          lineGradient.addColorStop(1, getColor(endColor) as string);
        }

        chartRef.current.data.datasets[0]!.borderColor = lineGradient;
        chartRef.current.update();
      }
    }, [color, endColor]);

    // Clean up
    useEffect(() => {
      return (): void => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, []);

    const firstData = data[0];
    const lastData = data[data.length - 1];
    return (
      <Fragment>
        <canvas
          ref={(ref): void => {
            if (ref) {
              canvasRef.current = ref;
            }
          }}
        />
        {endLabels && firstData && lastData && (
          <ChartLabels
            start={firstData.x}
            end={lastData.x}
            labelColor={labelColor}
            hideTodayLabel={hideTodayLabel}
          />
        )}
      </Fragment>
    );
  },
);

Chart.displayName = 'Chart';
