import * as React from 'react';
export const StreamlineSingleManBook = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M18.983 2H5.505C4.783 2 4.2 2.583 4.2 3.304m0 0c0 .722.583 1.305 1.305 1.305h13.913a.43.43 0 01.434.434v16.522a.43.43 0 01-.434.435H5.939c-.956 0-1.739-.783-1.739-1.74V3.305zM10.21 8.94a4.34 4.34 0 004.835.973m1.765 8.609a4.35 4.35 0 00-4.348-4.348 4.35 4.35 0 00-4.348 4.348h8.696zM12.46 12.87a2.605 2.605 0 002.609-2.61 2.605 2.605 0 00-2.609-2.608 2.605 2.605 0 00-2.609 2.609 2.605 2.605 0 002.61 2.609z" />
  </svg>
);
