import * as React from 'react';
export const StreamlineConversationChatBubble = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1 23a4.4 4.4 0 118.8 0m11.341-10.267a2.933 2.933 0 11-5.475 1.467M14.2 23a4.4 4.4 0 118.8 0M11.267 3.933h5.866m-5.866 2.934h5.866M5.4 17.133a2.933 2.933 0 100-5.866 2.933 2.933 0 000 5.866zM8.333 9.8h2.934v4.4l4.4-4.4h4.4V1H8.333v8.8z" />
  </svg>
);
