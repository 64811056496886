import {
  ActionLink,
  Box,
  Heading,
  Inline,
  Spinner,
  Stack,
} from '@spaceship-fspl/components';
import { FeatherLockIcon } from '@spaceship-fspl/icons-web';
import { PaddingProps, themes } from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { LinkButton } from 'components/link-button';
import { RouterLink } from 'components/router-link';
import { Tooltip } from 'components/tooltip';
import { TrackingProperties } from 'helpers/analytics';
import { Routes } from 'pages/routes';
import React from 'react';
import { ThemeProvider } from 'styled-components';

export const AccountCard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <Stack spaceY="xs">{children}</Stack>;
};

AccountCard.displayName = 'AccountCard';

export interface AccountCardEditProps {
  isEditing: boolean;
  canUpdateBankAccount?: boolean | null;
  text?: string;
  submitText?: string;
  onClick?: () => void;
  to?: Routes;
  isLoading?: boolean;
  editTrackingProperties?: TrackingProperties;
  submitTrackingProperties?: TrackingProperties;
  cancelTrackingProperties?: TrackingProperties;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  onCancel?: () => void;
  isSubmitDisabled?: boolean;
}

export interface AccountCardHeadingProps {
  title: string;
  isReadOnly?: boolean;
  edit?: AccountCardEditProps;
}

export const AccountCardHeading: React.FC<
  React.PropsWithChildren<AccountCardHeadingProps>
> = ({ title, isReadOnly, edit }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Heading variant={5} component="h3">
        {title}
      </Heading>

      {isReadOnly && (
        <Tooltip content="This information can’t be changed at this time.">
          <span>
            <FeatherLockIcon
              color="neutral.080"
              size="sm"
              testId="feather-lock-icon"
            />
          </span>
        </Tooltip>
      )}

      {!isReadOnly &&
        !!edit &&
        !edit.isEditing &&
        (edit.isLoading ? (
          <Spinner size="sm" />
        ) : (
          <>
            {edit.to && (
              <RouterLink
                to={edit.to}
                onClick={edit.onClick}
                trackingProperties={
                  edit.editTrackingProperties ?? {
                    name: `${title.toLowerCase().split(' ').join('_')}_${
                      edit.text ? edit.text.split(' ').join('_') : 'edit'
                    }`,
                  }
                }
              >
                <ActionLink color="indigo.070" size="xs" component="span">
                  {edit.text || 'Edit'}
                </ActionLink>
              </RouterLink>
            )}

            {!edit.to && edit.onClick && (
              <LinkButton
                size="xs"
                onClick={edit.onClick}
                trackingProperties={
                  edit.editTrackingProperties ?? {
                    name: `${title.toLowerCase().split(' ').join('_')}_${
                      edit.text ? edit.text.split(' ').join('_') : 'edit'
                    }`,
                  }
                }
              >
                {edit.text || 'Edit'}
              </LinkButton>
            )}
          </>
        ))}
      {!isReadOnly &&
        !!edit &&
        edit.isEditing &&
        (edit.isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Box display="flex" flexDirection="row" justifyContent="space-around">
            {!!edit.onCancel && (
              <Box>
                <Button
                  trackingProperties={
                    edit.cancelTrackingProperties ?? {
                      name: `${title
                        .toLowerCase()
                        .split(' ')
                        .join('_')}_cancel`,
                    }
                  }
                  onClick={edit.onCancel}
                  variant="secondary"
                  size="sm"
                >
                  Cancel
                </Button>
              </Box>
            )}
            {!!edit.onSubmit && (
              <Box paddingLeft="xxs">
                <Button
                  trackingProperties={
                    edit.submitTrackingProperties ?? {
                      name: `${title.toLowerCase().split(' ').join('_')}_${
                        edit.submitText
                          ? edit.submitText
                              .toLocaleLowerCase()
                              .split(' ')
                              .join('_')
                          : 'save'
                      }`,
                    }
                  }
                  isLoading={edit.isSubmitting}
                  isDisabled={edit.isSubmitDisabled}
                  onClick={edit.onSubmit}
                  variant="primary"
                  size="sm"
                >
                  {edit.submitText || 'Save'}
                </Button>
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};

AccountCardHeading.displayName = 'AccountCardHeading';

export interface AccountCardContentProps {
  isEmptyContent?: boolean;
  isLoading?: boolean;
  padding?: PaddingProps;
}

export const AccountCardContent: React.FC<
  React.PropsWithChildren<AccountCardContentProps>
> = ({
  children,
  isEmptyContent = false,
  isLoading = false,
  padding = { padding: { xs: 'md', md: 'lg' } },
}) => {
  if (isEmptyContent) {
    return (
      <Box
        backgroundColor="neutral.050"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="sm"
        {...padding}
      >
        {children}
      </Box>
    );
  }

  return (
    <ThemeProvider theme={themes.webapp.onWhite}>
      <Box
        backgroundColor="neutral.000"
        borderRadius="sm"
        boxShadow="sm"
        {...padding}
      >
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            minHeight={120}
            width="100%"
          >
            <Spinner />
          </Box>
        ) : (
          children
        )}
      </Box>
    </ThemeProvider>
  );
};

AccountCardContent.displayName = 'AccountCardContent';

export interface AccountCardEditFormButtonsProps {
  cancel: {
    onClick: () => void;
    trackingProperties: TrackingProperties;
  };
  submitTrackingProperties: TrackingProperties;
  isLoading?: boolean;
}

export const AccountCardEditFormButtons: React.FC<
  React.PropsWithChildren<AccountCardEditFormButtonsProps>
> = ({ cancel, submitTrackingProperties, isLoading }) => {
  return (
    <Box marginTop="md">
      <Inline alignX="right" spaceX="xs">
        <Button
          variant="secondary"
          size="sm"
          onClick={cancel.onClick}
          isDisabled={isLoading}
          trackingProperties={cancel.trackingProperties}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant="primary"
          size="sm"
          isLoading={isLoading}
          trackingProperties={submitTrackingProperties}
        >
          Save
        </Button>
      </Inline>
    </Box>
  );
};
