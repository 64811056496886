import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Divider,
  Heading,
  InfoPanel,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useGetUser } from '@spaceship-fspl/data';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { formatCurrency, formatDate } from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  getTransactionDescription,
  Transaction,
  useGetTransactions,
} from '@spaceship-fspl/super';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import { DATE_FORMAT_TRANSACTIONS } from 'components/transactions';
import { format } from 'date-fns';
import { Decimal } from 'decimal.js';
import {
  JoinedBeforeSftState,
  useJoinedBeforeSft,
} from 'helpers/hooks/use-joined-before-sft';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';

export const SuperTransactions: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const accountId = useGetUser().data?.contact?.account_id;
  const resp = useGetTransactions({ accountId });
  const flagsResp = useSuperPortfolioFlags();
  const { state: joinedBeforeSftState } = useJoinedBeforeSft();
  const sftPostMigrationTransactionHistoryDisclaimer =
    flagsResp.data?.superPortfolioFlags
      ?.sftPostMigrationTransactionHistoryDisclaimer;
  const groupedTransactions = useMemo(() => {
    const group: { [k: string]: Transaction[] } = {};
    if (resp.data?.pages) {
      const flattenTransactions = resp.data.pages.flatMap((item) => item.data);

      if (flattenTransactions.length) {
        flattenTransactions
          .sort((a, b) => {
            if (!a?.paidDate || !b?.paidDate) {
              return 0;
            }
            return a.paidDate < b.paidDate ? 1 : -1;
          })
          .forEach((tx?: Transaction) => {
            if (tx?.paidDate) {
              const day = formatDate(
                new Date(tx.paidDate),
                DATE_FORMAT_TRANSACTIONS,
              );
              const transactionsByDay = (group[day] = group[day] || []);
              transactionsByDay.push(tx);
            }
          });
        return group;
      }
    }

    return undefined;
  }, [resp.data?.pages]);

  const sftLink =
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimerLink;
  const sftLimitedServicePeriodDisclaimer =
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimer;
  const sftLimitedServicePeriodEnabled =
    sftLink &&
    sftLimitedServicePeriodDisclaimer &&
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;
  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 1, lg: 10 / 12 }}>
          <Stack spaceY={'sm'}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Heading variant={3}>Transactions</Heading>
              {!sftLimitedServicePeriodEnabled && (
                <Button
                  onClick={() => {
                    navigate(Routes.SUPER_CONTRIBUTIONS);
                  }}
                  variant="primary"
                  size="sm"
                  trackingProperties={{
                    name: 'super_transactions_contribute',
                  }}
                >
                  Contribute
                </Button>
              )}
            </Box>
            {sftLimitedServicePeriodEnabled ? (
              <InfoPanel
                textVariant={3}
                color="pink.030"
                icon={FeatherInfoIcon}
              >
                {sftLimitedServicePeriodDisclaimer} Read more about the transfer{' '}
                <TextLink href={sftLink} target="_blank" color="indigo.070">
                  here
                </TextLink>
                .
              </InfoPanel>
            ) : null}
            <Card
              minHeight={!resp.isLoading && groupedTransactions ? '0' : '80vh'}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {resp.isLoading ? (
                <Spinner />
              ) : !groupedTransactions ? (
                <Text
                  variant={2}
                  align="center"
                  color="neutral.080"
                  isBold={true}
                >
                  No transactions to display at this time.
                </Text>
              ) : (
                <Box width="100%">
                  <Box paddingX="lg" paddingY="xs">
                    <Heading variant={5} isBold={true}>
                      History
                    </Heading>
                  </Box>
                  {Object.keys(groupedTransactions).map(
                    (groupedTransactionsDate) => (
                      <React.Fragment key={groupedTransactionsDate}>
                        <Divider />
                        <Box paddingY="md" paddingX="lg">
                          <Columns spaceY={{ xs: 'xs', lg: 'none' }}>
                            <Columns.Column width={{ xs: 1, lg: 1 / 3 }}>
                              <Text
                                variant={4}
                                color="neutral.080"
                                isBold={true}
                              >
                                {groupedTransactionsDate
                                  ? format(
                                      new Date(groupedTransactionsDate),
                                      DATE_FORMAT_TRANSACTIONS,
                                    )
                                  : ''}
                              </Text>
                            </Columns.Column>
                            <Columns.Column width={{ xs: 1, lg: 2 / 3 }}>
                              <div>
                                {groupedTransactions[
                                  groupedTransactionsDate
                                ]?.map((transaction) => {
                                  const amount =
                                    transaction.amountIn?.amount ||
                                    transaction.amountOut?.amount;
                                  return (
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      key={transaction.id}
                                      width="100%"
                                    >
                                      <Text variant={3}>
                                        {getTransactionDescription(
                                          transaction.transactionType,
                                          transaction.coa,
                                        )}
                                      </Text>

                                      <Text variant={3} align="right">
                                        {amount
                                          ? formatCurrency(
                                              new Decimal(amount)
                                                .dividedBy(100)
                                                .toNumber(),
                                            )
                                          : '-'}
                                      </Text>
                                    </Box>
                                  );
                                })}
                              </div>
                            </Columns.Column>
                          </Columns>
                        </Box>
                      </React.Fragment>
                    ),
                  )}
                  {resp.hasNextPage && (
                    <Box paddingY="md" display="flex" justifyContent="center">
                      <Button
                        variant="tertiary"
                        size="lg"
                        trackingProperties={{
                          name: 'super_transactions_load_more',
                        }}
                        onClick={() => {
                          resp.fetchNextPage();
                        }}
                        isLoading={resp.isFetchingNextPage}
                      >
                        Load more
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Card>
            {joinedBeforeSftState === JoinedBeforeSftState.JOINED_BEFORE ? (
              <InfoPanel color={'indigo.040'} icon={FeatherInfoIcon}>
                {sftPostMigrationTransactionHistoryDisclaimer}
              </InfoPanel>
            ) : null}
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
