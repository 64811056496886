import fetch from 'cross-fetch';

import { config } from './config';

const { baseUrl, apiKey } = config.bankStatementsApi;

type APIMethod = 'GET' | 'POST';
enum APIRoute {
  PRELOAD = '/cs_preload',
  LOGIN = '/cs_login',
  LOGOUT = '/cs_logout',
  MFA = '/cs_mfa',
}

type BooleanResponse = '0' | '1';
type FieldType =
  | 'TEXT'
  | 'password'
  | 'captcha'
  | 'input'
  | 'select'
  | 'options'
  | 'header'
  | 'instructions'
  | 'image'
  | 'button'
  | 'hidden'
  | 'set'
  | 'html'
  | 'javascript';
type Region =
  | '' // Global - all regions
  | 'au' // Should only be this one
  | 'nz'
  | 'uk';
type KeyboardType = '' | 'default' | 'number';
type InstitutionType = 'bank';
type AccountType =
  | ''
  | 'transaction'
  | 'savings'
  | 'credit card'
  | 'personal loan'
  | 'home loan'
  | 'car loan'
  | 'insurance'
  | 'superannuation'
  | 'investments';

export interface RequestData {
  method: APIMethod;
  route: APIRoute;
  guid?: string;
  headers?: Record<string, unknown>;
  payload?: Record<string, unknown>;
}

const apiRequest = async ({
  method,
  route,
  headers,
  guid,
  payload,
}: RequestData): Promise<Record<string, unknown>> => {
  const response = await fetch(`${baseUrl}${route}${guid && `/${guid}`}`, {
    method,
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': apiKey,
      ...headers,
    },
  });

  if (!response.ok) {
    throw await response.json();
  }

  return response.json();
};

interface Validation {
  minLength?: number;
  maxLength?: number;
  chars?: string;
}

export interface CredentialsField {
  id?: string;
  name?: string;
  fieldID?: string;
  type?: FieldType;
  description?: string;
  keyboardType?: KeyboardType;
  values?: string | Record<string, string>; // Only if type = select
  validation?: Validation;
  optional?: boolean;
  src?: string; // Only if type = captcha
  width?: number; // Only if type = captcha
  height?: number; // Only if type = captcha
  alt?: string; // Only if type = captcha
}

export interface MFAField {
  type?: FieldType;
  id?: string;
  label?: string;
  value?: string;
  subElements?: MFAField[];
  options?: { [key: string]: string };
  src?: string; // Only if type = image
  width?: number; // Only if type = image
  height?: number; // Only if type = image
  alt?: string; // Only if type = image
}

export interface Institution {
  slug?: string;
  name?: string;
  credentials?: CredentialsField[];
  status?: string;
  available?: BooleanResponse;
  searchable?: BooleanResponse;
  display?: BooleanResponse;
  searchVal?: string;
  region?: Region;
  export_with_password?: BooleanResponse;
  estatements_supported?: BooleanResponse;
  transaction_listings_supported?: BooleanResponse;
  requires_preload?: BooleanResponse;
  requires_mfa?: BooleanResponse;
  updated_at?: string;
  max_days?: string;
  type?: InstitutionType;
  card_validation_supported?: BooleanResponse;
  is_business_bank?: BooleanResponse;
  ocr_supported?: BooleanResponse;
  do_not_proxy?: BooleanResponse;
  get_address_supported?: BooleanResponse;
  supports_payment_summaries?: BooleanResponse;
}

export interface LoginPreloadResponse {
  institution?: Institution;
  user_token?: string;
}

/**
 * Get login preload
 *
 * @param institutionSlug slug
 */
export const loginPreload = async (
  institutionSlug: string,
  userToken: string,
  guid: string,
): Promise<LoginPreloadResponse> => {
  const response: LoginPreloadResponse = await apiRequest({
    method: 'POST',
    route: APIRoute.PRELOAD,
    guid,
    payload: {
      institution: institutionSlug,
      user_token: userToken,
    },
  });
  return response;
};

export interface LoginRequest {
  credentials: {
    institution: string;
    [fieldId: string]: string;
  };
  referral_code?: string;
}

export interface Account {
  id?: number;
  name?: string;
  bsb?: string;
  accountNumber?: string;
  accountType?: AccountType;
  accountHolder?: string;
  balance?: string;
  available?: string;
  interestRate?: string;
}

export interface MFARequest {
  title?: string;
  fields?: MFAField[];
}

export interface LoginResponse {
  accounts?: Account[];
  mfa?: MFARequest;
  user_token?: string;
}

/**
 * Simple login.
 *
 * @param loginRequest User credentials
 * @param userToken    This may be a required parameter if a Login Preload was
 *                     made prior to this request.
 */
export const login = async (
  loginRequest: LoginRequest,
  userToken: string,
  guid: string,
): Promise<LoginResponse> =>
  apiRequest({
    method: 'POST',
    route: APIRoute.LOGIN,
    payload: { ...loginRequest, user_token: userToken },
    guid,
  });

export interface MFALoginRequest {
  [field_id: string]: string;
  user_token: string;
}

export interface MFAResponse {
  accounts?: Account[];
  user_token?: string;
}

/**
 * MFA Login.
 *
 * @param loginRequest
 */
export const mfaLogin = async (
  loginRequest: MFALoginRequest,
  userToken: string,
  guid: string,
): Promise<MFAResponse> => {
  const response: MFAResponse = await apiRequest({
    method: 'POST',
    route: APIRoute.MFA,
    payload: { ...loginRequest, user_token: userToken },
    guid,
  });
  return response;
};

/**
 * Log user out of session.
 *
 * @param userToken This should be the user_token value from the current
 *                  session, which you want to terminate.
 */
export const logout = async (userToken: string): Promise<boolean> => {
  await apiRequest({
    method: 'POST',
    route: APIRoute.LOGOUT,
    payload: {
      user_token: userToken,
    },
  });
  return true;
};
