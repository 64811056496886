import { ResponsiveMixinFunction } from '@spaceship-fspl/substance-style';

import { map, Media } from './media';
import { spaces } from './space';

export const borderWidths = {
  none: '0',
  sm: '1px',
  md: '2px',
  lg: '4px',
};

export type BorderWidth = keyof typeof borderWidths;

export const borderWidth: ResponsiveMixinFunction<Media, BorderWidth> = (
  width,
) => map(width, (index) => `border-width: ${borderWidths[index]};`);
export const borderWidthTop: ResponsiveMixinFunction<Media, BorderWidth> = (
  width,
) => map(width, (index) => `border-top-width: ${borderWidths[index]};`);
export const borderWidthBottom: ResponsiveMixinFunction<Media, BorderWidth> = (
  width,
) => map(width, (index) => `border-bottom-width: ${borderWidths[index]};`);
export const borderWidthLeft: ResponsiveMixinFunction<Media, BorderWidth> = (
  width,
) => map(width, (index) => `border-left-width: ${borderWidths[index]};`);
export const borderWidthRight: ResponsiveMixinFunction<Media, BorderWidth> = (
  width,
) => map(width, (index) => `border-right-width: ${borderWidths[index]};`);

export type BorderRadius = keyof typeof borderRadii;

export const borderRadii = spaces;

export const borderRadius: ResponsiveMixinFunction<Media, BorderRadius> = (
  radius,
) => map(radius, (index) => `border-radius: ${borderRadii[index]};`);
export const borderRadiusTopLeft: ResponsiveMixinFunction<
  Media,
  BorderRadius
> = (radius) =>
  map(radius, (index) => `border-top-left-radius: ${borderRadii[index]};`);
export const borderRadiusTopRight: ResponsiveMixinFunction<
  Media,
  BorderRadius
> = (radius) =>
  map(radius, (index) => `border-top-right-radius: ${borderRadii[index]};`);
export const borderRadiusBottomLeft: ResponsiveMixinFunction<
  Media,
  BorderRadius
> = (radius) =>
  map(radius, (index) => `border-bottom-left-radius: ${borderRadii[index]};`);
export const borderRadiusBottomRight: ResponsiveMixinFunction<
  Media,
  BorderRadius
> = (radius) =>
  map(radius, (index) => `border-bottom-right-radius: ${borderRadii[index]};`);
