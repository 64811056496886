import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { TaxGroup } from '@spaceship-fspl/graphql';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  AutoCompleteDropdown,
  AutoCompleteDropdownProps,
  Option,
} from 'components/autocomplete-dropdown';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import { RadioButton } from 'components/radio-button';
import { useNotifications } from 'contexts/notifications';
import { useOnboardingRequestContext } from 'contexts/saver/onboarding';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { TaxResidencyCard } from './card';
import { TaxResidencyFAQ } from './faq';
import {
  ForeignTaxResidenceField,
  TaxResidencyFormData,
  useTaxResidencyForm,
} from './hooks';

const TAX_RESIDENCY_OPTIONS = [
  { label: 'No', value: 'no' },
  { label: 'Yes', value: 'yes' },
];

interface VoyagerAddTaxResidencyCountriesProps {
  location: {
    state: {
      taxGroup?: TaxGroup;
    };
  };
}

export const VoyagerAddTaxResidencyCountries: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<VoyagerAddTaxResidencyCountriesProps>
  >
> = ({ location }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !location?.state?.taxGroup ||
      location.state.taxGroup === TaxGroup.AUSTRALIA_ONLY
    ) {
      navigate(Routes.VOYAGER_TAX_RESIDENCY_ADD);
    }
  }, [location, navigate]);

  return (
    <VoyagerTaxResidency
      variant="add"
      taxGroup={location?.state?.taxGroup}
      onSubmit={(foreignTaxResidences) => {
        if (foreignTaxResidences) {
          navigate?.(Routes.VOYAGER_TAX_RESIDENCY_ADD_PRIMARY_ADDRESS, {
            state: {
              taxGroup: location?.state?.taxGroup,
              foreignTaxResidences,
            },
          });
        }
      }}
    />
  );
};

interface VoyagerUpdateTaxResidencyCountriesProps {
  location: {
    state: {
      taxGroup?: TaxGroup;
    };
  };
}

export const VoyagerUpdateTaxResidencyCountries: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<VoyagerUpdateTaxResidencyCountriesProps>
  >
> = ({ location }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !location?.state?.taxGroup ||
      location.state.taxGroup === TaxGroup.AUSTRALIA_ONLY
    ) {
      navigate(Routes.VOYAGER_TAX_RESIDENCY);
    }
  }, [location, navigate]);

  return (
    <VoyagerTaxResidency
      variant="update"
      taxGroup={location?.state?.taxGroup}
      onSubmit={(foreignTaxResidences) => {
        if (foreignTaxResidences) {
          navigate?.(Routes.VOYAGER_TAX_RESIDENCY_PRIMARY_ADDRESS, {
            state: {
              taxGroup: location?.state?.taxGroup,
              foreignTaxResidences,
            },
          });
        }
      }}
    />
  );
};

export const VoyagerOnboardingTaxResidency: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const [request] = useOnboardingRequestContext();
  const track = useTrack();

  return (
    <VoyagerTaxResidency
      variant="onboarding"
      onSubmit={() => {
        track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_TAX_RESIDENCY_PAGE);
        if (request?.createSaverAccount?.portfolio) {
          navigate(Routes.VOYAGER_ONBOARDING_GREENID);
        } else {
          navigate(Routes.VOYAGER_ONBOARDING_PORTFOLIO);
        }
      }}
    />
  );
};

interface VoyagerTaxResidencyProps {
  variant: 'onboarding' | 'add' | 'update';
  taxGroup?: TaxGroup;
  onSubmit: (foreignTaxResidences?: Array<ForeignTaxResidenceField>) => void;
}

export const VoyagerTaxResidency: React.FC<
  React.PropsWithChildren<VoyagerTaxResidencyProps>
> = ({ variant, taxGroup, onSubmit }) => {
  const { fetchLoading } = useTaxResidencyForm();

  return (
    <PageContainer>
      <Stack spaceY="xxxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 6 / 12, lg: 4 / 12 }}>
            <Stack spaceY="sm">
              <Heading
                component="h1"
                variant={3}
                align={{ xs: 'left', md: 'center' }}
              >
                Your tax residency
              </Heading>
              {fetchLoading ? (
                <Box display="flex" justifyContent="center" padding="lg">
                  <Spinner />
                </Box>
              ) : (
                <TaxResidencyQuestions
                  variant={variant}
                  taxGroup={taxGroup}
                  onSubmit={onSubmit}
                />
              )}
            </Stack>
          </Columns.Column>
        </Columns>
        <TaxResidencyFAQ />
      </Stack>
    </PageContainer>
  );
};

const TaxResidencyQuestions: React.FC<
  React.PropsWithChildren<VoyagerTaxResidencyProps>
> = ({ variant, taxGroup, onSubmit }) => {
  const isAddressCaptureFlow = variant !== 'onboarding';
  const notification = useNotifications();

  const {
    updateLoading,
    initialFormValues,
    handleAustraliaAndOverseasSubmit,
    handleAustraliaOnlySubmit,
  } = useTaxResidencyForm();

  const form = useForm<TaxResidencyFormData>({
    defaultValues: {
      ...initialFormValues,
      isForeignTaxResident: isAddressCaptureFlow
        ? 'yes'
        : initialFormValues.isForeignTaxResident,
    },
    reValidateMode: 'onBlur',
  });

  const isFormDirty = form.formState.isDirty;
  const isForeignTaxResident = form.watch().isForeignTaxResident;
  const foreignTaxResidences = form.watch().foreignTaxResidences;
  const hasSelectedForeignTaxResidence = isForeignTaxResident === 'yes';

  const handleSubmit = async (values: TaxResidencyFormData): Promise<void> => {
    if (isAddressCaptureFlow) {
      if (values.foreignTaxResidences) {
        onSubmit(values.foreignTaxResidences);
      }
      return;
    }

    try {
      if (values.isForeignTaxResident === 'yes') {
        await handleAustraliaAndOverseasSubmit(values);
      } else if (values.isForeignTaxResident === 'no') {
        await handleAustraliaOnlySubmit();
      } else {
        throw Error('Please specify your tax residency status.');
      }

      if (variant !== 'onboarding') {
        notification.popToast({
          level: 'success',
          message: 'Your tax residency details have been updated',
        });
      }

      onSubmit();
    } catch (err) {
      notification.popToast({
        level: 'error',
        message: (err as Error).message,
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spaceY="md">
          {isAddressCaptureFlow ? (
            <Stack spaceY="md">
              <Text variant={2} isBold={true} align="center">
                {taxGroup === TaxGroup.OVERSEAS_ONLY
                  ? 'Which countries are you a tax resident of?'
                  : 'Which countries are you a tax resident of, other than Australia?'}
              </Text>

              <TaxResidencyForm />
            </Stack>
          ) : (
            <Stack spaceY="md">
              <Text variant={2} isBold={true}>
                Are you a tax resident of a country other than Australia?
              </Text>

              <Columns spaceX="xxs">
                {TAX_RESIDENCY_OPTIONS.map((option) => (
                  <Columns.Column key={option.value} width={1 / 2}>
                    <RadioButton
                      value={option.value}
                      defaultChecked={option.value === isForeignTaxResident}
                      {...form.register('isForeignTaxResident')}
                    >
                      <Text variant={2} isBold={true} align="center">
                        {option.label}
                      </Text>
                    </RadioButton>
                  </Columns.Column>
                ))}
              </Columns>

              {hasSelectedForeignTaxResidence && <TaxResidencyForm />}
            </Stack>
          )}

          <Text variant={3} color="neutral.080">
            By proceeding you confirm your information about tax residency is
            correct.
          </Text>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="primary"
              size="lg"
              trackingProperties={{ name: 'tax_residency_continue' }}
              isDisabled={
                hasSelectedForeignTaxResidence && !foreignTaxResidences?.length
              }
              type="submit"
              isLoading={updateLoading}
            >
              {isFormDirty && !isAddressCaptureFlow
                ? 'Save changes'
                : 'Continue'}
            </Button>
            {isFormDirty && !isAddressCaptureFlow && (
              <Box marginTop="sm">
                <Button
                  variant="secondary"
                  size="lg"
                  trackingProperties={{ name: 'tax_residency_cancel' }}
                  type="reset"
                  onClick={form.reset}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};

export const TaxResidencyForm: React.FC<React.PropsWithChildren> = () => {
  const { countries } = useTaxResidencyForm();
  const { control } = useFormContext<TaxResidencyFormData>();
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'foreignTaxResidences',
    keyName: 'fieldId',
  });

  const countryOptions = countries
    .slice() // copy before sorting in place
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .filter(
      (country) =>
        !fields.find((field) => field.countryId === country.id) &&
        country.name != 'Australia',
    )
    .map((country) => ({
      label: country.name,
      value: country.id,
    }));

  const handleDropdownSelect: AutoCompleteDropdownProps['onChange'] = (
    option,
  ) => {
    // FIXME: option return type - we know we're returning a single option,
    // not an array of options (in the case of multi-select).
    // @see: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
    // @see: https://github.com/JedWatson/react-select/issues/2902
    const opt = option as Option;
    const selectedCountry = countries.find(
      (country) => country.id === opt.value,
    );
    prepend({
      id: '',
      countryId: opt.value,
      countryName: opt.label,
      tinProvided: false,
      tinRequired: selectedCountry?.tinRequired ?? true,
      tinInformationLink: selectedCountry?.tinInformationLink ?? '',
    });
  };

  return (
    <Stack spaceY="md">
      <div data-testid="tax-residency-country-dropdown">
        <AutoCompleteDropdown
          options={countryOptions || []}
          name="countries"
          placeholder="Select a country"
          isClearable={false}
          isSearchable={true}
          controlShouldRenderValue={false}
          onChange={handleDropdownSelect}
        />
      </div>

      {fields.map((field, index) => {
        return (
          <TaxResidencyCard
            key={field.fieldId}
            index={index}
            onRemove={() => remove(index)}
          />
        );
      })}
    </Stack>
  );
};
