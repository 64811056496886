import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { Box, Spinner, Stack, Text } from '@spaceship-fspl/components';
import {
  WebAppBasiqInstitutions,
  WebAppBasiqInstitutions_basiqInstitutions,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppBasiqInstitutions';
import { FilterSelect } from 'components/filter-select';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { IS_PRODUCTION } from 'helpers/config';
import { Routes } from 'pages/routes';
import React, { ReactNode, useEffect } from 'react';

import { AboutLinkingTrackingBank } from './components/about-linking-tracking-bank';
import { ContentLayout } from './components/layout';
import { BoostRecipeCreateParams, BoostRecipeEditParams } from './types';

const defaultBanks = [
  'AU00601', // ANZ
  'AU04301', // CBA
  'AU01001', // NAB
  'AU14201', // Westpac
  'AU00201', // ING Direct
  'AU12301', // St. George
];

if (!IS_PRODUCTION) {
  // This is the test bank in preprod
  // ref: https://api.basiq.io/reference/connect
  defaultBanks.push('AU00000'); // Hooli
}

export type BoostsBasiqInstitutionsState =
  | { type: 'add-source' }
  | ({ type: 'edit-existing-recipe' } & Omit<BoostRecipeEditParams, 'sourceId'>)
  | ({ type: 'create-new-recipe' } & BoostRecipeCreateParams);

export const BoostsBasiqInstitutions: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<{
      location: {
        state: BoostsBasiqInstitutionsState;
      };
    }>
  >
> = (props) => {
  const navigate = useNavigate();
  const notifications = useNotifications();

  const resp = useQuery<WebAppBasiqInstitutions>(
    gql`
      query WebAppBasiqInstitutions {
        basiqInstitutions {
          id
          name
        }
      }
    `,
    {
      onError: () => {
        notifications.popToast({
          level: 'error',
          message: 'Oops, there was a problem loading supported banks.',
        });
      },
    },
  );

  useEffect(() => {
    if (
      !['add-source', 'edit-existing-recipe', 'create-new-recipe'].includes(
        props.location?.state?.type ?? '',
      )
    ) {
      navigate(Routes.BOOSTS_DASHBOARD_HOME);
    }
  }, [navigate, props.location?.state?.type]);

  const institutions = resp.data?.basiqInstitutions ?? [];

  return (
    <PageContainer>
      <Stack spaceY={{ xs: 'xl', md: 'xxl' }}>
        <ContentLayout>
          <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
            <PageHeading title="Link a tracking bank" />

            {resp.loading ? (
              <Box
                height={400}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="lg" />
              </Box>
            ) : (
              <div>
                <Stack spaceY={{ xs: 'sm', lg: 'lg' }}>
                  <Stack spaceY="sm">
                    <Text variant={2}>
                      To use Boosts, you need to link a tracking bank to each
                      boost. We track all the transactions in your tracking bank
                      accounts and feed that information into your boost to
                      automatically boost your investment balance.
                    </Text>
                    <Text variant={4}>
                      Note: When you link a tracking bank, all accounts within
                      that tracking bank are linked.
                    </Text>
                  </Stack>

                  <Box minHeight={448}>
                    <FilterSelect<WebAppBasiqInstitutions_basiqInstitutions>
                      placeholder="Find your bank"
                      data={institutions}
                      defaultFilter={(banks) =>
                        banks.filter((b) => defaultBanks.includes(b.id))
                      }
                      renderItem={(bank): ReactNode => (
                        <Text variant={2} isBold={true}>
                          {bank.name}
                        </Text>
                      )}
                      searchKey={(bank): string => bank.name || ''}
                      onSelect={(bank): void => {
                        switch (props.location?.state?.type) {
                          case 'add-source':
                            navigate(Routes.BOOSTS_BASIQ_LOGIN, {
                              state: {
                                type: 'add-source',
                                institutionId: bank.id,
                              },
                            });
                            break;

                          case 'create-new-recipe':
                            navigate(Routes.BOOSTS_BASIQ_LOGIN, {
                              state: {
                                type: 'add-source-and-create-recipe',
                                institutionId: bank.id,
                                templateId: props.location?.state?.templateId,
                                saverProductInstanceId:
                                  props.location?.state?.saverProductInstanceId,
                                parameters: props.location?.state?.parameters,
                              },
                            });
                            break;

                          case 'edit-existing-recipe':
                            navigate(Routes.BOOSTS_BASIQ_LOGIN, {
                              state: {
                                type: 'add-source-and-edit-recipe',
                                institutionId: bank.id,
                                recipeId: props.location?.state?.recipeId,
                                parameters: props.location?.state?.parameters,
                              },
                            });
                            break;
                        }
                      }}
                    />
                  </Box>
                </Stack>

                <PageFormButtonContainer>
                  <PageFormGoBackButton
                    trackingProperties={{ name: 'basiq_institutions_go_back' }}
                    onClick={() => navigate(-1)}
                  />
                </PageFormButtonContainer>
              </div>
            )}
          </Stack>
        </ContentLayout>

        {!resp.loading && <AboutLinkingTrackingBank />}
      </Stack>
    </PageContainer>
  );
};
