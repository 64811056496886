import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  useCanReadSaver,
  useCanReadSuper,
  useIsAuthenticated,
} from '@spaceship-fspl/auth';
import {
  ActionLink,
  Box,
  Columns,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { ProductOffering, useAddProductIntent } from '@spaceship-fspl/graphql';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import {
  SuperProductStarsIcon,
  VoyagerProductStarsIcon,
} from '@spaceship-fspl/icons-web';
import { backgroundColor, color, fontFamily } from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { Product } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { useSignupRequestContext } from 'contexts/saver/onboarding';
import { MarketingTrackingEvents } from 'helpers/analytics';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Routes } from './routes';

export const SignupProductSelect: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const isAuthenticated = useIsAuthenticated();
  const track = useTrack();
  const canReadSuper = useCanReadSuper();
  const canReadSaver = useCanReadSaver();
  const [request, setRequest] = useSignupRequestContext();
  const [addProductIntent, addProductIntentMeta] = useAddProductIntent();

  const selectProduct = async (product: ProductOffering): Promise<void> => {
    const isVoyager = product === ProductOffering.VOYAGER;

    setRequest({
      ...request,
      createLead: {
        ...request.createLead,
        product_intent: isVoyager ? Product.Enum.VOYAGER : Product.Enum.SUPER,
      },
    });

    try {
      await addProductIntent({
        variables: {
          input: { product },
        },
      });

      if (isVoyager) {
        track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_START);
        navigate(Routes.VOYAGER_ONBOARDING_PORTFOLIO);
      } else {
        track?.(MarketingTrackingEvents.SUPER_ONBOARDING_START);
        navigate(Routes.SUPER_SIGNUP_PORTFOLIO);
      }
    } catch {
      notifications.popToast({
        level: 'error',
        message:
          'Unable to save your details. Please try again or contact support.',
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (canReadSaver) {
        navigate(Routes.VOYAGER_DASHBOARD, { replace: true });
      } else if (canReadSuper) {
        navigate(Routes.SUPER_DASHBOARD, { replace: true });
      }
    }
  }, [isAuthenticated, canReadSuper, canReadSaver, navigate]);

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
          <Stack spaceY="xl">
            <Heading variant={3} align="center" component="h1">
              Where would you like to start?
            </Heading>
            <Columns
              alignX="center"
              spaceX={{ md: 'md' }}
              spaceY={{ xs: 'md', md: 'none' }}
            >
              <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
                <ProductCard
                  variant="voyager"
                  icon={
                    <VoyagerProductStarsIcon color="indigo.070" size="xxxl" />
                  }
                  title="Spaceship Voyager"
                  tagline="Start investing with as little as you like."
                  learnMoreLink={InternalRoutes.VOYAGER_LEARN_MORE}
                  onSelect={() => selectProduct(ProductOffering.VOYAGER)}
                  isLoading={addProductIntentMeta.loading}
                  isSelected={
                    request.createLead?.product_intent === Product.Enum.VOYAGER
                  }
                />
              </Columns.Column>

              <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
                <ProductCard
                  variant="super"
                  icon={
                    <SuperProductStarsIcon color="neutral.000" size="xxxl" />
                  }
                  title="Spaceship Super"
                  tagline="Join the super fund investing where the world is going."
                  learnMoreLink={InternalRoutes.SUPER_LEARN_MORE}
                  onSelect={() => selectProduct(ProductOffering.SUPER)}
                  isLoading={addProductIntentMeta.loading}
                  isSelected={
                    request.createLead?.product_intent === Product.Enum.SUPER
                  }
                />
              </Columns.Column>
            </Columns>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

type ProductCardVariant = 'voyager' | 'super';

interface ProductCardVariantProps {
  cardVariant: ProductCardVariant;
}

interface ProductCardProps {
  variant: ProductCardVariant;
  icon: JSX.Element;
  title: string;
  tagline: string;
  learnMoreLink: string;
  onSelect: () => void;
  isLoading: boolean;
  isSelected: boolean;
}

export const ProductCard: React.FC<
  React.PropsWithChildren<ProductCardProps>
> = ({
  variant,
  icon,
  title,
  tagline,
  learnMoreLink,
  onSelect,
  isLoading,
  isSelected,
}) => {
  const isVoyager = variant === 'voyager';

  return (
    <Box
      borderRadius="sm"
      boxShadow="md"
      backgroundColor={isVoyager ? 'neutral.000' : 'indigo.100'}
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid={`${variant}-card`}
    >
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        maxWidth={{ xs: 'none', lg: 368 }}
        padding="lg"
        paddingBottom="xl"
      >
        <Box flex={1} marginBottom="md">
          <Stack alignX="center" spaceY="md">
            {icon}

            <Stack spaceY="sm">
              <StyledProductTitle cardVariant={variant}>
                {title}
              </StyledProductTitle>
              <StyledProductTaglineText cardVariant={variant}>
                {tagline}
              </StyledProductTaglineText>
            </Stack>
          </Stack>
        </Box>

        <Stack alignX="center" spaceY="md">
          <StyledContinueButton
            cardVariant={variant}
            aria-label={title}
            onClick={onSelect}
            isLoading={isLoading && isSelected}
            isDisabled={isLoading}
            trackingProperties={{
              name: `signup_product_${variant}`,
            }}
          >
            Continue
          </StyledContinueButton>

          <ActionLink
            color={isVoyager ? 'indigo.070' : 'neutral.000'}
            size="sm"
            href={learnMoreLink}
            rel="noreferrer"
            target="_blank"
          >
            Learn more
          </ActionLink>
        </Stack>
      </Box>
    </Box>
  );
};

const colorVariant = css`
  ${({ cardVariant }: ProductCardVariantProps) =>
    color(cardVariant === 'voyager' ? 'indigo.100' : 'neutral.000')}
`;

const StyledProductTitle = styled.h2<ProductCardVariantProps>`
  ${fontFamily('heading')}
  ${colorVariant}
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
  text-align: center;
`;

const StyledProductTaglineText = styled(Text).attrs({
  variant: 1,
  align: 'center',
})<ProductCardVariantProps>`
  ${colorVariant}
`;

const StyledContinueButton = styled(Button).attrs({
  variant: 'primary',
  size: 'lg',
})<ProductCardVariantProps>`
  ${({ cardVariant }) =>
    cardVariant === 'super'
      ? css`
          ${color('indigo.070')}
          ${backgroundColor('neutral.000')}

    :hover, :active {
            ${color('neutral.000')}
            ${backgroundColor('indigo.070')}
          }
        `
      : ''}
`;
