import { Analytics, AnalyticsBrowser, Context } from '@segment/analytics-next';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type ResetFunction = Analytics['reset'];
export type PageFunction = Analytics['page'];

export type IdentifyFunction = (
  id: string | undefined,
  properties: TrackProperties,
) => Promise<Context | undefined>;

export type TrackFunction = (
  eventName: string,
  properties?: TrackProperties,
) => Promise<Context | undefined>;

interface TrackProperties {
  properties?: Record<string, unknown>;
  contextTraits?: Record<string, unknown>;
}

const SegmentContext = createContext<Analytics | undefined>(undefined);

export const SegmentProvider: FC<
  React.PropsWithChildren<{
    writeKey: string;
    onError?: (error: unknown) => void;
    analytics?: Analytics;
  }>
> = ({ children, writeKey, onError, analytics: defaultAnalytics }) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(
    () => defaultAnalytics,
  );

  useEffect(() => {
    let mounted = true;
    (async () => {
      try {
        const [response] = await AnalyticsBrowser.load({ writeKey });
        if (mounted) {
          setAnalytics(response);
        }
      } catch (error) {
        if (mounted) {
          onError?.(error);
        }
      }
    })();

    return () => {
      mounted = false;
    };
  }, [writeKey, onError]);

  const value = useMemo(() => analytics, [analytics]);

  return (
    <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>
  );
};

const useSegment = (): Analytics | undefined => {
  const analytics = useContext(SegmentContext);
  return useMemo<Analytics | undefined>(() => analytics, [analytics]);
};

export const useTrack = (): TrackFunction => {
  const analytics = useSegment();
  const track = useCallback(
    async (
      eventName: string,
      props?: TrackProperties,
    ): Promise<Context | undefined> => {
      const userTraits = analytics?.user().traits();

      // We pass these user traits to each `track` call
      // so they appear in downstream tools (i.e. BigQuery)
      // @see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#saving-traits-to-the-context-object
      const ourContextTraits = {
        contactId: userTraits?.contactId,
        actualContactId: userTraits?.actualContactId,
        fingerprint: userTraits?.fingerprint,
      };

      return analytics?.track(eventName, props?.properties, {
        context: {
          ...ourContextTraits,
          ...props?.contextTraits,
        },
      });
    },
    [analytics],
  );

  return track;
};

export const useIdentify = (): IdentifyFunction | undefined => {
  const analytics = useSegment();

  const identify = useCallback(
    async (
      // Anonymous identify by
      // passing `undefined` to `id`
      id: string | undefined,
      props?: TrackProperties,
    ): Promise<Context | undefined> => {
      const userTraits = analytics?.user().traits();

      // We pass these user traits to each `track` call
      // so they appear in downstream tools (i.e. BigQuery)
      // @see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#saving-traits-to-the-context-object
      const ourContextTraits = {
        contactId: userTraits?.contactId,
        actualContactId: userTraits?.actualContactId,
        fingerprint: userTraits?.fingerprint,
      };

      return analytics?.identify(id, props?.properties, {
        context: {
          ...ourContextTraits,
          ...props?.contextTraits,
        },
      });
    },
    [analytics],
  );
  return identify;
};

export const useReset = (): ResetFunction | undefined => {
  return useContext(SegmentContext)?.reset;
};

export const usePage = (): PageFunction | undefined => {
  return useContext(SegmentContext)?.page;
};
