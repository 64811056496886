import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { Box, Spinner, Stack, Text } from '@spaceship-fspl/components';
import {
  BasiqConnectionStatus,
  useCreateBoostRecipe,
  useUpdateBoostRecipe,
} from '@spaceship-fspl/graphql';
import { WebAppBoostsBasiqSelect } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsBasiqSelect';
import { formatDate } from '@spaceship-fspl/helpers';
import { InlineError } from 'components/inline-error';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { ContentLayout } from './components/layout';
import { BoostRecipeCreateParams, BoostRecipeEditParams } from './types';

export type BoostsBasiqSelectState =
  | ({ type: 'create-new-recipe' } & BoostRecipeCreateParams)
  | ({ type: 'edit-existing-recipe' } & Omit<
      BoostRecipeEditParams,
      'sourceId'
    >);

export const BoostsBasiqSelect: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<{
      location: {
        state: BoostsBasiqSelectState;
      };
    }>
  >
> = (props) => {
  const state = props.location?.state;
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [createBoostRecipe, createBoostRecipeMeta] = useCreateBoostRecipe();
  const [updateBoostRecipe, updateBoostRecipeMeta] = useUpdateBoostRecipe();

  const form = useForm<
    Partial<{
      sourceId: string;
    }>
  >();

  const resp = useQuery<WebAppBoostsBasiqSelect>(gql`
    query WebAppBoostsBasiqSelect {
      contact {
        id
        account {
          id
          basiqConnections {
            id
            institution {
              id
              name
            }
            connectedAt
            status
          }
        }
      }
    }
  `);

  const connections = useMemo(() => {
    const data =
      resp.data?.contact.account?.basiqConnections
        ?.filter(
          (c) =>
            c.status === BasiqConnectionStatus.CONNECTED ||
            c.status === BasiqConnectionStatus.CONNECTING,
        )
        ?.map((c) => ({
          id: c.id,
          name: c.institution?.name,
          connectedAt: formatDate(c.connectedAt, 'dd MMM yyyy'),
        })) || [];
    data.push({
      id: 'new',
      name: 'Link new tracking bank',
      connectedAt: '',
    });
    return data;
  }, [resp.data?.contact.account?.basiqConnections]);

  const handleSubmit = form.handleSubmit(async ({ sourceId }) => {
    try {
      if (!sourceId) {
        throw new Error('handleSubmit called with missing sourceId selection');
      }

      switch (state?.type) {
        case 'create-new-recipe':
          if (sourceId === 'new') {
            navigate(Routes.BOOSTS_BASIQ_INSTITUTIONS, {
              state: {
                type: 'create-new-recipe',
                saverProductInstanceId: state.saverProductInstanceId,
                templateId: state.templateId,
                parameters: state.parameters,
              },
            });
          } else {
            await createBoostRecipe({
              variables: {
                input: {
                  saverProductInstanceId: state.saverProductInstanceId,
                  templateId: state.templateId,
                  parameters: state.parameters,
                  sourceId,
                },
              },
            });
            navigate(Routes.BOOSTS_RECIPE_SETUP_SUCCESS, {
              state: {
                title:
                  'Your tracking bank has been successfully linked and your boost is ready!',
              },
            });
          }
          break;

        case 'edit-existing-recipe':
          if (sourceId === 'new') {
            navigate(Routes.BOOSTS_BASIQ_INSTITUTIONS, {
              state: {
                type: 'edit-existing-recipe',
                recipeId: state.recipeId,
                parameters: state.parameters,
              },
            });
          } else {
            await updateBoostRecipe({
              variables: {
                input: {
                  id: state.recipeId,
                  parameters: state.parameters,
                  sourceId,
                },
              },
            });
            navigate(Routes.BOOSTS_DASHBOARD_SETTINGS);
            notifications.popToast({
              level: 'success',
              message: 'We’ve updated your linked tracking account',
            });
          }
          break;

        default:
          throw new Error('handleSubmit called with unexpected state type');
      }
    } catch (error) {
      addRumError({ error });
      notifications.popToast({
        level: 'error',
        message: 'Oops, we ran into a problem setting up your boost',
      });
    }
  });

  return (
    <PageContainer>
      <ContentLayout>
        <Stack spaceY={{ xs: 'sm', md: 'md' }}>
          <PageHeading title="Select a tracking bank" />

          {resp.loading ? (
            <Box
              height={400}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="lg" />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Stack spaceY={{ xs: 'sm', md: 'md' }}>
                <Text variant={2}>
                  Select the tracking bank you’d like to link to your boost.
                  We’ll track your transactions and feed that information into
                  your boost to automatically boost your investment balance.
                </Text>

                <Stack spaceY={{ xs: 'lg', md: 'md' }}>
                  <Stack spaceY="sm">
                    {form.formState.errors.sourceId?.message && (
                      <InlineError
                        message={form.formState.errors.sourceId.message}
                      />
                    )}
                    {connections.map((c) => (
                      <RadioCard
                        key={c.id}
                        value={c.id}
                        contentPadding={{
                          padding: 'md',
                        }}
                        error={!!form.formState.errors.sourceId}
                        {...form.register('sourceId', {
                          required: 'Please select a tracking bank',
                        })}
                      >
                        <Text variant={2} isBold={true}>
                          {c.name}
                        </Text>
                        {c.connectedAt && (
                          <Text variant={4} color="neutral.085">
                            Linked {c.connectedAt}
                          </Text>
                        )}
                      </RadioCard>
                    ))}
                  </Stack>

                  <Text variant={3} color="neutral.085">
                    By pressing ‘Continue,’ you consent to Spaceship reading
                    your bank account transaction history for the purpose of
                    using the Boosts feature. Spaceship does not have the
                    ability to make transactions from this account.
                  </Text>
                </Stack>
              </Stack>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{ name: 'basiq_select_continue' }}
                  isLoading={
                    createBoostRecipeMeta.loading ||
                    updateBoostRecipeMeta.loading
                  }
                />
              </PageFormButtonContainer>
            </form>
          )}
        </Stack>
      </ContentLayout>
    </PageContainer>
  );
};
