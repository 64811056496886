import React from 'react';
import styled from 'styled-components';

export interface ShapeProps {
  variant: 'rectangle' | 'circle' | 'triangle' | 'star' | 'emoji';
  height: number;
  width: number;
}

export const Shape: React.FC<React.PropsWithChildren<ShapeProps>> = ({
  variant,
  height,
  width,
}) => {
  if (variant === 'emoji') {
    return (
      <StyledEmoji height={height}>
        <span role="img" aria-label="confetti">
          🎉
        </span>
      </StyledEmoji>
    );
  }

  return (
    <svg
      height={height}
      width={width}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      {variant === 'rectangle' && <rect height={height} width={width} />}
      {variant === 'circle' && (
        <ellipse
          ry={height / 2}
          rx={width / 2}
          cy={height / 2}
          cx={width / 2}
        />
      )}
      {variant === 'triangle' && (
        <polygon points={`0,${height} ${width / 3.5},0 ${width},${height}`} />
      )}
      {variant === 'star' && (
        <path
          d={`
          M ${width / 2} 0
          C ${width * 0.6} ${height * 0.25} ${width * 0.75} ${
            height * 0.4
          } ${width} ${height / 2}
          C ${width * 0.75} ${height * 0.6} ${width * 0.6} ${height * 0.75} ${
            width / 2
          } ${height}
          C ${width * 0.4} ${height * 0.75} ${width * 0.25} ${height * 0.6} 0 ${
            height / 2
          }
          C ${width * 0.25} ${height * 0.4} ${width * 0.4} ${height * 0.25} ${
            width / 2
          } 0 Z`}
        />
      )}
    </svg>
  );
};

const StyledEmoji = styled.div<{ height: number }>`
  display: block;
  text-align: center;

  ${({ height }) => `
    font-size: ${height}px;
    height: ${height}px;
    line-height: ${height}px;
    width: ${height}px;
  `}
`;
