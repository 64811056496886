import * as React from 'react';
export const StreamlineShieldCheck = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg stroke="currentColor" fill="none" viewBox="0 0 24 24" {...props}>
    <path vectorEffect="non-scaling-stroke" strokeWidth={2} d="M17.32 7.225l-5.764 8.346-4.38-4.378" />
    <path vectorEffect="non-scaling-stroke"
      strokeWidth={2}
      d="M2.5 4.137v7.304c0 4.847 2.964 9.207 7.486 10.966l1.023.392a2.88 2.88 0 002.113 0l1.023-.392a11.745 11.745 0 007.486-10.966V4.137c0-.574-.325-1.1-.851-1.348A21.182 21.182 0 0012.07 1a21.182 21.182 0 00-8.71 1.788c-.525.239-.85.765-.85 1.348l-.01.001z"
    />
  </svg>
);
