import { navigate } from '@reach/router';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { SuperTaxFileNumberCard as TFNCard } from 'components/super-tax-file-number-card';
import { Routes } from 'pages/routes';
import React from 'react';

import { AccountCard, AccountCardHeading } from './components';

export const SuperTaxFileNumberCard: React.FC<React.PropsWithChildren> = () => {
  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;
  if (sftLimitedServicePeriodEnabled) {
    return null;
  }
  return (
    <AccountCard>
      <AccountCardHeading title="Tax file number" />
      <TFNCard
        showProvidedTfnCard={true}
        onClick={() => {
          navigate(Routes.SUPER_TFN);
        }}
      />
    </AccountCard>
  );
};
