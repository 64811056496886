import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  TFNExemptionCode,
  useUpdateTfnExemption,
} from '@spaceship-fspl/graphql';
import {
  WebAppVoyagerTaxFileNumberExemption,
  WebAppVoyagerTaxFileNumberExemptionVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerTaxFileNumberExemption';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { TFN_EXEMPTION_CODE_OPTIONS } from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import { useNotifications } from 'contexts/notifications';
import { AccessibilityLabel } from 'helpers/accessibility';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { voyagerPortfolios } from 'helpers/portfolios';
import { requiredValidation } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React from 'react';
import { useForm } from 'react-hook-form';

interface PageParams {
  productId?: string;
}

export const VoyagerTaxFileNumberExemption: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: PageParams = useParams();

  const notification = useNotifications();
  const [updateTfnExemption, updateTfnExemptionResult] =
    useUpdateTfnExemption();

  const resp = useQuery<
    WebAppVoyagerTaxFileNumberExemption,
    WebAppVoyagerTaxFileNumberExemptionVariables
  >(
    gql`
      query WebAppVoyagerTaxFileNumberExemption(
        $id: ID!
        $idProvided: Boolean!
      ) {
        contact {
          id
          account {
            id
            saverProductInstance(id: $id) @include(if: $idProvided) {
              id
              portfolio
              tfnExemptionCode
            }
          }
        }
      }
    `,
    {
      variables: {
        id: productId,
        idProvided: !!productId,
      },
      onCompleted: (data) => {
        if (!data?.contact?.account?.saverProductInstance?.id) {
          navigate(`${Routes.VOYAGER_TFN}`, { replace: true });
        } else if (
          data?.contact?.account?.saverProductInstance?.tfnExemptionCode
        ) {
          navigate(`${Routes.VOYAGER_TFN}/${productId}`, { replace: true });
        }
      },
    },
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<{
    tfnExemptionCode: TFNExemptionCode;
  }>({ shouldFocusError: true, mode: 'onBlur' });

  const product = resp.data?.contact?.account?.saverProductInstance;

  if (!product) {
    navigate(Routes.VOYAGER_TFN);
    return null;
  }

  const config = voyagerPortfolios[product.portfolio];

  const onSubmit = handleSubmit(async ({ tfnExemptionCode }) => {
    try {
      await updateTfnExemption({
        variables: {
          input: {
            productId,
            tfnExemptionCode,
          },
        },
      });
      navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
      notification.popToast({
        level: 'success',
        message: 'Your Tax File Number exemption has been saved',
      });
    } catch {
      notification.popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  });

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 2 / 3, xl: 1 / 2 }}>
          <Stack spaceY="lg">
            <Stack spaceY="xs">
              <Heading
                variant={4}
                color="neutral.085"
                align="center"
                component="h2"
              >
                {config.title}
              </Heading>

              <Heading variant={3} align="center" component="h1">
                Please select an exemption reason
              </Heading>
            </Stack>

            <form onSubmit={onSubmit}>
              {!!errors.tfnExemptionCode?.message && (
                <Box marginBottom="md">
                  <Inline spaceX="xs" alignY="center" alignX="center">
                    <Box lineHeight={0}>
                      <FeatherAlertTriangleIcon size="md" color="red.100" />
                    </Box>
                    <Text color="red.100" variant={2} isBold={true}>
                      {errors.tfnExemptionCode.message}
                    </Text>
                  </Inline>
                </Box>
              )}

              <Stack spaceY="sm">
                {TFN_EXEMPTION_CODE_OPTIONS.map((options) => (
                  <RadioCard
                    key={options.value}
                    value={options.value}
                    error={!!errors.tfnExemptionCode}
                    contentPadding={{ padding: { xs: 'sm', md: 'md' } }}
                    aria-label={`tfn exemption option ${options.value.toLowerCase()}`}
                    {...register(
                      'tfnExemptionCode',
                      requiredValidation('TFN exemption reason'),
                    )}
                  >
                    <Box paddingRight="xl">
                      <Text variant={2} color="black.100">
                        {options.label}
                      </Text>
                    </Box>
                  </RadioCard>
                ))}
              </Stack>

              <Box display="flex" justifyContent="center" marginTop="xl">
                <Inline spaceX="md" spaceY="sm" alignX="center" alignY="center">
                  <Button
                    aria-label={AccessibilityLabel.CANCEL_BUTTON}
                    variant="secondary"
                    size="lg"
                    trackingProperties={{
                      name: 'voyager_tfn_exemption_cancel',
                    }}
                    onClick={() => {
                      navigate(`${Routes.VOYAGER_TFN}/${productId}`);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    size="lg"
                    aria-label={AccessibilityLabel.SAVE_BUTTON}
                    type="submit"
                    trackingProperties={{
                      name: 'voyager_tfn_exemption_save',
                      product_id: productId,
                    }}
                    isLoading={updateTfnExemptionResult.loading}
                  >
                    Save
                  </Button>
                </Inline>
              </Box>
            </form>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
