import { marketingTheme } from './marketing';
import { webappGreyTheme, webappWhiteTheme } from './webapp';

export const themes = {
  marketing: marketingTheme,
  webapp: {
    onGrey: webappGreyTheme,
    onWhite: webappWhiteTheme,
  },
};
