import * as React from 'react';
export const StreamlineBuildingModern2 = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M1.75 23V8.333h8.8V23M4.684 23V11.267M7.617 23V11.267M1.75 8.333L6.15 3.2l4.4 5.133M6.15 1v2.2M22.283 23V11.062a.734.734 0 00-.501-.696l-7.334-2.444a.732.732 0 00-.965.696V23M19.35 20.8v.733M19.35 17.133v.734M19.35 13.467v.733M16.417 20.8v.733M16.417 17.133v.734M16.417 13.467v.733" />
  </svg>
);
