import { useNavigate } from '@reach/router';
import { OldVoyagerBankAccountCard } from 'components/account-cards/voyager-bank-account';
import { useOnboardingRequestContext } from 'contexts/saver/onboarding';
import { Routes } from 'pages/routes';
import React, { memo } from 'react';

export const BankAccountSection = memo(() => {
  const navigate = useNavigate();
  const [{ createSaverAccount }] = useOnboardingRequestContext();

  return (
    <OldVoyagerBankAccountCard
      variant="signup_summary"
      bankAccount={{
        friendly_name: createSaverAccount?.source_account_friendly_name,
        account_name: createSaverAccount?.source_account_name,
        bsb: createSaverAccount?.source_bsb,
        account_number: createSaverAccount?.source_account_number,
      }}
      edit={{
        isEditing: false,
        onClick: () => navigate(Routes.BANK_ACCOUNT),
      }}
    />
  );
});

BankAccountSection.displayName = 'BankAccountSection';
