import { useEnsureToken } from '@spaceship-fspl/auth';
import { FetchFunction } from '@spaceship-fspl/fetch';
import React, { useCallback } from 'react';

const FetchContext = React.createContext<FetchFunction | undefined>(undefined);

export interface ProviderProps {
  fetch: FetchFunction;
}

export const Provider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  fetch,
  children,
}) => <FetchContext.Provider value={fetch}>{children}</FetchContext.Provider>;

export const useFetch = (): FetchFunction => {
  const fetch = React.useContext(FetchContext);

  if (!fetch) {
    throw new Error('@spaceship-fspl/data: Please wrap in <Provider/>.');
  }

  return fetch;
};

export const useAuthenticatedFetch = (): FetchFunction => {
  const fetch = useFetch();
  const ensureToken = useEnsureToken();
  return useCallback(
    async (options) => {
      const accessToken = await ensureToken();
      return await fetch({
        ...options,
        token: accessToken,
      });
    },
    [ensureToken, fetch],
  );
};
