import {
  Button as SpaceshipButton,
  ButtonProps as SpaceshipButtonProps,
} from '@spaceship-fspl/components';
import { useTrack } from '@spaceship-fspl/tracking';
import { TrackingEvent, TrackingProperties } from 'helpers/analytics';
import React from 'react';

export interface ButtonProps extends SpaceshipButtonProps {
  trackingProperties: TrackingProperties;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  onClick,
  trackingProperties,
  ...props
}) => {
  const track = useTrack();

  const handleClick = (): void => {
    track?.(TrackingEvent.CLICK, { properties: trackingProperties });
    onClick?.();
  };

  return <SpaceshipButton onClick={handleClick} {...props} />;
};
