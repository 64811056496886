import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Card,
  DynamicIcon,
  Heading,
  Inline,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  BasiqConnectionStatus,
  BoostRecipeStatus,
  useRemoveBasiqConnection,
  useUpdateBoostRecipe,
} from '@spaceship-fspl/graphql';
import {
  WebAppBoostsBasiqUnlink,
  WebAppBoostsBasiqUnlink_contact_account_basiqConnections as basiqConnections,
  WebAppBoostsBasiqUnlink_contact_account_saverBoostRecipes as saverBoostRecipes,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostsBasiqUnlink';
import { formatDate, useRenderTemplate } from '@spaceship-fspl/helpers';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { getBoostRecipeTemplateVariables } from '@spaceship-fspl/voyager';
import { Dropdown, Option } from 'components/dropdown';
import { InlineError } from 'components/inline-error';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { Routes } from 'pages/routes';
import React, { memo, useEffect, useMemo, useState } from 'react';

import { BasiqUnlinkModal } from './components/basiq-unlink-modal';
import { ContentLayout } from './components/layout';

const unlinkableConnectionStatuses = [
  BasiqConnectionStatus.CONNECTED,
  BasiqConnectionStatus.CONNECTING,
];

type RecipeUpdates = Record<string, string>;

interface PageParams {
  connectionId?: string;
}

export const BoostsBasiqUnlink: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const { connectionId = '' }: PageParams = useParams();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [showConfirmUnlinkModal, setShowConfirmUnlinkModal] = useState(false);
  const [connection, setConnection] = useState<basiqConnections>();
  const [recipes, setRecipes] = useState<saverBoostRecipes[]>();
  const [recipeUpdates, setRecipeUpdates] = useState<RecipeUpdates>({});

  const [removeBasiqConnection, removeBasiqConnectionMeta] =
    useRemoveBasiqConnection();
  const [updateBoostRecipe, updateBoostRecipeMeta] = useUpdateBoostRecipe();

  const resp = useQuery<WebAppBoostsBasiqUnlink>(
    gql`
      query WebAppBoostsBasiqUnlink {
        contact {
          id
          account {
            id
            basiqConnections {
              id
              institution {
                id
                name
              }
              connectedAt
              status
            }
            saverBoostRecipes {
              id
              description
              iconName
              name
              status
              saverProductInstance {
                id
                portfolio
              }
              parameters {
                id
                name
                type
                value
              }
              source {
                ... on BasiqConnection {
                  id
                  status
                  connectedAt
                  institution {
                    id
                    name
                  }
                }
                ... on WeatherStation {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      skip: !connectionId,
      onCompleted: (data) => {
        const conn = data.contact?.account?.basiqConnections?.find(
          (c) => c.id === connectionId,
        );

        if (
          conn?.status &&
          unlinkableConnectionStatuses.includes(conn.status)
        ) {
          setConnection(conn);
        }

        const recipes =
          data.contact?.account?.saverBoostRecipes?.filter(
            (r) => r.source?.id === connectionId,
          ) ?? [];
        setRecipes(recipes);
        setRecipeUpdates(
          recipes?.reduce((prev, cur) => ({ ...prev, [cur.id]: 'unlink' }), {}),
        );
      },
    },
  );

  useEffect(() => {
    if (!connectionId) {
      addRumError({
        error: new Error('Basiq unlink called without connectionId'),
      });
      navigate(Routes.BOOSTS_BASIQ_EDIT);
      notifications.popToast({
        level: 'error',
        message: 'Oops, we ran into a problem unlinking your tracking bank.',
      });
    }
  }, [connectionId, navigate, notifications]);

  const unlinkConnection = async (): Promise<void> => {
    try {
      if (!connectionId) {
        throw new Error(
          'Attempting to unlink tracking back without connectionId',
        );
      }

      await removeBasiqConnection({
        variables: { input: { id: connectionId } },
      });

      navigate(Routes.BOOSTS_BASIQ_SUCCESS, {
        state: {
          type: 'disconnect-source',
        },
      });
    } catch (error) {
      addRumError({ error });
      notifications.popToast({
        level: 'error',
        message: 'Oops, we are unable to unlink your tracking bank.',
      });
    }
  };

  return (
    <PageContainer>
      <ContentLayout>
        <Stack spaceY="md">
          <PageHeading title="Unlink tracking bank" />

          {resp.loading ? (
            <Box
              height={400}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="lg" />
            </Box>
          ) : (
            <>
              <Stack spaceY={{ xs: 'md', md: 'lg' }}>
                <Stack spaceY="xs">
                  <Text variant={4} color="neutral.085" isBold={true}>
                    TRACKING BANK TO UNLINK
                  </Text>

                  <Card
                    borderRadius="sm"
                    boxShadow="sm"
                    paddingX="lg"
                    paddingY="md"
                  >
                    {!connection ? (
                      <InlineError
                        message="Tracking bank not found"
                        isBold={true}
                      />
                    ) : (
                      <Stack spaceY="xxxs">
                        <Heading variant={5} isBold={true}>
                          {connection?.institution?.name}
                        </Heading>

                        {connection?.connectedAt && (
                          <Text variant={4} color="neutral.085">
                            Linked{' '}
                            {formatDate(connection.connectedAt, 'dd MMM yyyy')}
                          </Text>
                        )}
                      </Stack>
                    )}
                  </Card>
                </Stack>

                {recipes && recipes.length > 0 && (
                  <Stack spaceY={{ xs: 'sm', md: 'md' }}>
                    <Stack spaceY="xxs">
                      <Inline spaceX="xxxs" alignY="center">
                        <Text variant={4} color="neutral.085" isBold={true}>
                          UNLINKED BOOSTS
                        </Text>

                        <Box lineHeight={0}>
                          <FeatherAlertTriangleIcon
                            size="sm"
                            color="neutral.085"
                          />
                        </Box>
                      </Inline>

                      <Text variant={3} color="neutral.085">
                        By unlinking a tracking bank, all boosts linked to the
                        tracking bank will be deleted. To keep your boosts, you
                        should link these boosts to another tracking bank first.
                      </Text>
                    </Stack>

                    <Stack spaceY="sm">
                      {recipes?.map((r) => (
                        <RecipeCard
                          key={r.id}
                          recipe={r}
                          connections={
                            resp.data?.contact?.account?.basiqConnections
                          }
                          onChangeTrackingBank={(connectionId) =>
                            setRecipeUpdates((prevState) => ({
                              ...prevState,
                              [r.id]: connectionId,
                            }))
                          }
                        />
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>

              <PageFormButtonContainer>
                {connection && (
                  <PageFormContinueButton
                    trackingProperties={{ name: 'basiq_unlink_page_confirm' }}
                    isLoading={
                      resp.loading ||
                      removeBasiqConnectionMeta.loading ||
                      updateBoostRecipeMeta.loading
                    }
                    onClick={async () => {
                      if (Object.keys(recipeUpdates).length > 0) {
                        if (
                          Object.values(recipeUpdates).every(
                            (x) => x !== 'unlink',
                          )
                        ) {
                          // First re-assigning all linked boost recipes
                          try {
                            for (const [
                              recipeId,
                              connectionId,
                            ] of Object.entries(recipeUpdates)) {
                              if (recipeId) {
                                await updateBoostRecipe({
                                  variables: {
                                    input: {
                                      id: recipeId,
                                      sourceId: connectionId,
                                    },
                                  },
                                });
                              }
                            }
                          } catch {
                            notifications.popToast({
                              level: 'error',
                              message:
                                'Oops, we are unable to update your boost(s).',
                            });
                            return;
                          }
                          await unlinkConnection();
                        } else {
                          // User is unlinking a recipe, ask for confirmation
                          setShowConfirmUnlinkModal(true);
                        }
                      } else {
                        await unlinkConnection();
                      }
                    }}
                  >
                    Confirm
                  </PageFormContinueButton>
                )}

                <PageFormGoBackButton
                  trackingProperties={{ name: 'basiq_unlink_page_go_back' }}
                  onClick={() => navigate(-1)}
                />
              </PageFormButtonContainer>
            </>
          )}
        </Stack>
      </ContentLayout>

      <BasiqUnlinkModal
        showModal={showConfirmUnlinkModal}
        onCloseModal={() => {
          setShowConfirmUnlinkModal(false);
        }}
        onUnlink={async () => {
          if (Object.keys(recipeUpdates).length > 0) {
            try {
              for (const [recipeId, connectionId] of Object.entries(
                recipeUpdates,
              )) {
                if (recipeId) {
                  if (connectionId === 'unlink') {
                    await updateBoostRecipe({
                      variables: {
                        input: {
                          id: recipeId,
                          status: BoostRecipeStatus.DELETED,
                        },
                      },
                    });
                  } else {
                    await updateBoostRecipe({
                      variables: {
                        input: {
                          id: recipeId,
                          sourceId: connectionId,
                        },
                      },
                    });
                  }
                }
              }
            } catch {
              notifications.popToast({
                level: 'error',
                message: 'Oops, we are unable to update your boost(s).',
              });
              return;
            }
            setShowConfirmUnlinkModal(false);
            await unlinkConnection();
          }
        }}
      />
    </PageContainer>
  );
};

const RecipeCard: React.FC<
  React.PropsWithChildren<{
    recipe: saverBoostRecipes;
    connections?: basiqConnections[] | null;
    onChangeTrackingBank: (connectionId: string) => void;
  }>
> = memo(({ recipe, connections, onChangeTrackingBank }) => {
  const boostDescription = useRenderTemplate(
    recipe.description ?? '',
    getBoostRecipeTemplateVariables({
      weatherStationName:
        recipe.source?.__typename === 'WeatherStation'
          ? recipe.source.name
          : '',
      portfolio: recipe.saverProductInstance?.portfolio,
      parameters: recipe.parameters,
    }),
  );

  const bankOptions: Array<Option> = useMemo(
    () =>
      [
        {
          value: 'unlink',
          label: 'Unlink',
        },
      ].concat(
        connections
          ?.filter((c) => c.id !== recipe.source?.id)
          ?.map((c) => ({
            value: c.id ?? '',
            label: c.institution?.name ?? '',
          })) ?? [],
      ),
    [connections, recipe.source?.id],
  );

  return (
    <Card
      borderRadius="sm"
      boxShadow="sm"
      paddingX={{ xs: 'md', md: 'lg' }}
      paddingY="md"
    >
      <Stack spaceY="sm">
        <Stack spaceY="xs">
          <Inline spaceX="xxs">
            <DynamicIcon name={recipe.iconName} color="neutral.100" size="md" />
            <Heading variant={5} isBold={true}>
              {recipe.name}
            </Heading>
          </Inline>

          <Text variant={3}>{boostDescription}</Text>
        </Stack>

        <Dropdown
          name="new_connection"
          placeholder="Tracking bank"
          options={bankOptions}
          onChange={(event) => onChangeTrackingBank(event.target.value)}
        />
      </Stack>
    </Card>
  );
});

RecipeCard.displayName = 'RecipeCard';
