import { Accordion, Heading, Stack, Text } from '@spaceship-fspl/components';
import React from 'react';

export const AboutLinkingTrackingBank: React.FC<
  React.PropsWithChildren
> = () => (
  <Accordion variant="info" title="About linking a tracking bank">
    <Stack spaceY="md">
      <Stack spaceY="xxxs">
        <Heading variant={5} color="neutral.085" isBold={true}>
          Why do I need to link a tracking bank?
        </Heading>
        <Text variant={2} color="neutral.085">
          When you link a tracking bank, all accounts within that tracking bank
          are linked. We then track all the transactions in your tracking bank
          accounts and feed that information into your boosts.
          <br />
          <br />
          For example, if you choose our Round Ups boost, we track your
          transactions, and every time you spend money, we round up the purchase
          to the next whole dollar and invest the spare change into your
          portfolio.
        </Text>
      </Stack>

      <Stack spaceY="xxxs">
        <Heading variant={5} color="neutral.085" isBold={true}>
          How do I link a tracking bank?
        </Heading>
        <Text variant={2} color="neutral.085">
          To link a tracking bank, you need to provide your bank login
          information within the app. We use this information to establish a
          secure and automatic connection with your bank and verify your bank
          account.
        </Text>
      </Stack>

      <Stack spaceY="xxxs">
        <Heading variant={5} color="neutral.085" isBold={true}>
          How is my bank account login information protected and stored?
        </Heading>
        <Text variant={2} color="neutral.085">
          We take resonable steps to protect your personal information. Your
          bank account login information is not viewable by Spaceship or its
          service providers. You can withdraw your consent to use the login
          details at any time by removing your linked bank account, which will
          result in the deletion of your login details.
        </Text>
      </Stack>
    </Stack>
  </Accordion>
);
