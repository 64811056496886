import { RouteComponentProps } from '@reach/router';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  defaultFormValues,
  InvestmentPlanForm,
  PRESET_FREQUENCIES,
  VoyagerInvestmentPlan as InvestmentPlan,
} from 'components/voyager-investment-plan';
import { useInvestmentSetup } from 'contexts/saver/investment-setup';
import { cleanNumber, fromAussieDate, toISODate } from 'helpers/format';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const VoyagerOnboardingInvestmentPlan: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const formMethods = useForm<InvestmentPlanForm>({
    shouldFocusError: true,
    mode: 'onBlur',
    defaultValues: defaultFormValues,
  });

  const {
    onInvestmentPlanComplete,
    onInvestmentPlanSkip,
    activeBankAccountNumber,
  } = useInvestmentSetup();

  const handleSubmit = (value: InvestmentPlanForm): void => {
    onInvestmentPlanComplete?.({
      frequency: PRESET_FREQUENCIES.find(
        (freq) => freq.label === value.frequency,
      )?.value,
      amount: value.otherAmount
        ? cleanNumber(value.otherAmount)
        : cleanNumber(value.presetAmount),
      start_date: toISODate(fromAussieDate(value.startDate)),
    });
  };

  return (
    <OnboardingContainer>
      <FormProvider {...formMethods}>
        <InvestmentPlan
          onComplete={handleSubmit}
          onSkip={onInvestmentPlanSkip}
          variant="onboarding"
          activeBankAccountNumber={activeBankAccountNumber}
        />
      </FormProvider>
    </OnboardingContainer>
  );
};
