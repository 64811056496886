import * as React from 'react';
export const StreamlineDiagramArrowDashRight = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 16.467h2.933v3.666L23 12.067 14.933 4v3.667H12M9.067 16.467H6.133M3.2 16.467H1M9.067 7.667H6.133M3.2 7.667H1" />
  </svg>
);
