import {
  Box,
  Card,
  Heading,
  PresentationLink,
  Stack,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { SaverPortfolio, useDismiss } from '@spaceship-fspl/graphql';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { FeatherXIcon } from '@spaceship-fspl/icons-web';
import { getColor, getSpace, transition, zIndex } from '@spaceship-fspl/styles';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import voyagerStartInvestingSvg from 'assets/voyager-start-investing.svg';
import { RouterLink } from 'components/router-link';
import { DynamicConfigDismissibleId } from 'helpers/dynamic-config';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

interface PortfolioTmdManuallyApprovedCardProps {
  portfolio: SaverPortfolio;
  productId: string;
}

export const PortfolioTmdManuallyApprovedCard: React.FC<
  React.PropsWithChildren<PortfolioTmdManuallyApprovedCardProps>
> = ({ portfolio, productId }) => {
  const [dismiss] = useDismiss();
  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const portfolioInfo = voyagerPortfolios[portfolio];
  const dismissibleId =
    portfolio === SaverPortfolio.INDEX
      ? DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_INDEX_CARD
      : portfolio === SaverPortfolio.UNIVERSE
        ? DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_UNIVERSE_CARD
        : DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_EARTH_CARD;

  const handleDismiss = (): void => {
    dismiss({
      variables: {
        input: {
          id: dismissibleId,
        },
      },
      optimisticResponse: {
        dismiss: {
          __typename: 'DismissPayload',
          dismissible: {
            __typename: 'Dismissible',
            id: dismissibleId,
            hidden: true,
          },
        },
      },
    });
  };

  return (
    <Card
      overflow="hidden"
      aria-label={`${portfolioInfo.title} TMD approved card`}
    >
      <StyledDismissButton
        onClick={handleDismiss}
        aria-label="Dismiss TMD approved card"
      >
        <FeatherXIcon color="neutral.030" />
      </StyledDismissButton>

      <RouterLink
        to={`${
          isVerifiedUser
            ? Routes.VOYAGER_DEPOSIT
            : Routes.VOYAGER_INVESTMENT_PLAN
        }/${productId}`}
        trackingProperties={{
          name: 'portfolio_tmd_manually_approved_card_link',
        }}
        onClick={handleDismiss}
      >
        <StyledGradientBox
          paddingLeft={{ xs: 'md', lg: 'lg' }}
          paddingRight={{ xs: 'md', lg: 'xl' }}
          paddingY={{ xs: 'lg', lg: 'sm' }}
          position="relative"
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row-reverse' }}
            justifyContent={{ xs: 'center', lg: 'space-between' }}
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              minWidth={{ lg: 200 }}
              paddingBottom={{ xs: 'md', lg: 'none' }}
            >
              <StyledCardImageSvg src={voyagerStartInvestingSvg} />
            </Box>

            <Stack
              spaceY={{ xs: 'xxs', lg: 'xs' }}
              alignX={{ xs: 'center', lg: 'left' }}
            >
              <Heading
                variant={4}
                color="neutral.000"
                isBold={true}
                align={{ xs: 'center', lg: 'left' }}
                component="h3"
              >
                Congrats! You can now invest into your {portfolioInfo.title}
              </Heading>

              <PresentationLink
                size="sm"
                color="neutral.000"
                icon="arrow"
                component="span"
              >
                Start investing
              </PresentationLink>
            </Stack>
          </Box>
        </StyledGradientBox>
      </RouterLink>
    </Card>
  );
};

const StyledGradientBox = styled(Box)`
  background: linear-gradient(
    45deg,
    ${getColor('indigo.100')},
    ${getColor('indigo.050')}
  );
`;

const StyledDismissButton = styled(UnstyledButton)`
  position: absolute;
  top: ${getSpace('sm')};
  right: ${getSpace('sm')};
  opacity: 0.4;
  ${zIndex(2)};
  ${transition}

  &:hover {
    opacity: 1;
  }
`;

const StyledCardImageSvg = styled(SVG)`
  width: 100%;
  height: auto;
`;
