import {
  FeatherArrowRightIcon,
  FeatherChevronRightIcon,
} from '@spaceship-fspl/icons-web';
import {
  Color,
  color,
  fontFamily,
  fontWeight,
  map,
  ResponsiveValue,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

import { Inline } from '../../layout';
import { DistributivePick } from '../../types';
import { UnstyledLink, UnstyledLinkProps } from '../unstyled-link';

type PresentationLinkSize = 'xs' | 'sm' | 'md' | 'lg';
type PresentationLinkColor = DistributivePick<
  Color,
  'indigo.070' | 'indigo.090' | 'neutral.000' | 'neutral.100'
>;

const IconWrapper = styled.div`
  line-height: 0;
`;

const size = createVariant({
  xs: css`
    font-size: 14px;
  `,
  sm: css`
    font-size: 16px;
  `,
  md: css`
    font-size: 20px;
  `,
  lg: css`
    font-size: 24px;
  `,
});

const props = createProps({
  $size: (s: ResponsiveValue<PresentationLinkSize>) => map(s, (ss) => size(ss)),
  $color: color,
});

const Wrapper = styled(UnstyledLink)`
  ${props}
  ${fontFamily('text')}
  ${fontWeight(600)}
  display: inline-block;
  :hover ${IconWrapper} {
    transition: transform 0.5s;
    ${({ $size }) =>
      map(
        $size,
        (size) => css`
          transform: translate(${size === 'sm' ? 2 : 4}px, 0);
        `,
      )}
  }
`;

interface PresentationLinkProps extends UnstyledLinkProps {
  size: ResponsiveValue<PresentationLinkSize>;
  icon: 'arrow' | 'chevron';
  color?: PresentationLinkColor;
}

export const PresentationLink = React.forwardRef<
  HTMLAnchorElement,
  PresentationLinkProps
>(({ children, icon, size, color = 'neutral.100', ...otherProps }, ref) => {
  return (
    <Wrapper ref={ref} $size={size} $color={color} {...otherProps}>
      <Inline alignY="center" spaceX="xxxs">
        <div>{children}</div>
        <IconWrapper>
          {icon == 'arrow' ? (
            <FeatherArrowRightIcon size={size} />
          ) : (
            <FeatherChevronRightIcon size={size} />
          )}
        </IconWrapper>
      </Inline>
    </Wrapper>
  );
});

PresentationLink.displayName = 'PresentationLink';
