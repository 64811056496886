import * as React from 'react';
export const StreamlineDiscountCircle = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM8.333 15.667l7.334-7.334" />
    <path vectorEffect="non-scaling-stroke" d="M9.067 10.533a1.467 1.467 0 100-2.933 1.467 1.467 0 000 2.933zM14.933 16.4a1.467 1.467 0 100-2.933 1.467 1.467 0 000 2.933z" />
  </svg>
);
