import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { Box, Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import {
  StreamlineCheck1Icon,
  StreamlineSpaceRocketFlyingIcon,
  StreamlineWalkingIcon,
} from '@spaceship-fspl/icons-web';
import { useTrack } from '@spaceship-fspl/tracking';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import {
  useBankAccountSubmissionContext,
  useBankAccountVerificationContext,
} from 'contexts/bank-account-verification';
import { MarketingTrackingEvents } from 'helpers/analytics';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from './routes';

enum SelectMethod {
  'MANUAL' = 'manual',
  'AUTOMATIC' = 'automatic',
}

export const BankAccountSelectMethod: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const track = useTrack();
  const navigate = useNavigate();
  const location = useLocation();
  const upPath = `${location.pathname.replace(/\/+$/, '')}/${Routes.UP}`;
  const { selectedBank } = useBankAccountVerificationContext();
  const { variant } = useBankAccountSubmissionContext();
  const { register, handleSubmit } = useForm<{
    selectMethod: SelectMethod;
  }>({
    defaultValues: {
      selectMethod: SelectMethod.AUTOMATIC,
    },
  });

  useEffect(() => {
    if (variant === 'onboarding') {
      track?.(
        MarketingTrackingEvents.VOYAGER_ONBOARDING_LINK_BANK_SELECT_METHOD_SCREEN,
      );
    }
  }, [track, variant]);

  useEffect(() => {
    if (!selectedBank) {
      navigate?.(upPath);
    }
  }, [navigate, selectedBank, upPath]);

  const onSubmit = handleSubmit(async ({ selectMethod }) => {
    switch (selectMethod) {
      case SelectMethod.MANUAL: {
        track?.(
          MarketingTrackingEvents.VOYAGER_ONBOARDING_LINK_BANK_MANUAL_SELECT,
        );
        await navigate(`${upPath}${Routes.BANK_ACCOUNT_MANUAL}`);
        return;
      }
      case SelectMethod.AUTOMATIC: {
        track?.(
          MarketingTrackingEvents.VOYAGER_ONBOARDING_LINK_BANK_AUTO_SELECT,
        );
        await navigate(`${upPath}${Routes.BANK_ACCOUNT_LOGIN}`);
        return;
      }
    }
  });

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 10 / 12 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="xl">
              <Heading
                component="h1"
                variant={3}
                align={{ xs: 'left', md: 'center' }}
              >
                Select a linking option
              </Heading>
              <Columns spaceX="md" spaceY="md">
                <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                  <RadioCard
                    value={SelectMethod.MANUAL}
                    {...register('selectMethod')}
                  >
                    <Box
                      aria-label="Manual linking"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      height="100%"
                    >
                      <Stack spaceY="sm">
                        <StreamlineWalkingIcon
                          size="xxl"
                          strokeWidth={3}
                          color="indigo.070"
                        />

                        <Stack spaceY="md">
                          <Heading component="h3" variant={4}>
                            Manual linking
                          </Heading>
                          <Box display="flex" alignItems="baseline">
                            <StreamlineCheck1Icon
                              size="sm"
                              color="indigo.070"
                            />
                            <Box flex={1} marginLeft="sm">
                              <Text variant={2}>
                                Link using your BSB and Account Number
                              </Text>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="baseline">
                            <StreamlineCheck1Icon
                              size="sm"
                              color="indigo.070"
                            />
                            <Box flex={1} marginLeft="sm">
                              <Text variant={2}>
                                Best option for digital/neobank accounts
                              </Text>
                            </Box>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </RadioCard>
                </Columns.Column>
                <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                  <RadioCard
                    value={SelectMethod.AUTOMATIC}
                    {...register('selectMethod')}
                  >
                    <Box
                      aria-label="Automatic linking"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      height="100%"
                    >
                      <Stack spaceY="sm">
                        <StreamlineSpaceRocketFlyingIcon
                          size="xxl"
                          strokeWidth={3}
                          color="indigo.070"
                        />

                        <Stack spaceY="md">
                          <Heading component="h3" variant={4}>
                            Automatic linking
                          </Heading>
                          <Box display="flex" alignItems="baseline">
                            <StreamlineCheck1Icon
                              size="sm"
                              color="indigo.070"
                            />
                            <Box flex={1} marginLeft="sm">
                              <Text variant={2}>
                                Link using your bank login details
                              </Text>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="baseline">
                            <StreamlineCheck1Icon
                              size="sm"
                              color="indigo.070"
                            />
                            <Box flex={1} marginLeft="sm">
                              <Text variant={2}>Completely automated</Text>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="baseline">
                            <StreamlineCheck1Icon
                              size="sm"
                              color="indigo.070"
                            />
                            <Box flex={1} marginLeft="sm">
                              <Text variant={2}>
                                Secured with 256-bit encryption
                              </Text>
                            </Box>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </RadioCard>
                </Columns.Column>
              </Columns>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{
                    name: 'voyager_onboarding_link_bank_select_method_continue',
                  }}
                />
              </PageFormButtonContainer>
            </Stack>
          </form>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
