import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Card,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
  TextLink,
  Visible,
} from '@spaceship-fspl/components';
import { useNumber } from '@spaceship-fspl/dynamic-config';
import { useAppStoreLinks } from '@spaceship-fspl/graphql';
import {
  ExternalRoutes,
  formatCurrencyWithNoDecimal,
} from '@spaceship-fspl/helpers';
import { getColor, linearGradient } from '@spaceship-fspl/styles';
import qrCodeLogin from 'assets/qr-code-login.svg';
import { AppStoreLink } from 'components/app-store-link';
import { CenterPageContainer } from 'components/layouts/page';
import { DynamicConfigKeys } from 'helpers/dynamic-config';
import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const SuperFhss: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const maxTotalAudAmount = useNumber(
    DynamicConfigKeys.FHSS_MAX_TOTAL_AUD_AMOUNT,
    50000,
  );
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <CenterPageContainer>
      <Columns
        spaceX={{ xs: 'none', lg: 'xl' }}
        spaceY={{ xs: 'xl', lg: 'none' }}
        alignX="center"
        alignY="center"
      >
        <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
          <Stack spaceY="md">
            <Heading variant={3} component="h1">
              Save towards your first home with your Spaceship Super and First
              Home Super Saver (FHSS)
            </Heading>

            <Heading variant={4}>Only on the Spaceship app</Heading>

            <Text variant={2}>
              The First Home Super Saver scheme is an Australian government
              initiative created to help first home buyers save for their home
              deposit. If you&apos;re eligible, you can save up to{' '}
              {formatCurrencyWithNoDecimal(maxTotalAudAmount)} towards your
              first home deposit in your super fund instead of in a bank account
              by making extra voluntary contributions.
              <br />
              <br />
              Our app&apos;s First Home Super Saver feature allows you to track
              voluntary contributions made to Spaceship Super.
              <br />
              <br />
              Conditions apply.
              <br />
              <br />
              Visit the{' '}
              <TextLink
                color="indigo.070"
                href={ExternalRoutes.ATO_FHSS_SCHEME}
                target="_blank"
                rel="noopener noreferrer"
              >
                ATO&apos;s First Home Super Saver Scheme
              </TextLink>
              .
            </Text>
          </Stack>
        </Columns.Column>

        <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
          <Card borderRadius={{ xs: 'sm', lg: 'lg' }} overflow="hidden">
            <Visible isHidden={{ xs: true, lg: false }} displayValue="block">
              <Box paddingY="xxl" paddingX="xl">
                <Stack spaceY="lg" alignX="center">
                  <Heading variant={5} color="neutral.080" align="center">
                    Scan code for app
                  </Heading>

                  <StyledQRCodeSVG src={qrCodeLogin} />
                </Stack>
              </Box>
            </Visible>

            <StyledAppStoreBox>
              <Stack spaceY={{ xs: 'sm', lg: 'lg' }} alignX="center">
                <Visible isHidden={{ lg: true }} displayValue="block">
                  <Heading variant={5} color="neutral.000" align="center">
                    Visit the App Store
                  </Heading>
                </Visible>

                <Visible
                  isHidden={{ xs: true, lg: false }}
                  displayValue="block"
                >
                  <Heading variant={5} color="neutral.000" align="center">
                    Or visit the App Store
                  </Heading>
                </Visible>

                <Inline spaceX="sm" alignX="center" spaceY="xxs">
                  <AppStoreLink
                    store="gplay"
                    variant="dark"
                    size="xl"
                    url={appStoreLinksResp.data?.googlePlayStoreDeeplink?.url}
                  />
                  <AppStoreLink
                    store="ios"
                    variant="dark"
                    size="xl"
                    url={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
                  />
                </Inline>
              </Stack>
            </StyledAppStoreBox>
          </Card>
        </Columns.Column>
      </Columns>
    </CenterPageContainer>
  );
};

const StyledQRCodeSVG = styled(SVG)`
  max-height: 190px;
  max-width: 190px;
  color: ${getColor('indigo.070')};
`;

const StyledAppStoreBox = styled(Box).attrs({
  paddingY: { xs: 'lg', lg: 'xxl' },
  paddingX: { xs: 'lg', lg: 'xl' },
})`
  background: ${linearGradient({
    angle: '259.95deg',
    colorStops: [
      ['blue.050', '-90.67%'],
      ['indigo.050', '122.19%'],
    ],
  })};
`;
