import 'core-js/stable';
import 'es6-promise/auto';
import 'isomorphic-fetch';
import 'regenerator-runtime/runtime';

import { LocationProvider } from '@reach/router';
import { Provider as AuthProvider } from '@spaceship-fspl/auth';
import { Reset } from '@spaceship-fspl/components';
import { Provider as DataProvider } from '@spaceship-fspl/data';
import { backgroundColor, themes } from '@spaceship-fspl/styles';
import { SegmentProvider } from '@spaceship-fspl/tracking';
import { MFAVerificationModalRef } from 'components/mfa-verification-modal';
import { Provider as ApolloProvider } from 'contexts/apollo';
import { DeepLinkingProvider } from 'contexts/deep-linking';
import { IntercomProvider } from 'contexts/intercom';
import { MFAVerificationProvider } from 'contexts/mfa-provider';
import { NotificationsProvider } from 'contexts/notifications';
import { SuperProvider } from 'contexts/super-provider';
import { authClient } from 'helpers/auth-client';
import { config } from 'helpers/config';
import { addRumError, initMonitoring } from 'helpers/monitoring';
import React, { useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { fetch } from './helpers/fetch';
import { Navigation } from './navigation';

try {
  initMonitoring();
} catch (e) {
  console.error(e);
} finally {
  window.setInterval(
    () => window.location.reload(),
    1000 * 60 * 45, // 45 minutes
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    ${backgroundColor('neutral.015')}
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  },
});

const App: React.FC<React.PropsWithChildren> = () => {
  const handleSegmentError = useCallback((error: unknown): void => {
    addRumError({
      error,
      context: { reason: 'Could not initialise Segment' },
    });
  }, []);

  const mfaVerificationModalRef = React.useRef<MFAVerificationModalRef>(null);

  return (
    <LocationProvider>
      <SegmentProvider
        writeKey={config.segmentKey}
        onError={handleSegmentError}
      >
        <DeepLinkingProvider>
          <ThemeProvider theme={themes.webapp.onGrey}>
            <Reset />
            <GlobalStyle />
            <AuthProvider client={authClient}>
              <DataProvider fetch={fetch}>
                <NotificationsProvider>
                  <IntercomProvider>
                    <ApolloProvider
                      mfaVerificationModalRef={mfaVerificationModalRef}
                    >
                      <QueryClientProvider client={queryClient}>
                        <SuperProvider>
                          <MFAVerificationProvider
                            mfaVerificationModalRef={mfaVerificationModalRef}
                          >
                            <Navigation />
                          </MFAVerificationProvider>
                        </SuperProvider>
                      </QueryClientProvider>
                    </ApolloProvider>
                  </IntercomProvider>
                </NotificationsProvider>
              </DataProvider>
            </AuthProvider>
          </ThemeProvider>
        </DeepLinkingProvider>
      </SegmentProvider>
    </LocationProvider>
  );
};

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
