import { RouteComponentProps, useParams } from '@reach/router';
import {
  stringToDate,
  sydneyDate,
  useEstimatedProcessedDate,
} from '@spaceship-fspl/helpers';
import { VoyagerTransactionSuccess } from 'components/voyager-transaction-success';
import React from 'react';

interface VoyagerWithdrawSuccessProps {
  productId?: string;
  location: {
    state: {
      audAmount: string;
    };
  };
}

export const VoyagerWithdrawSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerWithdrawSuccessProps>>
> = (props) => {
  const { productId = '' } = useParams();

  const audAmount = props?.location?.state?.audAmount ?? '';
  const etaDate = stringToDate(useEstimatedProcessedDate(sydneyDate()));

  return (
    <VoyagerTransactionSuccess
      variant="withdraw"
      productId={productId}
      amount={audAmount}
      etaDate={etaDate}
    />
  );
};
