import { RouteComponentProps, useParams } from '@reach/router';
import { VoyagerTransactionSuccess } from 'components/voyager-transaction-success';
import React from 'react';

export interface VoyagerDepositSuccessProps {
  productId?: string;
  location: {
    state: {
      productId: string;
      audAmount: string;
      etaDate: Date;
    };
  };
}

export const VoyagerDepositSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerDepositSuccessProps>>
> = (props) => {
  const { productId = '' } = useParams();

  const audAmount = props?.location?.state?.audAmount ?? '';
  const etaDate = props?.location?.state?.etaDate
    ? new Date(props?.location?.state?.etaDate)
    : undefined;

  return (
    <VoyagerTransactionSuccess
      variant="invest"
      productId={productId}
      amount={audAmount}
      etaDate={etaDate}
    />
  );
};
