import * as React from 'react';
export const VoyagerProduct = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M13.01 1.45a.6.6 0 01.317.334l5.466 14.196a.599.599 0 01-.327.817c-1.315.506-2.76-.136-3.96-1.223-1.229-1.113-2.364-2.823-3.148-4.857-.784-2.035-1.089-4.063-.924-5.713.161-1.612.803-3.056 2.117-3.564a.6.6 0 01.459.01zm4.415 14.317L12.471 2.901c-.421.42-.736 1.156-.843 2.222-.143 1.427.119 3.266.85 5.162.73 1.897 1.77 3.437 2.834 4.4.793.718 1.52 1.052 2.113 1.082z" />
    <path d="M16.802 9.68a1.056 1.056 0 10.065-2.11 1.056 1.056 0 00-.065 2.11zM8.399 13.09a.6.6 0 01.144.835l-6.645 9.42a.6.6 0 01-.98-.691l6.645-9.42a.6.6 0 01.836-.146v.002zm2.243-5.907a.6.6 0 01.775.344l2.146 5.571a.6.6 0 01-.344.775l-.881.34a.6.6 0 01-.776-.345L9.416 8.297a.6.6 0 01.345-.775l.88-.34z" />
    <path d="M8.519 4.293a.6.6 0 01.775.345l4.63 12.025a.6.6 0 01-.344.775l-3.52 1.356a.599.599 0 01-.776-.344L4.654 6.425a.6.6 0 01.344-.776l3.52-1.356zm-.13 1.337l-2.4.924 4.199 10.906 2.4-.925L8.39 5.63zm9.36.53a.599.599 0 01.61-.591 2.487 2.487 0 011.69 4.276.6.6 0 01-.833-.863 1.289 1.289 0 00-.875-2.214.6.6 0 01-.59-.61l-.001.002z" />
    <path d="M18.248 3.98a.6.6 0 01.61-.59 4.507 4.507 0 013.063 7.755.6.6 0 11-.833-.864 3.307 3.307 0 00-2.25-5.69.6.6 0 01-.59-.611zm-9.474 7.67a.6.6 0 01-.345.776l-3.867 1.49a.6.6 0 11-.431-1.12l3.867-1.49a.601.601 0 01.776.345v-.001z" />
    <path d="M6.122 7.358a.6.6 0 01.287.799l-2.037 4.335a.6.6 0 11-1.086-.51l2.037-4.336a.6.6 0 01.8-.288zm-1.876 6.98a.6.6 0 01.785-.322l4.333 1.814a.6.6 0 11-.463 1.107l-4.333-1.814a.6.6 0 01-.322-.785z" />
    <path d="M3.355 10.998a.6.6 0 01.775.344l1.47 3.813a.6.6 0 01-.345.775l-2.053.79a.598.598 0 01-.776-.343L.957 12.564a.6.6 0 01.344-.776l2.054-.79zm-.129 1.335l-.934.36 1.037 2.693.934-.36-1.037-2.693zM9.198 12.607a1.12 1.12 0 100-2.239 1.12 1.12 0 000 2.24z" />
    <path d="M.424 9.024a.6.6 0 01.743-.41l8.2 2.36a.6.6 0 01-.332 1.154l-8.2-2.362a.6.6 0 01-.411-.742z" />
  </svg>
);
