import { Columns } from '@spaceship-fspl/components';
import { AUSSIE_DATE_FORMAT, capitalizeWords } from '@spaceship-fspl/helpers';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import {
  investmentPlanStartDateValidation,
  InvestmentType,
  useInvestmentValidation,
} from '@spaceship-fspl/voyager';
import { AccountCardEditFormButtons } from 'components/account-cards/components';
import { VoyagerInvestmentPlanSummaryCard } from 'components/account-cards/voyager-investment-plan-summary';
import { VoyagerOneOffInvestmentCard } from 'components/account-cards/voyager-one-off-investment';
import { ControllerInput } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import { usePortfolioRegistration } from 'contexts/saver/portfolio-registration';
import {
  cleanNumber,
  fromAussieDate,
  fromISODate,
  toAussieDate,
  toISODate,
} from 'helpers/format';
import { commonValidationRules, requiredValidation } from 'helpers/validation';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export interface InvestmentSectionProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const OneOffInvestmentSection: React.FC<
  React.PropsWithChildren<InvestmentSectionProps>
> = ({ isEditing, setIsEditing }) => {
  const { oneOffDeposit, setOneOffDeposit } = usePortfolioRegistration();
  const currentAmount = oneOffDeposit || '';

  const { control, handleSubmit, reset } = useForm<{
    initialInvestment: string;
  }>();

  const onSubmit = handleSubmit(({ initialInvestment }) => {
    setOneOffDeposit?.(cleanNumber(initialInvestment));
    setIsEditing(false);
  });

  const validate = useInvestmentValidation(InvestmentType.ONE_OFF);

  useEffect(() => {
    reset({ initialInvestment: currentAmount });
  }, [currentAmount, reset]);

  return (
    <VoyagerOneOffInvestmentCard
      audAmount={currentAmount}
      edit={{
        onClick: () => setIsEditing(true),
        text: !currentAmount ? 'Add' : 'Edit',
        isEditing,
      }}
    >
      {isEditing && (
        <form onSubmit={onSubmit}>
          <ControllerInput
            name="initialInvestment"
            control={control}
            type="text"
            format="currency"
            placeholder="Investment amount"
            rules={{
              required: 'Amount is required',
              validate,
            }}
          />

          <AccountCardEditFormButtons
            cancel={{
              onClick: () => setIsEditing(false),
              trackingProperties: { name: 'one_off_investment_edit_cancel' },
            }}
            submitTrackingProperties={{ name: 'one_off_investment_edit_save' }}
          />
        </form>
      )}
    </VoyagerOneOffInvestmentCard>
  );
};

export const InvestmentPlanSection: React.FC<
  React.PropsWithChildren<InvestmentSectionProps>
> = ({ isEditing, setIsEditing }) => {
  const { investmentPlan, setInvestmentPlan } = usePortfolioRegistration();

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<{
    recurringInvestment: {
      amount: string;
      frequency: SchedulerFrequency.Enum;
      start_date: string;
    };
  }>();

  const plan =
    !!investmentPlan?.amount &&
    !!investmentPlan?.frequency &&
    !!investmentPlan?.start_date
      ? {
          frequency: investmentPlan?.frequency,
          audAmount: investmentPlan?.amount,
          nextDue: investmentPlan?.start_date,
        }
      : undefined;

  const validate = useInvestmentValidation(InvestmentType.PLAN);

  const onSubmit = handleSubmit(({ recurringInvestment }) => {
    setInvestmentPlan?.({
      ...recurringInvestment,
      amount: cleanNumber(recurringInvestment?.amount ?? ''),
      start_date: toISODate(fromAussieDate(recurringInvestment.start_date)),
      frequency: Number(recurringInvestment?.frequency),
    });
    setIsEditing(false);
  });

  useEffect(() => {
    reset({
      recurringInvestment: {
        amount: investmentPlan?.amount || '',
        start_date: investmentPlan?.start_date
          ? toAussieDate(fromISODate(investmentPlan.start_date))
          : '',
        frequency: investmentPlan?.frequency
          ? Number(investmentPlan.frequency)
          : undefined,
      },
    });
  }, [investmentPlan, reset]);

  return (
    <VoyagerInvestmentPlanSummaryCard
      plan={plan}
      edit={{
        onClick: () => setIsEditing(true),
        text: !plan ? 'Set up plan' : 'Edit',
        isEditing,
      }}
      onSetupPlan={() => setIsEditing(true)}
      isNewPlan={true}
    >
      {isEditing && (
        <form onSubmit={onSubmit}>
          <Columns spaceX="xs" spaceY="sm">
            <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
              <Dropdown
                placeholder="Frequency"
                options={Object.entries(SchedulerFrequency.Enum)
                  .filter(
                    ([, val]) =>
                      val !== SchedulerFrequency.Enum.UNKNOWN &&
                      val !== SchedulerFrequency.Enum.ONE_TIME,
                  )
                  .map(([label, value]) => ({
                    value: String(value),
                    label: capitalizeWords(label),
                  }))}
                errorMessage={errors.recurringInvestment?.frequency?.message}
                {...register('recurringInvestment.frequency', {
                  ...requiredValidation('Frequency'),
                })}
              />
            </Columns.Column>

            <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
              <ControllerInput
                name="recurringInvestment.amount"
                control={control}
                type="text"
                format="currency"
                placeholder="Investment amount"
                rules={{
                  required: 'Amount is required',
                  validate,
                }}
              />
            </Columns.Column>

            <Columns.Column width={1}>
              <ControllerInput
                name="recurringInvestment.start_date"
                control={control}
                type="text"
                format="date"
                placeholder="Start date (dd-mm-yyyy)"
                rules={{
                  ...requiredValidation('Start date'),
                  pattern: commonValidationRules.date.pattern,
                  validate: (value) =>
                    investmentPlanStartDateValidation(
                      value,
                      AUSSIE_DATE_FORMAT,
                    ),
                }}
              />
            </Columns.Column>
          </Columns>

          <AccountCardEditFormButtons
            cancel={{
              onClick: () => setIsEditing(false),
              trackingProperties: { name: 'investment_plan_edit_cancel' },
            }}
            submitTrackingProperties={{ name: 'investment_plan_edit_save' }}
          />
        </form>
      )}
    </VoyagerInvestmentPlanSummaryCard>
  );
};
