import { RouteComponentProps } from '@reach/router';
import {
  FundSearchFund,
  FundSearchOperation,
  FundSearchResult,
  RolloverStatusDetail,
} from '@sargon/api-client';
import { Box, Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { addDays, formatCurrency, getToday } from '@spaceship-fspl/helpers';
import {
  HelpWheelIcon,
  StreamlineDiscountCircleIcon,
  StreamlineLegalScaleUnequal1Icon,
  StreamlineShieldWarningIcon,
} from '@spaceship-fspl/icons-web';
import {
  isOtpError,
  SuperMatchErrorType,
  useGetLatestFundSearchResult,
  useListFundSearchResult,
  useListRolloverRequests,
  usePerformFundSearch,
} from '@spaceship-fspl/super';
import {
  CenterOnboardingContainer,
  OnboardingContainer,
} from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useSuperMatch } from 'contexts/super/super-match';
import { isAfter, isEqual } from 'date-fns';
import { Decimal } from 'decimal.js';
import { cleanNumber } from 'helpers/format';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import {
  ClaimedFundCard,
  ClaimedFundTransferType,
} from './fund-card/claimed-fund';
import {
  UnclaimedFundsCard,
  UnclaimedFundsTransferType,
} from './fund-card/unclaimed-funds';

interface RolloverFunds {
  claimedFund: Record<string, Record<string, string>>;
  unclaimedFundsTransferType: UnclaimedFundsTransferType;
}

const getTotalClaimedFundsDecimal = (
  selectedClaimedFunds: RolloverFunds['claimedFund'],
  claimedFunds: FundSearchFund[],
): Decimal => {
  if (selectedClaimedFunds) {
    let total = new Decimal(0);
    Object.entries(selectedClaimedFunds).forEach(([key, value]) => {
      const balance = claimedFunds.find(
        (f) => `${f.fundABN}-${f.accountNumber}` === key,
      )?.accountBalance;

      if (
        value.transferType === ClaimedFundTransferType.FULL &&
        balance?.amount
      ) {
        total = total.add(new Decimal(balance.amount).dividedBy(100));
        return;
      }

      if (
        value.transferType === ClaimedFundTransferType.PARTIAL &&
        value.amount
      ) {
        total = total.add(new Decimal(Number(cleanNumber(value.amount))));
        return;
      }
    });
    return total;
  }

  return new Decimal(0);
};

export const SuperMatchResults: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { hasAccess, returnToStart, onCancel, onError, onSelectFunds } =
    useSuperMatch();
  const listRolloverRequests = useListRolloverRequests();
  const [fundSearchResults, setFundSearchResults] = useState<
    FundSearchResult | undefined
  >();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formMethods = useForm<RolloverFunds>({
    mode: 'onChange',
  });
  const claimedFunds = useMemo(
    () => fundSearchResults?.funds ?? [],
    [fundSearchResults?.funds],
  );

  const unclaimedMoneys = useMemo(
    () => fundSearchResults?.unclaimedMoneys ?? [],
    [fundSearchResults?.unclaimedMoneys],
  );

  const totalUnclaimedFundsDecimal = useMemo(() => {
    return unclaimedMoneys.reduce((total, fund) => {
      return total.add(new Decimal(fund?.amount?.amount || 0).dividedBy(100));
    }, new Decimal(0));
  }, [unclaimedMoneys]);

  const handleTransferFunds = (formValues: RolloverFunds): void => {
    setIsSubmitting(true);

    const selectedFunds: RolloverStatusDetail[] = [];

    if (claimedFunds && formValues.claimedFund) {
      const selectedFundsToRollover = Object.entries(formValues.claimedFund);

      claimedFunds.forEach(
        ({ accountBalance, uniqueSuperIdentifier, fundABN, accountNumber }) => {
          const key = `${fundABN}-${accountNumber}`;
          const fund = selectedFundsToRollover.find(
            ([name, value]) => name === key && !!value,
          );
          const value = fund?.[1];

          if (
            value?.transferType === ClaimedFundTransferType.FULL ||
            value?.transferType === ClaimedFundTransferType.PARTIAL
          ) {
            let transferAmount = undefined;
            if (
              value?.amount &&
              accountBalance?.amount &&
              accountBalance?.currency
            ) {
              const selectedAmount = new Decimal(
                Number(cleanNumber(value.amount)),
              ).mul(100);

              // amount is set to undefined for FULL transfer of fund
              transferAmount =
                selectedAmount.comparedTo(accountBalance.amount) !== 0
                  ? {
                      amount: selectedAmount.toNumber(),
                      currency: accountBalance.currency,
                    }
                  : undefined;
            }

            selectedFunds.push({
              fundABN,
              uniqueSuperIdentifier,
              accountNumber,
              transferAmount,
            });
          }
        },
      );
    }

    onSelectFunds({
      rolloverUnclaimedMoneys:
        formValues.unclaimedFundsTransferType ===
        UnclaimedFundsTransferType.FULL,
      funds: selectedFunds,
    });

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!hasAccess) {
      returnToStart();
    }
  }, [hasAccess, returnToStart]);

  if (!hasAccess) {
    return null;
  }

  if (!fundSearchResults || listRolloverRequests.isLoading) {
    return (
      <Searching
        setFundSearchResults={setFundSearchResults}
        onCancel={onCancel}
        onError={onError}
      />
    );
  }

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, xl: 9 / 12 }}>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleTransferFunds)}>
              <Stack spaceY="xl">
                <PageHeading
                  title="We found these super accounts"
                  subtitle="Please note, the funds and their balances come directly from the Australian Tax Office and can be up to 12 months out of date."
                />

                <Columns spaceX="md" spaceY="md">
                  <Columns.Column width={{ xs: 1, xl: 7 / 12 }}>
                    <Stack spaceY="sm">
                      {claimedFunds?.map((fund) => {
                        return (
                          <ClaimedFundCard
                            {...fund}
                            rolloverRequests={listRolloverRequests.data}
                            key={fund.fundName}
                          />
                        );
                      })}

                      {unclaimedMoneys.length > 0 && (
                        <UnclaimedFundsCard
                          unclaimedMoneys={unclaimedMoneys}
                          total={formatCurrency(
                            totalUnclaimedFundsDecimal.toNumber(),
                          )}
                          hasConsented={
                            !!listRolloverRequests.data?.unclaimedMoney
                          }
                        />
                      )}
                    </Stack>
                  </Columns.Column>
                  <Columns.Column width={{ xs: 1, xl: 5 / 12 }}>
                    <Disclaimer />
                  </Columns.Column>
                </Columns>

                <TotalRolloverFunds
                  totalUnclaimedFundsDecimal={totalUnclaimedFundsDecimal}
                  claimedFunds={claimedFunds}
                />
              </Stack>

              <PageFormButtonContainer>
                <PageFormContinueButton
                  isLoading={isSubmitting}
                  trackingProperties={{ name: 'supermatch_results_continue' }}
                />
                <PageFormCancelButton
                  onClick={onCancel}
                  trackingProperties={{ name: 'supermatch_results_cancel' }}
                />
              </PageFormButtonContainer>
            </form>
          </FormProvider>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

const TotalRolloverFunds: React.FC<
  React.PropsWithChildren<{
    totalUnclaimedFundsDecimal: Decimal;
    claimedFunds: FundSearchFund[];
  }>
> = ({ totalUnclaimedFundsDecimal, claimedFunds }) => {
  const { control } = useFormContext();

  const selectedClaimedFunds = useWatch({
    control,
    name: 'claimedFund',
    defaultValue: {},
  });

  const unclaimedFundsTransferType = useWatch({
    control,
    name: 'unclaimedFundsTransferType',
  });

  const isUnclaimedFundsFullTransfer =
    unclaimedFundsTransferType === UnclaimedFundsTransferType.FULL;

  const totalAmount = useMemo(() => {
    const totalRolloverClaimedFundsDecimal = getTotalClaimedFundsDecimal(
      selectedClaimedFunds,
      claimedFunds,
    );

    let totalRolloverFundsDecimal = totalRolloverClaimedFundsDecimal;

    if (isUnclaimedFundsFullTransfer) {
      totalRolloverFundsDecimal = totalRolloverClaimedFundsDecimal.add(
        totalUnclaimedFundsDecimal,
      );
    }

    return totalRolloverFundsDecimal.toNumber();
  }, [
    selectedClaimedFunds,
    claimedFunds,
    isUnclaimedFundsFullTransfer,
    totalUnclaimedFundsDecimal,
  ]);

  if (totalAmount > 0) {
    return (
      <div>
        <Text variant={2} color="neutral.085" align="center">
          Total to transfer to Spaceship Super:
        </Text>
        <Text variant={1} align="center" isBold={true}>
          {formatCurrency(totalAmount)}
        </Text>
      </div>
    );
  }

  return null;
};

const Searching: React.FC<
  React.PropsWithChildren<{
    setFundSearchResults: (data: FundSearchResult) => void;
    onCancel: () => void;
    onError: (errorType?: SuperMatchErrorType) => void;
  }>
> = ({ onCancel, onError, setFundSearchResults }) => {
  const [fundSearchId, setFundSearchId] = useState<string | undefined>();
  const [isPerformSearchRequired, setIsPerformSearchRequired] = useState(false);

  const latestFundSearchResult = useGetLatestFundSearchResult({});

  const performFundSearch = usePerformFundSearch({
    isEnabled: isPerformSearchRequired && !fundSearchId,
    retry: 5,
    retryDelay: 500,
  });

  const listFundSearchResult = useListFundSearchResult({
    isEnabled: !!fundSearchId,
    fundSearchId,
    retry: 10,
    retryDelay: 1000,
  });

  const setFundResultAndTotalFund = useCallback(
    (fundSearchResults: FundSearchResult) => {
      const { funds = [], unclaimedMoneys = [] } = fundSearchResults || {};
      const totalFundsFound = funds.length + unclaimedMoneys.length;

      if (totalFundsFound > 0) {
        setFundSearchResults(fundSearchResults);
      } else {
        onError(SuperMatchErrorType.NO_ACCOUNTS_FOUND);
      }
    },
    [onError, setFundSearchResults],
  );

  useEffect(() => {
    const checkLatestFundResultError = async (
      error: Response,
    ): Promise<void> => {
      if (await isOtpError(error)) {
        return;
      }
      setIsPerformSearchRequired(true);
    };

    if (latestFundSearchResult.isFetched && !fundSearchId) {
      if (latestFundSearchResult.data) {
        const createdDate = latestFundSearchResult.data?.created
          ? addDays(new Date(latestFundSearchResult.data.created), 30)
          : undefined;
        const todayDate = getToday();
        if (
          latestFundSearchResult.data?.status ===
            FundSearchOperation.StatusEnum.Success &&
          createdDate &&
          (isEqual(createdDate, todayDate) || isAfter(createdDate, todayDate))
        ) {
          setFundResultAndTotalFund(latestFundSearchResult.data);
          return;
        } else {
          setIsPerformSearchRequired(true);
          return;
        }
      } else if (latestFundSearchResult.isError) {
        checkLatestFundResultError(latestFundSearchResult.error as Response);
        return;
      }
    }
  }, [latestFundSearchResult, fundSearchId, setFundResultAndTotalFund]);

  useEffect(() => {
    if (performFundSearch.data && !fundSearchId) {
      const id = performFundSearch.data?.id;
      if (id) {
        setFundSearchId(id);
      } else {
        onError(SuperMatchErrorType.SUPERMATCH_DOWN);
      }
    }

    if (performFundSearch.isError) {
      onError(SuperMatchErrorType.SUPERMATCH_DOWN);
    }
  }, [performFundSearch, fundSearchId, onError]);

  useEffect(() => {
    if (listFundSearchResult.data && listFundSearchResult.isFetched) {
      setFundResultAndTotalFund(listFundSearchResult.data);
    }

    if (listFundSearchResult.isError) {
      onError(SuperMatchErrorType.SUPERMATCH_DOWN);
    }
  }, [listFundSearchResult, onError, setFundResultAndTotalFund]);

  return (
    <CenterOnboardingContainer>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <Stack spaceY="sm">
            <PageHeading
              title="Searching..."
              subtitle="Please wait while we find your super accounts."
            />
          </Stack>

          <PageFormButtonContainer>
            <PageFormCancelButton
              onClick={onCancel}
              trackingProperties={{ name: 'supermatch_searching_cancel' }}
            >
              Cancel search
            </PageFormCancelButton>
          </PageFormButtonContainer>
        </Columns.Column>
      </Columns>
    </CenterOnboardingContainer>
  );
};

const Disclaimer: React.FC<React.PropsWithChildren> = () => (
  <Box borderRadius="sm" backgroundColor="neutral.030" padding="md">
    <Stack spaceY="sm">
      <Heading variant={5} isBold={true} component="h3">
        Consolidating your super
      </Heading>
      <Text variant={3}>
        You should consider how rolling over your existing super accounts might
        impact you, including:
      </Text>

      <Box display="flex" flexDirection="row">
        <Box lineHeight={0} marginRight="md">
          <StreamlineLegalScaleUnequal1Icon size="xl" color="indigo.070" />
        </Box>
        <Text variant={3}>
          How the fees, risks and benefits of your other super funds compare to
          the fees, risks and benefits of Spaceship Super.
        </Text>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box lineHeight={0} marginRight="md">
          <StreamlineShieldWarningIcon size="xl" color="indigo.070" />
        </Box>
        <Text variant={3}>
          How by rolling over the full value of an existing super account to
          Spaceship Super, your existing super account will be closed and you
          may lose some benefits, such as life insurance.
        </Text>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box lineHeight={0} marginRight="md">
          <HelpWheelIcon size="xl" color="indigo.070" />
        </Box>
        <Text variant={3}>
          That Spaceship Super does not currently offer life, total permanent
          disability or income protection insurance.
        </Text>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box lineHeight={0} marginRight="md">
          <StreamlineDiscountCircleIcon size="xl" color="indigo.070" />
        </Box>
        <Text variant={3}>
          What investment or tax implications may arise for you.
        </Text>
      </Box>

      <Text variant={3}>
        Talking to a professional financial adviser can help you make this
        decision.
      </Text>
    </Stack>
  </Box>
);
