import { Box } from '@spaceship-fspl/components';
import { FeatherChevronDownIcon } from '@spaceship-fspl/icons-web';
import {
  Color,
  getColor,
  getSpace,
  shadows,
  Theme,
} from '@spaceship-fspl/styles';
import React, { useContext, useState } from 'react';
import Select, {
  components,
  GroupTypeBase,
  PlaceholderProps,
  Props as ReactSelectProps,
  StylesConfig,
  ValueContainerProps,
} from 'react-select';
import { ThemeContext } from 'styled-components';

const { Placeholder, ValueContainer } = components;

type CustomStylesType = StylesConfig<
  Option | Option[],
  boolean,
  GroupTypeBase<Option | Option[]>
>;

export interface Option {
  value: string;
  label: string;
}

export interface AutoCompleteDropdownProps
  extends ReactSelectProps<
    Option | Option[],
    boolean,
    GroupTypeBase<Option | Option[]>
  > {
  options: Option[];
  name?: string;
  placeholder: string;
  isLoading?: boolean;
  errorMessage?: string;
  id?: string;
}

type CustomValueContainerProps = ValueContainerProps<
  Option | Option[],
  boolean,
  GroupTypeBase<Option | Option[]>
>;

const CustomValueContainer: React.FC<
  React.PropsWithChildren<CustomValueContainerProps>
> = ({ children, ...props }) => {
  const filteredChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type !== Placeholder,
  );

  return (
    <ValueContainer {...props}>
      {/* Customise react select placeholder component to be the same as input label */}
      <Placeholder
        {...(props as PlaceholderProps<
          Option | Option[],
          boolean,
          GroupTypeBase<Option | Option[]>
        >)}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {filteredChildren}
    </ValueContainer>
  );
};

export const AutoCompleteDropdown: React.FC<
  React.PropsWithChildren<AutoCompleteDropdownProps>
> = ({
  isLoading,
  placeholder,
  errorMessage,
  id,
  controlShouldRenderValue,
  defaultMenuIsOpen,
  onFocus,
  onBlur,
  onMenuOpen,
  onMenuClose,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(defaultMenuIsOpen ?? false);
  const theme = useContext(ThemeContext);

  return (
    <Select
      id={id}
      instanceId={id}
      placeholder={errorMessage ?? placeholder}
      controlShouldRenderValue={controlShouldRenderValue}
      onFocus={(e) => {
        setIsFocused(true);
        onFocus?.(e);
      }}
      onMenuOpen={() => {
        setIsFocused(true);
        onMenuOpen?.();
      }}
      onBlur={(e) => {
        setIsFocused(false);
        onBlur?.(e);
      }}
      onMenuClose={() => {
        setIsFocused(false);
        onMenuClose?.();
      }}
      components={{
        ValueContainer: (props: CustomValueContainerProps) => (
          <CustomValueContainer {...props} />
        ),
        DropdownIndicator: () => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            paddingX="sm"
          >
            <FeatherChevronDownIcon color="neutral.080" size="sm" />
          </Box>
        ),
        IndicatorSeparator: null,
      }}
      styles={getStyles({
        isFocused,
        hasError: !!errorMessage,
        willRenderValues: controlShouldRenderValue ?? true,
        theme,
      })}
      {...props}
    />
  );
};

const getStyles = ({
  isFocused,
  hasError,
  willRenderValues,
  theme,
}: {
  isFocused: boolean;
  hasError: boolean;
  willRenderValues: boolean;
  theme: Theme;
}): CustomStylesType => {
  const fontStyle = {
    fontFamily: theme.fontFamilies.text,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    color: getColor('neutral.100'),
  };

  return {
    indicatorsContainer: () => ({
      cursor: 'pointer',
      height: '100%',
    }),
    control: (_, state) => {
      const errorShadow = getShadowStyle({ color: 'red.100', isThick: true });
      const focusShadow = getShadowStyle({
        color: 'indigo.070',
        isThick: true,
      });

      return {
        backgroundColor: getColor('neutral.000'),
        borderRadius: getSpace('xxs'),
        boxShadow: hasError
          ? errorShadow
          : isFocused
            ? focusShadow
            : state.hasValue
              ? getShadowStyle({ color: 'indigo.070' })
              : getShadowStyle({ color: 'neutral.050' }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: getSpace('xxl'),

        ':hover': {
          boxShadow: hasError
            ? errorShadow
            : isFocused
              ? focusShadow
              : getShadowStyle({ color: 'indigo.020', isThick: true }),
        },
      };
    },
    valueContainer: () => ({
      position: 'relative',
      flex: 1,
      height: '100%',
      cursor: 'text',
    }),
    placeholder: (_, state) => {
      const isFocusStyle =
        (willRenderValues && state.hasValue) || hasError || isFocused;

      return {
        ...fontStyle,
        fontSize: isFocusStyle ? '12px' : '16px',
        color: getColor(hasError ? 'red.100' : 'neutral.080'),
        lineHeight: '1em',
        paddingLeft: getSpace('sm'),
        pointerEvents: 'none',
        position: 'absolute',
        top: isFocusStyle ? '14px' : '50%',
        transform: `translateY(${isFocusStyle ? '0' : '-50'}%)`,
        transition: '150ms linear',
      };
    },
    input: () => ({
      position: 'absolute',
      bottom: 0,
      top: getSpace('md'),
      left: getSpace('sm'),
      right: 0,

      input: {
        ...fontStyle,
        height: `calc(${getSpace('xxl')} - ${getSpace('md')})`,
      },
    }),
    singleValue: () => ({
      ...fontStyle,
      position: 'absolute',
      bottom: 0,
      top: getSpace('md'),
      left: getSpace('sm'),
      right: 0,
      lineHeight: `calc(${getSpace('xxl')} - ${getSpace('md')})`,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    menu: (rsStyles) => ({
      ...rsStyles,
      borderRadius: getSpace('xxs'),
      boxShadow: shadows.lg,
      overflow: 'hidden',
    }),
    option: (rsStyles, state) => ({
      ...rsStyles,
      ...fontStyle,
      backgroundColor: getColor(
        state.isSelected
          ? 'indigo.070'
          : state.isFocused
            ? 'indigo.015'
            : 'neutral.000',
      ),
      color: getColor(state.isSelected ? 'neutral.000' : 'neutral.100'),
      cursor: 'pointer',
      paddingLeft: getSpace('sm'),
      paddingRight: getSpace('sm'),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      transition: '150ms linear',
    }),
  };
};

const getShadowStyle = ({
  color,
  isThick,
}: {
  color: Color;
  isThick?: boolean;
}): string => `
  inset 0px 0px 0px ${isThick ? 2 : 1}px ${getColor(color)};
`;
