export enum PersistKey {
  AUTH = 's8.auth.v1',
  SIGNUP = 's8.signup.v1',
  ONBOARDING = 's8.onboarding.v1',
  REFERRALS_CARD_LAST_DISMISSED = 's8.referrals_card_last_dismissed.v1',
  DEEP_LINKING_PARAMS = 's8.branch_params.v1',
  BIOMETRIC_VERIFICATION_SUCCESS_DISMISSED_UNTIL = 's8.biometric_verification_success_last_dismissed.v1',
  BOOSTS_CARD_LAST_DISMISSED = 's8.boosts_card_last_dismissed.v1',
  HIGHLIGHT_FEATURES_DISMISSED = 's8.highlight_features_dismissed.v1',
  SUPER_UNIT_PRICE_TIME_SCALE = 's8.super_unit_price_time_scale.v1',
  SUPERMATCH_GREENID_VERIFICATION_SUCCESS_DISMISSED_UNTIL = 's8.supermatch_greenid_verification_success_last_dismissed.v1',
  SUPERMATCH_CARD_DISMISSED_UNTIL = 's8.supermatch_card_dismissed_until.v1',
  SUPER_BALANCE_TIME_SCALE = 's8.super_balance_time_scale.v1',
  SUPER_SIGNUP_DETAILS = 'super_signup_details_v1',
  SUPER_INVESTMENT_ALLOCATIONS_SELECTION_REQUESTED = 'super_investment_allocations_selection_requested',
  SUPER_REFERRALS_CARD_DISMISSED_UNTIL = 'super_referrals_card_dismissed_until.v1',
}

export const retrieve = (key: PersistKey): string | null =>
  window.localStorage.getItem(key);

export const store = (key: PersistKey, value: string): void => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    // Clear key if we've run out of space
    if (e instanceof DOMException && e.code === e.QUOTA_EXCEEDED_ERR) {
      remove([key]);
    }
  }
};

export const remove = (keys: PersistKey[]): void => {
  try {
    keys.forEach((item) => window.localStorage.removeItem(item));
  } catch {
    // ignore error, mostly jest tests having issue with this
  }
};

export const clear = (): void => {
  window.localStorage.clear();
};
