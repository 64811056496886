import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSaver, useCanReadSuper } from '@spaceship-fspl/auth';
import { Columns, Stack } from '@spaceship-fspl/components';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { UserContactDetailsCard } from 'pages/account/cards/user-contact-details';
import { Routes } from 'pages/routes';
import React from 'react';

interface AccountConfirmContactDetailsProps {
  location: {
    state: {
      nextRoute?: Routes;
    };
  };
}

export const AccountConfirmContactDetails: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<AccountConfirmContactDetailsProps>
  >
> = ({ location }) => {
  const navigate = useNavigate();
  const isVoyagerUser = useCanReadSaver();
  const isSuperUser = useCanReadSuper();
  const canCancel = isVoyagerUser || isSuperUser;

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <Stack spaceY="xl">
            <PageHeading title="Is your contact information up to date?" />
            <UserContactDetailsCard />
          </Stack>

          <PageFormButtonContainer>
            <PageFormContinueButton
              trackingProperties={{
                name: 'account_confirm_contact_details_continue',
              }}
              onClick={() => {
                if (location?.state?.nextRoute) {
                  navigate(location.state.nextRoute);
                } else {
                  navigate(Routes.ACCOUNT_USER_DETAILS);
                }
              }}
            >
              Continue
            </PageFormContinueButton>

            {canCancel && (
              <PageFormCancelButton
                trackingProperties={{
                  name: 'account_confirm_contact_details_cancel',
                }}
                onClick={() => {
                  navigate(Routes.ACCOUNT_USER_DETAILS);
                }}
              />
            )}
          </PageFormButtonContainer>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};
