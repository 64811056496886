import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useCanReadSaver, useIsAuthenticated } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useUpdateUserSignupContact } from '@spaceship-fspl/data';
import { WebAppSuperSignupTfn } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperSignupTfn';
import { WebAppSuperUpdateTfn } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperUpdateTfn';
import { ExternalRoutes, InternalRoutes } from '@spaceship-fspl/helpers';
import {
  ApiErrorCodes,
  useCreateAccount,
  useUpdateMemberDetails,
} from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { SuperProductInstance } from '@spaceship-fspl/types/externalapi';
import { ControllerInput } from 'components/controller-input';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageFormSkipButton,
  PageHeading,
} from 'components/layouts/page';
import { useSuperReferralCode, useSuperReferrer } from 'contexts/deep-linking';
import { useNotifications } from 'contexts/notifications';
import { useSuperOnboarding } from 'contexts/super/onboarding';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { useLocalStorage } from 'helpers/hooks/local-storage';
import { addRumError } from 'helpers/monitoring';
import { PersistKey } from 'helpers/persist';
import { commonValidationRules } from 'helpers/validation';
import { navigateToLogInWhenUnauthenticated } from 'navigation/helpers';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Routes } from '../routes';

interface SuperTfnFormProps {
  onSubmit: (tfn?: string) => void;
  cancelButton?: {
    text: string;
    trackingPropName: string;
  };
  skipButton?: {
    text: string;
    trackingPropName: string;
  };
  saveButton: {
    text: string;
    trackingPropName: string;
  };
}

const SuperTfnForm: React.FC<React.PropsWithChildren<SuperTfnFormProps>> = ({
  onSubmit,
  cancelButton,
  saveButton,
  skipButton,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control } = useForm<{ tfn: string }>();

  const submitTfn = async (tfn?: string): Promise<void> => {
    setIsLoading(true);
    await onSubmit(tfn);
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(({ tfn }) => {
        submitTfn(tfn);
      })}
    >
      <Stack spaceY="lg">
        <Stack spaceY="xxs">
          <ControllerInput
            control={control}
            name="tfn"
            type="text"
            placeholder="Tax file number"
            rules={commonValidationRules.taxFileNumber}
          />
          <Text variant={3} color="indigo.080">
            <TextLink href={ExternalRoutes.ATO_FORGOT_TFN} target="_blank">
              Forgot your TFN?
            </TextLink>
          </Text>
        </Stack>

        <Stack spaceY="xxs">
          <Text component="h4" variant={2} isBold={true}>
            DISCLAIMER
          </Text>

          <Stack spaceY="md">
            <Text variant={3}>
              The Trustee for Spaceship Super is authorised to collect, use and
              disclose your TFN under the Superannuation Industry (Supervision)
              Act 1993. Spaceship Capital Limited as Promoter of Spaceship Super
              collects your TFN on behalf of the Trustee. Your details are
              collected in accordance with our{' '}
              <TextLink href={InternalRoutes.PRIVACY_POLICY} target="_blank">
                Privacy Policy and Privacy Collection Notice
              </TextLink>
              .
              <br />
              <br />
              You don&apos;t need to provide your TFN to Spaceship Super.
              However, providing it will have the following advantages:
            </Text>
            <Text component="ul" variant={3}>
              <li>
                The Trustee will be able to accept all permitted types of
                contributions to your Spaceship Super account;
              </li>
              <li>
                You won&apos;t pay more tax than you need to – other than the
                tax that may ordinarily apply. This affects both contributions
                to your Spaceship Super account and benefit payments when you
                start drawing down your super account; and
              </li>
              <li>
                It&apos;ll make it much easier to find other super accounts in
                your name so that you receive all your super benefits when you
                retire.
              </li>
            </Text>
            <Text variant={3}>
              When your super monies are transferred into, or out of, your
              Spaceship Super account, the Trustee of Spaceship Super may
              disclose your TFN to another super provider, unless you request in
              writing that you do not want Spaceship to disclose your TFN to any
              other super provider.
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <PageFormButtonContainer>
        <PageFormContinueButton
          isLoading={isLoading}
          trackingProperties={{ name: saveButton.trackingPropName }}
        >
          {saveButton.text}
        </PageFormContinueButton>

        {skipButton && (
          <PageFormSkipButton
            isDisabled={isLoading}
            onClick={submitTfn}
            trackingProperties={{ name: skipButton.trackingPropName }}
          >
            {skipButton.text}
          </PageFormSkipButton>
        )}

        {cancelButton && (
          <PageFormCancelButton
            isDisabled={isLoading}
            onClick={submitTfn}
            trackingProperties={{ name: cancelButton.trackingPropName }}
          >
            {cancelButton.text}
          </PageFormCancelButton>
        )}
      </PageFormButtonContainer>
    </form>
  );
};

export const SuperUpdateTfn: React.FC<
  React.PropsWithChildren<
    RouteComponentProps<{
      isOnboarding?: boolean;
    }>
  >
> = ({ isOnboarding = false }) => {
  const resp = useQuery<WebAppSuperUpdateTfn>(gql`
    query WebAppSuperUpdateTfn {
      contact {
        id
      }
    }
  `);
  const contactId = resp?.data?.contact?.id;

  const { popToast } = useNotifications();
  const { updateMember } = useUpdateMemberDetails();
  const Container = isOnboarding ? OnboardingContainer : PageContainer;

  const onSubmit = async (tfn?: string): Promise<void> => {
    if (tfn) {
      try {
        await updateMember(contactId, { taxId: tfn });
        popToast({
          level: 'success',
          message: 'Your tax file number details have been updated',
        });
      } catch (errorResponse) {
        const error = await (errorResponse as Response).json();
        addRumError({ error });
        popToast({
          level: 'error',
          message: error?.errors?.[0]?.detail || GENERIC_ERROR_MESSAGE,
        });
        return;
      }
    }

    window.history.back();
  };

  return (
    <Container>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, xl: 1 / 2 }}>
          <Box
            marginTop={{ xs: 'lg', lg: 'xl' }}
            marginBottom={{ xs: 'none', md: 'xxl' }}
          >
            <Box marginBottom="xl">
              <PageHeading
                title="Tax File Number (TFN)"
                subtitle="You can provide Spaceship with your TFN to help manage your Spaceship Super account."
              />
            </Box>

            <SuperTfnForm
              onSubmit={onSubmit}
              cancelButton={{
                text: 'Cancel',
                trackingPropName: 'update_super_tfn_cancel',
              }}
              saveButton={{
                text: 'Save',
                trackingPropName: 'update_super_tfn_submit',
              }}
            />
          </Box>
        </Columns.Column>
      </Columns>
    </Container>
  );
};

const SignupTfn: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const { gender, portfolio, setShowSuccessFlow } = useSuperOnboarding();
  const { popToast } = useNotifications();
  const isLoggedIn = useIsAuthenticated();
  const isVoyagerUser = useCanReadSaver();
  const { createSuperAccount } = useCreateAccount();
  const { value: referralCode, setValue: setReferralCode } =
    useSuperReferralCode();
  const { setValue: setReferrer } = useSuperReferrer();
  const { mutateAsync: updateUserSignupContact } = useUpdateUserSignupContact();
  const [, setHasRequestedPortfolioSelection] = useLocalStorage<
    boolean | undefined
  >(PersistKey.SUPER_INVESTMENT_ALLOCATIONS_SELECTION_REQUESTED, false);

  const resp = useQuery<WebAppSuperSignupTfn>(gql`
    query WebAppSuperSignupTfn {
      contact {
        id
      }
    }
  `);
  const contactId = resp?.data?.contact?.id;

  if (!gender) {
    navigate(Routes.SUPER_SIGNUP_DETAILS);
    return null;
  }

  if (!portfolio) {
    navigate(Routes.SUPER_SIGNUP_PORTFOLIO);
    return null;
  }

  const onSubmit = async (tfn?: string): Promise<void> => {
    // Login
    if (!isLoggedIn) {
      await navigate(Routes.LOGIN);
      return;
    }

    try {
      if (contactId && referralCode && !isVoyagerUser) {
        await updateUserSignupContact({
          contact_id: contactId,
          campaign: referralCode,
        });
        setReferralCode(undefined);
        setReferrer(undefined);
      }

      if (!tfn) {
        track?.(MarketingTrackingEvents.SUPER_ONBOARDING_TFN_SKIP);
      } else {
        track?.(MarketingTrackingEvents.SUPER_ONBOARDING_TFN_SUBMIT);
      }

      await createSuperAccount(
        {
          gender: Number(gender),
          portfolio,
          tfn,
          joinType: SuperProductInstance.JoinType.Enum.SIGN_UP_WEB,
        },
        () => {
          setShowSuccessFlow(true);
        },
      );

      setHasRequestedPortfolioSelection(true);
      track?.(MarketingTrackingEvents.SUPER_ONBOARDING_ACCOUNT_CREATED);
      navigate(Routes.SUPER_SIGNUP_SUCCESS);
    } catch (error) {
      const castedError = error as Error;

      const lowercaseErrorMessage = castedError.message.toLowerCase();
      const isEmailAlreadyExistsError = castedError.message.includes(
        ApiErrorCodes.ACCOUNT_WITH_EMAIL_EXISTS,
      );
      const isTFNInvalid =
        lowercaseErrorMessage.includes('tax file number') &&
        lowercaseErrorMessage.includes('invalid');

      addRumError({
        error,
        context: isEmailAlreadyExistsError
          ? { message: 'Create Super Account - Email address already exists.' }
          : {},
      });

      if (isEmailAlreadyExistsError) {
        navigate(Routes.SUPER_SIGNUP_ERROR);
        return;
      }

      popToast({
        level: 'error',
        message: isTFNInvalid
          ? 'The Tax File Number is invalid'
          : castedError.message,
      });
    }
  };

  return (
    <OnboardingContainer>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <Box
            marginTop={{ xs: 'none', lg: 'md' }}
            marginBottom={{ xs: 'none', md: 'md', lg: 'xxl' }}
          >
            <Box marginBottom="xl">
              <PageHeading
                title="Provide your Tax File Number (TFN)"
                subtitle="Providing your TFN is optional, but it can help Spaceship Super manage your account and action any rollovers you request."
              />
            </Box>

            <SuperTfnForm
              onSubmit={onSubmit}
              skipButton={{
                text: 'Skip',
                trackingPropName: 'signup_super_tfn_skip',
              }}
              saveButton={{
                text: 'Continue',
                trackingPropName: 'signup_super_tfn_submit',
              }}
            />
          </Box>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

export const SuperSignupTfn = navigateToLogInWhenUnauthenticated()(SignupTfn);
