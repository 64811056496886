import {
  Box,
  Card,
  Divider,
  Heading,
  PresentationLink,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import { MissingTINReason, useValidateTIN } from '@spaceship-fspl/graphql';
import { FeatherTrash2Icon } from '@spaceship-fspl/icons-web';
import { Checkbox } from 'components/checkbox';
import { ControllerInput } from 'components/controller-input';
import { Dropdown } from 'components/dropdown';
import * as React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TaxResidencyFormData } from './hooks';

interface TaxResidencyCardProps {
  index: number;
  onRemove: () => void;
}

const MISSING_TIN_REASONS: {
  label: string;
  value: MissingTINReason | '';
}[] = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Country doesn’t provide TIN',
    value: MissingTINReason.COUNTRY_DOES_NOT_PROVIDE_TIN,
  },
  {
    label: 'Yet to be issued',
    value: MissingTINReason.TIN_YET_TO_BE_ISSUED,
  },
  {
    label: 'Other',
    value: MissingTINReason.OTHER,
  },
];

export const TaxResidencyCard: React.FC<
  React.PropsWithChildren<TaxResidencyCardProps>
> = ({ index, onRemove }) => {
  const {
    control,
    formState: { errors },
    register,
    watch,
    unregister,
  } = useFormContext<TaxResidencyFormData>();
  const inputs = watch().foreignTaxResidences?.[index];
  const declaredTinMissing = inputs?.declaredTinMissing;
  const missingTinReason = inputs?.missingTinReason;
  const isTinRequired = inputs?.tinRequired;
  const tinInformationLink = inputs?.tinInformationLink;
  const [validateTIN] = useValidateTIN();

  useEffect(() => {
    if (declaredTinMissing) {
      unregister(`foreignTaxResidences.${index as number}.tin` as const);
    }

    if (!declaredTinMissing) {
      unregister(
        `foreignTaxResidences.${index as number}.missingTinReason` as const,
      );
      unregister(
        `foreignTaxResidences.${
          index as number
        }.missingTinOtherDescription` as const,
      );
    }
  }, [declaredTinMissing, unregister, index]);

  useEffect(() => {
    if (missingTinReason !== MissingTINReason.OTHER) {
      unregister(
        `foreignTaxResidences.${
          index as number
        }.missingTinOtherDescription` as const,
      );
    }
  }, [missingTinReason, unregister, index]);

  if (inputs?.tinProvided) {
    return (
      <Card borderRadius="sm" data-testid="tax-residency-country-card">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingY="sm"
          paddingX="md"
          data-testid="tax-residency-country-card-header"
        >
          <Heading variant={5} align="center" isBold={true}>
            {inputs.countryName}
          </Heading>
          <UnstyledButton onClick={onRemove}>
            <FeatherTrash2Icon color="neutral.080" />
          </UnstyledButton>
        </Box>
        <Divider />
        <Box padding="md">
          <Text variant={2} color="neutral.080">
            TIN provided
          </Text>
          <input
            type="hidden"
            value={inputs.id}
            {...register(`foreignTaxResidences.${index as number}.id` as const)}
          />

          <input
            type="hidden"
            value={'provided'}
            {...register(
              `foreignTaxResidences.${index as number}.tinProvided` as const,
            )}
          />

          <input
            value={inputs.countryId}
            type="hidden"
            {...register(
              `foreignTaxResidences.${index as number}.countryId` as const,
            )}
          />

          <input
            value={inputs.countryName}
            type="hidden"
            {...register(
              `foreignTaxResidences.${index as number}.countryName` as const,
            )}
          />
        </Box>
      </Card>
    );
  }

  return (
    <Card borderRadius="sm" data-testid="tax-residency-country-card">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        paddingY="sm"
        paddingX="md"
        data-testid="tax-residency-country-card-header"
      >
        <Heading variant={5} align="center" isBold={true}>
          {inputs?.countryName}
        </Heading>
        <UnstyledButton onClick={onRemove}>
          <FeatherTrash2Icon color="neutral.080" />
        </UnstyledButton>
      </Box>
      <Divider />
      <input
        type="hidden"
        value={inputs?.id}
        {...register(`foreignTaxResidences.${index as number}.id` as const)}
      />
      <input
        type="hidden"
        {...register(
          `foreignTaxResidences.${index as number}.countryId` as const,
        )}
      />
      <input
        value={inputs?.countryName}
        type="hidden"
        {...register(
          `foreignTaxResidences.${index as number}.countryName` as const,
        )}
      />
      <Box padding="md">
        <Stack spaceY="sm">
          {(!declaredTinMissing || isTinRequired) && (
            <>
              <ControllerInput
                name={`foreignTaxResidences.${index as number}.tin` as const}
                control={control}
                placeholder="Tax Identification Number (TIN)"
                type="text"
                value={inputs?.tin}
                maxLength={20}
                rules={{
                  required: 'Valid TIN is required',
                  validate: async (value?: string) => {
                    if (!value) {
                      return 'Valid TIN is required';
                    }

                    if (!inputs?.countryId) {
                      return 'Country is missing';
                    }

                    try {
                      const validateResp = await validateTIN({
                        variables: {
                          input: {
                            countryId: inputs.countryId,
                            tin: value,
                          },
                        },
                      });
                      const isValid =
                        validateResp.data?.validateTIN?.valid ?? false;

                      return isValid
                        ? true
                        : 'Tax Identification Number (TIN) is invalid';
                    } catch {
                      return 'We were unable to verify your TIN';
                    }
                  },
                }}
              />

              {!!tinInformationLink && (
                <Box display="flex" justifyContent="flex-end">
                  <PresentationLink
                    size="xs"
                    color="indigo.070"
                    icon="chevron"
                    href={tinInformationLink}
                    target="_blank"
                  >
                    Find out more about {inputs?.countryName} TINs
                  </PresentationLink>
                </Box>
              )}
            </>
          )}

          {!isTinRequired && (
            <>
              <Checkbox
                defaultChecked={declaredTinMissing}
                {...register(
                  `foreignTaxResidences.${
                    index as number
                  }.declaredTinMissing` as const,
                )}
              >
                <Text variant={2}>I don&apos;t have a TIN</Text>
              </Checkbox>

              {declaredTinMissing && (
                <>
                  <Dropdown
                    options={MISSING_TIN_REASONS}
                    placeholder="Select a reason"
                    value={inputs?.missingTinReason}
                    errorMessage={
                      errors?.foreignTaxResidences?.[index]?.missingTinReason
                        ?.message
                    }
                    {...register(
                      `foreignTaxResidences.${
                        index as number
                      }.missingTinReason` as const,
                      {
                        validate: (
                          value?: MissingTINReason,
                        ): boolean | string => !!value || 'Reason is required',
                      },
                    )}
                  />
                  {missingTinReason === MissingTINReason.OTHER && (
                    <ControllerInput
                      name={
                        `foreignTaxResidences.${
                          index as number
                        }.missingTinOtherDescription` as const
                      }
                      control={control}
                      placeholder="Specify your reason why"
                      type="text"
                      rules={{
                        required: 'Specify your reason why is required',
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Card>
  );
};
