import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { FeatherArrowLeftIcon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import { AccessibilityLabel } from 'helpers/accessibility';
import { Routes } from 'pages/routes';
import React from 'react';

export const VoyagerTaxFileNumberLearnMore: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, lg: 2 / 3, xl: 1 / 2 }}>
          <Stack spaceY="md">
            <Heading variant={3} align="center" component="h1">
              About your tax details
            </Heading>

            <Text variant={2}>
              You can choose to provide us with your Tax File Number (TFN), or
              claim an exemption, for taxation purposes.
            </Text>

            <Heading variant={4} isBold={true} component="h2">
              Do I have to provide my tax details?
            </Heading>

            <Text variant={2}>
              You are not required to provide us with this information. If you
              don&apos;t, we are required by law to deduct withholding tax from
              your investment income at the highest marginal rate plus Medicare
              levy. However, you may be able to claim a credit from the ATO for
              amounts withheld as part of your tax return.
            </Text>

            <Heading variant={4} isBold={true} component="h2">
              What if I have a TFN exemption?
            </Heading>

            <Text variant={2}>
              More information about claiming an exemption from quoting a TFN
              can be found on the ATO&apos;s website.
            </Text>

            <Heading variant={4} isBold={true} component="h2">
              What&apos;s my TFN?
            </Heading>

            <Text variant={2}>
              A TFN is a unique identifier issued by the Australian Tax Office
              (ATO) for taxation purposes. Refer to the ATO&apos;s website for
              information on where to find it.
            </Text>

            <Heading variant={4} isBold={true} component="h2">
              Why do I need to provide my TFN for each portfolio?
            </Heading>

            <Text variant={2}>
              We’re required to ask your permission to use your TFN for each
              investment portfolio that you open with us. Likewise, by law, we
              can only use your TFN for the portfolio it was collected for.
            </Text>
          </Stack>

          <Box display="flex" justifyContent="center" marginTop="xl">
            <Button
              aria-label={AccessibilityLabel.GO_BACK}
              trackingProperties={{
                name: 'voyager_tfn_learn_more_back_button',
              }}
              onClick={(): void => {
                navigate(Routes.ACCOUNT_VOYAGER_DETAILS);
              }}
              variant="tertiary"
              size="lg"
            >
              <Inline spaceX="xxs" alignY="center">
                <FeatherArrowLeftIcon />
                <span>Go back</span>
              </Inline>
            </Button>
          </Box>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
