import { RouteComponentProps, useNavigate } from '@reach/router';
import { Box, Columns, Stack, Text } from '@spaceship-fspl/components';
import { GENDER_OPTIONS } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { Gender } from '@spaceship-fspl/types/externalapi';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import { useSuperOnboarding } from 'contexts/super/onboarding';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { navigateToLogInWhenUnauthenticated } from 'navigation/helpers';
import { Routes } from 'pages/routes';
import React from 'react';
import { useForm } from 'react-hook-form';

const SignupSuperGenderSelectionPage: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const navigate = useNavigate();
  const track = useTrack();
  const { setGender } = useSuperOnboarding();
  const { handleSubmit, register } = useForm<{ gender: Gender.Enum }>();

  const onSubmit = ({ gender }: { gender: Gender.Enum }): void => {
    setGender(Number(gender));
    track?.(MarketingTrackingEvents.SUPER_ONBOARDING_GENDER_CONFIRM);
    navigate(Routes.SUPER_SIGNUP_TFN);
  };

  return (
    <OnboardingContainer>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <Box
            marginTop={{ xs: 'none', lg: 'md' }}
            marginBottom={{ xs: 'none', md: 'md', lg: 'xxl' }}
          >
            <Stack spaceY="md">
              <Columns alignX="center">
                <Columns.Column width={{ xs: 1, lg: 4 / 6 }}>
                  <PageHeading title="What is your Gender?" />
                </Columns.Column>
              </Columns>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spaceY="sm">
                  {GENDER_OPTIONS.map((option) => {
                    return (
                      <RadioCard
                        contentPadding={{ padding: 'md' }}
                        key={option.value}
                        value={option.value}
                        {...register('gender', {
                          required: 'Your gender is required',
                        })}
                      >
                        <Text variant={2} isBold={true}>
                          {option.label}
                        </Text>
                      </RadioCard>
                    );
                  })}
                </Stack>
                <PageFormButtonContainer>
                  <PageFormContinueButton
                    trackingProperties={{
                      name: 'signup_super_gender_selection_submit',
                    }}
                  />
                </PageFormButtonContainer>
              </form>
            </Stack>
          </Box>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};

export const SignupSuperGenderSelection = navigateToLogInWhenUnauthenticated()(
  SignupSuperGenderSelectionPage,
);
