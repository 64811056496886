import { Match, RouteComponentProps, useParams } from '@reach/router';
import {
  useCanReadSaver,
  useCanReadSuper,
  useLogout,
} from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Divider,
  InfoPanel,
  Stack,
  TextLink,
  Visible,
} from '@spaceship-fspl/components';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import {
  FeatherInfoIcon,
  IconProps,
  StreamlineAstronomyTelescopeStarsIcon,
  StreamlineSatellite1Icon,
  StreamlineSpaceAstronautAlternateIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  color,
  heading,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { HighlightFeature } from 'components/highlight-feature';
import { PageContainer, PageHeading } from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { SuperCrossSell } from './section/super-cross-sell';
import { SuperDetails } from './section/super-details';
import { UserDetails } from './section/user-details';
import { VoyagerCrossSell } from './section/voyager-cross-sell';
import { VoyagerDetails } from './section/voyager-details';

enum AccountNavSection {
  USER = 'user',
  SUPER = 'super',
  VOYAGER = 'voyager',
}

interface AccountNavItem {
  label: string;
  value: AccountNavSection;
  icon: React.ComponentType<IconProps>;
  path: Routes;
  highlightFeature?: FeatureFlagKeys;
  dismissHighlightFeatureOnClick?: boolean;
}

interface PageParams {
  section?: string;
}

export const Account: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const { section = 'user' }: PageParams = useParams();
  const logout = useLogout();
  const canReadSuper = useCanReadSuper();
  const canReadSaver = useCanReadSaver();
  const isSuperCrossSellEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPER_CROSS_SELL,
  );
  const isVoyagerCrossSellEnabled = useFeatureFlag(
    FeatureFlagKeys.VOYAGER_CROSS_SELL,
  );

  const navItems = useMemo(() => {
    const defaultNavItems = [USER_DETAILS_NAV_ITEM];

    if (canReadSuper) {
      defaultNavItems.push(SUPER_NAV_ITEM);
    } else if (isSuperCrossSellEnabled) {
      defaultNavItems.push(SUPER_XSELL_NAV_ITEM);
    }

    if (canReadSaver) {
      defaultNavItems.push(VOYAGER_NAV_ITEM);
    } else if (isVoyagerCrossSellEnabled) {
      defaultNavItems.push(VOYAGER_XSELL_NAV_ITEM);
    }

    return defaultNavItems;
  }, [
    canReadSaver,
    canReadSuper,
    isSuperCrossSellEnabled,
    isVoyagerCrossSellEnabled,
  ]);

  const flagsResp = useSuperPortfolioFlags();
  const sftLink =
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimerLink;
  const sftLimitedServicePeriodDisclaimer =
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodDisclaimer;
  const sftLimitedServicePeriodEnabled =
    sftLink &&
    sftLimitedServicePeriodDisclaimer &&
    flagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;
  return (
    <PageContainer>
      <Stack spaceY="sm">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageHeading title="Account" />

          <Visible isHidden={{ xs: true, md: false }}>
            <Button
              trackingProperties={{ name: 'logout' }}
              variant="primary"
              size="sm"
              onClick={logout}
            >
              Log out
            </Button>
          </Visible>
        </Box>
        {sftLimitedServicePeriodEnabled && canReadSuper ? (
          <InfoPanel textVariant={3} color="pink.030" icon={FeatherInfoIcon}>
            {sftLimitedServicePeriodDisclaimer} Read more about the transfer{' '}
            <TextLink href={sftLink} target="_blank" color="indigo.070">
              here
            </TextLink>
            .
          </InfoPanel>
        ) : null}

        <Columns
          spaceX={{ xs: 'none', lg: 'md' }}
          spaceY={{ xs: 'md', lg: 'none' }}
        >
          <Columns.Column width={{ xs: 1, lg: 4 / 12, xl: 3 / 12 }}>
            {navItems.map(
              ({
                label,
                value,
                icon: Icon,
                path,
                highlightFeature,
                dismissHighlightFeatureOnClick,
              }) => (
                <Match path={path} key={value}>
                  {({ match }) => (
                    <HighlightFeature
                      as={(props) => (
                        <RouterLink
                          to={path}
                          trackingProperties={{
                            name: 'account_page_section_tab',
                          }}
                          {...props}
                        />
                      )}
                      featureFlag={highlightFeature}
                      dismissOnClick={dismissHighlightFeatureOnClick}
                      borderColor={match ? 'neutral.050' : 'neutral.015'}
                      indicatorPosition={{
                        bottom: '12px',
                        left: '40px',
                      }}
                      key={value}
                    >
                      <StyledNavItem isActive={!!match}>
                        <Icon size="md" />
                        <Box marginLeft={{ xs: 'xs', lg: 'sm' }} flex={1}>
                          {label}
                        </Box>
                      </StyledNavItem>
                    </HighlightFeature>
                  )}
                </Match>
              ),
            )}

            <Visible isHidden={{ lg: true }}>
              <Box marginTop="sm">
                <Divider color="neutral.050" size="md" />
              </Box>
            </Visible>
          </Columns.Column>

          <Columns.Column width={{ xs: 1, lg: 8 / 12, xl: 6 / 12 }}>
            {section === AccountNavSection.USER && <UserDetails />}
            {section === AccountNavSection.SUPER && canReadSuper && (
              <SuperDetails />
            )}
            {section === AccountNavSection.SUPER &&
              !canReadSuper &&
              isSuperCrossSellEnabled && <SuperCrossSell />}
            {section === AccountNavSection.VOYAGER && canReadSaver && (
              <VoyagerDetails />
            )}
            {section === AccountNavSection.VOYAGER &&
              !canReadSaver &&
              isVoyagerCrossSellEnabled && <VoyagerCrossSell />}
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

const VOYAGER_NAV_ITEM: AccountNavItem = {
  label: 'Spaceship Voyager',
  value: AccountNavSection.VOYAGER,
  icon: StreamlineSatellite1Icon,
  path: Routes.ACCOUNT_VOYAGER_DETAILS,
  highlightFeature: FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED,
  dismissHighlightFeatureOnClick: true,
};

const VOYAGER_XSELL_NAV_ITEM: AccountNavItem = {
  label: 'Spaceship Voyager',
  value: AccountNavSection.VOYAGER,
  icon: StreamlineSatellite1Icon,
  path: Routes.ACCOUNT_VOYAGER_DETAILS,
  highlightFeature: FeatureFlagKeys.VOYAGER_CROSS_SELL,
  dismissHighlightFeatureOnClick: true,
};

const SUPER_NAV_ITEM: AccountNavItem = {
  label: 'Spaceship Super',
  value: AccountNavSection.SUPER,
  icon: StreamlineAstronomyTelescopeStarsIcon,
  path: Routes.ACCOUNT_SUPER_DETAILS,
};

const SUPER_XSELL_NAV_ITEM: AccountNavItem = {
  label: 'Spaceship Super',
  value: AccountNavSection.SUPER,
  icon: StreamlineAstronomyTelescopeStarsIcon,
  path: Routes.ACCOUNT_SUPER_DETAILS,
  highlightFeature: FeatureFlagKeys.SUPER_CROSS_SELL,
  dismissHighlightFeatureOnClick: true,
};

const USER_DETAILS_NAV_ITEM: AccountNavItem = {
  label: 'Your details',
  value: AccountNavSection.USER,
  icon: StreamlineSpaceAstronautAlternateIcon,
  path: Routes.ACCOUNT_USER_DETAILS,
};

const StyledNavItem = styled.div<{ isActive: boolean }>`
  ${heading({ variant: 5 })}
  ${borderRadius('xxs')}
  ${paddingX('md')}
  ${paddingY('sm')}
  display: flex;
  align-items: center;
  ${transition}

  ${({ isActive }) =>
    isActive
      ? css`
          ${backgroundColor('neutral.050')}
          ${color('indigo.070')}
        `
      : css`
          ${color('neutral.100')}
        `}

  :hover {
    ${({ isActive }) => (!isActive ? color('neutral.080') : '')}
  }
`;
