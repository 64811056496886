import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  FeatherChevronRightIcon,
  IconProps,
  StreamlineOfficialBuilding2Icon,
  StreamlineSingleNeutralIcon,
} from '@spaceship-fspl/icons-web';
import {
  borderColor,
  borderWidth,
  spaces,
  transition,
} from '@spaceship-fspl/styles';
import { useUserAccess } from '@spaceship-fspl/super';
import { RouterLink } from 'components/router-link';
import { SuperMemberDetailsAccordion } from 'components/super-member-details';
import React from 'react';
import styled from 'styled-components';

import { Routes } from '../routes';

export const SuperContributions: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const { canShareEmployerContributionDetails } = useUserAccess();
  return (
    <Box paddingY="xl">
      <Container>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
            <Stack spaceY="lg">
              <Heading variant={3} component="h1" align="center">
                Make a contribution to your super
              </Heading>

              <Stack spaceY="md">
                {canShareEmployerContributionDetails && (
                  <OptionCard
                    icon={StreamlineOfficialBuilding2Icon}
                    title="Send details to your employer"
                    body="Share your Spaceship Super member details with your employer."
                    route={Routes.SUPER_CONTRIBUTIONS_EMPLOYER}
                  />
                )}

                <OptionCard
                  icon={StreamlineSingleNeutralIcon}
                  title="Make a voluntary contribution"
                  body="Grow your super by making a personal contribution, or a different type of contribution, using BPAY."
                  route={Routes.SUPER_CONTRIBUTIONS_VOLUNTARY}
                />

                <SuperMemberDetailsAccordion />
              </Stack>
            </Stack>
          </Columns.Column>
        </Columns>
      </Container>
    </Box>
  );
};

interface OptionCardProps {
  icon: React.ComponentType<IconProps>;
  title: string;
  body: string;
  route: Routes;
}

const OptionCard: React.FC<React.PropsWithChildren<OptionCardProps>> = ({
  icon: Icon,
  title,
  body,
  route,
}) => {
  return (
    <RouterLink
      to={route}
      trackingProperties={{ name: 'super_contributions_page_option' }}
    >
      <StyledBox
        backgroundColor="neutral.000"
        borderRadius="sm"
        boxShadow="sm"
        padding="lg"
        position="relative"
      >
        <Box
          color="indigo.070"
          position="absolute"
          top={{ xs: spaces.md, md: spaces.lg }}
          right={{ xs: spaces.md, md: spaces.lg }}
        >
          <FeatherChevronRightIcon size="md" />
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          paddingRight={{ xs: 'none', md: 'xl' }}
        >
          <Box
            color="indigo.070"
            display="flex"
            justifyContent="center"
            marginRight={{ md: 'sm' }}
            marginBottom={{ xs: 'xxs', md: 'none' }}
          >
            <Icon size="xl" />
          </Box>

          <Stack spaceY="xxs">
            <Heading
              variant={5}
              component="h3"
              align={{ xs: 'center', md: 'left' }}
            >
              {title}
            </Heading>
            <Text variant={2} align={{ xs: 'center', md: 'left' }}>
              {body}
            </Text>
          </Stack>
        </Box>
      </StyledBox>
    </RouterLink>
  );
};

const StyledBox = styled(Box)`
  ${borderColor('neutral.000')}
  ${borderWidth('md')}
  border-style: solid;
  ${transition}

  :hover, :focus {
    ${borderColor('indigo.070')}
  }
`;
