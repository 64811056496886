import { navigate } from '@reach/router';
import { useTreatment } from '@spaceship-fspl/experimentation';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import { useUserAccess } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { ResourceItemProps, ResourceList } from 'components/resource-list';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { ExperimentNames } from 'helpers/experimentation';
import { useIsEligibleForSuperReferrals } from 'helpers/hooks/use-is-eligible-for-super-referrals';
import { Routes } from 'pages/routes';
import React from 'react';

import {
  AccountCard,
  AccountCardContent,
  AccountCardHeading,
} from './components';

export const SuperOtherActionsCard: React.FC<React.PropsWithChildren> = () => {
  const track = useTrack();
  const { canSuperMatch } = useUserAccess();
  const canRefer = useIsEligibleForSuperReferrals();
  const isSuperMatchEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPERMATCH_ENABLED,
  );
  const supermatchDeeplinkTreatment = useTreatment(
    ExperimentNames.SUPERMATCH_DEEPLINK,
  );

  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;

  const actions: Array<ResourceItemProps> = [
    {
      label: 'Important documents',
      onClick: () => navigate(Routes.SUPER_IMPORTANT_DOCUMENTS),
    },
  ];

  if (canSuperMatch) {
    actions.unshift({
      label: 'Find and consolidate super',
      isReadOnly: !!sftLimitedServicePeriodEnabled,
      onClick: async () => {
        if (isSuperMatchEnabled) {
          navigate(Routes.SUPER_SUPERMATCH);
        } else {
          await supermatchDeeplinkTreatment.activate();

          if (supermatchDeeplinkTreatment.name === 'variation') {
            navigate(Routes.SUPER_COMING_SOON_CONSOLIDATE);
          } else {
            navigate(Routes.SUPER_CONSOLIDATE);
          }
        }
      },
    });
  }

  if (canRefer) {
    actions.push({
      label: 'Share Spaceship Super',
      isReadOnly: !!sftLimitedServicePeriodEnabled,
      onClick: () => {
        track?.(MarketingTrackingEvents.SUPER_REFERRALS_ACCOUNT_CLICK);
        navigate(Routes.SUPER_REFERRAL_SHARE_CODE);
      },
    });
  }

  return (
    <AccountCard>
      <AccountCardHeading title="Other actions" />

      <AccountCardContent
        padding={{
          paddingX: { xs: 'md', md: 'lg' },
          paddingY: { xs: 'xs', md: 'sm' },
        }}
      >
        <ResourceList
          isSuperResource={true}
          isBold={true}
          resources={actions}
        />
      </AccountCardContent>
    </AccountCard>
  );
};
