import {
  Box,
  Card,
  Divider,
  Heading,
  Inline,
  Stack,
  Text,
  UnstyledButton,
} from '@spaceship-fspl/components';
import {
  FeatherArrowRightIcon,
  FeatherClipboardIcon,
  FeatherXIcon,
} from '@spaceship-fspl/icons-web';
import { color, transition } from '@spaceship-fspl/styles';
import React, { Fragment } from 'react';
import styled from 'styled-components';

export interface TodoItem {
  title: string;
  body: string;
  action?: () => Promise<void> | void;
  isShown: boolean;
  isDismissible?: boolean;
}

export const TodoCard: React.FC<
  React.PropsWithChildren<{ items: TodoItem[] }>
> = ({ items }) => {
  return (
    <Card
      padding={{ xs: 'md', lg: 'lg' }}
      height="100%"
      minHeight={{ xs: 'auto', xl: 310 }}
    >
      <Stack spaceY="sm">
        <Inline spaceY="xxs" alignX="center" alignY="center">
          <FeatherClipboardIcon />
          <Heading variant={4}>To-do</Heading>
        </Inline>

        {items.map(({ title, body, action, isDismissible }, index) => {
          const Icon = isDismissible ? FeatherXIcon : FeatherArrowRightIcon;

          const copy = (
            <Box flex={1}>
              <Stack spaceY="xxs">
                <StyledTodoHeading component="h3">{title}</StyledTodoHeading>
                <Text variant={3} color="neutral.080">
                  {body}
                </Text>
              </Stack>
            </Box>
          );

          return (
            <Fragment key={`${title}-${index}`}>
              <Divider color="neutral.050" />
              {action ? (
                <StyledTodoItemButton onClick={action}>
                  {copy}
                  <StyledIconContainer>
                    <Icon size="md" />
                  </StyledIconContainer>
                </StyledTodoItemButton>
              ) : (
                copy
              )}
            </Fragment>
          );
        })}
      </Stack>
    </Card>
  );
};

const StyledTodoHeading = styled(Heading).attrs({
  variant: 5,
})``;

const StyledIconContainer = styled.div`
  ${color('neutral.080')}
`;

const StyledTodoItemButton = styled(UnstyledButton)`
  ${transition}
  display: flex;
  text-align: left;

  :hover {
    ${StyledTodoHeading}, ${StyledIconContainer} {
      ${color('indigo.070')}
    }
  }
`;
