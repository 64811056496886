import { RouteComponentProps } from '@reach/router';
import {
  Columns,
  Divider,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { useCreateManualRolloverRequest } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { Checkbox } from 'components/checkbox';
import { ConsentList } from 'components/consent-list';
import { OnboardingContainer } from 'components/layouts/onboarding';
import {
  PageFormButtonContainer,
  PageFormCancelButton,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { useSuperMatch } from 'contexts/super/super-match';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const SuperMatchTerms: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const track = useTrack();
  const { hasAccess, fundsToTransfer, onCancel, onSuccess, returnToStart } =
    useSuperMatch();
  const rolloverRequest = useCreateManualRolloverRequest();
  const canTransferFunds = !!fundsToTransfer && hasAccess;
  const { popToast } = useNotifications();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ agree: boolean }>({
    defaultValues: {
      agree: false,
    },
  });

  const onSubmit = handleSubmit(async (): Promise<void> => {
    if (fundsToTransfer) {
      try {
        await rolloverRequest.mutateAsync(fundsToTransfer);
        track?.(MarketingTrackingEvents.SUPERMATCH_ROLLOVER_REQUEST_SUCCESSFUL);
        onSuccess();
      } catch (errorResponse) {
        const error = await (errorResponse as Response).json();
        addRumError({
          error,
          context: {
            reason:
              'createManualRolloverRequest: unable to create rollover request',
          },
        });
        popToast({
          level: 'error',
          message: error?.errors?.[0]?.detail || GENERIC_ERROR_MESSAGE,
        });
      }
    } else {
      returnToStart();
    }
  });

  useEffect(() => {
    if (!canTransferFunds) {
      returnToStart();
    }
  }, [canTransferFunds, returnToStart]);

  return (
    <OnboardingContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
          <form onSubmit={onSubmit}>
            <Stack spaceY="lg">
              <Stack spaceY="md">
                <Stack spaceY="xl">
                  <PageHeading title="Agree to consolidate your super into Spaceship Super" />
                  <Heading variant={4}>Rollover terms and conditions</Heading>
                </Stack>

                <ConsentList>
                  <li>
                    You agree to rollover super monies currently held with the
                    super funds you have selected into your Spaceship Super
                    account. You authorise the trustee of the Spaceship Super
                    fund to rollover the nominated amount for those super
                    accounts, which may be the total balance or a part of that
                    balance.
                  </li>
                  <li>
                    You understand that by selecting a nominated amount, only
                    that amount will rollover into your Spaceship Super account
                    and the remaining balance will be retained in the super
                    account from which the amount was rolled over. In the event
                    that the balance in your other super account is less than
                    your nominated amount, your rollover request may not
                    proceed. We will let you know if this happens.
                  </li>
                  <li>
                    You understand that rolling over your other super accounts
                    to Spaceship Super will not change future super
                    contributions made by your employer. If you want your
                    employer to make future employer contributions to Spaceship
                    Super, you will need to tell them to do so – you can do this
                    by completing a Super Choice form.
                  </li>
                  <li>
                    You understand you may ask the trustee of Spaceship Super
                    for information about any fees or charges that may apply to
                    a rollover, or any other information about the effect that
                    the rollover may have on your benefits, and confirm that do
                    not require such further information at this point.
                  </li>
                  <li>
                    You consent to your TFN, and any other information held by
                    Spaceship, being disclosed and used for the purposes of
                    consolidating your super accounts and rolling your super
                    monies held by other super providers into your Spaceship
                    Super account, and meeting any requirements set by the
                    superannuation law, the ATO or other regulatory authorities
                    in relation to consolidating your super accounts;
                  </li>
                  <li>
                    You understand that your consent provided in relation to the
                    use, storage and disclosure of your TFN when you provided
                    Spaceship with your TFN details continues to apply
                    unaffected; and
                  </li>
                  <li>
                    You understand that the consents and authorisations that you
                    have provided above are in force and valid for the duration
                    of your membership with Spaceship, and that you may revoke
                    your consent at any time by contacting Spaceship.
                  </li>
                </ConsentList>
              </Stack>

              <Divider color="neutral.070" />

              <Stack spaceY="sm">
                <Heading variant={4}>Agree to terms and conditions</Heading>

                <Checkbox
                  errorMessage={errors?.agree?.message}
                  {...register('agree', {
                    required: 'This checkbox is required.',
                  })}
                >
                  <Text variant={2}>
                    I agree to rollover the nominated balances of the super
                    accounts that I have selected into my Spaceship Super
                    account and I have read and understood the rollover terms
                    and conditions.
                    <br />
                    <br />I understand that rolling over my super accounts may
                    impact other benefits that I may have with those super
                    accounts, such as life insurance, and that Spaceship does
                    not currently offer life, total permanent disability or
                    income protection insurance.
                    <br />
                    <br />I declare that all of the details in this application
                    form are true and correct. I understand that this
                    application is subject to the Spaceship Product Disclosure
                    Statement, Reference Guide, Privacy Policy, and Membership
                    Terms and Conditions which I have read, understood and
                    accepted.
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>

            <PageFormButtonContainer>
              <PageFormContinueButton
                trackingProperties={{ name: 'supermatch_terms_continue' }}
              >
                Consolidate my super
              </PageFormContinueButton>
              <PageFormCancelButton
                onClick={onCancel}
                trackingProperties={{ name: 'supermatch_terms_cancel' }}
              />
            </PageFormButtonContainer>
          </form>
        </Columns.Column>
      </Columns>
    </OnboardingContainer>
  );
};
