import * as React from 'react';
export const SmileyBlessed = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M15.702 15.348c.296 0 .574.133.755.373a.94.94 0 01.173.822A4.787 4.787 0 0112 20.14a4.779 4.779 0 01-4.63-3.597.94.94 0 01.173-.822.97.97 0 01.755-.373h7.413-.01zM14.391 9.504a2.26 2.26 0 001.913 1.062 2.26 2.26 0 001.913-1.062M9.609 9.504a2.26 2.26 0 01-1.913 1.062 2.26 2.26 0 01-1.913-1.062" />
    <path vectorEffect="non-scaling-stroke" d="M12 23c6.074 0 11-4.926 11-11S18.074 1 12 1 1 5.926 1 12s4.926 11 11 11z" />
  </svg>
);
