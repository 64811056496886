import { RouteComponentProps, Router } from '@reach/router';
import { BoostsBasiqEdit } from 'pages/boosts/basiq-edit';
import { BoostsBasiqError } from 'pages/boosts/basiq-error';
import { BoostsBasiqInstitutions } from 'pages/boosts/basiq-institutions';
import { BoostsBasiqLogin } from 'pages/boosts/basiq-login';
import { BoostsBasiqRelink } from 'pages/boosts/basiq-relink';
import { BoostsBasiqSelect } from 'pages/boosts/basiq-select';
import { BoostsBasiqSuccess } from 'pages/boosts/basiq-success';
import { BoostsBasiqUnlink } from 'pages/boosts/basiq-unlink';
import { BoostsDashboard } from 'pages/boosts/dashboard';
import { BoostsIntro } from 'pages/boosts/intro';
import { BoostsRecipeSelect } from 'pages/boosts/recipe-select';
import { BoostsRecipeSetup } from 'pages/boosts/recipe-setup';
import { BoostsRecipeSetupSuccess } from 'pages/boosts/recipe-setup-success';
import { BoostsScheduledDetails } from 'pages/boosts/scheduled-details';
import { BoostsTermsAndConditions } from 'pages/boosts/terms-and-conditions';
import { PageNotFound } from 'pages/page-not-found';
import React from 'react';

export const Boosts: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <Router>
      <BoostsIntro path="intro" />
      <BoostsDashboard path="dashboard/:section" />
      <BoostsRecipeSelect path="recipe/select" />
      <BoostsRecipeSetup path="recipe/setup" />
      <BoostsRecipeSetupSuccess path="recipe/setup/success" />
      <BoostsScheduledDetails path="scheduled-details/:boostItemGroupId" />
      <BoostsTermsAndConditions path="terms-and-conditions" />
      <BoostsBasiqLogin path="basiq/login" />
      <BoostsBasiqSelect path="basiq/select" />
      <BoostsBasiqEdit path="basiq/edit" />
      <BoostsBasiqInstitutions path="basiq/institutions" />
      <BoostsBasiqRelink path="basiq/relink/:connectionId" />
      <BoostsBasiqUnlink path="basiq/unlink/:connectionId" />
      <BoostsBasiqError path="basiq/error" />
      <BoostsBasiqSuccess path="basiq/success" />
      <PageNotFound default={true} />
    </Router>
  );
};
