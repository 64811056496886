import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Box, Columns, Heading, Stack, Text } from '@spaceship-fspl/components';
import { WebAppSuperUnitPrice } from '@spaceship-fspl/graphql/src/__generated__/WebAppSuperUnitPrice';
import { formatDate } from '@spaceship-fspl/helpers';
import {
  useGetCurrentInvestmentAllocation,
  useUnitPrice,
} from '@spaceship-fspl/super';
import { PageContainer } from 'components/layouts/page';
import React from 'react';

import { SuperUnitPriceCard } from './unit-price-card';

export const SuperUnitPrice: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const currentSuperPortfolioKey =
    useGetCurrentInvestmentAllocation().investmentAllocation?.key;

  const resp = useQuery<WebAppSuperUnitPrice>(
    gql`
      query WebAppSuperUnitPrice($portfolio: SuperPortfolio!) {
        superPortfolioInformation(portfolio: $portfolio) {
          id
          name
        }
      }
    `,
    {
      skip: !currentSuperPortfolioKey,
      variables: {
        portfolio: currentSuperPortfolioKey?.toUpperCase(),
      },
    },
  );
  const info = resp.data?.superPortfolioInformation;

  const { latestUnitPrice } = useUnitPrice();

  const latestUnitPriceDate = latestUnitPrice?.date
    ? new Date(latestUnitPrice.date)
    : undefined;

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3 }}>
          <Heading component="h1" variant={3}>
            Spaceship Super unit price
          </Heading>

          <Box marginTop="lg" marginBottom="sm">
            <SuperUnitPriceCard
              chartHeight={340}
              portfolioFriendlyName={info?.name}
            />
          </Box>

          <Stack spaceY="md">
            <Text variant={4} color="neutral.080">
              <strong>
                Past performance is not a reliable indicator of future
                performance.
              </strong>{' '}
              Markets can go up and down, which means so too can the value of
              your investment.
            </Text>

            <Stack spaceY="sm">
              <Heading component="h2" variant={4} isBold={true}>
                What is this?
              </Heading>
              <Text variant={2} color="neutral.080">
                Unit prices reflect the value of the assets or investments
                within each investment option. The unit price shown is as of{' '}
                {formatDate(latestUnitPriceDate, 'dd MMMM yyyy')}.<br />
                <br />
                The purpose of this card is to provide you with a report on the
                unit price for your investment option. It shows the change in
                unit price over time. It does not show the individual
                performance of your investment, because the performance of your
                investment will depend on the timing of your investments.
                <br />
                <br />
                This card is provided for your information purposes only. Fund
                performance is typically more volatile over shorter periods of
                time due to the sometimes unpredictable nature of the share
                market. Performance over shorter periods (such as a day, week or
                month) will give you insight into this volatility and should not
                be used to make financial decisions. You should consider your
                personal circumstances, financial situation and needs before
                making a decision to invest additional funds, hold your
                investment, or withdraw your investment.
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading component="h2" variant={4} isBold={true}>
                What is the unit price?
              </Heading>
              <Text variant={2} color="neutral.080">
                The unit price (or value of each unit) is determined by dividing
                the fund&apos;s net asset value by the number of units on issue.
                Every unit you own represents your share of the investment
                option. The unit price is calculated up to four decimal places.
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading component="h2" variant={4} isBold={true}>
                How is the change in unit price calculated?
              </Heading>
              <Text variant={2} color="neutral.080">
                We calculate the unit price change on a rolling basis from the
                relevant period to the most recent unit price available. For
                example, the &apos;Year&apos; percentage is calculated as the
                change in unit price from 12 months ago to the date of the most
                recent unit price available.
              </Text>
            </Stack>

            <Stack spaceY="sm">
              <Heading component="h2" variant={4} isBold={true}>
                When is this information updated?
              </Heading>
              <Text variant={2} color="neutral.080">
                The unit price for Spaceship Super is calculated on a daily
                basis.
              </Text>
            </Stack>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
