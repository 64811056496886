import { google } from '@spaceship-fspl/types/externalapi';
import { addDays, format, formatDistance, isValid, parseISO } from 'date-fns';
import { format as formatTz, toDate } from 'date-fns-tz';

export const AUSSIE_DATE_FORMAT_REGEX = /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/;
export const AUSSIE_DATE_FORMAT = 'dd-MM-yyyy';

export const SHORTFORM_DATE_FORMAT_REGEX = /^(\d{1,2})[-/](\d{4})$/;
export const DATE_FORMAT_REQUESTS = 'yyyy-MM-dd';
export const DATE_FORMAT_SHORT_MONTH_NAME = 'd MMM yyyy';
export const DATE_FORMAT_LONG_MONTH_NAME = 'd MMMM yyyy';
export const DATE_FORMAT_TRANSACTIONS_SHORT = 'EEE, d MMM yyyy';
export const DATE_FORMAT_TRANSACTIONS_LONG = 'EEEE, d MMMM yyyy';

export const TIMEZONE_SYDNEY = { timeZone: 'Australia/Sydney' };

export const daysToMs = (days: number): number => 1000 * 60 * 60 * 24 * days;

export enum TimeScale {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  TWELVE_MONTHS = 12,
  MAX = 'All time',
}

export const formatTimeScale = (timeScale: TimeScale): string => {
  switch (timeScale) {
    case TimeScale.ONE_MONTH:
      return '1M';
    case TimeScale.THREE_MONTHS:
      return '3M';
    case TimeScale.SIX_MONTHS:
      return '6M';
    case TimeScale.TWELVE_MONTHS:
      return '1Y';
    case TimeScale.MAX:
      return 'All time';
  }
};

export const getToday = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const toSydneyStartOfDay = (date: Date): Date =>
  toDate(
    formatTz(date, 'yyyy-MM-dd 00:00:00z', TIMEZONE_SYDNEY),
    TIMEZONE_SYDNEY,
  );

export function sydneyDate(): Date {
  return toDate(new Date(), TIMEZONE_SYDNEY);
}

export function sydneyToday(): Date {
  return toSydneyStartOfDay(new Date());
}

export function getDateParts(dateString: string): {
  day: number;
  month: number;
  year: number;
} {
  const parts = dateString.split(/[-/]/);
  return {
    day: Number(parts[0]),
    month: Number(parts[1]) - 1,
    year: Number(parts[2]),
  };
}

export const timestampToDate = (
  time?: google.protobuf.ITimestamp | null,
): Date | null => {
  return time && typeof time.seconds == 'number' && time.seconds !== 0
    ? new Date(time.seconds * 1000)
    : null;
};

export function formatDate(
  dateToFormat: google.protobuf.ITimestamp | null,
  format?: string,
): string;
export function formatDate(dateToFormat?: Date, dateFormat?: string): string;
export function formatDate(dateToFormat: number, dateFormat?: string): string;
export function formatDate(
  dateToFormat: string | null,
  dateFormat?: string,
): string;
export function formatDate(
  dateToFormat:
    | google.protobuf.ITimestamp
    | Date
    | number
    | string
    | undefined
    | null,
  dateFormat = 'dd/MM/yyyy',
): string {
  if (!dateToFormat) {
    return '';
  }

  if (typeof dateToFormat === 'string') {
    if (AUSSIE_DATE_FORMAT_REGEX.test(dateToFormat)) {
      const parts = getDateParts(dateToFormat);
      return format(new Date(parts.year, parts.month, parts.day), dateFormat);
    }

    if (dateToFormat.length < 10) {
      return dateToFormat;
    }

    try {
      return format(parseISO(dateToFormat), dateFormat);
    } catch (error) {
      const castedError = error as Error | undefined;
      if (
        castedError?.message === 'Invalid date value' ||
        castedError?.message === 'Invalid time value'
      ) {
        return dateToFormat;
      } else {
        throw castedError;
      }
    }
  } else if (typeof dateToFormat === 'number') {
    return format(dateToFormat * 1000, dateFormat);
  } else if (dateToFormat instanceof Date) {
    try {
      return format(dateToFormat, dateFormat);
    } catch (error) {
      const castedError = error as Error | undefined;
      if (
        castedError?.message === 'Invalid date value' ||
        castedError?.message === 'Invalid time value'
      ) {
        return '';
      } else {
        throw castedError;
      }
    }
  }

  const dateTimestamp = timestampToDate(dateToFormat);
  return dateTimestamp ? format(dateTimestamp, dateFormat) : '';
}

// Throws
export function getShortformDateParts(dateString: string): {
  month: number;
  year: number;
} {
  const parts = dateString.split(/[-/]/);
  return {
    month: Number(parts[0]) - 1,
    year: Number(parts[1]),
  };
}

export function stringToDate(dateToFormat: string): Date {
  if (AUSSIE_DATE_FORMAT_REGEX.test(dateToFormat)) {
    const parts = getDateParts(dateToFormat);
    return new Date(parts.year, parts.month, parts.day);
  }
  if (AUSSIE_DATE_FORMAT_REGEX.test(dateToFormat)) {
    const parts = getShortformDateParts(dateToFormat);
    return new Date(parts.year, parts.month);
  }

  return new Date(dateToFormat);
}

export function formatDateForRequests(value: Date): string {
  return format(value, DATE_FORMAT_REQUESTS);
}

export function timeFromToday(value: Date | string): string {
  const today = sydneyToday();
  const dateValue = value instanceof Date ? value : stringToDate(value);
  if (isValid(dateValue)) {
    return formatDistance(today, dateValue);
  }
  return '';
}

export function getFinancialYear(value: Date | string): string {
  const dateValue = value instanceof Date ? value : stringToDate(value);
  const dateMonth = dateValue.getMonth() + 1;
  const dateYear = dateValue.getFullYear();

  if (dateMonth <= 6) {
    return dateYear.toString();
  }

  return (dateYear + 1).toString();
}

export function getLastFinancialYearDateRange(
  value: Date | string,
): [Date, Date] {
  const dateValue = value instanceof Date ? value : stringToDate(value);
  const dateMonth = dateValue.getMonth() + 1;
  const dateYear = dateValue.getFullYear();

  if (dateMonth <= 6) {
    return [
      stringToDate(`01/07/${dateYear - 2}`),
      stringToDate(`30/06/${dateYear - 1}`),
    ];
  }

  return [
    stringToDate(`01/07/${dateYear - 1}`),
    stringToDate(`30/06/${dateYear}`),
  ];
}

export function getShortformFinancialYear(value: Date | string): string {
  const year = Number(getFinancialYear(value));
  const getShortformYear = (fullYear: number): string =>
    fullYear.toString().slice(-2);

  return `${getShortformYear(year - 1)}/${getShortformYear(year)}`;
}

export { addDays };

export const getDefaultDateOfBirth = (minAge: number): Date => {
  const date = getToday();
  date.setFullYear(date.getFullYear() - minAge);

  return date;
};

export const formattedDifferenceInMonths = (
  start: Date,
  end: Date,
): string | undefined => {
  const diff =
    end.getMonth() -
    start.getMonth() +
    12 * (end.getFullYear() - start.getFullYear());

  if (isNaN(diff) || diff < 1) {
    return undefined;
  }

  if (diff == 1) {
    return `${diff} month`;
  }

  return `${diff} months`;
};
