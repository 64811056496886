import { navigate } from '@reach/router';
import { Box, Button, Heading, Stack, Text } from '@spaceship-fspl/components';
import { width } from '@spaceship-fspl/styles';
import mfaImage from 'assets/mfa@2x.png';
import { Routes } from 'pages/routes';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import { Modal } from './modal';

export interface MfaRegistrationModalRef {
  showModal: () => void;
  closeModal: () => void;
}

export const MfaRegistrationModal = forwardRef<MfaRegistrationModalRef>(
  (_, ref) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      showModal: () => {
        setShowModal(true);
      },
      closeModal: () => {
        setShowModal(false);
      },
    }));

    return (
      <Modal
        closeModal={() => {
          setShowModal(false);
        }}
        showModal={showModal}
        maxWidth={800}
        display="flex"
      >
        <Box
          padding="xl"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box marginBottom="md">
            <Stack spaceY="xs" alignX="center">
              <StyledFeatureImage src={mfaImage} alt="Security" />
              <Heading variant={3} align="center" component="h3">
                Step up your security
              </Heading>
              <Text
                variant={2}
                color="neutral.080"
                isBold={true}
                align="center"
              >
                Verify your mobile number to enable multi-factor authentication.
                It helps us help you safeguard your Spaceship account.
              </Text>
            </Stack>
          </Box>

          <Button
            type="submit"
            variant="primary"
            size="lg"
            onClick={() => {
              navigate(Routes.ACCOUNT_MFA_REGISTRATION);
            }}
          >
            Verify now
          </Button>
        </Box>
      </Modal>
    );
  },
);

MfaRegistrationModal.displayName = 'MfaRegistrationModal';

const StyledFeatureImage = styled.img`
  ${width({ xs: '180px', sm: '220px', md: '280px', xl: '350px' })}
  height: auto;
  max-width: 100%;
`;
