import {
  Box,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { Color } from '@spaceship-fspl/styles';
import qrCodeLogin from 'assets/qr-code-login.svg';
import qrCodeSignup from 'assets/qr-code-signup.svg';
import qrCodeSupermatch from 'assets/qr-code-supermatch.svg';
import { AppStoreLink } from 'components/app-store-link';
import { RouterLink } from 'components/router-link';
import { Routes } from 'pages/routes';
import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import arrowLg from './arrow-lg.svg';
import arrowMd from './arrow-md.svg';

type Theme = 'light' | 'dark';
type Size = 'md' | 'lg';

const DEFAULT_HEADING =
  'Download the Spaceship app today for 24/7 access to your investments.';
const DEFAULT_HEADING_IN_DEV =
  'This experience is currently only available on the Spaceship app';

interface CommonProps {
  theme: Theme;
  size: Size;
  appleAppStoreUrl?: string;
  googlePlayStoreUrl?: string;
  isStacked?: boolean;
  isInDevelopment?: boolean;
}

export interface VoyagerMobileAppPromotionProps extends CommonProps {
  variant: 'login' | 'signup';
}

export const VoyagerMobileAppPromotion: React.FC<
  React.PropsWithChildren<VoyagerMobileAppPromotionProps>
> = ({ isInDevelopment = false, variant, ...props }) => {
  const heading = isInDevelopment ? DEFAULT_HEADING_IN_DEV : DEFAULT_HEADING;
  const body = isInDevelopment
    ? 'While you wait, download the Spaceship app today to see when, where and how your investments, whenever you like.'
    : undefined;
  const qrCode = variant === 'login' ? qrCodeLogin : qrCodeSignup;

  return (
    <MobileAppPromotion
      qrCode={qrCode}
      heading={heading}
      body={body}
      {...props}
    />
  );
};

export type SuperMobileAppPromotionVariant = 'login' | 'signup' | 'supermatch';

export interface SuperMobileAppPromotionProps extends CommonProps {
  variant: SuperMobileAppPromotionVariant;
}

export const SuperMobileAppPromotion: React.FC<
  React.PropsWithChildren<SuperMobileAppPromotionProps>
> = ({ isInDevelopment = false, variant, ...props }) => {
  let heading = isInDevelopment ? DEFAULT_HEADING_IN_DEV : DEFAULT_HEADING;
  let body = isInDevelopment ? (
    <>
      While you wait, download the Spaceship app today to see when, where and
      how your super is invested, whenever you like.
    </>
  ) : undefined;
  let qrCode;

  switch (variant) {
    case 'supermatch':
      if (isInDevelopment) {
        heading = 'Download the mobile app to find and consolidate your super';
        body = (
          <>
            Download the Spaceship app today to see when, where and how your
            super is invested, whenever you like, with your digital dashboard.
            <br />
            <br />
            If you don&apos;t have your phone handy right now, don&apos;t worry
            — here are some other ways you can{' '}
            <RouterLink
              to={Routes.SUPER_CONSOLIDATE}
              trackingProperties={{
                name: 'super_mobile_app_promotion_consolidate',
              }}
            >
              <TextLink color="indigo.070" component="span">
                find and consolidate your super
              </TextLink>
            </RouterLink>
            .
          </>
        );
      }
      qrCode = qrCodeSupermatch;
      break;
    case 'signup':
      qrCode = qrCodeSignup;
      break;
    case 'login':
    default:
      qrCode = qrCodeLogin;
      break;
  }

  return (
    <MobileAppPromotion
      qrCode={qrCode}
      heading={heading}
      body={body}
      {...props}
    />
  );
};

interface MobileAppPromotionProps extends CommonProps {
  qrCode: string;
  heading: React.ReactNode;
  body?: React.ReactNode;
}

const MobileAppPromotion: React.FC<
  React.PropsWithChildren<MobileAppPromotionProps>
> = ({
  theme,
  size,
  isStacked,
  qrCode,
  appleAppStoreUrl,
  googlePlayStoreUrl,
  heading,
  body,
}) => {
  const isDark = theme === 'dark';
  const isMdSize = size === 'md';
  const appStoreLinkVariant = isDark ? 'dark' : 'transparent';
  const appStoreLinkSize = isStacked ? 'xxl' : isMdSize ? 'lg' : 'xl';
  const qrCodeSize = isStacked || !isMdSize ? QR_CODE_LG : QR_CODE_MD;

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', lg: isStacked ? 'column' : 'row-reverse' }}
      alignItems={{ lg: isStacked ? 'center' : 'flex-start' }}
      justifyContent="space-between"
    >
      <Box
        display={{ xs: 'none', lg: 'flex' }}
        flexDirection={{ lg: isStacked ? 'row' : 'row-reverse' }}
        alignItems="center"
        position="relative"
        width={isStacked ? 'unset' : '50%'}
      >
        <Box
          height={qrCodeSize}
          width={qrCodeSize}
          color={isDark ? 'indigo.070' : 'indigo.040'}
        >
          <StyledQRCodeSVG src={qrCode} />
        </Box>

        <Box
          paddingX="sm"
          position={{ lg: isStacked ? 'absolute' : 'static' }}
          left={{ lg: isStacked ? '100%' : 'auto' }}
        >
          <QrCodeInstruction
            arrowDirection={isStacked ? 'left' : 'right'}
            size={size}
            color={isDark ? 'neutral.080' : 'neutral.070'}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginTop={{ lg: isStacked ? 'lg' : 'none' }}
      >
        <Stack spaceY="md">
          <Heading
            variant={isMdSize ? 5 : 2}
            color={isDark ? 'neutral.100' : 'neutral.000'}
            align={{ xs: 'center', lg: isStacked ? 'center' : 'left' }}
            isBold={true}
          >
            {heading}
          </Heading>

          {body && (
            <Text
              variant={1}
              align={{ xs: 'center', lg: isStacked ? 'center' : 'left' }}
            >
              {body}
            </Text>
          )}
        </Stack>

        <Box
          display="flex"
          alignItems={{ xs: 'center', lg: isStacked ? 'center' : 'flex-start' }}
          justifyContent={isStacked ? 'center' : 'flex-start'}
          flexDirection={{ xs: 'column', lg: 'row' }}
          marginTop={{
            xs: 'md',
            lg: isStacked ? 'lg' : isMdSize ? 'xxs' : 'md',
          }}
        >
          <AppStoreLink
            store="gplay"
            variant={appStoreLinkVariant}
            size={appStoreLinkSize}
            url={googlePlayStoreUrl}
          />

          <Box
            marginTop={{ xs: 'sm', lg: 'none' }}
            marginLeft={{ lg: isMdSize ? 'xs' : 'md' }}
          >
            <AppStoreLink
              store="ios"
              variant={appStoreLinkVariant}
              size={appStoreLinkSize}
              url={appleAppStoreUrl}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const QrCodeInstruction: React.FC<
  React.PropsWithChildren<{
    arrowDirection: 'left' | 'right';
    size: 'md' | 'lg';
    color: Color;
  }>
> = ({ arrowDirection, size, color }) => {
  const isMd = size === 'md';
  const isLeft = arrowDirection === 'left';

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isMd ? 82 : 126}
      position="relative"
      color={color}
    >
      <StyledQrCodeInstructionContainer
        height={isMd ? 14 : 20}
        width={isMd ? 41 : 63}
        isLeft={isLeft}
      >
        <SVG src={isMd ? arrowMd : arrowLg} />
      </StyledQrCodeInstructionContainer>

      <Box marginTop="md">
        <StyledQrCodeInstructionCopy variant={isMd ? 4 : 2} color={color}>
          Scan code
          <br />
          for app
        </StyledQrCodeInstructionCopy>
      </Box>
    </Box>
  );
};

const QR_CODE_MD = 120;
const QR_CODE_LG = 180;

const StyledQRCodeSVG = styled(SVG)`
  height: 100%;
  width: 100%;
`;

const StyledQrCodeInstructionCopy = styled(Text).attrs({
  isBold: true,
  align: 'center',
})`
  white-space: nowrap;
`;

const StyledQrCodeInstructionContainer = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
})<{ isLeft: boolean }>`
  ${({ isLeft }) =>
    isLeft
      ? `
    left: 0;
    transform: scaleX(-1);
  `
      : `
    left: 50%;
  `}
`;
