import * as React from 'react';
export const StreamlineAstronomyTelescopeStars = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path vectorEffect="non-scaling-stroke" d="M7.216 23l4.47-7.45M16.155 23l-4.47-7.45M11.685 15.55V23M3.812 10.718l2.3-.939 1.125 2.76-2.299.938a.497.497 0 01-.647-.273l-.75-1.839a.497.497 0 01.271-.647z" />
    <path vectorEffect="non-scaling-stroke" d="M6.007 8.213l5.058-2.065 2.252 5.518L8.26 13.73a.496.496 0 01-.648-.272L5.735 8.86a.497.497 0 01.272-.647zM18.809 1.463a.744.744 0 00-1.04-.376L11.1 4.453a.745.745 0 00-.341.94l2.868 7.028a.745.745 0 00.9.433l7.123-2.264a.746.746 0 00.478-.993L18.81 1.463zM20.623 14.806v4.47M18.389 17.04h4.47M4.235 1.397v4.47M2 3.632h4.47" />
  </svg>
);
