import {
  Accordion,
  Box,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FundFromSpaceshipIcon,
  StreamlineCashPaymentWalletIcon,
  StreamlineCursorChooseIcon,
} from '@spaceship-fspl/icons-web';
import {
  useWithdrawalLearnMoreCopy,
  WITHDRAWAL_LEARN_MORE,
} from '@spaceship-fspl/voyager';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { TestId } from 'helpers/test-ids';
import React, { forwardRef } from 'react';

const WITHDRAWAL_LEARN_MORE_ICONS = {
  [WITHDRAWAL_LEARN_MORE.PROCESSING_TIME]: StreamlineCursorChooseIcon,
  [WITHDRAWAL_LEARN_MORE.STRIKE_TIME]: FundFromSpaceshipIcon,
  [WITHDRAWAL_LEARN_MORE.TAX_CONSEQUENCES]: StreamlineCashPaymentWalletIcon,
};

interface WithdrawalInfoAccordionProps {
  isAccordionOpen?: boolean;
}

const WithdrawalInfoAccordion = forwardRef<
  HTMLDivElement,
  WithdrawalInfoAccordionProps
>(({ isAccordionOpen = false }, ref) => {
  const withdrawalLearnMoreCopy = useWithdrawalLearnMoreCopy({
    isMoneyDayOneEnabled: useFeatureFlag(FeatureFlagKeys.MONEY_DAY_ONE_ENABLED),
  });
  return (
    <Accordion
      open={isAccordionOpen}
      variant="info"
      title="How withdrawals at Spaceship work"
    >
      <Box
        data-testid={TestId.VOYAGER_WITHDRAW_INFO_ACCORDION}
        ref={ref}
        paddingY={{ md: 'xxs' }}
      >
        <Stack spaceY="md">
          {withdrawalLearnMoreCopy.map(({ label, value }) => {
            const Icon = WITHDRAWAL_LEARN_MORE_ICONS[value];

            return (
              <Box display="flex" key={value}>
                <Box marginRight={{ xs: 'sm', md: 'md' }}>
                  <Icon size="xl" color="indigo.070" />
                </Box>
                <Text variant={2} whiteSpace="pre-line">
                  {label}
                </Text>
              </Box>
            );
          })}
          <Text variant={2}>
            More information about withdrawing funds from Spaceship can be found
            in our{' '}
            <TextLink
              color="indigo.070"
              rel="noreferrer"
              href={ExternalRoutes.VOYAGER_TRANSACTIONS_FAQ}
              target="_blank"
            >
              Frequently Asked Questions
            </TextLink>{' '}
            and{' '}
            <TextLink
              color="indigo.070"
              rel="noreferrer"
              href={ExternalRoutes.MONEY_SMART_INVESTING_AND_TAX}
              target="_blank"
            >
              Moneysmart: Investing and tax
            </TextLink>
            .
          </Text>
        </Stack>
      </Box>
    </Accordion>
  );
});

WithdrawalInfoAccordion.displayName = 'WithdrawalInfoAccordion';

export default WithdrawalInfoAccordion;
