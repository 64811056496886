import { gql, useQuery } from '@apollo/client';
import { navigate, RouteComponentProps } from '@reach/router';
import { useCanWriteSuper } from '@spaceship-fspl/auth';
import {
  Box,
  Button,
  Columns,
  Heading,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  MfaVerificationType,
  useCheckMfaVerification,
  useInitiateMfaVerification,
  useUpdateContact,
  useUserProducts,
} from '@spaceship-fspl/graphql';
import { InitiateMfaVerification_initiateMfaVerification } from '@spaceship-fspl/graphql/src/__generated__/InitiateMfaVerification';
import { WebAppMfaRegistration } from '@spaceship-fspl/graphql/src/__generated__/WebAppMfaRegistration';
import { StreamlineCheckCircle1Icon } from '@spaceship-fspl/icons-web';
import { width } from '@spaceship-fspl/styles';
import mfaImage from 'assets/mfa@2x.png';
import { ControllerInputWithInlineError } from 'components/controller-input';
import {
  PageContainer,
  PageFormButtonContainer,
  PageHeading,
} from 'components/layouts/page';
import { MfaRegistrationContext } from 'contexts/mfa-registration-provider';
import { useNotifications } from 'contexts/notifications';
import { AccessibilityLabel } from 'helpers/accessibility';
import {
  commonValidationRules,
  DEFAULT_COUNTRY_CODE,
  mfaValidationRules,
} from 'helpers/validation';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { Routes } from 'pages/routes';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

export const MfaRegistration: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const context = useContext(MfaRegistrationContext);
  if (!context) {
    throw new Error(
      'MfaRegistration component must be rendered within MfaRegistrationProvider',
    );
  }

  const resp = useQuery<WebAppMfaRegistration>(gql`
    query WebAppMfaRegistration {
      contact {
        id
        phoneNumberEditable
      }
    }
  `);

  const notifications = useNotifications();

  const [newPhoneNumber, setNewPhoneNumber] = useState<string | undefined>(
    undefined,
  );
  const [changingPhoneNumber, setChangingPhoneNumber] = useState(false);

  const [initiateMfaVerification, initiateMfaVerificationMeta] =
    useInitiateMfaVerification();
  const [checkMfaVerification, checkMfaVerificationMeta] =
    useCheckMfaVerification();
  const [updateContact, updateContactMeta] = useUpdateContact();
  const canWriteSuper = useCanWriteSuper();

  const { control, handleSubmit, reset } = useForm<{
    token: string;
  }>();

  const [mfaVerification, setMfaVerification] =
    useState<InitiateMfaVerification_initiateMfaVerification | null>(null);

  const [mfaVerificationType, setMfaVerificationType] =
    useState<MfaVerificationType>(MfaVerificationType.SMS);

  const initiateVerification = useCallback(
    async (type: MfaVerificationType, resend: boolean) => {
      try {
        const response = await initiateMfaVerification({
          variables: {
            input: {
              type,
              to: newPhoneNumber,
            },
          },
        });

        // only do the pop-up on resends
        if (resend) {
          notifications.popToast({
            level: 'success',
            message: 'Code resent',
          });
        }

        setMfaVerification(response.data?.initiateMfaVerification ?? null);
      } catch (error) {
        context.onError(error);
      }
    },
    [initiateMfaVerification, notifications, context, newPhoneNumber],
  );

  const onSubmit = handleSubmit(async ({ token }) => {
    try {
      await checkMfaVerification({
        variables: {
          input: {
            answer: {
              token,
              id: mfaVerification?.id ?? '',
            },
          },
        },
      });
      reset({ token: '' });

      // If the phone number has changed, and the above checkMfaVerification has passed, then we should be all clear to
      // update the contact phone number.
      if (newPhoneNumber) {
        await updateContact({
          variables: {
            input: {
              phoneNumber: newPhoneNumber,
            },
          },
        });
      }

      context.onSuccess();
    } catch (error) {
      context.onError(error);
    }
  });

  // Initiate the verification on page load
  useEffect(() => {
    if (
      !mfaVerification &&
      !initiateMfaVerificationMeta.error &&
      !initiateMfaVerificationMeta.loading
    ) {
      initiateVerification(mfaVerificationType, false);
    }
  }, [
    initiateVerification,
    mfaVerification,
    mfaVerificationType,
    initiateMfaVerificationMeta.error,
    initiateMfaVerificationMeta.loading,
  ]);

  const loading =
    initiateMfaVerificationMeta.loading ||
    checkMfaVerificationMeta.loading ||
    updateContactMeta.loading;

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          {!mfaVerification || resp.loading || updateContactMeta.loading ? (
            <Stack spaceY="xxl" alignX="center">
              <Spinner size="lg" />
            </Stack>
          ) : changingPhoneNumber ? (
            <MfaChangePhoneNumber
              onSubmit={(phoneNumber) => {
                reset({ token: '' });
                setChangingPhoneNumber(false);
                setNewPhoneNumber(phoneNumber);
                setMfaVerification(null);
              }}
              onCancel={() => {
                reset({ token: '' });
                setChangingPhoneNumber(false);
              }}
            />
          ) : (
            <Stack spaceY="xl">
              <Stack spaceY="lg" alignX="center">
                <Heading variant={3} align="center" component="h1">
                  Verify your mobile number
                </Heading>

                <Text variant={2} align="center">
                  Please enter the six-digit verification code we sent to your
                  mobile number {mfaVerification.to}
                </Text>

                {resp?.data?.contact?.phoneNumberEditable && !canWriteSuper ? (
                  <Text variant={2} align="center">
                    {`Not the right number? `}
                    <TextLink
                      target="_blank"
                      onClick={() => {
                        setChangingPhoneNumber(true);
                      }}
                      color="indigo.070"
                      data-testid="edit-phone"
                    >
                      Edit here
                    </TextLink>
                  </Text>
                ) : null}
              </Stack>

              <form onSubmit={onSubmit}>
                <Stack spaceY="xl">
                  <ControllerInputWithInlineError
                    name="token"
                    control={control}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    autoComplete="one-time-code"
                    placeholder="Verification code"
                    disabled={loading}
                    rules={mfaValidationRules.code}
                  />

                  <Stack alignX="center" spaceY="lg">
                    <Stack alignX="center" spaceY="md">
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        isDisabled={loading}
                        isLoading={checkMfaVerificationMeta.loading}
                      >
                        Continue
                      </Button>

                      <Text
                        variant={3}
                        color="neutral.080"
                        align="center"
                        isBold={true}
                      >
                        {`Didn't receive a code? `}
                        <TextLink
                          target="_blank"
                          onClick={() => {
                            if (!loading) {
                              initiateVerification(mfaVerificationType, true);
                            }
                          }}
                          color="indigo.070"
                          data-testid="resend-code"
                        >
                          Resend code
                        </TextLink>

                        {context.whatsAppRegistrationAllowed ? (
                          <>
                            {` or `}

                            <TextLink
                              target="_blank"
                              data-testid="resend-other-method"
                              color="indigo.070"
                              onClick={() => {
                                if (
                                  mfaVerificationType ===
                                  MfaVerificationType.SMS
                                ) {
                                  setMfaVerificationType(
                                    MfaVerificationType.WHATSAPP,
                                  );
                                  initiateVerification(
                                    MfaVerificationType.WHATSAPP,
                                    false,
                                  );
                                } else {
                                  setMfaVerificationType(
                                    MfaVerificationType.SMS,
                                  );
                                  initiateVerification(
                                    MfaVerificationType.SMS,
                                    false,
                                  );
                                }
                              }}
                            >
                              send to{' '}
                              {mfaVerificationType === MfaVerificationType.SMS
                                ? 'WhatsApp'
                                : 'SMS'}
                            </TextLink>
                            {` instead.`}
                          </>
                        ) : (
                          '.'
                        )}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          )}
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

export const MfaRegistrationSuccess: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const context = useContext(MfaRegistrationContext);
  if (!context) {
    throw new Error(
      'MfaRegistrationSuccess component must be rendered within MfaRegistrationProvider',
    );
  }

  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <Stack spaceY="xl" alignX="center">
            <StreamlineCheckCircle1Icon
              color="indigo.070"
              size="xxxl"
              strokeWidth={3}
            />

            <PageHeading title="Great, you’ve verified your mobile!" />

            <PageFormButtonContainer>
              <Button
                aria-label={AccessibilityLabel.CONTINUE}
                type="submit"
                variant="primary"
                size="lg"
                onClick={context.onDone}
              >
                Done
              </Button>
            </PageFormButtonContainer>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

export const MfaChangePhoneNumber: React.FC<{
  onSubmit: (phoneNumber: string) => void;
  onCancel: () => void;
}> = ({ onSubmit, onCancel }) => {
  const resp = useUserProducts();
  const account = resp.data?.contact.account;
  const hasNova = !!account?.novaProductInstance;
  const hasSuper = !!account?.superProductInstance;
  const hasVoyager =
    !!account?.saverProductInstances &&
    account.saverProductInstances.length > 0;

  // Restrict phone number changes to Australia only if there are no product instances on the account.
  const hasAnyProductInstance = hasNova || hasSuper || hasVoyager;

  const { control, handleSubmit } = useForm<{
    phoneNumber: string;
  }>();

  return (
    <Stack spaceY="xl">
      <PageHeading title="Enter your mobile number" />

      <form
        onSubmit={handleSubmit(({ phoneNumber }) => {
          onSubmit(parsePhoneNumber(phoneNumber, 'AU').number);
        })}
      >
        <Stack spaceY="xl">
          <ControllerInputWithInlineError
            name="phoneNumber"
            control={control}
            type="tel"
            placeholder="Mobile number"
            rules={{
              required: true,
              validate: (value?: string | null) => {
                if (value) {
                  return commonValidationRules.phoneNumber.validate(
                    value,
                    !hasAnyProductInstance,
                  );
                }
                return true;
              },
              onBlur: (event: React.FocusEvent<HTMLInputElement>): void => {
                event.target.value = new AsYouType(DEFAULT_COUNTRY_CODE).input(
                  event.target.value,
                );
              },
            }}
          />

          <Stack alignX="center" spaceY="lg">
            <Stack alignX="center" spaceY="md">
              <Button variant="primary" size="lg" type="submit">
                Continue
              </Button>

              <Button
                variant="secondary"
                size="lg"
                type="button"
                onClick={onCancel}
              >
                Back
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export const MfaRegistrationIntro: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <PageContainer>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 8 / 12, lg: 5 / 12 }}>
          <Stack spaceY="xl" alignX="center">
            <Box marginBottom="md">
              <Stack spaceY="xs" alignX="center">
                <StyledFeatureImage src={mfaImage} alt="Security" />
                <Heading variant={3} align="center" component="h3">
                  Step up your security
                </Heading>
                <Text
                  variant={2}
                  color="neutral.080"
                  isBold={true}
                  align="center"
                >
                  Verify your mobile number to enable multi-factor
                  authentication. It helps us help you safeguard your Spaceship
                  account.
                </Text>
              </Stack>
            </Box>

            <PageFormButtonContainer>
              <Button
                aria-label={AccessibilityLabel.CONTINUE}
                type="submit"
                variant="primary"
                size="lg"
                onClick={() => {
                  navigate(Routes.ACCOUNT_MFA_REGISTRATION_REQUIRED);
                }}
              >
                Verify now
              </Button>
            </PageFormButtonContainer>
          </Stack>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

const StyledFeatureImage = styled.img`
  ${width({ xs: '180px', sm: '220px', md: '280px', xl: '350px' })}
  height: auto;
  max-width: 100%;
`;
