import { FeatherXIcon } from '@spaceship-fspl/icons-web';
import { color, padding, zIndex } from '@spaceship-fspl/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box } from '../box';
import { Container } from '../layout';
import { Text } from '../text';

export const SESSION_STORAGE_KEY = 'viewed_emergency_message';

const CloseButton = styled.button`
  ${color('neutral.000')}
  ${padding('xxs')}
  ${zIndex(1)}
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 0;
  margin: 0;
  position: relative;
`;

const StyledTextContainer = styled(Text).attrs({
  variant: 2,
  color: 'neutral.000',
  component: 'div',
})`
  a {
    ${color('neutral.000')}

    :active,
    :visited,
    :hover {
      ${color('neutral.030')}
    }
  }
`;

export const EmergencyMessage: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [showMessage, setShowMessage] = useState(false);

  const handleClose = (): void => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
    setShowMessage(false);
  };

  useEffect(() => {
    // sessionStorage available in client (browser) but not at build time
    setShowMessage(sessionStorage.getItem(SESSION_STORAGE_KEY) === null);
  }, []);

  if (!showMessage) {
    return null;
  }

  return (
    <Box backgroundColor="indigo.070" paddingY="sm">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'space-between', md: 'center' }}
        >
          <StyledTextContainer>{children}</StyledTextContainer>
          <CloseButton onClick={handleClose}>
            <FeatherXIcon />
          </CloseButton>
        </Box>
      </Container>
    </Box>
  );
};
