import {
  Color,
  color,
  IconSize,
  iconSizes,
  map,
  Media,
  ResponsiveValue,
} from '@spaceship-fspl/styles';
import {
  createProps,
  ResponsiveMixinFunction,
} from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

export interface IconProps {
  color?: ResponsiveValue<Color>;
  size?: ResponsiveValue<IconSize>;
  strokeWidth?: ResponsiveValue<number>;
  className?: string;
  testId?: string;
}

export type IconComponent = React.ComponentType<IconProps>;

const size: ResponsiveMixinFunction<Media, IconSize> = (
  s: ResponsiveValue<IconSize>,
) =>
  map(
    s,
    (s) => css`
      width: ${iconSizes[s]}px;
      height: ${iconSizes[s]}px;
    `,
  );

const strokeWidth: ResponsiveMixinFunction<Media, number> = (
  s: ResponsiveValue<number>,
) =>
  map(
    s,
    (s) => css`
      stroke-width: ${s};
    `,
  );

const props = createProps({
  $color: color,
  $size: size,
  $strokeWidth: strokeWidth,
});

export function createIconComponent(
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  isFillOnly = false,
): IconComponent {
  const StyledIcon = styled(Icon)`
    vertical-align: middle;
    ${props}
  `;

  const IconWrapper: React.FC<IconProps> = ({
    color,
    size = 'md',
    strokeWidth = 2,
    className,
    testId,
  }) => {
    return (
      <StyledIcon
        data-testid={testId}
        className={className}
        $color={color}
        $size={size}
        $strokeWidth={isFillOnly ? undefined : strokeWidth}
      />
    );
  };

  return IconWrapper;
}
