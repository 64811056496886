import {
  ActionLink,
  ActionLinkProps,
  UnstyledButton,
  UnstyledButtonProps,
} from '@spaceship-fspl/components';
import { useTrack } from '@spaceship-fspl/tracking';
import { TrackingEvent, TrackingProperties } from 'helpers/analytics';
import React from 'react';

type LinkButtonProps = UnstyledButtonProps &
  Pick<ActionLinkProps, 'color' | 'size'> & {
    trackingProperties: TrackingProperties;
  };

export const LinkButton: React.FC<React.PropsWithChildren<LinkButtonProps>> = ({
  children,
  onClick,
  trackingProperties,
  type = 'button',
  color = 'indigo.070',
  size,
  ...props
}) => {
  const track = useTrack();
  return (
    <UnstyledButton
      type={type}
      onClick={() => {
        track?.(TrackingEvent.CLICK, { properties: trackingProperties });
        onClick?.();
      }}
      {...props}
    >
      <ActionLink component="span" color={color} size={size}>
        {children}
      </ActionLink>
    </UnstyledButton>
  );
};
